import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLBriqueAujourdhui from '../briqueAujourdhui.tpl.html';
import TPLBriqueAujourdhuiForfaitJ from '../cwBriqueAujourdhuiForfaitJ.tpl.html';
import { CompteursColl } from 'common/badgeage/models/compteurs.collection.js';
import { CWAjouterRegulPresenceModel } from './../models/cwAjouterRegulPresence.model';
import { CWAUTHMANAGER } from 'utils/cwAuthManager';
import { CWBadgeageVirtuelDialogView } from 'common/badgeage/views/cwBadgeageVirtuelDialog.view';
import { CWDialogModel as CWBadgeageModel } from 'common/badgeage/models/cwDialog.model';
import { CWDialogPopupType, CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWDIVERS } from 'utils/cwDivers';
import { CWForfaitJourModel } from '../../../forfaitj/models/cwForfaitJour.model';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWLastBadgeModel } from 'common/badgeage/models/cwLastBadge.model';
import { CWLOG } from 'utils/cwLog';
import { CWMarqueursModel } from '../models/cwMarqueurs.model';
import { CWMSGS } from 'utils/cwMsgs';
import { CWPanneauDeroulant } from 'core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWPanneauOptionsView } from './cwPanneauOptions.view';
import { CWPortletView } from 'src/core/components/brique/cwPortlet.view';
import { CWSensBadgeageCollection } from '../models/cwSensBadgeages.collection';
import { CWSTR } from 'utils/cwStr';
import { CWToucheFonctionColl } from '../models/cwToucheFonction.collection';
import { CWTYPE } from 'tda/cwTda';
import { CWTypeStructModel } from '../models/cwTypeStruct.model';
import { forkJoin } from 'rxjs';
import { i18n } from 'src/i18n.js';
import { NAV } from 'utils/nav.js';
import { objs } from 'src/objectsRepository';
import { SYNC } from 'src/utils/sync.js';
import { UTILS } from 'utils/utils.js';
/**
 * View of Brique 'AUJOURD'HUI'
 */
export class CWAujourdhuiView extends Backbone.View<Backbone.Model> {

  _maxLines: number;
  usePopulation: boolean;
  parent: CWPortletView;
  matricule: string;
  datedeb: string;
  habContext: CWHabilitationContext;
  habContextDialog: CWHabilitationContext;
  heure: string;
  habContextLastBadge: CWHabilitationContext;
  lastBadge: string;
  bdgEtatVal: boolean;
  canBadge: boolean;
  badgeES: string;
  timer: any;
  horAuto: string;
  lastBadgeSens: string;
  toucheFonctionColl: CWToucheFonctionColl;
  paramDiverTDU: boolean;
  paramDiverTVAL: boolean;
  paramDiverDC: boolean;
  paramDiverHSORT: boolean;
  paramDiverTDUC: boolean;
  paramDiverTVALC: boolean;
  paramDiverDCC: boolean;
  paramDiverMrqRestit: boolean;
  panneau: CWPanneauDeroulant;
  outsideClickListener: (arg1: JQuery.TriggeredEvent) => any;
  habContextCompteurs: CWHabilitationContext;
  bWkfBdg: boolean;
  listSensBadgeage: CWSensBadgeageCollection;
  lastBadgeModel: CWLastBadgeModel;
  forfaitjModel: CWForfaitJourModel;
  paramDiverRegPres: string;
  templateForfaitJ: any;
  marqModel: CWMarqueursModel;
  typeStruct: CWTypeStructModel;
  module: string;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "click .cw-btn-badger": (event: JQuery.TriggeredEvent) => {
        this._enregistrerBadgeage(event, null);
      },
      "click .cw-btn-rentrer": (event: JQuery.TriggeredEvent) => {
        this._enregistrerBadgeage(event, "e");
      },
      "click .cw-btn-sortir": (event: JQuery.TriggeredEvent) => { this._enregistrerBadgeage(event, "s"); },
      "click .cw-btn-points": "_ouvrirPanneauOptions",
      "click .cw-btn-presence": "_addRegulPresence",
      "click .cw-btn-agenda": "_navigateToAgenda"
    }, options.events);
    super(options);
    this._maxLines = 10;
    this.usePopulation = false;
    this.parent = null;
    this.matricule = null;
    this.datedeb = null;
    this.bdgEtatVal = true;
    this.module = "home" + objs.appRt.workflow.get("zone");
    if (options) {
      this.usePopulation = options.usePopulation || false;
      this.parent = options.parent || null;
      this.matricule = options.matricule || null;
      this.datedeb = options.datedeb || null;
    }
    this.template = TPLBriqueAujourdhui;
    this.templateForfaitJ = TPLBriqueAujourdhuiForfaitJ;

    this.habContext = new CWHabilitationContext({
      onglet: this.module,
      foncCour: "COL_BADGVIRT.G",
      natGest: ""
    });
    this.habContextCompteurs = new CWHabilitationContext({
      onglet: this.module,
      foncCour: "COL_TPSREEL.V",
      natGest: ""
    });
    this.canBadge = (objs.appRt.workflow.configuration.get("components").badgeage.mode !== 0);
    this.lastBadgeSens = null;
    this.toucheFonctionColl = new CWToucheFonctionColl([], { "module": this.module });
    this.toucheFonctionColl.idTypeTerminal = "99";
    this.toucheFonctionColl.setHabContext(this.habContext.copy());
    this.toucheFonctionColl.habContext.update({ foncCour: "N" });
    this.bWkfBdg = false;
    this.listSensBadgeage = new CWSensBadgeageCollection([], { "module": this.module });
    this.lastBadgeModel = new CWLastBadgeModel({ matricule: this.matricule });
    this.habContextLastBadge = new CWHabilitationContext({
      onglet: this.module,
      foncCour: "COL_BADG.V",
      natGest: ""
    });
    this.lastBadgeModel.setHabContext(this.habContextLastBadge);
    this.forfaitjModel = new CWForfaitJourModel({
      matric: this.matricule,
      date: this.datedeb
    });
    this.forfaitjModel.setHabContext(new CWHabilitationContext({
      onglet: this.module,
      foncCour: (CWHABILITATION.canView("COL_HOR.V") ? "COL_HOR.V" : CWHABILITATION.canView("COL_BR_AUJ.V") ? "COL_BR_AUJ.V" : null),
      natGest: ""
    }))
    this.marqModel = new CWMarqueursModel({ "module": this.module });
    this.typeStruct = new CWTypeStructModel({ "module": this.module });

  }


  checkHabilation(): boolean {
    return CWHABILITATION.canView("COL_BADGFONC.G") || CWHABILITATION.canView("COL_BADGVIRT.G");
  }

  refreshBrique(): CWAujourdhuiView {
    const calls = [];

    this.$el.attr("aria-busy", "true");
    if (CWHABILITATION.canView("COL_BADGFONC.G") && this.canBadge === true) {
      calls.push(this.toucheFonctionColl.fetch());
    }
    if (this.listSensBadgeage.length === 0) {
      calls.push(this.listSensBadgeage.fetch());
    }
    if (CWHABILITATION.canView("COL_BADG.V")) {
      this.habContextLastBadge = new CWHabilitationContext({
        onglet: this.module,
        foncCour: "COL_BADG.V",
        natGest: ""
      });
      this.lastBadgeModel.setHabContext(this.habContextLastBadge);
      calls.push(this.lastBadgeModel.fetch({
        success: (fresh: { [key: string]: any }) => {
          if (!CWSTR.isBlank(fresh.get("dervalide")) && !CWSTR.isBlank(fresh.get("derheure")) && !CWSTR.isBlank(fresh.get("derlibelle")) && !CWSTR.isBlank(fresh.get("derdate"))) {
            this.lastBadge = i18n.t('common:bvMenu_title', {
              "0": fresh.get("derlibelle"),
              "1": CWTYPE.DATE.format(fresh.get("derdate")),
              "2": fresh.get("derheure"),
              interpolation: { escapeValue: false }
            });
            this.bdgEtatVal = fresh.get("dervalide");
          } else {
            this.lastBadge = "";
            this.bdgEtatVal = true;
          }
        }
      }));
    }

    if (CWHABILITATION.canView('COL_BR_AUJ.V') || CWHABILITATION.canView('COL_HOR.V')) {
      calls.push(this.forfaitjModel.fetch())
    }

    forkJoin(calls).subscribe(
      (): void => {//succes
        this.listenTo(this.lastBadgeModel, "change", this.postBadgeage);
        this.renderTable();
      },
      (): void => { //error
        //nothing
      }
    );
    this.delegateEvents();
    return this;
  }

  renderTable(): void {
    const json = { UTILS: UTILS, 'i18n': i18n };
    const lToday = CWTYPE.DATE.dateToStr(SYNC.getServerDate(), "YYYYMMDD");

    this.$el.empty();
    if (!CWHABILITATION.canView('COL_BADGVIRT.G') && this.forfaitjModel.isForfaitJourWithRegul()) {
      this.$el.html(this.templateForfaitJ(json));
      if (this.forfaitjModel.isPresent()) {
        const messagePresence = CWAUTHMANAGER.getGenre() === "M" ? i18n.t("common:aujourdhui.messagePresence") : i18n.t("common:aujourdhui.messagePresenceF");
        const textPresent = messagePresence + '<span class="cw-presence--state">' + i18n.t("common:aujourdhui.present") + '</span>'
        this.$el.find('.cw-buttons-presence').hide();
        this.$el.find('.cw-presence').removeClass('cw-presence--noMessage')
          .removeClass('cw-texteSuperAllege')
          .addClass("cw-presence--isPresent")
          .addClass("cw-texteNormal")
          .html(textPresent);
      }

    } else {
      this.$el.html(this.template(json));
      this.badgeES = CWDIVERS.get("badgeES");
      this.horAuto = CWDIVERS.get("horAuto");
      this.paramDiverTDU = (CWDIVERS.get("tdu") === "1");
      this.paramDiverTVAL = (CWDIVERS.get("tval") === "1");
      this.paramDiverDC = (CWDIVERS.get("dc") === "1");
      this.paramDiverHSORT = (CWDIVERS.get("hsort") === "1");
      this.paramDiverTDUC = (CWDIVERS.get("tduC") === "1");
      this.paramDiverTVALC = (CWDIVERS.get("tvalC") === "1");
      this.paramDiverDCC = (CWDIVERS.get("dcC") === "1");
      this.paramDiverMrqRestit = (CWDIVERS.get("MrqRestit") === "1");
      if (this.lastBadgeModel && !CWSTR.isBlank(this.lastBadgeModel.get("derdate")) && lToday === this.lastBadgeModel.get("derdate")) {
        const lPosBadge = this.$el.find(".cw-badgeages");
        if (lPosBadge && lPosBadge.length > 0) {
          const lDivTitle = $("<div class='cw-texteTresAllege' style='display: flex; flex-wrap: wrap;'>").html(i18n.t('common:aujourdhui.messageBadger'));
          const lDiv = $("<div class='row'>");
          const lLeft = $("<div class='d-flex flex-column bg-left'>").css({ "width": "50%" });
          const badge = $("<div class='cw-badge-heure'>");
          const lCad = $("<span class='cw-texteNormal' style='display: inline-block;'>");
          const lValide = (!this.bdgEtatVal) ? "--italic" : "";

          lPosBadge.empty();
          lPosBadge.append(lDivTitle);
          if (CWSTR.isBlank(this.lastBadgeSens)) {
            if (CWSTR.isBlank(this.lastBadgeModel.get("dersens"))) {
              this.lastBadgeSens = "";
            } else {
              this.lastBadgeSens = this.lastBadgeModel.get("dersens");
            }
          }
          if (this.lastBadgeModel && !CWSTR.isBlank(this.lastBadgeModel.get("derheure"))) {
            lCad.append(this.lastBadgeModel.get("derheure"));
          }
          badge.append(lCad);
          if (lPosBadge.hasClass("cw-badgeages--noMessage")) {
            lPosBadge.removeClass("cw-badgeages--noMessage");
          }
          if (!CWSTR.isBlank(this.listSensBadgeage.get(this.lastBadgeSens.toUpperCase()))) {
            if (!CWSTR.isBlank(this.lastBadgeSens)) {
              switch (this.lastBadgeSens.toUpperCase()) {
                case "E":
                  badge.append(" <div class='cw-texteEntree" + lValide + "'>(" + this.listSensBadgeage.get(this.lastBadgeSens.toUpperCase()).get("libelle") + ")</div>");
                  break;
                case "S":
                  badge.append(" <div class='cw-texteSortie" + lValide + "'>(" + this.listSensBadgeage.get(this.lastBadgeSens.toUpperCase()).get("libelle") + ")</div>");
                  break;
                default:
                  badge.append(" <div class='cw-texteAutresbadgeages" + lValide + "'>(" + this.listSensBadgeage.get(this.lastBadgeSens.toUpperCase()).get("libelle") + ")</div>");
              }
            }
          } else {
            badge.append(" <div class='cw-texteAutresbadgeages" + lValide + "'>(" + this.lastBadgeModel.get("derlibelle") + ")</div>");
          }
          lDivTitle.append(badge);
          lDiv.append(lLeft);
          lPosBadge.append(lDiv);
        }
      }
      this.$el.attr("aria-busy", "false");
      if (this.canBadge === false) {
        this.$el.find(".cw-buttons-badger").hide();
      } else if (this.canBadge === true && CWHABILITATION.canView('COL_BADGVIRT.G')) {
        if (!this.$el.find(".cw-buttons-badger").is(":visible")) {
          this.$el.find(".cw-buttons-badger").show();
        }
        if (this.badgeES === "0") {
          const $buttonBadge = $("<button type='button' class='cw-btn-badger btn'>").append((i18n.t('common:aujourdhui.btn_badger')).toUpperCase());
          this.$el.find('.cw-buttons-badger').prepend($buttonBadge);
        } else if (this.badgeES === "1") {
          const $buttonRentre = $("<button type='button' class='cw-btn-rentrer btn'>").append((i18n.t('common:aujourdhui.btn_rentre')).toUpperCase());
          const $buttonSors = $("<button type='button' class='cw-btn-sortir btn'>").append((i18n.t('common:aujourdhui.btn_sors')).toUpperCase());
          this.$el.find('.cw-buttons-badger').prepend($buttonSors);
          this.$el.find('.cw-buttons-badger').prepend($buttonRentre);
        }

      }
      this._checkVisibilityBoutonPoints();
    }
    this._setTitle();
  }

  _showFonctionDialog(event: JQuery.TriggeredEvent): void {
    let sensVar: any = null;
    let fonctionVar = !CWSTR.isBlank($(event.target).data("code")) ? $(event.target).data("code") : null;
    const dtm = new Date();
    const decalage = -dtm.getTimezoneOffset();
    let badgeModel: CWBadgeageModel = null;
    let cursorBadge: any = null;

    //Désactivation des liens de badgeages
    cursorBadge = this.$el.find(".btn-brique-badger").css("cursor");
    this.$el.find(".btn-brique-badger").css("cursor", "wait");
    //Dialog compte
    this.habContextDialog = new CWHabilitationContext({
      onglet: this.module,
      foncCour: "COL_BADGVIRT.G",
      natGest: "A"
    });
    if (CWSTR.isBlank(fonctionVar)) {
      const buttonId = event.target.classList[0];
      switch (buttonId) {
        case "cw-btn-badger":
          sensVar = null;
          fonctionVar = null;
          break;
        case "cw-btn-rentrer":
          sensVar = "e";
          fonctionVar = null;
          break;
        case "cw-btn-sortir":
          sensVar = "s";
          fonctionVar = null;
          break;
        default: //Fonctions
        //Nothing
      }
      this.lastBadgeSens = sensVar;
    } else {
      this.lastBadgeSens = fonctionVar;
    }
    badgeModel = new CWBadgeageModel({
      matricule: this.matricule,
      sens: sensVar,
      fonction: fonctionVar,
      decalage: decalage
    });

    badgeModel.setHabContext(this.habContextDialog);
    this.heure = CWTYPE.DATE.dateToStr(SYNC.getServerDate(), "HH:mm", "", ":");
    if (CWHABILITATION.canView("COL_BADGVIRT.G") || CWHABILITATION.canView("COL_BADGFONC.G")) {

      badgeModel.save(null, {
        success: (fresh: { [key: string]: any }) => {
          if (!CWSTR.isBlank(fresh.get("message1")) || !CWSTR.isBlank(fresh.get("message2"))) {
            const dialogCompte = new CWDialogPopupView({
              view: CWBadgeageVirtuelDialogView,
              popupType: CWDialogPopupType.CONFIRMATION,
              viewData: {
                model: fresh,
                heure: this.heure,
                appendTo: (!CWSTR.isBlank(this.module) ? $("#" + this.module) : null)
              }
            });

            dialogCompte.setHeight(150);
            dialogCompte.setWidth(450);
            dialogCompte.open();
          } else {
            const text = "<span class=\"bvline1\">" + this._buildMessage(fresh.get("cptrendu"), fresh.get("parametres")) + "</span>";
            CWMSGS.showInfo(text);
          }
          this._getLastBadge();
          //Ré-activation des liens de badgeages
          this.$el.find(".bvMenuHButtonBadge").css("cursor", cursorBadge);
        },
        error: () => {
          //Ré-activation des liens de badgeages
          this.$el.find(".bvMenuHButtonBadge").css("cursor", cursorBadge);
        }
      });
    } else {
      //Ré-activation des liens de badgeages
      this.$el.find(".bvMenuHButtonBadge").css("cursor", cursorBadge);
    }
  }

  _buildMessage(message: string, parametres: { [key: string]: string }[]): string {
    let result = null;

    if (!CWSTR.isBlank(message)) {
      result = message;
      if (!CWSTR.isBlank(parametres) && parametres.length > 0) {
        for (let i = 0; i < parametres.length; i++) {
          const next = i + 1;

          result = result.replace("@" + next, parametres[i]["@" + next]);
        }
      }
    }
    return result;
  }

  /**
   * Gets the last posted badgeage virtuel
   */
  _getLastBadge(): void {
    if (CWHABILITATION.canView("COL_BADG.V")) {
      if (CWSTR.isBlank(this.lastBadgeModel)) {
        this.lastBadgeModel = new CWLastBadgeModel({ matricule: this.matricule });
        this.habContextLastBadge = new CWHabilitationContext({
          onglet: this.module,
          foncCour: "COL_BADG.V",
          natGest: ""
        });
        this.lastBadgeModel.setHabContext(this.habContextLastBadge);
      }
      this.lastBadgeModel.fetch();
    }
  }

  _enregistrerBadgeage(event: JQuery.TriggeredEvent, badgeSens: string): void {
    this.lastBadgeSens = badgeSens;
    this._showFonctionDialog(event);
  }

  _setTitle(): void {
    if (CWSTR.isBlank(this.timer)) {
      const lPos = this.parent.$el.find(".phx-portlet-header");

      if (lPos.length > 0) {
        const ltitleText = $("<div class='cw-aujourdhui-titreText col-9'>");
        const lHeure = CWTYPE.DATE.dateToStr(SYNC.getServerDate(), "HH:mm", "", ":");
        const ltitleHeure = $("<div class='cw-aujourdhui--now cw-titreBriqueJour col-3'>").html(lHeure);
        const lToday = $("<span class='cw-titreBriqueJour'>").html(CWTYPE.DATE.dateToStr(SYNC.getServerDate(), CWTYPE._getFormatByCode("DATE_A")));
        const lcontent = (lPos.children().children()[0]) ? lPos.children().children()[0] : /*la 1er. fois*/ lPos.children(); // uniquement le text "Aujourd'hui"

        ltitleText.append(lcontent);
        ltitleText.append(lToday);
        lPos.empty();
        lPos.append(ltitleText);
        lPos.append(ltitleHeure);
        this.timer = window.setInterval(() => {
          if (objs.appRt.workflow.get("zone") === this.module && CWSTR.isBlank(objs.appRt.workflow.get("usecase"))) {
            this._updateTime();
          } else {
            if (this.timer) {
              window.clearInterval(this.timer);
              this.timer = null;
            }
          }
        }, 1000);
      }
    }
    // Marqueurs de date
    this._renderMarqDate(this.matricule, this.datedeb, this.parent.$el.find("span.cw-titreBriqueJour"));
  }

  _updateTime(): void {
    const lPos = this.parent.$el.find(".phx-portlet-header .cw-aujourdhui--now");

    if (lPos.length > 0) {
      const antVal = lPos.text();
      const lHeure = CWTYPE.DATE.dateToStr(SYNC.getServerDate(), "HH:mm", "", ":");

      if (antVal !== lHeure) {
        lPos.html(lHeure);
      }
    }
  }

  _checkVisibilityBoutonPoints(): void {
    const lExistsBoutons = (this.toucheFonctionColl.length > 0 && this.canBadge === true);
    const lHasHabBadg = CWHABILITATION.canView("COL_BADGFONC.G");
    const lCompteurInst = (this.canBadge === true && (this.paramDiverTDU || this.paramDiverTVAL || this.paramDiverDC || this.paramDiverHSORT || this.paramDiverTDUC || this.paramDiverTVALC || this.paramDiverDCC));
    const lHasHabComptInst = CWHABILITATION.canView("COL_TPSREEL.V");

    if ((lExistsBoutons && lHasHabBadg) || (lCompteurInst && lHasHabComptInst)) {
      this.$el.find(".cw-btn-points").show();
      if (!this.$el.find(".cw-buttons-badger").is(":visible")) {
        this.$el.find(".cw-buttons-badger").show();
      }
    } else {
      this.$el.find(".cw-btn-points").hide();
    }
  }

  _creationPanneauOptions(ofContainer: HTMLElement): CWPanneauDeroulant {
    const lPosition = (ofContainer) ? { my: "right top", at: "right bottom", of: ofContainer } : { my: "center top", at: "center bottom", of: this.el }
    const lCompteurInst = this.paramDiverTDU || this.paramDiverTVAL || this.paramDiverDC || this.paramDiverHSORT || this.paramDiverTDUC || this.paramDiverTVALC || this.paramDiverDCC;
    const panneau = new CWPanneauDeroulant({
      view: CWPanneauOptionsView,
      minHeight: 45,
      notIconClose: true,
      notTitle: true,
      position: lPosition,
      viewData: {
        appendTo: this.el,
        modal: false,
        parent: this,
        coll: this.toucheFonctionColl,
        existsCompteurInst: lCompteurInst,
        canBadge: this.canBadge
      }
    });

    panneau.setHeight("auto");
    panneau.setWidth("auto");
    panneau.dialogClass += " cw-pave__panneau";
    return panneau;
  }

  _ouvrirPanneauOptions(event: JQuery.TriggeredEvent, ui: any): void {
    const lPos = this.$el.find(".cw-btn-points");
    let lPosButton = null;

    if (lPos.length > 0) {
      lPosButton = lPos[0];
    } else {
      lPosButton = null;
    }
    if (!CWSTR.isBlank(this.panneau)) {
      this._destroyPanneau(event, ui);
    }
    this.panneau = this._creationPanneauOptions(lPosButton);
    this.outsideClickListener = (event: any): any => {
      if (lPos.filter(event.target).length < 1 &&
        (lPos.find("." + event.target.classList[0]).length < 1 || lPos.offsetParent()[0] !== event.target.offsetParent) &&
        (this.panneau && this.panneau.$el.find($(event.target)).length < 1 && lPos.find(event.target).length < 1)) {
        this.panneau._closePanneau(event, null);
        lPos.blur();
      }
    };
    $(this.panneau.$el).off("dialogopen");
    $(this.panneau.$el).off("dialogclose");
    $(this.panneau.$el).on("dialogopen", (): void => {
      $(document).on("click" + (this.module ? ("." + this.module) : ""), this.outsideClickListener);
    });
    $(this.panneau.$el).on("dialogclose", (): void => {
      $(document).off("click" + (this.module ? ("." + this.module) : ""), this.outsideClickListener as any);
    });
    this.panneau.open();
  }

  _clickButtonsPanneau(event: JQuery.TriggeredEvent): void {
    this.panneau.close();
    this._showFonctionDialog(event);
    this.$el.find(".cw-btn-points").blur();
  }

  _clickLinkPanneau(): void {
    this.panneau.close();
    this._showCompteursDialog();
    this.$el.find(".cw-btn-points").blur();
  }

  _showCompteursDialog(): void {
    if (this.matricule) {
      const compteursColl = new CompteursColl({ matricule: this.matricule });

      compteursColl.setHabContext(this.habContextCompteurs);
      compteursColl.reset();
      compteursColl.fetch({
        success: (fresh: Backbone.Model[]) => {
          let dialogCompteurs: CWDialogPopupView = null;
          this.heure = CWTYPE.DATE.dateToStr(SYNC.getServerDate(), "HH:mm", "", ":");
          dialogCompteurs = new CWDialogPopupView({
            view: CWBadgeageVirtuelDialogView,
            popupType: CWDialogPopupType.CONFIRMATION,
            viewData: {
              collection: fresh,
              heure: this.heure,
              selectable: false,
              sansSelection: true,
              appendTo: (!CWSTR.isBlank(this.module) ? $("#" + this.module) : null)
            }
          });
          dialogCompteurs.setHeight(450);
          dialogCompteurs.setWidth(400);
          dialogCompteurs.open();
        }
      });
    }
  }

  _destroyPanneau(event?: JQuery.TriggeredEvent, ui?: any): void {
    if (this.panneau) {
      if (this.panneau.dialog.dialog("instance")) {
        this.panneau._closePanneau(event, ui);
        this.panneau.dialog.dialog("destroy");
      }
      this.panneau.dialog = null;
      this.panneau = null;
    }
  }

  postBadgeage(): void {
    if (!CWSTR.isBlank(this.lastBadgeModel.get("dervalide")) && !CWSTR.isBlank(this.lastBadgeModel.get("derheure")) && !CWSTR.isBlank(this.lastBadgeModel.get("derlibelle")) && !CWSTR.isBlank(this.lastBadgeModel.get("derdate"))) {
      this.lastBadge = i18n.t('common:bvMenu_title', {
        "0": this.lastBadgeModel.get("derlibelle"),
        "1": CWTYPE.DATE.format(this.lastBadgeModel.get("derdate")),
        "2": this.lastBadgeModel.get("derheure"),
        interpolation: { escapeValue: false }
      });
      this.bdgEtatVal = this.lastBadgeModel.get("dervalide");
    } else {
      this.lastBadge = "";
      this.bdgEtatVal = true;
    }
    this.renderTable();
  }


  _addRegulPresence(): void {
    const model = new CWAjouterRegulPresenceModel();
    model.set("matricule", this.matricule);
    model.set('datedeb', this.datedeb);

    model.setHabContext(new CWHabilitationContext({
      onglet: this.module,
      foncCour: "COL_REG.G",
      natGest: ""
    }));

    model.save(null, {
      success: (fresh) => {
        CWLOG.debug(JSON.stringify(fresh.attribute));
        this.refreshBrique();
      }
    })

  }

  _navigateToAgenda(): void {
    objs.appRt.workflow.contextPourAgenda = {
      from: "declarBtn"
    }
    NAV.navigate(this.module, "agenda");
  }

  /**
   * Display label from marqueur
   * @param matricule matricule
   * @param date actual date
   * @param lToday space for rendered date
   */
  _renderMarqDate(matricule: string, date: string, lToday: JQuery): void {
    let typeMarq: any = null, marq: any = null, iconEtiquette: any = null;
    this.marqModel.daterech = date;
    this.marqModel.matric = matricule;
    this.typeStruct.fetch({
      success: (type: any) => {
        if ((!CWSTR.isBlank(type) && !CWSTR.isBlank(type.get("code"))) && this.paramDiverMrqRestit) {
          this.marqModel.fetch({
            success: (fresh: any) => {
              iconEtiquette = $("<span class='cw-marqDate'>");
              if (!CWSTR.isBlank(fresh) && !CWSTR.isBlank(fresh.get("marqueurs"))) {
                marq = fresh.get("marqueurs")[0];
                if (!CWSTR.isBlank(marq)) {
                  typeMarq = fresh.get("typesmarqueur")[0];
                  iconEtiquette.html(UTILS.getSVGIcon('etiquette_pleine', `${typeMarq.style.affichage}_icon`, 16));
                  iconEtiquette.attr("title", typeMarq.libelle.bold() + " - " + typeMarq.libellelong);
                  lToday.find("span.cw-marqDate").remove();
                  lToday.append(iconEtiquette);
                }
              } else {
                if (lToday.find(".cw-marqDate").length > 0) {
                  lToday.find(".cw-marqDate").remove();
                }
              }
            }
          });
        }
      }
    });
  }

  removeInterval(): void {
    if (!CWSTR.isBlank(this.timer)) {//Important: remove setInterval
      window.clearInterval(this.timer);
      this.timer = null;
    }
  }

  activateInterval(): void {
    this._setTitle();
  }
}
