import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { LOG } from 'utils/log.js';
import { STR } from 'utils/str.js';
import { SYNC } from 'utils/sync.js';
import { UTILS } from 'utils/utils.js';

/**
 * Conversion of types
 *
 * */
export var TYPE = {};
TYPE = {

  /**
   * 		This is used when we want to add automatic events, formatting, parsing to a filed.
   */
  supportedClass: ".typeHourMinute, " +
    ".typeHourMinuteSecond, " +
    ".typeHourMinuteNightly, " +
    ".typeDurationHMS, " +
    ".typeDurationHM, " +
    ".typeDuration24HM, " +
    ".typeDurationHC, " +
    ".typeDuration24HC, " +
    ".typeDurationMN, " +
    ".typeShort, " +
    ".typeLong, " +
    ".typeCustom, " +
    ".typeDecimal, " +
    ".typeDecimal_1, " +
    ".typeDecimal_2, " +
    ".typeJour, " +
    ".typeJour_0, " +
    ".typeJour_1, " +
    ".typeJour_2, " +
    ".typePercentage, " +
    ".typePercentage_0, " +
    ".typePercentage_1, " +
    ".typePercentage_2, " +
    ".typeDate",

  /**
   * 		This is used when want to define a specific mask for a field or function.
   */

  /**
   * Get the Default Saisie Mask (for INPUT Data) in the array typeapplicatif by typeClass.
   */
  _getMaskByType: function(type) {
    let lRtn = "";
    var code = this._getCodeByTypeClass(type);

    if (!STR.isBlank(code)) {
      lRtn = this._getMaskByCode(code);
    }
    return lRtn;
  },

  /**
   * Get the Default Format Mask (for SHOW data) in the array typeapplicatif by typeClass.
   */
  _getFormatByType: function(type) {
    let lRtn = "";
    var code = this._getCodeByTypeClass(type);

    if (!STR.isBlank(code)) {
      lRtn = this._getFormatByCode(code);
    }
    return lRtn;
  },

  /**
   * Get the default TYPE code by typeClass.
   */
  _getCodeByTypeClass: function(typeClass) {
    var array = [];
    array["typeHourMinute"] = "INSTHM";
    array["typeHourMinuteSecond"] = "INSTHMS";
    array["typeHourMinuteNightly"] = "INSTHM";
    array["typeDurationHM"] = "DUREEHM";
    array["typeDurationHMS"] = "DUREEHMS";
    array["typeDuration24HM"] = "DUR24HM";
    array["typeDurationHC"] = "DUREEHC";
    array["typeDuration24HC"] = "DUR24HC";
    array["typeDurationMN"] = "DUREEMN";
    array["typeShort"] = "ENTCOURT";
    array["typeLong"] = "ENTLONG";
    array["typeDecimal"] = "DECIMAL_2";
    array["typeDecimal_1"] = "DECIMAL_1";
    array["typeDecimal_2"] = "DECIMAL_2";
    array["typeJour"] = "NBJOUR_2";
    array["typeJour_0"] = "NBJOUR_0";
    array["typeJour_1"] = "NBJOUR_1";
    array["typeJour_2"] = "NBJOUR_2";
    array["typePercentage"] = "POURCENT_2";
    array["typePercentage_0"] = "POURCENT_0";
    array["typePercentage_1"] = "POURCENT_1";
    array["typePercentage_2"] = "POURCENT_2";
    array["typeDate"] = "DATE";
    return array[typeClass];
  },

  /**
   * Get the default TYPE code by typeClass.
   */
  _getTypeClassByCode: function(code) {
    var array = [];
    array["DATE"] = "typeDate";
    array["DATE_A"] = "typeDate";
    array["DATE_L"] = "typeDate";
    array["DATEHEURE"] = "typeDate";
    array["INSTHM"] = "typeHourMinute";
    array["INSTHMS"] = "typeHourMinuteSecond";
    array["DUREEHM"] = "typeDurationHM";
    array["DUREEHMS"] = "typeDurationHMS";
    array["DUREEHC"] = "typeDurationHC";
    array["DUREEMN"] = "typeDurationMN";
    array["DUR24HM"] = "typeDuration24HM";
    array["DUR24HC"] = "typeDuration24HC";
    array["ENTCOURT"] = "typeShort";
    array["ENTLONG"] = "typeLong";
    array["NBJOUR_0"] = "typeJour_0";
    array["NBJOUR_1"] = "typeJour_1";
    array["NBJOUR_2"] = "typeJour_2";
    array["POURCENT_0"] = "typePercentage_0";
    array["POURCENT_1"] = "typePercentage_1";
    array["POURCENT_2"] = "typePercentage_2";
    array["DECIMAL_1"] = "typeDecimal_1";
    array["DECIMAL_2"] = "typeDecimal_2";
    return array[code];
  },

  /**
   * Returns the TYPE object by the typeClass name.
   */
  _getTypeByTypeClass: function(typeClass) {
    var array = [];
    array["typeHourMinute"] = TYPE.HOUR_MINUTE;
    array["typeHourMinuteSecond"] = TYPE.HOUR_MINUTE_SECONDS;
    array["typeHourMinuteNightly"] = TYPE.HOUR_MINUTE_NIGHTLY;
    array["typeDurationHM"] = TYPE.DURATION.HOUR_MINUTE;
    array["typeDurationHMS"] = TYPE.DURATION.HOUR_MINUTE_SECONDS;
    array["typeDuration24HM"] = TYPE.DURATION.HOUR_MINUTE_24;
    array["typeDurationHC"] = TYPE.DURATION.HOUR_HUNDREDTH;
    array["typeDuration24HC"] = TYPE.DURATION.HOUR_HUNDREDTH_24;
    array["typeDurationMN"] = TYPE.DURATION.MINUTES;
    array["typeShort"] = TYPE.SHORT;
    array["typeLong"] = TYPE.LONG;
    array["typeDecimal"] = TYPE.DECIMAL;
    array["typeDecimal_1"] = TYPE.DECIMAL_1;
    array["typeDecimal_2"] = TYPE.DECIMAL_2;
    array["typeJour"] = TYPE.JOUR;
    array["typeJour_0"] = TYPE.JOUR_0;
    array["typeJour_1"] = TYPE.JOUR_1;
    array["typeJour_2"] = TYPE.JOUR_2;
    array["typePercentage"] = TYPE.PERCENTAGE;
    array["typePercentage_0"] = TYPE.PERCENTAGE_0;
    array["typePercentage_1"] = TYPE.PERCENTAGE_1;
    array["typePercentage_2"] = TYPE.PERCENTAGE_2;
    array["typeDate"] = TYPE.DATE;
    array["typeCustom"] = TYPE.CUSTOM;
    return array[typeClass];
  },

  /**
   * Returns the TYPE object by the type code.
   */
  _getTypeByCode: function(code) {
    var array = [];
    array["DATE"] = TYPE.DATE;
    array["DATE_A"] = TYPE.DATE;
    array["DATE_L"] = TYPE.DATE;
    array["DATEHEURE"] = TYPE.DATE;
    array["INSTHM"] = TYPE.HOUR_MINUTE;
    array["INSTHMS"] = TYPE.HOUR_MINUTE_SECONDS;
    array["DUREEHM"] = TYPE.DURATION.HOUR_MINUTE;
    array["DUREEHMS"] = TYPE.DURATION.HOUR_MINUTE_SECONDS;
    array["DUREEHC"] = TYPE.DURATION.HOUR_HUNDREDTH;
    array["DUREEMN"] = TYPE.DURATION.MINUTES;
    array["DUR24HM"] = TYPE.DURATION.HOUR_MINUTE_24;
    array["DUR24HC"] = TYPE.DURATION.HOUR_HUNDREDTH_24;
    array["ENTCOURT"] = TYPE.SHORT;
    array["ENTLONG"] = TYPE.LONG;
    array["NBJOUR_0"] = TYPE.JOUR_0;
    array["NBJOUR_1"] = TYPE.JOUR_1;
    array["NBJOUR_2"] = TYPE.JOUR_2;
    array["POURCENT_0"] = TYPE.PERCENTAGE_0;
    array["POURCENT_1"] = TYPE.PERCENTAGE_1;
    array["POURCENT_2"] = TYPE.PERCENTAGE_2;
    array["DECIMAL_1"] = TYPE.DECIMAL_1;
    array["DECIMAL_2"] = TYPE.DECIMAL_2;
    array["BOOLEEN"] = TYPE.BOOLEAN;
    array["EMAIL"] = TYPE.EMAIL;
    array["RESTDIV97"] = TYPE.RESTDIV97;

    return array[code];
  },

  /**
   * Get the appropriate saisie mask in the typeapplicatif Array.
   */
  _getMaskByCode: function(code) {
    var mask = GLOBAL_DATA.types.get(code);
    if (!STR.isBlank(mask)) {
      mask = mask.get("masque");
    }
    return mask;
  },

  /**
   * Get the appropriate format mask in the typeapplicatif Array.
   */
  _getFormatByCode: function(code) {
    var mask = GLOBAL_DATA.types.get(code);
    if (!STR.isBlank(mask)) {
      mask = mask.get("format");
    }
    return mask;
  },

  /**
   * Returns the decimal of the mask with the code.
   */
  _getDecimalsByCode: function(code) {
    var mask = GLOBAL_DATA.types.get(code);
    var decimals = 0;
    if (!STR.isBlank(mask)) {
      decimals = mask.get("decimale");
    }
    return decimals;
  },

  /**
   * Returns the code of the masks for the current attribute.
   * Search in a view if any type or attribute has defined a
   * specific Masks with typeMaskByClass[] in view or returns the default
   * value for the type.
   */
  _getMaskCode: function(attr, view, typeClass) {
    var maskCode = null;
    if (!STR.isBlank(view.typeMaskByClass)) {
      //Check input class defined masks
      maskCode = view.typeMaskByClass[attr];
      if (STR.isBlank(maskCode)) {
        //Check UC Type masks changes
        if (!STR.isBlank(typeClass)) {
          maskCode = view.typeMaskByClass[typeClass];
        }
      }
    }
    if (STR.isBlank(maskCode)) {
      //If not custom Return the default
      maskCode = TYPE._getTypeByTypeClass(typeClass).DEFAULT_MASK_CODE;
    }
    return maskCode;
  },

  /**
   * Returns a mask based on a format given for typeCustom class.
   * It counts the total of numbers that there could be in the field and
   * give them to the mask.
   */
  _getMaskByFormat: function(formatMask) {
    var mask = "";
    var numberSize = 0;
    var totalMinus = 0;
    var totalPlus = 0;
    var thereIsAMinus = false;
    var thereIsAPlus = false;

    //We count how many numbers could the field have
    for (var i = 0; i < formatMask.length; i++) {
      if (formatMask[i] === "&" || formatMask[i] === "*" || formatMask[i] === "#" ||
        formatMask[i] === "<") {
        numberSize++;
      }
      if (formatMask[i] === "-") {
        totalMinus++;
      }
      if (formatMask[i] === "+") {
        totalPlus++;
      }
    }
    if (totalMinus !== 0) {
      numberSize = numberSize + totalMinus;
      thereIsAMinus = true;
    } else if (totalPlus !== 0) {
      numberSize = numberSize + totalPlus;
      thereIsAPlus = true;
    }

    //We create the mask based on how many numbers there  are and if there is a - or a +
    for (var j = 0; j < numberSize; j++) {
      if (j === 0 && thereIsAMinus) { //There can be a - at first position of the mask
        mask = mask + "-";
      } else if (j === 0 && thereIsAPlus) { //There can be a - at first position of the mask
        mask = mask + "-";
      } else {
        mask = mask + "&";
      }
    }
    return mask;
  },

  /**
   * Checks if the parametre is an Integer number
   */
  _isInt: function(n) {
    if (!STR.isBlank(n)) {
      var numberPattern = /^[+-]?[0-9]+$/;
      if (numberPattern.test(n.toString())) {
        return true;
      }
    }
    return false;
  },

  /**
   * Gets the position of the Caret at the passed input (from the left).
   */
  _getCaretPosition: function(ctrl) {
    var CaretPos = 0;
    // IE Support
    //		if (document.selection) {
    //			ctrl.focus ();
    //			var Sel = document.selection.createRange ();
    //			Sel.moveStart ('character', -ctrl.value.length);
    //			CaretPos = Sel.text.length;
    //		}
    // Firefox support
    //		else
    if (ctrl.selectionStart || String(ctrl.selectionStart) === '0') {
      CaretPos = ctrl.selectionStart;
    }
    return (CaretPos);
  },

  /**
   * Gets if the input has a selection.
   */
  _isCaretSelection: function(ctrl) {
    var selection = false;
    if (ctrl.selectionStart !== ctrl.selectionEnd) {
      selection = true;
    }
    return selection;
  },

  /**
   * Focus and set the Caret to the pos position at ctrl HTML input element
   */
  _setCaretPosition: function(ctrl, pos) {
    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(pos, pos);
    } else if (ctrl.createTextRange) {
      var range = ctrl.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  },

  /**
   * Main function of types focus event, apply the saisie mask to the current
   * input value and saves the orig value in TEMP variable.
   */
  _eventFocus: function(e, typeClass, type, view, formatMask) {
    //Get the attribute name and his model value
    var attr = e.target.className.split(" ")[0];
    if (view.dynamicAttributes === true && view._convertDynamicAttributes) {
      attr = view._convertDynamicAttributes(attr);
    }
    var attrModelValue = STR.getElValue(view._getModel(attr), attr);

    //Set temporal Original Value on the view to detect Changes
    view.TEMP_origVal = !STR.isBlank(attrModelValue) ? attrModelValue : "";

    //Get the input Mask
    var inputMask = "";
    if (formatMask === undefined) { //When typeClass is not typeCustom
      var maskCode = TYPE._getMaskCode(attr, view, typeClass);
      inputMask = TYPE._getMaskByCode(maskCode);
    } else { //When typeClass is typeCustom
      inputMask = TYPE._getMaskByFormat(formatMask);
    }
    //Change the value of the input
    if ($(e.target).hasClass("infinityDate") && attrModelValue === TYPE.DATE.INFINITY && GLOBAL_DATA.paramDivers.get("CACH_INFIN").get("valeur") === "1") {
      $(e.target).val(type.parseOnFocus("", inputMask));
    } else {
      $(e.target).val(type.parseOnFocus(attrModelValue, inputMask));
    }
    //Select the content of the input
    if (STR.isBlank($(e.target).attr("id"))) {
      window.lastFocusedInput = { type: "className", value: e.target.className };
    } else {
      window.lastFocusedInput = { type: "id", value: $(e.target).attr("id") };
    }

    window.setTimeout(function() {
      if (window.lastFocusedInput &&
        ((window.lastFocusedInput.type === "id" && $(e.target).attr("id") === window.lastFocusedInput.value) ||
          (window.lastFocusedInput.type === "className" && e.target.className === window.lastFocusedInput.value))) {
        e.target.select();
        window.lastFocusedInput = { type: "id", value: "" };
      }
    }, 50);
  },

  /**
   * Main function of types blur event, parse the content trigger a change to modify the model,
   * show/clean errors at the input triggered, and format the input with the format mask.
   */
  _eventBlur: function(e, typeClass, type, view, formatMask) {
    var attr = e.target.className.split(" ")[0];
    var parse = type.parse(e.target.value); //Parse the input and format the input

    //LOG.debug("----------------Blur:" + $(e.target).attr("id"));
    //Get the attribute name and his model value
    if (view.dynamicAttributes === true && view._convertDynamicAttributes) {
      attr = view._convertDynamicAttributes(attr);
    }
    //Get the format Mask
    if (formatMask === undefined) {
      var maskCode = TYPE._getMaskCode(attr, view, typeClass);

      formatMask = TYPE._getFormatByCode(maskCode);
    }
    if (typeClass === "typeHourMinuteNightly") { //Anomalie 118031 For the night hours we want to check also the type as the string represent not validated values and it was not trigering the change event from 25:00 to +1:00
      //si la valeur du champ est distinct de "__:__", on fait la vérification avec origVal
      //Check changes on the original Value
      if (String(view.TEMP_origVal) !== parse["val"] && type.parseOnFocus(view.TEMP_origVal, TYPE._getMaskByCode(TYPE._getMaskCode(attr, view, typeClass))) !== e.target.value) {
        $(e.target).change();
      }
    } else {
      //Check changes on the original Value
      if ((String(view.TEMP_origVal) !== parse["val"]) || (String(view.TEMP_origVal) === "0" && String(view.TEMP_origVal) !== parse["val"])) {
        $(e.target).change();
      }
    }
    if (!parse["errors"]) {
      $(e.target).val(type.format(parse["val"], formatMask));
    }
    delete view.TEMP_origVal;
  },

  /**
   * Update the input with the new value.
   */
  _eventKeypress: function(e, typeClass, type, view, left2Right, formatMask) {
    var key = e.which || e.keyCode;
    if (key === 8 || key === 46) {
      e.preventDefault();
      return true;
    }
    // If ctrl + c or ctrl+v do nothing.
    if (e.ctrlKey && (key === 99 || key === 118)) {
      return true;
    }

    var attr = e.target.className.split(" ")[0];

    //Get the input Mask
    var inputMask = "";
    if (formatMask === undefined) { //When typeClass is not typeCustom
      var maskCode = TYPE._getMaskCode(attr, view, typeClass);
      inputMask = TYPE._getMaskByCode(maskCode);
    } else { //When typeClass is typeCustom
      inputMask = TYPE._getMaskByFormat(formatMask);
    }

    var bloqued = [9, 16, 17, 18, 20, 35, 36, 37, 38, 39, 40, 144];
    if (_.indexOf(bloqued, key) === -1) {
      var caretPos = 0;
      if (left2Right) {
        caretPos = TYPE._getCaretPosition(e.target);
      } else {
        caretPos = e.target.value.length - TYPE._getCaretPosition(e.target);
      }
      var begin = e.target.selectionStart;
      var end = e.target.selectionEnd;
      var old = $(e.target).prop("previousValue");
      var newValue = e.target.value.slice(0, begin) + String.fromCharCode(key) + e.target.value.slice(end);

      if (STR.isBlank(newValue)) {
        newValue = e.target.value;
      }
      //			if(old !== newValue){
      $(e.target).val(TYPE.MASK.applyInputMask(newValue, inputMask, old, typeClass));
      $(e.target).prop("previousValue", e.target.value);
      //			}
      if (left2Right) {
        var separators = TYPE.MASK._getSeparatorsFromMask(inputMask);
        if (_.contains(separators, e.target.value[begin])) {
          caretPos += 1; // Move the caret to the right
        }
        TYPE._setCaretPosition(e.target, caretPos + 1);
      } else {
        caretPos -= end - begin; // Move the caret the selection size
        TYPE._setCaretPosition(e.target, e.target.value.length - caretPos);
      }
      e.preventDefault();
    }
    return true;
  },

  /**
   *	Update the input in a delete character event when the direction is right to left
   */
  _eventDownRight2Left: function(e, typeClass, type, view, formatMask) {
    var caretPos;
    var key = e.which || e.keyCode;
    var attr = e.target.className.split(" ")[0];
    var inputMask = "";
    var value = $(e.target).val();
    var valueLength = null;

    //Get the input Mask
    if (formatMask === undefined) { //When typeClass is not typeCustom
      var maskCode = TYPE._getMaskCode(attr, view, typeClass);

      inputMask = TYPE._getMaskByCode(maskCode);
    } else { //When typeClass is typeCustom
      inputMask = TYPE._getMaskByFormat(formatMask);
    }
    // if I'm moving the cursor with the arrows I don't let the cursor pass the value
    if (key === 37 || key === 38 || key === 39 || key === 40) {
      caretPos = e.target.value.length - TYPE._getCaretPosition(e.target);
      if (key === 37) {
        caretPos++;
      } else {
        caretPos--;
      }
      valueLength = e.target.value.replace(/_/g, "").length;
      if (caretPos >= valueLength) {
        TYPE._setCaretPosition(e.target, e.target.value.length - valueLength);
        e.preventDefault();
      }
    }
    //backspace, delete, and escape get special treatment
    else if (key === 8 || key === 46) {
      var begin = e.target.selectionStart;
      var end = e.target.selectionEnd;
      var old = null;
      var newValue = null;

      caretPos = e.target.value.length - TYPE._getCaretPosition(e.target);
      // Move the caret the selection size
      caretPos -= end - begin;
      if (key === 8) { // Backspace, delete the next
        old = value;
        if (begin === end) {
          begin--;
          var separators = TYPE.MASK._getSeparatorsFromMask(inputMask);
          if (_.contains(separators, e.target.value.slice(begin, end))) {
            caretPos += 1; // Move the caret to the left
          }
        }
        newValue = e.target.value.slice(0, begin) + e.target.value.slice(end);
        $(e.target).val(TYPE.MASK.applyInputMask(newValue, inputMask, old, typeClass));
      } else if (key === 46) { // Delete, delete de previous
        old = value;
        if (begin === end) {
          end++;
          caretPos -= 1; // Move the caret to the right
        }
        newValue = e.target.value.slice(0, begin) + e.target.value.slice(end);
        $(e.target).val(TYPE.MASK.applyInputMask(newValue, inputMask, old, typeClass));
      }
      TYPE._setCaretPosition(e.target, e.target.value.length - caretPos);
      e.preventDefault();
    } else if (this._isCaretSelection(e.target) === false) {
      caretPos = e.target.value.length - TYPE._getCaretPosition(e.target);
      valueLength = e.target.value.replace(/_/g, "").length;
      if (caretPos >= valueLength) {
        TYPE._setCaretPosition(e.target, e.target.value.length - valueLength);
      }
      //LOG.debug("keydown:" + caretPos + "  " + valueLength + "  " + (e.target.value.length - valueLength));
    }
  },

  /**
   * Update the input in a delete character event when the direction is left to right
   */
  _eventDownLeft2Right: function(e, typeClass, type, view) {
    var key = e.which || e.keyCode,
      caretPos,
      begin,
      end;
    var attr = e.target.className.split(" ")[0];
    var maskCode = TYPE._getMaskCode(attr, view, typeClass);
    var inputMask = TYPE._getMaskByCode(maskCode);
    var value = $(e.target).val();
    var old = null;
    var newValue = null;

    //backspace, delete, and escape get special treatment
    if (key === 8 || key === 46) {
      caretPos = TYPE._getCaretPosition(e.target);
      begin = e.target.selectionStart;
      end = e.target.selectionEnd;

      if (key === 8) { // Backspace, delete the next
        old = value;
        if (begin === end) {
          begin--;
          caretPos -= 1; // Move the caret to the left
        }
        newValue = e.target.value.slice(0, begin) + e.target.value.slice(end);
        $(e.target).val(TYPE.MASK.applyInputMask(newValue, inputMask, old, typeClass));
      } else if (key === 46) { // Delete, delete de previous
        old = value;
        if (begin === end) {
          end++;
          var separators = TYPE.MASK._getSeparatorsFromMask(inputMask);
          if (_.contains(separators, e.target.value.slice(begin, end))) {
            caretPos += 1; // Move the caret to the right
          }
        }
        newValue = e.target.value.slice(0, begin) + e.target.value.slice(end);
        $(e.target).val(TYPE.MASK.applyInputMask(newValue, inputMask, old, typeClass));
      }

      TYPE._setCaretPosition(e.target, caretPos);
      e.preventDefault();
    }
  },

  /**
   * Returns the max Size of the input,format Masks of the MaskCode.
   */
  _getInputLongByCode: function(tdaCode, defaultSize) {
    var inputMask = TYPE._getMaskByCode(tdaCode);
    var formatMask = TYPE._getFormatByCode(tdaCode);

    var size = defaultSize || 20;

    if (inputMask && formatMask) {
      size = Math.max(inputMask.length, formatMask.length);
    } else if (inputMask) {
      size = inputMask.length;
    } else if (formatMask) {
      size = formatMask.length;
    }

    return size;
  },

  /**
   * Fill the number with zeros from Left
   */
  addZerosFromLeft: function(number, length) {
    var zero = "0";
    var str = STR.isBlank(number) ? zero : number.toString();

    while (str.length < length) {
      str = zero + str;
    }
    return str;
  },

  /**
   * Fill the number with zeros from Right
   */
  addZerosFromRight: function(number, length) {
    var zero = "0";
    var str = STR.isBlank(number) ? zero : number.toString();

    while (str.length < length) {
      str = str + zero;
    }
    return str;
  }
};

/**
 * The Base of other types that has common information and simple functions
 */
TYPE.BASETYPE = {

  /**
   * Auxiliar function that provides similar Backbone extend functionality
   */
  extend: function(childProps) {
    var child = {};
    _.extend(child, this, childProps);
    return child;
  },

  //REVIEW: 	i18n : phx.i18n.common,

  /**
   * Apply an Input Mask to the input value.
   */
  parseOnFocus: function(input, inputMask) {
    if (STR.isBlank(inputMask)) { inputMask = TYPE._getMaskByCode(this.DEFAULT_MASK_CODE); }
    return TYPE.MASK.applyInputMask(input, inputMask);
  },

  /**
   * Validate the input and parse it to match with the model raw type
   */
  parse: function(input) {
    var out = {};
    if (!STR.isBlank(input)) {
      input = TYPE.MASK._cleanInput(input);
      var validate = this.validate(input);
      if (!STR.isBlank(validate)) {
        out.errors = validate;
      }
    }
    out.val = input;
    return out;
  },

  /**
   * Apply the formatMask to the input value
   */
  format: function(input, formatMask) {
    if (STR.isBlank(formatMask)) { formatMask = TYPE._getFormatByCode(this.DEFAULT_MASK_CODE); }
    return TYPE.MASK.applyFormatMask(input, formatMask);
  },

  events: function(selector) {

    if (!STR.isBlank(this.TYPE_CLASS)) {
      var eventsObj = {};

      if (selector === null || selector === undefined) {
        selector = "";
      }

      var self = this;

      eventsObj["focus " + selector + " ." + this.TYPE_CLASS + ":not([readonly])"] = function(e) {
        TYPE._eventFocus(e, self.TYPE_CLASS, self, this);
      };

      eventsObj["blur " + selector + " ." + this.TYPE_CLASS + ":not([readonly])"] = function(e) {
        TYPE._eventBlur(e, self.TYPE_CLASS, self, this);
      };

      eventsObj["keypress " + selector + " ." + this.TYPE_CLASS + ":not([readonly])"] = function(e) {
        TYPE._eventKeypress(e, self.TYPE_CLASS, self, this);
      };

      eventsObj["keydown " + selector + " ." + this.TYPE_CLASS + ":not([readonly])"] = function(e) {
        TYPE._eventDownRight2Left(e, self.TYPE_CLASS, self, this);
      };

      return eventsObj;
    }
    LOG.warn("TDA: TYPE_CLASS is empty, so i don't create any event for this type");
    return null;
  }
};

/**
 * Type for conversion of HourMinutes(1200) to String and String to
 * HourMinutes(1200) Format : Convert the type HourMinutes (integer) to
 * String 1021 -> 10:21 Parse : Convert the type String to HourMinutes
 * (integer) 10:21 -> 1021
 */
TYPE.HOUR_MINUTE = TYPE.BASETYPE.extend({

  TYPE_CLASS: "typeHourMinute",

  DEFAULT_MASK_CODE: "INSTHM",

  /**
   * Validate the input and parse it to match with the model raw type
   */
  parse: function(input) {
    var obj = {};
    if (!STR.isBlank(input)) {
      input = TYPE.MASK._cleanInput(input);
      var error = this.validate(input);
      if (!STR.isBlank(error)) {
        obj.errors = error;
      }
    }
    obj.val = input;
    return obj;
  },

  /**
   * Apply the formatMask to the input value
   */
  format: function(input, formatMask) {
    if (STR.isBlank(formatMask)) { formatMask = TYPE._getFormatByCode(this.DEFAULT_MASK_CODE); }
    var out = "";
    input = parseInt(input, 10);

    out = TYPE.MASK.applyFormatMask(input, formatMask);
    return out;
  },

  /**
   * Validate the input with the type logic.
   */
  validate: function(input) {
    var error = null;

    if (!STR.isBlank(input)) {
      input = input.toString();
      var min = parseInt(input.substring(input.length - 2, input.lengt), 10);
      var hour = parseInt(input.substring(input.length - 4, input.length - 2), 10);
      if (min > 59 || hour > 23) {
        error = i18n.t('messages:GL_2');
      }
    }
    return error;
  },

  /**
   * Adds h2 to h1.
   * h1 + h2
   * pe: h1: 700 (7:00), h2: 30 (0:30) = 730 >> (7:30)
   * if the result where more 2359 (23:59) returns 2359
   */
  plus: function(h1, h2) {
    h1 = h1.toString();
    h2 = h2.toString();
    var h1Hour = h1.substring(0, h1.length - 2);
    var h2Hour = h2.substring(0, h2.length - 2);
    if (STR.isBlank(h1Hour)) { h1Hour = 0; }
    if (STR.isBlank(h2Hour)) { h2Hour = 0; }
    var h1Min = h1 % 100;
    var h2Min = h2 % 100;

    var hours = parseInt(h1Hour, 10) + parseInt(h2Hour, 10);
    var minutes = parseInt(h1Min, 10) + parseInt(h2Min, 10);
    if (minutes > 59) {
      hours++;
      minutes -= 60;
    }
    var result = hours * 100 + minutes;
    return result;
  },

  hoursToMinutes: function(hours) {
    var h = Math.floor(hours / 100);
    var m = hours % 100;
    return h * 60 + m;
  },

  minutesToHours: function(minutes) {
    var h = Math.floor(minutes / 60);
    var m = minutes % 60;
    return h * 100 + m;
  },

  /**
   * Return the difference between 2 HourMinute types
   * h1 - h2
   * pe: h1: 700 (7:00), h2: 30 (0:30) = 630 >> (6:30)
   * if the result were less than 0 returns 0
   */
  diff: function(h1, h2) {
    h1 = h1.toString();
    h2 = h2.toString();
    var h1Hour = h1.substring(0, h1.length - 2);
    var h2Hour = h2.substring(0, h2.length - 2);
    if (STR.isBlank(h1Hour)) { h1Hour = 0; }
    if (STR.isBlank(h2Hour)) { h2Hour = 0; }
    var h1Min = h1 % 100;
    var h2Min = h2 % 100;

    var h1Date = new Date(0, 0, 0, h1Hour, h1Min, 0);
    var h2Date = new Date(0, 0, 0, h2Hour, h2Min, 0);
    var diff = h1Date.getTime() - h2Date.getTime();
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);

    if (hours < 0) {
      return 0;
    } else {
      return hours * 100 + minutes;
    }
  }
});

/**
 * Type for conversion of Nightly HourMinutes(1200) to String and String to
 * Nightly HourMinutes(1200) Format : Convert the type Nightly HourMinutes (integer) to
 * String 1021 -> 10:21 Parse : Convert the type String to HourMinutes
 * (integer) 10:21 -> 1021
 */
TYPE.HOUR_MINUTE_NIGHTLY = TYPE.BASETYPE.extend({

  TYPE_CLASS: "typeHourMinuteNightly",

  DEFAULT_MASK_CODE: "INSTHM",

  NIGHT_TIME: "+",

  /**
   * Apply an Input Mask to the input value.
   */
  parseOnFocus: function(input, inputMask) { //Anomalie 118031
    if (_.isNumber(input)) { //If its a number is bacause it comes from the database or it was validated
      return this.format(input, inputMask);
    }
    return TYPE.HOUR_MINUTE.parseOnFocus(input, inputMask);

  },

  /**
   * Validate the input and parse it to match with the model raw type
   */
  parse: function(input) {
    var nightSymbol = TYPE.HOUR_MINUTE_NIGHTLY.NIGHT_TIME;
    var obj = {};
    if (!STR.isBlank(input)) {
      input = input.toString();
      input = TYPE.MASK._cleanInput(input, [nightSymbol]);
      var error = this.validate(input);
      if (STR.isBlank(error)) {
        //24:00 is a Special Case
        if (!STR.isBlank(input) && String(input) === "2400") {
          input = "2400";
        }
        //If it's Night Time
        if (input.toString().indexOf(nightSymbol) !== -1) {
          input = parseInt(input, 10) + 2400;
        } else {
          input = parseInt(input, 10); //Anomalie 118031
        }
      } else {
        obj.errors = error;
      }
    }
    obj.val = input;
    return obj;
  },

  /**
   * Take the value of a Model attribute or input (previously parsed) and format
   * it adds the separator time and the nightSymbol if were necesarie.
   */
  format: function(input, formatMask, nightSymbol) {
    var out = "";
    //Night Time
    var nightTime = false;

    if (STR.isBlank(formatMask)) {
      formatMask = TYPE._getFormatByCode(this.DEFAULT_MASK_CODE);
    }
    if (STR.isBlank(nightSymbol)) {
      nightSymbol = TYPE.HOUR_MINUTE_NIGHTLY.NIGHT_TIME;
    }
    input = parseInt(input, 10);
    if (input > 2400) {
      input = input - 2400;
      nightTime = true;
    }
    out = TYPE.MASK.applyFormatMask(input, formatMask);
    if (nightTime === true) {
      out = nightSymbol.concat(out);
    }
    return out;
    //			}else{
    //				return TYPE.HOUR_MINUTE.parseOnFocus(input);
    //			}
  },

  /**
   * Validate the input value,
   *  - hours in range [0-23] | [0-47] (nightTime)
   * 	- minutes in range [0-59]
   */
  validate: function(input) {
    var error = null;

    if (!STR.isBlank(input)) {
      var nightSymbol = TYPE.HOUR_MINUTE_NIGHTLY.NIGHT_TIME;
      input = input.toString();
      var nightTime = input.toString().indexOf(nightSymbol) !== -1;
      var min = parseInt(input.substring(input.length - 2, input.lengt), 10);
      var hour = parseInt(input.substring(input.length - 4, input.length - 2), 10); //Anomalie 118031
      if (nightTime) {
        if (min > 59 || hour > 23) {
          error = i18n.t('messages:GL_2');
        }
      } else {
        if (input.length > 4 || (min > 59 || hour > 47)) {
          error = i18n.t('messages:GL_2');
        }
      }
    }
    return error;
  }
});

/**
 * TYPE HMS only got the FORMAT the user can't enter or edit HMS type
 */
TYPE.HOUR_MINUTE_SECONDS = TYPE.HOUR_MINUTE.extend({
  TYPE_CLASS: "typeHourMinuteSecond",
  DEFAULT_MASK_CODE: "INSTHMS",

  /**
   * Validate the input with the type logic.
   */
  validate: function(input) {
    var error = null;

    if (!STR.isBlank(input)) {
      input = input.toString();
      var seconds = parseInt(input.substring(input.length, input.length - 2), 10);
      var min = parseInt(input.substring(input.length - 2, input.length - 4), 10);
      var hour = parseInt(input.substring(input.length - 4, input.length - 6), 10);
      if (seconds > 59 || min > 59 || hour > 23) {
        error = i18n.t('messages:GL_2');
      }
    }
    return error;
  }
});

TYPE.DURATION = {

  /**
   * Type that defines the duration in Hour Minutes
   */
  HOUR_MINUTE: TYPE.BASETYPE.extend({

    TYPE_CLASS: "typeDurationHM",

    DEFAULT_MASK_CODE: "DUREEHM",

    /**
     * Validate the input value,
     * 	- minutes in range [0-59].
     */
    validate: function(input) {
      var error = null;

      if (!STR.isBlank(input)) {
        input = input.toString();
        var min = parseInt(input.substring(input.length - 2), 10);
        if (min > 59) {
          error = i18n.t('messages:GL_2');
        }
      }
      return error;
    },

    validateShort: function(input) {
      var error = this.validate(input);

      if (error) {
        return error;
      } else {
        if (parseInt(input) > 32767) {
          error = i18n.t('messages:GL_2');
        }

        return error;
      }
    },

    /**
     * Get the last 2 digits of the argument, in the case of HOUR_MINUTE type gives the minutes.
     */
    minutes: function(n) {
      if (!STR.isBlank(n)) {
        var parse = this.parse(n.toString());

        if (STR.isBlank(parse.errors)) {
          return parse["val"] % 100;
        }
      }
      return -1;
    },

    /**
     * Remove the last 2 digits of the argument, in the case of HOUR_MINUTE type gives the hours.
     */
    hours: function(n) {
      if (!STR.isBlank(n)) {
        var parse = this.parse(n.toString());

        if (STR.isBlank(parse.errors)) {
          var val = parse["val"];

          if (val.length > 2) {
            return parseInt(val.substring(0, val.length - 2), 10);
          } else {
            return 0;
          }
        }
      }
      return -1;
    },

    time: function(hours, minutes) {
      return TYPE.addZerosFromLeft(hours, 2) + TYPE.addZerosFromLeft(minutes, 2);
    },

    addDurees: function(d1, d2) {
      var duration = 0;
      duration = this._toMinutes(d1) + this._toMinutes(d2);
      return this._toDuration(duration);
    },
    _toMinutes: function(duration) {
      var h = Math.floor(duration / 100);
      var m = duration % 100;
      return h * 60 + m;
    },

    _toDuration: function(minutes) {
      var h = Math.floor(minutes / 60);
      var m = minutes % 60;
      return h * 100 + m;
    },
    _calculateDuration: function(h1, h2) {
      var duration = 0;
      if (h1 !== h2) {
        duration = this._toMinutes(h2) - this._toMinutes(h1);
        if (h1 > h2) {
          duration = 1440 + duration;
        }
      }
      return this._toDuration(duration);
    }
  }),

  /**
   * Type that defines the duration in Hour Minutes of ha day
   */
  HOUR_MINUTE_24: TYPE.BASETYPE.extend({

    TYPE_CLASS: "typeDuration24HM",

    DEFAULT_MASK_CODE: "DUR24HM", //DUREEHM

    /**
     * Validate the input value,
     *  - hours in range [0-23]
     * 	- minutes in range [0-59].
     */
    validate: function(input) {
      var error = null;

      if (!STR.isBlank(input)) {
        input = input.toString();
        var min = parseInt(input.substring(input.length - 2), 10);
        var hour = parseInt(input.substring(input.length - 4, input.length - 2), 10);

        if (!(min === 0 && hour === 24) && (min > 59 || hour > 23)) {
          error = i18n.t('messages:GL_2');
        }
      }
      return error;
    },

    /**
     * Get the last 2 digits of the argument, in the case of HOUR_MINUTE type gives the minutes.
     */
    minutes: function(str) {
      if (!STR.isBlank(str)) {
        var parse = this.parse(str.toString());
        if (STR.isBlank(parse.errors)) {
          return parse["val"] % 100;
        }
      }
      return -1;
    }
  }),

  /**
   * Type that defines the duration in hour and hundredths.
   */
  HOUR_HUNDREDTH: TYPE.BASETYPE.extend({

    TYPE_CLASS: "typeDurationHC",

    DEFAULT_MASK_CODE: "DUREEHC",

    /**
     * Validate the input with the type logic, in this case don't do nothing.
     */
    validate: function(input) { //eslint-disable-line
      var error = null;

      return error;
    }
  }),

  /**
   * Type that defines the duration in hours with hundredths.
   */
  HOUR_HUNDREDTH_24: TYPE.BASETYPE.extend({

    TYPE_CLASS: "typeDuration24HC",

    DEFAULT_MASK_CODE: "DUR24HC",

    /**
     * Validate the input with the type logic, in this case don't do nothing.
     */
    validate: function(input) { //eslint-disable-line
      var error = null;

      return error;
    }
  }),

  /**
   * Type that defines the duration in minutes.
   */
  MINUTES: TYPE.BASETYPE.extend({

    TYPE_CLASS: "typeDurationMN",

    DEFAULT_MASK_CODE: "DUREEMN",

    /**
     * Validate the input with the type logic, in this case don't do nothing.
     */
    validate: function(input) { //eslint-disable-line
      var error = null;

      return error;
    }
  })
};

/**
 * Type that defines the duration in hour minute seconds.
 */
TYPE.DURATION.HOUR_MINUTE_SECONDS = TYPE.DURATION.HOUR_MINUTE.extend({

  TYPE_CLASS: "typeDurationHMS",
  DEFAULT_MASK_CODE: "DUREEHMS",

  /**
   * Validate the input with the type logic.
   */
  validate: function(input) {
    var error = null;

    if (!STR.isBlank(input)) {
      input = input.toString();
      var seconds = parseInt(input.substring(input.length, input.length - 2), 10);
      var min = parseInt(input.substring(input.length - 2, input.length - 4), 10);
      var hour = parseInt(input.substring(input.length - 4, input.length - 6), 10);
      if (seconds > 59 || min > 59 || hour > 23) {
        error = i18n.t('messages:GL_2');
      }
    }
    return error;
  }
});

/**
 * Type that defines the Short numbers.
 */
TYPE.SHORT = TYPE.BASETYPE.extend({

  TYPE_CLASS: "typeShort",

  MAX_VALUE: 32767,

  MIN_VALUE: -32768,

  DEFAULT_MASK_CODE: "ENTCOURT",

  /**
   * Validate that the input is a number and is in the short range.
   */
  validate: function(short, min, max) {
    if (!STR.isBlank(short)) {
      if (STR.isBlank(min)) { min = TYPE.SHORT.MIN_VALUE; }
      if (STR.isBlank(max)) { max = TYPE.SHORT.MAX_VALUE; }
      if (!(TYPE._isInt(short) && short >= min && short <= max)) {
        return i18n.t('messages:GL_1008');
      }
    }
    return null;
  }
});

/**
 * Type that defines the Long numbers.
 */
TYPE.LONG = TYPE.BASETYPE.extend({

  TYPE_CLASS: "typeLong",

  MAX_VALUE: 2147483647,

  MIN_VALUE: -2147483648,

  DEFAULT_MASK_CODE: "ENTLONG",

  /**
   * Validate that the input is a number and is in the long range.
   */
  validate: function(long, min, max) {
    if (!STR.isBlank(long)) {
      if (STR.isBlank(min)) { min = TYPE.LONG.MIN_VALUE; }
      if (STR.isBlank(max)) { max = TYPE.LONG.MAX_VALUE; }
      if (!(TYPE._isInt(long) && long >= min && long <= max)) {
        return i18n.t('messages:GL_1008');
      }
    }
    return null;
  }
});

/**
 * Type that represents a customed number format.
 * If no customize the data is like a Long.
 */
TYPE.CUSTOM = TYPE.BASETYPE.extend({

  TYPE_CLASS: "typeCustom",

  MAX_VALUE: 2147483647,

  MIN_VALUE: -2147483648,

  DEFAULT_MASK_CODE: "ENTLONG",

  DEFAULT_FORMAT: "----------&",

  /**
   * Validate that the input is a number and is in the long range.
   */
  validate: function(long, min, max) {
    if (!STR.isBlank(long)) {
      if (STR.isBlank(min)) { min = TYPE.CUSTOM.MIN_VALUE; }
      if (STR.isBlank(max)) { max = TYPE.CUSTOM.MAX_VALUE; }
      if (!(TYPE._isInt(long) && long >= min && long <= max)) {
        return i18n.t('messages:GL_1008');
      }
    }
    return null;
  },

  validateWithMask: function(value, mask) {
    // To validate an a custom mask I try to format the value 
    // With the mask, if the result have "?", is because the value
    // is not valid
    if (!STR.isBlank(value) && !STR.isBlank(mask)) {
      return !TYPE.CUSTOM.format(value, mask).includes('?');
    }
    LOG.debug("I can't validate an a custom mask if the value or the mask are empty, check this");
    return true; //True by default.
  },

  events: function(selector) {
    var eventsObj = {};
    if (selector === null || selector === undefined) {
      selector = "";
    }
    var self = this;

    eventsObj["focus " + selector + " .typeCustom:not([readonly])"] = function(e) {
      var format = "";
      var view = this;
      if (view.typeFormatByClass && !STR.isBlank(view.typeFormatByClass[e.target.className.split(" ")[0]])) {
        format = view.typeFormatByClass[e.target.className.split(" ")[0]];
      } else {
        format = TYPE.CUSTOM.DEFAULT_FORMAT;
      }
      TYPE._eventFocus(e, "typeCustom", self, view, format);
    };
    eventsObj["blur " + selector + " .typeCustom:not([readonly])"] = function(e) {
      var format = "";
      var view = this;
      if (view.typeFormatByClass && !STR.isBlank(view.typeFormatByClass[e.target.className.split(" ")[0]])) {
        format = view.typeFormatByClass[e.target.className.split(" ")[0]];
      } else {
        format = TYPE.CUSTOM.DEFAULT_FORMAT;
      }
      TYPE._eventBlur(e, "typeCustom", self, view, format);
    };
    eventsObj["keypress " + selector + " .typeCustom:not([readonly])"] = function(e) {
      var format = "";
      var view = this;
      if (view.typeFormatByClass && !STR.isBlank(view.typeFormatByClass[e.target.className.split(" ")[0]])) {
        format = view.typeFormatByClass[e.target.className.split(" ")[0]];
      } else {
        format = TYPE.CUSTOM.DEFAULT_FORMAT;
      }
      TYPE._eventKeypress(e, "typeCustom", self, view, false, format);
    };
    eventsObj["keydown .typeCustom:not([readonly])"] = function(e) {
      var format = "";
      var view = this;
      if (view.typeFormatByClass && !STR.isBlank(view.typeFormatByClass[e.target.className.split(" ")[0]])) {
        format = view.typeFormatByClass[e.target.className.split(" ")[0]];
      } else {
        format = TYPE.CUSTOM.DEFAULT_FORMAT;
      }
      TYPE._eventDownRight2Left(e, "typeCustom", self, view, format);
    };

    return eventsObj;
  }
});

/**
 * Type that represents the decimal numbers.
 */
TYPE.DECIMAL = TYPE.BASETYPE.extend({

  TYPE_CLASS: "typeDecimal",

  DEFAULT_MASK_CODE: "DECIMAL_2",

  SEPARATOR: ".",

  /**
   * Validate the input and parse it to match with the model raw type
   */
  parse: function(input, ndecimals, separator) {
    var out = {};
    if (STR.isBlank(ndecimals)) { ndecimals = TYPE._getDecimalsByCode(this.DEFAULT_MASK_CODE); }
    if (STR.isBlank(separator)) { separator = TYPE.DECIMAL.SEPARATOR; }
    if (!STR.isBlank(input)) {
      input = TYPE.MASK._cleanInput(input);
      var validate = this.validate(input);
      if (!STR.isBlank(validate)) {
        out.errors = validate;
      }
      input = input / Math.pow(10, ndecimals);
    }
    out.val = input;
    return out;
  },

  /**
   * Apply the formatMask to the input value
   */
  format: function(input, formatMask, ndecimals) {
    var out = "";
    var lInput = null;

    if (STR.isBlank(formatMask)) {
      formatMask = TYPE._getFormatByCode(this.DEFAULT_MASK_CODE);
    }
    if (STR.isBlank(ndecimals)) {
      ndecimals = TYPE._getDecimalsByCode(this.DEFAULT_MASK_CODE);
    }
    lInput = input * Math.pow(10, ndecimals);
    lInput = Math.round(lInput);
    out = TYPE.MASK.applyFormatMask(lInput, formatMask);
    return out;
  },

  /**
   * Validate the input, checks if is a float or not.
   */
  validate: function(input, separator) {
    var error = null;

    if (STR.isBlank(separator)) {
      separator = TYPE.DECIMAL.SEPARATOR;
    }
    if (!STR.isBlank(input)) {
      if (_.isNaN(parseFloat(input))) {
        error = i18n.t('messages:GL_2');
      }
    }
    return error;
  },

  events: function(selector) {
    if (!STR.isBlank(this.TYPE_CLASS)) {
      var eventsObj = {};

      if (selector === null || selector === undefined) {
        selector = "";
      }

      var self = this;
      eventsObj["focus " + selector + " ." + this.TYPE_CLASS + ":not([readonly])"] = function(e) {
        var typeClass = self.TYPE_CLASS;
        var type = TYPE.DECIMAL;
        var view = this;

        //Get the attribute name and his model value
        var attr = e.target.className.split(" ")[0];
        var attrModelValue = STR.getElValue(view._getModel(attr), attr);
        //Set temporal Original Value on the view to detect Changes
        view.TEMP_origVal = attrModelValue || "";

        //Get the input Mask
        var maskCode = TYPE._getMaskCode(attr, view, typeClass);
        var inputMask = TYPE._getMaskByCode(maskCode);

        //Change the value of the input
        $(e.target).val(type.parseOnFocus(attrModelValue, inputMask));
        //Select the content of the input
        window.setTimeout(function() {
          //e.target.select();
          if (e.target && e.target.value && e.target.value.length && $("body").find($(e.target)).length > 0) {
            e.target.setSelectionRange(0, e.target.value.length);
          }
        }, 50);
      };
      eventsObj["blur " + selector + " ." + this.TYPE_CLASS + ":not([readonly])"] = function(e) {
        var typeClass = self.TYPE_CLASS;
        var type = TYPE.DECIMAL;
        var view = this;
        var attr = e.target.className.split(" ")[0];
        var maskCode = TYPE._getMaskCode(attr, view, typeClass);
        var formatMask = TYPE._getFormatByCode(maskCode);
        var decimals = TYPE._getDecimalsByCode(maskCode);
        var parse = type.parse(e.target.value, decimals);

        //Check changes on the original Value
        if (String(view.TEMP_origVal) !== parse["val"]) {
          $(e.target).change();
        }
        if (!parse["errors"]) {
          $(e.target).val(type.format(parse["val"], formatMask, decimals));
        }

        delete view.TEMP_origVal;
      };
      eventsObj["keypress " + selector + " ." + this.TYPE_CLASS + ":not([readonly])"] = function(e) {
        TYPE._eventKeypress(e, self.TYPE_CLASS, self, this);
      };
      eventsObj["keydown " + selector + " ." + this.TYPE_CLASS + ":not([readonly])"] = function(e) {
        TYPE._eventDownRight2Left(e, self.TYPE_CLASS, self, this);
      };
      return eventsObj;
    }
    LOG.warn("TDA: TYPE_CLASS is empty, so i don't create any event for this type");
    return null;

  }
});

/**
 * Type that represents the decimals
 */
TYPE.DECIMAL_1 = TYPE.DECIMAL.extend({

  TYPE_CLASS: "typeDecimal_1",

  DEFAULT_MASK_CODE: "DECIMAL_1"
});

/**
 * Type that represents the decimals
 */
TYPE.DECIMAL_2 = TYPE.DECIMAL.extend({

  TYPE_CLASS: "typeDecimal_2",

  DEFAULT_MASK_CODE: "DECIMAL_2"
});

/**
 * Type that represents the Jours quantity.
 */
TYPE.JOUR_0 = TYPE.LONG.extend({

  TYPE_CLASS: "typeJour_0",

  DEFAULT_MASK_CODE: "NBJOUR_0",

  /**
   * Validate the input integrity.
   */
  validate: function(input) {
    var error = null;

    if (!STR.isBlank(input)) {
      if (_.isNaN(parseFloat(input))) {
        error = i18n.t('messages:GL_2');
      }
      var min = TYPE.LONG.MIN_VALUE;
      var max = TYPE.LONG.MAX_VALUE;
      if (!(TYPE._isInt(input) && input >= min && input <= max)) {
        return i18n.t('messages:GL_1008');
      }
    }
    return error;
  }
});

/**
 * Type that represents the Jours quantity.
 */
TYPE.JOUR_1 = TYPE.JOUR_0.extend({

  TYPE_CLASS: "typeJour_1",

  DEFAULT_MASK_CODE: "NBJOUR_1"
});

/**
 * Type that represents the Jours quantity.
 */
TYPE.JOUR_2 = TYPE.JOUR_0.extend({

  TYPE_CLASS: "typeJour_2",

  DEFAULT_MASK_CODE: "NBJOUR_2"
});

/**
 * Type that represents the Percentages on the application.
 */
TYPE.PERCENTAGE = TYPE.LONG.extend({

  TYPE_CLASS: "typePercentage",

  DEFAULT_MASK_CODE: "POURCENT_2",

  /**
   * Validate the input integrity.
   */
  validate: function(input, min, max) {
    var error = null;

    if (!STR.isBlank(input)) {
      if (parseFloat(input) === "NaN") {
        error = i18n.t('messages:GL_2');
      } else {
        if (STR.isBlank(min)) { min = TYPE.LONG.MIN_VALUE; }
        if (STR.isBlank(max)) { max = TYPE.LONG.MAX_VALUE; }
        if (!STR.isBlank(min) && !STR.isBlank(max)) {
          if (!(input >= min && input <= max)) {
            error = i18n.t('messages:GL_1008');
          }
        } else if (STR.isBlank(min) && !STR.isBlank(max)) {
          if (!(input <= max)) {
            error = i18n.t('messages:GL_1008');
          }
        } else if (!STR.isBlank(min) && STR.isBlank(max)) {
          if (!(input >= min)) {
            error = i18n.t('messages:GL_1008');
          }
        }
      }
    }
    return error;
  }
});

/**
 * Type that represents percentages without decimal values
 */
TYPE.PERCENTAGE_0 = TYPE.PERCENTAGE.extend({

  TYPE_CLASS: "typePercentage_0",

  DEFAULT_MASK_CODE: "POURCENT_0"
});

/**
 * Type that represents percentages with only one decimal
 */
TYPE.PERCENTAGE_1 = TYPE.PERCENTAGE.extend({

  TYPE_CLASS: "typePercentage_1",

  DEFAULT_MASK_CODE: "POURCENT_1"
});

/**
 * Type that represents percentages with 2 decimals
 */
TYPE.PERCENTAGE_2 = TYPE.PERCENTAGE.extend({

  TYPE_CLASS: "typePercentage_2",

  DEFAULT_MASK_CODE: "POURCENT_2"
});

/**
 * Type for conversion of Boolean to String(in the languaje correct) and
 * String(in the languaje correct) to Boolean Format : Convert the type
 * Boolean to String(in the languaje correct) true -> "Oui" Parse :
 * Convert the type String(in the languaje correct) to Boolean "Oui" ->
 * true
 */
TYPE.BOOLEAN = TYPE.BASETYPE.extend({

  parseOnFocus: undefined,

  /**
   * Transforms a String into Boolean.
   */
  parse: function(input) {
    var obj;

    if (input === i18n.t('common:booltrue')) {
      obj = true;
    } else {
      obj = false;
    }
    return obj;
  },

  /**
   * Auxiliar format function the input into boolean text.
   */
  _format: function(obj) {
    var str = "";
    if (obj) {
      str = i18n.t('common:booltrue');
    } else {
      str = i18n.t('common:boolfalse');
    }
    return str;
  },

  /**
   * It formats a boolean data into text or icon, using uiquery icons if we pass iconStyle as true.
   */
  format: function(obj, iconStyle, fieldSetStyle, title) {
    if (STR.isBlank(iconStyle) || iconStyle === false) {
      return this._format(obj);
    }
    var $span = $("<span>");
    if (STR.isBlank(fieldSetStyle) || fieldSetStyle === false) {
      $span.attr("style", "margin-left: calc(50% - 8px);");
    }
    if (!STR.isBlank(title)) {
      $span.attr("title", title);
    }

    if (obj) {
      $span.append(UTILS.getSVGIcon("valider", "c-panneauMenu__tickIcon", 16))
      $span.find(".c-panneauMenu__tickIcon svg").css("fill", "#1978da");
    } else {
      $span.append(UTILS.getSVGIcon("croix", "cw-volet__close ui-dialog-titlebar-close", 16))
      $span.find(".cw-volet__close svg").css("fill", "#1978da");
    }
    return $span;
  }

});

/**
 * Type that defines the methods for email data type.
 */
TYPE.EMAIL = TYPE.BASETYPE.extend({

  parseOnFocus: undefined,
  parse: undefined,
  format: undefined,

  /**
   * Auxiliar function that checks the regexp for the input.
   */
  _validatePattern: function(str) {
    var RegExPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

    return RegExPattern.test(str);
  },

  /**
   * Validate if the input has a correct email format.
   */
  validate: function(input) {
    var error = null;

    if (!STR.isBlank(input) && (!this._validatePattern(input.trim()) || (input.split("@")[0].length > 64))) { //il faut vérifier aussi la règle : local-part is longer than 64 characters
      error = i18n.t('common:invalidEmail');
    }
    return error;
  }
});

/**
 * Type that defines the methods for SS Number logic data type.
 */
TYPE.RESTDIV97 = TYPE.BASETYPE.extend({

  parseOnFocus: undefined,
  parse: undefined,
  format: undefined,

  /**
   * Auxiliar function that checks the french SS number logic.
   */
  _validatePattern: function(str) {
    var valid = true;
    var typeA, typeB;
    var RegExPattern = /^[1-2][0-9]{2}(0[1-9]|1[0-2])(2[AB]|[0-9]{2})[0-9]{8}$/;

    valid = RegExPattern.test(str);
    if (valid) {
      //Check 'A' or 'B' on the 7th position
      if (str[6] === "A") {
        typeA = true;
        str[6] = 0;
      } else if (str[6] === "B") {
        typeB = true;
        str[6] = 0;
      }
      //Check reste logic
      var numss = parseInt(str.substr(0, 13), 10);
      var ctrlNum = parseInt(str.substring(13), 10);
      if (typeA) {
        numss -= 1000000;
      } else if (typeB) {
        numss -= 2000000;
      }
      var reste = numss % 97;
      if (ctrlNum !== (97 - reste)) {
        valid = false;
      }
    }

    return valid;
  },

  /**
   * Validate if the input has a correct format.
   */
  validate: function(input) {
    var error = null;
    if (!STR.isBlank(input)) {
      //...Le contrôle est à faire uniquement si le paramètre divers pers_numer vaut 2.
      if (GLOBAL_DATA.paramDivers.get("pers_numer").get("valeur") === "2") {
        //LOG.debug("parametre divers \"pers_numer\" (valeur) => " + GLOBAL_DATA.paramDivers.get("pers_numer").get("valeur"));
        if (!this._validatePattern(input)) {
          error = i18n.t('messages:GT_1323');
        }
      }
    }
    return error;
  }
});

/**
 * Type for conversion of Date to String(in the format correct) and
 * String(in the format correct) to Date Format : Convert the type Date
 * to String(in the format correct) Date -> "22/12/01" Parse : Convert
 * the type String(in the format correct) to Date "22/12/01" -> Date
 */
TYPE.DATE = TYPE.BASETYPE.extend({

  TYPE_CLASS: "typeDate",

  DEFAULT_MASK_CODE: "DATE",

  DEFAULT_MASK_BBDD: "YYYYMMDD",

  DEFAULT_MASK_FRENCH: "DD/MM/YYYY",

  SUNDAY_NUMBER: 0,

  MONDAY_NUMBER: 1,

  FRIDAY_NUMBER: 5,

  SATURDAY_NUMBER: 6,

  INFINITY: "21001231",

  INITIAL: "18991231",

  ML_SECONDS_DAY: 86400000,

  /**
   * Apply an Input Mask to the input value.
   */
  parseOnFocus: function(input, inputMask) {
    if (STR.isBlank(inputMask)) {
      inputMask = TYPE._getMaskByCode(this.DEFAULT_MASK_CODE);
    }
    if (!STR.isBlank(input)) {
      input = input.replace(/\//g, "");
    }
    //Change the input format from BBDD to Input format only if the date was valid.
    var dateInArray = null;
    if (!STR.isBlank(TYPE.DATE.validate(input))) {
      dateInArray = TYPE.DATE._obtainDateArray(input, inputMask.replace(/\//g, ""), true);
    } else {
      dateInArray = TYPE.DATE._obtainDateArray(input, TYPE.DATE.DEFAULT_MASK_BBDD);
    }
    return (TYPE.DATE._getMaskedStringFromArray(dateInArray[0], dateInArray[1], dateInArray[2], inputMask));
  },

  /**
   * Converts a string date into a formatted string date ready to send to the DB. (YYYYMMDD format)
   */
  parse: function(str, maskCode) {
    var out = {};

    //Date format long case
    if (maskCode === "DATE_L" || (str && str.length > 15)) {
      str = this.checkDayLength(str);
      var monthName = str.split(" ")[2];
      var monthNumber = parseInt((this.getInverseArrayMounthNameNumber()[monthName])) + 1;
      str = monthNumber < 10 ? str.replace(monthName, "0" + monthNumber) : str = str.replace(monthName, monthNumber);
    }

    //Clean the input
    str = TYPE.MASK._cleanDateInput(str);

    //Get the mask if is undefined and clean it
    if (STR.isBlank(maskCode)) {
      maskCode = TYPE.DATE.DEFAULT_MASK_CODE;
    }
    var mask = TYPE._getMaskByCode(maskCode);

    if (!STR.isBlank(str)) {
      mask = mask.replace(/\//g, "");

      var validate = this.validate(str, mask);
      if (!STR.isBlank(validate)) {
        out.errors = validate;
      } else {
        var dateArray = this._obtainDateArray(str, mask);
        var day = this._pad(dateArray[0]);
        var month = this._pad(dateArray[1]);
        str = this._pad(dateArray[2], 4) + month + day; //Default DB format is YYYYMMDD
      }
    }
    out.val = str;
    return out;
  },

  checkDayLength: function(dateSTR) {
    var dateParts = dateSTR.split(" ");
    if (dateParts.length > 1) {
      if (dateParts[1].length === 1 && !isNaN(parseInt(dateParts[1].length))) {
        dateParts[1] = "0" + dateParts[1];
      }
      return dateParts.join(" ");
    }
    return dateSTR;
  },

  getInverseArrayMounthNameNumber: function() {
    return _.extend(_.invert(i18n.t('common:monthNames', { returnObjects: true })),
      _.invert(i18n.t('common:monthNamesMin', { returnObjects: true })),
      _.invert(i18n.t('common:monthNamesCap', { returnObjects: true })),
      _.invert(i18n.t('common:monthNamesShort', { returnObjects: true })),
      _.invert(i18n.t('common:monthNamesShortMin', { returnObjects: true })),
      _.invert(i18n.t('common:monthNamesShortCap', { returnObjects: true })));
  },

  /**
   * Function to format a string date following the mask and using the parameter if it exists.
   */
  format: function(strDate, mask, separator, hourSeparator, milliseconds) {
    var date = null;

    //Get default mask is none defined
    if (STR.isBlank(mask)) {
      mask = TYPE._getFormatByCode(this.DEFAULT_MASK_CODE);
    }

    //Transform the data into DATE type
    if (!STR.isBlank(milliseconds)) {
      date = new Date(milliseconds);
    } else {
      //Convert the Model value (This can't change at least )
      date = this.strToDate(strDate, TYPE.DATE.DEFAULT_MASK_BBDD, ""); //Default BD date format >> "YYYYMMDD"
    }

    var formattedMask = "";
    if (!STR.isBlank(date)) {
      if (!STR.isBlank(separator)) {
        formattedMask = this._formatMask(mask, separator, hourSeparator);
      } else {
        formattedMask = mask;
      }

      var d = date.getDate(),
        D = date.getDay(),
        m = date.getMonth(),
        y = date.getFullYear(),
        H = date.getHours(),
        M = date.getMinutes(),
        s = date.getSeconds(),
        L = date.getMilliseconds(),
        flags = {
          Day: i18n.t('common:dayNames.' + D), //day long name
          day: i18n.t('common:dayNamesMin.' + D), //day long name minus.
          DAY: i18n.t('common:dayNamesCap.' + D), //day long name capital
          Dy: i18n.t('common:dayNamesShort.' + D), //day short name
          dy: i18n.t('common:dayNamesShortMin.' + D), //day short name minus.
          DY: i18n.t('common:dayNamesShortCap.' + D), //day short name capital
          DD: this._pad(d), //day with 0 at left
          D: d, //day without 0 at left
          Month: i18n.t('common:monthNames.' + m), //month long name
          month: i18n.t('common:monthNamesMin.' + m), //month long name minus.
          MONTH: i18n.t('common:monthNamesCap.' + m), //month long name capital
          Mo: i18n.t('common:monthNamesShort.' + m), //month short name
          mo: i18n.t('common:monthNamesShortMin.' + m), //month short name minus.
          MO: i18n.t('common:monthNamesShortCap.' + m), //month short name capital
          MM: this._pad(m + 1), //month with 0 at left
          M: m + 1, //month without 0 at left
          YYYY: y, //year with 4 digits
          YY: String(y).slice(2), //year with 2 digits
          hh: this._pad(H % 12 || 12), //hour with 0 at left in 12h format
          h: H % 12 || 12, //hour without 0 at left in 12h format
          HH: this._pad(H), //hour with 0 at left in 24h format
          H: H, //hour without 0 at left in 24h format
          mm: this._pad(M), //minutes with 0 at left
          m: M, //minutes without 0 at left
          ss: this._pad(s), //seconds with 0 at left
          s: s, //seconds without 0 at left
          l: this._pad(L, 3), //milliseconds with 2 digits format
          L: this._pad(L > 99 ? Math.round(L / 10) : L), //milliseconds rounded with 2 digits format
          tt: H < 12 ? "am" : "pm", //long am-pm for 12h format
          t: H < 12 ? "a" : "p", //short am-pm for 12h format
          TT: H < 12 ? "AM" : "PM", //long AM-PM for 12h format
          T: H < 12 ? "A" : "P" //short AM-PM for 12h format
        };

      //replace flags in formatted mask
      formattedMask = formattedMask.replace(/Day/g, "@@@@@@");
      formattedMask = formattedMask.replace(/day/g, "@@@@@");
      formattedMask = formattedMask.replace(/DAY/g, "@@@@");
      formattedMask = formattedMask.replace(/Dy/g, "@@@");
      formattedMask = formattedMask.replace(/dy/g, "@@");
      formattedMask = formattedMask.replace(/DY/g, "@");
      formattedMask = formattedMask.replace(/DD/g, flags.DD);
      formattedMask = formattedMask.replace(/D/g, flags.D);
      formattedMask = formattedMask.replace(/Month/g, "~~~~~~");
      formattedMask = formattedMask.replace(/month/g, "~~~~~");
      formattedMask = formattedMask.replace(/MONTH/g, "~~~~");
      formattedMask = formattedMask.replace(/Mo/g, "~~~");
      formattedMask = formattedMask.replace(/mo/g, "~~");
      formattedMask = formattedMask.replace(/MO/g, "~");
      formattedMask = formattedMask.replace(/MM/g, flags.MM);
      formattedMask = formattedMask.replace(/M/g, flags.M);
      formattedMask = formattedMask.replace(/YYYY/g, flags.YYYY);
      formattedMask = formattedMask.replace(/YY/g, flags.YY);
      formattedMask = formattedMask.replace(/hh/g, flags.hh);
      formattedMask = formattedMask.replace(/h/g, flags.h);
      formattedMask = formattedMask.replace(/HH/g, flags.HH);
      formattedMask = formattedMask.replace(/H/g, flags.H);
      formattedMask = formattedMask.replace(/mm/g, flags.mm);
      formattedMask = formattedMask.replace(/m/g, flags.m);
      formattedMask = formattedMask.replace(/ss/g, flags.ss);
      formattedMask = formattedMask.replace(/s/g, flags.s);
      formattedMask = formattedMask.replace(/l/g, flags.l);
      formattedMask = formattedMask.replace(/L/g, flags.L);
      formattedMask = formattedMask.replace(/tt/g, flags.tt);
      formattedMask = formattedMask.replace(/t/g, flags.t);
      formattedMask = formattedMask.replace(/TT/g, flags.TT);
      formattedMask = formattedMask.replace(/T/g, flags.T);

      formattedMask = formattedMask.replace(/@@@@@@/g, flags.Day);
      formattedMask = formattedMask.replace(/@@@@@/g, flags.day);
      formattedMask = formattedMask.replace(/@@@@/g, flags.DAY);
      formattedMask = formattedMask.replace(/@@@/g, flags.Dy);
      formattedMask = formattedMask.replace(/@@/g, flags.dy);
      formattedMask = formattedMask.replace(/@/g, flags.DY);
      formattedMask = formattedMask.replace(/~~~~~~/g, flags.Month);
      formattedMask = formattedMask.replace(/~~~~~/g, flags.month);
      formattedMask = formattedMask.replace(/~~~~/g, flags.MONTH);
      formattedMask = formattedMask.replace(/~~~/g, flags.Mo);
      formattedMask = formattedMask.replace(/~~/g, flags.mo);
      formattedMask = formattedMask.replace(/~/g, flags.MO);
    }

    return formattedMask;
  },

  /**
   * Function to manage infinity values for periods.
   */
  manageInfinity: function(strDate, mask, separator, hourSeparator, milliseconds) {
    if (strDate === TYPE.DATE.INFINITY && GLOBAL_DATA.paramDivers.get("CACH_INFIN").get("valeur") === "1") {
      return "";
    } else {
      return TYPE.DATE.format(strDate, mask, separator, hourSeparator, milliseconds);
    }
  },

  /**
   * Function to validate a string date that uses another two auxiliar functions to do it.
   */
  validate: function(strDate, mask, separator) {
    //If mask is undef use the default BBDD >> "YYYYMMDD"
    if (STR.isBlank(mask)) {
      mask = TYPE.DATE.DEFAULT_MASK_BBDD;
    }
    if (STR.isBlank(separator)) {
      separator = "";
    }
    if (!STR.isBlank(strDate)) {
      if (!this._validateDateFormat(strDate, mask)) {
        return i18n.t('messages:GL_4');
      } else if (!this._validateDate(strDate, mask, separator)) {
        return i18n.t('messages:GL_2');
      }
    }
    return null;
  },

  /**
   * Function to validate a period.
   */
  validatePeriod: function(startDate, endDate, options) {
    var errors = {};
    if (!STR.isBlank(startDate)) {
      var errorMsg = TYPE.DATE.validate(startDate.value);
      if (!STR.isBlank(errorMsg)) {
        errors.datedeb = errorMsg;
      } else if (STR.isBlank(startDate.value) && !STR.isBlank(startDate.label) && !STR.isBlank(options) && (STR.isBlank(options.editedAttr) || options.editedAttr === startDate.name) &&
        ((STR.isBlank(startDate.isInfinity) || !startDate.isInfinity))) { // || DIVERS.get("CACH_INFIN") == "0")) {
        errors.datedeb = i18n.t('common:required', { "0": startDate.label });
      }
    }
    if (!STR.isBlank(endDate)) {
      var errorMsg2 = TYPE.DATE.validate(endDate.value);
      if (!STR.isBlank(errorMsg2)) {
        errors.datefin = errorMsg2;
      } else if (STR.isBlank(errors.datedeb) && !STR.isBlank(startDate) && !STR.isBlank(startDate.value) && !STR.isBlank(endDate.value) && endDate.value < startDate.value) {
        errors.datefin = i18n.t('messages:GL_1057');
      } else if (STR.isBlank(endDate.value) && !STR.isBlank(endDate.label) && !STR.isBlank(options) && (STR.isBlank(options.editedAttr) || options.editedAttr === endDate.name) &&
        ((STR.isBlank(endDate.isInfinity) || !endDate.isInfinity))) { // || DIVERS.get("CACH_INFIN") == "0")) {
        errors.datefin = i18n.t('common:required', { "0": endDate.label });
      }
    }
    if (!_.isEmpty(errors)) {
      return errors;
    } else {
      return "";
    }
  },

  /**
   * Converts a date in a string into a Date object.
   */
  strToDate: function(strDate, mask) {
    var date = null;

    if (!STR.isBlank(strDate)) {
      if (STR.isBlank(mask)) {
        mask = TYPE.DATE.DEFAULT_MASK_BBDD;
      }
      var dateArray = this._obtainDateArray(strDate, mask);
      date = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
    }

    return date;
  },

  /**
   * Converts a Date object into a string using a mask format and an optional separator.
   */
  dateToStr: function(date, mask, separator, hsep) {
    var result = "";

    if (STR.isBlank(separator)) {
      separator = "";
    }

    var hasHour = true;
    if (STR.isBlank(hsep)) {
      hsep = "";
      hasHour = false;
    }

    if (!STR.isBlank(date)) {
      if (hasHour) {
        result = this.format("", mask, separator, hsep, date.getTime());
      } else {
        //Mount the strDate with the DataBase format (Model)
        var strDate = String(date.getFullYear()) + String(this._pad(date.getMonth() + 1)) + String(this._pad(date.getDate()));
        result = this.format(strDate, mask, separator, hsep);
      }
    }
    return result;
  },

  /**
   * Get the current day in DDBB format "YYYYMMDD"
   */
  getCurrentDate: function() {
    return TYPE.DATE.dateToStr(SYNC.getServerDate(), this.DEFAULT_MASK_BBDD);
  },
  //pb 255730
  /**
   * Function to calculating the previus day.
   */
  _yesterdayOf: function(date) {
    var day = parseInt(date.substring(6, 8));
    var month = parseInt(date.substring(4, 6), 10) - 1; //month index
    var year = parseInt(date.substring(0, 4), 10);
    var today = new Date(year, month, day); //new Date(year, monthIndex, day)	
    var yesterday = new Date(today - 1); //yesterday
    var sYesterday = yesterday.toISOString(); //aaaa-mm-ddTXX:XX:XX.XXXX	

    date = sYesterday.substring(0, 4) + sYesterday.substring(5, 7) + sYesterday.substring(8, 10);
    return date;
  },

  /**
   * Get the monday of the given week.
   */
  getWeeklyMonday: function(date) {
    var newDate = new Date(date);
    if (date.getDay() === this.SUNDAY_NUMBER) {
      UTILS.addDays(newDate, -6);
    } else if (date.getDay() !== this.MONDAY_NUMBER) {
      var daysToRest = 1 - date.getDay();
      UTILS.addDays(newDate, daysToRest);
    }
    return newDate;
  },

  /**
   * Get the sunday of the given week.
   */
  getWeeklySunday: function(date) {
    var newDate = new Date(date);
    if (date.getDay() === this.MONDAY_NUMBER) {
      UTILS.addDays(newDate, 6);
    } else if (date.getDay() !== this.SUNDAY_NUMBER) {
      var daysToSum = 7 - date.getDay();
      UTILS.addDays(newDate, daysToSum);
    }
    return newDate;
  },

  /**
   * Get the friday of the given week.
   */
  getWeeklyFriday: function(date) {
    var newDate = new Date(date);
    if (date.getDay() === this.MONDAY_NUMBER) {
      UTILS.addDays(newDate, 4);
    } else if (date.getDay() === this.SUNDAY_NUMBER) {
      UTILS.addDays(new Date(date), -2);
    } else {
      var daysToSum = 5 - date.getDay();
      UTILS.addDays(newDate, daysToSum);
    }
    return newDate;
  },

  getWeeklySaturday: function(date) {
    var newDate = new Date(date);
    if (date.getDay() === this.MONDAY_NUMBER) {
      UTILS.addDays(newDate, 5);
    } else if (date.getDay() === this.SUNDAY_NUMBER) {
      UTILS.addDays(newDate, -1);
    } else if (date.getDay() !== this.SATURDAY_NUMBER) {
      var daysToSum = 6 - date.getDay();
      UTILS.addDays(newDate, daysToSum);
    }
    return newDate;
  },

  /**
   * Get the difference in Days between 2 dates in BBDD format or date format
   */
  getDiffInDays: function(date1, date2, truncate) {
    if (!(date1 instanceof Date)) {
      date1 = TYPE.DATE.strToDate(date1);
    }
    if (!(date2 instanceof Date)) {
      date2 = TYPE.DATE.strToDate(date2);
    }

    if (!STR.isBlank(truncate) && truncate === true) {
      return Math.round((this._truncate(date2).getTime() - this._truncate(date1).getTime()) / (this.ML_SECONDS_DAY));
    } else {
      return Math.round((date2.getTime() - date1.getTime()) / (this.ML_SECONDS_DAY));
    }
  },

  getFullPeriode: function(date1, date2) {
    var periode = [];

    if (!(date1 instanceof Date)) {
      date1 = TYPE.DATE.strToDate(date1);
    }

    if (!(date2 instanceof Date)) {
      date2 = TYPE.DATE.strToDate(date2);
    }

    var diffDays = TYPE.DATE.getDiffInDays(date1, date2, true);
    if (diffDays >= 0) {
      for (var i = 0; i <= diffDays; ++i) {
        periode.push(TYPE.DATE.dateToStr(UTILS.addDays(date1, i === 0 ? 0 : 1), TYPE.DATE.DEFAULT_MASK_BBDD));
      }
    }

    return periode;
  },

  /**
   * Get the number of days in a period given by 2 dates in BBDD or date format
   */
  getNumberOfDays: function(date1, date2, truncate) {
    return Math.abs(this.getDiffInDays(date1, date2, truncate)) + 1;
  },

  /**
   * Remove hours minutes and seconds of the date passed as parameter
   */
  _truncate: function(date) {
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);
    date.setMilliseconds(0);
    return date;
  },

  /**
   * Aux function that returns an array with the day (pos 0), month (pos 1) and year (pos 2) from
   * a string date using a mask and a separator.
   */
  _obtainDateArray: function(str, mask, dontFillItWithDate) {
    if (dontFillItWithDate === undefined) {
      dontFillItWithDate = false;
    }
    var dateArray = new Array();
    dateArray[0] = null; //Days
    dateArray[1] = null; //Months
    dateArray[2] = null; //Years

    if (!STR.isBlank(mask) && mask.length === 8 && !STR.isBlank(str)) {

      //Fill up to 8 characters with X's
      var filledStr = (str + "XXXXXXXX").substr(0, 8);

      var day = mask.search("D"); //2 digits
      var month = mask.search("M"); //2 digits
      var year = mask.search("Y"); //4 digits

      dateArray[0] = filledStr.substr(day, 2);
      dateArray[1] = filledStr.substr(month, 2);
      dateArray[2] = filledStr.substr(year, 4);
      if (!dontFillItWithDate) {
        var currentDate = SYNC.getServerDate();
        if (!TYPE._isInt(dateArray[0]) /*|| parseInt(dateArray[0],10) === 0*/ ) {
          dateArray[0] = currentDate.getDate();
        }
        if (!TYPE._isInt(dateArray[1]) /*|| parseInt(dateArray[1],10) === 0*/ ) {
          dateArray[1] = currentDate.getMonth() + 1;
        }
        if (!TYPE._isInt(dateArray[2]) /*|| parseInt(dateArray[2],10) === 0*/ ) {
          dateArray[2] = currentDate.getFullYear();
        }
      } else {
        dateArray[0] = dateArray[0].replace(/X/g, "");
        dateArray[1] = dateArray[1].replace(/X/g, "");
        dateArray[2] = dateArray[2].replace(/X/g, "");
      }
    }

    return dateArray;
  },

  /**
   * Replaces the mask number positions by underscores.
   */
  _getMaskedStringFromArray: function(day, month, year, mask) {
    var dateInString = mask;
    dateInString = !STR.isBlank(day) ? dateInString.replace("DD", this._pad(day)) : dateInString.replace("DD", "__");
    dateInString = !STR.isBlank(month) ? dateInString.replace("MM", this._pad(month)) : dateInString.replace("MM", "__");
    dateInString = !STR.isBlank(year) ? dateInString.replace("YYYY", this._pad(year, 4)) : dateInString.replace("YYYY", "____");
    return dateInString;
  },

  /**
   * Function to format an Int or string with 0 at the left.
   */
  _pad: function(value, length) {
    value = String(value);
    length = length || 2;
    while (value.length < length) {
      value = "0" + value;
    }
    return value;
  },

  /**
   * Function to format a mask including separators in it.
   */
  _formatMask: function(mask, separator, hourSeparator) {
    var formattedMask = "";

    var dateArray = ["D", "M", "Y"];
    var hourArray = ["h", "H", "m", "s", "l", "L"];

    var sep = "";
    if (!STR.isBlank(separator)) {
      sep = separator;
    }

    var hsep = "";
    if (!STR.isBlank(hourSeparator)) {
      hsep = hourSeparator;
    }

    if (!STR.isBlank(mask)) {
      var lastChar = mask.charAt(0);
      for (var i = 0; i < mask.length; i++) {
        var char = mask.charAt(i);
        if (lastChar !== char) {
          if (_.indexOf(dateArray, char) !== -1 && _.indexOf(dateArray, lastChar) !== -1) {
            formattedMask += sep;
          }
          if (_.indexOf(hourArray, char) !== -1 && _.indexOf(hourArray, lastChar) !== -1) {
            formattedMask += hsep;
          }
          lastChar = char;
        }
        formattedMask += char;
      }
    }

    return formattedMask;
  },

  /**
   * Function to validate if a string date has a correct values in every part (year, month,...).
   */
  _validateDate: function(strDate, mask) {
    var result = true;

    var dateArray = this._obtainDateArray(strDate, mask);
    var day = parseInt(dateArray[0], 10);
    var month = parseInt(dateArray[1], 10);
    var year = parseInt(dateArray[2], 10);

    //The date can't be lower than 31/12/1899
    if (year < 1899 || year > 2100) {
      result = false;
    }
    if (year === 1899 && (day !== 31 || month !== 12)) {
      result = false;
    }

    //Validate the date months
    if (month < 1 || month > 12) {
      result = false;
    }

    //Validate the date days
    if (day < 1 || day > 31) {
      result = false;
    }
    if ((month === 4 || month === 6 || month === 9 || month === 11) && day > 30) {
      result = false;
    }
    if (month === 2 && this._isLeapYear(year) && day > 29) {
      result = false;
    }
    if (month === 2 && !this._isLeapYear(year) && day > 28) {
      result = false;
    }

    return result;
  },

  /**
   * Function to replace all the ocurrences in a string by another string.
   */
  _replaceAll: function(str, searchStr, replaceStr) {
    var result = str;
    if (!STR.isBlank(searchStr)) {
      while (result.indexOf(searchStr) !== -1) {
        result = result.replace(searchStr, replaceStr);
      }
    }
    return result;
  },

  /**
   * Function to validate the format of a string date.
   */
  _validateDateFormat: function(strDate, mask) {

    var dateLength = strDate.toString().length;
    var day = mask.search("D"); //2 digits
    var month = mask.search("M"); //2 digits
    var year = mask.search("Y"); //4 digits

    if (year < day && year < month) { //Years first 4,6,8
      return (dateLength === 4 || dateLength === 6 || dateLength === 8) ? true : false;
    } else if (year < day || year < month) { //Years at middle 2,6,8
      return (dateLength === 2 || dateLength === 6 || dateLength === 8) ? true : false;
    } else if (year > day && year > month) { //Years at last 2,4,8
      return (dateLength === 2 || dateLength === 4 || dateLength === 8) ? true : false;
    }
    return false;
  },

  events: function(selector) {
    var eventsObj = {};
    if (selector === null || selector === undefined) {
      selector = "";
    }
    var self = this;
    eventsObj["focus " + selector + " .typeDate:not([readonly])"] = function(e) {
      var view = this;
      TYPE._eventFocus(e, "typeDate", self, view);
    };
    //Customized blur event
    eventsObj["blur " + selector + " .typeDate:not([readonly])"] = function(e) {
      var view = this;
      var attr = e.target.className.split(" ")[0];
      var attrModelValue = STR.getElValue(view._getModel(attr), attr);

      //get Format Mask
      var maskCode = TYPE._getMaskCode(attr, this, "typeDate");
      var formatMask = TYPE._getFormatByCode(maskCode);

      //Parse the input value
      var parse = TYPE.DATE.parse(e.target.value, maskCode);

      //Check changes on the original Value.
      if (String(this.TEMP_origVal) !== parse["val"]) {
        if (!(view.dynamicAttributes && e.target.value === this.TEMP_origVal)) {
          $(e.target).change();
        }
      }

      //Check period conditions if needed
      if ($(e.target).hasClass("periodStart") && !$(e.target).hasClass("avoidEqualize") && String(this.TEMP_origVal) !== parse["val"] && !STR.isBlank(parse["val"])) {
        var periodId = $(e.target).attr("periodId");
        var endDate = view.$el.find(".periodEnd[periodId=" + periodId + "]");
        var endDateName = endDate.attr("class").split(" ")[0];
        var endDateValue = STR.getElValue(view._getModel(attr), endDateName);
        if (!STR.isBlank(endDateValue) && attrModelValue > endDateValue) {
          endDate.val(TYPE.DATE.format(parse["val"], formatMask));
          endDate.change();
        }
      }

      //Check errors and format if dont has
      //var errors = {};
      if ($(e.target).hasClass("infinityDate") && parse["val"] === TYPE.DATE.INFINITY && GLOBAL_DATA.paramDivers.get("CACH_INFIN").get("valeur") === "1") {
        $(e.target).val("");
      } else if (!parse["errors"]) {
        $(e.target).val(TYPE.DATE.format(parse["val"], formatMask));
      }
      /*} else if (parse["errors"] && $(e.target).hasClass("datesitu")) {
        $(e.target).val("");
      }*/
      delete this.TEMP_origVal;
    };
    eventsObj["keypress  " + selector + " .typeDate:not([readonly])"] = function(e) {
      TYPE._eventKeypress(e, "typeDate", TYPE.DATE, this, true);
    };
    eventsObj["keydown  " + selector + " .typeDate:not([readonly])"] = function(e) {
      TYPE._eventDownLeft2Right(e, "typeDate", TYPE.DATE, this);
    };
    return eventsObj;
  },

  /**
   * Function to know if a year is a leap year.
   */
  _isLeapYear: function(year) {
    var result = false;
    if (!STR.isBlank(year)) {
      if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
        result = true;
      }
    }
    return result;
  },

  /**
   * Function to know if a day is in a period. If day is not defined it will be the server current date.
   */
  _checkDateInPeriod: function(datedeb, datefin, dateJour) {
    if (dateJour === undefined) {
      dateJour = TYPE.DATE.dateToStr(SYNC.getServerDate(), "DD/MM/YYYY");
    }
    var dateDeb = TYPE.DATE.format(datedeb, TYPE._getFormatByCode(TYPE.DATE.DEFAULT_MASK_CODE));
    var dateFin = TYPE.DATE.format(datefin, TYPE._getFormatByCode(TYPE.DATE.DEFAULT_MASK_CODE));
    var d1 = dateDeb.split("/");
    var d2 = dateFin.split("/");
    var c = dateJour.split("/");
    let from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
    let to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
    let check = new Date(c[2], parseInt(c[1]) - 1, c[0]);
    return (check >= from && check <= to);
  }

});

/**
 * Type that defines the Mask utils functions to be used on all the other types.
 */
TYPE.MASK = {

  SEPARATOR_SYMBOL: undefined,
  MILLAR_SYMBOL: undefined,

  ERROR_INCOMPLETE: "Saisie incomplète",

  ERROR_IMPOSSIBLE: "Saisie impossible",

  /**
   * Apply mask to an attribute to transform the model data into screen visual
   */
  applyFormatMask: function(val, mask, separator, millar) {
    //If mask in blank return the val
    if (STR.isBlank(mask)) {
      return val;
    }
    //If val is blank return "" and don't process.
    if (STR.isBlank(val)) {
      return "";
    } else {
      val = val.toString();
    }

    if (STR.isBlank(separator)) { separator = this.SEPARATOR_SYMBOL; }
    if (STR.isBlank(millar)) { millar = this.MILLAR_SYMBOL; }

    //Clean the input
    val = TYPE.MASK._cleanInput(val);

    var maskPos = mask.length;
    var valPos = val.length;
    var out = "";
    var n = "";
    var negative = false;
    var maskWithSing = false;
    var error = undefined;
    var maskDigitLength = 0;

    //NEGATIVE Input NUMBER ?
    if (!STR.isBlank(val)) {
      if (val[0] === "-") {
        negative = true;
        val = val.substr(1);
        valPos--;
      }
    }

    //POSITIVE Input NUMBER ?
    if (!STR.isBlank(mask)) {
      if (mask[0] === "+") {
        maskWithSing = true;
      }
    }

    if (!STR.isBlank(mask)) {
      //MaskLength
      for (let i = 0; i < mask.length; i++) {
        if (mask[i] === "#" || mask[i] === "&" || mask[i] === "$" || mask[i] === "<" ||
          mask[i] === "*" || mask[i] === "-" || mask[i] === "+") {
          maskDigitLength++;
        }
      }
    }

    //			//DECIMAL ? separator =
    //			if(mask.indexOf(separator) != -1) {
    //				//Decimal Mask
    //				decimalMask = mask.substr(mask.indexOf(separator)+1);
    //				intMask = mask.substr(0,mask.indexOf(separator));
    //				maskPos = mask.indexOf(separator);
    //			}
    //			if(val.indexOf(separator) != -1) {
    //				decimalVal = val.substr(val.indexOf(separator)+1);
    //				intVal = val.substr(0,val.indexOf(separator));
    //				valPos = val.indexOf(separator);
    //			}

    //Check Size,
    var valDigitsNumber = val.match(/[0-9]/g);
    var whiteCount = 0;
    if (!STR.isBlank(valDigitsNumber) && valDigitsNumber.length > maskDigitLength) {
      error = "GL_1003";
    } else {
      valPos--;
      maskPos--;
      for (maskPos; maskPos >= 0; maskPos--) {
        n = val[valPos];
        switch (mask[maskPos]) {
          case "*":
            out = (!STR.isBlank(n)) ? n + out : "*" + out;
            valPos--;
            break;
          case "#":
            if (!STR.isBlank(n)) {
              out = n + out;
            } else {
              whiteCount++;
            }
            valPos--;
            break;
          case "&":
            out = (!STR.isBlank(n)) ? n + out : "0" + out;
            valPos--;
            break;
          case "<":
            out = (!STR.isBlank(n)) ? n + out : out + " ";
            valPos--;
            break;
          case millar:
            if (!STR.isBlank(n)) {
              out = millar + out;
            } else {
              out = String(out);
            }
            break;
          case separator:
            //Decimal are processed later
            break;
          case "-":
            if (!STR.isBlank(n)) {
              out = n + out;
            } else {
              if (negative) {
                if (out.indexOf("-") === -1) {
                  out = "-" + out;
                } else {
                  whiteCount++;
                }
              } else {
                whiteCount++;
              }
            }
            valPos--;
            break;
          case "+":
            if (!STR.isBlank(n)) {
              out = n + out;
            } else {
              if (negative) {
                if (out.indexOf("-") === -1) {
                  out = "-" + out;
                } else {
                  whiteCount++;
                }
              } else if (maskWithSing) {
                if (out.indexOf("+") === -1) {
                  out = "+" + out;
                } else {
                  whiteCount++;
                }
              } else {
                whiteCount++;
              }
            }
            valPos--;
            break;
          case "(":
            if (negative) {
              out = "(" + out;
            }
            break;
          case ")":
            if (negative) {
              out = out + ")";
            }
            break;
          case "$":
            if (!STR.isBlank(n)) {
              out = n + out;
            } else {
              if ("$" === out[0] || " " === out[0]) {
                whiteCount++;
              } else {
                out = "$" + out;
              }
            }
            valPos--;
            break;
          default:
            out = mask[maskPos] + out;
            //LOG.debug("Mask Symbol no special !");
        }
      }

      for (let i = 0; i < whiteCount; i++) {
        out = " " + out;
      }

      //Calculate the decimal Part
      //				if( !STR.isBlank(decimalMask)) {
      //					//Input Has decimal part
      //					var decimalOut = "";
      //					var decimalSize = decimalMask.length;
      //					if( !STR.isBlank(decimalVal) && decimalVal.length > decimalSize){
      //						//Round the decimals to the size of the mask decimals
      //						var coef = Math.pow(10,decimalSize);
      //						var num = decimalVal/Math.pow(10,decimalVal.length);//0.XXXXXXXX
      //						num = num*coef;//YY.XXXXXXX where YY is the number of decimalSize
      //						decimalOut = Math.round(num);
      //					} else {
      //						//Less or equal to mask decimal part
      //						for(var i = 0 ; i < decimalSize ; i++){
      //							switch (decimalMask[i]){
      //								case "#" :
      //									( !STR.isBlank(decimalVal) && decimalVal[i] != undefined)  ?
      //										decimalOut += decimalVal[i] : decimalOut += " ";
      //									break;
      //								case "&" :
      //									( !STR.isBlank(decimalVal) && decimalVal[i] != undefined)  ?
      //										decimalOut += decimalVal[i] : decimalOut += "0";
      //									break;
      //								case "*" :
      //									( !STR.isBlank(decimalVal) && decimalVal[i] != undefined)  ?
      //										decimalOut += decimalVal[i] : decimalOut += "*";
      //									break;
      //								case "$" :
      //									( !STR.isBlank(decimalVal) && decimalVal[i] != undefined)  ?
      //										decimalOut += decimalVal[i] : decimalOut += " ";
      //									break;
      //								case ")" :
      //									if(negative){
      //										decimalOut += ")";
      //									}
      //									break;
      //								default : decimalOut += decimalMask[i];
      //									LOG.debug("Decimal Undefined Symbol");
      //							}
      //						}
      //					}
      //					if(!STR.isBlank(decimalOut)){
      //						out = out + separator + decimalOut;
      //					}
      //				}
    }

    if (!STR.isBlank(error)) {
      out = "";
      for (var i = 0; i < maskDigitLength; i++) { out = out + "?"; }
    }
    return out;

  },

  /**
   * Function that transform the input data into new one when edit a field.
   */
  applyInputMask: function(input, mask, previousValue, typeClass) {

    var out = "";

    if (STR.isBlank(mask)) {
      return input;
    }

    //If typeDate call to his function
    if (typeClass === TYPE.DATE.TYPE_CLASS) {
      return TYPE.MASK.applyDateInputMask(input, mask, previousValue);
    }

    //Check if negative Mask and quit that symbol
    var allowSign = false;
    if (!STR.isBlank(mask)) {
      if (mask[0] === "-") {
        allowSign = true;
        //Removes the "-" from the mask
        mask = mask.substr(1);
      }
    }

    //If HM we need to conserve the nightSymbol +
    if (typeClass === TYPE.HOUR_MINUTE_NIGHTLY.TYPE_CLASS) {
      input = this._cleanInput(input, ["+"]);
      allowSign = true;
      //			}else if(typeClass == TYPE.DECIMAL.TYPE_CLASS) {
      //				input = this._cleanInput(input,["."]);
    } else {
      if (allowSign) {
        input = this._cleanInput(input, ["+"]);
      } else {
        input = this._cleanInput(input);
      }
    }

    // Change to number to clean left zeros
    //			if(!STR.isBlank(input)){
    //				input = parseInt(input,10).toString();
    //			}

    //Detect if the input has any sign
    var sign = undefined;
    if (!STR.isBlank(input)) {
      if (input[0] === "-" || input[0] === "+") {
        sign = input[0];
        //Removes the sign from the input
        input = input.substr(1);
      }
      //Trick
      if (typeClass === TYPE.HOUR_MINUTE_NIGHTLY.TYPE_CLASS && sign !== "+") {
        sign = undefined;
      }
    }

    //If the input is larger than the mask number capacity return previous
    var numberSize = 0;
    for (var i = 0; i < mask.length; i++) {
      if (mask[i] === "&") {
        numberSize++;
      }
    }
    if (numberSize < input.length) {
      return previousValue;
    }

    //Replacement
    var maskIt = mask.length - 1;
    var inputIt = input.length - 1;
    var alreadySigned = false;
    for (maskIt; maskIt >= 0; maskIt--) {
      switch (mask[maskIt]) {
        case "&": //NumberPlace, usually only get "&"
          if (STR.isBlank(input[inputIt])) {
            if (allowSign && !STR.isBlank(sign) && !alreadySigned) {
              out = sign + out;
              alreadySigned = true;
            }
            out = "_" + out;
          } else {
            out = input[inputIt] + out;
            inputIt--;
          }
          break;
        default: //Other symbols in the mask
          out = mask[maskIt] + out;
      }
    }

    if (allowSign && !STR.isBlank(sign) && !alreadySigned) {
      out = sign + out;
    }

    return out;
  },

  /**
   * Function that transform the input data into new one when edit a field, only for Date Type.
   */
  applyDateInputMask: function(input, mask, old) {
    var out = "";

    input = this._cleanDateInput(input);

    //Count the mask number capacity
    var numberSize = 0;
    for (var i = 0; i < mask.length; i++) {
      if (mask[i] === "D" || mask[i] === "Y" || mask[i] === "M") {
        numberSize++;
      }
    }
    if (numberSize < input.length) {
      //input = input.substr(0,numberSize);
      //If the input is larger than the mask number capacity
      return old;
    }

    //Replacement
    var maskIt = 0;
    var inputIt = 0;
    for (maskIt; maskIt < mask.length; maskIt++) {
      switch (mask[maskIt]) {
        case "Y":
        case "D":
        case "M": //NumberPlace
          if (STR.isBlank(input[inputIt])) {
            out = out + "_";
          } else {
            out = out + input[inputIt];
            inputIt++;
          }
          break;
        default: //Other symbols
          out = out + mask[maskIt];
      }
    }

    return out;
  },

  /**
   * Clean the input and only hold + or - if is on the first pos, and numbers,
   * acceptedSymbols are accepted too
   */
  _cleanInput: function(input, acceptedSymbols) {
    var out = "";
    if (!STR.isBlank(input)) {
      input = input.toString();
      for (var i = 0; i < input.length; i++) {
        if (!isNaN(parseInt(input[i], 10))) {
          out = out + input[i];
        }
        // "-" Symbol only can be in first position
        if (input[i] === "-") {
          if (out.length === 0) {
            out = "-";
          }
        }
        if (_.indexOf(acceptedSymbols, input[i]) >= 0) {
          // "+" NightSymbol only can stand in first position
          if (input[i] === "+") {
            if (out.length === 0) {
              out = "+";
            }
          } else {
            out = out + input[i];
          }
        }
      }
    }
    return out;
  },

  /**
   * Removes the mask unnecesary symbols to save the number on the model.
   */
  _cleanDateInput: function(input) {
    var out = "";
    if (!STR.isBlank(input)) {
      input = input.toString();
      for (var i = 0; i < input.length; i++) {
        if (!isNaN(parseInt(input[i], 10))) {
          out = out + input[i];
        }
      }
    }
    return out;
  },

  /**
   * returns the n first numbers of the input,
   * "-12,345.67"
   * n = 2 >> -12
   * n = 4 >> -12,34
   * n = 5 >> -12,345
   * n = 6 >> -12,34.6
   */
  _trimNumber: function(input, n) {
    var out = "";
    var numCount = 0;
    for (var i = 0; i < input.length && numCount < n; i++) {
      out = out + input[i].toString();
      if (!isNaN(input[i])) {
        numCount++;
      }
    }
    return out;
  },

  /**
   * Gets the characters which represent a separator in the mask
   */
  _getSeparatorsFromMask: function(mask) {
    var len = mask.length;
    var separators = [];
    for (var i = 0; i < len; i++) {
      if (mask[i] !== '-' && mask[i] !== '&') {
        separators.push(mask[i]);
      }
    }
    return separators;
  }
};
