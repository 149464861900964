import * as Backbone from 'Backbone';
import _ from 'underscore';
import CWGererRegularisationPopUpTPL from '../cwPopUp.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWButtonBarView } from 'src/core/controls/cwButtonBar.view';
import { CWGererRegularisationsGestion } from '../models/cwGererRegularisationsGestion';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';


export class CWGererRegularisationPopUpView extends CWBaseFormView {
  template: (params?: any) => string;
  public parent: CWGererRegularisationPopUpView | any;
  action: string;
  statut: string;
  btnbBar: CWButtonBarView;

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options.events = _.extend({
      "change :input:not([readonly])": "_change",
      "click button": "_clickListener",
      "keypress :input:not([readonly])": "_cleanValidationErrors"
    }, options.events);

    super(options);

    this.template = CWGererRegularisationPopUpTPL;

    this.model = new CWBaseModel({
      value: new CWGererRegularisationsGestion()
    });

    this.btnbBar = new CWButtonBarView();
    if (options.parent) {
      this.parent = options.parent;
    }
    if (options.action) {
      this.action = options.action;
      this.model.get("value").action = this.action;
    }
    if (options.statut) {
      this.statut = options.statut;
    }
  }

  render(): CWGererRegularisationPopUpView {
    const json = { "i18n": i18n, UTILS: UTILS };
    this.$el.append(this.template(json));

    let comment: string = "";
    switch (this.action) {
      case "accepter":
        comment = i18n.t('common:gererregularisations.commentaire_Accept');
        break;
      case "refuser":
        this.$el.find("label[for=commentaire],label[for^=commentaire_uid_]").addClass("cw-required");
        comment = i18n.t('common:gererregularisations.commentaire_Refus');
        break;
      case "suppression":
        switch (this.statut) {
          case "I":
            comment = i18n.t('common:gererregularisations.commentaire_SuppresionCours');
            break;
          case "A":
            comment = i18n.t('common:gererregularisations.commentaire_SuppresionAccept');
            break;
          default:
            comment = i18n.t('common:gererregularisations.commentaire_SuppresionSupp');
        }
        break;
      default:
        break;
    }
    this.parent._setTitle(comment);
    return this;
  }

  _clickListener(event: JQueryEventObject | any): void {
    const btnClicked = event.currentTarget.value;
    const model = this.model.get("value");

    switch (btnClicked) {
      case "btnOK":
        if (this.action === "accepter") {
          this.parent.model.set("action", "OK");
          this.parent.model.set("commentaire", model.get("commentaire"));
          this.parent.close();
        } else {
          if (this.action === "refuser") {
            model.off("invalid");
            model.on("invalid", this._showValidationErrors, this);

            if (model.isValid()) {
              this.parent.model.set("action", "OK");
              this.parent.model.set("commentaire", model.get("commentaire"));
              this.parent.close();
            }
          } else if (this.action === "suppression") {
            this.parent.model.set("action", "OK");
            this.parent.model.set("commentaire", model.get("commentaire"));
            this.parent.close();
          }
        }
        break;
      case "btnAnnuler":
        this.parent.model.set("action", "annuler");
        this.parent.close();
        break;
      default:
        break;
    }
  }
}
