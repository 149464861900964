import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLCommonSelecteurChemin from './cwSelecteurChemin.tpl.html';
import { AutocompleteColl } from '../combo/autocomplete.collection.js';
import { CWDialogPopupView } from '../dialog/popup/cwDialogPopup.view';
import { CWFORMS } from 'src/utils/cwForms';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWLOG } from 'src/utils';
import { CWSelecteurCheminDialogView } from './cwSelecteurCheminDialog.view.js';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { ReadOnlyModel } from '../../models/readOnly.model.js';
import { SelecteurCheminModeSimpleColl } from './selecteurCheminModeSimple.collection.js';
import { SelecteurCheminNivColl } from './selecteurCheminNiv.collection.js';
import { SelecteurCheminResultItemView } from './selecteurCheminResultItem.view.js';
import { SYNC } from 'utils/sync.js';
import { UTILS } from 'utils/utils.js';


export class CWSelecteurCheminView extends Backbone.View<Backbone.Model> {

  cache: { [key: string]: any };
  cacheEventsModel: Backbone.Model;
  listWidth: string;
  minSearchLength: number;
  multiselection: boolean;
  maxResultItems: number;
  modeComplet: boolean;
  readonly: boolean;
  plainText: boolean;
  structid: string;
  params: { [key: string]: any };
  coll: any;
  parentdebsitu: string;
  parentfinsitu: string;
  datedebut: string;
  datefin: string;
  name: string;
  selRacineAuto: any;
  selNoeudChemin: any;
  view: typeof CWSelecteurCheminDialogView; //ce n'est pas en typescrit - is not in typescript
  listRenderStyle: number;
  idCache: string;
  title: string;
  height: string | number;
  width: string | number;
  fieldWidth: string;
  racine: any;
  nonrattachable: any;
  nonrattactiv: any;
  keepOldId: any;
  addItemCallback: (arg1?: any, arg2?: any) => any;
  removeItemCallback: (arg1?: any, arg2?: any) => any;
  selection: typeof AutocompleteColl;
  habContext: CWHabilitationContext;
  appended: boolean;
  input: any;
  term: any;
  shouldCloseMenu: boolean;
  popup: CWDialogPopupView;
  popupResponse: any;
  niveauColl: typeof SelecteurCheminNivColl;
  listRenderer: any;
  autocompleteMode: boolean;
  attributesCheminSelected: any;
  currentCode: any;
  setItemPendingAction: boolean;
  private $appendTo: JQuery;



  /**
   * Constructor
   * Selecteur de chemin mode simple view
   */
  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options.className = options.className || "phx-selecteur-chemin";
    options.tagName = options.tagName || "div";
    options.events = _.extend({
      "click .phx-cw-chemin-button": "_openDetailPopup",
      "autocompleteopen": "_openlist",
      "autocompleteselect": "_selectItem",
      "tooltipclose .phx-chemin-input": "_focused",
      "tooltipopen .phx-chemin-input": "_focused",
      "change .phx-chemin-input": "_changeItem",
      "keyup .phx-chemin-input:not([readonly])": "_changeItem",
      "autocompleteresponse .phx-chemin-input": "_setTooltips",
      "autocompletefocus": "_keyboardMenuItemFocus"
    }, options.events);
    super(options);
    this.template = TPLCommonSelecteurChemin;
    this.cache = {};
    this.cacheEventsModel = new Backbone.Model();
    this.listWidth = "400px";
    this.minSearchLength = Configuration.selecteurChemin.minCharactersToSearch;
    this.multiselection = false;
    this.maxResultItems = Configuration.selecteurChemin.maxResultItems;
    this.modeComplet = false;
    this.readonly = false;
    this.plainText = false;
    this.setItemPendingAction = false;

    //optionsthat come from param.context
    if (!CWSTR.isBlank(options.context)) {
      const context = options.context;

      if (!CWSTR.isBlank(context.ctxActivSelMultiple)) {
        options.multiple = context.ctxActivSelMultiple
      }
      if (!CWSTR.isBlank(context.ctxStructure)) {
        options.structid = context.ctxStructure;
      }
      if (!CWSTR.isBlank(context.ctxActivModeComplet)) {
        options.modeComplet = context.ctxActivModeComplet;
      }
      if (!CWSTR.isBlank(context.ctxActivModeComplet)) {
        options.modeComplet = context.ctxActivModeComplet;
      }
      if (context.ctxPeriodeRecherche && !CWSTR.isBlank(context.ctxPeriodeRecherche.datedebut)) {
        options.datedebut = context.ctxPeriodeRecherche.datedebut;
      }
      if (context.ctxPeriodeRecherche && !CWSTR.isBlank(context.ctxPeriodeRecherche.datefin)) {
        options.datefin = context.ctxPeriodeRecherche.datefin;
      }
      if (!CWSTR.isBlank(context.ctxAfficheRacine)) {
        options.racine = context.ctxAfficheRacine;
      }
      if (!CWSTR.isBlank(context.ctxStructureCollab)) {
        options.nonrattachable = !context.ctxStructureCollab;
      }
      if (!CWSTR.isBlank(context.ctxStructureActivites)) {
        options.nonrattactiv = !context.ctxStructureActivites;
      }
      if (!CWSTR.isBlank(context.ctxEcran)) {
        options.ecran = context.ctxEcran;
      }
      if (!CWSTR.isBlank(context.ctxConsultation)) {
        options.readonly = context.ctxConsultation;
      }
      if (!CWSTR.isBlank(context.ctxHabilitation)) {
        options.habContext = context.ctxHabilitation;
      }
      //context.ctxSelRacineAuto > root is selectable (default true)
      if (!CWSTR.isBlank(context.ctxSelRacineAuto)) {
        options.selRacineAuto = context.ctxSelRacineAuto;
      }
      //context.ctxSelNoeudChemin > intermediate elements, not leaf or root, are selectable (default true).
      if (!CWSTR.isBlank(context.ctxSelNoeudChemin)) {
        options.selNoeudChemin = context.ctxSelNoeudChemin;
      }
      //context.ctxInitChemins > List consists of one or more structural paths preset in the initial display of the component.
      if (!CWSTR.isBlank(context.ctxInitChemins)) {
        options.initChemins = context.ctxInitChemins;
      }
    }

    this.coll = options.ws;
    /**
     * HTML template of the view
     */


    /**
     * Parameters of the initialization
     */
    this.params = options;

    if (options && !CWSTR.isBlank(options.multiselection)) {

      /**
       * Multiselection indicator
       */
      this.multiselection = options.multiselection;
    }
    const today = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(SYNC.getServerDate())).val;
    const parameters: any = {
      structid: null,
      parentcode: null,
      parentdebsitu: null,
      parentfinsitu: null,
      datedebut: today,
      datefin: today,
      multiple: false,
      intermediaire: true,
      partial: true,
      complet: false,
      selectionnes: "",
      niveau: null,
      racine: null,
      nonrattachable: null,
      nonrattactiv: null,
      elementcode: null,
      ecran: "",
      selRacineAuto: null,
      selNoeudChemin: null,
      habContext: null
    };

    /**
     * Id of the structure of the component
     */
    this.structid = null;
    if (options && !CWSTR.isBlank(options.structid)) {
      this.structid = options.structid;
      parameters.structid = options.structid;
    } else {
      this.structid = "";
    }

    parameters.multiple = this.multiselection;
    this.params.multiple = this.multiselection;

    this.params.complet = false;

    if (options && !CWSTR.isBlank(options.parentcode)) {
      parameters.parentcode = options.parentcode;
    }

    if (options && !CWSTR.isBlank(options.parentdebsitu)) {
      this.parentdebsitu = options.parentdebsitu;
    }
    if (options && !CWSTR.isBlank(options.parentfinsitu)) {
      this.parentfinsitu = options.parentfinsitu;
    }
    /**
     * Start date of the component
     */
    this.datedebut = undefined;
    if (options && !CWSTR.isBlank(options.datedebut)) {
      this.datedebut = options.datedebut;
      parameters.datedebut = options.datedebut;
    } else {
      this.datedebut = today;
      this.params.datedebut = today;
    }

    /**
     * End date of the component
     */
    this.datefin = undefined;
    if (options && !CWSTR.isBlank(options.datefin)) {
      this.datefin = options.datefin;
      parameters.datefin = options.datefin;
    } else {
      this.params.datefin = today;
      this.datefin = today;
    }

    if (options && !CWSTR.isBlank(options.intermediaire)) {
      parameters.intermediaire = options.intermediaire;
    }

    if (options && !CWSTR.isBlank(options.partial)) {
      parameters.partial = options.partial;
    }

    if (options && !CWSTR.isBlank(options.selectionnes)) {
      parameters.selectionnes = options.selectionnes;
    }

    if (options && !CWSTR.isBlank(options.niveau)) {
      parameters.niveau = options.niveau;
    }

    if (options) {
      parameters.racine = options.racine;
      parameters.nonrattachable = options.nonrattachable;
      parameters.nonrattactiv = options.nonrattactiv;
    }

    if (options && !CWSTR.isBlank(options.ecran)) {
      parameters.ecran = options.ecran;
    }

    if (options && !CWSTR.isBlank(options.selRacineAuto)) {
      parameters.selRacineAuto = options.selRacineAuto;
      this.selRacineAuto = options.selRacineAuto;
    }
    if (options && !CWSTR.isBlank(options.selNoeudChemin)) {
      parameters.selNoeudChemin = options.selNoeudChemin;
      this.selNoeudChemin = options.selNoeudChemin;

    }

    /**
     * Collection of elements of the component
     */
    this.coll = new SelecteurCheminModeSimpleColl(parameters);

    if (options && !CWSTR.isBlank(options.name)) {
      /**
       * Name of the component
       */
      this.name = options.name;
    }

    if (options && options.readonly) {
      /**
       * Readonly indicator
       */
      this.readonly = options.readonly;
    }

    if (options && !this.readonly && !CWSTR.isBlank(options.modeComplet)) {
      /**
       * Mode complet indicator
       */
      this.modeComplet = options.modeComplet;
      parameters.complet = this.modeComplet;
      parameters.habContext = options.habContext;
      if (this.modeComplet) {
        /**
         * View for the mode complet dialog
         */
        this.view = CWSelecteurCheminDialogView;
      }
    }

    if (options && !CWSTR.isBlank(options.plainText)) {
      /**
       * PlainText indicator
       */
      this.plainText = options.plainText;
    }

    /**
     * Style of rendering of the component elements
     */
    this.listRenderStyle = 1;
    if (options && !CWSTR.isBlank(options.listRenderStyle)) {
      this.listRenderStyle = options.listRenderStyle;
    }

    if (options && options.habContext) {
      this.habilitationContext(options.habContext);
    }

    if (options && options.idCache) {
      this.idCache = options.idCache;
      //Initialize Cache
      if (CWSTR.isBlank(this.cache["niveau"]) || CWSTR.isBlank(this.cache["item"])) {
        this.cache["niveau"] = {};
        this.cache["item"] = {};
      }
      //Initialize Niveau and item cache
      if (CWSTR.isBlank(this.cache["niveau"][this.idCache])) {
        this.cache["niveau"][this.idCache] = {};
      }
      if (CWSTR.isBlank(this.cache["item"][this.idCache])) {
        this.cache["item"][this.idCache] = {};
      }
    }

    /**
     * Title of the component
     */
    if (options && !CWSTR.isBlank(options.titlePopup)) {
      this.title = i18n.t('messages:GT_2128', { "1": options.titlePopup });
    } else {
      this.title = undefined;
      if (this.datedebut && this.datefin) {
        if (this.datedebut === this.datefin) {
          this.title = i18n.t('common:chemin.dlgtitle');
        } else {
          this.title = i18n.t('common:chemin.dlgtitle');
        }
      } else {
        this.title = "";
      }
    }

    /**
     * Height of the component
     */
    this.height = undefined;
    if (options && options.height) {
      this.height = options.height;
    } else {
      this.height = 500;
    }

    /**
     * Width of the component
     */
    this.width = undefined;
    if (options && options.width) {
      this.width = options.width;
    } else {
      this.width = 800;
    }

    /**
     * Width of the input field of the component
     */
    this.fieldWidth = undefined;
    if (options && options.fieldWidth) {
      const lRegxp = /^([0-9\.]+)[%]?$/g; //eslint-disable-line

      if (lRegxp.test(options.fieldWidth) || options.fieldWidth === "auto") {
        this.fieldWidth = options.fieldWidth;
      } else {
        throw new Error("the fieldWidth must be numeric (" + this.name + ")");
      }
    }

    if (options && options.racine) {

      /**
       * Indicator of the root element
       */
      this.racine = options.racine;
    }
    if (options && options.nonrattachable) {

      /**
       * Indicator of an element "non rattachable"
       */
      this.nonrattachable = options.nonrattachable;
    }
    if (options && options.nonrattactiv) {

      /**
       * Indicator of an element "non rattachable activite"
       */
      this.nonrattactiv = options.nonrattactiv;
    }
    if (options && !CWSTR.isBlank(options.elementcode)) {
      parameters.elementcode = options.elementcode;
    }

    if (options && options.keepOldId) {
      this.keepOldId = options.keepOldId;
    } else {
      this.keepOldId = null;
    }
    // called with the selected items
    /**
     * Callback function to execute when an element is added
     */
    this.addItemCallback = options.addItemCallback;

    /**
     * Callback function to execute when an element is deleted
     */
    this.removeItemCallback = options.removeItemCallback;

    // response list filled with user selection
    /**
     * Selection list of elements of the autocompletion results
     */
    this.selection = new AutocompleteColl();
    this.selection.itemRenderer = this._getAttrsRenderer();
    this.selection.on("click:item", this._removeItem, this);

    // initial display of the component.
    this.clearSelection();
    if (!CWSTR.isBlank(options.initChemins)) {
      if (_.isArray(options.initChemins) && options.initChemins.length > 0) {
        // init with one or more elements in arrays
        for (let i = 0; i < options.initChemins.length; i++) {
          if (!CWSTR.isBlank(options.initChemins[i].get("elementCode"))) {
            this.addValue(options.initChemins[i], null);
          }
        }
      } else if (!CWSTR.isBlank(options.initChemins.get("elementCode"))) {
        // init with an element in object
        this.addValue(options.initChemins, null);
      }
    }

    if (options.appendTo) {
      this.$appendTo = options.appendTo;
    } else if (options.context && !CWSTR.isBlank(options.context.ctxEcran)) {
      this.$appendTo = $("#" + options.context.ctxEcran);
    } else {
      this.$appendTo = null;
    }
  }

  setHabContext(habContext: CWHabilitationContext): void {
    this.habContext = habContext;
  }

  updateHabContext(attributes: { [key: string]: any }): void {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  }

  getHabContext(): CWHabilitationContext {
    return this.habContext;
  }

  /**
   * Called the first time to initialize the context of the combo
   */
  habilitationContext(context: CWHabilitationContext): void {
    if (this.coll) {
      if (CWSTR.isBlank(this.coll.habContext) || !_.isEqual(this.coll.habContext.toJSON(), context.toJSON())) {
        this.clearCache();
      }
      this.stopListening();
      this.coll.setHabContext(context);
      this.listenTo(context, "change", this.clearCache);
    }
  }

  clearCache(): void {
    for (const i in this.cache) {
      if (Object.prototype.hasOwnProperty.call(this.cache, i)) {
        this.cache[i].length = 0;
        delete this.cache[i];
      }
    }
    this.cache["pendingInUse"] = this.cache["pendingInUse"] || [];
  }

  /**
   * Cleans the selection of the component
   */
  cleanChemin(): void {
    this.$el.find(".phx-chemin-selection").empty();
    this.selection.reset();
  }

  /**
   * Sets the dates of the component
   */
  setDates(datedeb: string, datefin: string): void {
    const today = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(SYNC.getServerDate())).val;

    if (!CWSTR.isBlank(datedeb)) {
      this.datedebut = datedeb;
      this.params.datedebut = datedeb;
    } else {
      this.datedebut = today;
      this.params.datedebut = today;
    }

    if (!CWSTR.isBlank(datefin)) {
      this.datefin = datefin;
      this.params.datefin = datefin;
    } else {
      this.params.datefin = today;
      this.datefin = today;
    }
    this.coll.setDates(this.params.datedebut, this.params.datefin);
  }

  /**
   * Gets the path of an element of the component's list
   */
  _getChemin(model: any, initParent: number): string {
    let result = "";

    //When there are too many results show according message.
    if (_.isNull(model.code)) {
      return model.libelle;
    }
    if (model instanceof Backbone.Model && (!CWSTR.isBlank(model.get("attrs")) && _.isNull(model.get("attrs").code))) {
      return model.get("libelle");
    }

    if (initParent > 0) {
      result += ".../";
    } else {
      initParent = 1;
    }

    if (!CWSTR.isBlank(model)) {
      let parents = [];
      let item = {};

      if (model.parents) {
        parents = model.parents;
        item = new Backbone.Model(model);
      } else {
        if (model.code) {
          //Structure complete
          parents = [];
          item = new Backbone.Model(model);
        } else if (model.get("attrs") && model.get("attrs").parents) {
          parents = model.get("attrs").parents;
          item = new Backbone.Model(model.get("attrs"));
        }
      }

      if (parents.length > 1) {
        let first = true;

        for (let i = initParent; i < parents.length; i++) {
          if (first === true) {
            first = false;
          } else {
            result += "/";
          }
          const parentModel = new Backbone.Model(parents[i]);

          result += this._getRenderer().call(this, parentModel);
        }
      }
      if (!CWSTR.isBlank(result) && result !== ".../") {
        result += "/";
      }
      result += this._getRenderer().call(this, item);
    }
    return result;
  }

  /**
   * Shows the tooltip of an element
   */
  _showTooltip(model: any): string {
    let result = "";

    if (this.datedebut && this.datefin && !CWSTR.isBlank(model)) {
      if (model.datedebut > this.datedebut || model.datefin < this.datefin) {
        //(&#9679)= (●)
        result = "<span class=\"phx-chemin-tooltip\" title=\"\">(&#9679)</span>";
      }
    }
    return result;
  }

  /**
   * Method which is executed during the navigation in the menu using the keyboard
   */
  _keyboardMenuItemFocus(): boolean {
    return false;
  }

  /**
   * Method which is executed during the navigation in the menu using the keyboard
   */
  _getLevels(callback: (arg: { [key: string]: any }[]) => any): void {
    if (this._useCache() && !CWSTR.isBlank(this.cache["niveau"][this.idCache][this.structid])) {
      //Using cache and 2nd time
      if (this.cache["niveau"][this.idCache][this.structid].fetching) {
        this.listenToOnce(this.cacheEventsModel, "niveau" + this.idCache + this.structid, () => {
          this.niveauColl = this.cache["niveau"][this.idCache][this.structid].data;
          callback(this.niveauColl.models);
        });
      } else {
        this.niveauColl = this.cache["niveau"][this.idCache][this.structid].data;
        callback(this.niveauColl.models);
      }
    } else {
      if (this.niveauColl && this.niveauColl.models) {
        callback(this.niveauColl.models);
      } else {
        const coll = new SelecteurCheminNivColl({ code: this.structid });

        if (this._useCache()) {
          //Using cache and 1st time
          this.cache["niveau"][this.idCache][this.structid] = { fetching: true };
        }
        if (this.params && this.params.context && !CWSTR.isBlank(this.params.context.ctxEcran)) {
          coll.ecran = this.params.context.ctxEcran;
        }
        if (!CWSTR.isBlank(this.params.context) && this.params.context.ctxStructureSelpop === true) {
          coll.selectpopu = true;
        }
        coll.fetch({
          success: () => {
            if (!this.niveauColl) {
              this.niveauColl = coll;
              if (this._useCache()) {
                this.cache["niveau"][this.idCache][this.structid].data = this.niveauColl;
                this.cache["niveau"][this.idCache][this.structid].fetching = false;
                this.cacheEventsModel.trigger("niveau" + this.idCache + this.structid);
              }
            }
            callback(this.niveauColl.models);
          },
          error: () => {
            callback([]);
          }
        });
      }
    }
  }

  _useCache(): boolean {
    if (!CWSTR.isBlank(this.idCache)) {
      return true;
    }
    return false;
  }

  /**
   * Sets the readonly flag of the component
   */
  setReadOnly(readOnly: boolean): void {
    this.readonly = readOnly;
    this.render();
    if (this.modeComplet === true) {
      if (readOnly === true) {
        this.$el.find(".phx-cw-chemin-button").hide();
      } else {
        this.$el.find(".phx-cw-chemin-button").show();
      }
    }
  }

  /**
   * Sets the tooltips of the elements of the component
   */
  _setTooltips(event: JQueryEventObject, ui: any): void {
    this._getLevels((levels: any) => {
      for (let i = 0; i < ui.content.length; i++) {
        const tooltip = $(ui.content[i].label).tooltip({ content: "" });

        if (tooltip.length > 1) {
          const txt = this._tooltipText(ui.content[i], levels, true);

          tooltip.tooltip("option", "content", txt);
          ui.content[i].label = tooltip;
        }
      }
    });
  }

  /**
   * Returns the tooltip text of an element of the component
   */
  _tooltipText(model: any, levels: any, dateonly?: boolean): string {
    let txt = "";
    let header = "";

    if (model.attrs.datedebut > this.datedebut && model.attrs.datefin >= this.datefin) {
      header = i18n.t('common:chemin.sitpartdu', { "0": CWTYPE.DATE.format(model.attrs.datedebut) });
    }
    if (model.attrs.datedebut <= this.datedebut && model.attrs.datefin < this.datefin) {
      header = i18n.t('common:chemin.sitjusq', { "0": CWTYPE.DATE.format(model.attrs.datefin) });
    }
    if (model.attrs.datedebut > this.datedebut && model.attrs.datefin < this.datefin && model.attrs.datedebut !== model.attrs.datefin) {
      header = i18n.t('common:chemin.sitduau', { "0": CWTYPE.DATE.format(model.attrs.datedebut), "1": CWTYPE.DATE.format(model.attrs.datefin) });
    }
    if (model.attrs.datedebut > this.datedebut && model.attrs.datefin < this.datefin && model.attrs.datedebut === model.attrs.datefin) {
      header = i18n.t('common:chemin.sitdatedu', { "0": CWTYPE.DATE.format(model.attrs.datefin) });
    }
    if (header) {
      txt = "<span>" + header + "</span><br/>";
    }
    if (!dateonly) {
      txt += "<table style=\"width:500px\">";
      let marginLeft = 0;
      let tabAux = "<span class=\"ui-icon\" style=\"display:inline-block; background:none; padding:0; margin-left:10px\"></span>";

      if (model.attrs.parents && model.attrs.parents.length > 0) {
        let niveau = "";
        let tab = "";
        let j = 0;

        // paint chemin when node is not a root node
        for (j = 0; j < model.attrs.parents.length; j++) {
          // tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";
          tab = "<span style='display: inline-block; padding: 0; margin-right: 2px; margin-left: " + marginLeft + "px '>" + UTILS.getSVGIcon('fleche_droite_arrondie', '', 12) + "</span>";

          if (j === 0) {
            txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + model.attrs.parents[j].libelle + "(" + model.attrs.parents[j].codef + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + i18n.t('common:chemin.structure') + "</td></tr>";
          } else {
            niveau = "";
            if (j - 1 < levels.length) {
              niveau = levels[j - 1].get("libelle");
            }
            txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + tab + model.attrs.parents[j].libelle + "(" + model.attrs.parents[j].codef + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + niveau + "</td></tr>";
            marginLeft += 10;
          }
        }
        // tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";
        tab = "<span style='display: inline-block; padding: 0; margin-right: 2px; margin-left: " + marginLeft + "px '>" + UTILS.getSVGIcon('fleche_droite_arrondie', '', 12) + "</span>";
        niveau = "";
        if (j - 1 < levels.length) {
          niveau = levels[j - 1].get("libelle");
        }
        txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + tab + model.attrs.libelle + "(" + model.attrs.codef + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + niveau + "</td></tr>";
      } else {
        // paint chemin when node is a root node
        tabAux = "<span class=\"ui-icon\" style=\"display:inline-block; background:none; padding:0; margin-left:10px\"></span>";
        txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + model.attrs.libelle + "(" + model.attrs.codef + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + i18n.t('common:chemin.structure') + "</td></tr>";
      }
      txt += "</table>";
    }
    return txt;
  }

  /**
   * Gets the render function for every element of the list.
   */
  _getListRenderer(initParent: number): (arg1: any) => string {
    const result = (model: any): string => {
      return this._showTooltip(model) + "<span class=\"phx-chemin-option\">" + this._getChemin(model, initParent) + "</span>";
    };
    return result;
  }

  /**
   * Gets the render function depending on the attribute listRenderStyle value
   */
  _getRenderer(): (arg1: any) => string {
    let result = null;

    switch (this.listRenderStyle) {
      case 1:
        result = (item: any): string => {
          return item.get("libelle") + " (" + item.get("codef") + ")";
        };
        break;
      case 2:
        result = (item: any): string => {
          return item.get("codef") + " (" + item.get("libelle") + ")";
        };
        break;
      case 3:
        result = (item: any): string => {
          return item.get("libelle");
        };
        break;
      case 4:
        result = (item: any): string => {
          return item.get("codef");
        };
        break;
      default:
        break;
    }
    return result;
  }

  /**
   * Gets the render function depending on the attribute listRenderStyle value and the element level
   */
  _getAttrsRenderer(): (arg1: any) => string {
    let result = null;
    let lResultaux = "";

    switch (this.listRenderStyle) {
      case 1:
        result = (item: any): string => {
          lResultaux = "";
          if (!CWSTR.isBlank(item)) {
            if (item.niveau === 0) {
              lResultaux = i18n.t('common:chemin.structurecomp');
            } else {
              lResultaux = item.libelle + " (" + item.codef + ")";
            }
          }
          return lResultaux;
        };
        break;
      case 2:
        result = (item: any): string => {
          lResultaux = "";
          if (!CWSTR.isBlank(item)) {
            if (item.niveau === 0) {
              lResultaux = i18n.t('common:chemin.structurecomp');
            } else {
              lResultaux = item.codef + " (" + item.libelle + ")";
            }
          }
          return lResultaux;
        };
        break;
      case 3:
        result = (item: any): string => {
          lResultaux = "";
          if (!CWSTR.isBlank(item)) {
            if (item.niveau === 0) {
              lResultaux = i18n.t('common:chemin.structurecomp');
            } else {
              lResultaux = item.libelle;
            }
          }
          return lResultaux;
        };
        break;
      case 4:
        result = (item: any): string => {
          lResultaux = "";
          if (!CWSTR.isBlank(item)) {
            if (item.niveau === 0) {
              lResultaux = i18n.t('common:chemin.structurecomp');
            } else {
              lResultaux = item.codef;
            }
          }
          return lResultaux;
        };
        break;
      default:
        break;
    }
    return result;
  }

  /**
   * Gets the render function for an element of the list with the path of the element
   */
  getCheminRenderer(item: any): () => string {
    let result = null;

    if (!CWSTR.isBlank(item)) {
      switch (this.listRenderStyle) {
        case 1:
          result = (): string => {
            return item.get("libelle") + " (" + item.get("codef") + ")";
          };
          break;
        case 2:
          result = (): string => {
            return item.get("codef") + " (" + item.get("libelle") + ")";
          };
          break;
        case 3:
          result = (): string => {
            return item.get("libelle");
          };
          break;
        case 4:
          result = (): string => {
            return item.get("codef");
          };
          break;
        default:
          break;
      }
    }
    return result;
  }

  /**
   * Gets the initial level from the list
   */
  _getInitialNiveau(collection: any): number {
    let result = 0;
    let minParents = 0;
    let first = true;

    if (!CWSTR.isBlank(collection) && collection.models && collection.models.length > 1) {
      let end = false;

      for (let i = 0; i < collection.models.length; i++) {
        if (collection.models[i].get("parents").length < minParents && !first) {
          minParents = collection.models[i].get("parents").length;
        } else if (first === true) {
          first = false;
          minParents = collection.models[i].get("parents").length;
        }
      }
      // eslint-disable-next-line no-unmodified-loop-condition
      for (let j = 1; j < minParents && minParents > 1 && !end; j++) {
        let lastParent = null;

        for (let k = 0; k < collection.models.length && !end; k++) {
          if (CWSTR.isBlank(lastParent)) {
            lastParent = collection.models[k].get("parents")[j];
          } else if (collection.models[k].get("parents")[j].code !== lastParent.code) {
            result = j;
            end = true;
          }
        }
        if (!end) {
          result = j + 1;
        }
      }
    }
    return result;
  }

  /**
   * Sets the structure id of the component
   */
  setStructId(structId: string): void {
    this.params.structid = structId;
    this.$el.empty();
    this.render();
  }

  /**
   * Paints the view of the selecteur de chemin component
   */
  render(): CWSelecteurCheminView {
    if (!this.plainText) {
      $(this.el).html(this.template({ UTILS: UTILS }));
      $(this.el).find(".phx-chemin-input").prop("viewRef", (this as any));
      // instantiate/configure component
      if (CWSTR.isBlank(this.view)) {
        $(this.el).find(".phx-cw-chemin-button").remove();
      }
      $(this.el).find(".phx-chemin-input").removeClass("phx-chemin-input").addClass(this.name + " phx-chemin-input form-control");
      $(this.el).find(".chemin-error-container").css("display", "table");
      $(this.el).find(".chemin-error-container").removeClass("chemin-error-container").addClass(this.name + "-error-container");

      if (!this.readonly) {
        const lObjEl = this.$el;
        const cheminInput = lObjEl.find(".phx-chemin-input");

        this.appended = false;
        this.input = cheminInput.autocomplete({
          minLength: this.minSearchLength,
          source: (request: any, response: any) => {
            const term = request.term;

            //Delete current tooltip
            cheminInput.tooltip({ content: "" });
            cheminInput.trigger("mouseout");
            if (this.coll) {
              this.coll.params = "";
              this.coll.pagination.size = this.maxResultItems;
              this.coll.search = "&search=" + term;
              if (!CWSTR.isBlank(this.params.context) && this.params.context.ctxStructureSelpop === true) {
                this.coll.params = {};
                this.coll.params.selectpopu = true;
              }
              this.coll.fetch({
                success: () => {
                  const collection = new AutocompleteColl();
                  const initParent = this._getInitialNiveau(this.coll);

                  collection.itemRenderer = this._getListRenderer(initParent);

                  // add message to list if more results exists
                  if (this.coll.totalRecords > this.maxResultItems) {
                    let message = "";
                    let info = null;

                    if (this.modeComplet === true) {
                      message = i18n.t('messages:GL_1007');
                    } else {
                      message = i18n.t('messages:GL_1006');
                    }
                    info = new ReadOnlyModel({ code: null, libelle: message });
                    collection.add([info.toJSON()], { parse: true });
                  } else {
                    collection.add(this.coll.toJSON(), { parse: true });
                    if (this.selection.length > 0 && this.multiselection !== true) {
                      this.clearSelection()
                    }
                  }
                  //order the collection
                  collection.comparator = (model: any): any => {
                    if (model.get("attrs").libelle === i18n.t('common:chemin.structurecomp')) {
                      return "!";
                    } else {
                      const initParent = this._getInitialNiveau(this.coll);
                      return this._getChemin(model, initParent);
                    }

                  };
                  collection.sort();
                  response(collection.toJSON());
                }
              });
            }
          },
          position: {
            my: "left top",
            at: "left bottom",
            //of : $(this.el) //Without this option the menu is aligned correctly
            collision: "flipfit"
          },
          open: (): void => {
            const menu = $(cheminInput).data("ui-autocomplete").menu;

            // menu.activeMenu.addClass("phx-chemin-autocomplete-menu");
            menu.activeMenu.addClass("phx-chemin-autocomplete-menu");
            $('.ui-autocomplete').css('width', 'auto');
            menu.activeMenu.addClass("phx-selecteur-chemin-autocomplete-menu c-panneauMenu c-panneauMenu--noIcon");
            this._registerOneWheelEvent();
          },
          close: (): boolean => {
            $(document).off("wheel." + this.cid);
            $(document).off("mousedown." + this.cid);
            (cheminInput as any).searching = false;
            $(cheminInput).data('ui-autocomplete').term = null;
            // if (this.cache["current"] && this.autocompleteMode === true && (this as any)._setItem) {
            //   (this as any)._setItem(this.cache["current"].attrs);
            // }

            // if (this.multiselection === true && (this as any)._setInputValue !== undefined) {
            //   (this as any)._setInputValue("");
            // }
            return false;
          },
          create: () => {
            const menu = $(cheminInput).data("ui-autocomplete").menu;

            $(menu.activeMenu).css("position", "fixed");
            $(cheminInput).data('ui-autocomplete')._resizeMenu = (): void => {
              let tabContanerHeight = 0;
              const limitContainer = $("#phx-container");//Get the limit container

              if (this.appended === false) {
                //AppendTo option in order to let jquery recalculate z-index for each element
                $(cheminInput).autocomplete("option", "appendTo", null);
                this.appended = true;
              }
              $(".ui-tabs-nav").each(() => {
                // if (tab_contaner_height < $(this)[0].offsetHeight) {
                //   tab_contaner_height = $(this)[0].offsetHeight;
                // }
                if (tabContanerHeight < $(cheminInput)[0].offsetHeight) {
                  tabContanerHeight = $(cheminInput)[0].offsetHeight;
                }
              });

              //if (this.element[0] && $(this.element[0]) && limit_container && $(this.element[0]).offset()) {
              if (cheminInput[0] && $(cheminInput[0]) && limitContainer && $(cheminInput[0]).offset()) {
                const scrollTop = $(window).scrollTop(),
                  scrollBot = scrollTop + $(window).height(),
                  elTop = limitContainer.offset().top,
                  elBottom = elTop + limitContainer.outerHeight(),
                  visibleTop = elTop < scrollTop ? scrollTop : elTop,
                  visibleBottom = elBottom > scrollBot ? scrollBot : elBottom;

                menu.element[0].style.maxHeight = ""; //set default heiht

                const distanceFromInputToTopContainer = $(cheminInput[0]).offset().top - (limitContainer.offset().top + tabContanerHeight);
                let distanceFromInputToButtom = (visibleBottom - visibleTop - tabContanerHeight) - distanceFromInputToTopContainer;
                const heightOfSelectDespl = menu.element[0].offsetHeight;
                const heightOfTheInput = cheminInput[0].offsetHeight;

                distanceFromInputToButtom -= heightOfTheInput;

                if (distanceFromInputToButtom < heightOfSelectDespl) {
                  if (distanceFromInputToButtom > distanceFromInputToTopContainer) {
                    let maxHeight = distanceFromInputToButtom - 15;
                    if (maxHeight < 20) {
                      maxHeight = 20;
                    }
                    menu.element[0].style.maxHeight = maxHeight + "px";
                  } else {
                    menu.element[0].style.maxHeight = (distanceFromInputToTopContainer - 15) + "px";
                  }
                }
              }
            };
          }
        });
        // color search term in result list
        $(this.input).data("ui-autocomplete")._renderItem = (ul: any, item: any): JQuery => {
          this.term = $(cheminInput).data("ui-autocomplete").term;
          const keywords = $.trim(this.term);
          let output = item.label;
          let output2 = "";

          try {
            if (item.label[1] && item.label[1].textContent) {
              output = item.label[1].textContent.replace(new RegExp("(" + keywords + ")", "gi"),
                '<span class="phx-searched-char-sequence">$1</span>');
              output2 = item.label[0];
            } else {
              output = item.label.replace(new RegExp("(" + keywords + ")", "gi"),
                '<span class="phx-searched-char-sequence">$1</span>');
            }
          } catch (err) {
            //nothing
          }

          if (CWSTR.isBlank(item.attrs.id)) {
            return $("<li>").addClass("ui-state-disabled").html('<span class="phx-label-style phx-chemin-moreitems-info">' + item.label + '</span>').appendTo(ul);
          }
          return $("<li>").append($("<a>").html(output2).append(output)).appendTo(ul);
        };
        if (!CWSTR.isBlank(this.keepOldId)) {
          this.input.attr("id", this.keepOldId);
        }

      } else {
        this.$el.find(".phx-chemin-input").attr("readonly", "true");
        const fieldset = this.$el.find(".phx-chemin-wrap");
        CWFORMS.setInputFieldReadonly(fieldset, this.name, true);
      }

      //apply width to the component wrapper
      this.$el.find(".phx-chemin-wrap").css("width", this.fieldWidth);

      //apply width class to the input and remove button if not complete mode
      if (this.modeComplet) {
        if (String(this.fieldWidth).indexOf("%") === -1) {
          this.$el.find(".phx-chemin-input").css("width", parseInt(this.fieldWidth, 10) - 22 + "px");
        } else {
          this.$el.find(".phx-chemin-input").css("width", "calc(" + this.fieldWidth + " - 22px)");
        }
      } else {
        $(this.el).find(".phx-cw-chemin-button").remove();
        this.$el.find(".phx-chemin-input").css("width", "calc(100% - 4px)");
      }

      $(this.el).find(".phx-chemin-input").tooltip({ content: "" });

      if (!this.multiselection) {
        this.$el.find(".phx-chemin-selection");
      } else {
        this.$el.find(".phx-chemin-selection").css("display", "inherit");
      }
    }
    return this;
  }

  /**
   * Close autocomplete dropdown when scrolling outside.
   */
  _registerOneWheelEvent(): void {
    $(document).one("wheel." + this.cid, (event: JQueryEventObject) => {
      this.shouldCloseMenu = false;

      // wheel on the list
      try {
        if ($(event.target).attr("id") === $(this.input).autocomplete("widget").attr("id") || $(this.input).autocomplete("widget").find(event.target).length > 0) {
          this._registerOneWheelEvent();
          // return;
        } else if ($(this.input).autocomplete("widget").find(event.target).length === 0) {
          if ($(this.input).autocomplete("widget").is(":visible")) {
            $(this.input).autocomplete("close");
            if (CWSTR.isBlank(this.currentCode)) {
              this._paintItems();
            }
          }
          this.shouldCloseMenu = false;
        }
      } catch (e) {
        CWLOG.error("Autocomplete__registerOneWheelEvent: " + e);
      }
    }, null);
  }

  /**
   * Clears the current selection of the component
   */
  clearSelection(): void {
    const $input = this.$el.find(".phx-chemin-input");

    this.selection.reset();
    if ($input.data('ui-tooltip')) {
      $input.tooltip('destroy');
    }
    this._paintItems();
  }

  // item is a backbone model representing a chemin structure
  /**
   * Adds a value to the component
   */
  addValue(item: any, callback?: (arg1: any) => any): void {
    let itemid = "";
    let response: any;

    if (!CWSTR.isBlank(item.get("elementCode"))) {
      itemid = item.get("structid") + "," + item.get("date") + "," + item.get("elementCode");
    } else if (!CWSTR.isBlank(item.get("chemin"))) {
      itemid = item.get("structid") + "," + item.get("date") + "," + item.get("chemin");
    }
    if (this._useCache() && !CWSTR.isBlank(this.cache["item"][this.idCache]) && !CWSTR.isBlank(this.cache["item"][this.idCache][itemid])) {
      //cache is using and 2nd time
      if (this.cache["item"][this.idCache][itemid].fetching) {
        this.listenToOnce(this.cacheEventsModel, "item" + this.idCache + itemid, () => {
          //Paint items when event is triggered
          response = this.cache["item"][this.idCache][itemid].data;
          this.selection.add(response.toJSON(), { parse: true });
          this._paintItems();
          if (callback) {
            callback(response);
          }
        });
      } else {
        //Paint items when data is already loaded
        response = this.cache["item"][this.idCache][itemid].data;
        this.selection.add(response.toJSON(), { parse: true });

        this._paintItems();
        if (callback) {
          callback(response);
        }
      }
    } else {
      if (this._useCache()) {
        //First time using cache initialize object
        this.cache["item"][this.idCache][itemid] = { fetching: true };
      }
      item.fetch({
        success: (resp: any) => {
          if (this._useCache()) {
            this.cache["item"][this.idCache][itemid].data = resp;
            this.cache["item"][this.idCache][itemid].fetching = false;
            this.cacheEventsModel.trigger("item" + this.idCache + itemid);
          }
          if (resp && !CWSTR.isBlank(resp.get("structid"))) {
            this.selection.add(resp.toJSON(), { parse: true });
            this._paintItems();
          }
          this.attributesCheminSelected = resp.get("0");
          if (callback) {
            callback(resp);
          }
        }
      }, this);
    }
  }

  /**
   * Gets the selected values
   */
  getValues(): any {
    return this.selection;
  }

  /**
   * Paints every element of the selection
   */
  _paintItems(): void {
    if (this.multiselection) {
      $(this.el).find(".phx-chemin-selection").empty();
      const tmpItemViews: { [key: string]: any }[] = [];
      const selectionModels = this.selection.models;

      _.each(this.selection.models, (value: { [key: string]: any }) => {
        const itemView = new SelecteurCheminResultItemView({ "model": value, "readonly": this.readonly });

        this.once("loadedMultipleTooltip", (data: { [key: string]: any }) => {
          tmpItemViews.push({
            "itemView": data.item,
            "tooltip": data.tooltip
          });

          if (tmpItemViews.length === selectionModels.length) {
            this.trigger("generatedTooltip:selectourChemin", {
              multiple: true,
              "tooltipsArray": tmpItemViews
            });
          }
        });
        $(this.el).find(".phx-chemin-selection").append(itemView.render().el);
        if (value.tooltip) {
          $(itemView.el).tooltip({ content: value.tooltip });
          this.trigger("loadedMultipleTooltip", {
            "item": itemView,
            "tooltip": value.tooltip
          });
        } else {
          // add tooltip
          this._getLevels((levels: any) => {
            value.tooltip = this._tooltipText(value.attributes, levels);
            $(itemView.el).tooltip({ content: value.tooltip });
            this.trigger("loadedMultipleTooltip", {
              "item": itemView,
              "tooltip": value.tooltip
            });
          });
        }
      });
      if (this.selection.models.length === 0) {
        this.$el.find(".phx-chemin-selection").css("display", "inherit");
      } else {
        this.$el.find(".phx-chemin-selection").css("display", "table");
      }
      if (this.$el.find(".phx-chemin-selection").length > 0) {
        if (CWSTR.isBlank(this.params.paintItemsPosition)) {
          this.$el.find(".phx-chemin-selection").position({
            my: "left top",
            at: "left bottom",
            collision: "flip flip",
            of: this.$el.find(".phx-chemin-wrap")
          });
        } else {
          const filterLines = $('.cw-filtres-detail .cw-definitionfiltres-simple-line').toArray();
          let selectionLine = null;

          filterLines.forEach((element: any) => {
            selectionLine = $(element).find('.phx-chemin-selection');
            if (selectionLine.length > 0) {
              let customPosition = null;

              $(element).find('.cw-definitionfiltres-simple-btnRemove').position({
                of: $(element).find('.phx-chemin-wrap'),
                my: "left top",
                at: "right top",
                collision: "flip flip"
              });
              if (this.params.paintItemsPosition.customCheminPosition) {
                customPosition = this.params.paintItemsPosition.customCheminPosition;
              } else {
                customPosition = this.params.paintItemsPosition;
              }
              $(selectionLine).find(customPosition.targetParent).position({
                of: $(element).find(customPosition.targetElement),
                my: customPosition.my,
                at: customPosition.to,
                collision: customPosition.collision
              });
            }
          });
        }
      }
    } else {
      if (this.selection.length > 0) {
        // render item in input field (no multiselection)
        if (this.plainText) {
          this._getLevels((levels: any) => {
            const txt = this._tooltipText(this.selection.at(0).attributes, levels, null);
            let headerRight = null;
            let headerPop = null;

            this.$el.attr("title", "");
            this.$el.tooltip({ content: txt });
            this.$el.text(this.selection.at(0).get("label"));
            this.trigger("generatedTooltip:selectourChemin", {
              multiple: false,
              "tooltip": txt,
              selection: this.selection
            });

            headerRight = $(this.el).parents().find(".phx-right-menu").width();
            headerPop = $(this.el).parents().find(".phx-populations-menu").width();

            if (window.innerWidth <= 1920) {
              if (headerPop > headerRight) {
                $(this.el).parents().find(".phx-populations-menu").css("width", headerRight + "px");
              } else {
                $(this.el).parents().find(".phx-populations-menu").css("width", "auto");
                $(this.el).parents().find(".phx-population-menu-header").prop("title", "");
              }
            } else {
              $(this.el).parents().find(".phx-populations-menu").css("width", "auto");
            }
          });
        } else {
          this.$el.find(".phx-chemin-input").val(this.selection.at(0).get("label"));
          this.$el.attr("title", "");
          if (this.readonly) {
            // add tooltip
            this._getLevels((levels: any) => {
              if (this.selection && this.selection.at(0)) {
                const txt = this._tooltipText(this.selection.at(0).attributes, levels, null);

                this.$el.find("input." + UTILS.escapeJQueryString(this.name)).tooltip({ content: txt });
                this.trigger("generatedTooltip:selectourChemin", {
                  multiple: false,
                  "tooltip": txt
                });
              }
            });
          } else {
            // add tooltip
            this._getLevels((levels: any) => {
              if (this.selection && this.selection.at(0)) {
                const txt = this._tooltipText(this.selection.at(0).attributes, levels, null);

                //Initialize tooltip to prevent errors when tooltip is not initialized
                this.$el.find(".phx-chemin-input").tooltip({ content: txt });
                this.$el.find(".phx-chemin-input").tooltip("option", "content", txt);
                this.trigger("generatedTooltip:selectourChemin", {
                  multiple: false,
                  "tooltip": txt
                });
              }
            });
          }
        }
      }
    }
  }

  /**
   * Selects a new element in the component
   */
  _selectItem(event: JQueryEventObject, ui: any): boolean {
    this._addItem(ui.item.attrs);
    return false;
  }

  /**
   * Changes a item in the current selection
   */
  _changeItem(event: JQueryEventObject): void {
    const value = $(event.target).val();
    const $input = $(this.el).find(".phx-chemin-input");

    if (this.multiselection === false && (CWSTR.isBlank(value) || this.selection.length === 0) && this.addItemCallback) {
      const model = new this.coll.model;

      this.addItemCallback(model.attributes, this);
      this.selection.reset();
    }
    if ($input.data("ui-tooltip") && this.selection.length === 0) {
      $input.tooltip("option", "content", "");
    }
  }

  /**
   * Adds a new element to the selection
   */
  _addItem(attrs: { [key: string]: any }): boolean {
    //Case Traversal component population
    if (this.params && this.params.context && this.params.context.ctxStructureSelpop) {
      if (attrs.selectionnable === false) {
        return false;
      }
    }
    if (this.multiselection === true) {
      if (attrs.niveau === 0 || (this.selection.length === 1 && this.selection.models[0].get("attrs").niveau === 0)) {
        this.selection.reset();
      }

      // clear input field and set focus to it.
      $(this.el).find(".phx-chemin-input").val("");
      $(this.el).find(".phx-chemin-input").focus();
    } else {
      this.selection.reset();
    }
    // add item to selection list
    if (!this.selection.get(attrs.id)) {
      if (attrs.niveau === 0) {
        const rootAttrs = _.clone(attrs);

        rootAttrs.libelle = i18n.t('common:chemin.structurecomp');
        this.selection.add([rootAttrs], { parse: true });
        if (this.addItemCallback) {
          this.addItemCallback(rootAttrs);
        }
      } else {
        this.selection.add([attrs], { parse: true });
        if (this.addItemCallback) {
          this.addItemCallback(attrs, this);
        }
      }
      // paint items
      this._paintItems();
      // Notify edition
      $(this.el).find(".phx-chemin-input").trigger("change");
    }
    // retur false to prevent bubbling of event
    return false;
  }

  /**
   * Deletes an element from the list of selections of the component
   */
  _removeItem(model: Backbone.Model): void {
    // remove item from collection
    this.selection.remove(model);
    if (this.removeItemCallback) {
      this.removeItemCallback(model.attributes.attrs);
    }
    // repaint
    this._paintItems();
    // Notify edition
    $(this.el).find(".phx-chemin-input").trigger("change");
  }

  /**
   * Opens the detail popup view
   */
  _openDetailPopup(): void {
    if (this.popup !== undefined) {
      this.popup.remove();
      this.popup = null;
    }
    this.popup = new CWDialogPopupView({
      view: this.view,
      height: this.height,
      width: this.width,
      className: this.params.ecran,
      viewData: {
        parameters: this.params,
        listRenderStyle: this.listRenderStyle,
        currentSelection: this.selection
      }
    });
    this.popup.setHeight(this.height);
    this.popup.setWidth(this.width);
    this.popup._eventsBB = (event: any, value: any): void => {
      if (event === "response") {
        this.popupResponse = value;
      }
    };

    // set buttons
    const btn = [{
      text: i18n.t('common:apply'),
      btnClass: 'btn btn-primary btn-withIcon bt-col-blue',
      icon: UTILS.getSVGIcon("valider", "c-panneauMenu__tickIcon", 16, null),
      click: (): void => {
        let populationSelector = false;
        let checkedDescendents = false;

        if (this._isSelectorPopulationContext()) {
          populationSelector = true;
          checkedDescendents = $(".phx-selecteur-chemin-dialog input.populationDescendence").is(":checked");
        }
        $(this.popup.dialog).dialog("close");
        if (populationSelector === true) {
          this._popupResponseCallback("y", checkedDescendents);
        } else {
          this._popupResponseCallback("y", null);
        }
      }
    }, {
      text: i18n.t('common:cancel'),
      btnClass: 'btn btn-secondary btn-withIcon',
      icon: UTILS.getSVGIcon("croix", "", 16, null),
      click: (): void => {
        $(this.popup.dialog).dialog("close");
        this._popupResponseCallback("n", null);
      }
    }];

    // buttons
    this.popup.setButtons(btn, "cwDialog-buttons");
    this.popup._setTitle(this.title);

    try {
      this.popup.open();
    } catch (err) {
      CWLOG.debug("chemin: " + err);
    }
  }

  /**
   * Callback function to be executed after close the detail popup view
   */
  _isSelectorPopulationContext(): boolean {
    let populationSelector = false;

    if (this.params && this.params.context && this.params.context.ctxStructureSelpop === true) {
      populationSelector = true;
    }
    return populationSelector;
  }

  _popupResponseCallback(action: string, populationDescendence: any): void {
    if (action === "y" && !CWSTR.isBlank(this.popupResponse)) {
      let list = this.popupResponse;

      if (this.popupResponse instanceof Backbone.Collection) {
        list = this.popupResponse.models;
      } else {
        if (!_.isArray(list)) {
          list = list ? [list] : [];
        }
      }
      this.clearSelection();
      _.each(list, (item: Backbone.Model) => {
        this._addItem(item.get("attrs"));
      }, this);

      if (this._isSelectorPopulationContext()) {
        this._setPopulationDescendence(populationDescendence);
      }
    }
    $(this.el).find(".phx-chemin-input").focus();
  }

  _setPopulationDescendence(selected: boolean): void {
    $(".phx-populations-detail-structure-simple input.populationDescendence").prop("checked", selected);
  }

  // resize result list on open
  /**
   * Opens a new list of autocompletion results
   */
  _openlist(): void {
    const parentWidth = $(this.el).css("width");

    $(".phx-chemin-input", this.el).autocomplete("widget").css("width", parentWidth);
  }

  /**
   * Cleans the current selection
   */
  clean(): void {
    this.$el.find(".phx-chemin-input").val("");
    this.selection.reset();
  }

  /**
   * Method to be executed when an element of the list is focused
   */
  _focused(event: JQueryEventObject, ui: any): void {
    // reset tooltip on focus. (maybe a bug in jquery is removing the tooltip when field receive focus)
    // adding again the attribute "title" is enougth to have the tooltip work again.
    if (ui && ui.tooltip && CWSTR.isBlank($(".phx-chemin-input", this.el).attr("title"))) {
      $(".phx-chemin-input", this.el).attr("title", "");
    }

    if (event.type === "tooltipopen" && event.originalEvent && event.originalEvent.type === "focusin") {
      $(".phx-chemin-input", this.el).tooltip("close");
    }
  }
}
