import * as Backbone from 'Backbone';
import _ from 'underscore';
import bsitpartBriqueSituationTPL from '../briqueSituation.tpl.html';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSituationModel } from '../models/cwSituation.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { NAV } from 'utils/nav.js';
import { objs } from 'src/objectsRepository';
import { STYLE } from 'utils/style.js';
import { UTILS } from 'utils/utils.js';

/**
 * View of Brique 'Situations particulières'
 */
export class CWSituationView extends Backbone.View<Backbone.Model> {
  //REVIEW: 	i18n : phx.i18n.common.bsitpart,

  private _maxLines: number;

  usePopulation: boolean;
  template: (params: any) => string;
  styleBold: string;
  styleNormal: string;
  parent: any;
  model: any;
  habContext: CWHabilitationContext;
  paramMaxElements: string;
  /**
   * Constructor of this class
   */

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "click .table_BriqueSituationsParticulieres_link > span": "_linkToValidation"
    }, options.events);
    super(options);
    this._maxLines = 5;
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.template = bsitpartBriqueSituationTPL;

    this.styleBold = "phx-bold-input-style";
    this.styleNormal = "phx-input-style";
    this.parent = null;

    this.model = new CWSituationModel();
    this.model.usePopulation = this.usePopulation;

    this.habContext = new CWHabilitationContext({
      onglet: "bsitpart",
      foncCour: "COL_ANO.V", // eslint-disable-line
      natGest: "" // eslint-disable-line
    });
    this.model.setHabContext(this.habContext);

    if (options && options.parent) {
      this.parent = options.parent;
    }

    // param divers to control max lines in the "brique."
    // Nombre maximum d'éléments à charger dans une brique d'accueil
    this.paramMaxElements = GLOBAL_DATA.paramDivers.get("BRCnbElt").get("valeur");
  }

  /**
   * Renders this brique
   */
  render(): CWSituationView {
    const json = { 'i18n': i18n };
    this.$el.html(this.template(json));
    this.$el.attr("aria-busy", "true");
    this.$el.attr("aria-live", "polite");
    this.$el.find(".table_BriqueSituation").html(i18n.t('common:bsitpart.noSituationAffiche'));

    return this;
  }

  checkHabilation(): boolean {
    return CWHABILITATION.canView("COL_ANO.V");

  }

  /**
   * Refreshes all information inside the brique fetching again information
   */
  refreshBrique(): CWSituationView {

    if (CWHABILITATION.canView("COL_ANO.V")) {
      this.$el.find(".table_BriqueSituation").html(i18n.t('common:bsitpart.noSituationAffiche'));

      this.model.fetch({
        success: (fresh: any) => {
          this.parent.setTitle(i18n.t('common:bsitpart.situation') + " (" + fresh.get("total") + ")");
          this.renderTable();
        }
      });

      this.delegateEvents();
    }

    return this;
  }

  /**
   * Render the table inside the brique
   */
  renderTable(): void {
    //render the collection is in 'situation'
    const coll = this._renderCollection(this.model.get("situation"));

    if (this.model.get("situation").length > 0) {
      this.$el.find(".table_BriqueSituation").removeClass("noSituation");

      //create the columns for the table
      const tblColumns = [{
        //     property: "type",
        //     bootCol: "col-1 ",
        //     bootPad: "rowCellFirst ",
        //     bootMar: " "
        // }, {
        property: "date",
        bootCol: "col-5 ",
        bootPad: "rowCellFirst ",
        bootMar: " "
      }, {
        property: "libelle",
        bootCol: "col-7 ",
        bootPad: "rowCell rowCellLast ",
        bootMar: " "
      }];
      //create the table witdh the collection data and collection titles
      const table = this._createTableOfColl(coll, tblColumns, "tableBriqueSituation");

      this.$el.find(".table_BriqueSituation").html(table);

      const maxElemSize = coll.length;

      if (parseInt(this.paramMaxElements, 10) < maxElemSize) {
        this.parent.setTitle(i18n.t('common:bsitpart.situation') + " (" + this.paramMaxElements + ")");
      }

      let rowHeight = this.$el.find(".table_BriqueSituation .row").outerHeight();

      //Value for Default
      if (rowHeight < 5) {
        rowHeight = 19;
      }

      if (rowHeight !== null && this.parent !== null) {
        this.parent.setContentHeight(rowHeight * this._maxLines);
      }
    }
  }

  /**
   * Adds style to text
   */
  _textToStyle(txt: string, commentaire: string, heure: string, style: string): string {
    let newTxt = "";
    newTxt = "<span class='" + style + "'";
    if (commentaire !== "") {
      newTxt += " title='" + commentaire + "'";
    }
    newTxt += ">" + txt + "</span>";
    if (heure !== null) {
      newTxt += "<span class='cw-texteImportant'> (" + heure + ") </span>";
    }

    return newTxt;
  }

  /**
   * Prepares each model in the collection, adapting its attributes to html that will be used in table
   */
  _renderCollection(collection: { [key: string]: any }): { [key: string]: any } {
    _.each(collection, (obj: { [key: string]: any }): void => {
      //render the date
      const dateFormated = CWTYPE.DATE.format(obj.date, CWTYPE._getFormatByCode("DATE_A"));
      //obj.date = dateFormated;
      const matric = objs.appRt.workflow.authModel.get("matricule");
      let classLink = "";
      let style = "";

      // The link depends of the agenda's habilitations
      if (CWHABILITATION.canView("COL_AGHEBDO") === true) {
        classLink = "agenda/" + obj.date + "/" + matric + "/H";
      } else if (CWHABILITATION.canView("COL_AGANNU") === true) {
        classLink = "agenda/" + obj.date + "/" + matric + "/A";
      } else if (CWHABILITATION.canView("COL_AGMENS") === true) {
        classLink = "agenda/" + obj.date + "/" + matric + "/M";
      } else {
        style = "cursor:default;";
      }
      let htmlDate = "<span class='" + classLink + "'";
      htmlDate += !CWSTR.isBlank(style) ? " style='" + style + "'" : "";
      if (obj.calcul === "P") {
        htmlDate += " title='" + i18n.t('common:bsitpart.prevue') + "'";
      }
      htmlDate += ">" + dateFormated + "</span>";
      obj.date = htmlDate;

      //render the libelle
      let libelle = "";

      //render the heure
      const heureFormated = CWTYPE.HOUR_MINUTE.format(obj.heure).trim();
      if (obj.heure !== "0000") {
        obj.heure = heureFormated;
        libelle = this._textToStyle(obj.libelle, obj.commentaire, obj.heure, "cw-texteNormal ");
      } else {
        obj.heure = heureFormated;
        libelle = this._textToStyle(obj.libelle, obj.commentaire, null, "cw-texteNormal ");
      }

      obj.libelle = libelle;

      let className = "";
      let tooltip = "";
      const avertissementIcon = UTILS.getSVGIcon("avertissement", "", 24);
      if (!CWSTR.isBlank(obj.type)) {
        className = STYLE.getClassColorFromAnomalie(obj.type);
      }
      tooltip = this._getTitleFromAnomalie(obj.type);
      if (obj.type === "B" || obj.type === "P" || obj.type === "N") {
        obj.type = "<span class='" + className + " cw-anomalie cw-icon-situ' title='" + tooltip + "'>" +
          avertissementIcon +
          "</span>";
      }
    });
    return collection;
  }

  /**
   * Gets type of anomalie depending on Type of anomalie
   */
  _getTitleFromAnomalie(anocode: string): string {
    let title = "";
    switch (anocode) {
      case "B":
        title = i18n.t('common:bsitpart.bloqueante');
        break;
      case "P":
        title = i18n.t('common:bsitpart.persistante');
        break;
      case "N":
        title = i18n.t('common:bsitpart.non_bloqueante');
        break;
      default:
    }
    return title;
  }

  /**
   * Creates the HTML bootstrap of the table from the data
   */
  _createTableOfColl(coll: { [key: string]: any }, tblColumns: Array<any>, id: any): string {
    let table = "";
    let maxElemSize = coll.length;

    table = "<div id='" + id + "'>";
    //paint the data
    table += "<div class='container'>";
    if (Number(this.paramMaxElements) < maxElemSize) {
      maxElemSize = this.paramMaxElements;
    }
    for (let i = 0; i < maxElemSize; i++) {
      table += "<div class='row'>";
      for (let j = 0; j < tblColumns.length; j++) {
        let rowClass = "";
        if (tblColumns[j].property === "date" && coll[i].calcul === "C") {
          //When anotypeCal=C date is a link to agenda hebdomadaire
          rowClass = "table_BriqueSituationsParticulieres_link";
        }
        table +=
          "<div class='align-self-center " +
          tblColumns[j].bootCol +
          tblColumns[j].bootPad +
          tblColumns[j].bootMar +
          rowClass +
          "'>";
        if (tblColumns[j].property === "date") { // concaténation de l'icone avec la date pour rentrer dans le même bloc
          table += coll[i]["type"];
        }
        table += coll[i][tblColumns[j].property];
        table += "</div>";
      }
      table += "</div>";
    }
    table += "</div>";
    table += "</div>";
    return table;
  }

  /**
   * Opens vue journee panel at anomalie screen
   */
  _linkToValidation(event: any): void {
    let target = event.target;
    // click on icon get bad link
    if (!CWSTR.isBlank(target.nextElementSibling)) {
      target = target.nextElementSibling;
    }
    const ecran = target.className.split(" ")[0];

    if (ecran.indexOf("agenda/") !== -1) {
      NAV.navigate("coll", ecran, "uc", true, true);
    }
  }
}