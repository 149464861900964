import _ from 'underscore';
import { ComboBoxView2 } from 'core/components/combo/comboBoxView2.js';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWSTR } from './cwStr';
import { CWTYPE } from '../tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { UTILS } from './utils.js';

/**
 * Forms utilities
 */
export class CWFORMS {

  /** WIDGETS **/

  /**
   * Tooltip global configuration
   */
  static tooltip = {
    /**
     * Whether the tooltip should track (follow) the mouse.
     */
    track: true,

    position: {
      my: "left+10 top+10",
      at: "left bottom",
      collision: "flipfit"
    },
    /**
     * Method executed when a tooltip is opened.
     */
    open: function (event: JQuery.TriggeredEvent, ui: JQueryUI.TooltipUIParams): void {
      $(this).data("tooltip", true);
      if (event.originalEvent &&
        event.originalEvent.target &&
        $(event.originalEvent.target).hasClass("ui-state-error")) {
        $(ui.tooltip).addClass("ui-state-error");
      }
    },
    /**
     * Method executed when a tooltip is closed.
     */
    //ne pas enlever les valeurs "event" et "ui": ils sont utilisés dans autre lieu de l'application
    close: function (event?: JQuery.TriggeredEvent, ui?: JQueryUI.TooltipUIParams): void { //eslint-disable-line
      $(this).data("tooltip", false);
    },
    /**
     * Method executed to get the content of the tooltip from an element.
     */
    content: function (): string {
      return $(this).prop('title');
    }
  };

  /**
   * Show a red ball on the right of the labels associated to inputs with the
   * same name. See code for details.
   *
   */
  static showErrors(jQueryRootSelector: JQuery, fieldErrors: any, hold?: any): void {

    const $formErrors = jQueryRootSelector.find(".phx-formErrors");
    if (fieldErrors) {
      $formErrors.attr("role", "alert");
      //			$formErrors.attr("aria-live","assertive");
      const errorCount = CWFORMS._countErrors(fieldErrors);
      // for if have a 1 or much errors
      if (errorCount === 1) {
        $formErrors.html("1" + i18n.t('common:detectedError'));
      } else {
        $formErrors.html(errorCount + i18n.t('common:detectedErrors'));
      }
      //Errors for the reader
      const errorsDescription = CWSTR.extractObjectValues(fieldErrors);
      $formErrors.append($("<div>").css("position", "absolute").css("top", "-9999px").html(errorsDescription.join(",")));

      $formErrors.css("display", "block");
    } else {
      $formErrors.html("&nbsp;");
      $formErrors.css("display", "none");
    }


    jQueryRootSelector.find("label[for]").each((index, label): void => {
      const labelId = $(label).attr("for").replace(/[^a-zA-Z0-9._-]/g, "");
      let input = jQueryRootSelector.find("#" + labelId.replace(/\./g, "\\.") + ",." + labelId.replace(/\./g, "\\."));
      /*
      if(input && input.length>1){
        input = $(input[0]);
      }*/
      if (CWSTR.isBlank(input) || input.length <= 0) {
        // There is a label related to an input which don't exist.
        return;
      }
      const inputId = input.attr("id");
      let fieldName = input.attr("class").split(" ")[0];

      // CUSTOMER 143376 1) Due to different names between WS error field and html input defined
      //                 is necessary to change name of html field in order to set validaton
      //                 message for it (for saise.date could be "datedeb" or "datefin" errors)
      //				   assigning directly field name of error to labelId.
      //
      //				   For component evaluation, if is not executed so model validation works.
      let isBadgeage = false;
      if (fieldName === "saisie.date") {
        if (!fieldErrors.saisie) {
          fieldName = Object.keys(fieldErrors)[0]; // CUSTOMER 143376 Assign error variable from WS
        }

        isBadgeage = true;
      }

      // If composite object "inputname.code" not find a, error value will search for "inputname"
      const errorValue = CWSTR.getElValueFromObj(fieldErrors, fieldName) || CWSTR.getElValueFromObj(fieldErrors, fieldName.split(".")[0]);

      // CUSTOMER 143376 2) Let everything to its original state avoiding change method's flow.
      if (isBadgeage) {
        fieldName = "saisie.date";
      }

      // If hold attribute is true don't clean errors from the form
      if (!_.isString(errorValue) && $(input).hasClass("ui-state-error") && hold !== true) {
        // clear error message

        CWFORMS._cleanInputErrors(input, labelId, jQueryRootSelector, label);
      }

      if (_.isString(errorValue)) {
        // add error message
        input.removeClass("ui-state-error").removeAttr("aria-invalid");
        jQueryRootSelector.find("." + labelId.replace(/\./g, "\\.") + "-error").detach();
        input.addClass("ui-state-error").attr("aria-invalid", "true");

        if (input.parent('.input-group')) {
          input.parent('.input-group').addClass("ui-state-error").attr("aria-invalid", "true");
        }
        if (input.parent('.btn-group')) {
          input.parent('.btn-group').addClass("ui-state-error").attr("aria-invalid", "true");
        }

        if (!CWSTR.isBlank(errorValue)) {
          if (jQueryRootSelector.is("tr") === true || input.hasClass("phx-inline-error")) {
            $(label).addClass("griderror");
            $(label).attr("title", errorValue);
            //$(label).tooltip(new CwTooltip({ tooltipClass: "phx-grid-tooltip-error" }));
            $(label).tooltip(_.extend({}, CWFORMS.tooltip, { tooltipClass: "phx-grid-tooltip-error" }));
          } else {
            // error container defined in the template to
            // personalize position of the error message
            const errorContainer = jQueryRootSelector.find("." + fieldName.replace(/\./g, "\\.") +
              "-error-container");
            const errorDiv = $("<div></div>").addClass("phx-field-error " + labelId + "-error ui-state-error");

            //CUSTOMER 170849
            if (labelId === "pwd_confirm") {
              errorDiv.css("float", "left");
            }
            //							errorDiv.attr("role","alert");
            //							errorDiv.attr("aria-atomic","true");
            errorDiv.attr("id", inputId + "-error");

            errorDiv.html(errorValue);
            if (errorContainer.length === 0) {
              const referentielParent = input.parents(".phx-selecteur-referentiel");
              if (referentielParent.length > 0) {
                referentielParent.after(errorDiv);
              } else if (input.hasClass("phx-selecteur-activite-input") && input.next(".phx-selecteur-activite-button.ui-icon-search").length > 0) {
                // place the error just after the input field
                input.next(".phx-selecteur-activite-button.ui-icon-search").after(errorDiv);
              } else {
                //CUSTOMER 166240
                // Testing if field is date and has "datepicker" icon to place error after it
                if (input.hasClass("hasDatepicker") && input.next().hasClass("ui-datepicker-trigger")) {
                  input = input.next();
                }
                if (input.hasClass("hasDatepicker") && input.next().hasClass("input-group-append")) {
                  input = input.parent();
                }
                // place the error just after the input field
                if (input.parent(".input-group").length > 0) {
                  input.parent(".input-group").after(errorDiv);
                } else if (input.parent(".btn-group").length > 0) {
                  input.parent(".btn-group").after(errorDiv);
                } else {
                  input.after(errorDiv);
                }
              }
            } else {
              // place the error inside the container
              errorContainer.empty().append(errorDiv);
            }

          }
        }

        const viewRef = input.prop("viewRef");
        if (!CWSTR.isBlank(viewRef) && viewRef instanceof ComboBoxView2) {
          viewRef.showErrors();
        }
      }
    }
    );
  }

  /**
   * Counts the items in object (only terminals, not count if parent)
   *
   */
  private static _countErrors(data: { [key: string]: any }): number {
    let errors = 0;
    if (_.isObject(data) || _.isArray(data)) {
      for (const subField in data) {
        if (Object.prototype.hasOwnProperty.call(data, subField)) {
          errors += CWFORMS._countErrors(data[subField]);
        }
      }
    } else {
      errors = 1;
    }
    return errors;
  }

  public static countErrors(data: { [key: string]: any }): number {
    return CWFORMS._countErrors(data);
  }

  /**
   * Clean the red balls and tooltips added to labels by the function
   *
   */
  static cleanErrors(jQueryRootSelector: JQuery, className?: string): void {
    jQueryRootSelector.find(".phx-formErrors").html("&nbsp;");
    jQueryRootSelector.find(".phx-formErrors").css("display", "none");
    jQueryRootSelector.find("label[for]").each((index, label): void => {
      const labelId = $(label).attr("for").replace(/[^a-zA-Z0-9._-]/g, "").replace("/", "\\/");
      // If className only clean errors of that input
      if (!CWSTR.isBlank(labelId) && (CWSTR.isBlank(className) || labelId === className)) {
        let input = jQueryRootSelector.find("." + labelId.replace(/\./g, "\\."));
        if (input && input.length === 0) {
          input = jQueryRootSelector.find("#" + labelId.replace(/\./g, "\\."));
        }
        CWFORMS._cleanInputErrors(input, labelId, jQueryRootSelector, label);
      }
    });
  }

  /**
   * Clean the exists errors of the inputs
   *
   */
  private static _cleanInputErrors(input: JQuery, labelId: string, jQueryRootSelector: JQuery, label: Element): void {

    input.removeClass("ui-state-error").attr("aria-invalid", "false");

    if (input.parent('.input-group')) {
      input.parent('.input-group').removeClass("ui-state-error").attr("aria-invalid", "false");
    }
    if (input.parent('.btn-group')) {
      input.parent('.btn-group').removeClass("ui-state-error").attr("aria-invalid", "false");
    }

    input.find("." + labelId.replace(/\./g, "\\.")).removeClass("ui-state-error");

    jQueryRootSelector.find("." + labelId.replace(/\./g, "\\.") + "-error").detach();
    if ($(label).hasClass("griderror") === true) {
      $(label).removeClass("griderror");
      $(label).attr("title", "");
    }
    $(label).tooltip({ tooltipClass: "" });

    const viewRef = input.prop("viewRef");
    if (!CWSTR.isBlank(viewRef) && viewRef.cleanErrors) {
      viewRef.cleanErrors();
    }
  }

  /**
   * Each input is associated to a corresponding label in order to meet accessibility criterias
   */
  static linkLabelsToInputs(jQueryRootSelector: JQuery): void {
    let lNameRadio: string = null;
    let uidNameRadio: string = null;

    jQueryRootSelector.find(":input").each((index, element: HTMLInputElement): void => {
      const $domEl = $(element);
      const classes = $domEl.attr("class");

      if (!CWSTR.isBlank(classes)) {
        const name = $domEl.attr("class").split(" ")[0];
        const classnameSelector = name.replace(/\./g, "\\.");
        let newid = _.uniqueId(name + "_uid_");

        if (element.type === "select-one") {
          jQueryRootSelector.find("select." + classnameSelector).attr("id", newid).attr("aria-describedby", newid + "-info " + newid + "-error");
          const rootSelector = $(jQueryRootSelector.find("label[for='" + classnameSelector + "'], label[for^='" + classnameSelector + "_uid_']"));
          $(rootSelector[0]).attr("for", newid);
          jQueryRootSelector.find("div." + classnameSelector + "-info").attr("id", newid + "-info");

        } else if (element.type === "textarea") {
          jQueryRootSelector.find("textarea." + classnameSelector).attr("id", newid).attr("aria-describedby", newid + "-info " + newid + "-error");
          const rootSelector = $(jQueryRootSelector.find("label[for='" + classnameSelector + "'], label[for^='" + classnameSelector + "_uid_']"));
          $(rootSelector[0]).attr("for", newid);
          jQueryRootSelector.find("div." + classnameSelector + "-info").attr("id", newid + "-info");
        } else {
          // other fields case
          switch ($domEl.attr("type")) {
            case "text": {
              let rootSelector, $label;

              const oldId = $domEl.attr("id");
              const isEmptyId = CWSTR.isBlank(oldId);
              if (!isEmptyId && oldId.indexOf("_uid_") === -1) {
                // If the input has an id we keep it, it is to solve the case of the datepicker
                if ($domEl.hasClass("hasDatepicker")) {
                  newid = oldId;
                } else {
                  newid = _.uniqueId(oldId + "_uid_");
                }
                // Update input id to new id
                $domEl.attr("id", newid).attr("aria-describedby", newid + "-info " + newid + "-error");
                // Get label using oldId
                rootSelector = $(jQueryRootSelector.find("label[for='" + oldId + "'], label[for^='" + oldId + "_uid_'],label[datePicker-ref='" + oldId + "']"));

                $label = $(rootSelector[0]).attr("for", newid);
              } else if (isEmptyId) {
                // Search input by classname and update id
                jQueryRootSelector.find("input." + classnameSelector).attr("id", newid).attr("aria-describedby", newid + "-info " + newid + "-error");
                // Get label using classname
                rootSelector = $(jQueryRootSelector.find("label[for='" + classnameSelector + "'], label[for^='" + classnameSelector + "_uid_'],label[datePicker-ref='" + classnameSelector + "']"));

                $label = $(rootSelector[0]).attr("for", newid);
              }

              if (!CWSTR.isBlank($label)) {
                $(jQueryRootSelector.find("span." + classnameSelector)[0]).attr("aria-label", $label.text());
                jQueryRootSelector.find("div." + classnameSelector + "-info").attr("id", newid + "-info");
              }

              break;
            }
            case "checkbox": {
              jQueryRootSelector.find("input." + classnameSelector).attr("id", newid).attr("aria-describedby", newid + "-info " + newid + "-error");
              const rootSelector = $(jQueryRootSelector.find("label[for='" + classnameSelector + "'], label[for^='" + classnameSelector + "_uid_']"));
              $(rootSelector[0]).attr("for", newid);
              jQueryRootSelector.find("div." + classnameSelector + "-info").attr("id", newid + "-info");
              break;
            }
            case "radio": {
              const oldId = $domEl.attr("id");
              $(element).attr("id", newid).attr("aria-describedby", newid + "-info " + newid + "-error");
              const rootSelector = $(jQueryRootSelector.find("label[for='" + oldId + "'], label[for^='" + oldId + "_uid_']"));
              $(rootSelector[0]).attr("for", newid);
              jQueryRootSelector.find("div." + classnameSelector + "-info").attr("id", newid + "-info");
              //Pour l'attribute "name"
              if (CWSTR.isBlank(lNameRadio) || (!CWSTR.isBlank($domEl.attr("name")) && $domEl.attr("name") !== lNameRadio)) {
                lNameRadio = $domEl.attr("name");
                uidNameRadio = _.uniqueId(lNameRadio + "_uid_");
                $domEl.attr("name", uidNameRadio);
              } else if (!CWSTR.isBlank($domEl.attr("name")) && $domEl.attr("name") === lNameRadio) {
                $domEl.attr("name", uidNameRadio);//il doit avoir la même valeur d'identificateur
              }
              break;
            }
            default:
              break;
          }
        }
      }
    });
  }

  /**
   * Manage readonly fields of type input: 1 add span to show value of
   * readonly field
   */
  static setInputFieldReadonly(fieldset: JQuery, fieldname: string, readonly: boolean): void {
    const el = fieldset.find("input." + fieldname.replace(/\./g, "\\."));
    if (!CWSTR.isBlank(el)) {
      if (readonly === true) {
        el.attr("aria-readonly", "true");
        //Resize the input
        // CWFORMS.autosizeInput(el);
        // Hide components
        if (el.hasClass("hasDatepicker")) {
          CWFORMS.readonlyDatepicker(fieldset, fieldname, true);
        } else if (el.hasClass("phx-autocomplete-input")) {
          el.parent().find(".phx-autocomplete-button").hide();
        } else if (el.hasClass("phx-referentiel-input")) {
          el.parent().find(".phx-referentiel-button").hide();
        } else if (el.hasClass("phx-chemin-input")) {
          el.parent().find(".phx-chemin-button").hide();
        } else if (el.hasClass("phx-combobox-input")) {
          ComboBoxView2.prototype.enable.call(this, !readonly, el.parents(".phx-combobox"), fieldname);
        } else if (el.hasClass("c-cwComboBoxView2__input")) {
          CWComboBoxView2.prototype.enable.call(this, !readonly, el.parents(".c-cwComboBoxView2"), fieldname);
        }
        if (el.hasClass("form-control")) {
          el.parent(".input-group").addClass("readonly-within");
        }
        if (el.parent(".btn-group").length > 0) {
          el.parent(".btn-group").addClass("readonly-within");
        }
      } else {
        el.removeAttr("aria-readonly");

        // CWFORMS.resetSizeInput(el);

        // Show components
        if (el.hasClass("hasDatepicker")) {
          CWFORMS.readonlyDatepicker(fieldset, fieldname, false);
        } else if (el.hasClass("phx-autocomplete-input")) {
          el.parent().find(".phx-autocomplete-button").show();
        } else if (el.hasClass("phx-referentiel-input")) {
          el.parent().find(".phx-referentiel-button").show();
        } else if (el.hasClass("phx-chemin-input")) {
          el.parent().find(".phx-chemin-button").show();
        } else if (el.hasClass("phx-combobox-input")) {
          ComboBoxView2.prototype.enable.call(this, !readonly, el.parents(".phx-combobox"), fieldname);
        } else if (el.hasClass("c-cwComboBoxView2__input")) {
          CWComboBoxView2.prototype.enable.call(this, !readonly, el.parents(".c-cwComboBoxView2"), fieldname);
        }
        if (el.hasClass("form-control")) {
          el.parent(".input-group").removeClass("readonly-within");
        }
        if (el.parent(".btn-group").length > 0) {
          el.parent(".btn-group").removeClass("readonly-within");
        }
      }
    }
  }

  /**
   * Manage readonly fields of type textarea: 1 add span to show value of
   * readonly field
   */
  static setTextareaFieldReadonly(fieldset: JQuery, fieldname: string, readonly: boolean): void {
    const el = fieldset.find("textarea." + fieldname.replace(/\./g, "\\."));
    fieldset.find("span." + fieldname.replace(/\./g, "\\.")).remove();

    if (!CWSTR.isBlank(el)) {
      if (readonly === true) {
        el.prop("readOnly", true);
        el.attr("aria-readonly", "true");
        el.css("resize", "none");
        el.css("border", "none");

        if (el.hasClass("form-control")) {
          el.parent(".input-group").addClass("readonly-within");
        }
      } else {
        el.prop("readOnly", false);
        el.attr("aria-readonly", "false");
        el.css("resize", "");
        el.css("border", "");
        if (el.hasClass("form-control")) {
          el.parent(".input-group").removeClass("readonly-within");
        }
      }
    }
  }

  /**
   * Manage readonly fields of type select: 1 add span to show value of
   * readonly field
   */
  static setSelectFieldReadonly(fieldset: JQuery, fieldname: string, readonly: boolean): void {
    const el = fieldset.find("." + fieldname.replace(/\./g, "\\."));
    const value = $("option:selected", el).text();
    fieldset.find("input." + fieldname.replace(/\./g, "\\.")).remove();

    if (!CWSTR.isBlank(el)) {
      if (readonly === true) {
        const title = el.attr("title");
        const input = $("<input>").addClass(fieldname).val(value);
        input.attr("id", el.attr("id"));
        input.attr("title", title);
        input.prop("readOnly", true);
        input.attr("aria-readonly", "true");
        el.before(input);

        const select = el.filter("select");

        select.hide();

        if (el.hasClass("form-control")) {
          el.parent(".input-group").addClass("readonly-within");
        }
      } else {
        const input = el.filter("input");
        input.remove();
        el.show();

        if (el.hasClass("form-control")) {
          el.parent(".input-group").removeClass("readonly-within");
        }
      }
    }
  }

  /**
   * Assign a value from an object to another object.
   *
   */
  static assignValue(toObject: { [key: string]: any }, property: string, value: string, options?: { [key: string]: any }): void {
    if (property.indexOf(".") === -1) {
      toObject[property] = value;
    } else {
      CWFORMS._assignValue(toObject, property, value, options);
    }
  }

  /**
   * Assign a value from an object to another object.
   * @ private
   */
  private static _assignValue(toObject: { [key: string]: any }, property: string, value: string, options?: { [key: string]: any }): void {
    if (toObject) {
      const tokens = property.split(".");
      if (tokens.length > 1) {
        //Create property if not exist
        if (CWSTR.isBlank(toObject[tokens[0]])) {
          toObject[tokens[0]] = {};
        }
        // Complex element
        if (CWSTR.isBlank(toObject[tokens[0]])) { //If the path didn't exist we create it
          toObject[tokens[0]] = {};
        }
        CWFORMS._assignValue(toObject[tokens[0]], tokens.slice(1).join("."), value, options);
      } else {
        // Simple element
        toObject[tokens[0]] = value;
      }
    }
  }

  /**
   * Delete an el value to a model
   *
   */
  static removeValue(object: { [key: string]: any }, property: string, options?: { [key: string]: any }): void {
    if (property.indexOf(".") === -1) {
      delete object[property];
    } else {
      CWFORMS._removeValue(object, property, options);
    }
  }

  /**
   * Delete an el value to a model
   */
  private static _removeValue(object: { [key: string]: any }, property: string, options?: { [key: string]: any }): void {
    //		const tempObj = CWFORMS.pointToSBracket(object, property, options);
    //		const tokens = property.split(".");
    //		delete tempObj[_.last(tokens)];

    let objectValue = object;
    const tokens = property.split(".");
    if (tokens.length > 1) {
      // Complex element
      objectValue = object[tokens[0]];
      CWFORMS._removeValue(objectValue, tokens.slice(1).join("."), options);
      if (_.isEmpty(object[tokens[0]])) {
        delete object[tokens[0]];
      }
    } else {
      // Simple element
      delete objectValue[tokens[0]];
    }

  }

  /**
   * Selects the value after the last (.)
   *
   */
  static pointToSBracket(object: { [key: string]: any }, property: string, options?: { [key: string]: any }): string {
    if (!CWSTR.isBlank(object)) {
      if (property.indexOf(".") === -1) {
        return object[property];
      } else {
        return CWFORMS._pointToSBracket(object, property, options);
      }
    } else {
      throw Error("Object is not defined");
    }
  }

  /**
   * Selects the value after the last (.)
   */
  private static _pointToSBracket(object: { [key: string]: any }, property: string, options?: { [key: string]: any }): string {
    let objectValue: any = object;
    if (objectValue) {
      const tokens = property.split(".");
      if (tokens.length > 1) {
        // Complex element
        objectValue = object[tokens[0]];
        objectValue = CWFORMS._pointToSBracket(objectValue, tokens.slice(1).join("."), options);
      } else {
        // Simple element
        return objectValue[tokens[0]];
      }
    }
    return objectValue;
  }

  /**
   * Adjust the width of the input element to the content.
   */
  static autosizeInput(/*input: JQuery*/): void {
    //Store old width
    /*if (input.attr("old-width") === undefined) {
      if (!input.parents(":last").is("html")) {
        const viewRef = input.prop("viewRef");
        // To calculate the combobox width I need to clone all combobox and not only the input.
        if (viewRef instanceof ComboBoxView2) {
          const clon = viewRef.$el.clone();
          $("body .cloned_elements").append(clon);
          // I just take the with of the input.
          input.attr("old-width", clon.find("input").css("width"));
          clon.remove();
        } else {
          const clon = input.clone();
          $("body .cloned_elements").append(clon);
          input.attr("old-width", clon.css("width"));
          clon.remove();
        }
      } else if (input.is(":hidden")) {
        if (input.css("display") === "none") {
          input.show();
          input.attr("old-width", input.css("width"));
          input.hide();
        } else if (input.parents().filter((): boolean => { return $(this).css("display") === "none"; }).length > 0) {
          const parent = input.parents().filter((): boolean => { return $(this).css("display") === "none"; });
          parent.show();
          input.attr("old-width", input.css("width"));
          parent.hide();
        }
      } else {
        input.attr("old-width", input.css("width"));
      }
    }
    //Remove unnecesary whitespace
    input.val($.trim(input.val()));
    // const input = $(input);
    const value = input.val() || "";
    //Prepare Mirror
    //        const mirror = $('<span style="position:absolute; top:-999px; left:0; white-space:pre;"/>');
    //        mirror.text(value);
    //        $.each(['fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'letterSpacing', 'textTransform', 'wordSpacing', 'textIndent'], function (i, val) {
    //            mirror[0].style[val] = input.css(val);
    //        });
    //        $("body").append(mirror);
    //        input.width(mirror.width());
    //        mirror.remove();
    input.width(CWFORMS.getWidthFromMirror(value, input));*/
  }

  static getWidthFromMirror(text: string, element: JQuery): number {
    let width = 0;
    const mirror = $("body .cloned_elements .cw-forms_clone_elements");

    if (!CWSTR.isBlank(text) && mirror.length > 0 && element.length > 0) {
      mirror.text(text);
      $.each(['fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'letterSpacing', 'textTransform', 'wordSpacing', 'textIndent'], (i: any, val: any): void => {
        mirror[0].style[val] = String(element.css(val));
      });
      width = mirror.width();
      mirror.empty(); //clean once it has been used to calculate width of each line
      mirror.removeAttr("style"); //retablir les styles      
    }
    return width;
  }


  /**
   *  * Set or not set the form in readonly mode
   *
   */
  static setFormReadonly($fieldset: JQuery, readonlyForm: boolean, forcedByHabilitations?: boolean): void {
    $fieldset.find(":input").each((index, element): void => {
      CWFORMS.setFieldReadonly(element, readonlyForm, forcedByHabilitations);
    });
    $fieldset.find(".phx-multiLabel").each((index, element): void => {
      const viewRef = $(element).prop("viewRef");
      if (!CWSTR.isBlank(viewRef)) {
        viewRef.setReadOnly(readonlyForm);
      }
    });

    //Remove * (cw-required) for readonly data
    if (readonlyForm) {
      $fieldset.find("label.cw-required").css("background", "none");
    } else {
      $fieldset.find("label.cw-required").css("background", "");
    }
  }

  /**
   * Set or not set the fieldset in readonly mode
   *
   */
  static setFieldReadonly(element: any, setToReadonly: boolean, forcedByHabilitations?: boolean): void {
    const $domEl = $(element);

    if ($domEl.length > 0 && !CWSTR.isBlank($domEl.attr("class"))) {
      const name = $domEl.attr("class").split(" ")[0];
      const fieldset = $domEl.parent();
      const fieldIsReadonly = $domEl.is("[readonly]") || ($domEl.is(":disabled") && ($domEl.attr("type") === "radio" || $domEl.attr("type") === "checkbox"));// Check if I have already in readonly, for checkbox and radio they should be disabled.
      const forcedToReadonly = $domEl.is("[forcedreadonly]");

      // field is readonly and the habilitation try to make it editable, don't do nothing.
      if (fieldIsReadonly && !setToReadonly && ((forcedByHabilitations && !forcedToReadonly) || (!forcedByHabilitations && forcedToReadonly))) {
        return;
      }
      // field state is correct
      if (fieldIsReadonly === setToReadonly) {
        if (forcedByHabilitations === true && setToReadonly === true) {
          $domEl.attr("forcedreadonly", "");
          if ($domEl.hasClass("form-control")) {
            $domEl.parent(".input-group").addClass("readonly-within");
          }
          if ($domEl.parent(".btn-group").length > 0) {
            $domEl.parent(".btn-group").addClass("readonly-within");
          }
        }
        if (forcedByHabilitations === true && setToReadonly === false) {
          $domEl.removeAttr("forcedreadonly");
          if ($domEl.hasClass("form-control")) {
            $domEl.parent(".input-group").removeClass("readonly-within");
          }
          if ($domEl.parent(".btn-group").length > 0) {
            $domEl.parent(".btn-group").removeClass("readonly-within");
          }
        }
        return;
      }
      if (element.type === "select-one" || element.type === "textarea" || (element[0] && (element[0].type === "select-one" || element[0].type === "textarea"))) {
        if (setToReadonly === true) {
          if (forcedByHabilitations === true) {
            $domEl.attr("forcedreadonly", "");
          }
          // replace readonly field by span field and hide the readonly field
          if (element.type === "select-one" || (element[0] && element[0].type === "select-one")) {
            $domEl.prop("readonly", true);
            CWFORMS.setSelectFieldReadonly(fieldset, name, true);
          }
          if (element.type === "textarea" || (element[0] && element[0].type === "textarea")) {
            $domEl.prop("readonly", true);
            CWFORMS.setTextareaFieldReadonly(fieldset, name, true);
          }
        } else if (forcedByHabilitations === true || forcedToReadonly === false) {
          $domEl.prop("readonly", false);
          $domEl.removeAttr("forcedreadonly");
          if (element.type === "textarea" || (element[0] && element[0].type === "textarea")) {
            CWFORMS.setTextareaFieldReadonly(fieldset, name, false);
          } else if (element.type === "select-one" || (element[0] && element[0].type === "select-one")) {
            CWFORMS.setSelectFieldReadonly(fieldset, name, false);
          }
        }
      } else {
        const viewRef = $domEl.prop("viewRef");

        //other fields case
        switch ($domEl.attr("type")) {
          case "number":
          case "text":
            if (setToReadonly === true) {
              $domEl.prop("readonly", true);
              if (forcedByHabilitations === true) {
                $domEl.attr("forcedreadonly", "");
              }
            } else if (forcedByHabilitations === true || forcedToReadonly === false) {
              $domEl.prop("readonly", false);
              $domEl.removeAttr("forcedreadonly");
            }
            if ($domEl.hasClass("hasDatepicker")) {
              CWFORMS.readonlyDatepicker(fieldset, name, setToReadonly);
            } else if (viewRef && viewRef._enable) {
              viewRef._enable(!setToReadonly);
            } else if ($domEl.hasClass("typeDate") && setToReadonly === false) {
              CWFORMS.readonlyDatepicker(fieldset, name, setToReadonly);
            } else if (viewRef && viewRef.setReadOnly) {
              viewRef.setReadOnly(setToReadonly);
            } else {
              CWFORMS.setInputFieldReadonly(fieldset, name, setToReadonly);
            }
            break;
          case "checkbox":
          case "radio":
            if (setToReadonly === true) {
              $domEl.prop("disabled", true);
              $domEl.prop("readonly", true);
              if (forcedByHabilitations === true) {
                $domEl.attr("forcedreadonly", "");
              }
              if ($domEl.parent(".btn-group").length > 0) {
                $domEl.parent(".btn-group").addClass("readonly-within");
              }
            } else if (forcedByHabilitations === true || forcedToReadonly === false) {
              $domEl.prop("readonly", false);
              $domEl.prop("disabled", false);
              $domEl.removeAttr("forcedreadonly");
              if ($domEl.parent(".btn-group").length > 0) {
                $domEl.parent(".btn-group").removeClass("readonly-within");
              }
            }
            CWFORMS.forceUpdateLabelRender($domEl);
            break;
          default:
            break;
        }
      }
    }
  }

  /**
   * Force label update:
   * Bootstrap's custom control labels are not properly
   * rendered when input state is modified in IE and Edge
   * 
   * @param $domEl 
   */
  private static forceUpdateLabelRender($domEl: JQuery): void {
    if (UTILS.isIE()) {
      const inputId = $domEl.attr("id");
      if (!CWSTR.isBlank(inputId)) {
        $domEl.parents(".custom-control")
          .find("label[for='" + inputId + "']")
          .css("transform", "translate(0)");
      }
    }
  }

  /**
   * Default configuration for the datepickers used by setDatepicker(...)
   */
  private static getDefaultOptionDatepicker(view: any): void {
    let lan = UTILS.getLanguage();
    if (lan === "en") {
      // This patch is needed because jQuery Ui return
      // undefined for "en". The english options are returned
      // for "".
      lan = "";
    }

    const icon = UTILS.getSVGIcon('calendrier');

    const cwDatepickerArgs = {
      yearRange: "1899:2100",
      showOn: "button",
      //buttonImageOnly : true,
      buttonText: icon,
      //In JQuery Datepicker the 4 digits year format is 'yy'
      dateFormat: GLOBAL_DATA.types.get("DATE").get("masque").toLowerCase().replace("yyyy", "yy"),
      minDate: new Date(1899, 12 - 1, 31),
      onClose: (): void => {
        $(view).blur();
      },
      // Regional options got from the internationalization
      closeText: i18n.t('common:close'),
      prevText: i18n.t('common:precedent'),
      nextText: i18n.t('common:suivant'),
      currentText: i18n.t('common:aujourd_hui'),
      monthNames: i18n.t('common:monthNames', { returnObjects: true }),
      monthNamesShort: i18n.t('common:monthNamesShort', { returnObjects: true }),
      dayNames: i18n.t('common:dayNames', { returnObjects: true }),
      dayNamesShort: i18n.t('common:dayNamesShort', { returnObjects: true }),
      dayNamesMin: i18n.t('common:dayNamesInitialCap', { returnObjects: true }),
      weekHeader: i18n.t('common:semAbr'),
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: ''
    };
    return _.extend({}, cwDatepickerArgs);
  }

  /**
   * Set a date picker to an element in a view and adds the button icon
   */
  static setDatepicker(view: any, element: any, options?: { [key: string]: any }): void {
    const before = {
      beforeShow: (input: any, inst: any): void => {
        // Force href click triggering
        $('.ui-tabs-nav a[href^="#"]').off("click.hideDatepickers");
        $('.ui-tabs-nav a[href^="#"]').on("click.hideDatepickers", (): void => {
          $(view.el).trigger("hrefOuterClick");
        });
        // href click hides datepicker
        $(view.el).off("hrefOuterClick");
        $(view.el).on("hrefOuterClick", (): void => {
          ($ as any).datepicker._hideDatepicker();
          $(view.el).off("hrefOuterClick");
          $('.ui-tabs-nav a[href^="#"]').off("click.hideDatepickers");
        });

        $(inst.dpDiv).addClass("cw-datepickerCalendar");
        if (options && options.class) {
          $(inst.dpDiv).addClass(options.class);
        }
        if ($(input).hasClass("periodEnd") && CWSTR.isBlank(input.valueAsDate)) {
          const parse = CWTYPE.DATE.parse(input.value);//Parse the input value
          const periodId = $(input).attr("periodId");
          const startDate = view.$el.find(".periodStart[periodId=" + periodId + "]");
          const startDateName = startDate.attr("class").split(" ")[0];
          const startDateValue = CWSTR.getElValue(view._getModel(), startDateName);

          if (!CWSTR.isBlank(startDateValue) && (CWSTR.isBlank(parse["val"]) || (parse["val"] === CWTYPE.DATE.INFINITY && GLOBAL_DATA.paramDivers.get("CACH_INFIN").get("valeur") === "1"))) {
            CWFORMS.modifyDatepicker(view, ".periodEnd[periodId=" + periodId + "]", { "defaultDate": CWTYPE.DATE.strToDate(startDateValue) });
          }
        }
        $(view.el).on("mousewheel", (): void => {
          ($ as any).datepicker._hideDatepicker();
          $(view.el).off("mousewheel");
        })

        if (UTILS.isFirefox()) {
          $(view.el).on('wheel', (): void => {
            ($ as any).datepicker._hideDatepicker();
            $(view.el).off('wheel');
          })
        }
      }
    };

    if (view && view.typeMaskByClass && !CWSTR.isBlank(element)) {
      if (!options || !options.dateFormat) {
        const target = element.substring(1);
        const typeMask = view.typeMaskByClass[target];

        if (typeMask) {
          if (!options) {
            options = {};
          }
          options.dateFormat = CWTYPE._getMaskByCode(typeMask).toLowerCase().replace("yyyy", "yy");
        }
      }
    }
    $(view.el).find(element).each((index, it): void => {
      let datePickerOptions: any = _.clone(CWFORMS.getDefaultOptionDatepicker(view));
      let onSelect = {
        onSelect: (): void => {
          $(it).blur();
          $(it).focus();
        }
      };
      let formerId: string = null;
      let $inputGroup: JQuery = null;

      if (options && options.onSelect) {
        onSelect = options.onSelect;
      }
      if (options) {
        datePickerOptions = _.extend(datePickerOptions, options, before, onSelect);
      } else {
        datePickerOptions = _.extend(datePickerOptions, before, onSelect);
      }
      // Get initial input identifier
      formerId = it.classList[0];
      $(it).datepicker(datePickerOptions);
      CWFORMS.updateDatepickerButton($(it));
      // Update label "for" attribute to new id
      $inputGroup = $(it).parents(".form-group").first();
      $inputGroup.find("label[for='" + formerId + "']").attr("for", it.id);
    });
    $(view.el).find(element).parent().find("button.ui-datepicker-trigger").attr('tabindex', "0");
    // auto-size the datepicker when placed inside a table
    $(view.el).find(element).parents("td").addClass("wider");
  }

  public static updateDatepickerButton($elements: JQuery): void {
    $elements.each((index, input): void => {
      const $inputGroup = CWFORMS._updateDatepickerInputGroup(input);

      if ($inputGroup.find("button.ui-datepicker-trigger").length > 0) {
        let $triggerBtn: JQuery = null;

        $inputGroup.find(".input-group-append.cw-datepickerBtn").remove();
        $triggerBtn = $inputGroup.find("button.ui-datepicker-trigger");
        if ($triggerBtn.length > 0) {
          const $btnContainer = $("<div class='input-group-text'></div>");
          const $groupAppend = $("<div class='input-group-append cw-datepickerBtn'></div>");

          $triggerBtn.appendTo($btnContainer);
          $groupAppend.append($btnContainer);
          $inputGroup.append($groupAppend);
        }
      }
    });
  }

  /**
   *  Datepicker must be inside a input-group with this structure:
   * <div class='input-group'>
   *  <input type='typeDate....' />
   * </div>
   *  */
  private static _updateDatepickerInputGroup(element: Element): JQuery | JQuery<Element> {
    const $inputGroup = $(element).parent(".input-group");

    if ($inputGroup.length === 0) {
      const $divTmp = $("<div class='input-group'></div>");

      $(element).wrap($divTmp);
      return $divTmp;
    }
    return $inputGroup;
  }


  static modifyDatepicker(view: any, element: any, options?: { [key: string]: any }): void {
    //on ne doit pas faire ->datepicker("option", options);
    $(view.el).find(element).datepicker("setDate", options.defaultDate);
    $(view.el).find("button.ui-datepicker-trigger").addClass('ui-button ui-corner-right ui-button-icon-only');
    //on ne doit pas faire ->CWFORMS.updateDatepickerButton;
  }

  /**
   * Disable/Enable a datePicker element
   */
  static disableDatepicker(view: any, element: any, disabled: boolean): void {
    $(view.el).find(element).datepicker("option", "disabled", disabled);
    $(view.el).find("button.ui-datepicker-trigger").addClass('ui-button ui-corner-right ui-button-icon-only');
    if (disabled) {
      $(view.el).find(element).addClass("ui-state-disabled");
    } else {
      $(view.el).find(element).removeClass("ui-state-disabled");
    }
    // After using $.datepicker("option") it's necessary to re-update the input structure
    // with updateDatepickerButton function
    CWFORMS.updateDatepickerButton($(view.el).find(element));
  }

  /**
   * Make/remove datePicker element as readonly
   */
  static readonlyDatepicker(view: any, className: string, readonly: boolean): void {
    const $el = $(view);
    const $element = $el.find("." + className.replace(/\./g, "\\."));
    const oldValue = $element.val();

    if (readonly) {
      $element.prop("readonly", true);
      $element.datepicker("option", "showOn", '');
      $element.parents("td").removeClass("wider");
      if ($element.hasClass("form-control")) {
        $element.parent(".input-group").addClass("readonly-within");
      }
    } else {
      $element.prop("readonly", false);
      $element.datepicker("option", "showOn", "button");
      $el.find("button.ui-datepicker-trigger").addClass('ui-button ui-corner-right ui-button-icon-only');
      //			 auto-size the datepicker when placed inside a table
      $element.parents("td").addClass("wider");
      $element.siblings(".ui-datepicker-trigger").addClass("ui-datepicker-trigger ui-button ui-corner-right ui-button-icon-only");
      if ($element.hasClass("form-control")) {
        $element.parent(".input-group").removeClass("readonly-within");
      }
      CWFORMS.updateDatepickerButton($element);
    }
    // Reset value
    $element.val(oldValue);
  }

  /**
   * Disable an element
   */
  static disableElement(view: any, element: any): void {
    $(view.el).find(element).prop("disabled", true);
    $(view.el).find(element).addClass("ui-state-disabled");
  }

  /**
   * Enable an element
   */
  static enableElement(view: any, element: any): void {
    $(view.el).find(element).prop("disabled", false);
    $(view.el).find(element).removeClass("ui-state-disabled");
  }
}
