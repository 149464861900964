import * as Backbone from 'Backbone';
import TPLBresultatsBriqueTousResultatsCompteurs from '../briqueResultatsTousCompteurs.tpl.html';
import { CWResultatsModel } from '../models/cwResultats.model';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';


/**
 * View of Brique 'Resultats'
 */
export class CWResultatsPanneauView<TModel extends CWResultatsModel = CWResultatsModel> extends Backbone.View<TModel>{

    template: (params?: any) => string;
    model: CWResultatsModel | any;
    parent: any;

    constructor(params: Backbone.ViewOptions<TModel> | any) {
        params = params || {};
        super(params);
        this.model = params.model;
        this.parent = params.parent;
        this.template = TPLBresultatsBriqueTousResultatsCompteurs;
    }

    render(): CWResultatsPanneauView<TModel> {
        const json = { 'i18n': i18n };

        this.$el.html(this.template(json));
        this.$el.find(".table_ResultatsCompteurs ").html(this._arrayToTable(this.model.get("cpt"), this.model.get("dateref")));
        this.$el.css({ "max-height": "232px", "max-width": "500px", "width": "450px" });
        return this;
    }

    _arrayToTable(array: Array<any>, dateref: string): string {
        let table = "<table class='ui-grid-table phx-grid ui-grid'>";
        const cellRenderers: any = [];
        const tblColumns = [
            { property: "libelle", width: 170 },
            { property: "valeur", width: 55 },
            { property: "typevalo", width: 30 },
            { property: "datevalo", width: 90 }
        ];

        cellRenderers["valeur"] = (model: CWResultatsModel | any): string => {
            let style = "";
            if (model.valeur.indexOf("-") !== -1) { //if(model.valeur < 0)
                style = "class='ui-phx-valorisation-negative'";
            }
            return "<span " + style + " style='float: right;'>" + model.valeur + "</span>";
        };
        cellRenderers["typevalo"] = (model: CWResultatsModel | any): string => {
            let icon = "";
            let title = "";
            if (model.typevalo === "J") {
                icon = "class = 'phx-icon phx-icon-resultatgrpcompteurs-window'";
                title = "title = '" + i18n.t('common:bresultats.valeurJournee') + "'";
            } else if (model.typevalo === "P") {
                icon = "class = 'phx-icon phx-icon-resultatgrpcompteurs-windows'";
                title = "title = '" + i18n.t('common:bresultats.valeurPeriodique') + "'";
            }
            return "<span " + icon + " " + title + "></span>";
        };
        cellRenderers["datevalo"] = (model: CWResultatsModel | any): string => {
            if (model.datevalo !== dateref) {
                return "<span class='ui-phx-ihm-texte-donnees-alternatif'>" + CWTYPE.DATE.format(model.datevalo, CWTYPE._getFormatByCode("DATE_A")) + "</span>";
            }
            return "";
        };
        //paint the data
        table += "<tbody class='ui-grid-body'>";
        for (let i = 0; i < array.length; i++) {
            table += "<tr class='ui-grid-row'>";
            for (let j = 0; j < tblColumns.length; j++) {
                table += "<td class='phx-td" + j + "' style='width:" + tblColumns[j].width + "px;'>";
                if (cellRenderers[tblColumns[j].property]) {
                    table += cellRenderers[tblColumns[j].property](array[i]);
                } else {
                    table += array[i][tblColumns[j].property];
                }
                table += "</td>";
            }
            table += "</tr>";
        }
        table += "</tbody>";
        return table;
    }
}