module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\r\n<fieldset class="cw-fieldset" style="display:flex; flex-direction: column;">\r\n  <fieldset class="cw-fieldset groupecompteurs-detail-panel">\r\n    <div class="phx-formTable">\r\n      <div class="phx-formErrors ui-state-error"></div>\r\n      <div class="title_compteurs" style="display: flex;justify-content: flex-end">\r\n        <span class="valorisation"></span>\r\n        <span class=\'cw-icon iconConsul\' style="display: block; cursor: pointer;" id="consultation">\r\n          '+
((__t=( UTILS.getSVGIcon("engrenage","cw-icon--primary")))==null?'':__t)+
'\r\n        </span>\r\n      </div>\r\n    </div>\r\n  </fieldset>\r\n  <div class="groupecompteurs-collab" style="display:none;margin-bottom: 1em">\r\n    <div class="ctxGestionCollab"></div>\r\n  </div>\r\n  <div class="groupecompteurs-detail" style="height: 100%"></div>\r\n  <div class="tableresultats" style="min-height: 0;"></div>\r\n  <fieldset class="cw-fieldset" style="padding-top: 24px;">\r\n    <div class="showCompteurs filter-link-btn filter-more-btn cw-texteAllege" style="display:none">'+
((__t=(i18n.t("common:resultatgrpcompteurs.show")))==null?'':__t)+
'</div>\r\n    <div class="hideCompteurs filter-link-btn filter-more-btn cw-texteAllege" style="display:none">'+
((__t=(i18n.t("common:resultatgrpcompteurs.hide")))==null?'':__t)+
'</div></br>\r\n    <div class="tableCompteursInstant" style="display:none; min-height: 0;"></div>\r\n  </fieldset>\r\n</fieldset>\r\n';
}
return __p;
};
