import _ from 'underscore';
import TPLListerevenementsHeaderPopup from '../headerPopup.tpl.html';
import { CWAccepterEvtGen } from '../models/cwAccepterEvtGen.model';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWDialogView } from 'src/core/components/dialog/cwDialog.view';
import { CWDummyEvtGen } from '../models/cwDummyEvtGen.model';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { CWRefuserEvtGen } from '../models/cwRefuserEvtGen';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n';
import { Model, ViewOptions } from 'Backbone';
import { UTILS } from 'utils/utils.js';


export class CWListerEvtHeaderPopupView extends CWBaseFormView<CWReadOnlyModel> {
  private action: string;
  private commentaire: string;
  private commentRequired: boolean
  private isForced: string;
  private parent: CWDialogView;

  constructor(options?: ViewOptions<Model> | any) {
    options = options ? options : {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change",
      "keyup :input:not([readonly])": "_enableButtons",
      "click :input.isForced": "_change",
      "click button": "_clickListener"
    }, options.events);
    super(options);
    this.template = TPLListerevenementsHeaderPopup;

    this.model = new CWBaseModel({
      value: null
    });

    this.commentRequired = CWSTR.isBlank(options.commentRequired) ? false : options.commentRequired;

    if (options.action) {
      this.action = options.action;
      if (this.action === "A") {
        this.model.set("value", new CWAccepterEvtGen());
      } else if (this.action === "R") {
        this.model.set("value", new CWRefuserEvtGen());
      } else if (this.action === "C") {
        if (this.commentRequired) {
          this.model.set("value", new CWRefuserEvtGen());
        } else {
          this.model.set("value", new CWAccepterEvtGen());
        }
      }
    } else {
      this.model.set("value", new CWDummyEvtGen());
    }

    this.model.get("value").on("change", this._mapToForm, this);
    this.commentaire = CWSTR.isBlank(options.commentaire) ? "" : options.commentaire;
    this.isForced = CWSTR.isBlank(options.isForced) ? "" : options.isForced;

    if (options.parent) {
      this.parent = options.parent;
      this.parent.model.set("isCommentChanged", false);
    }
    this.listenTo(this, "btnResult", this._clickListener);

  }

  render(): CWListerEvtHeaderPopupView {
    const json = { i18n: i18n, UTILS: UTILS };
    this.$el.append(this.template(json));

    // Render buttons
    $(this.el).find(".btnValider").button();
    $(this.el).find(".btnRevert").button({
      icons: {
        primary: "ui-icon-arrowrefresh-1-w"
      },
      text: false
    });

    //Initialize the comment textarea with the previous comment and the forced checkbox.
    this.model.get("value").isForced = this.isForced;
    CWSTR.setElValue(this.model.get("value"), "commentaire", this.commentaire);

    if (this.action === "R" || this.commentRequired) {
      //$(this.el).find("fieldset.devenements-dialogPopupHeader label[for=commentaire], fieldset.devenements-dialogPopupHeader label[for^=commentaire_uid_]").addClass("required");
      $(this.el).find("fieldset.devenements-dialogPopupHeader label[for=commentaire],label[for^=commentaire_uid_]").addClass("cw-required");
    }

    //Disable the buttons
    $(this.el).find("fieldset.devenements-dialogPopupHeader button").button({ disabled: true });
    if (this.parent) {
      $(this.parent.el).css("height", "auto");
    }

    return this;
  }

  _change(event: JQueryEventObject | any): void {
    const element = event.currentTarget;

    //MapCheckBoxToModel
    if ($(element).hasClass("isForced")) {
      this.model.get("value").isForced = element.checked;
    } else {
      CWBaseFormView.prototype._change.call(this, event);
    }

    // Clear current validation erros
    this._cleanValidationErrors();
  }

  _enableButtons(): void {
    $(this.el).find("fieldset.devenements-dialogPopupHeader button").button({ disabled: false });
  }

  _mapToForm(): void {
    const model = this.model.get("value");
    const fieldset = this.$el.find("fieldset");

    this._mapModelToForm(fieldset, model, null);

    $(this.el).find("fieldset.devenements-dialogPopupHeader input.isForced").prop("checked", model.isForced);
  }

  _clickListener(action: string): void {
    const model = this.model.get("value");
    const commentaire = CWSTR.getElValue(model, "commentaire");
    const isForced = model.isForced;

    switch (action) {
      case "btnValider":
        // Error management
        model.off("invalid");
        model.on("invalid", this._showValidationErrors, this);

        if (model.isValid()) {
          this.parent.model.set("globalComment", commentaire);
          this.parent.model.set("isForced", isForced);
          if (commentaire !== this.commentaire) {//if the comment change...
            this.parent.model.set("isCommentChanged", true);
          }
          this.parent.close();
        }
        break;
      case "btnRevert":
        model.isForced = this.isForced;
        this.parent.close();
        break;
    }
  }
}