import _ from 'underscore';
import ListerevenementsDetailFormTPL from '../detailForm.tpl.html';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseGridColums, CWBaseGridView, validsWidthTypesForGridsColums } from 'src/core/grids/basegrid/cwBaseGrid.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWComboBoxView2 } from 'src/core/components/combo/cwComboBoxView2';
import { CWDataGridCellView } from 'src/core/grids/data_grid/cwDataGridCell.view';
import { CWDialogPopupType, CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWDialogView } from 'core/components/dialog/cwDialog.view';
import { CWEtiquette, validEtiquetteTypes } from 'src/core/components/etiquette/cwEtiquette.view';
import { CWEvenementModelColl, CWEvenementModelCollTypo, CWEvenementModelCollTypoCaracol } from '../models/cwListerEvtGen.collection';
import { CWFORMS } from 'src/utils/cwForms';
import { CWGererEvtGenView } from 'common/evenements/gerer/gererevtgen/views/cwGererEvtGen.view';
import { CWGererPiecesJointesView } from 'common/evenements/gerer/gererpiecesjointes/views/cwGererPiecesJointes.view';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWListerEvenementsGeneralView } from '../views/cwListerEvenementsGeneral.view';
import { CWListerEvetGenHistoriqueColl } from '../models/cwListerEvetGenHistorique.collection';
import { CWListerEvetGenOverloadedBaseModel } from '../models/cwListerEvtGenOverloadedBaseModel.model';
import { CWListerEvtGenModel } from '../models/cwListerEvtGen.model';
import { CWListerEvtGenWorkflow } from '../models/cwListerEvtGen.workflow';
import { CWListerEvtHeaderPopupView } from '../views/cwListerEvtHeaderPopup.view';
import { CWListerEvtResumePopUpView } from '../views/cwListerEvtResumePopUp.view';
import { CWListerEvtRowPopupView } from '../views/cwListerEvtRowPopup.view';
import {
  CWLOG,
  CWMSGS,
  CWSTR,
  NAV,
  UTILS
} from 'src/utils';
import { CWMenuGridModel } from 'src/core/grids/menugrid/cwMenuGrid.model';
import { CWMenuGridView } from 'src/core/grids/menugrid/cwMenuGrid.view';
import { CWPanneauDeroulant } from 'src/core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWProgressBarModel } from 'common/progressbar/models/cwProgressBar.model';
import { CWProgressBarView } from 'common/progressbar/views/cwProgressBar.view';
import { CWTYPE } from 'src/tda/cwTda';
import { CWVoletView } from 'core/components/dialog/volet/cwVolet.view';
import { CWWkflhistoView } from 'common/wkfhisto/views/cwWkfhisto.view';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n';
import { Model, View, ViewOptions } from 'Backbone';

export interface CWListerEvtFormViewOptions extends ViewOptions<CWBaseModel> {
  parentView?: View;
  context?: { [key: string]: any };
  parent?: CWListerEvenementsGeneralView;
  tableCellRenderers?: { [key: string]: (model: CWListerEvtGenModel) => string | JQuery };
  callback?: () => void;
  workflow?: CWListerEvtGenWorkflow;
}

/**
 * Validation form
 */

export class CWListerEvtFormView extends CWBaseFormView<CWBaseModel> {
  public context: { [key: string]: any };
  public parent: CWListerEvenementsGeneralView;
  public cellRenderers: { [key: string]: (model: CWListerEvtGenModel) => string | JQuery | HTMLElement };
  public workflow: CWListerEvtGenWorkflow;
  public modelsEvts: Model | any;
  protected tableModel: CWMenuGridModel<CWEvenementModelColl, CWListerEvtGenModel>;
  protected historiqueColl: CWListerEvetGenHistoriqueColl<CWListerEvtGenModel>;
  protected wkfEvenements: string;
  private styleLabel: string;
  private styleDate: string;
  private styleHour: string;
  private isSortedAsc: boolean;
  //Initialized in CWListerEvtFilterContentView and copy reference on CWListerEvenementsGeneralView -> _initializeViews()
  public comboSearchProfils: CWComboBoxView2;
  private headerDialog: CWPanneauDeroulant;
  private rowDialog: CWPanneauDeroulant;
  private tooltipDemande: string;
  protected table: CWMenuGridView<CWListerEvtGenModel, CWEvenementModelColl>;
  private popupGererEvtGenDialog: CWVoletView<CWGererEvtGenView>;
  private managingVueChange: boolean;
  protected pjPopupView: CWDialogView<CWGererPiecesJointesView>;
  private resumeDialog: CWDialogView;
  private dialogProgressBar: CWPanneauDeroulant;
  private progressBarOpened: boolean;
  private periodeTwoLines: boolean;
  private $appendTo: JQuery; //pour les messages
  public model: CWListerEvetGenOverloadedBaseModel;


  constructor(options?: CWListerEvtFormViewOptions) {
    options = options || {};
    options.events = _.extend({
      "click button": "_clickListener",
      "click .piece-jointe": "_managePieceJointe",
      "change input[class*='comboProfil']": "_manageRowProfil",
      "change .searchProfils": "_filterTableByProfil",
      "click .suivicollab-link": "_manageSuiviLink",
      "click .evtgen-link": "_manageEvtGenLink",
      "mouseenter .txtStatut": "_openTooltipListener",
      "mouseleave .txtStatut": "_closeTooltipListener",
      "click .c-grind__table .btn-validations": "_manageLineButtons",
      "click .c-grind__headerScroll__header .btn-validations": "_manageHeaderButtons",
      "click .btn-commentaire-bulle": "_commentairePopUp",
      "click .btn-commentaire-bulle-header": "_openHeaderPopup",
      'click .phx-td2 .ui-phx-ihm-texte-donnees': "_showVolet",
      'click .phx-td3 .ui-phx-ihm-texte-donnees': "_showVolet",
      'click .phx-td4 .ui-phx-ihm-texte-donnees': "_showVolet",
      'click .phx-td5 .ui-phx-ihm-texte-donnees': "_showVolet",
      'click .phx-td6 .ui-phx-ihm-texte-donnees': "_showVolet",
      'click .phx-td1': "_manageSuiviLink",
      'click .phx-td2': "_showVolet",
      'click .phx-td3': "_showVolet"
    }, options.events);
    super(options);
    this.template = ListerevenementsDetailFormTPL;
    if (options && options.context) {
      this.context = options.context;
    }
    if (options && options.parent) {
      this.parent = options.parent;
      this.cellRenderers = options.parent.tableCellRenderers;
    }
    this.model = new CWListerEvetGenOverloadedBaseModel({
      // This values are set in the workflow.
      progressBarModel: new CWProgressBarModel(),
      globalComment: "",
      isForced: false
    });
    this.model.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces,
    }));
    if (options.workflow) {
      this.workflow = options.workflow;
      this.workflow.updateOptions(options);
    }
    else {
      this.workflow = new CWListerEvtGenWorkflow(options);
    }
    this.$appendTo = (this.workflow && this.workflow.context && !CWSTR.isBlank(this.workflow.context.ctxEcran)) ? $("#" + this.workflow.context.ctxEcran) : null;
    this.listenTo(this.workflow, "manageComboProfiles", this.manageComboSearchProfile);
    this.listenTo(this.workflow, "manageTable", () => { this.manageGridDisplay() });
    this.workflow.formModel = this.model;
    this.tableModel = this.parent.tableModel;
    this.workflow.tableModel = this.tableModel;
    this.historiqueColl = new CWListerEvetGenHistoriqueColl<CWListerEvtGenModel>();
    this.historiqueColl.setHabContext(this.workflow.getHabContext());
    this.wkfEvenements = null;
    // Styles for the table
    this.styleLabel = "ui-phx-ihm-texte-donnees";
    this.styleDate = "phx-bold-input-style";
    this.styleHour = "phx-input-style";
    //events
    this.model.get("progressBarModel").on("change:value", this._changeProgressBar, this);
    this.model.on("cleanGridErrors", this._cleanErrorsTable, this);
    this.isSortedAsc = true;
    this.progressBarOpened = false;
    this.periodeTwoLines = false;
    if (this.context && this.context.ctxUtilisateur === "Responsable" && this.context.ctxValidation) {
      this.setContext(this.context, options.callback);
    }
    this.listenTo(this.workflow, "clean:comboProfil", this._CleanValueComboProfil);
    this.listenTo(this.workflow, "manageComboProfiles", (): void => { this._updateHeaderVisibility() });
  }

  // resets columns visibility when vue changes
  _manageVueChange(): void {
    this._updateHeaderVisibility();
  }

  _enableTableModelListeners(): void {
    this.tableModel.on("finish:proccess", this._validationFinnished, this);
    this.tableModel.coll.off("revert:table");
    this.tableModel.coll.on("revert:table", (): void => {
      this._revertTable();
    });
    this.tableModel.coll.on("updateHeader:coll", this._updateHeaderVisibility, this);
    this.tableModel.on("rows:checked", this.tableRowChecked, this);
    this.tableModel.coll.on("sync", (): void => {
      this.tableModel.coll.forEach((model): void => {
        model.off("invalid");
        model.on("invalid", this._showGridValidationErrors, this);
        if (this.managingVueChange === true) {
          this.managingVueChange = false;
        }
      });
    });
    this.tableModel.on("vue:change", this._manageVueChange, this);
    this.tableModel.coll.off("row:click");
    this.tableModel.coll.on("row:click", this._manageEvtGenLink, this);
  }

  setContext(context: { [key: string]: any }, callback?: () => void): void {
    this.context = context;
    this.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
    this.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces
    }));
    this.tableModel.coll.setSortings(this.context.ctxTriDate);
    this.workflow.setContext(this.context, callback);
  }

  _filterTableByProfil(): void {
    const item = this.comboSearchProfils.getItem();
    const filter: { [key: string]: any } = {};

    if (item && !CWSTR.isBlank(item.id)) {
      filter.profils = item.id;
    } else {
      filter.profils = null;
    }
    this.workflow._filterTable(filter, true);
  }

  _revertTable(): void {

    const tableColl = this.table.model.coll as CWEvenementModelColl;

    this.table.model.multiselectColl.reset();

    CWSTR.setElValue(this.model, "globalComment", undefined);
    $(this.el).find(".btn-validations.A").removeClass("selectionne").addClass("nonSelectionne");
    $(this.el).find(".btn-validations.R").removeClass("selectionne").addClass("nonSelectionne");
    $(this.el).find(".btn-commentaire-bulle-header").removeClass("selectionne");
    $(this.el).find(".btn-commentaire-bulle").removeClass("selectionne");

    this._manageRows(); //select - deselect row

    tableColl.nbEvtEtatValid = 0;
    tableColl.nbEvtEtatA = 0;
    tableColl.nbEvtEtatR = 0;
    this._displayHeaderRightText();
    tableColl.trigger("updateHeader:coll");
    tableColl.trigger("display:alert", "hide");

    this._cleanErrorsTable();
    this.workflow.btnBarModel.set("mode", "R");
  }

  _revertRow(model: CWListerEvtGenModel, all: boolean): void {
    CWSTR.setElValue(model, "commentaire", "");

    if (model.evtEtatValid === "A") {
      $(this.el).find(".checkBoxLine.A").prop("checked", false);
    } else if (model.evtEtatValid === "R") {
      $(this.el).find(".checkBoxLine.R").prop("checked", false);
    }

    model.evtEtatValid = "";

    if (all === true) {
      const comboProfil = $(this.el).find(".comboProfil_" + model.cid);
      if (comboProfil.length > 0 && model.selectedProfil && String(model.selectedProfil.code) !== "0") {
        const item = $(this.el).find(".comboProfil_" + model.cid).prop("viewRef");
        item.setItem("");
        model.selectedProfil = {
          code: "0",
          libelle: ""
        };
      }
    }
  }

  _openHeaderPopup(event: JQueryEventObject): void {
    const globalComment = this.model.get("globalComment");
    const isforced = this.model.get("isForced");//useless???
    let action = "";
    const commentrequired = this.checkRefuseEvenement();

    if (event.currentTarget.className.split(" ")[0] === "cw-icon") {
      action = event.currentTarget.className.split(" ")[2];
    } else {
      action = event.currentTarget.className.split(" ")[4];
    }

    if (this.rowDialog !== undefined) {
      this.rowDialog._closePanneau();
    }
    if (this.headerDialog === undefined) {
      this.headerDialog = new CWPanneauDeroulant({
        fixedButtons: true,
        view: CWListerEvtHeaderPopupView,
        viewData: {
          appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
          commentaire: globalComment,
          isForced: isforced,
          action: action,
          commentRequired: commentrequired,
        },
        notIconClose: false,
        closeOnClickOutside: true
      });
    } else {
      this.headerDialog.viewData = {
        commentaire: globalComment,
        isForced: isforced,
        action: action,
        commentRequired: commentrequired
      };
    }

    // set buttons
    const btn = [{
      text: i18n.t('dabsences.confirmer'),
      btnClass: 'btn btn-primary btn-withIcon bt-col-blue',
      icon: UTILS.getSVGIcon("valider", "c-panneauMenu__tickIcon", 16, null),
      click: (): void => {
        this.headerDialog.getInternalViewObject().trigger("btnResult", "btnValider");
      }
    }, {
      text: i18n.t('dabsences.annuler'),
      btnClass: 'btn btn-secondary btn-withIcon',
      icon: UTILS.getSVGIcon("croix", "", 16, null),
      click: (): void => {
        this.headerDialog.getInternalViewObject().trigger("btnResult", "btnRevert");
      }
    }];

    this.headerDialog.setButtons(btn);
    this.headerDialog.setHeight('auto');
    this.headerDialog.setWidth(500);
    this.headerDialog.model.set("globalComment", globalComment);


    const dialogModel = this.headerDialog.model;
    dialogModel.trigger("dlg:open", (): void => {
      const isForced = dialogModel.get("isForced");

      if (dialogModel.get("isCommentChanged") === true || isForced === true) {
        this.removeGlobalComment(dialogModel.get("globalComment"), action, isForced);
      }

      if (CWSTR.isBlank(dialogModel.get("globalComment"))) {
        if (action === "R") {
          this.$el.find(".btn-validations." + action).removeClass("selectionne").addClass("nonSelectionne");
          this._manageAllRows(null, false);
          this._manageRows();
          this._revertTable();
        }
      }
    });
    this.headerDialog._setTitle(i18n.t('dregularisations.commentaireGlobalPopup'));
    event.stopPropagation();
  }

  _openRowPopup(event: JQueryEventObject): void {
    const id = event.currentTarget.className.split(" ")[4];
    const model = this.table.model.coll.get(id) as CWListerEvtGenModel;
    const action = event.currentTarget.className.split(" ")[3];
    const comment = !CWSTR.isBlank(model.get("commentaire")) ? model.get("commentaire") : "";
    const refEvenement = model.get("evenement");

    if (this.headerDialog !== undefined) {
      this.headerDialog._closePanneau();
    }
    if (this.rowDialog === undefined) {
      this.rowDialog = new CWPanneauDeroulant({
        fixedButtons: true,
        maxHeight: 360,
        view: CWListerEvtRowPopupView,
        viewData: {
          appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
          commentaire: comment,
          action: model.evtEtatValid,
          refEvenement: refEvenement,
          workflow: this.workflow,
        },
        notIconClose: false,
        closeOnClickOutside: true
      });
    } else {
      this.rowDialog.viewData = {
        appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
        commentaire: comment,
        action: model.evtEtatValid,
        refEvenement: refEvenement,
        workflow: this.workflow,
      };
    }

    this.rowDialog.setPosition({
      my: "right top",
      at: "right bottom",
      of: $(event.currentTarget),
    });

    // set buttons
    const btn = [{
      text: i18n.t('dabsences.confirmer'),
      btnClass: 'btn btn-primary btn-withIcon bt-col-blue',
      icon: UTILS.getSVGIcon("valider", "c-panneauMenu__tickIcon", 16, null),
      click: (): void => {
        this.rowDialog.getInternalViewObject().trigger("btnResult", "btnValider");
      }
    }, {
      text: i18n.t('dabsences.annuler'),
      btnClass: 'btn btn-secondary btn-withIcon',
      icon: UTILS.getSVGIcon("croix", "", 16, null),
      click: (): void => {
        this.rowDialog.getInternalViewObject().trigger("btnResult", "btnRevert");
      }
    }];

    this.rowDialog.setButtons(btn);

    this.rowDialog.setHeight("360");
    this.rowDialog.setWidth("auto");

    this.rowDialog.model.set("commentaire", model.get("commentaire"));
    this.rowDialog.model.trigger("dlg:open", (): void => {
      if (this.rowDialog.model.get("isCommentChanged") === true) {
        const commentaire = this.rowDialog.model.get("commentaire");
        model.set("commentaire", commentaire, { silent: true });
        if (CWSTR.isBlank(commentaire)) {
          this.$el.find(".btn-commentaire-bulle." + id).removeClass("selectionne");
          this.table.model.multiselectColl.remove(model);
          this.refreshElements(model);
        } else {
          this.$el.find(".btn-commentaire-bulle." + id).addClass("selectionne");
        }
      } else {
        const commentaire = this.rowDialog.model.get("commentaire");
        const modelSelected = this.table.model.multiselectColl.get(model) as CWListerEvtGenModel;
        if (!CWSTR.isBlank(modelSelected)) {
          if (CWSTR.isBlank(commentaire) && modelSelected.evtEtatValid === "R") {
            this.table.model.multiselectColl.remove(model);
            this.refreshElements(model);
            model.evtEtatValid = null;
            $(this.el).find("." + action + "." + id).removeClass("selectionne").addClass("nonSelectionne");
          }
        }
      }
      this._manageRows(); //select - deselect row
    });
    const titlePopup = this.setPopupTitle(model, action);
    this.rowDialog._setTitle(titlePopup);
    event.stopPropagation();
  }

  /**
   * When we click the comment (bulle) icon at each ROW
   */
  _commentairePopUp(event: JQueryEventObject): void {
    const id = event.currentTarget.className.split(" ")[3];
    let model = this.table.model.coll.get(id) as CWListerEvtGenModel;
    const action = event.currentTarget.className.split(" ")[2];

    // needed for correction when they send
    // wrong character "/" in code. (WS)

    if (CWSTR.isBlank(model)) {
      //customer 168040 '/' character can be in all places of the string
      model = this._getEvenementModel(id);
    }

    const comment = !CWSTR.isBlank(model.get("commentaire")) ? model.get("commentaire") : "";
    const refEvenement = model.get("evenement");
    const evtEtatValid = model.evtEtatValid;

    if (this.headerDialog !== undefined) {
      this.headerDialog._closePanneau();
    }
    if (this.rowDialog === undefined) {
      this.rowDialog = new CWPanneauDeroulant({
        fixedButtons: true,
        maxHeight: 360,
        view: CWListerEvtRowPopupView,
        viewData: {
          appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
          commentaire: comment,
          refEvenement: refEvenement,
          action: action,
          evtEtat: evtEtatValid,
          workflow: this.workflow
        },
        notIconClose: false,
        closeOnClickOutside: true
      });
    } else {
      this.rowDialog.viewData = {
        appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
        commentaire: comment,
        refEvenement: refEvenement,
        workflow: this.workflow,
        evtEtat: evtEtatValid,
        action: action,
      };
    }

    this.rowDialog.setPosition({
      my: "right top",
      at: "right bottom",
      of: $(event.currentTarget),
    });

    // set buttons
    const btn = [{
      text: i18n.t('dabsences.confirmer'),
      btnClass: 'btn btn-primary btn-withIcon bt-col-blue',
      icon: UTILS.getSVGIcon("valider", "c-panneauMenu__tickIcon", 16, null),
      click: (): void => {
        this.rowDialog.getInternalViewObject().trigger("btnResult", "btnValider");
      }
    }, {
      text: i18n.t('dabsences.annuler'),
      btnClass: 'btn btn-secondary btn-withIcon',
      icon: UTILS.getSVGIcon("croix", "", 16, null),
      click: (): void => {
        this.rowDialog.getInternalViewObject().trigger("btnResult", "btnRevert");
      }
    }];

    this.rowDialog.setButtons(btn);

    this.rowDialog.setHeight("360");
    this.rowDialog.setWidth("auto");

    const titlePopup = this.setPopupTitle(model, action);
    this.rowDialog._setTitle(titlePopup)

    this.rowDialog.model.trigger("dlg:open", (): void => {
      if (this.rowDialog.model.get("isCommentChanged") === true) {
        const commentaire = this.rowDialog.model.get("commentaire");
        model.set("commentaire", commentaire, { silent: true });
        if (CWSTR.isBlank(commentaire)) {
          this.$el.find(".btn-commentaire-bulle." + id).removeClass("selectionne");
          model.individualComment = false;
        } else {
          this.$el.find(".btn-commentaire-bulle." + id).addClass("selectionne");
          model.individualComment = true;
        }
      }
    });

    event.stopPropagation();
  }

  /**
 * JMBS: Function to decide the title of the panneu de roulant of commentaires
 * @param model the model of the evenement (change the model of the TAB (absence, regul...))
 * @param action the action in which depends the title of the popup
 */
  private setPopupTitle(model: CWListerEvtGenModel, action: string): string {
    let titlePopup = "";
    if (model.get("etat") === "X") { //Demande de supression
      if (action === "A") {
        titlePopup = i18n.t('valevt.accepter_supress_title_popup').toUpperCase();
      }
      else if (action === "R") {
        titlePopup = i18n.t('valevt.refuser_supress_title_popup').toUpperCase();
      } else {
        if (model.evtEtatValid === "A") {
          titlePopup = i18n.t('valevt.accepter_supress_title_popup').toUpperCase();
        } else if (model.evtEtatValid === "R") {
          titlePopup = i18n.t('valevt.refuser_title_popup').toUpperCase();
        } else {
          titlePopup = i18n.t('valevt.comentaire_title_popup').toUpperCase();
        }
      }
    }
    else { //Demande normal
      if (action === "A") {
        titlePopup = i18n.t('valevt.accepter_title_popup').toUpperCase();
      }
      else if (action === "R") {
        titlePopup = i18n.t('valevt.refuser_title_popup').toUpperCase();

      } else {//comment icon
        if (model.evtEtatValid === "A") {
          titlePopup = i18n.t('valevt.accepter_title_popup').toUpperCase();
        } else if (model.evtEtatValid === "R") {
          titlePopup = i18n.t('valevt.refuser_title_popup').toUpperCase();
        } else {
          titlePopup = i18n.t('valevt.comentaire_title_popup').toUpperCase();
        }
      }
    }
    return titlePopup;
  }

  private _getEvenementModel(id: string): CWListerEvtGenModel {
    id = id.replace(/_/g, '/');
    id = id.replace(/-/g, '+');
    const model = this.table.model.coll.get(id) as CWListerEvtGenModel;
    id = id.replace(/\//g, '_');
    id = id.replace(/\+/g, '-');
    return model;
  }

  _openTooltipListener(event: JQueryEventObject): void {
    const demande = (event.currentTarget as unknown as any).demande.value;
    const reference = (event.currentTarget as unknown as any).evenement.value;

    const jquery = (event.currentTarget as unknown as any).attributes["aria-describedby"];
    if (jquery) {
      return;
    }

    this.tooltipDemande = demande || reference;

    const infobulleWorkflow = new CWWkflhistoView();
    infobulleWorkflow.render();
    const typeLabel = "EVTGEN";
    const ecran = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? this.context.ctxEcran : "valevt";
    const contextInfobulleWkf = {
      ctxEcran: ecran,
      ctxHabilitation: this.context.ctxHabilitation.HabilitationGestion,
      ctxTypeEvenement: typeLabel,
      ctxRefEvenement: reference,
      ctxDemId: demande,
      ctxModeRestitution: "infobulle",
      ctxActivModeSynth: true,
      ctxModeDefaut: "Deplie",
      ctxModeRech: true
    };
    infobulleWorkflow.setContext(contextInfobulleWkf);

    $(event.currentTarget).tooltip({ content: "" });
    $(event.currentTarget).tooltip("option", "content", (): string => {
      const content = infobulleWorkflow.$el;
      content.find(".phx-block-title").hide();
      return content.html();
    });
    $(event.currentTarget).prop("title", "");

    infobulleWorkflow.model.get("histoDem").once("sync", (): void => {
      try {
        const demande = (event.currentTarget as unknown as any).attributes.demande.value;
        if (this.tooltipDemande === demande) {
          $(event.currentTarget).tooltip("open");
        }
      } catch (error) {
        //Nothing
      }
    });

    infobulleWorkflow.model.get("histoEvent").once("sync", (): void => {
      try {
        const reference = (event.currentTarget as unknown as any).attributes.evenement.value;
        if (this.tooltipDemande === reference) {
          $(event.currentTarget).tooltip("open");
        }
      } catch (error) {
        //Nothing
      }
    });
  }

  _closeTooltipListener(): void {
    try {
      this.tooltipDemande = null;
    } catch (error) {
      //Nothing
    }
  }

  /*
   *  Change the comment in each evtGen if it is selected and it has the old global comment.
   *
   */
  _manageComments(newCommentaire: string, isForced: boolean): void {
    const ancientCommentaire = this.model.get("globalComment") === undefined ? "" : this.model.get("globalComment");
    const tableColl = this.table.model.coll;
    _.each(tableColl.models, (tableRow: CWListerEvtGenModel) => {
      let id = tableRow.get("id");
      id = id.replace(/\//g, '_');
      id = id.replace(/\+/g, '-');
      if (isForced === true) {
        tableRow.set("commentaire", newCommentaire, { silent: true });
        tableRow.individualComment = false;
        if (CWSTR.isBlank(newCommentaire)) {
          this.$el.find(".btn-commentaire-bulle." + id).removeClass("selectionne");
        } else {
          this.$el.find(".btn-commentaire-bulle." + id).addClass("selectionne");
        }
      } else if (tableRow.get("commentaire") === ancientCommentaire && tableRow.individualComment !== true) {
        tableRow.set("commentaire", newCommentaire, { silent: true });
        if (CWSTR.isBlank(newCommentaire)) {
          this.$el.find(".btn-commentaire-bulle." + id).removeClass("selectionne");
        } else {
          this.$el.find(".btn-commentaire-bulle." + id).addClass("selectionne");
        }
      }
    });
  }

  _manageLineButtons(event: JQueryEventObject): void {
    const checked = event.currentTarget.className.includes("nonSelectionne");
    const action = event.currentTarget.className.split(" ")[3];
    const id = event.currentTarget.className.split(" ")[4];
    let variateSelection = true;
    let code = null;

    let model = this.table.model.coll.get(id) as CWListerEvtGenModel;
    // needed for correction when they send
    // wrong character "/" in code. (WS)
    if (CWSTR.isBlank(model)) {
      //customer 168040 '/' character can be in all places of the string
      model = this._getEvtModel(id);
    }

    this._manageRow(model, action, checked);
    this._manageBtnBarActivation();
    this._displayHeaderRightText();
    this._checkNbAlert();

    switch (action) {
      case "A":
        if (checked === true) {
          if ($(this.el).find(".R." + id).hasClass("selectionne")) {
            $(this.el).find(".R." + id).addClass("nonSelectionne").removeClass("selectionne");
            variateSelection = false;
            this.table.model.coll.nbEvtEtatR--;
            this.table.model.coll.nbEvtEtatValid--;
          }
          $(this.el).find("." + action + "." + id).removeClass("nonSelectionne").addClass("selectionne");
          model.evtEtatValid = "A";
        } else {
          $(this.el).find("." + action + "." + id).removeClass("selectionne").addClass("nonSelectionne");
          $(this.el).find("." + action + "." + id).blur();
          model.evtEtatValid = "";
        }
        break;
      case "R":
        if (checked === true) {
          if ($(this.el).find(".A." + id).hasClass("selectionne")) {
            $(this.el).find(".A." + id).addClass("nonSelectionne").removeClass("selectionne");
            variateSelection = false;
            this.table.model.coll.nbEvtEtatA--;
            this.table.model.coll.nbEvtEtatValid--;
          }
          $(this.el).find("." + action + "." + id).removeClass("nonSelectionne").addClass("selectionne");
          this._openRowPopup(event);
          model.evtEtatValid = "R";
        } else {
          $(this.el).find("." + action + "." + id).removeClass("selectionne").addClass("nonSelectionne");
          $(this.el).find("." + action + "." + id).blur();
          model.evtEtatValid = "";
          model.set("commentaire", "");
          $(this.el).find(".btn-commentaire-bulle." + id).removeClass("selectionne");
        }
        break;
      default:
        break;
    }

    if (variateSelection) {
      if ($(event.currentTarget).parent().find(".selectionne").length > 0 && CWSTR.isBlank(this.table.model.multiselectColl.get(id))) {
        this.table.model.multiselectColl.add(model);
      }
      else {
        this.table.model.multiselectColl.remove(model);
      }

      if (this.parent && this.parent.selectedTypo && !CWSTR.isBlank(this.parent.selectedTypo.code)) {
        code = this.parent.selectedTypo.code;
      }

      this._manageRows(); //select - deselect row
      this.tableModel.coll.trigger("updateHeader:coll", code);
    }
  }

  _manageHeaderButtons(event: JQueryEventObject | any): void {
    const checked = event.currentTarget.className.includes("nonSelectionne");
    const action = event.currentTarget.className.split(" ")[4];

    if (checked === true) {
      const tableColl = this.table.model.coll as CWEvenementModelColl;
      tableColl.nbEvtEtatValid = 0;
      tableColl.nbEvtEtatA = 0;
      tableColl.nbEvtEtatR = 0;
    }

    this._manageAllRows(action, checked);
    this._manageBtnBarActivation();
    this._displayHeaderRightText();
    this._checkNbAlert();

    switch (action) {
      case "A":
        if (checked === true) {
          this._openHeaderPopup(event);
          $(this.el).find(".btn-validations.R").addClass("nonSelectionne").removeClass("selectionne");
          $(this.el).find(".btn-validations." + action).removeClass("nonSelectionne").addClass("selectionne");
        } else {
          $(this.el).find(".btn-validations." + action).removeClass("selectionne").addClass("nonSelectionne");
          this.removeGlobalComment("", action, false);
        }
        break;
      case "R":
        if (checked === true) {
          this._openHeaderPopup(event);
          $(this.el).find(".btn-validations.A").addClass("nonSelectionne").removeClass("selectionne");
          $(this.el).find(".btn-validations." + action).removeClass("nonSelectionne").addClass("selectionne");
        } else {
          $(this.el).find(".btn-validations." + action).removeClass("selectionne").addClass("nonSelectionne");
          this.removeGlobalComment("", action, false);
        }
        break;
      default:
        break;
    }
    this._manageRows(); //select - deselect row

    this.table.model.coll.trigger("updateHeader:coll");
  }

  //select - deselect row
  _manageRows(): void {
    _.each(this.table.rows, function (row) {
      row._manageLineSelected();
    });
  }

  private _getEvtModel(id: string): CWListerEvtGenModel {
    id = id.replace(/_/g, '/');
    id = id.replace(/-/g, '+');
    const model = this.table.model.coll.get(id) as CWListerEvtGenModel;
    id = id.replace(/\//g, '_');
    id = id.replace(/\+/g, '-');
    return model;
  }

  _checkNbAlert(): void {
    let nbAlert = this.workflow.pDiversValEGalertModel.get("valeur");
    const nbChecked = this.tableModel.coll.nbEvtEtatValid;

    if (CWSTR.isBlank(nbAlert)) { nbAlert = 5; } // Default Value
    nbAlert = parseInt(nbAlert);

    if (nbChecked >= nbAlert) {
      this.tableModel.coll.trigger("display:alert", "show");
    } else {
      this.tableModel.coll.trigger("display:alert", "hide");
    }
  }

  _manageAllRows(action: string, checked: boolean): void { // action A, R, ""
    const tableColl = this.tableModel.coll;

    _.each(tableColl.models, (tableRow): void => {
      tableRow.evtEtatValid = "";
    });

    _.each(tableColl.models, (tableRow): void => {
      this._manageRow(tableRow, action, checked);
    });
  }

  _manageRow(model: CWListerEvtGenModel, action: string, checked: boolean): void { // action A, R, ""
    const tableColl = this.table.model.coll as CWEvenementModelColl;
    if (checked === true) {
      model.evtEtatValid = action === "A" ? "A" : "R";
      if (action === "A") {
        tableColl.nbEvtEtatA++;
      } else {
        tableColl.nbEvtEtatR++;
      }
      model.nbEvtEtatValid = action === "A" ? tableColl.nbEvtEtatA : tableColl.nbEvtEtatR;
      tableColl.nbEvtEtatValid++;
    } else {
      if (action === "A") {
        tableColl.nbEvtEtatA--;
      } else {
        tableColl.nbEvtEtatR--;
      }
      model.nbEvtEtatValid = action === "A" ? tableColl.nbEvtEtatA : tableColl.nbEvtEtatR;
      model.evtEtatValid = "";
      tableColl.nbEvtEtatValid--;
    }
  }

  _manageBtnBarActivation(): void {
    const tableColl = this.tableModel.coll;

    if (tableColl.nbEvtEtatValid === 0) {
      this._cleanErrorsTable();
      //this.workflow.btnBarModel.set("mode", "R");
    } /*else {
      this.workflow.btnBarModel.set("mode", "E");
    }*/
    this.workflow.btnBarModel.set("mode", "E");
  }

  _displayHeaderRightText(): void {
    const tableColl = this.tableModel.coll;

    if (tableColl.nbEvtEtatValid === 0) {
      this.tableModel.coll.trigger("display:rightText", "hide");
    } else {
      this.tableModel.coll.trigger("display:rightText", "show");
    }
  }

  _checkAllRowsActived(action: string): boolean {
    const arrayCheck = $(this.el).find(".checkBoxLine." + action);
    for (let i = 0; i < arrayCheck.length; i++) {
      if ((arrayCheck[i] as HTMLInputElement).checked === false) {
        return false;
      }
    }
    return true;
  }

  render(): CWListerEvtFormView {
    const json = { "i18n": i18n };
    this.$el.html(this.template(json));

    $(this.el).find(".customBar_DEvenements").html(this.parent.customBar.render().el);
    $(this.el).css("height", "100%");

    this.manageGridDisplay();

    return this;
  }

  private manageGridDisplay(): void {
    if (this.workflow) {
      if (this.workflow.gridCanBeDisplayed()) {
        this._showGrid();
        $(this.el).find(".header.A").attr("title", i18n.t('dabsences.tout_accepter'));
        $(this.el).find(".header.R").attr("title", i18n.t('dabsences.tout_refuser'));
        $(this.el).find(".btn-commentaire-bulle-header.C").attr("title", i18n.t('dabsences.comment_title'));
      } else {
        this._hideGrid();
      }
    }
  }
  private _showGrid(): void {
    $(this.el).find(".table_devenements").show();
  }

  private _hideGrid(): void {
    $(this.el).find(".table_devenements").hide();
  }

  _newEvtGen(): void {
    const context: { [key: string]: any } = {};
    const actualDate = new Date();
    const date = CWTYPE.DATE.dateToStr(actualDate, "YYYYMMDD");

    context.ctxUtilisateur = "Responsable";
    context.ctxTypologieEvenement = this.parent.selectedTypo;
    context.ctxHabilitation = {
      HabilitationGestion: "RES_EVTGEN.G",
      HabilitationValidation: "RES_VAL_EGEN.G",
      HabilitationAcces: "RES_EVTGEN.V"
    };
    context.ctxEvtGenIntitule = this.parent.selectedTypo.intitule;
    context.ctxEcran = this.parent.context.ctxEcran;
    //Customer 196582
    context.ctxEvtGenModeSaiDef = this.parent.selectedTypo.saidefo;
    context.ctxEvtGenSaiRepJ = this.parent.selectedTypo.sairepet;
    context.ctxModeRepresentation = "pop-up";
    context.ctxGestionCollab = {
      matricule: this.parent.context.ctxCollab.matricule,
      matric_aux: this.parent.context.ctxCollab.matric_aux, //eslint-disable-line
      nom: this.parent.context.ctxCollab.nom,
      prenom: this.parent.context.ctxCollab.prenom
    };
    context.ctxDateConsultee = "";
    context.ctxActionsPossibles = ["Creer", "Modifier", "Supprimer", "Valider"];
    context.ctxModeInitialisation = "Ajout";
    context.ctxIdentifiantEvt = "";
    context.ctxValeursEvenement = "";
    context.ctxValeursCreation = {
      EvtGenDatedebut: date,
      EvtGenDatefin: date,
      //Customer 196582
      EvtGenCode: this.parent.selectedTypo.code,
      EvtGenModeSaiDef: this.parent.selectedTypo.saidefo
    };
    context.ctxHistoriqueWKF = true;
    context.ctxGestionCollective = false;
    context.ctxPieceJointe = (this.workflow.paramDivPieceJointe.get("valeur") === "1");
    this._initPopupGererEvtGen(context);
    this.popupGererEvtGenDialog.open();
    this.stopListening(this.popupGererEvtGenDialog.internalView.model, "evtGenChanged");
    this.listenTo(this.popupGererEvtGenDialog.internalView.model, "evtGenChanged", (model: CWListerEvtGenModel, action: string): void => {
      this.refreshList();
      this.workflow.trigger("listerEvtGenUpdated");
      this.workflow.trigger("evtGenChanged", model, action);
    });
  }

  _renderTable(): void {
    this.listenTo(this.table.model.coll, "sync", (): void => {
      this.table.model.coll.forEach((model) => { //mmmmm my be this are self? mmmm
        model.off("invalid");
        model.on("invalid", this._showGridValidationErrors, self);
      });
    });

    $(".table_devenements", this.el).html(this.table.el);
    this.parent.workflow.trigger("overrideActionBarMenu");
    this.table.setButtonBarAutoRender(false);
    if ((this.context.ctxUtilisateur === this.workflow.RESPONSABLE && CWHABILITATION.canView('RES_EVTGEN.V')) ||
      (this.context.ctxUtilisateur === this.workflow.COLLABORATEUR && CWHABILITATION.canView('COL_EVTGEN.V')) ||
      (this.context.ctxEcran === "valevt" && CWHABILITATION.canView('RES_VAL_EGEN.G'))) {
      this.table.render();
      $(this.table.el).find(".statut-center").parent("div.title.cw-texteEnteteColone").addClass("statut-parent");
    }
    else {
      this.table._configureButtonBar();//Prevents if the user can create but don't can view
    }

    this.table.model.coll.trigger("display:alert", "hide");
    this.table.model.coll.trigger("display:rightText", "hide");

    this._updateHeaderVisibility();
    this.parent.workflow.trigger("manageComboProfiles");
  }

  _updateHeaderVisibility(): void {
    if (!this.workflow.showProfils) {
      this.tableModel.lock("role");
      if (this.tableModel && this.tableModel.coll && this.tableModel.coll.params &&
        _.keys(this.tableModel.coll.params).indexOf("profils") !== (-1) && this.tableModel.coll.length > 0) {
        $(this.el).find(".profilsFilter").show();
      } else {
        $(this.el).find(".profilsFilter").hide();
      }
    } else {
      this.tableModel.unlock("role");
      $(this.el).find(".profilsFilter").show();
    }

    if (this.workflow.context.ctxGestionCollective) {
      this.tableModel.unlock("collaborateur");
    } else {
      this.tableModel.lock("collaborateur");
    }

    if (this.context.ctxUtilisateur === "Responsable") {
      if (this.workflow.paramDivPieceJointe.get("valeur") !== "1") {
        this.tableModel.lock("pj");
      } else {
        this.tableModel.unlock("pj");
      }
    }
  }

  _clickListener(event: JQueryInputEventObject): void {
    const btnClicked = (event.currentTarget as HTMLInputElement).value;
    this.model.get("progressBarModel").trigger("btn:click", btnClicked);
  }

  _changeProgressBar(model: Model, treated: number): void {
    const maxValue = this.model.get("progressBarModel").get("max");


    if (this.dialogProgressBar === undefined) {
      this.dialogProgressBar = new CWPanneauDeroulant({
        fixedButtons: true,
        view: CWProgressBarView,
        viewData: {
          appendTo: this.el,
          workflow: this.workflow,
        },
        notIconClose: true,
        notTitle: true,
        closeOnClickOutside: false
      });
    }

    this.dialogProgressBar.setHeight("auto");
    this.dialogProgressBar.setWidth(515);
    if (this.progressBarOpened === false) {
      this.progressBarOpened = true;
      this.dialogProgressBar.model.trigger("dlg:open");
    }
    if (!CWSTR.isBlank(this.dialogProgressBar.getInternalViewObject())) {
      this.dialogProgressBar.getInternalViewObject().trigger('changeProgressBar', treated, maxValue);
    }
  }

  _validationFinnished(): void {

    const tableColl = this.table.model.coll as CWEvenementModelColl;
    tableColl.nbEvtEtatValid = 0;
    tableColl.nbEvtEtatA = 0;
    tableColl.nbEvtEtatR = 0;
    //deselect the header buttons and icons
    this.table.$el.find(".validation span.btn-validations.header").removeClass("selectionne");
    this.table.$el.find(".validation span.btn-validations.header").addClass("nonSelectionne");
    this.table.$el.find(".btn-commentaire-bulle-header").removeClass("selectionne");
    //launch the popup with the resume
    if (this.workflow && this.workflow.module === "valevt") {
      this.workflow.trigger("updateButtonText");
    }
    this.table.model.coll.fetch({
      success: (fresh: CWEvenementModelColl) => {
        if (this.workflow && this.workflow.module !== "valevt") {
          this.workflow.trigger("updateButtonText", fresh.models.length.toString());
        }
        this.workflow.evenementsColl.models = fresh.models;
        this._initTypoCombo(this.parent.selectedTypo.code);
        fresh.trigger("reset");

        this.table.model.coll.trigger("manage:header", "show");
        this.table.model.coll.trigger("updateHeader:coll");

        this.dialogProgressBar._closePanneau(null, null);
        this.progressBarOpened = false;
        this._initPopupResumeView(this.model.nObjErrors);
        this.table.clearSelectedRows();
        this.model.set("globalComment", "");
      },
      silent: true
    });
  }

  _showGridValidationErrors(model: CWListerEvtGenModel, errors: { [key: string]: any }): void {
    if (errors && _.has(errors, "errorValidation") && _.has(errors, "errors")) {
      const fieldErrors = errors.errors;
      const comboName = "comboProfil_" + model.get("code").replace("/", "_");
      const combo = $(this.el).find("." + comboName);
      const tr = combo.closest('tr');
      CWFORMS.showErrors(tr, fieldErrors);
    }
  }

  _setOldProfils(coll: CWEvenementModelColl, oldColl: CWEvenementModelColl): void {
    _.each(coll.models, function (model) {
      if (model.get("profils").length > 1 && !CWSTR.isBlank(oldColl)) {
        const oldModel = oldColl.get(model.id);
        if (!CWSTR.isBlank(oldModel) && !CWSTR.isBlank(oldModel.selectedProfil)) {
          model.selectedProfil = oldModel.selectedProfil;
        }
      } else {
        model.selectedProfil = model.get("profils")[0];
      }
    });
  }

  _initTypoCombo(code: string): void {
    let typoOptions = this.tableModel.coll.typo;
    typoOptions = _.map(typoOptions, function (typo) {
      const libelle = typo.groupe + " (<span>" + typo.nbre + "</span>)";
      return { code: typo.code, libelle: libelle };
    });

    this.parent.customBar.initTypoCombo(typoOptions, code);
  }

  _initTable(code: string): void {
    let tblColumns: Array<CWBaseGridColums> = [];
    const caractEvt: { [key: string]: any } = [];
    let appendClassToRow: string = "";
    let countColumns: number = 0;
    let periodeWidth: number = 0;
    let firstString: boolean = true;
    const orderByGroupe = true; //Change to false  to order by libelle
    const typoOptions = this.tableModel.coll.typo;
    let selectedTypo: { [key: string]: any } = {};
    const itemPerPage = this.workflow.context.ctxValidation === true ? parseInt(GLOBAL_DATA.paramDivers.get("PgNbValEG").get("valeur"), 10) : parseInt(GLOBAL_DATA.paramDivers.get("PgNbLstEG").get("valeur"), 10);
    const emptyMessage: string = this.workflow.context.ctxGestionCollective === true ? i18n.t('messages:GT_2055') : i18n.t('messages:GT_2056');
    let sortableColumns = [];
    let table: CWMenuGridView<CWListerEvtGenModel, CWEvenementModelColl> = null;

    if (!_.isEmpty(typoOptions) && typoOptions.length > 0) {
      selectedTypo = typoOptions[0];
      if (!CWSTR.isBlank(code)) {
        const findTypo = _.find(typoOptions, (item: { [key: string]: any }): boolean => {
          return item.code === code;
        });

        if (!_.isEmpty(findTypo)) {
          selectedTypo = findTypo;
        }
      } else {
        code = selectedTypo.code;
      }
    }
    // Sort typologies by name
    typoOptions.sort(function (a, b) {
      if (orderByGroupe) {
        if (a.groupe > b.groupe) {
          return 1;
        }
        if (a.groupe < b.groupe) {
          return -1;
        }
      }
      else {
        if (a.libelle > b.libelle) {
          return 1;
        }
        if (a.libelle < b.libelle) {
          return -1;
        }
      }
      // a must be equal to b
      return 0;
    });
    this.parent.selectedTypo = selectedTypo;
    this.context.ctxTypologieEvenement = selectedTypo;
    this.workflow.trigger("selectedTypoChanged", selectedTypo);
    this._initTypoCombo(code);
    _.each(this.tableModel.coll.models, function (value) {
      value.collection.typo = typoOptions;
    });
    if (this.table) {
      this.table.model.off();
      this.table.undelegateEvents();
      this.table.remove();
    }
    this.tableModel.coll.trigger("reset");
    //le "selectedTypo" peut avoir différent numéro de colonnes
    selectedTypo.caracol = this.getIdxEvt(code);
    // Columns: Caractéristique personnalisable
    //is there is not any column --> periode gets its width
    if (CWSTR.isBlank(selectedTypo.caracol) || selectedTypo.caracol.length === 0) {
      periodeWidth = 4;
    }
    if (this.context.ctxGestionCollective === true && this.context.ctxValidation === true) {
      tblColumns = [
        { title: i18n.t('common:listerevtgen.collaborateur'), property: "collaborateur", width: 3 },
        { title: i18n.t('common:listerevtgen.periode'), property: "periode", width: (3 + periodeWidth) as validsWidthTypesForGridsColums }
      ];
      appendClassToRow = "hasValidationButtons";
    } else {
      tblColumns = [{ title: i18n.t('common:listerevtgen.periode'), property: "periode", width: (5 + periodeWidth) as validsWidthTypesForGridsColums }];
      appendClassToRow = "hasValidationButtons notGestColl";
    }
    if (!CWSTR.isBlank(selectedTypo.caracol) && selectedTypo.caracol.length !== 0) {
      Object.keys(selectedTypo).forEach((key: string) => {
        const typologie = (selectedTypo as { [key: string]: any })[key];

        if (key.startsWith("evtg_")) {
          const element = selectedTypo.caracol.find((element: { [key: string]: any }) => typologie.code === element.code);

          if (!_.isEmpty(element)) {
            //if we have 3 columns and a string one --> width 2 and placed first
            if (typologie.type === "C" && selectedTypo.caracol.length === 3 && firstString === true) {
              const column = { title: typologie.libelle, property: "caracol" + element.idx, width: (2) as validsWidthTypesForGridsColums };

              tblColumns.push(column);
              countColumns++;
              firstString = false;
            } else {
              caractEvt[element.idx] = typologie;
            }
          }
        }
      });
      _.each(selectedTypo.caracol, (value): boolean => {
        if (countColumns === 4) {
          return false;
        } else {
          let widthCaracol = 1;

          // if it's 2 columns --> width 2 for both, if 3 columns and not string column --> last column has width 2
          if ((selectedTypo.caracol.length === 3 && firstString === true && countColumns === 2) || selectedTypo.caracol.length === 2) {
            widthCaracol = 2;
            // with 1 column --> width 4
          } else if (selectedTypo.caracol.length === 1) {
            widthCaracol = 4;
          }
          if (!CWSTR.isBlank(caractEvt[value.idx])) {
            const column = { title: caractEvt[value.idx].libelle, property: "caracol" + value.idx, width: widthCaracol as validsWidthTypesForGridsColums };

            tblColumns.push(column);
            countColumns++;
          }
        }
        return true;
      });
    }
    if (this.context.ctxGestionCollective === true && this.context.ctxValidation === true) {
      const divColumnIcons = $("<div class='d-flex justify-content-end'>");

      divColumnIcons.append("<div class='validation offset-3 col-8'><div class='validationsContainer'>" +
        UTILS.getSVGIcon('valider', 'btn btn-round btn-validations header A nonSelectionne', null, null, true) +
        UTILS.getSVGIcon('croix', 'btn btn-round btn-validations header R nonSelectionne', null, null, true) + "</div><div class='comment cw-comment-icon'>" +
        UTILS.getSVGIcon('bulle', 'cw-icon btn-commentaire-bulle-header C', 20, null, true) + "</div></div>");
      if (!CWSTR.isBlank(selectedTypo.caracol) && selectedTypo.caracol.length > 4) {
        const divIconPoints = $("<div class='iconPoints mr-auto'>");

        divColumnIcons.prepend(divIconPoints.append(UTILS.getSVGIcon('trois_points')));
      }
      tblColumns.push({ title: divColumnIcons.html(), property: "icons", width: 2 });
    } else {
      const title = $("<div>");
      let columnStatut: CWBaseGridColums = null;

      if (!CWSTR.isBlank(selectedTypo.caracol) && selectedTypo.caracol.length > 4) {
        title.append("<div class='row'><div class='d-flex flex-row justify-content-center col-3'>" + UTILS.getSVGIcon('trois_points', 'iconPoints') + "</div><div class='statutContent col-9'>" + i18n.t('common:listerevtgen.statut') + "</div></div>");
      } else {
        title.append("<div class='statut-center'>" + i18n.t('common:listerevtgen.statut') + "</div>");
      }
      if (!UTILS.isFirefox() && !UTILS.isIE()) {
        title.find(".row").addClass("marginRight");
      }
      columnStatut = { title: title.html(), property: "statut", width: 3 as validsWidthTypesForGridsColums };
      tblColumns.push(columnStatut);
    }
    table = new CWMenuGridView<CWListerEvtGenModel, CWEvenementModelColl>({
      id: "listerevt_table",
      columns: tblColumns,
      model: this.tableModel,
      itemsPerPage: itemPerPage,
      title: " ",
      showFilteredRowsInTitle: true,
      disableAlternateRowBackground: true,
      gridHeightType: "auto",
      habilitations: "RES_VAL_EGEN.G",
      emptyMessage: emptyMessage,
      paginatorPosition: "butom",
      multiselection: true,
      hideMultiSelectionCheckBox: true,
      appendClassToRow: appendClassToRow,
      customHeaderClick: this.tableHeaderCustomClick,
      sortingUseAllColumn: true,
      defaultSortingOrder: ['datedeb', 'nom', 'prenom'],
      resizable: false,
      isNotSelectableRow: true
    });
    table.cellRenderers = this.getCellRenders(selectedTypo);
    sortableColumns = ["collaborateur", "periode"];
    _.each(selectedTypo.caracol, (value): void => {
      sortableColumns.push("caracol" + value.idx);
    });
    table.setSortableColumns(sortableColumns);
    table.setSortableCustomNames({
      "collaborateur": ["nom", "prenom"],
      "periode": "datedeb"
    });
    this.table = table;
    this.setTableSorting(this.table);
    this._enableTableModelListeners();
    this.listenTo(table.buttonBar.model, "btn:click", this._newEvtGen);
    this._renderTable();
    //add class to header dinamic columns to truncate text
    if (countColumns > 0) {
      for (let i = this.workflow.context.ctxGestionCollective === true ? 3 : 2; i < countColumns + 3; i++) {
        this.table.$el.find(".phx-th0" + i + " .title").addClass("d-inline-block text-truncate");
      }
    }
    this.parent.workflow.trigger("manageTitle");
    if (this.table.model.coll.length === 0) {
      this.parent.model.trigger("isEmpty", this);
    }
    this.manageGridDisplay();
  }

  protected getCellRenders(selectedTypo: CWEvenementModelCollTypo): { [key: string]: (model: CWListerEvtGenModel, className?: string, cell?: CWDataGridCellView) => JQuery | string | HTMLElement } {
    const cellRenderers: { [key: string]: (model: CWListerEvtGenModel, className?: string, cell?: CWDataGridCellView) => JQuery | string | HTMLElement } = {};

    // Configure sorting
    _.each(selectedTypo.caracol, (value): void => {
      cellRenderers["caracol" + value.idx] = (model: CWListerEvtGenModel, className: string, cell?: CWDataGridCellView): string => {
        const idx = parseInt(className.replace("caracol", ""));
        const caracol = _.findWhere(model.get("caracol"), { idx: idx });

        if (this.context.ctxEcran === "suivicollab") {
          if (model.get("statut").code !== "H" || this.periodeTwoLines === true) {
            cell.$el.addClass("hasEtiquette");
          } else {
            if (this.periodeTwoLines === false) {
              cell.$el.addClass("noEtiquette");
            }
          }
        }
        if (caracol) {
          const caracData = (model.collection as CWEvenementModelColl)._getTypologieCaracteristique(model.get("code"), caracol.code);

          if (caracData) {
            const error = this._findError(model.get("id"));
            let errorStyle = this.styleLabel;

            if (Object.keys(error).length > 0 && !CWSTR.isBlank(this.model.objErrors) && this.model.objErrors.length > 0) {
              errorStyle = "erreur-validation";
            }
            if (caracData.type === "D" && caracol.valeur !== null) {
              return this._textToStyle(CWTYPE.DATE.format(caracol.valeur, CWTYPE._getFormatByCode("DATE")), errorStyle);
            } else {
              if (!CWSTR.isBlank(caracData.masque)) {
                return this._textToStyle(CWTYPE.MASK.applyFormatMask(caracol.valeur, caracData.masque), errorStyle);
              } else {
                return this._textToStyle(caracol.valeur, errorStyle);
              }
            }
          }
        }
        return "";
      }
    });
    cellRenderers["collaborateur"] = (model: CWListerEvtGenModel): string => {
      const paramIdCollab = this.workflow.pDiversIdCollabModel.get("valeur");
      const profils = model.get("profils");
      let matricule = "";
      const completeCollab = model.get("nom").toUpperCase() + " " + model.get("prenom") + " (" + matricule + ")";
      const error = this._findError(model.get("id"));
      const classLabel = Object.keys(error).length > 0 ? "erreur-validation important" : "ui-phx-ihm-texte-donnees-important";

      if (profils && profils.length === 1) {
        model.selectedProfil = profils[0];
      }
      if (paramIdCollab === "matric_aux") {
        matricule = model.get("matricaux");
      } else {
        matricule = model.get("matricule");
      }
      if (CWHABILITATION.canView("RES_GCOLLAB.G") && CWHABILITATION.canView("RES_FICHE.V")) {
        const url = CWSTR.isBlank(model.get("datedeb")) ? "suivicollab/" + model.get("matricule") : "suivicollab/" + model.get("matricule") + "/" + model.get("datedeb");
        return "<a class='" + classLabel + " suivicollab-link " + url + "'>" + completeCollab + "</a>";
      } else {
        return "<span>" + completeCollab + "</span>";
      }
    };

    cellRenderers["periode"] = (model: CWListerEvtGenModel, column: string, cell: CWDataGridCellView): JQuery => {
      let ligneError = "";
      let result = null;
      let ligne1 = "";
      let ligne2 = "";
      let error: { [key: string]: any } = null;

      this.periodeTwoLines = false;
      if (!CWSTR.isBlank(model.get("datedeb")) && !CWSTR.isBlank(model.get("datefin"))) {
        const datedeb = model.get("datedeb");
        const datedebFormatted = CWTYPE.DATE.format(datedeb, CWTYPE._getFormatByCode("DATE_A"));
        const datefin = model.get("datefin");
        const datefinFormatted = CWTYPE.DATE.format(datefin, CWTYPE._getFormatByCode("DATE_A"));

        if (model.get("typesaisie") === "D" || (CWSTR.isBlank(model.get("typesaisie")) && datedeb === datefin)) {
          ligne1 += i18n.t('common:listerevtgen.periode_date', { "0": datedebFormatted, interpolation: { escapeValue: false } });
        } else if (model.get("typesaisie") === "R" || (CWSTR.isBlank(model.get("typesaisie")) && datedeb !== datefin)) {
          ligne1 += i18n.t('common:listerevtgen.periode_rep', { "0": datedebFormatted, "1": datefinFormatted, interpolation: { escapeValue: false } });
        }
      }
      if (!CWSTR.isBlank(model.get("heuredeb")) && !CWSTR.isBlank(model.get("heurefin")) && model.get("heuredeb") !== model.get("heurefin")) {
        let heureDiff = null;
        const heuredeb = CWTYPE.HOUR_MINUTE_NIGHTLY.format(model.get("heuredeb"));
        const heurefin = CWTYPE.HOUR_MINUTE_NIGHTLY.format(model.get("heurefin"));

        ligne1 += i18n.t('common:listerevtgen.heures_a', { "0": heuredeb, "1": heurefin, interpolation: { escapeValue: false } });
        if (CWSTR.isBlank(model.get("duree")) && heuredeb !== heurefin) {
          heureDiff = CWTYPE.HOUR_MINUTE.diff(model.get("heurefin"), model.get("heuredeb"));
        } else if (!CWSTR.isBlank(model.get("duree"))) {
          heureDiff = model.get("duree");
        }
        if (!CWSTR.isBlank(heureDiff) && heureDiff > 0) {
          let duree = CWTYPE.DURATION.HOUR_MINUTE.format(heureDiff);

          duree = this._textToStyle(duree.trim(), this.styleHour);
          ligne1 += " (" + duree + ")";
        }
      }
      _.each(model.get("caraevt"), (value: { [key: string]: any }): void => {
        let checkIsDate = null;
        const typologieCaracteristique = this.tableModel.coll._getTypologieCaracteristique(model.get("code"), value.code);

        if (typeof (value.valeur) === "string" && /^\d+$/.test(value.valeur)) {
          // validate return text error or empty if it is ok
          checkIsDate = CWSTR.isBlank(CWTYPE.DATE.validate(value.valeur, CWTYPE._getFormatByCode("DATE"))) || CWSTR.isBlank(CWTYPE.DATE.validate(value.valeur));
        }
        if (value.restevt === "V" && !CWSTR.isBlank(value.valeur)) {
          if (ligne2 !== "") {
            ligne2 += ", ";
          }
          if (checkIsDate) {
            ligne2 += CWTYPE.DATE.format(value.valeur, CWTYPE._getFormatByCode("DATE"));
          } else if (!CWSTR.isBlank(typologieCaracteristique.masque)) {
            ligne2 += CWTYPE.MASK.applyFormatMask(value.valeur, typologieCaracteristique.masque);
          } else {
            ligne2 += value.valeur;
          }
        } else if (value.restevt === "IV" && !CWSTR.isBlank(value.valeur)) {
          if (ligne2 !== "") {
            ligne2 += ", ";
          }

          if (checkIsDate) {
            ligne2 += value.libelle + " " + CWTYPE.DATE.format(value.valeur, CWTYPE._getFormatByCode("DATE"));
          } else if (!CWSTR.isBlank(typologieCaracteristique.masque)) {
            ligne2 += value.libelle + " " + CWTYPE.MASK.applyFormatMask(value.valeur, typologieCaracteristique.masque);
          } else {
            ligne2 += value.libelle + " " + value.valeur;
          }
        }
      });
      //errors
      error = this._findError(model.get("id"));
      if (Object.keys(error).length > 0 && !CWSTR.isBlank(this.model.objErrors) && this.model.objErrors.length > 0) {
        ligne1 = this._textToStyle(ligne1, "erreur-validation");
        ligneError = this._buildMessageError(error);
        ligne2 = this._textToStyle(ligne2, "erreur-validation");
      } else {
        ligne1 = this._textToStyle(ligne1, this.styleLabel);
        ligne2 = this._textToStyle(ligne2, "");
      }
      if (model.get("etat") === "X") {
        ligne1 += "<span class='ui-phx-demande-suppression'> " + i18n.t('common:listerevtgen.demandeSuppressionRegularisation') + "</span>";
      }
      result = $(ligne1);
      if (!CWSTR.isBlank(ligneError)) {
        const iconHelp = $("<div class='inline-info'>");

        iconHelp.attr("title", ligneError);
        iconHelp.append(UTILS.getSVGIcon('aide_bulle', 'cw-icon'));
        result.append(iconHelp);
      }
      if (model.get("indicateurpj")) {
        result.append("<span class='piece-jointe' style='padding:1em' data-evenement='" + UTILS.escapeJQueryString(model.get("evenement")) + "'>" + UTILS.getSVGIcon("trombone") + "</span>");
      }
      result.append($("<br/>" + ligne2));
      if (this.context.ctxEcran === "suivicollab") {
        if (model.get("statut").code !== "H" && CWSTR.isBlank(ligne2)) {
          cell.$el.addClass("hasEtiquette");
        } else {
          if (CWSTR.isBlank(ligne2)) {
            cell.$el.addClass("noEtiquette");
          }
        }
      }
      if (!CWSTR.isBlank(ligne2)) {
        this.periodeTwoLines = true;
      }
      return result;
    };
    cellRenderers["statut"] = (model: CWListerEvtGenModel): JQuery => {
      let type: validEtiquetteTypes;
      let subText: string = "";
      let div = null;
      let etiquetteStatut: CWEtiquette = null;

      switch (model.get("statut").code) {
        case "D":
        case "T":
        case "I": {
          type = "demande";
          break;
        } case "A": {
          type = "accepte";
          break;
        } case "H":
          return null;
        case "R": {
          type = "refuse";
          break;
        }
        default: {
          break;
        }
      }
      subText = model.get("etat") === "X" ? i18n.t('common:listerevtgen.suppression') : "";
      etiquetteStatut = new CWEtiquette({
        text: {
          enabled: model.get("statut").libelle
        },
        subText: {
          enabled: subText
        },
        state: "actif",
        type: type,
        id: model.get("code") + "_etiquette"
      });

      if (!CWSTR.isBlank(selectedTypo.caracol) && selectedTypo.caracol.length > 4) {
        div = $("<div class='row'><div class='col-3'></div><div class='col-9'><div class='d-flex flex-row justify-content-center statutContent'></div></div></div>");
        div.find(".statutContent").append(etiquetteStatut.render().el);
      } else {
        div = $("<div class='d-flex flex-row justify-content-center'></div>");
        div.append(etiquetteStatut.render().el);
      }

      return div;
    };
    cellRenderers["icons"] = (model: CWListerEvtGenModel): JQuery => {
      const iconValidation = $("<div class='validation offset-3 col-8'>");
      const iconComment = $("<div class='comment cw-comment-icon'>");
      const divButtons = $("<div class='validationsContainer'>");
      const divComment = $("<div class='commentContainer'>");
      const selectAccepter = model.evtEtatValid === "A" ? ' selectionne' : ' nonSelectionne';
      const selectRefuser = model.evtEtatValid === "R" ? ' selectionne' : ' nonSelectionne';
      let code = model.get("evenement").includes("/") === true ? model.get("evenement").replace(/\//g, '_') : model.get("evenement");
      const div = $("<div>");

      code = model.get("evenement").includes("+") === true ? model.get("evenement").replace(/\+/g, '-') : code;
      divButtons.append(UTILS.getSVGIcon('valider', 'btn btn-round btn-validations A ' + code + selectAccepter, null, null, true));
      //Add the particular case of the asterisk
      if (model.get("statut").code === "I") {
        divButtons.append($("<span class='btn-validations-asterisk'>*</span>"));
      }
      divButtons.append(UTILS.getSVGIcon('croix', 'btn btn-round btn-validations R ' + code + selectRefuser, null, null, true));
      divComment.append(UTILS.getSVGIcon('bulle', 'cw-icon btn-commentaire-bulle C ' + code /*+ selectRefuser*/, 20, null, true));
      divButtons.find(".A").attr("title", i18n.t('common:listerevtgen.accepter'));
      divButtons.find(".R").attr("title", i18n.t('common:listerevtgen.refuser'));
      divComment.find(".C").attr("title", i18n.t('common:listerevtgen.comment_title'));
      div.append(iconValidation.append(divButtons).append(iconComment.append(divComment)));
      return div;
    };

    cellRenderers["indicateurs"] = (model: CWListerEvtGenModel): JQuery => {
      if (model.get("indicateurpj") === true) {
        const icon = $("<div>");

        icon.addClass("piece-jointe").addClass("phx-grid-not-selectable-cell").addClass("inline-info");
        icon.append(UTILS.getSVGIcon('trombone', 'button cw-icon-rot'));
        if (model.get("piecesjointes").length > 1) {
          icon.attr("title", i18n.t('common:listerevtgen.pj_presences', { "0": model.get("piecesjointes").length }));
        } else {
          icon.attr("title", i18n.t('common:listerevtgen.pj_presence'));
        }
        icon.append("<div class='pj-menu-container-" + model.get("evenement") + "'></div");
        return icon;
      } else {
        return null;
      }
    };
    return cellRenderers;
  }

  private tableHeaderCustomClick(event: JQueryEventObject, table: CWBaseGridView): void {
    let state = false;

    if ($(event.currentTarget).hasClass("btn-validations")) {
      state = $(event.currentTarget).hasClass("nonSelectionne");
      if (state) {
        table.selectAllRows();
      } else {
        table.clearSelectedRows();
      }
    }
    table.model.trigger("tristate:checked", state);
  }

  _getWorkflowStyle(statut: string): string {
    switch (statut) {
      case "E":
        return "ui-phx-statut-en-cours-saisie";
      case "F":
      case "G":
        return "ui-phx-statut-en-cours-revision";
      case "C":
      case "M":
        return "ui-phx-statut-en-cours-modification";
      case "Y":
      case "Z":
        return "ui-phx-statut-a-revoir";
      case "I":
        return "ui-phx-statut-en-cours";
      case "A":
        return "ui-phx-statut-accepte";
      case "R":
        return "ui-phx-statut-refuse";
      case "H":
        return "ui-phx-statut-hors-validation";
      case "D":
        return "ui-phx-statut-demande";
      default:
        return "";
    }
  }

  _textToStyle(txt: string, style: string, linkStyle?: boolean): string {
    let newTxt = "";

    if (!CWSTR.isBlank(txt)) {
      if (linkStyle === true) {
        if (!CWSTR.isBlank(style)) {
          newTxt = "<a class='" + style + "'>" + txt + "</a>";
        } else {
          newTxt = "<a>" + txt + "</a>";
        }
      } else {
        if (!CWSTR.isBlank(style)) {
          newTxt = "<span class='" + style + "'>" + txt + "</span>";
        } else {
          newTxt = "<span>" + txt + "</span>";
        }
      }
    }
    return newTxt;
  }

  setTableSorting(table: CWMenuGridView<CWListerEvtGenModel, CWEvenementModelColl>): void {
    const paramIdCollab = this.workflow.pDiversIdCollabModel.get("valeur");

    table._manageSortingOld = table._manageSorting;
    table._manageSorting = (columnCode: string): void => {
      const oldCollection = this.tableModel.coll.clone();
      const keys = _.difference(_.keys(this.table.currentVue._columns), [columnCode]);
      _.each(keys, (key): void => {
        this.table.currentVue._columns[key].header.hideSortButton();
        this.table.currentVue._columns[key].header.$el.find(".title").removeClass("phx-state-sort");
      }, this);
      if (columnCode === "role") {
        this.tableModel.coll.comparator = (model1: CWListerEvtGenModel, model2: CWListerEvtGenModel): number => {
          if (this.isSortedAsc === false) {
            const auxModel = model1;
            model1 = model2;
            model2 = auxModel;
          }
          const libelle1 = model1.selectedProfil ? model1.selectedProfil.libelle : i18n.t('common:listerevtgen.renseigner');
          const libelle2 = model2.selectedProfil ? model2.selectedProfil.libelle : i18n.t('common:listerevtgen.renseigner');

          if (libelle1 < libelle2) {
            return -1; // model1 before model2
          } else if (libelle1 === libelle2) {
            return 0; // model1 equal model2
          } else {
            return 1; // model2 before model1
          }
        };
        this.isSortedAsc = !this.isSortedAsc;
        this.tableModel.coll.sort();
        this.tableModel.coll.trigger("reset");
        this._revertTable();
      } else if (columnCode === "collaborateur") {
        this.tableModel.coll.comparator = (model1: CWListerEvtGenModel, model2: CWListerEvtGenModel): number => {
          if (this.isSortedAsc === false) {
            const auxModel = model1;
            model1 = model2;
            model2 = auxModel;
          }
          let libelle1 = model1.get("prenom") + " " + model1.get("nom") + " ";
          let libelle2 = model2.get("prenom") + " " + model2.get("nom") + " ";

          libelle1 += (paramIdCollab === "matric_aux") ? model1.get("matricaux") : model1.get("matricule");
          libelle2 += (paramIdCollab === "matric_aux") ? model2.get("matricaux") : model2.get("matricule");

          if (libelle1 < libelle2) {
            return -1; // model1 before model2
          } else if (libelle1 === libelle2) {
            return 0; // model1 equal model2
          } else {
            return 1; // model2 before model1
          }
        };
        this.isSortedAsc = !this.isSortedAsc;
        this.tableModel.coll.sort();
        this.tableModel.coll.trigger("reset");
        this._revertTable();
      } else if (columnCode === "periode") {
        this.tableModel.coll.comparator = (model1: CWListerEvtGenModel, model2: CWListerEvtGenModel): number => {
          if (this.isSortedAsc === false) {
            const auxModel = model1;
            model1 = model2;
            model2 = auxModel;
          }
          const libelle1 = model1.get("datedeb");
          const libelle2 = model2.get("datedeb");

          if (libelle1 < libelle2) {
            return -1; // model1 before model2
          } else if (libelle1 === libelle2) {
            return 0; // model1 equal model2
          } else {
            return 1; // model2 before model1
          }
        };
        this.isSortedAsc = !this.isSortedAsc;
        this.tableModel.coll.sort();
        this.tableModel.coll.trigger("reset");
        this._revertTable();
      } else if (columnCode.indexOf("caracol") !== (-1)) {
        this.tableModel.coll.comparator = (model1: CWListerEvtGenModel, model2: CWListerEvtGenModel): number => {
          const index = parseInt(columnCode.replace("caracol", ""));

          if (this.isSortedAsc === false) {
            const auxModel = model1;
            model1 = model2;
            model2 = auxModel;
          }
          const caracol1 = model1.get("caracol");
          const caracol2 = model2.get("caracol");

          if (caracol1[index - 1] && caracol2[index - 1]) {
            const libelle1 = caracol1[index - 1].valeur;
            const libelle2 = caracol2[index - 1].valeur;

            if (libelle1 < libelle2) {
              return -1; // model1 before model2
            } else if (libelle1 === libelle2) {
              return 0; // model1 equal model2
            } else {
              return 1; // model2 before model1
            }
          }
          return null;
        };
        this.isSortedAsc = !this.isSortedAsc;
        this.tableModel.coll.sort();
        this.tableModel.coll.trigger("reset");
        this._revertTable();
      } else if (columnCode !== "a" && columnCode !== "r") {
        table._manageSortingOld(columnCode, (result): void => {
          this._revertTable();
          this._setOldProfils(result, oldCollection);
          this.tableModel.coll.trigger("reset");
        }, true);
      }
    }
  }

  _managePieceJointe(event: JQueryEventObject): boolean {
    const evenement = $(event.currentTarget).attr("data-evenement");
    const model = _.find(this.tableModel.coll.models, function (row) {
      return row.get("evenement") === evenement;
    });
    if (model.get("piecesjointes").length === 1) {
      const code = model.get("piecesjointes")[0].code;
      window.open(Configuration.restRoot + "/rest/fichier/download?id=" + encodeURIComponent(code) + "&x_token_key=" + encodeURIComponent(Configuration.x_token_key), "_blank");
    }

    if (model.get("piecesjointes").length > 1) {
      //Pum! Yes Pum! This is in old code, if you have time solve it please
      const formattedParameters = this._formatParameters(model, "phx-bold-input-style", "phx-input-style");
      const description = CWSTR.buildMessageParametres(model.get("libelle"), formattedParameters);

      if (this.pjPopupView) {
        this.pjPopupView.internalView.setPJ(model.get("piecesjointes"), description);
      } else {
        const context: { [key: string]: any } = {};
        context.ctxRefEvenement = null;
        context.ctxHabilitation = this.workflow.getHabContext();
        context.ctxEcran = "valevt";
        context.ctxGestionCollab = model.get("matricule");
        context.ctxActionsPossibles = [];
        context.ctxModeInitialisation = "Consultation";
        this.pjPopupView = this._initPopupView(context, model.get("piecesjointes"), description);
        this.pjPopupView.open((): void => {
          this.pjPopupView = null;
        });
      }
    }
    return false;
  }

  _formatParameters(model: CWListerEvtGenModel, styleDate: string, styleHour: string): { [key: string]: string } {
    const params: { [key: string]: string } = _.clone(model.get("parametres"));

    let value = "";
    // @1 absDateDebut
    value = model.get("parametres")["@absDateDebut"];
    if (!CWSTR.isBlank(value)) {
      value = CWTYPE.DATE.format(value, CWTYPE._getFormatByCode("DATE_A"));
      value = this._textToStyle(value, styleDate);
      params["@absDateDebut"] = value;
    }
    // @2 absUniteDebutLibelle
    value = model.get("parametres")["@absUniteDebutLibelle"];
    if (!CWSTR.isBlank(value)) {
      value = this._textToStyle(value, styleHour);
      params["@absUniteDebutLibelle"] = value;
    }
    // @3 absDateFin
    value = model.get("parametres")["@absDateFin"];
    if (!CWSTR.isBlank(value)) {
      value = CWTYPE.DATE.format(value, CWTYPE._getFormatByCode("DATE_A"));
      value = this._textToStyle(value, styleDate);
      params["@absDateFin"] = value;
    }
    // @4 absUniteFinLibelle
    value = model.get("parametres")["@absUniteFinLibelle"];
    if (!CWSTR.isBlank(value)) {
      value = this._textToStyle(value, styleHour);
      params["@absUniteFinLibelle"] = value;
    }
    // @5 absLibelleMotif
    value = model.get("parametres")["@absLibelleMotif"];
    if (!CWSTR.isBlank(value)) {
      value = this._textToStyle(value, styleHour);
      params["@absLibelleMotif"] = value;
    }
    // @6 absHeureDebut
    value = model.get("parametres")["@absHeureDebut"];
    if (parseInt(value) > 0) {
      value = CWTYPE.HOUR_MINUTE_NIGHTLY.format(value);
      value = this._textToStyle(value, styleHour);
      params["@absHeureDebut"] = value;
    }
    // @7 absHeurefin
    value = model.get("parametres")["@absHeureFin"];
    if (parseInt(value) > 0) {
      value = CWTYPE.HOUR_MINUTE_NIGHTLY.format(value);
      value = this._textToStyle(value, styleHour);
      params["@absHeureFin"] = value;
    }
    // @8 absHeureDuree
    value = model.get("parametres")["@absHeureDuree"];
    if (parseInt(value) >= 0) {
      value = CWTYPE.DURATION.HOUR_MINUTE.format(value);
      value = this._textToStyle(value, styleHour);
      params["@absHeureDuree"] = value;
    }
    // @9 absType
    value = model.get("parametres")["@absType"];
    if (parseInt(value) > 0) {
      value = this._textToStyle(value, styleHour);
      params["@absType"] = value;
    }
    return params;
  }

  _initPopupView(context: { [key: string]: any }, list: { [key: string]: any }, description: string): CWDialogView<CWGererPiecesJointesView> {

    const dialog = new CWDialogView<CWGererPiecesJointesView>({
      view: CWGererPiecesJointesView,
      viewData: {
        context: context,
        coll: list,
        description: description,
        modal: false,
        appendTo: this.$appendTo
      }
    });
    dialog.setHeight("auto");
    dialog.setWidth(600);
    return dialog;
  }
  /**
   * When suivi link is pressed navigate to suivi collaborateurs
   */
  _manageSuiviLink(event: JQueryEventObject): void {
    const target = event.target;

    if ($(event.currentTarget).hasClass("suivicollab-link")) {
      const ecran = target.className.split(" ")[2];
      NAV.navigate("resp", ecran, "uc", true, true);
    } else {
      if (!CWSTR.isBlank((event as any).isTrigger)) {
        const ecran = $(event.currentTarget).find(".suivicollab-link")[0].className.split(" ")[2];
        NAV.navigate("resp", ecran, "uc", true, true);
      }
    }

  }

  private tableRowChecked(numRows: number): void {
    if (this.context.ctxEcran === "valevt") {
      this.table.model.trigger("paginator", (numRows > 0 ? "hide" : "show"));
      this.workflow.trigger("tableRowChecked", numRows);
      this.workflow.filterModel.trigger(numRows > 0 ? "disableFilter" : "enableFilter");
      this.workflow.parent.customBar.disableBar(numRows > 0 ? true : false);
    }
  }

  /**
   * Open detail du evenement generique
   */
  _manageEvtGenLink(event: JQueryEventObject): void {
    let evtGenModel = this.table.model.coll.get((event as any).id);

    if (CWSTR.isBlank(evtGenModel)) {
      evtGenModel = _.find(this.table.model.coll.models, (model): boolean => {
        return (UTILS.escapeJQueryString(model.get("evenement")) === (event as any).id);
      });
    }
    if (!CWSTR.isBlank(evtGenModel) && this.context.ctxEcran === "suivicollab" && CWHABILITATION.canView('RES_EVTGEN.V')) {
      const context = this._buildContextEvenements(evtGenModel);

      this._initPopupGererEvtGen(context);
      this.popupGererEvtGenDialog.open();
      this.stopListening(this.popupGererEvtGenDialog.internalView.model, "evtGenChanged")
      this.listenTo(this.popupGererEvtGenDialog.internalView.model, "evtGenChanged", (model: CWListerEvtGenModel, action: string): void => {
        this.refreshList();
        this.workflow.trigger("listerEvtGenUpdated");
        this.workflow.trigger("evtGenChanged", model, action);
      });
    }
  }

  private _buildContextEvenements(evtGenModel: { [key: string]: any }): { [key: string]: any } {
    const context: { [key: string]: any } = {};

    if (!CWSTR.isBlank(evtGenModel)) {
      const selectedTypo = this.parent.selectedTypo;

      context.ctxUtilisateur = this.workflow.context.ctxUtilisateur;
      context.ctxTypologieEvenement = selectedTypo;
      context.ctxEvtGenModeSaiDef = selectedTypo.saidefo;
      context.ctxHistoriqueWKF = selectedTypo.soumiswkf;
      context.ctxEvtGenIntitule = selectedTypo.intitule;
      context.ctxHabilitation = {};
      if (this.workflow.context.ctxUtilisateur === this.workflow.COLLABORATEUR) {
        context.ctxHabilitation.HabilitationAcces = "";
        context.ctxHabilitation.HabilitationGestion = "COL_EVTGEN.G";
        context.ctxHabilitation.HabilitationValidation = "";
        context.ctxActionsPossibles = "";
      } else {
        context.ctxHabilitation.HabilitationAcces = "RES_EVTGEN.V";
        context.ctxHabilitation.HabilitationGestion = "RES_EVTGEN.G";
        context.ctxHabilitation.HabilitationValidation = "RES_VAL_EGEN.G";
        context.ctxActionsPossibles = ["Creer", "Modifier", "Supprimer", "Valider"];
      }
      context.ctxEcran = this.workflow.context.ctxEcran;
      context.ctxModeRepresentation = "pop-up";
      context.ctxGestionCollab = {};
      context.ctxGestionCollab.matricule = evtGenModel.get("matricule");
      context.ctxGestionCollab.matric_aux = evtGenModel.get("matricaux"); //eslint-disable-line
      context.ctxGestionCollab.nom = evtGenModel.get("nom");
      context.ctxGestionCollab.prenom = evtGenModel.get("prenom");
      context.ctxModeInitialisation = "Consultation";
      context.ctxIdentifiantEvt = evtGenModel.get("evenement");
      context.ctxValeursCreation = {};
      context.ctxValeursEvenement = {
        EvtGenCodeStatutWKF: !CWSTR.isBlank(evtGenModel.get("statut")) ? evtGenModel.get("statut").code : "",
        EvtGenEnSuppression: evtGenModel.get("etat") === "X",
        EvtGenCode: evtGenModel.get("code"),
        EvtGenTypeSaisie: evtGenModel.get("typesaisie"),
        EvtGenModeSaisie: evtGenModel.get("modesaisie"),
        EvtGenDateDebut: evtGenModel.get("datedeb"),
        EvtGenDateFin: evtGenModel.get("datefin"),
        EvtGenHeureDebut: evtGenModel.get("heuredeb"),
        EvtGenHeureFin: evtGenModel.get("heurefin"),
        EvtGenInfoComplAffect: evtGenModel.get("complement"),
        EvtGenListeInfoComplValeur: { caracol: evtGenModel.get("caracol"), caraevt: evtGenModel.get("caraevt") },
        EvtGenCommentaireWKF: evtGenModel.get("commentaire"),
        EvtGenOrigine: !CWSTR.isBlank(evtGenModel.get("origine")) ? evtGenModel.get("origine").code : ""
      };
      context.ctxGestionCollective = false;
      context.ctxHistoriqueWKF = true;
      context.ctxPieceJointe = (this.workflow.paramDivPieceJointe.get("valeur") === "1");
    }
    return context;
  }

  _initPopupGererEvtGen(context: { [key: string]: any }): CWVoletView<CWGererEvtGenView> {
    if (this.popupGererEvtGenDialog === undefined) {

      this.popupGererEvtGenDialog = new CWVoletView<CWGererEvtGenView>({
        view: CWGererEvtGenView,
        viewData: {
          context: context
        }
      });
    } else {
      this.popupGererEvtGenDialog.viewData = { context: context };
    }

    // this.popupGererEvtGenDialog.setHeight(750);
    // this.popupGererEvtGenDialog.setWidth(600);
    return this.popupGererEvtGenDialog;
  }

  _findError(objCode: string): { [key: string]: any } {
    let res: { [key: string]: any } = {};

    _.each(this.model.objErrors, (obj: { code: string }): void => {
      if (String(obj.code) === String(objCode)) {
        res = obj;
      }
    });
    return res;
  }

  _cleanErrorsTable(): void {
    this.table.$el.find(".griderror").remove();
    this.table.$el.find(".ui-state-error").removeClass("ui-state-error");
    this.workflow._checkProfils();
  }

  _buildMessageError(objError: { [key: string]: any }): string {
    const styleError = "ui-phx-msg-erreur-validation";
    let htmlError = "";
    if (objError.type === "A") {
      htmlError += i18n.t('messages:GT_1222') + ":";
    } else if (objError.type === "R") {
      htmlError += i18n.t('messages:GT_1223') + ":";
    }

    if (CWSTR.isBlank(objError.message)) {
      htmlError += i18n.t('messages:GT_1224');
    } else {
      htmlError += objError.message;
    }

    htmlError = this._textToStyle(htmlError, styleError);

    return htmlError;
  }

  _initPopupResumeView(data: { [key: string]: any }): void {
    if (this.resumeDialog === undefined) {
      this.resumeDialog = new CWDialogPopupView({
        popupType: CWDialogPopupType.INFORMATION,
        view: CWListerEvtResumePopUpView,
        viewData: {
          data: data,
          appendTo: this.$appendTo || this.el
        }
      });
    } else {
      this.resumeDialog.viewData = { data: data, appendTo: this.el };
    }

    // set buttons
    const btn = [{
      text: i18n.t('common:close'),
      btnClass: 'btn btn-primary btn-withIcon',
      icon: UTILS.getSVGIcon("croix", "", 16, null),
      click: (): void => {
        $(this.resumeDialog.dialog).dialog("close");
      }
    }];

    this.resumeDialog.setHeight("auto");
    this.resumeDialog.setWidth(620);
    this.resumeDialog.setButtons(btn);

    this.resumeDialog.model.trigger("dlg:open", (): void => {
      // Close Callback
      CWLOG.debug("callback from dialog view");
    });
    this.resumeDialog._setTitle(i18n.t('common:listerevtgen.resumePopUp_title'));

  }

  _manageRowProfil(event: JQueryEventObject): void {
    const className = event.currentTarget.className;
    let id = !CWSTR.isBlank(className.split(" ")[0].split("_")[1]) ? className.split(" ")[0].split("_")[1] : "";
    const item = $(event.currentTarget).prop("viewRef").getItem();
    let model = this.tableModel.coll.get(id);

    // needed for correction when they send
    // wrong character "/" in code. (WS)
    if (CWSTR.isBlank(model)) {
      //customer 168040 '/' character can be in all places of the string
      id = id.replace('_', '/');
      model = this.tableModel.coll.get(id);
      id = id.replace('/', '_');
    }

    if (model !== undefined) {
      if (!CWSTR.isBlank(item) && item.id !== undefined && !CWSTR.isBlank(item.id)) {
        model.selectedProfil = {
          code: item.id,
          libelle: item.label
        };
      } else {
        model.selectedProfil = {
          code: "0",
          libelle: ""
        };
      }
    }
  }

  /**
   *
   */
  private _showVolet(event: JQueryEventObject): void {
    if (this.context.ctxEcran === "valevt") {
      if ($(event.currentTarget).hasClass("ui-phx-ihm-texte-donnees") || !CWSTR.isBlank((event as any).isTrigger)) {
        const valueSearch: string = $(event.currentTarget).parents("tr").attr("cid");
        const row = this.table.rows.find((row) => { return row.cid === valueSearch })
        if (CWHABILITATION.canView('RES_EVTGEN.V')) {
          this.workflow.showVolet(row.model, this._buildContextEvenements(row.model));
        }
      }
    }
  }

  _CleanValueComboProfil(): void {
    if (this.comboSearchProfils) {
      //Nettoyer la value qui peut avoir le combobox2
      this.comboSearchProfils.setItem({ code: "", libelle: "" });
    }
  }

  getIdxEvt(code: string): Array<CWEvenementModelCollTypoCaracol> {
    let obj: Array<CWEvenementModelCollTypoCaracol> = null;
    const modelsRecherche = (this.parent?.tableModel?.coll?.models && this.parent.tableModel.coll.models.length > 0) ? this.parent.tableModel.coll.models : this.modelsEvts;
    const lfindModel = _.find(modelsRecherche, (item: CWListerEvtGenModel): boolean => {
      return (!CWSTR.isBlank(item.get("caracol")) && item.get("code") === code);
    });

    if (!_.isEmpty(lfindModel)) {
      obj = lfindModel.get("caracol");
    }
    return obj;
  }
  getGrid(): CWMenuGridView<CWListerEvtGenModel, CWEvenementModelColl> {
    return this.table;
  }

  /**
   * Here we check if we have any refuse action
   */
  private checkRefuseEvenement(): boolean {

    let checkComment = false;
    this.table.model.coll.each((itemAbsence: CWListerEvtGenModel): boolean => {
      if (itemAbsence.evtEtatValid === "R") {
        checkComment = true;
        return true;
      }
      return false;
    });
    return checkComment;
  }

  private refreshElements(model: CWListerEvtGenModel): void {
    //Set number of elements after remove model
    let nbRegEtatA = this.table.model.coll.nbEvtEtatA;
    let nbRegEtatR = this.table.model.coll.nbEvtEtatR;
    if (model.evtEtatValid === "A") {
      nbRegEtatA--;
      this.table.model.coll.nbEvtEtatA = nbRegEtatA;
    } else {
      nbRegEtatR--;
      this.table.model.coll.nbEvtEtatR = nbRegEtatR;
    }
    this.table.model.coll.nbEvtEtatValid--;
  }

  public refreshList(modelId?: string, action?: string): void {
    if (action === "delete" && !CWSTR.isBlank(modelId)) {
      let modelToSelect = null;
      const modelDeleted = this.tableModel.coll.find((model: CWListerEvtGenModel): boolean => {
        return model.get("evenement") === modelId;
      });
      const indexInCollection = this.tableModel.coll.indexOf(modelDeleted);

      //Find the previus with same typologie
      for (let aux = indexInCollection - 1; aux >= 0 && modelToSelect === null; aux--) {
        modelToSelect = this.tableModel.coll.at(aux);
        if (modelToSelect.get("code") !== modelDeleted.get("code")) {
          modelToSelect = null;
        }
      }
      if (modelToSelect === null) {
        for (let aux = indexInCollection + 1; aux < this.tableModel.coll.length && modelToSelect === null; aux++) {
          modelToSelect = this.tableModel.coll.at(aux);
          if (modelToSelect.get("code") !== modelDeleted.get("code")) {
            modelToSelect = null;
          }
        }
      }
      if (modelToSelect !== null) {
        modelId = modelToSelect.get("evenement");
      }
    }
    this.tableModel.coll.fetch({
      success: (): void => {
        this.workflow._numberEtat();
        this.workflow.evenementsColl = this.tableModel.coll.clone();
        this.workflow.refreshList();
        if (!CWSTR.isBlank(modelId)) {
          let modelSelected = this.tableModel.coll.find((model: CWListerEvtGenModel): boolean => {
            return model.get("evenement") === modelId;
          });

          if (_.isEmpty(modelSelected)) {
            modelSelected = new CWListerEvtGenModel({ id: modelId });
          }
          if (modelSelected) {
            this.tableModel.selectRow(modelSelected, (model: CWListerEvtGenModel): void => {
              if (_.isEmpty(model) && this.context.ctxEcran === "mevenements") {
                if (action !== "delete") {
                  CWMSGS.showConfirmAdapter(i18n.t('messages:GL_1041'), (result: string): void => {
                    if (result === "Y") {
                      modelId = null;
                      this.workflow.trigger("manageUnselected", true);
                    } else {
                      if (this.tableModel.coll.length === 0) {
                        this.workflow.trigger("manageUnselected", true);
                      }
                      this.workflow.trigger("collectionParams:cleaned");
                      this.stopListening(this.tableModel.coll, "sync");
                      this.listenToOnce(this.tableModel.coll, "sync", (): void => {
                        this.tableModel.selectRow(modelSelected);
                      });
                    }
                  }, false, { cancel: i18n.t('common:no'), no: i18n.t('common:no') })
                }
              }
            });
          }
        }
      },
      silent: true
    });
  }

  private manageComboSearchProfile(): void {
    if (this.workflow.showProfils && this.workflow.context.ctxEcran !== "suivicollab") {
      this.$el.find(".cmbProfil").show();
    } else {
      this.$el.find(".cmbProfil").hide();
    }
  }

  private removeGlobalComment(newCommentaire: string, action: string, isForced: boolean): void {

    this._manageComments(newCommentaire, isForced);
    this.model.set("globalComment", newCommentaire);
    this.model.set("isForced", isForced);

    if (CWSTR.isBlank(newCommentaire) || newCommentaire === null) {
      this.$el.find(".btn-commentaire-bulle-header").removeClass("selectionne");
    } else {
      this.$el.find(".btn-commentaire-bulle-header").addClass("selectionne");
    }
  }

}
