module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<fieldset class="gereractivites-detail cw-fieldset">\n    <div class="gereractivite-content c-form--fixedButtons">\n        <div class="form-group phx-formErrors ui-state-error"></div>\n        <div class="gereractivite-form-inputs c-form__scrollZone m-0">\n            <div class="actionactivite_title">\n                <span class="gereractivites-dateconsultee ">\n                    <span class="gereractivites-dateconsultee-text phx-details-header-text"></span> &nbsp; - &nbsp;\n                </span>\n                <span class="cw-titre gereractivites-intitule phx-details-header-text" style="display: inline-block"></span>\n                <span class="gereractivites-utilisateur">\n                    <span class="gereractivites-utilisateur-text c-gestionCollab cw-titreLight"></span>\n                </span>\n                <span class="actionactivite"></span>\n                <span class="gereractivites-recapitulatif ">\n                    <span class="gereractivites-recapitulatif-text"></span>\n                </span>\n            </div>\n            <!-- TODO in Vx -->\n            <!-- 				<div class="recap-link-div"><a class="recap-link"  tabindex="-1">'+
((__t=(i18n.ouvrir_recap))==null?'':__t)+
'</a>	</div> -->\n            <fieldset class="gereractivites-detail-form cw-fieldset ">\n                <div class="gereractivites-detail-events">\n                    <span class="statutActiviteLibelle">\n                        <span class="statutActiviteLibelle-text"></span>\n                    </span>\n                    <span class="typeEvenement"></span>\n                    <span class="typeSaisie"></span>\n                    <span class="modeSaisie"></span>\n                    <div class="originActif form-group">\n                        <label for="origine.code" class="cw-libelleEnrichi">'+
((__t=( UTILS.getSVGIcon("personnage", "", 16) ))==null?'':__t)+
''+
((__t=(i18n.t("common:gereractivites.origine")))==null?'':__t)+
'</label>\n                        <span class="comboOrigine"></span>\n                    </div>\n                    <div class="blockComplement form-group">\n                        <label for="complement" class="cw-libelleEnrichi">'+
((__t=( UTILS.getSVGIcon("bulle", "", 16) ))==null?'':__t)+
''+
((__t=(i18n.t("common:gereractivites.infocomplaffect")))==null?'':__t)+
'</label>\n                        <textarea class="complement form-control" maxlength="60" cols="30" rows="4"></textarea>\n                    </div>\n                    <div class="blockCommentaire form-group">\n                        <label for="commentaire" class="cw-libelleEnrichi">'+
((__t=( UTILS.getSVGIcon("bulle", "", 16) ))==null?'':__t)+
''+
((__t=(i18n.t("common:gereractivites.comment")))==null?'':__t)+
'</label>\n                        <textarea class="commentaire form-control" maxlength="60" cols="30" rows="4"></textarea>\n                    </div>\n                    <div class="gereractivites-piecesjointes form-group"></div>\n                </div>\n            </fieldset>\n            <fieldset class="cw-fieldset">\n                <span class="gereractivites-infocomp"></span>\n                <span class="message-activite"></span>\n            </fieldset>\n        </div>\n        <div class="c-form__groupCheckbox" style="margin-bottom:1px">\n            <div class="c-form__labelCheckbox attribAutoContainer h-auto d-none" title=\''+
((__t=(i18n.t("common:gereractivites.activratthor_tooltip")))==null?'':__t)+
'\'>\n                <input type="checkbox" value="true" name="addhoraireauto" class="addhoraireauto c-form__checkbox" />\n                <label for="addhoraireauto" class="c-form__label">'+
((__t=(i18n.t("common:gereractivites.activratthor")))==null?'':__t)+
'</label>\n            </div>\n        </div>\n    </div>\n    <div class="c-gererRecapContent__btnBar">\n        <ul class="validerListMenu menuContent"></ul>\n    </div>\n    <ul class="declarerListMenu menuContent"></ul>\n</fieldset>';
}
return __p;
};
