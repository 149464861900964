module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="phx-referentiel-wrap input-group">\n    <input class="phx-referentiel-input" type="text" placeholder="'+
((__t=(placeholder))==null?'':__t)+
'"/>\n    <div class="input-group-append">\n      <span class="cw-referentiel-button input-group-text">'+
((__t=(UTILS.getSVGIcon('loupe')))==null?'':__t)+
'</span>\n    </div>\n  </div>\n<div class="phx-referentiel-selection"></div>\n';
}
return __p;
};
