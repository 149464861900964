import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWCaracteristiquesCodifColl } from './cwCaracteristiques.codif.collection';
import { CWCaracteristiquesFormModel } from './cwCaracteristiquesForm.model';
import { CWComboBoxView2 } from '../combo/cwComboBoxView2';
import { CWFORMS } from 'utils/cwForms';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { UTILS } from 'utils/utils';

export interface CWCaracteristiquesFormOptions extends Backbone.ViewOptions {
  readOnly?: boolean;
  typeFormatByClass?: { [key: string]: any }; //Map<String, String>;
  typeMaskByClass?: { [key: string]: any }; //Map<String, Function>;
  infoCompName?: string;
  infoCompAttributeName?: string;
  infoCompColumns?: number;
  infoCompVisibleLabels?: boolean;
}

export interface CWCaracteristiqueParametrable {
  code: string;
  type: "C" | "N" | "D" | "J";
  libelle: string;
  valeur: string;
  ordre: number;
  oblig: boolean;
  datemin: string;
  datemax: string;
  nummin: number;
  nummax: number;
  charlongmax: number;
  masque: string;
  tda: string;
  codif: string;
  valeurcodif: { code: string; libelle: string };
}

/**
 * FormView providing methods to render fields dynamically
 * for caractéristiques paramétrables.
 *
 * Function "_loadInformationsComplementairesDynamic" needs to be
 * called to render and delegate TDA events of caractéristiques
 *
 * @infoCompName - Contains the caracteristiques HTML container selector
 * @infoCompAttributeName - Contains the attribute name of the caracteristiques inside the form model
 *
 */

export class CWCaracteristiquesForm extends CWBaseFormView {

  /**
   * Selector of the caractéristiques
   * paramétrables container
   */
  infoCompName: string;

  /**
   * Atribute name, inside the model,
   * containing the caractéristiques paramétrables
   */
  readOnly: boolean;
  typeFormatByClass: { [key: string]: any }; //Map<String, String>;
  typeMaskByClass: { [key: string]: any }; //Map<String, Function>;
  combos: { [key: string]: any }; //Array<any>;
  saisieMasse: boolean;
  infoCompAttributeName: string;
  protected infoCompColumns: number;
  protected infoCompVisibleLabels: boolean;
  protected tdaEvents: { [key: string]: any };
  protected $formGroups: JQuery[];

  constructor(viewOptions?: CWCaracteristiquesFormOptions) {
    viewOptions.events = _.extend({
      "change :input[class*='valeurcodif.code']": "_changeViewComposantCWCaractForm"
    }, viewOptions.events);
    super(viewOptions);
    this.infoCompName = '.infocomp-container';
    this.infoCompAttributeName = 'infocomp';
    this.typeFormatByClass = {};
    this.typeMaskByClass = {};
    this.combos = {};
    this.tdaEvents = {};
    this.readOnly = false;
    this.infoCompColumns = 1;
    this.infoCompVisibleLabels = true;
    this.typeFormatByClass = new Map();
    this.$formGroups = new Array<JQuery>();

    if (viewOptions) {
      if (viewOptions.infoCompName) {
        this.infoCompName = viewOptions.infoCompName;
      }
      if (viewOptions.infoCompColumns > 0) {
        this.infoCompColumns = viewOptions.infoCompColumns;
      }
      if (typeof viewOptions.infoCompVisibleLabels === "boolean") {
        this.infoCompVisibleLabels = viewOptions.infoCompVisibleLabels;
      }
      if (!CWSTR.isBlank(viewOptions.readOnly)) {
        this.readOnly = viewOptions.readOnly;
      }
      if (!CWSTR.isBlank(viewOptions.typeFormatByClass)) {
        this.typeFormatByClass = viewOptions.typeFormatByClass;
      }
      if (viewOptions.infoCompAttributeName) {
        this.infoCompAttributeName = viewOptions.infoCompAttributeName;
      }
    }
    if (viewOptions && viewOptions.model) {
      this.model.set("value", viewOptions.model);
    } else {
      this.model.set("value", new CWCaracteristiquesFormModel(null, { infoCompAttributeName: this.infoCompAttributeName }));
    }
  }

  protected _defaultTdaEvents(): any[] {
    return _.extend({},
      CWTYPE.DATE.events(this.infoCompName), CWTYPE.CUSTOM.events(this.infoCompName),
      CWTYPE.HOUR_MINUTE_SECONDS.events(this.infoCompName), CWTYPE.HOUR_MINUTE_NIGHTLY.events(this.infoCompName), CWTYPE.LONG.events(this.infoCompName),
      CWTYPE.DURATION.HOUR_MINUTE.events(this.infoCompName), CWTYPE.DURATION.HOUR_MINUTE_24.events(this.infoCompName), CWTYPE.PERCENTAGE_0.events(this.infoCompName),
      CWTYPE.DURATION.HOUR_MINUTE_SECONDS.events(this.infoCompName));
  }

  protected _syncTDAEventsDynamic(infoCompArray: Array<any>): void {
    const tdaUniques = _.uniq(_.pluck(infoCompArray, 'tda'));

    // Add type custom and date events manually
    _.defaults(this.tdaEvents, CWTYPE.CUSTOM.events(this.infoCompName));
    _.defaults(this.tdaEvents, CWTYPE.DATE.events(this.infoCompName));

    // Add other types events dynamically
    for (let i = 0; i < tdaUniques.length; i++) {
      if (!CWSTR.isBlank(tdaUniques[i])) {
        const tdaType = CWTYPE._getTypeByCode(tdaUniques[i]);
        if (tdaType && tdaType.events !== undefined) {
          // Events will be triggered only on fields inside this.infoCompName
          _.defaults(this.tdaEvents, tdaType.events(this.infoCompName));
        }
      }
    }
  }

  public _sortCaracteristiques(infoCompArray: Array<any>): Array<any> {
    return _.sortBy(infoCompArray, function (item) { return item.ordre; });
  }

  public _sortModelCaracteristiques(): void {
    const model = this._getModel();
    const infoCompArray = model.get(this.infoCompAttributeName);
    model.set(this.infoCompAttributeName, _.sortBy(infoCompArray, function (item: any) { return item.ordre; }));
  }

  /**
   * Creates necessary combos, input and date fields
   */
  public _loadInformationsComplementairesDynamic(infoCompArray: Array<any>): void {
    const model = this._getModel();

    if (!this.typeMaskByClass) {
      this.typeMaskByClass = new Map();
    }

    _.each(model.get(this.infoCompAttributeName), function (it: any) {
      if (it.type === "N" && CWSTR.isBlank(it.tda) && CWSTR.isBlank(it.masque)) {
        it.tda = "ENTLONG";
      }
    });

    if (infoCompArray === undefined) {
      infoCompArray = model.get(this.infoCompAttributeName);
    } else {
      model.set(this.infoCompAttributeName, infoCompArray);
    }

    if (infoCompArray) {

      this._sortModelCaracteristiques();

      // Sync events from fields TDA
      this._syncTDAEventsDynamic(model.get(this.infoCompAttributeName));

      // Mix events and tda events
      this.delegateEvents(_.defaults(this.events, this.tdaEvents));

      // Reset combos
      this.combos = [];

      // Reset the component
      this.$el.find(this.infoCompName).html("");
      this.$formGroups = [];

      // var infoCompOrder = infoCompArray.sortBy("ordre");
      const infoCompAttrs = model.get(this.infoCompAttributeName);

      if (infoCompAttrs && infoCompAttrs.length > 0) {
        infoCompAttrs.forEach((it: { [key: string]: any }, idx: string) => {
          this._manageFields(it, idx);
        });
        this._renderFields();
      }

      if (this.readOnly) {
        CWFORMS.setFormReadonly(this.$el.find(this.infoCompName), this.readOnly);
      }
    }
  }

  protected _manageFields(fieldData: any, fieldName: string): void {
    if (!CWSTR.isBlank(fieldData.codif)) {
      // PAINT A COMBO
      let placeholder = "";
      if (this.infoCompVisibleLabels === false) {
        placeholder = fieldData.libelle;
        if (fieldData.oblig === true) {
          placeholder += " (*)";
        }
      }
      const combo = this._createCombo(fieldName, fieldData.codif, placeholder, fieldData.type);
      this._paintField(fieldData, fieldName, combo);
      if (fieldData.oblig) {
        combo.required = true;
      }
    } else {
      let numMaxCharacter = 10;
      let inputSize = 14;
      let textarea = false;
      let $input: JQuery;
      // PAINT AN INPUT TEXT
      if (fieldData.type === "C") {
        numMaxCharacter = fieldData.charlongmax;
        inputSize = fieldData.charlongmax;
        if (numMaxCharacter === 200) {
          textarea = true;
        }
        if (UTILS.isIE()) {
          inputSize += 9;
        } else if (UTILS.isFirefox()) {
          inputSize += 2;
        }
      }
      if (fieldData.type === "D") {
        if (CWSTR.isBlank(fieldData.tda) || fieldData.tda === "DATE") {
          inputSize = 8;
        } else if (fieldData.tda === "DATE_L") {
          inputSize = 25;
        }
        if (UTILS.isIE()) {
          inputSize += 4;
        } else if (UTILS.isFirefox()) {
          inputSize += 2;
        }
      }
      //In the font size is a little bit more longer, so we need add +1 to all sizes. See JIRA  GSWTA-507
      inputSize += 1;
      if (textarea) {
        if (this.saisieMasse) {
          $input = this._createInputText(fieldName, fieldData.valeur, inputSize, numMaxCharacter);
          $input.css("width", "320px");
        } else {
          $input = this._createTextarea(fieldName, numMaxCharacter);
        }
      } else {
        $input = this._createInputText(fieldName, fieldData.valeur, inputSize, numMaxCharacter);
      }
      if (this.infoCompVisibleLabels === false) {
        let fieldLabel = fieldData.libelle;
        if (fieldData.oblig === true) {
          fieldLabel += " (*)";
        }
        $input.attr("placeholder", fieldLabel);
      }
      this._paintField(fieldData, fieldName, $input);
    }
  }

  protected _renderFields(): void {
    if (this.$formGroups.length > 0) {
      const appendRow = (): JQuery => {
        const $newRow = $("<div />").addClass("form-row");
        this.$el.find(this.infoCompName).append($newRow);
        return $newRow;
      }

      let $row = appendRow();
      let counter = 0;

      this.$formGroups.forEach(($group) => {
        if (counter === this.infoCompColumns) {
          $row = appendRow();
          counter = 0;
        }
        $group.addClass("col");
        $row.append($group);
        counter++;
      });

      // Fill last row with empty columns
      while (counter < this.infoCompColumns) {
        const $newCol = $("<div />").addClass("form-group col");
        $row.append($newCol);
        counter++;
      }

      // Initialize datepickers
      CWFORMS.setDatepicker(this, this.infoCompName + " .typeDate");
    }
  }

  /**
   * Paints the input fields or combos and their labels.
   */
  protected _paintField(fieldData: CWCaracteristiqueParametrable, fieldName: string, fieldToPaint: any): void {
    const pre = this.infoCompAttributeName;

    const $domEl = $("<div>").addClass(pre + "-field-container").addClass(pre + "-" + fieldData.code + "-container");
    if (fieldData.charlongmax === 200 && this.saisieMasse) {
      $domEl.css("max-width", "320px")
    }
    let $labelEl;
    if (!CWSTR.isBlank(fieldData.codif)) {
      $labelEl = $("<label>").attr("for", pre + "." + fieldName + ".valeurcodif.code");
    } else {
      $labelEl = $("<label>").attr("for", pre + "." + fieldName + ".valeur");
    }
    if (this.infoCompVisibleLabels === false) {
      $labelEl.addClass("sr-only");
    }
    if (fieldData.oblig) {
      $labelEl.addClass("cw-required");
    }
    $domEl.append($labelEl);

    const $formGroup = $("<div />").addClass("form-group carpers-container");
    $formGroup.append($domEl);
    $domEl.append(fieldToPaint)
    this.$formGroups.push($formGroup);

    if (!CWSTR.isBlank(fieldData.codif)) {
      if (fieldToPaint.type !== "select-one") {
        $domEl.append(fieldToPaint.render().el);
      } else {
        $domEl.append(fieldToPaint);
      }
      $labelEl.text(fieldData.libelle);

      if (fieldToPaint.setOutErrorContainer === true) {
        const $errorEl = $("<span class='" + pre + "." + fieldName + ".valeurcodif.code-error-container'>");
        $domEl.append($errorEl);
      }
    } else {
      // Paint the input fields
      if (this.saisieMasse) {
        // $domEl.append($("<span>").addClass("carpers-" + fieldName).html(fieldToPaint));
        if (UTILS.isIE()) {
          $domEl.css("max-width", "114px");
        }
      } else {
        if (fieldData.type === "D") {
          $domEl.append($('<div>').addClass('input-group').html(fieldToPaint));
        } else {
          $domEl.append(fieldToPaint);
        }
      }

      const $inputElement = $domEl.find("input." + pre + "\\." + fieldName + "\\.valeur");

      // Add typeLong mask and events changed in # customer 146014
      if (fieldData.type === "N") {
        if (!CWSTR.isBlank(fieldData.tda) && CWSTR.isBlank(fieldData.masque)) {
          $inputElement.addClass(CWTYPE._getTypeClassByCode(fieldData.tda));
        } else if (!CWSTR.isBlank(fieldData.masque)) {
          $inputElement.addClass("typeCustom");
          this.typeFormatByClass[pre + "." + fieldName + ".valeur"] = fieldData.masque;
        } else {
          $inputElement.addClass("typeLong");
        }
        $inputElement.removeAttr("maxlength");
      }

      //fills the dates if needed
      if (fieldData.type === "D") {
        const dateFieldNameNotEscaped = pre + "." + fieldName + ".valeur";
        if (!CWSTR.isBlank(fieldData.tda)) {
          $inputElement.addClass(CWTYPE._getTypeClassByCode(fieldData.tda));

          let tdaType;
          if (!CWSTR.isBlank(fieldData.tda)) {
            tdaType = CWTYPE._getTypeByCode(fieldData.tda);
            if (tdaType.format) {
              this.typeMaskByClass[dateFieldNameNotEscaped] = fieldData.tda;
            }
          }
        } else {
          $inputElement.addClass("typeDate");
          this.typeMaskByClass[dateFieldNameNotEscaped] = "DATE_A";
        }
        $inputElement.removeAttr("maxlength");
      }

      if (fieldData.type === "J") {
        if (!CWSTR.isBlank(fieldData.tda)) {
          $inputElement.addClass(CWTYPE._getTypeClassByCode(fieldData.tda));
        } else {
          $inputElement.addClass("typeJour");
        }
      }
      $labelEl.text(fieldData.libelle);
    }

    // evaluate if this must be hidden or not
    if (fieldData.libelle && fieldData.libelle.indexOf(".") === 0) {
      $domEl.hide();
    } else {
      $domEl.show();
    }

  }

  /**
   * Creates and returns a combo field.
   */
  protected _createCombo(idCombo: string, codif: string, placeholder?: string, typeInfocomp?: string): CWComboBoxView2 {
    const model = this._getModel();
    const habContext = model.getHabContext() ? model.habContext : this.model.getHabContext();
    const combo = new CWComboBoxView2({
      ws: new CWCaracteristiquesCodifColl([], { codif: codif, "typeInfocomp": typeInfocomp }),
      //width: 380,
      name: this.infoCompAttributeName + "." + idCombo + ".valeurcodif.code",
      habContext: habContext,
      setOutErrorContainer: true,
      optionsRender: (value: any): string => {
        if (!value || CWSTR.isBlank(value.code)) {
          return "";
        }
        return value.libelle + " (" + value.code + ")";
      },
      placeholder: placeholder
    });
    this.combos[idCombo] = combo;
    this.listenTo(model, "comboEdited", this._formEdited);
    return combo;
  }

  /**
   * Creates and returns an input text field.
   */
  protected _createInputText(idInput: string, value: string, size: number, maxLength: number): JQuery {
    const input = $("<input>")
      .addClass(this.infoCompAttributeName + "." + idInput + ".valeur")
      .addClass("form-control")
      .attr({ "type": "text", "maxlength": maxLength });
    //.attr({ "type": "text", "size": size, "maxlength": maxLength });
    return input;
  }

  /**
   * Creates and returns a textarea field.
   */
  protected _createTextarea(idInput: string, maxLength: number): JQuery {
    const input = $("<textarea>")
      .addClass(this.infoCompAttributeName + "." + idInput + ".valeur")
      .addClass("form-control")
      //.attr({ "maxlength": maxLength, "cols": 30, "rows": 4 })
      .attr({ "maxlength": maxLength, "rows": 4 })
      .css({
        whiteSpace: "pre-wrap"
      });
    return input;
  }

  render(): CWCaracteristiquesForm {
    if (this.template) {
      this.$el.html(this.template(null));
    }
    return this;
  }

  _changeViewComposantCWCaractForm(event: JQuery.TriggeredEvent, data: { [key: string]: any }): void {
    const className = event.target.className.split(" ")[0];

    // L'appel "this._change(event, data)" sera déjà faite par autre vue
    if (!_.isEmpty(this._getModel()) && className.indexOf(".valeurcodif.code") >= 0) {
      const tokens = className.split(".");

      if (tokens.length > 1) {
        const modelInfoComp = this._getModel().get(tokens[0]);

        modelInfoComp[tokens[1]].valeur = data;//mettre à jour l'information de "valeur" avec l'information du combo
      }
    }
  }
}
