import _ from 'underscore';
import { CWCaracteristiquesFormModel } from 'core/components/caracteristiques/cwCaracteristiquesForm.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';


/**
 * Lister Gerer Regularisations Model
 */
export class CWGererRegularisationsModel extends CWCaracteristiquesFormModel {

  isPeriode: boolean;
  matricule: boolean;
  action: string;
  profil: any;

  defaults(): { [key: string]: any } {
    return {
      "id": null,
      "iddemande": null,
      "table": "",
      "droit": null,
      "valeur": null,
      "datedeb": "",
      "datefin": "",
      "statut": {
        "code": "",
        "libelle": ""
      },
      "code": "",
      "matricule": null,
      "commentaire": "",
      "historique": null,
      "motif": {
        "code": "",
        "libelle": ""
      },
      "carpers": [],
      "origine": {
        "code": "",
        "libelle": ""
      }
    }
  }

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes);
    this.MODEGROUPE = false;
    this.infoCompAttributeName = "carpers";
    this.matricule = false;
    if (!CWSTR.isEmpty(options.matricule)) {
      this.matricule = true;
    }
    this.groupedErrors = {
      datedeb: ["datedeb", "datefin"],
      datefin: ["datedeb", "datefin"]
    };
    this.isPeriode = true;
    this.url = (): string => {
      const id = this.isNew() === true ? "" : encodeURIComponent(this.id);

      if (this.action === "saisiegroupee") {
        return Configuration.restRoot + "/rest/regul/composant/" + this.action;
      }
      if (CWSTR.isBlank(id)) {
        return Configuration.restRoot + "/rest/regul/composant/" + this.action;
      }
      return Configuration.restRoot + "/rest/regul/composant/" + this.action + "/" + id;
    };
  }

  validate(attrs: { [key: string]: any }, options: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};
    let errorMsg;

    if (CWSTR.isBlank(attrs.motif.code)) {
      errors.motif = {};
      errors.motif.code = i18n.t('common:required', { "0": i18n.t('common:gererregularisations.motif') });
    }
    if (this.matricule && CWSTR.isBlank(attrs.matricule)) {
      errors.matricule = i18n.t('common:required', { "0": i18n.t('common:gererregularisations.collab') });
    }
    if (this.isPeriode === true) {
      const individual = (options && options.individual && options.individual === true);

      //datedeb
      if (((options.editedAttr === "datedeb" && individual) || !individual) && CWSTR.isBlank(attrs.datedeb)) {
        errors.datedeb = i18n.t('common:required', { "0": i18n.t('common:gererregularisations.datedebut') });
      } else {
        errorMsg = CWTYPE.DATE.validate(attrs.datedeb);
        if (!CWSTR.isBlank(errorMsg)) {
          errors.datedeb = errorMsg;
        }
      }
      //datefin
      if (((options.editedAttr === "datefin" && individual) || !individual) && CWSTR.isBlank(attrs.datefin)) {
        errors.datefin = i18n.t('common:required', { "0": i18n.t('common:gererregularisations.datefin') });
      } else {
        errorMsg = CWTYPE.DATE.validate(attrs.datefin);
        if (!CWSTR.isBlank(errorMsg)) {
          errors.datefin = errorMsg;
        }
      }
      //period
      if (!CWSTR.isBlank(attrs.datedeb) && !CWSTR.isBlank(attrs.datefin)) {
        const errorDateFin = CWTYPE.DATE.validatePeriod(attrs.datedeb, attrs.datefin, { editedAttr: "datefin", individual: true });

        if (!CWSTR.isNull(errorDateFin)) {
          if (!CWSTR.isBlank(errorDateFin.datedeb)) {
            errors.datedeb = errorDateFin.datedeb;
          }
          if (!CWSTR.isBlank(errorDateFin.datefin)) {
            errors.datefin = errorDateFin.datefin;
          }
        }
      }
    } else {
      if (CWSTR.isBlank(attrs.datedeb)) {
        errors.datedeb = i18n.t('common:required', { "0": i18n.t('common:gererregularisations.datedebut_le') });
      } else {
        errorMsg = CWTYPE.DATE.validate(attrs.datedeb);
        if (!CWSTR.isBlank(errorMsg)) {
          errors.datedeb = errorMsg;
        }
      }
    }
    if (CWSTR.isBlank(attrs.valeur)) {
      errors.valeur = i18n.t('common:required', { "0": i18n.t('common:gererregularisations.valeur') });
    } else {
      if (attrs.motif && attrs.motif.typevaleur) {
        switch (attrs.motif.typevaleur) {
          case 1:
            errorMsg = CWTYPE.DURATION.HOUR_MINUTE.validate(attrs.valeur);
            break;
          case 2:
            errorMsg = CWTYPE.DURATION.HOUR_HUNDREDTH.validate(attrs.valeur);
            break;
          case 3:
            errorMsg = CWTYPE.LONG.validate(attrs.valeur);
            break;
          default:
            errorMsg = undefined;
            break;
        }
        if (!CWSTR.isBlank(errorMsg)) {
          errors.valeur = errorMsg;
        }
      }
      if (CWSTR.isBlank(errors.valeur)) {
        if (!CWSTR.isBlank(attrs.motif?.mask) && CWTYPE.CUSTOM.validateWithMask(attrs.valeur, attrs.motif.mask) === false) {
          errors.valeur = i18n.t('common:fieldformat', { "0": attrs.valeur, "1": attrs.motif.mask });
        } else if (CWSTR.isBlank(attrs.motif?.mask) && !CWSTR.isBlank(CWTYPE.CUSTOM.validate(attrs.valeur))) {
          errors.valeur = CWTYPE.CUSTOM.validate(attrs.valeur);
        }
      }
    }
    // Validate carpers attributes
    _.extend(errors, this._validateInfoComp(attrs));
    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  parse(response: { [key: string]: any }): { [key: string]: any } {
    if (this.MODEGROUPE === false) {
      if (CWSTR.isBlank(response.id) && !CWSTR.isBlank(response.code)) {
        response.id = response.matricule + "," + response.code + "," + response.table;
      }
    }
    if (response.result) {
      return null;
    }
    return response;
  }
}
