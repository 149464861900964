import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseCollection } from './cwBase.collection';
import { CWBaseModel } from './cwBase.model';
import { CWMSGS } from 'utils/cwMsgs';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';


export class CWPaginatedCollection<TModel extends CWBaseModel = CWBaseModel> extends CWBaseCollection<TModel> {
  /**
     * Name of the field(s) that are ids of the models included in the
     * collection.
     */
  public idnames: { [key: string]: any };

  /**
   * Params used at URL to filter and sort the collection
   */
  private _params: { [key: string]: any }; // {code:2000,label:"test",...}
  /**
   * pagination - Index and size of pagination
   **/
  public pagination: {
    startIndex: number;
    size: number;
    gruped?: boolean;
    ROWS_FOR_FILE?: number;
    itemsPerPage?: number;
    type?: string;//valeurs du type de pagination: "page", "liste"(par défaut)
  };
  /**
   * Fields set to true will be sorted
   */
  public sortings: { [key: string]: any }; // pairs {code:true, libelle:false...}
  /**
   * Number of total records in the collection
   */
  public totalRecords: number;
  // Pagination order
  public ajaxRequests: any;

  private _paginated: boolean;

  //Omitted filter params
  public omittedParams: Array<string>;
  private _lastSync: JQueryAjaxSettings | any;

  //Fix error on grids:
  public editModeCellSelected: any;
  //Fix error on cwEditableTree.model
  public seuilsParMetier: any;

  /**
   * Constructor
   * Paginated collection for the application. Manage also filtering and sorting
   */
  constructor(models?: TModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWBaseModel;
    super(models, options);
    this.idnames = ["code"];
    this.params = {}; // {code:2000,label:"test",...}
    this.pagination = {
      startIndex: 0,
      size: Configuration.pageSize
    };
    this.sortings = {}; // pairs {code:true, libelle:false...}
    this.totalRecords = 0;
    this.ajaxRequests = null;
    this._paginated = (options.paginated === false) ? false : true;
    this.omittedParams = [];
    this.omittedParams.push("target");
  }

  /**
   * get the pagination collection status
   */
  get paginated(): boolean {
    return this._paginated;
  }
  /**
   * Set to true when this collection is paginated (true by default)
   */
  set paginated(value: boolean) {
    this._paginated = value;
    this.trigger("change:pagination", value);
  }

  setOmittedParams(params: { [key: string]: any }): void {
    if (params) {
      // clean Omitted filter params
      this.omittedParams = [];
      this.omittedParams.push("target");
      for (const i in params) {
        if (this.omittedParams.indexOf(i) === -1) {
          this.omittedParams.push(i);
        }
      }
    }
  }

  /**
   * Calls standar Backbone collection reset to replace a collection with a new list of models
   */
  reset(models?: TModel[], options?: any): TModel[] {
    this.totalRecords = 0;
    return super.reset(models, options);
  }

  /**
   * Find a model inside the whole collection, and paginate to the required
   * record if needed
   */
  findAndGo(model: TModel, callback: (...args: any[]) => void, modeforced?: boolean): JQueryXHR {
    const fetchDataEmpty: JQueryXHR = null;
    let queryIds = "";

    if (model instanceof Backbone.Collection) {
      return fetchDataEmpty;
    }
    /*modeforced values
    null ou undefined -> cas normal (il vient d'un "fetch")
    true -> il vient de scrollToRow et il faut faire une nouvelle pétition (par le tri appliqué ou autres cirscontances)
    false -> il vient de scrollToRow et il ne faut pas faire une nouvelle pétition(un cas spécial)-> le modèle n'existe pas et il faut éviter une deuxième pétition
    */
    if ((!_.isBoolean(modeforced) && this.indexOf(this.get(model)) !== -1) || modeforced === false) {
      if (callback) {
        callback();
      }
      return fetchDataEmpty;
    }
    if (!model) {
      return fetchDataEmpty;
    }

    let ids = model.id;
    if (_.isString(ids)) {
      ids = model.id.split(",");
    } else {
      ids = [];
      ids.push(model.id);
    }
    _.each(this.idnames, function (value, index) {
      if (!_.isEmpty(queryIds)) {
        queryIds += ",";
      }
      queryIds += value + ";" + ids[index];
    });

    this.pagination.startIndex = 0;
    this.pagination.startIndex = 0;
    if (_.isUndefined(this.params) || _.isNull(this.params)) {
      this.params = {};
    }
    this.params.target = queryIds;
    return this.fetchPagination({
      success: (): any => {
        // Manages if the user should be asked to clean the filter
        let asking = false;

        const methodEnding = (selectOld?: boolean): void => {
          this.params.target = "";
          if (callback) {
            callback(selectOld);
          }
        };
        // const index = this.indexOf(model.id);
        //if (index < 0 && (!(model.get("ext") === "ext"))) {
        const index = this.models.find((collModel) => { return String(collModel.id) === String(model.id); });
        if (CWSTR.isBlank(index) && (!(model.get("ext") === "ext"))) { //Sorry the second part of the if i can't find a case, so is not tested
          // Check if there is a search
          const search = _.keys(_.omit(this.params, this.omittedParams)).length;
          if (search > 0) {
            const optionsText = { yes: i18n.t('common:yes'), no: i18n.t('common:no') };

            asking = true;
            CWMSGS.showConfirmAdapter(i18n.t('messages:GL_1041'), (result: string): void => {
              if (result === "C") {
                this.params = _.pick(this.params, this.omittedParams);
                this._resetOriginalParams();
                this.fetchPagination({
                  success: (): void => {
                    const lNotIsButton = true;

                    this.trigger("collectionParams:beforeCallback", lNotIsButton, "findAndGo");//on va ajouter le nom de la méthode afin de savoir l'origine du trigger et faire ou pas, autres actions.
                    methodEnding();
                    this.trigger("collectionParams:cleaned", lNotIsButton, "findAndGo");//on va ajouter le nom de la méthode afin de savoir l'origine du trigger et faire ou pas, autres actions.
                  },
                  error: (): void => {
                    methodEnding();
                  }
                });
              } else {
                methodEnding(true);
              }
            }, false, optionsText);
          }
        }

        if (!asking) {
          methodEnding();
        }
      }
    });

  }

  /**
   * This method reset the seach params when the collection has fixed parameters
   * and they are modified by a filter
   */
  _resetOriginalParams(): void {
    //Override it when the params will need to be reseted.
  }

  /**
   * Go to record at index passed as argument: "index"
   */
  goTo(index: number, callback: (collection: CWPaginatedCollection) => void): void {
    this.pagination.startIndex = index;
    this.fetchPagination({
      success: (fresh: CWPaginatedCollection) => {
        if (callback) {
          callback(fresh);
        }
      }
    });
  }

  /**
   * Go forward the number of records passed as argument: "number"
   */
  next(number: number, callback: () => void): void {
    this.pagination.startIndex += number;
    this.fetchPagination({
      success: function () {
        if (callback) {
          callback();
        }
      }
    });
  }

  /**
   * Go back the number of records passed as argument: "number"
   */
  prev(number: number, callback: () => void): void {
    this.pagination.startIndex -= number;
    this.fetchPagination({
      success: function () {
        if (callback) {
          callback();
        }
      }
    });
  }

  /**
   * Overrides the fetch to prepare the parameters before calling the server
   *  responds fetch without errors
   */
  fetch(options?: { [key: string]: any }): JQueryXHR {
    let oldSilent = false;
    const urlParams = this._prepareUrlParams();
    options = options ? _.clone(options) : {};
    if (!options.type || options.type === "GET") {
      options.data = urlParams;
    }
    if (CWSTR.isBlank(options.reset) || !_.isBoolean(options.reset)) {
      options.reset = true;
    }
    if (options.silent === true) {
      oldSilent = true;
    }
    options.silent = true;
    options.success = ((callback, self): any => {
      return (model: TModel, response: { [key: string]: any }, options2: { [key: string]: any }): any => {
        options.silent = false;

        if (oldSilent !== true) {
          self.trigger('reset', self, options2);
        }
        if (callback) {
          callback(model, response, options2);
        }
      };
    })(options.success, this);
    this.ajaxRequests = urlParams;
    return CWBaseCollection.prototype.fetch.call(this, options);
  }

  /**
   * Fetch used when we want to paginate. It will do a lazy data rendering
   */
  fetchPagination(options: { [key: string]: any }): JQueryXHR {
    let oldSilent = false;
    const urlParams = this._prepareUrlParams();
    options = options ? _.clone(options) : {};
    if (!options.type || options.type === "GET") {
      options.data = urlParams;
    }
    // Store old index to control the pagination.
    options.oldIndex = urlParams.index;

    if (CWSTR.isBlank(options.reset) || !_.isBoolean(options.reset)) {
      options.reset = true;
    }
    if (options.silent === true) {
      oldSilent = true;
    }
    options.silent = true;
    options.success = ((callback, self): any => {
      return (model: TModel, response: { [key: string]: any }, options: { [key: string]: any }): any => {
        if (options.oldIndex === 0 || self.ajaxRequests.index === options.oldIndex) {
          options.silent = false;
          if (oldSilent !== true) {
            self.trigger('reset', self, options);
          }
          if (callback) {
            callback(model, response, options);
          }
        }
      };
    })(options.success, this);
    this.ajaxRequests = urlParams;
    // Option to do the fetch synchronous
    options.sync = true;
    return CWBaseCollection.prototype.fetch.call(this, options);
  }

  /**
   * Prepare the 3 kinds of parameter this collection understand in an unique
   * object that the REST service can understand
   */
  _prepareUrlParams(): { [key: string]: any } {
    const pSorting: { [key: string]: any } = {};
    let querySort = "";
    const pWhere: { [key: string]: any } = {};
    let qParams: { [key: string]: any } = null;
    let respPagination: { [key: string]: any } = { pagination: (!CWSTR.isBlank(this.pagination.type)) ? this.pagination.type : "page" };//Nouveau paramètre 'pagination' ('type' est pour IHM) : valeurs possibles "page" et "liste" (par défaut)

    // Sorting configuration
    _.each(this.sortings, function (value, key) {
      const direction = (value) ? "asc" : "desc";

      if (!_.isEmpty(querySort)) {
        querySort += ",";
      }
      querySort += key;
      querySort += ";" + direction;
    });
    if (!_.isEmpty(querySort)) {
      pSorting.tri = querySort;
    }
    // Filter parameters not empty
    _.each(this.params, function (value, key) {
      if (!CWSTR.isBlank(value)) {
        pWhere[key] = value;
      }
    });

    // Join all the parameters
    qParams = _.extend(pWhere, pSorting);
    if (this.paginated) {
      // Map our fields to the expected REST pagination specs
      // check for gruped vignettes
      if (!CWSTR.isBlank(this.pagination.gruped)) {
        // set the values for grouped pagination
        respPagination = _.extend(respPagination, {
          index: this.pagination.startIndex * this.pagination.ROWS_FOR_FILE + 1,
          nbrang: this.pagination.size * this.pagination.ROWS_FOR_FILE
        })
      } else {
        respPagination = _.extend(respPagination, {
          index: this.pagination.startIndex + 1,
          nbrang: this.pagination.size
        });
      }
      // Join pagination parameters
      qParams = _.extend(qParams, respPagination);
    }
    if (this.usePopulation === true) {
      // Join population parameter
      qParams = _.extend(qParams, { "filtre": true });
    }
    return qParams;
  }

  /**
   * Prepare the 3 kinds of parameter this collection understand in an unique
   * object that the REST service can understand
   */
  resetPagination(): void {
    let lSize = Configuration.pageSize;

    this.pagination.startIndex = 0;
    if (!CWSTR.isBlank(this.pagination.itemsPerPage)) {
      lSize = this.pagination.itemsPerPage;
    }
    this.pagination.size = lSize;
  }

  /**
   * Process the response to feed the special field totalRecords and manage
   * the case of the response is an array instead of the expected one
   * {total=187, Array}
   */
  parse(response: Array<any> | { [key: string]: any }): { [key: string]: any } {
    if (_.isArray(response)) {
      // It is waiting for a response [objects]. If this is so, we have a
      // REST service not paginated
      this.paginated = false;
      this.totalRecords = parseInt(response.length.toString(), 10);
      this.pagination.size = this.totalRecords;
      return response;
    } else {
      // It is waiting for a response {total:100, list=[objects]}
      this.totalRecords = parseInt(response.total);
      this.pagination.startIndex = Math.max(0, response.index - 1);
      return response.list;
    }
  }

  /**
   * This method do the request synchronous
   */
  sync(model: TModel, xhr: JQueryAjaxSettings, options: { [key: string]: any }): any {
    if (options.sync) {
      _.extend(options, {
        error: () => {
          this._lastSync = null;
        }
      });
      if (!this._lastSync) {
        this._lastSync = Backbone.sync.call(this, model, xhr, options);
      } else {
        this._lastSync = this._lastSync.then(() => {
          if (this.ajaxRequests.index === options.oldIndex) {
            return Backbone.sync.call(this, model, xhr, options);
          }
          return null;
        });
      }
      return this._lastSync;
    } else {
      this._lastSync = null;
      return Backbone.sync.call(this, model, xhr, options);
    }
  }

  /**
   * Apply new filter to the collection and put the pagination in the 0 index
   *
   */
  applyFilter(params: { [key: string]: any }): void {
    this.params = params;
    this.pagination.startIndex = 0;
  }

  /**
   * Clear filters and put the pagination in the 0 index
   */
  clearFilter(): void {
    this.params = {};
    this.pagination.startIndex = 0;
  }

  setIdNames(valeur: Array<string>): void {
    this.idnames = valeur;
  }

  public set params(nValue: { [key: string]: any }) {
    this._params = nValue;
  }

  public get params(): { [key: string]: any } {
    return this._params;
  }
}
