import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLActivitetreesFamilleTree from '../cwFamilleTree.tpl.html';
import { CWActiviteColl } from 'common/evenements/planifier/activite/models/cwActivite.collection';
import { CWActiviteTreesViewType } from 'common/activitetrees/models/cwActivitetrees.workflow';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWBaseTreeView } from './cwBaseTree.view';
import { CWDIVERS } from 'utils/cwDivers';
import { CWSTR } from 'utils/cwStr';
import { CWTree2View } from 'core/tree/cwTree2.view';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n.js';

/**
 * Famille Tree  for results section
 */
export class CWFamilleTreeView extends CWBaseTreeView {

  racineColl: CWActiviteColl;
  deployColl: any;
  showSelected: boolean;
  multiselect: boolean;
  hideMenuOptions: boolean;
  showExpandAllIcon: boolean;
  readOnly: boolean;
  isCheckedCallback: any;
  listRenderStyle: number;
  hideCheck: boolean;
  opennode: boolean;
  firstLevelModels: any;
  initSelectedModels: any;
  label: any;
  context: { [key: string]: any };
  selActOuv: string;


  /**
   * Constructor
   * Hierarchies Activites Tree
   */
  constructor(params?: { [key: string]: any }) {
    params = params || {};
    params.tagName = "span";
    params.className = "phx-activite-famille-tree";
    params.events = _.extend({
      "click .phx-activite-famille-tree-menuicon": "_toggleMenu",
      "click .phx-activite-famille-tree-menucontent span": "_changeRenderer",
      "click .phx-activite-famille-tree-menucontent li": "_changeRenderer"
    }, params.events);
    super(params);
    this.template = TPLActivitetreesFamilleTree;
    if (!params) {
      throw new Error("Parameters are missing in the tree definition");
    }
    this.params = params;
    this.workflow = params.workflow;
    this.context = params.context;
    this.racineModel = params.racineModel;
    this.racineColl = params.racineColl;
    this.deployColl = params.deployColl;
    this.showSelected = true;
    if (!CWSTR.isBlank(params.showSelected)) {
      this.showSelected = params.showSelected;
    }
    this.model = new CWBaseModel({
      domaine: 0,
      value: null
    });
    //EVO 186: Multiselection mode, to show the checkboxes
    this.multiselect = false;
    if (params && !CWSTR.isBlank(params.multiselect)) {
      this.multiselect = params.multiselect;
    }
    //Show / hide labeling menu option
    this.hideMenuOptions = true;
    //Show / hide Expand / collapse all option
    this.showExpandAllIcon = true;
    if (params && params.showExpandAllIcon) {
      this.showExpandAllIcon = params.showExpandAllIcon;
    }
    //Checks are editable or not
    this.readOnly = false;
    if (params && params.readOnly) {
      this.readOnly = params.readOnly;
    }
    if (params && params.isCheckedCallback) {
      this.isCheckedCallback = params.isCheckedCallback;
    }
    this.model.on("change:domaine", this._setDomaineID, this);
    this.model.on("selectNode", this._selectNode, this);
    this.model.on("expandTreePath", this.expandTreePath, this);
    this.model.on("updateTreeNodeRecursive", this.updateTreeNodeRecursive, this);
    this.model.on("updateParentAfterDelete", this.updateParentAfterDelete, this);
    this.model.on("updateFamilleParent", this._updateFamilleParent, this);
    this.model.on("updateTreeNode", this.updateTreeNode, this);
    this.model.on("updateActiviteParentsFamille", this._updateActiviteParentsFamille, this);
    this.model.on("updateActiviteParents", this._updateActiviteParents, this);
    this.model.on("findExpandedParents", this.findExpandedParents, this);
    this.model.on("selectCorrectNodeOrFirst", this._selectCorrectNodeOrFirst, this);
    this.model.on("selectCorrectNodeAfterDup", this._selectCorrectNodeAfterDup, this);
    this.listRenderStyle = 0;
    this.hideCheck = false;
    if (!CWSTR.isBlank(params.hideCheck)) {
      this.hideCheck = params.hideCheck
    }
    this.selActOuv = CWDIVERS.get("SelActOuv");
  }

  _obtainView(): string {
    return "FAMILLE";
  }

  _getRenderer(listRendererStyle?: number | string, classToApply?: string): (item: CWBaseModel) => string {
    let result = null;
    const domaineDetail = this.workflow.context.ctxDomaineDetail;
    const niveauxTypeHierarchy = this.workflow.context.ctxNiveauxTypeHierarchy;
    const listRenderer = !CWSTR.isBlank(listRendererStyle) ? listRendererStyle : this.listRenderStyle;
    let format = null;

    switch (String(listRenderer)) {
      case "0":
        result = (item: CWBaseModel): string => {
          let renderfunction = null;

          if (item.get("code") === " ") {
            return i18n.t('activite.hors_regorupement');
          } else if (item.get("typelt") === "S") {
            return item.get("libelleformat");
          } else { //Activites
            if (item.get("hiertypeniv") && !CWSTR.isBlank(item.get("hiertypeniv").niveau)) {
              let itemNiveau = null;

              if (this.workflow.hierarchie) {
                itemNiveau = this.workflow.hierarchie.findWhere({ niveau: item.get("hiertypeniv").niveau });
              } else {
                if (niveauxTypeHierarchy) {
                  itemNiveau = niveauxTypeHierarchy[item.get("hiertypeniv").niveau];
                }
              }
              if (itemNiveau) {
                let affichageClass = "";

                if (itemNiveau.get) { //is a model
                  affichageClass = itemNiveau.get("style").affichage;
                  renderfunction = this._getRenderer(parseInt(itemNiveau.get("format")), affichageClass);
                } else { //Is a object
                  affichageClass = itemNiveau.style.affichage;
                  renderfunction = this._getRenderer(parseInt(itemNiveau.format), affichageClass);
                }
                return renderfunction(item);
              } else {
                if (!CWSTR.isBlank(domaineDetail) && !CWSTR.isBlank(domaineDetail.get("fmtact"))) { //Default format for domaine
                  format = (!CWSTR.isBlank(domaineDetail.get("fmtact"))) ? domaineDetail.get("fmtact") : 1;
                  renderfunction = this._getRenderer(parseInt(format));
                  return renderfunction(item);
                } else {
                  return item.get("libelle") + " (" + item.get("code") + ")";
                }
              }
            } else if (!CWSTR.isBlank(domaineDetail) && !CWSTR.isBlank(domaineDetail.get("fmtact"))) { //Default format for domaine
              format = (!CWSTR.isBlank(domaineDetail.get("fmtact"))) ? domaineDetail.get("fmtact") : 1;
              renderfunction = this._getRenderer(parseInt(format));
              return renderfunction(item);
            } else {
              return item.get("libelle") + " (" + (!CWSTR.isBlank(item.get("codeparfamille")) ? item.get("codeparfamille") : item.get("code")) + ")";
            }
          }
        };
        break;
      case "1":
        result = (item: CWBaseModel): string => {
          if (item.get("code") === " ") {
            return i18n.t('activite.hors_regorupement');
          } else {
            const spanItem = $("<span></span>");

            if (classToApply) {
              spanItem.addClass(classToApply);
            }
            spanItem.text(item.get("libelle") + " (" + item.get("code") + ")");
            return spanItem[0].outerHTML;
          }
        };
        break;
      case "2":
        result = (item: CWBaseModel): string => {
          if (item.get("code") === " ") {
            return i18n.t('activite.hors_regorupement');
          } else {
            const spanItem = $("<span></span>");

            if (classToApply) {
              spanItem.addClass(classToApply);
            }
            spanItem.text(item.get("code") + " (" + item.get("libelle") + ")");
            return spanItem[0].outerHTML;
          }
        };
        break;
      case "3":
        result = (item: CWBaseModel): string => {
          if (item.get("code") === " ") {
            return i18n.t('activite.hors_regorupement');
          } else {
            const spanItem = $("<span></span>");

            if (classToApply) {
              spanItem.addClass(classToApply);
            }
            spanItem.text(item.get("libelle"));
            return spanItem[0].outerHTML;
          }
        };
        break;
      case "4":
        result = (item: CWBaseModel): string => {
          if (item.get("code") === " ") {
            return i18n.t('activite.hors_regorupement');
          } else {
            const spanItem = $("<span></span>");

            if (classToApply) {
              spanItem.addClass(classToApply);
            }
            spanItem.append(item.get("code"));
            return spanItem[0].outerHTML;
          }
        };
        break;
      default:
        break;
    }
    return result;
  }

  /**
   * Callback is a function to select the right node(from the previous view)
   */
  _paintTree(calback?: () => void): void {
    const activiteColl = this.deployColl.clone();

    //Gets the collection to build the tree
    activiteColl.setHabContext(this.workflow.getHabContext());
    this.opennode = !CWSTR.isBlank(this.tree) && !CWSTR.isBlank(this.tree.root.opennode) && this.tree.root.opennode === true ? true : false;
    // clean tree
    this.close();
    //Create the tree
    this.tree = new CWTree2View({
      "showSelected": this.showSelected,
      "coll": null,
      "name": null,
      "draggable": true,
      "rootModel": this.racineModel,
      "manyRoots": true,
      "firstLevelModels": this.firstLevelModels,
      "buildUrlColl": (parentNiveau: number, node: CWBaseModel, collection: { [key: string]: any }): void => {
        let domaineDetail: Backbone.Model = null;

        collection.setNiveau(parentNiveau + 1);
        collection.setParentTypelt(node.get("typelt"));
        collection.setParentCode((!CWSTR.isBlank(node.get("codeparfamille")) ? node.get("codeparfamille") : node.get("code")));
        collection.setParentDateDebut(node.get("datedeb"));
        collection.setParentDateFin(node.get("datefin"));
        domaineDetail = this.workflow.context.ctxDomaineDetail;
        collection.setDomaine(!CWSTR.isBlank(domaineDetail) ? domaineDetail.get("domcode") : "");
        //Selecteur activite tooltip necessary data
        collection.setDomaineLibelle(!CWSTR.isBlank(domaineDetail) ? domaineDetail.get("domlib") : "");
        collection.setContext(this.workflow.context.ctxFilterData, this.workflow.context.ctxNiveauAttendu);
        collection.setVue(1);
      },
      "dragAndDropCallback": (): void => {
        //Do nothing
      },
      "checkClass": (model: CWBaseModel): string => {
        if (model.get("typelt") === "F") {
          return "cw-triangle-icon";
        } else {
          return "cw-non-icon";
        }
      },
      "renderer": this._getRenderer(),
      "selectableNode": (model: CWBaseModel): boolean => {
        if (model.get("typelt") === "F" || (!CWSTR.isBlank(model.get("indic_inapt")) && (model.get("indic_inapt") === "C" || model.get("indic_inapt") === "P"))) {
          return false;
        }
        return true;
      },
      "tooltipRenderer": (model: CWBaseModel, view: { [key: string]: any }): void => {
        let label = $(view.$el.find(".cw-treenode-label")[0]).html();
        const competencePartielle = this._getCompetencePartielle(model);//Icons
        const couvertureCertifications = this._getCouvertureCertifications(model);
        const inpatitudeComplete = this._getInaptitudeComplete(model);
        const inpatitudePartielle = this._getInaptitudePartielle(model);
        let padding = 0;
        const treeLabel = view.$el.find(".cw-treenode-label")[0];
        const tooltipIcon = " <span class=\"cw-tree-tooltip-icon\" title=\"\"></span>";
        let tooltip: JQuery = null, tooltipCheck: JQuery = null, datedeb = "", datefin = "";
        const $lPosTooltiCpheck = view.$el.find("div.cw-treenode-check-container");
        const dated = CWSTR.isBlank(model.get("datedeb")) ? CWTYPE.DATE.INITIAL : model.get("datedeb");
        const datef = CWSTR.isBlank(model.get("datefin")) ? CWTYPE.DATE.INFINITY : model.get("datefin");
        let text = "";

        if (competencePartielle !== "" || couvertureCertifications !== "") {
          padding = 25;
        }
        if (inpatitudeComplete !== "" || inpatitudePartielle !== "") {
          padding += 25;
        }
        if (padding > 0) {
          label = "<span style=\"padding-left: " + padding + "px\">" + label + "</span>";
        }
        //Avoid aplying not selectionnable IHM style to familles
        if ($(treeLabel).hasClass("ui-phx-ihm-non-selectionnable")) {
          $(treeLabel).removeClass("ui-phx-ihm-non-selectionnable");
          if (model.get("typelt") !== "F") {
            label = "<span class='ui-phx-ihm-non-selectionnable'>" + label + "</span>";
          }
        }
        if ((dated > this.params.workflow.context.ctxPeriodeGestion.datedeb && datef < this.params.workflow.context.ctxPeriodeGestion.datefin) ||
          datef < this.params.workflow.context.ctxPeriodeGestion.datefin ||
          dated > this.params.workflow.context.ctxPeriodeGestion.datedeb) {
          datedeb = CWTYPE.DATE.format(dated);
          datefin = CWTYPE.DATE.format(datef);
          if ((dated > CWTYPE.DATE.INITIAL && datef < CWTYPE.DATE.INFINITY)) {
            datedeb = CWTYPE.DATE.format(model.get("datedeb"));
            datefin = CWTYPE.DATE.format(model.get("datefin"));
          } else if (datef < CWTYPE.DATE.INFINITY) {
            datedeb = "... ";
            datefin = CWTYPE.DATE.format(datef);
          } else if (dated > CWTYPE.DATE.INITIAL) {
            datedeb = CWTYPE.DATE.format(dated);
            datefin = "  ...";
          }
          text += "[" + datedeb + " - " + datefin + "]";
          if (this.context?.ctxModeRepresentation === "pop-up") {
            view.$el.find(".cw-treenode-label:first").html(competencePartielle + couvertureCertifications + inpatitudeComplete + inpatitudePartielle + label + tooltipIcon);
            tooltip = view.$el.find(".cw-tree-tooltip-icon").tooltip({ content: "" });
            tooltip.tooltip("option", "content", text);
            if (model.get("feuille") === true) {
              $lPosTooltiCpheck.append(competencePartielle + couvertureCertifications + inpatitudeComplete + inpatitudePartielle + tooltipIcon);
              tooltipCheck = $lPosTooltiCpheck.find(".cw-tree-tooltip-icon").tooltip({ content: "" });
              tooltipCheck.tooltip("option", "content", text);
            }
          } else {
            view.$el.find(".cw-treenode-label:first").html(competencePartielle + couvertureCertifications + inpatitudeComplete + inpatitudePartielle + label);
          }
        } else {
          view.$el.find(".cw-treenode-label:first").html(competencePartielle + couvertureCertifications + inpatitudeComplete + inpatitudePartielle + label);
        }
      },
      "checkPeriode": (model: CWBaseModel, context: { [key: string]: any }): boolean => {
        let lRtn = true;

        //S'il n'y a pas d'information des périodes, il ne devrait rien changer-> Il retournera true 
        if (model && model instanceof Backbone.Model && context && !CWSTR.isBlank(context.ctxPeriodeDebut) && !CWSTR.isBlank(context.ctxPeriodeFin)) {
          if (model.get("datedeb") > context.ctxPeriodeDebut || context.ctxPeriodeFin > model.get("datefin")) {
            lRtn = false;
          }
        }
        return lRtn;
      },
      "multiselect": this.multiselect,
      "checkedColl": this.initSelectedModels,
      "readOnly": this.readOnly,
      "isCheckedCallback": this.isCheckedCallback,
      "hideCheck": this.hideCheck,
      "view": CWActiviteTreesViewType.FAMILLE,
      // expand nodes for activite selecteur
      "expandedNodes": this.workflow.context.ctxFromSelecteurDactivites ? this.workflow.context.ctxFromSelecteurDactivites : false,
      "context": this.context
    });
    this.tree.setSortFunction(this._sortTree);
    this.tree.setColl(activiteColl);
    this.tree.model.on("nodeSelected", this._setSelection, this);
    this.tree.model.on("updatedTreeNodeCollection", this.checkSelectionableLeafts, this);
    this.$el.find(".phx-activite-famille-tree-tree").html(this.tree.el);
    this.tree.render();
    this.setAffichage(this.listRenderStyle);
    if (!CWSTR.isBlank(this.opennode) && this.opennode === true) {
      this.tree.root.opennode = true;
    }
    if (!CWSTR.isBlank(this.tree.context) && this.tree.context.forcedSelectNodeOrFirst === true) {
      this.tree.expandRoot((): void => {
        this._selectCorrectNodeOrFirst();
      });
    } else {
      if (this.selActOuv === "2") {
        this.tree.expandRoot((): void => {
          this._selectOnlyRootNode(this.tree);
        });
      } else {
        this.tree.expandRoot();
      }
    }
    if (calback) {
      calback();
    }
  }

  _selectCorrectNodeOrFirst(shouldReload?: boolean): void {
    this.workflow.trigger("selectLastSelectedOrFirst", shouldReload, this._obtainView());
  }

  render(callback?: () => void): this {
    const json = { i18n: i18n, label: this.label };
    let domaineDetail: Backbone.Model = null;
    const helpRacineColl = this.racineColl.clone();

    $(this.el).html(this.template(json));
    //remove all the events
    this.undelegateEvents();
    //Restart events
    this.delegateEvents();
    // paint tree
    helpRacineColl.domaine = this.params.domaine;
    helpRacineColl.vue = 1;
    domaineDetail = this.workflow.context.ctxDomaineDetail;
    helpRacineColl.domaine = !CWSTR.isBlank(domaineDetail) ? domaineDetail.get("domcode") : "";
    helpRacineColl.setContext(this.workflow.context.ctxFilterData);
    helpRacineColl.setHabContext(this.workflow.getHabContext());
    if (!CWSTR.isBlank(helpRacineColl.domaine)) {
      helpRacineColl.fetch({
        success: (fresh: { [key: string]: any }) => {
          //Obtain activites without family
          if (fresh.models && fresh.models.length > 0) {
            this.checkSelectionableLeafts(fresh);
            this.racineModel = this.initializeRacineModel(helpRacineColl.models[0]);
            this.racineModel.set("code", "");
            if (!CWSTR.isBlank(this.racineModel.get("codeparfamille"))) {
              this.racineModel.unset("codeparfamille");
            }
            this.racineModel.firstLevelColl = helpRacineColl;
            this.firstLevelModels = [];
            _.each(helpRacineColl.models, (model: CWBaseModel) => {
              if (CWSTR.isBlank(model.get("code"))) { //Hors regroupement
                model.set("code", " ");
                model.set("libelle", i18n.t('activite.hors_regorupement'));
              }
              this.firstLevelModels.push(model);
            });
            _.each(this.firstLevelModels, (firstLevel: { [key: string]: any }) => {
              firstLevel.vue = 1;
              firstLevel.niveau = 1;
              if (!CWSTR.isBlank(firstLevel.get("code"))) {
                firstLevel.set("codeparfamille", firstLevel.get("code"));
                firstLevel.set("code", firstLevel.get("code") + "_fam");
              } else if (!CWSTR.isBlank(firstLevel.get("codeparfamille"))) {
                firstLevel.unset("codeparfamille");
              }
            });
            this.workflow.trigger("tree:activitiesNumber", fresh.models.length);
            this._paintTree(callback);
          } else { //No results found initialize empty tree
            this.firstLevelModels = [];
            this.racineModel = this.initializeRacineModel(helpRacineColl.models[0]);
            this.racineModel.set("code", "");
            this.racineModel.firstLevelColl = helpRacineColl;
            this.model.set("value", null);
            this.workflow.trigger("tree:activitiesNumber", 0);
            this._paintTree(callback);
          }
        }
      });
    }
    //Build the menu
    if (!this.hideMenuOptions) {
      const menuContent = $(".phx-activite-famille-tree-menucontent", this.el);

      menuContent.menu();
      menuContent.hide();
    } else {
      $(this.el).find(".phx-activite-famille-tree-dlgresultheader").hide();
      $(this.el).find(".phx-activite-famille-tree-menucontent").hide();
    }
    return this;
  }

  /**
   * After an activite has been updated, check if its familly has changed, in case it has changed
   * update old and new families
   */
  _updateFamilleParent(freshModel: CWBaseModel, oldValues: { [key: string]: any }): void {
    const callback = (): void => {
      const family = CWSTR.getElValue(freshModel, "famille.code");

      //find the family and expand it to select the correct node
      this.tree.findElement(this.tree.root, family, null, null, (node: { [key: string]: any }) => {
        node.model.node.trigger("expandNode", null, (): void => {
          this._selectNode(freshModel, undefined);
        });
      });
    };

    if (!CWSTR.isBlank(oldValues.famille) && freshModel.get("famille").code !== oldValues.famille.code) { //Famille has changed
      let oldFamily: { [key: string]: any } = undefined;
      let newFamily: { [key: string]: any } = undefined;
      const size = this.tree.root.sonsColl.length;

      for (let i = 0; i < size; i++) {
        const son = this.tree.root.sonsColl[i];

        if (son) {
          if (son.model.node.get("code") === freshModel.get("famille").code) {
            newFamily = son;
          } else if (son.model.node.get("code") === oldValues.famille.code) {
            oldFamily = son;
          }
        }
      }
      //Update old famille
      if (oldFamily) {
        const callbackAfterUpdateCurrentFamilly = (): void => {
          const deleteOldFamily = oldFamily.sonsColl.length === 0; // if no have sons delete it
          let createNewFamily = false;

          if (newFamily) {
            newFamily.model.trigger("updateTreeNode", callback);
          } else {
            // if not found, create it.
            createNewFamily = true;
          }
          if (deleteOldFamily || createNewFamily) {
            this.tree.root.model.trigger("updateTreeNode", callback);
          }
        };

        //update new famille
        oldFamily.model.trigger("updateTreeNode", callbackAfterUpdateCurrentFamilly, false);
      }
    } else if (freshModel.get("transverse") !== oldValues.transverse || freshModel.get("libelle") !== oldValues.libelle) { //transverse has changed but famille hasn't changed
      //update only new famille
      this.tree.findElementWithResponse(this.tree.root, freshModel.get("famille").code, undefined, true, (found: boolean, node: { [key: string]: any }) => {
        if (found) {
          node.model.trigger("updateTreeNode", callback);
        }
      });
    }
  }

  _obtainCode(model?: CWBaseModel): string {
    return model.get("famille_code");
  }

  /**
   *
   */
  setAffichage(style: number, repaintTree?: boolean): void {
    $(".phx-activite-famille-tree-opt1", this.el).removeClass("ui-state-active");
    $(".phx-activite-famille-tree-opt2", this.el).removeClass("ui-state-active");
    $(".phx-activite-famille-tree-opt3", this.el).removeClass("ui-state-active");
    $(".phx-activite-famille-tree-opt4", this.el).removeClass("ui-state-active");
    switch (style) {
      case 1:
        this.listRenderStyle = 1;
        $(".phx-activite-famille-tree-opt1", this.el).addClass("ui-state-active");
        break;
      case 2:
        this.listRenderStyle = 2;
        $(".phx-activite-famille-tree-opt2", this.el).addClass("ui-state-active");
        break;
      case 3:
        this.listRenderStyle = 3;
        $(".phx-activite-famille-tree-opt3", this.el).addClass("ui-state-active");
        break;
      case 4:
        this.listRenderStyle = 4;
        $(".phx-activite-famille-tree-opt4", this.el).addClass("ui-state-active");
        break;
      default:
      //Nothing
    }
    if (this.tree) {
      this.tree.setRenderer(this._getRenderer());
      if (repaintTree !== false) {
        this.tree.root.repaintLabel(this._getRenderer());
      }
      this.trigger("rendererChanged");
    }
  }

  /**
   * In order to implement the button to select the way to render the tree
   */
  _changeRenderer(event: { [key: string]: any }): boolean {
    switch (event.target.className.split(" ")[0]) {
      case "phx-activite-famille-tree-opt1":
        this.setAffichage(1);
        break;
      case "phx-activite-famille-tree-opt2":
        this.setAffichage(2);
        break;
      case "phx-activite-famille-tree-opt3":
        this.setAffichage(3);
        break;
      case "phx-activite-famille-tree-opt4":
        this.setAffichage(4);
        break;
      default:
      //Nothing
    }
    return false;
  }

  //Method to close and open the menu.
  _toggleMenu(): void {
    const menuContent = $(".phx-activite-famille-tree-menucontent", this.el);

    if (menuContent.is(":visible")) {
      menuContent.hide();
    } else {
      const menuBtn = $(".phx-activite-famille-tree-menuicon", this.el);

      menuContent.show().position({
        my: "right top",
        at: "right bottom",
        of: menuBtn
      });
      //If you click out of the menu, close the menu.
      $(document).one("mousedown", (event: { [key: string]: any }): void => {
        const element = $(this.el).find(event.target);

        if (element.length === 0) {
          this._toggleMenu();
        }
      });
      //If you click in one option, the menu is closed
      $(document).one("mouseup", (event: { [key: string]: any }): void => {
        const element = $(this.el).find(event.target);

        if (element.length > 0 && event.target.className.split(" ")[0] !== "phx-activite-famille-tree-menuicon") {
          this._toggleMenu();
        }
      });
    }
  }

  /**
   * Special case due to the family copy can have a diferent family(parent) that the origin had.
   * Busca todos los puntos donde esta la actividad y actualiza al padre.
   * Update all parents of an activite and select current activite after updates
   * If current ativite is not found, select fist node
   */
  _updateActiviteParentsFamille(freshActivity: CWBaseModel, oldActivity: { [key: string]: any }, callbackAfterUpdate: () => void): void {
    const newFamily = CWSTR.getElValue(freshActivity, "famille.code");
    const oldFamily = (!CWSTR.isBlank(oldActivity.famille) && !CWSTR.isBlank(oldActivity.famille.code)) ? oldActivity.famille.code : "";
    const code = freshActivity.get("code");
    const callback = (expandedParents: { [key: string]: any }): void => {
      let parentsLength = 0;
      let callbackExecuted = false;

      parentsLength = expandedParents.length - 1;
      _.each(expandedParents, (parentNode, index) => {
        if (parseInt(index) === parentsLength) {
          this.model.trigger("updateTreeNode", parentNode.model.node.get("code"), parentNode.model.node.get("datedeb"), parentNode.model.node.get("hierid"), callbackAfterUpdate);
          callbackExecuted = true;
        } else {
          this.model.trigger("updateTreeNode", parentNode.model.node.get("code"), parentNode.model.node.get("datedeb"), parentNode.model.node.get("hierid"));
        }
      });
      if (callbackExecuted === false && callbackAfterUpdate) {
        callbackAfterUpdate();
      }
    };
    if (newFamily !== oldFamily) {
      this.tree.root.model.trigger("updateTreeNode", () => {
        this.model.trigger("findExpandedParents", code, callback);
      });
    } else {
      this.model.trigger("findExpandedParents", code, callback);
    }
  }

  getCheckedRows(): { [key: string]: any } | null {
    let lRtn = null;

    if (this.tree && this.tree.getCheckedRows) {
      lRtn = this.tree.getCheckedRows();
    }
    return lRtn;
  }

  setCheckedRows(selectedModels: { [key: string]: any }): void {
    this.initSelectedModels = selectedModels;
    if (!CWSTR.isBlank(this.tree)) {
      this.tree.setCheckedRows(selectedModels);
    }
  }

  clearCheckedRows(): void {
    if (!CWSTR.isBlank(this.tree)) {
      this.tree.clearCheckedRows();
    }
  }

  _obtainLastParentView(): { [key: string]: any } {
    //Do nothing
    return {};
  }

  _selectOnlyRootNode(treeView: CWTree2View): void {//sélectionne uniquement le nœud racine
    this.workflow.trigger("selectOnlyRootNode", treeView, this._obtainView());
  }

  getFirstLevelModels(): CWBaseModel[] {
    return this.firstLevelModels;
  }
}
