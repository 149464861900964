import _ from 'underscore';
import gererevtgenGererevtgenTPL from '../cwGererevtgen.tpl.html';
import gererevtgenMultiLabelModeSaisieTPL from '../cwMultiLabel-modeSaisie.tpl.html';
import gererevtgenMultiLabelTypeSaisieTPL from '../cwMultiLabel-typeSaisie.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWBaseView } from 'common/evenements/gerer/gererrecapitulatifs/views/cwBase.view';
import { CWButtonBarValidationView } from 'core/controls/cwButtonBarValidation.view';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWDialogView } from 'core/components/dialog/cwDialog.view';
import { CWDIVERS } from 'utils/cwDivers';
import { CWEtiquette, validEtiquetteTypes } from 'core/components/etiquette/cwEtiquette.view';
import { CWFORMS } from 'utils/cwForms';
import { cwGererEvtGenComplementComboColl } from '../models/cwGererEvtGenComplementCombo.collection';
import { CWGererevtgenGestion } from '../models/cwGererEvtGenGestion';
import { CWGererEvtGenModel } from '../models/cwGererEvtGen.model';
import { CWGererevtgenWorkflow } from '../models/cwGererEvtGen.workflow';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWMEvenementsRouter } from 'src/uc/mon_compte/gerer/mevenements/cwMEvenements.router';
import { CWMultiLabel } from 'core/views/cwMultiLabel';
import { CWPanneauDeroulant } from 'core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWPieceJointes } from 'common/piecesjointes/views/cwPieceJointes.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { CWWkflhistoView } from 'common/wkfhisto/views/cwWkfhisto.view';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n';
import { Model, ViewOptions } from 'Backbone';
import { objs } from 'src/objectsRepository';
import { SYNC } from 'utils/sync.js';
import { UTILS } from 'utils/utils.js';

export interface CWGererEvtGenViewOptions extends ViewOptions<CWBaseModel> {
  context?: { [key: string]: any };
  parent?: CWDialogView | CWMEvenementsRouter;
}

/**
 * Render gerer evtgen view
 */
export class CWGererEvtGenView extends CWBaseFormView<CWBaseModel> {

  public workflow: CWGererevtgenWorkflow;
  public btnBar: CWButtonBarValidationView;
  public isReady: boolean;
  public isContextInitialized: boolean;
  public ctxActivModeSynth: boolean;
  public context: { [key: string]: any };
  public parent: any;
  public hasBeenFocused: boolean;
  public contextInitialized: boolean;
  public listeMotifColl: void;
  public disabled: boolean;
  public origineContext: string;
  public comboOrigine: CWComboBoxView2;
  public original: CWGererEvtGenModel;
  public COLLAB_ID: string;
  public SAISIE_HEURE: string;
  public SAISIE_DUREE: string;
  public contextComponentWkf: { [key: string]: any };
  public composantWorkflow: CWWkflhistoView;
  public pieceJointeModified: boolean;
  public pieceJointe: CWPieceJointes;
  public modeSaisie: CWMultiLabel;
  public typeSaisie: CWMultiLabel;
  public evtGenModified: boolean;
  public storeEvtGen: { [key: string]: any };
  public comboMotif: CWComboBoxView2;
  private etiquetteHisto1: CWEtiquette;
  private histoEtiquettePopup: CWPanneauDeroulant;
  private histoEtiquetteOpen: boolean;
  private outsideClickListener: (arg1: JQueryEventObject) => any;

  constructor(options?: CWGererEvtGenViewOptions) {
    options = options ? options : {};
    options.events = _.extend({
      "change :input": "_manageView",
      "click li.phx-multiLabel-option": "_changeTypeSaisie",
      "click span .phx-icon-triangle-e": "_HistoSyntetic",
      "click span .phx-icon-triangle-s": "_HistoDetaille",
      "keyup :input:not([readonly])": "_notifyEdition",
      "focus .gererevtgen-detail .gererevtgen-form-inputs :input:not([readonly])": "_focused",
      "click .menuItem": "menuItemListener",
      "click div.gererevtgen-containerMasquer-button": "_hideAndShowTextComp"
    }, CWTYPE.SHORT.events(),
      CWTYPE.DATE.events(),
      CWTYPE.DURATION.HOUR_MINUTE.events(),
      CWTYPE.HOUR_MINUTE_NIGHTLY.events(),
      CWTYPE.CUSTOM.events(),
      CWTYPE.LONG.events(),
      options.events
    );
    options.className = options.className || "gererevtgen-detail gererevtgen-content cw-fieldset";
    options.tagName = options.tagName || "fieldset";
    super(options);
    this.id = null;
    this.isReady = false;
    this.isContextInitialized = false;
    this.template = gererevtgenGererevtgenTPL;
    this.model = new CWBaseModel({
      value: null,
      mode: "C"
    });
    this.histoEtiquetteOpen = false;
    this.ctxActivModeSynth = true; // true as in SFD.
    this.workflow = new CWGererevtgenWorkflow({}, { "context": this.context });
    this.workflow.formModel = this.model;
    this.btnBar = new CWButtonBarValidationView({ id: "gererevtgen_btnbar" });
    this.workflow.btnBarModel = this.btnBar.model;
    this.workflow.gererevtgen = this;
    if (options) {
      if (options && options.context) {
        this.context = options.context;
        this.model.setHabContext(new CWHabilitationContext({
          onglet: this.context.ctxEcran,
          foncCour: this.context.ctxHabilitation.HabilitationAcces
        }));
      }
      if (options && options.parent) {
        this.parent = options.parent;
      }
    }
    if (CWSTR.isBlank(this.model.getHabContext())) {
      this.model.setHabContext(new CWHabilitationContext({
        onglet: "",
        foncCour: ""
      }));
    }
    // When the value is changed, we have to map the new value to the form
    this.model.on("change:value", this._setContextGMSituation, this);
    this.model.on("form:edited", this._displaySubtitle, this);
    this.typeMaskByClass = {};
    this.typeMaskByClass["datedeb"] = "DATE";
    this.typeMaskByClass["datefin"] = "DATE";
    this.hasBeenFocused = false;
    this.contextInitialized = false;
    this.listeMotifColl = null;
    this.disabled = false;
    this.typeFormatByClass = {};
    this.listenTo(this, "selectedTypoChanged", this.selectedTypoChanged);
    this.listenTo(this, "manageUnselected", this.manageModeUnselected);
    this.listenTo(this.workflow, "manageUnselected", this.manageModeUnselected);
  }

  _initCombos(): void {
    let lsUser = "";

    //Combo Origine
    if (this.context && this.context.ctxUtilisateur) {
      lsUser = (this.context.ctxUtilisateur === "Responsable") ? "respo" : "collab";
    } else {
      lsUser = (objs.appRt.workflow.get("zone") === "resp") ? "respo" : "collab";
    }
    this.origineContext = lsUser;
    this.comboOrigine = new CWComboBoxView2({
      ws: new CWCombosColl({
        urlMine: Configuration.restRoot + "/rest/ls/origine/" + this.origineContext
      }),
      autocomplete: true,
      name: "origine.code",
      //width: "160px",
      habContext: this.model.getHabContext(),
      optionsRender: function (item): string {
        return (!item || CWSTR.isBlank(item.code)) ? "" : item.libelle;
      }
    });
    this.listenTo(this.comboOrigine, "comboEdited", this._formEdited);
  }

  _formEdited(): void {
    this.model.trigger("form:edited");
  }

  _setInitialContext(context: { [key: string]: any }, avoidMaxPiecesFetch?: boolean): void {
    if (this.workflow.setupState === undefined) {
      this.workflow.setupState = 0;
      this.workflow.context = context;
      this.workflow.setUp(() => {
        this.contextInitialized = true;
        this.setContext(context);
        if (this.context.ctxModeRepresentation === "pop-up" && !(this.parent instanceof CWMEvenementsRouter)) {
          $(this.parent.el).css("height", "auto");
        }
      }, avoidMaxPiecesFetch);
    }
  }

  setContext(context: { [key: string]: any }): void {
    this.isContextInitialized = true;
    this.model.updateHabContext({
      onglet: context.ctxEcran,
      foncCour: context.ctxHabilitation.HabilitationAcces,
      natGest: ""
    });
    if (this.contextInitialized === true) {
      this.context = context;
      if (CWSTR.isBlank(this.context)) {
        throw new Error("A context must be defined...");
      }
      this._prepareUI();
      this._manageModeInitialisation((): void => {
        this._manageActionsPossibles();
        this._manageUtilisateur();
        this._manageHabilitation();
      });
    } else {
      if (this.workflow.setupState !== undefined) {
        this.context = context;
      } else {
        this._setInitialContext(context);
      }
    }
    this.workflow.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this.workflow.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application
    }
  }

  _renderMessageInformatif(motif: CWGererEvtGenModel): void {
    const comm = $(this.el).find(".gererevtgen-containerMasquer");//display text comm
    const maGestJust = GLOBAL_DATA.paramDivers.get("maGestJust").get("valeur");
    let showComm = false;

    /*
     * Proposé uniquement dans les conditions suivantes :
      1) MaTexteComp est non vide et L’aide n’est pas en lien avec la gestion des justificatifs (le paramètre ’maGestJust’ est égal à 0)
      2) ou bien l’aide est en lien maGestJust’ est égal à 1 - et la gestion des pièces jointes est activée pour ce motif (MaJustifAjout vaut OUI).
      */
    if (!CWSTR.isBlank(motif) && ((!CWSTR.isBlank(motif.get("comm") && maGestJust === "0") || (!CWSTR.isBlank(motif.get("comm")) && maGestJust === "1" && motif.get("justif") === true)))) {
      showComm = true;
      comm.find(".gererevtgen-containerMasquer-texteComp").text(motif.get("comm"))
    }
    if (showComm) {
      comm.show();
    } else {
      comm.hide();
    }
  }

  _hideAndShowTextComp(): void {
    this.$el.find(".gererevtgen-containerMasquer-texteComp, .gererevtgen-masquer").toggle();
    this.$el.find(".gererevtgen-savoir").toggle();
  }

  _prepareUI(): void {
    // set button bar
    if (this.context.ctxModeRepresentation === "pop-up") { // Pop up
      this.$el.find(".c-gererEvtGenContent__btnBar").append(this.btnBar.render().el);
      this.$el.find("#gererevtgen_btnbar").css("float", "left");
      this._setTitlePopUp();
    } else { // Imbrique
      if (this.context.ctxEcran !== "saisiescoll") {
        if (this.parent && this.parent.layout) {
          $(this.parent.layout.el).find(".l-panelB-butomButtons").prepend(this.btnBar.render().el);
        }
      }
    }
    this.btnBar.model.trigger("hide:save");
    this.btnBar.model.trigger("hide:revert");
    this.btnBar.model.trigger("hide:new");
    this.btnBar.model.trigger("hide:nouveau");
    this.btnBar.model.trigger("hide:copy");
    this.btnBar.model.trigger("hide:delete");
    this.btnBar.model.trigger("hide:valider");
  }

  _manageModeInitialisation(callback: () => void): void {
    const initMode = this.context.ctxModeInitialisation;
    const saisieHeure = this.SAISIE_HEURE;
    const saisieDuree = this.SAISIE_DUREE;
    const evtgen = new CWGererEvtGenModel(null, { context: this.context, SAISIE_HEURE: saisieHeure, SAISIE_DUREE: saisieDuree });

    switch (initMode) {
      case "Consultation":
        this._initModeConsultation(evtgen, callback);
        break;
      case "Ajout":
        this._initModeAjout(evtgen, callback);
        break;
      case "InitForAction":
        this._initForAction(callback);
        break;
      default:
        this._initDefaultMode(evtgen, callback);
        break;
    }
  }

  _initModeConsultation(evtgen: CWGererEvtGenModel, callback: () => void): void {
    this.model.updateHabContext({
      foncCour: this.context.ctxHabilitation.HabilitationAcces,
      natGest: ""
    });
    evtgen.setHabContext(this.model.getHabContext());
    this._enableForm();
    this.btnBar.model.trigger("enable:delete");
    this.btnBar.model.trigger("hide:delete");
    this.btnBar.model.set("mode", "R");
    this.btnBar.model.trigger("hide:copy");
    evtgen.id = this.context.ctxIdentifiantEvt;
    evtgen.action = "element";

    evtgen.fetch({
      success: (fresh: CWGererEvtGenModel): void => {
        fresh.store();
        this.model.set("value", evtgen); //This will trigger a _setContextGMSituation, and this a _mapToForm
        this.model.store();
        this.original = fresh.clone();
        this._paintWorkflowStatus();
        this._showTypeStatusTitle();
        this._configBtnBar();
        if (callback) {
          callback();
        }
        this._manageWKFHisto();
        this._renderMessageInformatif(fresh);
        this._storeEvtGen();
        if (this.context.ctxActionsPossibles.indexOf("Valider") !== -1
          && this.context.ctxUtilisateur.toUpperCase() === "RESPONSABLE"
          && this.context.ctxModeInitialisation.toUpperCase() === "CONSULTATION" && this.context.ctxEcran !== "valevt") {
          this.btnBar.model.trigger("hide:valider");
          if (fresh.get("profils") && fresh.get("profils").length > 0) {
            this.model.get("value").set("profils", fresh.get("profils"));
            this.btnBar.configureValidationsButtonFromModel(this.model.get("value"));
            if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation)) {
              this.btnBar.model.trigger("show:valider");
            } else {
              this.btnBar.model.trigger("hide:valider");
            }
          } else {
            this.btnBar.model.trigger("hide:valider");
          }
        }
        this._showIndicateurEvenamentMessage();
        this._mapToForm();
        this._manageRestrictions(fresh);
        this._updateVisibiliteOrigine();
      }
    });
  }

  /**************************************************/
  /** INFORMATIONS COMPLEMENTARIES PERSONALIZABLES **/
  /**************************************************/
  /**
   * Creates all the combos or input fields or date fields when are necessary.
   */
  _LoadDynamicFieldData(): void {
    const gererEvtGen = this.model.get("value");
    const infocomp: Array<any> = [];

    //Reset the component
    this.$el.find(".gererevtgen-infocompsaisie").html("");
    _.each(gererEvtGen.attributes, (value: any, key: string): void => {
      if (key.indexOf("evtg_") > -1) {
        infocomp.push(value);
      }
    });
    const infoCompOrder = _.sortBy(infocomp, (item): boolean => {
      if (item) {
        return item.code === "EVTG_ID" ? 0 : item.ordre;
      }
      return false;
    });

    _.each(infoCompOrder, (it: { [key: string]: any }): void => {
      if (it && it.code) {
        this._manageFields(it);
      }
    });
  }



  _manageFields(fieldData: { [key: string]: any }): void {
    const fieldName = fieldData.code.toLowerCase();
    if (!CWSTR.isBlank(fieldData.codif)) {
      // PAINT A COMBO
      const combo = this._createCombo(fieldData);
      this._paintField(fieldData, combo);
    } else {
      // PAINT AN INPUT TEXT//paint an input text field
      let numMaxCharacter = 10;
      let inputSize = 10; //Customer 198546
      if (fieldName.indexOf("charl") !== -1) {
        numMaxCharacter = 200;
        inputSize = 35;
      } else {
        if (fieldName.indexOf("char") !== -1) {
          numMaxCharacter = 8;
        }
      }
      const input = this._createInputText(fieldName, inputSize, numMaxCharacter);
      this._paintField(fieldData, input);
    }
  }

  /**
   * Paints the input fields or combos and their labels.
   */
  _paintField(fieldData: { [key: string]: any }, fieldToPaint: string | JQuery): void {
    const fieldName = fieldData.code.toLowerCase();
    const $domEl = $("<div>");
    const $labelEl = $("<label>").addClass("cw-libelleEnrichi").attr("for", fieldName + ".valeur");

    $domEl.addClass("form-group");
    if (fieldData.oblig) { $labelEl.addClass("cw-required"); }
    $domEl.append($labelEl);
    this.$el.find(".gererevtgen-infocompsaisie").append($domEl);
    if (!CWSTR.isBlank(fieldData.codif)) {
      $domEl.append(fieldToPaint);
      $labelEl.text(fieldData.libelle);
    } else {
      // Paint the input fields
      $domEl.append(fieldToPaint);
      // Add typeLong mask and events changed in # customer 146014
      if (fieldData.type === "N") {
        if (fieldData.masque) {
          $domEl.find("input." + fieldName + "\\.valeur").addClass("typeCustom");
          this.typeFormatByClass[fieldName + ".valeur"] = fieldData.masque;
        } else {
          $domEl.find("input." + fieldName + "\\.valeur").addClass("typeLong");
        }
        $domEl.find("input." + fieldName + "\\.valeur").removeAttr("maxlength");
      } else if (fieldData.type === "D") {
        const $divAppend = $("<div>");
        const $divGroup = $("<div>");

        CWFORMS.setDatepicker(this, "." + fieldName + "\\.valeur");
        $domEl.find("div.input-group").addClass("c-date-selector");
        $divAppend.addClass("input-group-append").addClass("cw-datepickerBtn");
        $divGroup.addClass("input-group-text");
        $domEl.find(".ui-datepicker-trigger").detach().appendTo($divGroup);
        $divAppend.append($divGroup);
        $domEl.find("div.input-group").append($divAppend);
        // fills the dates if needed
        $domEl.find("." + fieldName + "\\.valeur").addClass("typeDate");
        $domEl.find("." + fieldName + "\\.valeur").removeAttr("maxlength");
      } else if (fieldData.type === "J") {
        $domEl.find("input." + fieldName + "\\.valeur").addClass("typeJour");
      } else {
        $domEl.find("input." + fieldName + "\\.valeur")
      }
      $labelEl.text(fieldData.libelle);
    }
    $domEl.show();
    // evaluate if EVTG_ID this must be readonly or not
    if (fieldData.code === "EVTG_ID") {
      if (CWSTR.isBlank(fieldData.valeur)) {
        $domEl.hide();
      } else {
        $domEl.find("input." + fieldName + "\\.valeur").attr("readonly", "true");
      }
    }
  }

  _createCombo(fieldData: { [key: string]: any }): string {
    if (fieldData) {
      const idCombo = fieldData.code.toLowerCase();
      const combo = new CWComboBoxView2({
        id: "cmb" + idCombo + ".valeur",
        ws: new cwGererEvtGenComplementComboColl([], { codif: fieldData.codif, "typeInfocomp": fieldData.type }),
        habContext: this.workflow.formModel.getHabContext(),
        name: idCombo + ".valeur",
        required: fieldData.oblig,
        optionsRender: (value: { [key: string]: any }): string => {
          if (!value || CWSTR.isBlank(value.code)) {
            return "";
          }
          return value.libelle + " (" + value.code + ")";
        },
        preprocessBeforeSetItem: (item: { [key: string]: any }): { code: string; libelle: string } => {
          return { code: item.valeur, libelle: "" };
        }
      });
      this.listenTo(combo, "comboEdited", this._formEdited);
      return combo.render().el;
    }
    return null;
  }

  /**
   * Creates and returns an input text field.
   */
  _createInputText(idInput: string, size: number, maxLength: number): JQuery {
    const input = $("<input>").addClass(idInput + ".valeur").addClass("form-control").attr({ "type": "text", "size": size, "maxlength": maxLength });

    return input;
  }

  /**
   * Paints the input fields or combos and their labels.
   */
  _paintFields(view: CWBaseView, infocoms: { [key: string]: any }, fieldToPaint: any, fieldName: string): void {
    const container = $("<div>").addClass("form-group").addClass(fieldName + "-container");
    //create the label
    container.append($("<label>").addClass("lb" + fieldName).attr({ "for": fieldName + ".valeur" }).text(infocoms.libelle));
    if (!CWSTR.isBlank(infocoms.codif)) {
      //show the combo
      container.append(fieldToPaint.render().el);
    } else {
      //Paint the input fields
      container.append($("<span>").addClass("cmb" + fieldName + ".valeur").html(fieldToPaint));

      if (infocoms.type === "N") {
        if (infocoms.masque) {
          container.find("." + UTILS.addScapeSlashesForJQuerySelector(fieldName + ".valeur")).addClass("typeCustom");
          this.typeFormatByClass[fieldName + ".valeur"] = infocoms.masque;
        } else {
          container.find("." + UTILS.addScapeSlashesForJQuerySelector(fieldName + ".valeur")).addClass("typeLong");
        }
        container.find("." + UTILS.addScapeSlashesForJQuerySelector(fieldName + ".valeur")).removeAttr("maxlength");
      }

    }
    //evaluate if this must be hidden or not
    if (CWSTR.isBlank(infocoms.libelle) || infocoms.libelle.charAt(0) === ".") {
      container.hide();
    } else {
      container.show();
    }
    //Evaluate if this is required or not
    if (!CWSTR.isBlank(infocoms.oblig) && infocoms.oblig === true) {
      container.find("." + UTILS.addScapeSlashesForJQuerySelector(fieldName + ".valeur")).addClass("required");
      container.find(".lb" + UTILS.addScapeSlashesForJQuerySelector(fieldName)).addClass("required");
    }
    //Add the new container
    $(view.el).find(".gererevtgen-infocompsaisie").append(container);

    //fills the dates if needed
    if (CWSTR.isBlank(infocoms.codif) && infocoms.type === "D") {
      CWFORMS.setDatepicker(view, "." + UTILS.addScapeSlashesForJQuerySelector(fieldName + ".valeur"));
      container.find("." + UTILS.addScapeSlashesForJQuerySelector(fieldName + ".valeur")).addClass("typeDate").removeAttr("maxlength");
    }
  }

  _initModeAjout(evtgen: CWGererEvtGenModel, callback: () => void): void {
    this.model.updateHabContext({
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: "A"
    });

    this.btnBar.model.trigger("show:save");
    this.btnBar.model.trigger("show:revert");


    if (this.context.ctxEcran !== "agenda") {
      evtgen.set("datedeb", this.context.ctxValeursCreation.EvtGenDatedebut || "");
      evtgen.set("datefin", this.context.ctxValeursCreation.EvtGenDatefin || "");
    }

    evtgen.set("heuredeb", this.context.ctxValeursCreation.AbsHeureDebut || "0000");
    evtgen.set("heurefin", this.context.ctxValeursCreation.AbsHeureFin || "0000");
    evtgen.set("matricule", this.context.ctxGestionCollab.matricule || "");
    evtgen.set("code", this.context.ctxTypologieEvenement.code);
    if (this.context.ctxTypologieEvenement.saiauto) {
      evtgen.set("modesaisie", this.context.ctxTypologieEvenement.saiauto[0].code);
    } else if (this.context.ctxTypologieEvenement.modesaisies && this.context.ctxTypologieEvenement.modesaisies[0].autorise) {
      evtgen.set("modesaisie", this.context.ctxTypologieEvenement.modesaisies[0].code)
    }

    // Get dynamic fields from typologie details
    _.each(this.context.ctxTypologieEvenement, (value: any, key: string): void => {
      if (key.indexOf("evtg_") > -1) {
        evtgen.set(key, value);
      }
    });

    evtgen.store();
    this.model.set("value", evtgen); //This will trigger a _setContextGMSituation

    const dateSelected = this.context.ctxVueJourneeDate;
    if (CWSTR.isBlank(this.model.get("value").attributes.datedeb)) {
      this.model.get("value").attributes.datedeb = dateSelected;
    }
    if (CWSTR.isBlank(this.model.get("value").attributes.datefin)) {
      this.model.get("value").attributes.datefin = dateSelected;
    }
    const fieldset = this.$el.find("fieldset");

    this._mapModelToForm(fieldset, this.model.get("value"), {}, true);

    //Customer 193084
    if ((!CWSTR.isBlank(this.model.get("value").context.ctxTypologieEvenement) && !this.model.get("value").context.ctxTypologieEvenement.sairepet) || this.disabled === true) {
      this.$el.find(".phx-icon-triangle-s").hide();
    } else {
      this.$el.find(".phx-icon-triangle-s").show();
      this.$el.find(".modeSaisie .phx-multiLabel-btn").hide();
    }
    this.model.store();
    this.original = evtgen.clone();
    this._showTypeStatusTitle();
    this._configBtnBar();
    this._showIndicateurEvenamentMessage();
    if (callback) {
      callback();
    }
    this._manageRestrictions(evtgen);
    this._manageWKFHisto();
    this._renderMessageInformatif(this.model.get("value"));
    this.btnBar.model.set("mode", "E");
    this._storeEvtGen();
    if (CWSTR.isBlank(this.context.ctxActionsPossibles) && CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("btn:click", "new");
    }
    //hide historique de l'evenement
    this._updateVisibiliteOrigine();

    if (this.context && this.context.ctxEcran === "saisiescoll") {
      this.manageModeUnselected(true);
      this.$el.css({ "overflow": "hidden" });
    }
    this._LoadDynamicFieldData();
  }

  _initForAction(callback?: () => void): void {
    this.model.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces
    }));

    if (callback) {
      callback();
    }
  }

  _initDefaultMode(evtgen: CWGererEvtGenModel, callback?: () => void): void {
    if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("show:new");
      this.btnBar.model.trigger("show:save");
      this.btnBar.model.trigger("show:revert");
    } else {
      this.btnBar.model.trigger("hide:new");
      this.btnBar.model.trigger("hide:save");
      this.btnBar.model.trigger("hide:revert");
    }
    evtgen.store();
    this.model.set("value", evtgen); //This will trigger a _setContextGMSituation
    this.model.store();
    this._configBtnBar();
    this._manageRestrictions(evtgen);
    this._manageWKFHisto();
    this._renderMessageInformatif(this.model.get("value"));
    this.btnBar.model.set("mode", "R");
    if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
      if (CWSTR.isBlank(this.context.ctxTypologieEvenement) || CWSTR.isBlank(this.context.ctxTypologieEvenement.code)) {
        this.btnBar.model.trigger("disable:new");
      }
    }
    this._disableForm();
    //S'il est requis désactiver le form dans ce point, les pièces jointes ont dépendance
    //de la valeur "this.disable". Pour cette raison, managePieceJointe doit aller après
    //de "this._disableForm(true)"
    this._managePieceJointe();
    this._storeEvtGen();
    this.manageModeUnselected(true);
    //Il doit être la dernier action
    if (callback) {
      callback();
    }
  }

  _showTypeStatusTitle(): void {
    if (this.context.ctxEcran !== "saisiescoll") {
      this._setTitlePopUp();
    }
    this._paintWorkflowStatus();
  }

  _showCreationButtons(): void {
    this.btnBar.model.trigger("show:new");
  }

  _manageActionsPossibles(): void {
    //["Creer","Supprimer","Modifier","Valider"]
    if (this.context.ctxActionsPossibles) {
      if (this.context.ctxModeInitialisation === "Consultation") {

        if (this.context.ctxActionsPossibles.indexOf("Modifier") === -1 && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
          this._disableForm();
        }

        if (this.context.ctxActionsPossibles.indexOf("Creer") === -1 || this.context.ctxModeRepresentation === "pop-up") {
          this.btnBar.model.trigger("hide:new");
        } else {
          this.btnBar.model.trigger("show:new");
        }

        if (this.context.ctxActionsPossibles.indexOf("Supprimer") === -1) {
          this.btnBar.model.trigger("hide:delete");
        } else {
          this.btnBar.model.trigger("show:delete");
        }

        if (this.context.ctxActionsPossibles.indexOf("Valider") === -1) {
          this.btnBar.model.trigger("hide:valider");
        } else if (this.context.ctxEcran !== "valevt") {
          if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation)) {
            this.btnBar.model.trigger("show:valider");
          }
        }
      } else {
        if (this.context.ctxActionsPossibles && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion) && this.context.ctxActionsPossibles.indexOf("Creer") !== -1) {
          this._showCreationButtons();
        }
      }
    }
    this.btnBar.model.trigger("hide:copy");
  }

  _disableForm(): void {
    this.disabled = true;
    this.$el.find(".phx-multiLabel-btn").hide();
    CWFORMS.setFormReadonly(this.$el, true, false);

  }

  _enableForm(forced?: boolean): void {
    if (forced !== true) {
      forced = false;
    }
    this.disabled = false;
    this.$el.find(".phx-multiLabel-btn").show();
    CWFORMS.setFormReadonly(this.$el, false, forced);
    CWFORMS.setFieldReadonly(this.$el.find(".dureecalculee"), true, false);
    CWFORMS.setFieldReadonly(this.$el.find("input.evtg_id\\.valeur"), true, false);
  }

  _manageUtilisateur(): void {
    if (this.context.ctxUtilisateur === "Responsable" && this.context.ctxGestionCollective === false && !CWSTR.isBlank(this.context.ctxGestionCollab)) {
      // information du collaborateur
      this.$el.find(".c-gestionCollab").css("display", "");
      let collabText = this.context.ctxGestionCollab.nom + " " + this.context.ctxGestionCollab.prenom;
      if (CWSTR.isBlank(this.COLLAB_ID) || this.COLLAB_ID === "matric") {
        collabText += " (" + this.context.ctxGestionCollab.matricule + ")";
      } else if (this.context.ctxGestionCollab.matric_aux) {
        collabText += " (" + this.context.ctxGestionCollab.matric_aux + ")";
      } else if (this.context.ctxGestionCollab.matricule) {
        collabText += " (" + this.context.ctxGestionCollab.matricule + ")";
      }
      this.$el.find(".c-gestionCollab").html(collabText);
    } else {
      this.$el.find(".c-gestionCollab").css("display", "none");
    }
  }

  _manageHabilitation(): void {
    if (!CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:new");
      this.btnBar.model.trigger("hide:nouveau");
    }

    if (!CWHABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion) && this.context.ctxModeInitialisation !== "Ajout") {
      // we cannot disable the form if we are in "collectifs"
      if (objs.appRt.workflow.get("usecase") !== "saisiescoll") {
        this._disableForm();
      }
    }

    if (!CWHABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion) && !CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:revert");
      this.btnBar.model.trigger("hide:save");
    }

    if (!CWHABILITATION.canDelete(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("hide:delete");
    }

    if (!CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation)) {
      this.btnBar.model.trigger("hide:valider");
    }
  }

  //	Manage restrictions for actions
  _manageRestrictions(model: CWGererEvtGenModel): void {
    if (model.get("statut") && !CWSTR.isBlank(model.get("statut").code)) {
      if (this.context.ctxUtilisateur === "Collaborateur") {
        this.btnBar.model.trigger("hide:valider");

        switch (model.get("statut").code) {
          case "A": //Accepte
            this._disableForm();
            break;
          case "H": //Hors validation
            this._disableForm();
            break;
          case "T":
          case "D": // Demande
            if (model.get("etat") === "X") {
              this._disableForm();
            }
            break;
          default:
            this._enableForm();
            break;
        }
      } else if (this.context.ctxUtilisateur === "Responsable") {
        switch (model.get("statut").code) {
          case "D": //Demande
          case "T": //Demande
            this._disableForm();
            this.btnBar.model.trigger("hide:delete");
            break;
          case "I": //En cours
            this._disableForm();
            this.btnBar.model.trigger("hide:delete");
            break;
          case "A": //Accepte
            this.btnBar.model.trigger("hide:valider");
            this.btnBar.model.trigger("show:delete");
            break;
          case "H": //Hors validation
            this.btnBar.model.trigger("hide:valider");
            if (CWHABILITATION.canDelete(this.context.ctxHabilitation.HabilitationGestion)) {
              this.btnBar.model.trigger("show:delete");
            }

            break;
          default:
            this._enableForm();
            break;
        }
      }

      if (model.get("statut").code === "R") { //Refuse
        this._disableForm();
        this.btnBar.model.trigger("hide:valider");
        this.btnBar.model.trigger("hide:delete");
      }
    }
    this._managePieceJointe();
  }

  _manageWKFHisto(historique: boolean = true): void {
    if (historique && this.context.ctxModeInitialisation !== "Ajout" && CWSTR.getElValue(this.model.get("value"), "historique") === true) {
      const typeLabel = "EVTGEN";
      const demandeId = CWSTR.getElValue(this.model.get("value"), "iddemande");
      const refEvenement = CWSTR.getElValue(this.model.get("value"), "evenement");

      this.contextComponentWkf = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: this.context.ctxHabilitation.HabilitationAcces,
        ctxTypeEvenement: typeLabel,
        ctxRefEvenement: refEvenement,
        ctxDemId: demandeId,
        ctxModeRestitution: "Imbrique",
        ctxActivModeSynth: this.ctxActivModeSynth,
        ctxModeDefaut: "Replie",
        ctxModeRech: true
      };

      this.composantWorkflow = new CWWkflhistoView();
      this.composantWorkflow.render();
      this.composantWorkflow.setContext(this.contextComponentWkf);
      this.composantWorkflow.hideTitle();
      this.composantWorkflow.hideIcon();

    } else {
      $(this.el).find(".gererevtgen-wkfhisto").hide();
    }
  }

  _managePieceJointe(): void {
    const refEvenement = CWSTR.getElValue(this.model.get("value"), "evenement");
    //const mode = !CWSTR.isBlank(this.model.get("value").context) ? this.model.get("value").context.ctxModeInitialisation : this.context.ctxModeInitialisation;
    const paramJustif = CWDIVERS.get("pJointe") === "1" ? true : false;

    //obtain the max number of pieces availables
    const maxPieces = !CWSTR.isBlank(this.workflow.maxPieces) ? this.workflow.maxPieces.get("maxi") : 0;

    if (this.context.ctxGestionCollective !== true && this.context.ctxPieceJointe === true && maxPieces > 0 && paramJustif) {
      const context = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: this.context.ctxHabilitation,
        ctxRefEvenement: refEvenement,
        ctxModeRestitution: "Imbrique",
        ctxModeDefaut: "Replie",
        ctxActionsPossibles: this.disabled ? [] : ["Creer", "Supprimer"],
        ctxGestionCollab: this.context.ctxGestionCollab
      };

      this.pieceJointeModified = false;
      this.pieceJointe = new CWPieceJointes({
        context: context,
        type: "PJEVTGEN",
        maxPieces: maxPieces,
        popupGerer: true,
        appendTo: (this.context.ctxModeRepresentation !== "pop-up") ? this.context.ctxAppendTo : null,
        modal: true
      });

      //overwrite managebuttons function to check if the evtgen can be modified or not.
      this.pieceJointe._oldManageButtons = this.pieceJointe._manageButtons;
      this.pieceJointe._manageButtons = (event): void => {
        this._focused(event, (): void => {
          this.pieceJointe._oldManageButtons(event);
        });
      };

      $(this.el).find(".gererevtgen-piecesjointes").html(this.pieceJointe.render().el);
      this.pieceJointe.setContext(context);
      this.pieceJointe.setDescription(this.getEvtGenDescription(this.model.get("value")));
      this.pieceJointe.setPiecesJointes(this.model.get("value").get("piecesjointes"));

      //Customer 193084
      if ((!CWSTR.isBlank(this.model.get("value").context.ctxTypologieEvenement) && !this.model.get("value").context.ctxTypologieEvenement.sairepet) || this.disabled === true) {
        this.$el.find(".phx-multiLabel-btn").hide();
      } else {
        this.$el.find(".phx-multiLabel-btn:first").show();
        this.$el.find(".modeSaisie .phx-multiLabel-btn").hide();
      }

      this.pieceJointe.model.on("piecejointeChanged", (): void => {
        this.model.trigger("form:edited");
        this.pieceJointeModified = true;
      }, this);

      if (this.disabled) {
        this.pieceJointe.desactiverPieceJointe(true);
      }

      $(this.el).find(".gererevtgen-piecesjointes").show();
    } else {
      $(this.el).find(".gererevtgen-piecesjointes").hide();
    }

  }

  getEvtGenDescription(evtgen: CWGererEvtGenModel): string {
    let dated: string = null;
    let datef: string = null;
    const unite = ",";
    const motif = "";
    const type = this.context.ctxTypologieEvenement ? this.context.ctxTypologieEvenement.libelle : null;
    let description = "";

    if (evtgen) {

      if (!CWSTR.isBlank(evtgen.get("datedeb"))) {
        dated = CWTYPE.DATE.format(evtgen.get("datedeb"), CWTYPE._getFormatByCode("DATE"));
      }
      if (!CWSTR.isBlank(evtgen.get("datefin"))) {
        datef = CWTYPE.DATE.format(evtgen.get("datefin"), CWTYPE._getFormatByCode("DATE"));
      }

      if (dated && datef) {
        const fdated = "<span class='phx-bold-input-style'>" + dated + "</span>";
        const fdatef = "<span class='phx-bold-input-style'>" + datef + "</span>";
        const funite = "<span class='phx-input-style'>" + unite + "</span>";

        if (dated === datef) {
          description = i18n.t('common:gererevtgen.evtGenDescription1', { "0": fdated, "1": funite, "2": type, "3": motif, interpolation: { escapeValue: false } });
        } else {
          description = i18n.t('common:gererevtgen.evtGenDescription2', { "0": fdated, "1": fdatef, "2": funite, "3": type, "4": motif, interpolation: { escapeValue: false } });
        }
      } else {
        description = i18n.t('common:gererevtgen.evtGenDescription3', { "0": motif });
      }
    }
    return description;
  }

  _getFirstLetter(word: string): string {
    let letter = "";
    if (_.isString(word) && word.length > 0) {
      letter = word.charAt(0);
    }

    return letter;
  }

  _getLetter(word: string, position: number): string {
    let letter = "";
    if (_.isNumber(position) && !_.isNaN(position)) {
      if (_.isString(word) && (word.length > (position - 1))) {
        letter = word.charAt(position - 1);
      }
    }
    return letter;
  }

  _isVowel(char: string): boolean {
    return /^[aeiouáàéèíìóòúùâêîôûäëïöü]$/.test(char.toLowerCase());
  }

  _setTitlePopUp(): void {
    let title = "";
    const type = this.context.ctxTypologieEvenement;
    title = CWSTR.toFirstUpperCase(type.intitule);
    let $span: JQuery = null;
    if (this.context.ctxModeInitialisation === "Ajout") {
      $span = $('<span>').addClass('gererevtgen-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne').html(String(" - ").concat(i18n.t('common:gererevtgen.creation_en_cours')));
    } else if (this.context.ctxModeInitialisation === "Consultation") {
      $span = $('<span>').addClass('gererevtgen-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne d-none').html(String(" - ").concat(i18n.t('common:gererevtgen.modification_en_cours')));
    }

    if (this.context.ctxActionsPossibles.indexOf("Creer") !== -1 && this.context.ctxModeInitialisation === "Ajout") {
      let preTitle = type.genre === "M" ? i18n.t('common:gererevtgen.nouveau') : i18n.t('common:gererevtgen.nouvelle');

      if (UTILS.getLanguage() === "fr" &&
        (this._isVowel(this._getFirstLetter(type.intitule)) ||
          (this._getFirstLetter(type.intitule).toLowerCase() === 'h' && this._isVowel(this._getLetter(type.intitule, 2))))) {
        preTitle = type.genre === "M" ? i18n.t('common:gererevtgen.nouveauAvantVoix') : i18n.t('common:gererevtgen.nouvelle');
      }
      title = preTitle + " " + title;
    }
    if (!(this.parent instanceof CWMEvenementsRouter)) {
      let titlObjt = null;
      if ($span) {
        titlObjt = $('<div>').append($('<div>').addClass("gererevtgen-title-popup row ").append($('<span>').addClass("typeEvtgen col").text(title.toUpperCase()).append($span)));
      }
      else {
        titlObjt = $('<div>').append($('<div>').addClass("gererevtgen-title-popup row ").append($('<span>').addClass("typeEvtgen col-8").text(title.toUpperCase())));
      }
      titlObjt.find(".gererevtgen-title-popup").append('</span> <span class="c-titleHistoWkf"></span>');
      this.parent.isTitleHTML = true;
      this.parent._setTitle(titlObjt.html());
    }
    else {
      if ($span) {
        title += $span[0].outerHTML;
      }
      this.trigger("setLayoutTitle", title);
    }
  }

  _setContextGMSituation(): void {
    this.hasBeenFocused = false;
    this._mapToForm();
  }

  render(avoidMaxPiecesFetch?: boolean): CWGererEvtGenView {
    const json = { "i18n": i18n, "UTILS": UTILS };
    this.$el.empty();

    this.$el.html(this.template(json));

    this.renderForm();

    this._renderCommentaire();

    if (this.context) {
      this._setInitialContext(this.context, avoidMaxPiecesFetch);
    }

    if (this.context.ctxModeRepresentation === "pop-up") {
      this.trigger("manageUnselected", false);
    }

    if (this.parent) {
      if (!(this.parent instanceof CWMEvenementsRouter)) {
        $(this.parent.el).css("height", "auto");
      }
      this.$el.parent().parent().css({ "min-width": "600px" });
    }

    return this;
  }

  renderForm(): void {
    // MultiLabels
    this._renderDeroulantLabels();

    this._initCombos();
    this.$el.find(".comboOrigine").html(this.comboOrigine.render().el);

    // Dates
    CWFORMS.setDatepicker(this, ".datedeb");
    CWFORMS.setDatepicker(this, ".datefin");
  }

  _HistoDetaille(): void {
    this.ctxActivModeSynth = false;
  }

  _HistoSyntetic(): void {
    this.ctxActivModeSynth = true;
  }

  _configBtnBar(): void {
    this._configureNouveauButton();
  }

  _configureValidationButton(): void {
    const model = this.model.get("value");
    const profils = model.get("profils");

    const ul = $(".gererevtgen-detail", this.el).find("ul.validerListMenu");
    ul.empty();
    if (profils && profils.length === 1 && profils[0] && ul) {
      const accepter = $("<li>");
      const refuser = $("<li>");
      let a = $('<a class="menuItem" data-value="2">' + i18n.t('common:gererevtgen.btnAccepter') + '</a>');
      a.attr("data-code", profils[0].code);
      accepter.append(a);
      ul.append(accepter);

      a = $('<a class="menuItem" data-value="3">' + i18n.t('common:gererevtgen.btnRefuser') + '</a>');
      a.attr("data-code", profils[0].code);
      refuser.append(a);
      ul.append(refuser);
    } else if (profils && profils.length > 1 && ul) {
      const accepter = $("<li>");
      const refuser = $("<li>");
      accepter.append('<span class="ui-corner-all">' + i18n.t('common:gererevtgen.btnAccepter') + '</span>');
      ul.append(accepter);
      _.each(profils, (obj: { [key: string]: any }): void => {
        const item = $("<li>");
        const a = $('<a class="menuItem" data-value="2">- ' + obj.libelle + '</a>');
        a.attr("data-code", obj.code);
        item.append(a);
        ul.append(item);
      }, this);

      refuser.append('<span class="ui-corner-all">' + i18n.t('common:gererevtgen.btnRefuser') + '</span>');
      ul.append(refuser);
      _.each(profils, (obj: { [key: string]: any }): void => {
        const item = $("<li>");
        const a = $('<a class="menuItem" data-value="3">- ' + obj.libelle + '</a>');
        a.attr("data-code", obj.code);
        item.append(a);
        ul.append(item);
      }, this);
    } else if (!profils || profils.length === 0) {
      this.btnBar.model.trigger("hide:valider");
    }
    $(".gererevtgen-detail", this.el).find("button.valider")
      .button({ icons: { secondary: "ui-icon-triangle-1-s" }, text: true })
      .click(() => {
        const menu = $(".gererevtgen-detail", this.el).find("ul.validerListMenu").show().position({
          my: "left top",
          at: "left bottom",
          of: this
        });
        $(document).one("click", () => {
          menu.hide();
        });
        return false;
      });

    $(".gererevtgen-detail", this.el).find("ul.validerListMenu").hide().menu();
  }

  _configureNouveauButton(): void {
    const buttonNouveau = $(".gererevtgen-detail", this.el).find("button.nouveau");

    buttonNouveau.button({ icons: { secondary: "ui-icon-triangle-1-s" }, text: true })
      .click((): boolean => {
        const menu = $(".gererevtgen-detail", this.el).find("ul.nouveauListMenu").show().position({
          my: "left top",
          at: "left bottom",
          of: buttonNouveau
        });
        $(document).one("click", (): void => {
          menu.hide();
        });
        return false;
      });

    $(".gererevtgen-detail", this.el).find("ul.nouveauListMenu").hide().menu();
  }

  menuItemListener(event: JQueryEventObject): void {
    const action = (event.currentTarget.attributes as any)["data-value"].nodeValue;

    switch (action) {
      case "2": {
        const code = (event.currentTarget.attributes as any)["data-code"].nodeValue;
        this.btnBar.model.trigger("btn:click", "accepter", code);
        break;
      }
      case "3": {
        const code = (event.currentTarget.attributes as any)["data-code"].nodeValue;
        this.btnBar.model.trigger("btn:click", "refuser", code);
        break;
      }
      case "evtgenButton": {
        this.btnBar.model.trigger("btn:click", "nouvelleEvtGen");
        break;
      }
    }
  }

  // Method for searching a unite libelle in a list of unites with the unite code
  _searchUnitelib(liste: Model, unite: string): string {
    const element = _.find(liste.attributes, (element: { [key: string]: any }): boolean => {
      if (element.code === unite) {
        return true;
      }
      return false;
    });
    return (element.libelle) ? element.libelle : "";
  }

  _getWorkflowStyle(statut: string): string {
    switch (statut) {
      case "A":
        return "ui-phx-statut-accepte";
      case "D":
      case "T":
        return "ui-phx-statut-demande";
      case "I":
        return "ui-phx-statut-en-cours";
      case "R":
        return "ui-phx-statut-refuse";
      case "H":
        return "ui-phx-statut-hors-validation";
    }
    return null;
  }

  _mapToForm(): void {
    if (this.$el === undefined) {
      // this happens if component has been removed before initialization process has finished
      return;
    }

    this._enableForm();
    const mevtgen = this.model.get("value");

    if (CWSTR.isBlank(mevtgen.get("code"))) {
      this.$el.find(".gererevtgen-form-inputs").hide();
    } else {
      this.$el.find(".gererevtgen-form-inputs").show();

      const $fieldset = this.$el.find(".gererevtgen-content");
      mevtgen.setHabContext(this.model.getHabContext());

      const renderers = {};
      // Error management
      mevtgen.off("invalid");
      mevtgen.on("invalid", this._showValidationErrors, this);
      // Clear current validation erros
      this._cleanValidationErrors();

      // Visibility
      this._panelStateRender();

      //Dynamic Data
      this._LoadDynamicFieldData();

      // Map model to the form
      // 4º parameteravoids linking labels, manage manually the datepickers problem
      this._mapModelToForm($fieldset, mevtgen, renderers, true);

      // Manage Delorants Labels
      this._manageMultiLabels();

      //Manual Label Linking
      CWFORMS.linkLabelsToInputs(this.$el.find(".modeSaisie"));
      CWFORMS.linkLabelsToInputs(this.$el.find(".gererevtgen-infocompsaisie"));
      CWFORMS.linkLabelsToInputs(this.$el.find(".typeSaisie div[data-bloc-name='D']"));
      CWFORMS.linkLabelsToInputs(this.$el.find(".typeSaisie div[data-bloc-name='R']"));

      // Calculate Soit
      this._manageSoitValue(mevtgen.get("heuredeb"), mevtgen.get("heurefin"));

      this._manageUniteDeb(CWSTR.getElValue(mevtgen, "modesai_periodeb.code"));
      this._manageUniteFin(CWSTR.getElValue(mevtgen, "modesai_periofin.code"));

      this._enableModeSaisie();
      //this.typeSaisie.setMenuAccess(!this._panelStateIsReadonly());

      this.comboOrigine.setItem(mevtgen.get("origine"));
    }
  }

  _enableModeSaisie(): void {
    let hasModeSaisie = false; //par defaut

    if (!CWSTR.isBlank(this.context) && !CWSTR.isBlank(this.context.ctxTypologieEvenement)) {
      const evtGenTypo = this.context.ctxTypologieEvenement;
      let indexSaiauto;
      // Liste des modes de saisie autorisés. EvtGenSaiHDF vaut true si la liste contient le mode "PLH"
      if (evtGenTypo.saiauto || (evtGenTypo.modesaisies && evtGenTypo.modesaisies[0].autorise)) {
        if ((!CWSTR.isBlank(evtGenTypo.saiauto) && evtGenTypo.saiauto.length > 0) || (!CWSTR.isBlank(evtGenTypo.modesaisies) && evtGenTypo.modesaisies.length > 0)) {
          // It is an array?
          if (!CWSTR.isBlank(evtGenTypo.saiauto)) {
            indexSaiauto = _.findIndex(evtGenTypo.saiauto, (item: { [key: string]: any }): boolean => {
              return item.code === "PLH";
            });
          } else if (!CWSTR.isBlank(evtGenTypo.modesaisies)) {
            indexSaiauto = _.findIndex(evtGenTypo.modesaisies, (item: { [key: string]: any }): boolean => {
              return item.code === "PLH";
            });
          }
          hasModeSaisie = indexSaiauto > -1;
        } else if (!CWSTR.isBlank(evtGenTypo.saiauto) || !CWSTR.isBlank(evtGenTypo.modesaisies)) {
          // It is an object?
          if (!CWSTR.isBlank(evtGenTypo.saiauto)) {
            hasModeSaisie = evtGenTypo.saiauto.code === "PLH";
          } else if (!CWSTR.isBlank(evtGenTypo.modesaisies)) {
            hasModeSaisie = evtGenTypo.modesaisies.code === "PLH";
          }
        }
      }
    }

    if (hasModeSaisie) {
      this.modeSaisie.setMenuAccess(false);
      this.modeSaisie.$el.show();
    } else {
      this.modeSaisie.$el.hide();
    }
  }

  _manageSoitValue(mheuredeb: number, mheurefin: number): void {
    let soit = 0;
    const heuredeb = parseInt(String(mheuredeb), 10);
    const heurefin = parseInt(String(mheurefin), 10);
    const heuredebBool = !CWSTR.isBlank(mheuredeb) && mheurefin >= mheuredeb;
    const heurefinBool = !CWSTR.isBlank(mheurefin) && mheurefin >= mheuredeb;

    if (heuredebBool && heurefinBool && (mheuredeb !== 0 || mheurefin !== 0)) {
      // formula to calcul the duration
      const md = heuredeb % 100;
      const mf = heurefin % 100;
      const hd = Math.floor(heuredeb / 100);
      const hf = Math.floor(heurefin / 100);

      if (mf - md < 0) {
        soit = (hf - hd - 1) * 100 + (mf - md) + 60;
      } else {
        soit = (hf - hd) * 100 + (mf - md);
      }

      if (soit < 0) {
        soit = 0;
      }
    }
    // mevtgen.set("dureecalculee", soit);
    // this.$el.find(".dureecalculee").val(CWTYPE.DURATION.HOUR_MINUTE_24.format(mevtgen.get("dureecalculee")));
    this.$el.find(".dureecalculee").val(CWTYPE.DURATION.HOUR_MINUTE_24.format(soit));
  }

  _manageUniteDeb(value: string): void {
    if (value === "H") {
      this.$el.find(".heuredeb-container").show();
      $(".gererevtgen-form-inputs .heuredeb-container").css("display", "");
      this.$el.find(".selecteurModeComplet").css("margin-right", "-10px");
      $(".heuredeb-container").css("display", "table-cell");
    } else {
      this.$el.find(".heuredeb-container").hide();
    }
  }

  _manageUniteFin(value: string): void {
    if (value === "H") {
      this.$el.find(".heurefin-container").show();
      $(".gererevtgen-form-inputs .heurefin-container").css("display", "");
      this.$el.find(".selecteurModeComplet").css("margin-right", "-10px");
      $(".heurefin-container").css("display", "table-cell");
    } else {
      this.$el.find(".heurefin-container").hide();
    }
  }

  /**
   * Map the multi Labels with the model data
   */
  _manageMultiLabels(): void {
    const gererEvtGen = this.model.get("value");

    if (CWSTR.isBlank(gererEvtGen.get("typesaisie")) && !CWSTR.isBlank(this.context.ctxTypologieEvenement)) {
      const evtGenTypo = this.context.ctxTypologieEvenement;
      // Saidefo: O == Date, N == Periode repetitive
      const typesaisie = evtGenTypo.saidefo === "O" ? "D" : "R";
      gererEvtGen.set("typesaisie", typesaisie);
    }

    if (!CWSTR.isBlank(gererEvtGen.get("typesaisie"))) {
      this.typeSaisie.setLabelByName(gererEvtGen.get("typesaisie"));
    }
  }

  /**
   * Checks if we need to set the panel in ReadOnly
   */
  _panelStateIsReadonly(): boolean {
    if (this.model.get("value")) {
      const canUpdate = CWHABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion);
      return !canUpdate && !this.model.get("value").isNew();
    }
    return false;
  }

  /**
   * Make the From editable or not depending on the habilitations
   */
  _panelStateRender(): void {
    const isReadonly = this._panelStateIsReadonly();
    CWFORMS.setFormReadonly(this.$el, isReadonly, true);
    this._enableModeSaisie();
    // add special fields manually (tables, combos, ...)
    if (isReadonly) {
      this.typeSaisie.setMenuAccess(false, true);
      this.modeSaisie.setMenuAccess(false, true);
    }
  }

  _paintWorkflowStatus(): void {
    const evenement = this.model.get("value");
    let $layout = null;
    if (this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass") {
      if (this.context.ctxModeRepresentation === "pop-up") {
        $layout = $(this.$el.parent().parent()).find('.c-titleHistoWkf');
      } else {
        if (this.parent instanceof CWMEvenementsRouter) {
          $layout = this.parent.layout ? $(this.parent.layout.el).find(".l-panelB-buttonContainer") : this.$el.find(".l-panelB-buttonContainer");
        }
      }
    }
    switch (this.context.ctxModeInitialisation) {
      case "Consultation": {
        $layout.show();

        if (!CWSTR.isBlank(evenement.get("statut").code)) {
          //etiquette workflowhisto
          const subtextEtiquette = evenement.get("etat") === "X" ? i18n.t('common:etiquette_subText.suppression') : "";
          const codeStatut = this.checkSCodetatut(evenement.get("statut").code);
          const text = evenement.get("statut").code === "H" ? "" : evenement.get("statut").libelle;

          if (!_.isEmpty(this.etiquetteHisto1)) {
            this.etiquetteHisto1.model.stopListening();
            this.etiquetteHisto1.model.off();
            this.etiquetteHisto1.$el.off();
            this.etiquetteHisto1.off();
            this.etiquetteHisto1.remove();
            this.etiquetteHisto1 = null;
          }
          this.etiquetteHisto1 = new CWEtiquette({
            id: "etiquetteWorkHisto",
            text: { enabled: text }, //perhaps we should change for i18n text            
            subText: { enabled: subtextEtiquette },
            type: codeStatut,
            state: "actif",
            onlyIcon: this.workflow.wkfEvtGenActiv && evenement.get("iddemande") >= 0 && evenement.get("statut").code === "H"
          });
          // display historique icon only with WKF active
          if (this.workflow.wkfEvtGenActiv === true && evenement.get("iddemande") >= 0) {
            this.etiquetteHisto1.icon = 'horloge_fleche';
            this.listenTo(this.etiquetteHisto1.model, "click", this.histoEtiquetteClick);
          }
          // display background only if label
          if (evenement.get("statut").code === "H") {
            this.etiquetteHisto1.setNoBackground();
          }
          $layout.html(this.etiquetteHisto1.render().el);
        }
        break;
      }
      case "Ajout": {
        //Remove etiquete in mode ajout
        $layout.html("");
        break;
      }
      default: {
        if (!CWSTR.isBlank($layout)) {
          $layout.hide();
        }
      }
    }
  }

  private histoEtiquetteClick(): void {
    if (this.histoEtiquetteOpen) {
      this.histoEtiquettePopup.close();
      this.histoEtiquetteOpen = false;
    } else {
      this.histoEtiquetteOpen = true;
      if (_.isEmpty(this.histoEtiquettePopup)) {
        this.histoEtiquettePopup = new CWPanneauDeroulant({
          fixedButtons: true,
          maxHeight: 360,
          view: this.composantWorkflow,
          viewData: {
            appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
            modal: false,
          },
          notIconClose: true,
          closeOnClickOutside: true
        });
        if (_.isEmpty(this.outsideClickListener)) {
          this.outsideClickListener = (event: any): any => {
            if ($(event.target).parents(".c-panneauDeroulant").length === 0 && $(event.target).closest(".ui-datepicker-header").length === 0 && $(event.target).closest(".ui-datepicker-calendar").length === 0 && this.histoEtiquettePopup) {
              this.histoEtiquettePopup._closePanneau(event, null);
            }
          };
        }
        if (this.context.ctxModeRepresentation === "Imbrique") {
          this.histoEtiquettePopup.$el.on("dialogopen", (): void => {
            $(document).off("click" + (this.workflow.module ? ("." + this.workflow.module) : ""), this.outsideClickListener as () => any);
            $(document).on("click" + (this.workflow.module ? ("." + this.workflow.module) : ""), this.outsideClickListener);
          });
          this.histoEtiquettePopup.$el.on("dialogclose", (): void => {
            $(document).off("click" + (this.workflow.module ? ("." + this.workflow.module) : ""), this.outsideClickListener as any);
            this.histoEtiquetteOpen = false;
          });
        } else {
          this.histoEtiquettePopup.model.on("dlg:close", (): void => {
            this.histoEtiquetteOpen = false;
          });
        }
      } else {
        this.histoEtiquettePopup.view = this.composantWorkflow;
        this.histoEtiquettePopup.viewData = {
          appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
          modal: false
        };
      }
      this.histoEtiquettePopup.setPosition({
        my: "right top",
        at: "right bottom",
        of: $(event.currentTarget)
      });
      this.histoEtiquettePopup.setHeight("auto");
      this.histoEtiquettePopup.setWidth(500);
      this.histoEtiquettePopup.open();
      this.histoEtiquettePopup._setTitle(i18n.t('common:listerevtgen.historique').toUpperCase());
    }
    event.stopPropagation();
  }

  private checkSCodetatut(code: string): validEtiquetteTypes {
    let status: validEtiquetteTypes;
    switch (code) {
      case "D":
        status = 'demande';
        break;
      case "T":
        status = 'demande';
        break;
      case "I":
        status = 'demande';
        break;
      case "A":
        status = 'accepte';
        break;
      case "H"://this case maybe is not useful
        status = 'accepte';
        break;
      case "R":
        status = 'refuse';
        break;
      default:
        status = 'demande';
        break;
    }
    return status;
  }

  _showIndicateurEvenamentMessage(): void {
    const AbsGenereEdT = this.model.get("value").get("generationedt");
    const ctxIdentifiantEvt = this.context.ctxIdentifiantEvt;
    const element = this.$el.find(".gererevtgen-evenamentmessage");

    if (this.context.ctxModeInitialisation === "Consultation" && !CWSTR.isBlank(ctxIdentifiantEvt) && AbsGenereEdT === true) {
      let content = i18n.t('messages:GT_1943') || "";

      content = '<div class="phx-block"><span class="phx-block-title-text ui-phx-icon-metier from-emprei-du-temps"></span></div>';
      element.html(content);
      element.show();
    } else {
      element.html("");
      element.hide();
    }
  }

  _renderDeroulantLabels(): void {
    const json = { "i18n": i18n, "UTILS": UTILS };

    const tplTypeSaisie = gererevtgenMultiLabelTypeSaisieTPL;
    this.typeSaisie = new CWMultiLabel({
      template: tplTypeSaisie(json),
      required: true,
      name: "typesaisie",
      parentView: this
    });
    this.$el.find(".typeSaisie").html(this.typeSaisie.render().el);

    const tplModeSaisie = gererevtgenMultiLabelModeSaisieTPL;
    this.modeSaisie = new CWMultiLabel({
      template: tplModeSaisie(json),
      required: true,
      name: "modesaisie",
      parentView: this
    });
    this.$el.find(".modeSaisie").html(this.modeSaisie.render().el);
    //Filter modeSaisie
    this.modeSaisie.setFilter((response: Array<{ [key: string]: any }>): Array<{ [key: string]: any }> => {
      //return only unités checked
      return _.filter(response, (resp): boolean => {
        let found = false;
        if (!CWSTR.isBlank(this.context) && this.context.ctxValeursEvenement.saiauto) {
          if (this.context.ctxValeursEvenement.saiauto.code === resp.name) { found = true; }
        }
        return found;
      });
    });
  }

  _renderCommentaire(): void {
    if (this.context.ctxTypologieEvenement.soumiswkf === true) {
      this.$el.find(".commentaireWkf").show();
    } else {
      this.$el.find(".commentaireWkf").hide();
    }
  }

  /**
   * Type Saisie change event.
   */
  _changeTypeSaisie(event: JQueryEventObject): void {
    const value = this.model.get("value");
    const className = event.target.className.split(" ")[0];

    value.validationError = null;
    this.evtGenModified = true;
    switch (className) {
      case "R": //répétition
      case "D": //date
        value.set("datefin", value.get("datedeb"));
        if (this.pieceJointe && !CWSTR.isBlank(this.pieceJointe.description)) {
          this.pieceJointe.setDescription(this.getEvtGenDescription(value));
        }
        break;
      case "POURCENT":
      case "UNITE":
      default:
        if (value.get("modesaisie") === "PERIOC") {
          //When changing from type saisie PERIOD to another typesaisie select first valid modesaisie
          value.set("modesaisie", "PLH");
        }
        this.$el.find(".modeSaisie").show();
    }
    value.set("typesaisie", className);
    this._notifyEdition(event);
    this._mapToForm();
  }

  _manageChangeDateDeb(datedeb: string): void {
    const datefin = this.model.get("value").get("datefin");
    if (!CWSTR.isBlank(datedeb) && CWSTR.isBlank(CWTYPE.DATE.validate(datedeb))) {
      const dat = CWTYPE.DATE.strToDate(datedeb);
      const date = CWTYPE.DATE.format(datedeb, CWTYPE._getFormatByCode("DATE_S"));
      $(this.el).find(".gererevtgen-detail .datedeb").datepicker("option", "defaultDate", dat);
      $(this.el).find(".gererevtgen-detail .datedeb").val(date);
      if ($(this.el).find(".gererevtgen-detail .datefin").val() === "" || this._getModel().get("typesaisie") === "D") {
        CWSTR.setElValue(this.model.get("value"), "datefin", datedeb);
        $(this.el).find(".gererevtgen-detail .datefin").datepicker("option", "defaultDate", dat);
        $(this.el).find(".gererevtgen-detail .datefin").val(date);
      } else if (datedeb > datefin) {
        CWSTR.setElValue(this.model.get("value"), "datefin", datedeb);
        $(this.el).find(".gererevtgen-detail .datefin").datepicker("option", "defaultDate", dat);
        $(this.el).find(".gererevtgen-detail .datefin").val(date);
      }
    } else {
      $(this.el).find(".gererevtgen-detail .datedeb").datepicker("option", "defaultDate", SYNC.getServerDate());
    }
    $(this.el).find(".ui-datepicker-trigger").addClass("ui-datepicker-trigger ui-button ui-corner-right ui-button-icon-only");
  }

  _manageChangeDateFin(datefin: string): void {
    if (!CWSTR.isBlank(datefin) && CWSTR.isBlank(CWTYPE.DATE.validate(datefin))) {
      const dat = CWTYPE.DATE.strToDate(datefin);
      const date = CWTYPE.DATE.format(datefin, CWTYPE._getFormatByCode("DATE_B"));
      $(this.el).find(".gererevtgen-detail .datefin").datepicker("option", "defaultDate", dat);
      $(this.el).find(".gererevtgen-detail .datefin").val(date);
    } else {
      $(this.el).find(".gererevtgen-detail .datefin").datepicker("option", "defaultDate", SYNC.getServerDate());
    }
    $(this.el).find(".ui-datepicker-trigger").addClass("ui-datepicker-trigger ui-button ui-corner-right ui-button-icon-only");
  }

  _manageView(event: JQueryEventObject, data: object): void {
    let datedeb: string = null;
    let datefin: string = null;
    const mevtgen = this.model.get("value");
    const className = event.target.className.split(" ")[0];

    this.evtGenModified = true;
    this._change(event, data);

    if (className === "datedeb") {
      this._manageChangeDateDeb(mevtgen.get("datedeb"));
    } else if (className === "datefin") {
      this._manageChangeDateFin(mevtgen.get("datefin"));
    }

    // Addition default change Logic
    switch (className) {
      case "datedeb":
        datedeb = mevtgen.get("datedeb");
        datefin = mevtgen.get("typesaisie") === "D" ? mevtgen.get("datedeb") : mevtgen.get("datefin");
        if (mevtgen.get("typesaisie") === "R" && datefin <= datedeb) { // if the start date is changed, update end date
          const dateAux1 = CWTYPE.DATE.strToDate(datedeb);
          const fieldset = this.$el.find(".multiLabelTypeSaisie_form");
          datefin = CWTYPE.DATE.dateToStr(dateAux1, CWTYPE.DATE.DEFAULT_MASK_BBDD);
          mevtgen.set("datefin", datefin);
          this._cleanValidationErrors();
          this._mapModelToForm(fieldset, this._getModel(), {}, true);
        }
        break;
      case "datefin":
        datedeb = mevtgen.get("datedeb");
        datefin = mevtgen.get("typesaisie") === "D" ? mevtgen.get("datedeb") : mevtgen.get("datefin");
        break;
      case "heuredeb":
      case "heurefin":
        this._manageSoitValue(mevtgen.get("heuredeb"), mevtgen.get("heurefin"));
        break;
      default:
      //Nothing
    }
    if (this.pieceJointe) {
      this.pieceJointe.setDescription(this.getEvtGenDescription(mevtgen));
    }
    //infocomp
    if (!_.isEmpty(mevtgen) && className?.toLowerCase().indexOf("evtg_") >= 0) {
      const tokens = className.split(".");

      if (tokens.length > 2) {
        const modelInfoComp = mevtgen.get(tokens[1].toLowerCase());

        modelInfoComp.valeur = data;//mettre à jour l'information de "valeur" avec l'information du combo ou autre élément
      }
    }
  }

  _manageUniteHeure(unit: string): void {
    if (unit === 'H') {
      this.$el.find(".heure").show();
      //this._manageDureeView();
    } else {
      const mevtgens = this.model.get("value");
      mevtgens.set("heuredeb", "0000");
      mevtgens.set("heurefin", "0000");

      this.$el.find(".heure .heuredeb").val(CWTYPE.HOUR_MINUTE.format("0000"));
      this.$el.find(".heure .heurefin").val(CWTYPE.HOUR_MINUTE.format("0000"));
      this.$el.find(".heure .duree").val(CWTYPE.DURATION.HOUR_MINUTE.format("0000"));
      this.$el.find(".heure").hide();
    }
  }

  _compareModels(): string {
    const base = [];
    let needConfirmation = false;
    if (this.original.get("datedeb") !== this.model.get("value").get("datedeb")) {
      base.push(i18n.t('messages:GT_1333'));
      needConfirmation = true;
    }
    if (this.original.get("datefin") !== this.model.get("value").get("datefin")) {
      base.push(i18n.t('messages:GT_1334'));
      needConfirmation = true;
    }
    if (this.original.get("motif").code !== this.model.get("value").get("motif").code) {
      base.push(i18n.t('messages:GT_1335'));
      needConfirmation = true;
    }
    if (this.original.get("heuredeb") !== this.model.get("value").get("heuredeb") ||
      this.original.get("heurefin") !== this.model.get("value").get("heurefin")) {
      base.push(i18n.t('messages:GT_1337'));
      needConfirmation = true;
    }
    if (this.original.get("duree") !== this.model.get("value").get("duree")) {
      base.push(i18n.t('messages:GT_1338'));
      needConfirmation = true;
    }
    if (needConfirmation === true) {
      return i18n.t('messages:GT_1049') + " " + base.join(",");
    }
    return null;
  }

  _tooltipListener(event: JQueryEventObject): void {
    if (this.context.ctxHistoriqueWKF === false) {
      const infobulleWorkflow = new CWWkflhistoView();

      const jquery = (event.currentTarget.attributes as any)["aria-describedby"];
      if (jquery) {
        return;
      }

      const typeLabel = this.context.ctxTypeEvenement;

      let demandeId: string = null;
      let refEvenement: string = null;
      if (CWSTR.getElValue(this.model.get("value"), "iddemande") === 0) {
        refEvenement = CWSTR.getElValue(this.model.get("value"), "evenement");
        demandeId = null;
      } else {
        demandeId = CWSTR.getElValue(this.model.get("value"), "iddemande");
        refEvenement = null;
      }

      const contextInfobulleWkf = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: this.context.ctxHabilitation.HabilitationAcces,
        ctxTypeEvenement: typeLabel,
        ctxRefEvenement: refEvenement,
        ctxDemId: demandeId,
        ctxModeRestitution: "infobulle",
        ctxActivModeSynth: true,
        ctxModeDefaut: "Replie",
        ctxModeRech: true
      };
      infobulleWorkflow.setContext(contextInfobulleWkf);

      $(event.currentTarget).tooltip({ content: "" });
      $(event.currentTarget).tooltip("option", "content", function () {
        return infobulleWorkflow.$el.html();
      });
      $(event.currentTarget).prop("title", "");

      infobulleWorkflow.model.get("histoDem").once("sync", function () {
        $(event.currentTarget).tooltip("open");
      });
    }
  }

  _focused(event: JQueryEventObject | JQueryKeyEventObject, callback?: () => void): void {
    if (callback) {
      callback();
    }
  }

  // store evtgen information for a possible revert
  _storeEvtGen(): void {
    this.storeEvtGen = {
      evtgen: this.model.get("value"),
      ctxModeInitialisation: this.context.ctxModeInitialisation,
      ctxTypeEvenement: this.context.ctxTypeEvenement,
      ctxHistoriqueWKF: this.context.ctxHistoriqueWKF
    };
    this.storeEvtGen.evtgen.store();
  }

  _restoreEvtGen(): void {
    this.evtGenModified = false;
    if (this.storeEvtGen) {
      this.model.set("value", this.storeEvtGen.evtgen);
      this.context.ctxModeInitialisation = this.storeEvtGen.ctxModeInitialisation;
      this.context.ctxTypeEvenement = this.storeEvtGen.ctxTypeEvenement;
      this.context.ctxHistoriqueWKF = this.storeEvtGen.ctxHistoriqueWKF;
    }
    this.model.get("value").revert();
    this.model.trigger("change:value");
  }

  revertView(): void {
    if (this.context.ctxModeRepresentation === "pop-up" && this.context.ctxModeInitialisation === "Ajout") {
      if (!(this.parent instanceof CWMEvenementsRouter)) {
        this.parent.close();
      }
    } else { //mode Imbrique
      this.hasBeenFocused = false;

      this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestion, natGest: "" });

      this._restoreEvtGen();

      this.workflow.btnBarModel.set("mode", "R");

      if (this.context && this.context.ctxEcran !== "suivicollab") {
        if (this.context.ctxActionsPossibles.indexOf("Creer") >= 0 && this.context.ctxHabilitation &&
          !CWSTR.isBlank(this.context.ctxHabilitation.HabilitationGestion) && CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion) && this.context.ctxModeRepresentation !== "pop-up") {
          this.btnBar.model.trigger("show:new");
        } else {
          this.btnBar.model.trigger("hide:new");
        }
      } else {
        this.btnBar.model.trigger("hide:new");
      }

      this._renderMessageInformatif(this.model.get("value"));
      this._manageActionsPossibles();
      this._manageRestrictions(this.model.get("value"));
      this._showTypeStatusTitle();
      this._manageWKFHisto();

      if (this.context.ctxModeInitialisation !== "Consultation" && CWSTR.isBlank(this.context.ctxModeInitialisation)) {
        this._showCreationButtons();
        this._disableForm();
        //S'il est requis désactiver le form dans ce point, les pièces jointes ont dépendance
        //de la valeur "this.disable". Pour cette raison, managePieceJointe doit aller après
        //de "this._disableForm()"
        this._managePieceJointe();
      }
      if (this.model.get("value").isNew()) {
        const previous = this.model.previous("value");
        if (this.context && this.context.ctxModeRepresentation !== "pop-up" && previous.isNew()) {
          this.btnBar.model.set("mode", "C");
        }
      }
    }
    this.model.trigger("mode:revert");
  }

  savePiecesJointes(evtgen: CWGererEvtGenModel, callback?: () => void): void {
    if (evtgen && this.context.ctxPieceJointe === true && this.pieceJointeModified === true) {
      const evenement = evtgen.get("evenement");

      this.pieceJointe.applyPendingActions(evenement, callback);
    } else {
      if (callback) {
        callback();
      }
    }
  }

  saveEvtGen(): void {
    const target = this.model.get("value");
    let saveAction: string = null;

    if (this.evtGenModified === true || target.isNew()) {

      target.MODEGROUPE = false;

      target.action = "demande";

      saveAction = "update";
      if (target.isNew()) {
        saveAction = "create";
      } else {
        target.blacklist = ["historique", "nom", "evtg_id", "prenom", "matriculeaux", "saiauto"];
      }
      const saveCallback = (fresh: CWGererEvtGenModel): void => {
        this.evtGenModified = false;
        this.savePiecesJointes(fresh, (): void => {
          if (this.context.ctxModeRepresentation === "pop-up") {
            if (!(this.parent instanceof CWMEvenementsRouter)) {
              this.parent.close();
            }
          }
          this.model.trigger("evtGenChanged", fresh, saveAction);
          if (this.parent instanceof CWMEvenementsRouter && !CWSTR.isBlank(this.parent.workflow)) {
            this.parent.workflow.listerEvtGen.workflow.trigger("refreshList");
          }
        });
      };
      const errorSaveCallback = (model: CWGererEvtGenModel): void => {
        if (!CWSTR.isBlank(model.validationError)) {
          // server does not send the error in datedebut (only in datefin)
          // so we create the error manually in the save process.
          if (!CWSTR.isBlank(model.validationError.errors.datefin)) {
            model.validationError.errors.datedeb = model.validationError.errors.datefin;
            delete model.validationError.errors.datefin;
            this._showValidationErrors(model, model.validationError);
          }
        }
      };

      if (target.isValid()) {
        target.save(null, {
          success: saveCallback,
          error: errorSaveCallback
        });
      } else {
        this.workflow.btnBarModel.set("mode", "E");
      }
    } else {
      this.savePiecesJointes(target, (): void => {
        if (this.context.ctxModeRepresentation === "pop-up" && !(this.parent instanceof CWMEvenementsRouter)) {
          this.parent.close();
        }
        this.model.trigger("evtGenChanged", target, saveAction);
      });
    }
  }

  deleteEvtGen(commentaire: string): void {
    const target = this.model.get("value");
    let droit = target.get("droit");

    const habDelete = this.context.ctxUtilisateur === "Responsable" ? "RES_EVTGEN.G" : "COL_EVTGEN.G";
    target.updateHabContext({ foncCour: habDelete, natGest: "S" });

    if (!droit) {
      droit = "N";
    }
    target.action = "demande";
    target.id = this.context.ctxIdentifiantEvt;

    const destroyCallback = (): void => {
      if (this.context.ctxModeRepresentation === "pop-up" && !(this.parent instanceof CWMEvenementsRouter)) {
        this.parent.close();
      }
      this.model.trigger("evtGenChanged", target, "delete");
    };

    if (!CWSTR.isBlank(commentaire)) {
      target.set("commentaire", commentaire);
    } else {
      target.set("commentaire", " ");
      target.set("droit", "N");
    }

    target.destroy({
      dataType: "json",
      url: Configuration.restRoot + "/rest/composant/evtgen/" + encodeURIComponent(target.id) + "/" + encodeURIComponent(target.get("commentaire")) + "/" + encodeURIComponent(droit),
      contentType: 'application/json',
      success: destroyCallback,
      wait: true
    });
  }

  deleteEvenementFromMenuButton(message: { [key: string]: any }): void {
    this.id = encodeURIComponent(message.id);
    message.setHabContext(this.model.getHabContext());
    this.model.set("value", message, { silent: true });
    this.btnBar.model.trigger("btn:click", "delete");
  }

  // actions for buttons in menuLine
  deleteEvtGenFromMenuButton(evtgen: CWGererEvtGenModel | any): void {
    evtgen.setHabContext(this.model.getHabContext());
    this.model.set("value", evtgen, { silent: true });
    this.btnBar.model.trigger("btn:click", "delete");
  }

  accepterEvtGenFromMenuButton(evtgen: CWGererEvtGenModel | any): void {
    evtgen.setHabContext(this.model.getHabContext());
    this.model.set("value", evtgen, { silent: true });
    this.btnBar.model.trigger("btn:click", "accepter", evtgen.profil.code);
  }

  refuserEvtGenFromMenuButton(evtgen: CWGererEvtGenModel | any): void {
    evtgen.setHabContext(this.model.getHabContext());
    this.model.set("value", evtgen, { silent: true });
    this.btnBar.model.trigger("btn:click", "refuser", evtgen.profil.code);
  }

  fetchEvtGen(id: string, callback?: (model: CWGererEvtGenModel) => void): void {
    const evtgen = new CWGererEvtGenModel(null, { context: this.context, SAISIE_HEURE: this.SAISIE_HEURE, SAISIE_DUREE: this.SAISIE_DUREE });
    this.model.updateHabContext({
      foncCour: this.context.ctxHabilitation.HabilitationAcces,
      natGest: ""
    });
    evtgen.setHabContext(this.model.getHabContext());

    evtgen.id = id;
    evtgen.fetch({
      success: (freshModel: CWGererEvtGenModel): void => {
        this.model.set("value", freshModel);
        if (callback) {
          callback(freshModel);
        }
      }
    });
  }

  newEvtGen(): void {
    this.context.ctxModeInitialisation = "Ajout";
    this.context.ctxHistoriqueWKF = false;

    this.model.trigger("mode:create");
    this.model.updateHabContext({
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: "A"
    });

    this._enableForm();
    const evtGen = new CWGererEvtGenModel(null, { context: this.context, SAISIE_HEURE: this.SAISIE_HEURE, SAISIE_DUREE: this.SAISIE_DUREE });

    evtGen.setHabContext(this.model.getHabContext());
    evtGen.set("code", this.context.ctxTypologieEvenement.code);
    evtGen.set("matricule", this.context.ctxGestionCollab.matricule);
    if (this.context.ctxTypologieEvenement.saiauto) {
      evtGen.set("modesaisie", this.context.ctxTypologieEvenement.saiauto.code);
    }

    // Get dynamic fields from typologie details
    _.each(this.context.ctxTypologieEvenement, (value, key: string): void => {
      if (key.indexOf("evtg_") > -1) {
        evtGen.set(key, value);
      }
    });

    this.model.set("value", evtGen);

    this._showTypeStatusTitle();
    this._LoadDynamicFieldData();
    if (!CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this._manageActionsPossibles();
    }
    else {
      this._enableForm();
    }
    this._manageWKFHisto(false);

    this._renderMessageInformatif(this.model.get("value"));
    this._managePieceJointe();

    this._showIndicateurEvenamentMessage();

    this.btnBar.model.trigger("disable:nouveau");
    this.btnBar.model.trigger("hide:valider");
    this.btnBar.model.trigger("show:save");
    this.btnBar.model.trigger("show:revert");
    this.btnBar.model.set("mode", "E");

    this.$el.find("fieldset .datedeb:visible").focus();
  }

  _changeComboCollMotHab(NatGest: string): void {
    let ecran: string = null;

    if (this.model && this.model.getHabContext()) {
      this.comboMotif.coll.setHabContext(this.model.getHabContext());
    }
    if (this.context && this.context.Habilitation && this.context.Habilitation.HabilitationGestion) {
      ecran = !CWSTR.isBlank(this.context.ctxEcran) ? this.context.ctxEcran : "mevenements";
      this.comboMotif.coll.setHabContext(new CWHabilitationContext({
        foncCour: this.context.Habilitation.HabilitationGestion,
        natGest: NatGest,
        onglet: ecran
      }));
    } else if (this.context && this.context.Habilitation && this.context.Habilitation.HabilitationConsultation) {
      ecran = !CWSTR.isBlank(this.context.ctxEcran) ? this.context.ctxEcran : "mevenements";
      this.comboMotif.coll.setHabContext(new CWHabilitationContext({
        foncCour: this.context.Habilitation.HabilitationConsultation,
        natGest: NatGest,
        onglet: ecran
      }));
    }
  }

  accepterEvtGen(commentaire: string, profil: string, justifok: string): void {
    const target = this.model.get("value");
    const droit = CWSTR.isBlank(target.get("droit")) ? "N" : target.get("droit");

    const accepterModel = new CWGererevtgenGestion();
    accepterModel.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: ""
    }));

    accepterModel.action = "accepter";
    accepterModel.id = target.id;
    accepterModel.set("commentaire", commentaire);
    accepterModel.set("justifok", justifok);
    accepterModel.set("droit", droit);
    accepterModel.set("profil", {});
    accepterModel.get("profil").code = profil;
    const accepterCallback = (): void => {
      if (this.context.ctxModeRepresentation === "pop-up" && !(this.parent instanceof CWMEvenementsRouter)) {
        this.parent.close();
      }
      this.model.trigger("evtGenChanged", this.model.get("value"), "update");
    };
    accepterModel.save(null, {
      success: accepterCallback,
      //error -> rien à faire
    });
  }

  refuserEvtGen(commentaire: string, profil: string): void {
    const target = this.model.get("value");
    const droit = CWSTR.isBlank(target.get("droit")) ? "N" : target.get("droit");

    const refuserModel = new CWGererevtgenGestion();
    refuserModel.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: ""
    }));

    refuserModel.action = "refuser";
    refuserModel.id = target.id;
    refuserModel.set("commentaire", commentaire);
    refuserModel.set("droit", droit);
    refuserModel.set("profil", {});
    refuserModel.get("profil").code = profil;
    const refuserCallback = (): void => {
      if (this.context.ctxModeRepresentation === "pop-up" && !(this.parent instanceof CWMEvenementsRouter)) {
        this.parent.close();
      }
      this.model.trigger("evtGenChanged", this.model.get("value"), "update");
    };
    refuserModel.save(null, {
      success: refuserCallback,
      //error -> rien à faire
    });
  }

  valider(callback?: () => void): void {
    this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestion, natGest: "" });
    const target = this.model.get("value");
    target.setHabContext(this.model.getHabContext());

    target.action = "validesaisie";
    const validerCallback = (fresh: CWGererEvtGenModel, response: { [key: string]: any }): void => {
      if (this.context.ctxModeRepresentation === "pop-up" && !(this.parent instanceof CWMEvenementsRouter)) {
        this.parent.close();
      }
      this.model.trigger("evtGenChanged", this.model.get("value"), "update");
      if (response && response.result === "ok") {
        if (callback) {
          callback();
        }
      }
    };
    target.save(null, {
      success: validerCallback,
      error: validerCallback
    });
  }

  _updateVisibiliteOrigine(): void {
    const posOrigin = this.$el.find(".originActif"); //label and combo
    let typeEvt = null;
    let contextActif = null;

    //La présence de cette information dans le formulaire ne sera effective que si,
    // pour le type d’événement, la saisie de l’origine de la demande est autorisée.
    //
    if (this.model.get("value").isNew()) {
      if (!CWSTR.isBlank(this.context.ctxValeursCreation.EvtGenCode)) {
        typeEvt = this.context.ctxValeursCreation.EvtGenCode;
      } else {
        //dans le drag&drop, il est nouveau mais  "this.model.get("value").get("typeevenement")" a la valeur du type
        if (!CWSTR.isBlank(this.model.get("value")) && !CWSTR.isBlank(this.model.get("value").get("code"))) {
          typeEvt = this.model.get("value").get("code");
        }
      }
    } else {
      typeEvt = this.model.get("value").get("code");
    }
    contextActif = GLOBAL_DATA.contextActif.contextActifEvtgen;

    if (CWSTR.isBlank(contextActif) || (!CWSTR.isBlank(typeEvt) && (CWSTR.isBlank(contextActif[typeEvt]) || (contextActif[typeEvt] && contextActif[typeEvt].get("active") !== true)))) {
      posOrigin.hide();
    } //Il n'est pas necessaire l'action "show" parce qu'il vient de le faire(la création)
  }

  getButtonBarRef(): CWButtonBarValidationView {
    return this.btnBar;
  }

  hideBtnBar(): void {
    this.btnBar.model.trigger('hide');
  }

  showBtnBar(): void {
    this.btnBar.model.trigger('show');
  }

  showTitle(): void {
    let $layout: JQuery = null;
    if (this.context && this.context.ctxEcran !== "saisiescoll") {
      if (this.context.ctxModeRepresentation === "Imbrique" && this.parent instanceof CWMEvenementsRouter) {
        $layout = $(this.parent.layout.el).find(".typeEvtGen");
      } else {
        if (!(this.parent instanceof CWMEvenementsRouter)) {
          $layout = this.parent.$el.parent().find(".typeEvtGen")
        }
      }
      $layout.removeClass('d-none');
    }
  }

  private manageModeUnselected(unSelected: boolean): void {
    const formMode: string = this.btnBar.model.get("mode");
    if (unSelected === true && formMode !== "E") {
      this.$el.find(".gererevtgen-form-inputs").hide();
      this.hideBtnBar();
      this.showTitle();
      this.$el.find(".unselectedElementText").show();
      if (this.parent instanceof CWMEvenementsRouter && this.parent.listerEvtGen
        && this.parent.listerEvtGen.tableModel && this.parent.listerEvtGen.tableModel.coll.models.length === 0) {
        this.$el.find(".consulterText").hide();
      }
      else {
        this.$el.find(".consulterText").show();
      }
    } else {
      this.$el.find(".gererevtgen-form-inputs").show();
      this.showBtnBar();
      this.showTitle();
      this.$el.find(".unselectedElementText").hide();
    }
  }

  private selectedTypoChanged(newType: { [key: string]: any }): void {
    if (this.context) {
      this.context.ctxTypologieEvenement = newType;
      if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
        if (CWSTR.isBlank(this.context.ctxTypologieEvenement) || CWSTR.isBlank(this.context.ctxTypologieEvenement.code)) {
          this.btnBar.model.trigger("disable:new");
        }
        else {
          this.btnBar.model.trigger("enable:new");
        }
      } else {
        this.btnBar.model.trigger("hide:new");
      }
      this._renderCommentaire();
    }
  }

  private _displaySubtitle(): void {
    if (this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass") {
      let $layout = null;
      if (this.context.ctxModeRepresentation === "Imbrique" && this.parent instanceof CWMEvenementsRouter) {
        $layout = $(this.parent.layout.el).find(".typeEvtGen");
      } else if (this.context.ctxModeRepresentation === "Imbrique" && !(this.parent instanceof CWMEvenementsRouter)) {
        $layout = this.parent.$el.parent().find(".typeEvtGen");
      }
      if (this.context.ctxModeInitialisation === "Consultation" && $layout) {
        const subtitle = $layout.find('.gererevtgen-subtitle');
        if (subtitle.hasClass('d-none')) {
          subtitle.removeClass('d-none');
        }
      }
    }
  }
}
