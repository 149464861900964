module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!-- css in staticUCs.css -->\n<fieldset class="gererregularisations-detail cw-fieldset">\n    <div class="phx-formTable">\n        <div class="form-group phx-formErrors ui-state-error"></div>\n        <div class="gererregularisation-content">\n            <div class="collab form-group"><label for="matricule" class="cw-required">'+
((__t=(i18n.t("common:gererregularisations.collab")))==null?'':__t)+
'</label><span class="cmbCollaborateurs"></span></div>\n            <div class="gererregularisation-form-inputs"><span class="statutRegulLibelle"></span>\n                <div class="wrap-form">\n                    <div class="motif form-group">\n                      <label for="motif.code" class="cw-required">'+
((__t=(i18n.t("common:gererregularisations.motif")))==null?'':__t)+
'</label>\n                      <span class="cmbMotif" title="'+
((__t=(i18n.t('common:gererregularisations.motif_title')))==null?'':__t)+
'"></span>\n                    </div><div class="gererregularisations-dates dates gererregularisations-detail-form form-group">\n                        <div class="date-container">\n                            <div class="date-group clearfix"><label for="datedeb" class="cw-required">'+
((__t=(i18n.t("common:gererregularisations.datedebut")))==null?'':__t)+
'</label><input type="text" class="datedeb typeDate form-control periodStart" periodId="1" value="" size="11" /></div>\n                        </div><div class="date-container datefinal">\n                            <div class="date-group"><label for="datefin" class="cw-required">'+
((__t=(i18n.t("common:gererregularisations.datefin")))==null?'':__t)+
'</label><input type="text" class="datefin typeDate form-control periodEnd" periodId="1" value="" size="11" /></div>\n                        </div>\n                    </div><div class="valInfo form-group">\n                        <div class="valInfo-container">\n                            <div class="valeur valInfo-group"><label for="valeur" class="cw-required">'+
((__t=(i18n.t("common:gererregularisations.valeur")))==null?'':__t)+
'</label><input type="text" class="valeur typeCustom form-control" value="" size="12" /></div>\n                        </div><div class="valInfo-container">\n                            <div class="spinfocomp valInfo-group"><label for="infocomp">'+
((__t=(i18n.t("common:gererregularisations.commentregul")))==null?'':__t)+
'</label><input type="text" class="infocomp form-control" maxlength="60" size="20"></input></div>\n                        </div>\n                    </div>\n                    <div class="originActif clearfix form-group"><label for="origine.code" class="">'+
((__t=(i18n.t("common:gererregularisations.origine")))==null?'':__t)+
'</label><span class="comboOrigine"></span></div>\n                    <div class="blockCommentaire clearfix form-group"><label for="commentaire">'+
((__t=(i18n.t("common:gererregularisations.comment")))==null?'':__t)+
'</label><input type="text" class="commentaire form-control" maxlength="60" size="20" rows="4"></textarea></div>\n                    <div class="gererregularisations-infocomp clearfix"></div>\n                    <div class="gererregularisations-content-btn-bar"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n    </div>\n    <ul class="validerListMenu menuContent"></ul>\n</fieldset>\n';
}
return __p;
};
