import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLGererrecapitulatifsDetailHeader from '../detailHeader.tpl.html';
import {
  ctxFiltreApplique,
  ctxTriDate,
  CWListerecapGeneralContextInterface,
  CWListerecapGeneralView
  } from 'common/evenements/lister/listerecap/views/cwListerecapGeneral.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWButtonBarValidationView } from 'src/core/controls/cwButtonBarValidation.view';
import { CWCommentPopupView } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/views/cwCommentPopup.view';
import { CWCreerRecapView } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/views/cwCreerRecap.view';
import { CWDialogPopupType, CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWGererPiecesJointesView } from 'src/uc/common/evenements/gerer/gererpiecesjointes/views/cwGererPiecesJointes.view';
import { CWGererRecapGeneralView } from './cwGererRecapGeneral.view';
import { CWGererrecapitulatifsWorkflow } from '../models/cwGererrecapitulatifs.workflow';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWInfoComPopupView } from 'src/uc/common/evenements/gerer/gererrecapitulatifs/views/cwInfoComPopup.view';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n.js';
import { ResultControlesView } from 'src/uc/common/resultcontroles/views/resultControles.view.js';
import { UTILS } from 'src/utils/utils';


export class CWGererRecapitulatifsHeaderView extends Backbone.View {

  btnBar: CWButtonBarValidationView;
  context: { [key: string]: any };
  commentDialog: CWDialogPopupView;
  creator: CWCreerRecapView;
  infoCompDialog: CWDialogPopupView;
  model: any;
  pieceJointeModified: boolean;
  pjPopupView: any;
  selector: CWListerecapGeneralView;
  workflow: CWGererrecapitulatifsWorkflow;
  parent: CWGererRecapGeneralView;


  constructor(options?: { [key: string]: any }) {
    options = options || {};
    options.events = _.extend({
      "click .gererrecapitulatifs-dialog-btn": "_showDialog",
      "click .piece-jointe": "_managePieceJointe",
      "click .menuItem": "_menuItemListener",
      "click .comment": "_openInfoComPopup",
      "click .plus": "_openInfoComPopup"
    });
    super(options);

    if (options && options.parent) {
      this.parent = options.parent;
    }
    let selectorContexte: CWListerecapGeneralContextInterface = null;
    let firstLoad = true;

    this.template = TPLGererrecapitulatifsDetailHeader;
    this.workflow = options.workflow;
    this.context = options.context;
    this.btnBar = new CWButtonBarValidationView({ id: "gererrecapitulatifs_btnbar", isHideOnDisabled: false });
    this.model = new CWBaseModel({
      value: null,
      infocomp: null,
      comment: null
    });
    selectorContexte = this._getInitSelectorContext();
    if (this.context?.ctxEcran === "suivicollab" || this.context?.ctxEcran === "valevt") {
      firstLoad = false;
    }
    this.selector = new CWListerecapGeneralView({ "context": selectorContexte, "hideLinkGererRecap": true, "selectInSorting": false, "firstLoad": firstLoad });

    if (CWHABILITATION.canCreate("COL_RECAP.G") || CWHABILITATION.canCreate("RES_RECAP.G")) {
      this.creator = new CWCreerRecapView({ workflow: this.workflow, parent: this });
    }
    this.listenTo(this.workflow, "contextchanged", this.setContext);
    this.listenTo(this.selector.form.table.model, "change:value", this._listClick);
    this.listenTo(this.model, "modeModificationSelected", this._modeModification);
    this.listenTo(this.model, "recapSelected", this._recapChange);
    this.listenTo(this.model, "closePopupDelete", this._closePopupWhenDelete);
    this.listenTo(this.workflow, "etatReevaluated", () => { this._recapChange(true); });
    this.listenTo(this.model, "updateProfils", this._updateProfils);
    this.listenTo(this.workflow, "tableDataLoaded", this._checkHeaderButtons2);
    this.listenTo(this.btnBar.model, "btn:click", this._buttonAction);
    this.listenTo(this.workflow, "checkWkfRecap", this._checkWkfRecap);
    this.pieceJointeModified = false;
  }

  _listClick(): void {
    if (!CWSTR.isBlank(this.selector.form.table.model.get("value"))) {
      this.workflow._updateHeaderModel(this.selector.form.table.model.get("value").id, this.selector.form.table.model.get("value"));
    }
  }

  _updateList(): void {
    if (!CWSTR.isBlank(this.selector) && !CWSTR.isBlank(this.selector.context)) {
      this.selector.setContext(this.selector.context);
    }
  }

  _updateProfils(): void {
    this.btnBar.configureValidationsButtonFromModel(this.workflow.profilsRecapActivite);
  }

  //Customer 152336
  _modeModification(): void {
    this.model.get("value").get("statut").code = "M";
    this.model.modification = true;
  }

  _recapChange(etatReevaluated: boolean): void {
    const val = this.model.get("value");

    this.$el.find(".gererrecapitulatifs-header span.comment").hide();
    if (_.isEmpty(val)) {
      this.$el.find(".gererrecapitulatifs-header span.recapTitle").html(i18n.t('common:gererrecapitulatifs.headerDefaultTitle'));
      this.$el.find(".gererrecapitulatifs-header span.sep").html("");
      this.$el.find(".gererrecapitulatifs-header span.date").html("");
      this.$el.find(".gererrecapitulatifs-header span.status").html("");
      this.$el.find(".gererrecapitulatifs-header span.comment").hide();
      this.$el.find(".gererrecapitulatifs-header span.piece-jointe").hide();
      this.$el.find(".gererrecapitulatifs-header span.plus").hide();
      this._updateList();
      this.btnBar.model.trigger("hide:delete");
      this.btnBar.model.trigger("hide:valider");
      this.btnBar.model.trigger("hide:modifier");
      this.btnBar.model.trigger("hide:controler");
      this.btnBar.model.trigger("hide:soumettre");
    } else {
      this.$el.find(".gererrecapitulatifs-header span.recapTitle").html(val.get("libelle"));
      this.$el.find(".gererrecapitulatifs-header span.sep").html("&nbsp;-&nbsp;");
      this.$el.find(".gererrecapitulatifs-header span.date").html(CWTYPE.DATE.format(val.get("datedeb"), CWTYPE._getFormatByCode("DATE_L")) +
        "&nbsp;" + i18n.t('common:gererrecapitulatifs.dateTo') + "&nbsp;" +
        CWTYPE.DATE.format(val.get("datefin"), CWTYPE._getFormatByCode("DATE_L")) + "&nbsp;");
      const stat = val.get("statut")?.code;
      const style = this.workflow.styleFromStatus(stat);

      if (etatReevaluated) {
        this._updateList();
      }

      this.$el.find(".gererrecapitulatifs-header span.status").html("<span class='" + style + "'>(" + (val.get("statut")?.libelle ? val.get("statut").libelle : "") + ")</span>");
      this._checkHeaderButtons();
      this._configureValidationButton();
    }
    this._enableActions();
    const $dialog = this.$el.find(".gererrecapitulatifs-dialog");
    $dialog.hide();
    this.model.modification = false;
  }

  _closePopupWhenDelete(): void {
    if (this.workflow.attributes.parent) {
      this.workflow.attributes.parent.close();
    }
  }

  _menuItemListener(event: JQueryEventObject): void {
    const action = $(event.currentTarget).data("value");
    const code = $(event.currentTarget).data("code");
    this.btnBar.model.trigger("btn:click", action, code);
  }

  _buttonAction(buttonId: string, code: string): void {
    let profils = null;
    let profil = null;
    let hab = null;
    //let control: CWControlRecap = null;
    let successCallback: () => void = null;
    let model: any = null;
    if (!CWSTR.isBlank(this.workflow.headerModel.get("value"))) {
      switch (buttonId) {
        case "accepter":
        case "refuser":
          profils = this.workflow.profilsRecapActivite.get("profils");
          profil = _.find(profils, (prof: { [key: string]: any }) => { return prof.code === code; });
          this._openCommentPopup(buttonId, profil);
          break;
        case "modifier":
        case "soumettre":
          this._openCommentPopup(buttonId, profil);
          break;
        case "controler"://ne pas supprimer le code commenté - Jira GSCHR-8646
          //hab = this.workflow.habV.clone();
          //hab.set("foncCour", this.workflow.context.ctxUtilisateur === "Responsable" ? "RES_RECAP_CTRL" : "COL_RECAP_CTRL");
          //control = new CWControlRecap();
          //control.set("id", this.workflow.headerModel.get("value").get("code"));
          //control.setHabContext(hab);
          //successCallback = (): void => {
          //  switch (this.model.get("value").get("etat")) {
          //    case "Y":
          //    case "M":
          //    case "F":
          //      this.workflow._updateHeaderModel(this.model.get("value").get("code"), this.model.get("value"), true);
          //      break;
          //    default:
          //      break;
          //  }
          //  this.workflow.trigger("recapUpdated");
          //};
          //control.fetch({
          //  success: (model: any, xhr: any) => {
          //    successCallback();
          //    this._openControlePopUp("Information", xhr, control);
          //  },
          //  error: (model: any, xhr: any) => {
          //    if (xhr.status === 410) {
          //      this._openControlePopUp("Information", xhr, control, successCallback);
          //    }
          //  }
          //});
          break;
        case "delete":
          hab = this.workflow.habG.clone();
          hab.set("natGest", "S");
          model = this.workflow.headerModel.get("value");
          model.setHabContext(hab);
          model.set("id", model.get("code"));
          successCallback = (): void => {
            this.workflow.headerModel.set("value", null);
            model.setHabContext(this.workflow.habV);
            this.workflow.headerModel.set("infocomp", null);
            this.workflow.headerModel.trigger("recapSelected");
            this.workflow.trigger("recapUpdated");
            this.workflow.headerModel.trigger("closePopupDelete");
          };
          model.destroy({
            success: () => {
              successCallback();
            },
            error: (model: any, xhr: any) => {
              if (xhr.status === 410) {
                this._openControlePopUp("Traitement", xhr, model, successCallback);
              }
            },
            wait: true
          });
          break;
        default:
          break;
      }
    }
  }

  _openControlePopUp(utilisation: any, xhr: any, model: any, success?: any): void {
    const periods = {
      datedeb: this.workflow.headerModel.get("value").get("datedeb"),
      datefin: this.workflow.headerModel.get("value").get("datefin")
    };
    const context = {
      ctxUtilisateur: this.workflow.context.ctxUtilisateur,
      ctxUtilisation: utilisation,
      ctxModePopup: "Modale",
      ctxGestionCollab: this.workflow.context.ctxCollab,
      ctxPeriodeControles: periods,
      ctxTitrePopup: i18n.t('common:gererrecapitulatifs.controle_supprimer'),
      ctxIdentifiantRecap: this.workflow.headerModel.get("value").get("code")
    };
    const controlePopUp = new ResultControlesView({
      context: context,
      habilitations: this.workflow.getHabContext(),
      messagesModel: new CWBaseModel(xhr.responseJSON),
      originalModel: model,
      actionModel: "destroy"
    });

    controlePopUp.open({ success: success });
  }

  _enableActions(): void {
    const val = this.model.get("infocomp");

    this.btnBar.model.trigger("enable:delete");
    this.btnBar.model.trigger("enable:valider");
    this.btnBar.model.trigger("enable:modifier");
    this.parent.trigger("showhandle");
    //ne pas supprimer le code suivat : désactivé temporellement par le jira GSCHR-8646
    //if ((this.workflow.contenuRecapActivites.get("absences") !== null && this.workflow.contenuRecapActivites.get("absences").length > 0) ||
    // (this.workflow.contenuRecapActivites.get("activites") !== null && this.workflow.contenuRecapActivites.get("activites").length > 0)) {
    //this.btnBar.model.trigger("enable:controler");
    //}
    this.btnBar.model.trigger("enable:soumettre");
    if (!_.isEmpty(val)) {
      const existInfoComp = !_.isEmpty(val.get("infocomp"));

      if (!CWSTR.isBlank(val.get("piecesjointes")) && val.get("piecesjointes").length > 0 && this.context.ctxPieceJointe === true) {
        this.$el.find(".gererrecapitulatifs-header span.piece-jointe").attr("data-evenement", val.get("evenement"));
        this.$el.find(".gererrecapitulatifs-header span.piece-jointe").show();
      } else {
        this.$el.find(".gererrecapitulatifs-header span.piece-jointe").hide();
      }
      if (!this.workflow.isActiveWorkflowRecap() || CWSTR.isBlank(val.get("commentaire_infocomp"))) {
        this.$el.find(".gererrecapitulatifs-header span.comment").hide();
      } else {
        this.$el.find(".gererrecapitulatifs-header span.comment").show();
      }
      if (this.context.ctxPieceJointe !== true && !this.workflow.isActiveWorkflowRecap() && !existInfoComp) {
        this.$el.find(".gererrecapitulatifs-header span.plus").hide();
      } else {
        this.$el.find(".gererrecapitulatifs-header span.plus").show();
      }
    }
  }

  _disableActions(): void {
    if (!CWSTR.isBlank(this.btnBar)) {
      this.btnBar.model.trigger("disable:delete");
      this.btnBar.model.trigger("disable:valider");
      this.btnBar.model.trigger("disable:modifier");
      this.btnBar.model.trigger("disable:controler");
      this.btnBar.model.trigger("disable:soumettre");
    }
    this.parent.trigger("hidehandle");
    this.$el.find(".gererrecapitulatifs-header span.comment").hide();
    this.$el.find(".gererrecapitulatifs-header span.piece-jointe").hide();
    this.$el.find(".gererrecapitulatifs-header span.plus").hide();
  }

  _checkInfoCompEditable(): boolean {
    const val = this.model.get("value");

    if (CWSTR.isBlank(val)) {
      return false;
    }
    switch (val.get("etat")) {
      case "Y":
      case "E":
      case "M":
      case "F":
        return true;
      case "D":
      case "Z":
      case "C":
      case "G":
        return this.context.ctxUtilisateur === "Responsable" && this.workflow.etatFinalModel.get("valideur");
      case "A":
        return this.context.ctxUtilisateur === "Responsable" && this.workflow.etatFinalModel.get("valideurfinal");
      default:
        return false;
    }
  }

  _checkHeaderButtons(): void {
    if (this._checkButtonModifier()) {
      this.btnBar.model.trigger("show:modifier");
    } else {
      this.btnBar.model.trigger("hide:modifier");
    }
    if (this._checkButtonSoumettre()) {
      this.btnBar.model.trigger("show:soumettre");
    } else {
      this.btnBar.model.trigger("hide:soumettre");
    }
    if (this._checkButtonValider()) {
      this.btnBar.model.trigger("show:valider");
    } else {
      this.btnBar.model.trigger("hide:valider");
    }
    this.btnBar.model.trigger("hide:controler");
    this.btnBar.model.trigger("hide:delete");
  }

  _checkButtonModifier(): boolean {
    const val = this.model.get("value");

    if (!((this.context.ctxUtilisateur === "Responsable" && CWHABILITATION.canUpdate("RES_RECAP.G")) || (this.context.ctxUtilisateur === "Collaborateur" && CWHABILITATION.canUpdate("COL_RECAP.G")))) {
      return false;
    }
    if (CWSTR.isBlank(val)) {
      return false;
    }
    switch (val.get("etat")) {
      case "D":
      case "Z":
      case "C":
      case "G":
        return this.context.ctxUtilisateur === "Collaborateur" || !this.workflow.etatFinalModel.get("valideur");
      case "A":
        return this.context.ctxUtilisateur === "Collaborateur" || !this.workflow.etatFinalModel.get("valideurfinal");
      default:
        return (this.model.modification === true);
    }
  }

  _checkButtonSoumettre(): boolean {
    const val = this.model.get("value");

    if (!(this.context.ctxUtilisateur === "Responsable" && CWHABILITATION.canView("RES_RECAP.G") || this.context.ctxUtilisateur === "Collaborateur" && CWHABILITATION.canView("COL_RECAP.G"))) {
      return false;
    }
    if (!this.workflow.evtRecapActSoumisWkf.active) {
      return false;
    }
    if (CWSTR.isBlank(val)) {
      return false;
    }
    switch (val.get("etat")) {
      case "E":
      case "Y":
      case "M":
      case "F":
        return true;
      default:
        return false;
    }
  }

  _checkButtonValider(): boolean {
    if (this.context.ctxUtilisateur === "Responsable" && !_.isEmpty(this.workflow.etatFinalModel) && this.workflow.etatFinalModel.get("valideur")) {
      const val = this.model.get("value");

      if (!(CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation.recapitulatif) && this.workflow.evtRecapActSoumisWkf.active)) {
        return false;
      }
      if (CWSTR.isBlank(val)) {
        return false;
      }
      switch (val.get("etat")) {
        case "D":
        case "Z":
        case "C":
        case "G":
          return true;
        default:
          return false;
      }
    }
    return false;
  }

  _checkHeaderButtons2(): void {
    if (this._checkButtonSupprimer()) {
      this.btnBar.model.trigger("show:delete");
    } else {
      this.btnBar.model.trigger("hide:delete");
    }
    if (this._checkButtonControler()) {
      //ne pas supprimer le code commenté --> ajouté par le jira GSCHR-8646
      //this.btnBar.model.trigger("show:controler"); 
      if (this.workflow.contenuRecapActivites.get("absences").length > 0 || this.workflow.contenuRecapActivites.get("activites").length > 0) {
        // this.btnBar.model.trigger("enable:controler");
      } else {
        this.btnBar.model.trigger("disable:controler");
      }
    } else {
      this.btnBar.model.trigger("hide:controler");
    }
    if (this.model.get("value") && (this.model.get("value").get("etat") === "E" || this.model.get("value").get("etat") === "Y" || this.model.get("value").get("etat") === "M" || this.model.get("value").get("etat") === "F")) {
      this.btnBar.model.trigger("enable:soumettre");
    } else {
      this.btnBar.model.trigger("disable:soumettre");
    }
  }

  _checkButtonSupprimer(): boolean {
    const val = this.model.get("value");

    if (!((this.context.ctxUtilisateur === "Responsable" && CWHABILITATION.canDelete("RES_RECAP.G")) || (this.context.ctxUtilisateur === "Collaborateur" && CWHABILITATION.canDelete("COL_RECAP.G")))) {
      return false;
    }
    if (CWSTR.isBlank(val)) {
      return false;
    }
    switch (val.get("etat")) {
      case "E":
        if (this.workflow.recapHasActivity()) {
          return false;
        }
        return true;
      case "A":
        return this.context.ctxUtilisateur === "Responsable" && this.workflow.etatFinalModel.get("valideurfinal");
      default:
        return false;
    }
  }

  _checkButtonControler(): boolean {
    const val = this.model.get("value");

    if (!(this.context.ctxUtilisateur === "Responsable" && CWHABILITATION.canView("RES_RECAP_CTRL") || this.context.ctxUtilisateur === "Collaborateur" && CWHABILITATION.canView("COL_RECAP_CTRL"))) {
      return false;
    }
    if (CWSTR.isBlank(val)) {
      return false;
    }
    switch (val.get("etat")) {
      case "E":
      case "Y":
      case "M":
      case "F":
        return true;
      case "D":
      case "Z":
      case "C":
      case "G":
        return this.context.ctxUtilisateur === "Responsable" && this.workflow.etatFinalModel.get("valideur");
      default:
        return false;
    }
  }

  _managePieceJointe(): boolean {
    const model = this.model.get("value");
    const infocomp = this.model.get("infocomp");

    if (!CWSTR.isBlank(infocomp) && infocomp.get("piecesjointes").length === 1) {
      const code = infocomp.get("piecesjointes")[0].code;

      window.open(Configuration.restRoot + "/rest/fichier/download?id=" + encodeURIComponent(code) + "&x_token_key=" + encodeURIComponent(Configuration.x_token_key), "_blank");
    }
    if (!CWSTR.isBlank(infocomp) && infocomp.get("piecesjointes").length > 1) {
      const description = "<span class='phx-bold-input-style'>" + model.get("libelle") + "</span>&nbsp;" + CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE_L")) +
        "&nbsp;" + i18n.t('common:gererrecapitulatifs.dateTo') + "&nbsp;" +
        CWTYPE.DATE.format(model.get("datefin"), CWTYPE._getFormatByCode("DATE_L")) +
        "&nbsp;(" + model.get("statut").libelle + ")";

      if (this.pjPopupView) {
        this.pjPopupView.internalView.setPJ(infocomp.get("piecesjointes"), description);
      } else {
        const maxPieces = !CWSTR.isBlank(this.workflow.maxPiecesAct) ? this.workflow.maxPiecesAct.get("maxi") : 0;
        const hab = {
          HabilitationAcces: this.workflow.habV.get("foncCour"),
          HabilitationGestion: this.workflow.habG.get("foncCour")
        };
        const context = {
          ctxRefEvenement: model.evenement,
          ctxHabilitation: hab,
          ctxEcran: this.context.ctxEcran,
          ctxGestionCollab: this.context.ctxCollab,
          ctxModeInitialisation: (this.workflow.context.ctxFixeRecap || !this._checkInfoCompEditable()) ? "Consultation" : "Ajout",
          ctxActionsPossibles: (this.workflow.context.ctxFixeRecap || !this._checkInfoCompEditable()) ? [] : ["Creer", "Supprimer"]
        };

        this.pjPopupView = this._initPjPopupView(context, infocomp.get("piecesjointes"), description, maxPieces);
        this.pjPopupView.model.on("piecejointeChanged", () => {
          this.pieceJointeModified = true;
        });
        this.pjPopupView.open(() => {
          this.pjPopupView = null;
          if (this.pieceJointeModified) {
            this.model.get("infocomp").fetch({
              success: () => {
                this._updateList();
                this._enableActions();
              }
            });
          }
        });
      }
    }
    return false;
  }

  _initPjPopupView(context: { [key: string]: any }, list: any, description: string, maxpieces: number): CWDialogPopupView {
    const dialog = new CWDialogPopupView({
      view: CWGererPiecesJointesView,
      viewData: {
        context: context,
        coll: list,
        description: description,
        modal: true,
        maxPieces: maxpieces,
        appendTo: (this.context.ctxEcran === "suivicollab" && this.parent && this.parent.$el) ? this.parent.$el : this.workflow.$appendTo
      }
    });

    this.pieceJointeModified = false;
    dialog.setHeight("auto");
    dialog.setWidth(600);
    return dialog;
  }

  _openInfoComPopup(): void {
    let editable = false;
    let dialogModel: Backbone.Model = null;

    if (CWSTR.isBlank(this.model.get("value"))) {
      return;
    }
    editable = this._checkInfoCompEditable();
    if (this.infoCompDialog === undefined) {
      this.infoCompDialog = new CWDialogPopupView({
        popupType: CWDialogPopupType.AVEC_SAISIE,
        view: CWInfoComPopupView,
        viewData: {
          id: this.model.get("value").get("code"),
          workflow: this.workflow,
          etat: this.model.get("value").get("statut").libelle,
          editable: editable,
          appendTo: (this.context.ctxEcran === "suivicollab") ? null : this.workflow.$appendTo,
          modal: true
        }
      });
    } else {
      this.infoCompDialog.viewData = {
        id: this.model.get("value").get("code"),
        workflow: this.workflow,
        etat: this.model.get("value").get("statut").libelle,
        editable: editable,
        appendTo: (this.context.ctxEcran === "suivicollab") ? null : this.workflow.$appendTo,
        modal: true
      };
    }
    this.infoCompDialog.setHeight(430);
    this.infoCompDialog.setWidth(520);
    dialogModel = this.infoCompDialog.model;
    dialogModel.trigger("dlg:open", () => {

      if (dialogModel.get("arePJChanged") === true) {
        this.model.get("infocomp").fetch({
          success: () => {
            this._updateList();
            this._enableActions();
          }
        });
      } else if (dialogModel.get("isCommentChanged") === true) {
        this._enableActions();
      }
    });
    //changer si l'on est dans "suivicollab" le mode modal parce qu'il aura deux fenetres modales
    if (this.context.ctxEcran === "suivicollab" && this.infoCompDialog?.$el?.parent()) {
      const posOverlay = $("body").find(".ui-widget-overlay.ui-front");

      if (posOverlay && posOverlay.length === 2) {
        const zIndexFromSuivi = parseInt((!CWSTR.isBlank($(posOverlay[0]).css("z-index")) ? $(posOverlay[0]).css("z-index") : "100"), 10);
        const zIndexFromInfoComp = parseInt((!CWSTR.isBlank($(posOverlay[0]).css("z-index")) ? ((parseInt($(posOverlay[1]).css("z-index"), 10) < 1000) ? $(posOverlay[1]).css("z-index") : String(parseInt($(posOverlay[1]).css("z-index"), 10) - 100)) : "100"), 10);

        if (zIndexFromInfoComp <= zIndexFromSuivi) {
          $(posOverlay[1]).css("z-index", String(zIndexFromSuivi + 1));
          this.infoCompDialog.$el.parent().css("z-index", String(zIndexFromSuivi + 2));
        }
      }
    }
    this.infoCompDialog._setTitle(i18n.t('common:gererrecapitulatifs.titleInfoComp'));
  }

  _openCommentPopup(action: any, profil: any): void {
    const model = this.model.get("value");
    let comment = "";
    let dialogModel: Backbone.Model = null;

    if (!CWSTR.isBlank(model)) {
      comment = !CWSTR.isBlank(model.get("commentaire")) ? model.get("commentaire") : "";
    }
    if (CWSTR.isBlank(this.commentDialog)) {
      this.commentDialog = new CWDialogPopupView({
        view: CWCommentPopupView,
        popupType: CWDialogPopupType.AVEC_SAISIE,
        viewData: {
          commentaire: comment,
          action: action,
          profil: profil,
          workflow: this.workflow,
          modal: true,
          appendTo: (this.context.ctxEcran === "suivicollab" && this.parent && this.parent.$el) ? this.parent.$el : this.workflow.$appendTo
        }
      });
    } else {
      this.commentDialog.viewData = {
        commentaire: comment,
        action: action,
        profil: profil,
        workflow: this.workflow,
        modal: true,
        appendTo: (this.context.ctxEcran === "suivicollab" && this.parent && this.parent.$el) ? this.parent.$el : this.workflow.$appendTo
      };
    }
    this.commentDialog.setHeight(430);
    this.commentDialog.setWidth(500);
    dialogModel = this.commentDialog.model;
    dialogModel.trigger("dlg:open", () => {
      if (dialogModel.get("reeval") === true) {
        this.workflow._updateHeaderModel(this.model.get("value").get("code"), this.model.get("value"), true);
      }
    });
    this.commentDialog._setTitle(i18n.t('common:msgs_confirm_title'));
  }

  _configureValidationButton(): void {
    const profils = this.workflow.profilsRecapActivite.get("profils");
    const ul = $(".gererrecapitulatifs-header", this.el).find("ul.validerListMenu");
    const accepter = $("<li>");
    const refuser = $("<li>");
    const item = $("<li>");
    let a = null;

    ul.empty();
    if (profils && profils.length === 1 && profils[0] && ul) {
      let a = $("<a class='menuItem' data-value='accepter'>" + i18n.t('common:gererrecapitulatifs.btnAccepter') + "</a>");

      a.attr("data-code", profils[0].code);
      accepter.append(a);
      ul.append(accepter);
      a = $("<a class='menuItem' data-value='refuser'>" + i18n.t('common:gererrecapitulatifs.btnRefuser') + "</a>");
      a.attr("data-code", profils[0].code);
      refuser.append(a);
      ul.append(refuser);
    } else if (profils && profils.length > 1 && ul) {
      accepter.append("<span class='ui-corner-all'>" + i18n.t('common:gererrecapitulatifs.btnAccepter') + "</span>");
      ul.append(accepter);
      _.each(profils, (obj: { [key: string]: any }) => {
        a = $("<a class='menuItem' data-value='accepter'>- " + obj.libelle + "</a>");
        a.attr("data-code", obj.code);
        item.append(a);
        ul.append(item);
      });
      refuser.append("<span class='ui-corner-all'>" + i18n.t('common:gererrecapitulatifs.btnRefuser') + "</span>");
      ul.append(refuser);
      _.each(profils, (obj: { [key: string]: any }) => {
        a = $("<a class='menuItem' data-value='refuser'>- " + obj.libelle + "</a>");
        a.attr("data-code", obj.code);
        item.append(a);
        ul.append(item);
      }, this);
    } else if (!profils || profils.length === 0) {
      this.btnBar.model.trigger("hide:valider");
    }
    $(".gererrecapitulatifs-header", this.el).find("button.valider").button({ icons: { secondary: "ui-icon-triangle-1-s" }, text: true }).click(() => {
      const menu = $(".gererrecapitulatifs-header", this.el).find("ul.validerListMenu").show().position({
        my: "left top",
        at: "left bottom",
        of: this
      });

      $(document).one("click", () => {
        menu.hide();
      });
      return false;
    });
    $(".gererrecapitulatifs-header", this.el).find("ul.validerListMenu").hide().menu();
  }

  _showDialog(): void {
    if (!this.context.ctxFixeRecap) {
      const $dialog = this.$el.find(".gererrecapitulatifs-dialog");

      if (!$dialog.is(":visible")) {
        this._disableActions();
        this._recapChange(true);
        $dialog.show();
        $(document).one("mousedown", (event) => {
          const element = this.$el.find(".gererrecapitulatifs-dialog").find(event.target as any);

          if (element.length === 0 && !$(event.target).hasClass("gererrecapitulatifs-dialog-btn")) {
            this._showDialog();
          }
        });
      } else {
        this._enableActions();
        $dialog.hide();
      }
    }
  }

  render(): CWGererRecapitulatifsHeaderView {
    const json = { "i18n": i18n };

    this.$el.append(this.template(json));
    this.$el.find(".gererrecapitulatifs-header span.buttons").html(this.btnBar.render().el);
    this.btnBar.addButton("modifier", i18n.t('common:gererrecapitulatifs.btnModifier'));
    this.btnBar.addButton("controler", i18n.t('common:gererrecapitulatifs.btnControler'));
    this.btnBar.addButton("soumettre", i18n.t('common:gererrecapitulatifs.btnSoumettre'));
    this.btnBar.model.trigger("enable:modifier");
    this.btnBar.model.trigger("hide:save");
    this.btnBar.model.trigger("hide:revert");
    this.btnBar.model.trigger("hide:new");
    this.btnBar.model.trigger("hide:copy");
    this.btnBar.model.trigger("hide:delete");
    this.btnBar.model.trigger("hide:valider");
    this.btnBar.model.trigger("hide:modifier");
    this.btnBar.model.trigger("hide:controler");
    this.btnBar.model.trigger("hide:soumettre");
    this.$el.find("div.listerRecaps").html(this.selector.render().el);
    if (!CWHABILITATION.canCreate("COL_RECAP.G") && !CWHABILITATION.canCreate("RES_RECAP.G")) {
      this.$el.find("div.creerRecapsTitle").hide();
    } else {
      this.$el.find("div.creerRecaps").html(this.creator.render().el);
    }
    if (this.context.ctxEcran === "suivicollab" || this.context.ctxEcran === "valevt") {
      this.model.trigger("recapSelected");
    }
    if (this.context.ctxEcran === "mactivites") {
      this._recapActImbrique();
    }
    this.$el.find(".gererrecapitulatifs-header span.piece-jointe").append(UTILS.getSVGIcon('trombone', 'button cw-icon-rot'));
    return this;
  }

  setContext(context: { [key: string]: any }): void {
    let hab: { [key: string]: any } = null;
    let selectorContext: CWListerecapGeneralContextInterface = null;

    this.context = context;
    hab = {
      HabilitationAcces: this.context.ctxUtilisateur === 'Responsable' ? "RES_RECAP.V" : "COL_RECAP.V",
      HabilitationGestion: this.context.ctxUtilisateur === 'Responsable' ? "RES_RECAP.G" : "COL_RECAP.G",
      HabilitationValidation: "RES_VAL_RECAP.G"
    }
    selectorContext = {
      ctxEcran: this.context.ctxEcran,
      ctxUtilisateur: this.context.ctxUtilisateur,
      ctxHabilitation: hab,
      ctxCollab: this.context.ctxCollab,
      ctxGestionCollective: false,
      ctxValidation: false,
      ctxValideur: false,
    } as CWListerecapGeneralContextInterface;
    selectorContext.ctxFiltreApplique = ["E", "F", "M", "Y", "C", "G", "Z", "A", "D", "H", "I"] as ctxFiltreApplique[]
    selectorContext.ctxTriDate = "Descendant" as ctxTriDate
    this.selector.setContext(selectorContext);
    if (!CWSTR.isBlank(this.workflow.context.ctxPeriodeRecap) && !CWSTR.isBlank(this.workflow.context.ctxTypeRecap) && this.workflow.recapColl.models.length === 1) {
      this.workflow._updateHeaderModel(this.workflow.recapColl.models[0].get("code"), this.workflow.recapColl.models[0]);
    }
    if (this.context.ctxFixeRecap) {
      this.$el.find(".gererrecapitulatifs-dialog-btn").hide();
    } else if (this.model.get("value") === null) {
      this._showDialog();
    }
    this.workflow.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this.workflow.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application
    }
  }

  _getInitSelectorContext(): CWListerecapGeneralContextInterface {
    const hab = {
      HabilitationAcces: this.context.ctxUtilisateur === 'Responsable' ? "RES_RECAP.V" : "COL_RECAP.V",
      HabilitationGestion: this.context.ctxUtilisateur === 'Responsable' ? "RES_RECAP.G" : "COL_RECAP.G",
      HabilitationValidation: "RES_VAL_RECAP.G"
    }
    const selectorContext = {
      ctxEcran: this.context.ctxEcran,
      ctxUtilisateur: this.context.ctxUtilisateur,
      ctxHabilitation: hab,
      ctxCollab: this.context.ctxCollab,
      ctxGestionCollective: false,
      ctxValidation: (this.context?.ctxEcran === "valevt" ? true : false),
      ctxValideur: false,
    } as CWListerecapGeneralContextInterface;

    selectorContext.ctxFiltreApplique = ["E", "F", "M", "Y", "C", "G", "Z", "A", "D", "H", "I"] as ctxFiltreApplique[]
    selectorContext.ctxTriDate = "Descendant" as ctxTriDate
    return selectorContext
  }

  _recapActImbrique(): void {
    const val = this.model.get("value");

    this.$el.find(".gererrecapitulatifs-header span.comment").hide();
    if (_.isEmpty(val)) {
      this.$el.find(".gererrecapitulatifs-header span.recapTitle").html(i18n.t('common:gererrecapitulatifs.headerDefaultTitle'));
      this.$el.find(".gererrecapitulatifs-header span.sep").html("");
      this.$el.find(".gererrecapitulatifs-header span.date").html("");
      this.$el.find(".gererrecapitulatifs-header span.status").html("");
      this.$el.find(".gererrecapitulatifs-header span.comment").hide();
      this.$el.find(".gererrecapitulatifs-header span.piece-jointe").hide();
      this.$el.find(".gererrecapitulatifs-header span.plus").hide();
      this._updateList();
      this.btnBar.model.trigger("hide:delete");
      this.btnBar.model.trigger("hide:valider");
      this.btnBar.model.trigger("hide:modifier");
      this.btnBar.model.trigger("hide:controler");
      this.btnBar.model.trigger("hide:soumettre");
    } else {
      const stat = val.get("statut")?.code;
      const style = this.workflow.styleFromStatus(stat);

      this.$el.find(".gererrecapitulatifs-header span.recapTitle").html(val.get("libelle"));
      this.$el.find(".gererrecapitulatifs-header span.sep").html("&nbsp;-&nbsp;");
      this.$el.find(".gererrecapitulatifs-header span.date").html(CWTYPE.DATE.format(val.get("datedeb"), CWTYPE._getFormatByCode("DATE_L")) +
        "&nbsp;" + i18n.t('common:gererrecapitulatifs.dateTo') + "&nbsp;" + CWTYPE.DATE.format(val.get("datefin"), CWTYPE._getFormatByCode("DATE_L")) + "&nbsp;");
      this.$el.find(".gererrecapitulatifs-header span.status").html("<span class='" + style + "'>(" + (val.get("statut")?.libelle ? val.get("statut").libelle : "") + ")</span>");
      this._checkHeaderButtons();
      this._configureValidationButton();
    }
    this._enableActions();
  }

  _checkWkfRecap(): void {
    if (!this.workflow.isActiveWorkflowRecap() && this.btnBar.model) {
      this.btnBar.model.trigger("hide:valider");
    }
    if (!this.workflow.isActiveWorkflowRecap()) {
      this.$el.find(".gererrecapitulatifs-header span.comment").hide();
    }
  }

  remove(): any {
    if (this.selector) {
      this.selector.remove();
      this.selector = null;
    }
    if (this.btnBar) {
      this.btnBar.remove();
      this.btnBar = null;
    }
    this.$el.empty();
    return super.remove();
  }
}
