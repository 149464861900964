import _ from 'underscore';
import TPLGererregularisationsGererRegularisationsHor from '../cwGererRegularisationsHor.tpl.html';
import TPLGererregularisationsGererRegularisationsSaisieMasse from '../cwGererregularisations-saisiemass-mode.tpl.html';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWButtonBarValidationView } from 'core/controls/cwButtonBarValidation.view';
import { CWCaracteristiquesForm } from 'core/components/caracteristiques/cwCaracteristiquesForm.view';
import { CWCarPersonMotifRegulModel } from '../models/cwCarPersonMotif.model';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWDIVERS } from 'utils/cwDivers';
import { CWEtiquette, validEtiquetteTypes } from 'core/components/etiquette/cwEtiquette.view';
import { CWFORMS } from 'utils/cwForms';
import { CWGererRegularisationsGestion } from '../models/cwGererRegularisationsGestion';
import { CWGererRegularisationsModel } from '../models/cwGererRegularisations.model';
import { CWGererRegularisationsWorkflow } from '../models/cwGererregularisations.workflow';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWListeMotifRegulColl } from '../models/cwListeMotif.collection';
import { CWListeMotifRegulModel } from '../models/cwListeMotif.model';
import { CWMRegulRouter } from 'mon_compte/gerer/mregul/cwMRegul.router';
import { CWMSGS } from 'utils/cwMsgs';
import { CWPanneauDeroulant } from 'core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWSelectCollabPopulationView } from 'common/referentiel/referentielcollab/views/cwSelectCollabPopulation.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { CWWkflhistoView } from 'common/wkfhisto/views/cwWkfhisto.view';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { Model, ViewOptions } from 'Backbone';
import { objs } from 'src/objectsRepository';
import { onError } from 'src/Interceptors/onError.js';
import { UTILS } from 'utils/utils.js';


/**
 * Render gerer regularisation view
 */
export class CWGererRegularisationView extends CWCaracteristiquesForm {
  private isContextInitialized: boolean;
  private collabBackup: any;
  private listeMotifs: CWListeMotifRegulColl<CWListeMotifRegulModel>;
  context: { [key: string]: any };
  private carPers: CWBaseModel;
  public workflow: CWGererRegularisationsWorkflow;
  public btnBar: CWButtonBarValidationView;
  private paramIdCollab: string;
  private parent: CWDialogPopupView | CWMRegulRouter;
  private orientation: number;
  private formatValeurMask: string;
  public typeFormatByClass: { [key: string]: any };
  public dateFormat: string;
  public _regulParPeriode: boolean;
  public hasBeenFocused: boolean;
  public flagDataReady: boolean;
  private comboMotifColl: CWListeMotifRegulColl;
  private comboMotif: CWComboBoxView2;
  private origineContext: { [key: string]: any } | string;
  private comboOrigine: CWComboBoxView2;
  private referentielCollabPopu: CWSelectCollabPopulationView;
  private referentielReadOnly: boolean;
  public isEditable: boolean;
  public stateIndication: boolean;
  private composantWorkflow: CWWkflhistoView;
  private contextComponentWkf: { [key: string]: any };
  private original: CWGererRegularisationsModel;
  public diver: string;
  etiquetteHisto1: CWEtiquette;
  private histoEtiquettePopup: CWPanneauDeroulant;
  defaultTypeMaskByClass: { [key: string]: any };
  panneauIsOpen: boolean;
  isDislayedSubtitle: boolean;
  errorPetAntSaveDelete: boolean; //flag pour savoir si la petition dernier était en erreur ou pas
  desactiveHabOuEtat: boolean;
  private disabled: boolean;
  private infoErrorModify: { [key: string]: any };
  private notCheckCombos: boolean;
  private outsideClickListener: (arg1: JQueryEventObject) => any;

  constructor(options?: ViewOptions<Model> | any) {
    options = options || {};
    options.events = _.extend({
      "change :input": "_manageView",
      "keyup :input:not([readonly])": "_notifyEdition",
      "keyup textarea:not([readonly])": "_notifyEdition",
      "click .menuItem": "menuItemListener",
    }, CWTYPE.DATE.events(".gererregularisations-dates"), CWTYPE.CUSTOM.events(".value"), CWTYPE.CUSTOM.events(".valInfo"), options.events);
    options.tagName = "fieldset";
    options.className = "cw-fieldset cwGererRegularisation"
    super(options);
    const localCallback = (): void => {
      if (options.parent) {
        this.parent = options.parent;
        this.workflow.parent = this.parent;
      }
      if (options.orientation) { // 0: Horizontal ; 1: Vertical
        this.orientation = options.orientation;
      }
      if (this.context && this.context.ctxSaisieMasse) {
        // Saisie masse screen
        this.template = TPLGererregularisationsGererRegularisationsSaisieMasse;
      } else {
        // default Orientation
        this.template = TPLGererregularisationsGererRegularisationsHor;
      }
      // When the value is changed, we have to map the new value to the form
      this.model.on("change:value", this._mapToForm, this);
      this.model.on("change:mode", this._manageMode, this);
      this.model.on("form:edited", this._displaySubtitle, this);
      this.formatValeurMask = "";
      this.typeFormatByClass = {};
      this.typeFormatByClass["valeur"] = "";
      this.typeMaskByClass = {};
      if (this.context.ctxSaisieMasse) {
        this.dateFormat = "DATE";
      } else {
        this.dateFormat = "DATE_L";
      }
      this.typeMaskByClass = {};
      this.typeMaskByClass["datedeb"] = this.dateFormat;
      this.typeMaskByClass["datefin"] = this.dateFormat;
      this.defaultTypeMaskByClass = this.typeMaskByClass;
      this._regulParPeriode = true;
      this.hasBeenFocused = false;
      // we need to maintain all values of combo also in : this.comboMotifColl (used in getMotif)
      // the flagDataReady  : is to load data only once.
      this.flagDataReady = false;
      this.comboMotifColl = new CWListeMotifRegulColl();
      this.comboMotifColl.setHabContext(this.model.getHabContext());
      if (CWHABILITATION.canView(this.comboMotifColl.habContext.get("foncCour"))) {
        this.comboMotifColl.fetch();
      }
      this.panneauIsOpen = false;
      this.isDislayedSubtitle = false;
      this._initCombos();
      if (this.context.ctxEcran !== "saisiemass") {
        this.listenTo(this, "manageUnselected", this.manageModeUnselected);
        this.listenTo(this.workflow, "manageUnselected", this.manageModeUnselected);
      }
      this.errorPetAntSaveDelete = false;
    };

    this.isContextInitialized = false;
    this.id = null;
    this.collabBackup = null;
    this.desactiveHabOuEtat = false;
    this.infoErrorModify = {};
    this.notCheckCombos = true;
    this.disabled = false;
    this.listeMotifs = new CWListeMotifRegulColl();
    this.infoCompName = ".gererregularisations-infocomp";
    this.infoCompAttributeName = "carpers";
    this.context = options.context;
    if (this.context && this.context.ctxSaisieMasse === true) {
      this.model = new CWBaseModel({
        value: new CWGererRegularisationsModel(null, { "matricule": true, infoCompAttributeName: this.infoCompAttributeName }),
        mode: "C"
      });
      this.saisieMasse = true;
    } else {
      this.model = new CWBaseModel({
        value: new CWGererRegularisationsModel(null, { infoCompAttributeName: this.infoCompAttributeName }),
        mode: "C"
      });
    }
    this.carPers = new CWBaseModel({ value: new CWCarPersonMotifRegulModel() });
    if (options.context) {
      let lHabContext: CWHabilitationContext = null;
      let foncC = null;

      if (!CWSTR.isBlank(this.context.ctxEcran)) {
        if (!CWSTR.isBlank(this.context.ctxHabilitation.HabilitationAcces)) {
          foncC = this.context.ctxHabilitation.HabilitationAcces;
        } else if (!CWSTR.isBlank(this.context.ctxHabilitation.HabilitationGestion)) {
          foncC = this.context.ctxHabilitation.HabilitationGestion;
        }
        lHabContext = new CWHabilitationContext({
          onglet: this.context.ctxEcran,
          foncCour: foncC
        });
      }
      this.model.setHabContext(lHabContext);
    }
    this.workflow = new CWGererRegularisationsWorkflow({}, { "context": this.context, "wkfRecupere": options.wkfRecupere, "wkfActiv": options.wkfActiv });
    this.listenTo(this.workflow, "initWorkflowActif", this._manageCommentaire);
    this.btnBar = new CWButtonBarValidationView({
      id: "regulcollab_btnbar",
      isHideOnDisabled: this.context.ctxEcran === "saisiemass" ? false : true
    });
    this.btnBar.deleteButton('copy');
    this.workflow.btnBarModel = this.btnBar.model;
    this.workflow.gererRegularisations = this;
    this.workflow.formModel = this.model;
    this.paramIdCollab = CWDIVERS.get("idCollab");
    if (this.context.ctxEcran === "mregul") {
      this.workflow.setUp((): void => {
        localCallback();
      });
    } else {
      this.workflow.setUp();
      localCallback();
    }
  }

  getState(): string {
    return this.btnBar.model.get("mode");
  }

  getReferentielCollabPopu(): CWSelectCollabPopulationView {
    return this.referentielCollabPopu;
  }

  _initCombos(): void {
    let lsUser = "";

    this.comboMotif = new CWComboBoxView2({
      ws: new CWListeMotifRegulColl(),
      autocomplete: true,
      name: "motif.code",
      optionsRender: (item: { [key: string]: any }): string => {
        return (!item || CWSTR.isBlank(item.code)) ? "" : item.libelle + " (" + item.code + ")";
      },
      required: true,
      habContext: this.model.getHabContext()
    });
    if (this.context.ctxPourPlanningMedical === true) {
      this.comboMotif.coll.params = _.extend({}, this.comboMotif.coll.params, { "medical": true });
      this.comboMotif.clearCache();
      this.comboMotif.clearColl();
    }
    // combo origine
    if (this.context && this.context.ctxUtilisateur) {
      lsUser = (this.context.ctxUtilisateur === "Responsable") ? "respo" : "collab";
    } else {
      lsUser = (objs.appRt.workflow.get("zone") === "resp") ? "respo" : "collab";
    }
    this.origineContext = lsUser;
    this.comboOrigine = new CWComboBoxView2({
      ws: new CWCombosColl({
        urlMine: Configuration.restRoot + "/rest/ls/origine/" + this.origineContext
      }),
      autocomplete: true,
      name: "origine.code",
      habContext: this.model.getHabContext(),
      optionsRender: (item: { [key: string]: any }): string => {
        return (!item || CWSTR.isBlank(item.code)) ? "" : item.libelle;
      }
    });
    if (this.context && this.context.ctxUtilisateur === "Responsable" && (this.context.ctxSaisieMasse === true || this.context.ctxGestionCollab)) {
      this.referentielCollabPopu = new CWSelectCollabPopulationView({
        context: this.context,
        diversIdCollab: this.paramIdCollab,
        readonly: this.referentielReadOnly,
        foncCour: this.context.ctxHabilitation.HabilitationGestion,
        appendTo: this.workflow.$appendTo,
        height: 650,
        width: 850
      });
      this.$el.find(".cmbCollaborateurs").html(this.referentielCollabPopu.render().el);
      this.listenTo(this.referentielCollabPopu.model, "referentielChanged", this._referentialPopulationChanged);
    }
    this.listenTo(this.comboMotif, "comboEdited", this._formEdited);
    this.listenTo(this.comboOrigine, "comboEdited", this._formEdited);
  }

  _referentialPopulationChanged(): void {
    this.model.get("value").set("matricule", this.referentielCollabPopu.model.get('matric'), { silent: true });
    this.context.ctxGestionCollab.matricule = this.referentielCollabPopu.model.get('matric');
    this._formEdited();
  }

  _paintCollaborateur(): void {
    this.$el.find('.cmbCollaborateurs input').val(this.referentielCollabPopu.referentiel.itemRenderer(this.collabBackup));
  }

  _panelStateIsReadonly(): boolean {
    const model = this._getModel();
    let isReadOnly = false;

    if (this.context.ctxGestionCollective && !CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      isReadOnly = true;
    } else if (this.context && this.context.ctxHabilitation && this.context.ctxHabilitation.HabilitationGestion) {
      const canUpdate = CWHABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion);
      isReadOnly = !canUpdate && !model.isNew();
    }
    return isReadOnly;
  }

  _formEdited(): void {
    this.model.trigger("form:edited");
  }

  setContext(context: { [key: string]: any }, fromMenu?: boolean): void {
    this.isContextInitialized = true;
    if (CWSTR.isBlank(this.model.getHabContext())) {
      this.model.updateHabContext({
        onglet: context.ctxEcran,
        foncCour: this.context.ctxHabilitation.HabilitationAcces,
        natGest: ""
      });
    }
    this.context = context;
    this._manageModeRepresentation();
    if (!fromMenu) {
      this._manageUtilisateur();
    }
    this._manageModeInitialisation((): void => {
      this._manageActionsPossibles();
      this._manageHabilitation();
      if (this.context.ctxModeInitialisation === "") {
        this._disableForm();
      }
      this._initSubtitle(false);
      this.hasBeenFocused = false;
      if (this.context.ctxEcran === "saisiemass") {
        this.btnBar.deleteButton("new");
        this.btnBar.deleteButton("copy");
        this.btnBar.deleteButton("delete");
      }
      this.workflow.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
      if (this.context && this.context.ctxModeRepresentation === "pop-up") {
        this.workflow.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application
      }
    });
  }

  _manageModeRepresentation(): void {
    if (this.context && this.context.ctxModeRepresentation && this.context.ctxModeRepresentation === "pop-up" && !(this.parent instanceof CWMRegulRouter)) { // Pop up
      this.$el.find(".c-gererRegularisationContent__btnBar").append(this.btnBar.render().el);
      this.$el.find("#regulcollab_btnbar").css("float", "left");
      this._setTitlePopUp();
      $((this.parent as CWDialogPopupView).el).parent().css("min-width", "600px");
      this.$el.find(".c-gererRegularisationContent").addClass('c-form--fixedButtons');
      this.$el.find('.gererregularisation-form-inputs').addClass('c-form__scrollZone m-0 ');
    } else if (this.context.ctxSaisieMasse === true) {
      this.$el.find(".gererregularisations-content-btn-bar").append(this.btnBar.render().el);
    } else {
      this.$el.find(".gererregularisations-utilisateur").hide();
      if (this.context && this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass") {
        //this.$el.find(".gererregularisation-content").prepend(this.btnBar.render().el);
        if (this.parent instanceof CWMRegulRouter) {
          $(this.parent.layout.el).find(".l-panelB-butomButtons").prepend(this.btnBar.render().el);
        }
        this._setTitleImbrique();
      }
    }
  }

  public getButtonBarRef(): CWButtonBarValidationView {
    return this.btnBar;
  }

  menuItemListener(event: JQueryEventObject): void {
    const action = (event.currentTarget.attributes as any)["data-value"].nodeValue;
    const code = (event.currentTarget.attributes as any)["data-code"].nodeValue;

    switch (action) {
      case "2":
        this.btnBar.model.trigger("btn:click", "accepter", code);
        break;
      case "3":
        this.btnBar.model.trigger("btn:click", "refuser", code);
        break;
    }
  }

  _setTitlePopUp(): void {
    const title = $('<div>').html('<div class="gererregularisations-titlePopup"><span class="typeRegul">' + this._getTitle() + '</span> <span class="c-titleHistoWkf"></span>');

    if (!(this.parent instanceof CWMRegulRouter)) {
      this.parent.isTitleHTML = true;
      this.parent._setTitle(title.html());
    }
  }

  _setTitleImbrique(): void {
    if (this.parent instanceof CWMRegulRouter) {
      $(this.parent.layout.el).find('.typeRegul').html(this._getTitle()).removeClass('d-none');
    }
  }

  _getTitle(): string {
    const title = this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.length >= 1 && _.contains(this.context.ctxActionsPossibles, "Creer") &&
      this.context.ctxModeInitialisation === "Ajout" ? i18n.t('common:gererregularisations.nouvelle_regul_title') : i18n.t('common:gererregularisations.regul_title');

    return title.toUpperCase();
  }

  _manageUtilisateur(): void {
    if (this.context.ctxUtilisateur === "Responsable" && this.context.ctxGestionCollective === false && !CWSTR.isBlank(this.context.ctxGestionCollab)) {
      let collabText = this.context.ctxGestionCollab.nom + " " + this.context.ctxGestionCollab.prenom;

      // information du collaborateur
      this.$el.find(".c-gestionCollab").removeClass("d-none");
      if (CWSTR.isBlank(this.diver) || (this.diver === "matric")) {
        collabText += " (" + this.context.ctxGestionCollab.matricule + ")";
      } else if (this.context.ctxGestionCollab.matric_aux) {
        collabText += " (" + this.context.ctxGestionCollab.matric_aux + ")";
      } else if (this.context.ctxGestionCollab.matricule) {
        collabText += " (" + this.context.ctxGestionCollab.matricule + ")";
      }
      this.$el.find(".c-gestionCollab").html(collabText);
    } else {
      this.$el.find(".c-gestionCollab").addClass('d-none');
    }
  }

  _renderMessageInformatif(motif: any): void {
    const comm = this.$el.find(".gererregularisations-aide");
    let indication: string;
    let showComm = false;

    //display text indication
    if (typeof motif.get === "function") {
      indication = motif.get('indication');
    } else {
      indication = motif.indication || "";
    }
    if (!CWSTR.isBlank(motif) && !CWSTR.isBlank(indication)) {
      showComm = true;
      comm.attr('title', indication);
    } else {
      comm.attr('title', "");
    }
    if (showComm) {
      comm.removeClass('d-none');
    } else {
      comm.addClass('d-none');
    }
  }

  _manageModeInitialisation(callback: () => void): void {
    const initMode = this.context.ctxModeInitialisation;
    let regul: CWGererRegularisationsModel = null;

    //réinitialisation des valeurs
    this.disabled = false;
    this.desactiveHabOuEtat = false;
    this.isEditable = true;
    this.errorPetAntSaveDelete = false;
    this.infoErrorModify = {};
    this.notCheckCombos = true;
    //-------------------
    if (this.context && this.context.ctxSaisieMasse === true) {
      regul = new CWGererRegularisationsModel(null, { "matricule": true, "infoCompAttributeName": this.infoCompAttributeName });
    } else {
      regul = new CWGererRegularisationsModel(null, { "infoCompAttributeName": this.infoCompAttributeName });
    }
    switch (initMode) {
      case "Consultation":
        this._initModeConsultation(regul, callback);
        break;
      case "Ajout":
        this._initModeAjout(regul, callback);
        break;
      case "InitForAction":
        break;
      default:
        this._initDefaultMode(regul, callback);
    }
  }

  _paintWorkflowStatus(): void {
    let $layout;

    if (this.context && this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass") {
      if (this.context.ctxModeRepresentation === "pop-up") {
        $layout = $(this.$el.parent().parent()).find('.c-titleHistoWkf');
      } else {
        if (this.parent instanceof CWMRegulRouter) {
          $layout = this.parent.layout ? $(this.parent.layout.el).find(".l-panelB-buttonContainer") : this.$el.find(".l-panelB-buttonContainer");
        }
      }
      if (this.context.ctxModeInitialisation === "Consultation") {//if is consultation mode
        const regul = this.model.get("value");
        let subtextEtiquette: string = null;
        let codeStatut: validEtiquetteTypes = null;
        let text: string = null;
        // not display label without workflow  !this.workflow.wkfActiv ||
        const isOnlyIcon = this.workflow.wkfActiv && regul.get("iddemande") >= 0 && regul.get("statut").code === "H";
        const RegulPermut = regul.get("permutation");

        $layout.show();
        //il faut supprimer l'information précédente ('listenTo' aussi)
        if (!_.isEmpty(this.etiquetteHisto1)) {
          this.etiquetteHisto1.model.stopListening();
          this.etiquetteHisto1.model.off();
          this.etiquetteHisto1.$el.off();
          this.etiquetteHisto1.off();
          this.etiquetteHisto1.remove();
          this.etiquetteHisto1 = null;
        }
        if (RegulPermut) {
          this.etiquetteHisto1 = new CWEtiquette({
            id: "etiquetteWorkHisto",
            text: { enabled: i18n.t('common:gererregularisations.permute') },
            type: "accepte",
            state: "actif"
          });
        } else {
          //etiquette workflowhisto
          subtextEtiquette = regul.get("etat") === "X" ? i18n.t('common:etiquette_subText.suppression') : "";
          codeStatut = this.checkSCodetatut(regul.get("statut").code);
          text = regul.get("statut").code === "H" ? "" : regul.get("statut").libelle;
          this.etiquetteHisto1 = new CWEtiquette({
            id: "etiquetteWorkHisto",
            text: { enabled: text }, //perhaps we should change for i18n text
            subText: { enabled: subtextEtiquette },
            type: codeStatut,
            state: "actif",
            onlyIcon: isOnlyIcon
          });
          if (this.workflow.wkfActiv && regul.get("iddemande") >= 0) {
            // display histo icon if wfk was required 
            this.etiquetteHisto1.icon = 'horloge_fleche';
            this.listenTo(this.etiquetteHisto1.model, "click", this.histoEtiquetteClick);
          } else if (isOnlyIcon === true || text === "") {
            this.etiquetteHisto1.setNoBackground();
          }
        }
        $layout.html(this.etiquetteHisto1.render().el);
      } else {
        if (!CWSTR.isBlank($layout)) {
          $layout.hide();
        }
      }
    }
  }

  private histoEtiquetteClick(): void {
    if (!CWSTR.isBlank(this.histoEtiquettePopup) && this.panneauIsOpen === true) {
      this.histoEtiquettePopup.close();
      this.panneauIsOpen = false;
    } else {
      this.panneauIsOpen = true;
      if (_.isEmpty(this.histoEtiquettePopup)) {
        this.histoEtiquettePopup = new CWPanneauDeroulant({
          fixedButtons: true,
          maxHeight: 360,
          view: this.composantWorkflow,
          viewData: {
            appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
            modal: false
          },
          notIconClose: true,
          closeOnClickOutside: true
        });
        if (_.isEmpty(this.outsideClickListener)) {
          this.outsideClickListener = (event: any): any => {
            if ($(event.target).parents(".c-panneauDeroulant").length === 0 && $(event.target).closest(".ui-datepicker-header").length === 0 && $(event.target).closest(".ui-datepicker-calendar").length === 0 && this.histoEtiquettePopup) {
              this.histoEtiquettePopup._closePanneau(event, null);
            }
          };
        }
        if (this.context.ctxModeRepresentation === "Imbrique") {
          this.histoEtiquettePopup.$el.on("dialogopen", (): void => {
            $(document).off("click" + (this.workflow.module ? ("." + this.workflow.module) : ""), this.outsideClickListener as () => any);
            $(document).on("click" + (this.workflow.module ? ("." + this.workflow.module) : ""), this.outsideClickListener);
          });
          this.histoEtiquettePopup.$el.on("dialogclose", (): void => {
            $(document).off("click" + (this.workflow.module ? ("." + this.workflow.module) : ""), this.outsideClickListener as any);
            this.panneauIsOpen = false;
          });
        } else {
          this.histoEtiquettePopup.model.on("dlg:close", (): void => {
            this.panneauIsOpen = false;
          });
        }
      } else {
        this.histoEtiquettePopup.view = this.composantWorkflow;
        this.histoEtiquettePopup.viewData = {
          appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
          modal: false
        };
      }
      this.histoEtiquettePopup.setPosition({
        my: "right top",
        at: "right bottom",
        of: $(event.currentTarget)
      });
      this.histoEtiquettePopup.setHeight("auto");
      this.histoEtiquettePopup.setWidth(500);
      this.histoEtiquettePopup.open();
      this.histoEtiquettePopup._setTitle(i18n.t('common:listerevtgen.historique').toUpperCase());
    }
    event.stopPropagation();
  }

  // This method return the style typoe of the etiquette when we send the letter of the status
  private checkSCodetatut(code: string): validEtiquetteTypes {
    let status: validEtiquetteTypes;

    switch (code) {
      case "D":
        status = 'demande';
        break;
      case "T":
        status = 'demande';
        break;
      case "I":
        status = 'demande';
        break;
      case "A":
        status = 'accepte';
        break;
      case "H"://this case maybe is not useful
        status = 'accepte';
        break;
      case "R":
        status = 'refuse';
        break;
      default:
        status = 'demande';
        break;
    }
    return status;
  }

  _manageActionsPossibles(): void {
    //["Creer","Supprimer","Modifier","Valider"]
    if (this.context.ctxModeInitialisation === "Consultation") {
      if (this.context && CWSTR.isBlank(this.context.ctxIdentifiantEvt)) {
        this.workflow.btnBarModel.set("mode", "R");
        this.btnBar.model.trigger("hide:delete");
      } else {
        if (this.context && this.context.ctxSaisieMasse === true) {
          this.btnBar.model.trigger("hide:new");
          this.btnBar.model.trigger("hide:delete");
        } else {
          if (this.context.ctxActionsPossibles && this.context.ctxModeInitialisation === "Ajout" && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion) && CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
            if (this.context.ctxActionsPossibles.indexOf("Creer") !== -1) {
              this.btnBar.model.trigger("show:new");
            } else {
              this.btnBar.model.trigger("hide:new");
            }
          } else if (this.context.ctxActionsPossibles && this.context.ctxModeInitialisation === "Consultation") {
            if (this.context.ctxActionsPossibles.indexOf("Creer") !== -1 && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion) && CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion) && this.context.ctxModeRepresentation !== "pop-up") {
              this.btnBar.model.trigger("show:new");
            } else {
              this.btnBar.model.trigger("hide:new");
            }
            if (this.context.ctxActionsPossibles.indexOf("Modifier") === -1 && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion) && CWHABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion)) {
              this._disableForm();
            }
            if (this.context.ctxActionsPossibles.indexOf("Valider") !== -1 && CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation) && this.model.get("value").get("profils") &&
              this.model.get("value").get("profils").length > 0 && this.context.ctxUtilisateur === "Responsable") {
              this.btnBar.model.trigger("show:valider");
              this.btnBar.model.trigger("show:accepter");
              this.btnBar.model.trigger("show:refuser");
            }
          }
        }
      }
    }
  }

  _manageHabilitation(): void {
    const peutCreer = (!CWSTR.isBlank(this.context?.ctxHabilitation?.HabilitationGestion)) ? CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion) : false;
    const peutModifier = (!CWSTR.isBlank(this.context?.ctxHabilitation?.HabilitationGestion)) ? CWHABILITATION.canUpdate(this.context.ctxHabilitation.HabilitationGestion) : false;
    const peutSupprimer = (!CWSTR.isBlank(this.context?.ctxHabilitation?.HabilitationGestion)) ? CWHABILITATION.canDelete(this.context.ctxHabilitation.HabilitationGestion) : false;
    const peutValider = (!CWSTR.isBlank(this.context?.ctxHabilitation?.HabilitationValidation)) ? CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation) : false;
    let isFormDesactive = false;

    if (this.model.get("value").isNew()) {
      this.isEditable = peutCreer;
    } else {
      this.isEditable = peutModifier;
    }
    if (!peutCreer && this.context.ctxModeInitialisation !== "Consultation") {
      this.btnBar.model.trigger("hide:new");
      this.btnBar.model.trigger("hide:nouveau");
      CWFORMS.setFormReadonly(this.$el.find(".gererregularisation-form-inputs"), true, false);
      this.desactiveHabOuEtat = true;
      isFormDesactive = true;
    } else if (!peutCreer) {
      this.btnBar.model.trigger("hide:nouveau");
    }
    if (!peutModifier && this.context.ctxModeInitialisation !== "Ajout") {
      // we cannot disable the form if we are in "collectifs"
      if (objs.appRt.workflow.get("usecase") !== "saisiescoll") {
        this._disableForm();
        this.desactiveHabOuEtat = true;
      }
      CWFORMS.setFormReadonly(this.$el.find("gererregularisation-form-inputs"), true, false);
      isFormDesactive = true;
    }
    if (!peutModifier && !peutCreer) {
      this.btnBar.model.trigger("hide:revert");
      this.btnBar.model.trigger("hide:save");
      if (!isFormDesactive) {
        this._disableForm();
      }
    }
    if (!peutSupprimer) {
      this.btnBar.model.trigger("hide:delete");
    }
    if (!peutValider) {
      this.btnBar.model.trigger("hide:valider");
      this.btnBar.model.trigger("hide:accepter");
      this.btnBar.model.trigger("hide:refuser");
    }
  }

  _updateSaveButtonState(): void {
    this._manageRestrictions();
  }

  //	Accepte, En course, Demande, Hors validation, refuse, Demande -> 	"A","I","D","H","R","T"
  //	Manage restrictions for actions
  _manageRestrictions(editable?: boolean): void {
    const model = this.model.get("value");

    if (editable === false) {
      this._disableForm();
    }
    if (editable === true && this.disabled === true) {
      this._enableForm();
    }
    this.btnBar.hideValidationBtn();
    if (this.context && this.context.ctxUtilisateur === "Collaborateur" && !CWSTR.isBlank(model) && !CWSTR.isBlank(model.get("statut"))) {
      this.btnBar.model.trigger("hide:valider");
      this.btnBar.model.trigger("hide:accepter");
      this.btnBar.model.trigger("hide:refuser");
      switch (model.get("statut").code) {
        case "A": //Accepte
        case "H": //Hors validation
          this._disableForm();
          this.desactiveHabOuEtat = true;
          break;
        case "T":
        case "D": // Demande
          if (model.get("etat") === "X") {
            this._disableForm();
            this.desactiveHabOuEtat = true;
          }
          break;
        default:
          if (editable !== false) {
            this._enableForm();
          }
      }
    } else if (this.context && this.context.ctxUtilisateur === "Responsable" && model && !CWSTR.isBlank(model.get("statut"))) {
      switch (model.get("statut").code) {
        case "D": //Demande
        case "T": //Demande
        case "I": //En cours
          this.btnBar.model.trigger("hide:delete");
          this._disableForm();
          this.desactiveHabOuEtat = true;
          break;
        case "A": //Accepte
        case "H": //Hors validation
          this.btnBar.model.trigger("hide:valider");
          this.btnBar.model.trigger("hide:accepter");
          this.btnBar.model.trigger("hide:refuser");
          this.desactiveHabOuEtat = true;
          break;
        default:
          if (editable !== false) {
            this._enableForm();
          }
      }
    }
    if (model && !CWSTR.isBlank(model.get("statut")) && model.get("statut").code === "R") { //Refuse
      this._disableForm();
      this.btnBar.model.trigger("hide:accepter");
      this.btnBar.model.trigger("hide:refuser");
      this.btnBar.model.trigger("hide:delete");
      this.desactiveHabOuEtat = true;
    }
  }

  _disableForm(): void {
    this.disabled = true;
    CWFORMS.setFormReadonly(this.$el, true, false);
  }

  _enableForm(): void {
    const isReadonly = this.$el.find(".infocomp").prop("readonly");

    this.disabled = false;
    CWFORMS.setFormReadonly(this.$el, false, false);
    CWFORMS.setFieldReadonly(this.$el.find(".infocomp"), isReadonly, false);
  }

  render(): CWGererRegularisationView {
    const json = { "i18n": i18n, "UTILS": UTILS };

    this.$el.empty();
    this.$el.html(this.template(json));
    if (this.context && this.context.ctxUtilisateur === "Collaborateur") {
      this.$el.find(".infocomp").prop("readonly", "true");
    }
    this.renderForm();
    if (this.context) {
      this.setContext(this.context);
    }
    if (this.parent && !(this.parent instanceof CWMRegulRouter)) {
      $((this.parent as CWDialogPopupView).el).css("height", "auto");
    }
    if (this.context && this.context.ctxSaisieMasse) {
      this.trigger("end:renderComponent");
    }
    if (this.context.ctxModeRepresentation === "pop-up") {
      this.trigger("manageUnselected", false);
      this._managePeriode(this, this._getMotif(this.model.get('value').get('motif').code, this.comboMotifColl));
    }
    this._manageCommentaire();
    return this;
  }

  renderForm(callback?: () => void): CWGererRegularisationView {
    this._initCombos();
    //Render combos
    this.$el.find(".cmbMotif").html(this.comboMotif.render().el);
    this.$el.find(".comboOrigine").html(this.comboOrigine.render().el);
    this._updateVisibiliteOrigine();
    // Dates
    CWFORMS.setDatepicker(this, ".datedeb");
    CWFORMS.setDatepicker(this, ".datefin");
    if (callback) {
      callback();
    }
    return this;
  }

  _getMotif(codeSelected: string, coll?: CWListeMotifRegulColl | CWComboBoxView2): CWGererRegularisationsModel {
    let motifs: Array<CWListeMotifRegulModel> = this.comboMotif.coll.models;
    let motif: CWGererRegularisationsModel = null;

    if (CWSTR.isBlank(coll) === false) {
      if (coll instanceof CWComboBoxView2) {
        motifs = coll.collection.models as Array<CWListeMotifRegulModel>;
      }
      else {
        motifs = coll.models;
      }
    }
    motif = _.find(motifs, (auxMotif: { [key: string]: any }): boolean => {
      if (auxMotif.attrs) {
        return String(auxMotif.attrs.code) === String(codeSelected);
      } else {
        return String(auxMotif.get("code")) === String(codeSelected);
      }
    }) as CWGererRegularisationsModel;
    if (!motif) {
      motif = _.find(this.comboMotif.cache[""], (auxMotif: { [key: string]: any }): boolean => {
        if (auxMotif.attrs) {
          return String(auxMotif.attrs.code) === String(codeSelected);
        } else {
          return String(auxMotif.get("code")) === String(codeSelected);
        }
      }) as CWGererRegularisationsModel;
    }
    return motif;
  }

  _managePeriode(view: CWGererRegularisationView, motif: CWGererRegularisationsModel, showMessage?: boolean): void {
    const aux = this.workflow.formModel.get("value");
    const labelDatedeb = view.$el.find("label.gererregularisations-dates__label span.gererregularisations-dates__Textlabel");
    let gererRegul = null;

    if (motif && motif instanceof Model) {
      view._regulParPeriode = motif.get("periode");
    } else if (motif && (motif as any).attrs) {
      view._regulParPeriode = (motif as any).attrs.periode;
    } else if (motif) {
      view._regulParPeriode = true;
    }
    // update "isPeriode" in form model
    aux.isPeriode = view._regulParPeriode;
    gererRegul = this.model.get("value");
    this._manageChangeDateDeb(gererRegul.get("datedeb"));
    this._manageChangeDateFin(gererRegul.get("datefin"));
    if (view._regulParPeriode === true) {
      if (this.context && this.context.ctxSaisieMasse) {
        view.$el.find("div.datefinal").removeClass("ui-state-disabled");
        view.$el.find("div.datefinal input.datefin").prop('disabled', false);
        view.$el.find("div.datefinal button.ui-datepicker-trigger").prop('disabled', false);
      }
      labelDatedeb.text(i18n.t('common:gererregularisations.periode'));
      view.$el.find("div.datefinal").show();
    } else {
      if (showMessage &&
        !CWSTR.isBlank(view.model.get("value").get("datedeb")) &&
        view.model.get("value").get("datedeb") !== view.model.get("value").get("datefin")) {
        CWMSGS.showInfo(i18n.t('messages:GT_1178'), this.workflow.$appendTo);
      }
      view.model.get("value").set({ "datefin": view.model.get("value").get("datedeb") }, { silent: true });
      if (this.context && !this.context.ctxSaisieMasse) {
        view.$el.find("div.datefinal").hide();
        // Change label here
      } else {
        view.$el.find("div.datefinal").addClass("ui-state-disabled");
        view.$el.find("div.datefinal input.datefin").prop('disabled', true);
        view.$el.find("div.datefinal button.ui-datepicker-trigger").prop('disabled', true);
        view.$el.find("div.datefinal input.datefin").val("");
      }
      //view.$el.find("label[for=datedeb],label[for^=datedeb_uid_]").text(i18n.t('common:gererregularisations.datedebut_le'));
      labelDatedeb.text(i18n.t('common:gererregularisations.date'));
    }
    if (this.context && this.context.ctxSaisieMasse) {
      labelDatedeb.text(i18n.t('common:gererregularisations.datedebut_le') + " / " + i18n.t('common:gererregularisations.datedebut'));
    }
  }

  _selectMotif(view: CWGererRegularisationView, code: string, doFetch?: boolean): void {
    const codeSelected = code;
    const callback = (coll?: CWListeMotifRegulColl): void => {
      let motif = view._getMotif(codeSelected, coll);

      this.comboMotifColl = coll;
      if (!CWSTR.isBlank(motif)) {
        if ((motif as any).attrs) {
          const aux = new CWGererRegularisationsModel((motif as any).attrs);

          motif = aux;
        }
        //Set typevaleur to model validation
        view.model.get("value").get("motif").typevaleur = motif.get("typevaleur");
        //Show | Hide indication
        this._renderMessageInformatif(motif);
      }
      if (view.$el) {
        view._managePeriode(view, motif, true);
      }
      if (!CWSTR.isBlank(motif)) {
        const carPersMotif = view.carPers.get("value");

        carPersMotif.setHabContext(view.model.getHabContext());
        carPersMotif.set("id", motif.get("id"));
        carPersMotif.fetch({
          success: (fresh: CWCarPersonMotifRegulModel): void => {
            view.context.ctxValeursCreation = view.context.ctxValeursCreation || {};
            if (!CWSTR.isBlank(view.context.ctxValeursCreation.RegCodeMotif)) {
              view.context.ctxValeursCreation.RegCodeMotif = null;
            }
            view.model.get("value").set("carpers", fresh.get("carpers"));
            view.$el.parent().parent().css("height", "auto");
            view.typeMaskByClass = Object.assign({}, this.defaultTypeMaskByClass);
            view._mapToForm();
            view.typeFormatByClass["valeur"] = motif.get("format");
            // we put the default value in "valeur" - if motif has a default value
            // and if we are in mode creation.
            if (CWSTR.isBlank(view.model.get("value").id)) {
              const valdef = motif.get("valdef");

              view.model.get("value").set("valeur", valdef);
              view.$el.find("input.valeur").val(CWTYPE.CUSTOM.format(valdef, view.typeFormatByClass["valeur"]));
            } else { // if we are in mo dification we change only the format
              view.$el.find("input.valeur").val(CWTYPE.CUSTOM.format(view.$el.find("input.valeur").val(), view.typeFormatByClass["valeur"]));
            }
          }
        });
      }
      if (view.context && view.context.ctxSaisieMasse === true) {
        view.trigger("end:renderComponent");
      }
    };

    if (doFetch === true) {
      this.comboMotif.fetchCombo(callback);
    } else if (code !== view.carPers.get("value").get('id')) {
      callback();
    }
  }

  _mapToForm(): void {
    if (this.$el) {
      const gererRegul: CWGererRegularisationsModel = this.model.get("value");
      const renderers: { [key: string]: any } = {};
      const fieldset = this.$el.find(".gererregularisation-form-inputs");

      if (gererRegul === null) {
        // this.$el.find(".gererregularisation-form-inputs").hide();
        fieldset.hide();
      } else {
        // this.$el.find(".gererregularisation-form-inputs").show();
        fieldset.show();
        gererRegul.setHabContext(this.model.getHabContext());
        //When no infocomp and if the field is in readonly hide the camp
        if (CWSTR.isBlank(gererRegul.get("infocomp")) && this.$el.find(".infocomp").prop("readonly")) {
          this.$el.find(".spinfocomp").hide();
        } else {
          this.$el.find(".spinfocomp").show();
        }
        _.each(this.listeMotifs.models, (element) => {
          if (element.get("code") === gererRegul.get("motif").code) {
            this.typeFormatByClass["valeur"] = element.get("format");
            gererRegul.get("motif").libelle = element.get("libelle");
          }
        });
        if (this.flagDataReady === true) {
          //when the motif has changed we need to update the visualization of the periodes
          if (!CWSTR.isBlank(this._getMotif(this.model.get("value").get("motif").code, this.comboMotifColl))) { //Prevent call in creation
            this._managePeriode(this, this._getMotif(this.model.get("value").get("motif").code, this.comboMotifColl));
          }
        }
        this.flagDataReady = true;
        this.typeFormatByClass["valeur"] = CWSTR.getElValue(gererRegul, "motif.format");
        // Error management
        gererRegul.off("invalid");
        gererRegul.on("invalid", this._showValidationErrors, this);
        // Clear current validation erros
        this._cleanValidationErrors();
        //this._panelStateRender(gererRegul);
        this._panelStateRender(this.$el);
        //Dynamic Data
        this._loadInformationsComplementairesDynamic(gererRegul.get("carpers"));
        this._mapModelToForm(fieldset, gererRegul, renderers);
        //				this.comboMotif.setItem(gererRegul.get("motif")); //it is not necessary. _mapModelToForm loads and set values to form yet
        if (this.context.ctxModeInitialisation === "Ajout") {
          const emptyColl = this.comboMotif.coll.length > 0 ? false : true;

          if (this.context && this.context.ctxValeursCreation && this.context.ctxValeursCreation.RegCodeMotif) {
            this._selectMotif(this, this.context.ctxValeursCreation.RegCodeMotif, emptyColl);
          }
        }
        if (gererRegul.isNew() && (this.context && this.context.ctxSaisieMasse !== true && this.context.ctxVolet !== true)) {
          fieldset.find(".motif\\.code").focus();
        }
        if (this.context.ctxSaisieMasse === true) {
          // we call to set the height to the table if the activite has many characteristics
          this.trigger("setTableScroll");
        }
      }
    }
  }

  private _displaySubtitle(): void {
    if (!this.isDislayedSubtitle) {
      let $layout: JQuery = null;

      if (this.context && this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass") {
        if (this.context.ctxModeRepresentation === "Imbrique" && this.parent instanceof CWMRegulRouter) {
          $layout = $(this.parent.layout.el).find(".typeRegul");
        } else {
          if (!(this.parent instanceof CWMRegulRouter)) {
            $layout = (this.parent as CWDialogPopupView).$el.parent().find(".typeRegul")
          }
        }
      }
      if (this.context.ctxModeInitialisation === "Consultation") {
        const subtitle = $layout.find('.gererregularisations-subtitle');

        if (subtitle.hasClass('d-none')) {
          subtitle.removeClass('d-none');
          this.isDislayedSubtitle = true;
        }
      }
    }
  }

  private _hideSubtiltle(): void {
    let $layout: JQuery = null;

    if (this.context && this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass") {
      if (this.context.ctxModeRepresentation === "Imbrique" && this.parent instanceof CWMRegulRouter) {
        $layout = $(this.parent.layout.el).find(".typeRegul");
      } else {
        if (!(this.parent instanceof CWMRegulRouter)) {
          $layout = (this.parent as CWDialogPopupView).$el.parent().find(".typeRegul")
        }
      }
      $layout.find('.gererregularisations-subtitle').addClass('d-none');
      this.isDislayedSubtitle = false;
    }
  }

  private _initSubtitle(needToBeDisplay?: boolean): void {
    let $layout: JQuery = null;

    if (this.context && this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass") {
      if (this.context.ctxModeRepresentation === "Imbrique" && this.parent instanceof CWMRegulRouter) {
        $layout = $(this.parent.layout.el).find(".typeRegul");
      } else {
        if (!(this.parent instanceof CWMRegulRouter)) {
          $layout = (this.parent as CWDialogPopupView).$el.parent().find(".typeRegul")
        }
      }
      if ($layout.find('.gererregularisations-subtitle').length === 0) {
        if (this.context.ctxModeInitialisation === "Ajout") {
          $layout.append($('<span>').addClass('gererregularisations-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne').html(String(" - ").concat(i18n.t('common:gererregularisations.creation_en_cours'))));
        } else if (this.context.ctxModeInitialisation === "Consultation") {
          $layout.append($('<span>').addClass('gererregularisations-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne d-none').html(String(" - ").concat(i18n.t('common:gererregularisations.modification_en_cours'))));
        }
      }
    }
    if (needToBeDisplay) {
      this._displaySubtitle();
    } else if (this.isDislayedSubtitle) {
      this._hideSubtiltle();
    }
  }

  _manageView(event: JQueryInputEventObject, data: object): void {
    const gererRegul = this.model.get("value");
    const className = event.target.className.split(" ")[0];

    this._displaySubtitle();
    //General Change call
    if (this._getModel().context && this._getModel().context.ctxSaisieMasse === true && className === "matricule") {
      this._referentialPopulationChanged();
    }
    if (this.context.ctxEcran === "saisiemass" && CWSTR.isBlank(data) && gererRegul && !CWSTR.isBlank(gererRegul.get("motif"))) {
      data = gererRegul.get("motif").code;
      if (!_.isUndefined(this.comboMotif.cache.current)) {
        this.comboMotif.input.val(this.comboMotif.cache.current.label);
      } else if (_.isNumber(data)) {
        this.comboMotif.term = "";
        this.comboMotif.coll.params.search = "";
        this.comboMotif.fetchCombo(() => {
          this.comboMotif.setItem({ code: data });
        })
      }
    }
    this._change(event, data);
    if (className === "motif.code") {
      const codeSelected = $(event.target).prop("data-code");

      this._selectMotif(this, codeSelected);
      this._cleanValidationErrors();
      $(event.target).blur();
    }
    if (className === "valeur") {
      let parse: { [key: string]: any } = null;

      if ($(event.target).hasClass('typeCustom')) {
        parse = CWTYPE.CUSTOM.parse((event.target as any).value);
      } else {
        parse = CWTYPE.LONG.parse((event.target as any).value, this.typeFormatByClass["valeur"]);
      }
      CWSTR.setElValue(gererRegul, className, parse["val"], { silent: true });
    }
    if (this._regulParPeriode === false) {
      gererRegul.set("datefin", gererRegul.get("datedeb"), { silent: true });
      //when the motif has changed we need to update the visualization of the periodes
      if (!CWSTR.isBlank(this._getMotif(this.model.get("value").get("motif").code))) {
        //Prevent call in creation
        this._managePeriode(this, this._getMotif(this.model.get("value").get("motif").code), false);
      }
    } else {
      if (className === "datedeb") {
        this._manageChangeDateDeb(gererRegul.get("datedeb"));
      } else if (className === "datefin") {
        this._manageChangeDateFin(gererRegul.get("datefin"));
      }
    }
    if (UTILS.isIE() && (className === "datedeb" || className === "datefin")) {
      $(event.target.parentElement).find(".ui-datepicker-trigger").focus();
    }
  }

  _manageChangeDateDeb(datedeb: string): void {
    if (this._getModel().isNew()) {
      const datefin = this.model.get("value").get("datefin");

      if (!CWSTR.isBlank(datedeb) && CWSTR.isBlank(CWTYPE.DATE.validate(datedeb))) {
        const date = CWTYPE.DATE.format(datedeb, CWTYPE._getFormatByCode(this.dateFormat));

        this.$el.find(".gererregularisations-dates .datedeb").val(date);
        if (this.$el.find(".gererregularisations-dates .datefin").val() === "") {
          CWSTR.setElValue(this.model.get("value"), "datefin", datedeb);
          this.$el.find(".gererregularisations-dates .datefin").val(date);
        } else if (datedeb > datefin) {
          CWSTR.setElValue(this.model.get("value"), "datefin", datedeb);
          this.$el.find(".gererregularisations-dates .datefin").val(date);
        }
      }
    }
  }

  _manageChangeDateFin(datefin: string): void {
    if (!CWSTR.isBlank(datefin) && CWSTR.isBlank(CWTYPE.DATE.validate(datefin))) {
      const date = CWTYPE.DATE.format(datefin, CWTYPE._getFormatByCode(this.dateFormat));

      this.$el.find(".gererregularisations-dates .datefin").val(date);
    }
  }

  _manageWKFHisto(historique: boolean): void {
    if (historique === true && CWSTR.getElValue(this.model.get("value"), "historique") === true) {
      this.contextComponentWkf = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: this.context.ctxHabilitation.HabilitationAcces,
        ctxTypeEvenement: "REGULARISATION",
        ctxRefEvenement: CWSTR.getElValue(this.model.get("value"), "evenement"),
        ctxDemId: CWSTR.getElValue(this.model.get("value"), "iddemande"),
        ctxModeRestitution: "Imbrique",
        ctxModeDefaut: "Deplie",
        ctxModeRech: false
      };
      this.composantWorkflow = new CWWkflhistoView();
      this.composantWorkflow.setContext(this.contextComponentWkf);
      this.composantWorkflow.render();
      // $(this.el).find(".gererregularisations-wkfhisto").html(this.composantWorkflow.render().el);
      // this.composantWorkflow.setContext(this.contextComponentWkf);
      this.composantWorkflow.hideIcon();
      this.composantWorkflow.hideTitle();
    } else {
      this.$el.find(".gererregularisations-wkfhisto").hide();
    }
  }

  saveRegulCollab(): void {
    const target: CWGererRegularisationsModel = this.model.get("value");
    let saveAction = "update";
    const saveCallback = (fresh: CWGererRegularisationsModel): void => {
      if (this.context.ctxModeRepresentation === "pop-up") {
        if (!(this.parent instanceof CWMRegulRouter)) {
          this.parent.close();
        }
      }
      this.model.trigger("regulChanged", fresh, saveAction);
      this.workflow.btnBarModel.set("mode", "C");
      if (this.context && this.context.ctxSaisieMasse) {
        this.collabBackup = this.referentielCollabPopu.model.toJSON();
        this.selectCollab();
      }
      this.model.trigger("editionEnd");
    };
    const errorSaveCallback = (model: CWGererRegularisationsModel, response: { [key: string]: any }): void => {
      if (this._regulParPeriode === false) {
        if (!_.isEmpty(model.validationError)) {
          // server does not send the error in datedebut (only in datefin)
          // so we create the error manually in the save process.
          if (!CWSTR.isBlank(model.validationError?.errors.datefin)) {
            model.validationError.errors.datedeb = model.validationError.errors.datefin;
            delete model.validationError.errors.datefin;
            this._showValidationErrors(model, model.validationError);
          }
        }
      }
      this.errorPetAntSaveDelete = true;
      if (this.context.ctxModeRepresentation === "pop-up") {
        if (_.isEmpty(model.validationError) || _.isEmpty(model.validationError.errors)) {
          if (response.responseJSON?.icone === "?" && response.responseJSON?.boutons.length > 1) {
            this.stopListening(target, "sync");
            this.listenToOnce(target, "sync", (): void => {
              (this.parent as CWDialogPopupView).close();
            });
          }
        }
      }
    };

    // we pass the type to the validation (model)
    target.isPeriode = this._regulParPeriode;
    target.set("matricule", this.context.ctxGestionCollab.matricule);
    target.action = "demande";
    if (target.isNew()) {
      saveAction = "create";
    }
    if (target.isValid()) {
      target.save(null, {
        success: saveCallback,
        error: errorSaveCallback
      });
    } else {
      this.workflow.btnBarModel.set("mode", "E");
    }
  }

  selectCollab(): void {
    const collab$ = this.$el.find('input.matricule');
    const inputValue = collab$.val() as string;
    const length = inputValue.length;

    if ((collab$.get(0) as any).setSelectionRange) {
      collab$.get(0).focus();
      (collab$.get(0) as any).setSelectionRange(0, length);
    }
  }

  newRegulCollab(callfrom: string): void {
    let target = null;

    this.stopMessagePrevClickRegul();
    this.notCheckCombos = true;
    if (this.context && this.context.ctxSaisieMasse === true) {
      target = new CWGererRegularisationsModel(null, { "matricule": true, "infoCompAttributeName": this.infoCompAttributeName });
    } else {
      target = new CWGererRegularisationsModel(null, { "infoCompAttributeName": this.infoCompAttributeName });
    }
    if (callfrom !== "mregul") {
      this.btnBar.model.trigger("disable:refuser");
      this.model.trigger("mode:create");
    }
    this.carPers.get("value").set("id", null);
    target.set("matricule", this.context.ctxGestionCollab.matricule);
    this.btnBar.model.trigger("disable:accepter");
    this._enableForm();
    this.model.set("value", target);
    this.model.trigger("form:edited", true);
    //Update context
    if (this.context.ctxEcran !== "saisiemass") {
      this.context.ctxModeInitialisation = "Ajout";
    }
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this._setTitlePopUp();
    } else {
      this._setTitleImbrique();
    }
    this._initSubtitle(true);
    this._paintWorkflowStatus();
    this._manageWKFHisto(false);
    this._renderMessageInformatif(target);
    if (this.$el.find(".indication").is(":visible")) {
      this.$el.find(".indication").hide();
    }
    if (!CWSTR.isBlank(this.$el)) {
      this.$el.find(".actionregul").html(i18n.t('common:gererregularisations.actionregul'));
    }
    this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestion, natGest: "A" });
  }

  revertView(): void {
    if (this.context && this.context.ctxModeRepresentation === "pop-up" && this.context.ctxModeInitialisation === "Ajout") {
      (this.parent as CWDialogPopupView).close();
    } else {//mode Imbrique
      let motifHelp = "";

      this.hasBeenFocused = false;
      //Update context
      if (this.context.ctxEcran !== "saisiemass") {
        this.context.ctxModeInitialisation = "Consultation";
      }
      this.workflow.btnBarModel.trigger("enable:valider");
      this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationAcces, natGest: "" });
      if (this.model.get("value") && this.model.get("value").isNew() && (this.context && !this.context.ctxSaisieMasse)) {
        const previous = this.model.previous("value");

        if (previous) {
          this.model.unset("value", { silent: true });
          this.model.set("value", previous);
          if (this.context && this.context.ctxModeRepresentation !== "pop-up" && previous.isNew()) {
            this.model.set("value", null);
            this.workflow.btnBarModel.set("mode", "C");
          } else {
            this.workflow.btnBarModel.set("mode", "R");
            if (this.context && this.context.ctxModeRepresentation !== "pop-up") {
              this._setTitleImbrique();
            } else {
              this._setTitlePopUp();
            }
            this._paintWorkflowStatus();
          }
          this._manageRestrictions();
        } else if (CWSTR.isBlank(this.context.ctxIdentifiantEvt) || this.model.get("value").isNew()) {
          this.model.set("value", null);
          this.workflow.btnBarModel.set("mode", "C");
          this._cleanValidationErrors();
        }
      } else {
        if (this.model.get("value")) {
          this.model.get("value").revert();
        }
        this.model.trigger("change:value");
        this.workflow.btnBarModel.set("mode", "R");
        this._manageHabilitation();
        if (this.context && this.context.ctxModeRepresentation === "pop-up" && this.context.ctxModeInitialisation === "Consultation") {
          this.btnBar.model.trigger("hide:new");
        }
      }
      this._initSubtitle(false);
      this._manageWKFHisto(this.context.ctxHistoriqueWKF);
      if (this.model.get("value") !== null) {
        motifHelp = this.model.get("value").get('motif');
      }
      this._renderMessageInformatif(motifHelp);
    }
  }

  deleteRegulCollab(commentaire: string, mode?: string): void {
    const target = this.model.get("value");
    let droit = target.get("droit");
    const successDestroyCallback = (): void => {
      if (this.context.ctxModeRepresentation === "pop-up") {
        if (!(this.parent instanceof CWMRegulRouter)) {
          this.parent.close();
        }
      }
      if (this.context && this.context.ctxSaisieMasse === true) {
        this.stopListening(target, "sync");
        this.trigger("delete-row:remove-model", { response: "Y" });
      } else {
        this.model.trigger("regulChanged", target, "delete");
      }
    };
    const errorDestroyCallback = (): void => {
      if (this.context?.ctxSaisieMasse === true) {
        this.errorPetAntSaveDelete = true;
      }
    };
    const errorDestroySaisieMasseCallback = (): void => {
      if (this.context?.ctxSaisieMasse === true) {
        this.errorPetAntSaveDelete = true;
        this.trigger("delete-row:remove-model", { response: "N" }, true);//on doit envoyer l'information de l'erreur et demander rétablir le listenToOnce
        this.stopListening(target, "sync");
        this.listenToOnce(target, "sync", (model?: { [key: string]: any }, response?: { [key: string]: any }, options?: { [key: string]: any }): void => {
          target.set('refresh', options);
          this.model.trigger("regulChanged", model, "delete");
          this.trigger("delete-row:remove-model", { response: "Y" });
        });
      }
    };

    this.errorPetAntSaveDelete = false;
    this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestion, natGest: "S" });
    if (!droit) {
      droit = "N";
    }
    target.action = "demande";
    if (!CWSTR.isBlank(commentaire)) {
      target.set("commentaire", commentaire);
    } else {
      target.set("commentaire", " ");
      target.set("droit", "N");
    }
    if (this.context && this.context.ctxSaisieMasse === true && !CWSTR.isBlank(mode)) {
      CWMSGS.showConfirmAdapter(i18n.t('messages:GT_1677'), (result: string): void => {
        if (result === "Y") {
          target.destroy({
            contentType: 'application/json',
            url: Configuration.restRoot + "/rest/regul/composant/" + target.action + "/" + encodeURIComponent(target.id) + "/" + encodeURIComponent(target.get("commentaire")) + "/" + encodeURIComponent(droit),
            success: successDestroyCallback,
            error: errorDestroySaisieMasseCallback,
            wait: true
          });
        } else {
          this.trigger("delete-row:remove-model", { response: "N" });
        }
      }, null, null, null, this.workflow.$appendTo);
    } else {
      target.destroy({
        contentType: 'application/json',
        url: Configuration.restRoot + "/rest/regul/composant/" + target.action + "/" + encodeURIComponent(target.id) + "/" + encodeURIComponent(target.get("commentaire")) + "/" + encodeURIComponent(droit),
        success: successDestroyCallback,
        error: errorDestroyCallback,
        wait: true
      });
    }
  }

  deleteRegulCollabFromMenuButton(regul: CWGererRegularisationsModel): void {
    this.id = encodeURIComponent(regul.id);
    regul.setHabContext(this.model.getHabContext());
    this.model.set("value", regul, { silent: true });
    this.btnBar.model.trigger("btn:click", "delete");
  }

  accepterRegulCollab(commentaire: string, profil: { [key: string]: any }): void {
    const values = this.model.get("value");
    const target = new CWGererRegularisationsGestion();
    const id = values.id;
    let droit = "N";
    const saveCallback = (): void => {
      if (this.context.ctxModeRepresentation === "pop-up") {
        if (!(this.parent instanceof CWMRegulRouter)) {
          this.parent.close();
        }
      }
      this.model.trigger("regulChanged", this.model.get("value"), "update");
    };

    if (!CWSTR.isBlank(values.get("droit"))) {
      droit = values.get("droit");
    }
    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: ""
    }));
    target.action = "accepter";
    target.id = id;
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    target.set("profil", {});
    target.get("profil").code = profil;
    target.save(null, {
      success: saveCallback,
      //error -> rien à faire ici
    });
  }

  accepterRegulCollabFromMenuButton(regul: CWGererRegularisationsModel): void {
    this.id = encodeURIComponent(regul.id);
    regul.setHabContext(this.model.getHabContext());
    this.model.set("value", regul, { silent: true });
    this.btnBar.model.trigger("btn:click", "accepter", regul.get("profil").code);
  }

  refuserRegulCollab(commentaire: string, profil: { [key: string]: any }): void {
    const values = this.model.get("value");
    const target = new CWGererRegularisationsGestion();
    const id = values.id;
    let droit = "N";
    const saveCallback = (): void => {
      if (this.context.ctxModeRepresentation === "pop-up") {
        if (!(this.parent instanceof CWMRegulRouter)) {
          this.parent.close();
        }
      }
      this.model.trigger("regulChanged", this.model.get("value"), "update");
    };

    if (!CWSTR.isBlank(values.get("droit"))) {
      droit = values.get("droit");
    }
    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: ""
    }));
    target.action = "refuser";
    target.id = id;
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    target.set("profil", {});
    target.get("profil").code = profil;
    target.save(null, {
      success: saveCallback,
      //error -> rien à faire ici
    });
  }

  refuserRegulCollabFromMenuButton(regul: CWGererRegularisationsModel): void {
    this.id = encodeURIComponent(regul.id);
    regul.setHabContext(this.model.getHabContext());
    this.model.set("value", regul, { silent: true });
    this.btnBar.model.trigger("btn:click", "refuser", regul.get("profil").code);
  }

  valider(callback: () => void): void {
    const target: CWGererRegularisationsModel = this.model.get("value");
    const saveCallback = (model: CWGererRegularisationsModel, response: XMLHttpRequest["response"]): void => {
      if (response.result === "ok") {
        if (callback) {
          callback();
        }
      } else if (response.oldStatus === 406 && !this._regulParPeriode && response.responseJSON.attribut === "datefin") {
        model.validationError.errors.datedeb = model.validationError.errors.datefin;
        delete model.validationError.errors.datefin;
        model.trigger("invalid", model, model.validationError, "datedeb");
      }
    };

    this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationGestion, natGest: "" });
    target.action = "validesaisie";
    target.setHabContext(this.model.getHabContext());
    target.save(null, {
      success: saveCallback,
      error: saveCallback
    });
  }

  _updateVisibiliteOrigine(): void {
    const posOrigin = this.$el.find(".originActif"); //label and combo
    const contextActif = GLOBAL_DATA.contextActif.contextActifReg;

    //La présence de cette information dans le formulaire ne sera effective que si,
    // pour le type d’événement «régul », la saisie de l’origine de la demande est autorisée.
    if (CWSTR.isBlank(contextActif) || contextActif.get("active") !== true) {
      posOrigin.hide();
    } //Il n'est pas necessaire l'action "show" parce qu'il vient de le faire(la création)
  }

  hideBtnBar(): void {
    this.btnBar.model.trigger('hide');
  }

  showBtnBar(): void {
    this.btnBar.model.trigger('show');
  }

  hideTitle(): void {
    let $layout: JQuery = null;

    if (this.context && this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass") {
      if (this.context.ctxModeRepresentation === "Imbrique" && this.parent instanceof CWMRegulRouter) {
        $layout = $(this.parent.layout.el).find(".typeRegul");
      } else {
        if (!(this.parent instanceof CWMRegulRouter)) {
          $layout = (this.parent as CWDialogPopupView).$el.parent().find(".typeRegul")
        }
      }
      $layout.addClass('d-none');
    }
  }

  showTitle(): void {
    let $layout: JQuery = null;

    if (this.context && this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass") {
      if (this.context.ctxModeRepresentation === "Imbrique" && this.parent instanceof CWMRegulRouter) {
        $layout = $(this.parent.layout.el).find(".typeRegul");
      } else {
        if (!(this.parent instanceof CWMRegulRouter)) {
          $layout = (this.parent as CWDialogPopupView).$el.parent().find(".typeRegul")
        }
      }
      $layout.removeClass('d-none');
    }
  }

  private manageModeUnselected(unSelected: boolean): void {
    const formMode: string = this.btnBar.model.get("mode");

    if (unSelected === true && formMode !== "E") {
      this.$el.find(".gererregularisation-content").hide();
      this.hideBtnBar();
      this.$el.find(".unselectedElementText").show();
      if (this.parent instanceof CWMRegulRouter && this.parent.workflow && this.parent.workflow.listerRegularisations
        && this.parent.workflow.listerRegularisations.table && this.parent.workflow.listerRegularisations.table.model
        && this.parent.workflow.listerRegularisations.table.model.coll && this.parent.workflow.listerRegularisations.table.model.coll.length === 0) {
        this.$el.find(".consulterText").hide();
      }
      else {
        this.$el.find(".consulterText").show();
      }
      this.$el.parents().find(".typeRegul.cw-titre").html(i18n.t('common:gererregularisations.regul_title'))
    } else {
      this.$el.find(".gererregularisation-content").show();
      this.showBtnBar();
      this.showTitle();
      this.$el.find(".unselectedElementText").hide();
    }
  }

  public _notifyEdition(event: JQueryEventObject): void {
    super._notifyEdition(event);
    this._initSubtitle(true);
  }

  _initModeConsultation(regul: CWGererRegularisationsModel, callback: () => void): void {
    if (this.context && this.context.ctxEcran === "mregul") {
      this.btnBar.setDroit("copy", false);
    }
    //If we are in consultation mode we shouldnt need buttons bottom side
    this.btnBar.model.set("mode", "R");
    this.btnBar.model.trigger("hide:copy");
    this.btnBar.model.trigger("hide:new");
    this.btnBar.model.trigger("enable:delete");
    regul.id = this.context.ctxGestionCollab.matricule + "," + this.context.ctxIdentifiantEvt;
    regul.action = "element";
    this.model.updateHabContext({ natGest: "M" });
    regul.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces
    }));
    regul.fetch({
      success: (fresh: CWGererRegularisationsModel): void => {
        fresh.store();
        this.model.set("value", regul); //This will trigger a _mapToForm
        this.original = fresh.clone();
        this._checkModeSilentModifyRegGest((peutModifier: boolean, infoError?: { [key: string]: any }, infoResponse?: { [key: string]: any }): void => {
          this.infoErrorModify = {
            "isModifiable": peutModifier,
            "error": infoError,
            "response": infoResponse,
            "options": {}
          };
          this._enableForm();
          this._manageWKFHisto(this.context.ctxHistoriqueWKF);
          this._renderMessageInformatif(fresh.get('motif'));
          this._paintWorkflowStatus();
          this._manageRestrictions(true);//this.isEditable);
          this._managePeriode(this, this._getMotif(this.model.get('value').get('motif').code, this.comboMotifColl));
          if (this.$el.find("button.accepter").is(":visible") === true) {
            const profilId = regul.get("matricule") + "," + regul.get("code") + "," + regul.get("table");
            const profilModel = new CWBaseModel();

            this.btnBar.model.trigger("hide:valider");
            this.btnBar.model.trigger("hide:accepter");
            this.btnBar.model.trigger("hide:refuser");
            profilModel.url = (): string => {
              return Configuration.restRoot + "/rest/regul/composant/profils/" + encodeURIComponent(profilId);
            };
            profilModel.setHabContext(this.model.getHabContext().copy());
            profilModel.updateHabContext({ foncCour: "RES_VAL_REG.G", natGest: "" });
            profilModel.fetch({
              success: () => {
                this.model.get("value").set("profils", profilModel.get("profils"));//on doit nettoyer l'anterieur information
                if (profilModel.get("profils") && profilModel.get("profils").length > 0) {
                  this.btnBar.configureValidationsButtonFromModel(this.model.get("value"));
                  this.btnBar.model.trigger("show:valider");
                  this.btnBar.model.trigger("show:accepter");
                  this.btnBar.model.trigger("show:refuser");
                } else {
                  this.btnBar.model.trigger("hide:valider");
                  this.btnBar.model.trigger("hide:accepter");
                  this.btnBar.model.trigger("hide:refuser");
                }
                if (typeof callback === "function") {
                  callback();
                }
                this._manageRestrictions(this.isEditable);//on doit vérifier ici, autre fois, les possibles modifications des boutons
              }
            });
            if (this.context && this.context.ctxEcran === "valevt") {
              this.btnBar.model.trigger('hide');
            }
          } else {
            if (typeof callback === "function") {
              callback();
            }
          }
          //Mettre toujours à la fin
          this.$el.find(".gererregularisation-form-inputs").off("focusin");
          this.$el.find(".gererregularisation-form-inputs").one("focusin", (): void => {
            this._actionPrevClickRegul();
            if (!this.desactiveHabOuEtat) {
              if (this._showMessageModifyRegGest()) {
                this._manageRestrictions(this.infoErrorModify?.isModifiable);
                this._MessageModifyRegGest();
              }
            }
          });
        });
      }
    });
  }

  _initModeAjout(regul: CWGererRegularisationsModel, callback: () => void): void {
    this.model.updateHabContext({
      foncCour: this.context.ctxHabilitation.HabilitationGestion,
      natGest: "A"
    });
    this.btnBar.model.trigger("disable:new");
    this.btnBar.model.trigger("disable:delete");
    this.btnBar.model.trigger("hide:valider");
    CWSTR.setElValue(regul, "motif.code", this.context.ctxValeursCreation.RegCodeMotif || "");
    regul.set("datedeb", this.context.ctxValeursCreation.RegDateDebut || "");
    regul.set("datefin", this.context.ctxValeursCreation.RegDateFin || "");
    regul.set("valeur", this.context.ctxValeursCreation.RegValeur || "");
    regul.set("commentaire", this.context.ctxValeursCreation.RegCommentaire || "");
    regul.set("infocomp", this.context.ctxValeursCreation.RegInfoCompl || "");
    regul.store();
    this.model.set("value", regul);
    this._manageWKFHisto(this.context.ctxHistoriqueWKF);
    this._renderMessageInformatif(this.model.get("value"));
    this._paintWorkflowStatus();
    this.btnBar.model.set("mode", "E");
    if (typeof callback === "function") {
      callback();
    }
    if (this.context.ctxSaisieMasse === true) {
      this.btnBar.$el.find(".revert").parent().attr("title", "");
      this.btnBar.$el.find(".revert").attr("title", "");
      this.btnBar.$el.find(".revert").removeClass("ui-button-icon-only").addClass("ui-button-special");
      this.btnBar.$el.find(".revert").addClass("ui-button-text-only");
      this.btnBar.$el.find(".revert span.ui-icon-arrowrefresh-1-w").remove();
      this.btnBar.model.trigger("show:save");
      this.btnBar.model.trigger("show:revert");
      this._manageRestrictions();
    } else {
      this._manageRestrictions(this.isEditable);
    }
    if (CWSTR.isBlank(this.context.ctxActionsPossibles) && CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
      this.btnBar.model.trigger("btn:click", "new");
    }
    if (this.context && (this.context.ctxEcran === "saisiescoll")) {
      this.manageModeUnselected(true);
      this.$el.css({ "overflow": "hidden" });
    }
  }

  _initDefaultMode(regul: CWGererRegularisationsModel, callback: () => void): void {
    this.btnBar.model.trigger("show:new");
    regul.store();
    this.model.set("value", regul);
    this.btnBar.model.set("mode", "C");
    this.context.ctxHistoriqueWKF = false;
    if (typeof callback === "function") {
      callback();
    }
    this._manageRestrictions();
    this._manageWKFHisto(false);
    this._renderMessageInformatif(this.model.get("value"));
    this._paintWorkflowStatus();
    this._disableForm();
  }

  _checkModeSilentModifyRegGest(callback: (result: boolean, infoError?: { [key: string]: any }, infoResponse?: { [key: string]: any }) => any): void {
    const lModel = this.model?.get("value");
    const regulId = lModel?.id;

    if (regulId) {
      if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
        const isModifiableModel = new CWGererRegularisationsGestion();

        isModifiableModel.id = regulId;
        isModifiableModel.action = "modifiable";
        isModifiableModel.setHabContext(new CWHabilitationContext({
          onglet: this.context.ctxEcran,
          foncCour: this.context.ctxHabilitation.HabilitationGestion,
          natGest: ""
        }));
        if (!CWSTR.isBlank(this.context.ctxSimulation)) {
          isModifiableModel.activeSimulation = true;
        }
        isModifiableModel.fetch({
          success: () => {
            this.isEditable = true;
            if (typeof callback === "function") {
              callback(true);
            }
          },
          error: (model?: { [key: string]: any }, response?: { [key: string]: any }) => {
            this.isEditable = false;
            if (response.status === 406 && typeof callback === "function") {
              const lError: { [key: string]: any } = {};
              const lResponse = _.clone(response);

              lError.response = _.clone(response);
              lError.responseData = _.clone(response.responseJSON);
              //après les actions précédentes de "clone", on doit changer la valeur de status et omit, pour traiter ici le message
              response.status = 200;
              response.responseText = null;
              response.omit = true;
              if (lResponse.responseJSON?.icone === "?" && lResponse.responseJSON?.boutons.length > 1) {
                lResponse.responseJSON.boutons[0].action = "CONTINUE";//l'information possible du premier bouton ne sera pas utilisé. On doit la changer pour suivre avec l'action
                lResponse.responseText = JSON.stringify(lResponse.responseJSON);
              }
              callback(false, lError, lResponse);
            }
          }
        });
      } else {
        this.isEditable = false;
        if (typeof callback === "function") {
          callback(false);
        }
      }
    } else {
      this.isEditable = true; //création
      if (typeof callback === "function") {
        callback(true);
      }
    }
    this.errorPetAntSaveDelete = false;
  }

  _showMessageModifyRegGest(): boolean {
    let rtn = false;
    const response = this.infoErrorModify?.response;

    if (this.infoErrorModify?.isModifiable === false && !_.isEmpty(response) && ((response.responseJSON?.icone === "X" && response.responseJSON?.boutons[0].action === "ABORT") || (response.responseJSON?.icone === "?" && response.responseJSON?.boutons[1].action === "ABORT"))) {
      rtn = true;
    }
    return rtn;
  }

  _MessageModifyRegGest(): void {
    if (this.infoErrorModify?.isModifiable === false && !_.isEmpty(this.infoErrorModify?.error) && !_.isEmpty(this.infoErrorModify?.response)) {
      this.infoErrorModify.options.success = (): void => {
        this.isEditable = true;
        this.infoErrorModify.isModifiable = this.isEditable;
        this._enableForm();
      };
      onError(this.infoErrorModify?.error, this.infoErrorModify?.response, this.infoErrorModify?.options);
    }
  }

  _actionPrevClickRegul(): void {
    if (this._showMessageModifyRegGest()) {
      const $posCalend = this.$el.find("input.typeDate:not([readonly])");
      const listeCombos = _.filter(this, (item: any): boolean => {
        return (item instanceof CWComboBoxView2);
      });

      //Combo's
      this.notCheckCombos = false;
      if (listeCombos && listeCombos.length > 0) {
        for (let i = 0; i < listeCombos.length; i++) {
          if (!CWSTR.isBlank(listeCombos[i].coll)) {
            listeCombos[i].stopListening(listeCombos[i].coll, "sync");
            listeCombos[i].listenToOnce(listeCombos[i].coll, "sync", (): void => {
              if (!this.notCheckCombos) {
                const $tempComboFetch = listeCombos[i].$el.find("input.c-cwComboBoxView2__input");

                this.notCheckCombos = true;
                if ($tempComboFetch.autocomplete("instance")) {
                  $tempComboFetch.autocomplete("close");
                }
              }
            });
          }
        }
      }
      //Si l'il y a d'information complémentaire, on doit rechercher tous les les listes de sélection
      if (!CWSTR.isBlank(this.infoCompAttributeName) && !_.isEmpty(this.model.get("value").get(this.infoCompAttributeName))) {
        const $posCombos = this.$el.find("input.c-cwComboBoxView2__input:not([readonly])");

        if ($posCombos && $posCombos.length > 0) {
          for (let i = 0; i < $posCombos.length; i++) {
            const $tempCombo = $($posCombos[i]);

            if ($tempCombo.autocomplete("instance") && $tempCombo.autocomplete("widget").is(":visible")) {
              $tempCombo.autocomplete("close");
            }
          }
        }
      }
      //Datepicker's
      if ($posCalend && $posCalend.length > 0) {
        for (let i = 0; i < $posCalend.length; i++) {
          $($posCalend[i]).datepicker("hide");
        }
      }
      //Boutons
      if (this.btnBar.isVisible("revert") || this.btnBar.isVisible("save")) {
        this.btnBar.model.trigger("btn:click", "revert");
      }
    }
  }

  stopMessagePrevClickRegul(): void {
    if (this.$el) {
      this.$el.find(".gererregularisation-form-inputs").off("focusin");
    }
  }

  _manageCommentaire(): void {
    if (this.workflow.wkfActiv === true) {
      //this.$el.find(".blockCommentaire").show();
      this.$el.find(".blockCommentaire").removeClass("d-none");
    } else {
      //this.$el.find(".blockCommentaire").hide();
      this.$el.find(".blockCommentaire").addClass("d-none");
    }
  }
}
