module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n\n<div class="form-group devenements-dialogPopupRow">\n  <fieldset class="devenements-dialogPopupRow cw-fieldset">\n    <div class="input-group">\n        <div class="phx-devenements-data">\n          <br>\n          <label for="commentaire" class="cw-label-entrezcomment text-left" title="'+
((__t=(i18n.t('common:listerevtgen.commentaires')))==null?'':__t)+
'">'+
((__t=(UTILS.getSVGIcon('bulle','',16)))==null?'':__t)+
''+
((__t=(i18n.t("common:listerevtgen.commentaires")))==null?'':__t)+
'</label>\n          <textarea class="commentaire form-control" rows="6" cols="40" maxlength="200" style="resize:none"></textarea>\n          <br>\n          <span class="devenements-wkfhisto"></span>\n          <span class="buttons"></span>\n        </div>\n    </div>\n  </fieldset>\n</div>\n';
}
return __p;
};
