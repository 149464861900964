module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="phx-menu ui-corner-all phx-menu-hover" data-value="'+
((__t=(index))==null?'':__t)+
'">\n    <span class="phx-menu-text">'+
((__t=(nom))==null?'':__t)+
'</span>\n    <span tabindex="0" class="phx-menu-icon">\n        '+
((__t=(UTILS.getSVGIcon('fleche_bas','cw-menu-arrow ml-2', 12)))==null?'':__t)+
'\n    </span>\n    <div class="ui-menu ui-corner-all userMenu c-panneauMenu">\n        <ul></ul>\n    </div>\n</div>\n\n';
}
return __p;
};
