import { CWGererRegularisationView } from 'common/evenements/gerer/gererregularisations/views/cwGererRegularisation.view';
import { CWListeDetailPanelView } from 'core/layouts/cwListeDetailPanel.view';
import { CWListerRegularisationColl } from 'common/evenements/lister/listerregularisations/models/cwListerRegularisation.collection';
import { CWListerRegularisationView } from 'common/evenements/lister/listerregularisations/views/cwListerRegularisation.view';
import { CWMregulWorkflow } from './models/cwMregul.workflow';
import { CWSTR } from 'src/utils/cwStr';
import { CWSubRoute } from 'core/routers/cwSubRoute';
import { i18n } from 'src/i18n';
import { objs } from 'src/objectsRepository';

/* Configuration of the Use Case Gestion des regularisation
 * @see Redmine #4241
 */

/**
 * Router for the use case "RegulCollab"
 */
export class CWMRegulRouter extends CWSubRoute {


  matricule: string | number;
  workflow: CWMregulWorkflow;
  layout: CWListeDetailPanelView;
  isCalledFromBrique: boolean;

  constructor(prefix?: any, option?: any) {
    CWMRegulRouter.prototype.routes = (): { [key: string]: any } => {
      return {
        "mregul": "list",
        "mregul/new": "newMRegul",
        "mregul/:id": "details",
        "mregul/bq": "callFromBrique"
      };
    };
    super(prefix, option);
    // Initializes the main parts of the UI and its workflow
    // get matricule to use
    this.matricule = objs.appRt.workflow.authModel.get("matricule");
    this.workflow = new CWMregulWorkflow({ matricule: this.matricule, module: this.module });

    this.layout = new CWListeDetailPanelView({ position: 57, defaultOpeningSize: "50/50" });
  }

  list(): void {
    // If it's the first time the user enter the UC, we will present it with
    // an initial status, if not, we'll navigate to the current status of
    // the screen
    const firstTime = (this.workflow.get("ready") === false);
    //0 - clean screen and initializes the layout
    this._initLayoutUC(function () {
      if (firstTime) {
        // ...
      }
    });
  }

  details(): void {
    const firstTime = (this.workflow.get("ready") === false);
    //0 - clean screen and initializes the layout
    this._initLayoutUC(function () {
      if (firstTime) {
        // ...
      }
    });
  }

  newMRegul(): void {
    this._initLayoutUC();
  }

  _hideGererRegularisations(): void {
    if (!CWSTR.isBlank(this.workflow.gererRegularisations)) {
      this.layout.$el.find(".gererregularisation-form-inputs").hide();
      this.layout.trigger("manageUnselected", false); //<- TODO Check if "layout" is correct (this.layout.$el.find(".unselectedElementText").show();)

      this.layout.trigger("manageUnselected", false);
    }
  }

  _initLayoutUC(callback?: () => void): void {
    if (this.workflow.get("ready") === true) {
      if (callback) {
        callback();
      }
    } else {
      let gererRegularisations: CWGererRegularisationView = null;
      let listerRegularisations: CWListerRegularisationView = null;
      const optionsListerregularisations: any = {// listerregularisations
        context: {
          ctxUtilisateur: "Collaborateur",
          ctxHabilitation: {
            HabilitationAcces: "COL_REG.V",
            HabilitationGestion: "COL_REG.G",
          },
          ctxEcran: "mregul",
          ctxCollab: this.matricule,
          ctxGestionCollab: {
            matricule: this.matricule,
          },
          ctxModeRepresentation: "Simple", //Detaille or Simple
          //ctxPeriode: null,
          ctxStatut: "D,I,H,R,A",
          ctxActionsPossibles: ["Creer"],
          ctxModeInitialisation: ""
        },
        workflow: this.workflow,
        parent: this,
        isForResponsable: false
      };
      const localSetup = (): void => {
        this.workflow.setUp((): void => {
          $("#" + this.module).empty();
          //When the workflow is ready, we can render the view without concerns
          $("#" + this.module).append(this.layout.el);
          this.layout.render();
          listerRegularisations.render();
          $(".l-panelA-entete", this.layout.el).html(i18n.t('common:listerabsencesouhait.lefttitle'));
          $(".l-panelA-filter", this.layout.el).html(listerRegularisations.getfilter().el);
          $(".l-panelA-liste", this.layout.el).html(listerRegularisations.getGrid().el);
          this.layout.autoRedistributeButtons(gererRegularisations.getButtonBarRef());
          $(".l-panelB-content", this.layout.el).html(gererRegularisations.render().el);
          this.listenTo(gererRegularisations.model, "form:edited", () => {
            listerRegularisations.trigger("showedRegEdited", true);
          });
          this.listenTo(gererRegularisations.model, "editionEnd", () => {
            listerRegularisations.trigger("showedRegEdited", false);
          });
          //Display title of absence on the layout
          $(".l-panelB-entete", this.layout.el).append($('<span>').addClass('typeRegul cw-titre'));
          $(".l-panelB-entete", this.layout.el).addClass('d-flex justify-content-between');
          this._hideGererRegularisations();
          if (callback) {
            callback();
          }
          this.trigger("uc:ready");
        });
      };
      let optionsGererregularisation: { [key: string]: any } = null;

      if (this.isCalledFromBrique === true) {
        optionsListerregularisations.context.statutInitial = "D,T,I";
        optionsListerregularisations.context.statusEtiquette = { "accepte": "inactif", "demande": "actif", "refuse": "inactif" };
      }
      listerRegularisations = new CWListerRegularisationView(optionsListerregularisations);
      (listerRegularisations.table.model.coll as CWListerRegularisationColl).backupStatuts = optionsListerregularisations.context.ctxStatut; //backup for one exceptional case
      this.workflow.listerRegularisations = listerRegularisations;
      // gererregularisations
      optionsGererregularisation = optionsListerregularisations;
      optionsGererregularisation.context.ctxModeRepresentation = 'Imbrique';//cette information est partagée avec optionsListerregularisations et elle sera utilisée
      optionsGererregularisation.context.ctxModeInitialisation = "";
      gererRegularisations = new CWGererRegularisationView(optionsGererregularisation);
      this.workflow.gererRegularisations = gererRegularisations;
      this.workflow.on("hideGererRegularisations", this._hideGererRegularisations, this);
      if (this.workflow.gererRegularisations.workflow.wkfRecupere !== true) {
        this.listenTo(gererRegularisations.workflow, "initWorkflowActif", (etat: boolean): void => {
          this.workflow.wkfActiv = etat;
          listerRegularisations.workflow.wkfActiv = etat;
          listerRegularisations.workflow.wkfRecupere = true;
          localSetup();
        });
      } else {
        this.workflow.wkfActiv = this.workflow.gererRegularisations.workflow.wkfActiv;
        listerRegularisations.workflow.wkfActiv = this.workflow.wkfActiv;
        listerRegularisations.workflow.wkfRecupere = true;
        localSetup();
      }
    }
  }

  callFromBrique(): void {
    this.isCalledFromBrique = true;
    this._initLayoutUC();
  }
}
