import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLListerevenementsRowPopup from '../rowPopup.tpl.html';
import { CWAccepterEvtGen } from '../models/cwAccepterEvtGen.model';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWDialogView } from 'src/core/components/dialog/cwDialog.view';
import { CWDummyEvtGen } from '../models/cwDummyEvtGen.model';
import { CWEditableGridView } from 'src/core/grids/editablegrid/cwEditableGrid.view';
import { CWformListerEvetGenWorkFlow } from '../models/cwFormListerEvetGenWorkFlow.model';
import { CWFORMS } from 'src/utils/cwForms';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWRefuserEvtGen } from '../models/cwRefuserEvtGen';
import { CWSTR } from 'src/utils';
import { CWWkflhistoView } from 'src/uc/common/wkfhisto/views/cwWkfhisto.view';
import { i18n } from 'src/i18n';
import { UTILS } from 'utils/utils.js';


export class CWListerEvtRowPopupView extends CWBaseFormView<CWBaseModel> {

  private refEvenement: string;
  private demandeId: string;
  private action: string;
  private workflow: CWformListerEvetGenWorkFlow;
  private commentaire: string;
  private parent: CWDialogView;
  public table: CWEditableGridView;
  private evtEtat: string;


  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options ? options : {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change",
      "keyup :input:not([readonly])": "_enableButtons",
      "click button": "_clickListener"
    }, options.events);
    super(options);
    this.template = TPLListerevenementsRowPopup;

    this.model = new CWBaseModel({
      value: null
    });
    this.refEvenement = options.refEvenement;
    this.evtEtat = options.evtEtat;
    this.demandeId = options.demandeId;
    if (options.action) {
      this.action = options.action;
      if (this.action === "A") {
        this.model.set("value", new CWAccepterEvtGen());
      } else if (this.action === "R") {
        this.model.set("value", new CWRefuserEvtGen());
      } else if (this.action === "C") {
        if (this.evtEtat === "R") {
          this.model.set("value", new CWRefuserEvtGen());
        } else {
          this.model.set("value", new CWAccepterEvtGen());
        }
      }
    } else {
      this.model.set("value", new CWDummyEvtGen());
    }
    this.workflow = options.workflow;
    this.model.get("value").on("change:commentaire", this._mapToForm, this);
    this.commentaire = CWSTR.isBlank(options.commentaire) ? "" : options.commentaire;
    if (options.parent) {
      this.parent = options.parent;
      this.parent.model.set("isCommentChanged", false);
    }
    this.listenTo(this, "btnResult", this._clickListener);
  }

  render(): CWListerEvtRowPopupView {
    const json = { i18n: i18n, UTILS: UTILS };

    this.$el.append(this.template(json));
    // Render buttons
    $(this.el).find(".btnValider").button();
    //Initialize the comment textarea with the previous comment.
    CWSTR.setElValue(this.model.get("value"), "commentaire", this.commentaire);
    //Make the comment mandatory if is for refuse an absence
    if (this.action === "R" || this.evtEtat === "R") {
      $(this.el).find("fieldset.devenements-dialogPopupRow label[for=commentaire], fieldset.devenements-dialogPopupRow label[for^=commentaire_uid_]").addClass("cw-required");
      $(this.el).find(".phx-dabsences-data .cw-label-entrezcomment").addClass("cw-required");
    }
    //Disable the buttons
    $(this.el).find("fieldset.devenements-dialogPopupRow button").button({ disabled: true });
    $(this.el).find(".btnAnnuler").button({ disabled: false });
    this._manageWKFHisto();
    return this;
  }

  _manageWKFHisto(): void {
    const contextComponentWkf = {
      ctxEcran: "valevt",
      ctxHabilitation: "RES_VAL_EGEN.G",
      ctxTypeEvenement: "EVTGEN",
      ctxRefEvenement: this.refEvenement,
      ctxDemId: this.demandeId,
      ctxModeRestitution: "Imbrique",
      ctxActivModeSynth: true,
      ctxModeDefaut: "Replie",
      ctxModeRech: "F"
    };
    const composantWorkflow = new CWWkflhistoView();

    $(this.el).find(".devenements-wkfhisto").html(composantWorkflow.render().el);
    composantWorkflow.setContext(contextComponentWkf);
  }

  _change(event: JQueryEventObject): void {
    super._change.call(this, event);
    // Clear current validation erros
    this._cleanValidationErrors();
  }

  _enableButtons(): void {
    $(this.el).find("fieldset.devenements-dialogPopupRow button").button({ disabled: false });
  }

  _mapToForm(): void {
    const model = this.model.get("value");

    if (model !== null) {
      const fieldset = this.$el.find("fieldset");

      model.setHabContext(this.model.getHabContext());
      this._mapModelToForm(fieldset, model, null);
    }
  }

  _panelStateIsReadonly(mod: boolean): boolean {
    let isReadOnly = false;

    if (mod) {
      const canView = CWHABILITATION.canView("RES_VAL_EGEN.G");

      isReadOnly = !canView;
    }
    return isReadOnly;
  }

  _panelStateRender(mod: JQuery): void {
    const isReadonly = this._panelStateIsReadonly(mod ? true : false);

    CWFORMS.setFormReadonly(this.$el, isReadonly, true);
  }

  _panelStateRenderCustomFields(isReadOnly: boolean): void {
    this.table.enableEdition(!isReadOnly);
  }

  _clickListener(action: string): void {
    const model = this.model.get("value");
    const commentaire = CWSTR.getElValue(model, "commentaire");

    switch (action) {
      case "btnValider":
        // Error management
        model.off("invalid");
        model.on("invalid", this._showValidationErrors, this);
        if (model.isValid()) {
          this.parent.model.set("commentaire", commentaire);
          this.parent.model.set("isCommentChanged", true);
          this.parent.close();
        }
        break;
      case "btnRevert":
        this.parent.close();
        break;
      default:
      //Nothing
    }
  }
}