import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWSplitPanelView } from 'core/layouts/cwSplitPanel.view';
import { CWSTR } from 'src/utils/cwStr';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';

export class CWDialogView<TViewRendered extends Backbone.View = Backbone.View> extends Backbone.View<Backbone.Model> {
  maxHeight: number | string;
  maxWidth: number;
  dialog: JQuery;
  dialogTemplate: string;
  dialogClass: string;
  height: number | string;
  width: number | string;
  minHeight: number;
  minWidth: number | undefined;
  data: { [key: string]: any };
  position: { [key: string]: any };
  title: string;
  isTitleHTML: boolean;
  view: Backbone.View<Backbone.Model> | any;
  viewData: Backbone.ViewOptions<Backbone.Model> | any;
  isExternalView: boolean;
  modal: boolean;
  appendTo: JQuery;
  annuled: boolean;
  internalView: TViewRendered;
  buttons: JQuery;
  fixedButtons: boolean;
  callback: (argument?: any) => any;
  draggable: boolean;
  resizable: boolean;
  timeout: number;
  flagTableResized: boolean;
  classObjectResizable: string;

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.className = options.className || "phx-Dialog";
    options.model = options.model || new Backbone.Model();
    options.events = _.extend({
      "dialogbeforeclose": "_handleEvents",
      "dialogcreate": "_handleEvents",
      "dialogopen": "_handleEvents",
      "dialogfocus": "_handleEvents",
      "dialogdragStart": "_handleEvents",
      "dialogdrag": "_handleEvents",
      "dialogdragstop": "_handleEvents",
      "dialogresizeStart": "_handleEvents",
      "dialogresize": "_handleEvents",
      "dialogresizeStop": "_handleEvents",
      "dialogclose": "_handleEvents"
    }, options.events);
    super(options);
    this.dialog = null;
    this.dialogTemplate = "";
    this.dialogClass = "cw-dialog cw-style";
    this.height = 400;
    this.width = 300;
    this.minHeight = 150;
    this.minWidth = 150;
    this.title = "";
    this.timeout = 6000; // millis
    this.data = {};
    this.position = {};
    this.fixedButtons = false;
    this.annuled = false;
    this.modal = true;
    this.internalView = null;
    this.flagTableResized = false;
    this.classObjectResizable = "";
    if (!CWSTR.isBlank(options)) {
      if (options.view) {
        this.view = options.view;
        if (!CWSTR.isBlank(this.view.cid)) {
          this.isExternalView = true;
        }
      }
      if (!CWSTR.isBlank(options.title)) {
        this.title = options.title;
      }
      if (!CWSTR.isBlank(options.isTitleHTML)) {
        this.isTitleHTML = options.isTitleHTML;
      }
      if (!CWSTR.isBlank(options.dialogClass)) {
        this.dialogClass += " " + options.dialogClass;
      }
      if (_.isBoolean(options.resizable)) {
        this.resizable = options.resizable;
      }
      if (_.isBoolean(options.draggable)) {
        this.draggable = options.draggable;
      }
      if (!CWSTR.isBlank(options.position)) {
        this.position = options.position;
      }
      if (!CWSTR.isBlank(options.maxHeight)) {
        this.maxHeight = options.maxHeight;
      }
      if (!CWSTR.isBlank(options.maxWidth)) {
        this.maxWidth = options.maxWidth;
      }
      if (!CWSTR.isBlank(options.minHeight)) {
        this.minHeight = options.minHeight;
      }
      if (!CWSTR.isBlank(options.minWidth)) {
        this.minWidth = options.minWidth;
      }
      if (!CWSTR.isBlank(options.height)) {
        this.height = options.height;
      }
      if (!CWSTR.isBlank(options.width)) {
        this.width = options.width;
      }
      if (_.isBoolean(options.modal)) {
        this.modal = options.modal;
      }
      if (_.isBoolean(options.fixedButtons)) {
        this.fixedButtons = options.fixedButtons;
      }
      if (!CWSTR.isBlank(options.buttons)) {
        this.setButtons(options.buttons);
      }
      if (options.viewData) {
        this.viewData = options.viewData;

        if (_.isBoolean(options.viewData.modal)) {
          this.modal = options.viewData.modal;
        } else {
          this.modal = (options.modal === false) ? false : true;
        }
        if (!CWSTR.isBlank(options.viewData.appendTo)) {
          this.appendTo = options.viewData.appendTo;
        }
      }
      if (!CWSTR.isBlank(options.classObjetResizable)) {
        this.classObjectResizable = options.classObjetResizable;
      }
    }
    this.listenTo(this.model, "dlg:open", this.open);
    this.listenTo(this.model, "dlg:close", this.close);
    this.listenTo(this.model, "dlg:resize", this.resize);
    this.listenTo(this.model, "dlg:reposition", this.reposition);
  }

  setAnnuled(annuled: boolean): void {
    this.annuled = annuled;
  }

  getAnnuled(): boolean {
    return this.annuled;
  }

  setWidth(width: number | string): void {
    this.width = width;
  }

  setHeight(height: number | string): void {
    this.height = height;
  }

  setMinWidth(width: number | undefined): void {
    this.minWidth = width;
  }

  setMinHeight(height: number): void {
    this.minHeight = height;
  }

  setPosition(position: { [key: string]: any }, key?: string): void {
    this.position = position;
    if (this.dialog && position) {
      this._setPosition(position);
    }
    if (!CWSTR.isBlank(key) && this.internalView && this.internalView.model) {
      this.internalView.listenTo(this.internalView.model, key, () => {
        this._setPosition(this.position);
      });
    }
  }

  setButtons(optionValue: any, customClass?: string): void {

    const buttons = customClass ? $("<div>").addClass(customClass) : $("<div>").addClass("cwDialog-buttons");

    if (!CWSTR.isBlank(optionValue)) {
      optionValue.forEach((opt: any) => {
        const btn = $("<button type='button'>").text(opt.text);
        const icon: JQuery = opt.icon;
        btn.addClass(opt.btnClass).attr('value', '').append(icon);
        btn.click(() => {
          opt.click();
        });
        buttons.append(btn);
      });
    }

    this.buttons = buttons;
  }

  hideCloseButton(): void {
    this.dialogClass += " no-close";
  }

  render(): CWDialogView {
    this.dialog = $(this.el).dialog({
      modal: this.modal,
      height: this.height,
      width: this.width,
      minHeight: this.minHeight,
      minWidth: this.minWidth,
      maxHeight: (this.maxHeight as any),
      maxWidth: this.maxWidth,
      position: this.position,
      appendTo: this.appendTo as any,
      closeText: i18n.t('common:close'),
      dialogClass: this.dialogClass,
      closeOnEscape: false,
      create: (): void => {
        const $parent = this.$el.parent();
        // Fix jQuery UI issue. When width is auto, minWidth is not applied.
        if (!CWSTR.isBlank(this.minWidth) && this.width === "auto") {
          $parent.css("minWidth", this.minWidth);
        }
        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");

        // Update title manually on creation:
        // this way if the title has HTML it's properly parsed.
        if (this.isTitleHTML) {
          $parent.find(".ui-dialog-title").html(this.title);
        } else {
          $parent.find(".ui-dialog-title").text(this.title);
        }
      },
      resizable: this.resizable,
      draggable: this.draggable
    });
    if (!CWSTR.isBlank(this.appendTo)) {
      this.dialog.dialog("widget").draggable("option", "containment", this.appendTo);
    }
    return this;
  }

  resize(): void {
    // Iterate all proto-resizables and change its height.
    const components = $(".phx-proto-resizable", this.el);
    const height = $(this.el).height();
    const dialogOffsetTop = $(this.el).offset().top;

    _.each(components, (component: HTMLElement) => {
      let offsetTop = $(component).offset().top - dialogOffsetTop;
      // IF is a panel B4 adjust the size of the scroll
      if ($(component).hasClass("phx-panelB4")) {
        offsetTop += CWSplitPanelView.SPLIT_BORDER_WIDTH;
      }
      if (offsetTop > 0) {
        $(component).height(height - offsetTop);
      }
    }, this);
    //CUSTOMER 170849
    if (!CWSTR.isBlank(objs.appRt)) {
      objs.appRt.workflow.trigger("resize");
    }
  }

  reposition(): void {
    const isVisible = (this.dialog) ? this.dialog.is(":visible") : false;

    if (isVisible && this.dialog && this.$el.dialog("instance")) {
      const position = this.dialog.dialog("option", "position");

      this._resizeTableOrObject();
      this.dialog.dialog("option", "position", position);
    }
  }

  fitParent(): void {
    if (this.appendTo) {
      const containerHeight = this.appendTo.outerHeight();
      const dialogHeight = this.dialog.outerHeight();

      if (dialogHeight > (containerHeight - 110)) {
        this.dialog.height(containerHeight - 110);
      }
    }
  }

  open(callback?: (argument?: any) => any): void {
    // If the view already exist remove it
    this._removeInternalView();
    // Render the dialog only if it not exist.
    if (this.dialog && this.dialog.dialog("instance")) {
      this.dialog.dialog("option", "closeOnEscape", false);
      this.dialog.dialog("open");
    } else {
      this.render();
    }
    this.dialog.dialog("option", "closeOnEscape", false);
    this.dialog.dialog("widget").off("keydown");
    this.dialog.dialog("widget").on("keydown", (event: any): void => {
      if (this.dialog.dialog("option", "closeOnEscape") && !event.isDefaultPrevented() && event.keyCode && event.keyCode === $.ui.keyCode.ESCAPE) {
        event.preventDefault();
        if (this.internalView) {
          this.internalView.trigger("beforse:closeView");
          setTimeout(() => {
            this.close();
          }, 1200);
        } else {
          this.close();
        }
      }
    });
    //add callback
    this.callback = callback;
    // render new view
    if (this.view) {
      if (this.isExternalView) {
        this.internalView = this.view;
      } else {
        let args = _.extend({ parent: this }, this.viewData);
        this.internalView = new this.view(args);
        args = null;
      }
      this.listenTo(this.internalView, "all", this._eventsBB);
      if (this.fixedButtons) {
        const $container = $("<div>").addClass("c-form--fixedButtons");
        const $scrollZone = $("<div>").addClass("c-form__scrollZone");
        $scrollZone.append(this.internalView.el);
        $container.append($scrollZone);
        $container.append(this.buttons);
        this.$el.empty().append($container);
      } else {
        this.$el.html(this.internalView.el);
        this.$el.append(this.buttons);
      }
      this.internalView.render();
      this.internalView.delegateEvents();
    } else {
      this.$el.append(this.buttons);
    }

    // JQuery UI Dialog doesn't accept percentages
    const $container = !CWSTR.isBlank(this.appendTo) ? $(this.appendTo) : $(window);
    const containerHeight = $container.height();

    if (typeof this.maxHeight === "string" && this.maxHeight.indexOf("%") > -1) {
      const factor = parseFloat(this.maxHeight.replace("%", "")) * 0.01; // 0.01-> idem diviser par 100 mais beaucoup plus rapide

      if (!$container.hasClass("phx-portlet") || containerHeight > 130) {//pour corriger des problèmes avec les briques
        this.dialog.css("max-height", factor * containerHeight);
      }
    } else if (typeof this.maxHeight === "number" && this.height !== "auto") {
      // This fixes JQuery UI Dialog bug when using height and maxHeight
      this.dialog.parent().css("max-height", this.maxHeight);
    }
    if (typeof this.height === "string") {
      if (this.height.indexOf("%") > -1) {
        const factor = parseInt(this.height.replace("%", "")) * 0.01;// 0.01-> idem diviser par 100 mais beaucoup plus rapide

        this.dialog.css("height", factor * containerHeight);
      }
      // Fixes "vh" unit issue with JQuery
      if (this.height.indexOf("vh") > -1) {
        this.dialog.css("height", this.height);
      }
    }
    if (this.dialog.dialog("instance")) {
      this.dialog.dialog("option", "closeOnEscape", true);
    }
  }

  _eventsBB(event?: any, value?: any): void { //eslint-disable-line
    //empty function
  }

  _removeInternalView(): void {
    if (!this.isExternalView && this.internalView) {
      this.internalView.trigger("manageClosing");
      this.stopListening(this.internalView);
      this.internalView.stopListening();
      this.internalView.$el.empty();
      this.internalView.remove();
      delete this.internalView.$el;
      delete this.internalView.el;
      this.internalView = null;
    }
  }

  _close(): void {
    if (!this.isExternalView) {
      this._removeInternalView();
      delete this.internalView;
    }
    //call callback
    if (this.callback) {
      this.callback();
    }
  }

  close(): void {
    if (this.$el && this.$el.dialog("instance")) {
      this.dialog.dialog("close");
    }
  }

  isOpen(): boolean {
    if (CWSTR.isBlank(this.dialog)) {
      return false;
    } else {
      return this.dialog.dialog("isOpen");
    }
  }

  _stopFocus(event: JQuery.TriggeredEvent, ui: any): void { //eslint-disable-line
    //    	event.preventDefault();
    //    	event.stopPropagation();
  }

  _handleEvents(event: JQuery.TriggeredEvent, ui: any): void {
    if (event.type === "dialogclose") {
      this.model.trigger("dlg:close", event, ui);
      this._close();
    }
    if (event.type === "dialogresize") {
      this.model.trigger("dlg:resize", event, ui);
    }
    if (event.type === "dialogdrag") {
      this.model.trigger("dlg:drag", event, ui);
    }
    if (event.type === "dialogdragstop") {
      // Fix: after drop, height "auto" is removed
      if (this.height === "auto") {
        $(event.target).parent(".ui-dialog").height("auto");
      }
      this.model.trigger("dlg:dragstop", event, ui);
    }
    if (event.type === "dialogfocus") {
      this._stopFocus(event, ui);
    }
    if (event.type === "dialogbeforeclose") {
      this.model.trigger("dlg:beforeclose", event, ui);
      this._removeInternalView();
    }
    if (event.type === "dialogopen") {
      //on désactive les tooltips pendants et on l'active donc il doit fonctionner à nouveau
      _.each($(document).find("[aria-describedby]"), (element: HTMLElement): void => {
        if (element && $(element).tooltip("instance")) {
          $(element).tooltip("disable");
          $(element).tooltip("enable");
        }
      });
    }
    event.stopPropagation();
  }

  remove(): Backbone.View<Backbone.Model> {
    $(this.dialog).parent().off();
    //$(this.dialog).dialog("destroy");
    return Backbone.View.prototype.remove.call(this);
  }

  updateTitle(title: string): void {
    this._setTitle(title);
  }

  /**
   * Set the title to the dialog
   */
  _setTitle(title: string): void {
    if (this.dialog) {
      if (this.isTitleHTML) {
        $(this.dialog).parent().find(".ui-dialog-title").html(title);
      } else {
        $(this.dialog).parent().find(".ui-dialog-title").text(title);
      }
    } else {
      this.title = title;
    }
  }

  /**
   * Set the title to the dialog
   */
  _setPosition(position: { [key: string]: any }): void {
    if (this.dialog && this.dialog.dialog("instance")) {
      this.dialog.dialog("option", "position", position);
    }
  }

  setOption(option: string, valeur: string | number | boolean | { [key: string]: any }): void {
    if (this.$el && this.$el.dialog("instance")) {
      this.$el.dialog("option", option, valeur);
    }
  }

  getInternalViewObject(): Backbone.View {
    return this.internalView;
  }

  _resizeTableOrObject(): void {
    let lHeightView = null;
    const lHeightPopup = (this.$el) ? this.$el.height() : null;
    const lMinHeight = 50;

    if (!CWSTR.isBlank(lHeightPopup)) {
      let lDiff = null;

      if (this.internalView && this.internalView.$el) {
        lHeightView = this.internalView.$el.outerHeight();
      }
      if (!CWSTR.isBlank(lHeightView)) {
        if (this.flagTableResized === false) {
          lDiff = lHeightView - lHeightPopup;
          if (lDiff > 0) {
            if (CWSTR.isBlank(this.classObjectResizable)) {
              const lPosTable = this.$el.find("table.c-grind__table");

              if (lPosTable.length > 0) {
                const lIncrem = Math.floor(lDiff / lPosTable.length);

                for (let i = 0; i < lPosTable.length; i++) {
                  const lPosParentTable = $(lPosTable[i]).parents(".cwDataGrid,.cwMenuGrid");
                  let lHeightTable = null;
                  let lNewHeightTable = null;

                  if (lPosParentTable.length !== 1) {
                    break;
                  }
                  lHeightTable = lPosParentTable.height();
                  lNewHeightTable = (lHeightTable - lIncrem) >= 0 ? (lHeightTable - lIncrem) : 0;
                  if (lHeightTable > 0 && lNewHeightTable < lMinHeight) {
                    lNewHeightTable = lMinHeight;
                  }
                  lPosParentTable.css({ "height": lNewHeightTable + "px", "overflow-y": "auto" }).data("heightAnt", lHeightTable);
                  if (this.flagTableResized === false) {
                    this.flagTableResized = true;
                  }
                }
              }
            } else {
              const lPosObject = this.$el.find("." + this.classObjectResizable.replace(/ /g, " ."));//replaces blank spaces with blanks and a dot

              if (lPosObject.length > 0) {
                const lIncrem = Math.floor(lDiff / lPosObject.length);

                for (let i = 0; i < lPosObject.length; i++) {
                  const lPosElement = $(lPosObject[i]);
                  let lHeightTable = null;
                  let lNewHeightTable = null;

                  lHeightTable = lPosElement.height();
                  lNewHeightTable = (lHeightTable - lIncrem) >= 0 ? (lHeightTable - lIncrem) : 0;
                  if (lHeightTable > 0 && lNewHeightTable < lMinHeight) {
                    lNewHeightTable = lMinHeight;
                  }
                  lPosElement.css({ "height": lNewHeightTable + "px", "overflow-y": "auto" }).data("heightAnt", lHeightTable);
                  if (this.flagTableResized === false) {
                    this.flagTableResized = true;
                  }
                }
              }
            }
          }
        } else {
          if (CWSTR.isBlank(this.classObjectResizable)) {
            const lPosTable = this.$el.find("table.c-grind__table");

            if (lPosTable.length > 0) {
              for (let i = 0; i < lPosTable.length; i++) {
                const lPosParentTable = $(lPosTable[i]).parents(".cwDataGrid");
                let lHeightTable = null;

                if (lPosParentTable.length !== 1) {
                  break;
                }
                lHeightTable = lPosParentTable.data("heightAnt");
                if (!CWSTR.isBlank(lHeightTable) && lHeightTable >= 0) {
                  lPosParentTable.css({ "height": lHeightTable + "px", "overflow-y": "auto" }).data("heightAnt", null);
                }
              }
            }
          } else {
            const lPosObject = this.$el.find("." + this.classObjectResizable.replace(/ /g, " ."));//replaces blank spaces with blanks and a dot

            if (lPosObject.length > 0) {
              for (let i = 0; i < lPosObject.length; i++) {
                const lPosElement = $(lPosObject[i]);
                let lHeightTable = null;

                lHeightTable = lPosElement.data("heightAnt");
                lPosElement.css({ "height": lHeightTable + "px", "overflow-y": "auto" }).data("heightAnt", null);
              }
            }
          }
          this.flagTableResized = false;
        }
      }
    }
  }

  getTitle(): string {
    return this.title;
  }

  getValueDraggable(): boolean {
    return this.draggable;
  }

  setValueDraggable(newVal: boolean): void {
    if (this.dialog && this.dialog.dialog("instance") && _.isBoolean(newVal)) {
      this.draggable = newVal;
      this.dialog.dialog("option", "draggable", this.draggable);
    }
  }
}
