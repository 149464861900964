module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="phx-populations-menu">\n    <div class="phx-menu phx-menu-hover d-flex align-items-center" tabindex="0">\n        <span class="phx-population-menu-header cw-texte-reduit">'+
((__t=(i18n.t("populationsmenu.tous")))==null?'':__t)+
'</span>\n        '+
((__t=(UTILS.getSVGIcon('fleche_bas','cw-menu-arrow', 12)))==null?'':__t)+
'\n    </div>\n    <ul class="phx-populations-menu-list c-panneauMenu ">\n        <span class="generated-list-elements-container"></span>\n        ';
if(habilitation_gerer){ 
__p+='\n        <li class=\'phx-menu-link phx-hover separator-affich ui-menu-divider\'></li>\n        <li class=\'ui-menu-item phx-populations-menu-select-collab phx-menu-link phx-hover \'>\n            <a href="javascript:void(0)" tabindex="-1" class=\'select-collab\' style=\'cursor:pointer\'>\n                '+
((__t=(i18n.t("populationsmenu.select_collab")))==null?'':__t)+
'...</a>\n        </li>\n        <span class="structure-elements-container"></span>\n        <li class=\'phx-menu-link phx-hover separator-affich ui-menu-divider\'></li>\n        <a href=\'#z/resp/uc/populations\' tabindex="-1" class=\'\'>\n            <li class=\'ui-menu-item phx-menu-link phx-hover \'>\n                '+
((__t=(i18n.t("populationsmenu.accessPopulations")))==null?'':__t)+
'\n            </li>\n        </a>\n        <a href=\'#z/resp/uc/populations/new\' tabindex="-1" class=\'ewf\' target=\'\'>\n            <li class=\'ui-menu-item phx-menu-link phx-hover  create-population-opt\'>\n                '+
((__t=(i18n.t("populationsmenu.create")))==null?'':__t)+
'...\n            </li>\n        </a>\n        ';
 } 
__p+='\n    </ul>\n</div>';
}
return __p;
};
