import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWActiviteDefaults } from '../models/cwActiviteDefaults.model';
import { CWActiviteDetailModel } from '../models/cwActiviteDetail.model';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWButtonBarMenu } from 'core/controls/cwButtonBar.view';
import { CWButtonBarValidationView } from 'core/controls/cwButtonBarValidation.view';
import { CWCalculHorairesActiviteCollab } from '../models/cwCalculHorairesActiviteCollab.model';
import { CWCaracteristiquesForm } from 'core/components/caracteristiques/cwCaracteristiquesForm.view';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWDIVERS } from 'src/utils';
import { CWEtatFinalRecapActModel } from '../models/cwEtatFinalRecapAct.model';
import { CWEtiquette, validEtiquetteTypes } from 'core/components/etiquette/cwEtiquette.view';
import { CWFORMS } from 'utils/cwForms';
import { CWGererActivitesDeplacementModel } from '../models/cwGererActivitesDeplacement.model';
import { CWGererActivitesGestionModel } from '../models/cwGererActivitesGestion.model';
import { CWGererActivitesModel } from '../models/cwGererActivites.model';
import { CWGereractivitesWorkflow } from '../models/cwGereractivites.workflow';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWLICENCE } from 'utils/cwLicence';
import { CWModeSaisieOptionsColl } from '../models/cwModeSaisieOptions.collection';
import { CWMSGS } from 'utils/cwMsgs';
import { CWMultiLabel } from 'core/views/cwMultiLabel';
import { CWPanneauDeroulant } from 'core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWPieceJointes } from 'common/piecesjointes/views/cwPieceJointes.view';
import { CWPretsRefreshPlanningDialog } from 'mon_equipe/planning/planmed/views_popups/cwPretsRefreshPlanningDialog';
import { CWPretsRefreshPlanningDialog as CWPretsRefreshPlanrespgDialog } from 'mon_equipe/planning/planresp/views_popups/cwPretsRefreshPlanningDialog';
import { CWSelectCollabPopulationView } from 'common/referentiel/referentielcollab/views/cwSelectCollabPopulation.view';
import { CWSelecteurActivitesView } from 'common/selecteuractivites/views/cwSelecteurActivites.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { CWTypeGestionModel } from 'mon_compte/consulter/fichecollab/models/cwTypeGestion.model';
import { CWTypeSaisieOptionsColl } from '../models/cwTypeSaisieOptions.collection';
import { CWTypoDomCol } from 'common/typodom/models/cwTypoDom.collection';
import { CWUniteComboColl } from '../models/cwUniteComboColl.collection';
import { CWWkflhistoView } from 'common/wkfhisto/views/cwWkfhisto.view';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { Model, ViewOptions } from 'Backbone';
import { objs } from 'src/objectsRepository';
import { onError } from 'src/Interceptors/onError.js';
import { SYNC } from 'utils/sync.js';
import { UTILS } from 'utils/utils.js';

/**
 * Apply the context to the Component.
 *
 * ctxUtilisateur			> "Responsable" | "Collaborateur"
 * ctxUtilisation			> >>>
 * ctxTypologieActivite		> Typologie d’activité pour le composant: "ACTIVITE" || "ASTREINTE"
 * ctxTypeEvtGere			> ["P" | "R"]
 * ctxHabilitation			HabilitationAcces COL_ACT_PREV.V, COL_ACT_REAL.V, RES_ACT_PREV.V, RES_ACT_REAL.V
 * 							HabilitationGestion		COL_ACT_PREV.G, COL_ACT_REAL.G, RES_ACT_PREV.G, RES_ACT_REAL.G
 * 							HabilitationValidation	RES_VAL_ACTPR.G, RES_VAL_ACTRE.G
 * ctxEcran					> Identifiant de l’écran.
 * ctxModeRepresentation	> "Imbrique" | "pop-up"
 * ctxGestionCollab			> Information du collaborateur concerné
 * ctxDateConsultee			> Date de consultation de l’événement
 * ctxActionsPossibles		> ["Creer", "Dupliquer", "Supprimer", "Modifier", "Valider", "Declarer"]
 * ctxModeInitialisation	> "Consultation" | "Ajout" | "Transformation"
 * ctxIdentifiantEvt		> En cas de restitution d’un événement existant, identifiant de celui-ci.
 * ctxValeursEvenement		> Consult : valeurs de l’événement pour l’affichage dans le formulaire.
 * ctxValeursCréation   > Creation : Valeurs par défaut souhaitées pour la création d’une activité.
 * ctxHistoriqueWKF			> true | false
 * ctxGestionCollective		> true | false
 * ctxPieceJointe			> true | false
 * ctxDomainesActivités		> Liste des domaines d’activité à utiliser pour identifier les activités du référentiel à gérer dans le composant.
 *
 */
export abstract class CWGererActiviteView extends CWCaracteristiquesForm {

  protected isRendered: boolean; //Flag to prevent if set context execute before render
  protected isContextInitialized: boolean;
  context: { [key: string]: any };
  protected templateDeplacement: any;
  diver: any;
  parent: any;
  workflow: CWGereractivitesWorkflow;
  btnBar: CWButtonBarValidationView;
  plworkflow: any;
  minWidth: any;
  hasBeenFocused: boolean;
  isPrevue: boolean;
  isRealisee: boolean;
  actDefaults: any;
  actTypeSaisieOptionsColl: any;
  actModeSaisieOptionsColl: any;
  collTypoDeplac: any;
  saisieChanged: any;
  etatFinalModel: any;
  menuOptions: any[];
  affAutoHora: any;
  preferenceHoraireAuto: any;
  selectActPrevue: any;
  selectActRealisee: any;
  uniteComboColl: any;
  unitedebComboColl: any;
  unitefinComboColl: any;
  comboUniteDeb: CWComboBoxView2;
  comboUniteFin: CWComboBoxView2;
  comboUnites: CWComboBoxView2;
  origineContext: string;
  comboOrigine: CWComboBoxView2;
  referentielCollabPopu: any;
  paramIdCollab: any;
  referentielReadOnly: any;
  comboTypeDeplacement: CWComboBoxView2;
  comboCompte: CWComboBoxView2;
  modeSaisie: CWMultiLabel;
  original: any;
  disabled: boolean;
  stateIndication: boolean;
  typeEvenement: CWMultiLabel;
  typeSaisie: CWMultiLabel;
  pieceJointeModified: boolean;
  pieceJointe: any;
  modeSaisieSelected: any;
  contextComponentWkf: { ctxEcran: any; ctxHabilitation: string; ctxTypeEvenement: string; ctxRefEvenement: any; ctxDemId: any; ctxModeRestitution: string; ctxActivModeSynth: boolean; ctxModeDefaut: string; ctxModeRech: boolean };
  composantWorkflow: any;
  actSelDetail: any;
  domActDetail: any;
  defaultMaps: string[];
  collabBackup: any;
  typeGestionOfCollab: any;
  dataComptesBackup: any;
  panneauIsOpen: boolean;
  histoEtiquettePopup: CWPanneauDeroulant;
  inputFocusName: Element;//Dans Saisiemass
  isDislayedSubtitle: boolean;
  isEditable: boolean;
  FRACTIONJMAT: any;
  listFocusClass: string[];
  isClosed: boolean;
  errorPetAntSaveDelete: boolean; //flag pour savoir si la petition dernier était en erreur ou pas
  desactiveHabOuEtat: boolean;
  infoErrorModify: { [key: string]: any };
  notCheckCombos: boolean;
  etiquetteHisto1: CWEtiquette;
  outsideClickListener: (arg1: JQueryEventObject) => any;

  constructor(options?: ViewOptions<Model> | any) {
    options = options || {};
    options.events = _.extend({
      "change :input": "_changeView",
      "click li.phx-multiLabel-option": "_changeTypeSaisie",
      "click a": "_manageLink",
      "keyup :input:not([readonly])": "_notifyEdition",
      "keyup textarea:not([readonly])": "_notifyEdition",
      "mouseover span.statutActiviteLibelle": "_tooltipListener",
      "click .menuItem": "menuItemListener",
      "keyup textarea.complement": "showAdvertisment",
      "change .typeCombo": "_changeTypeSaisie",
    }, CWTYPE.DATE.events(".gereractivites-detail-events"),
      CWTYPE.HOUR_MINUTE.events(".gereractivites-detail-events"), CWTYPE.HOUR_MINUTE_NIGHTLY.events(".gereractivites-detail-events"),
      CWTYPE.DURATION.HOUR_MINUTE.events(".gereractivites-detail-events"), CWTYPE.DURATION.HOUR_MINUTE_24.events(".gereractivites-detail-events"),
      CWTYPE.PERCENTAGE_0.events(".gereractivites-detail-events"),
      CWTYPE.SHORT.events(".gereractivites-detail-events"), options.events);
    options.tagName = "div";
    options.className = "global-Gerer-Act";
    options.infoCompName = ".gereractivites-infocomp";
    options.infoCompAttributeName = options.infoCompAttributeName || "carpers";
    super(options);
    const isGar = CWLICENCE.hasModule("GAR");
    const isGtm = CWLICENCE.hasModule("GTM");
    let lTypeAct = null;

    this.infoCompName = options.infoCompName;
    this.infoCompAttributeName = options.infoCompAttributeName;
    this.isContextInitialized = false;
    this.id = null;
    this.isRendered = false;
    this.isDislayedSubtitle = false;
    this.isClosed = false;
    this.desactiveHabOuEtat = false;
    this.infoErrorModify = {};
    this.notCheckCombos = true;
    this.context = options.context;
    this.workflow = new CWGereractivitesWorkflow({}, { "context": this.context });
    this.btnBar = new CWButtonBarValidationView({
      id: "activitecollab_btnbar",
      isHideOnDisabled: this.context.ctxEcran === "saisiemass" ? false : true
    });
    this.workflow.btnBarModel = this.btnBar.model;
    if (this.context && this.context.ctxSaisieMasse === true) {
      this.model = new CWBaseModel({
        value: new (this.typeofModel())(null, { "matricule": null, infoCompAttributeName: this.infoCompAttributeName, context: (!CWSTR.isBlank(options)) ? options.context : {} }),
        mode: "C"
      });
      this.saisieMasse = true;
    } else {
      this.model = new CWBaseModel({
        value: new (this.typeofModel())(null, { infoCompAttributeName: this.infoCompAttributeName, context: (!CWSTR.isBlank(options)) ? options.context : {} }),
        mode: "C"
      });
    }
    this.workflow.cwGererActivitesView = this; // must be reimplementied
    this.workflow.formModel = this.model;
    //Set context
    if (options.context) {
      const lAccess = (this.context.ctxModeInitialisation === "Consultation") ? "Acces" : "Gestion";

      if (!CWSTR.isBlank(this.context.ctxTypeEvtGere)) {
        lTypeAct = this.context.ctxTypeEvtGere[0];
        this._getModel().set("typeevenement", lTypeAct, { silent: true });
      }
      if (this.context.ctxUtilisateur === "Responsable") {
        this.context.ctxHabilitation.HabilitationGestionRecap = "RES_RECAP.G";
      } else {
        this.context.ctxHabilitation.HabilitationGestionRecap = "COL_RECAP.G";
      }
      this.model.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: this._getHabilitationDuContext(lTypeAct, lAccess)
      }));
    }
    switch (lTypeAct) {
      case "P":
        this.workflow.contutilid = "ACTPREV";
        break;
      case "R":
        this.workflow.contutilid = "ACTREAL";
        break;
      default:
        this.workflow.contutilid = null;
    }
    this.workflow.setUp(() => {
      if ((this._getModel().get("typeevenement") === "P" && this.workflow.wkfActivitePrevActiv === false) ||
        (this._getModel().get("typeevenement") === "R" && this.workflow.wkfActiviteRealActiv === false)) {
        this.$el.find(".blockCommentaire").hide();
      }
    }
    );
    // When the value is changed, we have to map the new value to the form
    this.model.on("change:value", this._mapToForm, this);
    this.model.on("change:mode", this._manageMode, this);
    this.model.on("form:edited", this._displaySubtitle, this);
    if (options && options.parent) {
      this.parent = options.parent;
    }
    // Evo 755 Sprint 8 story 22129
    this.plworkflow = options.plworkflow;
    if (options.minWidth) {
      this.minWidth = options.minWidth;
    } else {
      if (!(this.context && this.context.ctxSaisieMasse === true)) {
        this.minWidth = "99%";
      }
    }
    //When form is being edited, not show recap link
    this.btnBar.model.on("change:mode", this._disableEnableOptions, this);
    this.btnBar.model.on("revert", this._disableEnableOptions, this);
    this.typeFormatByClass = {};
    this.typeMaskByClass = {};
    if (this.context && this.context.ctxSaisieMasse) {
      this.typeMaskByClass["datedeb"] = "DATE";
      this.typeMaskByClass["datefin"] = "DATE";
    }
    else {
      this.typeMaskByClass["datedeb"] = "DATE_A";
      this.typeMaskByClass["datefin"] = "DATE_A";
      this.typeMaskByClass["dateDebD"] = "DATE_A";
      this.typeMaskByClass["dateDebP"] = "DATE_A";
      this.typeMaskByClass["dateFinP"] = "DATE_A";
      this.typeMaskByClass["dateDebR"] = "DATE_A";
      this.typeMaskByClass["dateFinR"] = "DATE_A";
      this.typeMaskByClass["dtdebinter"] = "DATE_A";
      this.typeMaskByClass["dtappel-container"] = "DATE_A";
      this.typeMaskByClass["data-result"] = "DATE_A";
    }
    this.hasBeenFocused = false;
    this.isPrevue = false;
    this.isRealisee = false;
    //Model to select default values for activité
    this.actDefaults = null;
    this.actTypeSaisieOptionsColl = new CWTypeSaisieOptionsColl();
    this.actModeSaisieOptionsColl = new CWModeSaisieOptionsColl();
    this.saisieChanged = null;
    this.etatFinalModel = new CWEtatFinalRecapActModel();
    this.menuOptions = [];
    //EVO 186 - Aff auto horaires
    this.affAutoHora = options.affAutoHora;
    //EVO 949 - Aff auto horaires
    this.preferenceHoraireAuto = options.preferenceHoraireAuto;
    //780
    if (isGar && isGtm && !CWSTR.isBlank(GLOBAL_DATA.fractionJour.get('GTMED')) && this.context.ctxDeplacement === true) {
      this.FRACTIONJMAT = GLOBAL_DATA.fractionJour.get('GTMED')['fractions'].find((elem: any) => { return elem.code === 'MAT' });
      if (options.collTypoDeplac) {
        this.collTypoDeplac = options.collTypoDeplac;
      } else {
        const defaut = {
          visualisation: {
            actprev: "",
            actreal: ((this.context?.ctxUtilisateur !== "Responsable") ? "COL_ACT_REAL.V" : "RES_ACT_REAL.V"),
          },
          gestion: {
            actprev: "",
            actreal: ((this.context?.ctxUtilisateur !== "Responsable") ? "COL_ACT_REAL.G" : "RES_ACT_REAL.G"),
          },
          validation: {
            actprev: "",
            actreal: ((this.context?.ctxUtilisateur === "Responsable") ? "RES_VAL_ACTRE.G" : ""),
          }
        };

        if (!CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationConsultation)) {
          if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
            defaut.visualisation = defaut.gestion;
          }
        }
        this.collTypoDeplac = new CWTypoDomCol({
          habContext: new CWHabilitationContext({
            onglet: this.context.ctxEcran,
            foncCour: (this.context.ctxFoncCour ? this.context.ctxFoncCour : defaut),
            natGest: "",
          }),
          initFetch: true,
          deplacements: true,
        });
        this.listenTo(this.collTypoDeplac, "fetch:success", this._createMenuOptions);
      }
    }
    this.listenTo(this.workflow, "declareModify", this._declareModify);
    if (this.context.ctxEcran !== "saisiemass") {
      this.listenTo(this, "manageUnselected", this.manageModeUnselected);
      this.listenTo(this.workflow, "manageUnselected", this.manageModeUnselected);
    }
    if (this.context.ctxModeRepresentation === "pop-up") {
      this.listenTo(this, "manageClosing", this._checkIsClosed);
    }
    this.errorPetAntSaveDelete = false;
  }

  abstract typeofModel(): typeof CWGererActivitesModel;
  abstract newActivite(): void;
  abstract saveActivite(modeDeclarer: boolean): void;
  /**
    * Performs a Revert on the From.
    * Function Called when Press the revert Btn.
    */
  abstract revertView(): void;
  /**
    * The View Custom change event, peform logics when the inputs change his value and
    * performs put the new values on the current model with the FormView _change function.
    */
  abstract _changeView(event: JQueryEventObject, data: any): void;
  /**
   * Map the Model to the form, perform some data managements befor call
   * the FormView default MapModelToForm function.
   */
  abstract _mapToForm(): void;
  abstract _initModeConsultation(activite: CWGererActivitesModel, callback?: () => void): void;
  abstract _setTitlePopUp(headerNew?: boolean, evenement?: string): void;
  abstract _showTypeStatusTitle(): void;
  abstract render(): this;


  _initCombos(): void {
    let lsUser = "";
    const habilitationPrev: { [key: string]: any } = {};
    const habilitationReal: { [key: string]: any } = {};
    const widthSelectAct: number = null;
    let prevueContext = null;
    let realiseeContext = null;
    const commonContext: { [key: string]: any } = this._getCommonContext();
    const localDate = SYNC.getServerDate();
    const startDay = CWTYPE.DATE.dateToStr(localDate, "YYYYMMDD");
    let typeevtid: string = null;

    if (commonContext.ctxTypeContenu === "R") { // Referentiel case
      habilitationPrev.HabilitationConsultation = "PAR_ACTIV.V";
      habilitationPrev.HabilitationGestion = "PAR_ACTIV.G";
    } else if (commonContext.ctxUtilisateur === "Responsable") { // Responsable case
      habilitationPrev.HabilitationConsultation = "RES_ACT_PREV.V";
      if (CWHABILITATION.canUpdate("RES_ACT_PREV.G")) {
        habilitationPrev.HabilitationGestion = "RES_ACT_PREV.G";
      }
      habilitationPrev.HabilitationValidation = "RES_VAL_ACTPR.G";
      //EVO 378 Validation
      habilitationPrev.HabilitationValidation = {};
      habilitationPrev.HabilitationValidation.prevue = "RES_VAL_ACTPR.G";
      habilitationPrev.HabilitationValidation.realisee = "RES_VAL_ACTRE.G";
      //EVO 378 fin
    } else { // Collaborateur case
      if (CWHABILITATION.canUpdate("COL_ACT_PREV.G")) {
        habilitationPrev.HabilitationGestion = {};
        habilitationPrev.HabilitationGestion.prevue = "COL_ACT_PREV.G";
      } else {
        habilitationPrev.HabilitationConsultation = {};
        habilitationPrev.HabilitationConsultation.prevue = "COL_ACT_PREV.V";
      }
    }
    if (commonContext.ctxTypeContenu === "R") { // Referentiel case
      habilitationReal.HabilitationConsultation = "PAR_ACTIV.V";
      habilitationReal.HabilitationGestion = "PAR_ACTIV.G";
    } else if (commonContext.ctxUtilisateur === "Responsable") { // Responsable case
      habilitationReal.HabilitationConsultation = "RES_ACT_REAL.V";
      if (CWHABILITATION.canUpdate("RES_ACT_REAL.G")) {
        habilitationReal.HabilitationGestion = "RES_ACT_REAL.G";
      }
      habilitationReal.HabilitationValidation = "RES_VAL_ACTRE.G";
      //EVO 378 Validation
      habilitationReal.HabilitationValidation = {};
      habilitationReal.HabilitationValidation.prevue = "RES_VAL_ACTPR.G";
      habilitationReal.HabilitationValidation.realisee = "RES_VAL_ACTRE.G";
    } else { // Collaborateur case
      if (CWHABILITATION.canUpdate("COL_ACT_REAL.G")) {
        habilitationReal.HabilitationGestion = {};
        habilitationReal.HabilitationGestion.realisee = "COL_ACT_REAL.G";
      } else {
        habilitationReal.HabilitationConsultation = {};
        habilitationReal.HabilitationConsultation.realisee = "COL_ACT_REAL.V";
      }
    }
    if (_.isEmpty(this.context.ctxValeursCreation) || CWSTR.isBlank(this.context.ctxValeursCreation.ActDateDebut)) {
      this.context.ctxValeursCreation = {
        ActDateDebut: startDay,
        ActDateFin: startDay
      };
    }
    prevueContext = UTILS.cloneObj(_.extend(commonContext, {
      "ctxTypeEvtGere": "P",
      "ctxHabilitation": habilitationPrev
    }));
    realiseeContext = UTILS.cloneObj(_.extend(commonContext, {
      "ctxTypeEvtGere": "R",
      "ctxHabilitation": habilitationReal
    }));
    if (this.context.ctxModeRepresentation !== "pop-up" && this.context.ctxSaisieMasse !== true) {
      prevueContext.ctxModeRepresentation = "Imbrique";
      realiseeContext.ctxModeRepresentation = "Imbrique";
      prevueContext.ctxImbriqueEl = this.$el.find(".selecteurModeComplet");
      realiseeContext.ctxImbriqueEl = this.$el.find(".selecteurModeComplet");
    } else {
      prevueContext.ctxModeRepresentation = "pop-up";
      realiseeContext.ctxModeRepresentation = "pop-up";
    }
    if (!CWSTR.isBlank(this.context.ctxTypologieActivite)) {
      this.selectActPrevue = new CWSelecteurActivitesView({
        name: "actprev",
        height: 600,
        width: "1020px",
        fieldWidth: widthSelectAct,
        addItemCallback: (data: { [key: string]: any }): void => {
          this._addItemSelActCallback(data);
        },
        removeItemCallback: (): void => {
          this._removeItemSelActCallback();
        },
        context: prevueContext,
        appendTo: (this.workflow && this.workflow.$appendTo) ? this.workflow.$appendTo : null
      });
      this.selectActPrevue.model.on("imbrique:show", this._showImbrique, this);
      this.selectActPrevue.model.on("imbrique:hide", this._hideImbrique, this);
      this.selectActRealisee = new CWSelecteurActivitesView({
        name: "actreal",
        height: 600,
        width: "1020px",
        fieldWidth: widthSelectAct,
        addItemCallback: (data: { [key: string]: any }): void => {
          this._addItemSelActCallback(data);
        },
        removeItemCallback: (): void => {
          this._removeItemSelActCallback();
        },
        context: realiseeContext,
        appendTo: (this.workflow && this.workflow.$appendTo) ? this.workflow.$appendTo : null
      });
      this.selectActRealisee.model.on("imbrique:show", this._showImbrique, this);
      this.selectActRealisee.model.on("imbrique:hide", this._hideImbrique, this);
    }
    typeevtid = this.context.ctxGestionCollective === true ? "REFACTIVITE" : "ACTIVITE";
    this.uniteComboColl = new CWUniteComboColl({
      typeevtid: typeevtid,
    });
    this.uniteComboColl.setHabContext(this.model.getHabContext());
    this.unitedebComboColl = new CWCombosColl({
      name: "utilevenement/modesaisie/periodedebut/activite"
    });
    this.unitedebComboColl.setHabContext(this.model.getHabContext());
    this.unitefinComboColl = new CWCombosColl({
      name: "utilevenement/modesaisie/periodefin/activite"
    });
    this.unitefinComboColl.setHabContext(this.model.getHabContext());
    // Combos Listes Selections
    this.comboUniteDeb = new CWComboBoxView2({
      enum: [],
      name: "modesai_periodeb.code",
      required: true,
      habContext: this.model.getHabContext(),
    });
    this.listenTo(this.comboUniteDeb, "comboEdited", this._formEdited);
    this.comboUniteFin = new CWComboBoxView2({
      enum: [],
      name: "modesai_periofin.code",
      required: true,
      habContext: this.model.getHabContext(),
    });
    this.listenTo(this.comboUniteFin, "comboEdited", this._formEdited);
    this.comboUnites = new CWComboBoxView2({
      enum: [],
      name: "unitedeb.code",
      required: true,
      habContext: this.model.getHabContext(),
    });
    this.listenTo(this.comboUnites, "comboEdited", this._formEdited);
    // combo origine
    if (this.context && this.context.ctxUtilisateur) {
      lsUser = (this.context.ctxUtilisateur === "Responsable") ? "respo" : "collab";
    } else {
      lsUser = (objs.appRt.workflow.get("zone") === "resp") ? "respo" : "collab";
    }
    this.origineContext = lsUser;
    this.comboOrigine = new CWComboBoxView2({
      ws: new CWCombosColl({
        urlMine: Configuration.restRoot + "/rest/ls/origine/" + this.origineContext
      }),
      name: "origine.code",
      habContext: this.model.getHabContext(),
      optionsRender: (item: any): string => {
        return (!item || CWSTR.isBlank(item.code)) ? "" : item.libelle;
      }
    });
    this.listenTo(this.comboOrigine, "comboEdited", this._formEdited);
    if (this.context && this.context.ctxUtilisateur === "Responsable" && (this.context.ctxSaisieMasse === true || this.context.ctxGestionCollab)) {
      const lAccess = (this.context.ctxModeInitialisation === "Consultation") ? "Acces" : "Gestion";

      this.referentielCollabPopu = new CWSelectCollabPopulationView({
        context: this.context,
        diversIdCollab: this.paramIdCollab,
        readonly: this.referentielReadOnly,
        foncCour: this._getHabilitationDuContext(this._getTypeEvt(), lAccess),
        appendTo: this.workflow.$appendTo,
        height: 650,
        width: 850
      });
      this.$el.find(".cmbCollaborateurs").html(this.referentielCollabPopu.render().el);
      this.listenTo(this.referentielCollabPopu.model, "referentielChanged", this._referentialPopulationChanged);
    }
  }

  /**
   * Prepare initial context context, can be change on implemented class
   */
  protected _getCommonContext(): { [key: string]: any } {
    const commonContext: { [key: string]: any } = {};

    commonContext.ctxTypeContenu = objs.appRt.workflow.get("zone") === "ref" ? "R" : "E";
    commonContext.ctxUtilisateur = this.context.ctxUtilisateur || "Collaborateur";
    commonContext.ctxHabilitation = {};
    commonContext.ctxHabilitation.HabilitationConsultation = null;
    commonContext.ctxHabilitation.HabilitationGestion = null;
    commonContext.ctxHabilitation.HabilitationValidation = null;
    commonContext.ctxEcran = this.context.ctxEcran;
    commonContext.ctxUtilisation = "";
    commonContext.ctxActivitesInitiales = "";
    commonContext.ctxPeriodeGestion = {};
    commonContext.ctxPeriodeGestion.datedeb = this.context.ctxValeursCreation.ActDateDebut;
    commonContext.ctxPeriodeGestion.datefin = this.context.ctxValeursCreation.ActDateFin;
    commonContext.ctxIndExisteDateDeb = true;
    commonContext.ctxIndExistePeriode = false;
    commonContext.ctxPeriodeHistoCour = "";
    commonContext.ctxPeriodeHistoPrec = "";
    commonContext.ctxTypeEvtGere = "";
    commonContext.ctxTypologieActivite = this.context.ctxTypologieActivite;
    commonContext.ctxDomainesActivites = this.context.ctxDomainesActivites || "";
    commonContext.ctxCollab = this.context.ctxGestionCollab ? this.context.ctxGestionCollab.matricule : "";
    commonContext.ctxFiltreStructCollab = true;
    commonContext.ctxActivHeritage = true;
    commonContext.ctxModeRestitution = "Simplifie";
    commonContext.ctxAccesModeComplet = true;
    commonContext.ctxSelectMultiple = false;
    commonContext.ctxConsultationSeule = false;
    commonContext.ctxAffIndicTemporel = true;
    commonContext.ctxRefActivUniq = false;
    commonContext.ctxActivCompetenceExacte = true;
    commonContext.ctxActivAvecInaptitude = false;
    commonContext.ctxNiveauAttendu = -1;
    commonContext.ctxPlanActivites = this.context.ctxPlanActivites;
    if (this.context.ctxPourPlanningMedical === true) {
      commonContext.ctxPourPlanningMedical = true;
    }
    //Mode unique
    commonContext.ctxUnique = this.context.ctxUnique || false;
    return commonContext;
  }

  _referentialPopulationChanged(): void {
    const matricule = CWSTR.isBlank(this.referentielCollabPopu.model.get('matric')) ? null : this.referentielCollabPopu.model.get('matric');

    this._getModel().set("matricule", matricule, { silent: true });
    this.context.ctxGestionCollab.matricule = matricule;
    this._formEdited();
  }

  _paintCollaborateur(): void {
    if (!CWSTR.isBlank(this.collabBackup)) {
      this.$el.find('.cmbCollaborateurs input').val(this.referentielCollabPopu.referentiel.itemRenderer(this.collabBackup));
    }
  }

  _showImbrique(): void {
    const currentWidth = this.$el.find(".gereractivites-detail").width();
    const selActWidth = this.$el.find(".selecteurModeComplet").width();
    const totalWidth = currentWidth + selActWidth + 50;


    if (this.context.ctxModeRepresentation === "pop-up") {
      this.parent.dialog.dialog("option", "width", totalWidth + 20);
      this.$el.find(".selecteurModeComplet").css("width", "330px");
    }
  }

  _hideImbrique(): void {
    if (this.context.ctxModeRepresentation === "pop-up") {
      this.parent.dialog.dialog("option", "width", "auto"); //670px
    }
  }

  _addItemSelActCallback(data: { [key: string]: any }, callbackOrigin?: () => any): void {
    /*isClosed: The check is made for whether the pop-up has been closed with the ESC key.
    If the check is not performed and the pop-up has been closed, an error will occur*/
    if (!CWSTR.isBlank(data) && this.isClosed === false) {
      const activiteCode = data.code;
      const libelle = data.libelle;
      const activiteChanged = this._getModel().get("activite") !== activiteCode;

      this._initSubtitle(true);
      this._formEdited();
      CWSTR.setElValue(this._getModel(), "activite", activiteCode);
      CWSTR.setElValue(this._getModel(), "libelle", libelle);
      this._enableModeSaisie();
      CWSTR.setElValue(this._getModel(), "hierid", data.hierid);
      CWSTR.setElValue(this._getModel(), "straid", data.straid);
      CWSTR.setElValue(this._getModel(), "structid", data.parentstraid);
      CWSTR.setElValue(this._getModel(), "domainCode", data.parentdomcode);
      CWSTR.setElValue(this._getModel(), "typegest", data.typegest);
      if (activiteChanged === true) {
        this._actSelectedChanged(callbackOrigin);
      } else {
        if (typeof callbackOrigin === "function") {
          callbackOrigin();
        }
      }
    } else {
      if (typeof callbackOrigin === "function") {
        callbackOrigin();
      }
    }
  }

  _removeItemSelActCallback(): void {
    const activiteChanged = this._getModel().get("activite") !== "";

    this._formEdited();
    CWSTR.setElValue(this._getModel(), "activite", "");
    CWSTR.setElValue(this._getModel(), "libelle", "");
    this._enableModeSaisie();
    if (activiteChanged === true) {
      this._actSelectedChanged();
    }
  }

  _enableModeSaisie(): void {
    let lbVal = true; //par defaut

    if (CWSTR.isBlank(this._getModel().get("activite")) || this._panelStateIsReadonly()) {
      this._initializeOptionsColl();
      lbVal = false;
    }
    if (this.modeSaisie) {
      this.modeSaisie.setMenuAccess(lbVal);
    }
  }

  _initializeOptionsColl(): void {
    const lTypeEvt = this._getTypeEvt();
    const lAccess = (this.context.ctxModeInitialisation === "Consultation") ? "Acces" : "Gestion";
    const lHabAcces = this._getHabilitationDuContext(lTypeEvt, lAccess);

    //When no activity is selected actTypeSaisieOptionsColl adn actModeSaisieOptionsColl are initialized
    this.actTypeSaisieOptionsColl = new CWTypeSaisieOptionsColl();
    this.actTypeSaisieOptionsColl.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: lHabAcces
    }));
    this.actModeSaisieOptionsColl = new CWModeSaisieOptionsColl();
    this.actModeSaisieOptionsColl.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: lHabAcces
    }));
  }

  _formEdited(): void {
    this.model.trigger("form:edited");
  }

  setContext(context: { [key: string]: any }): void {
    let lTypeEvt: string = null;
    let lAccess: string = null;
    let lHabAcces: string = null;

    //en premier lieu l'attribution du contexte
    this.context = context;
    // et ensuite le reste
    lTypeEvt = this._getTypeEvt();
    lAccess = (this.context.ctxModeInitialisation === "Consultation") ? "Acces" : "Gestion";
    lHabAcces = this._getHabilitationDuContext(lTypeEvt, lAccess);
    this.isContextInitialized = true;
    if (CWSTR.isBlank(this.model.getHabContext())) {
      this.model.updateHabContext({
        onglet: context.ctxEcran,
        foncCour: lHabAcces,
        natGest: ""
      });
    }
    this.isPrevue = _.contains(this.context.ctxTypeEvtGere, "P");
    this.isRealisee = _.contains(this.context.ctxTypeEvtGere, "R");
    this._closeTooltipListener();
    //obtain maxpieces
    if (this.isRendered === true) {
      const lFuncLocal = (): void => {
        //check if the pop-up is closed
        if (!this.isClosed) {
          this._manageModeRepresentation();
          this._manageUtilisateur();
          this._manageModeInitialisation();
        }
      };

      if (!CWSTR.isBlank(this.workflow.maxPieces)) {
        this.workflow.maxPieces.setHabContext(new CWHabilitationContext({
          onglet: this.context.ctxEcran,
          foncCour: "N",
          natGest: ""
        }));
        this.workflow.maxPieces.fetch({
          success: (): void => {
            lFuncLocal();
          }
        });
      } else {
        lFuncLocal();
      }
    }
    if (this.context.ctxEcran === "saisiemass") {
      this.btnBar.deleteButton("new");
      this.btnBar.deleteButton("copy");
      this.btnBar.deleteButton("delete");
    }
    this.workflow.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this.workflow.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application
    }
  }

  _InitDatesPeriode(): void {
    let datedeb = "";
    let datefin = "";
    let dateFinYear: number, dateFinMonth: number, dateFinDay: number;

    // when the date changes we need to put valid dates. following SFD - Gerer une Activité (change in 168339)
    if (this.context.ctxValeursCreation && this.context.ctxValeursCreation.ActDateFin &&
      this.context.ctxValeursCreation.ActDateDebut) {
      datedeb = this.context.ctxValeursCreation.ActDateDebut;
      datefin = this.context.ctxValeursCreation.ActDateFin;
      if (!CWSTR.isBlank(datefin) && typeof datefin === 'object') {
        datefin = String(this.$el.find(".datefin").val()).replace(/\//g, '');
      }
      if (!CWSTR.isBlank(datedeb) && typeof datedeb === 'object') {
        datedeb = String(this.$el.find(".datedeb").val()).replace(/\//g, '');
      }
      dateFinYear = Number(datedeb.substring(0, 4));
      dateFinMonth = Number(datedeb.substring(4, 6));
      dateFinDay = Number(datedeb.substring(6, 8));
    }
    /* En création, dans le cas où dans le contexte d’entrée une date de fin est
      fournie (ctxValeursCréation.ActDateFin),
      utiliser cette date reçue maist uniquement si la date de début en cours vaut toujours
        la date de début du contexte de création */
    if (!CWSTR.isBlank(this.$el.find(".datedeb").val()) && datedeb !== String(this.$el.find(".datedeb").val()).replace(/\//g, '')) {
      let datefinObj: Date = null;
      let finalDay: string = null;
      let finalMonth: string = null;
      let finalYear: string = null;
      let finalFinDate: string = null;

      datedeb = String(this.$el.find(".datedeb").val()).replace(/\//g, '');
      datefin = String(this.$el.find(".datefin").val()).replace(/\//g, '');
      dateFinYear = Number(datedeb.substring(4, 8));
      dateFinMonth = Number(datedeb.substring(2, 4));
      dateFinDay = Number(datedeb.substring(0, 2));
      if (datedeb === datefin || CWSTR.isBlank(datefin)) {
        dateFinDay = dateFinDay + 1;
      } else {
        dateFinDay = Number(datefin.substring(0, 2));
      }
      datefinObj = new Date(dateFinYear, dateFinMonth - 1, dateFinDay);
      finalDay = ("0" + datefinObj.getDate()).slice(-2);
      finalMonth = ("0" + (datefinObj.getMonth() + 1)).slice(-2);
      finalYear = datefinObj.getFullYear().toString();
      finalFinDate = finalYear + finalMonth + finalDay;
      this.$el.find(".datefin").val(finalDay + "/" + finalMonth + "/" + finalYear);
      this.context.ctxValeursCreation.ActDateFin = finalFinDate;
      this._getModel().set("datefin", finalFinDate);
    }
  }

  /**
   * Prepare the presentation for the choosen ctxModeRepresentation and renders the btn bar.
   */
  _manageModeRepresentation(): void {
    const habilitationGestion = this._getHabilitationDuContext(this._getTypeEvt(), "Gestion");

    if (this.context && this.context.ctxModeRepresentation && this.context.ctxModeRepresentation === "pop-up") { // Pop up
      // pop-up
      if (this.$el) {
        this.$el.find(".gereractivite-content").append(this.btnBar.render().el);
      }
      this._setTitlePopUp();
    } else {
      if (this.context.ctxSaisieMasse === true) {
        if (this.$el) {
          this.$el.find(".gereractivite-content-btn-bar").append(this.btnBar.render().el);
        }
      }
      // Imbrique
      if (this.$el) {
        this.$el.find(".gereractivites-utilisateur").hide();
      }
      if (this.context.ctxEcran === "mdepl") {
        if (this.parent.layout.el) {
          $(this.parent.layout.el).find(".l-panelB-butomButtons").prepend(this.btnBar.render().el);
        }
        if (this.menuOptions.length === 0) {
          if (!CWHABILITATION.canCreate(habilitationGestion)) {
            this.btnBar.model.trigger("hide:new");
          } else {
            this._createMenuOptions();
          }
        }
      }
    }
  }

  public getButtonBarRef(): CWButtonBarValidationView {
    return this.btnBar;
  }

  /**
   * Paint the Utilisateur Information
   */
  _manageUtilisateur(): void {
    if (this.context.ctxUtilisateur === "Responsable" && this.context.ctxGestionCollective === false && !CWSTR.isBlank(this.context.ctxGestionCollab)) {
      let collabText = this.context.ctxGestionCollab.nom + " " + this.context.ctxGestionCollab.prenom;

      // information du collaborateur
      this.$el.find(".c-gestionCollab").removeClass("d-none");
      if ((CWSTR.isBlank(this.diver) || (this.diver === "matric")) && !CWSTR.isBlank(this.context.ctxGestionCollab.matricule)) {
        collabText += " (" + this.context.ctxGestionCollab.matricule + ")";
      } else if (this.context.ctxGestionCollab.matric_aux) {
        collabText += " (" + this.context.ctxGestionCollab.matric_aux + ")";
      } else if (this.context.ctxGestionCollab.matricule) {
        collabText += " (" + this.context.ctxGestionCollab.matricule + ")";
      }
      this.$el.find(".c-gestionCollab").html(collabText);
    } else {
      this.$el.find(".c-gestionCollab").addClass('d-none');
    }
  }


  // create menu options for the custom button "NEW".
  _createMenuOptions(): void {
    if (!CWSTR.isBlank(this.collTypoDeplac)) {
      const typologies = _.sortBy(this.collTypoDeplac.getArrayValuesGestion(), (a: Model) => {
        return a.get('evenement');
      });
      const results = typologies;

      _.each(results, (typEvt: Model): void => {
        const labelopt = typEvt.get("evenement");
        const typo = typEvt.get("code");
        const typeEvt = typEvt.get("typeevenement");

        if (typeEvt && typeEvt.indexOf("R") > -1) {
          const btnObj = { name: typo + "," + typeEvt, label: labelopt };

          this.menuOptions.push(btnObj);
        }
      });
    }
    if (this.menuOptions.length > 1) {
      const menuPosition = { my: "right top", at: "right bottom", collision: "none flip" };

      this.btnBar.addMenuToButton("new", this.menuOptions, null, null, menuPosition);
    } else if (this.menuOptions.length === 0) {
      this.btnBar.hideButton("new");
      $(this.$el.parents().find(".unselectedElementText .ajouterText")).hide();
      if (!this.$el.parents().find(".unselectedElementText .consulterText").is(":visible")) {
        this.$el.parents().find(".unselectedElementText").hide();
      }
    }
  }

  protected _manageReadOnlyReposOblig(activite: any): boolean {
    const isReadOnlyGenere = GLOBAL_DATA.paramDivers.get('GME_Rev_RO');
    const typologie = GLOBAL_DATA.typologies.get(this.context.ctxTypologieActivite);
    const oldAttributes = this._getModel().oldAttributes ? JSON.parse(this._getModel().oldAttributes) : null;
    let rtn = false;

    if (typologie && typologie.get('typeGestionActivite') === "REPOBLIG") {
      if (isReadOnlyGenere.get('valeur') === "0" && activite.get('gentrt')) {
        //Afficher mesasge général
        this.$el.find(".message-activite").text(i18n.t('messages:GME_123')).show();
        this._disableForm();
        this.btnBar.model.trigger('hide:delete');
        rtn = true;
        this.desactiveHabOuEtat = true;
      } else {
        const $activ = this.$el.find(".actprev");

        this.$el.find(".message-activite").hide();
        //Set filed motif readOnly + display text GME 127
        CWFORMS.setFieldReadonly($activ, true);
        $activ.parent().parent().append($("<div>").addClass("isReadOnlyMotif p-2").text(i18n.t('messages:GME_127')));
        //Set filed datedeb readOnly if typesaisie is date + display text GME 127
        if (activite.get('typesaisie') === "D" && oldAttributes && oldAttributes.typesaisie === "D") {
          const $datedeb = this.$el.find(".datedeb");

          CWFORMS.setFieldReadonly($datedeb, true);
        }
      }
    }
    return rtn;
  }


  _manageModeInitialisation(): void {
    const initMode = this.context.ctxModeInitialisation;
    const lTypeEvt = this._getTypeEvt();
    const lAccess = (initMode === "Consultation" || initMode === "") ? "Acces" : "Gestion";
    const lHabAcces = this._getHabilitationDuContext(lTypeEvt, lAccess);
    const lHabAccesConsult = this._getHabilitationDuContext(lTypeEvt, "Acces");
    let activite: CWGererActivitesModel = null;
    const gererActivite = this._getModel();
    let infoActivite: { [key: string]: any } = null;
    let callbackInit: () => void;

    //réinitialisation des valeurs
    this.disabled = false;
    this.desactiveHabOuEtat = false;
    this.isEditable = true;
    this.errorPetAntSaveDelete = false;
    this.infoErrorModify = {};
    this.notCheckCombos = true;
    //-------------------
    if (this.context && this.context.ctxSaisieMasse === true) {
      activite = new (this.typeofModel())(null, { "matricule": null, infoCompAttributeName: this.infoCompAttributeName, context: (!CWSTR.isBlank(this.context)) ? this.context : {} });
    } else {
      activite = new (this.typeofModel())(null, { infoCompAttributeName: this.infoCompAttributeName, context: (!CWSTR.isBlank(this.context)) ? this.context : {} });
    }
    if (CWSTR.isBlank(this.actDefaults)) {
      this.actDefaults = new CWActiviteDefaults();
      this.actDefaults.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: lHabAcces
      }));
    }
    this._initializeOptionsColl();
    switch (initMode) {
      case "Consultation":
        this.model.updateHabContext({ foncCour: lHabAcces, natGest: "" });
        this._initModeConsultation(activite);//, () => null);
        break;
      case "Ajout":
        this.btnBar.model.trigger("disable:new");
        this.btnBar.model.trigger("disable:delete");
        this.btnBar.model.trigger("hide:valider");
        this.btnBar.model.trigger("hide:refuser");
        this._updateVisibiliteOrigine();
        this.setActiviteToCreate(activite);
        this.model.updateHabContext({ foncCour: this._getHabilitationDuContext(activite.get("typeevenement"), "Gestion"), natGest: "A" });
        callbackInit = (): void => {
          if (!CWSTR.isBlank(activite.get("activite")) && (activite.context.ctxEcran === "tabservind" || activite.context.ctxEcran.indexOf("agenda") >= 0)) {
            const actDetail = new CWActiviteDetailModel({
              actCode: activite.get("activite")
            });

            actDetail.isDragAndDrop = true;
            actDetail.setHabContext(this.model.getHabContext());
            actDetail.fetch({
              success: (data: any): void => {
                activite.set("domainCode", data.get("domaine"));
                this._manageCallbackInit(activite);
              }
            })
          } else {
            this._manageCallbackInit(activite);
          }
        };
        if (!CWSTR.isBlank(this.context.ctxValeursEvenement) && !CWSTR.isBlank(this.context.ctxValeursEvenement.ActIdRecapitulatif) && !CWSTR.isBlank(this.context.ctxValeursEvenement.ActIdRecapitulatif.RecapActEta)) {
          const callbackAfterInitialize = (): void => {
            this._getEtatFinal(activite, callbackInit);
          };

          this.initializeCreation(activite, callbackAfterInitialize);
        } else {
          this.initializeCreation(activite, callbackInit);
        }
        if (this.context?.ctxSaisieMasse === true) {
          this.btnBar.$el.find(".revert").parent().attr("title", "");
          this.btnBar.$el.find(".revert").attr("title", "");
          this.btnBar.$el.find(".revert").removeClass("ui-button-icon-only").addClass("ui-button-special");
          this.btnBar.$el.find(".revert").addClass("ui-button-text-only");
          this.btnBar.$el.find(".revert span.ui-icon-arrowrefresh-1-w").remove();
          this.btnBar.model.trigger("show:save");
          this.btnBar.model.trigger("show:revert");
          this.btnBar.model.trigger("hide:new");
          this.btnBar.model.trigger("hide:copy");
          this.btnBar.model.trigger("hide:delete");
          this.trigger("end:renderComponent");
        }
        break;
      case "Transformation":
        this.workflow.modeDeclarer = true;
        activite.id = this.context.ctxIdentifiantEvt;
        this.setActiviteToTransform(activite);
        //Get infos complementaires and commentaire in order to initialize this data from WS (gerer recapitulatif doesn't have
        //infos complementairesm data (so it can't be initialized from context)
        if (this.context && this.context.ctxSaisieMasse === true) {
          infoActivite = new (this.typeofModel())(null, { "matricule": null, infoCompAttributeName: this.infoCompAttributeName, context: (!CWSTR.isBlank(this.context)) ? this.context : {} });
        } else {
          infoActivite = new (this.typeofModel())(null, { infoCompAttributeName: this.infoCompAttributeName, context: (!CWSTR.isBlank(this.context)) ? this.context : {} });
        }
        infoActivite.setHabContext(new CWHabilitationContext({
          onglet: this.context.ctxEcran,
          foncCour: lHabAccesConsult
        }));
        infoActivite.id = this.context.ctxIdentifiantEvt;
        infoActivite.action = "element";
        //Refresh activite default values for the id
        this.actDefaults.id = activite.get("activite");
        this.actDefaults.hierid = activite.get("hierid");
        this.actDefaults.itemid = activite.get("straid");
        this.actDefaults.fetch({
          success: (fresh: any): void => {
            gererActivite.set("carpers", fresh.get("carpers"));
            this.setDefaultActiviteValues(gererActivite, fresh, fresh.get("valdef_impose"), true);
            this.actTypeSaisieOptionsColl.activite = activite.get("activite");
            this.actTypeSaisieOptionsColl.fetch({
              success: (): void => {
                this.actModeSaisieOptionsColl.activite = activite.get("activite");
                this.actModeSaisieOptionsColl.fetch({
                  success: (): void => {
                    const callback = (): void => {
                      infoActivite.fetch({
                        success: (freshInfo: any): void => {
                          activite.set("domainCode", freshInfo.get("domaine").code);
                          activite.set("carpers", freshInfo.get("carpers"));
                          activite.set("complement", freshInfo.get("complement"));
                          activite.set("libelle", freshInfo.get("libelle"));
                          this._enableForm();
                          //Initialize activity if it's not allowed
                          if (this.context.ctxValeursEvenement.ActTransformationAutorise !== "O") {
                            activite.set("hierid", "");
                            activite.set("straid", "");
                            activite.set("structid", null);
                            activite.set("libelle", "");
                            activite.set("activite", "");
                          }
                          this.model.set("value", activite);
                          this.model.store();
                          this.original = activite;
                          this._initWKFHisto();
                          this._showDateConsultee();
                          this._showIntitule();
                          this._showTypeStatusTitle();
                          this._manageRestrictions(activite, true);
                          this._manageCheckAtribAuto();
                          this._manageActionsPossibles();
                          this._manageHabilitation();
                          this._managePieceJointe();
                          this._initSelecteurDates();
                          this._showIndicateurEvenamentMessage();
                          this._updateVisibiliteOrigine();
                          this.btnBar.model.trigger("disable:new");
                          this.btnBar.model.trigger("disable:delete");
                          this.btnBar.model.trigger("hide:valider");
                          this.btnBar.model.trigger("hide:refuser");
                          this.btnBar.model.set("mode", "E");
                        }
                      });
                    };

                    this._updateCombosForActivite(activite, callback);
                  }
                });
              }
            });
          }
        });
        break;
      case "InitForAction":
        this._initForAction();
        break;
      default:
        this.setActiviteToCreate(activite);
        this.model.set("value", activite);
        this._getModel().store();
        this._manageActionsPossibles();
        this._manageHabilitation();
        this._managePieceJointe();
        this._showIndicateurEvenamentMessage();
        this._updateVisibiliteOrigine();
    }
  }

  private _manageCallbackInit(activite: CWGererActivitesModel): void {
    this.model.set("value", activite);
    this._getModel().store();
    this.btnBar.model.set("mode", "E");
    this._initSelecteurDates();
    this._showDateConsultee();
    this._showIntitule();
    this._manageCheckAtribAuto();
    this._manageActionsPossibles();
    this._manageHabilitation();
    this._manageRestrictions(activite, false);
    this._managePieceJointe();
    this._showIndicateurEvenamentMessage();
    this.$el.find(":focus").blur();
    $(this.$el.find('input:visible')[0]).focus();
  }

  protected setActiviteToCreate(activite: CWGererActivitesModel): void {
    let typeEvt = "";

    activite.set("activite", this.context.ctxValeursCreation.ActCode || "");
    activite.set("libelle", this.context.ctxValeursCreation.ActLibelle || "");
    activite.set("datedeb", this.context.ctxValeursCreation.ActDateDebut || "");
    activite.set("dateDebD", this.context.ctxValeursCreation.ActDateDebut || "");
    activite.set("dateDebP", this.context.ctxValeursCreation.ActDateDebut || "");
    activite.set("dateDebR", this.context.ctxValeursCreation.ActDateDebut || "");
    activite.set("datefin", this.context.ctxValeursCreation.ActDateFin || "");
    activite.set("dateFinP", this.context.ctxValeursCreation.ActDateFin || "");
    activite.set("dateFinR", this.context.ctxValeursCreation.ActDateFin || "");
    activite.set("heuredeb", this.context.ctxValeursCreation.ActHeureDebut || "");
    activite.set("heurefin", this.context.ctxValeursCreation.ActHeureFin || "");
    activite.set("straid", this.context.ctxValeursCreation.ActElement || "");
    if (this.context.ctxTypeEvtGere && this.context.ctxTypeEvtGere.length > 0 && this.context.ctxTypeEvtGere.indexOf(this.context.ctxValeursEvenement.ActTypeEvenement) !== -1) {
      typeEvt = this.context.ctxValeursEvenement.ActTypeEvenement;
    } else if (this.context.ctxTypeEvtGere && this.context.ctxTypeEvtGere.length > 0) {
      typeEvt = this.context.ctxTypeEvtGere[0];
    }
    if (this.context && this.context.ctxSaisieMasse === true) {
      if (this.isPrevue) {
        typeEvt = "P";
      } else if (this.isRealisee) {
        typeEvt = "R";
      }
    }
    activite.set("typeevenement", typeEvt);
    CWSTR.setElValue(activite, "typologie.code", this.context.ctxTypologieActivite);
    if (!CWSTR.isBlank(this.context.ctxValeursEvenement.ActIdRecapitulatif)) {
      CWSTR.setElValue(activite, "recapitulatif.code", this.context.ctxValeursEvenement.ActIdRecapitulatif.RecapActCode || "");
      CWSTR.setElValue(activite, "recapitulatif.etat", this.context.ctxValeursEvenement.ActIdRecapitulatif.RecapActEta || "");
      CWSTR.setElValue(activite, "recapitulatif.libelle", this.context.ctxValeursEvenement.ActIdRecapitulatif.ActLibelleRecapitulatif || "");
      CWSTR.setElValue(activite, "recapitulatif.datedeb", this.context.ctxValeursEvenement.ActIdRecapitulatif.RecapActDateDeb || "");
      CWSTR.setElValue(activite, "recapitulatif.datefin", this.context.ctxValeursEvenement.ActIdRecapitulatif.RecapActDateFin || "");
      CWSTR.setElValue(activite, "recapitulatif.evenement", this.context.ctxValeursEvenement.ActIdRecapitulatif.RecapEvenement || "");
      CWSTR.setElValue(activite, "recapitulatif.statut_code", this.context.ctxValeursEvenement.ActIdRecapitulatif.RecapStatutCode || "");
      CWSTR.setElValue(activite, "recapitulatif.statut_libelle", this.context.ctxValeursEvenement.ActIdRecapitulatif.RecapStatutLibelle || "");
    }
  }

  protected setActiviteToTransform(activite: CWGererActivitesModel): void {
    activite.set("activite", this.context.ctxValeursEvenement.ActCode || "");
    activite.set("libelle", this.context.ctxValeursEvenement.ActLibelle || "");
    activite.set("datedeb", this.context.ctxValeursEvenement.ActDateDebut || "");
    activite.set("dateDebD", this.context.ctxValeursEvenement.ActDateDebut || "");
    activite.set("dateDebP", this.context.ctxValeursEvenement.ActDateDebut || "");
    activite.set("dateDebR", this.context.ctxValeursEvenement.ActDateDebut || "");
    activite.set("datefin", this.context.ctxValeursEvenement.ActDateFin || "");
    activite.set("dateFinP", this.context.ctxValeursEvenement.ActDateFin || "");
    activite.set("dateFinR", this.context.ctxValeursEvenement.ActDateFin || "");
    activite.set("heuredeb", this.context.ctxValeursEvenement.ActHeureDebut || "");
    activite.set("heurefin", this.context.ctxValeursEvenement.ActHeureFin || "");
    activite.set("typeevenement", "R");
    CWSTR.setElValue(activite, "typologie.code", this.context.ctxTypologieActivite);
    activite.set("unitedeb", this.context.ctxValeursEvenement.ActUniteDebut || "");
    activite.set("unitefin", this.context.ctxValeursEvenement.ActUniteFin || "");
    activite.set("pourcentage", this.context.ctxValeursEvenement.ActPourcentage || "");
    activite.set("duree", this.context.ctxValeursEvenement.ActDuree || "");
    activite.set("typesaisie", this.context.ctxValeursEvenement.ActTypeSaisie || "");
    activite.set("modesaisie", this.context.ctxValeursEvenement.ActModeSaisie || "");
    //				activite.set("infocomp", this.context.ctxValeursEvenement.ActListeInfoComplValeur  || {});
    activite.set("hierid", this.context.ctxValeursEvenement.ActHierarchieIdent || "");
    activite.set("straid", this.context.ctxValeursEvenement.ActElementIdent || "");
    activite.set("structid", this.context.ctxValeursEvenement.ActIdStructure || null);
    activite.set("evenement", this.context.ctxValeursEvenement.ActEvenement || "");
    activite.set("horspresav", this.context.ctxValeursEvenement.ActHorsPresAvant || "");
    activite.set("horspresap", this.context.ctxValeursEvenement.ActHorsPresApres || "");
    CWSTR.setElValue(activite, "modesai_periodeb.code", this.context.ctxValeursEvenement.ActModeSaisiePerioDebut);
    CWSTR.setElValue(activite, "modesai_periofin.code", this.context.ctxValeursEvenement.ActModeSaisiePerioFin);
    if (!CWSTR.isBlank(this.context.ctxValeursEvenement.ActIdRecapitulatif) && !CWSTR.isBlank(this.context.ctxValeursEvenement.ActIdRecapitulatif.RecapActCode)) {
      CWSTR.setElValue(activite, "recapitulatif.code", this.context.ctxValeursEvenement.ActIdRecapitulatif.RecapActCode);
    }
    CWSTR.setElValue(activite, "statut.code", this.context.ctxValeursEvenement.ActCodeStatutWKF);
    CWSTR.setElValue(activite, "statut.libelle", "");
  }

  _initSelecteurDates(): void {
    const model = this._getModel();
    const datedeb = CWSTR.getElValue(model, "datedeb");
    const datefin = CWSTR.getElValue(model, "datefin");

    if (this.selectActPrevue) {
      this.selectActPrevue.setCollDates(datedeb, datefin);
    }
    if (this.selectActRealisee) {
      this.selectActRealisee.setCollDates(datedeb, datefin);
    }
  }

  _initForAction(callback?: () => void): void {
    const lTypeEvt = this._getTypeEvt();
    const lAccess = (this.context.ctxModeInitialisation === "Consultation") ? "Acces" : "Gestion";
    const lHabAcces = this._getHabilitationDuContext(lTypeEvt, lAccess);

    this.workflow.setButtonBarListener();
    this.model.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: lHabAcces
    }));
    if (callback) {
      callback();
    }
  }

  _getEtatFinal(activite: { [key: string]: any }, callback?: () => void): void {
    const value = !CWSTR.isBlank(activite) ? activite : this._getModel();

    this.etatFinalModel = new CWEtatFinalRecapActModel();
    this.etatFinalModel.id = value.get("recapitulatif").code;
    if (this.context.ctxUtilisateur === "Responsable") {
      const hc = this.model.getHabContext().copy();

      hc.update({ foncCour: "RES_VAL_RECAP.G", natGest: "M" });
      this.etatFinalModel.setHabContext(hc);
      this.etatFinalModel.fetch({
        success: (): void => {
          if (callback) {
            callback();
          }
        }
      });
    } else {
      if (callback) {
        callback();
      }
    }
  }

  initializeCreation(activite: CWBaseModel, callbackInit: () => void): void {
    //SFD Règles d’initialisation du composant en mode création
    if (CWSTR.isBlank(this.context.ctxValeursCreation.ActCode) && CWSTR.isBlank(this.context.ctxValeursCreation.ActDateDebut) &&
      CWSTR.isBlank(this.context.ctxValeursCreation.ActDateFin)) {
      //Si aucune donnée n’est renseignée dans ctxValeursCréation :
      activite.set("typesaisie", "D");
      activite.set("modesaisie", "PLH");
      this.modeSaisie.setMenuAccess(false);
      if (callbackInit) { callbackInit(); }
    } else if (CWSTR.isBlank(this.context.ctxValeursCreation.ActCode) && !CWSTR.isBlank(this.context.ctxValeursCreation.ActDateDebut) && !CWSTR.isBlank(this.context.ctxValeursCreation.ActDateFin)) {
      //Si l'activité n'est pas renseignée et les dates de début et fin sont renseignées dans ctxValeursCreation
      if (this.context.ctxValeursCreation.ActDateDebut === this.context.ctxValeursCreation.ActDateFin) {
        activite.set("typesaisie", "D");
      } else {
        activite.set("typesaisie", "D");
        activite.set("modesaisie", "PLH");
      }
      if (!CWSTR.isBlank(this.context.ctxValeursCreation.ActUniteDebut) && !CWSTR.isBlank(this.context.ctxValeursCreation.ActUniteFin)) {
        if (activite.get("typesaisie") === "P") {
          activite.set("modesaisie", "UNITE");
          activite.set("modesaisie", "PERIOC");
          const unitesCallback = (): void => {
            const unitedeb = this.unitedebComboColl.findWhere({ code: this.context.ctxValeursCreation.ActUniteDebut });
            const unitefin = this.unitefinComboColl.findWhere({ code: this.context.ctxValeursCreation.ActUniteFin });

            if (CWSTR.isBlank(unitedeb) || CWSTR.isBlank(unitefin)) {
              activite.get("modesai_periodeb").code = "J";
              activite.get("modesai_periofin").code = "J";
            } else {
              activite.get("modesai_periodeb").code = this.context.ctxValeursCreation.ActUniteDebut;
              activite.get("modesai_periofin").code = this.context.ctxValeursCreation.ActUniteFin;
            }
            this.checkUnitesCoherence(activite);
            if (callbackInit) {
              callbackInit();
            }
          };
          this._updateCombosForActivite(activite, unitesCallback);
        } else {
          CWSTR.setElValue(activite, "unitedeb.code", this.context.ctxValeursCreation.ActUniteDebut || "");
          if (callbackInit) {
            callbackInit();
          }
        }
      } else {
        //Sinon  (Unité de début et/ou fin non renseignées), initialiser le mode de saisie avec le mode de saisie par défaut associé au type de saisie positionné.
        if (callbackInit) {
          callbackInit();
        }
      }
    } else if (CWSTR.isBlank(this.context.ctxValeursCreation.ActCode) && !CWSTR.isBlank(this.context.ctxValeursCreation.ActDateDebut) && CWSTR.isBlank(this.context.ctxValeursCreation.ActDateFin)) {
      //Si la date de début est renseignée et pas l'activité et la date de fin dans ctxValeursCreation
      activite.set("typesaisie", "D");
      if (!CWSTR.isBlank(this.context.ctxValeursCreation.ActUniteDebut)) {
        activite.set("modesaisie", "UNITE");
        CWSTR.setElValue(activite, "unitedeb.code", this.context.ctxValeursCreation.ActUniteDebut || "");
      } else {
        activite.set("modesaisie", "PLH");
      }
      if (callbackInit) {
        callbackInit();
      }
    } else if (CWSTR.isBlank(this.context.ctxValeursCreation.ActCode) && CWSTR.isBlank(this.context.ctxValeursCreation.ActDateDebut) && !CWSTR.isBlank(this.context.ctxValeursCreation.ActDateFin)) {
      //La date de fin est renseignée et pas l’activité et la date de début dans ctxValeursCréation
      activite.set("datefin", "");
      if (callbackInit) {
        callbackInit();
      }
    } else if (!CWSTR.isBlank(this.context.ctxValeursCreation.ActCode)) {
      const callback = (): void => {
        this.actDefaults.fetch({
          success: (fresh: any): void => {
            this.actTypeSaisieOptionsColl.activite = this.context.ctxValeursCreation.ActCode;
            this.actTypeSaisieOptionsColl.fetch({
              success: (): void => {
                this.actModeSaisieOptionsColl.activite = this.context.ctxValeursCreation.ActCode;
                this.actModeSaisieOptionsColl.fetch({
                  success: (): void => {
                    this.initCreationModeWithAct(activite, fresh, callbackInit);
                  }
                });
              }
            });
          }
        });
      };

      //UNITES
      if (!CWSTR.isBlank(this.context.ctxValeursCreation.ActUniteDebut)) {
        activite.get("unitedeb").code = this.context.ctxValeursCreation.ActUniteDebut;
      }
      if (!CWSTR.isBlank(this.context.ctxValeursCreation.ActUniteFin)) {
        activite.get("unitefin").code = this.context.ctxValeursCreation.ActUniteFin;
      }
      if (CWSTR.isBlank(activite.get("unitedeb").code && CWSTR.isBlank(activite.get("unitefin").code))) {
        activite.get("unitedeb").code = "J";
        activite.get("unitefin").code = "J";
      }
      this.actDefaults.id = this.context.ctxValeursCreation.ActCode;
      this._updateCombosForActivite(activite, callback);
    }
  }

  initCreationModeWithAct(activite: CWBaseModel, defaultValues: { [key: string]: any }, callback?: () => void): void {
    let unitedeb, unitefin;

    if (!CWSTR.isBlank(this.context.ctxValeursCreation.ActCode) &&
      CWSTR.isBlank(this.context.ctxValeursCreation.ActDateDebut) &&
      CWSTR.isBlank(this.context.ctxValeursCreation.ActDateFin)) {
      //Si seule l’activité est renseignée dans ctxValeursCréation
      this.setDefaultActiviteValues(activite, defaultValues, true);
    } else if (!CWSTR.isBlank(this.context.ctxValeursCreation.ActCode) &&
      !CWSTR.isBlank(this.context.ctxValeursCreation.ActDateDebut) &&
      !CWSTR.isBlank(this.context.ctxValeursCreation.ActDateFin)) {
      //Si l’activité et les dates de début et fin sont renseignées dans ctxValeursCreation
      if (this.context.ctxValeursCreation.ActDateDebut === this.context.ctxValeursCreation.ActDateFin) {
        activite.set("typesaisie", "D");
      } else {
        const periodeFound = this.actTypeSaisieOptionsColl.findWhere({ code: "P" });
        const repetitiveFound = this.actTypeSaisieOptionsColl.findWhere({ code: "R" });

        if (!CWSTR.isBlank(periodeFound) && !CWSTR.isBlank(repetitiveFound)) {
          if (this.actDefaults.get("saisie_typedef").code === "P" || this.actDefaults.get("saisie_typedef").code === "R") {
            activite.set("typesaisie", this.actDefaults.get("saisie_typedef").code);
          } else {
            activite.set("typesaisie", "R");
          }
        } else if (!CWSTR.isBlank(repetitiveFound) && !CWSTR.isBlank(this.context.ctxValeursCreation.ActDateDebut) &&
          !CWSTR.isBlank(this.context.ctxValeursCreation.ActDateFin)) {
          activite.set("typesaisie", "R");
        } else if ((CWSTR.isBlank(periodeFound) && !CWSTR.isBlank(repetitiveFound)) ||
          (!CWSTR.isBlank(periodeFound) && CWSTR.isBlank(repetitiveFound))) {
          activite.set("typesaisie", this.actDefaults.get("saisie_typedef").code);
        } else {
          activite.set("typesaisie", "D");
          activite.set("datefin", "");
        }
      }
      if (!CWSTR.isBlank(this.context.ctxValeursCreation.ActUniteDebut) && !CWSTR.isBlank(this.context.ctxValeursCreation.ActUniteFin)) {
        if (activite.get("typesaisie") === "P") {
          unitedeb = this.unitedebComboColl.findWhere({ code: this.context.ctxValeursCreation.ActUniteDebut });
          unitefin = this.unitefinComboColl.findWhere({ code: this.context.ctxValeursCreation.ActUniteFin });
        } else {
          unitedeb = this.uniteComboColl.findWhere({ code: this.context.ctxValeursCreation.ActUniteDebut });
          unitefin = this.uniteComboColl.findWhere({ code: this.context.ctxValeursCreation.ActUniteFin });
        }
        if ((activite.get("typesaisie") === "P" && !CWSTR.isBlank(unitedeb) && !CWSTR.isBlank(unitefin)) || (activite.get("typesaisie") !== "P" && !CWSTR.isBlank(unitedeb))) {
          const modesaisie = activite.get("typesaisie") === "P" ? "PERIOC" : "UNITE";
          const callbackControlPeriode = (): void => {
            if (activite.get("typesaisie") === "P") {
              const unitedeb = this.unitedebComboColl.findWhere({ code: this.context.ctxValeursCreation.ActUniteDebut });
              unitefin = this.unitefinComboColl.findWhere({ code: this.context.ctxValeursCreation.ActUniteFin });
              if (CWSTR.isBlank(unitedeb) || CWSTR.isBlank(unitefin)) {
                activite.set("modesai_periodeb", this.unitedebComboColl.at(0));
                activite.set("modesai_periofin", this.unitefinComboColl.at(0));
              }
            }
          };

          //Si elles sont autorisées pour l’activité et le type de saisie : le mode de saisie est initialisé à «Unité»
          activite.set("modesaisie", modesaisie);
          activite.set("carpers", defaultValues.get("carpers"));
          this.checkUnitesCoherence(activite, callbackControlPeriode);
        } else { //Initialisation Mode de saisie et composants associés Cas 1
          this._initModeCas1(activite, defaultValues);
        }
      } else {
        this.setDefaultActiviteValues(activite, defaultValues, false);
      }
    } else if (!CWSTR.isBlank(this.context.ctxValeursCreation.ActCode) && !CWSTR.isBlank(this.context.ctxValeursCreation.ActDateDebut) && CWSTR.isBlank(this.context.ctxValeursCreation.ActDateFin)) {
      //Si l'activité et la date de début sont renseignées et pas la date de fin dans ctxValeursCréation
      activite.set("typesaisie", "D");
      if (!CWSTR.isBlank(this.context.ctxValeursCreation.ActUniteDebut)) { //Si l’unité de début est renseignée dans ctxValeursCréation
        unitedeb = this.uniteComboColl.findWhere({ code: this.context.ctxValeursCreation.ActUniteDebut });
        if (!CWSTR.isBlank(unitedeb)) { //Si elle est autorisée pour l’activité et le type de saisie «Date»
          activite.set("modesaisie", "UNITE");
          CWSTR.setElValue(activite, "unitedeb.code", this.context.ctxValeursCreation.ActUniteDebut || "");
          activite.set("carpers", defaultValues.get("carpers"));
          this.checkUnitesCoherence(activite);
        } else {
          // Initialisation Mode de saisie et composants associ�s Cas 1
          this._initModeCas1(activite, defaultValues);
        }
      } else {
        // Initialisation Mode de saisie et composants associ�s Cas 1
        this._initModeCas1(activite, defaultValues);
      }
    } else if (!CWSTR.isBlank(this.context.ctxValeursCreation.ActCode) &&
      CWSTR.isBlank(this.context.ctxValeursCreation.ActDateDebut) &&
      !CWSTR.isBlank(this.context.ctxValeursCreation.ActDateFin)) {
      //Si l'activité et la date de fin sont renseignées et pas la date de début dans ctxValeursCréation
      this.setDefaultActiviteValues(activite, defaultValues, true);
    }
    if ((activite.get("typeevenement") === "P" && defaultValues.get("soumiswkf_p")) || (activite.get("typeevenement") === "R" && defaultValues.get("soumiswkf_r"))) {
      // activite gérée en workflow : commentaire
      this.$el.find(".blockCommentaire").show();
    } else {
      // activité non gérée en workflow : pas de commentaire
      this.$el.find(".blockCommentaire").hide();
    }
    if (typeof callback === "function") {
      callback();
    }
  }

  _initModeCas1(activite: { [key: string]: any }, defaultValues: { [key: string]: any }): void {
    if (activite.get("typesaisie") === "P") {
      //Type de saisie «Période» : Initialiser le mode de saisie par défaut pour une période continue
      //Et initialiser les composants de saisie associés à vide sans tenir compte des valeurs de ctxValeursCréation
      activite.set("heuredeb", "");
      activite.set("heurefin", "");
    }
    this.setDefaultActiviteValues(activite, defaultValues, false);
  }

  /**
   * ["Creer","Supprimer","Modifier","Valider","Dupliquer"]
   */
  _manageActionsPossibles(): void {
    if (this.context.ctxActionsPossibles && this.context.ctxModeInitialisation === "Ajout") {
      if (this.context.ctxActionsPossibles.indexOf("Creer") === -1 || this.context.ctxModeRepresentation === "pop-up") {
        this.btnBar.model.trigger("hide:new");
      }
    } else if (this.context.ctxActionsPossibles && this.context.ctxModeInitialisation === "Consultation") {
      const model = this._getModel();
      const isCollab = (this.context && this.context.ctxUtilisateur === "Collaborateur");

      //Declarer
      if (CWHABILITATION.canCreate(isCollab ? "COL_ACT_REAL.G" : "RES_ACT_REAL.G") && model.get("typeevenement") === "P" && (model.get("typetransfo") === "N" || CWSTR.isBlank(model.get("typetransfo")))) {
        this.btnBar.btnClasses['declarer'] = "btn-secondary";
        this.btnBar.addButton("declarer", i18n.t('common:gereractivites.btnDeclarer'));
        //Après une action de annulation, ce bouton si avant il était visible, il devra être rétabli (il faut forcer l'activation et la visualisation): mode E-> mode R
        if (this.btnBar.model.hasChanged("mode")) {
          this.btnBar.model.trigger("show:declarer");
          this.btnBar.model.trigger("enable:declarer");
        }
        this._configureDeclarerButton();
      }
      if (this.context.ctxActionsPossibles.indexOf("Creer") === -1 || this.context.ctxModeRepresentation === "pop-up") {
        this.btnBar.model.trigger("hide:new");
      }
      if (this.context.ctxActionsPossibles.indexOf("Supprimer") === -1) {
        this.btnBar.model.trigger("hide:delete");
      } else if (this.context.ctxModeInitialisation !== "Ajout") {
        this.btnBar.model.trigger("show:delete");
      }
      if (this.context.ctxActionsPossibles.indexOf("Modifier") === -1) {
        this._disableForm();
        this.isEditable = false;
        this.desactiveHabOuEtat = true;//il n'affichera pas le message(date tops anciènne, etc) car il sera déjà bloqué
      }
      if (this.context.ctxActionsPossibles.indexOf("Creer") === -1 && this.context.ctxActionsPossibles.indexOf("Modifier") === -1) {
        this.btnBar.model.trigger("hide:save");
        this.btnBar.model.trigger("hide:revert");
      }
      if (this.context.ctxActionsPossibles.indexOf("Valider") === -1) {
        this.btnBar.model.trigger("hide:valider");
      }
      if (this.context.ctxActionsPossibles.indexOf("Declarer") === -1) {
        this.btnBar.model.trigger("hide:declarer");
      }
      if (this.context.ctxActionsPossibles.indexOf("Dupliquer") === -1 || this._getModel().get("code") === "") {
        this.btnBar.model.trigger("hide:copy");
      }
    } else if (this.context.ctxActionsPossibles && this.context.ctxModeInitialisation === "Transformation") {
      if (this.context.ctxActionsPossibles.indexOf("Creer") === -1) {
        this.btnBar.model.trigger("hide:new");
      }
      if (this.context.ctxActionsPossibles.indexOf("Supprimer") === -1) {
        this.btnBar.model.trigger("hide:delete");
      }
      if (this.context.ctxActionsPossibles.indexOf("Valider") === -1) {
        this.btnBar.model.trigger("hide:valider");
      }
      if (this.context.ctxActionsPossibles.indexOf("Declarer") === -1) {
        this.btnBar.model.trigger("hide:declarer");
      }
    }
  }

  _showGrilleHoraireMessage(): void {
    if (this.context && this.context.ctxActiviteBesoin !== true) {
      if (this._getModel().get("typegest") === "GTMED") {
        this.$el.find(".message-activite").text(i18n.t('messages:GME_52')).show();
      } else {
        this.$el.find(".message-activite").text(i18n.t('messages:GT_5257')).show();
      }
    }
  }

  _hideGrilleHoraireMessage(): void {
    if (this.context && this.context.ctxActiviteBesoin !== true) {
      this.$el.find(".message-activite").hide();
    }
  }

  _manageHabilitation(): void {
    let habilitationGestion = this._getHabilitationDuContext(this._getTypeEvt(), "Gestion"); //this.context.ctxHabilitation.HabilitationGestion;
    let peutCreer = false;
    let peutModifier = false;
    let peutSupprimer = false;
    let peutValider = false;
    let isFormDesactive = false;

    if (this.context.ctxSaisieMasse === true && _.isObject(habilitationGestion)) {
      habilitationGestion = _.values(habilitationGestion).join(",");
    }
    peutCreer = (!CWSTR.isBlank(habilitationGestion)) ? CWHABILITATION.canCreate(habilitationGestion) : false;
    peutModifier = (!CWSTR.isBlank(habilitationGestion)) ? CWHABILITATION.canUpdate(habilitationGestion) : false;
    peutSupprimer = (!CWSTR.isBlank(habilitationGestion)) ? CWHABILITATION.canDelete(habilitationGestion) : false;
    peutValider = (!CWSTR.isBlank(this.context?.ctxHabilitation?.HabilitationValidation)) ? CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation) : false;
    if (this._getModel().isNew()) {
      this.isEditable = peutCreer;
    } else {
      this.isEditable = this.isEditable && peutModifier;//"this.isEditable" peut être déjà désactivé ("false") et on ne doit pas perdre cette information
    }
    if (!peutCreer) {
      this.btnBar.model.trigger("hide:new");
      this.btnBar.model.trigger("hide:nouveau");
      if (this.context.ctxModeInitialisation !== "Consultation") {
        CWFORMS.setFormReadonly(this.$el.find(".gereractivite-content"), true, false);
        this.desactiveHabOuEtat = true;
        isFormDesactive = true;
      }
    }
    if (!peutModifier && this.context.ctxModeInitialisation !== "Ajout") {
      // we cannot disable the form if we are in "collectifs"
      if (objs.appRt.workflow.get("usecase") !== "saisiescoll") {
        this._disableForm();
        this.desactiveHabOuEtat = true;
      }
      CWFORMS.setFormReadonly(this.$el.find(".gereractivite-content"), true, false);
      isFormDesactive = true;
    }
    if (!peutModifier && !peutCreer) {
      this.btnBar.model.trigger("hide:revert");
      this.btnBar.model.trigger("hide:save");
      if (!isFormDesactive) {
        this._disableForm();
      }
    }
    if (!peutSupprimer) {
      this.btnBar.model.trigger("hide:delete");
    }
    if (!peutValider) {
      this.btnBar.model.trigger("hide:accepter");
      this.btnBar.model.trigger("hide:refuser");
    }
  }

  _fetchIsModifiable(activiteId: string, successCallback: (model: { [key: string]: any }, response: { [key: string]: any }) => void, errorCallback: (model: CWGererActivitesGestionModel, response: { [key: string]: any }) => void): void {
    if (!CWSTR.isBlank(activiteId)) {
      const isModifiableModel = new CWGererActivitesGestionModel();
      const lHabGestion = this._getHabilitationDuContext(this._getTypeEvt(), "Gestion");

      this.hasBeenFocused = true;
      isModifiableModel.id = activiteId;
      isModifiableModel.action = "modifiable";
      isModifiableModel.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: lHabGestion,
        natGest: ""
      }));
      isModifiableModel.fetch({
        success: successCallback,
        error: errorCallback
      });
    }
  }

  /**
   * Check if the Form is Modifiable
   */
  _isModifiable(event: JQueryEventObject, callback?: () => void): void {
    if (this.context.ctxModeInitialisation === "Consultation" && !CWSTR.isBlank(this.hasBeenFocused) && !this.hasBeenFocused) {
      const target = this._getModel();

      if (target && target.id) {
        this._fetchIsModifiable(target.id, (_model, response) => {
          if (response && response.result === "ok") {
            this._enableForm();
            this._manageImposeDefaults();
            this._manageCheckAtribAuto();
            if ($(event.target).hasClass("phx-combobox-input")) {
              $(event.target.parentElement).find(".phx-combobox-button").click();
            } else {
              $(event.target).click();
            }
            if (typeof callback === "function") {
              callback();
            }
          }
        },
          () => {
            $(event.target).mouseout();
            this.workflow.btnBarModel.set("mode", "R");
            this.workflow.btnBarModel.trigger("disable:delete");
            this.workflow.btnBarModel.trigger("disable:valider");
            this.workflow.btnBarModel.trigger("disable:declarer");
            this._disableForm();
          }
        );
      }
    } else {
      if (typeof callback === "function") {
        callback();
      }
    }
  }

  _configureDeclarerButton(): void {
    const model = this._getModel();
    const values: CWButtonBarMenu = [{
      className: "decModif",
      name: "decModif",
      label: i18n.t('common:gereractivites.btnDeclarerModif')
    }];

    if (model.get("transformation_auto") === "O") {
      values.push({
        className: "declarer",
        name: "decIden",
        label: i18n.t('common:gereractivites.btnDeclarerIden')
      });
    }
    this.btnBar.addMenuToButton('declarer', values, true)
    if (model.get("transformation_auto") === "N") {
      this.btnBar.model.trigger("hide:declarer");
    }
  }

  _configureValidationButton(): void {
    const model = this._getModel();
    const profils = !CWSTR.isBlank(model) ? model.get("profils") : null;
    let accepter, refuser;
    const $ul = $(".gereractivites-detail", this.el).find("ul.validerListMenu");

    if (!CWSTR.isBlank(profils)) {
      if (profils.length === 1 && profils[0] && $ul) {
        let $a = $("<a class='menuItem' data-value='2' >" + i18n.t('common:gereractivites.btnAccepter') + "</a>");

        accepter = $("<li>").addClass("ui-menu-item");
        refuser = $("<li>").addClass("ui-menu-item");
        $a.attr("data-code", profils[0].code);
        accepter.append($a);
        $ul.append(accepter);
        $a = $("<a class='menuItem' data-value='3' >" + i18n.t('common:gereractivites.btnRefuser') + "</a>");
        $a.attr("data-code", profils[0].code);
        refuser.append($a);
        $ul.append(refuser);
      }
      if (profils.length > 1 && $ul) {
        accepter = $("<li>").addClass("ui-menu-item");
        refuser = $("<li>").addClass("ui-menu-item");
        accepter.append("<span class='ui-corner-all'>" + i18n.t('common:gereractivites.btnAccepter') + "</span>");
        $ul.append(accepter);
        _.each(profils, (obj: { [key: string]: any }): void => {
          const $item = $("<li>").addClass("ui-menu-item");
          const $a = $("<a class='menuItem' data-value='2' >- " + obj.libelle + "</a>");

          $a.attr("data-code", obj.code);
          $item.append($a);
          $ul.append($item);
        }, this);
        refuser.append("<span class='ui-corner-all'>" + i18n.t('common:gereractivites.btnRefuser') + "</span>");
        $ul.append(refuser);
        _.each(profils, (obj: { [key: string]: any }): void => {
          const $item = $("<li>").addClass("ui-menu-item");
          const $a = $("<a class='menuItem' data-value='3'>- " + obj.libelle + "</a>");

          $a.attr("data-code", obj.code);
          $item.append($a);
          $ul.append($item);
        }, this);
      }
    }
    $(".gereractivites-detail", this.el).find("button.valider").button({ icons: { secondary: "ui-icon-triangle-1-s" }, text: true }).click((): boolean => {
      const menu = $(".gereractivites-detail", this.el).find("ul.validerListMenu").show().position({
        my: "left top",
        at: "left bottom",
        of: this
      });

      $(document).one("click", (): void => {
        menu.hide();
      });
      return false;
    });
    $(".gereractivites-detail", this.el).find("ul.validerListMenu").hide().menu();
  }

  menuItemListener(event: JQueryEventObject): void {
    const action = (event.currentTarget.attributes as any)["data-value"].nodeValue;
    const code = (event.currentTarget.attributes as any)["data-code"].nodeValue;

    switch (action) {
      case "2":
        this.btnBar.model.trigger("btn:click", "accepter", code);
        break;
      case "3":
        this.btnBar.model.trigger("btn:click", "refuser", code);
        break;
      case "4":
        this.btnBar.model.trigger("btn:click", "decIden", code);
        break;
      case "5":
        this.btnBar.model.trigger("btn:click", "decModif", code);
      // not default
    }
  }

  /*
   * Hide the status and type evenement if alsoTypeEv is true
   */
  _hideTypeStatusTitle(): void {
    this.$el.find(".statutActiviteLibelle").hide();
  }

  _showDateConsultee(): void {
    if (this.context.ctxModeRepresentation === "Imbrique" && this.context.ctxGestionCollective === false &&
      !CWSTR.isBlank(this.context.ctxDateConsultee)) {
      this.$el.find(".gereractivites-dateconsultee").show();

      this.$el.find(".gereractivites-dateconsultee-text").html(CWTYPE.DATE.format(this.context.ctxDateConsultee, CWTYPE._getFormatByCode("DATE_A")));
    } else {
      this.$el.find(".gereractivites-dateconsultee").hide();
    }
  }

  _showIntitule(): void {
    let text: string = null;

    if (this.context.ctxModeRepresentation === "Imbrique" && this.context.ctxGestionCollective === false && this.context.ctxEcran !== "mdepl") {
      this.$el.find(".gereractivites-intitule").show();
      if (this.context.ctxModeInitialisation === "Consultation") {
        this.$el.find(".gereractivites-intitule").html(CWSTR.getElValue(this._getModel(), "libelle") + " " + CWSTR.getElValue(this._getModel(), "activite"));
      } else if (this.context.ctxModeInitialisation === "Ajout") {
        const typologie = GLOBAL_DATA.typologies.get(this.context.ctxTypologieActivite);
        const genre = typologie.get("genre");
        const evenement = typologie.get("evenement");
        let evt = "";

        if (this.isPrevue && !this.isRealisee) {
          evt += i18n.t('common:gereractivites.prevue');
        } else if (this.isPrevue && this.isRealisee) {
          evt += i18n.t('common:gereractivites.realisee');
        }
        if (genre === "F") {
          text = "< " + i18n.t('common:gereractivites.nouvelle_title_F') + " " + evenement;
        } else if (genre === "M") {
          text = "< " + i18n.t('common:gereractivites.nouvelle_title_M') + " " + evenement;
        }
        if (!CWSTR.isBlank(evt)) { text += " " + evt; }
        text += " >";
        this.$el.find(".gereractivites-intitule").html(text);
      } else {
        text = "< " + i18n.t('common:gereractivites.nouvelle_title_F') + " " + i18n.t('common:gereractivites.actionactivite');
        this.$el.find(".gereractivites-intitule").html(text);
      }
    } else {
      this.$el.find(".gereractivites-intitule").hide();
    }
  }

  _showRecapitulatif(): void {
    const recapitulatif = CWSTR.getElValue(this._getModel(), "recapitulatif");

    if (this.context.ctxModeInitialisation === "Consultation" && !CWSTR.isBlank(recapitulatif) && !CWSTR.isBlank(recapitulatif.code) && !CWSTR.isBlank(recapitulatif.libelle)) {
      const text = i18n.t('common:gereractivites.recapitulatif') + " <span class='actionRecap'>" + recapitulatif.libelle + "</span> " + i18n.t('common:gereractivites.du') + " " + CWTYPE.DATE.format(recapitulatif.datedeb) + " " + i18n.t('common:gereractivites.au') + " " + CWTYPE.DATE.format(recapitulatif.datefin);

      this.$el.find(".gereractivites-recapitulatif").show();
      this.$el.find(".gereractivites-recapitulatif-text").html(text);
    } else {
      this.$el.find(".gereractivites-recapitulatif").hide();
    }
  }

  _disableEnableOptions(): void {
    if (this.btnBar.model.get("mode") !== "R") {
      this.$el.find(".recap-link-div").hide();
    } else {
      this.$el.find(".recap-link-div").show();
    }
  }

  _paintWorkflowStatus($layout: any): void {
    const activite = this._getModel();
    const onlyIcon = this.context.ctxHistoriqueWKF && activite.get("iddemande") >= 0 && activite.get("statut").code === "H";
    const wkfActif = activite && activite.get("typeevenement") && activite.get("typeevenement") === "P" ?
      this.workflow.wkfActivitePrevActiv : this.workflow.wkfActiviteRealActiv;
    if (this.context.ctxModeInitialisation === "Consultation" && (CWSTR.isUndefined(this.context.ctxValeurEvenement) || this.context.ctxValeurEvenement && CWSTR.isBlank(this.context.ctxValeurEvenement.ActldRecpitulatif))) {//if is consultation mode and isn't an activity of recap
      const ActGenereEdT = activite.get("generationedt");
      const ActPermut = activite.get("permutation");

      if (!CWSTR.isBlank(activite.get("statut").code)) {
        if (ActGenereEdT || ActPermut) {
          if (!_.isEmpty(this.etiquetteHisto1)) {
            this.etiquetteHisto1.model.stopListening();
            this.etiquetteHisto1.model.off();
            this.etiquetteHisto1.$el.off();
            this.etiquetteHisto1.off();
            this.etiquetteHisto1.remove();
            this.etiquetteHisto1 = null;
          }
          this.etiquetteHisto1 = new CWEtiquette({
            id: "etiquetteWorkHisto",
            text: { enabled: ActGenereEdT ? i18n.t('common:gereractivites.generated_by_edt') : i18n.t('common:gereractivites.permute') },
            type: "accepte",
            state: "actif"
          });
          $layout.show();
          $layout.html(this.etiquetteHisto1.render().el);
        } else {
          const subtextEtiquette = activite.get("etat") === "X" ? i18n.t('common:etiquette_subText.suppression') : "";//etiquette workflowhisto
          const codeStatut = this.checkSCodetatut(activite.get("statut").code);
          const text = activite.get("statut").code === "H" ? "" : activite.get("statut").libelle;

          if (!_.isEmpty(this.etiquetteHisto1)) {
            this.etiquetteHisto1.model.stopListening();
            this.etiquetteHisto1.model.off();
            this.etiquetteHisto1.$el.off();
            this.etiquetteHisto1.off();
            this.etiquetteHisto1.remove();
            this.etiquetteHisto1 = null;
          }
          this.etiquetteHisto1 = new CWEtiquette({
            id: "etiquetteWorkHisto",
            text: { enabled: text }, //perhaps we should change for i18n text
            subText: { enabled: subtextEtiquette },
            type: codeStatut,
            state: "actif",
            onlyIcon: onlyIcon
          });

          $layout.show();
          // Displays historique icon only if wkf is active
          if (this.context.ctxHistoriqueWKF === true && wkfActif && activite.get("iddemande") >= 0) {
            this.etiquetteHisto1.icon = 'horloge_fleche';
            this.listenTo(this.etiquetteHisto1.model, "click", this.histoEtiquetteClick);
          } else if (onlyIcon === true || text === "") {
            this.etiquetteHisto1.setNoBackground();
          }

          $layout.html(this.etiquetteHisto1.render().el);
        }
      }
      if (!CWSTR.isBlank(activite.get("acttypeaffect")) && activite.get("acttypeaffect") === "A" && this.context.ctxUtilisateur !== "Collaborateur") {
        const codeStatut = "auto";

        if (!_.isEmpty(this.etiquetteHisto1)) {
          this.etiquetteHisto1.model.stopListening();
          this.etiquetteHisto1.model.off();
          this.etiquetteHisto1.$el.off();
          this.etiquetteHisto1.off();
          this.etiquetteHisto1.remove();
          this.etiquetteHisto1 = null;
        }
        this.etiquetteHisto1 = new CWEtiquette({
          id: "etiquetteBlockAuto",
          text: {
            enabled: i18n.t("common:vuejourneeactivites.auto")
          },
          type: codeStatut,
          state: "actif",
          className: "c-etiquette cw-auto-etiq cw-texteAllege d-flex flex-row align-items-center",
        });
        $layout.html(this.etiquetteHisto1.render().el);
      }
    } else {
      if (!CWSTR.isBlank($layout)) {
        $layout.hide();
      }
    }
  }

  _paintGenereEtiquette($layout: any): void {
    const activite = this._getModel();

    if (this.context.ctxModeRepresentation === "pop-up") {
      if (this.context.ctxModeInitialisation === "Consultation") {//if is consultation mode
        if (this.context.ctxTypeActivite === "REPOBLIG" && !CWSTR.isBlank(activite.get("gentrt")) && activite.get("gentrt")) {
          if (!_.isEmpty(this.etiquetteHisto1)) {
            this.etiquetteHisto1.model.stopListening();
            this.etiquetteHisto1.model.off();
            this.etiquetteHisto1.$el.off();
            this.etiquetteHisto1.off();
            this.etiquetteHisto1.remove();
            this.etiquetteHisto1 = null;
          }
          this.etiquetteHisto1 = new CWEtiquette({
            id: "etiquetteBlockGenere",
            text: { enabled: i18n.t("common:vuejourneeactivites.generer") },
            type: "custom",
            state: "actif",
            className: "c-etiquette cw-genere-etiq cw-texteAllege d-flex flex-row align-items-center",
            values: "N"
          });
          $layout.show();
          $layout.html(this.etiquetteHisto1.render().el);
        }
      }
    }
  }

  protected histoEtiquetteClick(): void {
    if (!CWSTR.isBlank(this.histoEtiquettePopup) && this.panneauIsOpen === true) {
      this.histoEtiquettePopup.close();
      this.panneauIsOpen = false;
    } else {
      this.panneauIsOpen = true;
      if (CWSTR.isBlank(this.histoEtiquettePopup)) {
        this.histoEtiquettePopup = new CWPanneauDeroulant({
          fixedButtons: true,
          maxHeight: 360,
          view: this.composantWorkflow,
          viewData: {
            appendTo: this.el,
            modal: false
          },
          notIconClose: true,
          closeOnClickOutside: true
        });
        if (_.isEmpty(this.outsideClickListener)) {
          this.outsideClickListener = (event: any): any => {
            if ($(event.target).parents(".c-panneauDeroulant").length === 0 && $(event.target).closest(".ui-datepicker-header").length === 0 && $(event.target).closest(".ui-datepicker-calendar").length === 0 && this.histoEtiquettePopup) {
              this.histoEtiquettePopup._closePanneau(event, null);
            }
          };
        }
        if (this.context.ctxModeRepresentation === "Imbrique") {
          this.histoEtiquettePopup.$el.on("dialogopen", (): void => {
            $(document).off("click" + (this.workflow.module ? ("." + this.workflow.module) : ""), this.outsideClickListener as () => any);
            $(document).on("click" + (this.workflow.module ? ("." + this.workflow.module) : ""), this.outsideClickListener);
          });
          this.histoEtiquettePopup.$el.on("dialogclose", (): void => {
            $(document).off("click" + (this.workflow.module ? ("." + this.workflow.module) : ""), this.outsideClickListener as any);
            this.panneauIsOpen = false;
          });
        } else {
          this.histoEtiquettePopup.model.on("dlg:close", (): void => {
            this.panneauIsOpen = false;
          });
        }
      } else {
        this.histoEtiquettePopup.view = this.composantWorkflow;
        this.histoEtiquettePopup.viewData = {
          appendTo: this.el,
          modal: false
        };
      }
      this.histoEtiquettePopup.setPosition({
        my: "right top",
        at: "right bottom",
        of: $(event.currentTarget)
      });
      this.histoEtiquettePopup.setHeight("auto");
      this.histoEtiquettePopup.setWidth(500);
      // this.histoEtiquettePopup.model.trigger("dlg:open");
      // this.histoEtiquettePopup.model.off("dlg:close");
      // this.histoEtiquettePopup.model.on("dlg:close", (): void => {
      //   this.panneauIsOpen = false;
      //   this.histoEtiquettePopup.close();
      // }, this);
      this.histoEtiquettePopup.open();
      this.histoEtiquettePopup._setTitle(i18n.t('common:listerevtgen.historique').toUpperCase());
    }
    event.stopPropagation();
  }

  // This method return the style typoe of the etiquette when we send the letter of the status
  protected checkSCodetatut(code: string): validEtiquetteTypes {
    let status: validEtiquetteTypes;

    switch (code) {
      case "D":
        status = 'demande';
        break;
      case "T":
        status = 'demande';
        break;
      case "I":
        status = 'demande';
        break;
      case "A":
        status = 'accepte';
        break;
      case "H"://this case maybe is not useful
        status = 'accepte';
        break;
      case "R":
        status = 'refuse';
        break;
      default:
        status = 'demande';
        break;
    }
    return status;
  }

  _getWorkflowStyle(statut: string): string {
    let style = "";

    switch (statut) {
      case "E":
        style = "ui-phx-statut-en-cours-saisie";
        break;
      case "F":
      case "G":
        style = "ui-phx-statut-en-cours-revision";
        break;
      case "C":
      case "M":
        style = "ui-phx-statut-en-cours-modification";
        break;
      case "Y":
      case "Z":
        style = "ui-phx-statut-a-revoir";
        break;
      case "A":
        style = "ui-phx-statut-accepte";
        break;
      case "D":
        style = "ui-phx-statut-demande";
        break;
      case "H":
        style = "ui-phx-statut-hors-validation";
        break;
      case "X":
        style = "ui-phx-demande-suppression";
      //Not default
    }
    return style;
  }

  /**
   * Manage restrictions for actions depending on etat and habilitations
   */
  _manageRestrictions(model: CWGererActivitesModel, editable: boolean): void {
    const isRespo = (this.context && this.context.ctxUtilisateur === "Responsable");
    const isActRepo = (!model.isNew() && !this._manageReadOnlyReposOblig(model));

    if (this.context.ctxSaisieMasse === true) {
      return;
    }
    //Il faut mantenir la valeur de 'editable' si c'est 'false' (on fait une operation 'AND') et d'autre part appliquer la valeur de 'isActRepo'
    editable = editable && isActRepo;
    //ETAT Recapitulatif
    if (!CWSTR.isBlank(model) && !CWSTR.isBlank(model.get("recapitulatif")) && !CWSTR.isBlank(model.get("recapitulatif").etat)) {
      if (editable === false) {
        this._disableForm();
      }
      if (editable === true && this.disabled === true) {
        this._enableForm();
      }
      //dans un récapitulatif ne sera pas possible la validation individuelle des activités (l'action sera faite avec la validation du récapitulatif)
      this.btnBar.model.trigger("hide:valider");
      switch (model.get("recapitulatif").etat) {
        case "D":
        case "Z":
        case "C":
        case "G":
          if (!isRespo || (isRespo && this.etatFinalModel.get("valideur") !== true)) {
            this._disableForm();
            this.btnBar.model.trigger("hide:new");
            this.btnBar.model.trigger("hide:delete");
            this.btnBar.model.trigger("hide:declarer");
            this.btnBar.model.trigger("hide:copy");//pour le mode imbriqué 
            this.desactiveHabOuEtat = true;
          }
          break;
        case "A":
          if (!isRespo || (isRespo && this.etatFinalModel.get("valideurfinal") !== true)) {
            this._disableForm();
            this.btnBar.model.trigger("hide:new");
            this.btnBar.model.trigger("hide:delete");
            this.btnBar.model.trigger("hide:declarer");
            this.btnBar.model.trigger("hide:copy");//pour le mode imbriqué 
            this.desactiveHabOuEtat = true;
          } else {
            const isRecap = this._getModel().get("recapitulatif") && !CWSTR.isBlank(this._getModel().get("recapitulatif").code);

            if (isRespo && isRecap && !this.desactiveHabOuEtat && this.disabled === true) {
              this._enableForm();
            }
          }
          break;
        default: //cas "E",  cas "Y",  cas "M" et cas "F"
          this._enableForm();
          this._manageImposeDefaults();
      }
      //statut
      if (!CWSTR.isBlank(model) && !CWSTR.isBlank(model.get("statut")) && !CWSTR.isBlank(model.get("statut").code)) {
        switch (model.get("statut").code) {
          case "H": {
            //dans un récapitulatif ne seront pas affichés les boutons de valider
            if (!isRespo || (isRespo && this.etatFinalModel.get("valideur") !== true && this.etatFinalModel.get("valideurfinal") !== true)) {
              this._disableForm();
              this.btnBar.model.trigger("hide:new");
              this.btnBar.model.trigger("hide:delete");
              this.btnBar.model.trigger("hide:declarer");
              this.btnBar.model.trigger("hide:copy");
              this.desactiveHabOuEtat = true;
            }
            break;
          }
          default:
          //Nothing
        }
      }
      // STATUT Activite
    } else if (!CWSTR.isBlank(model) && !CWSTR.isBlank(model.get("statut")) && !CWSTR.isBlank(model.get("statut").code)) {
      if (editable === true && this.disabled === true) {
        this._enableForm();
      }
      this.btnBar.model.trigger("hide:copy");
      if (this.context.ctxEcran !== "mdepl") {
        this.btnBar.model.trigger("hide:new");
      }
      switch (model.get("statut").code) {
        case "D":
          if (!isRespo) {
            this.btnBar.model.trigger("hide:valider");
            this.btnBar.model.trigger("hide:declarer");
            if (model.get("etat") === "X") {
              this._disableForm();
              this.desactiveHabOuEtat = true;
            }
          } else {
            this._disableForm();
            this.btnBar.model.trigger("hide:delete");
            this.btnBar.model.trigger("hide:declarer");
            this.desactiveHabOuEtat = true;
          }
          break;
        case "A":
          if (!isRespo) {
            this._disableForm();
            this.desactiveHabOuEtat = true;
          }
          this.btnBar.model.trigger("hide:valider");
          break;
        case "R":
          this._disableForm();
          this.btnBar.model.trigger("hide:delete");
          this.btnBar.model.trigger("hide:valider");
          this.btnBar.model.trigger("hide:declarer");
          this.desactiveHabOuEtat = true;
          break;
        case "H":
          if (!isRespo) {
            this._disableForm();
            this.desactiveHabOuEtat = true;
          }
          this.btnBar.model.trigger("hide:valider");
          break;
        case "I":
          if (isRespo) {
            this._disableForm();
            this.desactiveHabOuEtat = true;
            this.btnBar.model.trigger("hide:delete");
          }
          break;
        default:
          this._enableForm();
          this._manageImposeDefaults();
      }
    }
  }

  _manageImposeDefaults(): void {
    if (!this._getModel().isNew() && this.actDefaults.get("valdef_impose") === true) {
      this._blockDefaultValues(this.defaultMaps);
    }
  }

  _disableForm(): void {
    if (this.$el) {
      CWFORMS.setFormReadonly(this.$el, true, false);
    }
    this.disabled = true;
    this._desactiverPieceJointe(true);
  }

  _enableForm(): void {
    CWFORMS.setFormReadonly(this.$el, false, false);
    CWFORMS.setFieldReadonly(this.$el.find(".dureecalculee"), true, false);
    if (this.context.ctxActiviteBesoin === true) {
      CWFORMS.setFieldReadonly(this.$el.find(".actprev"), true);
      CWFORMS.setFieldReadonly(this.$el.find(".datedeb"), true);
      CWFORMS.setFieldReadonly(this.$el.find(".datefin"), true);
      this.$el.find(".typeEvenement .phx-multiLabel label").removeClass("cw-required");
      this.$el.find(".typeSaisie .phx-multiLabel label").removeClass("cw-required");
      this.$el.find(".typeSaisie span").hide();
    }
    if (this.context.ctxCouverturePartielle === false) {
      this.$el.find(".modeSaisie .phx-multiLabel label").removeClass("cw-required");
      CWFORMS.setFieldReadonly(this.$el.find("span.comboUnite :input"), true);
      CWFORMS.setFieldReadonly(this.$el.find("span.comboUniteDeb :input"), true);
      CWFORMS.setFieldReadonly(this.$el.find("span.comboUniteFin :input"), true);
    }
    this.disabled = false;
    this._desactiverPieceJointe(false);
  }

  _manageLink(event: JQueryEventObject): void {
    const element = event.target.className.split(" ")[0];

    switch (element) {
      case "montreraide": // SHOW HELP
        this.$el.find(".montreraide").hide();
        this.$el.find(".masqueraide").show();
        this.$el.find(".indication").show();
        this.stateIndication = false;
        break;
      case "masqueraide": // HIDE HELP
        this.$el.find(".indication").hide();
        this.$el.find(".masqueraide").hide();
        this.$el.find(".montreraide").show();
        this.stateIndication = true;
      //Not default
    }
  }

  _manageSoitValue(mheuredeb: string, mheurefin: string): void {
    const activiteModel = this._getModel();
    const soit = this._calculateDuree(mheuredeb, mheurefin);

    activiteModel.set("dureecalculee", soit);
    this.$el.find("fieldset.gereractivites-detail-form input.dureecalculee").val(CWTYPE.DURATION.HOUR_MINUTE_24.format(activiteModel.get("dureecalculee")));
    CWFORMS.autosizeInput(/*el*/);
  }

  _calculateDuree(mheuredeb: string, mheurefin: string): number {
    let duree = 0;
    const heuredeb = parseInt(mheuredeb, 10);
    const heurefin = parseInt(mheurefin, 10);
    const heuredebBool = !CWSTR.isBlank(heuredeb) && heurefin >= heuredeb;
    const heurefinBool = !CWSTR.isBlank(heurefin) && heurefin >= heuredeb;

    if (heuredebBool === true && heurefinBool === true && (Number(heuredeb) !== 0 || Number(heurefin) !== 0)) {
      const md = heuredeb % 100;
      const mf = heurefin % 100;
      const hd = Math.floor(heuredeb / 100);
      const hf = Math.floor(heurefin / 100);

      // formula to calcul the duration
      if (mf - md < 0) {
        duree = (hf - hd - 1) * 100 + (mf - md) + 60;
      } else {
        duree = (hf - hd) * 100 + (mf - md);
      }
      if (duree < 0) {
        duree = 0;
      }
    }
    return duree;
  }

  _nextDayDuree(mheuredeb: string, mheurefin: string): string {
    let day = "";
    const heuredeb = parseInt(mheuredeb, 10);
    const heurefin = parseInt(mheurefin, 10);
    const heuredebBool = !CWSTR.isBlank(mheuredeb);
    const heurefinBool = !CWSTR.isBlank(mheurefin);

    if (heuredebBool === true && heurefinBool === true && (heuredeb !== 0 || heurefin !== 0) && heuredeb > heurefin) {
      if (!CWSTR.isBlank(this._getModel().get("dtdebinter"))) {
        if (this._getModel().get("dateDebD") && this._getModel().get("dateDebD") < this._getModel().get("dtdebinter")) {
          day = CWTYPE.DATE.format(this._getModel().get("dtdebinter"), CWTYPE._getFormatByCode("DATE_A"));
        } else {
          day = UTILS.addDaysString(this._getModel().get("dtdebinter"), 1);
          day = CWTYPE.DATE.format(day, CWTYPE._getFormatByCode("DATE_A"));
        }
      }
    }
    return day;
  }

  _manageHorsPresence(value: string): void {
    if (value === "E" || value === "S") {
      this.$el.find(".label-presencehorspresence-periode").show();
    } else {
      this.$el.find(".label-presencehorspresence-periode").hide();
    }
  }

  _manageUniteDeb(value: string): void {
    if (value === "H") {
      this.$el.find(".heuredeb-container").show();
      $(".gereractivite-form-inputs .heuredeb-container").css("display", "");
      this._setHeureDeb();
    } else {
      this.$el.find(".heuredeb-container").hide();
    }
  }

  _manageUniteFin(value: string): void {
    if (value === "H") {
      this.$el.find(".heurefin-container").show();
      $(".gereractivite-form-inputs .heurefin-container").css("display", "");
      this._setHeureFin();
    } else {
      this.$el.find(".heurefin-container").hide();
    }
  }

  _manageButonValider(activite: { [key: string]: any }, callback?: () => any): void { //aqui
    if (this.context.ctxActionsPossibles.indexOf("Valider") !== -1 && this.context.ctxUtilisateur.toUpperCase() === "RESPONSABLE" && this.context.ctxModeInitialisation.toUpperCase() === "CONSULTATION") {
      const profilId = activite.get("code");
      const profilModel = new CWBaseModel();
      const lHabVal = (this._getModel() && !CWSTR.isBlank(this._getModel().get("typeevenement"))) ? this._getHabilitationDuContext(this._getModel().get("typeevenement"), "Validation") : this.context.ctxHabilitation.HabilitationValidation;
      const lFoncC = (typeof lHabVal === "string") ? lHabVal : _.omit(lHabVal, "length");

      profilModel.url = (): string => {
        return Configuration.restRoot + "/rest/composant/activite/profils/" + encodeURIComponent(profilId);
      };
      profilModel.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: lFoncC
      }));
      profilModel.fetch({
        success: (): void => {
          if (profilModel.get("profils") && profilModel.get("profils").length > 0) {
            this._getModel().set("profils", profilModel.get("profils"));
            // this._configureValidationButton();
            if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationValidation)) {
              this.btnBar.configureValidationsButtonFromModel(this._getModel());
              this.btnBar.model.trigger("show:valider");
            } else {
              this.btnBar.model.trigger("hide:valider");
            }
          } else {
            this.btnBar.model.trigger("hide:valider");
          }
          if (typeof callback === "function") {
            callback();
          }
        },
        error: (model: any, xhr: any) => {
          if (xhr.status === 550) {
            xhr.status = 200;
            this.btnBar.model.trigger("hide:valider");
          }
          if (typeof callback === "function") {
            callback();
          }
        }
      });
    } else if (typeof callback === "function") {
      callback();
    }
  }

  _managePieceJointe(): void {
    const maxPieces = !CWSTR.isBlank(this.workflow.maxPieces) ? this.workflow.maxPieces.get("maxi") : 0;// Obtain the max number of pieces availables

    if (maxPieces > 0 && this.context.ctxPieceJointe === true && GLOBAL_DATA.paramDivers.get("pJointe").get("valeur") === "1") {
      let currentHabAccess;
      let currentHabGestion;
      const refEvenement = CWSTR.getElValue(this._getModel(), "evenement");
      let newPjHabContext: { [key: string]: any } = null;
      let context: { [key: string]: any } = null;

      this.pieceJointeModified = false;
      if (typeof this.context.ctxHabilitation.HabilitationAcces === 'object') {
        currentHabAccess = this.context.ctxHabilitation.HabilitationAcces[this.model.get('value').get('typeevenement')];
      } else if (typeof this.context.ctxHabilitation.HabilitationAcces === "string") {
        currentHabAccess = this.context.ctxHabilitation.HabilitationAcces;
      }

      if (typeof this.context.ctxHabilitation.HabilitationGestion === 'object') {
        currentHabGestion = this.context.ctxHabilitation.HabilitationGestion[this.model.get('value').get('typeevenement')];
      } else if (typeof this.context.ctxHabilitation.HabilitationGestion === "string") {
        currentHabGestion = this.context.ctxHabilitation.HabilitationGestion;
      }
      newPjHabContext = {
        'HabilitationAcces': currentHabAccess,
        'HabilitationGestion': currentHabGestion,
      }
      context = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: newPjHabContext,
        ctxRefEvenement: refEvenement,
        ctxModeRestitution: "Imbrique",
        ctxModeDefaut: "Replie",
        ctxActionsPossibles: this.disabled ? [] : ["Creer", "Supprimer"],
        ctxGestionCollab: this.context.ctxGestionCollab
      };
      // Create the component
      this.pieceJointe = new CWPieceJointes({
        context: context,
        type: "PJEVTACT",
        maxPieces: maxPieces,
        mode: this.context.ctxModeInitialisation,
        appendTo: (this.context.ctxModeRepresentation !== "pop-up") ? this.context.ctxAppendTo : null,
        modal: true
      });
      //overwrite managebuttons function to check if the absence can be modified or not.
      this.pieceJointe._oldManageButtons = this.pieceJointe._manageButtons;
      this.pieceJointe._manageButtons = (event: JQueryEventObject): void => {
        this._isModifiable(event, (): void => {
          this.pieceJointe._oldManageButtons(event);
        });
      };
      $(this.el).find(".gereractivites-piecesjointes").html(this.pieceJointe.render().el);
      this.pieceJointe.setContext(context);
      this.pieceJointe.setDescription(this._getActivitePJDescription(this._getModel()));
      this.pieceJointe.setPiecesJointes(this._getModel().get("piecesjointes"));
      this.pieceJointe.model.on("piecejointeChanged", (): void => {
        this.model.trigger("form:edited");
        this.pieceJointeModified = true;
      }, this);
    } else {
      $(this.el).find(".gereractivites-piecesjointes").hide();
    }
  }

  /**
   * TODO
   * Function Used by PiecesJointes
   */
  _getActivitePJDescription(arg: any): string { //eslint-disable-line
    return "";
  }

  getState(): string {
    return this.btnBar.model.get("mode");
  }

  manageIndicationVisibility(gererActivite: { [key: string]: any }): void {
    //Show or hide indication
    if (!CWSTR.isBlank(gererActivite.get("activite")) && gererActivite.get("indication")) {
      this.$el.find('.typeEvenement .multiLabel-iconHelp').removeClass('d-none').attr('title', gererActivite.get("indication"));
    } else {
      this.$el.find('.typeEvenement .multiLabel-iconHelp').addClass('d-none').attr('title', "");
    }
  }

  _manageModeSaisie(): void {
    const gererActivite = this._getModel();

    if (gererActivite.get("typesaisie") === "P") {
      this.$el.find(".modeSaisie").hide();
    } else {
      this.$el.find(".modeSaisie").show();
    }
  }
  /**
   * Manage the zone : «Informations liées à l’activité»
   */
  _manageLegend(): void {
    const $fieldset = this.$el.find("fieldset.gereractivites-detail-form");

    if (this.context.ctxValeursEvenement.ActEnSuppression === true) {
      $fieldset.find("legend").html(i18n.t('common:gereractivites.gererlegend_delete')).addClass("ui-phx-titre-demande-suppression");
    } else {
      $fieldset.find("legend").html(i18n.t('common:gereractivites.gererlegend')).removeClass("ui-phx-titre-demande-suppression");
    }
  }

  /**
   * Map the multi Labels with the model data
   */
  _manageMultiLabels(): void {
    const gererActivite = this._getModel();
    const lTypeEvt = gererActivite.get("typeevenement") === "P" ? "PRE" : "REA"; //Il faut pour différencier de "P" -> "Periode" et "R"-> Répétition du mode saissie dans _changeTypeSaisie

    this.typeEvenement.setLabelByName(lTypeEvt);
    this.typeSaisie.setLabelByName(gererActivite.get("typesaisie"));
    if (gererActivite.get("typesaisie") !== "P") { //if not "PERIOC"
      this.modeSaisie.setLabelByName(gererActivite.get("modesaisie"));
    }
  }

  /**
   * Checks if we need to set the panel in ReadOnly
   */
  _panelStateIsReadonly(): boolean {
    if (this._getModel()) {
      const lHabGestion = this._getHabilitationDuContext(this._getTypeEvt(), "Gestion");
      const canUpdate = CWHABILITATION.canUpdate(lHabGestion);

      return !canUpdate && !this._getModel().isNew();
    }
    return false;
  }

  /**
   * Make the From editable or not depending on the habilitations
   */
  _panelStateRender(): void {
    const isReadonly = this._panelStateIsReadonly();

    CWFORMS.setFormReadonly(this.$el, isReadonly, true);
    if (this.context.ctxActiviteBesoin === true) {
      CWFORMS.setFieldReadonly(this.$el.find(".actprev"), true);
      CWFORMS.setFieldReadonly(this.$el.find(".datedeb"), true);
      CWFORMS.setFieldReadonly(this.$el.find(".datefin"), true);
      this.$el.find(".typeEvenement .phx-multiLabel label").removeClass("cw-required");
      this.$el.find(".typeSaisie .phx-multiLabel label").removeClass("cw-required");
      this.$el.find(".typeSaisie span").hide();
      this.$el.find(".modeSaisie .phx-multiLabel .phx-multiLabel-btn").remove();
    }
    if (this.context.ctxCouverturePartielle === false) {
      this.$el.find(".modeSaisie .phx-multiLabel label").removeClass("cw-required");
      CWFORMS.setFormReadonly(this.$el.find(".comboUnites"), false);
      CWFORMS.setFormReadonly(this.$el.find(".comboUniteDeb"), false);
      CWFORMS.setFormReadonly(this.$el.find(".comboUniteFin"), false);
    }
    // add special fields manually (tables, combos, ...)
    if (isReadonly) {
      this.typeSaisie.setMenuAccess(false, true);
      this.typeEvenement.setMenuAccess(false, true);
      this.modeSaisie.setMenuAccess(false, true);
    }
  }

  /**
   * Same as Change but for multilabel component
   */
  _changeMultiLabel(name: string, selectedValue: any): void {
    const gererActivite = this._getModel();
    const prevElValue = CWSTR.getElValue(gererActivite, name);

    this.modeSaisieSelected = selectedValue;
    if (name === "typeevenement") {
      const lTypeEvt = selectedValue[0]; //"PRE" -> "P" , "REA"->"R"
      let $modeComplet: JQuery = null;

      CWSTR.setElValue(gererActivite, name, lTypeEvt);
      if (this.selectActPrevue) {
        this.selectActPrevue.clean();
      }
      if (this.selectActRealisee) {
        this.selectActRealisee.clean();
      }
      //Reset Model ref.attributes
      CWSTR.setElValue(gererActivite, "activite", "", { silent: true });
      CWSTR.setElValue(gererActivite, "libelle", "", { silent: true });
      $modeComplet = this.$el.find(".selecteurModeComplet");
      if ($modeComplet.is(":visible") && (prevElValue !== lTypeEvt)) {
        this.$el.find(".selecteurModeComplet").hide();
        this._hideImbrique();
      }
    } else {
      CWSTR.setElValue(gererActivite, name, selectedValue);
      this.modeSaisieSelected = selectedValue;
      if (gererActivite.isNew()) { //Flag to know if modesaisie or typesaisie have changed in creation mode;
        this.saisieChanged = true;
      }
    }
  }

  /**
   * Type Saisie change event.
   */
  _changeTypeSaisie(event: JQueryEventObject): void {
    let className = event.target.className.split(" ")[0];
    const value = this._getModel();
    const lMode = "Gestion"; //seulement en mode Gestion est possible la modification : ce n'est pas necessaire faire ->(this.context.ctxModeInitialisation === "Consultation") ? "Acces" : "Gestion";
    const typologie = GLOBAL_DATA.typologies.get(this.context.ctxTypologieActivite);
    const oldAttributes = this._getModel().oldAttributes ? JSON.parse(this._getModel().oldAttributes) : null;

    if (className !== "typeevenement" && this.context && this.context.ctxSaisieMasse === true && this.modeSaisieSelected) {
      value.set(className, this.modeSaisieSelected);
      className = this.modeSaisieSelected;
    }
    value.validationError = null;
    switch (className) {
      case "PRE":
      case "REA":
        value.set("typeevenement", className[0]); //className[0] -> "P" ou "R"
        //on change l'habilitation au type correct
        this._updateHabilitationForChangeTypeEvt(this._getHabilitationDuContext(className[0], lMode));
        break;
      case "P":
        value.set("modesaisie", "PERIOC");
        this.$el.find(".modeSaisie").hide();
        // contruct valid dates in mode Periode
        this._InitDatesPeriode();
        //Evo #692 update datefin to datedeb +1
        if (typologie.get('typeGestionActivite') === "REPOBLIG") {
          this.initReposObligPeriod();
        }
        value.set("heuredeb", this.actDefaults.get("per_hdebdef"));
        value.set("heurefin", this.actDefaults.get("per_hfindef"));
        //set the values of comboUniteDeb and comboUniteFin
        this.setDefaultActiviteValues(value, this.actDefaults, true, true);
        this.$el.find(".typesaisie").focus();
        break;
      case "R":
        if (value.get("modesaisie") === "PERIOC") {
          value.set("modesaisie", "PLH");
        }
        // we dont need to increase datefin in Mode Repetition.
        this._setUniteDeb(value);
        value.set("heuredeb", this.actDefaults.get("dat_hdebdef"));
        value.set("heurefin", this.actDefaults.get("dat_hfindef"));
        value.set("datefin", value.get("datedeb"));
        if (!value.get("modesaisie")) {
          value.set("modesaisie", "PLH");
        }
        break;
      case "DUREE":
        value.set("duree", this.actDefaults.get("dat_dureedef"));
        break;
      case "POURCENT":
        value.set("pourcentage", this.actDefaults.get("dat_pourcentdef"));
        break;
      // falls through
      case "UNITE":
        this._setUniteDeb(value);
        break;
      default:
        if (value.get("modesaisie") === "PERIOC" || value.get("modesaisie") === "UNITE") {
          let modesaisie = "PLH";
          const modeSaisieOptionsInformed = (!CWSTR.isBlank(this.actModeSaisieOptionsColl) && this.actModeSaisieOptionsColl.models.length > 0);

          //When changing from type saisie PERIOD to another typesaisie select first valid modesaisie
          if (modeSaisieOptionsInformed === true) {
            modesaisie = modeSaisieOptionsInformed === true ? this.actModeSaisieOptionsColl.at(0).get("code") : "PLH";
          }
          value.set("modesaisie", modesaisie);
        }
        this.$el.find(".modeSaisie").show();
        value.set("heuredeb", this.actDefaults.get("dat_hdebdef"));
        value.set("heurefin", this.actDefaults.get("dat_hfindef"));
        this._setUniteDeb(value);
        //Evo #692 Set filed datedeb readOnly if typesaisie is date + display text GME 127
        if (this.context.ctxModeInitialisation === "Consultation" && oldAttributes && oldAttributes.typesaisie === "D" && value.get('typesaisie') === "D") {
          const $datedeb = this.$el.find(".datedeb");

          CWFORMS.setFieldReadonly($datedeb, true);
        }
    }
    this._manageCheckAtribAuto();
    this._notifyEdition(event);
    this._mapToForm();
  }

  protected initReposObligPeriod(): void {
    const dateAux1 = CWTYPE.DATE.strToDate(this._getModel().get('datedeb'));

    if (!CWSTR.isBlank(dateAux1)) {
      const dateFin = CWTYPE.DATE.dateToStr(UTILS.addDays(dateAux1, 1), CWTYPE.DATE.DEFAULT_MASK_BBDD);

      this._getModel().set({ "datefin": dateFin }, { silent: true });
      this._getModel().set({ "dateFinP": dateFin }, { silent: true });
    }
    if (this.context.ctxModeInitialisation === "Consultation") {
      const $datedeb = this.$el.find(".datedeb");

      CWFORMS.setFieldReadonly($datedeb, false);
    }
  }

  // avoid loose Unite deb in changes of typesaisie
  _setUniteDeb(value: { [key: string]: any }): void {
    if (CWSTR.isBlank(value.get("unitedeb"))) {
      value.set("unitedeb", this.actDefaults.get("saisie_dateunitedef"));
    }
  }

  _getTypegestForUnites(activiteObject: { [key: string]: any }, setUnitesCallback: (arg: string) => void): void {
    if (!CWSTR.isBlank(activiteObject.get("typegest"))) {
      setUnitesCallback(activiteObject.get("typegest"));
    } else {
      if (this.context.ctxGestionCollective === true) {
        setUnitesCallback(null);
      } else {
        if (CWSTR.isBlank(this.typeGestionOfCollab) || this.typeGestionOfCollab.collab !== this.context.ctxGestionCollab.matricule || this.typeGestionOfCollab.date !== this._getModel().get("datedeb")) {
          const datedebgest = this._getModel().get("datedeb") ? this._getModel().get("datedeb") : this.context.ctxValeursCreation.ActDateDebut;

          if (CWSTR.isBlank(this.context.ctxGestionCollab.matricule) || CWSTR.isBlank(datedebgest)) {
            setUnitesCallback(null);
          } else {
            const lTypeEvt = this._getTypeEvt();
            const lAccess = (this.context.ctxModeInitialisation === "Consultation") ? "Acces" : "Gestion";
            const lHabAcces = this._getHabilitationDuContext(lTypeEvt, lAccess);

            this.typeGestionOfCollab = new CWTypeGestionModel({
              collab: this.context.ctxGestionCollab.matricule,
              date: datedebgest
            });
            //ctxHabilitation.HabilitationAcces["P"]
            this.typeGestionOfCollab.setHabContext(new CWHabilitationContext({
              onglet: this.context.ctxEcran,
              foncCour: lHabAcces
            }));
            this.typeGestionOfCollab.fetch({
              success: (fresh: any): void => {
                setUnitesCallback(fresh.get("typegest"));
              }
            });
          }
        } else {
          setUnitesCallback(this.typeGestionOfCollab.get("typegest"));
        }
      }
    }
  }

  _mapUnitesForCombo(model: { [key: string]: any }): { [key: string]: any } {
    return {
      code: model.get("code"),
      libelle: model.get("libelle")
    };
  }

  /**
   *
   */
  _updateCombosForActivite(activiteObject: { [key: string]: any }, callback?: () => void): void {
    const dateError = CWTYPE.DATE.validate(this._getModel().get("datedeb"));
    const setUnitesCallback = (typeGest: string): void => {
      this.uniteComboColl.code = activiteObject.get("activite");
      this.uniteComboColl.typegest = typeGest;
      this.uniteComboColl.dateper = "DREPD";
      if (activiteObject.context) {
        this.uniteComboColl.besactid = activiteObject.context.ctxBesActId;
      }
      this.unitedebComboColl.params = { code: activiteObject.get("activite") };
      this.unitefinComboColl.params = { code: activiteObject.get("activite") };
      this.unitedebComboColl.fetch({
        success: (): void => {
          this.unitefinComboColl.fetch({
            success: (): void => {
              this.uniteComboColl.fetch({
                success: (): void => {
                  this.comboUnites.enum = this.uniteComboColl.map(this._mapUnitesForCombo);
                  this.comboUniteFin.enum = this.unitefinComboColl.map(this._mapUnitesForCombo);
                  this.comboUniteDeb.enum = this.unitedebComboColl.map(this._mapUnitesForCombo);
                  this.comboUnites.clearCache();
                  this.comboUniteDeb.clearCache();
                  this.comboUniteFin.clearCache();
                  if (callback) {
                    callback();
                  }
                }
              });
            }
          });
        }
      });
    };

    if (CWSTR.isBlank(dateError)) {
      this._getTypegestForUnites(activiteObject, setUnitesCallback);
    }
  }
  /**
   *
   */
  _emptyCombosUnite(): void {
    this.uniteComboColl.params = {};
    this.comboUnites.clearCache();
    this.unitedebComboColl.params = {};
    this.comboUniteDeb.clearCache();
    this.unitefinComboColl.params = {};
    this.comboUniteFin.clearCache();
  }

  _actSelectedChanged(callbackOrigin?: () => any): void {
    const gererActivite = this._getModel();

    //ctxHabilitation.HabilitationAcces["P"]
    // activite dans un récapitulatif : pas de commentaire
    if (gererActivite.get("recapitulatif") && !CWSTR.isBlank(gererActivite.get("recapitulatif").code)) {
      this.$el.find(".blockCommentaire").hide();
    }
    if (!CWSTR.isBlank(gererActivite.get("activite"))) {
      const lTypeEvt = this._getTypeEvt();
      const lAccess = (this.context.ctxModeInitialisation === "Consultation") ? "Acces" : "Gestion";
      const lHabAcces = this._getHabilitationDuContext(lTypeEvt, lAccess);
      const savTypeSaisie = gererActivite.get("typesaisie");
      const savModesaiPeriodeb = gererActivite.get("modesai_periodeb");
      const savModesaiPeriofin = gererActivite.get("modesai_periofin");
      const savModeSaisie = gererActivite.get("modesaisie");
      const callback = (): void => {
        /*isClosed: The check is made for whether the pop-up has been closed with the ESC key.
        If the check is not performed and the pop-up has been closed, an error will occur*/
        if (!this.isClosed) {
          this.actDefaults.fetch({
            success: (fresh: any): void => {
              let findCarPerDeplac: { [key: string]: any } = null;
              const freshStock = new CWActiviteDefaults(JSON.parse(JSON.stringify(fresh)));// copy fresh to allow callback using it

              // activité non gérée dans un récapitulatif
              if (gererActivite.get("recapitulatif") && CWSTR.isBlank(gererActivite.get("recapitulatif").code)) {
                if ((gererActivite.get("typeevenement") === "P" && fresh.get("soumiswkf_p")) || (gererActivite.get("typeevenement") === "R" && fresh.get("soumiswkf_r"))) {
                  // activite gérée en workflow : commentaire
                  this.$el.find(".blockCommentaire").show();
                } else {
                  // activité non gérée en workflow : pas de commentaire
                  this.$el.find(".blockCommentaire").hide();
                }
              }
              //gererActivite.set("infocomp", fresh.get("infocomp"));
              gererActivite.set("carpers", fresh.get("carpers"));
              findCarPerDeplac = _.find(fresh.get("carpers"), { "codif": "GMETD" });
              if (!_.isEmpty(findCarPerDeplac) && !CWSTR.isBlank(findCarPerDeplac.valeur)) {
                if (!_.isEmpty(findCarPerDeplac.valeurcodif)) {
                  this._getModel().set("typedeplac", findCarPerDeplac.valeurcodif);
                } else {
                  this._getModel().get("typedeplac").code = findCarPerDeplac.valeur;
                }
              }
              gererActivite.set("typesaisie", fresh.get("saisie_typedef").code);
              gererActivite.set("indication", fresh.get("indication"));
              if (CWSTR.isBlank(fresh.get("dat_hdebdef") && CWSTR.isBlank(fresh.get("dat_hdebdef")))) {
                if (gererActivite.get("modesaisie") !== "PLH" || CWSTR.isBlank(gererActivite.get("heuredeb")) || CWSTR.isBlank(gererActivite.get("heurefin")) || gererActivite.get("heuredeb") === gererActivite.get("heurefin")) {
                  gererActivite.set("heuredeb", this.context.ctxValeursCreation.ActHeureDebut || "");
                  gererActivite.set("heurefin", this.context.ctxValeursCreation.ActHeureFin || "");
                }
                //en autre cas, les heures se doivent laisser si l'elles sont remplies
              } else {
                if (gererActivite.isNew() || (CWSTR.isBlank(gererActivite.get("heuredeb")) && CWSTR.isBlank(gererActivite.get("heurefin")))) {
                  gererActivite.set("heuredeb", fresh.get("dat_hdebdef"));
                  gererActivite.set("heurefin", fresh.get("dat_hfindef"));
                }
                //en autre cas, les heures se doivent laisser si l'elles sont remplies
              }
              gererActivite.set("modesai_periodeb", fresh.get("saisie_debperiodedef"));
              gererActivite.set("modesai_periofin", fresh.get("saisie_finperiodedef"));
              this.actTypeSaisieOptionsColl.activite = gererActivite.get("activite");
              if (_.isEmpty(this.actTypeSaisieOptionsColl.habContext) || _.isEmpty(this.actModeSaisieOptionsColl.habContext)) {
                const lTypeEvt = this._getTypeEvt();
                const lAccess = (this.context.ctxModeInitialisation === "Consultation") ? "Acces" : "Gestion";
                const lHabAcces = this._getHabilitationDuContext(lTypeEvt, lAccess);

                this.actTypeSaisieOptionsColl.setHabContext(new CWHabilitationContext({
                  onglet: this.context.ctxEcran,
                  foncCour: lHabAcces
                }));
                this.actModeSaisieOptionsColl.setHabContext(new CWHabilitationContext({
                  onglet: this.context.ctxEcran,
                  foncCour: lHabAcces
                }));
              }
              // Dans le cas des recaps, le type de saisie n'est pas surchargé sur la valeur initialisée par l'action du bouton Ajouter
              if (gererActivite.get("recapitulatif") && gererActivite.get("modesaisie") === "PERIOC") {
                gererActivite.set("typesaisie", savTypeSaisie);
                gererActivite.set("modesai_periodeb", savModesaiPeriodeb);
                gererActivite.set("modesai_periofin", savModesaiPeriofin);
                gererActivite.set("modesaisie", savModeSaisie);
              }
              this.actTypeSaisieOptionsColl.fetch({
                success: (): void => {
                  this.actModeSaisieOptionsColl.activite = gererActivite.get("activite");
                  this.actModeSaisieOptionsColl.fetch({
                    success: (): void => {
                      const currentTypeSaisie = gererActivite.get("typesaisie");
                      const currentModeSaisie = gererActivite.get("modesaisie");
                      const typeSaisieFound = _.find(this.actTypeSaisieOptionsColl.models, (typesaisieModel: { [key: string]: any }): boolean => {
                        return typesaisieModel.get("code") === currentTypeSaisie;
                      });
                      let modeSaisieFound = null;
                      let callback2 = null;
                      let emptyEnteringContext = false;

                      if (currentTypeSaisie === "P") {
                        //When current typesaisie=Period, current modesaisie is going to be  perioc but this modesaisie doesn't come in the WS of mode
                        //Saisies allowed, so we suppose it is allowed
                        modeSaisieFound = "PERIOC";
                      } else {
                        modeSaisieFound = _.find(this.actModeSaisieOptionsColl.models, (modesaisieModel: { [key: string]: any }): boolean => {
                          return modesaisieModel.get("code") === currentModeSaisie;
                        });
                      }
                      //Le type de saisie ou le mode de saisie ne fait pas partie des types et modes de saisies autorisés paramétrés pour l’activité,
                      //Le type de saisie et le mode de saisie correspondent à une initialisation suite à un contexte de création sans valeurs renseignées
                      //et il n’y a pas eu de modification du type et/ou du mode ou de saisie de valeurs par l’utilisateur
                      emptyEnteringContext = CWSTR.isBlank(this.context.ctxValeursCreation.ActCode) && CWSTR.isBlank(this.context.ctxValeursCreation.ActDateDebut) && CWSTR.isBlank(this.context.ctxValeursCreation.ActDateFin);
                      if (this.context && this.context.ctxSaisieMasse === true) {
                        emptyEnteringContext = false;
                      }
                      this.$el.parent().parent().css("height", "auto");
                      if (CWSTR.isBlank(typeSaisieFound) || CWSTR.isBlank(modeSaisieFound) || (this.context.ctxModeInitialisation === "Ajout" && emptyEnteringContext === true && this.saisieChanged !== true)) {
                        callback2 = (): void => {
                          this.setDefaultActiviteValues(gererActivite, freshStock, true);
                          if (UTILS.isIE()) {
                            this.$el.find(".typesaisie").focus();
                          }
                        };
                      } else {
                        if (!CWSTR.isBlank(typeSaisieFound) && !CWSTR.isBlank(modeSaisieFound) && (this.saisieChanged || this.context.ctxModeInitialisation !== "Ajout")) {
                          callback2 = null;
                        } else {
                          callback2 = (): void => {
                            this.setDefaultActiviteValues(gererActivite, freshStock, fresh.get("valdef_impose"), true);
                            this._mapToForm();
                            if (UTILS.isIE()) {
                              this.$el.find(".typesaisie").focus();
                            }
                          };
                        }
                      }
                      if (typeof callbackOrigin === "function") {
                        callbackOrigin();
                      }
                      this.checkUnitesCoherence(gererActivite, callback2, this.actDefaults);
                    }
                  });
                }
              });
            }
          });
        } else {
          if (typeof callbackOrigin === "function") {
            callbackOrigin();
          }
        }
      };

      if (CWSTR.isBlank(this.actDefaults)) {
        this.actDefaults = new CWActiviteDefaults();
        this.actDefaults.setHabContext(new CWHabilitationContext({
          onglet: this.context.ctxEcran,
          foncCour: lHabAcces
        }));
      } else {
        this.actDefaults.updateHabContext({ "foncCour": lHabAcces });
      }
      this.actDefaults.id = gererActivite.get("activite");
      this.actDefaults.hierid = gererActivite.get("hierid");
      this.actDefaults.itemid = gererActivite.get("straid");
      $(document.activeElement).blur();
      this._updateCombosForActivite(gererActivite, callback);
    } else {
      this._emptyCombosUnite();
      this._initializeOptionsColl();
      if (typeof callbackOrigin === "function") {
        callbackOrigin();
      }
    }
  }

  setDefaultActiviteValues(gererActivite: { [key: string]: any }, defaultValues: { [key: string]: any }, changeTypeSaisie: boolean, avoidMapToForm?: boolean): void {
    const defaultPropertiesMap: string[] = [];

    if (!CWSTR.isBlank(defaultValues.get("saisie_modesaidatedef")) && !CWSTR.isBlank(defaultValues.get("saisie_modesaidatedef").code)) {
      gererActivite.set("modesaisie", defaultValues.get("saisie_modesaidatedef").code);
      defaultPropertiesMap.push("modesaisie");
    } else {
      const modeSaisieFound = this.actModeSaisieOptionsColl.findWhere({ code: gererActivite.get("modesaisie") });//Check that current modesaisie is valid for activite, if it is not,set one by default

      if (CWSTR.isBlank(modeSaisieFound)) {
        const firstModel = this.actModeSaisieOptionsColl.at(0);
        const code = !CWSTR.isBlank(firstModel) ? firstModel.get("code") : null;

        gererActivite.set("modesaisie", code);
      }
    }
    gererActivite.set("carpers", defaultValues.get("carpers"));
    //gererActivite.set("infocomp", defaultValues.get("infocomp"));
    if (!CWSTR.isBlank(defaultValues.get("saisie_typedef")) && !CWSTR.isBlank(defaultValues.get("saisie_typedef").code)) {
      if (changeTypeSaisie !== false) {
        defaultPropertiesMap.push("typesaisie");
      }
      if (gererActivite.get("typesaisie") === "P") {
        gererActivite.set("modesaisie", "PERIOC");
        defaultPropertiesMap.push("modesaisie");
      } //If typesaisie=P, modesaisie is PERIOC
    }
    gererActivite.set("typehorspresence", defaultValues.get("typehorspresence"));
    defaultPropertiesMap.push("typehorspresence");
    /*	Du SFD: Affichage pour le mode de saisie en présence complète
      -	Dans ce cas, la case à cocher est uniquement pour habiller le formulaire. Elle est cochée par défaut et non modifiable.
       */
    gererActivite.set("presence", true);
    CWFORMS.setFieldReadonly(".presence", true);
    /*Du SFD:	Affichage pour le mode de saisie en reliquat du temps effectif non affecté
      -	Dans ce cas, la case à cocher est uniquement pour habiller le formulaire. Elle est cochée par défaut et non modifiable.
      horspresence --> reliquat
     */
    gererActivite.set("horspresence", true);
    CWFORMS.setFieldReadonly(".horspresence", true);
    if (!CWSTR.isBlank(defaultValues.get("saisie_debperiodedef")) && !CWSTR.isBlank(defaultValues.get("saisie_debperiodedef").code)) {
      switch (defaultValues.get("saisie_debperiodedef").code) {
        case "U": {
          let periodeb = { code: "", libelle: "" };

          if (!CWSTR.isBlank(defaultValues.get("saisie_debunitedef")) && !CWSTR.isBlank(defaultValues.get("saisie_debunitedef").code)) {
            periodeb = defaultValues.get("saisie_debunitedef");
            if (periodeb.code.indexOf("U_") !== 0) {
              periodeb.code = "U_" + periodeb.code;
            }
          }
          gererActivite.set("modesai_periodeb", periodeb);
          defaultPropertiesMap.push("modesai_periodeb");
        }
          break;
        case "H":
          gererActivite.set("modesai_periodeb", { code: "H", libelle: "" });
          break;
        case "X":
          gererActivite.set("modesai_periodeb", { code: "S", libelle: "" });
        // not default
      }
      defaultPropertiesMap.push("modesai_periodeb");
    }
    if (!CWSTR.isBlank(defaultValues.get("saisie_finperiodedef")) && !CWSTR.isBlank(defaultValues.get("saisie_finperiodedef").code)) {
      switch (defaultValues.get("saisie_finperiodedef").code) {
        case "U": {
          let periofin = { code: "", libelle: "" };

          if (!CWSTR.isBlank(defaultValues.get("saisie_finunitedef")) && !CWSTR.isBlank(defaultValues.get("saisie_finunitedef").code)) {
            periofin = defaultValues.get("saisie_finunitedef");
            if (periofin && periofin.code && periofin.code.indexOf("U_") !== 0) {
              periofin.code = "U_" + periofin.code;
            }
          }
          gererActivite.set("modesai_periofin", periofin);
          defaultPropertiesMap.push("modesai_periofin");
        }
          break;
        case "H":
          gererActivite.set("modesai_periofin", { code: "H", libelle: "" });
          break;
        case "X":
          gererActivite.set("modesai_periofin", { code: "E", libelle: "" });
        // not default
      }
      defaultPropertiesMap.push("modesai_periofin");
    }
    if (gererActivite.get("typesaisie") !== "P" && !CWSTR.isBlank(defaultValues.get("saisie_dateunitedef")) && !CWSTR.isUndefined(defaultValues.get("saisie_dateunitedef").code)) {
      if (CWSTR.isBlank(gererActivite.get("unitedeb")) || CWSTR.isBlank(gererActivite.get("unitedeb").code)) {
        gererActivite.set("unitedeb", defaultValues.get("saisie_dateunitedef"));
        defaultPropertiesMap.push("unitedeb");
      }
    }
    if (!CWSTR.isBlank(defaultValues.get("saisie_typedef")) &&
      !CWSTR.isBlank(defaultValues.get("saisie_typedef").code)) {
      if (gererActivite.get("modesaisie") === "PERIOC") {
        if (CWSTR.isBlank(gererActivite.get("heuredeb"))) {
          gererActivite.set("heuredeb", defaultValues.get("per_hdebdef"));
          defaultPropertiesMap.push("heuredeb");
        }
        if (CWSTR.isBlank(gererActivite.get("heurefin"))) {
          gererActivite.set("heurefin", defaultValues.get("per_hfindef"));
          defaultPropertiesMap.push("heurefin");
        }
      }
      if (!changeTypeSaisie) {
        if (defaultValues.get("saisie_typedef").code === "D" || defaultValues.get("saisie_typedef").code === "R") {
          if (CWSTR.isBlank(gererActivite.get("heuredeb"))) {
            gererActivite.set("heuredeb", defaultValues.get("dat_hdebdef"));
          }
          if (CWSTR.isBlank(gererActivite.get("heurefin"))) {
            gererActivite.set("heurefin", defaultValues.get("dat_hfindef"));
          }
          defaultPropertiesMap.push("heuredeb");
          defaultPropertiesMap.push("heurefin");
        }
      }
    }
    if (!CWSTR.isBlank(defaultValues.get("dat_dureedef"))) {
      gererActivite.set("duree", defaultValues.get("dat_dureedef"));
      defaultPropertiesMap.push("duree");
    }
    if (!CWSTR.isBlank(defaultValues.get("dat_pourcentdef"))) {
      gererActivite.set("pourcentage", defaultValues.get("dat_pourcentdef"));
      defaultPropertiesMap.push("pourcentage");
    }
    if (!CWSTR.isBlank(defaultValues.get("dat_presdefav"))) {
      gererActivite.set("horspresav", defaultValues.get("dat_presdefav"));
      defaultPropertiesMap.push("dat_pourcentdef");
    }
    if (!CWSTR.isBlank(defaultValues.get("dat_presdefap"))) {
      gererActivite.set("horspresap", defaultValues.get("dat_presdefap"));
      defaultPropertiesMap.push("horspresap");
    }
    //Display information label about horspresence
    this._manageLabelPresenceHorsPresence(gererActivite.get("presence"), gererActivite.get("horspresence"), gererActivite.get("typehorspresence"));
    this.defaultMaps = defaultPropertiesMap;
    if (CWSTR.isBlank(avoidMapToForm) || avoidMapToForm === false) {
      this._mapToForm();
    }
    //when setting default values for the new activite,validate because there might be errors
    //from previous activite type
    this._getModel().isValid();
    ///if faut nettoyer les erreurs produits par le changement de l'activité
    this._cleanDefaultValidationErrorsGererActivite();
    this.workflow.trigger("change:defaultActiviteValues"); //trigger pour s'il fait faire "_cleanValidationErrors"
  }

  _enableValuesActivite(): void {
    this.typeSaisie.setMenuAccess(!this._panelStateIsReadonly());
    if (this.context.ctxCouverturePartielle === false) {
      CWFORMS.setFormReadonly(this.$el.find(".comboUnites"), true);
      CWFORMS.setFormReadonly(this.$el.find(".comboUniteDeb"), true);
      CWFORMS.setFormReadonly(this.$el.find(".comboUniteFin"), true);
      this.$el.find(".modeSaisie .phx-multiLabel label").removeClass("cw-required");
    } else {
      CWFORMS.setFormReadonly(this.$el.find(".comboUnites"), false);
      CWFORMS.setFormReadonly(this.$el.find(".comboUniteDeb"), false);
      CWFORMS.setFormReadonly(this.$el.find(".comboUniteFin"), false);
    }
    if (this.context.ctxActiviteBesoin === true) {
      CWFORMS.setFieldReadonly(this.$el.find(".actprev"), true);
      CWFORMS.setFieldReadonly(this.$el.find(".datedeb"), true);
      CWFORMS.setFieldReadonly(this.$el.find(".datefin"), true);
      this.$el.find(".typeEvenement .phx-multiLabel label").removeClass("cw-required");
      this.$el.find(".typeSaisie .phx-multiLabel label").removeClass("cw-required");
      this.$el.find(".typeSaisie span").hide();
    }
    CWFORMS.setFieldReadonly(".heuredeb", false);
    CWFORMS.setFieldReadonly(".heurefin", false);
    CWFORMS.setFieldReadonly(".pourcentage", false);
    CWFORMS.setFieldReadonly(".duree", false);
    //Hors présence, avant ->horspresav et après -> horspresap
    CWFORMS.setFieldReadonly(".horspresav", false);
    CWFORMS.setFieldReadonly(".horspresap", false);
  }

  _blockDefaultValues(defaultPropsFilled: string[]): void {
    if (defaultPropsFilled && defaultPropsFilled.length > 0) {
      CWFORMS.setFormReadonly(this.$el.find(".comboUnites"), this.hasElement(defaultPropsFilled, "unitedeb"));
      CWFORMS.setFieldReadonly(this.$el.find(".heuredeb"), this.hasElement(defaultPropsFilled, "heuredeb"));
      CWFORMS.setFieldReadonly(this.$el.find(".heurefin"), this.hasElement(defaultPropsFilled, "heurefin"));
      CWFORMS.setFieldReadonly(this.$el.find(".duree"), this.hasElement(defaultPropsFilled, "duree"));
      CWFORMS.setFieldReadonly(this.$el.find(".pourcentage"), this.hasElement(defaultPropsFilled, "pourcentage"));
      CWFORMS.setFieldReadonly(this.$el.find(".horspresav"), this.hasElement(defaultPropsFilled, "horspresav"));
      CWFORMS.setFieldReadonly(this.$el.find(".horspresap"), this.hasElement(defaultPropsFilled, "horspresap"));
    }
  }

  protected hasElement(elementsArray: string[], e: string): boolean {
    return elementsArray.indexOf(e) > -1;
  }

  /**
   * Display label of presence
   */
  _manageLabelPresenceHorsPresence(isPresence: boolean, isHorsPresence: boolean, typeHorsPresence: string): void {
    let labelPresenceHorsPresence = "<br>";

    if (isPresence && isHorsPresence) {
      labelPresenceHorsPresence = i18n.t('common:gereractivites.indif_presence');
    } else if (isPresence && !isHorsPresence) {
      labelPresenceHorsPresence = i18n.t('common:gereractivites.sur_presence');
    } else if (!isPresence && isHorsPresence && "E" === typeHorsPresence) {
      labelPresenceHorsPresence = i18n.t('common:gereractivites.horspresence_eff');
    } else if (!isPresence && isHorsPresence && "H" === typeHorsPresence) {
      labelPresenceHorsPresence = i18n.t('common:gereractivites.horspresence_autoparhor');
    }
    this.$el.find(".label-presencehorspresence").html(labelPresenceHorsPresence);
    this.$el.find(".label-presencehorspresence-periode").html(labelPresenceHorsPresence);
  }

  checkUnitesCoherence(gererActivite: { [key: string]: any }, callback?: () => void, actDefaultObject?: { [key: string]: any }): void {
    const allowedUnite = this.uniteComboColl.findWhere({ code: gererActivite.get("unitedeb").code });
    let allowedUniteDeb = null;
    let allowedUniteFin = null;

    if (!CWSTR.isBlank(gererActivite.get("modesai_periodeb")) && !CWSTR.isBlank(gererActivite.get("modesai_periodeb").code)) {
      allowedUniteDeb = this.unitedebComboColl.findWhere({ code: gererActivite.get("modesai_periodeb").code });
    }
    if (!CWSTR.isBlank(gererActivite.get("modesai_periofin")) && !CWSTR.isBlank(gererActivite.get("modesai_periofin").code)) {
      allowedUniteFin = this.unitefinComboColl.findWhere({ code: gererActivite.get("modesai_periofin").code });
    }
    if (CWSTR.isBlank(allowedUnite)) {
      gererActivite.set("unitedeb", { code: "", libelle: "" });
      if (actDefaultObject) {
        actDefaultObject.set("saisie_dateunitedef", { code: "", libelle: "" })
      }
    } else {
      gererActivite.get("unitedeb").libelle = allowedUnite.get("libelle");
    }
    if (CWSTR.isBlank(allowedUniteDeb)) {
      gererActivite.set("modesai_periodeb", { code: "", libelle: "" });
      if (actDefaultObject) {
        actDefaultObject.set("saisie_debperiodedef", { code: "", libelle: "" });
      }
    } else {
      if (CWSTR.isBlank(gererActivite.get("modesai_periodeb").libelle)) { //Set libelle value in order to avoid another call to combo WS
        gererActivite.get("modesai_periodeb").libelle = allowedUniteDeb.get("libelle");
      }
    }
    if (CWSTR.isBlank(allowedUniteFin)) {
      gererActivite.set("modesai_periofin", { code: "", libelle: "" });
      if (actDefaultObject) {
        actDefaultObject.set("saisie_finperiodedef", { code: "", libelle: "" });
      }
    } else {
      if (CWSTR.isBlank(gererActivite.get("modesai_periofin").libelle)) { //Set libelle value in order to avoid another call to combo WS
        gererActivite.get("modesai_periofin").libelle = allowedUniteFin.get("libelle");
      }
    }
    if (callback) {
      callback();
    }
  }

  _restoreActivite(): void {
    const previous = this.model.previous("value");

    if (this.context.ctxModeInitialisation === "Consultation" && this._getModel().isNew() && !CWSTR.isBlank(previous?.id)) {
      //revert a creation in mode Initialization consultation
      this.model.unset("value", { silent: true });
      this.model.set("value", previous);
    } else {
      this.workflow.modeDeclarer = false;
      this._getModel().revert();
    }
    this.model.trigger("change:value");
  }

  _restoreInitialState(): void {
    const lTypeEvt = this._getTypeEvt();
    const lAccess = (this.context.ctxModeInitialisation === "Consultation") ? "Acces" : "Gestion";
    const lHab = this._getHabilitationDuContext(lTypeEvt, lAccess);

    //ctxHabilitation.HabilitationAcces["P"]
    if (this.context.ctxModeInitialisation === "Consultation") {
      this.model.updateHabContext({ foncCour: lHab, natGest: "" });
      this.btnBar.model.trigger("enable:delete");
      this.btnBar.model.set("mode", "R");
      if (!CWHABILITATION.canCreate(this.context.ctxHabilitation.HabilitationGestion)) {
        this.btnBar.model.trigger("disable:new");
      }
    } else {
      if (this.context.ctxModeInitialisation === "Ajout") {
        this.model.updateHabContext({ foncCour: lHab, natGest: "A" });
        this.btnBar.model.trigger("disable:new");
        this.btnBar.model.trigger("disable:delete");
        this.btnBar.model.trigger("hide:valider");
        this.btnBar.model.trigger("hide:refuser");
        this.btnBar.model.trigger("disable:declarer");
      }
    }
    this._manageCheckAtribAuto();
    this._manageActionsPossibles();
    this._manageHabilitation();
    this._manageRestrictions(this._getModel(), this.isEditable);
    this._managePieceJointe();
    this._initSelecteurDates();
    if (this.context.ctxModeInitialisation === "Ajout") {
      this.btnBar.model.set("mode", "E");
    }
  }

  /**
   * Create the Workflow Composant and atach to the form
   */
  _initWKFHisto(): void {
    //ctxHabilitation.HabilitationAcces["P"]
    if (this.context.ctxHistoriqueWKF === true) {
      const lTypeEvt = this._getTypeEvt();
      const lHabAcces = this._getHabilitationDuContext(lTypeEvt, "Acces");

      this.contextComponentWkf = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: lHabAcces,
        ctxTypeEvenement: "ACTIVITE",
        ctxRefEvenement: CWSTR.getElValue(this._getModel(), "evenement"),
        ctxDemId: CWSTR.getElValue(this._getModel(), "iddemande"),
        ctxModeRestitution: "-", //"Imbrique",
        ctxActivModeSynth: true,
        ctxModeDefaut: "Replie",
        ctxModeRech: true
      };
      //Prepared for the new etiquette v5
      this.composantWorkflow = new CWWkflhistoView();
      this.composantWorkflow.render();
      this.composantWorkflow.setContext(this.contextComponentWkf);
      this.composantWorkflow.hideIcon();
    }
  }

  _tooltipListener(event: JQueryEventObject): void {
    if (this.context.ctxHistoriqueWKF === false) {
      const infobulleWorkflow = new CWWkflhistoView();
      const jquery = (event.currentTarget.attributes as any)["aria-describedby"];
      const demandeId = CWSTR.getElValue(this._getModel(), "iddemande");
      const refEvenement = CWSTR.getElValue(this._getModel(), "evenement");
      const lTypeEvt = this._getTypeEvt();
      const lAcces = (this.context.ctxModeInitialisation === "Consultation") ? "Acces" : "Gestion";
      const lHabAcces = this._getHabilitationDuContext(lTypeEvt, lAcces);
      const contextInfobulleWkf = {
        ctxEcran: this.context.ctxEcran,
        ctxHabilitation: lHabAcces,
        ctxTypeEvenement: "ACTIVITE",
        ctxRefEvenement: refEvenement,
        ctxDemId: demandeId,
        ctxModeRestitution: "infobulle",
        ctxActivModeSynth: true,
        ctxModeDefaut: "Replie",
        ctxModeRech: true
      };

      if (jquery) {
        return;
      }
      infobulleWorkflow.setContext(contextInfobulleWkf);
      $(event.currentTarget).tooltip({ content: "" });
      $(event.currentTarget).tooltip("option", "content", (): string => {
        return infobulleWorkflow.$el.html();
      });
      $(event.currentTarget).prop("title", "");
      infobulleWorkflow.model.get("histoDem").once("sync", (): void => {
        $(event.currentTarget).tooltip("open");
      });
    }
  }

  _closeTooltipListener(): void {
    try {
      $("span.statutActiviteLibelle", this.el).tooltip("destroy");
    } catch (error) {
      // empty
    }
  }

  /**
   * Prints an icon in the activite pop-up if an activity was added form the edt (generationedt === true)
   */
  _showIndicateurEvenamentMessage(): void {
    const ActGenereEdT = this._getModel().get("generationedt");
    const ctxIdentifiantEvt = this.context.ctxIdentifiantEvt;
    const element = this.$el.find(".gereractivites-evenamentmessage");

    if (this.context.ctxModeInitialisation === "Consultation" && !CWSTR.isBlank(ctxIdentifiantEvt) && ActGenereEdT === true) {
      let content = i18n.t('messages:GT_1943') || "";

      content = "<div class='phx-block'><span class='phx-block-title-text ui-phx-icon-metier from-emprei-du-temps'></span></div>";
      element.html(content);
      element.show();
    } else {
      element.html("");
      element.hide();
    }
  }

  _checkHoraireActivite(act: { [key: string]: any }, callback?: (arg?: any) => void): void {
    if (act.get("addhoraireauto") && this.context.ctxActiviteBesoin !== true) {
      const horairesForAct = new CWCalculHorairesActiviteCollab();
      let lOnglet = "planresp";
      const lfoncCour = this._getHabilitationDuContext(this._getTypeEvt(), "Gestion");
      let hc: CWHabilitationContext = null;

      //Get info horaire for this affectation
      horairesForAct.set({
        "matricule": act.get("matricule"),
        "activite": act.get("activite"),
        "hierid": act.get("hierid"),
        "straid": act.get("straid"),
        "typesaisie": act.get("typesaisie"),
        "modesaisie": act.get("modesaisie"),
        "modesai_periodeb": act.get("modesai_periodeb"),
        "modesai_periofin": act.get("modesai_periofin"),
        "datedeb": act.get("datedeb"),
        "unitedeb": act.get("unitedeb"),
        "heuredeb": act.get("heuredeb"),
        "datefin": act.get("datefin"),
        "unitefin": act.get("unitefin"),
        "heurefin": act.get("heurefin"),
        "duree": act.get("duree"),
        "dureecalculee": act.get("dureecalculee"),
        "pourcentage": act.get("pourcentage"),
        "horspresav": act.get("horspresav"),
        "horspresap": act.get("horspresap"),
        "horspresence": act.get("horspresence"),
        "typehorspresence": act.get("typehorspresence"),
      });
      horairesForAct.activiteCode = act.get("activite");
      if (this.context && !CWSTR.isBlank(this.context.ctxEcran)) {
        lOnglet = this.context.ctxEcran;
      }
      hc = new CWHabilitationContext({
        onglet: lOnglet,
        foncCour: lfoncCour,
        natGest: "A"
      });
      horairesForAct.setHabContext(hc);
      horairesForAct.fetch({//type "POST"
        success: (response: any): void => {
          if (!CWSTR.isBlank(response) && !CWSTR.isBlank(response.get("horaires"))) {
            act.set("horaires", response.get("horaires"));
          } else {
            act.set("horaires", []);
          }
          if (callback) {
            // sends response to allow obtain horaires
            callback(response);
          }
        },
        error: (m: any, x: any, xrf: any): void => {
          xrf.xhr.status = 200;
          xrf.xhr.responseText = "";
          if (callback) {
            callback();
          }
        }
      });
    } else {
      if (callback) {
        callback();
      }
    }
  }

  _manageTypeSaisieDate(target: { [key: string]: any }): void {
    let datedeb: string;
    let datefin: string;

    //In order to send datefin=datedeb for type saisie date
    switch (target.get("typesaisie")) {
      case "D":
        datedeb = datefin = target.get("dateDebD");
        break;
      case "P":
        datedeb = target.get("dateDebP");
        datefin = target.get("dateFinP");
        break;
      case "R":
        datedeb = target.get("dateDebR");
        datefin = target.get("dateFinR");
        break;
    }
    target.set("datedeb", datedeb);
    target.set("datefin", datefin);
  }

  selectCollab(): void {
    const collab$ = this.$el.find('input.matricule');
    const inputValue = String(collab$.val());
    const length = inputValue.length;

    if ((collab$.get(0) as any).setSelectionRange) {
      collab$.get(0).focus();
      (collab$.get(0) as any).setSelectionRange(0, length);
    }
  }

  _setTitleImbrique(action?: any): void {
    if (this.parent) {
      $(this.parent.layout.el).find('.typeActivite').html(this._getTitle(action)).removeClass('d-none');
    }
  }

  _getTitle(action?: any): string {
    let title;
    let typologie;

    if (action === "revert") {
      typologie = GLOBAL_DATA.typologies.get(this._getModel().get("typologie").code);
    } else {
      typologie = GLOBAL_DATA.typologies.get(this.context.ctxTypologieActivite);
    }
    if (typologie) {
      title = typologie.get("evenement");
    } else {
      title = this.context.ctxActionsPossibles && this.context.ctxActionsPossibles.length >= 1 && _.contains(this.context.ctxActionsPossibles, "Creer") &&
        this.context.ctxModeInitialisation === "Ajout" ? i18n.t('common:gereractivites.nouvelle_deplacement_title') : i18n.t('common:gereractivites.mineDeplacement');
    }
    return title.toUpperCase();
  }

  _initSubtitle(needToBeDisplay?: boolean): void {
    let $layout: JQuery = null;

    if (this.context && this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass" && this.context.ctxEcran !== "mactivites" && this.context.ctxEcran !== "suivicollab") {
      if (this.context.ctxModeRepresentation === "Imbrique" && this.context.ctxEcran === "mdepl") {
        $layout = $(this.parent.layout.el).find(".typeActivite");
      } else {
        if (!(this.context.ctxEcran === "mdepl")) {
          $layout = this.parent.$el.parent().find(".typeActivite")
        }
      }
      if ($layout.find('.gereractivites-subtitle').length === 0) {
        if (this.context.ctxModeInitialisation === "Ajout") {
          //780
          // $layout.append($('<span>').addClass('gererdeplacements-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne').html(String(" - ").concat(i18n.t('common:gereractivites.creation_en_cours'))));
          $layout.append($('<span>').addClass('gereractivites-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne').html(String(" - ").concat(i18n.t('common:gereractivites.creation_en_cours'))));
          //780
        } else if (this.context.ctxModeInitialisation === "Consultation") {
          $layout.append($('<span>').addClass('gereractivites-subtitle').addClass('cw-texteAllege cw-texteAllege--selectionne d-none').html(String(" - ").concat(i18n.t('common:gereractivites.modification_en_cours'))));
        }
      }
    }
    if (needToBeDisplay) {
      this._displaySubtitle();
    } else if (this.isDislayedSubtitle) {
      this._hideSubtiltle();
    }
  }

  _hideSubtiltle(): void {
    let $layout: JQuery = null;

    if (this.context && this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass") {
      if (this.context.ctxModeRepresentation === "Imbrique" && this.context.ctxEcran === "mdepl") {
        $layout = $(this.parent.layout.el).find(".typeActivite");
      } else {
        if (!(this.context.ctxEcran === "mdepl")) {
          $layout = this.parent.$el.parent().find(".typeActivite")
        }
      }
      $layout.find('.gereractivites-subtitle').addClass('d-none');
      this.isDislayedSubtitle = false;
    }
  }

  copyActivite(): void {
    const target = this._getModel().clone();
    const lHabGestion = this._getHabilitationDuContext(this._getTypeEvt(), "Gestion");

    this.model.updateHabContext({ foncCour: lHabGestion, natGest: "A" });
    delete target.id;
    target.set("matricule", this.context.ctxGestionCollab.matricule);
    this.model.trigger("mode:create");
    this._enableForm();
    this.model.set("value", target);
    this.model.trigger("form:edited", true);
    this._hideTypeStatusTitle();
    this._managePieceJointe();
  }

  deleteActivite(commentaire?: string, reloadPlanningModal?: boolean, mode?: string): void {
    const target = this._getModel();
    const droit = target.get("droit");
    const isRecap = target.get("recapitulatif") && !CWSTR.isBlank(target.get("recapitulatif").code);
    let lHabGestion = this._getHabilitationDuContext(this._getTypeEvt(), "Gestion");
    const successDestroyCallback = (data: { [key: string]: any }, periodes: { datedeb: string; datefin: string }[]): void => {
      const notIsRefreshPlanningAll = reloadPlanningModal; //s'il y a une action de "refresh:all", après il ne doit pas avoir autre de "refresh:selection"
      const lFuncCallbackPlanReload = (optionBtn?: { [key: string]: any }): void => {//optionBtn.refreshPlanAll(options de la popup, Y->true, N->false)
        if (this.context && this.context.ctxActiviteBesoin === true && !CWSTR.isBlank(this.plworkflow)) {
          const arrayCellIds: string[] = [];
          const nbPeriodes = periodes ? periodes.length : 0;

          arrayCellIds.push(data.get("matricule") + "," + data.get("datedeb"));
          this.plworkflow.ctxChangerPlanningBesoin = true;
          this.plworkflow.ctxInitPlanActivite = false;
          if (nbPeriodes > 1) {
            let idemPeriode = false;
            let limiteDateDeb: string = null;
            let limiteDateFin: string = null;
            const listCompletArrayCellIds: string[] = [];//pour enlever des pétitions en double

            if (typeof this.plworkflow.periodeActifPlanning === "function") {
              const periodePlanning = this.plworkflow.periodeActifPlanning();

              if (!_.isEmpty(periodePlanning)) {
                limiteDateDeb = periodePlanning.datedeb;
                limiteDateFin = periodePlanning.datefin;
              }
            }
            for (let index = 0; index < nbPeriodes; index++) {
              const periode = _.clone(periodes[index]);

              if (!CWSTR.isBlank(limiteDateDeb) && periode.datedeb < limiteDateDeb) {
                periode.datedeb = limiteDateDeb;
              }
              if (!CWSTR.isBlank(limiteDateFin) && periode.datefin > limiteDateFin) {
                periode.datefin = limiteDateDeb;
              }
              if (periode.datedeb === periode.datefin && listCompletArrayCellIds.indexOf(periode.datedeb) < 0) {
                arrayCellIds.push(data.get("matricule") + "," + periode.datedeb);
                listCompletArrayCellIds.push(data.get("matricule") + "," + periode.datedeb);
              } else {
                const listDate = CWTYPE.DATE.getFullPeriode(periode.datedeb, periode.datefin);

                listDate.forEach((value) => {
                  const cellDayId = data.get("matricule") + "," + value;

                  if (listCompletArrayCellIds.indexOf(cellDayId) < 0) {
                    arrayCellIds.push(cellDayId);
                    listCompletArrayCellIds.push(cellDayId);
                  }
                });
              }
              if (!_.isEmpty(arrayCellIds) && (!notIsRefreshPlanningAll || optionBtn?.refreshPlanAll === false)) {
                this.plworkflow.trigger("refresh:selection", periode.datedeb, periode.datefin, null, null, null, true, arrayCellIds, true, null, optionBtn);
              }
              if (!idemPeriode && periode.datefin === data.get("datefin") && periode.datedeb === data.get("datedeb")) {
                idemPeriode = true;
              }
            }
            if (!idemPeriode && (!notIsRefreshPlanningAll || optionBtn?.refreshPlanAll === false)) {
              this.plworkflow.trigger("refresh:selection", data.get("datedeb"), data.get("datefin"), null, null, null, true, arrayCellIds, true, null, optionBtn);
            }
          } else if (nbPeriodes === 1) {
            let dateDebPeriode = data.get("datedeb");
            let dateFinPeriode = data.get("datefin");

            dateDebPeriode = dateDebPeriode > periodes[0].datedeb ? periodes[0].datedeb : dateDebPeriode;
            dateFinPeriode = dateFinPeriode > periodes[0].datefin ? dateFinPeriode : periodes[0].datefin;
            //on doit rafraichir le tableau de service et le planning d'activités
            if (dateDebPeriode === dateFinPeriode) {
              arrayCellIds.push(data.get("matricule") + "," + periodes[0].datedeb);
            } else {
              const listDate = CWTYPE.DATE.getFullPeriode(dateDebPeriode, dateFinPeriode);

              listDate.forEach((value) => {
                const cellIdDay = data.get("matricule") + "," + value;

                if (arrayCellIds.indexOf(cellIdDay) < 0) {
                  arrayCellIds.push(cellIdDay);
                }
              });
            }
            if (!notIsRefreshPlanningAll || optionBtn?.refreshPlanAll === false) {
              this.plworkflow.trigger("refresh:selection", dateDebPeriode, dateFinPeriode, null, null, null, true, arrayCellIds, true, null, optionBtn);
            }
          } else {
            if (!notIsRefreshPlanningAll || optionBtn?.refreshPlanAll === false) {
              this.plworkflow.trigger("refresh:selection", data.get("datedeb"), data.get("datefin"), null, null, null, true, arrayCellIds, true, null, optionBtn);
            }
          }
          this.plworkflow.totalsModel.trigger("change");
          this.plworkflow.totalsModel.trigger("sync", null, null, null, true);
        }
      };

      if (this.context.ctxModeRepresentation === "pop-up") {
        this.parent.close();
      }
      this.stopListening(target, "sync");
      if (reloadPlanningModal) {
        const lfuncOptionNoN = (optionBtn: { [key: string]: any }): void => {
          this.model.trigger("activiteChanged", target, "delete");
          lFuncCallbackPlanReload(optionBtn);
        };

        if (this.plworkflow && _.isBoolean(this.plworkflow.affRefPrets)) {
          //A preference stored so we don't  have to show dialog, only act as stored
          if (this.plworkflow.affRefPrets === true) {
            this.plworkflow.trigger("refresh:PlanningFromPopup", true);
          } else {//If affRefPrets=false, don't refresh all planning
            const optionNon = { "refreshPlanAll": false };

            lfuncOptionNoN(optionNon);
          }
        } else {
          const callback = (event?: JQuery.TriggeredEvent, optionBtn?: { [key: string]: any }): void => { //optionBtn.refreshPlanAll(options de la popup, Y->true, N->false)
            if (_.isEmpty(optionBtn) || optionBtn.refreshPlanAll === false) {
              lfuncOptionNoN(optionBtn);
            }
          };

          //No preference stored so we have to shoe dialog
          this._openRefreshPretsDialog(null, callback);
        }
      } else {
        lFuncCallbackPlanReload();
      }
      if (this.context && this.context.ctxSaisieMasse === true) {
        this.trigger("delete-row:remove-model", { response: "Y" });
      } else {
        target.set("refresh", periodes);
        this.model.trigger("activiteChanged", target, "delete");
      }
    };
    const errorDestroyCallbackError = (): void => {
      if (this.context?.ctxSaisieMasse === true) {
        this.errorPetAntSaveDelete = true;
      }
    };
    const errorDestroySaisieMasseCallbackError = (): void => {
      if (this.context?.ctxSaisieMasse === true) {
        this.errorPetAntSaveDelete = true;
        this.trigger("delete-row:remove-model", { response: "N" }, true);//on doit envoyer l'information de l'erreur et demander rétablir le listenToOnce
        this.stopListening(target, "sync");
        this.listenToOnce(target, "sync", (model?: { [key: string]: any }, response?: { [key: string]: any }, options?: { [key: string]: any }): void => {
          target.set('refresh', options);
          this.model.trigger("activiteChanged", model, "delete");
          this.trigger("delete-row:remove-model", { response: "Y" });
        });
      }
    };

    if (isRecap) {
      this.model.updateHabContext({ foncCour: lHabGestion, natGest: "S" });
    } else {
      if (this.context && this.context.ctxSaisieMasse !== true) {
        this.model.updateHabContext({ foncCour: lHabGestion, natGest: "S" });
      }
      if (this.context && this.context.ctxSaisieMasse === true) {
        // Customer #223332.
        // If target.habContext.get("foncCour") is string, Edge throws an error.
        // If target.habContext.get("foncCour") us string, Edge considers each letter as an element.
        // In any case, we can assume that if the value is a string, there is only one habilitation.
        if (!(typeof target.habContext.get("foncCour") === 'string') && Object.keys(target.habContext.get("foncCour")).length > 1) {
          lHabGestion = this._getHabilitationDuContext(target.get("typeevenement"), "Gestion");
          this.model.updateHabContext({ foncCour: lHabGestion, natGest: "A" });
        }
      }
    }
    target.action = "element";
    if (this.context && this.context.ctxSaisieMasse === true && !mode) {
      CWMSGS.showConfirmAdapter(i18n.t('messages:GT_1677'), (result: string): void => {
        if (result === "Y") {
          target.destroy({
            contentType: 'application/json',
            data: !CWSTR.isBlank(commentaire) ? JSON.stringify({ commentaire: commentaire, droit: droit }) : JSON.stringify({ commentaire: "", droit: "N" }),
            success: successDestroyCallback,
            error: errorDestroySaisieMasseCallbackError,
            wait: true
          });
        } else {
          this.trigger("delete-row:remove-model", { response: "N" });
        }
      }, null, null, null, this.workflow.$appendTo);
    } else {
      target.destroy({
        contentType: 'application/json',
        data: !CWSTR.isBlank(commentaire) ? JSON.stringify({ commentaire: commentaire, droit: droit }) : JSON.stringify({ commentaire: "", droit: "N" }),
        success: successDestroyCallback,
        error: errorDestroyCallbackError,
        wait: true
      });
    }
  }

  accepterActiviteFromMenuButton(activite: { [key: string]: any }): void {
    const profil = (activite.get('profil')) ? activite.get('profil').code : "";

    activite.setHabContext(this.model.getHabContext());
    this.model.set("value", activite, { silent: true });
    this.btnBar.model.trigger("btn:click", "accepter", profil);
  }

  refuserActiviteFromMenuButton(activite: { [key: string]: any }): void {
    const profil = (activite.get('profil')) ? activite.get('profil').code : "";

    activite.setHabContext(this.model.getHabContext());
    this.model.set("value", activite, { silent: true });
    this.btnBar.model.trigger("btn:click", "refuser", profil);
  }

  accepterActivite(commentaire: string, profil: string, justifok?: string): void {
    const values = this._getModel();
    const droit = CWSTR.isBlank(values.get("droit")) ? "N" : values.get("droit");
    const target = new CWGererActivitesGestionModel();
    const saveCallback = (_fresh: { [key: string]: any }, refresh: { [key: string]: any }): void => {
      if (refresh && refresh[0] && refresh[0].datedeb && refresh[0].datefin) {
        this._getModel().set("refresh", refresh);
      }
      if (this.context.ctxModeRepresentation === "pop-up") {
        this.parent.close();
      }
      this.model.trigger("activiteChanged", this._getModel(), "update");
    };
    const errorCallback = (model?: { [key: string]: any }, response?: { [key: string]: any }): void => {
      if (response.status === 200) {//La valeur a été changée dans CWBaseModel
        response.status = response.oldStatus;//On doit le rétablir
      }
      if (response.status === 406 && response.responseJSON?.attribut) {
        response.responseJSON.attribut = null; //On doit nettoyer l'information du attribut avant de continuer car on est dans un volet pour valider (ce n'est pas modifiable-> on ne doit pas afficher l'erreur du attribut)
        //Avec cette modification et status égal à 406, on affichera une popup
      }
    };

    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: ""
    }));
    target.action = "accepter";
    // send evenement
    target.id = this._getModel().get("evenement");
    target.set("commentaire", commentaire);
    target.set("justifok", justifok);
    target.set("droit", droit);
    target.set("profil", {});
    target.get("profil").code = profil; //<- 378 del profil;
    target.save(null, {
      success: saveCallback,
      error: errorCallback
    });
  }

  refuserActivite(commentaire: string, profil: string): void {
    const values = this._getModel();
    const droit = CWSTR.isBlank(values.get("droit")) ? "N" : values.get("droit");
    const target = new CWGererActivitesGestionModel();
    const saveCallback = (_fresh: { [key: string]: any }, refresh: { [key: string]: any }): void => {
      if (refresh && refresh[0] && refresh[0].datedeb && refresh[0].datefin) {
        this._getModel().set("refresh", refresh);
      }
      this.model.trigger("activiteChanged", this._getModel(), "update");
      if (this.context.ctxModeRepresentation === "pop-up") {
        this.parent.close();
      }
    };

    target.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation,
      natGest: ""
    }));
    target.action = "refuser";
    // evenement
    target.id = this._getModel().get("evenement");
    target.set("commentaire", commentaire);
    target.set("droit", droit);
    target.set("profil", {});
    target.get("profil").code = profil;
    target.save(null, {
      success: saveCallback,
      error: saveCallback
    });
  }

  valider(callback: () => void): void {
    const target = this._getModel();
    const saveCallback = (_model: any, response: any): void => {
      if (response.result === "ok") {
        if (callback) {
          callback();
        }
      }
    };

    this.model.updateHabContext({ foncCour: this.context.ctxHabilitation.HabilitationValidation, natGest: "" });
    target.action = "validesaisie";
    target.setHabContext(this.model.getHabContext());
    target.save(null, {
      success: saveCallback,
      error: saveCallback
    });
  }

  savePiecesJointes(activite: { [key: string]: any }, callback: () => void): void {
    if (activite && this.context.ctxPieceJointe === true && this.pieceJointeModified === true) {
      const evenement = activite.get("evenement");

      this.pieceJointe.applyPendingActions(evenement, callback);
    } else {
      if (callback) {
        callback();
      }
    }
  }

  _initializeCheckAtrinbAuto(): void {
    const model = this._getModel();

    if (model.isNew()) {
      let value = true;

      //this.$el.find(".attribAutoContainer").hide();
      this.$el.find(".attribAutoContainer").addClass("d-none");
      if (this.context.ctxUtilisateur === "Collaborateur" || this.context.ctxGestionCollective === true || CWSTR.isBlank(this.actDefaults.get("ratthor")) || this.actDefaults.get("ratthor") === false ||
        (!CWSTR.isBlank(model.get("recapitulatif")) && !CWSTR.isBlank(model.get("recapitulatif").code)) || CWSTR.isBlank(model.get("unitedeb")) || CWSTR.isBlank(model.get("unitedeb").code)) {
        value = false;
      }
      if (!CWSTR.isBlank(this.actDefaults.get("unites"))) {
        _.each(this.actDefaults.get("unites"), (unite: { [key: string]: any }): void => {
          if (this.context.ctxTypeEvtGere[0] === "P" && unite.attribhoraireprev === false) {
            value = false;
          } else {
            if (this.context.ctxTypeEvtGere[0] === "R" && unite.attribhorairereal === false) {
              value = false;
            }
          }
        });
      }
      model.set("activratthor", value);
      this._manageCheckAtribAuto();
    }
  }

  _manageCheckAtribAuto(): void {
    const model = this._getModel();

    if (this.context.ctxActiviteBesoin === true) {
      const preferenceHoraireAuto = (this.plworkflow.preferences.getPreferenceValue("PL_TOTALIS_COLL", "varint5") === 1);

      // recherche de la valeur par
      model.set("addhoraireauto", preferenceHoraireAuto);
      this.$el.find(".addhoraireauto").prop("checked", preferenceHoraireAuto);
      //this.$el.find(".attribAutoContainer").hide();
      this.$el.find(".attribAutoContainer").addClass("d-none");
    } else {
      if (model.get("modesaisie") === "UNITE") {
        if (!CWSTR.isBlank(model.get("unitedeb")) && !CWSTR.isBlank(model.get("unitedeb").code) && !CWSTR.isBlank(model.get("activite"))) {
          //JSS: After check this call with Jean Philippe we can't call to an a referentiel service from exploitation, so we need to remo the DomainDetailModel call
          // I left this comment if this causes futre problem to track the error.
          // this.domActDetail = new DomActiviteDetailModel({

          //this.actSelDetail = new ActiviteDetailModel({
          this._manageAtribHoraire();
          const isReadOnly = CWSTR.isBlank(GLOBAL_DATA.paramDivers.get("horAutoCf")) || parseInt(GLOBAL_DATA.paramDivers.get("horAutoCf").get("valeur"), 10) === 0 ? true : false;
          CWFORMS.setFormReadonly(this.$el.find(".attribAutoContainer"), isReadOnly, false);
        }
      } else {
        //this.$el.find(".attribAutoContainer").hide();
        this.$el.find(".attribAutoContainer").addClass("d-none");
        model.set("addhoraireauto", false);
        this.$el.find(".addhoraireauto").prop("checked", false);
      }
    }
  }

  _manageAtribHoraire(): void {
    let isVisible = false;
    const model = this._getModel();

    if (this.context.ctxUtilisateur === "Responsable" && this.context.ctxGestionCollective === false &&
      (this.actDefaults && this.actDefaults.get("ratthor") === true) &&
      (CWSTR.isBlank(model.get("recapitulatif")) || CWSTR.isBlank(model.get("recapitulatif").code)) &&
      (!CWSTR.isBlank(this.actDefaults.get("ratthor")) && this.actDefaults.get("ratthor") === true) &&
      (!CWSTR.isBlank(model.get("unitedeb")) && !CWSTR.isBlank(model.get("unitedeb").code))) {
      isVisible = true;
    }
    if (isVisible && !CWSTR.isBlank(this.actDefaults.get("unites"))) {
      isVisible = false;
      _.each(this.actDefaults.get("unites"), (unite: { [key: string]: any }): void => {
        if (unite.code === model.get("unitedeb").code) {
          if (this.context.ctxTypeEvtGere[0] === "P" && unite.attribhoraireprev === true) {
            isVisible = true;
          } else {
            if (this.context.ctxTypeEvtGere[0] === "R" && unite.attribhorairereal === true) {
              isVisible = true;
            }
          }
        }
      });
    }
    if (isVisible) {
      model.set("addhoraireauto", true);
      this.$el.find(".addhoraireauto").prop("checked", true);
      this.$el.find(".attribAutoContainer").removeClass("d-none");
    } else {
      this.$el.find(".attribAutoContainer").addClass("d-none");
      model.set("addhoraireauto", false);
      this.$el.find(".addhoraireauto").prop("checked", false);
    }
  }

  _updateVisibiliteOrigine(): void {
    const lPosOrigin = this.$el.find(".originActif"); //label and combo
    let lTypeAct = null;
    let lContextActif = null;

    //La présence de cette information dans le formulaire ne sera effective que si,
    // pour le type d’événement « absence », la saisie de l’origine de la demande est autorisée.
    if (this._getModel().isNew()) {
      if (this.context.ctxTypeEvtGere.length === 1) {
        lTypeAct = this.context.ctxTypeEvtGere[0];
      } else {
        //dans le drag&drop, il est nouveau mais  "this._getModel().get("typeevenement")" a la valeur du type
        if (!CWSTR.isBlank(this._getModel()) && !CWSTR.isBlank(this._getModel().get("typeevenement"))) {
          lTypeAct = this._getModel().get("typeevenement");
        }
      }
    } else {
      lTypeAct = this._getModel().get("typeevenement");
    }
    //voir pour actPre ou actRea
    switch (lTypeAct) {
      case "P":
        //voir actPre
        lContextActif = GLOBAL_DATA.contextActif.contextActifActPre;
        break;
      case "R":
        //voir actRea
        lContextActif = GLOBAL_DATA.contextActif.contextActifActRea;
        break;
      default:
      //hide mais on ne fait rien car l_contextActif est déjà null et il le fera dans le if
    }
    if (CWSTR.isBlank(lContextActif) || lContextActif.get("active") !== true) {
      lPosOrigin.hide();
    } //Il n'est pas necessaire l'action "show" parce qu'il vient de le faire(la création)
  }

  _setReloadPlanningFlag(model: { [key: string]: any }): boolean {
    let result = false;
    const infoOptions: { [key: string]: any } = { "model": model };

    if (this.context && (this.context.ctxPourPlanningMedical === true || this.context.ctxActiviteBesoin === true)) {
      let cellId: string = null;
      let activiteSelectedDataCell: any = null;

      if (CWSTR.isBlank(this.context.ctxGestionCollab.matricule) && !CWSTR.isBlank(model.get("matricule"))) {
        cellId = model.get("matricule");
      } else {
        cellId = this.context.ctxGestionCollab.matricule;
      }
      cellId += ',' + (!CWSTR.isBlank(this.context.ctxGestionData) ? this.context.ctxGestionData : model.get("datedeb"));
      activiteSelectedDataCell = this.plworkflow.evtModel.get("ACTIVITES").get(cellId);
      if (CWSTR.isBlank(activiteSelectedDataCell)) {
        activiteSelectedDataCell = this.plworkflow.evtModel.get("INDICATEURS").get(cellId);
      }
      if (!activiteSelectedDataCell) {
        return false; // les habilitations font que l'activité n'est pas affichée sur le planning donc pas de rechargement
      }
      infoOptions.matricule = activiteSelectedDataCell.get("elt");
      if (this.context.ctxGestionCollab.ext === true && !CWSTR.isBlank(activiteSelectedDataCell) && this._isActivitePlanAct(activiteSelectedDataCell, model) &&
        (!this._hasMorePlanActActivites(infoOptions) && !this._hasMorePlanActIndicateursActivites(infoOptions))) {
        result = true;
      }
    }
    return result;
  }

  _isActivitePlanAct(dataCell: { [key: string]: any }, modelActivitie: { [key: string]: any }): boolean {
    const activitieId = modelActivitie.id;
    const activitieIndex = _.findIndex(dataCell.get("evt").models, (model: { [key: string]: any }): boolean => {
      let rtn = (model.get("dts").row === activitieId && model.get("dts").ext === false);//Activité, mode en Unité

      if (!rtn) {//mode en indicateurs
        const dtsActDay = model.get("dts");

        //on doit rechercher dans les indicateurs d'activités prévues
        if (!_.isEmpty(dtsActDay) && !_.isEmpty(dtsActDay.typo)) {
          for (let i = 0; i < dtsActDay.typo.length && !rtn; i++) {
            const prevActDay = dtsActDay.typo[i].prev;

            if (!_.isEmpty(prevActDay)) {
              for (let j = 0; j < prevActDay.length && !rtn; j++) {
                const actBes = prevActDay[j];

                if (!_.isEmpty(actBes) && actBes.ext === false && actBes.row === activitieId) {
                  rtn = true;
                }
              }
            }
          }
        }
      }
      return rtn;
    });

    return (activitieIndex >= 0);
  }

  _isOutOfDateRange(target: { [key: string]: any }): boolean {
    if (this.context && this.context.ctxPourPlanningMedical === true) {
      if (this.plworkflow.imageModel) {
        const dateact = parseInt(target.get("datedeb"), 10);
        const datedeb = parseInt(this.plworkflow.imageModel.datedeb, 10);
        const datefin = parseInt(this.plworkflow.imageModel.datefin, 10);

        return !((dateact >= datedeb) && (dateact <= datefin));
      }
      return false;
    } else {
      return false;
    }
  }

  _openRefreshPretsDialog(fresh: any, closeCallback: () => void): void {
    let $posXclose: JQuery = null;
    const dialog = new CWDialogPopupView({
      view: (this.context.ctxEcran === "planmed") ? CWPretsRefreshPlanningDialog : CWPretsRefreshPlanrespgDialog,
      viewData: {
        workflow: this.plworkflow,
        modal: true,
        appendTo: this.workflow.$appendTo
        //closeCallback: closeCallback -> ne pas utiliser cette option. On doit utiliser l'option close de la popup
      },
      close: closeCallback// il sera tenu en compte dans le cas de fermer, par le bouton, et s'on fait click sur la X de la popup
    });

    dialog.setHeight(245);
    dialog.setMinHeight(240);
    dialog.setWidth(400);
    dialog.open();
    $posXclose = dialog.$el.parent().find(".ui-dialog-titlebar-close");
    if ($posXclose && $posXclose.length > 0) {
      $posXclose.off("click");
      //changer l'option par défaut lorsque l'on clique sur la "X" de l'entête de la popup
      $posXclose.on("click", (dialog.getInternalViewObject() as CWPretsRefreshPlanningDialog | CWPretsRefreshPlanrespgDialog)._manageNo.bind(dialog.getInternalViewObject()));//"_manageNo-> de CWPretsRefreshPlanningDialog ou CWPretsRefreshPlanrespgDialog"
    }
  }

  _setHeureDeb(): void {
    let heuredeb = String(this.$el.find(".heuredeb-container input").val());
    if (!CWSTR.isBlank(heuredeb)) {
      const myHourArray = heuredeb.split(":");

      heuredeb = myHourArray[0] + myHourArray[1];
    }
    if (CWSTR.isBlank(this._getModel().get("heuredeb"))) {
      this._getModel().set("heuredeb", heuredeb);
    }
  }

  _setHeureFin(): void {
    let heurefin = String(this.$el.find(".heurefin-container input").val());

    if (!CWSTR.isBlank(heurefin)) {
      const myHourArray = heurefin.split(":");

      heurefin = myHourArray[0] + myHourArray[1];
    }
    if (CWSTR.isBlank(this._getModel().get("heurefin"))) {
      this._getModel().set("heurefin", heurefin);
    }
  }

  _declareModify(): void {
    this.notCheckCombos = true;
    this._enableForm();
    this._getModel().set("typeevenement", "R");
    this._mapToForm();
    this._enableForm();
  }

  _getTypeEvt(): string {
    let lTypeEvt = null;
    const lModel = (this.model) ? this._getModel() : null;

    if (lModel && !CWSTR.isBlank(lModel.get("typeevenement"))) {
      lTypeEvt = lModel.get("typeevenement");
    } else {
      if (this.context.ctxTypeEvtGere && this.context.ctxTypeEvtGere.length > 0 && this.context.ctxTypeEvtGere.indexOf(this.context.ctxValeursEvenement.ActTypeEvenement) !== -1) {
        lTypeEvt = this.context.ctxValeursEvenement.ActTypeEvenement;
      } else if (this.context.ctxTypeEvtGere && this.context.ctxTypeEvtGere.length > 0) {
        lTypeEvt = this.context.ctxTypeEvtGere[0];
      }
    }
    return lTypeEvt;
  }

  _getHabilitationDuContext(aTypeEvenement: string, aMode: string): string {
    const lModel = (this.model) ? this._getModel() : null;
    let lRtn = "";

    if (this.context.ctxHabilitation && typeof this.context.ctxHabilitation === "object") {
      let lTypeEvt: string = (aTypeEvenement) ? aTypeEvenement : this._getTypeEvt();

      if (!CWSTR.isBlank(aMode) && !CWSTR.isBlank(lTypeEvt)) {
        if (this.context.ctxSaisieMasse === true) {
          if (lTypeEvt === "P") {
            lTypeEvt = "prevue";
          } else if (lTypeEvt === "R") {
            lTypeEvt = "realisee";
          }
        }
        switch (aMode) {
          case "Acces":
            if (this.context.ctxHabilitation.HabilitationAcces && typeof this.context.ctxHabilitation.HabilitationAcces === "object") {
              lRtn = this.context.ctxHabilitation.HabilitationAcces[lTypeEvt];
            } else {
              lRtn = this.context.ctxHabilitation.HabilitationAcces;
            }
            break;
          case "Gestion":
            if (this.context.ctxHabilitation.HabilitationGestion && typeof this.context.ctxHabilitation.HabilitationGestion === "object") {
              lRtn = this.context.ctxHabilitation.HabilitationGestion[lTypeEvt];
            } else {
              lRtn = this.context.ctxHabilitation.HabilitationGestion;
            }
            if (this.workflow && this.workflow.modeDeclarer === true) {
              //la déclaration avec modification est la transformation à réalisée: on doit fixer l'habilitation à réaliser
              lRtn = lRtn.replace("PREV", "REAL");
            }
            break;
          case "Validation":
            if (this.context.ctxHabilitation.HabilitationValidation && typeof this.context.ctxHabilitation.HabilitationValidation === "object") {
              lRtn = this.context.ctxHabilitation.HabilitationValidation[lTypeEvt];
            } else {
              lRtn = this.context.ctxHabilitation.HabilitationValidation;
            }
            break;
          default:
          //Nothing
        }
        if (CWSTR.isBlank(lRtn)) {
          lRtn = "";
        }
      }
    } else if (this.context.ctxHabilitation) {
      lRtn = this.context.ctxHabilitation;
    }
    if (CWSTR.isBlank(lRtn) && lModel && lModel.getHabContext() && aMode === "Acces") {
      lRtn = lModel.getHabContext().get("foncCour");
    }
    return lRtn;
  }

  _updateHabilitationForChangeTypeEvt(aNewHabilitation: string): void {
    const gererActivite = this._getModel();

    /*ils sont déjà modifiés dans l'action de changèment
      -comboUnites
      -comboUniteDeb
      -comboUniteFin
      -comboOrigine
    */
    //model
    gererActivite.habContext.set("foncCour", aNewHabilitation);
    //referentielCollabPopu
    if (this.referentielCollabPopu) {
      this.referentielCollabPopu.habContext.set("foncCour", aNewHabilitation);
    }
    //actDefaults
    if (this.actDefaults) {
      this.actDefaults.habContext.set("foncCour", aNewHabilitation);
    }
    //actTypeSaisieOptionsColl
    if (this.actTypeSaisieOptionsColl) {
      this.actTypeSaisieOptionsColl.habContext.set("foncCour", aNewHabilitation);
    }
    //actModeSaisieOptionsColl
    if (this.actModeSaisieOptionsColl) {
      this.actModeSaisieOptionsColl.habContext.set("foncCour", aNewHabilitation);
    }
    //selectActPrevue
    if (this.selectActPrevue && this.selectActPrevue.coll) {
      this.selectActPrevue.coll.habContext.set("foncCour", aNewHabilitation);
    }
    //selectActRealisee
    if (this.selectActRealisee && this.selectActRealisee.coll) {
      this.selectActRealisee.coll.habContext.set("foncCour", aNewHabilitation);
    }
    //unitedebComboColl
    if (this.uniteComboColl) {
      this.uniteComboColl.habContext.set("foncCour", aNewHabilitation);
    }
    //unitedebComboColl
    if (this.unitedebComboColl) {
      this.unitedebComboColl.habContext.set("foncCour", aNewHabilitation);
    }
    //unitefinComboColl
    if (this.unitefinComboColl) {
      this.unitefinComboColl.habContext.set("foncCour", aNewHabilitation);
    }
    this._managePieceJointe();
  }

  protected _displaySubtitle(): void {
    if (!this.isDislayedSubtitle) {
      if (this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass" && this.context.ctxEcran !== "mactivites" && this.context.ctxEcran !== "suivicollab") {
        let $layout = this.context.ctxModeRepresentation === "Imbrique" ? $(this.parent.layout.el).find(".typeActivite") : this.parent.$el.parent().find(".typeActivite");

        if (this.context.ctxEcran === "mdepl") {
          $layout = this.context.ctxModeRepresentation === "Imbrique" ? $(this.$el.parent().parent().parent()).find(".l-panelB-entete") : this.parent.$el.parent().find(".typeActivite");
        }
        if (this.context.ctxModeInitialisation === "Consultation") {
          const subtitle = $layout.find('.gereractivites-subtitle');

          if (subtitle.hasClass('d-none')) {
            subtitle.removeClass('d-none');
            this.isDislayedSubtitle = true;
          }
        }
      }
    }
  }

  hideBtnBar(): void {
    this.btnBar.model.trigger('hide');
  }
  showBtnBar(): void {
    this.btnBar.model.trigger('show');
  }

  hideTitle(): void {
    let $layout: JQuery = null;
    let $statusLayout: JQuery = null;

    if (this.context && this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass") {
      if (this.context.ctxModeRepresentation === "Imbrique" && this.context.ctxEcran === "mdepl") {
        $layout = $(this.parent.layout.el).find(".typeActivite");
        $statusLayout = $(this.parent.layout.el).find(".l-panelB-buttonContainer");
      } else {
        if (!(this.context.ctxEcran === "mdepl")) {
          $layout = this.parent.$el.parent().find(".typeActivite");
          $statusLayout = this.parent.$el.parent().find(".l-panelB-buttonContainer");
        }
      }
      $layout.addClass('d-none');
      $statusLayout.addClass('d-none');
    }
  }

  showTitle(): void {
    let $layout: JQuery = null;
    let $statusLayout: JQuery = null;

    if (this.context && this.context.ctxEcran !== "saisiescoll" && this.context.ctxEcran !== "saisiemass") {
      if (this.context.ctxModeRepresentation === "Imbrique" && this.context.ctxEcran === "mdepl") {
        $layout = $(this.parent.layout.el).find(".typeActivite");
        $statusLayout = $(this.parent.layout.el).find(".l-panelB-buttonContainer");
      } else {
        if (!(this.context.ctxEcran === "mdepl")) {
          $layout = this.parent.$el.parent().find(".typeActivite");
          $statusLayout = this.parent.$el.parent().find(".l-panelB-buttonContainer");
        }
      }
      $layout.removeClass('d-none');
      $statusLayout.removeClass('d-none');
    }
  }

  protected manageModeUnselected(unSelected: boolean): void {
    const formMode: string = this.btnBar.model.get("mode");

    if (unSelected === true && formMode !== "E") {
      this.$el.find(".act-deplacement").hide();
      this.hideBtnBar();
      this.hideTitle();
      this.$el.find(".unselectedElementText").show();
      this.btnBar.model.trigger("hide:delete");
    } else {
      this.$el.find(".act-deplacement").show();
      this.showBtnBar();
      this.showTitle();
      this.$el.find(".unselectedElementText").hide();
    }
  }

  public _notifyEdition(event: JQueryEventObject): void {
    super._notifyEdition(event);
    this._initSubtitle(true);
  }

  /**
   * Selects the first element in the form
   */
  public _setFocus(): void {
    this.$el.find("fieldset .cw-selecteur-activite-input:visible").focus();
  }

  protected _cleanDefaultValidationErrorsGererActivite(): void {
    const gererActivite = this._getModel();

    if (gererActivite.validationError) {
      // Clear current validation errors
      gererActivite.validationError.errorValidation = "";
      gererActivite.validationError.errors = {};
    }
    // Clear current validation erros
    this._cleanValidationErrors();
  }

  _checkIsClosed(): void {
    this.isClosed = true;
  }

  _hasMorePlanActActivites(infoOptions: { [key: string]: any }): boolean {
    if (!_.isEmpty(infoOptions) && !_.isEmpty(infoOptions.model) && !CWSTR.isBlank(infoOptions.matricule)) {
      const activitieId: string = infoOptions.model.id;
      const elt: string = infoOptions.matricule;
      const activitiesArrayElt = _.filter(this.plworkflow.evtModel.get("ACTIVITES").models, (model: { [key: string]: any }): boolean => {
        return String(model.get("elt")) === String(elt);//ne pas supprimer "String("
      });
      const countActivities = _.reduce(activitiesArrayElt, (acum: number, element: { [key: string]: any }): number => {
        const elements = _.filter(element.get("evt").models, (model: { [key: string]: any }): boolean => {
          const codeFindAct = model.get("dts").row;
          const isActExterne = (model.get("dts").ext === false);

          return (codeFindAct !== activitieId && isActExterne);
        });

        return (elements.length > 0) ? acum + elements.length : acum;
      }, 0);

      return (countActivities > 0) ? true : false;
    } else {
      return false;
    }
  }

  _hasMorePlanActIndicateursActivites(infoOptions: { [key: string]: any }): boolean {
    if (!_.isEmpty(infoOptions) && !_.isEmpty(infoOptions.model) && !CWSTR.isBlank(infoOptions.matricule)) {
      const activitieId: string = infoOptions.model.id;
      const elt: string = infoOptions.matricule;
      const indicateursArrayElt = _.filter(this.plworkflow.evtModel.get("INDICATEURS").models, (model: { [key: string]: any }): boolean => {
        return String(model.get("elt")) === String(elt);//ne pas supprimer "String("
      });
      const countIndicateurs = _.reduce(indicateursArrayElt, (acum: number, element: Backbone.Model): number => {
        const models = element.get("evt").models;
        let totalCount = 0;

        if (models instanceof Array) {
          totalCount = _.reduce(models, (acumModels: number, model: Backbone.Model): number => {
            let count = 0;

            if (!CWSTR.isBlank(model.get("dts")) && !CWSTR.isBlank(model.get("dts").typo)) {
              count = _.reduce(model.get("dts").typo, (acumPartial, dataTypo: { [key: string]: any }) => {
                let prevCount = 0;

                if (!_.isNull(dataTypo.prev) && dataTypo.prev instanceof Array) {
                  prevCount = _.filter(dataTypo.prev, (indicateurPrevData: { [key: string]: any }): boolean => {
                    const isExterneActPrev = (indicateurPrevData.ext === false);

                    return (indicateurPrevData.row !== activitieId && isExterneActPrev);
                  }).length;
                }
                //les activités réalisées ne sont pas ajoutées/utilisées dans le plan de besoins.
                return acumPartial + prevCount;
              }, 0);
            }
            return acumModels + count;
          }, 0);
          return acum + totalCount;
        } else {
          return acum;
        }
      }, 0);

      return (countIndicateurs > 0) ? true : false;
    } else {
      return false;
    }
  }

  _showMessageModifyActGest(): boolean {
    let rtn = false;
    const response = this.infoErrorModify?.response;

    if (this.infoErrorModify?.isModifiable === false && !_.isEmpty(response) && ((response.responseJSON?.icone === "X" && response.responseJSON?.boutons[0].action === "ABORT") || (response.responseJSON?.icone === "?" && response.responseJSON?.boutons[1].action === "ABORT"))) {
      rtn = true;
    }
    return rtn;
  }

  _MessageModifyActGest(): void {
    if (this.infoErrorModify?.isModifiable === false && !_.isEmpty(this.infoErrorModify?.error) && !_.isEmpty(this.infoErrorModify?.response)) {//&& !this._manageReadOnlyReposOblig(this._getModel())) {
      if (this.isEditable === false) {
        this._disableForm();
      }
      this.infoErrorModify.options.success = (): void => {
        this.isEditable = true;
        this.infoErrorModify.isModifiable = this.isEditable;
        this._enableForm();
      };
      onError(this.infoErrorModify?.error, this.infoErrorModify?.response, this.infoErrorModify?.options);
    }
  }

  stopMessagePrevClickActivite(): void {
    if (this.$el) {
      this.$el.find(".gereractivite-form-inputs").off("focusin");
    }
  }

  _actionPrevClickActivite(): void {
    if (this._showMessageModifyActGest()) {
      const $posCalend = this.$el.find("input.typeDate:not([readonly])");
      const listeCombos = _.filter(this, (item: any): boolean => {
        return (item instanceof CWComboBoxView2);
      });

      //Combo's
      this.notCheckCombos = false;
      if (listeCombos && listeCombos.length > 0) {
        for (let i = 0; i < listeCombos.length; i++) {
          if (!CWSTR.isBlank(listeCombos[i].coll)) {
            listeCombos[i].stopListening(listeCombos[i].coll, "sync");
            listeCombos[i].listenToOnce(listeCombos[i].coll, "sync", (): void => {
              if (!this.notCheckCombos) {
                const $tempComboFetch = listeCombos[i].$el.find("input.c-cwComboBoxView2__input");

                this.notCheckCombos = true;
                if ($tempComboFetch.autocomplete("instance")) {
                  $tempComboFetch.autocomplete("close");
                }
              }
            });
          }
        }
      }
      //Si l'il y a d'information complémentaire, on doit rechercher tous les les listes de sélection
      if (!_.isEmpty(this._getModel()) && !_.isEmpty(this._getModel().get("infocomp"))) {
        const $posCombos = this.$el.find("input.c-cwComboBoxView2__input:not([readonly])");

        if ($posCombos && $posCombos.length > 0) {
          for (let i = 0; i < $posCombos.length; i++) {
            const $tempCombo = $($posCombos[i]);

            if ($tempCombo.autocomplete("instance") && $tempCombo.autocomplete("widget").is(":visible")) {
              $tempCombo.autocomplete("close");
            }
          }
        }
      }
      //Datepicker's
      if ($posCalend && $posCalend.length > 0) {
        for (let i = 0; i < $posCalend.length; i++) {
          $($posCalend[i]).datepicker("hide");
        }
      }
      //Boutons
      if (this.btnBar.isVisible("revert") || this.btnBar.isVisible("save")) {
        this.btnBar.model.trigger("btn:click", "revert");
      }
    }
  }

  _checkModeSilentModifyActGest(model: CWGererActivitesModel | CWGererActivitesDeplacementModel, callback: (result: boolean, infoError?: { [key: string]: any }, infoResponse?: { [key: string]: any }) => any): void {
    const activiteId = model ? model.get("id") : null;
    const transformationEnCours = (this.context.ctxModeInitialisation === "Transformation" || this.context.ctxModeInitialisation === "Declaration");

    if (activiteId && transformationEnCours !== true) {
      if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationGestion)) {
        const isModifiableModel = new CWGererActivitesGestionModel();

        isModifiableModel.id = activiteId;
        isModifiableModel.action = "modifiable";
        isModifiableModel.setHabContext(new CWHabilitationContext({
          onglet: this.context.ctxEcran,
          foncCour: this.context.ctxHabilitation.HabilitationGestion,
          natGest: ""
        }));
        if (!CWSTR.isBlank(this.context.ctxSimulation)) {
          isModifiableModel.activeSimulation = true;
        }
        isModifiableModel.fetch({
          success: (): void => {
            this.isEditable = true;
            if (typeof callback === "function") {
              callback(true);
            }
          },
          error: (model?: { [key: string]: any }, response?: { [key: string]: any }): void => {
            this.isEditable = false;
            if (response.status === 406 && typeof callback === "function") {
              const lError: { [key: string]: any } = {};
              const lResponse = _.clone(response);

              lError.response = _.clone(response);
              lError.responseData = _.clone(response.responseJSON);
              //après les actions précédentes de "clone", on doit changer la valeur de status et omit, pour traiter ici le message
              response.responseText = null;
              response.omit = true;
              if (lResponse.responseJSON?.icone === "?" && lResponse.responseJSON?.boutons.length > 1) {
                lResponse.responseJSON.boutons[0].action = "CONTINUE";//l'information possible du premier bouton ne sera pas utilisé. On doit la changer pour suivre avec l'action
                lResponse.responseText = JSON.stringify(lResponse.responseJSON);
              }
              callback(false, lError, lResponse);
            }
          }
        });
      } else {
        this.isEditable = false;
        if (typeof callback === "function") {
          callback(false);
        }
      }
    } else {
      this.isEditable = true; //création
      if (typeof callback === "function") {
        callback(true);
      }
    }
    this.errorPetAntSaveDelete = false;
  }

  _desactiverPieceJointe(etat: boolean): void {
    if (_.isBoolean(etat) && !_.isEmpty(this.pieceJointe)) {
      const paramPJointe = (CWDIVERS.get("pJointe") === "1");

      if (paramPJointe === true && this.context.ctxPieceJointe === true && etat === true) {
        this.pieceJointe.desactiverPieceJointe(true);
      } else {
        this.pieceJointe.desactiverPieceJointe(false);
      }
    }
  }
  _getModel(): CWGererActivitesModel {
    return super._getModel() as CWGererActivitesModel;
  }
}
