import { i18n } from './i18n.js';
import { LOG } from './utils/log.js';
import { CWLOG } from './utils/cwLog';
import { objs } from './objectsRepository';
import { CWAppRouter } from './app/cwRouter';
import { PhoenixAppRouterFrame } from './appFrame/router.js';
import { UTILS } from './utils/utils.js';

import './sass/main.scss';
if (!window.Promise) {
  window.Promise = require('promise-polyfill').default;
}
if (!window.fetch) {
  objs.isWhatwgFetch = true;
  Configuration.x_token_key = "";
  require('whatwg-fetch');
}

if (window.fetch) {
  Backbone.ajax = require('./backbone.fetch').default;
}

if (!window.Map) {
  require('es6-map/implement');
}
import { config } from './root/root';

//Configure the log level for the application

if (!Backbone.History.started) {

  config();

  LOG.level = Configuration.logLevel;
  CWLOG.level = Configuration.logLevel;

  i18n.changeLanguage("fr", function() {

    // Add feedback to Ajax requests
    $("#phx-loading_feedback").hide();
    // Show feedback gif on ajax request
    $(document).on("ajaxStart", function() {
      LOG.debug("ajaxStart");

      // Add feedback to Ajax requests
      $("#phx-loading_feedback").show();

      //UTILS.showHidder();
    });
    // Hide feedback gif on ajax stop
    $(document).on("ajaxStop", function() {
      LOG.debug("ajaxStop");

      // Add feedback to Ajax requests
      $("#phx-loading_feedback").hide();

      UTILS.resetHidder();
    });
    $(document).on("ajaxError", function(event, xhr) {
      LOG.debug("ajaxError");

      // Add feedback to Ajax requests
      $("#phx-loading_feedback").hide();
      //Si omit = true, nous ne ferons pas le "resetHidder". Dans un autre cas, oui.
      if (!(xhr && _.isBoolean(xhr.omit) && xhr.omit)) {
        UTILS.resetHidder();
      }
    });

    // Workaround to imitate pseudo-selector :focus-within
    // that is not available in all modern browsers
    $(document).on("focus", ".form-control", (ev) => {
      const parent = $(ev.target).parent(".input-group,.btn-group");
      if (parent) { parent.addClass("focus-within"); }
    }).on("blur", ".form-control", (ev) => {
      const parent = $(ev.target).parent(".input-group,.btn-group");
      if (parent) { parent.removeClass("focus-within"); }
    });

    // Simulate a click when pressing "Enter" key
    // in elements with class .cw-triggerClick
    $(document).on("keydown", ".cw-triggerClick", (ev) => {
      const keycode = (ev.keyCode ? ev.keyCode : ev.which);
      if (keycode === 13) {
        $(ev.target).click();
      }
    });

    // Set the focus in the date input to format it with the correct mask before call _showDatepicker
    $.datepicker._oldShowDatepicker = $.datepicker._showDatepicker;
    $.datepicker._showDatepicker = function(input) {
      if ($(input).is(":visible") && !$(input).is(":disabled") && !$(input).is(":focus")) {
        input.focus();
      }
      return $.datepicker._oldShowDatepicker(input);
    };

    LOG.info("Init application...");
    var lan = UTILS.getLanguage();
    $("body").attr("lang", lan);
    LOG.info("Selected language : " + lan);

    if (UTILS.isFramed() === false) {
      objs.appRt = new CWAppRouter();
    } else {
      objs.appRt = new PhoenixAppRouterFrame();
    }

    LOG.info("Routers initialized");
    // Change to start only if online
    Backbone.history.start();

    LOG.info("Backbone initialized");
    objs.appRt.configureHomeLayout();

    LOG.info("Set up jQuery UI components at the home");
    LOG.info("Application successfully started!");
  });
}

if (module.hot) {
  module.hot.accept();
}
