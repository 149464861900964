module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="phx-profiles-menu">\n    <div class="phx-menu">\n        <span class="ui-icon phx-profiles-first-icon cw-fleche-pleine-gauche-icon c-panneauMenu__submenuIcon">\n            <span></span>\n        </span>\n        <span class="phx-profiles-menu-header ui-phx-ihm-texte-application" tabindex="0"></span>\n    </div>\n    <ul class="phx-profiles-menu-list ui-menu ui-corner-all c-panneauMenu">\n        ';
 if(tousCheck === "1"){ 
__p+='\n        <div class="phx-profiles-tous-check">\n            <li class=\'ui-menu-item\' id="tousli">\n                <span class=\'c-panneauMenu__tickIcon cw-icon cw-icon-16 cw-valider-icon\' style="display:none"></span>\n                <span id="tous" class="phx-bistate-box">\n                    <a href="javascript:void(0)" tabindex="-1" class="prfall-check">\n                        '+
((__t=(i18n.t("profilutilisateur.tous_check")))==null?'':__t)+
'\n                    </a>\n                </span>\n            </li>\n        </div>\n        ';
 } 
__p+='\n        <div class="phx-profiles-menu-mesProfils" style="display: flex;\n        flex-direction: column;\n        align-items: flex-start;\n        align-content: flex-start;"></div>\n\n        <div class="phx-profiles-menu-profilsRecus" style="display:none">\n            <li class=\'phx-menu-link ui-menu-divider\'></li>\n            <span class="phx-profiles-recus"></span>\n        </div>\n        <li class=\'phx-menu-link ui-menu-divider\'></li>\n        <li>\n            <div class="phx-buttonBar">\n                <button type="button" class="defaut ui-button-special ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" value="new" role="button" style="display: inline-block;">\n                    <span id="defaut" class="ui-button-text">'+
((__t=(i18n.t("profilutilisateur.defaut")))==null?'':__t)+
'</span>\n                </button>\n            </div>\n        </li>\n    </ul>\n</div>';
}
return __p;
};
