module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<fieldset class="gereractivites-detail-form cw-fieldset">\n    <div class="phx-formTable">\n        <div class="form-group phx-formErrors ui-state-error"></div>\n        <div class="gereractivite-content">\n            <div class="collab form-group">\n                <label for="matricule" class="cw-required">'+
((__t=(i18n.t("common:gererhoraires.collab")))==null?'':__t)+
'</label>\n                <span class="cmbCollaborateurs"></span>\n                <span class="matricule-error-container"></span>\n            </div>\n            <div class="gereractivite-form-inputs">\n                <div class="wrap-form">\n                    <div class="gereractivites-detail-events">\n                        <div class="row">\n                            <div class="typeEvenement"></div>\n                            <div class="typeSaisie"></div>\n                            <div class="modeSaisie"></div>\n                        </div>\n                        <div class="row">\n                            <div class="attribAutoContainer inline" title=\''+
((__t=(i18n.t("common:gereractivites.activratthor_tooltip")))==null?'':__t)+
'\'>\n                                <label>&nbsp;</label>\n                                <input type="checkbox" value="true" name="addhoraireauto" class="addhoraireauto" />\n                                <label for="addhoraireauto">'+
((__t=(i18n.t("common:gereractivites.activratthor")))==null?'':__t)+
'</label>\n                            </div>\n                            <div class="originActif clearfix">\n                                <label for="origine.code" class="">\n                                    '+
((__t=(i18n.t("common:gereractivites.origine")))==null?'':__t)+
'</label>\n                                <span class="comboOrigine" title=""></span>\n                            </div>\n                            <div class="blockComplement clearfix">\n                                <label for="complement clearfix" class="complement">\n                                    '+
((__t=(i18n.t("common:gereractivites.infocomplaffect")))==null?'':__t)+
'</label>\n                                <input class="complement form-control" type="text" maxlength="60"></input>\n                            </div>\n                            <span class="gereractivites-piecesjointes"></span>\n                            <div class="gereractivites-infocomp clearfix">\n                                <div class="gereractivite-content-btn-bar"></div>\n                            </div>\n                        </div>\n\n                    </div>\n\n\n                    <span class="gereractivites-wkfhisto"></span>\n                    <span class="gereractivites-evenamentmessage"></span>\n                    <span class="gereractivites-IndicateurPermut"></span>\n                </div>\n            </div>\n        </div>\n\n        <ul class="validerListMenu menuContent">\n        </ul>\n        <ul class="declarerListMenu menuContent">\n        </ul>\n</fieldset>';
}
return __p;
};
