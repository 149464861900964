module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cw-treenode-row d-flex align-items-center">\n  <span class="cw-treenode-leaf-icon" style="display:none"></span>\n  <span class="cw-treenode-icon"></span>\n  <div class="cw-treenode-wrap">\n    <div class="d-flex align-items-center">\n      <div class="custom-control custom-checkbox cw-treenode-check-container" style="display:none">\n          <input type="checkbox" id="cw-treenode-check'+
((__t=(cid))==null?'':__t)+
'" class="cw-treenode-check" value="true" />\n          <label for="cw-treenode-check'+
((__t=(cid))==null?'':__t)+
'" class="cw-texteNormal custom-control-label">'+
((__t=(libelle))==null?'':__t)+
'</label>\n      </div>\n      <span class="cw-treenode-label cw-texteNormal">'+
((__t=(libelle))==null?'':__t)+
'</span>\n      <div class="tree_arrows">\n        <button type="button" class="cw-treenode-prev ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only" role="button" style="display:none">\n          <span class="ui-button-icon-primary ui-icon ui-icon-triangle-1-w" ></span>\n        </button>\n        <button type="button" class="cw-treenode-next ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only" role="button" style="display:none">\n          <span class="ui-button-icon-primary ui-icon ui-icon-triangle-1-e" ></span>\n        </button>\n      </div>\n      <span class="cw-treenode-refresh" style="visibility:hidden;"></span>\n    </div>\n  </div>\n</div>\n';
}
return __p;
};
