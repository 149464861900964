import { i18n } from '../i18n.js';
import { STR } from 'utils/str.js';

/**
 * Enumerations definitión and getter
 */
export var ENUMS = {

  /**
   * Types de valeur
   */
  TYPE_VALEUR: "_typevaleur",
  _typevaleur: ["1", "2", "3"],

  /**
   * Souhait or Absence
   */
  SOUHAIT_OR_ABSENCE: "_souhaitAbsence",
  _souhaitAbsence: ["0", "1", "2"],

  /**
   * Souhait or Absence
   */
  ABSENCE_SOUHAIT: "_absenceSouhait",
  _absenceSouhait: ["1", "2"],

  /**
   * Régularisation protégée GTM
   */
  REGULARISATION_PROTEGEE: "_regularisationprotegee",
  _regularisationprotegee: ["GTMreg_RS", "GTMreg_RSI", "GTMreg_RQ"],

  /**
   * Vitesses de transmission
   */
  VITESSES_TRANSMISSION: "_vitessestransmission",
  _vitessestransmission: ["300", "600", "1200", "1400", "4800", "9600"],

  /**
   * Actions sites commutés
   */
  ACTION_SITES_COMMUTES: "_actionsitescommutes",
  _actionsitescommutes: ["0", "1", "2", "3"],

  /**
   * Coefficient multiplicateur
   */
  COEFFICIENT_MULTIPLICATEUR: "_coefficientmultiplicateur",
  _coefficientmultiplicateur: ["1", "10", "100", "1000"],

  /**
   * Type de valeur divers
   */
  TYPE_VALEUR_DIVERS: "_typevaleurdivers",
  _typevaleurdivers: ["A", "N"],

  /**
   * Opérateurs
   */
  OPERATEURS: "_operateurs",
  _operateurs: ["E", "B", "L", "G"],

  /**
   * Type de calcul périodicité si sortie
   */
  TYPE_CALCUL_PERIODICITE: "_typecalculperiodicite",
  _typecalculperiodicite: ["A", "J", "I"],

  /**
   * regles de workflow
   */
  WKF_REGTYPE: "_wkfregtype",
  _wkfregtype: ["AVANT", "DEBUT", "EVALUATION", "TRAITEMENT"],
  /**
   * Critères de domaine d'application
   */
  CRITERES_DOMAINE_APP: "_criteresdomaineapp",
  _criteresdomaineapp: ["E", "M", "H", "P", "C"],

  /**
   * Intitulés par défaut des classifications
   */
  INTITULES_DEFAUT_CLASSIF: "_intitulesdefautclassif",
  _intitulesdefautclassif: ["210", "220", "230"],

  /**
   * Nature de l'affichage d'un élément
   */
  NATURE_AFFICHAGE_ELEMENT: "_natureaffichageelement",
  _natureaffichageelement: ["1", "2", "3", "4"],

  /**
   * Niveaux de priorités
   */
  NIVEAUX_PRIORITES: "_niveauxpriorites",
  _niveauxpriorites: ["0", "1", "2", "3", "4", "5", "6", "7"],

  /**
   * Niveaux de définition des paramètres généraux
   */
  NIVEAUX_DEFINITION: "_niveauxdefinition",
  _niveauxdefinition: ["ASSORT", "GENERAL", "STRUCTURE"],

  /**
   * Domaine d’utilisation
   */
  DOMAINE_UTILISATION: "_domaineutilisation",
  _domaineutilisation: ["BESOIN", "MODUL", "OPTIMI"],

  /**
   * Caractères obligatoires
   */
  CARACTERES_OBLIGATOIRES: "_caracteresobligatoires",
  _caracteresobligatoires: ["O", "F", "N"],

  /**
   * Types de valeur éditée
   */
  TYPES_VALEUR_EDITEE: "_typesvaleureditee",
  _typesvaleureditee: ["R", "C"],

  /**
   * Commande
   */
  COMMANDE: "_commande",
  _commande: ["00", "01", "28", "61", "62", "63", "64", "M0"],
  _commande_valeur: ["0", "0", "0", "1", "1", "1", "1", "0"],

  /**
   * Horaire attaché
   */
  HORAIRE_ATTACHE: "_horaireattache",
  _horaireattache: ["O", "N", "X"],

  /**
   * Type jour exceptionnel obligatoire
   */
  TYPE_JOUR_EXCEP_OBLIGATOIRE: "_typejourexcepobligatoire",
  _typejourexcepobligatoire: ["F", "F1", "F2", "F3"],

  /**
   * Numéro de badge utilisé
   */
  NUMERO_BADGE_UTILISE: "_numerobadgeutilise",
  _numerobadgeutilise: ["0", "1", "2"],

  /**
   * Test présence collaborateur
   */
  TEST_PRESENCE_COLLABORATEUR: "_testpresencecollaborateur",
  _testpresencecollaborateur: ["0", "2", "3"],

  /**
   * Code fonction
   */
  CODE_FONCTION: "_codefonction",
  _codefonction: ["F0", "FA", "FB", "FC", "FD", "FE", "FF", "FG", "FH", "FI", "FJ", "FK", "FL", "FM", "FN", "FO",
    "FP"
  ],

  /**
   * Oui/Non facultatif
   */
  OUI_NON_FACULTATIF: "_ouinonfacultatif",
  _ouinonfacultatif: ["O", "N"],

  /**
   * Types de valeur d'arrêté
   */
  TYPES_VALEUR_ARRETE: "_typesvaleurarrete",
  _typesvaleurarrete: ["A", "C", "R"],

  /**
   * Liste Des Methodes
   */
  LISTE_DES_METHODES: "_listedesmethodes",
  _listedesmethodes: ["1", "2", "3"],

  /**
   * Compteurs temps réel
   */
  COMPTEURS_TEMPS_REEL: "_compteurstempsreel",
  _compteurstempsreel: ["1", "2", "3", "4"],

  /**
   * Type anomalies
   */
  TYPE_ANOMALIES: "_typeAnomalies",
  _typeAnomalies: ["B", "N", "P"],

  /**
   * Type calcul anomalies
   */
  TYPE_CALCUL_NOMALIES: "_typeCalculAnomalies",
  _typeCalculAnomalies: ["C", "A", "P"],

  /**
   * Type Tri Numeric
   */
  TYPE_TRI_NUM: "_typeTriNum",
  _typeTriNum: ["C", "D"],

  /**
   * Type Tri Text
   */
  TYPE_TRI_TEXT: "_typeTriText",
  _typeTriText: ["C", "D"],

  /**
   * Fichiers
   */
  FICHIERS: "_fichiers",
  _fichiers: ["fdmux", "fdlog", "fdgest", "fdctl"],

  /**
   * Types de colonne pour le paramétrage des synoptiques
   */
  TYPES_COLONNE_PARAM_SYNO: "_typescolonneparamsyno",
  _typescolonneparamsyno: ["A", "C", "T"],

  /**
   * Types de référence pour le paramétrage des synoptiques
   */
  TYPES_REFERENCE_PARAM_SYNO: "_typesreferenceparamsyno",
  _typesreferenceparamsyno: ["N", "A", "C"],

  /**
   * Portées caractéristiques d'attribution de qualifications
   */
  PORTEES_CARAC_ATTR_QUALIF: "_porteescaracattrqualif",
  _porteescaracattrqualif: ["N", "O", "F"],

  /**
   * Type de saisie à proposer par défaut en création d’une affectation à une activité du domaine.
   */
  TYPE_SAISIE_DEFAUT_ACTIVITE: "_domActTypeSaiDef",
  _domActTypeSaiDef: ["RE", "PH", "PU"],

  /**
   * Liste des actions automatiques
   */
  ACTION_AUTOMATIQUE: "_actionAutomatique",
  _actionAutomatique: ["ACCEPTATION", "RELANCE", "REFUS"],

  /**
   *Type de paramétrage appliqué à la remontée de la descendance
   */
  STRUCTURE_DESCENDANCE: "_structureDescendance",
  _structureDescendance: [0, 1, 2, 3],

  /*
  Niveaux d'utilisateurs pour contrôles
  */
  NIVEAUX_UTIL: "_niveauxutil",
  _niveauxutil: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],

  /**
   * Get enumeration array with this format: [ {"code" : <x>, "libelle" :
   * <internationalization_libelle> }];
   */
  getValues: function(enumId) {
    var enumArray = [];
    var codes = this[enumId];
    _.each(codes, function(code) {
      enumArray.push({
        "code": code,
        "libelle": i18n.t('common:' + enumId.substring(1) + '_' + code)
      });
    }, this);
    return enumArray;
  },

  /**
   * Get the libelle of an array filtering by the code.
   */
  getLibelle: function(enumId, code) {
    var enumArray = this.getValues(enumId);
    var filteredObject = _.find(enumArray, function(object) {
      return String(object.code) === String(code);
    });

    if (!STR.isBlank(filteredObject) && !_.isNull(filteredObject)) {
      return filteredObject.libelle;
    } else {
      return null;
    }
  }
};
