import { ActionBarView } from 'core/controls/actionBar.view.js';
import { ColumnsChooserView } from './columnsChooser.view.js';
import { DataGridCellThFakeView } from '../data_grid/dataGridCellThFake.view.js';
import { DataGridCellThView } from '../data_grid/dataGridCellTh.view.js';
import { DataGridRowView } from '../data_grid/dataGridRow.view.js';
import { HABILITATION } from 'utils/habilitation.js';
import { i18n } from 'src/i18n.js';
import { InternColumnModel } from './internColumn.model.js';
import { InternVueModel } from './internVue.model.js';
import { LOG } from 'utils/log.js';
import { MainChooserView } from './mainChooser.view.js';
import { objs } from 'src/objectsRepository';
import { STR } from 'utils/str.js';
import { UTILS } from 'utils/utils.js';
import TPL_common_table from './table.tpl.html';

export var BaseGridView = Backbone.View.extend({

  /**
   * Event launched by the parent class to reload any table
   *
   */

  /**
   * Default grid columns width
   */
  DEFAULT_COL_WIDTH: 100,
  /**
   * Default grid columns width
   */
  DEFAULT_MIN_COL_WIDTH: 100,
  /**
   * Default grid height
   */
  DEFAULT_HEIGHT: 350,
  /**
   * Default grid width, format: Number%
   */
  DEFAULT_WIDTH: "100%",
  /**
   * Rows per page quantity if height can't be calculated
   */
  DEFAULT_ROWS_PER_PAGE: 12,
  /**
   * Name of the Grid by default
   */
  DEFAULT_NANE: "Base",

  /**
   * Parameter to indicate if update is allowed by habilitations.
   */
  habilitationUpdate: true,

  /**
   * Parameter that indicate that the table has been globally locked by an external habilitation (see function lockTable).
   */
  lockedByHabilitations: false,

  /**
   * Parameter to indicate if this table is editable.
   */
  editable: false,

  /**
   * Parameter that tells if the table allows multi selection.
   */
  multiselection: false,

  /**
   * Indicates an attribute to filter rows that can be selected
   */
  filterSelection: "",

  /**
   * Indicates an attribute to filter rows that can be selected
   */
  filterSelectionFunction: undefined,

  /**
   * parameter that tells if the rows are selectable
   */
  selectable: true,

  /**
   * Parameter that paint the column chooser btn
   */
  columnsChooser: true,

  /**
   * Defines how the height of the grid should be calculated.
   * posible values : statique, auto, nolimite
   */
  gridHeightType: "statique",

  /**
   * Minimum rows visible (just to have a consistent grid representation when there is less than x rows --> nolimite.
   */
  minVisibleRows: 3,

  /**
   * Maximum rows visible (just to have a consistent grid representation when there is more than x rows --> auto
   */
  maxVisibleRows: null,

  /**
   * In case the grid is inside a dialog we can pass the dialog to calculate table size.
   */
  parentContainer: null,

  /**
   * In this case the buttons omit the qualification attributes
   */
  omitHabilitationAttributes: false,

  /**
   * push the new button in left o right the actionBar default buttons
   */
  positionNewButtonsOnLeft: false,

  /**
   * Remove grid style
   */
  removeGridStyle: false,

  /**
   * Constructor
   * View underlying a generic grid
   *  	ex: {property: "nature", title: "Nature", width: 50}
   *  	can be one of these: "statique", "auto", "nolimite".
   */
  initialize: function(options) {
    var self = this;

    this.height = this.DEFAULT_HEIGHT;
    this.width = this.DEFAULT_WIDTH;
    this.cellRenderers = {}; //Allow the definition of custom cell renderer per column

    this.minWidthMode = (options.minWidthMode) ? options.minWidthMode : false;

    // habilitation
    this.habilitations = options.habilitations;

    //habilitation in mode M
    this.habModeM = false;
    if (options && options.habModeM) {
      this.habModeM = options.habModeM;
    }

    if (options && options.defaultHeight && _.isNumber(options.defaultHeight)) {
      this.height = options.defaultHeight;
    }

    // set the title of the table
    this.title = options.title;
    this.showFilteredRowsInTitle = options.showFilteredRowsInTitle;
    this._isRowUpdatable = true;

    this.rowHeight = null; // It used to be on the outside as class variable
    this.gridHeight = null;

    this.template = TPL_common_table;

    if (options && _.isBoolean(options.selectable)) {
      this.selectable = options.selectable;
    }

    this.selectInSorting = true;
    if (options && !STR.isBlank(options.selectInSorting)) {
      this.selectInSorting = options.selectInSorting;
    }

    //showButtonsMultipleVues decides if  buttons that allow to change vue selected are going to be shown
    if (options && !STR.isBlank(options.showButtonsMultipleVues)) {
      this.showButtonsMultipleVues = options.showButtonsMultipleVues;
    } else {
      this.showButtonsMultipleVues = false;
    }

    //showLevierMultipleViews decides if  buttons that allow to change vue selected are going to be shown
    if (options && !STR.isBlank(options.showLevierMultipleViews)) {
      this.showLevierMultipleViews = options.showLevierMultipleViews;
    } else {
      this.showLevierMultipleViews = false;
    }

    //Create a standard vue if vue does not exist
    if (!options.vues) { //Initialize this.vues an this.currentVue when only one view is going to be used
      this.vues = {};
      //columns definition
      if (!options.columns) {
        throw new Error("You must define the columns attribute to use a " + this.DEFAULT_NANE + "GridView");
      }

      if (options.minWidthMode) {
        options.columns.forEach(function(column) {
          if (!Object.prototype.hasOwnProperty.call(column, "minWidth")) {
            throw new Error("You must define the columns minWidth attribute for each column in , min-width-mode");
          }
        })
      }

      var vue = new InternVueModel(null, options);
      this.defaultVue = "CURRENT";
      vue.columns = options.columns;
      this.vues["CURRENT"] = vue;
      this.currentVue = this.vues["CURRENT"];

    } else { //Initialize this.vues an this.currentVue when there are many views defined for this table
      if (options.defaultVue) {
        this.defaultVue = options.defaultVue;
      } else {
        throw new Error("You must define a default vue to use a " + this.DEFAULT_NANE + "GridView");
      }
      this.vues = {};
      _.each(_.keys(options.vues), function(key) {
        if (!options.vues[key].columns) {
          throw new Error("You must define the columns attribute for each vue to use a " + self.DEFAULT_NANE + "GridView");
        } else {
          // self.vues[key] = new InternVueModel(key, options.vues[key].columns, options.vues[key].nongroupee, options.vues[key].menuName, options.vues[key].buttonText, options.vues[key].beforeFetchCallback);
          self.vues[key] = new InternVueModel(key, options.vues[key]);
        }
      }, this);

      if (Object.keys(options.vues).length > 0) {
        this.currentVue = this.vues[this.defaultVue];
        if (this.showButtonsMultipleVues === true) {
          this._initializeVuesButtonBar();
        }
      } else {
        throw new Error("You must define at least one vue to use a " + this.DEFAULT_NANE + "GridView");
      }

      this.model.on("change:currentVue", this._manageVueChange, this);
    }
    this.emptyMessage = "";
    if (options && options.emptyMessage) {
      this.emptyMessage = options.emptyMessage;
    }

    // check if there is only one column to hide the column chooser button
    if (this.currentVue.columns.length === 1 && Object.keys(this.vues).length === 1) {
      this.columnsChooser = false;
    } else {
      if (!STR.isBlank(options.columnsChooser)) {
        this.columnsChooser = options.columnsChooser;
      }
    }

    this.forceHideChooserMenu = (options.forceHideChooserMenu === true);

    this.multiselection = (options.multiselection === true);
    // add multi-selection column
    if (this.multiselection) {
      _.each(this.vues, function(vue) {
        vue.columns.splice(0, 0, { title: "", property: "phx-multiselect", width: 32 });
      });
      this.model.multiselectColl.on("add", this._manageMultiSelection, this);
      this.model.multiselectColl.on("remove", this._manageMultiSelection, this);

      //filter the selection
      if (options.filterSelection) {
        this.filterSelection = options.filterSelection;
        var obj = {};
        obj[this.filterSelection] = true;
        this.filterMultiSelectColl = obj;
      }

      //filter the selection
      if (options.filterSelectionFunction) {
        this.filterSelectionFunction = options.filterSelectionFunction;
      }
    }

    // add the action column
    if (this.editable && this.selectable) {
      _.each(this.vues, function(vue) {
        if (!self.minWidthMode) {
          vue.columns.push({ title: "", property: "phx-action", width: 36 });
        } else {
          vue.columns.push({ title: "", property: "phx-action", width: 36, "minWidth": 36 });
        }
      });
    }
    // add an empty last column
    _.each(this.vues, function(vue) {
      if (!self.minWidthMode) {
        vue.columns.push({ title: "", property: "phx-last", width: 1 });
      } else {
        vue.columns.push({ title: "", property: "phx-last", width: 18, "minWidth": 18 });
      }
    });

    // Initializes private columns
    _.each(this.vues, function(vue) {
      _.each(vue.columns, function(column) {
        vue._columns[column.property] = new InternColumnModel();
        if (self.minWidthMode) {
          column.width = '100%';
          vue._columns[column.property].width = column.width;
          vue._columns[column.property].minWidth = column.minWidth;
        } else {
          column.width = UTILS.getTextWidth(column.width);
          vue._columns[column.property].width = column.width;
        }
      });
    });

    // configure checkbox, action and last column for every vue
    _.each(_.keys(this.vues), function(key) {
      self.setOmittedColumns(["phx-multiselect", "phx-action", "phx-last"], key);
    });

    _.each(this.vues, function(vue) {
      if (vue._columns["phx-action"]) {
        vue._columns["phx-action"].sortable = false;
        vue._columns["phx-action"].visible = true;
      }
      if (vue._columns["phx-multiselect"]) {
        vue._columns["phx-multiselect"].sortable = false;
        vue._columns["phx-multiselect"].visible = true;
      }

    });

    // repaint rows when a reset event is fired on the collection
    this.model.coll.on("reset", this._paintRows, this);

    // Show/hide columns
    this.model.on("toggle:column", this.toggleColumn, this);
    // Lock/unlock columns
    this.model.on("lock:column", this._lockColumn, this);
    this.model.on("unlock:column", this._unlockColumn, this);

    // Reset rowHeight
    this.model.on("reset:rowHeight", this._resetRowHeight, this);

    //scroll utility
    this.model.on("scroll:to", this._scrollToIndex, this);
    this.model.on("scroll:toIfHidden", this._scrollToIndexIfHidden, this);
    this.model.on("focus:header", this._focusHeader, this);

    // Control pagination limits
    this.lastPage = false;
    this.firstPage = true;

    this.columnsChooserView = null;

    if (options && options.columnsChooserOverridedView) {
      this.columnsChooserOverridedView = options.columnsChooserOverridedView;
    }

    if (this.model.enableSelectionOnDblclick === true) {
      this.enableSelectionOnDblclick = true;
    }
    // refresh row index on select / unselect
    this.listenTo(this.model.coll, "row:select", this._showSelectedRowIndex);
    this.listenTo(this.model.coll, "row:unselect", this._showSelectedRowIndex);

    // table height options
    if (options.gridHeightType) {
      this.gridHeightType = options.gridHeightType;
      this.parentContainer = options.parentContainer;
      if (this.gridHeightType !== "statique" && this.gridHeightType !== "auto" && this.gridHeightType !== "nolimite") {
        this.gridHeightType = "statique";
      }
    }
    if (!STR.isBlank(options.minVisibleRows) && options.minVisibleRows >= 0) {
      this.minVisibleRows = options.minVisibleRows;
    }

    if (options.maxVisibleRows && options.maxVisibleRows > 0) {
      this.maxVisibleRows = options.maxVisibleRows;
    }

    this.useMaxVisibleRows = false;
    if (this.gridHeightType === "nolimite" && options.maxVisibleRows) {
      this.useMaxVisibleRows = true;
    }

    if (this.gridHeightType === "auto") {
      this.listenTo(objs.appRt.workflow, "resize", function() {
        if (self.$el.is(":visible")) {
          self._resizeGrid();
        }
      });
    }

    // event used to fix a bug in IE with the scrolls
    this.listenTo(objs.appRt.workflow, "fixScrollPosition", function() {
      if (self.$el.is(":visible")) {
        self._fixScrollPosition();
      }
    });

    if (options && options.omitHabilitationAttributes) {
      this.omitHabilitationAttributes = options.omitHabilitationAttributes;
    }

    if (options && options.positionNewButtonsOnLeft) {
      this.positionNewButtonsOnLeft = options.positionNewButtonsOnLeft;
    }

    if (options && _.isBoolean(options.removeGridStyle)) {
      this.removeGridStyle = options.removeGridStyle;
    }
  },
  /**
   * Initialize the button bar that will be used to change the selected vue
   */
  _initializeVuesButtonBar: function() {
    /**
     * ButtonBar
     */
    this.vuesButtonBar = new ActionBarView({});
    this.model.vuesBtnBarModel = this.vuesButtonBar.model;
    this.model.vuesBtnBarModel.set("mode", "C");
  },

  /* EVO 537 */
  _addButtonSwitchVues: function() {
    var selectedOption = {},
      selectedText = "",
      selectedTextContainer = $("<span class='vuesLevierSelected'></span>");

    if (this.currentVue && this.currentVue.vueName) {
      selectedText = this.currentVue.vueName;
    } else {
      selectedOption = this.vues[Object.keys(this.vues)[0]];
      selectedText = selectedOption.vueName;
    }

    selectedText = i18n.t('common:grid.vue').toLowerCase() + " " + selectedText.toLowerCase();
    selectedTextContainer.append(selectedText);

    $(".phx-grid-title-bar-rowsNumber", this.el).after(", ");
    var container = $(".phx-grid-title-barContent", this.el);
    var menuContainer = $("<span></span>");
    var btn = $("<span class='btnSwitchVues'></span>")
      .append(selectedTextContainer)
      .append(
        $("<span>")
        .addClass("ui-icon")
        .addClass("ui-icon-triangle-1-s")
        .css("display", "inline-block")
        .css("vertical-align", "middle")
      );
    var menuBtn = this._addMenuSwitchVues(selectedOption);

    menuContainer.append(btn);
    menuContainer.append(menuBtn);
    container.append(menuContainer);

    btn
      .click(function() {
        var menu = menuBtn.show().position({
          my: "left top",
          at: "left bottom",
          of: this //eslint-disable-line
        });
        $(document).one("click", function() {
          menu.hide();
        });
        return false;
      });
  },

  _addMenuSwitchVues: function(selectedOption) {
    // var options = [{ text: "Vue simple", code: "simple" }];
    var self = this;
    var options = this.vues;

    var $menu = $("<ul></ul>");
    _.each(options, function(value) {
      var $li = $("<li></li>");
      var $a = $("<a></a>");

      if (value.code === selectedOption.code) {
        var selectedFlag = $("<span>").addClass("phx-icon phx-icon-planresp-valider");
        $a.append(selectedFlag);
      }

      $a.html(i18n.t('common:grid.vue') + " " + value.vueName.toLowerCase());
      $a.attr("data-code", value.vueName);
      $li.append($a);
      $menu.append($li);
      $li.click(function(event) {
        var target = event.target;
        var vueName = $(target).data("code");
        self.model.trigger("change:currentVue", vueName)
      });
    }, this);
    $menu.menu();
    $menu.css("cursor", "pointer");
    $menu.css("width", "150px");
    $menu.css("position", "absolute");
    $menu.css("z-index", "100");
    $menu.hide();

    return $menu;
  },

  // END EVO 537

  /**
   * Enables/Disables the Grid
   */
  enabled: function(enable) {
    this.editable = enable && this.habilitationUpdate;
    if (enable) {
      $(this.el).find(".phx-grid").removeClass("ui-state-disabled");
      $(this.el).find(".phx-grid-disabled").width(0);
      $(this.el).find(".phx-grid-disabled").height(0);
    } else {
      $(this.el).find(".phx-grid").addClass("ui-state-disabled");
      var width = $(this.el).find(".phx-grid").width();
      var height = $(this.el).find(".phx-grid").height();
      $(this.el).find(".phx-grid-disabled").width(width);
      $(this.el).find(".phx-grid-disabled").height(height);
    }

  },

  setHeight: function(height) {
    this.height = height;
  },

  /**
   * Locks the grid forbidding the access to buttons or editable rows.
   */
  lockTable: function() {
    this.lockedByHabilitations = true;

    this.applyHabilitations();

    if (this.lockedByHabilitations === true) {
      this.buttonBar.model.trigger("hab.hide:all");
      this.habilitationUpdate = false;
      this.editable = false;
    }
  },

  /**
   * Unlocks the grid allowing the access to buttons or editable rows.
   */
  unLockTable: function() {
    this.lockedByHabilitations = false;

    this.buttonBar.model.trigger("hab.show:all");
    this.habilitationUpdate = true;
    this.editable = true;

    this.applyHabilitations();
  },

  /**
   * Event launched when press any key.
   */
  events: {
    'keydown .phx-grid-scroll': 'keyEvent',
    'keydown .ui-grid-header': 'keyEventHeader',
    "click th": "_clickListenerHeader"
  },

  keyEventHeader: function(e) {
    var ret = true;
    if (e.keyCode === 40) { // down 1 row
      this._selectAdjacentRow(e, "th", "next");
      ret = false;
    }
    if (e.keyCode === 39) { // right
      this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), 1, null, "th");
      ret = false;
    }
    if (e.keyCode === 37) { // left
      this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), -1, null, "th");
      ret = false;
    }
    if (e.keyCode === 35) { // end, moves to the last cell in the current row
      this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), -1, this.currentVue.columns.length - 1, "th");
      ret = false;
    }
    if (e.keyCode === 36) { // home, moves to the first cell in the current row
      this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), 1, -1, "th");
      ret = false;
    }
    if (e.keyCode === 38) { // up. This is just to avoid the movement of the screen
      ret = false;
    }
    if (e.keyCode === 33 || e.keyCode === 34) { // up 1 page
      ret = false;
    }
    if (e.keyCode === 13 || e.keyCode === 32) { // enter or space
      $(e.target).click();
      ret = false;
    }
    //return false to avoid the movement of the window when we press an arrow key
    return ret;
  },

  /**
   * Manage the keypress events on the grid.
   */
  keyEvent: function(e) {
    var ret = true;
    var silent = false;

    if (this.model.get('mode') !== "E") {
      e.stopImmediatePropagation();
      if (STR.isBlank(this.rowsPerPage)) {
        this._calculateRowsPerPage();
      }

      if (e.keyCode === 38) { // up 1 row
        //Accessibility
        this._selectAdjacentRow(e, "td", "prev");

        this.model.selectPreviousRow();
        this._scrollIntoView();
        ret = false;
      }
      if (e.keyCode === 33) { // up 1 page
        if (!$(e.target).parents("tr:first").siblings().andSelf().first().hasClass("phx-selected") ||
          !(this.$el.find(".phx-grid-scroll-extra1").height() === 0)) {
          silent = $(e.target).parents("tr:first").is(".phx-selected") === true ? false : true;
          this.model.selectFirstRow(silent);
          this._scrollIntoView();
        }

        ret = false;

        //Accessibility
        this._selectAdjacentRow(e, "td", "first");
      }
      if (e.keyCode === 40) { // down 1 row
        this._selectAdjacentRow(e, "td", "next");
        this.model.selectNextRow();
        this._scrollIntoView();
        ret = false;
      }
      if (e.keyCode === 34) { // down 1 page
        if (!$(e.target).parents("tr:first").siblings().andSelf().last().hasClass("phx-selected")) {
          silent = $(e.target).parents("tr:first").is(".phx-selected") === true ? false : true;
          this.model.selectLastRow(silent);
          this._scrollIntoView();
        }
        ret = false;

        //Accessibility
        this._selectAdjacentRow(e, "td", "last");
      }

      //Accessibility
      if (e.keyCode === 39) { // right
        this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), 1, null, "td");
        ret = false;
      }
      if (e.keyCode === 37) { // left
        this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), -1, null, "td");
        ret = false;
      }
      if (e.keyCode === 35) { // end, moves to the last cell in the current row
        this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), -1, this.currentVue.columns.length - 1, "td");
        ret = false;
      }
      if (e.keyCode === 36) { // home, moves to the first cell in the current row
        this._selectAdjacentCell($(e.currentTarget).find("[tabindex=0]").parents("tr:first").children(), 1, -1, "td");
        ret = false;
      }
      if (e.keyCode === 13 || e.keyCode === 113) { // enter or F2
        var cell = $(e.currentTarget).find(".grid-cell-focus");
        var tr = $(e.currentTarget).find(".phx-selected");
        this.model.coll.editModeCellSelected = tr.children().index(cell);
      }
      if (e.keyCode === 32) {
        ret = false;
      }
    }
    //return false if event was managed overwise return true
    return ret;
  },

  /**
   * return true if column name starts with "phx-", in that case is a special column.
   */
  _specialColumns: function(columnId) {
    return columnId.indexOf("phx-") === 0;
  },

  /**
   * Show/Hide the column if isn't a special column.
   */
  toggleColumn: function(columnId) {
    if (this._specialColumns(columnId)) {
      return;
    }
    if (!this.currentVue._columns[columnId]) {
      return;
    }
    this._toggleColumn(columnId);
    this.currentVue._columns[columnId].visible = !this.currentVue._columns[columnId].visible;
  },

  /**
   * Performs the Show/Hide Column action.
   */
  _toggleColumn: function(columnId) {
    var totalWidth = null;

    if (this._specialColumns(columnId)) {
      return;
    }
    if (!this.currentVue._columns[columnId]) {
      return;
    }
    if (this.minWidthMode) {
      this._resetWidthsHeadersColumns();
    }
    // Hide
    if (this.currentVue._columns[columnId].visible) {
      // Two types of 'hide' method depending browser (reason: rendering problems in Chrome browser)
      this.currentVue._columns[columnId].hide();
      /*if (!UTILS.isFirefox() && !UTILS.isIE()) {
        this.currentVue._columns[columnId].hide_in_chrome();
      } else {
        this.currentVue._columns[columnId].hide();
      }*/

      //apply accessibility visibility
      _.each(this.currentVue._columns[columnId].cells, function(cell) {
        cell.$el.attr("aria-hidden", true);
      }, this);

      if (!this.minWidthMode) {
        // Reduce the table min-width
        totalWidth = $(this.el).find(".phx-grid-scroll table").css("min-width");
        totalWidth = parseInt(totalWidth.substring(0, totalWidth.length - 2)) - this.currentVue._columns[columnId].width;
        this._setTableMinWidth(totalWidth);
      } else {
        totalWidth = $(this.el).find(".phx-grid-scroll table").css("min-width");
        totalWidth = parseInt(totalWidth.substring(0, totalWidth.length - 2)) - this.currentVue._columns[columnId].minWidth;
        this._setTableMinWidth(totalWidth);

      }
      //			$(this.el).find("table").css("min-width",totalWidth+"px");

    } else { // Show
      // Two types of 'show' method depending browser (reason: rendering problems in Chrome browser)
      this.currentVue._columns[columnId].show();
      /*if (!UTILS.isFirefox() && !UTILS.isIE()) {
        this.currentVue._columns[columnId].show_in_chrome();
      } else {
        this.currentVue._columns[columnId].show();
      }*/

      //apply accessibility visibility
      _.each(this.currentVue._columns[columnId].cells, function(cell) {
        cell.$el.attr("aria-hidden", false);
      }, this);

      if (!this.minWidthMode) {
        // Reduce the table min-width
        totalWidth = $(this.el).find(".phx-grid-scroll table").css("min-width");
        totalWidth = parseInt(totalWidth.substring(0, totalWidth.length - 2)) + this.currentVue._columns[columnId].width;
        this._setTableMinWidth(totalWidth);
        //			$(this.el).find("table").css("min-width",totalWidth+"px");
      } else {
        totalWidth = $(this.el).find(".phx-grid-scroll table").css("min-width");
        totalWidth = parseInt(totalWidth.substring(0, totalWidth.length - 2)) + this.currentVue._columns[columnId].minWidth;
        this._setTableMinWidth(totalWidth);
      }
    }

  },

  _resetWidthsHeadersColumns: function() {
    var self = this;

    $.each(this.currentVue._columns, function(key) {
      self.currentVue._columns[key].header.$el.width("100%");
      self.currentVue._columns[key].header.$el.find("> .ui-resizable").width("100%").css("min-width", self.currentVue._columns[key].minWidth);
      self.currentVue._columns[key].headerFake.$el.width("100%").css("min-width", self.currentVue._columns[key].minWidth);
    });
  },

  /**
   * If the column is visible when lock it, perform a toggle to hide it.
   */
  _lockColumn: function(columnId) {
    if (this.currentVue._columns[columnId] && this.currentVue._columns[columnId].visible) {
      this.model.trigger("toggle:column", columnId);
    }
  },

  /**
   * If the column is not visible when unlock it, perform a toggle to show it.
   */

  _unlockColumn: function(columnId) {
    if (this.currentVue._columns[columnId] && !this.currentVue._columns[columnId].visible) {
      this.model.trigger("toggle:column", columnId);
    }
  },

  /**
   * Scroll the grid to the row of the selected index.
   */
  _scrollToIndex: function(index, callback) {
    var _this = this;
    var $scrollDiv = $(".phx-grid-scroll", this.el);
    var counter = 0;
    var interval = setInterval(function() {
      counter++;
      if ($scrollDiv.is(":visible") || counter > 5) {
        var heightReal = $scrollDiv.find("table tbody").height();
        var sizePage = _this.model.coll.length;
        var minHeight = 15;
        var rowHeightTree = (sizePage !== 0) ? (heightReal / sizePage) : _this.rowHeight;

        if (rowHeightTree < minHeight && rowHeightTree !== _this.rowHeight) {
          rowHeightTree = _this.rowHeight;
        }
        if (rowHeightTree !== _this.rowHeight) {
          var totalRecords = _this.model.coll.totalRecords;
          var startIndex = _this.model.coll.pagination.startIndex;
          var size = _this.model.coll.pagination.size;
          var endIndex = Math.min(startIndex + size, totalRecords);
          var extraHeight = (totalRecords - endIndex) * rowHeightTree;
          var preHeight = (startIndex) * rowHeightTree;

          //il faut modifier extra1 et ou extra2
          $(".phx-grid-scroll-extra2", _this.el).height(extraHeight);
          $(".phx-grid-scroll-extra1", _this.el).height(preHeight);
          _this.rowHeight = rowHeightTree; //la valeur nouvelle de rowHeight
        }
        $scrollDiv.scrollTop(index * rowHeightTree);
        clearInterval(interval);
        if (typeof callback === "function") {
          callback();
        }
      }
    }, 300);
  },

  /**
   * Scroll the grid to the row of the selected index if is not visible.
   */
  _scrollToIndexIfHidden: function(index, increment) {
    var l_index = index + increment; // recalculate index
    var rowTopOffset = !STR.isBlank(this.$el.find("tr.ui-grid-row").eq(l_index).offset()) ? this.$el.find("tr.ui-grid-row").eq(l_index).offset().top : null;
    var tableTopOffset = this.$el.find(".phx-grid-scroll").offset().top;
    var tableHeight = this.$el.find(".phx-grid-scroll").height();
    var rowIndex = !STR.isBlank(this.$el.find("tr.ui-grid-row").eq(l_index)[0]) ? this.$el.find("tr.ui-grid-row").eq(l_index)[0].rowIndex : null;
    var extraHeight = this.$el.find(".phx-grid-scroll-extra1").height();
    var rowNumber = Math.round((extraHeight) / this.rowHeight) + rowIndex;

    if (increment < 0 && rowTopOffset < tableTopOffset) {
      $(".phx-grid-scroll", this.el).scrollTop((rowNumber - 1) * this.rowHeight);
    } else if (increment > 0 && (rowTopOffset + this.rowHeight) > (tableHeight + tableTopOffset)) {
      var currentScroll = $(".phx-grid-scroll", this.el).scrollTop();

      $(".phx-grid-scroll", this.el).scrollTop(currentScroll + this.rowHeight);
    }
  },

  /**
   * Calculate the rows per page for the current grid,
   * using the row Height and the grid Height set
   */
  _calculateRowsPerPage: function() {
    if (STR.isBlank(this.rowHeight) || _.isNull(this.rowHeight) || this.rowHeight <= 0) {
      var $rows = $('tbody tr', this.el);

      this.rowHeight = $rows.is(':visible') ? $rows.outerHeight() : $rows.getHiddenDimensions().outerHeight;
      this.gridHeight = 0;
      if ($rows && $rows.length) {
        for (var i = 0; i < $rows.length && i < this.maxVisibleRows; i++) {
          this.gridHeight += $($rows[i]).outerHeight();
        }
      }
    }
    /**
     * The rows per page quantity
     */
    this.rowsPerPage = this.DEFAULT_ROWS_PER_PAGE;
    if (this.rowHeight > 0) {
      this.rowsPerPage = parseInt(this.height / this.rowHeight, 10);
    }
  },

  /**
   * Prints the row index with the total on the footer of the table
   */
  _showSelectedRowIndex: function() {
    var totalRecords = this.model.coll.totalRecords;
    var row = $(".phx-grid-scroll tr > .ui-state-active", this.el);
    var rowNumber = null;

    if (this.model.coll.paginated === false) {
      totalRecords = this.model.coll.length;
    }
    rowNumber = totalRecords;
    // if selection
    if (row.length > 0 && this.rowHeight > 0) {
      var rowIndex = row.parent()[0].rowIndex;
      var extraHeight = this.$el.find(".phx-grid-scroll-extra1").height();

      rowNumber = Math.round((extraHeight) / this.rowHeight) + rowIndex;
    }
    $(".phx-grid-footer-right", this.el).html(rowNumber + "/" + totalRecords);
    //Manage the visibility of the rows
    if (this.model.coll.length === 0) {
      this.$el.find(".ui-grid-table").hide();
    } else {
      this.$el.find(".ui-grid-table").show();
    }
  },

  /**
   * Show/Hide table footer.
   */
  setTotalRowsVisible: function(visible) {
    if (!STR.isBlank(visible)) {
      if (visible === true) {
        $(".phx-grid-footer-right", this.el).show();
      } else {
        $(".phx-grid-footer-right", this.el).hide();
      }
    }
  },

  /**
   * Function that renders the header of the grid.
   */
  _renderHead: function() {
    var self = this;
    var totalWidth = 0;
    var l_cont = 0;
    var colWidth = this.DEFAULT_COL_WIDTH;
    var minWidth = this.DEFAULT_COL_WIDTH;

    // Iterates over the 2 headers the visible and the fake one
    $(this.el).find("thead").each(function(index, thead) {
      //var row = $(self.make("tr"));
      var row = $("<tr></tr>");
      $(thead).append(row);
      if (index === 0) {
        // Create a th for every header and store it
        _.each(self.currentVue.columns, function(column, cellIndex, columns) {
          var lastHeader = (cellIndex === (columns.length - 1)),
            options = {
              hIndex: index,
              cellIndex: cellIndex,
              text: column.title,
              last: lastHeader,
              dataGrid: self,
              columnCode: column.property,
              multiselection: (column.property === "phx-multiselect")
            };
          if (self.minWidthMode) {
            _.extend(options, { "minWidth": column.minWidth, "minWidthMode": true });
          }

          var thCell = new DataGridCellThView(options);
          row.append(thCell.render().el);

          /*  @property {Boolean} wordSpacing - Adjustment of header's text of table.The text will wrap when necessary*/

          // Configure widths and remove border right of the last header column
          if (!lastHeader) {

            colWidth = (column.width) ? column.width : self.DEFAULT_COL_WIDTH;
            $(thCell.el).css("width", colWidth);

            if (!STR.isBlank(column.wordSpacing)) {
              var colwidthspacing = 5;
              $(thCell.el).find(".title").css("white-space", "normal");
              $(thCell.el).find(".title").css("word-spacing", colwidthspacing + "px");
              $(thCell.el).find(".title").css("text-align", "center");
            }
          } else {
            //						this.columnsChooserView = new ColumnsChooserView({dataGrid:this});
            //						row.append(this.columnsChooserView.render().el);
          }

          if (self.minWidthMode) {
            minWidth = (column.minWidth) ? column.minWidth : self.DEFAULT_COL_WIDTH;
            $(thCell.el).css("min-width", minWidth);
            $(thCell.el).css("width", colWidth);
          }

          // Stores the headers
          self.currentVue._columns[column.property].header = thCell;
          self.listenTo(thCell, "click", self._manageSorting);

          if (!self.minWidthMode) {
            if (column.width) {
              totalWidth += column.width;
            } else {
              totalWidth += self.DEFAULT_MIN_COL_WIDTH;
            }
          } else {
            if (column.minWidth) {
              totalWidth += column.minWidth;
            } else {
              totalWidth += self.DEFAULT_MIN_COL_WIDTH;
            }
          }
        });
      } else {
        // Create a th for every header and store it
        _.each(self.currentVue.columns, function(column, cellIndex, columns) {
          var lastHeader = (cellIndex === (columns.length - 1));
          var thCell = new DataGridCellThFakeView({ hIndex: index, cellIndex: cellIndex, last: lastHeader });
          row.append(thCell.render().el);
          // Configure widths
          if (!lastHeader) {
            colWidth = (column.width) ? column.width : self.DEFAULT_COL_WIDTH;
            $(thCell.el).css("width", colWidth);
          }

          if (self.minWidthMode) {
            minWidth = (column.minWidth) ? column.minWidth : self.DEFAULT_COL_WIDTH;
            $(thCell.el).css("min-width", minWidth);
          }

          // Stores the fake headers
          self.currentVue._columns[column.property].headerFake = thCell;
        });
      }
    });

    // set min-width
    //		$(this.el).find("table").css("min-width",totalWidth+"px");
    this._setTableMinWidth(totalWidth);

    var header = null;
    var headerFake = null;

    // Configure resizable columns
    _.each(this.currentVue.columns, function(column, cellIndex, columns) {
      if (cellIndex < columns.length - 1) {
        header = self.currentVue._columns[column.property].header;
        headerFake = self.currentVue._columns[column.property].headerFake;
        if (!STR.isBlank(header)) {
          header.resizable(headerFake);
          headerFake.resizable(header);
        }
      }
    });

    if (!STR.isBlank(header)) {
      // set title
      this._renderTitle();
      //Le nombre de colonnes peut inclure deux de contrôle "phx-action" et "phx-last" que nous ne devons pas raconter
      //pour "show/hide" le menu de colonnes
      l_cont = 0;
      if (this.currentVue.columns.length > 0) {
        _.each(this.currentVue.columns, function(item) {
          if (item && item.property !== "phx-action" && item.property !== "phx-last") {
            l_cont++;
          }
        });
      }
      //Update columnsChooser for this view
      if (this.currentVue.columns.length === 1 || l_cont === 1) {
        this.columnsChooser = false;
      } else if (STR.isBlank(this.forceHideChooserMenu) || this.forceHideChooserMenu === false) {
        this.columnsChooser = true;
      }
      // render column chooser
      if (Object.keys(this.vues).length > 1) {
        if (!(this.columnsChooser === false)) {
          //Only initialize columnschooserview, the first time the vue renders
          if (STR.isBlank(this.columnsChooserView)) {
            this.columnsChooserView = new MainChooserView({ dataGrid: this, el: $(".phx-grid-main-chooser", this.el) });
          }
          this.columnsChooserView.resetVisibility();
          this.columnsChooserView.render();
        }
      } else {
        if (!(this.columnsChooser === false)) {
          if (this.columnsChooserOverridedView) {
            this.columnsChooserView = new this.columnsChooserOverridedView({ dataGrid: this, el: $(".phx-grid-main-chooser", this.el) });
            this.columnsChooserView.render();
          } else {
            this.columnsChooserView = new ColumnsChooserView({ dataGrid: this, el: $(".phx-grid-main-chooser", this.el) });
            this.columnsChooserView.render();
          }

        }
      }

      // make special two columns not resizeable
      this.currentVue._columns["phx-last"].hideResizeable();
      if (this.currentVue._columns["phx-action"]) {
        this.currentVue._columns["phx-action"].hideResizeable();
      }
    }

  },

  /**
   * Clean the header of the grid and renders it.
   */
  reRenderHead: function() {
    var thead = $(this.el).find("thead");
    if (thead.length > 0) {
      thead.empty();
    }
    this._renderHead();
  },

  /**
   * Render the button bar to change vue selected
   */
  _renderVuesButtonBar: function() {
    var self = this;
    if (this.showButtonsMultipleVues) {
      _.each(_.keys(this.vues), function(key) {
        if (!STR.isBlank(self.vues[key].buttonText)) {
          self.vuesButtonBar.addButton(self.vues[key].buttonText, key, false, null);
          self.vuesButtonBar.setButtonVisibleInModeC(key, true);
        } else {
          throw new Error("buttonText must be defined in vues when showButtonsMultipleVues is true");
        }
      });
      $(".phx-grid-title-bar-action", this.el).prepend(this.vuesButtonBar.render().el);
      this.vuesButtonBar.$el.css("display", "inline-block");
      if (this.buttonBar) {
        this.buttonBar.$el.css("display", "inline-block");
      }
      //Hide buttons
      this.model.vuesBtnBarModel.trigger("hab.hide:new");
      this.model.vuesBtnBarModel.trigger("hab.hide:copy");
      this.model.vuesBtnBarModel.trigger("hab.hide:delete");
      this.model.vuesBtnBarModel.trigger("hab.hide:save");
      this.model.vuesBtnBarModel.trigger("hab.hide:revert");
      _.each(_.keys(this.vues), function(key) {
        if (self.currentVue === self.vues[key]) {
          self.model.vuesBtnBarModel.trigger("hab.hide:" + key);
        }
      });

      //Liste to
      this.model.vuesBtnBarModel.off("btn:click");
      this.model.vuesBtnBarModel.on("btn:click", this._vueButtonBarAction, this);
    }
  },

  _toggleVuesButtons: function() {
    var self = this;

    if (this.showButtonsMultipleVues) {
      _.each(_.keys(this.vues), function(key) {
        if (self.currentVue === self.vues[key]) {
          self.model.vuesBtnBarModel.trigger("hab.hide:" + key);
        } else {
          self.model.vuesBtnBarModel.trigger("hab.show:" + key);
          self.model.vuesBtnBarModel.trigger("show:" + key);
        }
      });
    }
  },

  _toggleVuesLevier: function() {
    if (this.showLevierMultipleViews) {
      $(".vuesLevierSelected", this.$el).text(i18n.t('common:grid.vue').toLowerCase() + " " + this.currentVue.vueName.toLowerCase());
    }
  },

  _vueButtonBarAction: function(buttonId) {
    this.model.trigger("buttonVueChanged:click", buttonId);
  },

  /**
   *
   * Manages change of view launched from menu
   */
  _manageVueChange: function(vueName) {
    var self = this;

    //When vue has changed
    if (this.currentVue !== this.vues[vueName]) {
      this.currentVue = this.vues[vueName];
      this.reRenderHead();
      this._toggleVuesButtons();
      this._toggleVuesLevier();
      //Hide header for columns that are not visible
      _.each(this.currentVue._columns, function(column) {
        if (column.visible === false) {
          column.hideHeader();
        }
      });
      this.model.trigger("vue:change", vueName);
      if (this.currentVue.beforeFetchCallback) {
        this.currentVue.beforeFetchCallback(function() { self._manageVueChangeCallback() });
      } else {
        this._manageVueChangeCallback();
      }
    }
  },

  refreshVueColumns: function(vueName, tblColumns) {
    var _self = this;

    // Remove tables with empty titles
    this.vues[vueName].columns = _.filter(tblColumns, function(item) {
      return item.title !== "" || _self._specialColumns(item.property);
    });

    if (vueName === this.currentVue.vueName) {
      this.reRenderHead();
      this._toggleVuesButtons();
      this._toggleVuesLevier();
    }

    //Hide header for columns that are not visible
    _.each(this.vues[vueName]._columns, function(column, key) {
      var columnDefinition = _.find(_self.vues[vueName].columns, function(item) {
        return item.property === key;
      });
      if (!columnDefinition && !_self._specialColumns(key)) {
        _self.vues[vueName]._columns[key].remove();
        delete _self.vues[vueName]._columns[key];
      } else if (column.visible === false) {
        column.hideHeader();
      }
    });
    this.positionHeader();
  },

  _manageVueChangeCallback: function() {
    var self = this;

    this.model.coll.fetch({
      success: function() {
        if (!STR.isBlank(self.model.get("value"))) {
          self.model.selectRow(self.model.get("value"));
        }
      }
    });
  },

  /**
   * Remove sorting arrow from all columns and
   * clear sorting params from collection
   */
  clearSorting: function() {
    var self = this;
    var coll = this.model.coll;
    var keys = _.keys(this.currentVue._columns);

    if (!STR.isBlank(coll.sortings)) {
      coll.sortings = {};
    }
    _.each(keys, function(key) {
      self.currentVue._columns[key].header.hideSortButton();
      self.currentVue._columns[key].header.$el.find(".title").removeClass("phx-state-sort");
      self.currentVue._columns[key].sort = null;
    });
  },

  /**
   * Manage the Sortig when click on a Column header
   * ordering by the columns and refreshing the table
   */
  _manageSorting: function(columnCode, callback, silent, collection) {
    var self = this;

    if (this.currentVue._columns[columnCode].sortable) {
      var coll = this.model.coll;

      if (!STR.isBlank(collection)) {
        coll = collection;
      }
      this.currentVue._columns[columnCode].header.$el.find(".title").addClass("phx-state-sort");

      var keys = _.difference(_.keys(this.currentVue._columns), [columnCode]);
      _.each(keys, function(key) {
        self.currentVue._columns[key].header.hideSortButton();
        self.currentVue._columns[key].header.$el.find(".title").removeClass("phx-state-sort");
      });
      if (!_.isNull(this.currentVue._columns[columnCode].sort)) {
        coll.sortings = {};
        //Look if exist customized column codes for sort / support arrays of sortable columns
        if (!STR.isBlank(this.currentVue._sortableCustomNames[columnCode])) {
          var singular = !_.isArray(this.currentVue._sortableCustomNames[columnCode]);
          var sortableColumnCode = singular ? [this.currentVue._sortableCustomNames[columnCode]] : _.clone(this.currentVue._sortableCustomNames[columnCode]);

          for (var i = 0, l = sortableColumnCode.length; i < l; i++) {
            coll.sortings[sortableColumnCode[i]] = this.currentVue._columns[columnCode].sort;
          }
        } else {
          coll.sortings[columnCode] = this.currentVue._columns[columnCode].sort;
        }

        coll.fetch({
          success: function(result) {
            if (result.models.length > 0 && silent !== true && self.selectInSorting === true) {
              self.model.selectRow(result.models[0]);
            }
            if (callback) {
              callback(result);
            }
          },
          silent: silent
        });
      }
    }
  },

  _fixScrollPosition: function() {
    $(".phx-grid-scroll", this.el).scrollTop(this._lastScrollPosition);
    LOG.debug("called _fixScrollPosition after view becomes visible to fix the positionof the scroll - IE bug");
  },

  /**
   * Scroll Handler used by the views taht extend this base view.
   * Calculate if pagination is needded.
   */
  _scrollControl: function() {
    var self = this;
    var newStartIndex = null;
    var fistCellPosition = this.$el.find(".phx-grid-scroll-extra1").offset().top + this.$el.find(".phx-grid-scroll-extra1").height() + (this.rowHeight / 3); // Adjusted with 1/3 of the first cell just in case
    var lastCellPosition = this.$el.find(".phx-grid-scroll-extra2").offset().top - (this.rowHeight / 3); // Adjusted with 1/3 of the last cell just in case
    var scrollStartPosition = this.$el.find(".phx-grid-scroll").offset().top;
    var scrollEndPosition = this.$el.find(".phx-grid-scroll").offset().top + this.$el.find(".phx-grid-scroll").height();

    if (this.model.coll.length === this.model.coll.totalRecords ||
      this.model.coll.totalRecords <= this.model.coll.pagination.size) {
      // No pagination is needed. This is done so to support not paginated collections
      return;
    }
    var scrollPosition = $(".phx-grid-scroll", this.el).scrollTop();
    if (this._lastScrollPosition !== scrollPosition) {
      var rowsBefore = Math.round(this.model.coll.pagination.size / 6);
      var rowsAfter = rowsBefore;
      var rowsShowed = Math.round(this.$el.find(".phx-grid-scroll").height() / this.rowHeight);
      var gap = this.model.coll.pagination.size - rowsShowed - rowsAfter;
      var scrollDown = true;

      if (scrollPosition < this._lastScrollPosition) {
        scrollDown = false;
      }

      this._lastScrollPosition = scrollPosition;
      var rowNumber = Math.round(scrollPosition / this.rowHeight);
      this.rowNumber = rowNumber;
      var startIndex = this.model.coll.pagination.startIndex;
      // scroll up
      if (scrollDown && lastCellPosition <= scrollEndPosition) {

        if (startIndex === this.model.coll.totalRecords) {
          //Avoid indexOutOfBounds
          return;
        }
        newStartIndex = rowNumber - rowsBefore;
        this.lastPage = ((startIndex + this.model.coll.pagination.size) >= this.model.coll.totalRecords);
        if (!this.lastPage) {

          if (Math.abs(newStartIndex - startIndex) >= gap - 1) {
            newStartIndex = Math.min(newStartIndex, this.model.coll.totalRecords);
            this.isPaginating = true;
            this.model.coll.goTo(newStartIndex, function() {
              self.isPaginating = false;
              var value = self.model.get("value");
              var newValue = self.model.coll.get(value);
              if (!STR.isBlank(newValue)) {
                $(".phx-grid-scroll", self.el).focus();
                self.model._manageRowSelection(newValue);
              }
            });
          }
        }
        // scroll down
      } else if (!scrollDown && fistCellPosition > scrollStartPosition) {
        if (startIndex === 0) {
          //Avoid indexOutOfBounds
          return;
        }

        newStartIndex = rowNumber - gap;
        this.firstPage = (startIndex === 0);
        //if(!this.firstPage){}
        LOG.debug("startIndex: " + startIndex + " -> newStartIndex: " + newStartIndex);

        if (Math.abs(newStartIndex - startIndex) >= gap - 1) {
          this.isPaginating = true;
          newStartIndex = Math.max(newStartIndex, 0);
          this.model.coll.goTo(newStartIndex, function() {
            self.isPaginating = false;
            var value = self.model.get("value");
            var newValue = self.model.coll.get(value);
            if (!STR.isBlank(newValue)) {
              $(".phx-grid-scroll", self.el).focus();
              self.model._manageRowSelection(newValue);
            }
          });
        }
        //}/*!this.firstPage*/
      } else if ((((fistCellPosition < scrollStartPosition) && (lastCellPosition <= scrollStartPosition)) || ((fistCellPosition >= scrollEndPosition) && (lastCellPosition > scrollStartPosition))) && this.isPaginating === true) {
        // scroll over the table

        /*// variables for LOG
        var a = (fistCellPosition < scrollStartPosition) && (lastCellPosition  <= scrollStartPosition) ;
        var b = (fistCellPosition >= scrollEndPosition) && (lastCellPosition  > scrollStartPosition) ;
        var c = this.isPaginating === true;
        LOG.debug("a: "+a+",b: "+b+",c: "+c+" row: "+rowNumber);
        // end LOG*/

        if (Math.abs(rowNumber - startIndex) >= gap - 1) {
          //LOG.debug("%%%%%> goto: "+rowNumber);
          this.model.coll.goTo(rowNumber, function() {
            var value = self.model.get("value");
            var newValue = self.model.coll.get(value);

            if (!STR.isBlank(newValue)) {
              $(".phx-grid-scroll", self.el).focus();
              self.model._manageRowSelection(newValue);
            }
          });
        }
      } // end scroll over the table
    }
  },

  /**
   * Set the text of a column
   *
   */
  setColumnTitle: function(property, title) {
    var thCell = this.currentVue._columns[property].header;

    if (thCell) {
      thCell.setHeaderTitle(title);
    }
    if (this.columnsChooserView) {
      this.columnsChooserView.setMenuItemTitle(property, title);
    }
  },

  /**
   * Sets the minimum width of the grid
   */
  _setTableMinWidth: function(totalWidth) {
    var extraspace = 18;

    if (this.minWidthMode) {
      $(this.el).find(".phx-grid-header-scroll table").css({ "min-width": (totalWidth) + "px", "table-layout": "auto" });
      $(this.el).find(".phx-grid-scroll table.ui-grid-table").css({ "min-width": totalWidth + "px", "table-layout": "auto" });
      $(this.el).find(".phx-grid-scroll .phx-grid-no-title-message").css({ "min-width": totalWidth + "px", "table-layout": "auto" });
    } else {
      $(this.el).find(".phx-grid-header-scroll table").css("min-width", (totalWidth + extraspace) + "px");
      $(this.el).find(".phx-grid-scroll table.ui-grid-table").css("min-width", totalWidth + "px");
      $(this.el).find(".phx-grid-scroll .phx-grid-no-title-message").css("min-width", totalWidth + "px");
    }
  },

  /**
   * Renders the rows of the current grid.
   */
  _paintRows: function() {
    var tbody = $(this.el).find("tbody.ui-grid-body");
    var self = this;
    var scrollTop = null;
    var totalRecords = null;
    var startIndex = null;
    var size = null;
    var endIndex = null;
    var extraHeight = null;
    var preHeight = null;

    if (tbody.length === 0) {
      // Avoid an initial call during Workflow setUp before the page is rendered
      return;
    }
    scrollTop = $(".phx-grid-scroll", this.el).scrollTop();
    if (this.rows) {
      for (var r = 0; r < this.rows.length; r++) {
        this.rows[r].remove();
        this.rows[r] = null;
      }
      this.rows.length = 0;
    } else {
      this.rows = [];
    }
    tbody.empty();
    _.each(this.currentVue._columns, function(column) {
      if (column && column.cells) {
        for (var c = 0; c < column.cells.length; c++) {
          column.cells[c].remove();
          column.cells[c] = null;
        }
        column.cells = [];
      }
    });
    for (var i = 0; i < this.model.coll.length; i++) {
      var rModel = this.model.coll.at(i);
      var rowView = new DataGridRowView({ model: rModel, dataGrid: this });
      var row = rowView.render().el;

      if (!this.removeGridStyle) {
        $(row).addClass("ui-grid-row");
        if (i % 2 === 1) {
          $(row).addClass("ui-grid-row-alt");
        }
      }
      // select the first cell of the first row when the table is at detail section and has not select row by default.
      if (i === 0 && !STR.isBlank($(row).children()[0]) && STR.isBlank(this.model.coll.editModeCellSelected)) {
        if ($(row).children().eq(0).find(":input").is(":input")) {
          $(row).children().eq(0).find(":input").attr({ "tabindex": "0" }).addClass("grid-cell-focus");
        } else {
          $(row).children().eq(0).attr({ "tabindex": "0" }).addClass("grid-cell-focus");
        }
      }
      tbody.append(row);
      this.rows.push(rowView);
    }
    // Toggle control
    // Hide the columns that must be hidden by default.
    if (this.currentVue._visibleColumns.length > 0) {
      _.each(_.difference(_.keys(this.currentVue._columns), this.currentVue._visibleColumns), function(key) {
        if (key.indexOf("phx-") !== 0) {
          self.model.trigger("toggle:column", key);
        }
      });
      this.currentVue._visibleColumns = [];
    }
    // Hide the columns that must be hidden by default.
    if (this.currentVue._lockedColumns.length > 0) {
      _.each(this.currentVue._lockedColumns, function(key) {
        self.model.trigger("lock:column", key);
      });
      this.currentVue._lockedColumns = [];
    }
    // Adjust scroll
    this.showTable(true); //(1) C'est important pour le calcul de "rowHeight". Ne pas le toucher ou le mettre dans un "if"
    if (this.model.coll.length > 0 && (!this.rowHeight || this.rowHeight === 0)) {
      this._calculateRowsPerPage();
    }
    if (this.model.coll.length === 0) { //(2)Ne pas toucher.
      this.showTable(false);
    }
    totalRecords = this.model.coll.totalRecords;
    startIndex = this.model.coll.pagination.startIndex;
    size = this.model.coll.pagination.size;
    endIndex = Math.min(startIndex + size, totalRecords);
    if (this.model.coll.paginated === false) {
      totalRecords = this.model.coll.length;
      endIndex = totalRecords;
      startIndex = 0;
    }
    extraHeight = (totalRecords - endIndex) * this.rowHeight;
    $(".phx-grid-scroll-extra2", this.el).height(extraHeight);
    preHeight = (startIndex) * this.rowHeight;
    $(".phx-grid-scroll-extra1", this.el).height(preHeight);
    $(".phx-grid-scroll", this.el).scrollTop(scrollTop);
    $(".phx-grid-footer-right", this.el).html(endIndex + "/" + totalRecords);
    if (totalRecords === 0 &&
      (!this.overrideFilteredRowsInTitle || (this.overrideFilteredRowsInTitle && this.overrideFilteredRowsInTitle(totalRecords) === ""))) {
      this.setCustomMessageTable();
    } else {
      //Unavailable message for tab focus
      this.$el.find(".phx-grid-no-title-message").hide();
      this.$el.find(".phx-grid-no-title-message").find("span").attr("tabindex", -1);
    }
    this._renderFilteredRowsInTitle();
    if (this.multiselection) {
      this.currentVue._columns["phx-multiselect"].header._manageChecking();
      this._manageMultiSelection();
    }
    // dynamic resize heigth of grid
    this._resizeGrid();
  },

  /**
   * Set an aux Message to the table.
   */
  setCustomMessageTable: function(title) {
    var messageDiv = this.$el.find(".phx-grid-no-title-message");
    var divHelper = $("<div>").addClass("phx-grid-no-title-message-helper").css({
      "text-align": "center",
      "display": "inline-block",
      "vertical-align": "middle",
      "height": "100%"
    });

    if (!STR.isBlank(title)) {
      messageDiv.html(title);
    } else {
      var emptyMessage = !STR.isBlank(this.emptyMessage) ? this.emptyMessage : i18n.t('common:grid_no_item_title');
      var span = $("<span>").html(emptyMessage).attr({ "tabindex": "0" });

      //Unavailable message for tab focus
      messageDiv.html(span);
    }
    messageDiv.css({
      "text-align": "center",
      "display": "inline-block",
      "vertical-align": "middle",
      "height": "100%",
      "width": "100%"
    }).append(divHelper).show();
  },

  /**
   * Show/Hide Custom Message
   */
  manageCustomMessageVisibility: function(isVisible) {
    if (isVisible === true) {
      this.$el.find(".phx-grid-no-title-message").show();
    } else {
      this.$el.find(".phx-grid-no-title-message").hide();
    }
  },

  /**
   * Dynamically resize the height of the grid as needed
   */
  _resizeGrid: function() {
    var availableHeight = null;
    var minHeight = 57;
    var l_tmp = 0;

    if (this.maxVisibleRows && this.rowHeight && this.rowHeight > 0) {
      availableHeight = this.rowHeight * this.maxVisibleRows;
    }

    if (this.minVisibleRows && this.rowHeight && this.rowHeight > 0) {
      minHeight = (this.rowHeight * this.minVisibleRows) + 3;
    }

    // auto ajustable grid
    if (this.gridHeightType === "auto") {
      var topOffset = $(this.el).offset().top;
      var height = $(this.el).height();
      var scrollHeight = $(".phx-grid-scroll", this.el).height();

      var windowsHeight = 0;
      /*if (this.parentContainer) {
        windowsHeight = $(this.parentContainer.el).height();
        topOffset = topOffset - $(this.parentContainer.el).offset().top;
      } else {
        windowsHeight = $("#phx-wrap").height();
      }*/
      if (this.parentContainer) {
        windowsHeight = $(this.parentContainer.el).height();
        topOffset = topOffset - $(this.parentContainer.el).offset().top;
      } else {
        if (this.$el.parents(".phx-grid-container").length > 0) {
          windowsHeight = this.$el.parents(".phx-grid-container").height();
          topOffset = topOffset - this.$el.parents(".phx-grid-container").offset().top + 10;
        } else {
          windowsHeight = $("#phx-wrap").height();
        }
      }

      //Adjustement for tables under filter
      var filterHeigth = 0;
      if (this.$el.parents("div[class*='phx-panelA']").length > 0) {
        filterHeigth = 1;
      }
      l_tmp = height - scrollHeight;
      if (l_tmp < 0) {
        l_tmp = 0;
      }
      availableHeight = Math.max(windowsHeight - topOffset - l_tmp, minHeight) - filterHeigth - 5;

      if (this.maxVisibleRows && this.rowHeight && this.rowHeight > 0) {
        if (this.model.coll.length > 0) {
          availableHeight = Math.min(this.rowHeight * this.model.coll.length, this.rowHeight * this.maxVisibleRows);
        } else {
          availableHeight = Math.min(availableHeight, this.rowHeight * this.maxVisibleRows);
        }
      } else if (this.model.coll.length === 0 && !this.rowHeight) {
        //If rowHeight has not value because the collection is empty set the minHeight
        availableHeight = minHeight;
      }
      availableHeight = Math.max(availableHeight + 2, minHeight);
    }

    // grid with infinite height and minimum visible rows
    if (this.gridHeightType === "nolimite" && this.model.coll.paginated === false) {
      if (this.model.coll.length >= this.minVisibleRows) {
        availableHeight = "auto";
      } else {
        availableHeight = minHeight;
      }
    }

    if (this.gridHeightType === "nolimite" && this.useMaxVisibleRows === true && this.gridHeight > minHeight) {
      availableHeight = this.gridHeight;
    }

    if (availableHeight) {
      if ($(".phx-grid-scroll", this.el).height() !== availableHeight) {
        $(".phx-grid-scroll", this.el).height(availableHeight);
      }
    }
  },

  /**
   * Scroll the table to make the selected item in the table visible
   *
   * The selected item must be marked with class phx-selected
   * The div containing the table must be marked with class phx-grid-scroll
   */
  _scrollIntoView: function() {
    var element = this.$el.find("tbody .phx-selected");
    if (!STR.isBlank(element[0])) {
      // gets the scrollable container containing the selected row
      var parent = $(element).parents(".phx-grid-scroll");
      // current position of the scroll
      var scrollTop = $(parent).scrollTop();
      // table height
      var tableHeight = $(parent).height();
      // offset of the selected row with respect to the top of the table
      var elementOffset = scrollTop + $(element).offset().top - $(parent).offset().top;

      // check if selected row is out of view
      if (elementOffset < scrollTop) {
        // to maintain selected row on top of the table
        $(parent).scrollTop(elementOffset);
      } else if (elementOffset > (scrollTop + tableHeight - element.height())) {
        // to maintain selected row on bottom of the table
        $(parent).scrollTop(elementOffset + element.height() - tableHeight);
      }
    }
  },

  /**
   * Manages the multiSelection for the grid.
   */
  _manageMultiSelection: function() {
    if (this.model.multiselectColl.length > 0) {
      $(".phx-grid-footer-left", this.el).html(i18n.t('common:selectedRows') + this.model.multiselectColl.length);
    } else {
      $(".phx-grid-footer-left", this.el).empty();
    }
    // trigger event with the number of row checked for multiselection
    this.model.trigger("rows:checked", this.model.multiselectColl.length);
  },

  /**
   * Custom Backbone remove function that removes the View.
   */
  remove: function() {
    var self = this;

    delete this.height;
    delete this.width;
    if (this.rows) {
      for (var r = 0; r < this.rows.length; r++) {
        this.rows[r].remove();
        this.rows[r] = null;
      }
      this.rows.length = 0;
    }
    this.stopListening();
    _.each(this.vues, function(vue) {
      _.each(vue.cellRenderers, function(cellRenderer) {
        if (typeof cellRenderer === Object && cellRenderer.remove) {
          cellRenderer.remove();
        }
      });
    });
    _.each(_.keys(this.vues), function(keyVue) {
      var keys = _.keys(self.vues[keyVue].cellRenderers);
      var key = null;
      var visibleColumnsLength = null;
      var lockedColumnsLength = null;
      var columnsLength = null;

      for (key in keys) {
        if (key) {
          delete self.vues[keyVue].cellRenderers[keys[key]];
        }
      }
      delete self.vues[keyVue].cellRenderers;
      key = null;
      keys = _.keys(self.vues[keyVue]._columnsAlign);
      for (key in keys) {
        if (key) {
          delete self.vues[keyVue]._columnsAlign[keys[key]];
        }
      }
      delete self.vues[keyVue]._columnsAlign;
      _.each(self.vues[keyVue]._columns, function(column) {
        column.remove();
      });
      keys = _.keys(self.vues[keyVue]._columns);
      key = null;
      for (key in keys) {
        if (key) {
          delete self.vues[keyVue]._columns[keys[key]];
        }
      }
      delete self.vues[keyVue]._columns;
      keys = _.keys(self.vues[keyVue]._sortableCustomNames);
      key = null;
      for (key in keys) {
        if (key) {
          delete self.vues[keyVue]._sortableCustomNames[keys[key]];
        }
      }
      delete self.vues[keyVue]._sortableCustomNames;
      visibleColumnsLength = self.vues[keyVue]._visibleColumns.length;
      self.vues[keyVue]._visibleColumns.splice(0, visibleColumnsLength);
      delete self.vues[keyVue]._visibleColumns;
      lockedColumnsLength = self.vues[keyVue]._lockedColumns.length;
      self.vues[keyVue]._lockedColumns.splice(0, lockedColumnsLength);
      delete self.vues[keyVue]._lockedColumns;
      columnsLength = self.vues[keyVue].columns.length;
      self.vues[keyVue].columns.splice(0, columnsLength);
      delete self.vues[keyVue].columns;
      delete self.vues[keyVue];
    });

    delete this.currentVue;
    delete this.vues;

    delete this.template;

    delete this.multiselection;
    delete this.selectable;
    delete this.lastPage;
    delete this.firstPage;

    if (!STR.isBlank(this.columnsChooserView)) {
      this.columnsChooserView.remove();
      delete this.columnsChooserView;
    }
    if (this.model && this.model.coll) {
      this.model.coll.reset(null, { silent: true });
    }

    Backbone.View.prototype.remove.call(this);
    this.$el.empty();
  },

  /**
   * Sets the Visible columns.
   */
  setVisibleColumns: function(columnsName, vueName) {
    if (STR.isBlank(vueName)) {
      this.currentVue._visibleColumns = columnsName;
    } else {
      this.vues[vueName]._visibleColumns = columnsName;
    }
  },

  /**
   * Sets the Lockeds columns.
   */
  setLockedColumns: function(columnsName, vueName) {
    if (STR.isBlank(vueName)) {
      this.currentVue._lockedColumns = columnsName;
    } else {
      this.vues[vueName]._lockedColumns = columnsName;
    }
  },

  /**
   * Sets the Omitted columns.
   */
  setOmittedColumns: function(columnsName, vueName) {
    var key = null;
    var keys = null;
    var self = this;

    if (STR.isBlank(vueName)) {
      _.each(columnsName, function(columnName) {
        if (self.currentVue._columns[columnName]) {
          self.currentVue._columns[columnName].omitMenu = true;
        }
      });
      keys = _.keys(this.currentVue._columns);
      for (key in keys) {
        if (key.indexOf("phx-") === 0) {
          this.currentVue._columns[key].omitMenu = true;
        }
      }
    } else {
      _.each(columnsName, function(columnName) {
        if (self.vues[vueName]._columns[columnName]) {
          self.vues[vueName]._columns[columnName].omitMenu = true;
        }
      });
      keys = _.keys(this.vues[vueName]._columns);
      for (key in keys) {
        if (key.indexOf("phx-") === 0) {
          this.vues[vueName]._columns[key].omitMenu = true;
        }
      }
    }

  },

  /**
   * Set the alignment of the columns. The default aligment is left, to override this
   * behavior you must specify the correct aligment like { "columnName" : "left|right|center",...}.
   */
  setColumnsAlignment: function(columnsAlignment, vueName) {
    if (STR.isBlank(vueName)) {
      this.currentVue._columnsAlign = columnsAlignment;
    } else {
      this.vues[vueName]._columnsAlign = columnsAlignment;
    }
  },

  /**
   * Sets the Sortable columns.
   */
  setSortableColumns: function(columnsName, vueName) {
    var keys = null;
    var self = this;

    //Standard use when there is only one vue for the table
    if (STR.isBlank(vueName)) {
      // Reset values
      _.each(_.keys(this.currentVue._columns), function(columnName) {
        self.currentVue._columns[columnName].sortable = true;
      });

      _.each(_.difference(_.keys(this.currentVue._columns), columnsName), function(columnName) {
        self.currentVue._columns[columnName].sortable = false;
      });

      keys = _.keys(this.currentVue._columns);
      for (var key in keys) {
        if (key.indexOf("phx-") === 0) {
          this.currentVue._columns[key].sortable = false;
        }
      }
    } else { //Modified use for a table with many vues
      // Reset values
      _.each(_.keys(this.vues[vueName]._columns), function(columnName) {
        self.vues[vueName]._columns[columnName].sortable = true;
      });

      _.each(_.difference(_.keys(this.vues[vueName]._columns), columnsName), function(columnName) {
        self.vues[vueName]._columns[columnName].sortable = false;
      });

      keys = _.keys(this.currentVue._columns);
      for (var key2 in keys) {
        if (key2.indexOf("phx-") === 0) {
          this.vues[vueName]._columns[key2].sortable = false;
        }
      }
    }

  },

  /**
   * Customize the names of the columns when call rest for sorting
   * ex: niveau.code >> niveau => { "niveau.code" : "niveau_ordre",... }
   */
  setSortableCustomNames: function(sortableNames, vueName) {
    if (STR.isBlank(vueName)) {
      this.currentVue._sortableCustomNames = sortableNames;
    } else {
      this.vues[vueName]._sortableCustomNames = sortableNames;
    }
  },

  /**
   * Resets the row  and the grid Height
   */
  _resetRowHeight: function() {
    this.rowHeight = 0;
    this.gridHeight = 0;
  },

  /**
   * Manage the habilitations of the grid hidding the not allowed actions
   */
  applyHabilitations: function() {
    var self = this;
    if (!STR.isBlank(this.habilitations)) {
      if ((this.omitHabilitationAttributes && !HABILITATION.canView(this.habilitations)) || (!this.omitHabilitationAttributes && !HABILITATION.canCreate(this.habilitations))) {
        this.buttonBar.model.trigger("hab.hide:new");
        this.buttonBar.model.trigger("hab.hide:copy");
      }
      if ((this.omitHabilitationAttributes && !HABILITATION.canView(this.habilitations)) || (!this.omitHabilitationAttributes && !HABILITATION.canDelete(this.habilitations))) {
        this.buttonBar.model.trigger("hab.hide:delete");
      }
      if ((this.omitHabilitationAttributes && !HABILITATION.canView(this.habilitations)) || (!this.omitHabilitationAttributes && !HABILITATION.canUpdate(this.habilitations))) {
        this.habilitationUpdate = false;
        this.editable = false;
      }
      if ((this.omitHabilitationAttributes && !HABILITATION.canView(this.habilitations)) || (!this.omitHabilitationAttributes && !HABILITATION.canCreate(this.habilitations) && !HABILITATION.canUpdate(this.habilitations))) {
        this.buttonBar.model.trigger("hab.hide:save");
      }
      if (this.habModeM === true && !this.omitHabilitationAttributes && !HABILITATION.canUpdate(this.habilitations)) {
        this.buttonBar.model.trigger("hab.hide:new");
        this.buttonBar.model.trigger("hab.hide:copy");
        this.buttonBar.model.trigger("hab.hide:delete");
      }
      if (this.omitHabilitationAttributes && !HABILITATION.canView(this.habilitations) && this.buttonBar.newButtons) {
        _.each(this.buttonBar.newButtons, function(button) {
          self.buttonBar.model.trigger("hab.hide:" + button.buttonAction);
        });
      }
    }
  },

  /**
   * Set and renders the grid title.
   */
  setTitle: function(title) {
    this.title = title;
    this._renderTitle();
    this._renderFilteredRowsInTitle();
  },

  /**
   * Renders the title of the grid.
   */
  _renderTitle: function() {
    $(".phx-grid-title-bar-text", this.el).html(this.title);
  },

  /**
   * Renders the filtered rows number on the title with the total.
   */
  _renderFilteredRowsInTitle: function() {
    if (this.showFilteredRowsInTitle === true) {
      this._renderTitle();
      var totalRecords = this.model.coll.totalRecords;
      if (this.model.coll.paginated === false) {
        totalRecords = this.model.coll.length;
      }
      // this method can be defined from our usecase if we need special treatment
      if ((this.overrideFilteredRowsInTitle) && (this.overrideFilteredRowsInTitle(totalRecords) !== "")) {
        totalRecords = this.overrideFilteredRowsInTitle(totalRecords);
      }
      $(".phx-grid-title-bar-rowsNumber", this.el).html(" (" + totalRecords + ")");
    }
  },

  /**
   * Refresh the number of filtered rows in the title of the grid.
   */
  recalculateFilteredRowsInTitle: function() {
    this._renderFilteredRowsInTitle();
  },

  /**
   * Indicates if row can be saved
   */
  _allowRowUpdate: function(allowed) {
    this._isRowUpdatable = allowed;
  },

  /**
   * Gets all rows marked for selection if in multiselection, otherwise return the active row.
   */
  getSelectedRows: function() {
    var self = this;
    if (this.multiselection === true) {
      if (!STR.isBlank(this.filterSelection)) {
        this.model.multiselectColl.models = this.model.multiselectColl.where(this.filterMultiSelectColl);
      } else {
        if (this.filterSelectionFunction) {
          this.model.multiselectColl.models = _.filter(this.model.multiselectColl.models, function(model) {
            return self.filterSelectionFunction(model);
          })
        }
      }
      return this.model.multiselectColl;
    } else {
      return this.getCurrentRow();
    }
  },

  /**
   * Get active row (row selected but not marked for selection.
   */
  getCurrentRow: function() {
    return this.model.get("value");
  },

  /**
   * Clear all selected rows.
   */
  clearSelectedRows: function() {
    this.model.multiselectColl.reset(null);
    this.model.coll.forEach(function(model) {
      model.trigger("row:unchecked");
    });
    this._manageMultiSelection();

  },

  /**
   * Select all rows on the grid
   */
  selectAllRows: function() {
    var self = this;
    this.model.multiselectColl.reset(null, { silent: true });
    var clonedCollection = this.model.coll.clone();

    clonedCollection.setHabContext(this.model.coll.getHabContext());
    clonedCollection.url = this.model.coll.url;
    clonedCollection.params = this.model.coll.params;

    if (this.model.coll.paginated === false) {
      if (!STR.isBlank(this.filterSelection)) {
        clonedCollection.models = clonedCollection.where(this.filterMultiSelectColl);
      } else {
        clonedCollection.models = _.filter(clonedCollection.models, function(model) {
          return model.get("grillehoraire") !== true && STR.isBlank(model.get('libelleerror'));
        })
      }
      this.model.multiselectColl.add(clonedCollection.models);
      this.model.coll.forEach(function(model) {
        model.trigger("row:checked");
      });
      this._manageMultiSelection();
    } else {
      clonedCollection.paginated = false;
      clonedCollection.fetch({
        success: function() {
          if (!STR.isBlank(self.filterSelection)) {
            clonedCollection.models = clonedCollection.where(self.filterMultiSelectColl);
          } else if (self.filterSelectionFunction) {
            clonedCollection.models = _.filter(self.clonedCollection.models, function(model) {
              return model.get("grillehoraire") !== true && STR.isBlank(model.get('libelleerror'));
            })
          }
          self.model.multiselectColl.add(clonedCollection.models);
          self.model.coll.forEach(function(model) {
            model.trigger("row:checked");
          });
          self._manageMultiSelection();
        }
      });
    }
  },

  _selectedCellIsVisible: function() {
    var offsetCell = $(this.el).find(".ui-grid-row.phx-selected").offset();
    var offsetTable = this.$el.find(".phx-grid-scroll").offset();
    var heightTable = this.$el.find(".phx-grid-scroll").height();
    var lb_rtn = false;

    if (offsetCell && offsetTable && heightTable) {
      if (offsetCell.top >= offsetTable.top && offsetCell.top <= (offsetTable.top + heightTable)) {
        lb_rtn = true;
      } else {
        lb_rtn = false;
      }
    }
    return lb_rtn;
  },

  //  ACCESIBILITY FUNCTIONS
  //Select the adjacent cell (header and table)
  _selectAdjacentCell: function(container, operation, position, tag) {
    var numberCells = this.currentVue.columns.length;
    var currentCell = $(this.el).find(".grid-cell-focus");
    var nextCellPosition = null;
    var nextCell = null;

    //obtain the position of the new cell. if it is an input we need to focus the input not the cell.
    if (currentCell.is("td") || currentCell.is("th")) {
      nextCellPosition = !STR.isBlank(position) ? position : currentCell.parent().children().index(currentCell);
    } else {
      nextCellPosition = !STR.isBlank(position) ? position : currentCell.parents(tag + ":first").parent().children().index(currentCell.parents(tag + ":first"));
    }

    //obtain the new cell
    do {
      nextCellPosition = nextCellPosition + operation;
      nextCell = !STR.isBlank(container[nextCellPosition]) ? container[nextCellPosition] : null;
    } while ((STR.isBlank(nextCell) && nextCellPosition >= 0 && nextCellPosition < numberCells) || (!STR.isBlank(nextCell) && !$(nextCell).is(":visible")));

    //select the new cell
    if (!STR.isBlank(nextCell) && this._selectedCellIsVisible()) { //$(nextCell).is(":visible")){
      var scrollTop = $(".phx-grid-scroll", this.el).scrollTop();

      this.cellSelected = nextCellPosition;
      currentCell.attr({ "tabindex": "-1" }).removeAttr("aria-selected").removeClass("grid-cell-focus");
      if ($(nextCell).find(":input").is(":input")) {
        $(nextCell).find(":input").attr({ "tabindex": "0" }).addClass("grid-cell-focus").focus(); //{ preventScroll: true } produit une erreur de chargement de page
      } else {
        $(nextCell).attr({ "tabindex": "0", "aria-selected": "true" }).addClass("grid-cell-focus").focus(); //{ preventScroll: true } produit une erreur de chargement de page
      }
      $(".phx-grid-scroll", this.el).scrollTop(scrollTop);
    }
  },

  //horizontal selection inside the table
  _selectAdjacentRow: function(e, tag, adjacent) {
    var currentCell = $(e.currentTarget).find(".grid-cell-focus");
    var nextCellPosition = null;
    var nextCell = null;

    //if it is an input we need to focus the input not the cell.
    if (currentCell.is(":input")) {
      nextCellPosition = currentCell.parents(tag + ":first").parent().children().index(currentCell.parents(tag + ":first"));
    } else {
      nextCellPosition = currentCell.parent().children().index(currentCell);
    }

    //tag == "th" ----> header -> table movement
    if (tag === "th") {
      var firstRowTable = $(this.el).find(".ui-grid-table .ui-grid-body").children()[0];
      nextCell = $(firstRowTable).children()[nextCellPosition];
    } else {
      switch (adjacent) {
        case "next":
          //key down
          nextCell = $(e.target).parents("tr:first").next().children()[nextCellPosition];
          break;

        case "prev":
          // key up
          nextCell = $(e.target).parents("tr:first").prev().children()[nextCellPosition];

          if (STR.isBlank(nextCell) && this.$el.find(".phx-grid-scroll-extra1").height() === 0) {
            this._focusHeader();
          }
          break;

        case "first":
          //re pag
          nextCell = $(e.currentTarget).find("table tbody tr:first").children()[nextCellPosition];
          break;

        case "last":
          //av pag
          nextCell = $(e.currentTarget).find("table tbody tr:last").children()[nextCellPosition];
          break;
        default:
          break;
      }
    }

    if (!STR.isBlank(nextCell)) {
      currentCell.attr({ "tabindex": "-1" }).removeAttr("aria-selected").removeClass("grid-cell-focus");
      if ($(nextCell).find(":input").is(":input")) {
        $(nextCell).find(":input").attr("tabindex", "0").addClass("grid-cell-focus").focus();
      } else {
        $(nextCell).attr({ "tabindex": "0", "aria-selected": "true" }).addClass("grid-cell-focus").focus();
      }
    }
  },

  //focus header when we navigate table -> header
  _focusHeader: function() {
    var headerRow = $(this.el).find(".phx-grid-header-scroll table thead tr");
    var currentCell = $(this.el).find(".grid-cell-focus");
    var index = null;
    var nextCell = null;

    if (currentCell.is(":input")) {
      index = currentCell.parents("tr:first").children().index(currentCell.parents("td:first"));
    } else {
      index = currentCell.parents("tr:first").children().index(currentCell);
    }
    nextCell = headerRow.children()[index];

    if (!STR.isBlank(nextCell)) {
      currentCell.attr({ "tabindex": "-1" }).removeAttr("aria-selected").removeClass("grid-cell-focus");
      if ($(nextCell).find(":input").is(":input")) {
        $(nextCell).find(":input").attr("tabindex", "0").addClass("grid-cell-focus").focus();
      } else {
        $(nextCell).attr({ "tabindex": "0", "aria-selected": "true" }).addClass("grid-cell-focus").focus();
      }
    }

    if (this.model.get("value")) {
      this.model._manageRowSelection(this.model.get("value"));
    }
  },

  _clickListenerHeader: function(e) {
    var headerRow = $(this.el).find(".phx-grid-header-scroll table thead tr");
    var currentCell = $(this.el).find(".grid-cell-focus");
    var index = headerRow.find("th").index(e.currentTarget);
    this.model.coll.editModeCellSelected = index;
    this.cellSelected = index;
    var nextCell = headerRow.children()[index];

    if (!STR.isBlank(nextCell)) {
      currentCell.attr({ "tabindex": "-1" }).removeAttr("aria-selected").removeClass("grid-cell-focus");
      if ($(nextCell).find(":input").is(":input")) {
        $(nextCell).find(":input").attr("tabindex", "0").addClass("grid-cell-focus").focus();
      } else {
        $(nextCell).attr({ "tabindex": "0", "aria-selected": "true" }).addClass("grid-cell-focus").focus();
      }
    }
  },

  positionHeader: function() {
    var self = this;
    var interval = setInterval(function() {
      if (self.$el.parents("body").length > 0) {
        var calculateMargin = function() {
          $(".phx-grid-title-bar", self.el).css({ "position": "absolute", "top": "0px", "width": "100%" });
          var margin = $(".phx-grid-title-bar", self.el).eq(0).outerHeight(true);
          $(".phx-grid-header-scroll", self.el).css("margin-top", margin);
          self._resizeGrid(); // prevents extra right scrollbar
          clearInterval(interval);
          self._resizeGrid();
        };
        if (self.$el.is(":hidden")) {
          if (self.$el.css("display") === "none") {
            self.$el.show();
            calculateMargin();
            self.$el.hide();
          } else if (self.$el.parents().filter(function(index, element) { return $(element).css("display") === "none"; }).length > 0) {
            var parent = self.$el.parents().filter(function(index, element) { return $(element).css("display") === "none"; });
            parent.show();
            calculateMargin();
            parent.hide();
          }
        } else {
          calculateMargin();
        }
      }
    }, 100);
  },

  resetSorting: function() {
    //Hide the sort buttons of all columns
    var keys = _.keys(this.currentVue._columns),
      self = this,
      i = 0,
      key = null;

    for (; i < keys.length; i++) {
      key = keys[i];

      if (self.currentVue._columns[key].sortable) {
        self.currentVue._columns[key].sort = null;
        self.currentVue._columns[key].header.hideSortButton();
        self.currentVue._columns[key].header.$el.find(".title").removeClass("phx-state-sort");

        self.orderedData[key] = null;
      }
    }
  },

  showTable: function(etat) {
    if (_.isBoolean(etat)) {
      var $lPos = this.$el.find(".ui-grid-table");

      if ($lPos && $lPos.length > 0) {
        if (etat) {
          this.$el.find(".ui-grid-table").show(); //table
          $(".phx-grid-footer-right", this.el).show(); //compteurs
        } else {
          this.$el.find(".ui-grid-table").hide(); //table
          $(".phx-grid-footer-right", this.el).hide(); //compteurs
        }
      }
    }
  },

    /**
   * Hide buttons even if the habilitation allows these actions to be used
   */
    hideAllButtons: function() {
        this.buttonBar.model.trigger("hab.hide:all");
    },
});
