module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="grid-action-bar d-flex">\n    <div class="rowOrdering">\n\n        <button class="placer cw-grid-title-bar-menu btn btn-secondary btn-carre">'+
((__t=(UTILS.getSVGIcon('direction_premier')))==null?'':__t)+
'</button>\n        <button class="monter cw-grid-title-bar-menu btn btn-secondary btn-carre">'+
((__t=(UTILS.getSVGIcon('direction_haut')))==null?'':__t)+
'</button>\n        <button class="descendre cw-grid-title-bar-menu btn btn-secondary btn-carre">'+
((__t=(UTILS.getSVGIcon('direction_bas')))==null?'':__t)+
'</button>\n        <button class="premier cw-grid-title-bar-menu btn btn-secondary btn-carre">'+
((__t=(UTILS.getSVGIcon('direction_dernier')))==null?'':__t)+
'</button>\n        <!--<span tabindex="0" class="placer ui-icon ui-icon-arrowthickstop-1-n cw-hover"></span>-->\n        <!--<span tabindex="0" class="monter ui-icon ui-icon-arrowthick-1-n cw-hover"> -->\n        <!-- <span tabindex="0" class="descendre ui-icon ui-icon-arrowthick-1-s cw-hover"></span> -->\n        <!--\n    <span tabindex="0" class="placer" style="margin-right: 8px;">\n      '+
((__t=(UTILS.getSVGIcon('direction_premier')))==null?'':__t)+
'\n    </span>\n    <span tabindex="0" class="monter" style="margin-right: 8px;">\n      '+
((__t=(UTILS.getSVGIcon('direction_haut')))==null?'':__t)+
'\n    </span>\n    <span tabindex="0" class="descendre" style="margin-right: 8px;">\n        '+
((__t=(UTILS.getSVGIcon('direction_bas')))==null?'':__t)+
'\n     </span>  \n     <span tabindex="0" class="premier">\n        '+
((__t=(UTILS.getSVGIcon('direction_dernier')))==null?'':__t)+
'\n     </span>  \n     -->\n        <!--<span tabindex="0" class="premier ui-icon ui-icon-arrowthickstop-1-s cw-hover"></span>-->\n    </div>\n    <div class="newRowOrdering">\n        <div class="monter-menu cw-grid-title-bar-menu">\n            <button class="monter btn btn-secondary btn-carre">'+
((__t=(UTILS.getSVGIcon('triangle_haut')))==null?'':__t)+
'</button>\n        </div>\n        <div class="descendre-menu cw-grid-title-bar-menu">\n            <button class="descendre btn btn-secondary btn-carre">'+
((__t=(UTILS.getSVGIcon('triangle_bas')))==null?'':__t)+
'</button>\n        </div>\n    </div>\n    <!-- Delete button displayed in the table header -->\n    <div class="deleteHead-menu cw-grid-title-bar-menu">\n        <button class="deleteHead btn btn-secondary-alert btn-carre">'+
((__t=(UTILS.getSVGIcon('poubelle')))==null?'':__t)+
'</button>\n    </div>\n    <div class="new-menu cw-grid-title-bar-menu">\n        <button class="new nwBtn btn btn-secondary btn-withIcon">'+
((__t=(grid.t("common:grid.bouton_creer")))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon('plus')))==null?'':__t)+
'</button>\n    </div>\n    <!-- Delete button is not longer displayed in the table header -->\n    <!-- The button is hidden but preserved here so it can be detached to another location -->\n    <div class="delete-menu cw-grid-title-bar-menu d-none">\n        <button class="delete btn btn-primary-alert btn-withIcon">'+
((__t=(grid.t("common:grid.del")))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon('poubelle')))==null?'':__t)+
'</button>\n    </div>\n    <!-- Dupliquer button is not longer displayed in the table header -->\n    <!-- The button is hidden but preserved here so it can be detached to another location -->\n    <div class="copy-menu cw-grid-title-bar-menu d-none">\n        <button class="copy btn btn-primary btn-withIcon">'+
((__t=(grid.t("common:grid.copy")))==null?'':__t)+
' '+
((__t=(UTILS.getSVGIcon('dupliquer')))==null?'':__t)+
'</button>\n    </div>\n</div>';
}
return __p;
};
