import * as Backbone from 'Backbone';
import _ from 'underscore';
import AppHomeTPL from '../cwHome.tpl.html';
import { CWAffectationEnCoursView } from 'common/briques/baffec/views/cwAffectationEnCours.view';
import { CWAujourdhuiView } from 'common/briques/aujourdhui/views/cwAujourdhui.view';
import { CWDemandesCollaborateursView } from 'common/briques/bdemcoll/views/cwDemandesCollaborateurs.view';
import { CWDialogPopupType, CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWEffectifsView } from 'common/briques/beff/views/cwEffectifs.view';
import { CWGeneralView } from 'common/briques/persobriques/views/cwGeneral.view';
import { CWInfosMemosView } from 'common/briques/binfmem/views/cwInfosMemos.view';
import { CWMesDemandesView } from 'common/briques/bdem/views/cwMesDemandes.view';
import { CWMesEmpruntsView } from 'common/briques/bemprunt/views/cwMesEmprunts.view';
import { CWPersonalisationMenuView } from 'common/briques/persobriques/views/cwPersonalisationMenu.view';
import { CWPortletView } from 'core/components/brique/cwPortlet.view';
import { CWResultatsView } from 'common/briques/bresultats/views/cwResultats.view';
import { CWSituationsARegulariserView } from 'common/briques/bsitreg/views/cwSituationsARegulariser.view';
import { CWSituationView } from 'common/briques/bsitpart/views/cwSituation.view';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { CWWorkFlowModel } from '../models/cwWorkFlow.model';
import { i18n } from 'src/i18n.js';
import { SYNC } from 'src/utils/sync.js';
import { UTILS } from 'src/utils';


/**
 * A view to paint the  home page of each zone
 */
export class CWHomeView<TModel extends CWWorkFlowModel = CWWorkFlowModel> extends Backbone.View<TModel> {

  private columns: number;
  private zJSON: { [key: string]: any };
  template: (json?: { [key: string]: any }) => string;
  private BRIQUES_VIEWS: { [key: string]: any };
  private listBriques: { [key: string]: any }[];
  private dialogPersonnaliser: CWDialogPopupView;
  private generalView: CWGeneralView;
  RESPONSABLE_TYPE: any;
  persoMenu: CWPersonalisationMenuView;

  constructor(options: Backbone.ViewOptions<TModel> | any) {
    options.className = options.className || "ui-widget-content";

    super(options);
    this.model.on("reload:brique", (): void => {
      this._reloadBrique(true);
    }, this);
    this.template = AppHomeTPL;
    this.zJSON = options.zJSON;
    this.persoMenu = new CWPersonalisationMenuView({});

    this.columns = 3;
    this.BRIQUES_VIEWS = {};
    this.listenTo(this.model, "change:uri", this._changeZone);
    this.listenTo(this.model, "refresh:Briques_" + this.zJSON.id, this._managePopulationChange);
    this.listenTo(this.model, "refresh:Brique_" + this.zJSON.id, this._refreshBriques);
    this.listenTo(this.model, "click:personnaliser" + this.zJSON.id, this._personnaliser);

    const zone = this.model.get("zone");
    const args: any = ["context"];
    args.context = {
      ctxEcran: "home" + zone,
      espaceid: this.zJSON.emid,
      zone: zone,
      zoneName: this.zJSON.nom
    };
    this.generalView = new CWGeneralView(args);
    this.listenTo(this.generalView.listeView.model, "refresh:Briques_" + this.zJSON.id, this._managePopulationChange);
  }

  render(): CWHomeView<TModel> {

    this.$el.html(this.template());
    $(".cw-portlet", this.el).sortable({
      delay: 200,
      connectWith: ".cw-portlet",
      items: ".phx-portlet",
      distance: 0.1,
      dropOnEmpty: true,
      cursor: "move",
      change: (event: JQueryEventObject, ui: any) => {
        if (event.target === ui.item.parent()[0]) {
          let elementsColumn1 = this.$el.find(".phx-portlet-container .cw-column1").children().length;
          let elementsColumn2 = this.$el.find(".phx-portlet-container .cw-column2").children().length;
          const elementId = ui.item[0].attributes.id.value;
          if (this.$el.find(".phx-portlet-container .cw-column1 #" + elementId).length > 0) { // Si on trouve le element sur la column au moment que on move on l'ignore, maintenant il est un placeholder.
            elementsColumn1 -= 1;
          } else if (this.$el.find(".phx-portlet-container .cw-column2 #" + elementId).length > 0) {
            elementsColumn2 -= 1;
          }
          if (elementsColumn1 < elementsColumn2 || elementsColumn1 > elementsColumn2 + 1) {
            let columnToAdjust = ".cw-column1";
            if (elementsColumn1 < elementsColumn2) {
              columnToAdjust = ".cw-column2";
            }
            const elementsColumnAdjust = this.$el.find(".phx-portlet-container " + columnToAdjust).children().length;

            let elementToMove = this.$el.find(".phx-portlet-container " + columnToAdjust).children()[elementsColumnAdjust - 1];

            if (elementToMove.attributes["class" as any].value.indexOf("ui-sortable-placeholder") > -1) {
              elementToMove = this.$el.find(".phx-portlet-container " + columnToAdjust).children()[elementsColumnAdjust - 2]
            }

            const columnToReceive = columnToAdjust === ".cw-column1" ? ".cw-column2" : ".cw-column1";
            this.$el.find(".phx-portlet-container " + columnToReceive).append(elementToMove);
          }
        }
      },
      update: (event: JQueryEventObject, ui: any) => {
        if (event.target === ui.item.parent()[0]) {
          this.generalView.context.ctxEcran = "home" + this.model.get("zone");
          const columnClasses = ui.item[0].parentNode.className.split(" ");
          const elementsColumn1 = this.$el.find(".phx-portlet-container .cw-column1").children().length;
          const elementsColumn2 = this.$el.find(".phx-portlet-container .cw-column2").children().length;
          columnClasses.forEach((columnClass: string) => {
            if (columnClass.indexOf("cw-column") > -1) {
              // CASE 1 : Move only 1 brique and save position.
              /*
              const maxElements = elementsColumn1 + elementsColumn2;
              const itemId = ui.item[0].attributes.id.value;
              let newPos = this.$el.find(".phx-portlet-container #"+itemId).index() +1;
              if(this.$el.find(".phx-portlet-container .cw-column1 #"+itemId).index() < 0){ // Si on trouve le bloque sur la column 2 la position est * 2.
                newPos = newPos * 2;
              }
              newPos = newPos > maxElements ? maxElements : newPos;
              if(columnClass[columnClass.length - 1] === "2"){ // Column 2
                this.generalView.listeView._loadDataHomeAndUpdate(itemId, newPos);
              } else {
                this.generalView.listeView._loadDataHomeAndUpdate(itemId, newPos);
              }*/

              // CASE 2 : Save all briques position.
              const itemsColumn1 = [];
              const itemsColumn2 = [];
              const briquesOrder = [];
              for (let i = 0; i < elementsColumn1; i++) {
                itemsColumn1.push(this.$el.find(".phx-portlet-container .cw-column1").children()[i].attributes["id" as any].value);
              }
              for (let j = 0; j < elementsColumn2; j++) {
                itemsColumn2.push(this.$el.find(".phx-portlet-container .cw-column2").children()[j].attributes["id" as any].value);
              }
              const l = Math.min(itemsColumn1.length, itemsColumn2.length);

              for (let i = 0; i < l; i++) {
                briquesOrder.push(itemsColumn1[i], itemsColumn2[i]);
              }
              briquesOrder.push(...itemsColumn1.slice(l), ...itemsColumn2.slice(l));
              this.generalView.listeView._loadDataHomeAndUpdateAllItems(briquesOrder);

            }
          });
        }
      }
    });
    $(".phx-portlet-container", this.el).disableSelection();
    return this;
  }

  _managePopulationChange(refreshBrique: boolean): void {
    this._reloadBrique(refreshBrique);
  }

  _refreshBriques(briquesToRefresh?: { [key: string]: any }[] | any, options?: { [key: string]: any }): void {
    this._reloadBrique(false, briquesToRefresh, options);
  }

  //RefreshAll=true if all briques have to be refreshed
  //briquesToRefresh, Array with the id of briques that should be refreshed, if only some specific briques have to be refreshed
  _reloadBrique(refreshAll: boolean, briquesToRefresh?: { [key: string]: any }[] | any, options?: { [key: string]: any }): void {
    const zone = this.model.get("zone");
    const callback = (): void => {
      const briques = this.model.BRIQUES[zone];
      const views = this.BRIQUES_VIEWS;
      let index = 1;

      _.each(briques, (brique: { [key: string]: any }): void => {
        const briqueViewId = this.model.getBriqueUniqueId(brique);

        if (this.briqueAlreadyCreated(briqueViewId)) { //Created
          //IF bresultats has no data it shouldn't be shown
          if (CWSTR.isBlank(views[briqueViewId].view) || CWSTR.isBlank(views[briqueViewId].view.isEmpty) || views[briqueViewId].view.isEmpty() !== true) {
            views[briqueViewId].$el.show();
            views[briqueViewId].view.delegateEvents();
            if ((briqueViewId as string).split(",")[0] === "aujourdhui") {
              (this.BRIQUES_VIEWS[briqueViewId].view as CWAujourdhuiView).activateInterval(); //appel à méthode du brique aujourd'hui pour activer le setInterval
            }
          }
          if (refreshAll === true) {
            this.refreshBrique(briqueViewId);
          } else if (!refreshAll && _.isArray(briquesToRefresh)) {
            for (let i = 0; i < briquesToRefresh.length; i++) {
              if (briquesToRefresh[i] === briqueViewId) { //brique.id) {
                this.refreshBrique(briqueViewId, options);
                break;
              }
            }
          }
        } else { //Not created
          this.createBrique(brique, (index % 2) + 1);
        }
        //KEEP ORDER sent in configuration Web Service in the order of painting
        if (!CWSTR.isBlank(this.BRIQUES_VIEWS[briqueViewId])) {
          const lPos = this.$el.find(".phx-portlet-container .cw-column" + ((++index % 2) + 1));
          if (lPos.length > 0) {
            lPos.append(this.BRIQUES_VIEWS[briqueViewId].$el);
          }
        }

      }, this);
      //Look for briques that are not activated anymore
      _.each(_.keys(this.BRIQUES_VIEWS), (briqueViewId: string): void => {
        if (!this.briqueisActivated(briqueViewId)) { //It is not activated (hide this brique)
          if ((briqueViewId as string).split(",")[0] === "aujourdhui") {
            (this.BRIQUES_VIEWS[briqueViewId].view as CWAujourdhuiView).removeInterval(); //appel à méthode du brique aujourd'hui pour énlever le setInterval
          }
          this.BRIQUES_VIEWS[briqueViewId].$el.remove();//remove brique
        }
      });
    };
    if (String(zone) === String(this.zJSON["id"])) {
      this.model._reloadBriquesInfo(callback, "home" + this.zJSON["id"]);
    }
  }

  briqueAlreadyCreated(briqueViewId: number | string): boolean {
    let created = false;
    const views = this.BRIQUES_VIEWS;

    if (!CWSTR.isBlank(views[briqueViewId])) {
      created = true;
    }
    return created;
  }

  briqueisActivated(briqueViewId: string): boolean {
    let activated = false;
    const zone = this.model.get("zone");
    const briques = this.model.BRIQUES[zone];
    const briqueFound = _.find(briques, (brique): boolean => {
      const lValBrique = this.model.getBriqueUniqueId(brique);
      let lRtn = false;

      if (lValBrique && briqueViewId) {
        if (briqueViewId.split(",")[0] === "bresultats") {
          lRtn = (briqueViewId.split(",")[3] === lValBrique.split(",")[3]);
        } else {
          lRtn = (briqueViewId.split(",")[0] === lValBrique.split(",")[0]);
        }
      }
      return lRtn;
    });

    if (!CWSTR.isBlank(briqueFound)) {
      activated = true;
    }
    return activated;
  }

  refreshBrique(briqueViewId: number | string, options?: { [key: string]: any }): void {
    const views = this.BRIQUES_VIEWS;

    if (views[briqueViewId].view && views[briqueViewId].view.refreshBrique) {
      views[briqueViewId].view.refreshBrique(options);
    } else {
      views[briqueViewId].render();
    }
  }

  _changeZone(): void {
    if (this.model.get("zone") === this.zJSON.id && this.model.isGoingToZoneHome()) {
      this.loadComponents();
    }
  }

  loadComponents(): void {
    if (_.keys(this.BRIQUES_VIEWS).length <= 0) {
      let i = 1;

      this.listBriques = this.model.BRIQUES[this.zJSON.id];
      _.each(this.listBriques, (brique: { [key: string]: any }): void => {
        this.createBrique(brique, (++i % 2) + 1);
      });
    } else {
      _.each(this.BRIQUES_VIEWS, (itemBrique: CWPortletView) => {
        if (itemBrique.view && (itemBrique.view as any).repaintBriqueNotFetch) {
          (itemBrique.view as any).repaintBriqueNotFetch();
        }
        if ((itemBrique.id === "bdemcoll" || itemBrique.id === "aujourdhui" || itemBrique.id === "bdem") && this.model.isGoingToZoneHome() && this.briqueisActivated(itemBrique.id)) {
          this._callRefresBrique(itemBrique, itemBrique.id)
        }
      });
    }
  }

  createBrique(brique: { [key: string]: any }, column: number): void {
    const briqueToday = CWTYPE.DATE.dateToStr(SYNC.getServerDate());
    const usePopulation = this.model.get("zone") === "resp";
    const briqueViewId = this.model.getBriqueUniqueId(brique);
    let portlet: CWPortletView = null;
    let heure = "";
    let view = null;
    const lPos = this.$el.find(".phx-portlet-container .cw-column" + (column || 1));

    switch (brique.id) {
      case "bemprunt":
        portlet = new CWPortletView({
          id: "bemprunt",
          //idEmid: "bemprunt," + brique.emid,
          title: i18n.t('common:bemprunt.mesEmprunts_title', { "0": briqueToday }),
          view: null
        });
        portlet.view = new CWMesEmpruntsView({ parent: portlet, usePopulation: usePopulation });
        break;
      case "binfmem":
        portlet = new CWPortletView({
          id: "binfmem",
          //idEmid: "binfmem," + brique.emid,
          title: i18n.t('common:binfmem.infosMemos'),
          tooltip: i18n.t('common:binfmem.infosMemos_title'),
          view: null
        });
        portlet.view = new CWInfosMemosView({ parent: portlet, usePopulation: usePopulation });
        break;
      case "bdem":
        portlet = new CWPortletView({
          id: "bdem",
          title: i18n.t('common:bdem.mesDemandes'),
          tooltip: i18n.t('common:bdem.mesDemandes_title'),
          view: null,
          parent: this
        });
        portlet.view = new CWMesDemandesView({ parent: portlet, usePopulation: usePopulation });
        (portlet.view as any).idEmid = "bdem," + brique.emid;
        break;
      case "bsitpart":
        portlet = new CWPortletView({
          id: "bsitpart",
          //idEmid: "bsitpart," + brique.emid,
          title: i18n.t('common:bsitpart.situation'),
          view: null
        });
        portlet.view = new CWSituationView({ parent: portlet, usePopulation: usePopulation });
        break;
      case "bdemcoll":
        portlet = new CWPortletView({
          id: "bdemcoll",
          //idEmid: "bdemcoll," + brique.emid,
          title: i18n.t('common:bdemcoll.DemandesCollaborateurs'),
          view: null
        });
        portlet.view = new CWDemandesCollaborateursView({ parent: portlet, usePopulation: usePopulation });
        break;
      case "baffec":
        portlet = new CWPortletView({
          id: "baffec",
          //idEmid: "baffec," + brique.emid,
          title: i18n.t('common:baffec.affectationEnCours'),
          view: null
        });
        portlet.view = new CWAffectationEnCoursView({ parent: portlet, usePopulation: usePopulation });
        break;
      case "bsitreg":
        portlet = new CWPortletView({
          id: "bsitreg",
          //idEmid: "bsitreg," + brique.emid,
          title: i18n.t('common:bsitreg.SituationsARegulariser'),
          view: null
        });
        portlet.view = new CWSituationsARegulariserView({ parent: portlet, usePopulation: usePopulation });
        break;
      case "beff":
        heure = CWTYPE.DATE.dateToStr(SYNC.getServerDate(), "HH:mm", "", ":");
        portlet = new CWPortletView({
          id: "beff",
          //idEmid: "beff," + brique.emid,
          title: i18n.t('common:beff.brickTitle', { "0": heure }),
          view: null
        });
        portlet.view = new CWEffectifsView({ parent: portlet, usePopulation: usePopulation });
        break;
      case "bresultats":
        view = new CWResultatsView({ brique: brique, usePopulation: usePopulation });
        portlet = new CWPortletView({
          id: "bresultats" + view.cid.substring(4) + "_codeb_" + UTILS.escapeJQueryString(brique.personnalisation.codebrique),
          //idEmid: "bresultats" + view.cid.substring(4) + "_codeb_" + brique.personnalisation.codebrique + "," + brique.emid,
          view: view,
          usePopulation: usePopulation
        });
        view.parent = portlet;
        break;
      case "aujourdhui":
        portlet = new CWPortletView({
          id: "aujourdhui",
          //idEmid: "aujourdhui," + brique.emid,
          title: (i18n.t('common:aujourdhui.aujourdhui_title')),
          view: null
        });
        portlet.view = new CWAujourdhuiView({ "parent": portlet, "usePopulation": usePopulation, "matricule": this.model.authModel.get("matricule"), "datedeb": CWTYPE.DATE.parse(briqueToday).val });
        break;
      default:
        break;
    }
    if (lPos.length > 0) {
      lPos.append(portlet.render().el);
    }
    this._callRefresBrique(portlet, brique.id);
    this.BRIQUES_VIEWS[briqueViewId] = portlet;
  }

  hasCreatedComponents(): boolean {
    return false;
  }

  _personnaliser(zoneid: string, uc?: string, action?: string): void {
    const zone = this.model.get("zone");
    const lINfoZone = this.model.configuration.get("confignav").get("zones").find((item: { [key: string]: any }): boolean => {
      return item.id === zone;
    });
    const context = {
      ctxEcran: "home" + zone,
      espaceid: lINfoZone.emid,
      zone: zone,
      zoneName: lINfoZone.nom
    };

    if (context.espaceid === zoneid) {
      if (CWSTR.isBlank(uc)) {
        if (this.dialogPersonnaliser === undefined) {
          const lPosition = { my: "center top", at: "center top+140", of: window };
          this.dialogPersonnaliser = new CWDialogPopupView({
            view: CWGeneralView,
            popupType: CWDialogPopupType.AVEC_SAISIE,
            position: lPosition,
            maxHeight: 670,
            viewData: {
              context: context,
              appendTo: (context && !CWSTR.isBlank(context.ctxEcran)) ? $("#" + context.ctxEcran) : null
            }
          });
        } else {
          this.dialogPersonnaliser.viewData = {
            context: context,
            appendTo: (context && !CWSTR.isBlank(context.ctxEcran)) ? $("#" + context.ctxEcran) : null
          };
        }
        this.dialogPersonnaliser.setHeight("auto");
        this.dialogPersonnaliser.setWidth("auto");
        _.each(this.model.configuration.get("confignav").get("zones"), (zone: { [key: string]: any }): void => {
          if (String(zone.id) === String(this.model.get("zone"))) {
            this.dialogPersonnaliser._setTitle(i18n.t('app.optionPersonnalisation') + ' "' + zone.nom + '"');
          }
        }, this);

        this.dialogPersonnaliser.open((): void => {
          //What to do on close
        });
        this.dialogPersonnaliser.internalView.model.off("reload");
        this.dialogPersonnaliser.internalView.model.on("reload",
          (collection: any, refreshData: any, bToRefresh: { [key: string]: any }[] | any): void => {
            this._reloadBrique(false, bToRefresh);
          }, this);
      } else {
        switch (action) {
          case "save":
            this.persoMenu._showDialogSaveCriteria(null, uc);
            break;
          case "autosave":
            this.persoMenu._autoSaveCriteria(uc);
            break;
          case "delete":
            this.persoMenu._showDialogDeleteCriteria(uc);
            break;
          default:
          // code block
        }
      }
    }
  }

  _callRefresBrique(portlet: CWPortletView, brique: string): void {
    let lBriq = null;

    switch (brique) {
      case "bemprunt":
        lBriq = portlet.view as CWMesEmpruntsView;
        break;
      case "binfmem":
        lBriq = portlet.view as CWInfosMemosView;
        break;
      case "bdem":
        lBriq = portlet.view as CWMesDemandesView;
        break;
      case "bsitpart":
        lBriq = portlet.view as CWSituationView;
        break;
      case "bdemcoll":
        lBriq = portlet.view as CWDemandesCollaborateursView;
        break;
      case "baffec":
        lBriq = portlet.view as CWAffectationEnCoursView;
        break;
      case "bsitreg":
        lBriq = portlet.view as CWSituationsARegulariserView;
        break;
      case "beff":
        lBriq = portlet.view as CWEffectifsView;
        break;
      case "bresultats":
        lBriq = portlet.view as CWResultatsView;
        break;
      case "aujourdhui":
        lBriq = portlet.view as CWAujourdhuiView;
        break;
      default:
        break;
    }
    if (lBriq && lBriq.refreshBrique) {
      lBriq.refreshBrique();
    }
  }
}
