import * as Backbone from 'Backbone';
import _ from 'underscore';
import beffDetailTPL from '../detail.tpl.html';
import { CWEffectifsModel } from '../models/cwEffectifs.model';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWLibellesModelColl } from '../models/cwLibellesModel.collection';
import { CWPortletView } from 'src/core/components/brique/cwPortlet.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { LOG } from 'src/utils';
import { NAV } from 'utils/nav.js';
import { objs } from 'src/objectsRepository';
import { SYNC } from 'utils/sync.js';
import { UTILS } from 'utils/utils.js';

/**
 * View of Brique 'Effectifs'
 */
export class CWEffectifsView<TModel extends CWEffectifsModel = CWEffectifsModel> extends Backbone.View<TModel> {

  usePopulation: boolean;
  parent: CWPortletView;
  habContext: CWHabilitationContext;
  ETAT_PRESENT: string;
  ETAT_ABSENT_PREVU: string;
  ETAT_ABSENT_NON_PREVU: string;
  ETAT_INDEFINI: string;
  ROWS_FOR_FILE: number;
  replotIndex: number;
  plot: any;
  collection: CWLibellesModelColl<TModel>;
  controllerEffectif: any;

  constructor(options: Backbone.ViewOptions<CWEffectifsModel> | any) {
    options = options || {};
    options.className = options.className ? options.className : "CWEffectifsViewContainer";
    options.events = _.extend({
      "click .cw-lienEnrichi": "_goToDetail"
    }, CWTYPE.SHORT, options.events);
    super(options);
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    if (options && options.parent) {
      this.parent = options.parent;
    }
    this.template = beffDetailTPL;
    this.habContext = new CWHabilitationContext({
      onglet: "beff",
      foncCour: "RES_EFFECT.V",
      natGest: ""
    });
    this.model = new CWEffectifsModel() as TModel;
    this.model.usePopulation = this.usePopulation;
    this.collection = new CWLibellesModelColl();
    this.collection.usePopulation = this.usePopulation;
    this.model.setHabContext(this.habContext);
    //class of etats
    this.ETAT_PRESENT = "ui-phx-etat-present";
    this.ETAT_ABSENT_PREVU = "ui-phx-etat-absent-prevu";
    this.ETAT_ABSENT_NON_PREVU = "ui-phx-etat-absent-non-prevu";
    this.ETAT_INDEFINI = "ui-phx-etat-indefini";
    this.ROWS_FOR_FILE = 3;
    this.replotIndex = 0;
    if (options && options.parent) {
      this.parent = options.parent;
    }
    this.plot = null;
    // To avoid multiple fetch calls by population changes
    this.controllerEffectif = null;
  }

  render(): CWEffectifsView<TModel> {
    const hour = SYNC.getServerHour();
    const $a = $("<span>").addClass("cw-beffTitle").append("à ");
    const $time = $("<span>").addClass("cw-beffTitle__time").append($("<span>").addClass("cw-titreBriqueJour").append(CWTYPE.HOUR_MINUTE.format(hour)));

    this.$el.html(this.template());
    this.$el.find(".graphiqueZone").attr("id", "graphiqueZone_" + this.cid);
    $time.prepend($a);
    this.parent.$el.find('.phx-portlet-header').append($time);
    setInterval(() => {
      this._refreshTime(this.parent.$el);
    }, 60000);
    return this;
  }

  _refreshTime(brique: JQuery): void {
    if (brique && brique.find(".cw-titreBriqueJour").length > 0) {
      brique.find(".cw-titreBriqueJour").html((CWTYPE.HOUR_MINUTE.format(SYNC.getServerHour())));
    }
  }

  checkHabilation(): boolean {
    return CWHABILITATION.canView("RES_EFFECT.V");

  }

  refreshBrique(): CWEffectifsView<TModel> {
    this._initializeColors();
    this._renderData();
    this.delegateEvents();
    return this;
  }

  refreshTitle(): void {
    this.parent.setTitle(i18n.t('common:beff.brickTitle'));
  }

  _renderData(): void {
    if (this.controllerEffectif) {
      // cancel request
      this.controllerEffectif.abort()
    }
    // make our request cancellable
    this.controllerEffectif = new AbortController();
    const signal = this.controllerEffectif.signal;

    this.model.fetch({
      success: (fetchedModel: any) => {
        this.controllerEffectif = null;
        this._renderGrafique(fetchedModel);
        this.model = fetchedModel;
        this.refreshTitle();
        this.collection.fetch({
          success: (collection: any) => {
            const loupeIcon = UTILS.getSVGIcon("loupe", "", 16);

            this._renderSyntheseTable(collection);
            $(this.el).find(".zoneDetailLink .cw-lienEnrichi").html("<span class='mr-2'>" + i18n.t('common:beff.linkDetail') + "</span>" + loupeIcon);
          }
        });
      },
      error: (model: any, response: any): void => {
        if (response.status === 444) {
          this.$el.find(".beff-detail").children().hide();
          const span = $("<div class='phx-grid-no-title-message emptyMessage cw-texteNormal'>").text(response.responseJSON?.message.split("\r\n")[0]);
          this.$el.find(".beff-detail").append(span);
          response.status = 200;
        } else {
          LOG.debug("Aborted WS: Effectif");
        }
      },
      signal: signal
    });
  }

  _goToDetail(): void {
    NAV.navigate("resp", "presabs");
  }

  _renderGrafique(model: CWEffectifsModel): void {
    if (this.plot) {
      const lPos = this.$el.find(".printGraphique");

      this.plot.destroy();
      this.plot = null;
      if (lPos.length > 0) {
        const $lDiv = $("<div class='graphiqueZone'>").attr("id", "graphiqueZone_" + this.cid).css({ "height": "233px", "width": "220px" });

        lPos.empty();
        lPos.append($lDiv);
      }
    }
    if (model.get("present") === 0 && model.get("absentprevu") === 0 && model.get("absentnonprevu") === 0 && model.get("indefini") === 0) {
      const span = $("<div class='phx-grid-no-title-message emptyMessage'>").text(i18n.t('common:beff.noEffectifsAffiche'));

      this.$el.find(".beff-detail").children().hide();
      this.$el.find(".beff-detail").append(span);
      this.replotIndex = 1;
    } else {
      const presentColor = $("." + this.ETAT_PRESENT).css("background-color");
      const absentsPColor = $("." + this.ETAT_ABSENT_PREVU).css("background-color");
      const absentsNPColor = $("." + this.ETAT_ABSENT_NON_PREVU).css("background-color");
      const indefiniColor = $("." + this.ETAT_INDEFINI).css("background-color");
      const valeurs = [
        [i18n.t("common:beff.presents"), model.get("present")],
        [i18n.t("common:beff.absentsPrevus"), model.get("absentprevu")],
        [i18n.t("common:beff.absentsNonPrevus"), model.get("absentnonprevu")],
        [i18n.t("common:beff.indefini"), model.get("indefini")]
      ];
      const colors = [presentColor, absentsPColor, absentsNPColor, indefiniColor];
      const lPosPlot = $("#graphiqueZone_" + this.cid);

      this.$el.find(".beff-detail").children().show();
      this.$el.find(".emptyMessage").hide();
      if (this.cid && lPosPlot.length > 0 && lPosPlot.get(0)) {
        this.plot = (jQuery as any).jqplot('graphiqueZone_' + this.cid, [valeurs], {
          grid: {
            drawBorder: false,
            drawGridlines: false,
            background: 'transparent',
            shadow: false
          },
          seriesColors: colors,
          seriesDefaults: {
            shadow: false,
            // Make this a pie chart.
            renderer: (jQuery as any).jqplot.PieRenderer,
            rendererOptions: {
              diameter: 200, // auto-size by dafault
              showDataLabels: true,
              dataLabels: "value",
              highlightMouseOver: false
            }
          }
        });
      }
      this.replotIndex = 0;
    }
    this.listenTo(objs.appRt.workflow, "change:usecase", (workflow: any) => {
      if (workflow.get("zone") === "resp" && workflow.get("usecase") === "") {
        if (this.replotIndex === 0) {
          if (!CWSTR.isBlank(this.plot)) {
            this.plot.replot();
            this.replotIndex++;
          }
        }
      }
    });
    this.listenTo(objs.appRt.workflow, "change:zone", (workflow: any, zone: string) => {
      const lastuc = "lastuc-" + zone;

      if (zone === "resp" && CWSTR.isBlank(workflow.get(lastuc))) {
        if (this.replotIndex === 0) {
          if (!CWSTR.isBlank(this.plot)) {
            this.plot.replot();
            this.replotIndex++;
          }
        }
      }
    });
  }

  _renderSyntheseTable(collection: any): void {
    // const presentColor = $("." + this.ETAT_PRESENT).css("background-color");
    // const absentsPColor = $("." + this.ETAT_ABSENT_PREVU).css("background-color");
    // const absentsNPColor = $("." + this.ETAT_ABSENT_NON_PREVU).css("background-color");
    // const indefiniColor = $("." + this.ETAT_INDEFINI).css("background-color");
    const fetchedModel = this.model;
    for (let i = 0; i < collection.length; i++) {
      let effectif = null;
      let libelleClass = null;
      let printEffectif = true;
      //let classColor = null;
      let cssClass = null;

      switch (collection.models[i].get("code")) {
        case "PRES":
          effectif = fetchedModel.get("present");
          libelleClass = ".libellePresent";
          //classColor = presentColor;
          cssClass = this.ETAT_PRESENT;
          break;
        case "ABSPREV":
          effectif = fetchedModel.get("absentprevu");
          libelleClass = ".libelleAbsentPrevu";
          // classColor = absentsPColor;
          cssClass = this.ETAT_ABSENT_PREVU;
          if (effectif === 0) {
            printEffectif = false;
          }
          break;
        case "ABSNPREV":
          effectif = fetchedModel.get("absentnonprevu");
          libelleClass = ".libelleAbsentNonPrevu";
          // classColor = absentsNPColor;
          cssClass = this.ETAT_ABSENT_NON_PREVU;
          if (effectif === 0) {
            printEffectif = false;
          }
          break;
        case "INDEFINI":
          effectif = fetchedModel.get("indefini");
          libelleClass = ".libelleIndefini";
          // classColor = indefiniColor;
          cssClass = this.ETAT_INDEFINI;
          if (effectif === 0) {
            printEffectif = false;
          }
          break;
        default:
          printEffectif = false;
          break;
      }

      if (printEffectif === true) {
        const $ligne = $("<div>").addClass("row").css('margin', '0px');
        const $libelle = $("<div class='cw-beffLib'>");
        const $carreColor = $("<div>").addClass('cw-beff__carre').append($("<div>").addClass('carreColor ' + cssClass));

        $libelle.append($("<span>").addClass('cw-texteImportant').append(effectif + " "));
        $libelle.append($("<span>").addClass('cw-texteAllege').append(collection.models[i].get("libelle").toLowerCase()));
        $ligne.append($libelle);
        $ligne.append($carreColor);
        $(this.el).find(libelleClass).html($ligne.html());
      } else {
        $(this.el).find(libelleClass).remove();
      }
    }
  }

  _initializeColors(): void {
    $(this.el).find(".initializeColors").html("<span class=" + this.ETAT_PRESENT + "></span><span class=" + this.ETAT_ABSENT_PREVU + "></span><span class=" + this.ETAT_ABSENT_NON_PREVU + "></span><span class=" + this.ETAT_INDEFINI + "></span>");
  }
}
