module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=\'c-etiquette__texts__container flex-column  flex-fill\'>\n    <span class="c-etiquette__text" data-value="'+
((__t=(values))==null?'':__t)+
'">'+
((__t=(text))==null?'':__t)+
'</span>\n    <span class=\'c-etiquette__subText cw-texteTresAllege\'>'+
((__t=(subText))==null?'':__t)+
'</span>\n</div>\n<div class=\'c-etiquette__withIcon_icon\'>\n    <!-- icon was here -->\n</div>';
}
return __p;
};
