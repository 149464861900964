import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLPopulationsmenuPopulationMenu from '../populationMenu.tpl.html';
import TPLPopulationsmenuStructuresMenu from '../structuresMenu.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWDialogPopupView } from 'src/core/components/dialog/popup/cwDialogPopup.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWPlainStructuresView } from './cwPlainStructures.view';
import { CWPopulationColl } from '../models/cwPopulation.collection';
import { CWPopulationGenerationModel } from '../models/cwPopulationStructure.model';
import { CWPopulationModel } from '../models/cwPopulation.model';
import { CWPopulationSelectColaborateursModel as CWCollaborateurModel } from '../models/cwPopulationSelectColaborateurs.model';
import { CWPopupSelectCollabView } from './cwPopupSelectCollab.view';
import { CWPopupSelectStructureView } from './cwPopupSelectStructure.view';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { CWTypeStructureColl } from '../models/cwTypeStructure.collection';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
import { SYNC } from 'src/utils/sync.js';
import { UTILS } from 'src/utils/utils.js';

export class CWPopulationMenuView extends CWBaseFormView {

  templateStructure: (data?: any) => any;
  dialog: { [key: string]: any };
  isVisible: boolean;
  nbMaxPopEntete: number;
  showPopulationPlus: boolean;
  populationColl: CWPopulationColl;
  diversIdCollab: { [key: string]: any };
  selectedDefaultPopulationData: { [key: string]: any };
  model: CWPopulationModel;
  structuresArray: any[];
  canViewStructures: boolean;
  structure: CWTypeStructureColl;
  fetchingData: boolean;
  defaultModel: { [key: string]: any };
  $appendTo: JQuery;
  collabDialog: CWDialogPopupView;
  $clientWidth: any;


  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "click .structureSelectorMenu": "_selectStructureSelector",
      "click .phx-menu.phx-menu-hover": "_showList",
      "click .menuPopulationPlus": "_showPopulationPlus",
      "click .menuPopulationIdent": "_changeActivePopulation",
      "click .phx-populations-menu-select-collab": "_openSelectorCollab",
      "click li": (event: JQuery.TriggeredEvent): void => {
        const $lPos = $(event.target).find("a");

        //L'option "phx-populations-menu-select-collab" a déjà sa méthode (pour ne pas faire des appels en double)
        if ($lPos && $lPos.length > 0 && !$lPos.hasClass("select-collab")) {
          $(event.target).find("a").trigger("click");
        }
      },
      "mouseover .phx-population-menu-header": "_checkInfobulle"
    }, options.events);
    options.className = "phx-populations-menu-zone";
    super(options);
    this.template = TPLPopulationsmenuPopulationMenu;
    this.templateStructure = TPLPopulationsmenuStructuresMenu;
    this.dialog = {};
    this.isVisible = false;
    this.nbMaxPopEntete = 2;
    this.showPopulationPlus = false;
    this.populationColl = new CWPopulationColl();
    this.diversIdCollab = GLOBAL_DATA.paramDivers.get("idCollab");
    this.collabDialog = null;
    this.$appendTo = options.appendTo;
    this.$clientWidth = null;
    if (options && !CWSTR.isBlank(options.selectedDefaultPopulationData)) {
      this.selectedDefaultPopulationData = options.selectedDefaultPopulationData;
    }
    this.habContext = new CWHabilitationContext({
      onglet: "populationsmenu",
      foncCour: "RES_POPCOLLAB.V"
    });
    this.model = new CWPopulationModel();
    this.model.set("libelle", i18n.t('populationsmenu.tous'));
    this.model.set("type", "D");
    this.model.set("ident", null);
    if (!CWSTR.isBlank(options.selectedPopulation)) {
      this.model.set("ident", options.selectedPopulation);
    }
    this.model.setHabContext(this.getHabContext().copy());
    if (options.disableInvalidation !== true) {
      // listen to UC which invalidates population active
      this.listenTo(objs.appRt.workflow, "change:usecase", (workflow: { [key: string]: any }): void => {
        if (workflow.get("zone") === "resp") {
          const usecase = objs.appRt.workflow.get("usecase");

          if (!CWSTR.isBlank(usecase) && objs[usecase + "Rt"]) {
            this.invalidatePopulation(objs[usecase + "Rt"].invalidatePopulation);
          } else {
            this.invalidatePopulation(false);
          }
        }
      });
    }
    this.canViewStructures = CWHABILITATION.canView("RES_POPCOLLAB.G");
    this.listenTo(this, "structures:loaded", (data: any): void => {
      this.renderStructuresList(data);
    });
    this._getStructures();
  }

  _getStructures(callback?: () => void): void {
    this.structuresArray = [];
    //on doit verifier autre fois avec les nouveaux droits
    this.canViewStructures = CWHABILITATION.canView("RES_POPCOLLAB.G");
    if (this.canViewStructures) {
      this.structure = new CWTypeStructureColl(null, { indselpop: true });
      this.structure.setHabContext(new CWHabilitationContext({
        onglet: "populationsmenu",
        foncCour: "RES_POPCOLLAB.G"
      }));
      this.structure.fetch({
        success: (dataStructuresModel: any, dataRaw: any): void => {
          const dataFiltered = dataRaw;

          this.structuresArray = dataFiltered;
          this.trigger("structures:loaded", dataFiltered);

          if (typeof callback === "function") {
            callback();
          }
        }
      });
    } else {
      if (typeof callback === "function") {
        callback();
      }
    }
  }

  _setCollaborateurOnTraversalComponent(model: { [key: string]: any }, callback: (rawData: { [key: string]: any }) => void): void {
    const habContext = new CWHabilitationContext({
      onglet: "populationsmenu",
      foncCour: "RES_POPCOLLAB.G"
    }),
      PopulationGenerationModelTemp = new CWPopulationGenerationModel({
        code: model.get("matric"),
        nature: "C",
        desc: null
      });

    PopulationGenerationModelTemp.setHabContext(habContext);
    PopulationGenerationModelTemp.save(null, {
      success: callback
    })
  }

  _clearTooltipStructure(): void {
    this.$el.find(".phx-population-menu-header").get(0).title = "";
    this.$el.find(".phx-population-menu-header").tooltip({ content: "" });
  }

  _filterStructures(dataStructures: any): any {
    const dataStructuresFiltereds = _.filter(dataStructures, (item: number): any => {
      return dataStructures[item].structureSelPopActiv === true;
    });

    return dataStructuresFiltereds;
  }

  renderStructuresList(list: any): void {
    const fragment = document.createDocumentFragment();

    _.each(list, (item: any): void => {
      if (item.rattcolpar === "O" || item.rattcolpar === "F") {
        const data = { "element": item, "i18n": i18n },
          tmpElement = $(this.templateStructure(data))[0];

        fragment.appendChild(tmpElement);
      }
    })
    this.$el.find(".structure-elements-container").append(fragment);
  }

  _getContextSelectorChemin(structure: any): any {
    return {
      ctxEcran: 'populationsmenu',
      ctxStructure: structure.code,
      ctxStructureCollab: false,
      ctxStructureActivites: false,
      ctxStructureSelpop: true,
      ctxInitChemins: null,
      ctxPeriodeRecherche: {
        datedebut: structure.debsitu,
        datefin: structure.finsitu
      },
      ctxHabilitation: this.habContext.copy(),
      ctxActivModeComplet: true,
      ctxAfficheRacine: true,
      ctxConsultation: false,
      ctxSelRacineAuto: false,
      ctxSelNoeudChemin: true,
      ctxActivSelMultiple: false,
      format: structure.format,
      ctxSelRemDesc: structure.typeremdesc,
      recine: structure.racine,
      name: structure.codef
    };
  }

  _setStructureOnTraversalComponent(selection: any, descendence: boolean): void {
    const habContext = new CWHabilitationContext({
      onglet: "populationsmenu",
      foncCour: "RES_POPCOLLAB.G"
    });
    const PopulationGenerationModelTemp = new CWPopulationGenerationModel({
      code: selection.at(0).get("id"),
      nature: "S",
      desc: descendence
    });

    PopulationGenerationModelTemp.setHabContext(habContext);
    PopulationGenerationModelTemp.save(null, {
      success: (model: any, rawData: any): void => {
        this.model = new CWPopulationModel();
        this.model.set("ident", rawData.ident);
        this.model.set("type", "D");
        this.model.set("nature", "S");
        this.model.set("desc", descendence);
        this.model.set("libelle", selection.at(0).get("label"));
        this.model.set("code", selection.at(0).id);
        this.model.set("struct", this.structure.codestructureRequest);
        this.id = rawData.ident;
        objs.appRt.workflow.syncModel.trigger("change:population", this.model);
      }
    })
  }

  _selectStructureSelector(event: any): any {
    const structureIdentifier = $(event.currentTarget).data("codestructure");

    event.preventDefault();
    event.stopPropagation();
    this.structure.indselpop = false;
    this.structure.codestructureRequest = structureIdentifier;
    this.structure.fetch({
      success: (model: any, dataRaw: any): any => {
        const dataContext = this._getContextSelectorChemin(dataRaw);
        let dialogtmp: CWDialogPopupView = null;

        this.dialog[dataContext.name] = new CWDialogPopupView({
          dialogClass: "c-cwDialogPopup cw-style cwHeader__transversal",
          view: CWPopupSelectStructureView,
          viewData: {
            parent: this,
            context: dataContext,
            habContext: this.getHabContext().copy()
          }
        });
        dialogtmp = this.dialog[dataContext.name];
        dialogtmp.setHeight("auto");
        dialogtmp.setWidth(570);
        dialogtmp._setTitle(i18n.t("messages:GT_2128", { "1": dataRaw.libselpop }));
        dialogtmp.model.trigger("dlg:open");
      }
    })

  }

  render(callback?: (arg: any) => void): CWPopulationMenuView {
    const json = { 'UTILS': UTILS, 'i18n': i18n, 'habilitation_gerer': CWHABILITATION.canView("RES_POPCOLLAB.G") };
    const onSucessFunction = (list: any): void => {
      if (this.model.get("ident")) {
        this.setPopulationActive(this.model.get("ident"));
      }
      if (typeof callback === "function") {
        callback(list);
      }
    };
    let menuLinks: JQuery = null;
    let ptgVisiPop: number = null;

    this.$el.append(this.template(json));
    this.populationColl.setHabContext(this.getHabContext().copy());
    if (CWHABILITATION.canView("RES_POPCOLLAB.V")) {
      this.populationColl.fetch({
        success: (list: any): void => {
          onSucessFunction(list);
        }
      });
    } else {
      if (this.model.get("ident") && !CWSTR.isBlank(this.selectedDefaultPopulationData)) {
        const model = new CWPopulationModel();

        model.id = this.selectedDefaultPopulationData.id;
        model.set("ident", this.selectedDefaultPopulationData.ident);
        model.set("code", this.selectedDefaultPopulationData.id);
        model.set("libelle", this.selectedDefaultPopulationData.libelle);
        model.set("type", this.selectedDefaultPopulationData.type);
        this.populationColl.add(model);
      }
      onSucessFunction(this.populationColl);
    }
    this.$el.find(".phx-population-menu-header").keydown((e: JQuery.TriggeredEvent): void => {
      this._headerKeyboradNavigation(e);
    }, null);
    this.$el.find(".phx-populations-menu-list").hide();
    //prepare keyboard navigation for each link at menu
    menuLinks = this.$el.find(".phx-populations-menu-list li.phx-menu-link a");
    _.each(menuLinks, (link: any): void => {
      $(link).keydown((e: JQuery.TriggeredEvent): void => {
        this._keyboardNav(e);
      }, null);
    });
    //Check if the user can create populations
    if (GLOBAL_DATA.paramDivers.get("ptgVisiPop") && GLOBAL_DATA.paramDivers.get("ptgVisiPop").get("valeur")) {
      ptgVisiPop = parseInt(GLOBAL_DATA.paramDivers.get("ptgVisiPop").get("valeur"), 10);
    }
    if (!((CWHABILITATION.canCreate("RES_POPCOLLAB.G") && ptgVisiPop !== 2) || (CWHABILITATION.canCreate("RES_POPTOUS.G") && ptgVisiPop === 2))) {
      this.$el.find(".create-population-opt").hide();
    }
    return this;
  }

  repaintPopulation(callback?: () => void): void {
    const json = { 'UTILS': UTILS, 'i18n': i18n, 'habilitation_gerer': CWHABILITATION.canView("RES_POPCOLLAB.G") };

    this.setPopulationActive();
    // Rerender menu with current habilitation
    this.$el.html(this.template(json));
    // Hide menu
    this.$el.find(".phx-populations-menu-list").hide();
    this.isVisible = false;
    // Get populations
    this.fetchingData = false;
    this._addElements(null, (): void => {
      //Hide the list
      this._showList();
      this._getStructures((): void => {
        if (this.model.get("ident")) {
          this.setPopulationActive(this.model.get("ident"));
        }
        if (typeof callback === "function") {
          callback();
        }
      });
    });
  }

  _eventMouseUp(): void {
    //If you click out of the menu, close the menu.
    $(document).one("mouseup", (event: JQuery.TriggeredEvent): void => {
      const menuElements = $(".phx-populations-menu-list", this.el).find(event.target).length;
      const headerElements = this.$el.find(event.target).length - menuElements;

      if (headerElements === 0) {
        //Don't close menu if mouseup is on scroll bar customer 160164
        if (event.target.className === "generated-list-elements-container") {
          this._eventMouseUp();
        } else {
          this.$el.find(".phx-populations-menu-list").hide();
          this.isVisible = false;
        }
      }
    });
  }

  _showList(event?: JQuery.TriggeredEvent, callback?: (arg?: any) => void): void {
    if (this.isVisible === false) {
      this._addElements(event, callback);
      this._eventMouseUp();
    } else {
      this.$el.find(".phx-populations-menu-list").hide();
      this.isVisible = false;
    }
  }

  _addElements(event: JQuery.TriggeredEvent, callback?: (arg: any) => void): void {
    if (this.fetchingData !== true) {
      const onSucessFunction = (list: any): void => {
        const html = this.generatePopulationListElements(list);
        let $menuList: JQuery = null;
        let topObject: number = null;
        let windowsHeight: number = null;
        let contextMenuheight: number = null;
        const scrollsize = 20;
        let maxScrollHeight: number = null;
        let $elementList: JQuery = null;
        let $posMenuList: JQuery = null;

        //----------------------
        this.$el.find("ul.phx-populations-menu-list .generated-list-elements-container").html(html);
        this.fetchingData = false;
        //Show the list
        $posMenuList = this.$el.find(".phx-populations-menu-list");
        $posMenuList.show();
        $posMenuList.css("display", "");
        this.isVisible = true;
        $menuList = this.$el.find("ul.phx-populations-menu-list");
        topObject = $menuList.offset().top;
        //Get height size of the windows (normally px unit)
        windowsHeight = $(window).height();
        //Get height size of the pop up object (normally px unit)
        contextMenuheight = $menuList.height();
        //maxScrollHeight is all height size without all under first separator (under scroll bar)
        maxScrollHeight = windowsHeight;
        if (this.$el.find("ul.phx-populations-menu-list .ui-menu-divider").length > 0) {
          maxScrollHeight = this.$el.find("ul.phx-populations-menu-list .ui-menu-divider")[0].offsetTop;
        }
        if (CWSTR.isBlank(this.$clientWidth)) {
          if ($menuList[0].clientWidth !== 0) {
            this.$clientWidth = $menuList[0].clientWidth;
          }
        }
        if ($menuList[0].clientWidth > this.$clientWidth) {
          $menuList.width(this.$clientWidth);
          $menuList.find(".generated-list-elements-container").width(this.$clientWidth);
        }
        //Population part
        $elementList = this.$el.find("ul.phx-populations-menu-list .generated-list-elements-container");
        //customer 160164 : If all pop up cannot be displayed with current position get pop up (only population elements) with scroll
        if (topObject + contextMenuheight > windowsHeight) {
          const maxHeight = windowsHeight - topObject - scrollsize;
          const separatorSize = 10;//10px is security to display all block
          let lowerMenuSize: number = null;

          $elementList.css("overflow-x", "hidden");
          $elementList.css("overflow-y", "scroll");
          $elementList.css("display", "inline-block");
          //Lower menu is all height size under first separator (under scroll bar) + separator size
          lowerMenuSize = contextMenuheight - maxScrollHeight + separatorSize;
          $elementList.css("max-height", maxHeight - lowerMenuSize);
          $menuList.css("max-height", maxHeight);
        } else {
          $elementList.css("overflow-x", "hidden");
          $elementList.css("overflow-y", "hidden");
          $elementList.css("display", "");
          $elementList.css("max-height", "");
          $menuList.css("max-height", "");
        }
        //Après tous les modifications de styles
        _.each($menuList.find("li .menuPopulationIdent"), (element: any) => {
          if (element.scrollWidth > element.clientWidth) {
            $(element).attr("title", $(element).text());
          }
        });
        this._prepareKeyboardNav();
        //---repositionner
        $posMenuList.position({
          my: "right top",
          at: "right bottom",
          of: this.$el.find(".phx-populations-menu")
        });
        //----
        if (typeof callback === "function") {
          callback(event);
        }
      };

      this.fetchingData = true;
      this.populationColl.setHabContext(this.getHabContext().copy());
      if (CWHABILITATION.canView("RES_POPCOLLAB.V")) {
        this.populationColl.fetch({
          success: (list: any): void => {
            onSucessFunction(list);
          },
          error: (): void => {
            this.$el.find("ul.phx-populations-menu-list .generated-list-elements-container").html("");
            this.fetchingData = false;

            //Show the list
            this.$el.find(".phx-populations-menu-list").show();
            this.isVisible = true;
          }
        });
      } else {
        onSucessFunction(this.populationColl);
      }
    }
  }

  generatePopulationListElements(list: { [key: string]: any }): string {
    let elements = "<li class='ui-menu-item phx-hover ui-corner-all'>";
    let itemsToShow: number = null;

    //Tots el collabs
    if (CWSTR.isBlank(this.model.get("ident")) && CWSTR.isBlank(this.model.get("code"))) {
      elements += "<span class='c-panneauMenu__tickIcon cw-icon cw-icon-16 cw-valider-icon'/></span>";
    }
    elements += "<a href='javascript:void(0)' tabindex='-1'  class='menuPopulationIdent'>" + i18n.t('populationsmenu.tous') + "</a></li>";
    //Elements retrieved by the fetch
    itemsToShow = this.showPopulationPlus ? list.length : Math.min(this.nbMaxPopEntete, list.length);
    for (let i = 0; i < itemsToShow; i++) {
      if (CWHABILITATION.canView("RES_POPCOLLAB.V")) {
        const model = list.models[i];
        const libelle = model.get("libelle");

        elements += "<li class='ui-menu-item phx-hover ui-corner-all'>";
        if (model.get("ident") === this.model.get("ident")) {
          elements += "<span class='c-panneauMenu__tickIcon cw-icon cw-icon-16 cw-valider-icon'/></span>";
        }
        elements += "<a href='javascript:void(0)' tabindex='-1' class='menuPopulationIdent cw-texte-reduit' data-value='" + model.get("ident") + "'>" + libelle + "</a></li>";
      }
    }
    if (list.length > this.nbMaxPopEntete && this.showPopulationPlus === false) {
      elements += "<li class='ui-menu-item phx-menu-link phx-hover ui-corner-all'><a href='javascript:void(0)' tabindex='-1' class='menuPopulationPlus'>... " +
        i18n.t('populationsmenu.plusPopulations') + "</a></li>";
    }
    this.showPopulationPlus = false;
    return elements;
  }

  _showPopulationPlus(event: JQuery.TriggeredEvent): void {
    this.showPopulationPlus = true;
    this._showList(event);
  }

  _openSelectorCollab(): void {
    if (CWSTR.isBlank(this.collabDialog)) {
      const dialog = new CWDialogPopupView({
        dialogClass: "c-cwDialogPopup cw-style cwHeader__transversal",
        view: CWPopupSelectCollabView,
        viewData: {
          parentView: this,
          appendTo: this.$appendTo
        }
      });

      dialog.setHeight("auto");
      dialog.setWidth(570);
      this.collabDialog = dialog;
    }
    if (!this.collabDialog.isOpen()) {
      const dialogModel = this.collabDialog.model;

      dialogModel.set("collab", null); // mise à null du collab car on ouvre la fenetre
      if (!CWSTR.isBlank(this.collabDialog.$el.parent())) {
        this.collabDialog.$el.parent().find(".cwDialog-buttons").hide();
      }
      dialogModel.trigger("dlg:open", (): void => {
        if (!CWSTR.isBlank(dialogModel.get("collab"))) {
          this._setCollaborateurActive(dialogModel.get("ident"), dialogModel.get("collab"));
        }
      });
    }
  }

  _setCollaborateurActive(ident: string, model: { [key: string]: any }): void {
    const selectedModel = new CWPopulationModel();

    this._clearTooltipStructure();
    this.$el.find(".phx-population-menu-header").html(model.get("libelle"));
    selectedModel.id = ident;
    selectedModel.set("ident", ident);
    selectedModel.set("code", model.get("matric"));
    selectedModel.set("type", "D");
    selectedModel.set("nature", "C");
    selectedModel.set("libelle", model.get("libelle"));
    selectedModel.set("desc", null);
    selectedModel.set("struct", null);
    this.populationColl.add(selectedModel);
    this.model = selectedModel.clone();
    objs.appRt.workflow.syncModel.trigger("change:population", selectedModel);
  }

  _changeActivePopulation(event: JQuery.TriggeredEvent): boolean {
    const ident = $(event.target).attr("data-value");

    // get selected population
    this.setPopulationActive(ident);
    objs.appRt.workflow.syncModel.trigger("change:population", this.model);
    return false;
  }

  changePopulationActiveById(ident: string): void {
    // avtive population
    this.setPopulationActive(ident);
    objs.appRt.workflow.syncModel.trigger("change:population", this.model);
  }

  _headerKeyboradNavigation(e: JQuery.TriggeredEvent): void {
    if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 40) {
      //Enter spacebar down
      this._showList(e, (e: JQuery.TriggeredEvent): void => {
        $(e.target).closest("div.phx-populations-menu").find("a").first().focus();
        e.preventDefault();
      });
    }
  }

  _prepareKeyboardNav(): void {
    const populationsListLinks = this.$el.find(".phx-populations-menu-list .generated-list-elements-container li a");

    //Prepare keyboard navigation for each population link
    _.each(populationsListLinks, (link: any): void => {
      $(link).keydown((e: JQuery.TriggeredEvent): void => {
        this._keyboardNav(e);
      });
    });
  }

  _keyboardNav(e: JQuery.TriggeredEvent): void {
    if (e.keyCode === 38) { // up
      const menuLink = $(e.target).closest("li");

      if (menuLink.prevAll().first().length === 0) {
        const parentMenu = menuLink.closest('div.phx-populations-menu');
        const lastLiElement = parentMenu.find(".phx-populations-menu-list").find("li:not(.ui-menu-divider)").last();

        //Focus last menuitem
        lastLiElement.find("a").focus();
      } else {
        let prevMenu = menuLink.prevAll().first();

        //Focus previous item
        prevMenu = this._discardSeparators(prevMenu, "UP");
        this._selectFocusable(prevMenu, "UP");
      }
    } else if (e.keyCode === 40) {
      const menuLink = $(e.target).closest("li");

      // down
      if (menuLink.nextAll().first().length === 0) {
        //Focus first item
        if (menuLink.closest(".generated-list-elements-container").length !== 0) {
          //The current link is a population link
          menuLink.closest(".phx-populations-menu-list").find("> li:not(.ui-menu-divider)").first().find("a").focus();
        } else {
          const parentMenu = menuLink.closest('div.phx-populations-menu');
          const lastLiElement = parentMenu.find(".phx-populations-menu-list").find("li:not(.ui-menu-divider)").first();

          //The current link is not a population
          //Focus first menuitem
          lastLiElement.find("a").focus();
        }
      } else {
        let nextMenu = menuLink.nextAll().first();

        //Focus next item
        nextMenu = this._discardSeparators(nextMenu, "DOWN");
        this._selectFocusable(nextMenu, "DOWN");
      }
    } else if (e.keyCode === 32) {
      // spacebar
      $(e.target).click();
    } else if (e.keyCode === 27) {// escape
      const parentMenu = $(e.target).closest('div.phx-populations-menu');

      parentMenu.find(".phx-population-menu-header").focus();
      //Simulate click in the menuBar in order to close drop/down menu
      parentMenu.find(".phx-population-menu-header").click();
    } else if (e.keyCode === 9) {// tab
      const ulMenu = $(e.target).closest(".phx-populations-menu-list");

      //If menu is visible close menu
      if (ulMenu.is(":visible")) {
        const parentMenu = $(e.target).closest('div.phx-populations-menu');

        parentMenu.find(".phx-population-menu-header").click();
      }
    }
  }

  _discardSeparators(prevMenu: JQuery<Element>, action: string): JQuery<Element> {
    if (action === "UP") {
      if (prevMenu.hasClass("ui-menu-divider")) {
        prevMenu = this._discardSeparators(prevMenu.prevAll().first(), action);
      }
    } else if (action === "DOWN") {
      if (prevMenu.hasClass("ui-menu-divider")) {
        prevMenu = this._discardSeparators(prevMenu.nextAll().first(), action);
      }
    }
    return prevMenu;
  }

  /*
   * Select the link in selectedMenu
   * selectedMenu can be a li containing a menu selector or a span containing all populations
   */
  _selectFocusable(selectedMenu: { [key: string]: any }, action: string): void {
    if (selectedMenu.hasClass("generated-list-elements-container")) {
      //Populations menu
      if (action) {
        if (action === "UP") {
          //Select Last element from populations menu
          selectedMenu.find("li").last().find("a").focus();
        } else if (action === "DOWN") {
          //Select first element from populations menu
          selectedMenu.find("li").first().find("a").focus();
        }
      }
    } else if (selectedMenu.hasClass("phx-menu-link") || selectedMenu.hasClass("ui-menu-item")) {
      //Links menu
      selectedMenu.find("a").first().focus();
    }
  }

  /**
   * updatePopulationStructureLibelle - Load and render structure libelle and
   * tooltip in population component
   *
   * @param  {type} populationModel description
   * @return {type}                 description
   */
  updatePopulationStructureLibelle(populationModel: { [key: string]: any }): void {
    const today = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(SYNC.getServerDate())).val;
    const structureData = {
      debsitu: today,
      finsitu: today,
      format: 1,
      itemcode: populationModel.get("code"),
      itemid: populationModel.get("code"),
      itemlib: "",
      structid: populationModel.get("struct"),
      structlib: "",
      racine: 1,
      codef: populationModel.get("struct"),
      code: populationModel.get("struct"),
      typeremdesc: 2
    };
    const objModel = new Backbone.Model({
      debsitu: structureData.debsitu,
      finsitu: structureData.finsitu,
      format: structureData.format,
      itemcode: structureData.itemcode,
      itemid: structureData.itemid,
      itemlib: structureData.itemlib,
      structid: structureData.structid,
      structlib: structureData.structlib
    });
    const structToPaint = {
      model: objModel,
      structCode: structureData.structid,
      structure: structureData.structlib,
      debsitu: structureData.debsitu,
      finsitu: structureData.finsitu
    };
    const view = new CWPlainStructuresView({// we create the View with the option table = false to adapt the html.(because is not a table)
      model: structToPaint,
      table: false,
      context: this._getContextSelectorChemin(structureData),
      plainText: true,
      populationColl: this.populationColl
    });

    view.render();
    this.$el.find(".phx-population-menu-header").html(view.el);
  }

  setPopulationActiveFromStructure(populationModel: { [key: string]: any }, callback?: () => void): void {
    this.updatePopulationStructureLibelle(populationModel);
    this.populationColl.add(populationModel);
    this.model = populationModel.clone();
    if (typeof callback === "function") {
      callback();
    }
  }

  setPopulationActiveFromCollab(populationModel: { [key: string]: any }, callback?: () => void): void {
    const collabModel = new CWCollaborateurModel();

    collabModel.id = populationModel.get("code");
    collabModel.setHabContext(this.getHabContext().copy());
    if (!CWSTR.isBlank(populationModel.habilitationOrigine)) {
      collabModel.updateHabContext({ "foncCour": populationModel.habilitationOrigine });
    }
    collabModel.params = {};
    collabModel.params.matric = populationModel.get("code");
    collabModel.fetch({
      url: Configuration.restRoot + "/rest/population/collaborateurs",
      success: (fresh: any): void => {
        if (fresh && fresh.get(0)) {
          let libelle = fresh.get(0).nom + " " + fresh.get(0).prenom + " (";

          // Default value is matric
          if (this.diversIdCollab.get("valeur") === "matric_aux") {
            libelle += fresh.get(0).matricaux;
          } else {
            libelle += fresh.get(0).matric;
          }
          libelle += ")";
          this.$el.find(".phx-population-menu-header").html(i18n.t('populationsmenu.tous'));
          populationModel.set("libelle", libelle);
          this.$el.find(".phx-population-menu-header").html(libelle);
          if (typeof callback === "function") {
            callback();
          }
        }
      }
    });
  }

  /**
   *
   * @param ident
   * @param defaultModel -> selectedDefaultPopulationData
   */
  setPopulationActive(ident?: string, defaultModel?: { [key: string]: any }, callback?: () => void): void {
    // set header
    if (!CWSTR.isBlank(ident) && String(ident) !== "0") {
      let activePopulation: CWPopulationModel = null;

      this._clearTooltipStructure();
      activePopulation = this.populationColl.get(ident);
      if (activePopulation) {
        if (activePopulation.get("nature") !== "S") {
          const libelle = activePopulation.get("libelle");

          this.$el.find(".phx-population-menu-header").html(i18n.t('populationsmenu.tous'));
          this.$el.find(".phx-population-menu-header").html(libelle);
        } else {
          this.updatePopulationStructureLibelle(activePopulation);
        }
        this.model.id = activePopulation.get("ident");
        this.model.set("ident", activePopulation.get("ident"));
        this.model.set("code", activePopulation.get("code"));
        this.model.set("type", activePopulation.get("type"));
        this.model.set("libelle", activePopulation.get("libelle"));
        this.model.set("nature", activePopulation.get("nature"));
        this.model.set("desc", activePopulation.get("desc"));
        this.model.set("struct", activePopulation.get("struct"));
        if (typeof callback === "function") {
          callback();
        }
      } else if (defaultModel) {
        if (!CWHABILITATION.canView("RES_POPCOLLAB.V")) {
          const model = new CWPopulationModel();

          this.populationColl.remove(this.populationColl.models[0]); //Remove previous element
          this.defaultModel = defaultModel;
          model.id = this.defaultModel.id;
          model.set("ident", this.defaultModel.ident);
          model.set("code", this.defaultModel.code);
          model.set("libelle", this.defaultModel.libelle);
          model.set("type", this.defaultModel.type);
          model.set("struct", this.defaultModel.struct);
          model.set("nature", this.defaultModel.nature);
          model.set("desc", this.defaultModel.desc);
          this.populationColl.add(model);
          this.setPopulationActive(ident, null, callback);
        } else {
          this.defaultModel = defaultModel;
          if (defaultModel.nature === "C" || defaultModel.nature === "S") {
            const populationModel = new CWPopulationModel();

            populationModel.id = defaultModel.ident;
            populationModel.set("ident", defaultModel.ident);
            populationModel.set("code", defaultModel.code);
            populationModel.set("libelle", defaultModel.libelle);
            populationModel.set("type", defaultModel.type);
            populationModel.set("struct", defaultModel.struct);
            populationModel.set("nature", defaultModel.nature);
            populationModel.set("desc", defaultModel.desc);
            populationModel.habilitationOrigine = defaultModel.habilitationOrigine;
            this.populationColl.add(populationModel);
            this.model = populationModel.clone();
            this.$el.find(".phx-population-menu-header").html(i18n.t('populationsmenu.tous'));
            if (defaultModel.nature === "S") {
              this.setPopulationActiveFromStructure(populationModel, callback);
            } else if (defaultModel.nature === "C") {
              this.setPopulationActiveFromCollab(populationModel, callback);
            }
          }
        }
      }
    } else {
      this.$el.find(".phx-population-menu-header").prop("title", "");
      this.$el.find(".phx-population-menu-header").tooltip({ content: "" });
      this.$el.find(".phx-population-menu-header").html(i18n.t('populationsmenu.tous'));
      this.model.set("ident", null);
      this.model.set("type", "D");
      this.model.set("libelle", i18n.t('populationsmenu.tous'));
      this.model.set("nature", null);
      this.model.set("desc", null);
      this.model.set("code", null);
      this.model.set("struct", null);
      this.model.set("structure", false);
      this.model.id = null;
      this.id = null;
    }
  }

  invalidatePopulation(invalidate: boolean): void {
    if (invalidate === true) {
      this.$el.find(".phx-menu").addClass("ui-phx-population-invalidee");
    } else {
      this.$el.find(".phx-menu").removeClass("ui-phx-population-invalidee");
    }
  }

  _checkInfobulle(): void {
    const $lpos = this.$el.find(".phx-population-menu-header");

    if ($lpos && $lpos.length > 0) {
      if ($lpos[0].clientWidth < $lpos[0].scrollWidth) {
        if (CWSTR.isBlank($lpos.attr("title"))) {
          $lpos.attr("title", $lpos.text());
        }
      } else {
        if (!CWSTR.isBlank($lpos.attr("title"))) {
          $lpos.attr("title", "");
        }
      }
    }
  }
}
