module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="parametragebresultats-detail-main">\n    <fieldset class="cw-fieldset parametragebresultats-detail">\n        <div class="phx-formTable">\n            <div class="phx-formErrors ui-state-error" style="margin-left: 230px"></div>\n            <div>\n                <div class="parametragebresultats-content">\n                    <div class="parametragebresultats-form-inputs">\n                        <div class="phx-details-header-text">\n                            <div class="cw-titre titreBrique"></div>\n                            <div class="infoComplementaire d-flex">\n                                <div class="infoCompBrique"></div>\n                                <div class="zoneBtn__activer"></div>\n                                <div class="zoneBtn__desactiver"></div>\n                            </div>\n                            </br>\n                        </div>\n\n                        <div class="form-row">\n                            <div class="form-group col-2">\n                                <label for="codebrique" class="cw-required">'+
((__t=(i18n.t('common:parametragebresultats.code_label')))==null?'':__t)+
'</label>\n                                <input type="text" class="codebrique form-control" value="" maxlength="15" />\n                            </div>\n                            <div class="form-group col-3">\n                                <label for="libelle" class="cw-required">'+
((__t=(i18n.t('common:parametragebresultats.titre_label')))==null?'':__t)+
'</label>\n                                <input type="text" class="libelle form-control" value="" maxlength="60" />\n                            </div>\n                            <div class="form-group col-2">\n                                <label>'+
((__t=(i18n.t('common:parametragebresultats.visibilite_label')))==null?'':__t)+
'</label>\n                                <div class="form-group cw-customControlGroup">\n                                    <div class="custom-control custom-checkbox">\n                                        <input type="checkbox" class="visibilite custom-control-input" name="customCheckbox" />\n                                        <label for="visibilite" class="custom-control-label" title="'+
((__t=(i18n.t('common:parametragebresultats.visibilite_tooltip')))==null?'':__t)+
'">'+
((__t=(i18n.t('common:parametragebresultats.publique_label')))==null?'':__t)+
'</label>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class="form-group col-5">\n                                <label title="'+
((__t=(i18n.t('common:parametragebresultats.date_tooltip')))==null?'':__t)+
'">'+
((__t=(i18n.t('common:parametragebresultats.date_label')))==null?'':__t)+
'</label>\n                                <div class="radioDateReference">\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </fieldset>\n    <div style="min-width : 780px;">\n        <span class="tableCompteurs"></span>\n    </div>\n    <div class="btnBar-container"></div>\n</div>';
}
return __p;
};
