import { CWActiviteModel } from 'src/uc/common/evenements/planifier/activite/models/cwActivite.model';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWTreeModel } from './cwTree.model';
import { CWTreeNodeColl } from './cwTreeNode.collection';
/**
 * Node Model of the Tree Component
 */
export class CWTreeNodeModel extends CWBaseModel {

  root: CWTreeModel;
  node: CWActiviteModel | CWTreeNodeModel;
  firstLevelModels: any;
  firstLevelNode: boolean;
  selectableNode: any;
  isChecked: boolean;
  checkedColl: any;
  coll: CWTreeNodeColl;
  parent: any;
  niveau: any;
  childrens: any;
  isCheckedInitOnlyRootNode: boolean;//Pour si le paramdiver "selActOuv" a la valeur "2"

  /**
   * Collection to fetch the child nodes of the current node
   */
  defaults(): { [key: string]: any } {
    return {
      expanded: false,
      hasChild: undefined,
      label: "",
      level: 0
    }
  }
}