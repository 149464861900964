import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWAbsenceModel } from 'common/evenements/gerer/gererrecapitulatifs/models/cwAbsence.model';
import { CWActiviteModel } from 'common/evenements/gerer/gererrecapitulatifs/models/cwActivite.model';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWContentRecapActivitesModel } from 'common/evenements/gerer/gererrecapitulatifs/models/cwContentRecapActivites.model';
import { CWDeleteActivityModel } from 'common/evenements/gerer/gererrecapitulatifs/models/cwDeleteActivity.model';
import { CWEtatFinalRecapActModel } from 'common/evenements/gerer/gererrecapitulatifs/models/cwEtatFinalRecapAct.model';
import { CWEvtRecapActSoumisWkf } from 'common/evenements/gerer/gererrecapitulatifs/models/cwEvtRecapActSoumisWkf';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWInfoCompRecap } from 'common/evenements/gerer/gererrecapitulatifs/models/cwInfoCompRecap';
import { CWModeRepresentationModel } from 'common/evenements/gerer/gererrecapitulatifs/models/cwModeRepresentation.model';
import { CWMSGS } from 'utils/cwMsgs';
import { CWPiecesAutorisees } from 'common/evenements/gerer/gererpiecesjointes/models/cwPiecesAutorisees';
import { CWPresencesColl } from 'common/evenements/gerer/gererrecapitulatifs/models/cwPresences.collection';
import { CWProfilsRecapActivite } from 'common/evenements/gerer/gererrecapitulatifs/models/cwProfilsRecapActivite';
import { CWReadOnlyModel } from 'core/models/cwReadOnly.model';
import { CWReadTypeEvenementCollection } from './cwReadTypeEvenement.collection';
import { CWRecapActiviteTypeEvtColl } from 'common/evenements/gerer/gererrecapitulatifs/models/cwRecapActiviteTypeEvt.collection';
import { CWRecapActModel } from 'common/evenements/gerer/gererrecapitulatifs/models/cwRecapAct.model';
import { CWRecapColl } from 'common/evenements/gerer/gererrecapitulatifs/models/cwRecap.collection';
import { CWSTR } from 'utils/cwStr';
import { CWTableColl } from 'common/evenements/gerer/gererrecapitulatifs/models/cwTable.collection';
import { CWTableModel } from 'common/evenements/gerer/gererrecapitulatifs/models/cwTable.model';
import { CWTransformActivityModel } from 'common/evenements/gerer/gererrecapitulatifs/models/cwTransformActivity.model';
import { CWTYPE } from 'tda/cwTda';
import { CWTypoDomCol } from 'common/typodom/models/cwTypoDom.collection';
import { CWWkfEvtColl } from '../models/cwWkfEvt.collection';
import { EVENEMENT } from 'utils/evenement.js';
import { forkJoin } from 'rxjs';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';
import { UTILS_PL } from 'utils/utils_pl.js';

interface ContextActHabilitation {
  HabilitationAcces?: string | {
    prevues?: string;
    realisees?: string;
  };
  HabilitationGestion?: string | {
    prevues?: string;
    realisees?: string;
  };
}

interface ContextCollab {
  matric_aux?: string;
  nom?: string;
  prenom?: string;
}

interface ContextActIdRecap {
  ActLibelleRecapitulatif?: string;
  RecapActCode?: string;
  RecapActDateDeb?: string;
  RecapActDateFin?: string;
  RecapActEta?: string;
  RecapEvenement?: string;
  RecapStatutCode?: string;
  RecapStatutLibelle?: string;
}

interface ContextActValeurEvt {
  ActCode?: string;
  ActCodeStatutWKF?: string;
  ActCommentaireWKF?: string;
  ActDateDebut?: string;
  ActDateFin?: string;
  ActDuree?: string;
  ActElementIdent?: string;
  ActEnSuppression?: boolean;
  ActEvenement?: string;
  ActHeureDebut?: string;
  ActHeureFin?: string;
  ActHierarchieIdent?: string;
  ActHorsPresApres?: string;
  ActHorsPresAvant?: string;
  ActIdRecapitulatif?: ContextActIdRecap;
  ActIdStructure?: string;
  ActInfoComplAffect?: string;
  ActLibelle?: string;
  ActListeInfoComplValeur?: string;
  ActModeSaisie?: string;
  ActModeSaisiePerioDebut?: string;
  ActModeSaisiePerioFin?: string;
  ActPourcentage?: string;
  ActTransformationAutorise?: string;
  ActTypeEvenement?: string;
  ActTypeSaisie?: string;
  ActUniteDebut?: string;
  ActUniteFin?: string;
}

interface ContextActValeurCreation {
  ActCode?: string;
  ActDateDebut?: string;
  ActDateFin?: string;
  ActHeureDebut?: string;
  ActHeureFin?: string;
  ActInfoComplAffect?: string;
  ActUniteDebut?: string;
  ActUniteFin?: string;
}

interface ContextGererActivite {
  ctxActionsPossibles?: Array<string>;
  ctxDateConsultee?: any;
  ctxDomainesActivites?: Array<string>;
  ctxEcran?: string;
  ctxGestionCollab?: ContextCollab;
  ctxGestionCollective?: boolean;
  ctxHabilitation?: ContextActHabilitation;
  ctxHistoriqueWKF?: boolean;
  ctxIdentifiantEvt?: string;
  ctxModeInitialisation?: string;
  ctxModeRepresentation?: string;
  ctxPieceJointe?: boolean;
  ctxTypeEvtGere?: any;
  ctxTypologieActivite?: any;
  ctxUtilisateur?: string;
  ctxUtilisation?: string;
  ctxValeursCreation?: ContextActValeurCreation;
  ctxValeursEvenement?: ContextActValeurEvt;
}

interface ContextHabAbsence {
  HabilitationAcces: string;
  HabilitationGestion: string;
  HabilitationValidation: string;
}

interface ContextValeurEvtAbs {
  AbsCodeStatutWKF: string;
  AbsCodeMotif: string;
  AbsDateDebut: any;
  AbsUniteDebut: string;
  AbsDateFin: any;
  AbsUniteFin: string;
  AbsHeureDuree: any;
  AbsHeureDebut: any;
  AbsHeureFin: any;
  AbsCommentaire: string;
}

interface ContextGererAbsence {
  ctxUtilisateur?: string;
  ctxHabilitation?: ContextHabAbsence;
  ctxEcran?: string;
  ctxGestionCollab?: ContextCollab;
  ctxActionsPossibles?: Array<any>;
  ctxGestionCollective?: boolean;
  ctxModeRepresentation?: string;
  ctxTypeEvenement?: string;
  ctxModeInitialisation?: string;
  ctxIdentifiantEvt?: string;
  ctxValeursEvenement?: ContextValeurEvtAbs;
  ctxValeursCreation?: object;
  ctxHistoriqueWKF?: boolean;
  ctxPieceJointe?: boolean;
  ctxRecapActivite?: boolean;
}

/**
 * Model Representing the UC work flow
 */
export class CWGererrecapitulatifsWorkflow extends CWReadOnlyModel {
  ABSENCE: string;
  ACTIVITE: string;
  COLLABORATEUR: string;
  contenuRecapActivites: CWContentRecapActivitesModel;
  dateBBDD: any;
  DAY: string;
  EMPTY: string;
  etatFinalModel: any;
  evenAlreadyDeleted: Array<any>;
  evtRecapActSoumisWkf: any;
  firstLoad: boolean;
  formModel: any;
  habG: any;
  habV: any;
  headerModel: any;
  HEURE_CENTIEME: string;
  HEURE_MINUTE: string;
  maxPiecesAbs: CWPiecesAutorisees;
  maxPiecesAct: CWPiecesAutorisees;
  MIXTE: string;
  mixteTableModel: any;
  mixteModel: any;
  modeRepresentationModel: CWModeRepresentationModel;
  MONO: string;
  monoTableModel: any;
  monoTypeModel: any;
  MONTH: string;
  presencesColl: any;
  PREVUE: string;
  profilsRecapActivite: CWProfilsRecapActivite;
  REALISEE: string;
  recapColl: CWRecapColl;
  recapTypeEvtColl: any;
  RESPONSABLE: string;
  tableMixteColl: any;
  tableMonoColl: any;
  typeEvt: string;
  Typologies: { [key: string]: any };
  valueToSelect: CWBaseModel;
  WEEK: string;
  public module: string;
  public $appendTo: JQuery; //pour les messages
  collTypo: CWTypoDomCol;
  isReadyCollTypo: boolean;
  mustCallSetUp: boolean;
  callbackSetUp: () => any;
  maxPiecesRecap: any;
  wkfEvtColl: CWWkfEvtColl;

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    const habActVisu: { [key: string]: any } = {};
    const habActGest: { [key: string]: any } = {};
    const habActFinal: { [key: string]: any } = {};

    //context utilisateur
    this.context = options.context;
    this.COLLABORATEUR = "Collaborateur";
    this.RESPONSABLE = "Responsable";
    //VUES
    this.HEURE_CENTIEME = "Heures_Centieme";
    this.HEURE_MINUTE = "Heures_Minutes";
    this.MONO = "MONO";
    this.MIXTE = "MIXTE";
    this.MONTH = "MONTH";
    this.WEEK = "WEEK";
    this.DAY = "DAY";
    this.ACTIVITE = "Activite";
    this.ABSENCE = "Absence";
    this.EMPTY = "Empty";
    this.headerModel = null;
    this.etatFinalModel = null;
    this.modeRepresentationModel = new CWModeRepresentationModel();
    this.contenuRecapActivites = new CWContentRecapActivitesModel();
    this.profilsRecapActivite = new CWProfilsRecapActivite();
    this.recapTypeEvtColl = null;
    this.recapColl = new CWRecapColl();
    this.firstLoad = true;
    this.monoTypeModel = null;
    this.mixteModel = null;
    this.monoTableModel = null;
    this.mixteTableModel = null;
    this.presencesColl = null;
    this.setHabContext(new CWHabilitationContext());
    this.valueToSelect = new CWBaseModel({
      value: null,
      datedeb: "99999999",
      datefin: "00000000"
    });
    this.Typologies = {};
    this.PREVUE = "P";
    this.REALISEE = "R";
    this.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
    if (this.context && this.context.ctxModeRepresentation === "pop-up") {
      this.$appendTo = null; //c'est un Volet et il a une valeur differente dans "z-index" et aussi, la zone est toute l'application
    }
    if (this.context.ctxUtilisateur === this.COLLABORATEUR) {
      if (CWHABILITATION.canView("COL_ACT_PREV.V")) {
        habActVisu.actprev = "COL_ACT_PREV.V";
      }
      if (CWHABILITATION.canView("COL_ACT_REAL.V")) {
        habActVisu.actreal = "COL_ACT_REAL.V";
      }
      if (!_.isEmpty(habActVisu)) {
        habActFinal.visualisation = habActVisu;
      }
      if (CWHABILITATION.canView("COL_ACT_PREV.G")) {
        habActGest.actprev = "COL_ACT_PREV.G";
      }
      if (CWHABILITATION.canView("COL_ACT_REAL.G")) {
        habActGest.actreal = "COL_ACT_REAL.G";
      }
      if (!_.isEmpty(habActGest)) {
        habActFinal.gestion = habActGest;
      }
    } else {
      if (CWHABILITATION.canView("RES_ACT_PREV.V")) {
        habActVisu.actprev = "RES_ACT_PREV.V";
      }
      if (CWHABILITATION.canView("RES_ACT_REAL.V")) {
        habActVisu.actreal = "RES_ACT_REAL.V";
      }
      if (!_.isEmpty(habActVisu)) {
        habActFinal.visualisation = habActVisu;
      }
      if (CWHABILITATION.canView("RES_ACT_PREV.G")) {
        habActGest.actprev = "RES_ACT_PREV.G";
      }
      if (CWHABILITATION.canView("RES_ACT_REAL.G")) {
        habActGest.actreal = "RES_ACT_REAL.G";
      }
      if (!_.isEmpty(habActGest)) {
        habActFinal.gestion = habActGest;
      }
    }
    if (!_.isEmpty(habActFinal)) {
      this.collTypo = new CWTypoDomCol({
        habContext: new CWHabilitationContext({
          onglet: (this.module ? this.module : ((this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? this.context.ctxEcran : "")),
          foncCour: habActFinal,
        }),
        initFetch: true,
      });
      this.listenTo(this.collTypo, 'fetch:success', this._listenerFetchCollTypo);
    } else {
      this._listenerFetchCollTypo();
    }
    this.wkfEvtColl = new CWWkfEvtColl();
    this.wkfEvtColl.setHabContext(new CWHabilitationContext({ onglet: (this.module ? this.module : ((this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? this.context.ctxEcran : "")), foncCour: "N" }));
  }

  setUp(context: { [key: string]: any }, callback: () => any): void {
    if (this.isReadyCollTypo) {
      this._setUp(context, callback);
    } else {
      this._storeCallbackSetup(context, callback);
    }
  }

  _listenerFetchCollTypo(): void {
    this.isReadyCollTypo = true;
    if (this.mustCallSetUp) {
      this._setUp(this.context, this.callbackSetUp);
    }
  }

  _storeCallbackSetup(context: { [key: string]: any }, callback: () => any): void {
    this.callbackSetUp = callback;
    this.mustCallSetUp = true;
    this.context = context;
  }

  _setUp(context: { [key: string]: any }, callback: () => void): void {
    const callbackFetchData = (): void => {
      this.firstLoad = false;
      if (!CWSTR.isBlank(this.context.ctxPeriodeRecap) && !CWSTR.isBlank(this.context.ctxTypeRecap)) {
        const recapHab = this._getRecapHab();
        const filtreApplique = ["E", "F", "M", "Y", "C", "G", "Z", "A", "D", "H", "I"];
        const lPromises: any = [];

        //Look for a valid recapitulatif
        //recapCollContext
        this.recapColl.setHabContext(recapHab);
        this.recapColl.setParametres(filtreApplique, this.context.ctxTypeRecap, this.context.ctxPeriodeRecap.datedeb, this.context.ctxPeriodeRecap.datefin, this.context.ctxCollab.matricule, this.context.ctxPieceJointe);
        lPromises.push(this.wkfEvtColl.fetch());
        lPromises.push(this.recapColl.fetch());
        forkJoin(lPromises).subscribe(() => {
          if (callback) {
            callback();
          }
          this.trigger("checkWkfRecap");
        },
          () => {
            //   Lorsque il y a des errors 
          });
      } else {
        this.wkfEvtColl.fetch({
          success: (): void => {
            if (callback) {
              callback();
            }
            this.trigger("checkWkfRecap");
          }
        });
      }
    };
    let fonc: string = null;

    this.context = context;
    fonc = this.context.ctxUtilisateur === "Responsable" ? "RES_RECAP." : "COL_RECAP.";
    this.$appendTo = (this.context && !CWSTR.isBlank(this.context.ctxEcran)) ? $("#" + this.context.ctxEcran) : null;
    // Declare events consumers
    this.listenTo(this.headerModel, "recapSelected", this.loadTableData);
    this.listenTo(this, "etatReevaluated", this._repaintTable);
    this.updateHabContext({ onglet: this.context.ctxEcran, foncCour: "", natGest: "" });
    this.monoTableModel.on("change:value", this._tableValueChange, this);
    if (this.firstLoad === true) {
      const evtRecapActSoumisWkf = new CWEvtRecapActSoumisWkf();
      const hrecap = this.getHabContext().copy();
      const hre = this.context.ctxHabilitation.HabilitationAcces;
      const lFuncLocal = (): void => {
        evtRecapActSoumisWkf.fetch({
          success: (fresh: any) => {
            this.firstLoad = false;
            if (callbackFetchData) {
              callbackFetchData();
            }
            this.evtRecapActSoumisWkf = fresh.get(0);
          }
        });
      };

      hrecap.update({ foncCour: hre, natGest: "" });
      evtRecapActSoumisWkf.setHabContext(new CWHabilitationContext({
        onglet: this.context.ctxEcran,
        foncCour: "N",
        natGest: ""
      }));
      if (this.context.ctxPieceJointe === true) {//avant this.paramPjointe
        const callsMaxPieces = [];

        this.maxPiecesAct = new CWPiecesAutorisees({ id: "PJEVTACT" });
        this.maxPiecesAct.setHabContext(hrecap);
        this.maxPiecesAbs = new CWPiecesAutorisees({ id: "PJEVTABS" });
        this.maxPiecesAbs.setHabContext(hrecap);
        this.maxPiecesRecap = new CWPiecesAutorisees({ id: "PJEVTRECAP" });
        this.maxPiecesRecap.setHabContext(hrecap);
        callsMaxPieces.push(this.maxPiecesAct.fetch());
        callsMaxPieces.push(this.maxPiecesAbs.fetch());
        callsMaxPieces.push(this.maxPiecesRecap.fetch());
        forkJoin(callsMaxPieces).subscribe((): void => {//succes   
          lFuncLocal();
        },
          (): void => { //error
            //nothing
          }
        );
      } else {
        lFuncLocal();
      }
    } else {
      if (callbackFetchData) {
        callbackFetchData();
      }
    }
    this.habG = new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: fonc + "G",
      natGest: "M"
    });
    this.habV = new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: fonc + "V",
      natGest: ""
    });
    this.habContext = this.habV;
    this.set("ready", true);
  }

  _tableValueChange(model: { [key: string]: any }): void {
    const value = model.get("value");

    // Update Btn Bar and Form
    if (CWSTR.isBlank(value) || value.level < 4 || (value.level === 4 && value.type === this.EMPTY)) {
      //Model is not activite model
      this.formModel.set("value", null);
    } else {
      this.formModel.set("value", value);
      let position = _.indexOf(this.monoTableModel.coll.models, value);
      if (position > 0) {
        position--;
      }
      this.valueToSelect.set("value", this.monoTableModel.coll.at(position));
      this.valueToSelect.set("datedeb", value.get("datedeb"));
      this.valueToSelect.set("datefin", value.get("datefin"));
    }
  }

  /**
   * Reload Header Model, when a recapitulatif has been selected, or when we have to reevaluate etat of recapitulatif
   * after an action
   */
  _updateHeaderModel(code: string, model: { [key: string]: any }, isReevaluating?: boolean, callback?: () => void): void {
    let hab: CWHabilitationContext = null;

    if (model === null) { //model to obtain profils and collaborateur from
      model = this.headerModel.get("value");
    }
    if (this.headerModel.get("value") === null) {
      this.headerModel.set("value", new CWRecapActModel());
    }
    this.headerModel.get("value").setHabContext(this.habV.copy());
    if (this.headerModel.get("infocomp") === null) {
      this.headerModel.set("infocomp", new CWInfoCompRecap());
    }
    this.headerModel.get("infocomp").setHabContext(this.habV);
    this.headerModel.get("value").set("id", code);
    this.headerModel.get("value").fetch({
      success: (headerModel: { [key: string]: any }) => {
        if (!CWSTR.isBlank(headerModel.get("code"))) {
          this.headerModel.get("infocomp").id = headerModel.get("code");
          this.headerModel.get("infocomp").fetch({
            success: () => {
              this.etatFinalModel = new CWEtatFinalRecapActModel();
              this.etatFinalModel.id = headerModel.get("code");
              if (this.context.ctxUtilisateur === "Responsable" && CWHABILITATION.canView("RES_VAL_RECAP.G")) {
                const hab = this.habG.clone();

                hab.set("foncCour", "RES_VAL_RECAP.G");
                this.etatFinalModel.setHabContext(hab);
                this.etatFinalModel.fetch({
                  success: () => {
                    this.headerModel.etatFinalModel = this.etatFinalModel;
                    if (isReevaluating === true) { //We are reevaluating etat
                      this.trigger("etatReevaluated", callback);
                    } else { //A new recap has been selected
                      //Customer 152336 and Customer 164855
                      if (this.etatFinalModel.get("valideur") === false) {
                        this.headerModel.trigger("modeModificationSelected");
                      }
                      this.headerModel.trigger("recapSelected");
                    }
                  }
                });
              } else {
                if (isReevaluating === true) { //We are reevaluating etat
                  this.trigger("etatReevaluated", callback);
                } else { //A new recap has been selected
                  this.headerModel.trigger("recapSelected");
                }
              }
            }
          });
        }
      }
    });
    //Get profils
    hab = this.habV.clone();
    if (this.context.ctxUtilisateur === "Responsable") {
      hab = this.habG.clone();
    }
    this.profilsRecapActivite.setHabContext(hab);
    this.profilsRecapActivite.code = code;

    this.profilsRecapActivite.fetch({
      success: () => {
        this.headerModel.trigger('updateProfils');
      }
    });
  }

  /**
   * When new recap has been selected we load recap data.
   */
  loadTableData(): void {
    const recap = this.headerModel.get("value");
    const lbReloadTypo = (_.isEmpty(this.Typologies) ? true : (CWSTR.isBlank(this.recapTypeEvtColl?.idRecap) ? true : ((recap && !CWSTR.isBlank(recap.get("recapitulatif")) && recap.get("recapitulatif") !== this.recapTypeEvtColl?.idRecap) ? true : false)));
    //Dans "this.recapTypeEvtColl?.idRecap" aura l'antérieur "id". Si l'il est le même valeur, on ne fera pas une nouvelle pétition

    if (CWSTR.isBlank(recap)) { //Null hader model,, because recap has been deleted
      this.trigger("tableDataLoaded");
    } else {
      const lPromises: any = [];

      //Get presences
      this.presencesColl = new CWPresencesColl();
      this.presencesColl.matricule = this.context.ctxCollab.matricule;
      this.presencesColl.datedeb = recap.get("datedeb");
      this.presencesColl.datefin = recap.get("datefin");
      this.presencesColl.setHabContext(this._getpresencesCollHab());
      //Get type evenements defined for Recap
      lPromises.push(this.presencesColl.fetch());
      this.recapTypeEvtColl = new CWRecapActiviteTypeEvtColl({ idRecap: recap.get("recapitulatif") });
      this.recapTypeEvtColl.setHabContext(this._getrecapTypeEvtCollHab());
      //Get representation MODE (MONO or MIXTE)
      lPromises.push(this.recapTypeEvtColl.fetch());
      this.modeRepresentationModel.setHabContext(this._getrepresentationModelHab());
      this.modeRepresentationModel.code = recap.get("code");
      lPromises.push(this.modeRepresentationModel.fetch());
      if (lbReloadTypo) {// optimisation
        // type de evenement
        this.Typologies = {};//On devra nettoyer l'information des Typologies
        this._getTypeEvtF(lPromises);
      }
      forkJoin(lPromises).subscribe(() => {
        //Get table data content        
        this._getContenuRecap();
      },
        () => {
          //   Lorsque il y a des errors , par exemple on peut utiliser  this._logout();
        });
    }
  }

  _getTypeEvtF(aPromises: Array<any>): CWReadTypeEvenementCollection {
    let lRtn = null;

    if (this.collTypo && this.collTypo.listVal && this.collTypo.listVal.visualisation.length > 0) {
      const foncCour: { [key: string]: any } = {};
      const lOnglet = ((this.context && this.context.ctxEcran) ? this.context.ctxEcran : (this.module ? this.module : ""));
      const typeEvt = new CWReadTypeEvenementCollection([], { idRecapitulatif: this.recapTypeEvtColl?.idRecap });

      if (this.get("utilisateur") === this.COLLABORATEUR || this.context.ctxUtilisateur === this.COLLABORATEUR) {
        if (CWHABILITATION.canView("COL_ACT_PREV.V")) {
          foncCour.actprev = "COL_ACT_PREV.V";
        }
        if (CWHABILITATION.canView("COL_ACT_REAL.V")) {
          foncCour.actreal = "COL_ACT_REAL.V";
        }
      } else {
        if (CWHABILITATION.canView("RES_ACT_PREV.V")) {
          foncCour.actprev = "RES_ACT_PREV.V";
        }
        if (CWHABILITATION.canView("RES_ACT_REAL.V")) {
          foncCour.actreal = "RES_ACT_REAL.V";
        }
      }
      typeEvt.setHabContext(new CWHabilitationContext({
        onglet: lOnglet,
        foncCour: foncCour,
        natGest: ""
      }));
      aPromises.push(typeEvt.fetch({
        success: (fresh: Backbone.Collection) => {
          if (fresh) {
            for (let j = 0; j < fresh.length; j++) {
              const lType: Backbone.Model = fresh.at(j);

              (lType as any).code = lType.id;
              this.Typologies[lType.id] = lType;
            }
          }
        }
      }));
      lRtn = typeEvt;
    }
    return lRtn;
  }

  _generateTypologieObject(fresh: { [key: string]: any }): any {
    const obj: { code?: string; typeevt?: Array<string> } = {};
    obj.code = fresh.typologie;
    obj.typeevt = [];
    if (fresh.get("actprev") === true) {
      obj.typeevt.push(this.PREVUE);
    }
    if (fresh.get("actreal") === true) {
      obj.typeevt.push(this.REALISEE);
    }

    return obj;
  }

  /**
   * Calls the WS that gets activites and absence data for the recapitulatif,
   * and generates data for the recap and pants the table
   */
  _getContenuRecap(reevaluate?: boolean, callback?: () => void, maintainTableState?: any): void {
    const recap = this.headerModel.get("value");
    const representation = this.modeRepresentationModel.get("representation");

    //unselect current selected row
    if (representation === this.MONO) {
      const tableModel = this.monoTableModel;
      //When a new recap has been seected, empty current selected value for table monotype
      if (!CWSTR.isBlank(tableModel.get("value"))) {
        tableModel.set("value", null);
      }
    }
    const contenuRecapActivitesHAB = this._getcontenuRecapHab();
    this.contenuRecapActivites.setHabContext(contenuRecapActivitesHAB);
    this.contenuRecapActivites.code = recap.get("code");
    this.contenuRecapActivites.params = { "periode_debut": recap.get("datedeb"), "periode_fin": recap.get("datefin") };
    this.contenuRecapActivites.fetch({
      success: () => {
        if (reevaluate === true) { //up
          this._updateHeaderModel(recap.get("code"), null, true, callback);
        } else {
          this._repaintTable(callback, maintainTableState);
        }
      }
    });
  }

  /**
   * Repaint all the table headerModel
   */
  _repaintTable(callback: () => void, maintainTableState: any): void {
    const recap = this.headerModel.get("value");
    const representation = this.modeRepresentationModel.get("representation");
    if (representation === this.MONO) {
      this._generateTableColl(recap, representation, maintainTableState);
    } else if (representation === this.MIXTE) {
      this._generateTableColl(recap, representation, maintainTableState);
    }
    this.formModel.set("value", null);
    this.trigger("tableDataLoaded", callback);
  }

  _getcontenuRecapHab(): CWHabilitationContext {
    const hr = this.getHabContext().copy();
    const hactprev2 = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_ACT_PREV.V" : "RES_ACT_PREV.V";
    const hactreal2 = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_ACT_REAL.V" : "RES_ACT_REAL.V";
    const habsence2 = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_ABS.V" : "RES_ABS.V";
    const hrecap = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_RECAP.V" : "RES_RECAP.V";
    const foncHabilitation2 = { "actreal": hactreal2, "actprev": hactprev2, "absence": habsence2, "recap": hrecap };

    hr.update({ foncCour: foncHabilitation2, natGest: "" });
    return hr;
  }

  _getrepresentationModelHab(): CWHabilitationContext {
    const hr = this.getHabContext().copy();
    const hactprev = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_ACT_PREV.V" : "RES_ACT_PREV.V";
    const hactreal = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_ACT_REAL.V" : "RES_ACT_REAL.V";
    const hrecap = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_RECAP.V" : "RES_RECAP.V";
    const foncHabilitation = { "actprev": hactprev, "actreal": hactreal, "recap": hrecap };

    hr.update({ foncCour: foncHabilitation, natGest: "" });
    return hr;
  }

  _getRecapHab(): CWHabilitationContext {
    const hrecap = this.getHabContext().copy();
    const hre = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_RECAP.V" : "RES_RECAP.V";
    const habilitation = { "acces": (this.context?.ctxEcran === "valevt" ? "RES_VAL_RECAP.G" : hre) };

    hrecap.update({ foncCour: habilitation, natGest: "" });
    return hrecap;
  }

  _getrecapTypeEvtCollHab(): CWHabilitationContext {
    const hrecap = this.getHabContext().copy();
    const hre = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_RECAP.V" : "RES_RECAP.V";

    hrecap.update({ foncCour: hre, natGest: "" });
    return hrecap;
  }

  _getpresencesCollHab(): CWHabilitationContext {
    const hrecap = this.getHabContext().copy();
    const hre = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_RECAP.V" : "RES_RECAP.V";

    hrecap.update({ foncCour: hre, natGest: "" });
    return hrecap;
  }

  _selectNewRow(model: { [key: string]: any }): void {
    const representation = this.modeRepresentationModel.get("representation");
    const tableModel = representation === this.MONO ? this.monoTableModel : this.mixteTableModel;

    if (representation === this.MONO) {
      const modelInColl = tableModel.coll.get(model.get("datedeb") + "," + model.get("code"));
      if (!CWSTR.isBlank(modelInColl)) {
        modelInColl.trigger("row:click", modelInColl);
      }
    } else if (representation === this.MIXTE) {
      //Look for the activity created
      const modelInColl = _.find(tableModel.coll.models, (tableRow: any) => {
        let evenem = null;
        if (model.get("typeevenement") === "P") {
          evenem = tableRow.get("prevueColumn");
        } else {
          evenem = tableRow.get("realiseeColumn");
        }
        return (!CWSTR.isBlank(evenem) && evenem.type === this.ACTIVITE && evenem.get("code") === model.get("code"));
      });
      if (!CWSTR.isBlank(modelInColl)) {
        modelInColl.trigger("row:click", modelInColl);
      }
    }
  }

  _resetSelectData(): void {
    this.valueToSelect.set("value", null);
    this.valueToSelect.set("datedeb", "99999999");
    this.valueToSelect.set("datefin", "00000000");
  }

  /**Reverting a creation action from monotype view
   **/
  _activiteReverted(model: { [key: string]: any }, initializationMode: string): void {
    //If we revert a ajout
    if (initializationMode === "Ajout") {
      //Reset form in order to show data of the current row selected.
      this.formModel.trigger("change:value");
    }
  }

  _activiteChanged(model: { [key: string]: any }, action: string): void {
    let callback = (): void => {
      this._resetSelectData();
      this._selectNewRow(model);
      this.trigger("recapUpdated");
    };
    const recap = this.headerModel.get("value");
    const etat = recap.get("etat");
    const representation = this.modeRepresentationModel.get("representation");
    const tableModel = representation === this.MONO ? this.monoTableModel : this.mixteTableModel;

    const datedebMin = this.valueToSelect.get("datedeb") < model.get("datedeb") ? this.valueToSelect.get("datedeb") : model.get("datedeb");
    const datefinMax = this.valueToSelect.get("datefin") > model.get("datefin") ? this.valueToSelect.get("datefin") : model.get("datefin");
    const datesModel = new CWBaseModel({
      datedeb: datedebMin,
      datefin: datefinMax
    });

    switch (action) {
      case "create":
        this._refreshDataByWS(datesModel, (): void => {
          if (this._checkReevaluerEtatAfterInsert(etat) === true) {
            // Reevaluate recap and repaint table
            this._updateHeaderModel(recap.get("code"), null, true, callback);
          } else {
            this._repaintTable(callback, true);
          }
        });
        break;
      case "transformation":
        this._refreshDataByWS(datesModel, (): void => {
          if (this._checkReevaluerEtatAfterInsert(etat) === true) {
            // Reevaluate recap and repaint table
            this._updateHeaderModel(recap.get("code"), null, true, callback);
          } else {
            this._repaintTable(callback, true);
          }
        });
        break;
      case "update":
        this._refreshDataByWS(datesModel, (): void => {
          this._repaintTable(callback, true);
        });
        break;
      case "delete":
        callback = (): void => {
          tableModel.selectRow(this.valueToSelect.get("value"));
          this._resetSelectData();
          this.trigger("recapUpdated");
        };
        this._refreshDataByWS(datesModel, (): void => {
          this._repaintTable(callback, true);
        });
        break;
      default:
        break;
    }
  }

  _checkReevaluerEtatAfterInsert(etat: string): boolean {
    return (etat === "D" || etat === "Y" || etat === "Z");
  }

  /**
   * Refresh data after activity modification, creation or deletion
   * in order of painting it in the table
   */
  _refreshData(delModel: { [key: string]: any }, addModel: { [key: string]: any }, callback: () => void): any {
    let contenu = this.contenuRecapActivites.get("activites");
    if (delModel !== null) {
      contenu = _.filter(contenu, (item: { [key: string]: any }) => {
        return item.code === delModel.get("code");
      });
    }
    if (addModel !== null && addModel.get("recapitulatif").code === this.headerModel.get("value").get("code")) {
      contenu.push(addModel.attributes);
    }
    if (callback) {
      callback();
    }
  }

  /**
   * Refresh data after activity modification, creation or deletion
   * in order of painting it in the table
   * calling a WS to return new data
   */
  _refreshDataByWS(model: { [key: string]: any }, callback: () => void): void {
    const deb = model.get("datedeb");
    const fin = model.get("datefin");
    //Delete activites for affected period
    let deleteContent: Array<{ [key: string]: any }> = [];
    _.each(this.contenuRecapActivites.get("activites"), (item: { [key: string]: any }) => {
      if (!(item.datedeb <= fin && item.datefin >= deb)) {
        deleteContent.push(item);
      }
    });
    this.contenuRecapActivites.set("activites", deleteContent);
    //Delete absences for affected period
    deleteContent = [];
    _.each(this.contenuRecapActivites.get("absences"), (item: { [key: string]: any }) => {
      if (!(item.datedeb <= fin && item.datefin >= deb)) {
        deleteContent.push(item);
      }
    });
    this.contenuRecapActivites.set("absences", deleteContent);
    const newContent = new CWContentRecapActivitesModel();
    newContent.setHabContext(this._getcontenuRecapHab());
    newContent.code = this.headerModel.get("value").get("code");
    newContent.params = { "periode_debut": deb, "periode_fin": fin };
    newContent.fetch({
      success: (fresh: { [key: string]: any }) => {
        _.each(fresh.get("activites"), (item: { [key: string]: any }) => {
          this.contenuRecapActivites.get("activites").push(item);
        });
        _.each(fresh.get("absences"), (item: { [key: string]: any }) => {
          this.contenuRecapActivites.get("absences").push(item);
        });
        if (callback) {
          callback();
        }
      }
    });
  }

  _ajoutActivityMonoType(buttonId: string): void {

    if (buttonId !== "ajouter") {
      // Don't manage ajouter button, only manage when a typologye is selected
      const typologie = buttonId.split(",")[0];
      const startDate = buttonId.split(",")[1];
      const endDate = buttonId.split(",")[2];
      this.trigger("initierSaisieActMonoType", typologie, startDate, endDate);
    }
  }

  _ajoutActivityMixteType(buttonId: string | any): void {

    if (buttonId !== "ajouter") {
      // Don't manage ajouter button, only manage when a typologye is selected
      const typologie = buttonId.split(",")[0];
      const startDate = buttonId.split(",")[1];
      const endDate = buttonId.split(",")[2];
      const typeevenement = buttonId.split(",")[3];
      this.trigger("initierSaisieActMixteType", typologie, startDate, endDate, typeevenement);
    }
  }

  _supprimerMonoTypeAction(buttonId: string): void {
    switch (buttonId) {
      case "delete": //SUpprimer action
        this._supprimerPrevuesAction("deleteP");
        break;
      default:
        break;
    }
  }

  _getDayIdByEvenementID(EvModel: { [key: string]: any }): string {
    const debText = EvModel.id.split(",")[0];
    const debDate = CWTYPE.DATE.strToDate(debText);
    const year = debDate.getFullYear();
    const month = debDate.getMonth();
    const week = $.datepicker.iso8601Week(debDate);
    const id = year + "," + month + "," + week + "," + debText;
    return id;
  }

  _supprimerPrevuesAction(buttonId: string): void {
    const recap = this.headerModel.get("value");
    const etat = recap.get("etat");
    const callback = (): void => {
      const tableModel = this.modeRepresentationModel.get("representation") === this.MONO ? this.monoTableModel : this.mixteTableModel;
      const debText = this.valueToSelect.get("datedeb");
      if (!CWSTR.isBlank(debText)) {
        // If an activity is selected we have a date to select
        const debDate = CWTYPE.DATE.strToDate(debText);
        const year = debDate.getFullYear();
        const month = debDate.getMonth();
        const week = $.datepicker.iso8601Week(debDate);
        const id = year + "," + month + "," + week + "," + debText;
        const modelToSelect = tableModel.coll.get(id);
        if (!CWSTR.isBlank(modelToSelect)) {
          modelToSelect.trigger("row:click", modelToSelect);
        }
      }
    };
    switch (buttonId) {
      case "deleteP":
        // SUpprimer action
        this._launchDeleteProcess("P", () => {
          // Reload the table to show new data
          this._refreshDataByWS(this.valueToSelect, () => {
            if (etat === "D" || etat === "Y" || etat === "Z") {
              // Reload etat data to reevaluate etat and reload table data
              this._updateHeaderModel(recap.get("code"), null, true, callback);
            } else {
              this._repaintTable(callback, true);
            }
          });
        });
        break;
      default:
        break;
    }
  }

  _getJournee(dateBBDD: string): { [key: string]: any } {
    return this.presencesColl.findWhere({ date: dateBBDD });
  }

  _supprimerDeclaresAction(buttonId: string): void {
    const recap = this.headerModel.get("value");
    const etat = recap.get("etat");
    const callback = (): void => {
      const tableModel = this.modeRepresentationModel.get("representation") === this.MONO ? this.monoTableModel : this.mixteTableModel;
      const debText = this.valueToSelect.get("datedeb");
      const debDate = CWTYPE.DATE.strToDate(debText);
      const year = debDate.getFullYear();
      const month = debDate.getMonth();
      const week = $.datepicker.iso8601Week(debDate);
      const id = year + "," + month + "," + week + "," + debText;
      const modelToSelect = tableModel.coll.get(id);
      if (!CWSTR.isBlank(modelToSelect)) {
        modelToSelect.trigger("row:click", modelToSelect);
      }
    };
    switch (buttonId) {
      case "deleteR": //SUpprimer action
        this._launchDeleteProcess("D", (): void => {
          this._refreshDataByWS(this.valueToSelect, (): void => {
            if (etat === "D" || etat === "Y" || etat === "Z") {
              //Reload etat data to reevaluate etat and reload table data
              this._updateHeaderModel(recap.get("code"), null, true, callback);
            } else {
              this._repaintTable(callback, true);
            }
          });
        });
        break;
      default:
        break;
    }
  }

  _transformAction(startDate: any, endDate: any, code: string): void {
    const recap = this.headerModel.get("value");
    const etat = recap.get("etat");

    const callback = (): void => {
      // In mixte view, select the new created activy after creation
      // If selected row is still in the coll, select it
      if (!CWSTR.isBlank(this.valueToSelect.get("value"))) {
        // Select month day or week
        this._selectNewRow(this.valueToSelect.get("value"));
        this._resetSelectData();
      }
    };

    this._launchTransformProcess("P", () => {
      //Reload the table to show new data
      this._refreshDataByWS(this.valueToSelect, () => {
        if (etat === "D" || etat === "Y" || etat === "Z") {
          //Reload etat data to reevaluate etat and reload table data
          this._updateHeaderModel(recap.get("code"), null, true, callback);
        } else {
          //Only repaint table if a transformation has been done correctly (without error)
          if (!CWSTR.isBlank(this.valueToSelect.get("value"))) {
            this._repaintTable(callback, true);
          }
        }
      });
    }, startDate, endDate, code);
  }

  _launchDeleteProcess(type: string, callback: () => void): void {
    this.evenAlreadyDeleted = [];
    this._resetSelectData();
    // items will be treated sequentially
    CWMSGS.showConfirmAdapter(i18n.t('common:delconfirm'), (result: string) => {
      if (result === "Y") {
        const callbackNew = (): void => {
          this.trigger("recapUpdated");
          if (callback) {
            callback();
          }
        };
        const messageConfirm = this._validateSupression(type);
        if (CWSTR.isBlank(messageConfirm)) {
          this._executerItemDelete(type, 0, callbackNew);
        } else {
          CWMSGS.showConfirmAdapter(messageConfirm, (result: string) => {
            if (result === "Y") {
              this._executerItemDelete(type, 0, callbackNew);
            }
          }, null, null, null, this.$appendTo);
        }
      }
    }, null, null, null, this.$appendTo);
  }

  _launchTransformProcess(type: string, callback: () => void, startDate: any, endDate: any, code: string): void {
    this.evenAlreadyDeleted = [];
    this._resetSelectData();
    // items will be treated sequentially

    const callbackNew = (): void => {
      this.trigger("recapUpdated");
      if (callback) {
        callback();
      }
    };

    this._executerItemTransform(type, 0, callbackNew, startDate, endDate, code);
  }

  /**
   * Execute the action in a activity and continues the recursion if there are more to treat.
   */
  _executerItemDelete(type: string, index: number, callback: () => void): void {
    const representation = this.modeRepresentationModel.get("representation");
    const tableColl = representation === this.MONO ? this.tableMonoColl : this.tableMixteColl;

    if (index < tableColl.models.length) {
      const rowModel = tableColl.models[index];
      if (rowModel.level === 4) {
        const action = type === "P" ? rowModel.evPSuppr : rowModel.evDSuppr;
        const activity = type === "P" ? rowModel.get("prevueColumn") : rowModel.get("realiseeColumn");
        // Treat a collaborator if it has been selected in the collection, else we continue the process
        if (!CWSTR.isBlank(activity) && !CWSTR.isBlank(action) && action === true && this._activityAlreadyDeleted(activity.get("code")) === false) {
          if (activity.get("datedeb") < this.valueToSelect.get("datedeb")) {
            this.valueToSelect.set("datedeb", activity.get("datedeb"));
            let position = _.indexOf(tableColl.models, rowModel);
            if (position > 0) {
              position--;
            }
            this.valueToSelect.set("value", tableColl.at(position));
          }
          if (activity.get("datefin") > this.valueToSelect.get("datefin")) {
            this.valueToSelect.set("datefin", activity.get("datefin"));
          }
          const itemVal = this._buildModelToVal(activity, type, "delete");
          const originalOptions = {
            success: (): void => {
              this.evenAlreadyDeleted.push(activity.get("code"));
              index++;
              this._executerItemDelete(type, index, callback);
            },
            error: (model: any, xhf: any): void => {
              this._manageError(model, originalOptions, xhf, () => {
                index++;
                this._executerItemDelete(type, index, callback);
              }, "DELETE");
            },
            wait: true
          };
          itemVal.destroy(originalOptions); //end save
        } else {
          index++;
          this._executerItemDelete(type, index, callback);
        }
      } else {
        index++;
        this._executerItemDelete(type, index, callback);
      }
    } else { //Process finished
      if (callback) {
        callback();
      }
    }
  }

  /**
   * Execute the action in a activity and continues the recursion if there are more to treat.
   */
  _executerItemTransform(type: string, index: number, callback: () => void, startDate: any, endDate: any, code: string): void {
    const tableColl = this.tableMixteColl;

    if (index < tableColl.models.length) {
      const rowModel = tableColl.models[index];
      if (rowModel.level === 4) {
        const activity = rowModel.get("prevueColumn");
        // Treat a collaborator if it has been selected in the collection, else we continue the process
        if (!CWSTR.isBlank(activity) && activity.type === this.ACTIVITE && activity.currentDate >= startDate &&
          activity.currentDate <= endDate && this._recapCanTransformAct(activity, false) && (CWSTR.isBlank(code) || code === activity.get("code")) &&
          this._activityAlreadyDeleted(activity.get("code")) === false) {
          const itemVal = this._buildModelToVal(activity, type, "transform");
          const originalOptions = {
            success: (freshItem: { [key: string]: any }): void => {
              this.evenAlreadyDeleted.push(activity.get("code"));
              if (freshItem.get("datedeb") < this.valueToSelect.get("datedeb")) {
                this.valueToSelect.set("datedeb", freshItem.get("datedeb"));
                this.valueToSelect.set("value", freshItem);
              }
              if (freshItem.get("datefin") > this.valueToSelect.get("datefin")) {
                this.valueToSelect.set("datefin", freshItem.get("datefin"));
              }
              index++;
              this._executerItemTransform(type, index, callback, startDate, endDate, code);
            },
            error: (model: any, xhf: any): void => {
              this._manageError(model, originalOptions, xhf, () => {
                index++;
                this._executerItemTransform(type, index, callback, startDate, endDate, code);
              }, "TRANSFORM");
            }
          };
          itemVal.fetch(originalOptions); //end save
        } else {
          index++;
          this._executerItemTransform(type, index, callback, startDate, endDate, code);
        }
      } else {
        index++;
        this._executerItemTransform(type, index, callback, startDate, endDate, code);
      }
    } else { //Process finished
      if (callback) {
        callback();
      }
    }
  }

  _manageError(model: { [key: string]: any }, options: { [key: string]: any }, xhr: any, callback: () => void, action: string): void {
    let customMsg: { [key: string]: any } = {};
    const status = xhr.status;

    try {
      if (!CWSTR.isBlank(xhr.oldResponseText)) {
        customMsg = JSON.parse(xhr.oldResponseText);
      } else {
        customMsg = JSON.parse(xhr.responseText);
      }
    } catch (error) {
      // ignore
    }

    if (status === 406 || status === 500 || xhr.oldStatus === 406 || xhr.oldStatus === 500) {
      if (!CWSTR.isBlank(customMsg.titre) && !CWSTR.isBlank(customMsg.message)) {
        switch (customMsg.titre) {
          case "i18n_error":
            xhr.omit = true;
            CWMSGS.showError(customMsg.message, null, this.$appendTo);
            if (callback) {
              callback();
            }
            break;
          case "i18n_confirmation":
          case "i18n_question":
          case "i18n_alert":
          case "i18n_information":
            xhr.omit = true;
            // notification message returned by server that needs processing...
            CWMSGS.showNotification(customMsg, (result: string) => {
              switch (result) {
                case "ABORT":
                  if (callback) {
                    callback();
                  }
                  break;
                default:
                  options.url = Configuration.restRoot + "/rest/" + result;
                  if (action === "DELETE") {
                    model.destroy(options);
                  } else {
                    model.fetch(options);
                  }
              }
            }, null, this.$appendTo);
            break;
          default:
            break;
        }
      }
    }
  }

  /**
   * Build the correct model to execute it depends on the action selected.
   */
  _buildModelToVal(activite: { [key: string]: any }, type: string, action: string): any {
    let code: string = null;
    let model: CWDeleteActivityModel = null;
    let lTypeEvt: string = null;

    switch (action) {
      case "delete":
        code = activite.get("code");
        model = new CWDeleteActivityModel();
        lTypeEvt = (type === "P" ? "prevues" : "realisees");
        model.setHabContext(new CWHabilitationContext({
          onglet: this.context.ctxEcran,
          foncCour: this.context.ctxHabilitation.HabilitationGestion[lTypeEvt],
          natGest: "M"
        }));
        model.set("id", code);
        return model;
      case "transform": {
        code = activite.get("code");
        model = new CWTransformActivityModel();
        const sType: string = (type) ? type : activite.get("typeevenement");

        if (sType === "P") {
          lTypeEvt = "realisees";
          model.setHabContext(new CWHabilitationContext({
            onglet: this.context.ctxEcran,
            foncCour: this.context.ctxHabilitation.HabilitationGestion[lTypeEvt],
            natGest: "M"
          }));
          model.set("id", code);
        }
        return model;
      }
      default:
        return null;
    }
  }

  _activityAlreadyDeleted(code: string): boolean {
    const activityFound = _.find(this.evenAlreadyDeleted, (codeAlreadyDeleted) => {
      return codeAlreadyDeleted === code;
    });
    return !CWSTR.isBlank(activityFound);
  }

  /**
   * Si une des activités à supprimer concerne une activité prévue ayant servi
   * de référence pour la transformation en activité réalisée, demander la confirmation à l’utilisateur
   */
  _validateSupression(type: string): string {
    const representation = this.modeRepresentationModel.get("representation");
    const tableColl = representation === this.MONO ? this.tableMonoColl : this.tableMixteColl;
    let messageValidations = "";
    let nbActivitesPrevuesTrans = 0;
    let nbDatesAcPrevuesTrans = 0;
    let lastDate = "";
    let displayDates = "";
    if (type === "P") {
      _.each(tableColl.models, (rowModel: { [key: string]: any }) => {
        if (rowModel.level === 4) {
          const action = type === "P" ? rowModel.evPSuppr : rowModel.evDSuppr;
          const activity = type === "P" ? rowModel.get("prevueColumn") : rowModel.get("realiseeColumn");
          // Treat a collaborator if it has been selected in the collection, else we continue the process
          if (!CWSTR.isBlank(activity) && !CWSTR.isBlank(action) && action === true && (activity.get("typetransfo") === "P" || activity.get("typetransfo") === "C")) {
            nbActivitesPrevuesTrans++;
            if (CWSTR.isBlank(lastDate)) {
              lastDate = CWTYPE.DATE.format(activity.currentDate);
              nbDatesAcPrevuesTrans++;
              displayDates += CWTYPE.DATE.format(activity.currentDate);
            } else if (lastDate !== activity.currentDate) {
              nbDatesAcPrevuesTrans++;
              displayDates += "," + CWTYPE.DATE.format(activity.currentDate);
            }
          }
        }
      });
      if (nbActivitesPrevuesTrans === 1) {
        // Si une seule activité prévue
        messageValidations = i18n.t('messages:GT_1421', { "1": lastDate });
      }
      if (nbActivitesPrevuesTrans > 1 && nbDatesAcPrevuesTrans === 1) {
        // Si plusieurs activités pour une seule date
        messageValidations = i18n.t('messages:GT_1422', { "1": lastDate });
      }
      if (nbActivitesPrevuesTrans > 1 && nbDatesAcPrevuesTrans > 1) {
        // Si plusieurs activités pour plusieurs dates
        messageValidations = i18n.t('messages:GT_1423', { "1": displayDates });
      }
    }

    return messageValidations;
  }

  /**
   * Generates table coll data from contenurecapdata
   * Format:
   * TableColl with models TableModel
   * Each Model (level1=Month, level2=week, level3=day, level4=evenement):
   * -prevueColumn (data of the evenement to show in prevue column, empty if there is no evenement.It isn't always a prevue evenement, it can be a realisee activite in monotype view)
   * -realiseeColumn (data of the evenement to show in realisee column, empty if there is no evenement)
   * properties:
   * (TreetypeGridView properties)
   *  	isExpanded (if this row is expanded or not)
   *		level (1=Month, 2=week, 3=day, 4=evenement)
   *		collapsible (if ti can be collapsed)
   *		branch (identifies the level 1 that contains this row)
   *		hasChild (if it has childs)
   * (Other properties)
   *       - For months:
   *            id: year+","+month;
   *            monthInfoArray: (0) - Month  (1)  year
   *       - For weeks:
   *             id: year+","+month+","+week;
   *            weekInfoArray: (0) - week (1) - FirstWeekDay (2) - LastWeekday
   *       - For Days:
   *            id: year+","+month+","+week+","+dateBBDD;
   *            dayInfoArray (0) - Day on the week, (1) - Day on the month, (2) -Current day in format YYYYMMDD
   *            summeDuresAct: Summe durees of the  activites of this day (in format hhmm).
   *
   *       - For Evenements
   *            For Monotype: id:,dateBBDD+","+evenementCode
   *            For Mixtetype: id:,dateBBDD+","+prevueEvenementCode+","realiseeEvenementCode
   *       	  currentDate - Current date that is being drawn
   *			  type [ACTIVITE, ABSENCE or EMPTY]
   *			  evPSuppr [undefined (for prevue evenements that cannot be suppresed or empty evenements),
   *                     false (for evenements that can be supresed, but its checkbox is not marked),
   *                      true (for evenements that can be supresed, with checkbox marked)]
   *			  evDSuppr[undefined (for realisee evenements that cannot be suppresed or empty evenements),
   *                     false (for realisee evenements that can be supresed, but its checkbox is not marked),
   *                      true (for realisee evenements that can be supresed, with checkbox marked)]
   */
  _generateTableColl(recap: { [key: string]: any }, representation: string, maintainTableState: boolean): void {
    const tableColl = representation === this.MONO ? this.tableMonoColl : this.tableMixteColl;
    tableColl.nbevPSuppr = 0;
    tableColl.nbevDSuppr = 0;
    const startDate = CWTYPE.DATE.strToDate(recap.get("datedeb"));
    const endDate = CWTYPE.DATE.strToDate(recap.get("datefin"));
    const dateAux = UTILS_PL.clone(startDate);
    const numDays = CWTYPE.DATE.getNumberOfDays(startDate, endDate);

    let year = dateAux.getFullYear();
    let month = dateAux.getMonth();
    let week = $.datepicker.iso8601Week(dateAux);
    //let lastYear = dateAux.getFullYear();
    let lastMonth = dateAux.getMonth();
    let lastWeek = $.datepicker.iso8601Week(dateAux);

    const lastStateColl = maintainTableState === true ? tableColl.clone() : null;
    tableColl.reset(null, { silent: true });

    let branch = 0;
    for (let i = 0; i < numDays; i++) {
      year = dateAux.getFullYear();
      month = dateAux.getMonth();
      week = $.datepicker.iso8601Week(dateAux);
      const dateBBDD = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(dateAux)).val;
      if (month === lastMonth && i !== 0) {
        if (week === lastWeek) {
          // ADD DAY
          const modelDay = new CWTableModel();
          modelDay.set("code", this.DAY);
          modelDay.id = year + "," + month + "," + week + "," + dateBBDD;
          const lastStateDayModel = !CWSTR.isBlank(lastStateColl) ? lastStateColl.get(modelDay.id) : null;
          modelDay.isExpanded = CWSTR.isBlank(lastStateDayModel) ? true : lastStateDayModel.isExpanded;
          modelDay.level = 3;
          modelDay.collapsible = true;
          modelDay.branch = branch;
          modelDay.hasChild = true;
          const dayInfoArray = this._createDayInfoArray(dateAux);
          modelDay.dayInfoArray = dayInfoArray;
          tableColl.add(modelDay);
          const summeDuresAct = this._addDayDataModels(year, month, week, dayInfoArray[1], dateAux, branch, representation);
          modelDay.summeDuresAct = summeDuresAct;
        } else {
          // WeekChanged
          // ADD WEEK
          const modelWeek = new CWTableModel();
          modelWeek.set("code", this.WEEK);
          modelWeek.id = year + "," + month + "," + week;
          const lastStateWeekModel = !CWSTR.isBlank(lastStateColl) ? lastStateColl.get(modelWeek.id) : null;
          modelWeek.isExpanded = CWSTR.isBlank(lastStateWeekModel) ? true : lastStateWeekModel.isExpanded;
          modelWeek.level = 2;
          modelWeek.collapsible = true;
          modelWeek.branch = branch;
          modelWeek.hasChild = true;
          const weekInfoArray = this._createWeekInfoArray(week, dateAux);
          modelWeek.weekInfoArray = weekInfoArray;
          tableColl.add(modelWeek);
          // ADD DAY
          const modelDay = new CWTableModel();
          modelDay.set("code", this.DAY);
          modelDay.id = year + "," + month + "," + week + "," + dateBBDD;
          const lastStateDayModel = !CWSTR.isBlank(lastStateColl) ? lastStateColl.get(modelDay.id) : null;
          modelDay.isExpanded = CWSTR.isBlank(lastStateDayModel) ? true : lastStateDayModel.isExpanded;
          modelDay.level = 3;
          modelDay.collapsible = true;
          modelDay.branch = branch;
          modelDay.hasChild = true;
          const dayInfoArray = this._createDayInfoArray(dateAux);
          modelDay.dayInfoArray = dayInfoArray;
          tableColl.add(modelDay);
          const summeDuresAct = this._addDayDataModels(year, month, week, dayInfoArray[1], dateAux, branch, representation);
          modelDay.summeDuresAct = summeDuresAct;
        }
      } else {
        // MonthChanged Or first iteration
        branch++;
        // ADD MONTH
        const modelMonth = new CWTableModel();
        modelMonth.set("code", this.MONTH);
        modelMonth.id = year + "," + month;
        const lastStateMonthModel = !CWSTR.isBlank(lastStateColl) ? lastStateColl.get(modelMonth.id) : null;
        modelMonth.isExpanded = CWSTR.isBlank(lastStateMonthModel) ? true : lastStateMonthModel.isExpanded;
        modelMonth.level = 1;
        modelMonth.collapsible = true;
        modelMonth.branch = branch;
        modelMonth.hasChild = true;
        const monthInfoArray = this._createMonthInfoArray(month, year);
        modelMonth.monthInfoArray = monthInfoArray;
        tableColl.add(modelMonth);

        // ADD WEEK
        const modelWeek = new CWTableModel();
        modelWeek.set("code", this.WEEK);
        modelWeek.id = year + "," + month + "," + week;
        const lastStateWeekModel = !CWSTR.isBlank(lastStateColl) ? lastStateColl.get(modelWeek.id) : null;
        modelWeek.isExpanded = CWSTR.isBlank(lastStateWeekModel) ? true : lastStateWeekModel.isExpanded;
        modelWeek.level = 2;
        modelWeek.collapsible = true;
        modelWeek.branch = branch;
        modelWeek.hasChild = true;
        const weekInfoArray = this._createWeekInfoArray(week, dateAux);
        modelWeek.weekInfoArray = weekInfoArray;
        tableColl.add(modelWeek);

        // ADD DAY
        const modelDay = new CWTableModel();
        modelDay.set("code", this.DAY);
        modelDay.id = year + "," + month + "," + week + "," + dateBBDD;
        const lastStateDayModel = !CWSTR.isBlank(lastStateColl) ? lastStateColl.get(modelDay.id) : null;
        modelDay.isExpanded = CWSTR.isBlank(lastStateDayModel) ? true : lastStateDayModel.isExpanded;
        modelDay.level = 3;
        modelDay.collapsible = true;
        modelDay.branch = branch;
        modelDay.hasChild = true;
        const dayInfoArray = this._createDayInfoArray(dateAux);
        modelDay.dayInfoArray = dayInfoArray;
        tableColl.add(modelDay);
        const summeDuresAct = this._addDayDataModels(year, month, week, dayInfoArray[1], dateAux, branch, representation);
        modelDay.summeDuresAct = summeDuresAct;
      }
      //lastYear = year;
      lastMonth = month;
      lastWeek = week;
      UTILS.addDays(dateAux, 1);
    }
  }

  _createMonthInfoArray(month: number, year: number): Array<number> {
    const monthInfoItem = [];
    monthInfoItem[0] = month;
    monthInfoItem[1] = year;
    return monthInfoItem;
  }

  _createWeekInfoArray(week: number, firstWeekDay: Date): Array<string> {
    const weekInfoItem = [];
    weekInfoItem[0] = week;
    const firstWeekDayCloned = UTILS_PL.clone(firstWeekDay);
    const firstWeekDayParsed = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(firstWeekDayCloned)).val;
    weekInfoItem[1] = firstWeekDayParsed; //First day of the week in format YYYYMMDD
    let dayOfWeek = firstWeekDayCloned.getDay(); //Get the position in the week of the first week day
    dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek; //Transform sundays into day number 7
    const lastWeekDay = UTILS.addDays(firstWeekDayCloned, 7 - dayOfWeek);
    const lastWeekDayParsed = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(lastWeekDay)).val; //First day of the week in format YYYYMMDD
    weekInfoItem[2] = lastWeekDayParsed;
    return weekInfoItem;
  }

  _createDayInfoArray(date: Date): Array<number> {
    const dayInfoItem = [];
    dayInfoItem[0] = date.getDay(); //Day of week
    const dateParsed = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(date)).val;
    const dateArray = CWTYPE.DATE._obtainDateArray(dateParsed, "YYYYMMDD");
    dayInfoItem[1] = dateArray[0]; //Day in month
    dayInfoItem[2] = dateParsed; //Day in YYYYMMDD
    return dayInfoItem;
  }

  /**
   *
   */
  _addDayDataModels(year: number, month: number, week: number, dayOfMonth: number, date: Date, branch: number, representation: string): number {
    let summeDuresActivites = 0;
    if (representation === this.MONO) {
      summeDuresActivites = this._addDayDataModelsMono(year, month, week, dayOfMonth, date, branch, representation);
    } else {
      summeDuresActivites = this._addDayDataModelsMixte(year, month, week, dayOfMonth, date, branch, representation);
    }
    return summeDuresActivites;
  }

  _addDayDataModelsMono(year: number, month: number, week: number, dayOfMonth: number, date: Date, branch: number, representation: string): number {
    let summeDuresActivites = 0;
    const tableColl = representation === this.MONO ? this.tableMonoColl : this.tableMixteColl;
    const activitesArray = [];
    const absencesArray = [];
    const activitesAndAbsencesArray: Array<any> = [];
    const dateBBDD = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(date)).val;
    _.each(this.contenuRecapActivites.get("activites"), (activite: { [key: string]: any }) => {
      if (activite.datedeb <= dateBBDD && activite.datefin >= dateBBDD) {
        activitesArray.push(activite);
        activitesAndAbsencesArray.push(activite);
        activite.type = this.ACTIVITE;
        activite.currentDate = this.dateBBDD;
        activite.nature = this._getNature(activite);
      }
    });
    _.each(this.contenuRecapActivites.get("absences"), (absence: { [key: string]: any }) => {
      if (absence.datedeb <= dateBBDD && absence.datefin >= dateBBDD) {
        absencesArray.push(absence);
        activitesAndAbsencesArray.push(absence);
        absence.type = this.ABSENCE;
        absence.currentDate = this.dateBBDD;
        absence.nature = this._getNature(absence);
      }
    });
    if (activitesAndAbsencesArray.length === 0) {
      //Add empty child
      const rowModel = new CWTableModel();
      rowModel.set("prevueColumn", null);
      rowModel.isExpanded = false;
      rowModel.level = 4;
      rowModel.collapsible = false;
      rowModel.branch = branch;
      rowModel.hasChild = false;
      rowModel.type = this.EMPTY;
      rowModel.evPSuppr = undefined;
      rowModel.evDSuppr = undefined;
      this._setDateProperties(rowModel, year, month, week, dayOfMonth, dateBBDD);
      tableColl.add(rowModel);
    } else {
      activitesAndAbsencesArray.sort(this._sortEvenements);
      _.each(activitesAndAbsencesArray, (ev: any) => {
        if (ev.type === this.ACTIVITE) {
          const activite = new CWActiviteModel(ev);

          //Calcul duree
          const duree = this._getActiviteDuree(activite);
          summeDuresActivites = CWTYPE.DURATION.HOUR_MINUTE.addDurees(summeDuresActivites, duree);

          const rowModel = new CWTableModel();
          rowModel.set("prevueColumn", activite);
          rowModel.get("prevueColumn").type = this.ACTIVITE;
          rowModel.set("id", dateBBDD + "," + activite.get("code"));
          this._setDateProperties(rowModel.get("prevueColumn"), year, month, week, dayOfMonth, dateBBDD);

          this._setDateProperties(rowModel, year, month, week, dayOfMonth, dateBBDD);
          rowModel.isExpanded = false;
          rowModel.level = 4;
          rowModel.collapsible = false;
          rowModel.branch = branch;
          rowModel.hasChild = false;
          rowModel.currentDate = dateBBDD;
          rowModel.type = this.ACTIVITE;
          rowModel.evPSuppr = this._checkActiviteCanBeSupressed(activite) === true ? false : undefined;
          rowModel.evDSuppr = undefined;

          tableColl.add(rowModel);
        } else if (ev.type === this.ABSENCE) {
          const absence = new CWAbsenceModel(ev);
          const rowModel = new CWTableModel();
          rowModel.set("prevueColumn", absence);
          rowModel.get("prevueColumn").type = this.ABSENCE;
          this._setDateProperties(rowModel.get("prevueColumn"), year, month, week, dayOfMonth, dateBBDD);
          this._setDateProperties(rowModel, year, month, week, dayOfMonth, dateBBDD);
          rowModel.set("id", dateBBDD + "," + absence.get("code"));
          rowModel.isExpanded = false;
          rowModel.level = 4;
          rowModel.collapsible = false;
          rowModel.branch = branch;
          rowModel.hasChild = false;
          rowModel.currentDate = dateBBDD;
          rowModel.type = this.ABSENCE;
          rowModel.evPSuppr = undefined;
          rowModel.evDSuppr = undefined;
          tableColl.add(rowModel);
        }

      });
    }
    return summeDuresActivites;
  }

  _addDayDataModelsMixte(year: number, month: number, week: number, dayOfMonth: number, date: Date, branch: number, representation: string): number {
    let summeDuresActivites = 0;
    const tableColl = representation === this.MONO ? this.tableMonoColl : this.tableMixteColl;
    const actAndAbsArray: Array<any> = [];
    const evenPrevuesArray: Array<any> = [];
    const evenDeclareesArray: Array<any> = [];
    const dateBBDD = CWTYPE.DATE.parse(CWTYPE.DATE.dateToStr(date)).val;

    //Generate array with activites and absences and its types
    _.each(this.contenuRecapActivites.get("activites"), (activite: { [key: string]: any }) => {
      if (activite.datedeb <= dateBBDD && activite.datefin >= dateBBDD) {
        activite.type = this.ACTIVITE;
        actAndAbsArray.push(activite);

        activite.type = this.ACTIVITE;
        activite.currentDate = this.dateBBDD;
        activite.nature = this._getNature(activite);
      }
    });
    _.each(this.contenuRecapActivites.get("absences"), (absence: { [key: string]: any }) => {
      if (absence.datedeb <= dateBBDD && absence.datefin >= dateBBDD) {
        absence.type = this.ABSENCE;
        actAndAbsArray.push(absence);

        absence.type = this.ABSENCE;
        absence.currentDate = this.dateBBDD;
        absence.nature = this._getNature(absence);
      }
    });
    actAndAbsArray.sort(this._sortEvenements);

    //Separate evenements prevues and evenements declares
    _.each(actAndAbsArray, (evenement: { [key: string]: any }) => {
      if (evenement.type === this.ACTIVITE) {
        if (evenement.typeevenement === "P") { //Prevue
          evenPrevuesArray.push(evenement);
        } else { //Realisee
          evenDeclareesArray.push(evenement);
        }
      } else if (evenement.type === this.ABSENCE) {
        if (this._getAbsenceValidee(evenement) !== true) { //Absence non validee
          evenPrevuesArray.push(evenement);
        } else { //Absence validee
          evenDeclareesArray.push(evenement);
        }
      }
    });

    if (evenPrevuesArray.length === 0 && evenDeclareesArray.length === 0) { //EMPTY MODEL
      //Add empty child
      const rowModel = new CWTableModel();
      rowModel.set("prevueColumn", null);
      rowModel.isExpanded = false;
      rowModel.level = 4;
      rowModel.collapsible = false;
      rowModel.branch = branch;
      rowModel.hasChild = false;
      rowModel.type = this.EMPTY;
      rowModel.evPSuppr = undefined;
      rowModel.evDSuppr = undefined;
      this._setDateProperties(rowModel, year, month, week, dayOfMonth, dateBBDD);
      rowModel.set("id", dateBBDD + ",undefined,undefined");
      tableColl.add(rowModel);
    } else {

      let moreData = true;
      let indexPrevue = 0;
      let indexDeclares = 0;
      let dataFound = false;
      while (moreData === true) {
        const rowModel = new CWTableModel();
        if (evenPrevuesArray.length > 0 && indexPrevue < evenPrevuesArray.length) { //PREVUE COLUMN
          const ev = evenPrevuesArray[indexPrevue];
          if (ev.type === this.ACTIVITE) { //ACTIVITE
            const activite = new CWActiviteModel(ev);
            //Calcul duree
            if (this._checkAfficherPrevue(activite, dateBBDD) === true) {
              const duree = this._getActiviteDuree(activite);
              if (activite.get("typeevenement") === "R") { //***
                summeDuresActivites = CWTYPE.DURATION.HOUR_MINUTE.addDurees(summeDuresActivites, duree);
              }
              rowModel.set("prevueColumn", activite);
              this._setDateProperties(rowModel.get("prevueColumn"), year, month, week, dayOfMonth, dateBBDD);
              rowModel.get("prevueColumn").type = this.ACTIVITE;
              rowModel.evPSuppr = this._checkActiviteCanBeSupressed(activite) === true ? false : undefined;
              rowModel.evDSuppr = undefined;
            }
          } else { //ABSENCE
            const absence = new CWAbsenceModel(ev);
            rowModel.set("prevueColumn", absence);
            this._setDateProperties(rowModel.get("prevueColumn"), year, month, week, dayOfMonth, dateBBDD);
            rowModel.get("prevueColumn").type = this.ABSENCE;
            rowModel.evPSuppr = undefined;
            rowModel.evDSuppr = undefined;
          }
          indexPrevue++;
          dataFound = true;
        }
        if (evenDeclareesArray.length > 0 && indexDeclares < evenDeclareesArray.length) { //DECLARES COLUMN
          const ev = evenDeclareesArray[indexDeclares];
          if (ev.type === this.ACTIVITE) {
            // ACTIVITE
            const activite = new CWActiviteModel(ev);
            //Calcul duree
            const duree = this._getActiviteDuree(activite);
            summeDuresActivites = CWTYPE.DURATION.HOUR_MINUTE.addDurees(summeDuresActivites, duree);
            rowModel.set("realiseeColumn", activite);
            this._setDateProperties(rowModel.get("realiseeColumn"), year, month, week, dayOfMonth, dateBBDD);
            rowModel.get("realiseeColumn").type = this.ACTIVITE;
            rowModel.evPSuppr = CWSTR.isBlank(rowModel.evPSuppr) ? undefined : rowModel.evPSuppr;
            rowModel.evDSuppr = false;
          } else {
            // ABSENCE
            const absence = new CWAbsenceModel(ev);
            rowModel.set("realiseeColumn", absence);
            this._setDateProperties(rowModel.get("realiseeColumn"), year, month, week, dayOfMonth, dateBBDD);
            rowModel.get("realiseeColumn").type = this.ABSENCE;
            rowModel.evPSuppr = CWSTR.isBlank(rowModel.evPSuppr) ? undefined : rowModel.evPSuppr;
            rowModel.evDSuppr = undefined;
          }
          indexDeclares++;
          dataFound = true;
        }
        if (indexPrevue >= evenPrevuesArray.length && indexDeclares >= evenDeclareesArray.length) {
          moreData = false;
        }
        if (dataFound === true) {
          this._setDateProperties(rowModel, year, month, week, dayOfMonth, dateBBDD);
          const prevue = rowModel.get("prevueColumn");
          const realisee = rowModel.get("realiseeColumn");
          const prevueCode = !CWSTR.isBlank(rowModel.get("prevueColumn")) ? prevue.get("code") : undefined;
          const realiseeCode = !CWSTR.isBlank(rowModel.get("realiseeColumn")) ? realisee.get("code") : undefined;
          rowModel.set("id", dateBBDD + "," + prevueCode + "," + realiseeCode);
          rowModel.isExpanded = false;
          rowModel.level = 4;
          rowModel.collapsible = false;
          rowModel.branch = branch;
          rowModel.hasChild = false;
          rowModel.currentDate = dateBBDD;
          tableColl.add(rowModel);
        }
      }
    }
    return summeDuresActivites;
  }

  /**
   * returns 1 if a goes after b
   * retusn 0 if
   * returns -1 if b comes after a
   */
  _sortEvenements(a: { [key: string]: any }, b: { [key: string]: any }): number {
    const natureA = a.nature;
    const natureB = b.nature;

    let comp = 0;
    comp = natureA === natureB ? 0 : (natureA < natureB ? -1 : 1);
    if (comp === 0) { //Both have the same nature
      if (a.modesaisie === "Heures" && b.modesaisie === "Heures") { //Both are activites and we can compare heures
        comp = (a.heuredeb < b.heuredeb) ? -1 : (a.heuredeb < b.heuredeb) ? 1 : 0;
      } else {
        comp = (a.modesaisie === "Heures" && b.modesaisie !== "Heures") ? -1 : (b.modesaisie === "Heures" && a.modesaisie !== "Heures") ? 1 : 0;
      }
      if (comp === 0) { //Compare Unites
        if (a.modesaisie === "UNITE" && b.modesaisie === "UNITE") { //Both are activites and we can compare nites
          comp = (a.unitedeb < b.unitedeb) ? -1 : (a.unitedeb < b.unitedeb) ? 1 : 0;
        } else {
          comp = (a.modesaisie === "UNITE" && b.modesaisie !== "UNITE") ? -1 : (b.modesaisie === "UNITE" && a.modesaisie !== "UNITE") ? 1 : 0;
        }
      }
      if (comp === 0) { //Compare a partir de
        if (a.typesaisie === "C" && b.typesaisie === "C" &&
          a.currentDate === a.datedeb && !CWSTR.isBlank(a.heuredeb) &&
          b.currentDate === b.datedeb && !CWSTR.isBlank(b.heuredeb)) { //Both are activites and we can compare nites
          comp = (a.heuredeb < b.heuredeb) ? -1 : (a.heuredeb < b.heuredeb) ? 1 : 0;
        }
      }
      if (comp === 0) { //Compare a jusqua
        if (a.typesaisie === "C" && b.typesaisie === "C" &&
          a.currentDate === a.datefin && !CWSTR.isBlank(a.heurefin) &&
          b.currentDate === b.datefin && !CWSTR.isBlank(b.heurefin)) { //Both are activites and we can compare nites
          comp = (a.heurefin < b.heurefin) ? -1 : (a.heurefin < b.heurefin) ? 1 : 0;
        }
      }
      if (comp === 0) { //Compare a jusqua
        if (a.typesaisie !== "C" && b.typesaisie !== "C" &&
          a.modesaisie === "POURCENT" && b.modesaisie === "POURCENT" &&
          !CWSTR.isBlank(a.pourcentage) && !CWSTR.isBlank(b.pourcentage)) { //Both are activites and we can compare nites
          comp = (a.pourcentage < b.pourcentage) ? 1 : (a.pourcentage < b.pourcentage) ? -1 : 0;
        }
      }
    }

    return comp;
  }

  _getNature(a: { [key: string]: any }): string {
    let nature = "";
    if (a.type === this.ACTIVITE) {
      const typologie = _.find(GLOBAL_DATA.typologies.models, (typ: { [key: string]: any }) => {
        return typ.get("code") === a.typologie.code;
      });
      if (!CWSTR.isBlank(typologie)) {
        nature = typologie.get("evenement");
      }
    } else if (a.type === this.ABSENCE) {
      nature = i18n.t('common:gererrecapitulatifs.absence');
    }
    return nature;
  }

  _setDateProperties(model: { [key: string]: any }, year: number, month: number, week: number, dayOfMonth: number, dateBBDD: any): void {
    model.currentDate = dateBBDD;
    model.year = year;
    model.month = month;
    model.week = week;
    model.dayOfMonth = dayOfMonth;
  }

  _getPrevueMonthEvenements(onlyActivites: boolean, year: number, month: number): CWTableColl {
    const representation = this.modeRepresentationModel.get("representation");
    const monthEvColl = new CWTableColl();
    const tableColl = representation === this.MONO ? this.tableMonoColl : this.tableMixteColl;
    _.each(tableColl.models, (model: { [key: string]: any }) => {
      if (model.level === 4) {
        // DAY
        const prevueModel = model.get("prevueColumn");
        if (!CWSTR.isBlank(prevueModel)) {
          if (prevueModel.month === month && prevueModel.year === year) {
            if (onlyActivites !== true) {
              prevueModel.evPSuppr = model.evPSuppr;
              monthEvColl.add(prevueModel);
            } else {
              if (prevueModel.type === this.ACTIVITE) {
                prevueModel.evPSuppr = model.evPSuppr;
                monthEvColl.add(prevueModel);
              }
            }
          }
        }
      }
    });
    return monthEvColl;
  }

  _getRealiseeMonthEvenements(onlyActivites: boolean, year: number, month: number): CWTableColl {
    const representation = this.modeRepresentationModel.get("representation");
    const monthEvColl = new CWTableColl();
    const tableColl = representation === this.MONO ? this.tableMonoColl : this.tableMixteColl;
    _.each(tableColl.models, (model: { [key: string]: any }) => {
      if (model.level === 4) {
        // DAY
        const realiseeModel = model.get("realiseeColumn");
        if (!CWSTR.isBlank(realiseeModel)) {
          if (realiseeModel.month === month && realiseeModel.year === year) {
            if (onlyActivites !== true) {
              realiseeModel.evDSuppr = model.evDSuppr;
              monthEvColl.add(realiseeModel);
            } else {
              if (realiseeModel.type === this.ACTIVITE) {
                realiseeModel.evDSuppr = model.evDSuppr;
                monthEvColl.add(realiseeModel);
              }
            }
          }
        }
      }
    });
    return monthEvColl;
  }

  _getPrevueWeekEvenements(onlyActivites: boolean, year: number, week: number): CWTableColl {
    const representation = this.modeRepresentationModel.get("representation");
    const weekEvColl = new CWTableColl();
    const tableColl = representation === this.MONO ? this.tableMonoColl : this.tableMixteColl;
    _.each(tableColl.models, (model: { [key: string]: any }) => {
      if (model.level === 4) {
        // DAY
        const prevueModel = model.get("prevueColumn");
        if (!CWSTR.isBlank(prevueModel)) {
          if (prevueModel.week === week && prevueModel.year === year) {
            if (onlyActivites !== true) {
              prevueModel.evPSuppr = model.evPSuppr;
              weekEvColl.add(prevueModel);
            } else {
              if (prevueModel.type === this.ACTIVITE) {
                prevueModel.evPSuppr = model.evPSuppr;
                weekEvColl.add(prevueModel);
              }
            }
          }
        }
      }
    });
    return weekEvColl;
  }

  _getRealiseeWeekEvenements(onlyActivites: boolean, year: number, week: number): CWTableColl {
    const representation = this.modeRepresentationModel.get("representation");
    const weekEvColl = new CWTableColl();
    const tableColl = representation === this.MONO ? this.tableMonoColl : this.tableMixteColl;
    _.each(tableColl.models, (model: { [key: string]: any }) => {
      if (model.level === 4) {
        // DAY
        const realiseeModel = model.get("realiseeColumn");
        if (!CWSTR.isBlank(realiseeModel)) {
          if (realiseeModel.week === week && realiseeModel.year === year) {
            if (onlyActivites !== true) {
              realiseeModel.evDSuppr = model.evDSuppr;
              weekEvColl.add(realiseeModel);
            } else {
              if (realiseeModel.type === this.ACTIVITE) {
                realiseeModel.evDSuppr = model.evDSuppr;
                weekEvColl.add(realiseeModel);
              }

            }
          }
        }
      }
    });
    return weekEvColl;
  }

  _getDayPrevueEvenements(onlyActivites: boolean, dateBBDD: any): CWTableColl {
    const representation = this.modeRepresentationModel.get("representation");
    const dayEvColl = new CWTableColl();
    const tableColl = representation === this.MONO ? this.tableMonoColl : this.tableMixteColl;
    _.each(tableColl.models, (model: { [key: string]: any }) => {
      if (model.level === 4) {
        // DAY
        const prevueModel = model.get("prevueColumn");
        if (!CWSTR.isBlank(prevueModel)) {
          if (prevueModel.currentDate === dateBBDD) {
            if (onlyActivites !== true) {
              prevueModel.evPSuppr = model.evPSuppr;
              dayEvColl.add(prevueModel);
            } else {
              if (prevueModel.type === this.ACTIVITE) {
                prevueModel.evPSuppr = model.evPSuppr;
                dayEvColl.add(prevueModel);
              }

            }
          }
        }
      }
    });
    return dayEvColl;
  }

  _getDayRealiseeEvenements(onlyActivites: boolean, dateBBDD: any): CWTableColl {
    const representation = this.modeRepresentationModel.get("representation");
    const dayEvColl = new CWTableColl();
    const tableColl = representation === this.MONO ? this.tableMonoColl : this.tableMixteColl;
    _.each(tableColl.models, (model: { [key: string]: any }) => {
      if (model.level === 4) {
        // DAY
        const realiseeModel = model.get("realiseeColumn");
        if (!CWSTR.isBlank(realiseeModel)) {
          if (realiseeModel.currentDate === dateBBDD) {
            if (onlyActivites !== true) {
              realiseeModel.evDSuppr = model.evDSuppr;
              dayEvColl.add(realiseeModel);
            } else {
              if (realiseeModel.type === this.ACTIVITE) {
                realiseeModel.evDSuppr = model.evDSuppr;
                dayEvColl.add(realiseeModel);
              }

            }
          }
        }
      }
    });
    return dayEvColl;
  }

  _getAnyPrevueEvenement(onlyActivites: boolean): CWTableColl {
    const representation = this.modeRepresentationModel.get("representation");
    const monthEvColl = new CWTableColl();
    const tableColl = representation === this.MONO ? this.tableMonoColl : this.tableMixteColl;
    _.each(tableColl.models, (model: { [key: string]: any }) => {
      if (model.level === 4) {
        // DAY
        const prevueModel = model.get("prevueColumn");
        if (!CWSTR.isBlank(prevueModel)) {
          if (onlyActivites !== true) {
            prevueModel.evPSuppr = model.evPSuppr;
            monthEvColl.add(prevueModel);
          } else {
            if (prevueModel.type === this.ACTIVITE) {
              prevueModel.evPSuppr = model.evPSuppr;
              monthEvColl.add(prevueModel);
            }
          }
        }
      }
    });
    return monthEvColl;
  }

  _getAnyRealiseeEvenement(onlyActivites: boolean): CWTableColl {
    const representation = this.modeRepresentationModel.get("representation");
    const monthEvColl = new CWTableColl();
    const tableColl = representation === this.MONO ? this.tableMonoColl : this.tableMixteColl;
    _.each(tableColl.models, (model: { [key: string]: any }) => {
      if (model.level === 4) {
        // DAY
        const realiseeModel = model.get("realiseeColumn");
        if (!CWSTR.isBlank(realiseeModel)) {
          if (onlyActivites !== true) {
            realiseeModel.evDSuppr = model.evDSuppr;
            monthEvColl.add(realiseeModel);
          } else {
            if (realiseeModel.type === this.ACTIVITE) {
              realiseeModel.evDSuppr = model.evDSuppr;
              monthEvColl.add(realiseeModel);
            }
          }
        }
      }
    });
    return monthEvColl;
  }

  /**
   * [IHM] Selon l’état de la case à cocher après modification
   * Cochée : Afficher les activités prévues correspondant totalement ou partiellement à une activité réalisée de la même journée (ActPrevIndActReal renseigné)
   * Décochée : Masquer les activités prévues correspondant totalement ou partiellement à une activité réalisée de la même journée (ActPrevIndActReal renseigné).
   */
  _checkAfficherPrevue(activite: { [key: string]: any }, dateBBDD: any): boolean {
    // If checkbox is not marked, and a transformation has been made, hide prevue activites that have been transformed into realisée activites for the current date
    if (this.mixteModel.get("toujours") === false &&
      (activite.get("typetransfo") === "C" || activite.get("typetransfo") === "P")) {
      //look that this activite prevue has been transformed into an activite realisée for the same day
      const actRealiseeAssociated = _.find(this.contenuRecapActivites.get("activites"), (act: { [key: string]: any }) => {
        if (act.datedeb <= dateBBDD && act.datefin >= dateBBDD) {
          return act.typeevenement === "R" && !CWSTR.isBlank(act.evenement_origine) && act.evenement_origine === activite.get("evenement");
        } else {
          return false;
        }
      });
      return CWSTR.isBlank(actRealiseeAssociated);
    }
    return true;
  }

  _getAbsenceValidee(absence: { [key: string]: any }): boolean {
    return (!CWSTR.isBlank(absence.statut) && absence.statut.code === "A" || absence.statut.code === "H");
  }

  _getTypeEvt(activite: { [key: string]: any }): string {
    let typeCode = "";
    const typeEvt = activite.get("typeevenement");

    if (typeEvt === "P") {
      typeCode = "ACTPREV";
    } else if (typeEvt === "R") {
      typeCode = "ACTREAL";
    }
    return typeCode;
  }

  _checkActiviteGereParRecap(activite: { [key: string]: any }, isAbsence?: boolean): boolean {
    let activiteGere = false;

    if (CWSTR.isBlank(isAbsence) || isAbsence !== true) {
      const domaineAct = activite.get("domaine");
      const typeCode = this._getTypeEvt(activite);
      const model = _.find(this.recapTypeEvtColl.models, (model: { [key: string]: any }) => {
        return (model.get("domaine").code === domaineAct.code && model.get("type").code === typeCode);//typeevenement
      });

      if (!CWSTR.isBlank(model)) {
        activiteGere = model.get("gestion") === true ? true : false; //gestion??
      }
      return activiteGere;
    } else {
      const model = _.find(this.recapTypeEvtColl.models, (model: { [key: string]: any }) => {
        return model.get("type").code === "ABSENCE";
      });

      if (!CWSTR.isBlank(model)) {
        activiteGere = model.get("gestion") === true ? true : false;
      }
      return activiteGere;
    }
  }

  _checkLAbelShownParRecap(activite: { [key: string]: any }, isAbsence: boolean): boolean {
    let activiteLabel = false;
    if (CWSTR.isBlank(isAbsence) || isAbsence === false) { //ACTIVITE
      const domaineAct = activite.get("domaine");
      const typeCode = this._getTypeEvt(activite);
      const model = _.find(this.recapTypeEvtColl.models, (model: { [key: string]: any }) => {
        return (model.get("domaine").code === domaineAct.code && model.get("type").code === typeCode);
      });
      if (!CWSTR.isBlank(model)) {
        activiteLabel = model.get("label") === true ? true : false;
      }
      return activiteLabel;
    } else { //ABSENCE
      const model = _.find(this.recapTypeEvtColl.models, (model: { [key: string]: any }) => {
        return model.get("type").code === "ABSENCE";
      });
      if (!CWSTR.isBlank(model)) {
        activiteLabel = model.get("label") === true ? true : false;
      }
      return activiteLabel;
    }
  }

  _getDomaineActivitesList(typeevenement: string): Array<string> {
    const domaineList: Array<string> = [];
    _.each(this.recapTypeEvtColl.models, (model: { [key: string]: any }) => {
      if (typeevenement === "P") {
        // Prevue
        if (model.get("type").code === "ACTPREV") {
          domaineList.push(model.get("domaine").code);
        }
      } else {
        // Realisee
        if (model.get("type").code === "ACTREAL") {
          domaineList.push(model.get("domaine").code);
        }
      }
    });
    return domaineList;
  }

  _getActiviteDuree(activite: { [key: string]: any }): number {
    const recap = this.headerModel.get("value");
    const dmaj = CWSTR.isBlank(recap.get("collaborateur")) ? CWTYPE.DATE.INITIAL : recap.get("collaborateur").dmaj;
    let duree = 0;
    if (activite.get("datefin") <= dmaj && activite.get("typesaisie") !== "C") {
      if (activite.get("modesaisie") === "PLH") {
        duree = this._calculateDuration(activite.get("heuredeb"), activite.get("heurefin"));
      } else if (activite.get("modesaisie") === "DUREE") {
        duree = activite.get("duree");
      }
    }
    return duree;
  }

  /**
   * Build the HTML sctructure for each Activite Line
   */
  _buildActiviteLine(activite: { [key: string]: any }, vueName: string): string {
    const response = [];
    const representation = this.modeRepresentationModel.get("representation");

    // Témoin Activité prévue
    if (activite.get("typeevenement") === "R") {
      //Look if this activite realisee cpomes from a prevue activite transformed
      const prevueAssociated = this._getPrevueAssociated(activite);
      if (!CWSTR.isBlank(prevueAssociated)) {
        if (prevueAssociated.typetransfo === "C") {
          response.push('<span class="phx-icon phx-icon-temmoin-exacte" style="display:inline-block;vertical-align:middle;cursor:default;"></span>');
        } else if (prevueAssociated.typetransfo === "P") {
          response.push('<span class="phx-icon phx-icon-temmoin-partiel" style="display:inline-block;vertical-align:middle;cursor:default;"></span>');
        } else if (prevueAssociated.typetransfo === "N") {
          response.push('<span class="phx-icon phx-icon-temmoin-ne-correspond" style="display:inline-block;vertical-align:middle;cursor:default;"></span>');
        }
      }
    }

    // Témoin amont
    if (activite.get("datedeb") < activite.currentDate) {
      response.push('<span class="ui-icon ui-icon-circle-arrow-w" style="display:inline-block;vertical-align:middle;cursor:default;"></span>');
    }

    // Indication temporelle
    if (activite.get("typesaisie") === "C") {
      //Pour les journées intermédiaires.
      if (activite.get("datedeb") < activite.currentDate &&
        activite.currentDate < activite.get("unitefin") &&
        activite.get("presence") && !activite.get("horspresence")) {
        const cssClass = "ui-phx-ihm-texte-application";
        response.push('<span class="' + cssClass + '">' + i18n.t('messages:GT_1404') + ',</span>');
      }
      //Pour la date de début en journée complète
      //Pour la date de début en fraction de journée
      if (activite.get("datedeb") === activite.currentDate && CWSTR.isBlank(activite.get("unitedeb").code)) {
        const cssClass = (activite.get("typeevenement") === "P" && representation === this.MIXTE) ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
        response.push('<span class="' + cssClass + '">' + activite.get("unitedeb").libelle + ',</span>');
      }
      //Pour la date de fin en journée complète
      //Pour la date de fin en fraction de journée
      if (activite.get("datefin") === activite.currentDate && !CWSTR.isBlank(activite.get("unitedeb").code)) {
        const cssClass = (activite.get("typeevenement") === "P" && representation === this.MIXTE) ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
        response.push('<span class="' + cssClass + '">' + activite.get("unitefin").libelle + ',</span>');
      }
      // Pour les activités débutant à une heure précise
      if (activite.get("datedeb") === activite.currentDate && !CWSTR.isBlank(activite.get("heuredeb"))) {
        const messageClass = "ui-phx-ihm-texte-application";
        const donneesClass = (activite.get("typeevenement") === "P" && representation === this.MIXTE) ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
        const message = i18n.t('messages:GT_1747', { "1": '<span class="' + donneesClass + '">' + CWTYPE.HOUR_MINUTE_NIGHTLY.format(activite.get("heuredeb")) + '</span>', interpolation: { escapeValue: false } });
        response.push('<span class="' + messageClass + '">' + message + '</span>,');
      }
      // Pour les activités se terminant à une heure précise
      if (activite.get("datefin") === activite.currentDate && !CWSTR.isBlank(activite.get("heurefin"))) {
        const messageClass = "ui-phx-ihm-texte-application";
        const donneesClass = (activite.get("typeevenement") === "P" && representation === this.MIXTE) ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
        const message = i18n.t('messages:GT_1748', { "1": donneesClass, "2": CWTYPE.HOUR_MINUTE_NIGHTLY.format(activite.get("heurefin")) });
        response.push('<span class="' + messageClass + '">' + message + '</span>');
      }
      //Pour les activités débutant après la présence du collaborateur
      if (activite.get("datedeb") === activite.currentDate && !CWSTR.isBlank(activite.get("modesai_periodeb").libelle) && activite.get("modesai_periodeb").libelle === "S") {
        const cssClass = "ui-phx-ihm-texte-application";
        response.push('<span class="' + cssClass + '">' + i18n.t('messages:GT_1403') + ',</span>');
      }
      //Pour les activités débutant avant la présence du collaborateur
      if (activite.get("datedeb") === activite.currentDate && !CWSTR.isBlank(activite.get("modesai_periofin").libelle) && activite.get("modesai_periofin").libelle === "S") {
        const cssClass = "ui-phx-ihm-texte-application";
        response.push('<span class="' + cssClass + '">' + i18n.t('messages:GT_1402') + ',</span>');
      }
    } else if (activite.get("typesaisie") === "D") {
      switch (activite.get("modesaisie")) {
        case "UNITE":
          //Pour les activités se déroulant sur une fraction de journée :
          if (!CWSTR.isBlank(activite.get("unitedeb").code) && !CWSTR.isBlank(activite.get("unitefin").code) &&
            activite.get("unitedeb").code === activite.get("unitefin").code &&
            activite.get("unitefin").code !== "D" &&
            activite.get("datedeb") <= activite.currentDate && activite.currentDate <= activite.get("datefin")) {
            const cssClass = (activite.get("typeevenement") === "P" && representation === this.MIXTE) ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
            response.push('<span class="' + cssClass + '">' + activite.get("unitedeb").libelle + ',</span>');
          }
          break;
        case "PLH":
          // Pour les activités se déroulement sur un créneau horaire
          if (activite.get("datedeb") <= activite.currentDate && activite.currentDate <= activite.get("datefin")) {
            const messageClass = "ui-phx-ihm-texte-application";
            const donneesClass = (activite.get("typeevenement") === "P" && representation === this.MIXTE) ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
            const duration = this._calculateDuration(activite.get("heuredeb"), activite.get("heurefin"));
            let durationFormatted = "";
            if (vueName === i18n.t('common:gererrecapitulatifs.vue_pd_HM')) {
              durationFormatted = CWTYPE.DURATION.HOUR_MINUTE.format(duration);
            } else {
              durationFormatted = CWTYPE.DURATION.HOUR_HUNDREDTH.format(this._toHourHundreth(duration));
            }
            const message = i18n.t('messages:GT_1721', {
              "1": '<span class="' + donneesClass + '">' + CWTYPE.HOUR_MINUTE_NIGHTLY.format(activite.get("heuredeb")) + '</span>',
              "2": '<span class="' + donneesClass + '">' + CWTYPE.HOUR_MINUTE_NIGHTLY.format(activite.get("heurefin")) + '</span>',
              "3": '<span class="' + donneesClass + '">' + durationFormatted + '</span>',
              interpolation: { escapeValue: false }
            });
            response.push('<span class="' + messageClass + '">' + message + '</span>');
          }
          break;
        case "DUREE":
          // Pour les activités saisies en durée
          if (activite.get("datedeb") <= activite.currentDate && activite.currentDate <= activite.get("datefin")) {
            const cssClass = (activite.get("typeevenement") === "P" && representation === this.MIXTE) ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
            let message = "";
            if (vueName === i18n.t('common:gererrecapitulatifs.vue_pd_HM')) {
              message = "(" + CWTYPE.DURATION.HOUR_MINUTE.format(activite.get("duree")) + ")";
            } else {
              message = "(" + CWTYPE.DURATION.HOUR_HUNDREDTH.format(this._toHourHundreth(activite.get("duree"))) + ")";
            }
            response.push("<span class='" + cssClass + "'>" + message + "</span>,");
          }
          break;
        case "RELIQUAT":
          // Pour les activités saisies en reliquat
          if (activite.get("datedeb") <= activite.currentDate && activite.currentDate <= activite.get("datefin")) {
            const cssClass = "ui-phx-ihm-texte-application";
            const message = i18n.t('messages:GT_1722') + ",";
            response.push('<span class="' + cssClass + '">' + message + '</span>');
          }
          break;
        case "POURCENT":
          // Pour les activités saisies en reliquat
          if (activite.get("datedeb") <= activite.currentDate && activite.currentDate <= activite.get("datefin")) {
            const cssClass = (activite.get("typeevenement") === "P" && representation === this.MIXTE) ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
            const message = CWTYPE.PERCENTAGE_0.format(activite.get("pourcentage")) + ",";
            response.push('<span class="' + cssClass + '">' + message + '</span>');
          }
          break;
        case "HPRES":
          //Pour les activités débutant avant la présence du collaborateur
          if (activite.get("datedeb") <= activite.currentDate && activite.currentDate <= activite.get("datefin")) {
            const cssClass = "ui-phx-ihm-texte-application";
            if (activite.get("horspresav") && !activite.get("horspresap")) {
              response.push('<span class="' + cssClass + '">' + i18n.t('messages:GT_1402') + ',</span>');
            }
            if (!activite.get("horspresav") && activite.get("horspresap")) {
              response.push('<span class="' + cssClass + '">' + i18n.t('messages:GT_1403') + ',</span>');
            }
            if (activite.get("horspresav") && activite.get("horspresap")) {
              response.push('<span class="' + cssClass + '">' + i18n.t('messages:GT_1404') + ',</span>');
            }
          }
          break;
        default:
          break;
      }
    }

    //Nature d’événement, solo si RecapActAffLabelEvt  = true ¿?¿?
    const labelHasToBeShown = this._checkLAbelShownParRecap(activite, false);
    let cssClass = "";
    if (labelHasToBeShown === true) {
      const typologie = _.find(GLOBAL_DATA.typologies.models, (typ: { [key: string]: any }) => {
        return typ.get("code") === activite.get("typologie").code;
      });
      if (!CWSTR.isBlank(activite.get("typedom")) && activite.get("typedom") === "ACT") {
        cssClass = "ui-phx-style-act";
      } else if (!CWSTR.isBlank(activite.get("typedom")) && activite.get("typedom") === "ASTR") {
        cssClass = "ui-phx-style-astr";
      }
      if (!CWSTR.isBlank(typologie)) {
        response.push('<span class="' + cssClass + '">' + typologie.get("evenement") + '</span>,');
      }
    }

    // Libellé d’événement > Format DomActFormatDef
    cssClass = (activite.get("typeevenement") === "P" && representation === this.MIXTE) ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";
    const labelDisplayed = EVENEMENT.getLibelle(activite.get("format"), activite.get("activite"), activite.get("libelle"));
    response.push('<span class="' + cssClass + '">' + labelDisplayed + '</span>');

    // Indicateur de présence d’une pièce jointe
    if (activite.get("indicateurpj") === true && this.context.ctxPieceJointe === true) {
      response.push('<span class="piece-jointe cw-icon-16 ctime-sprite-icon cw-icon" data-type="' + this.ACTIVITE + '" data-code="' + activite.get("code") + '" style="display:inline-block;" title="' + i18n.t('common:gererrecapitulatifs.pj_title') + '"><svg role="img"><use xlink:href="assets/images/icons.svg#sprite-trombone"></use></svg></span>');
    }

    // Témoin aval
    if (activite.get("datefin") > activite.currentDate) {
      response.push('<span class="ui-icon  ui-icon-circle-arrow-e" style="display:inline-block;vertical-align:middle;cursor:default"></span>');
    }

    return response.join(" ");
  }

  /**
   * Look for an activite prevue d'origine for this activité realisée
   * evenement_origine from activite realisee is equal to evenement of activite prevue
   */
  _getPrevueAssociated(activite: { [key: string]: any }): { [key: string]: any } {
    if (!CWSTR.isBlank(activite.get("evenement_origine"))) {
      //look that this activite prevue has been transformed into an activite realisée for the same day
      const actPrevueAssociated = _.find(this.contenuRecapActivites.get("activites"), (act: { [key: string]: any }) => {
        if (act.typetransfo === "C" || act.typetransfo === "P") {
          return act.typeevenement === "P" && act.evenement === activite.get("evenement_origine");
        } else {
          return false;
        }
      });
      return actPrevueAssociated;
    }
    return null;
  }

  _toHourHundreth(duration: number): number {
    //duration has format hhmm
    const h = Math.floor(duration / 100);
    const m = CWTYPE.DURATION.HOUR_MINUTE.minutes(duration);
    const minutesHundreth = Math.floor(m * 100 / 60);
    return h * 100 + minutesHundreth;
  }

  _calculateDuration(h1: any, h2: any): number {
    let duration = 0;
    if (h1 !== h2) {
      duration = this._toMinutes(h2) - this._toMinutes(h1);
      if (h1 > h2) {
        duration = 1440 + duration;
      }
    }
    return this._toDuration(duration);
  }

  _toMinutes(duration: number): number {
    const h = Math.floor(duration / 100);
    const m = duration % 100;
    return h * 60 + m;
  }

  _toDuration(minutes: number): number {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    return h * 100 + m;
  }

  _getWorkflowAbsenceStyle(statut: { [key: string]: any }): string {
    const statutCode = !CWSTR.isBlank(statut.code) ? statut.code : "";
    switch (statutCode) {
      case "A":
        return "ui-phx-statut-accepte";
      case "D":
      case "T":
        return "ui-phx-statut-demande";
      case "I":
        return "ui-phx-statut-en-cours";
      case "R":
        return "ui-phx-statut-refuse";
      case "H":
        return "ui-phx-statut-hors-validation";
      default:
        return "";
    }
  }

  _buildActiviteSpanStatus(activite: { [key: string]: any }): string {
    const style = this._getWorkflowStyle(activite.get("statut"));
    const modelDomaineRecap = _.find(this.recapTypeEvtColl.models, (model: { [key: string]: any }) => {
      return (model.get("type").code === activite.get("domaine").code && model.get("gestion") === true);
    });
    const labelHasToBeShown = !_.isEmpty(modelDomaineRecap) ? false : true;//il n'y a pas de la gestion de l'activité dans le récapitulatif(cette n'est pas géré), il faut afficher la libellé statut
    let lib = activite.get("statut").libelle;
    if (labelHasToBeShown === false) {
      lib = "";
    }
    const spanStatus = '<span class="' + style + '">' + lib + '</span>';

    return spanStatus;
  }

  _buildAbsenceSpanStatus(absence: { [key: string]: any }): string {
    const style = this._getWorkflowAbsenceStyle(absence.get("statut"));
    const spanStatus = '<span class="' + style + '">' + absence.get("statut").libelle + '</span>';
    return spanStatus;
  }

  _getWorkflowStyle(statut: { [key: string]: any }): string {
    if (CWSTR.isBlank(statut) || CWSTR.isBlank(statut.code)) {
      return "";
    } else {
      const stat = !CWSTR.isBlank(statut.code) ? statut.code : "";
      switch (stat) {
        case "E":
          return "ui-phx-statut-en-cours-saisie";
        case "F":
        case "G":
          return "ui-phx-statut-en-cours-revision";
        case "C":
        case "M":
          return "ui-phx-statut-en-cours-modification";
        case "Y":
        case "Z":
          return "ui-phx-statut-a-revoir";
        case "A":
          return "ui-phx-statut-accepte";
        case "D":
          return "ui-phx-statut-demande";
        case "H":
          return "ui-phx-statut-hors-validation";
        default:
          return "";
      }
    }
  }

  /**
   * Build the HTML sctructure for each Activite Line
   */
  _buildAbsenceLine(absence: { [key: string]: any }, vueName: string): string {
    const response = [];

    // Témoin amont
    if (absence.get("datedeb") < absence.currentDate) {
      response.push('<span class="ui-icon ui-icon-circle-arrow-w" style="display:inline-block;vertical-align:middle;cursor:default;"></span>');
    }
    const representation = this.modeRepresentationModel.get("representation");
    const cssClass = representation === this.MIXTE ? "ui-phx-ihm-texte-donnees-alternatif" : "ui-phx-ihm-texte-donnees";

    // Indication temporelle
    // -Absences saisies sur une période continue : ActTypeSaisie = C
    // Pour les absences avec la date de début en journée complète.
    if (absence.get("datedeb") === absence.currentDate && !CWSTR.isBlank(absence.get("unitefin").libelle) &&
      absence.get("unitefin").libelle !== "null" && !CWSTR.isBlank(absence.get("unitedeb")) && absence.get("unitedeb").code === "J") {
      const message = i18n.t('messages:GT_1589') + ",";
      response.push('<span class="' + cssClass + '">' + message + '</span>');
    }
    // Pour les absences avec la date de fin en journée complète.
    else if (absence.get("datefin") === absence.currentDate &&
      !CWSTR.isBlank(absence.get("unitefin")) && !CWSTR.isBlank(absence.get("unitefin").libelle) &&
      absence.get("unitefin").libelle !== "null" && absence.get("unitefin").code === "J") {
      const message = i18n.t('messages:GT_1589') + ",";
      response.push('<span class="' + cssClass + '">' + message + '</span>');
    }
    // Pour les absences avec une date intermédiaire en journée complète.
    else if (absence.get("datedeb") <= absence.currentDate && absence.currentDate <= absence.get("datefin") &&
      !CWSTR.isBlank(absence.get("unitedeb")) &&
      !CWSTR.isBlank(absence.get("unitefin")) &&
      absence.get("unitedeb").code !== absence.get("unitefin").code) {
      const message = i18n.t('messages:GT_1589') + ",";
      response.push('<span class="' + cssClass + '">' + message + '</span>');
    }
    // Pour les absences avec la date de début en fraction de journée.
    else if (absence.get("datedeb") === absence.currentDate && !CWSTR.isBlank(absence.get("unitedeb").libelle) &&
      absence.get("unitedeb").libelle !== "null" && !CWSTR.isBlank(absence.get("unitedeb")) && absence.get("unitedeb").code !== "J" &&
      absence.get("unitedeb").code !== "H") {
      const message = absence.get("unitedeb").libelle;
      response.push('<span class="' + cssClass + '">' + message + '</span>');
    } //Pour les absences avec la date de fin en fraction de journée.
    else if (absence.get("datefin") === absence.currentDate && !CWSTR.isBlank(absence.get("unitefin").libelle) &&
      absence.get("unitefin").libelle !== "null" && !CWSTR.isBlank(absence.get("unitefin")) && absence.get("unitefin").code !== "J" &&
      absence.get("unitefin").code !== "H") {
      const message = absence.get("unitefin").libelle;
      response.push('<span class="' + cssClass + '">' + message + '</span>');
    } //Pour les absences avec une date intermédiaire en fraction de journée.
    else if (absence.get("datedeb") <= absence.currentDate && absence.get("datefin") <= absence.currentDate &&
      !CWSTR.isBlank(absence.get("unitedeb").libelle) &&
      absence.get("unitedeb").libelle !== "null" && !CWSTR.isBlank(absence.get("unitedeb")) && absence.get("unitedeb").code !== "J" &&
      absence.get("unitedeb").code !== "H" && absence.get("unitedeb").code === absence.get("unitefin").code) {
      const message = absence.get("unitedeb").libelle;
      response.push('<span class="' + cssClass + '">' + message + '</span>');
    } //Pour les absences se déroulant sur une plage horaire
    else if (absence.get("datedeb") <= absence.currentDate && absence.get("datefin") <= absence.currentDate &&
      !CWSTR.isBlank(absence.get("unitedeb").libelle) &&
      absence.get("unitedeb").libelle !== "null" && !CWSTR.isBlank(absence.get("unitedeb")) &&
      absence.get("unitedeb").code === "H" && absence.get("heuredeb") !== 0 && absence.get("heurefin") !== 0) {
      const messageClass = "ui-phx-ihm-texte-application";
      const donneesClass = cssClass;
      const duration = this._calculateDuration(absence.get("heuredeb"), absence.get("heurefin"));
      let durationFormatted = "";
      if (vueName === i18n.t('common:gererrecapitulatifs.vue_pd_HM')) {
        durationFormatted = CWTYPE.DURATION.HOUR_MINUTE.format(duration);
      } else {
        durationFormatted = CWTYPE.DURATION.HOUR_HUNDREDTH.format(this._toHourHundreth(duration));
      }
      const message = i18n.t('messages:GT_1721', {
        "1": '<span class="' + donneesClass + '">' + CWTYPE.HOUR_MINUTE.format(absence.get("heuredeb")) + '</span>',
        "2": '<span class="' + donneesClass + '">' + CWTYPE.HOUR_MINUTE.format(absence.get("heurefin")) + '</span>',
        "3": '<span class="' + donneesClass + '">' + durationFormatted + '</span>',
        interpolation: { escapeValue: false }
      });

      response.push("<span class='" + messageClass + "'>" + message + "</span>");
    } //Pour les absences saisies en durée
    else if (absence.get("datedeb") <= absence.currentDate && absence.get("datefin") <= absence.currentDate &&
      !CWSTR.isBlank(absence.get("unitedeb").libelle) && absence.get("unitedeb").libelle !== "null" &&
      absence.get("unitedeb").code === "H" && absence.get("heuredeb") === 0 && absence.get("heurefin") === 0) {
      //let cssClass="ui-phx-ihm-texte-donnees";
      let message = "";
      if (vueName === i18n.t('common:gererrecapitulatifs.vue_pd_HM')) {
        message = "(" + CWTYPE.DURATION.HOUR_MINUTE.format(absence.get("duree")) + ")";
      } else {
        message = "(" + CWTYPE.DURATION.HOUR_HUNDREDTH.format(this._toHourHundreth(absence.get("duree"))) + ")";
      }

      response.push('<span class="' + cssClass + '">' + message + '</span>,');
    }

    //Nature d’événement, solo si RecapActAffLabelEvt  = true ¿?¿?
    const labelHasToBeShown = this._checkLAbelShownParRecap(absence, true);
    if (labelHasToBeShown === true) {
      response.push('<span class="ui-phx-absence-dans-recapitulatif">' + i18n.t('common:gererrecapitulatifs.absence') + '</span>,');
    }

    //LABEL
    response.push(absence.get("absence") + " (" + absence.get("libelle") + ")");

    // Indicateur de présence d’une pièce jointe
    if (absence.get("indicateurpj") === true && this.context.ctxPieceJointe === true) {
      response.push('<span class="piece-jointe cw-icon-16 ctime-sprite-icon cw-icon" data-type="' + this.ABSENCE + '" data-code="' + absence.get("code") + '" style="display:inline-block;" title="' + i18n.t('common:gererrecapitulatifs.pj_title') + '"><svg role="img"><use xlink:href="assets/images/icons.svg#sprite-trombone"></use></svg></span>');
    }

    // Témoin aval
    if (absence.get("datefin") > absence.currentDate) {
      response.push('<span class="ui-icon  ui-icon-circle-arrow-e" style="display:inline-block;vertical-align:middle;cursor:default"></span>');
    }

    return response.join(" ");
  }

  _checkActiviteCanBeSupressed(activite: { [key: string]: any }): boolean {
    let canBeSupressed = false;

    if (this._recapEtatCanBeSupressed(activite.get("typeevenement")) === true && this._checkActiviteGereParRecap(activite, false) === true) {
      canBeSupressed = true;
    }
    return canBeSupressed;
  }

  _recapEtatCanBeSupressed(typeevenement: string): boolean {
    let habAct = "";
    const habRecap = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_RECAP.G" : "RES_RECAP.G";
    if (typeevenement === "P") {
      habAct = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_ACT_PREV.G" : "RES_ACT_PREV.G";
    } else {
      habAct = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_ACT_REAL.G" : "RES_ACT_REAL.G";
    }

    if (CWHABILITATION.canDelete(habAct) && CWHABILITATION.canUpdate(habRecap)) {
      const val = this.headerModel.get("value");
      switch (val.get("etat")) {
        case "E":
        case "Y":
        case "M":
        case "F":
          return true;
        case "D":
        case "A":
        case "Z":
        case "C":
        case "G":
          return (this.context.ctxUtilisateur === "Responsable" && this.etatFinalModel !== null && this.etatFinalModel.get("valideur") === true);
        default:
          return false;
      }
    }
    return false;
  }

  _recapEtatActCanBeInstantiated(typeevenement: string): boolean {
    let habAct = "";
    const habRecap = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_RECAP.G" : "RES_RECAP.G";
    if (typeevenement === "P") {
      habAct = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_ACT_PREV.G" : "RES_ACT_PREV.G";
    } else {
      habAct = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_ACT_REAL.G" : "RES_ACT_REAL.G";
    }

    const canManage = this._recapCanManageTypeEvenement(typeevenement);

    if (CWHABILITATION.canCreate(habAct) && CWHABILITATION.canUpdate(habRecap) && canManage === true) {
      const val = this.headerModel.get("value");
      switch (val.get("etat")) {
        case "E":
        case "M":
        case "F":
        case "Y":
          return true;
        case "D":
        case "A":
        case "Z":
        case "C":
        case "G":
          return (this.context.ctxUtilisateur === "Responsable" && this.etatFinalModel !== null && this.etatFinalModel.get("valideur") === true);
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Checks that the recapitulatif manages the type d'evenement for a domaine
   */
  _recapCanManageTypeEvenement(typeevenement: string): boolean {
    let canManage = false;
    const typeEv = typeevenement === "P" ? "ACTPREV" : "ACTREAL";

    const model = _.find(this.recapTypeEvtColl.models, (model: { [key: string]: any }) => {
      return (model.get("type").code === typeEv && model.get("gestion") === true);
    });
    if (!CWSTR.isBlank(model)) {
      canManage = true;
    }
    return canManage;
  }

  /**
   * Check if transformation of an activity can be done by checking:
   *  -Habilitations
   *  -Etat recapitulatif, context and utilvalideur
   *  - typetransfo !=C and !=P
   *  - For transformation direct: The recapitulatif manages activites realisees for the domaine of the activity
   *   - For transformation with modification: The recapitulatif manages activites realisees for a domaine
   */
  _recapCanTransformAct(activite: { [key: string]: any }, withModification: any): boolean {
    const typetransfo = activite.get("typetransfo");
    const transformauto = activite.get("transformation_auto");
    if (this._recapCanManageRealiseeInDomaine(activite, withModification) === true && (typetransfo !== "C" && typetransfo !== "P") && (transformauto !== "N" || withModification)) {
      //Pas déjà transformée même partiellement
      //Gérable en réalisé dans le récapitulatif, ceci étant précisée dans le paramétrage des associations d’événement dans le récapitulatif.

      const habAct = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_ACT_REAL.G" : "RES_ACT_REAL.G";
      const habRecap = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_RECAP.G" : "RES_RECAP.G";

      if (CWHABILITATION.canCreate(habAct) && CWHABILITATION.canUpdate(habRecap)) {
        const val = this.headerModel.get("value");
        switch (val.get("etat")) {
          case "E":
          case "M":
          case "F":
          case "Y":
            return true;
          case "D":
          case "A":
          case "Z":
          case "C":
          case "G":
            return (this.context.ctxUtilisateur === "Responsable" && this.etatFinalModel !== null && this.etatFinalModel.get("valideur") === true);
          default:
            return false;
        }
      } else {
        return false;
      }
    }
    return false;
  }

  /**
   * Returns true if there is an activite that can be transformed in the period espicified
   */
  _canTransformInPeriod(datedeb: any, datefin: any, withModification: any): boolean {
    const tableColl = this.tableMixteColl;
    const model = _.find(tableColl.models, (model: { [key: string]: any }) => {
      if (model.level === 4) {
        // DAY
        const prevueModel = model.get("prevueColumn");
        if (!CWSTR.isBlank(prevueModel)) {
          if (prevueModel.type === this.ACTIVITE && this._recapCanTransformAct(prevueModel, withModification) &&
            datedeb <= prevueModel.currentDate && prevueModel.currentDate <= datefin) {
            return true;
          }
        }
      }
      return false;
    });
    return !CWSTR.isBlank(model);
  }

  /**
   * Checks that transformation can be done:
   *  - For transformation direct: The recapitulatif manages activites realisees for the domaine of the activity
   *   - For transformation with modification: The recapitulatif manages activites realisees for a domaine
   */
  _recapCanManageRealiseeInDomaine(activite: { [key: string]: any }, withModification: any): boolean {
    let canManage = false;
    const typeEv = "ACTREAL";
    let model = null;

    if (withModification !== true) { //If we want to test transformation with modification, the recapitulatif must manage activites realisees for the same domaine of the activite
      model = _.find(this.recapTypeEvtColl.models, (model: { [key: string]: any }) => {
        return (model.get("type").code === typeEv && model.get("domaine").code === activite.get("domaine").code && model.get("gestion") === true);
      });
    } else { //If we want to test transformation with modification,the recapitulatif hast to have any domaine that manages activites realis�es
      model = _.find(this.recapTypeEvtColl.models, (model: { [key: string]: any }) => {
        return (model.get("type").code === typeEv && model.get("gestion") === true);
      });
    }

    if (!CWSTR.isBlank(model)) {
      canManage = true;
    }
    return canManage;
  }

  /**
   * Habilitations let consulting the activity
   */
  _actCanBeConsulted(typeevenement: string): boolean {
    let habAct = "";
    const habRecap = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_RECAP.G" : "RES_RECAP.G";

    if (typeevenement === "P") {
      habAct = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_ACT_PREV.V" : "RES_ACT_PREV.V";
    } else {
      habAct = this.context.ctxUtilisateur === this.COLLABORATEUR ? "COL_ACT_REAL.V" : "RES_ACT_REAL.V";
    }

    if (CWHABILITATION.canView(habAct) && CWHABILITATION.canUpdate(habRecap)) {
      return true;
    }
    return false;
  }

  _initializeGererActivitesContext(typeevenement: string, typologie: any): any {
    const contextGererActivite: ContextGererActivite = {};

    contextGererActivite.ctxUtilisateur = this.context.ctxUtilisateur;
    contextGererActivite.ctxUtilisation = "SAIS_ACT_STD";
    contextGererActivite.ctxTypologieActivite = typologie;

    contextGererActivite.ctxEcran = this.context.ctxEcran;
    const hab: ContextActHabilitation = {};

    if (typeevenement === "P") {
      if (!_.isObject(this.context.ctxHabilitation.HabilitationAcces)) {
        hab.HabilitationAcces = this.context.ctxHabilitation.HabilitationAcces;
      } else {
        hab.HabilitationAcces = this.context.ctxHabilitation.HabilitationAcces.prevues;
      }
      if (!_.isObject(this.context.ctxHabilitation.HabilitationGestion)) {
        hab.HabilitationGestion = this.context.ctxHabilitation.HabilitationGestion;
      } else {
        hab.HabilitationGestion = this.context.ctxHabilitation.HabilitationGestion.prevues;
      }
    } else if (typeevenement === "R") {
      if (!_.isObject(this.context.ctxHabilitation.HabilitationAcces)) {
        hab.HabilitationAcces = this.context.ctxHabilitation.HabilitationAcces;
      } else {
        hab.HabilitationAcces = this.context.ctxHabilitation.HabilitationAcces.realisees;
      }
      if (!_.isObject(this.context.ctxHabilitation.HabilitationGestion)) {
        hab.HabilitationGestion = this.context.ctxHabilitation.HabilitationGestion;
      } else {
        hab.HabilitationGestion = this.context.ctxHabilitation.HabilitationGestion.realisees;
      }
    }
    contextGererActivite.ctxHabilitation = hab;

    return contextGererActivite;
  }

  _consultGererActivitesContext(activite: { [key: string]: any }): any {
    const contextGererActivite = this._initializeGererActivitesContext(activite.get("typeevenement"), activite.get("typologie").code);
    const recap = this.headerModel.get("value");
    contextGererActivite.ctxTypeEvtGere = [];
    contextGererActivite.ctxTypeEvtGere.push(activite.get("typeevenement"));

    //Check if activite can ge gere by recapitulatif
    const activiteGere = this._checkActiviteGereParRecap(activite, false);
    contextGererActivite.ctxDateConsultee = activite.currentDate;

    contextGererActivite.ctxGestionCollab = {};
    contextGererActivite.ctxGestionCollab = this.context.ctxCollab;
    contextGererActivite.ctxGestionCollab.nom = !CWSTR.isBlank(recap.get("collaborateur")) ? recap.get("collaborateur").nom : "";
    contextGererActivite.ctxGestionCollab.prenom = !CWSTR.isBlank(recap.get("collaborateur")) ? recap.get("collaborateur").prenom : "";
    contextGererActivite.ctxGestionCollab["matric_aux"] = !CWSTR.isBlank(recap.get("collaborateur")) ? recap.get("collaborateur")["matric_aux"] : "";

    contextGererActivite.ctxValeursEvenement = {};
    contextGererActivite.ctxValeursEvenement.ActTypeEvenement = activite.get("typeevenement");
    contextGererActivite.ctxValeursEvenement.ActCodeStatutWKF = activite.get("statut").code;
    contextGererActivite.ctxValeursEvenement.ActEnSuppression = false;
    contextGererActivite.ctxValeursEvenement.ActIdRecapitulatif = {};
    contextGererActivite.ctxValeursEvenement.ActIdRecapitulatif.RecapActCode = recap.get("code");
    contextGererActivite.ctxValeursEvenement.ActCode = activite.get("code");
    contextGererActivite.ctxValeursEvenement.ActTypeSaisie = activite.get("typesaisie");
    contextGererActivite.ctxValeursEvenement.ActModeSaisie = activite.get("modesaisie");
    contextGererActivite.ctxValeursEvenement.ActDateDebut = activite.get("datedeb");
    contextGererActivite.ctxValeursEvenement.ActUniteDebut = activite.get("unitedeb");
    contextGererActivite.ctxValeursEvenement.ActDateFin = activite.get("datefin");
    contextGererActivite.ctxValeursEvenement.ActUniteFin = activite.get("unitefin");
    contextGererActivite.ctxValeursEvenement.ActHeureDebut = activite.get("heuredeb");
    contextGererActivite.ctxValeursEvenement.ActHeureFin = activite.get("heurefin");
    contextGererActivite.ctxValeursEvenement.ActDuree = activite.get("duree");
    contextGererActivite.ctxValeursEvenement.ActPourcentage = activite.get("pourcentage");
    contextGererActivite.ctxValeursEvenement.ActInfoComplAffect = "";
    contextGererActivite.ctxValeursEvenement.ActListeInfoComplValeur = "";
    contextGererActivite.ctxValeursEvenement.ActCommentaireWKF = "";

    contextGererActivite.ctxValeursCreation = {};
    contextGererActivite.ctxHistoriqueWKF = true;
    contextGererActivite.ctxGestionCollective = false;
    contextGererActivite.ctxPieceJointe = this.context.ctxPieceJointe;

    contextGererActivite.ctxModeInitialisation = "Consultation";
    contextGererActivite.ctxIdentifiantEvt = activite.get("code");
    if (this.modeRepresentationModel.get("representation") === this.MONO) {
      // Consultation specific Context
      contextGererActivite.ctxModeRepresentation = "Imbrique";
    } else {
      // Creation specific Context
      contextGererActivite.ctxModeRepresentation = "pop-up";
    }
    if (activiteGere === true) {
      if (contextGererActivite.ctxModeRepresentation === "Imbrique") {
        contextGererActivite.ctxActionsPossibles = ["Creer", "Supprimer", "Modifier", "Dupliquer", "Declarer"];
      } else {
        contextGererActivite.ctxActionsPossibles = ["Supprimer", "Modifier", "Declarer"];
      }
      contextGererActivite.ctxDomainesActivites = this._getDomaineActivitesList(activite.get("typeevenement"));
    } else {
      contextGererActivite.ctxActionsPossibles = [];
      contextGererActivite.ctxDomainesActivites = [];
    }
    return contextGererActivite;
  }

  _instantierSaisieActiviteContext(typeEvenement: string, typologie: any, datedeb: any, datefin: any): any {
    const contextGererActivite = this._initializeGererActivitesContext(typeEvenement, typologie);
    const recap = this.headerModel.get("value");

    contextGererActivite.ctxTypeEvtGere = [];
    contextGererActivite.ctxTypeEvtGere.push(typeEvenement);
    //Check if activite can ge gere by recapitulatif

    contextGererActivite.ctxGestionCollab = {};
    contextGererActivite.ctxGestionCollab = this.context.ctxCollab;
    contextGererActivite.ctxGestionCollab.nom = !CWSTR.isBlank(recap.get("collaborateur")) ? recap.get("collaborateur").nom : "";
    contextGererActivite.ctxGestionCollab.prenom = !CWSTR.isBlank(recap.get("collaborateur")) ? recap.get("collaborateur").prenom : "";
    contextGererActivite.ctxGestionCollab["matric_aux"] = !CWSTR.isBlank(recap.get("collaborateur")) ? recap.get("collaborateur")["matric_aux"] : "";

    contextGererActivite.ctxValeursEvenement = {};
    contextGererActivite.ctxValeursEvenement.ActTypeEvenement = typeEvenement;
    contextGererActivite.ctxValeursEvenement.ActEnSuppression = false;
    contextGererActivite.ctxValeursEvenement.ActIdRecapitulatif = {};
    contextGererActivite.ctxValeursEvenement.ActIdRecapitulatif.RecapActCode = recap.get("code");
    contextGererActivite.ctxValeursEvenement.ActIdRecapitulatif.RecapActEta = recap.get("etat");
    contextGererActivite.ctxValeursEvenement.ActIdRecapitulatif.ActLibelleRecapitulatif = recap.get("libelle");
    contextGererActivite.ctxValeursEvenement.ActIdRecapitulatif.RecapActDateDeb = recap.get("datedeb");
    contextGererActivite.ctxValeursEvenement.ActIdRecapitulatif.RecapActDateFin = recap.get("datefin");
    contextGererActivite.ctxValeursEvenement.ActIdRecapitulatif.RecapEvenement = recap.get("evenement");
    contextGererActivite.ctxValeursEvenement.ActIdRecapitulatif.RecapStatutCode = recap.get("statut").code;
    contextGererActivite.ctxValeursEvenement.ActIdRecapitulatif.RecapStatutLibelle = recap.get("statut").libelle;

    contextGererActivite.ctxValeursCreation = {};
    contextGererActivite.ctxValeursCreation.ActDateDebut = datedeb;
    contextGererActivite.ctxValeursCreation.ActDateFin = datefin;

    contextGererActivite.ctxHistoriqueWKF = false;
    contextGererActivite.ctxGestionCollective = false;
    contextGererActivite.ctxPieceJointe = this.context.ctxPieceJointe;

    contextGererActivite.ctxModeInitialisation = "Ajout";
    contextGererActivite.ctxIdentifiantEvt = null;
    if (this.modeRepresentationModel.get("representation") === this.MONO) {
      // Consultation specific Context
      contextGererActivite.ctxModeRepresentation = "Imbrique";
    } else {
      // Creation specific Context
      contextGererActivite.ctxModeRepresentation = "pop-up";
    }
    contextGererActivite.ctxActionsPossibles = ["Creer", "Supprimer", "Modifier", "Dupliquer", "Declarer"];
    contextGererActivite.ctxDomainesActivites = this._getDomaineActivitesList(typeEvenement);

    return contextGererActivite;
  }

  _transformGererActiviteContext(activite: any): any {
    const contextGererActivite = this._initializeGererActivitesContext("R", activite.get("typologie").code);
    const recap = this.headerModel.get("value");

    //Check if activite can ge gere by recapitulatif
    contextGererActivite.ctxTypeEvtGere = [];
    contextGererActivite.ctxTypeEvtGere.push("R");

    contextGererActivite.ctxGestionCollab = {};
    contextGererActivite.ctxGestionCollab = this.context.ctxCollab;
    contextGererActivite.ctxGestionCollab.nom = !CWSTR.isBlank(recap.get("collaborateur")) ? recap.get("collaborateur").nom : "";
    contextGererActivite.ctxGestionCollab.prenom = !CWSTR.isBlank(recap.get("collaborateur")) ? recap.get("collaborateur").prenom : "";
    contextGererActivite.ctxGestionCollab["matric_aux"] = !CWSTR.isBlank(recap.get("collaborateur")) ? recap.get("collaborateur")["matric_aux"] : "";

    contextGererActivite.ctxValeursEvenement = {};
    contextGererActivite.ctxValeursEvenement.ActTypeEvenement = "R";
    contextGererActivite.ctxValeursEvenement.ActCodeStatutWKF = "";
    contextGererActivite.ctxValeursEvenement.ActEnSuppression = false;
    contextGererActivite.ctxValeursEvenement.ActIdRecapitulatif = {};
    contextGererActivite.ctxValeursEvenement.ActIdRecapitulatif.RecapActCode = recap.get("code");
    contextGererActivite.ctxValeursEvenement.ActCode = activite.get("activite");
    contextGererActivite.ctxValeursEvenement.ActLibelle = activite.get("libelle");
    contextGererActivite.ctxValeursEvenement.ActTypeSaisie = activite.get("typesaisie");
    contextGererActivite.ctxValeursEvenement.ActModeSaisie = activite.get("modesaisie");
    contextGererActivite.ctxValeursEvenement.ActDateDebut = activite.get("datedeb");
    contextGererActivite.ctxValeursEvenement.ActUniteDebut = activite.get("unitedeb");
    contextGererActivite.ctxValeursEvenement.ActDateFin = activite.get("datefin");
    contextGererActivite.ctxValeursEvenement.ActUniteFin = activite.get("unitefin");
    contextGererActivite.ctxValeursEvenement.ActHeureDebut = activite.get("heuredeb");
    contextGererActivite.ctxValeursEvenement.ActHeureFin = activite.get("heurefin");
    contextGererActivite.ctxValeursEvenement.ActDuree = activite.get("duree");
    contextGererActivite.ctxValeursEvenement.ActPourcentage = activite.get("pourcentage");
    contextGererActivite.ctxValeursEvenement.ActHorsPresAvant = activite.get("horspresav");
    contextGererActivite.ctxValeursEvenement.ActHorsPresApres = activite.get("horspresap");
    contextGererActivite.ctxValeursEvenement.ActModeSaisiePerioDebut = CWSTR.getElValue(activite, "modesai_periodeb.code");
    contextGererActivite.ctxValeursEvenement.ActModeSaisiePerioFin = CWSTR.getElValue(activite, "modesai_periofin.code");
    contextGererActivite.ctxValeursEvenement.ActInfoComplAffect = "";
    contextGererActivite.ctxValeursEvenement.ActListeInfoComplValeur = "";
    contextGererActivite.ctxValeursEvenement.ActCommentaireWKF = "";
    contextGererActivite.ctxValeursEvenement.ActHierarchieIdent = activite.get("hierid");
    contextGererActivite.ctxValeursEvenement.ActElementIdent = activite.get("straid");
    contextGererActivite.ctxValeursEvenement.ActIdStructure = activite.get("structid");
    contextGererActivite.ctxValeursEvenement.ActEvenement = activite.get("evenement");
    contextGererActivite.ctxValeursEvenement.ActTransformationAutorise = activite.get("transformation_auto");

    contextGererActivite.ctxValeursCreation = {};
    contextGererActivite.ctxValeursCreation.ActCode = "";
    contextGererActivite.ctxValeursCreation.ActDateDebut = "";
    contextGererActivite.ctxValeursCreation.ActDateFin = "";
    contextGererActivite.ctxValeursCreation.ActUniteDebut = "";
    contextGererActivite.ctxValeursCreation.ActUniteFin = "";
    contextGererActivite.ctxValeursCreation.ActHeureDebut = "";
    contextGererActivite.ctxValeursCreation.ActHeureFin = "";
    contextGererActivite.ctxValeursCreation.ActInfoComplAffect = "";

    contextGererActivite.ctxHistoriqueWKF = false;
    contextGererActivite.ctxGestionCollective = false;
    contextGererActivite.ctxPieceJointe = this.context.ctxPieceJointe;

    contextGererActivite.ctxModeInitialisation = "Transformation";
    contextGererActivite.ctxIdentifiantEvt = activite.get("code");
    if (this.modeRepresentationModel.get("representation") === this.MONO) {
      // Consultation specific Context
      contextGererActivite.ctxModeRepresentation = "Imbrique";
    } else {
      // Creation specific Context
      contextGererActivite.ctxModeRepresentation = "pop-up";
    }
    contextGererActivite.ctxActionsPossibles = [];
    contextGererActivite.ctxDomainesActivites = this._getDomaineActivitesList("R");

    return contextGererActivite;
  }

  _consulterAbsencesContext(absence: { [key: string]: any }): any {
    const recap = this.headerModel.get("value");
    const representation = this.modeRepresentationModel.get("representation");
    const contextAbsences: ContextGererAbsence = {
      ctxUtilisateur: this.context.ctxUtilisateur,
      ctxHabilitation: {
        HabilitationAcces: this.context.ctxHabilitation ? this.context.ctxHabilitation.HabilitationAcces.absences : "",
        HabilitationGestion: this.context.ctxHabilitation ? this.context.ctxHabilitation.HabilitationGestion.absences : "",
        HabilitationValidation: this.context.ctxHabilitation ? this.context.ctxHabilitation.HabilitationValidation.absences : ""
      },
      ctxEcran: this.context.ctxEcran,
      ctxGestionCollab: this.context.ctxCollab,
      ctxActionsPossibles: [],
      ctxGestionCollective: false,
      ctxModeRepresentation: representation === this.MONO ? "Imbrique" : "pop-up",
      ctxTypeEvenement: "Absence",
      ctxModeInitialisation: "Consultation",
      ctxIdentifiantEvt: absence.get("code") + "," + absence.get("table"),
      ctxValeursEvenement: {
        AbsCodeStatutWKF: absence.get("statut").code,
        AbsCodeMotif: absence.get("motif"),
        AbsDateDebut: absence.get("datedeb"),
        AbsUniteDebut: absence.get("unitedeb"),
        AbsDateFin: absence.get("datefin"),
        AbsUniteFin: absence.get("unitefin"),
        AbsHeureDuree: absence.get("duree"),
        AbsHeureDebut: absence.get("heuredeb"),
        AbsHeureFin: absence.get("heurefin"),
        AbsCommentaire: absence.get("commentaire")
      },
      ctxValeursCreation: {},
      ctxHistoriqueWKF: true,
      ctxPieceJointe: this.context.ctxPieceJointe,
      ctxRecapActivite: true
    };

    contextAbsences.ctxGestionCollab.nom = recap.get("collaborateur").nom;
    contextAbsences.ctxGestionCollab.prenom = recap.get("collaborateur").prenom;
    contextAbsences.ctxGestionCollab["matric_aux"] = recap.get("collaborateur")["matric_aux"];
    return contextAbsences;
  }

  styleFromStatus(status: string): string {
    let style = "";
    switch (status) {
      case "E":
        style = "ui-phx-statut-en-cours-saisie";
        break;
      case "F":
      case "G":
        style = "ui-phx-statut-en-cours-revision";
        break;
      case "C":
      case "M":
        style = "ui-phx-statut-en-cours-modification";
        break;
      case "Y":
      case "Z":
        style = "ui-phx-statut-a-revoir";
        break;
      case "A":
        style = "ui-phx-statut-accepte";
        break;
      case "D":
        style = "ui-phx-statut-demande";
        break;
      case "H":
        style = "ui-phx-statut-hors-validation";
        break;
      default:
        style = "";
    }
    return style;
  }

  recapHasActivity(): boolean {
    return this.contenuRecapActivites && !_.isEmpty(this.contenuRecapActivites.get("activites"));
  }

  isActiveWorkflowRecap(): boolean {
    let rtn = true;

    if (this.wkfEvtColl && this.wkfEvtColl.length > 0) {
      rtn = this.wkfEvtColl.models[0].get("active");
    }
    return rtn;
  }
}
