module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class=\'cw-navSituation\' style="display: inline-flex;">\n    <div class=\'cw-liensNavegation\'>\n        <a class="linkVueJournee" style="display: block; margin-bottom: 5px;"">\n            <span class=" menuText cw-navSituation__lien">'+
((__t=(i18n.t('suivicollab.menuVueJournee')))==null?'':__t)+
'</span>\n        </a>\n        <a class="linkSituation" style="display: block; margin-bottom: 5px;"">\n            <span class=" menuText cw-navSituation__lien">'+
((__t=(i18n.t('fichecollab.menuSituation')))==null?'':__t)+
'</span>\n        </a>\n    </div>\n    <div style="display: flex;">\n        <div class="cw-navigation-suivicoll" style="align-self: flex-end;">\n            <span class="cwMenuButton btn btn-secondary cw-btn__navSuiviColl">\n                '+
((__t=(UTILS.getSVGIcon('fleche_haut','',12)))==null?'':__t)+
'\n            </span>\n        </div>\n    </div>\n</div>';
}
return __p;
};
