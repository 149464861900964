module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="progressBar-container">\n    <div class="cw-texteNormal messages">\n        <span class="waitmessage"></span>\n        <span class="valueText ui-phx-ihm-texte-donnees"></span>\n    </div>\n    <div class="progressBarContainer">\n        <div class="progress-bar"></div>\n        ';
 if (!isBtnStop){ 
__p+='\n            <div class="btnPause btn btn-secondary btn-onlyIcon">\n                '+
((__t=(UTILS.getSVGIcon('rectangle_debout','')))==null?'':__t)+
'\n        ';
 }else { 
__p+='\n            <div class="btnStop btn btn-secondary btn-onlyIcon">\n                '+
((__t=(UTILS.getSVGIcon('carre','')))==null?'':__t)+
'\n        ';
}
__p+='\n            </div>\n    </div>\n</div>';
}
return __p;
};
