import _ from 'underscore';
import { CWLOG } from 'utils/cwLog';
import { CWSTR } from 'utils/cwStr';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { SYNC } from 'utils/sync.js';
import { UTILS } from 'utils/utils.js';

declare global {
  interface Window {
    lastFocusedInput: any;
  }
}

/**
 * Conversion of types
 *
 * */
//export class CWTYPE {
export class CWTYPE {

  /**
   * 		This is used when we want to add automatic events, formatting, parsing to a filed.
   */
  static supportedClass = ".typeHourMinute, " +
    ".typeHourMinuteSecond, " +
    ".typeHourMinuteNightly, " +
    ".typeDurationHMS, " +
    ".typeDurationHM, " +
    ".typeDuration24HM, " +
    ".typeDurationHC, " +
    ".typeDuration24HC, " +
    ".typeDurationMN, " +
    ".typeShort, " +
    ".typeLong, " +
    ".typeCustom, " +
    ".typeDecimal, " +
    ".typeDecimal_1, " +
    ".typeDecimal_2, " +
    ".typeJour, " +
    ".typeJour_0, " +
    ".typeJour_1, " +
    ".typeJour_2, " +
    ".typePercentage, " +
    ".typePercentage_0, " +
    ".typePercentage_1, " +
    ".typePercentage_2, " +
    ".typeDate";

  /**
   * 		This is used when want to define a specific mask for a field or function.
   */

  /**
   * Get the Default Saisie Mask (for INPUT Data) in the array typeapplicatif by typeClass.
   */
  static _getMaskByType(type: string): string {
    let lRtn = "";
    const code = CWTYPE._getCodeByTypeClass(type);

    if (!CWSTR.isBlank(code)) {
      lRtn = CWTYPE._getMaskByCode(code);
    }
    return lRtn;
  }

  /**
   * Get the Default Format Mask (for SHOW data) in the array typeapplicatif by typeClass.
   */
  static _getFormatByType(type: string): string {
    let lRtn = "";
    const code = CWTYPE._getCodeByTypeClass(type);

    if (!CWSTR.isBlank(code)) {
      lRtn = CWTYPE._getFormatByCode(code);
    }
    return lRtn;
  }

  /**
   * Get the default CWTYPE code by typeClass.
   */
  static _getCodeByTypeClass(typeClass: string): string {
    const array: any = [];

    array["typeHourMinute"] = "INSTHM";
    array["typeHourMinuteSecond"] = "INSTHMS";
    array["typeHourMinuteNightly"] = "INSTHM";
    array["typeDurationHM"] = "DUREEHM";
    array["typeDurationHMS"] = "DUREEHMS";
    array["typeDuration24HM"] = "DUR24HM";
    array["typeDurationHC"] = "DUREEHC";
    array["typeDuration24HC"] = "DUR24HC";
    array["typeDurationMN"] = "DUREEMN";
    array["typeShort"] = "ENTCOURT";
    array["typeLong"] = "ENTLONG";
    array["typeDecimal"] = "DECIMAL_2";
    array["typeDecimal_1"] = "DECIMAL_1";
    array["typeDecimal_2"] = "DECIMAL_2";
    array["typeJour"] = "NBJOUR_2";
    array["typeJour_0"] = "NBJOUR_0";
    array["typeJour_1"] = "NBJOUR_1";
    array["typeJour_2"] = "NBJOUR_2";
    array["typePercentage"] = "POURCENT_2";
    array["typePercentage_0"] = "POURCENT_0";
    array["typePercentage_1"] = "POURCENT_1";
    array["typePercentage_2"] = "POURCENT_2";
    array["typeDate"] = "DATE";
    return array[typeClass];
  }

  /**
   * Get the default CWTYPE code by typeClass.
   */
  static _getTypeClassByCode(code: string): string {
    const array: any = [];

    array["DATE"] = "typeDate";
    array["DATE_A"] = "typeDate";
    array["DATE_L"] = "typeDate";
    array["DATEHEURE"] = "typeDate";
    array["INSTHM"] = "typeHourMinute";
    array["INSTHMS"] = "typeHourMinuteSecond";
    array["DUREEHM"] = "typeDurationHM";
    array["DUREEHMS"] = "typeDurationHMS";
    array["DUREEHC"] = "typeDurationHC";
    array["DUREEMN"] = "typeDurationMN";
    array["DUR24HM"] = "typeDuration24HM";
    array["DUR24HC"] = "typeDuration24HC";
    array["ENTCOURT"] = "typeShort";
    array["ENTLONG"] = "typeLong";
    array["NBJOUR_0"] = "typeJour_0";
    array["NBJOUR_1"] = "typeJour_1";
    array["NBJOUR_2"] = "typeJour_2";
    array["POURCENT_0"] = "typePercentage_0";
    array["POURCENT_1"] = "typePercentage_1";
    array["POURCENT_2"] = "typePercentage_2";
    array["DECIMAL_1"] = "typeDecimal_1";
    array["DECIMAL_2"] = "typeDecimal_2";
    return array[code];
  }

  /**
   * Returns the CWTYPE object by the typeClass name.
   */
  static _getTypeByTypeClass(typeClass: string): any {
    const array: any = [];

    array["typeHourMinute"] = CWTYPE.HOUR_MINUTE;
    array["typeHourMinuteSecond"] = CWTYPE.HOUR_MINUTE_SECONDS;
    array["typeHourMinuteNightly"] = CWTYPE.HOUR_MINUTE_NIGHTLY;
    array["typeDurationHM"] = CWTYPE.DURATION.HOUR_MINUTE;
    array["typeDurationHMS"] = CWTYPE.DURATION.HOUR_MINUTE_SECONDS;
    array["typeDuration24HM"] = CWTYPE.DURATION.HOUR_MINUTE_24;
    array["typeDurationHC"] = CWTYPE.DURATION.HOUR_HUNDREDTH;
    array["typeDuration24HC"] = CWTYPE.DURATION.HOUR_HUNDREDTH_24;
    array["typeDurationMN"] = CWTYPE.DURATION.MINUTES;
    array["typeShort"] = CWTYPE.SHORT;
    array["typeLong"] = CWTYPE.LONG;
    array["typeDecimal"] = CWTYPE.DECIMAL;
    array["typeDecimal_1"] = CWTYPE.DECIMAL_1;
    array["typeDecimal_2"] = CWTYPE.DECIMAL_2;
    array["typeJour"] = CWTYPE.JOUR;
    array["typeJour_0"] = CWTYPE.JOUR_0;
    array["typeJour_1"] = CWTYPE.JOUR_1;
    array["typeJour_2"] = CWTYPE.JOUR_2;
    array["typePercentage"] = CWTYPE.PERCENTAGE;
    array["typePercentage_0"] = CWTYPE.PERCENTAGE_0;
    array["typePercentage_1"] = CWTYPE.PERCENTAGE_1;
    array["typePercentage_2"] = CWTYPE.PERCENTAGE_2;
    array["typeDate"] = CWTYPE.DATE;
    array["typeCustom"] = CWTYPE.CUSTOM;
    return array[typeClass];
  }

  /**
   * Returns the CWTYPE object by the type code.
   */
  static _getTypeByCode(code: string): any {
    const array: any = [];

    array["DATE"] = CWTYPE.DATE;
    array["DATE_A"] = CWTYPE.DATE;
    array["DATE_L"] = CWTYPE.DATE;
    array["DATEHEURE"] = CWTYPE.DATE;
    array["INSTHM"] = CWTYPE.HOUR_MINUTE;
    array["INSTHMS"] = CWTYPE.HOUR_MINUTE_SECONDS;
    array["DUREEHM"] = CWTYPE.DURATION.HOUR_MINUTE;
    array["DUREEHMS"] = CWTYPE.DURATION.HOUR_MINUTE_SECONDS;
    array["DUREEHC"] = CWTYPE.DURATION.HOUR_HUNDREDTH;
    array["DUREEMN"] = CWTYPE.DURATION.MINUTES;
    array["DUR24HM"] = CWTYPE.DURATION.HOUR_MINUTE_24;
    array["DUR24HC"] = CWTYPE.DURATION.HOUR_HUNDREDTH_24;
    array["ENTCOURT"] = CWTYPE.SHORT;
    array["ENTLONG"] = CWTYPE.LONG;
    array["NBJOUR_0"] = CWTYPE.JOUR_0;
    array["NBJOUR_1"] = CWTYPE.JOUR_1;
    array["NBJOUR_2"] = CWTYPE.JOUR_2;
    array["POURCENT_0"] = CWTYPE.PERCENTAGE_0;
    array["POURCENT_1"] = CWTYPE.PERCENTAGE_1;
    array["POURCENT_2"] = CWTYPE.PERCENTAGE_2;
    array["DECIMAL_1"] = CWTYPE.DECIMAL_1;
    array["DECIMAL_2"] = CWTYPE.DECIMAL_2;
    array["BOOLEEN"] = CWTYPE.BOOLEAN;
    array["EMAIL"] = CWTYPE.EMAIL;
    array["RESTDIV97"] = CWTYPE.RESTDIV97;
    return array[code];
  }

  /**
   * Get the appropriate saisie mask in the typeapplicatif Array.
   */
  static _getMaskByCode(code: string): string {
    let mask = GLOBAL_DATA.types.get(code);

    if (!CWSTR.isBlank(mask)) {
      mask = mask.get("masque");
    }
    return mask;
  }

  /**
   * Get the appropriate format mask in the typeapplicatif Array.
   */
  static _getFormatByCode(code: string): string {
    let mask = GLOBAL_DATA.types.get(code);

    if (!CWSTR.isBlank(mask)) {
      mask = mask.get("format");
    }
    return mask;
  }

  /**
   * Returns the decimal of the mask with the code.
   */
  static _getDecimalsByCode(code: string): number {
    const mask = GLOBAL_DATA.types.get(code);
    let decimals = 0;

    if (!CWSTR.isBlank(mask)) {
      decimals = mask.get("decimale");
    }
    return decimals;
  }

  /**
   * Returns the code of the masks for the current attribute.
   * Search in a view if any type or attribute has defined a
   * specific Masks with typeMaskByClass[] in view or returns the default
   * value for the this.
   */
  static _getMaskCode(attr: string, view: { [key: string]: any }, typeClass: string): string {
    let maskCode = null;

    if (!CWSTR.isBlank(view.typeMaskByClass)) {
      //Check input class defined masks
      maskCode = view.typeMaskByClass[attr as any];
      if (CWSTR.isBlank(maskCode)) {
        //Check UC Type masks changes
        if (!CWSTR.isBlank(typeClass)) {
          maskCode = view.typeMaskByClass[typeClass as any];
        }
      }
    }
    if (CWSTR.isBlank(maskCode)) {
      //If not custom Return the default
      maskCode = CWTYPE._getTypeByTypeClass(typeClass).DEFAULT_MASK_CODE;
    }
    return maskCode;
  }

  /**
   * Returns a mask based on a format given for typeCustom class.
   * It counts the total of numbers self there could be in the field and
   * give them to the mask.
   */
  static _getMaskByFormat(formatMask: string): string {
    let mask = "";
    let numberSize = 0;
    let totalMinus = 0;
    let totalPlus = 0;
    let thereIsAMinus = false;
    let thereIsAPlus = false;

    //We count how many numbers could the field have
    for (let i = 0; i < formatMask.length; i++) {
      if (formatMask[i] === "&" || formatMask[i] === "*" || formatMask[i] === "#" ||
        formatMask[i] === "<") {
        numberSize++;
      }
      if (formatMask[i] === "-") {
        totalMinus++;
      }
      if (formatMask[i] === "+") {
        totalPlus++;
      }
    }
    if (totalMinus !== 0) {
      numberSize = numberSize + totalMinus;
      thereIsAMinus = true;
    } else if (totalPlus !== 0) {
      numberSize = numberSize + totalPlus;
      thereIsAPlus = true;
    }
    //We create the mask based on how many numbers there  are and if there is a - or a +
    for (let i = 0; i < numberSize; i++) {
      if (i === 0 && thereIsAMinus) { //There can be a - at first position of the mask
        mask = mask + "-";
      } else if (i === 0 && thereIsAPlus) { //There can be a - at first position of the mask
        mask = mask + "-";
      }
      else {
        mask = mask + "&";
      }
    }
    return mask;
  }

  /**
   * Checks if the parametre is an Integer number
   */
  static _isInt(valeur: any): boolean {
    if (!CWSTR.isBlank(valeur)) {
      const numberPattern = /^[+-]?[0-9]+$/;

      if (numberPattern.test(valeur.toString())) {
        return true;
      }
    }
    return false;
  }

  /**
   * Gets the position of the Caret at the passed input (from the left).
   */
  static _getCaretPosition(ctrl: { [key: string]: any }): number {
    let CaretPos = 0;

    if (ctrl.selectionStart || ctrl.selectionStart === '0') {
      CaretPos = ctrl.selectionStart;
    }
    return (CaretPos);
  }

  /**
   * Gets if the input has a selection.
   */
  static _isCaretSelection(ctrl: { [key: string]: any }): boolean {
    let selection = false;

    if (ctrl.selectionStart !== ctrl.selectionEnd) {
      selection = true;
    }
    return selection;
  }

  /**
   * Focus and set the Caret to the pos position at ctrl HTML input element
   */
  static _setCaretPosition(ctrl: { [key: string]: any }, pos: number): void {
    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(pos, pos);
    } else if (ctrl.createTextRange) {
      const range = ctrl.createTextRange();

      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  }

  /**
   * Main function of types focus event, apply the saisie mask to the current
   * input value and saves the orig value in TEMP variable.
   */
  static _eventFocus(e: any, typeClass: string, type: { [key: string]: any }, view: { [key: string]: any }, formatMask?: string): void {
    //Get the attribute name and his model value
    let attr = e.target.className.split(" ")[0];
    let attrModelValue = null;
    let inputMask = "";

    if (view.dynamicAttributes === true && view._convertDynamicAttributes) {
      attr = view._convertDynamicAttributes(attr);
    }
    attrModelValue = CWSTR.getElValue(view._getModel(attr), attr);
    //Set temporal Original Value on the view to detect Changes
    view.tempOrigVal = !CWSTR.isBlank(attrModelValue) ? attrModelValue : "";
    //Get the input Mask
    if (CWSTR.isBlank(formatMask)) { //When typeClass is not typeCustom
      const maskCode = CWTYPE._getMaskCode(attr, view, typeClass);

      inputMask = CWTYPE._getMaskByCode(maskCode);
    } else {//When typeClass is typeCustom
      inputMask = CWTYPE._getMaskByFormat(formatMask);
    }
    //Change the value of the input
    if ($(e.target).hasClass("infinityDate") && attrModelValue === CWTYPE.DATE.INFINITY && GLOBAL_DATA.paramDivers.get("CACH_INFIN").get("valeur") === "1") {
      $(e.target).val(type.parseOnFocus("", inputMask));
    } else {
      $(e.target).val(type.parseOnFocus(attrModelValue, inputMask));
    }
    //Select the content of the input
    if (CWSTR.isBlank($(e.target).attr("id"))) {
      window.lastFocusedInput = { type: "className", value: e.target.className };
    } else {
      window.lastFocusedInput = { type: "id", value: $(e.target).attr("id") };
    }

    window.setTimeout(() => {
      if (window.lastFocusedInput &&
        ((window.lastFocusedInput.type === "id" && $(e.target).attr("id") === window.lastFocusedInput.value) ||
          (window.lastFocusedInput.type === "className" && e.target.className === window.lastFocusedInput.value))) {
        e.target.select();
        e.target.scrollLeft = e.target.scrollWidth;
        window.lastFocusedInput = { type: "id", value: "" };
      }
    }, 50);
  }

  /**
   * Main function of types blur event, parse the content trigger a change to modify the model,
   * show/clean errors at the input triggered, and format the input with the format mask.
   */
  static _eventBlur(e: any, typeClass: string, type: { [key: string]: any }, view: { [key: string]: any }, formatMask?: string): void {
    let attr = e.target.className.split(" ")[0];
    const parse = type.parse(e.target.value); //Parse the input and format the input

    //CWLOG.debug("----------------Blur:" + $(e.target).attr("id"));
    //Get the attribute name and his model value
    if (view.dynamicAttributes === true && view._convertDynamicAttributes) {
      attr = view._convertDynamicAttributes(attr);
    }
    //Get the format Mask
    if (CWSTR.isBlank(formatMask)) {
      const maskCode = CWTYPE._getMaskCode(attr, view, typeClass);

      formatMask = CWTYPE._getFormatByCode(maskCode);
    }
    if (typeClass === "typeHourMinuteNightly") { //Anomalie 118031 For the night hours we want to check also the type as the string represent not validated values and it was not trigering the change event from 25:00 to +1:00
      //si la valeur du champ est distinct de "__:__", on fait la vérification avec origVal
      //Check changes on the original Value
      if (String(view.tempOrigVal) !== String(parse["val" as any]) && type.parseOnFocus(String(view.tempOrigVal), CWTYPE._getMaskByCode(CWTYPE._getMaskCode(attr, view, typeClass))) !== e.target.value) {
        $(e.target).change();
      }
    } else {
      //Check changes on the original Value
      if (String(view.tempOrigVal) !== String(parse["val" as any]) || (String(view.tempOrigVal) === "0" && String(view.tempOrigVal) !== parse["val" as any])) {
        $(e.target).change();
      }
    }
    if (!parse["errors" as any]) {
      $(e.target).val(type.format(parse["val" as any], formatMask));
    }
    delete view.tempOrigVal;
  }

  /**
   * Update the input with the new value.
   */
  static _eventKeypress(e: any, typeClass: string, type: { [key: string]: any }, view: { [key: string]: any }, left2Right?: boolean, formatMask?: string): boolean {
    const key = e.which || e.keyCode;
    const attr = e.target.className.split(" ")[0];
    let inputMask = "";
    const bloqued = [9, 16, 17, 18, 20, 35, 36, 37, 38, 39, 40, 144];

    if (key === 8 || key === 46) {
      e.preventDefault();
      return true;
    }
    // If ctrl + c or ctrl+v do nothing.
    if (e.ctrlKey && (key === 99 || key === 118)) {
      return true;
    }
    //Get the input Mask
    if (CWSTR.isBlank(formatMask)) { //When typeClass is not typeCustom
      const maskCode = CWTYPE._getMaskCode(attr, view, typeClass);

      inputMask = CWTYPE._getMaskByCode(maskCode);
    } else { //When typeClass is typeCustom
      inputMask = CWTYPE._getMaskByFormat(formatMask);
    }
    if (_.indexOf(bloqued, key) === -1) {
      let caretPos = 0;
      const begin = e.target.selectionStart;
      const end = e.target.selectionEnd;
      const old = $(e.target).prop("previousValue");
      let newValue = e.target.value.slice(0, begin) + String.fromCharCode(key) + e.target.value.slice(end);
      const regex = /^-?[0-9//_]+$/;
      let isCorrectPatterNewValue = true;
      let valAux = null;

      if (left2Right) {
        caretPos = CWTYPE._getCaretPosition(e.target);
      } else {
        caretPos = e.target.value.length - CWTYPE._getCaretPosition(e.target);
      }
      if (CWSTR.isBlank(newValue)) {
        newValue = e.target.value;
      }
      isCorrectPatterNewValue = regex.test(newValue);
      valAux = newValue.replace(/_/g, ""); //sans le character "_"
      if (!isCorrectPatterNewValue && !CWSTR.isBlank(newValue) && (regex.test(valAux) || valAux === "-")) {
        isCorrectPatterNewValue = true;//pour ne pas faire -> newValue = old;
      }
      if (!isCorrectPatterNewValue && regex.test(old)) {
        //par l'action d'event blur (cas s'il y a un fetch-> montre la roue tournante->hidder),
        //la valeur peut changer quand un autre masque est appliqué. Avec cette action, nous rétablissons la valeur et
        //éviter les erreurs de formatage, telles que 2/20/20__ pour le 02/01/2020
        newValue = old;
      }
      $(e.target).val(CWTYPE.MASK.applyInputMask(newValue, inputMask, old, typeClass));
      $(e.target).prop("previousValue", e.target.value);
      if (left2Right) {
        const separators = CWTYPE.MASK._getSeparatorsFromMask(inputMask);

        if (_.contains(separators, e.target.value[begin])) {
          caretPos += 1; // Move the caret to the right
        }
        CWTYPE._setCaretPosition(e.target, caretPos + 1);
      } else {
        caretPos -= end - begin; // Move the caret the selection size
        CWTYPE._setCaretPosition(e.target, e.target.value.length - caretPos);
      }
      e.preventDefault();
    }
    return true;
  }

  /**
   *	Update the input in a delete character event when the direction is right to left
   */
  static _eventDownRight2Left(e: any, typeClass: string, type: { [key: string]: any }, view: { [key: string]: any }, formatMask?: string): void {
    let caretPos;
    const key = e.which || e.keyCode;
    const attr = e.target.className.split(" ")[0];
    let inputMask = "";
    const value = $(e.target).val();

    if (CWSTR.isBlank(formatMask)) { //When typeClass is not typeCustom
      const maskCode = CWTYPE._getMaskCode(attr, view, typeClass);

      inputMask = CWTYPE._getMaskByCode(maskCode);
    } else { //When typeClass is typeCustom
      inputMask = CWTYPE._getMaskByFormat(formatMask);
    }
    // if I'm moving the cursor with the arrows I don't let the cursor pass the value
    if (key === 37 || key === 38 || key === 39 || key === 40) {
      let valueLength = 0;

      caretPos = e.target.value.length - CWTYPE._getCaretPosition(e.target);
      if (key === 37) {
        caretPos++;
      } else {
        caretPos--;
      }
      valueLength = e.target.value.replace(/_/g, "").length;
      if (caretPos >= valueLength) {
        CWTYPE._setCaretPosition(e.target, e.target.value.length - valueLength);
        e.preventDefault();
      }
    }
    //backspace, delete, and escape get special treatment
    else if (key === 8 || key === 46) {
      let begin = e.target.selectionStart;
      let end = e.target.selectionEnd;
      let newValue = null;
      let old = null;

      caretPos = e.target.value.length - CWTYPE._getCaretPosition(e.target);
      // Move the caret the selection size
      caretPos -= end - begin;
      if (key === 8) { // Backspace, delete the next
        old = value;
        if (begin === end) {
          begin--;
          const separators = CWTYPE.MASK._getSeparatorsFromMask(inputMask);

          if (_.contains(separators, e.target.value.slice(begin, end))) {
            caretPos += 1; // Move the caret to the left
          }
        }
        newValue = e.target.value.slice(0, begin) + e.target.value.slice(end);
        $(e.target).val(CWTYPE.MASK.applyInputMask(newValue, inputMask, old, typeClass));
      } else if (key === 46) { // Delete, delete de previous
        old = value;
        if (begin === end) {
          end++;
          caretPos -= 1; // Move the caret to the right
        }
        newValue = e.target.value.slice(0, begin) + e.target.value.slice(end);
        $(e.target).val(CWTYPE.MASK.applyInputMask(newValue, inputMask, old, typeClass));
      }
      CWTYPE._setCaretPosition(e.target, e.target.value.length - caretPos);
      e.preventDefault();
    } else if (CWTYPE._isCaretSelection(e.target) === false) {
      const valueLength = e.target.value.replace(/_/g, "").length;

      caretPos = e.target.value.length - CWTYPE._getCaretPosition(e.target);
      if (caretPos >= valueLength) {
        CWTYPE._setCaretPosition(e.target, e.target.value.length - valueLength);
      }
      //CWLOG.debug("keydown:" + caretPos + "  " + valueLength + "  " + (e.target.value.length - valueLength));
    }
  }

  /**
   * Update the input in a delete character event when the direction is left to right
   */
  static _eventDownLeft2Right(e: any, typeClass: string, type: { [key: string]: any }, view: { [key: string]: any }): void {
    const key = e.which || e.keyCode;
    let caretPos,
      begin,
      end;
    const attr = e.target.className.split(" ")[0];
    const maskCode = CWTYPE._getMaskCode(attr, view, typeClass);
    const inputMask = CWTYPE._getMaskByCode(maskCode);
    const value = $(e.target).val();

    //backspace, delete, and escape get special treatment
    if (key === 8 || key === 46) {
      caretPos = CWTYPE._getCaretPosition(e.target);
      begin = e.target.selectionStart;
      end = e.target.selectionEnd;

      if (key === 8) { // Backspace, delete the next
        const old = value;
        let newValue = null;

        if (begin === end) {
          begin--;
          caretPos -= 1; // Move the caret to the left
        }
        newValue = e.target.value.slice(0, begin) + e.target.value.slice(end);
        $(e.target).val(CWTYPE.MASK.applyInputMask(newValue, inputMask, old, typeClass));
      } else if (key === 46) { // Delete, delete de previous
        const old = value;
        let newValue = null;

        if (begin === end) {
          const separators = CWTYPE.MASK._getSeparatorsFromMask(inputMask);

          end++;
          if (_.contains(separators, e.target.value.slice(begin, end))) {
            caretPos += 1; // Move the caret to the right
          }
        }
        newValue = e.target.value.slice(0, begin) + e.target.value.slice(end);
        $(e.target).val(CWTYPE.MASK.applyInputMask(newValue, inputMask, old, typeClass));
      }
      CWTYPE._setCaretPosition(e.target, caretPos);
      e.preventDefault();
    }
  }

  /**
   * Returns the max Size of the input,format Masks of the MaskCode.
   */
  static _getInputLongByCode(tdaCode: string, defaultSize: number): number {
    const inputMask = CWTYPE._getMaskByCode(tdaCode);
    const formatMask = CWTYPE._getFormatByCode(tdaCode);
    let size = defaultSize || 20;

    if (inputMask && formatMask) {
      size = Math.max(inputMask.length, formatMask.length);
    } else if (inputMask) {
      size = inputMask.length;
    } else if (formatMask) {
      size = formatMask.length;
    }
    return size;
  }

  /**
   * Fill the number with zeros from Left
   */
  static addZerosFromLeft(numb: number, length: number): string {
    const zero = "0";
    let str = CWSTR.isBlank(numb) ? zero : numb.toString();

    while (str.length < length) {
      str = zero + str;
    }
    return str;
  }

  /**
   * Fill the number with zeros from Right
   */
  static addZerosFromRight = function (numb: number, length: number): string {
    const zero = "0";
    let str = CWSTR.isBlank(numb) ? zero : numb.toString();

    while (str.length < length) {
      str = str + zero;
    }
    return str;
  }

  /**
 * Type self defines the Mask utils functions to be used on all the other types.
 */
  static MASK = {

    SEPARATOR_SYMBOL: undefined as any,
    MILLAR_SYMBOL: undefined as any,

    ERROR_INCOMPLETE: "Saisie incomplète",

    ERROR_IMPOSSIBLE: "Saisie impossible",

    /**
     * Apply mask to an attribute to transform the model data into screen visual
     */
    applyFormatMask: function (valeur: any, mask?: string, separator?: string, millar?: string): any {
      let val = valeur;
      let lMillar = millar;
      let lSeparator = separator;
      let out = "";
      let n = "";
      let negative = false;
      let maskWithSing = false;
      let error = undefined;
      let maskDigitLength = 0;
      let maskPos = 0;
      let valPos = 0;
      let whiteCount = 0;
      let valDigitsNumber = null;

      //If mask in blank return the val
      if (CWSTR.isBlank(mask)) {
        return val;
      }
      //If val is blank return "" and don't process.
      if (CWSTR.isBlank(val)) {
        return "";
      } else {
        val = val.toString();
      }
      if (CWSTR.isBlank(lSeparator)) {
        lSeparator = CWTYPE.MASK.SEPARATOR_SYMBOL;
      }
      if (CWSTR.isBlank(lMillar)) {
        lMillar = CWTYPE.MASK.MILLAR_SYMBOL;
      }
      //Clean the input
      val = CWTYPE.MASK._cleanInput(val);
      maskPos = mask.length;
      valPos = val.length;
      //NEGATIVE Input NUMBER ?
      if (!CWSTR.isBlank(val)) {
        if (val[0] === "-") {
          negative = true;
          val = val.substr(1);
          valPos--;
        }
      }
      //POSITIVE Input NUMBER ?
      if (!CWSTR.isBlank(mask)) {
        if (mask[0] === "+") {
          maskWithSing = true;
        }
      }
      if (!CWSTR.isBlank(mask)) {
        //MaskLength
        for (let i = 0; i < mask.length; i++) {
          if (mask[i] === "#" || mask[i] === "&" || mask[i] === "$" || mask[i] === "<" ||
            mask[i] === "*" || mask[i] === "-" || mask[i] === "+") {
            maskDigitLength++;
          }
        }
      }
      //Check Size,
      valDigitsNumber = val.match(/[0-9]/g);
      if (!CWSTR.isBlank(valDigitsNumber) && valDigitsNumber.length > maskDigitLength) {
        error = "GL_1003";
      } else {
        valPos--;
        maskPos--;
        for (maskPos; maskPos >= 0; maskPos--) {
          n = val[valPos];
          switch (mask[maskPos]) {
            case "*":
              out = (!CWSTR.isBlank(n)) ? n + out : "*" + out;
              valPos--;
              break;
            case "#":
              if (!CWSTR.isBlank(n)) {
                out = n + out;
              } else {
                whiteCount++;
              }
              valPos--;
              break;
            case "&":
              out = (!CWSTR.isBlank(n)) ? n + out : "0" + out;
              valPos--;
              break;
            case "<":
              out = (!CWSTR.isBlank(n)) ? n + out : out + " ";
              valPos--;
              break;
            case millar:
              if (!CWSTR.isBlank(n)) {
                out = millar + out;
              } else {
                out = String(out);
              }
              break;
            case separator:
              //Decimal are processed later
              break;
            case "-":
              if (!CWSTR.isBlank(n)) {
                out = n + out;
              } else {
                if (negative) {
                  if (out.indexOf("-") === -1) {
                    out = "-" + out;
                  } else {
                    whiteCount++;
                  }
                } else {
                  whiteCount++;
                }
              }
              valPos--;
              break;
            case "+":
              if (!CWSTR.isBlank(n)) {
                out = n + out;
              } else {
                if (negative) {
                  if (out.indexOf("-") === -1) {
                    out = "-" + out;
                  } else {
                    whiteCount++;
                  }
                } else if (maskWithSing) {
                  if (out.indexOf("+") === -1) {
                    out = "+" + out;
                  } else {
                    whiteCount++;
                  }
                } else {
                  whiteCount++;
                }
              }
              valPos--;
              break;
            case "(":
              if (negative) {
                out = "(" + out;
              }
              break;
            case ")":
              if (negative) {
                out = out + ")";
              }
              break;
            case "$":
              if (!CWSTR.isBlank(n)) {
                out = n + out;
              } else {
                if ("$" === out[0] || " " === out[0]) {
                  whiteCount++;
                } else {
                  out = "$" + out;
                }
              }
              valPos--;
              break;
            default:
              out = mask[maskPos] + out;
          }
        }
        for (let i = 0; i < whiteCount; i++) {
          out = " " + out;
        }
      }
      if (!CWSTR.isBlank(error)) {
        out = "";
        for (let i = 0; i < maskDigitLength; i++) {
          out = out + "?";
        }
      }
      return out;
    },

    /**
     * Function self transform the input data into new one when edit a field.
     */
    applyInputMask: function (input: any, mask: string, previousValue?: any, typeClass?: string): any {
      let out = "";
      let allowSign = false;
      let sign = undefined;
      let numberSize = 0;
      let alreadySigned = false;
      let maskIt = null;
      let inputIt = null;

      if (CWSTR.isBlank(mask)) {
        return input;
      }
      //If typeDate call to his function
      if (typeClass === CWTYPE.DATE.TYPE_CLASS) {
        return CWTYPE.MASK.applyDateInputMask(input, mask, previousValue);
      }
      //Check if negative Mask and quit self symbol
      if (!CWSTR.isBlank(mask)) {
        if (mask[0] === "-") {
          allowSign = true;
          //Removes the "-" from the mask
          mask = mask.substr(1);
        }
      }
      //If HM we need to conserve the nightSymbol +
      if (typeClass === CWTYPE.HOUR_MINUTE_NIGHTLY.TYPE_CLASS) {
        input = CWTYPE.MASK._cleanInput(input, ["+"]);
        allowSign = true;
      } else {
        if (allowSign) {
          input = CWTYPE.MASK._cleanInput(input, ["+"]);
        } else {
          input = CWTYPE.MASK._cleanInput(input);
        }
      }
      //Detect if the input has any sign
      if (!CWSTR.isBlank(input)) {
        if (input[0] === "-" || input[0] === "+") {
          sign = input[0];
          //Removes the sign from the input
          input = input.substr(1);
        }
        //Trick
        if (typeClass === CWTYPE.HOUR_MINUTE_NIGHTLY.TYPE_CLASS && sign !== "+") {
          sign = undefined;
        }
      }
      //If the input is larger than the mask number capacity return previous
      for (let i = 0; i < mask.length; i++) {
        if (mask[i] === "&") {
          numberSize++;
        }
      }
      if (numberSize < input.length) {
        return previousValue;
      }
      //Replacement
      maskIt = mask.length - 1;
      inputIt = input.length - 1;
      for (maskIt; maskIt >= 0; maskIt--) {
        switch (mask[maskIt]) {
          case "&": //NumberPlace, usually only get "&"
            if (CWSTR.isBlank(input[inputIt])) {
              if (allowSign && !CWSTR.isBlank(sign) && !alreadySigned) {
                out = sign + out;
                alreadySigned = true;
              }
              out = "_" + out;
            } else {
              out = input[inputIt] + out;
              inputIt--;
            }
            break;
          default: //Other symbols in the mask
            out = mask[maskIt] + out;
        }
      }
      if (allowSign && !CWSTR.isBlank(sign) && !alreadySigned) {
        out = sign + out;
      }
      return out;
    },

    /**
     * Function self transform the input data into new one when edit a field, only for Date this.
     */
    applyDateInputMask: function (source: any, mask: string, old: string): string {
      let out = "";
      const input = CWTYPE.MASK._cleanDateInput(source);
      let numberSize = 0;
      let maskIt = 0;
      let inputIt = 0;

      //Count the mask number capacity
      for (let i = 0; i < mask.length; i++) {
        if (mask[i] === "D" || mask[i] === "Y" || mask[i] === "M") {
          numberSize++;
        }
      }
      if (numberSize < input.length) {
        return old;
      }
      //Replacement
      for (maskIt; maskIt < mask.length; maskIt++) {
        switch (mask[maskIt]) {
          case "Y":
          case "D":
          case "M": //NumberPlace
            if (CWSTR.isBlank(input[inputIt])) {
              out = out + "_";
            } else {
              out = out + input[inputIt];
              inputIt++;
            }
            break;
          default: //Other symbols
            out = out + mask[maskIt];
        }
      }
      return out;
    },

    /**
     * Clean the input and only hold + or - if is on the first pos, and numbers,
     * acceptedSymbols are accepted too
     */
    _cleanInput: function (source: any, acceptedSymbols?: Array<string>): string {
      let out = "";

      if (!CWSTR.isBlank(source)) {
        const input = source.toString();

        for (let i = 0; i < input.length; i++) {
          if (!isNaN(parseInt(input[i], 10))) {
            out = out + input[i];
          }
          // "-" Symbol only can be in first position
          if (input[i] === "-") {
            if (out.length === 0) {
              out = "-";
            }
          }
          if (_.indexOf(acceptedSymbols, input[i]) >= 0) {
            // "+" NightSymbol only can stand in first position
            if (input[i] === "+") {
              if (out.length === 0) {
                out = "+";
              }
            } else {
              out = out + input[i];
            }
          }
        }
      }
      return out;
    },

    /**
     * Removes the mask unnecesary symbols to save the number on the model.
     */
    _cleanDateInput: function (input: any): string {
      let out = "";

      if (!CWSTR.isBlank(input)) {
        input = input.toString();
        for (let i = 0; i < input.length; i++) {
          if (!isNaN(parseInt(input[i], 10))) {
            out = out + input[i];
          }
        }
      }
      return out;
    },

    /**
     * returns the n first numbers of the input,
     * "-12,345.67"
     * n = 2 >> -12
     * n = 4 >> -12,34
     * n = 5 >> -12,345
     * n = 6 >> -12,34.6
     */
    _trimNumber: function (input: string, n: number): string {
      let out = "";
      let numCount = 0;

      for (let i = 0; i < input.length && numCount < n; i++) {
        out = out + input[i].toString();
        if (!isNaN(parseInt(input[i], 10))) {
          numCount++;
        }
      }
      return out;
    },

    /**
     * Gets the characters which represent a separator in the mask
     */
    _getSeparatorsFromMask: function (mask: string): Array<string> {
      const len = mask.length;
      const separators = [];

      for (let i = 0; i < len; i++) {
        if (mask[i] !== '-' && mask[i] !== '&') {
          separators.push(mask[i]);
        }
      }
      return separators;
    }
  };

  /**
 * The Base of other types self has common information and simple functions
 */
  static BASETYPE = {

    DEFAULT_MASK_CODE: "",
    TYPE_CLASS: "",

    /**
     * Auxiliar function self provides similar Backbone extend functionality
     */
    extend: function (childProps: any): any {
      const child = {};

      _.extend(child, CWTYPE.BASETYPE, childProps);
      return child;
    },

    /**
     * Apply an Input Mask to the input value.
     */
    parseOnFocus: function (input: any, inputMask: string): string {
      if (CWSTR.isBlank(inputMask)) {
        inputMask = CWTYPE._getMaskByCode(CWTYPE.BASETYPE.DEFAULT_MASK_CODE);
      }
      return CWTYPE.MASK.applyInputMask(input, inputMask);
    },

    /**
     * Validate the input and parse it to match with the model raw type
     */
    parse: function (input: any, type?: any): { [key: string]: any } {
      const out: any = {};
      let lInput = input;

      if (!CWSTR.isBlank(lInput)) {
        let validate = null;

        if (CWSTR.isBlank(type)) {
          type = CWTYPE.BASETYPE;
        }
        lInput = CWTYPE.MASK._cleanInput(lInput);
        validate = type.validate(lInput);
        if (!CWSTR.isBlank(validate)) {
          out.errors = validate;
        }
      }
      out.val = lInput;
      return out;
    },

    /**
     * Apply the formatMask to the input value
     */
    format: function (input: any, formatMask?: string): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.BASETYPE.DEFAULT_MASK_CODE);
      }
      return CWTYPE.MASK.applyFormatMask(input, formatMask);
    },

    events: function (selector?: string, type?: any): { [key: string]: any } {
      if (!CWSTR.isBlank(type.TYPE_CLASS)) {
        const eventsObj: any = {};

        if (CWSTR.isBlank(selector)) {
          selector = "";
        }
        if (CWSTR.isBlank(type)) {
          type = CWTYPE.BASETYPE;
        }
        eventsObj["focus " + selector + " ." + type.TYPE_CLASS + ":not([readonly])" as any] = function (e: JQueryEventObject): void {
          CWTYPE._eventFocus(e, type.TYPE_CLASS, type, this);
        };
        eventsObj["blur " + selector + " ." + type.TYPE_CLASS + ":not([readonly])" as any] = function (e: JQueryEventObject): void {
          CWTYPE._eventBlur(e, type.TYPE_CLASS, type, this);
        };
        eventsObj["keypress " + selector + " ." + type.TYPE_CLASS + ":not([readonly])" as any] = function (e: JQueryEventObject): void {
          CWTYPE._eventKeypress(e, type.TYPE_CLASS, type, this);
        };
        eventsObj["keydown " + selector + " ." + type.TYPE_CLASS + ":not([readonly])" as any] = function (e: JQueryEventObject): void {
          CWTYPE._eventDownRight2Left(e, type.TYPE_CLASS, type, this);
        };
        return eventsObj;
      }
      else {
        CWLOG.warn("CWTDA: TYPE_CLASS is empty, so i don't create any event for this type");
      }
      return null;
    }
  };

  /**
 * Type for conversion of HourMinutes(1200) to String and String to
 * HourMinutes(1200) Format : Convert the type HourMinutes (integer) to
 * String 1021 -> 10:21 Parse : Convert the type String to HourMinutes
 * (integer) 10:21 -> 1021
 */
  static HOUR_MINUTE = {

    TYPE_CLASS: "typeHourMinute",

    DEFAULT_MASK_CODE: "INSTHM",

    /**
     * Validate the input and parse it to match with the model raw type
     */
    parse: function (input: any): { [key: string]: any } {
      const obj: any = {};
      let lInput = input;
      let error = null;

      if (!CWSTR.isBlank(lInput)) {
        lInput = CWTYPE.MASK._cleanInput(lInput);
        error = CWTYPE.HOUR_MINUTE.validate(lInput);
        if (!CWSTR.isBlank(error)) {
          obj.errors = error;
        }
      }
      obj.val = lInput;
      return obj;
    },

    /**
     * Apply the formatMask to the input value
     */
    format: function (input: string, formatMask?: string): string {
      let out = "";
      let lInput = null;

      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.HOUR_MINUTE.DEFAULT_MASK_CODE);
      }
      lInput = parseInt(input, 10);
      out = CWTYPE.MASK.applyFormatMask(lInput, formatMask);
      return out;
    },

    /**
     * Validate the input with the type logic.
     */
    validate: function (input: string | number): string {
      let error = null;
      let lInput = null;

      if (!CWSTR.isBlank(input)) {
        lInput = input.toString();
        const min = parseInt(lInput.substring(lInput.length - 2, lInput.length), 10);
        const hour = parseInt(lInput.substring(lInput.length - 4, lInput.length - 2), 10);

        if (min > 59 || hour > 23) {
          error = i18n.t('messages:GL_2');
        }
      }
      return error;
    },

    /**
     * Adds h2 to h1.
     * h1 + h2
     * pe: h1: 700 (7:00), h2: 30 (0:30) = 730 >> (7:30)
     * if the result where more 2359 (23:59) returns 2359
     */
    plus: function (vh1: any, vh2: any): number {
      const h1 = vh1.toString();
      const h2 = vh2.toString();
      let h1Hour = h1.substring(0, h1.length - 2);
      let h2Hour = h2.substring(0, h2.length - 2);
      let result = null;
      let h1Min = null;
      let h2Min = null;
      let hours = null;
      let minutes = null;

      if (CWSTR.isBlank(h1Hour)) {
        h1Hour = 0;
      }
      if (CWSTR.isBlank(h2Hour)) {
        h2Hour = 0;
      }
      h1Min = h1 % 100;
      h2Min = h2 % 100;
      hours = parseInt(h1Hour, 10) + parseInt(h2Hour, 10);
      minutes = h1Min + h2Min;
      if (minutes > 59) {
        hours++;
        minutes -= 60;
      }
      result = hours * 100 + minutes;
      return result;
    },

    hoursToMinutes: function (hours: number): number {
      const h = Math.floor(hours / 100);
      const m = hours % 100;

      return h * 60 + m;
    },

    minutesToHours: function (minutes: number): number {
      const h = Math.floor(minutes / 60);
      const m = minutes % 60;

      return h * 100 + m;
    },

    /**
     * Return the difference between 2 HourMinute types
     * h1 - h2
     * pe: h1: 700 (7:00), h2: 30 (0:30) = 630 >> (6:30)
     * if the result were less than 0 returns 0
     */
    diff: function (vh1: any, vh2: any): number {
      const h1 = vh1.toString();
      const h2 = vh2.toString();
      let h1Hour = h1.substring(0, h1.length - 2);
      let h2Hour = h2.substring(0, h2.length - 2);
      let h1Min = null;
      let h2Min = null;
      let h1Date = null;
      let h2Date = null;
      let diff = null;
      let hours = null;
      let minutes = null;

      if (CWSTR.isBlank(h1Hour)) {
        h1Hour = 0;
      }
      if (CWSTR.isBlank(h2Hour)) {
        h2Hour = 0;
      }
      h1Min = h1 % 100;
      h2Min = h2 % 100;
      h1Date = new Date(0, 0, 0, h1Hour, h1Min, 0);
      h2Date = new Date(0, 0, 0, h2Hour, h2Min, 0);
      diff = h1Date.getTime() - h2Date.getTime();
      hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      minutes = Math.floor(diff / 1000 / 60);
      if (hours < 0) {
        return 0;
      } else {
        return hours * 100 + minutes;
      }
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.BASETYPE.parseOnFocus(input, inputMask);
    },

    events: function (selector?: string): { [key: string]: any } {
      return CWTYPE.BASETYPE.events(selector, CWTYPE.HOUR_MINUTE);
    }
  };

  /**
   * Type for conversion of Nightly HourMinutes(1200) to String and String to
   * Nightly HourMinutes(1200) Format : Convert the type Nightly HourMinutes (integer) to
   * String 1021 -> 10:21 Parse : Convert the type String to HourMinutes
   * (integer) 10:21 -> 1021
   */
  static HOUR_MINUTE_NIGHTLY = {

    TYPE_CLASS: "typeHourMinuteNightly",

    DEFAULT_MASK_CODE: "INSTHM",

    NIGHT_TIME: "+",

    /**
     * Apply an Input Mask to the input value.
     */
    parseOnFocus: function (input: any, inputMask: string): string { //Anomalie 118031
      if (_.isNumber(input)) { //If its a number is bacause it comes from the database or it was validated
        return CWTYPE.HOUR_MINUTE_NIGHTLY.format(input, inputMask);
      }
      CWTYPE.HOUR_MINUTE.parse(input);
      return CWTYPE.HOUR_MINUTE.parseOnFocus(input, inputMask);
    },

    /**
     * Validate the input and parse it to match with the model raw type
     */
    parse: function (input: any): { [key: string]: any } {
      const nightSymbol = CWTYPE.HOUR_MINUTE_NIGHTLY.NIGHT_TIME;
      const obj: any = {};
      let lInput = null;

      if (!CWSTR.isBlank(input)) {
        let error = null;

        lInput = CWTYPE.MASK._cleanInput(input.toString(), [nightSymbol]);
        error = CWTYPE.HOUR_MINUTE_NIGHTLY.validate(lInput);
        if (CWSTR.isBlank(error)) {
          //If it's Night Time
          if (input.toString().indexOf(nightSymbol) !== -1) {
            lInput = parseInt(lInput, 10) + 2400;
          } else {
            lInput = parseInt(lInput, 10); //Anomalie 118031
          }
        } else {
          obj.errors = error;
        }
      }
      obj.val = lInput;
      return obj;
    },

    /**
     * Take the value of a Model attribute or input (previously parsed) and format
     * it adds the separator time and the nightSymbol if were necesarie.
     */
    format: function (input: any, formatMask?: string, nightSymbol?: any): string {
      let out = "";
      let nightTime = false;

      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.HOUR_MINUTE_NIGHTLY.DEFAULT_MASK_CODE);
      }
      if (CWSTR.isBlank(nightSymbol)) {
        nightSymbol = CWTYPE.HOUR_MINUTE_NIGHTLY.NIGHT_TIME;
      }
      //Night Time
      input = parseInt(input, 10);
      if (input > 2400) {
        input = input - 2400;
        nightTime = true;
      }
      out = CWTYPE.MASK.applyFormatMask(input, formatMask);
      if (nightTime === true) {
        out = nightSymbol.concat(out);
      }
      return out;
    },

    /**
     * Validate the input value,
     *  - hours in range [0-23] | [0-47] (nightTime)
     * 	- minutes in range [0-59]
     */
    validate: function (input: any): string {
      let error = null;

      if (!CWSTR.isBlank(input)) {
        const nightSymbol = CWTYPE.HOUR_MINUTE_NIGHTLY.NIGHT_TIME;
        const lInput = input.toString();
        const nightTime = lInput.indexOf(nightSymbol) !== -1;
        const min = parseInt(lInput.substring(lInput.length - 2, lInput.length), 10);
        const hour = parseInt(lInput.substring(lInput.length - 4, lInput.length - 2), 10); //Anomalie 118031

        if (nightTime) {
          if (min > 59 || hour > 23) {
            error = i18n.t('messages:GL_2');
          }
        } else {
          if (lInput.length > 4 || (min > 59 || hour > 47)) {
            error = i18n.t('messages:GL_2');
          }
        }
      }
      return error;
    },

    events: function (selector?: string): { [key: string]: any } {
      return CWTYPE.BASETYPE.events(selector, CWTYPE.HOUR_MINUTE_NIGHTLY);
    }
  };


  static DURATION = {

    /**
     * Type self defines the duration in Hour Minutes
     */
    HOUR_MINUTE: {

      TYPE_CLASS: "typeDurationHM",

      DEFAULT_MASK_CODE: "DUREEHM",

      /**
       * Validate the input value,
       * 	- minutes in range [0-59].
       */
      validate: function (input: any): string {
        let error = null;

        if (!CWSTR.isBlank(input)) {
          const lInput = input.toString();
          const min = parseInt(lInput.substring(lInput.length - 2), 10);

          if (min > 59) {
            error = i18n.t('messages:GL_2');
          }
        }
        return error;
      },

      validateShort: function (input: string): string {
        let error = CWTYPE.DURATION.HOUR_MINUTE.validate(input);

        if (error) {
          return error;
        } else {
          if (parseInt(input) > 32767) {
            error = i18n.t('messages:GL_2');
          }

          return error;
        }
      },

      /**
       * Get the last 2 digits of the argument, in the case of HOUR_MINUTE type gives the minutes.
       */
      minutes: function (n: number): number {
        let lRtn = -1;

        if (!CWSTR.isBlank(n)) {
          const parse = CWTYPE.DURATION.HOUR_MINUTE.parse(n.toString());

          if (CWSTR.isBlank(parse.errors)) {
            lRtn = parse["val" as any] % 100;
          }
        }
        return lRtn;
      },

      /**
       * Remove the last 2 digits of the argument, in the case of HOUR_MINUTE type gives the hours.
       */
      hours: function (n: number): number {
        let lRtn = -1;

        if (!CWSTR.isBlank(n)) {
          const parse = CWTYPE.DURATION.HOUR_MINUTE.parse(n.toString());

          if (CWSTR.isBlank(parse.errors)) {
            const val = parse["val" as any];

            if (val.length > 2) {
              lRtn = parseInt(val.substring(0, val.length - 2), 10);
            } else {
              lRtn = 0;
            }
          }
        }
        return lRtn;
      },

      time: function (hours: number, minutes: number): string {
        return CWTYPE.addZerosFromLeft(hours, 2) + CWTYPE.addZerosFromLeft(minutes, 2);
      },

      addDurees: function (d1: number, d2: number): number {
        const duration = CWTYPE.DURATION.HOUR_MINUTE._toMinutes(d1) + CWTYPE.DURATION.HOUR_MINUTE._toMinutes(d2);

        return CWTYPE.DURATION.HOUR_MINUTE._toDuration(duration);
      },

      _toMinutes: function (duration: number): number {
        const h = Math.floor(duration / 100);
        const m = duration % 100;

        return h * 60 + m;
      },

      _toDuration: function (minutes: number): number {
        const h = Math.floor(minutes / 60);
        const m = minutes % 60;

        return h * 100 + m;
      },

      _calculateDuration: function (h1: number, h2: number): number {
        let duration = 0;

        if (h1 !== h2) {
          duration = CWTYPE.DURATION.HOUR_MINUTE._toMinutes(h2) - CWTYPE.DURATION.HOUR_MINUTE._toMinutes(h1);
          if (h1 > h2) {
            duration = 1440 + duration;
          }
        }
        return CWTYPE.DURATION.HOUR_MINUTE._toDuration(duration);
      },

      parseOnFocus: function (input: any, inputMask: string): string {
        return CWTYPE.BASETYPE.parseOnFocus(input, inputMask);
      },

      parse: function (input: any): { [key: string]: any } {
        return CWTYPE.BASETYPE.parse(input, CWTYPE.DURATION.HOUR_MINUTE);
      },

      format: function (input: any, formatMask?: string): string {
        if (CWSTR.isBlank(formatMask)) {
          formatMask = CWTYPE._getFormatByCode(CWTYPE.DURATION.HOUR_MINUTE.DEFAULT_MASK_CODE);
        }
        return CWTYPE.BASETYPE.format(input, formatMask);
      },

      events: function (selector?: string): { [key: string]: any } {
        return CWTYPE.BASETYPE.events(selector, CWTYPE.DURATION.HOUR_MINUTE);
      }
    },

    /**
     * Type self defines the duration in Hour Minutes of ha day
     */
    HOUR_MINUTE_24: {

      TYPE_CLASS: "typeDuration24HM",

      DEFAULT_MASK_CODE: "DUR24HM", //DUREEHM

      /**
       * Validate the input value,
       *  - hours in range [0-23]
       * 	- minutes in range [0-59].
       */
      validate: function (input: any): string {
        let error = null;

        if (!CWSTR.isBlank(input)) {
          const lInput = input.toString();
          const min = parseInt(lInput.substring(lInput.length - 2), 10);
          const hour = parseInt(lInput.substring(lInput.length - 4, lInput.length - 2), 10);

          if (!(min === 0 && hour === 24) && (min > 59 || hour > 23)) {
            error = i18n.t('messages:GL_2');
          }
        }
        return error;
      },

      /**
       * Get the last 2 digits of the argument, in the case of HOUR_MINUTE type gives the minutes.
       */
      minutes: function (num: number): number {
        let lRtn = -1;

        if (!CWSTR.isBlank(num)) {
          const parse = CWTYPE.DURATION.HOUR_MINUTE_24.parse(num.toString());

          if (CWSTR.isBlank(parse.errors)) {
            lRtn = parse["val" as any] % 100;
          }
        }
        return lRtn;
      },

      parseOnFocus: function (input: any, inputMask: string): string {
        return CWTYPE.BASETYPE.parseOnFocus(input, inputMask);
      },

      parse: function (input: any): { [key: string]: any } {
        return CWTYPE.BASETYPE.parse(input, CWTYPE.DURATION.HOUR_MINUTE_24);
      },

      format: function (input: any, formatMask?: string): string {
        if (CWSTR.isBlank(formatMask)) {
          formatMask = CWTYPE._getFormatByCode(CWTYPE.DURATION.HOUR_MINUTE_24.DEFAULT_MASK_CODE);
        }
        return CWTYPE.BASETYPE.format(input, formatMask);
      },

      events: function (selector?: string): { [key: string]: any } {
        return CWTYPE.BASETYPE.events(selector, CWTYPE.DURATION.HOUR_MINUTE_24);
      }
    },

    /**
     * Type self defines the duration in hour and hundredths.
     */
    HOUR_HUNDREDTH: {

      TYPE_CLASS: "typeDurationHC",

      DEFAULT_MASK_CODE: "DUREEHC",

      /**
       * Validate the input with the type logic, in this case don't do nothing.
       */
      validate: function (input: any): string { //eslint-disable-line
        const error = "";

        return error;
      },
      parseOnFocus: function (input: any, inputMask: string): string {
        return CWTYPE.BASETYPE.parseOnFocus(input, inputMask);
      },

      parse: function (input: any): { [key: string]: any } {
        return CWTYPE.BASETYPE.parse(input, CWTYPE.DURATION.HOUR_HUNDREDTH);
      },

      format: function (input: any, formatMask?: string): string {
        if (CWSTR.isBlank(formatMask)) {
          formatMask = CWTYPE._getFormatByCode(CWTYPE.DURATION.HOUR_HUNDREDTH.DEFAULT_MASK_CODE);
        }
        return CWTYPE.BASETYPE.format(input, formatMask);
      },

      events: function (selector?: string): { [key: string]: any } {
        return CWTYPE.BASETYPE.events(selector, CWTYPE.DURATION.HOUR_HUNDREDTH);
      }
    },

    /**
     * Type self defines the duration in hours with hundredths.
     */
    HOUR_HUNDREDTH_24: {

      TYPE_CLASS: "typeDuration24HC",

      DEFAULT_MASK_CODE: "DUR24HC",

      /**
       * Validate the input with the type logic, in this case don't do nothing.
       */
      validate: function (input: any): string { //eslint-disable-line
        const error = "";

        return error;
      },
      parseOnFocus: function (input: any, inputMask: string): string {
        return CWTYPE.BASETYPE.parseOnFocus(input, inputMask);
      },

      parse: function (input: any): { [key: string]: any } {
        return CWTYPE.BASETYPE.parse(input, CWTYPE.DURATION.HOUR_HUNDREDTH_24);
      },

      format: function (input: any, formatMask?: string): string {
        if (CWSTR.isBlank(formatMask)) {
          formatMask = CWTYPE._getFormatByCode(CWTYPE.DURATION.HOUR_HUNDREDTH_24.DEFAULT_MASK_CODE);
        }
        return CWTYPE.BASETYPE.format(input, formatMask)
      },

      events: function (selector?: string): { [key: string]: any } {
        return CWTYPE.BASETYPE.events(selector, CWTYPE.DURATION.HOUR_HUNDREDTH_24);
      }
    },

    /**
     * Type self defines the duration in minutes.
     */
    MINUTES: {

      TYPE_CLASS: "typeDurationMN",

      DEFAULT_MASK_CODE: "DUREEMN",

      /**
       * Validate the input with the type logic, in this case don't do nothing.
       */
      validate: function (input: any): string { //eslint-disable-line
        const error = "";

        return error;
      },

      parseOnFocus: function (input: any, inputMask: string): string {
        return CWTYPE.BASETYPE.parseOnFocus(input, inputMask);
      },

      parse: function (input: any): { [key: string]: any } {
        return CWTYPE.BASETYPE.parse(input, CWTYPE.DURATION.MINUTES);
      },

      format: function (input: any, formatMask?: string): string {
        if (CWSTR.isBlank(formatMask)) {
          formatMask = CWTYPE._getFormatByCode(CWTYPE.DURATION.MINUTES.DEFAULT_MASK_CODE);
        }
        return CWTYPE.BASETYPE.format(input, formatMask);
      },

      events: function (selector?: string): { [key: string]: any } {
        return CWTYPE.BASETYPE.events(selector, CWTYPE.DURATION.MINUTES);
      }
    },

    /**
  * Type self defines the duration in hour minute seconds.
  */
    HOUR_MINUTE_SECONDS: {

      TYPE_CLASS: "typeDurationHMS",
      DEFAULT_MASK_CODE: "DUREEHMS",

      /**
       * Validate the input with the type logic.
       */
      validate: function (input: any): string {
        let error = null;

        if (!CWSTR.isBlank(input)) {
          const lInput = input.toString();
          const seconds = parseInt(lInput.substring(lInput.length, lInput.length - 2), 10);
          const min = parseInt(lInput.substring(lInput.length - 2, lInput.length - 4), 10);
          const hour = parseInt(lInput.substring(lInput.length - 4, lInput.length - 6), 10);

          if (seconds > 59 || min > 59 || hour > 23) {
            error = i18n.t('messages:GL_2');
          }
        }

        return error;
      },

      validateShort: function (input: string): string {
        let error = CWTYPE.DURATION.HOUR_MINUTE_SECONDS.validate(input);

        if (error) {
          return error;
        } else {
          if (parseInt(input) > 32767) {
            error = i18n.t('messages:GL_2');
          }

          return error;
        }
      },

      /**
       * Get the last 2 digits of the argument, in the case of HOUR_MINUTE type gives the minutes.
       */
      minutes: function (n: number): number {
        let lRtn = -1;

        if (!CWSTR.isBlank(n)) {
          const parse = CWTYPE.DURATION.HOUR_MINUTE_SECONDS.parse(n.toString());

          if (CWSTR.isBlank(parse.errors)) {
            lRtn = parse["val" as any] % 100;
          }
        }
        return lRtn;
      },

      /**
       * Remove the last 2 digits of the argument, in the case of HOUR_MINUTE type gives the hours.
       */
      hours: function (n: number): number {
        let lRtn = -1;

        if (!CWSTR.isBlank(n)) {
          const parse = CWTYPE.DURATION.HOUR_MINUTE_SECONDS.parse(n.toString());

          if (CWSTR.isBlank(parse.errors)) {
            const val = parse["val" as any];

            if (val.length > 2) {
              lRtn = parseInt(val.substring(0, val.length - 2), 10);
            } else {
              lRtn = 0;
            }
          }
        }
        return lRtn;
      },

      time: function (hours: number, minutes: number): string {
        return CWTYPE.addZerosFromLeft(hours, 2) + CWTYPE.addZerosFromLeft(minutes, 2);
      },

      addDurees: function (d1: number, d2: number): number {
        const duration = CWTYPE.DURATION.HOUR_MINUTE_SECONDS._toMinutes(d1) + CWTYPE.DURATION.HOUR_MINUTE_SECONDS._toMinutes(d2);

        return CWTYPE.DURATION.HOUR_MINUTE_SECONDS._toDuration(duration);
      },

      _toMinutes: function (duration: number): number {
        const h = Math.floor(duration / 100);
        const m = duration % 100;

        return h * 60 + m;
      },

      _toDuration: function (minutes: number): number {
        const h = Math.floor(minutes / 60);
        const m = minutes % 60;

        return h * 100 + m;
      },

      _calculateDuration: function (h1: number, h2: number): number {
        let duration = 0;

        if (h1 !== h2) {
          duration = CWTYPE.DURATION.HOUR_MINUTE_SECONDS._toMinutes(h2) - CWTYPE.DURATION.HOUR_MINUTE_SECONDS._toMinutes(h1);
          if (h1 > h2) {
            duration = 1440 + duration;
          }
        }
        return CWTYPE.DURATION.HOUR_MINUTE_SECONDS._toDuration(duration);
      },

      parseOnFocus: function (input: any, inputMask: string): string {
        return CWTYPE.BASETYPE.parseOnFocus(input, inputMask);
      },

      parse: function (input: any): { [key: string]: any } {
        return CWTYPE.BASETYPE.parse(input, CWTYPE.DURATION.HOUR_MINUTE_SECONDS);
      },

      format: function (input: any, formatMask?: string): string {
        if (CWSTR.isBlank(formatMask)) {
          formatMask = CWTYPE._getFormatByCode(CWTYPE.DURATION.HOUR_MINUTE_SECONDS.DEFAULT_MASK_CODE);
        }
        return CWTYPE.BASETYPE.format(input, formatMask);
      },

      events: function (selector?: string): { [key: string]: any } {
        return CWTYPE.BASETYPE.events(selector, CWTYPE.DURATION.HOUR_MINUTE_SECONDS);
      }
    }
  };

  /**
   * CWTYPE HMS only got the FORMAT the user can't enter or edit HMS type
   */
  static HOUR_MINUTE_SECONDS = {
    TYPE_CLASS: "typeHourMinuteSecond",
    DEFAULT_MASK_CODE: "INSTHMS",

    /**
     * Validate the input with the type logic.
     */
    validate: function (input: any): string {
      let error = null;

      if (!CWSTR.isBlank(input)) {
        const lInput = input.toString();
        const seconds = parseInt(lInput.substring(lInput.length, lInput.length - 2), 10);
        const min = parseInt(lInput.substring(lInput.length - 2, lInput.length - 4), 10);
        const hour = parseInt(lInput.substring(lInput.length - 4, lInput.length - 6), 10);

        if (seconds > 59 || min > 59 || hour > 23) {
          error = i18n.t('messages:GL_2');
        }
      }
      return error;
    },

    validateShort: function (input: string): string {
      return CWTYPE.DURATION.HOUR_MINUTE.validateShort(input);
    },

    minutes: function (n: number): number {
      return CWTYPE.DURATION.HOUR_MINUTE.minutes(n);
    },

    hours: function (n: number): number {
      return CWTYPE.DURATION.HOUR_MINUTE.hours(n);
    },

    time: function (hours: number, minutes: number): string {
      return CWTYPE.DURATION.HOUR_MINUTE.time(hours, minutes);
    },

    addDurees: function (d1: number, d2: number): number {
      return CWTYPE.DURATION.HOUR_MINUTE.addDurees(d1, d2);
    },

    _toMinutes: function (duration: number): number {
      return CWTYPE.DURATION.HOUR_MINUTE._toMinutes(duration);
    },

    _toDuration: function (minutes: number): number {
      return CWTYPE.DURATION.HOUR_MINUTE._toDuration(minutes);
    },

    _calculateDuration: function (h1: number, h2: number): number {
      return CWTYPE.DURATION.HOUR_MINUTE._calculateDuration(h1, h2);
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.DURATION.HOUR_MINUTE.parseOnFocus(input, inputMask);
    },

    parse: function (input: any): { [key: string]: any } {
      return CWTYPE.BASETYPE.parse(input, CWTYPE.HOUR_MINUTE);
    },

    format: function (input: any, formatMask?: string): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.HOUR_MINUTE.DEFAULT_MASK_CODE);
      }
      return CWTYPE.DURATION.HOUR_MINUTE.format(input, formatMask);
    },

    events: function (selector?: string): { [key: string]: any } {
      return CWTYPE.BASETYPE.events(selector, CWTYPE.HOUR_MINUTE);
    }
  };

  /**
   * Type self defines the Short numbers.
   */
  static SHORT = {

    TYPE_CLASS: "typeShort",

    MAX_VALUE: 32767,

    MIN_VALUE: -32768,

    DEFAULT_MASK_CODE: "ENTCOURT",

    /**
     * Validate self the input is a number and is in the short range.
     */
    validate: function (short: number, min?: number, max?: number): string {
      let lRtn = null;

      if (!CWSTR.isBlank(short)) {
        if (CWSTR.isBlank(min)) { min = CWTYPE.SHORT.MIN_VALUE; }
        if (CWSTR.isBlank(max)) { max = CWTYPE.SHORT.MAX_VALUE; }
        if (!(CWTYPE._isInt(short) && short >= min && short <= max)) {
          lRtn = i18n.t('messages:GL_1008');
        }
      }
      return lRtn;
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.BASETYPE.parseOnFocus(input, inputMask);
    },

    parse: function (input: any): { [key: string]: any } {
      return CWTYPE.BASETYPE.parse(input, CWTYPE.SHORT);
    },

    format: function (input: any, formatMask?: string): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.SHORT.DEFAULT_MASK_CODE);
      }
      return CWTYPE.BASETYPE.format(input, formatMask)
    },

    events: function (selector?: string): { [key: string]: any } {
      return CWTYPE.BASETYPE.events(selector, CWTYPE.SHORT)
    }
  };

  /**
   * Type self defines the Long numbers.
   */
  static LONG = {

    TYPE_CLASS: "typeLong",

    MAX_VALUE: 2147483647,

    MIN_VALUE: -2147483648,

    DEFAULT_MASK_CODE: "ENTLONG",

    /**
     * Validate self the input is a number and is in the long range.
     */
    validate: function (long: number, min?: number, max?: number): string {
      let lRtn = null;

      if (!CWSTR.isBlank(long)) {
        if (CWSTR.isBlank(min)) { min = CWTYPE.LONG.MIN_VALUE; }
        if (CWSTR.isBlank(max)) { max = CWTYPE.LONG.MAX_VALUE; }
        if (!(CWTYPE._isInt(long) && long >= min && long <= max)) {
          lRtn = i18n.t('messages:GL_1008');
        }
      }
      return lRtn;
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.BASETYPE.parseOnFocus(input, inputMask);
    },

    parse: function (input: any, type?: any): { [key: string]: any } {
      if (CWSTR.isBlank(type)) {
        type = CWTYPE.LONG;
      }
      return CWTYPE.BASETYPE.parse(input, type);
    },

    format: function (input: any, formatMask?: string): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.LONG.DEFAULT_MASK_CODE);
      }
      return CWTYPE.BASETYPE.format(input, formatMask)
    },

    events: function (selector?: string, type?: any): { [key: string]: any } {
      if (CWSTR.isBlank(type)) {
        type = CWTYPE.LONG;
      }
      return CWTYPE.BASETYPE.events(selector, type)
    }
  };

  /**
   * Type self represents a customed number format.
   * If no customize the data is like a Long.
   */
  static CUSTOM = {

    TYPE_CLASS: "typeCustom",

    MAX_VALUE: 2147483647,

    MIN_VALUE: -2147483648,

    DEFAULT_MASK_CODE: "ENTLONG",

    DEFAULT_FORMAT: "----------&",

    /**
     * Validate self the input is a number and is in the long range.
     */
    validate: function (long: number, min?: number, max?: number): string {
      let lRtn = null;

      if (!CWSTR.isBlank(long)) {
        if (CWSTR.isBlank(min)) { min = CWTYPE.CUSTOM.MIN_VALUE; }
        if (CWSTR.isBlank(max)) { max = CWTYPE.CUSTOM.MAX_VALUE; }
        if (!(CWTYPE._isInt(long) && long >= min && long <= max)) {
          lRtn = i18n.t('messages:GL_1008');
        }
      }
      return lRtn;
    },

    validateWithMask: function (value: string | number, mask: string): boolean {
      // To validate an a custom mask I try to format the value 
      // With the mask, if the result have "?", is because the value
      // is not valid
      if (!CWSTR.isBlank(value) && !CWSTR.isBlank(mask)) {
        return !CWTYPE.CUSTOM.format(value, mask).includes('?');
      }
      CWLOG.debug("I can't validate an a custom mask if the value or the mask are empty, check this");
      return true; //True by default.
    },

    events: function (selector?: any): any {
      const eventsObj: any = {};

      if (CWSTR.isBlank(selector)) {
        selector = "";
      }
      eventsObj[("focus " + selector + " .typeCustom:not([readonly])") as any] = function (e: any): void {
        let format = "";

        if (!CWSTR.isBlank(this.typeFormatByClass[e.target.className.split(" ")[0]])) {
          format = this.typeFormatByClass[e.target.className.split(" ")[0]];
        }
        else {
          format = CWTYPE.CUSTOM.DEFAULT_FORMAT;
        }
        CWTYPE._eventFocus(e, "typeCustom", CWTYPE.CUSTOM, this, format);
      };
      eventsObj[("blur " + selector + " .typeCustom:not([readonly])") as any] = function (e: any): void {
        let format = "";

        if (!CWSTR.isBlank(this.typeFormatByClass[e.target.className.split(" ")[0]])) {
          format = this.typeFormatByClass[e.target.className.split(" ")[0]];
        }
        else {
          format = CWTYPE.CUSTOM.DEFAULT_FORMAT;
        }
        CWTYPE._eventBlur(e, "typeCustom", CWTYPE.CUSTOM, this, format);
      };
      eventsObj[("keypress " + selector + " .typeCustom:not([readonly])") as any] = function (e: any): void {
        let format = "";

        if (!CWSTR.isBlank(this.typeFormatByClass[e.target.className.split(" ")[0]])) {
          format = this.typeFormatByClass[e.target.className.split(" ")[0]];
        }
        else {
          format = CWTYPE.CUSTOM.DEFAULT_FORMAT;
        }
        CWTYPE._eventKeypress(e, "typeCustom", CWTYPE.CUSTOM, this, false, format);
      };
      eventsObj[("keydown .typeCustom:not([readonly])") as any] = function (e: any): void {
        let format = "";

        if (!CWSTR.isBlank(this.typeFormatByClass[e.target.className.split(" ")[0]])) {
          format = this.typeFormatByClass[e.target.className.split(" ")[0]];
        }
        else {
          format = CWTYPE.CUSTOM.DEFAULT_FORMAT;
        }
        CWTYPE._eventDownRight2Left(e, "typeCustom", CWTYPE.CUSTOM, this, format);
      };
      return eventsObj;
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.BASETYPE.parseOnFocus(input, inputMask);
    },

    parse: function (input: any): { [key: string]: any } {
      return CWTYPE.BASETYPE.parse(input, CWTYPE.CUSTOM);
    },

    format: function (input: any, formatMask?: string): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.CUSTOM.DEFAULT_MASK_CODE);
      }
      return CWTYPE.BASETYPE.format(input, formatMask)
    }
  };

  /**
 * Type self represents the decimal numbers.
 */
  static DECIMAL = {

    TYPE_CLASS: "typeDecimal",

    DEFAULT_MASK_CODE: "DECIMAL_2",

    SEPARATOR: ".",

    /**
     * Validate the input and parse it to match with the model raw type
     */
    parse: function (input: any, ndecimals: number, separator?: string, type?: any): { [key: string]: any } {
      const out: any = {};

      if (CWSTR.isBlank(type)) {
        type = CWTYPE.DECIMAL;
      }
      out.val = input;
      if (CWSTR.isBlank(ndecimals)) {
        ndecimals = CWTYPE._getDecimalsByCode(type.DEFAULT_MASK_CODE);
      }
      if (CWSTR.isBlank(separator)) {
        separator = CWTYPE.DECIMAL.SEPARATOR;
      }
      if (!CWSTR.isBlank(input)) {
        const lInput = CWTYPE.MASK._cleanInput(input);
        const validate = CWTYPE.DECIMAL.validate(lInput, separator);

        if (!CWSTR.isBlank(validate)) {
          out.errors = validate;
        }
        out.val = input / Math.pow(10, ndecimals);
      }
      return out;
    },

    /**
     * Apply the formatMask to the input value
     */
    format: function (input: any, formatMask?: string, ndecimals?: number, type?: any): string {
      let out = "";
      let lInput: number = null;

      if (CWSTR.isBlank(type)) {
        type = CWTYPE.DECIMAL;
      }
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(type.DEFAULT_MASK_CODE);
      }
      if (CWSTR.isBlank(ndecimals)) {
        ndecimals = CWTYPE._getDecimalsByCode(type.DEFAULT_MASK_CODE);
      }
      lInput = input * Math.pow(10, ndecimals);
      lInput = Math.round(lInput);
      out = CWTYPE.MASK.applyFormatMask(lInput, formatMask);
      return out;
    },

    /**
     * Validate the input, checks if is a float or not.
     */
    validate: function (input: any, separator?: string): string {
      let error = null;

      if (CWSTR.isBlank(separator)) {
        separator = CWTYPE.DECIMAL.SEPARATOR;
      }
      if (!CWSTR.isBlank(input) && _.isNaN(parseFloat(input))) {
        error = i18n.t('messages:GL_2');
      }
      return error;
    },

    events: function (selector?: any, type?: any): any {
      const eventsObj: any = {};

      if (CWSTR.isBlank(selector)) {
        selector = "";
      }
      if (CWSTR.isBlank(type)) {
        type = CWTYPE.DECIMAL;
      }
      eventsObj[("focus " + selector + " ." + type.TYPE_CLASS + ":not([readonly])") as any] = function (e: any): void {
        const typeClass = type.TYPE_CLASS;
        const attr = e.target.className.split(" ")[0];//Get the attribute name and his model value
        const attrModelValue = CWSTR.getElValue(this._getModel(attr), attr);
        let maskCode = null;
        let inputMask = null;

        //Set temporal Original Value on the view to detect Changes
        this.tempOrigVal = attrModelValue || "";
        //Get the input Mask
        maskCode = CWTYPE._getMaskCode(attr, this, typeClass);
        inputMask = CWTYPE._getMaskByCode(maskCode);
        //Change the value of the input
        $(e.target).val(CWTYPE.DECIMAL.parseOnFocus(attrModelValue, inputMask));
        //Select the content of the input
        window.setTimeout(() => {
          if (e.target && e.target.value && e.target.value.length && $("body").find($(e.target)).length > 0) {
            e.target.setSelectionRange(0, e.target.value.length);
          }
        }, 50);
      };
      eventsObj[("blur " + selector + " ." + type.TYPE_CLASS + ":not([readonly])") as any] = function (e: any): void {
        const typeClass = type.TYPE_CLASS;
        const attr = e.target.className.split(" ")[0];
        const maskCode = CWTYPE._getMaskCode(attr, this, typeClass);
        const formatMask = CWTYPE._getFormatByCode(maskCode);
        const decimals = CWTYPE._getDecimalsByCode(maskCode);
        const parse = CWTYPE.DECIMAL.parse(e.target.value, decimals, type);

        //Check changes on the original Value
        if (String(this.tempOrigVal) !== parse["val" as any]) {
          $(e.target).change();
        }
        if (!parse["errors" as any]) {
          $(e.target).val(CWTYPE.DECIMAL.format(parse["val" as any], formatMask, decimals, type));
        }

        delete this.tempOrigVal;
      };
      eventsObj["keypress " + selector + " ." + type.TYPE_CLASS + ":not([readonly])" as any] = function (e: any): void {
        CWTYPE._eventKeypress(e, type.TYPE_CLASS, CWTYPE.DECIMAL, this);
      };
      eventsObj["keydown " + selector + " ." + type.TYPE_CLASS + ":not([readonly])" as any] = function (e: any): void {
        CWTYPE._eventDownRight2Left(e, type.TYPE_CLASS, CWTYPE.DECIMAL, this);
      };
      return eventsObj;
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.BASETYPE.parseOnFocus(input, inputMask);
    }
  };

  /**
   * Type for conversion of Boolean to String(in the languaje correct) and
   * String(in the languaje correct) to Boolean Format : Convert the type
   * Boolean to String(in the languaje correct) true -> "Oui" Parse :
   * Convert the type String(in the languaje correct) to Boolean "Oui" ->
   * true
   */
  static BOOLEAN = {

    parseOnFocus: undefined as any,

    /**
     * Transforms a String into Boolean.
     */
    parse: function (input: string): boolean {
      let obj = false;

      if (input === i18n.t('common:booltrue')) {
        obj = true;
      }
      return obj;
    },

    /**
     * Auxiliar format function the input into boolean text.
     */
    _format: function (obj: any): string {
      let lRtn = "";

      if (obj) {
        lRtn = i18n.t('common:booltrue');
      } else {
        lRtn = i18n.t('common:boolfalse');
      }
      return lRtn;
    },

    /**
     * It formats a boolean data into text or icon, using uiquery icons if we pass iconStyle as true.
     */
    format: function (obj: any, iconStyle?: boolean, fieldSetStyle?: boolean, title?: string, couleur?: string): any {
      const $span = $("<span>");
      const colour = CWSTR.isBlank(couleur) ? "#1978da" : couleur; //couleur en format "#000000"

      if (CWSTR.isBlank(iconStyle) || iconStyle === false) {
        return CWTYPE.BOOLEAN._format(obj);
      }
      if (CWSTR.isBlank(fieldSetStyle) || fieldSetStyle === false) {
        $span.attr("style", "margin-left: calc(50% - 8px);");
      }
      if (!CWSTR.isBlank(title)) {
        $span.attr("title", title);
      }
      if (obj) {
        $span.append(UTILS.getSVGIcon("valider", "c-panneauMenu__tickIcon", 16))
        $span.find(".c-panneauMenu__tickIcon svg").css("fill", colour);
      } else {
        $span.append(UTILS.getSVGIcon("croix", "cw-volet__close ui-dialog-titlebar-close", 16))
        $span.find(".cw-volet__close svg").css("fill", colour);
      }
      return $span;
    },

    events: function (selector?: string): { [key: string]: any } {
      return CWTYPE.BASETYPE.events(selector, CWTYPE.BOOLEAN);
    }
  };

  /**
   * Type self defines the methods for email data this.
   */
  static EMAIL = {

    DEFAULT_MASK_CODE: "",
    TYPE_CLASS: "",

    parseOnFocus: undefined as any,
    parse: undefined as any,
    format: undefined as any,

    /**
     * Auxiliar function self checks the regexp for the input.
     */
    _validatePattern: function (str: string): any {
      const RegExPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

      return RegExPattern.test(str);
    },

    /**
     * Validate if the input has a correct email format.
     */
    validate: function (input: any): string {
      let error = null;

      if (!CWSTR.isBlank(input) && (!CWTYPE.EMAIL._validatePattern(input.trim()) || (input.split("@")[0].length > 64))) {//il faut vérifier aussi la règle : local-part is longer than 64 characters
        error = i18n.t('common:invalidEmail');
      }
      return error;
    },

    events: function (selector?: string): { [key: string]: any } {
      return CWTYPE.BASETYPE.events(selector, CWTYPE.EMAIL);
    }
  };


  /**
   * Type self defines the methods for SS Number logic data this.
   */
  static RESTDIV97 = {

    DEFAULT_MASK_CODE: "",
    TYPE_CLASS: "",

    parseOnFocus: undefined as any,
    parse: undefined as any,
    format: undefined as any,

    /**
     * Auxiliar function self checks the french SS number logic.
     */
    _validatePattern: function (str: string): boolean {
      let valid = true;
      let typeA, typeB;
      const RegExPattern = /^[1-2][0-9]{2}(0[1-9]|1[0-2])(2[AB]|[0-9]{2})[0-9]{8}$/;
      const lFuncReplaceAt = (source: string, index: number, char: string): string => {
        let lRtn = source;

        if (!CWSTR.isBlank(lRtn) && !CWSTR.isBlank(index) && !CWSTR.isBlank(char)) {
          const a = source.split("");

          a[index] = char;
          lRtn = a.join("");
        }
        return lRtn;
      };

      valid = RegExPattern.test(str);
      if (valid) {
        let lInput = str;
        let numss = null;
        let ctrlNum = null;
        let reste = null;

        //Check 'A' or 'B' on the 7th position
        if (str[6] === "A") {
          typeA = true;
          lInput = lFuncReplaceAt(lInput, 6, "0");
        } else if (str[6] === "B") {
          typeB = true;
          lInput = lFuncReplaceAt(lInput, 6, "0");
        }
        //Check reste logic
        numss = parseInt(lInput.substr(0, 13), 10);
        ctrlNum = parseInt(lInput.substring(13), 10);
        if (typeA) {
          numss -= 1000000;
        } else if (typeB) {
          numss -= 2000000;
        }
        reste = numss % 97;
        if (ctrlNum !== (97 - reste)) {
          valid = false;
        }
      }
      return valid;
    },

    /**
     * Validate if the input has a correct format.
     */
    validate: function (input: string): string {
      let error = null;

      if (!CWSTR.isBlank(input)) {
        //...Le contrôle est à faire uniquement si le paramètre divers pers_numer vaut 2.
        if (GLOBAL_DATA.paramDivers.get("pers_numer").get("valeur") === "2") {
          // CWLOG.debug("parametre divers \"pers_numer\" (valeur) => " + GLOBAL_DATA.paramDivers.get("pers_numer").get("valeur"));
          if (!CWTYPE.RESTDIV97._validatePattern(input)) {
            error = i18n.t('messages:GT_1323');
          }
        }
      }
      return error;
    },

    events: function (selector?: string): { [key: string]: any } {
      return CWTYPE.BASETYPE.events(selector, CWTYPE.RESTDIV97);
    }
  };


  /**
   * Type for conversion of Date to String(in the format correct) and
   * String(in the format correct) to Date Format : Convert the type Date
   * to String(in the format correct) Date -> "22/12/01" Parse : Convert
   * the type String(in the format correct) to Date "22/12/01" -> Date
   */
  static DATE = {

    TYPE_CLASS: "typeDate",

    DEFAULT_MASK_CODE: "DATE",

    DEFAULT_MASK_BBDD: "YYYYMMDD",

    DEFAULT_MASK_FRENCH: "DD/MM/YYYY",

    SUNDAY_NUMBER: 0,

    MONDAY_NUMBER: 1,

    FRIDAY_NUMBER: 5,

    SATURDAY_NUMBER: 6,

    INFINITY: "21001231",

    INITIAL: "18991231",

    ML_SECONDS_DAY: 86400000,

    /**
     * Apply an Input Mask to the input value.
     */
    parseOnFocus: function (input: string, inputMask: string): string {
      let lInput = null;
      let lInputMask = null;
      let dateInArray = null;

      if (!CWSTR.isBlank(input)) {
        input = (typeof input === "string") ? input : String(input)
        lInput = input;
      }
      lInputMask = inputMask;
      if (CWSTR.isBlank(lInputMask)) {
        lInputMask = CWTYPE._getMaskByCode(CWTYPE.DATE.DEFAULT_MASK_CODE);
      }
      if (!CWSTR.isBlank(lInput)) {
        lInput = input.replace(/\//g, "");
      }
      //Change the input format from BBDD to Input format only if the date was valid.
      if (!CWSTR.isBlank(CWTYPE.DATE.validate(lInput))) {
        dateInArray = CWTYPE.DATE._obtainDateArray(lInput, lInputMask.replace(/\//g, ""), true);
      } else {
        dateInArray = CWTYPE.DATE._obtainDateArray(lInput, CWTYPE.DATE.DEFAULT_MASK_BBDD);
      }
      return (CWTYPE.DATE._getMaskedStringFromArray(dateInArray[0], dateInArray[1], dateInArray[2], lInputMask));
    },

    /**
     * Converts a string date into a formatted string date ready to send to the DB. (YYYYMMDD format)
     */
    parse: function (str: string, maskCode?: string): { [key: string]: any } {
      const out: any = {};
      let lInput = str;
      let mask = null;

      //Date format long case
      if (maskCode === "DATE_L" || lInput.length >= 15) {
        lInput = CWTYPE.DATE.checkDayLength(lInput);
        const monthName = lInput.split(" ")[2];
        const monthNumber = parseInt((CWTYPE.DATE.getInverseArrayMounthNameNumber()[monthName as any])) + 1;

        lInput = monthNumber < 10 ? lInput.replace(monthName, "0" + monthNumber) : lInput = lInput.replace(monthName, String(monthNumber));
      }
      //Clean the input
      lInput = CWTYPE.MASK._cleanDateInput(lInput);
      //Get the mask if is undefined and clean it
      if (CWSTR.isBlank(maskCode)) {
        maskCode = CWTYPE.DATE.DEFAULT_MASK_CODE;
      }
      mask = CWTYPE._getMaskByCode(maskCode);
      if (!CWSTR.isBlank(lInput)) {
        let validate = null;

        mask = mask.replace(/\//g, "");
        validate = CWTYPE.DATE.validate(lInput, mask);
        if (!CWSTR.isBlank(validate)) {
          out.errors = validate;
        } else {
          const dateArray = CWTYPE.DATE._obtainDateArray(lInput, mask);
          const day = CWTYPE.DATE._pad(dateArray[0]);
          const month = CWTYPE.DATE._pad(dateArray[1]);

          lInput = CWTYPE.DATE._pad(dateArray[2], 4) + month + day; //Default DB format is YYYYMMDD
        }
      }
      out.val = lInput;
      return out;
    },

    checkDayLength: function (dateSTR: string): string {
      const dateParts = dateSTR.split(" ");

      if (dateParts.length > 1) {
        if (!isNaN(dateParts[1].length) && dateParts[1].length === 1) {
          dateParts[1] = "0" + dateParts[1];
        }
        return dateParts.join(" ");
      }
      return dateSTR;
    },

    getInverseArrayMounthNameNumber: function (): any {
      return _.extend(_.invert(i18n.t('common:monthNames', { returnObjects: true })),
        _.invert(i18n.t('common:monthNamesMin', { returnObjects: true })),
        _.invert(i18n.t('common:monthNamesCap', { returnObjects: true })),
        _.invert(i18n.t('common:monthNamesShort', { returnObjects: true })),
        _.invert(i18n.t('common:monthNamesShortMin', { returnObjects: true })),
        _.invert(i18n.t('common:monthNamesShortCap', { returnObjects: true })));
    },

    /**
     * Function to format a string date following the mask and using the parameter if it exists.
     */
    format: function (strDate: string, mask?: string, separator?: string, hourSeparator?: string, milliseconds?: number): string {
      let date = null;
      let formattedMask = "";

      //Get default mask is none defined
      if (CWSTR.isBlank(mask)) {
        mask = CWTYPE._getFormatByCode(CWTYPE.DATE.DEFAULT_MASK_CODE);
      }
      //Transform the data into DATE type
      if (!CWSTR.isBlank(milliseconds)) {
        date = new Date(milliseconds);
      } else {
        //Convert the Model value (This can't change at least )
        date = CWTYPE.DATE.strToDate(strDate, CWTYPE.DATE.DEFAULT_MASK_BBDD); //Default BD date format >> "YYYYMMDD"
      }
      if (!CWSTR.isBlank(date)) {
        const d = date.getDate(),
          D = date.getDay(),
          m = date.getMonth(),
          y = date.getFullYear(),
          H = date.getHours(),
          M = date.getMinutes(),
          s = date.getSeconds(),
          L = date.getMilliseconds(),
          flags = {
            Day: i18n.t('common:dayNames.' + D), //day long name
            day: i18n.t('common:dayNamesMin.' + D), //day long name minus.
            DAY: i18n.t('common:dayNamesCap.' + D), //day long name capital
            Dy: i18n.t('common:dayNamesShort.' + D), //day short name
            dy: i18n.t('common:dayNamesShortMin.' + D), //day short name minus.
            DY: i18n.t('common:dayNamesShortCap.' + D), //day short name capital
            DD: CWTYPE.DATE._pad(d), //day with 0 at left
            D: d, //day without 0 at left
            Month: i18n.t('common:monthNames.' + m), //month long name
            month: i18n.t('common:monthNamesMin.' + m), //month long name minus.
            MONTH: i18n.t('common:monthNamesCap.' + m), //month long name capital
            Mo: i18n.t('common:monthNamesShort.' + m), //month short name
            mo: i18n.t('common:monthNamesShortMin.' + m), //month short name minus.
            MO: i18n.t('common:monthNamesShortCap.' + m), //month short name capital
            MM: CWTYPE.DATE._pad(m + 1), //month with 0 at left
            M: m + 1, //month without 0 at left
            YYYY: y, //year with 4 digits
            YY: String(y).slice(2), //year with 2 digits
            hh: CWTYPE.DATE._pad(H % 12 || 12), //hour with 0 at left in 12h format
            h: String(H % 12 || 12), //hour without 0 at left in 12h format
            HH: CWTYPE.DATE._pad(H), //hour with 0 at left in 24h format
            H: H, //hour without 0 at left in 24h format
            mm: CWTYPE.DATE._pad(M), //minutes with 0 at left
            m: M, //minutes without 0 at left
            ss: CWTYPE.DATE._pad(s), //seconds with 0 at left
            s: s, //seconds without 0 at left
            l: CWTYPE.DATE._pad(L, 3), //milliseconds with 2 digits format
            L: CWTYPE.DATE._pad(L > 99 ? Math.round(L / 10) : L), //milliseconds rounded with 2 digits format
            tt: H < 12 ? "am" : "pm", //long am-pm for 12h format
            t: H < 12 ? "a" : "p", //short am-pm for 12h format
            TT: H < 12 ? "AM" : "PM", //long AM-PM for 12h format
            T: H < 12 ? "A" : "P" //short AM-PM for 12h format
          };

        if (!CWSTR.isBlank(separator)) {
          formattedMask = CWTYPE.DATE._formatMask(mask, separator, hourSeparator);
        } else {
          formattedMask = mask;
        }
        //replace flags in formatted mask
        formattedMask = formattedMask.replace(/Day/g, "@@@@@@");
        formattedMask = formattedMask.replace(/day/g, "@@@@@");
        formattedMask = formattedMask.replace(/DAY/g, "@@@@");
        formattedMask = formattedMask.replace(/Dy/g, "@@@");
        formattedMask = formattedMask.replace(/dy/g, "@@");
        formattedMask = formattedMask.replace(/DY/g, "@");
        formattedMask = formattedMask.replace(/DD/g, flags.DD);
        formattedMask = formattedMask.replace(/D/g, String(flags.D));
        formattedMask = formattedMask.replace(/Month/g, "~~~~~~");
        formattedMask = formattedMask.replace(/month/g, "~~~~~");
        formattedMask = formattedMask.replace(/MONTH/g, "~~~~");
        formattedMask = formattedMask.replace(/Mo/g, "~~~");
        formattedMask = formattedMask.replace(/mo/g, "~~");
        formattedMask = formattedMask.replace(/MO/g, "~");
        formattedMask = formattedMask.replace(/MM/g, flags.MM);
        formattedMask = formattedMask.replace(/M/g, String(flags.M));
        formattedMask = formattedMask.replace(/YYYY/g, String(flags.YYYY));
        formattedMask = formattedMask.replace(/YY/g, flags.YY);
        formattedMask = formattedMask.replace(/hh/g, flags.hh);
        formattedMask = formattedMask.replace(/h/g, flags.h);
        formattedMask = formattedMask.replace(/HH/g, flags.HH);
        formattedMask = formattedMask.replace(/H/g, String(flags.H));
        formattedMask = formattedMask.replace(/mm/g, flags.mm);
        formattedMask = formattedMask.replace(/m/g, String(flags.m));
        formattedMask = formattedMask.replace(/ss/g, flags.ss);
        formattedMask = formattedMask.replace(/s/g, String(flags.s));
        formattedMask = formattedMask.replace(/l/g, flags.l);
        formattedMask = formattedMask.replace(/L/g, flags.L);
        formattedMask = formattedMask.replace(/tt/g, flags.tt);
        formattedMask = formattedMask.replace(/t/g, flags.t);
        formattedMask = formattedMask.replace(/TT/g, flags.TT);
        formattedMask = formattedMask.replace(/T/g, flags.T);
        formattedMask = formattedMask.replace(/@@@@@@/g, flags.Day);
        formattedMask = formattedMask.replace(/@@@@@/g, flags.day);
        formattedMask = formattedMask.replace(/@@@@/g, flags.DAY);
        formattedMask = formattedMask.replace(/@@@/g, flags.Dy);
        formattedMask = formattedMask.replace(/@@/g, flags.dy);
        formattedMask = formattedMask.replace(/@/g, flags.DY);
        formattedMask = formattedMask.replace(/~~~~~~/g, flags.Month);
        formattedMask = formattedMask.replace(/~~~~~/g, flags.month);
        formattedMask = formattedMask.replace(/~~~~/g, flags.MONTH);
        formattedMask = formattedMask.replace(/~~~/g, flags.Mo);
        formattedMask = formattedMask.replace(/~~/g, flags.mo);
        formattedMask = formattedMask.replace(/~/g, flags.MO);
      }
      return formattedMask;
    },

    /**
     * Function to manage infinity values for periods.
     */
    manageInfinity: function (strDate: string, mask?: string, separator?: string, hourSeparator?: string, milliseconds?: number): string {
      if (strDate === CWTYPE.DATE.INFINITY && GLOBAL_DATA.paramDivers.get("CACH_INFIN").get("valeur") === "1") {
        return "";
      } else {
        return CWTYPE.DATE.format(strDate, mask, separator, hourSeparator, milliseconds);
      }
    },

    /**
     * Function to validate a string date self uses another two auxiliar functions to do it.
     */
    validate: function (strDate: string, mask?: string, separator?: string): string {
      let lRtn = null;

      //If mask is undef use the default BBDD >> "YYYYMMDD"
      if (CWSTR.isBlank(mask)) {
        mask = CWTYPE.DATE.DEFAULT_MASK_BBDD;
      }
      if (CWSTR.isBlank(separator)) {
        separator = "";
      }
      if (!CWSTR.isBlank(strDate)) {
        if (!CWTYPE.DATE._validateDateFormat(strDate, mask)) {
          lRtn = i18n.t('messages:GL_4');
        } else if (!CWTYPE.DATE._validateDate(strDate, mask)) {
          lRtn = i18n.t('messages:GL_2');
        }
      }
      return lRtn;
    },

    /**
     * Function to validate a period.
     */
    validatePeriod: function (startDate: { [key: string]: any }, endDate: { [key: string]: any }, options?: { [key: string]: any }): { [key: string]: any } {
      const errors: any = {};

      if (!CWSTR.isBlank(startDate)) {
        const errorMsg = CWTYPE.DATE.validate(startDate.value);

        if (!CWSTR.isBlank(errorMsg)) {
          errors.datedeb = errorMsg;
        } else if (CWSTR.isBlank(startDate.value) && !CWSTR.isBlank(startDate.label) && !CWSTR.isBlank(options) && (CWSTR.isBlank(options.editedAttr) || options.editedAttr === startDate.name) &&
          ((CWSTR.isBlank(startDate.isInfinity) || !startDate.isInfinity))) {
          errors.datedeb = i18n.t('common:required', { "0": startDate.label });
        }
      }
      if (!CWSTR.isBlank(endDate)) {
        const errorMsg2 = CWTYPE.DATE.validate(endDate.value);

        if (!CWSTR.isBlank(errorMsg2)) {
          errors.datefin = errorMsg2;
        } else if (CWSTR.isBlank(errors.datedeb) && !CWSTR.isBlank(startDate) && !CWSTR.isBlank(startDate.value) && !CWSTR.isBlank(endDate.value) && endDate.value < startDate.value) {
          errors.datefin = i18n.t('messages:GL_1057');
        } else if (CWSTR.isBlank(endDate.value) && !CWSTR.isBlank(endDate.label) && !CWSTR.isBlank(options) && (CWSTR.isBlank(options.editedAttr) || options.editedAttr === endDate.name) &&
          ((CWSTR.isBlank(endDate.isInfinity) || !endDate.isInfinity))) {
          errors.datefin = i18n.t('common:required', { "0": endDate.label });
        }
      }
      if (!_.isEmpty(errors)) {
        return errors;
      } else {
        return null;
      }
    },

    /**
     * Converts a date in a string into a Date object.
     */
    strToDate: function (strDate: string, mask?: string): Date {
      let date = null;
      let lMask = mask;

      if (!CWSTR.isBlank(strDate)) {
        let dateArray = null;

        if (CWSTR.isBlank(lMask)) {
          lMask = CWTYPE.DATE.DEFAULT_MASK_BBDD;
        }
        dateArray = CWTYPE.DATE._obtainDateArray(strDate, lMask);
        date = new Date(parseInt(dateArray[2], 10), (parseInt(dateArray[1], 10) - 1), parseInt(dateArray[0], 10));
      }
      return date;
    },

    /**
     * Converts a Date object into a string using a mask format and an optional separator.
     */
    dateToStr: function (date: Date, mask?: string, separator?: string, hsep?: string): string {
      let result = "";
      let lSeparator = separator;
      let lHsep = hsep;
      let hasHour = true;

      if (CWSTR.isBlank(lSeparator)) {
        lSeparator = "";
      }
      if (CWSTR.isBlank(lHsep)) {
        lHsep = "";
        hasHour = false;
      }
      if (!CWSTR.isBlank(date)) {
        if (hasHour) {
          result = CWTYPE.DATE.format("", mask, lSeparator, lHsep, date.getTime());
        } else {
          //Mount the strDate with the DataBase format (Model)
          const strDate = String(date.getFullYear()) + String(CWTYPE.DATE._pad(date.getMonth() + 1)) + String(CWTYPE.DATE._pad(date.getDate()));

          result = CWTYPE.DATE.format(strDate, mask, lSeparator, lHsep);
        }
      }
      return result;
    },

    /**
     * Get the current day in DDBB format "YYYYMMDD"
     */
    getCurrentDate: function (): string {
      return CWTYPE.DATE.dateToStr(SYNC.getServerDate(), CWTYPE.DATE.DEFAULT_MASK_BBDD);
    },

    /**
     * Get the monday of the given week.
     */
    getWeeklyMonday: function (date: Date): Date {
      const newDate = new Date(date);

      if (date.getDay() === CWTYPE.DATE.SUNDAY_NUMBER) {
        UTILS.addDays(newDate, -6);
      } else if (date.getDay() !== CWTYPE.DATE.MONDAY_NUMBER) {
        const daysToRest = 1 - date.getDay();

        UTILS.addDays(newDate, daysToRest);
      }
      return newDate;
    },

    /**
     * Get the sunday of the given week.
     */
    getWeeklySunday: function (date: Date): Date {
      const newDate = new Date(date);

      if (date.getDay() === CWTYPE.DATE.MONDAY_NUMBER) {
        UTILS.addDays(newDate, 6);
      } else if (date.getDay() !== CWTYPE.DATE.SUNDAY_NUMBER) {
        const daysToSum = 7 - date.getDay();

        UTILS.addDays(newDate, daysToSum);
      }
      return newDate;
    },

    /**
     * Get the friday of the given week.
     */
    getWeeklyFriday: function (date: Date): Date {
      const newDate = new Date(date);

      if (date.getDay() === CWTYPE.DATE.MONDAY_NUMBER) {
        UTILS.addDays(newDate, 4);
      } else if (date.getDay() === CWTYPE.DATE.SUNDAY_NUMBER) {
        UTILS.addDays(new Date(date), -2);
      } else {
        const daysToSum = 5 - date.getDay();

        UTILS.addDays(newDate, daysToSum);
      }
      return newDate;
    },

    getWeeklySaturday: function (date: Date): Date {
      const newDate = new Date(date);

      if (date.getDay() === CWTYPE.DATE.MONDAY_NUMBER) {
        UTILS.addDays(newDate, 5);
      } else if (date.getDay() === CWTYPE.DATE.SUNDAY_NUMBER) {
        UTILS.addDays(newDate, -1);
      } else if (date.getDay() !== CWTYPE.DATE.SATURDAY_NUMBER) {
        const daysToSum = 6 - date.getDay();

        UTILS.addDays(newDate, daysToSum);
      }
      return newDate;
    },

    /**
     * Get the difference in Days between 2 dates in BBDD format or date format
     */
    getDiffInDays: function (date1: string | Date, date2: string | Date, truncate?: boolean): number {
      let lDate1: Date;
      let lDate2: Date;

      if (!(date1 instanceof Date)) {
        lDate1 = CWTYPE.DATE.strToDate(date1);
      } else {
        lDate1 = date1 as Date;
      }
      if (!(date2 instanceof Date)) {
        lDate2 = CWTYPE.DATE.strToDate(date2);
      } else {
        lDate2 = date2 as Date;
      }
      if (!CWSTR.isBlank(truncate) && truncate === true) {
        return Math.round((CWTYPE.DATE._truncate(lDate2).getTime() - CWTYPE.DATE._truncate(lDate1).getTime()) / (CWTYPE.DATE.ML_SECONDS_DAY));
      } else {
        return Math.round((lDate2.getTime() - lDate1.getTime()) / (CWTYPE.DATE.ML_SECONDS_DAY));
      }
    },

    getFullPeriode: function (date1: string | Date, date2: string | Date): Array<string> {
      const periode = [];
      let lDate1: Date;
      let lDate2: Date;
      let diffDays = null;

      if (!(date1 instanceof Date)) {
        lDate1 = CWTYPE.DATE.strToDate(date1);
      } else {
        lDate1 = date1 as Date;
      }
      if (!(date2 instanceof Date)) {
        lDate2 = CWTYPE.DATE.strToDate(date2);
      } else {
        lDate2 = date2 as Date;
      }
      diffDays = CWTYPE.DATE.getDiffInDays(lDate1, lDate2, true);
      if (diffDays >= 0) {
        for (let i = 0; i <= diffDays; ++i) {
          periode.push(CWTYPE.DATE.dateToStr(UTILS.addDays(lDate1, i === 0 ? 0 : 1), CWTYPE.DATE.DEFAULT_MASK_BBDD));
        }
      }
      return periode;
    },

    /**
     * Get the number of days in a period given by 2 dates in BBDD or date format
     */
    getNumberOfDays: function (date1: string | Date, date2: string | Date, truncate?: boolean): number {
      return Math.abs(CWTYPE.DATE.getDiffInDays(date1, date2, truncate)) + 1;
    },

    /**
     * Remove hours minutes and seconds of the date passed as parameter
     */
    _truncate: function (date: Date): Date {
      const lDate = date;

      lDate.setSeconds(0);
      lDate.setMinutes(0);
      lDate.setHours(0);
      lDate.setMilliseconds(0);
      return lDate;
    },

    /**
     * Aux function self returns an array with the day (pos 0), month (pos 1) and year (pos 2) from
     * a string date using a mask and a separator.
     */
    _obtainDateArray: function (str: string, mask?: string, dontFillItWithDate?: boolean): Array<string> {
      let lDontFillItWithDate = dontFillItWithDate;
      const dateArray: any = [];

      if (CWSTR.isBlank(lDontFillItWithDate)) {
        lDontFillItWithDate = false;
      }
      dateArray[0] = null; //Days
      dateArray[1] = null; //Months
      dateArray[2] = null; //Years
      if (!CWSTR.isBlank(mask) && mask.length === 8 && !CWSTR.isBlank(str)) {
        const filledStr = (str + "XXXXXXXX").substr(0, 8); //Fill up to 8 characters with X's
        const day = mask.search("D"); //2 digits
        const month = mask.search("M"); //2 digits
        const year = mask.search("Y"); //4 digits

        dateArray[0] = filledStr.substr(day, 2);
        dateArray[1] = filledStr.substr(month, 2);
        dateArray[2] = filledStr.substr(year, 4);
        if (!lDontFillItWithDate) {
          const currentDate = SYNC.getServerDate();

          if (!CWTYPE._isInt(dateArray[0])) {
            dateArray[0] = currentDate.getDate();
          }
          if (!CWTYPE._isInt(dateArray[1])) {
            dateArray[1] = currentDate.getMonth() + 1;
          }
          if (!CWTYPE._isInt(dateArray[2])) {
            dateArray[2] = currentDate.getFullYear();
          }
        } else {
          dateArray[0] = dateArray[0].replace(/X/g, "");
          dateArray[1] = dateArray[1].replace(/X/g, "");
          dateArray[2] = dateArray[2].replace(/X/g, "");
        }
      }
      return dateArray;
    },

    /**
     * Replaces the mask number positions by underscores.
     */
    _getMaskedStringFromArray: function (day?: string | number, month?: string | number, year?: string | number, mask?: string): string {
      let dateInString = mask;

      dateInString = !CWSTR.isBlank(day) ? dateInString.replace("DD", CWTYPE.DATE._pad(day)) : dateInString.replace("DD", "__");
      dateInString = !CWSTR.isBlank(month) ? dateInString.replace("MM", CWTYPE.DATE._pad(month)) : dateInString.replace("MM", "__");
      dateInString = !CWSTR.isBlank(year) ? dateInString.replace("YYYY", CWTYPE.DATE._pad(year, 4)) : dateInString.replace("YYYY", "____");
      return dateInString;
    },

    /**
     * Function to format an Int or string with 0 at the left.
     */
    _pad: function (value: string | number, length?: number): string {
      let lValue = String(value);
      const len = length || 2;

      while (lValue.length < len) {
        lValue = "0" + lValue;
      }
      return lValue;
    },

    /**
     * Function to format a mask including separators in it.
     */
    _formatMask: function (mask?: string, separator?: string, hourSeparator?: string): string {
      let formattedMask = "";
      const dateArray = ["D", "M", "Y" as any];
      const hourArray = ["h", "H", "m", "s", "l", "L" as any];
      let sep = "";
      let hsep = "";

      if (!CWSTR.isBlank(separator)) {
        sep = separator;
      }
      if (!CWSTR.isBlank(hourSeparator)) {
        hsep = hourSeparator;
      }
      if (!CWSTR.isBlank(mask)) {
        let lastChar = mask.charAt(0);

        for (let i = 0; i < mask.length; i++) {
          const char = mask.charAt(i);

          if (lastChar !== char) {
            if (_.indexOf(dateArray, char) !== -1 && _.indexOf(dateArray, lastChar) !== -1) {
              formattedMask += sep;
            }
            if (_.indexOf(hourArray, char) !== -1 && _.indexOf(hourArray, lastChar) !== -1) {
              formattedMask += hsep;
            }
            lastChar = char;
          }
          formattedMask += char;
        }
      }
      return formattedMask;
    },

    /**
     * Function to validate if a string date has a correct values in every part (year, month,...).
     */
    _validateDate: function (strDate: string, mask: string): boolean {
      let result = true;
      const dateArray = CWTYPE.DATE._obtainDateArray(strDate, mask);
      const day = parseInt(dateArray[0], 10);
      const month = parseInt(dateArray[1], 10);
      const year = parseInt(dateArray[2], 10);

      //Day/Month/Year are numeric
      if (isNaN(day) || isNaN(month) || isNaN(year)) {
        result = false;
      }

      //The date can't be lower than 31/12/1899
      if (year < 1899 || year > 2100) {
        result = false;
      }
      if (year === 1899 && (day !== 31 || month !== 12)) {
        result = false;
      }
      //Validate the date months
      if (month < 1 || month > 12) {
        result = false;
      }
      //Validate the date days
      if (day < 1 || day > 31) {
        result = false;
      }
      if ((month === 4 || month === 6 || month === 9 || month === 11) && day > 30) {
        result = false;
      }
      if (month === 2 && CWTYPE.DATE._isLeapYear(year) && day > 29) {
        result = false;
      }
      if (month === 2 && !CWTYPE.DATE._isLeapYear(year) && day > 28) {
        result = false;
      }
      return result;
    },

    /**
     * Function to replace all the ocurrences in a string by another string.
     */
    _replaceAll: function (str: string, searchStr: string, replaceStr: string): string {
      let result = str;

      if (!CWSTR.isBlank(searchStr)) {
        while (result.indexOf(searchStr) !== -1) {
          result = result.replace(searchStr, replaceStr);
        }
      }
      return result;
    },

    /**
     * Function to validate the format of a string date.
     */
    _validateDateFormat: function (strDate: string, mask: string): boolean {
      const dateLength = strDate.length;
      const day = mask.search("D"); //2 digits
      const month = mask.search("M"); //2 digits
      const year = mask.search("Y"); //4 digits

      if (year < day && year < month) { //Years first 4,6,8
        return (dateLength === 4 || dateLength === 6 || dateLength === 8) ? true : false;
      } else if (year < day || year < month) { //Years at middle 2,6,8
        return (dateLength === 2 || dateLength === 6 || dateLength === 8) ? true : false;
      } else if (year > day && year > month) { //Years at last 2,4,8
        return (dateLength === 2 || dateLength === 4 || dateLength === 8) ? true : false;
      }
      return false;
    },

    events: function (selectorDate?: string): any {
      const eventsObj: any = {};
      let selector = selectorDate

      if (CWSTR.isBlank(selector)) {
        selector = "";
      }
      eventsObj["focus " + selector + " .typeDate:not([readonly])" as any] = function (e: any): void {
        CWTYPE._eventFocus(e, "typeDate", CWTYPE.DATE, this);
      };
      //Customized blur event
      eventsObj["blur " + selector + " .typeDate:not([readonly])" as any] = function (e: any): void {
        const attr = e.target.className.split(" ")[0];
        const maskCode = CWTYPE._getMaskCode(attr, this, "typeDate");//get Format Mask
        const formatMask = CWTYPE._getFormatByCode(maskCode);
        const parse = CWTYPE.DATE.parse(e.target.value, maskCode);//Parse the input value

        //Check changes on the original Value.
        if (String(this.tempOrigVal) !== parse["val" as any]) {
          if (!(this.dynamicAttributes && String(e.target.value) === String(this.tempOrigVal))) {
            $(e.target).change();
          }
        }
        //Check period conditions if needed
        if ($(e.target).hasClass("periodStart") && !$(e.target).hasClass("avoidEqualize") && String(this.tempOrigVal) !== parse["val" as any] && !CWSTR.isBlank(parse["val" as any]) && !parse.errors) {
          const periodId = $(e.target).attr("periodId");
          const endDate = this.$el.find(".periodEnd[periodId=" + periodId + "]");
          const endDateName = endDate.attr("class").split(" ")[0];
          const endDateValue = CWSTR.getElValue(this._getModel(attr), endDateName);

          if (!CWSTR.isBlank(endDateValue) && parse["val" as any] > endDateValue) {
            endDate.val(CWTYPE.DATE.format(parse["val" as any], formatMask));
            endDate.change();
          }
        }
        //Check errors and format if dont has
        if ($(e.target).hasClass("infinityDate") && parse["val" as any] === CWTYPE.DATE.INFINITY && GLOBAL_DATA.paramDivers.get("CACH_INFIN").get("valeur") === "1") {
          $(e.target).val("");
        } else if (!parse["errors" as any]) {//} && !$(e.target).hasClass("datesitu")) {
          $(e.target).val(CWTYPE.DATE.format(parse["val" as any], formatMask));
        }
        delete this.tempOrigVal;
      };
      eventsObj["keypress  " + selector + " .typeDate:not([readonly])" as any] = function (e: JQueryEventObject): void {
        CWTYPE._eventKeypress(e, "typeDate", CWTYPE.DATE, this, true);
      };
      eventsObj["keydown  " + selector + " .typeDate:not([readonly])" as any] = function (e: JQueryEventObject): void {
        CWTYPE._eventDownLeft2Right(e, "typeDate", CWTYPE.DATE, this);
      };
      return eventsObj;
    },

    /**
     * Function to know if a year is a leap year.
     */
    _isLeapYear: function (year?: number): boolean {
      let result = false;

      if (!CWSTR.isBlank(year) && (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0))) {
        result = true;
      }
      return result;
    },

    /**
     * Function to know if a day is in a period. If day is not defined it will be the server current date.
     */
    _checkDateInPeriod: function (datedeb: string, datefin: string, dateJour?: string): boolean {
      const lDateJour = (!CWSTR.isBlank(dateJour)) ? dateJour : CWTYPE.DATE.dateToStr(SYNC.getServerDate(), "DD/MM/YYYY");
      const dateDeb = CWTYPE.DATE.format(datedeb, CWTYPE._getFormatByCode(CWTYPE.DATE.DEFAULT_MASK_CODE));
      const dateFin = CWTYPE.DATE.format(datefin, CWTYPE._getFormatByCode(CWTYPE.DATE.DEFAULT_MASK_CODE));
      const d1 = dateDeb.split("/");
      const d2 = dateFin.split("/");
      const c = lDateJour.split("/");
      const from = new Date(parseInt(d1[2]), parseInt(d1[1], 10) - 1, parseInt(d1[0], 10));
      const to = new Date(parseInt(d2[2]), parseInt(d2[1], 10) - 1, parseInt(d2[0], 10));
      const check = new Date(parseInt(c[2]), parseInt(c[1], 10) - 1, parseInt(c[0], 10));

      return (check >= from && check <= to);
    },

    //pb 255730
    /**
     * Function to calculating the previus day.
     */
    _yesterdayOf: function (date: string): string {
      const day = parseInt(date.substring(6, 8));
      const month = parseInt(date.substring(4, 6), 10) - 1;//month index
      const year = parseInt(date.substring(0, 4), 10);
      const today: Date = new Date(year, month, day);//new Date(year, monthIndex, day)	
      const yesterday: Date = new Date((today as any) - 1); //yesterday
      const sYesterday = yesterday.toISOString(); //aaaa-mm-ddTXX:XX:XX.XXXX	

      date = sYesterday.substring(0, 4) + sYesterday.substring(5, 7) + sYesterday.substring(8, 10);
      return date;
    }
  };

  /**
 * Type self represents the decimals
 */
  static DECIMAL_1 = {

    TYPE_CLASS: "typeDecimal_1",

    DEFAULT_MASK_CODE: "DECIMAL_1",

    /**
     * Validate the input and parse it to match with the model raw type
     */
    parse: function (input: any, ndecimals: number, separator?: string): { [key: string]: any } {
      return CWTYPE.DECIMAL.parse(input, ndecimals, separator, CWTYPE.DECIMAL_1);
    },

    /**
     * Apply the formatMask to the input value
     */
    format: function (input: any, formatMask?: string, ndecimals?: number): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.DECIMAL_1.DEFAULT_MASK_CODE);
      }
      return CWTYPE.DECIMAL.format(input, formatMask, ndecimals, CWTYPE.DECIMAL_1);
    },

    /**
     * Validate the input, checks if is a float or not.
     */
    validate: function (input: any, separator?: string): string {
      return CWTYPE.DECIMAL.validate(input, separator);
    },

    events: function (selector?: any): any {
      return CWTYPE.DECIMAL.events(selector, CWTYPE.DECIMAL_1);
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.DECIMAL.parseOnFocus(input, inputMask);
    }
  };

  /**
   * Type self represents the decimals
   */
  static DECIMAL_2 = {

    TYPE_CLASS: "typeDecimal_2",

    DEFAULT_MASK_CODE: "DECIMAL_2",

    /**
     * Validate the input and parse it to match with the model raw type
     */
    parse: function (input: any, ndecimals: number, separator?: string): { [key: string]: any } {
      return CWTYPE.DECIMAL.parse(input, ndecimals, separator, CWTYPE.DECIMAL_2);
    },

    /**
     * Apply the formatMask to the input value
     */
    format: function (input: any, formatMask?: string, ndecimals?: number): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.DECIMAL_2.DEFAULT_MASK_CODE);
      }
      return CWTYPE.DECIMAL.format(input, formatMask, ndecimals, CWTYPE.DECIMAL_2);
    },

    /**
     * Validate the input, checks if is a float or not.
     */
    validate: function (input: any, separator?: string): string {
      return CWTYPE.DECIMAL.validate(input, separator);
    },

    events: function (selector?: any): any {
      return CWTYPE.DECIMAL.events(selector, CWTYPE.DECIMAL_2);
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.DECIMAL.parseOnFocus(input, inputMask);
    }
  };

  /**
   * Type self represents the Jours quantity.
   */
  static JOUR = {

    TYPE_CLASS: "typeJour",

    DEFAULT_MASK_CODE: "NBJOUR",

    /**
     * Validate the input integrity.
     */
    validate: function (input: any): string {
      let error = null;

      if (!CWSTR.isBlank(input)) {
        const min = CWTYPE.LONG.MIN_VALUE;
        const max = CWTYPE.LONG.MAX_VALUE;

        if (_.isNaN(parseFloat(input))) {
          error = i18n.t('messages:GL_2');
        } else if (!(CWTYPE._isInt(input) && input >= min && input <= max)) {
          error = i18n.t('messages:GL_1008');
        }
      }
      return error;
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.LONG.parseOnFocus(input, inputMask);
    },

    parse: function (input: any, type?: any): { [key: string]: any } {
      if (CWSTR.isBlank(type)) {
        type = CWTYPE.JOUR;
      }
      return CWTYPE.LONG.parse(input, type);
    },

    format: function (input: any, formatMask?: string): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.JOUR.DEFAULT_MASK_CODE);
      }
      return CWTYPE.LONG.format(input, formatMask);
    },

    events: function (selector?: string, type?: any): { [key: string]: any } {
      if (CWSTR.isBlank(type)) {
        type = CWTYPE.JOUR;
      }
      return CWTYPE.LONG.events(selector, type);
    }
  };

  /**
   * Type self represents the Jours quantity.
   */
  static JOUR_0 = {

    TYPE_CLASS: "typeJour_0",

    DEFAULT_MASK_CODE: "NBJOUR_0",

    /**
     * Validate the input integrity.
     */
    validate: function (input: any): string {
      return CWTYPE.JOUR.validate(input);
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.JOUR.parseOnFocus(input, inputMask);
    },

    parse: function (input: any): { [key: string]: any } {
      return CWTYPE.JOUR.parse(input, CWTYPE.JOUR_0);
    },

    format: function (input: any, formatMask?: string): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.JOUR_0.DEFAULT_MASK_CODE);
      }
      return CWTYPE.JOUR.format(input, formatMask);
    },

    events: function (selector?: string): { [key: string]: any } {
      return CWTYPE.JOUR.events(selector, CWTYPE.JOUR_0);
    }
  };


  /**
  * Type self represents the Jours quantity.
  */
  static JOUR_1 = {

    TYPE_CLASS: "typeJour_1",

    DEFAULT_MASK_CODE: "NBJOUR_1",

    /**
     * Validate the input integrity.
     */
    validate: function (input: any): string {
      return CWTYPE.JOUR.validate(input);
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.JOUR.parseOnFocus(input, inputMask);
    },

    parse: function (input: any): { [key: string]: any } {
      return CWTYPE.JOUR.parse(input, CWTYPE.JOUR_1);
    },

    format: function (input: any, formatMask?: string): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.JOUR_1.DEFAULT_MASK_CODE);
      }
      return CWTYPE.JOUR.format(input, formatMask);
    },

    events: function (selector?: string): { [key: string]: any } {
      return CWTYPE.JOUR.events(selector, CWTYPE.JOUR_1);
    }
  };

  /**
   * Type self represents the Jours quantity.
   */
  static JOUR_2 = {

    TYPE_CLASS: "typeJour_2",

    DEFAULT_MASK_CODE: "NBJOUR_2",

    /**
     * Validate the input integrity.
     */
    validate: function (input: any): string {
      return CWTYPE.JOUR.validate(input);
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.JOUR.parseOnFocus(input, inputMask);
    },

    parse: function (input: any): { [key: string]: any } {
      return CWTYPE.JOUR.parse(input, CWTYPE.JOUR_2);
    },

    format: function (input: any, formatMask?: string): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.JOUR_2.DEFAULT_MASK_CODE);
      }
      return CWTYPE.JOUR.format(input, formatMask);
    },

    events: function (selector?: string): { [key: string]: any } {
      return CWTYPE.JOUR.events(selector, CWTYPE.JOUR_2);
    }
  };

  /**
   * Type self represents the Percentages on the application.
   */
  static PERCENTAGE = {

    TYPE_CLASS: "typePercentage",

    DEFAULT_MASK_CODE: "POURCENT_2",

    /**
     * Validate the input integrity.
     */
    validate: function (input: any, min?: number, max?: number): string {
      let error = null;

      if (!CWSTR.isBlank(input)) {
        if (_.isNaN(parseFloat(input))) {
          error = i18n.t('messages:GL_2');
        } else {
          if (CWSTR.isBlank(min)) {
            min = CWTYPE.LONG.MIN_VALUE;
          }
          if (CWSTR.isBlank(max)) {
            max = CWTYPE.LONG.MAX_VALUE;
          }
          if (!CWSTR.isBlank(min) && !CWSTR.isBlank(max)) {
            if (!(input >= min && input <= max)) {
              error = i18n.t('messages:GL_1008');
            }
          } else if (CWSTR.isBlank(min) && !CWSTR.isBlank(max)) {
            if (!(input <= max)) {
              error = i18n.t('messages:GL_1008');
            }
          } else if (!CWSTR.isBlank(min) && CWSTR.isBlank(max)) {
            if (!(input >= min)) {
              error = i18n.t('messages:GL_1008');
            }
          }
        }
      }
      return error;
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.LONG.parseOnFocus(input, inputMask);
    },

    parse: function (input: any, type?: any): { [key: string]: any } {
      if (CWSTR.isBlank(type)) {
        type = CWTYPE.PERCENTAGE;
      }
      return CWTYPE.LONG.parse(input, type);
    },

    format: function (input: any, formatMask?: string): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.PERCENTAGE.DEFAULT_MASK_CODE);
      }
      return CWTYPE.LONG.format(input, formatMask);
    },

    events: function (selector?: string, type?: any): { [key: string]: any } {
      if (CWSTR.isBlank(type)) {
        type = CWTYPE.PERCENTAGE;
      }
      return CWTYPE.LONG.events(selector, type);
    }
  };

  /**
 * Type self represents percentages without decimal values
 */
  static PERCENTAGE_0 = {

    TYPE_CLASS: "typePercentage_0",

    DEFAULT_MASK_CODE: "POURCENT_0",

    /**
     * Validate the input integrity.
     */
    validate: function (input: any, min?: number, max?: number): string {
      return CWTYPE.PERCENTAGE.validate(input, min, max);
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.PERCENTAGE.parseOnFocus(input, inputMask);
    },

    parse: function (input: any): { [key: string]: any } {
      return CWTYPE.PERCENTAGE.parse(input, CWTYPE.PERCENTAGE_0);
    },

    format: function (input: any, formatMask?: string): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.PERCENTAGE_0.DEFAULT_MASK_CODE);
      }
      return CWTYPE.PERCENTAGE.format(input, formatMask);
    },

    events: function (selector?: string): { [key: string]: any } {
      return CWTYPE.PERCENTAGE.events(selector, CWTYPE.PERCENTAGE_0);
    }
  };

  /**
   * Type self represents percentages with only one decimal
   */
  static PERCENTAGE_1 = {

    TYPE_CLASS: "typePercentage_1",

    DEFAULT_MASK_CODE: "POURCENT_1",

    /**
     * Validate the input integrity.
     */
    validate: function (input: any, min?: number, max?: number): string {
      return CWTYPE.PERCENTAGE.validate(input, min, max);
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.PERCENTAGE.parseOnFocus(input, inputMask);
    },

    parse: function (input: any): { [key: string]: any } {
      return CWTYPE.PERCENTAGE.parse(input, CWTYPE.PERCENTAGE_1);
    },

    format: function (input: any, formatMask?: string): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.PERCENTAGE_1.DEFAULT_MASK_CODE);
      }
      return CWTYPE.PERCENTAGE.format(input, formatMask);
    },

    events: function (selector?: string): { [key: string]: any } {
      return CWTYPE.PERCENTAGE.events(selector, CWTYPE.PERCENTAGE_1);
    }
  };

  /**
   * Type self represents percentages with 2 decimals
   */
  static PERCENTAGE_2 = {

    TYPE_CLASS: "typePercentage_2",

    DEFAULT_MASK_CODE: "POURCENT_2",

    /**
     * Validate the input integrity.
     */
    validate: function (input: any, min?: number, max?: number): string {
      return CWTYPE.PERCENTAGE.validate(input, min, max);
    },

    parseOnFocus: function (input: any, inputMask: string): string {
      return CWTYPE.PERCENTAGE.parseOnFocus(input, inputMask);
    },

    parse: function (input: any): { [key: string]: any } {
      return CWTYPE.PERCENTAGE.parse(input, CWTYPE.PERCENTAGE_2);
    },

    format: function (input: any, formatMask?: string): string {
      if (CWSTR.isBlank(formatMask)) {
        formatMask = CWTYPE._getFormatByCode(CWTYPE.PERCENTAGE_2.DEFAULT_MASK_CODE);
      }
      return CWTYPE.PERCENTAGE.format(input, formatMask);
    },

    events: function (selector?: string): { [key: string]: any } {
      return CWTYPE.PERCENTAGE.events(selector, CWTYPE.PERCENTAGE_2);
    }
  };
}//CLASS





















