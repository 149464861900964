module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="c-paginator__container">\n    <div class="c-paginator__item c-paginator__first c-paginator__action">\n        '+
((__t=(UTILS.getSVGIcon('chevron-trait_gauche')))==null?'':__t)+
'\n    </div>\n    <div class="c-paginator__item c-paginator__prev c-paginator__action">\n        '+
((__t=(UTILS.getSVGIcon('chevron_gauche')))==null?'':__t)+
'\n    </div>\n    <div class="c-paginator__item c-paginator__count">\n        <span class="cw-titreSousBloc">'+
((__t=(i18n.t("common:grid.paginator.page")))==null?'':__t)+
' </span>\n        <span class="cw-texteSecondarySemibold c-paginator__pageNumber">-</span>\n        <span class="cw-titreSousBloc"> / </span>\n        <span class="cw-texteSecondarySemibold c-paginator__lastPage">-</span>\n        <span class="cw-titreSousBloc"> (</span><span class="cw-texteSecondarySemibold c-paginator__numberElements">-</span>\n        <span class="cw-titreSousBloc c-paginator__elementsString">'+
((__t=(i18n.t("common:grid.paginator.elements")))==null?'':__t)+
')</span><span class="cw-titreSousBloc">)</span>\n    </div>\n    <div class="c-paginator__item c-paginator__next c-paginator__action">\n        '+
((__t=(UTILS.getSVGIcon('chevron_droite')))==null?'':__t)+
'\n    </div>\n    <div class="c-paginator__item c-paginator__last c-paginator__action">\n        '+
((__t=(UTILS.getSVGIcon('chevron-trait_droite')))==null?'':__t)+
'\n    </div>\n</div>\n';
}
return __p;
};
