import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLListerregularisationsFilter from '../cwMoreFilter.tpl.html';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWComboBoxView2 } from 'src/core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'src/core/components/combo/cwCombos.collection';
import { CWDregularisationsWorkflow } from 'src/uc/mon_equipe/valider/dregularisations/models/cwDregularisations.workflow';
import { CWFilterBaseModel } from 'src/core/models/cwFilterBase.model';
import { CWFORMS } from 'utils/cwForms';
import { CWMoreFilterModel } from '../models/cwMoreFilter.model';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n.js';


/**
 * View for the content of the filter part
 */
export class CWFilterMoreContentListeRegulView extends CWBaseFormView<CWFilterBaseModel> {
  private comboMotif: CWComboBoxView2;
  private workflow: CWDregularisationsWorkflow;
  model: CWMoreFilterModel;
  private motifComboPlaceholder: { [key: string]: any };

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options.events = _.extend({
      "change :input:not([readonly])": "_changeModel"
    }, CWTYPE.LONG.events(),
      CWTYPE.DATE.events(),
      CWTYPE.DATE.events(),
      CWTYPE.CUSTOM.events(), options.events);
    super(options);
    this.template = TPLListerregularisationsFilter;
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }
    this.setHabContext(options.mainView.getHabContext());
    this._initCombos();

    this.model = new CWMoreFilterModel(null, this.workflow);

    this.typeMaskByClass = {};
    this.typeMaskByClass["datedeb"] = "DATE_L";
    this.typeMaskByClass["datefin"] = "DATE_L";
  }

  _initCombos(): void {
    this.motifComboPlaceholder = {
      code: "aucun",
      libelle: i18n.t('common:placeholder_combo.combo_motifs_regul')
    }
    this.comboMotif = new CWComboBoxView2({
      ws: new CWCombosColl({
        comboId: "motifregularisation"
      }),
      autocomplete: true,
      name: "motif",
      habContext: this.getHabContext(),
      optionsRender: (item: { [key: string]: any }): string => {
        if (item.code === this.motifComboPlaceholder.code || CWSTR.isBlank(item.code)) {
          return this.motifComboPlaceholder.libelle;
        }
        return item.libelle + " (" + item.code + ")";
      }
    });

  }
  _getModel(): CWMoreFilterModel {
    return this.model;
  }

  render(): CWFilterMoreContentListeRegulView {
    const json = { "i18n": i18n };
    this.$el.html(this.template(json));

    //Render the combos
    $(this.el).find(".cmbMotif").html(this.comboMotif.render().el);


    // Render the datepickers
    CWFORMS.setDatepicker(this, ".datedeb");
    CWFORMS.setDatepicker(this, ".datefin");

    this._mapModelToForm($(this.el), this._getModel());
    this.comboMotif.setItem(this.motifComboPlaceholder);
    return this;
  }

  _changeModel(event: JQueryEventObject, data: string | any, silentValue: boolean): void {
    const className = event.currentTarget.className.split(" ")[0];
    let selectedMotif;

    if (className === "motif") {
      selectedMotif = this.comboMotif.getItemId();
      if (CWSTR.isBlank(selectedMotif)) {
        this.comboMotif.setItem(this.motifComboPlaceholder);
      }
    }
    this._change(event, data, silentValue, true);
    for (const i in this.model.attributes) {
      if (CWSTR.isBlank(this.model.get(i))) {
        this.model.unset(i, { silent: true });
      }
    }
  }

  restaureDefaultValues(): void {
    this.model.restaureDefaultValues();
    this._mapModelToForm($(this.el), this._getModel());
    this.comboMotif.setItem(this.motifComboPlaceholder);
  }

}
