import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseGridCollection } from 'src/core/grids/basegrid/cwBaseGrid.collection';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWListerecapGeneralContextInterface } from '../views/cwListerecapGeneral.view';
import { CWRecapModel } from './cwRecap.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';


/**
 * Collection of DAbsence
 */
export class CWRecapColl<TModel extends CWRecapModel = CWRecapModel> extends CWBaseGridCollection<CWRecapModel> {
  coll: Backbone.Collection<TModel>;
  filtreStatus: string;
  filtreDatedeb: string;
  filtreDatefin: string;
  matricule: string;
  piecejointe: boolean;
  nbrecEtatValid: number;
  nbrecEtatA: number;
  nbrecEtatR: number;
  /**
   * Add this to have Outline
   *
   */
  constructor(models?: CWRecapModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWRecapModel;
    super(models, options);
    //Default values from class
    this.habilitationV = "N";
    this.habilitationG = "N";
    this.url = (): string => {
      const optionalParams = this._buildURL();
      return Configuration.restRoot + "/rest/composant/recapactivite/liste" + optionalParams;
    };
    this.paginated = true;
  }

  setSortings(ctxTriDate: string): void {
    const asc = (ctxTriDate === "Ascendant") ? true : false;
    this.sortings = { datedeb: asc, nom: asc, prenom: asc };
  }

  /**
   * Set parametres to the collection from context
   */
  setParametres(context: CWListerecapGeneralContextInterface, pDiverIdCollab: string, maxPieces: CWBaseModel, paramDivPieceJointe: CWBaseModel): void {
    if (context.ctxFiltreApplique && context.ctxFiltreApplique.length > 1) {
      this.filtreStatus = context.ctxFiltreApplique.join(",");
    } else {
      this.filtreStatus = "";
    }

    if (context.ctxPeriode && context.ctxPeriode.datedeb && context.ctxPeriode.datedeb !== CWTYPE.DATE.INITIAL) {
      this.filtreDatedeb = context.ctxPeriode.datedeb;
    }
    if (context.ctxPeriode && context.ctxPeriode.datefin && context.ctxPeriode.datefin !== CWTYPE.DATE.INFINITY) {
      this.filtreDatefin = context.ctxPeriode.datefin;
    }
    if (context.ctxCollab && (context.ctxCollab.matricule)) {
      this.matricule = context.ctxCollab.matricule;
    }
    if (CWSTR.isBlank(maxPieces) || maxPieces.get("maxi") <= 0 ||
      paramDivPieceJointe.get("valeur") !== "1") {
      this.piecejointe = false;
    } else {
      this.piecejointe = true;
    }
  }
  /**
   * Build the URL by the params
   */
  _buildURL(): string {
    let existParam = false;
    let params = "";

    if (!CWSTR.isBlank(this.filtreStatus)) {
      existParam = true;
      params += "filtre_statuts=" + encodeURIComponent(this.filtreStatus);
    }

    if (!CWSTR.isBlank(this.filtreDatedeb)) {
      if (existParam) {
        params += "&";
      }
      existParam = true;
      params += "filtre_datedeb=" + encodeURIComponent(this.filtreDatedeb);
    }
    if (!CWSTR.isBlank(this.filtreDatefin)) {
      if (existParam) {
        params += "&";
      }
      existParam = true;
      params += "filtre_datefin=" + encodeURIComponent(this.filtreDatefin);
    }
    if (!CWSTR.isBlank(this.matricule)) {
      if (existParam) {
        params += "&";
      }
      existParam = true;
      params += "matricule=" + encodeURIComponent(this.matricule);
    }
    if (!CWSTR.isBlank(this.piecejointe)) {
      if (existParam) {
        params += "&";
      }
      existParam = true;
      params += "piecejointe=" + encodeURIComponent(this.piecejointe);
    }
    if (existParam) {
      params = "?" + params;
    }
    return params;
  }
}
