import _ from 'underscore';
import { CWDialogPopupType, CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSTR } from './cwStr';
import { DesactivateMessageModel } from 'core/models/desactivateMessage.model.js';
import { i18n } from 'src/i18n.js';
import { SESSION } from './session.js';
//import { UTILS } from '../utils/utils';

/**
 * User messages manager
 */

export interface ShowConfirmOptions {
  text: string;
  callback?: (action: string) => any;
  buttons?: { [key in "yes" | "no" | "cancel"]?: boolean }; // for example: buttons: { yes: true, no: true },
  buttonsText?: { [key in "yes" | "no" | "cancel"]?: string }; // for example: buttonsText: { yes: "Oui", no: "Non", cancel: "Annuler" },
  buttonsClass?: { [key in "yes" | "no" | "cancel"]?: string }; // for example: buttonsClass: { yes: "btn-primary", no: "btn-secondary", cancel: "btn-alert" },
  appendTo?: JQuery | HTMLElement;
  width?: string | number;
  height?: string | number;
}

export class CWMSGS {

  /**
   * Confirm the family, number and usecase.
   *
   */
  static confirmMessage(opt: { [key: string]: any }, options: { [key: string]: any }): boolean {
    const casesArray = [
      { famille: "GT", numero: 1496, usecase: "utilisateur" },
      { famille: "GT", numero: 1496, usecase: "habr" },
      { famille: "GT", numero: 1496, usecase: "habg" },
      { famille: "GT", numero: 1496, usecase: "habp" },
      { famille: "GT", numero: 1932, usecase: "suivicollab" }
    ];
    let onglet = "";

    if (!CWSTR.isBlank(options) && !CWSTR.isBlank(options.headers) && !CWSTR.isBlank(options.headers["gfi-context"]) && !CWSTR.isBlank(JSON.parse(options.headers["gfi-context"]).onglet)) {
      onglet = JSON.parse(options.headers["gfi-context"]).onglet;
    } else {
      return false;
    }

    for (let i = 0; i < casesArray.length; i++) {
      if ((opt.famille === casesArray[i].famille) && (opt.numero === casesArray[i].numero) && (onglet === casesArray[i].usecase)) {
        return true;
      }
    }

    return false;
  }

  /**
   * Show a info message to the user
   *
   */
  static showInfo(str: string, appendTo?: JQuery, callback?: (element?: CWDialogPopupView) => any): JQuery {
    let dialogView: CWDialogPopupView = null;
    const timeout = 3000; // millis
    const viewData: any = {
      modal: false
    }
    if (!CWSTR.isBlank(appendTo)) {
      viewData.appendTo = appendTo;
    }
    const options: any = {
      viewData: viewData,
      popupType: CWDialogPopupType.INFORMATION,
      textContent: str,
      height: "auto",
      width: "auto",
      maxHeight: 200,
      maxWidth: 800,
      minHeight: 100,
      minWidth: 400,
      notIconClose: true,
      position: {
        my: "right bottom",
        at: "right bottom",
        of: "#phx-container"
      },
      create: (): void => {
        const $parent = dialogView.$el.parent();

        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
        // apply unique styling
        dialogView.$el.parents(".ui-dialog:first").find(".ui-dialog-titlebar-close").addClass("ui-state-highlight");
      },
      open: (): void => {
        window.setTimeout(() => {
          // If focused element is not inside dialog, restore it back after dialog close.
          let activeElement;
          const dialogContainer = dialogView.$el.parent().get(0);

          if (dialogContainer && !$.contains(dialogContainer, document.activeElement) && dialogContainer !== document.activeElement) {
            activeElement = document.activeElement;
          }
          if (!CWSTR.isBlank($(dialogView.dialog).dialog("instance"))) {
            $(dialogView.dialog).dialog('close');
          }
          if (activeElement && activeElement instanceof HTMLInputElement) {
            activeElement.focus();
          }
        }, timeout);
      },
      close: (): void => {
        if ($(dialogView.dialog).dialog("instance")) {
          $(dialogView.dialog).dialog("destroy");
        }
        $(dialogView.dialog).remove();
        //on désactive les tooltips pendants et on l'active donc il doit fonctionner à nouveau
        _.each($(document).find("[aria-describedby]"), (element): void => {
          if (element && $(element).tooltip("instance")) {
            $(element).tooltip("disable");
            $(element).tooltip("enable");
          }
        });
      }
    };
    dialogView = new CWDialogPopupView(options);
    dialogView.setHeight("auto");
    dialogView.setWidth("auto");
    dialogView.open();
    if (typeof callback === "function") {
      callback(dialogView);
    }
    const lPos = dialogView.$el.parent(".c-cwDialogPopup.ui-dialog");
    if (lPos.length > 0 && !lPos.hasClass("cw-info__reposition")) {
      lPos.addClass("cw-info__reposition");
    }
    dialogView.model.on("dlg:close", (events: JQueryEventObject): void => {
      const $element = $(events.currentTarget);

      if ($element.dialog("instance")) {
        $element.dialog("destroy");
        $element.remove();
      }
    });
    return dialogView.dialog;
  }

  /**
   * Show a warning message to the user
   *
   */
  static showWarning(str: string, callback?: () => any, noShowAgainButton?: boolean, noShowAgainId?: string, appendTo?: JQuery): JQuery {
    if (noShowAgainButton === true && SESSION.get(noShowAgainId) === true) {
      if (!CWSTR.isBlank(callback)) {
        callback();
      }
      return null;
    } else {
      let dialogView: CWDialogPopupView = null;
      const text = str;
      const icon = "<span class='ui-icon ui-icon-alert' style='float: left; margin-right: .3em;'></span>";
      const id = _.uniqueId("dialogShowWarning");
      const $div = $("<div>").attr("id", id);
      const $p = $("<p>").addClass("phx-msgs-body").html(icon + text);
      const viewData: { [key: string]: any } = { modal: true };
      let options: { [key: string]: any } = {};

      if (!CWSTR.isBlank(appendTo)) {
        viewData.appendTo = appendTo;
      }
      $div.append($p);
      if (noShowAgainButton === true) {
        const noShowAgain = $("<div class='custom-control custom-checkbox'><input id='neplusaff' type='checkbox' name='neplusaff' class='custom-control-input'></input><label for='neplusaff' class='custom-control-label'>" + i18n.t('common:neplusaff') + "</label></div");

        noShowAgain.css({
          "margin-top": "20px",
          "margin-bottom": "-20px",
          "margin-left": "calc(50% - 90px)"
        });
        $div.append(noShowAgain);
      }
      options = {
        viewData: viewData,
        popupType: CWDialogPopupType.INFORMATION,
        textContent: $div.html(),
        dialogClass: "ui-dialog-showWarning",
        width: 500,
        title: i18n.t('common:msgs_warning_title'),
        closeText: i18n.t('common:close'),
        buttons: [{
          autofocus: true,
          btnClass: "btn btn-primary",
          text: i18n.t('common:close'),
          click: (): void => {
            $(dialogView.dialog).dialog("close");
          }
        }],
        create: (): void => {
          const $parent = dialogView.$el.parent();

          $parent.removeClass("ui-corner-all");
          $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
          // apply unique styling
          dialogView.$el.parents(".ui-dialog:first").find(".ui-dialog-content span.ui-icon").wrap('<div class="ui-state-highlight" style="border:none; background:none;" />');
          dialogView.$el.parents(".ui-dialog:first").find(".ui-dialog-titlebar").addClass("ui-state-highlight");
        },
        close: (event: JQueryEventObject): void => {
          if (noShowAgainButton === true) {
            SESSION.set(noShowAgainId, $(event.target).find("[name=neplusaff]").is(":checked"));
          }
          if (!CWSTR.isBlank(callback)) {
            callback();
          }
          if ($(dialogView.dialog).dialog("instance")) {
            $(dialogView.dialog).dialog("destroy");
          }
          $(dialogView.dialog).remove();
          //on désactive les tooltips pendants et on l'active donc il doit fonctionner à nouveau
          _.each($(document).find("[aria-describedby]"), (element) => {
            if (element && $(element).tooltip("instance")) {
              $(element).tooltip("disable");
              $(element).tooltip("enable");
            }
          });
        }
      };
      dialogView = new CWDialogPopupView(options);
      dialogView.setHeight("auto");
      dialogView.setWidth("500");
      dialogView.open();
      dialogView.model.on("dlg:close", (events: JQueryEventObject): void => {
        const $element = $(events.currentTarget);

        if ($element.dialog("instance")) {
          $element.dialog("destroy");
          $element.remove();
        }
      });
      return dialogView.dialog;
    }
  }

  /**
   * Show a warning message to the user
   *
   */
  static showWarningDialogView(str: string, callback?: () => any, noShowAgainButton?: boolean, noShowAgainId?: string, appendTo?: JQuery): CWDialogPopupView {
    if (noShowAgainButton === true && SESSION.get(noShowAgainId) === true) {
      if (!CWSTR.isBlank(callback)) {
        callback();
      }
      return null;
    } else {
      let dialogView: CWDialogPopupView = null;
      const text = str;
      const icon = "<span class='ui-icon ui-icon-alert' style='float: left; margin-right: .3em;'></span>";
      const id = _.uniqueId("dialogShowWarning");
      const $div = $("<div>").attr("id", id);
      const $p = $("<p>").addClass("phx-msgs-body").html(icon + text);
      const viewData: any = {
        modal: true
      }

      if (!CWSTR.isBlank(appendTo)) {
        viewData.appendTo = appendTo;
      }

      $div.append($p);

      if (noShowAgainButton === true) {
        const noShowAgain = $("<div class='custom-control custom-checkbox'><input id='neplusaff' type='checkbox' name='neplusaff' class='custom-control-input'></input><label for='neplusaff' class='custom-control-label'>" + i18n.t('common:neplusaff') + "</label></div");
        noShowAgain.css({
          "margin-top": "20px",
          "margin-bottom": "-20px",
          "margin-left": "calc(50% - 90px)"
        });
        $div.append(noShowAgain);
      }

      const options: any = {
        viewData: viewData,
        popupType: CWDialogPopupType.INFORMATION,
        textContent: $div.html(),
        dialogClass: "ui-dialog-showWarning",
        width: 500,
        title: i18n.t('common:msgs_warning_title'),
        closeText: i18n.t('common:close'),
        buttons: [{
          autofocus: true,
          btnClass: "btn btn-primary",
          text: i18n.t('common:close'),
          click: (): void => {
            $(dialogView.dialog).dialog("close");
          }
        }],
        create: (): void => {
          const $parent = dialogView.$el.parent();

          $parent.removeClass("ui-corner-all");
          $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
          // apply unique styling
          dialogView.$el.parents(".ui-dialog:first").find(".ui-dialog-content span.ui-icon").wrap('<div class="ui-state-highlight" style="border:none; background:none;" />');
          dialogView.$el.parents(".ui-dialog:first").find(".ui-dialog-titlebar").addClass("ui-state-highlight");
          //dialogView.$el.parents(".ui-dialog:first").find(".ui-dialog-titlebar-close").addClass("ui-state-highlight");
        },
        close: (event: JQueryEventObject): void => {
          if (noShowAgainButton === true) {
            SESSION.set(noShowAgainId, $(event.target).find("[name=neplusaff]").is(":checked"));
          }
          if (!CWSTR.isBlank(callback)) {
            callback();
          }
          if ($(dialogView.dialog).dialog("instance")) {
            $(dialogView.dialog).dialog("destroy");
          }
          $(dialogView.dialog).remove();
          //on désactive les tooltips pendants et on l'active donc il doit fonctionner à nouveau
          _.each($(document).find("[aria-describedby]"), (element) => {
            if (element && $(element).tooltip("instance")) {
              $(element).tooltip("disable");
              $(element).tooltip("enable");
            }
          });
        }
      };

      dialogView = new CWDialogPopupView(options);
      dialogView.setHeight("auto");
      dialogView.setWidth("500");
      dialogView.open();
      dialogView.model.on("dlg:close", (events: JQueryEventObject): void => {
        const $element = $(events.currentTarget);

        if ($element.dialog("instance")) {
          $element.dialog("destroy");
          $element.remove();
        }
      });
      return dialogView;
    }
  }

  /**
   * Show a info message to the user in a center popup
   *
   */
  static showInfoOpt(str: string, callback?: () => any, noShowAgainButton?: boolean, noShowAgainId?: string, appendTo?: JQuery): JQuery {
    if (noShowAgainButton === true && SESSION.get(noShowAgainId) === true) {
      if (!CWSTR.isBlank(callback)) {
        callback();
      }
      return null;
    } else {
      let dialogView: CWDialogPopupView = null;
      const text = str;
      const icon = "<span class='ui-icon ui-icon-info' style='float: left; margin-right: .3em;'></span>";
      const id = _.uniqueId("dialogShowWarning");
      const $div = $("<div>").attr("id", id);
      const $p = $("<p>").addClass("phx-msgs-body").html(icon + text);
      const viewData: any = {
        modal: true
      }

      $div.append($p);

      if (!CWSTR.isBlank(appendTo)) {
        viewData.appendTo = appendTo;
      }

      if (noShowAgainButton === true) {
        const noShowAgain = $("<div class='custom-control custom-checkbox'><input id='neplusaff' type='checkbox' name='neplusaff' class='custom-control-input'></input><label for='neplusaff' class='custom-control-label'>" + i18n.t('common:neplusaff') + "</label></div");

        noShowAgain.css({
          "margin-top": "20px",
          "margin-bottom": "-20px",
          "margin-left": "calc(50% - 90px)"
        });
        $div.append(noShowAgain);
      }

      const options: any = {
        viewData: viewData,
        popupType: CWDialogPopupType.INFORMATION,
        textContent: $div.html(),
        dialogClass: "ui-dialog-showWarning",
        width: 500,
        title: i18n.t('common:information'),
        closeText: i18n.t('common:close'),
        buttons: [{
          autofocus: true,
          text: i18n.t('common:close'),
          btnClass: "btn btn-primary",
          click: (): void => {
            $(dialogView.dialog).dialog("close");
          }
        }],
        create: (): void => {
          const $parent = dialogView.$el.parent();
          $parent.removeClass("ui-corner-all");
          $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
          // apply unique styling
          dialogView.$el.parents(".ui-dialog:first").find(".ui-dialog-content span.ui-icon").wrap('<div class="ui-state-highlight" style="border:none; background:none;" />');
          dialogView.$el.parents(".ui-dialog:first").find(".ui-dialog-titlebar").addClass("ui-state-highlight");
          dialogView.$el.parents(".ui-dialog:first").find(".ui-dialog-titlebar-close").addClass("ui-state-highlight");
        },
        close: (event: JQueryEventObject): void => {
          if (noShowAgainButton === true) {
            SESSION.set(noShowAgainId, $(event.target).find("[name=neplusaff]").is(":checked"));
          }
          if (!CWSTR.isBlank(callback)) {
            callback();
          }
          if ($(dialogView.dialog).dialog("instance")) {
            $(dialogView.dialog).dialog("destroy");
          }
          $(dialogView.dialog).remove();
          //on désactive les tooltips pendants et on l'active donc il doit fonctionner à nouveau
          _.each($(document).find("[aria-describedby]"), (element): void => {
            if (element && $(element).tooltip("instance")) {
              $(element).tooltip("disable");
              $(element).tooltip("enable");
            }
          });
        }
      };

      dialogView = new CWDialogPopupView(options);
      dialogView.setHeight("auto");
      dialogView.setWidth("500");
      dialogView.open();
      dialogView.model.on("dlg:close", (events: JQueryEventObject): void => {
        const $element = $(events.currentTarget);

        if ($element.dialog("instance")) {
          $element.dialog("destroy");
          $element.remove();
        }
      });
      return dialogView.dialog;
    }
  }

  /**
   * Show an error message to the user
   *
   */
  static showError(str: string, callback?: () => any, appendTo?: JQuery, isjQuery: boolean = true, position?: { [key: string]: any }): JQuery | CWDialogPopupView {
    let dialogView: CWDialogPopupView = null;
    const text = str;
    const icon = "<span class='ui-icon ui-icon-circle-close' style='float: left; margin-right: .3em;'></span>";
    const id = _.uniqueId("dialogShowError");
    const $div = $("<div>").attr("id", id);
    const $p = $("<p>").addClass("phx-msgs-body").html(icon + text);
    const viewData: any = {
      modal: true
    }
    $div.append($p);
    if (!CWSTR.isBlank(appendTo)) {
      viewData.appendTo = appendTo;
    }
    const options: any = {
      viewData: viewData,
      popupType: CWDialogPopupType.INFORMATION,
      textContent: $div.html(),
      dialogClass: "ui-dialog-showError",
      width: 500,
      title: i18n.t('common:msgs_error_title'),
      closeText: i18n.t('common:close'),
      buttons: [{
        autofocus: true,
        text: i18n.t('common:close'),
        btnClass: "btn btn-primary",
        click: (): void => {
          $(dialogView.dialog).dialog("close");
        }
      }],
      create: (): void => {
        const $parent = dialogView.$el.parent();
        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
        // apply unique styling
        dialogView.$el.parents(".ui-dialog:first").find(".ui-dialog-content span.ui-icon").wrap('<div class="ui-state-error" style="border:none; background:none;" />');
      },
      close: (): void => {
        if (!CWSTR.isBlank(callback)) {
          callback();
        }
        if ($(dialogView.dialog).dialog("instance")) {
          $(dialogView.dialog).dialog("destroy");
        }
        $(dialogView.dialog).remove();
        //on désactive les tooltips pendants et on l'active donc il doit fonctionner à nouveau
        _.each($(document).find("[aria-describedby]"), (element): void => {
          if (element && $(element).tooltip("instance")) {
            $(element).tooltip("disable");
            $(element).tooltip("enable");
          }
        });
      }
    };

    if (position) {
      options.position = position;
    }

    dialogView = new CWDialogPopupView(options);
    dialogView.setHeight("auto");
    dialogView.setWidth("500");
    dialogView.open();
    dialogView.model.on("dlg:close", (events: JQueryEventObject): void => {
      const $element = $(events.currentTarget);

      if ($element.dialog("instance")) {
        $element.dialog("destroy");
        $element.remove();
      }
    });
    return isjQuery ? dialogView.dialog : dialogView;
  }

  static showConfirmAdapter(str?: string,
    callback?: (...args: any[]) => any,
    tributton?: boolean,
    optionsText?: any,
    oneButton?: boolean,
    appendTo?: JQuery | HTMLElement,
    buttonsClass?: { [key: string]: any },
    width?: string | number,
    height?: string | number
  ): JQuery {

    optionsText = optionsText || {};
    const buttons = { yes: true, no: false, cancel: false };
    if (tributton) {
      buttons.no = true
    }
    if (!_.isBoolean(oneButton) || !oneButton) {
      buttons.cancel = true;
      optionsText.cancel = (optionsText ? (tributton ? (optionsText.cancel ? optionsText.cancel : i18n.t('common:cancel')) :
        (optionsText.no ? optionsText.no : i18n.t('common:cancel'))) : i18n.t('common:cancel'));
    }
    const dialogOptions: ShowConfirmOptions = {
      "text": str,
      "callback": callback,
      "buttons": buttons,
      "buttonsText": optionsText,
      "appendTo": appendTo,
      "width": width,
      "height": height
    }

    if (buttonsClass) {
      dialogOptions.buttonsClass = buttonsClass;
    }

    return CWMSGS.showConfirm(dialogOptions, true) as JQuery;
  }

  static showConfirmAdapterDialogView(str?: string,
    callback?: (...args: any[]) => any,
    tributton?: boolean,
    optionsText?: any,
    oneButton?: boolean,
    appendTo?: JQuery | HTMLElement,
    buttonsClass?: { [key: string]: any },
    width?: string | number,
    height?: string | number
  ): CWDialogPopupView {

    optionsText = optionsText || {};
    const buttons = { yes: true, no: false, cancel: false };
    if (tributton) {
      buttons.no = true
    }
    if (!_.isBoolean(oneButton) || !oneButton) {
      buttons.cancel = true;
      optionsText.cancel = (optionsText ? (tributton ? (optionsText.cancel ? optionsText.cancel : i18n.t('common:cancel')) :
        (optionsText.no ? optionsText.no : i18n.t('common:cancel'))) : i18n.t('common:cancel'));
    }
    const dialogOptions: ShowConfirmOptions = {
      "text": str,
      "callback": callback,
      "buttons": buttons,
      "buttonsText": optionsText,
      "appendTo": appendTo,
      "width": width,
      "height": height
    }

    if (buttonsClass) {
      dialogOptions.buttonsClass = buttonsClass;
    }

    return CWMSGS.showConfirm(dialogOptions, false) as CWDialogPopupView;
  }

  /**
   * Show a message to the user requiring confirmation
   *
   * @param {ShowConfirmOptions} options							
   * 
   */
  static showConfirm(options: ShowConfirmOptions, isjQuery?: boolean): JQuery | CWDialogPopupView {
    let dialogView: CWDialogPopupView = null;
    const { buttons, buttonsText, buttonsClass, appendTo, callback, text, width, height } = options;
    const viewData: any = {
      modal: true
    }
    if (!CWSTR.isBlank(appendTo)) {
      viewData.appendTo = appendTo;
    }
    const dialogOptions: any = {
      "viewData": viewData,
      "popupType": CWDialogPopupType.CONFIRMATION,
      "textContent": text,
      "title": i18n.t('common:msgs_confirm_title'),
      "width": !CWSTR.isBlank(width) ? width : "auto",
      "height": !CWSTR.isBlank(height) ? height : "auto",
      "closeText": i18n.t('common:close'),
      "close": (): void => {
        if ($(dialogView.dialog).dialog("instance")) {
          $(dialogView.dialog).dialog("destroy");
        }
        $(dialogView.dialog).remove();
        //on désactive les tooltips pendants et on l'active donc il doit fonctionner à nouveau
        _.each($(document).find("[aria-describedby]"), (element): void => {
          if (element && $(element).tooltip("instance")) {
            $(element).tooltip("disable");
            $(element).tooltip("enable");
          }
        });
      }
    };
    const dialogButtons: any = [{
      text: (buttonsText ? (buttonsText.yes ? buttonsText.yes : i18n.t('common:yes')) : i18n.t('common:yes')),
      btnClass: (buttonsClass ? (buttonsClass.yes ? "btn " + buttonsClass.yes : "btn btn-primary") : "btn btn-primary"),
      click: (): void => {
        $(dialogView.dialog).dialog("close");
        callback("Y");
      }
    }];
    dialogView = new CWDialogPopupView(dialogOptions);
    if (buttons.no) {
      dialogButtons.push({
        text: (buttonsText ? (buttonsText.no ? buttonsText.no : i18n.t('common:no')) : i18n.t('common:no')),
        btnClass: (buttonsClass ? (buttonsClass.no ? "btn " + buttonsClass.no : "btn btn-primary") : "btn btn-secondary"),
        click: (): void => {
          $(dialogView.dialog).dialog("close");
          callback("N");
        }
      });
    }
    if (buttons.cancel) {
      dialogButtons.push({
        autofocus: true,
        text: (buttonsText ? (buttonsText.cancel ? buttonsText.cancel : i18n.t('common:cancel')) : i18n.t('common:cancel')),
        btnClass: (buttonsClass ? (buttonsClass.cancel ? "btn " + buttonsClass.cancel : "btn btn-primary") : "btn btn-secondary"),
        click: (): void => {
          $(dialogView.dialog).dialog("close");
          callback("C");
        }
      });
    }
    dialogView.setButtons(dialogButtons);
    dialogView.open();
    dialogView.model.on("dlg:close", (events: JQueryEventObject): void => {
      const $element = $(events.currentTarget);

      if ($element.dialog("instance")) {
        $element.dialog("destroy");
        $element.remove();
      }
    });
    return isjQuery ? dialogView.dialog : dialogView;
  }

  /**
   * Show a message of notification
   *
   */
  static showNotification(notification: { [key: string]: any }, callback?: (action: string) => any, options?: { [key: string]: any }, appendTo?: JQuery): JQuery {
    let dialogView: CWDialogPopupView = null;
    let title = "";
    let image = "";
    let dialogClass = "ui-state-highlight";
    const id = _.uniqueId("dialogShowNotification");
    let $div: any = null;
    let $p: any = null;
    let check: any = null;
    let noShowAgain: JQuery = null;
    const noShowAgainButton: boolean = false || notification.noShowAgain;
    const noShowAgainId: string = "" || notification.noShowAgainId;
    const btn: any = [];
    let dialogOptions: any = {};
    const callbackAction = (action: string): void => {
      // manage notification buttons
      switch (action) {
        case "BREAK": // ABORT
          break;
        case "ACTION": // CONTINUE
          if (callback) {
            callback(action);
          }
          break;
        default:
          if (check && noShowAgain && noShowAgain.find("input").prop("checked")) {
            const model = new DesactivateMessageModel();
            model.famille = notification.famille;
            model.numero = notification.numero;

            model.setHabContext(new CWHabilitationContext({
              onglet: JSON.parse(options.headers["gfi-context"]).onglet,
              foncCour: JSON.parse(options.headers["gfi-context"]).fonc_cour, // eslint-disable-line @typescript-eslint/naming-convention
              natGest: ""
            }));
            model.save(null, {
              success: () => {
                if (callback) {
                  callback(action);
                }
              },
              error: () => {
                // Do nothing
              }
            });

          } else {
            if (callback) {
              callback(action);
            }
          }
      }
    };

    // set notification icon
    switch (notification.icone) {
      case "!":
        image = "<span class='ui-icon ui-icon-alert' style='float: left; margin: 0 7px 20px 0;'></span>";
        break;
      case "?":
        image = "<span class='ui-icon ui-icon-info' style='float: left; margin: 0 7px 20px 0;'></span>";
        break;
      case "X":
        image = "<span class='ui-icon ui-icon-circle-close' style='float: left; margin: 0 7px 20px 0;'></span>";
        break;
      default:
    }
    // set notification title
    switch (notification.titre) {
      case "i18n_error":
        title = i18n.t('common:error');
        dialogClass = "ui-state-error";
        break;
      case "i18n_alert":
        title = i18n.t('common:alert');
        dialogClass = "ui-state-error";
        break;
      case "i18n_information":
        title = i18n.t('common:information');
        dialogClass = "ui-state-highlight";
        break;
      case "i18n_confirmation":
        title = i18n.t('common:confirmation');
        dialogClass = "ui-state-showConfirm";
        break;
      case "i18n_question":
        title = i18n.t('common:question');
        dialogClass = "ui-state-highlight";
        break;
      default:
        title = notification.titre;
        dialogClass = "ui-state-message";
        break;
    }
    $div = $("<div>").attr("id", id);
    $div.append(image);
    $p = $("<p>").addClass("phx-msgs-body").html(notification.message);
    $div.append($p);
    check = CWMSGS.confirmMessage(notification, options);
    if (check || noShowAgainButton === true) {
      noShowAgain = $("<div class='custom-control custom-checkbox'><input id='neplusaff' type='checkbox' name='neplusaff' class='custom-control-input'></input><label for='neplusaff' class='custom-control-label'>" + i18n.t('common:neplusaff') + "</label></div");
      noShowAgain.css({
        "margin-top": "20px",
        "margin-bottom": "-20px",
        //"margin-left": "calc(50% - 90px)"
      });
      $div.append(noShowAgain);
    }
    let btnClass = 'btn btn-primary btn-withIcon';
    //let icon = UTILS.getSVGIcon("croix", "", 16, null);
    _.each(notification.boutons, (button: { [key: string]: any }) => {
      let text = "";
      switch (button.libelle) {
        case "i18n_close":
          text = i18n.t('common:close');
          break;
        case "i18n_ok":
          text = i18n.t('common:ok');
          //icon = UTILS.getSVGIcon("valider", "", 16, null);
          break;
        case "i18n_yes":
          text = i18n.t('common:yes');
          //icon = UTILS.getSVGIcon("valider", "", 16, null);
          break;
        case "i18n_no":
          text = i18n.t('common:no');
          btnClass = 'btn btn-secondary btn-withIcon';
          break;
        case "i18n_continue":
          text = i18n.t('common:continu');
          break;
        case "i18n_abort":
          text = i18n.t('common:abort');
          btnClass = 'btn btn-secondary btn-withIcon';
          break;
        case "i18n_fermer":
          text = "Fermer";
          //icon = UTILS.getSVGIcon("croix", "", 16, null);
          break;
        case "i18n_annuler":
          text = "Annuler";
          btnClass = 'btn btn-secondary btn-withIcon';
          break;
        case "i18n_appliquer":
          text = "Appliquer";
          break;
        case "i18n_abandon":
          text = i18n.t('common:abandon');
          btnClass = 'btn btn-secondary';
          break;
        default:
          text = button.libelle;
          break;
      }
      btn.push({
        text: text,
        btnClass: btnClass,
        //icon: icon,
        click: (): void => {
          $(dialogView.dialog).dialog("close");
          callbackAction(button.action);
        }
      });
    }, this);

    // Set autofoucs to the last button, it usually is the cancel button.
    (_.last(btn) as any).autofocus = true;
    const viewData: any = {
      modal: true
    }
    if (!CWSTR.isBlank(appendTo)) {
      viewData.appendTo = appendTo;
    }
    dialogOptions = {
      viewData: viewData,
      popupType: CWDialogPopupType.INFORMATION,
      title: title,
      textContent: $div.html(),
      dialogClass: dialogClass,
      width: 500,
      maxHeight: "80%",
      buttons: btn,
      closeText: i18n.t('common:close'),
      create: (): void => {
        const $parent = dialogView.$el.parent();
        $parent.removeClass("ui-corner-all");
        $parent.find(".ui-dialog-titlebar").removeClass("ui-corner-all");
      },
      close: (event: JQueryEventObject): void => {
        if (noShowAgainButton === true) {
          SESSION.set(noShowAgainId, $(event.target).find("[name=neplusaff]").is(":checked"));
        }
        if ($(dialogView.dialog).dialog("instance")) {
          $(dialogView.dialog).dialog("destroy");
        }
        $(dialogView.dialog).remove();
        //on désactive les tooltips pendants et on l'active donc il doit fonctionner à nouveau
        _.each($(document).find("[aria-describedby]"), (element): void => {
          if (element && $(element).tooltip("instance")) {
            $(element).tooltip("disable");
            $(element).tooltip("enable");
          }
        });
      },
      open: (): void => {
        dialogView.$el.parent().find(".ui-dialog-buttonset button").focus();
      }
    };
    dialogView = new CWDialogPopupView(dialogOptions);
    dialogView.setHeight("auto");
    dialogView.setWidth("500");
    dialogView.open();
    dialogView.model.on("dlg:close", (events: JQueryEventObject): void => {
      const $element = $(events.currentTarget);

      if ($element.dialog("instance")) {
        $element.dialog("destroy");
        $element.remove();
      }
    });
    return dialogView.dialog;
  }

  /**
   * Return a jQuery Object with the content of the message and the diffrents actions
   *
   */
  static getAlertHtml(notification: { [key: string]: any }, callback?: (action: string) => any, options?: { [key: string]: any }): JQuery {
    let $div: any = null;
    let image = "";
    let $p: any = null;
    let check: any = null;
    const id = _.uniqueId("AlertNotification");
    let noShowAgain: JQuery = null;
    const noShowAgainButton: boolean = false || notification.noShowAgain;
    let btnClass = 'btn btn-primary btn-withIcon';
    const buttons = $("<div>").addClass("cwDialog-buttons");
    const callbackAction = (action: string): void => {
      // manage notification buttons
      switch (action) {
        case "BREAK": // ABORT
          break;
        case "ACTION": // CONTINUE
          if (callback) {
            callback(action);
          }
          break;
        default:
          if (check && noShowAgain && noShowAgain.find("input").prop("checked")) {
            const model = new DesactivateMessageModel();
            model.famille = notification.famille;
            model.numero = notification.numero;

            model.setHabContext(new CWHabilitationContext({
              onglet: JSON.parse(options.headers["gfi-context"]).onglet,
              foncCour: JSON.parse(options.headers["gfi-context"]).fonc_cour, // eslint-disable-line @typescript-eslint/naming-convention
              natGest: ""
            }));
            model.save(null, {
              success: () => {
                if (callback) {
                  callback(action);
                }
              },
              error: () => {
                // Do nothing
              }
            });

          } else {
            if (callback) {
              callback(action);
            }
          }
      }
    };

    // set notification icon
    switch (notification.icone) {
      case "!":
        image = "<span class='ui-icon ui-icon-alert' style='float: left; margin: 0 7px 20px 0;'></span>";
        break;
      case "?":
        image = "<span class='ui-icon ui-icon-info' style='float: left; margin: 0 7px 20px 0;'></span>";
        break;
      case "X":
        image = "<span class='ui-icon ui-icon-circle-close' style='float: left; margin: 0 7px 20px 0;'></span>";
        break;
      default:
    }
    $div = $("<div>").attr("id", id);
    $div.append(image);
    $p = $("<p>").addClass("phx-msgs-body").html(notification.message);
    $div.append($p);
    check = CWMSGS.confirmMessage(notification, options);
    if (check || noShowAgainButton === true) {
      noShowAgain = $("<div>").addClass('custom-control custom-checkbox')
        .append($("<input>")
          .attr({
            id: 'neplusaff',
            type: 'checkbox',
            name: 'neplusaff',
            class: 'custom-control-input'
          })
        ).append($('<label>')
          .attr({
            for: 'neplusaff',
            class: 'custom-control-label'
          }).html("i18n.t('common:neplusaff')")
        ).css({
          "margin-top": "20px",
          "margin-bottom": "-20px",
        });
      $div.append(noShowAgain);
    }
    _.each(notification.boutons, (button: { [key: string]: any }) => {
      let text = "";
      const btn = $("<button type='button'>");

      switch (button.libelle) {
        case "i18n_close":
          text = i18n.t('common:close');
          break;
        case "i18n_ok":
          text = i18n.t('common:ok');
          break;
        case "i18n_yes":
          text = i18n.t('common:yes');
          break;
        case "i18n_no":
          text = i18n.t('common:no');
          btnClass = 'btn btn-secondary btn-withIcon';
          break;
        case "i18n_continue":
          text = i18n.t('common:continu');
          break;
        case "i18n_abort":
          text = i18n.t('common:abort');
          btnClass = 'btn btn-secondary btn-withIcon';
          break;
        case "i18n_fermer":
          text = "Fermer";
          break;
        case "i18n_annuler":
          text = "Annuler";
          btnClass = 'btn btn-secondary btn-withIcon';
          break;
        case "i18n_appliquer":
          text = "Appliquer";
          break;
        case "i18n_abandon":
          text = i18n.t('common:abandon');
          btnClass = 'btn btn-secondary';
          break;
        default:
          text = button.libelle;
          break;
      }
      btn.text(text);
      btn.addClass(btnClass).attr('value', '');
      btn.click((): void => {
        callbackAction(button.action);
      });
      buttons.append(btn);
    }, this);
    $div.append(buttons);
    return $div;
  }
}
