import { FORMS } from 'utils/forms.js';
import { LOG } from 'utils/log.js';
import { STR } from 'utils/str.js';
import { TreeNodeModel } from './treeNode.model.js';
import TPL_common_tree_node from './cwTreeNode.tpl.html';
import { UTILS } from 'utils/utils.js';
import { objs } from 'src/objectsRepository';

export var CWTreeNodeView = Backbone.View.extend({

  /**
   * Classes of the DOM element for the view
   */
  className: "cw-treenode cw-treenode-expandable",

  /**
   * Event launched when click on Expand/Collapse button.
   */
  /**
   * Event launched click on the Node label.
   */
  /**
   * Event launched when the Node label is clicked twice.
   */
  /**
   * Event when click the Refresh Node Button.
   */
  events: {
    "click .cw-treenode-icon": "_changeTreeState",
    "click .cw-treenode-label": "itemSelected",
    "dblclick .cw-treenode-label": "_itemChoosen",
    "click .cw-treenode-refresh": "_refreshClickedSubtree",
    "click .cw-treenode-prev": "_refreshClickedSubtree",
    "click .cw-treenode-next": "_refreshClickedSubtree",
    "change .cw-treenode-check-container input[type='checkbox']": "_changeCheckState",
  },

  /**
   * View of nodes of the Tree Component
   */
  initialize: function(a_params) {
    this.template = TPL_common_tree_node;
    /**
     * Node Model of the current View.
     */
    this.model = new TreeNodeModel();
    /**
     * Collection of Sons Node Views.
     */
    this.sonsColl = [];
    /**
     * View of the parent node.
     */
    this.parent = undefined;
    /**
     * Function that renders a tooltip next to a current node view.
     */
    this.tooltipRenderer = undefined;
    /**
     * Function that renders the tooltip over the current node view.
     */
    this.overLabelTooltipRenderer = undefined;
    /**
     * True if want to show the selected items.
     */
    this.showSelected = undefined;
    /**
     * Sorting function for the sub tree.
     */
    this.sortTree = undefined;
    /**
     * True if you want to set the selected items to read-only.
     */
    this.readOnly = false;

    var params = (a_params || {});
    if (params.parent) {
      this.parent = params.parent;
    }
    if (params.root) {
      this.model.root = params.root;
      if (this.model.root.rootModel) {
        this.model.node = this.model.root.rootModel;
      }
      if (params.firstLevelNode && params.firstLevelNode === true &&
        this.model.root.firstLevelModels) {
        this.model.firstLevelModels = this.model.root.firstLevelModels;
        this.model.firstLevelNode = true;
      }
      if (this.model.root.checkClass) {
        this.checkClass = this.model.root.checkClass;
      }
      if (this.model.root.selectableNode) {
        this.model.selectableNode = this.model.root.selectableNode;
      }
      if (!STR.isBlank(this.model.root.get("label"))) {
        this.model.set("label", this.model.root.get("label"));
      } else if (this.model.node) {
        if (this.model.root.renderer) {
          this.model.set("label", this.model.root.renderer.call(this, this.model.node));
        } else {
          this.model.set("label", this.model.node.get("libelle"));
        }

        if (this.model.root.tooltipRenderer) {
          this.tooltipRenderer = this.model.root.tooltipRenderer;
        }

        if (this.model.root.overLabelTooltipRenderer) {
          this.overLabelTooltipRenderer = this.model.root.overLabelTooltipRenderer;
        }
      }
    }

    // information associated with current node
    if (params.node) {
      this.model.node = params.node;
      if (this.model.root.renderer) {
        this.model.set("label", this.model.root.renderer.call(this, this.model.node));
      } else {
        this.model.set("label", this.model.node.get("libelle"));
      }
    }

    if (params.showSelected) {
      this.showSelected = params.showSelected;
    }
    this.selectableNode = true;
    if (!STR.isBlank(params.selectableNode)) {
      this.selectableNode = params.selectableNode;
    }

    //EVO 186: Multiselection mode, to show the checkboxes
    this.multiselect = false;
    if (!STR.isBlank(params.multiselect)) {
      this.multiselect = params.multiselect;
    }

    if (params && params.isCheckedCallback) {
      this.isCheckedCallback = params.isCheckedCallback;
      this._isChecked = params.isCheckedCallback;
    }

    this.hideCheck = false;
    if (!STR.isBlank(params.hideCheck)) {
      this.hideCheck = params.hideCheck;
    }
    if (!STR.isBlank(params.readOnly)) {
      this.readOnly = params.readOnly;
    }

    if (params && params.hieractivitidad) {
      this.hieractivitidad = params.hieractivitidad;
    }

    if (this.multiselect && (STR.isBlank(this.parent) || STR.isBlank(this.parent.model) || STR.isBlank(this.parent.model.checkedColl))) {
      if (!STR.isBlank(this.parent) && !STR.isBlank(this.parent.model) &&
        !STR.isBlank(this.parent.model.root) && !STR.isBlank(this.parent.model.root.checkedColl)) {
        this.parent.model.checkedColl = this.parent.model.root.checkedColl;
      } else {
        throw Error("You must use a model.checkedColl in multiselection mode");
      }
    }

    this.model.set("level", params.level);
    this.listenTo(this.model, "updateTreeNode", this._updateTreeNode);
    this.listenTo(this.model, "updateTreeNodeRecursive", this._updateTreeNodeRecursive);
    this.listenTo(this.model, "updateParentTreeNode", this._updateParentTreeNode);
    this.listenTo(this.model, "refresh", this._refreshSubtreeByTrigger);

    this._addListenersToNode();

    this.model.coll = this.model.root.coll.clone();
    if (!STR.isBlank(this.model.root.coll.habContext)) {
      this.model.coll.setHabContext(this.model.root.coll.getHabContext());
    } else {
      this.model.coll.usecase = this.model.root.coll.usecase;
      this.model.coll.habilitationV = this.model.root.coll.habilitationV;
      this.model.coll.habilitationG = this.model.root.coll.habilitationG;
    }

    this.model.coll.structid = this.model.root.coll.structid;
    this.model.coll.domaine = this.model.root.coll.domaine;
    this.model.root.buildUrlColl.call(this, this.model.get("level"), this.model.node, this.model.coll);

    this.id = this.cid;
  },

  /**
   * Repaint the displayed label and the tooltip if is defined.
   */
  repaintLabel: function(renderer) {
    if (this.$el.find(".cw-treenode-label").length > 0) {
      var label = renderer.call(this, this.model.node);
      this.model.set("label", label);
      $(this.$el.find(".cw-treenode-label")[0]).html(label);
      //show tooltips
      if (this.tooltipRenderer) {
        this.tooltipRenderer(this.model.node, this);
      }
      if (this.overLabelTooltipRenderer) {
        this.overLabelTooltipRenderer(this.model.node, this);
      }
      if (this.checkClass) {
        var iconClass = this.checkClass(this.model.node, this);
        if (!STR.isBlank(iconClass)) {
          this.$el.addClass(iconClass);
        }
      }
      for (var i = 0; i < this.sonsColl.length; i++) {
        this.sonsColl[i].repaintLabel(renderer);
      }
      this._sortSubTree();
    }
  },

  /**
   * Render Main View Function
   * 	Sets the drag and drop option if enabled.
   *  Renders the tooltips.
   */
  render: function(callback) {
    var self = this;
    var json = { cid: this.cid, libelle: this.model.root.renderer.call(this, this.model.node) };
    var l_posNode = null;
    var l_posNodeContainer = null;
    var l_posNodeLabel = null;
    var l_posWrap = null;

    this.$el.attr("id", this.id);
    this.$el.addClass("cw-level-" + this.model.get("level"));

    this.$el.html(this.template(json));
    //Add data for future acces
    l_posWrap = this.$el.find(".cw-treenode-wrap");
    l_posWrap.data("view", this);
    l_posNode = this.$el.find(".cw-treenode-check");
    l_posNodeContainer = this.$el.find(".cw-treenode-check-container");
    l_posNodeLabel = this.$el.find(".cw-treenode-label");
    if (this.model.root.get("draggable") === true) {
      l_posWrap.draggable({
        revert: true,
        delay: 150,
        //make a copy of the content and adds to a floating on the cursor
        helper: "clone",
        cursorAt: { top: -15, left: -15 },
        start: this._calculateDrops,
        stop: this._resetDrops
      });

      l_posWrap.droppable({
        //Don't propagate the drop event
        greedy: true,
        //Set the drag interact on cursor
        tolerance: "pointer",
        //Class when hover accepted element
        hoverClass: "ui-drop-hover",
        //Function when drop an acceptable element
        drop: function(event, ui) {
          var targetView = $(event.target).data("view");
          var origView = ui.draggable.data("view");
          LOG.debug("DROPPED SUCCES !!! From: " + origView.model.get("label") + "(" + origView.model.cid + ") To:" + targetView.model.get("label") + "(" + targetView.model.cid + ")");
          origView.callback = self.model.root.dragAndDropCallback;
          origView.targetView = targetView;
          //self.model.root.dragAndDropCallback(origView,targetView);
        }

        //			over : function(event,ui){
        //				var view = $(event.target).data( "view" );
        //
        //				if (view.$el.hasClass("cw-treenode-expandable")){
        //					view._expand(event.target);
        //				}
        //			}
      });
    }

    //Decide if this node is selectable or it isn't
    //If data returned for selectionnable is false or null, don't let select the node
    //If the element has been marked  as selectableNode=false (for familles structures etc) don't select the element
    //If value of Rechrexp hierarchie niveau is false 
    if (!this.checksRechrexp(this.model.node) || this.checkSelectionnableForScreen() || this.selectableNode === false) {
      //adds class to know that this node is not selectionnable
      this.$el.find(".cw-treenode-label").addClass("cw-node-not-selectionnable").addClass("ui-cw-ihm-non-selectionnable");
    }

    //add id information to nodeview in order to find it easily  by html
    if (!STR.isBlank(this.model.node.get("id"))) {
      this.$el.attr("tree-node-Id", this.model.node.get("id"));
    }
    this._showHideIcon();

    //show tooltips
    if (this.tooltipRenderer) {
      this.tooltipRenderer(this.model.node, this);
    }
    //show tooltips
    if (this.overLabelTooltipRenderer) {
      this.overLabelTooltipRenderer(this.model.node, this, callback);
    }
    if (this.checkClass) {
      var iconClass = this.checkClass(this.model.node, this);
      if (!STR.isBlank(iconClass)) {
        this.$el.addClass(iconClass);
      }
    }

    l_posWrap.hover(function(e) {
      self._showRefreshButton(e);
    });

    //EVO 186: Multiselection mode, show the checkboxes
    if (this.multiselect) {
      var lf_callback = function(result) {
        if (result) {
          l_posNode.prop('checked', true);
          //Check all parents
          self._checkAllParents(self);
        } else {
          l_posNode.prop('checked', false);
        }
      };

      l_posNodeContainer.show();
      l_posNodeLabel.hide();
      this._isChecked(this.model, lf_callback);
      //if (!STR.isBlank(this.parent.readOnly)) {
      if (!STR.isBlank(this.readOnly)) {
        //FORMS.setFieldReadonly(l_posNode, this.parent.readOnly, true);
        FORMS.setFieldReadonly(l_posNode, this.readOnly, true);
      }
    }

    //Add class to identify checkbox for this node
    l_posNode.addClass("treenode-check-" + UTILS.escapeJQueryString(String(this.model.node.get("code"))));
    l_posNode.addClass("custom-control-input");
    if (this.model.node.get("feuille") === true && this.hideCheck === true) {
      l_posNodeContainer.hide(); //check manqué
      l_posNodeLabel.show(); //check manqué
      //FORMS.setFieldReadonly(this.$el.find(".cw-treenode-check.treenode-check-" + UTILS.escapeJQueryString(String(this.model.node.get("code")))), true, false);
    }

    if (!this.overLabelTooltipRenderer) {
      if (callback) {
        callback();
      }
    }
    return this;
  },
  /**
   * Set selectionable with hierarchie niveau of Rechrexp 
   */
  checksRechrexp: function(node) {
    var niveau = STR.isBlank(node.get("hiertypeniv")) ? null : node.get("hiertypeniv").niveau;
    var selectionable = true;
    var found = null;

    // we make not selectionable only in explotation
    if (niveau && this.hieractivitidad && this.hieractivitidad.habContext.get("onglet") !== "activite") {
      found = this.hieractivitidad.find(function(item) {
        return item.get('niveau') === niveau;
      });
    }
    if (found && found.get("saisieexp") === false) {
      selectionable = false;
    }
    return selectionable;
  },

  /**
   * Cheks list of screens not apply selectionnable value
   */
  checkSelectionnableForScreen: function() {
    var screenList = ["defjourex", "populations"]; // list of screens filter in not applied
    var onglet = objs.appRt.workflow.get("usecase");

    if (screenList.indexOf(onglet) === -1) {
      // retun true if non selectionnable
      return (
        (!STR.isBlank(this.model.node.get("selectionnable")) && this.model.node.get("selectionnable") === false) ||
        _.isNull(this.model.node.get("selectionnable")));

    } else {
      // when chemin selector is in screen filter do not apply non selectionable
      return false;
    }
  },

  /**
   * Show/Hide the expand node button
   */
  _showHideIcon: function() {
    if (!STR.isBlank(this.model) && !STR.isBlank(this.model.node)) {
      //Show or not the expand icon
      var feuille = this.model.node.get("feuille");
      if (feuille === false || this.model.get("hasChild") > 0) {
        this.$el.find(".cw-treenode-icon").first().show();
        this.$el.find(".cw-treenode-leaf-icon").first().hide();
      } else {
        this.$el.find(".cw-treenode-icon").first().hide();
        this.$el.find(".cw-treenode-leaf-icon").first().show();
      }
      //Show Marqueur icon
      var avecmarq = this.model.node.get("avecmarq") || false;
      var $containMarq = this.$el.find(".containMarq");
      if (avecmarq === false) {
        $containMarq.first().hide();
      } else if ($containMarq.length > 0) {
        $containMarq.first().show();
      } else {
        var containMarqIcon = $("<span class='containMarq'></span>");
        this.$el.append(containMarqIcon);
      }
    }
  },

  _showArrows: function() {
    if (!STR.isBlank(this.model) && !STR.isBlank(this.model.node) && !STR.isBlank(this.model.root) && String(this.model.node.get("id")) === String(this.model.root.rootModel.get("id"))) {
      this._manageArrowsVisibility();
    }
  },

  _manageArrowsVisibility: function() {
    if (this.model.coll.paginated && (this.model.coll.totalRecords > this.model.coll.pagination.size)) {
      $(".cw-treenode-prev", this.el).first().css("display", "inline-block");
      $(".cw-treenode-next", this.el).first().css("display", "inline-block");

      if (this.model.coll.pagination.startIndex < this.model.coll.pagination.size) {
        $("button.cw-treenode-prev", this.el).first().addClass("ui-button-disabled ui-state-disabled").prop("disabled", true);
      } else {
        $("button.cw-treenode-prev", this.el).first().removeClass("ui-button-disabled ui-state-disabled").prop("disabled", false);

      }

      if ((this.model.coll.totalRecords - this.model.coll.pagination.startIndex) < this.model.coll.pagination.size) {
        $(".cw-treenode-next", this.el).first().addClass("ui-button-disabled ui-state-disabled").prop("disabled", true);
      } else {
        $(".cw-treenode-next", this.el).first().removeClass("ui-button-disabled ui-state-disabled").prop("disabled", false);
      }
    }
  },

  /**
   * Manage the Refresh Button Display.
   */
  _showRefreshButton: function(event) {
    if (event.type === "mouseenter") { // When the mouse enter on the title block
      $(".cw-treenode-refresh", this.el).first().css("visibility", "visible");
    } else {
      $(".cw-treenode-refresh", this.el).first().css("visibility", "hidden");
    }
  },

  /**
   * Function called when start the drag.
   */
  _calculateDrops: function(event) {
    LOG.debug("dragged");
    var view = $(event.target).data("view");
    var model = view.model;

    //Disable own
    $(event.target).droppable("disable");
    //Disable the childs
    if (model.get("hasChild") > 0 && model.get("expanded") === true) {
      //disable droppable from childrens
      view.$el.children(".cw-treenode").find(".cw-treenode-wrap").droppable("disable");
    }
    //disable the direct father
    view.$el.parent().children(".cw-treenode-wrap").droppable("disable");

  },

  /**
   * Function called when end the drag.
   */
  _resetDrops: function(event) {
    LOG.debug("End Drag");
    var targetView = $(event.target).data("view");

    //Enable all droppables
    if (targetView) {
      if (targetView.$el && targetView.$el.parent()) {
        targetView.$el.parent().find(".cw-treenode-wrap.ui-droppable-disabled").droppable("enable");
      }
      if (targetView.callback) {
        targetView.callback(targetView, targetView.targetView);
        targetView.callback = undefined;
      }
    }
  },

  /**
   * Expand/Collapses the Tree Node.
   */
  _changeTreeState: function(event, callback) {
    if (this.$el.hasClass("cw-treenode-expandable")) {
      this._expand(event.target, callback);
    } else {
      this._collapse(event.target);
    }
    return false;
  },

  /**
   * Manages the node selection.
   */
  itemSelected: function(event) {
    LOG.debug("node selected");
    if (!this.$el.find(".cw-treenode-label").first().hasClass("cw-node-not-selectionnable")) { //When this node can be selected
      if (this.showSelected) {
        this.$el.find("span.cw-treenode-label").first().addClass("ui-state-active");
        this.$el.find("span.cw-treenode-label").first().addClass("cw-treeNode-lastNodeSelected");
      }
      if (event && event.ctrlKey) {
        this.model.root.trigger("nodeSelectedWithCtrlKey", this.model);
      } else {
        this.model.root.trigger("nodeSelected", this.model);
      }
    }
    return false;
  },

  /**
   * Manages the node selection.
   */
  _itemChoosen: function() {
    LOG.debug("node choosen");
    if (!this.$el.find(".cw-treenode-label").first().hasClass("cw-node-not-selectionnable")) {
      this.model.root.trigger("nodeChoosen", this.model);
    }
    return false;
  },

  /**
   * Clean the View
   */
  empty: function() {
    // Clean memory
    for (var i = 0; i < this.sonsColl.length; i++) {
      this.sonsColl[i].remove();
    }
    this.sonsColl = [];
    // Clean DOM
    this.$el.empty();
  },

  /**
   * Remove the View.
   */
  remove: function() {
    // COMPLETELY UNBIND THE VIEW
    if (this.$el) {
      if (this.$el.off) {
        this.undelegateEvents();
      }
      this.$el.removeData().unbind();
    }
    delete this.model;
    this.clearSonsArray();
    Backbone.View.prototype.remove.call(this); // Remove view from DOM
    delete this.$el; // Delete the jQuery wrapped object variable
    delete this.el; // Delete the variable reference to this node
  },

  clearSonsArray: function() {
    // Clean memory
    for (var i = 0; i < this.sonsColl.length; i++) {
      if (this.sonsColl[i].remove) {
        this.sonsColl[i].remove();
      }
    }

    this.sonsColl = [];
  },

  //EVO 186: Multiselection mode, add/remove checked collections ----->
  /**
   * Manages the checkboxes selection.
   */
  _changeCheckState: function(event) {
    var self = this;
    var callback = function(result) {
      self.model.isChecked = !result;
      if (self.model.isChecked && self.model.node && !STR.isBlank(self.model.node.get("code"))) {
        self.parent.model.checkedColl.add(self.model);
        if (STR.isBlank(self.parent.model.get("value"))) {
          // if no rows are selected and a row is checked for multiselection, we select the current row
          self.model.trigger("row:click", self.model);
        }
        //Check all parents
        self._checkAllParents(self);
      } else {
        self.parent.model.checkedColl.remove(self.model);
      }
      if (STR.isBlank(self.checkSons) || self.checkSons) {
        self._checkAllSons(self, self.model.isChecked);
      }
    }
    this._isChecked(this.model, callback);

    event.stopPropagation();
  },

  _checkAllParents: function(view) {
    var parent = null;
    if (!STR.isBlank(view.parent)) {
      parent = view.parent;
    }
    if (!STR.isBlank(parent) && !STR.isBlank(parent.model) && !STR.isBlank(parent.model.node)) {
      var callback = function(result) {
        if (!result) { //True is an a node with sons and false is a node with sons
          parent.checkSons = false; //Prevent to check sons
          if (!STR.isBlank(parent.model.node.get("code"))) {
            //parent.model.node.get("code") peut être un integer ou un string-> pour tous, j'utilise String( x ) et corrige l'erreur lorsque le méthode "replace" dans UTILS.escape....
            parent.$el.find(".treenode-check-" + UTILS.escapeJQueryString(String(parent.model.node.get("code")))).click(); //Click in the checkbox to trigger the events
            //parent.model.trigger("row:click", parent.model);
          }
          parent.checkSons = true;
        }
      }
      this._isChecked(parent.model, callback);
    }
  },

  /**
   * Checks if the activity model is added to the collection.
   */
  _isChecked: function(model, callback) {
    var item = this.parent.model.checkedColl.find(function(a_item) {
      if (!STR.isBlank(a_item) && !STR.isBlank(a_item.node)) {
        if (model.node.get("code") === a_item.node.get("code")) {
          return true;
        }
        return false;
      } else {
        return a_item.cid === model.cid
      }
    });
    if (!STR.isBlank(item)) {
      //Check if the model is inserted, and remplace it for the real model
      if (!STR.isBlank(item.flagSetted) && item.flagSetted === true) {
        this.parent.model.checkedColl.remove(item);
        this.parent.model.checkedColl.add(model);
      }
      if (callback) {
        return callback(true);
      }
      return true;
    }
    if (callback) {
      return callback(false);
    }
    return false;
  },

  _getParentNodesAsString: function(model) {
    var nodesString = "";
    if (!STR.isBlank(model) && !STR.isBlank(model.node)) {
      var parents = model.node.get("parents");
      for (var i = 1; i < parents.length; i++) {
        nodesString += parents[i].code + "/";
      }
      nodesString = nodesString.slice(0, -1); //Remove last "/"
    }
    return nodesString;
  },
  //_makeDdummyParents : function (model, stringDummy) {}, // get nothing
  //_addRealModelToCollection : function (model, oldModel) {},

  /**
   * Checks all sons of one activity famlies.
   */
  _checkAllSons: function(context, check) {
    if (context.model.node.get("feuille") === false && context.sonsColl.length === 0) { //Node have childrens and don't have expanded
      context._expand(null, function() {
        if (context.sonsColl.length !== 0) { //Have childs!
          context._checkAllSons(context, check);
        }
      });
    }
    _.each(context.sonsColl, function(view) { //Model.node.childrens
      var callback = function(result) {
        if (!result && check) {
          context.parent.model.checkedColl.add(view.model);
        } else if (result && !check) {
          context.parent.model.checkedColl.remove(view.model);
        }
      }
      if (view.hideCheck !== true || (view.hideCheck === true && view.model.node.get("feuille") === false)) {
        context._isChecked(view.model, callback);
        $(view.el).find(".cw-treenode-check").prop('checked', check);
      }
      //Check if the node have childrens
      var feuille = view.model.node.get("feuille");
      if (feuille === false) { //Hab hildrens
        view._expand(null, function() {
          view._checkAllSons(view, check);
        });
      }
    });
  },

  /**
   * collapse all nodes
   */
  _collapseAllNodes: function(a_context) {
    var context = a_context;

    if (STR.isBlank(context)) {
      context = this;
    }
    _.each(context.sonsColl, function(view) { //Model.node.childrens
      var feuille = view.model.node.get("feuille");
      if (feuille === false) { //Hab hildrens
        if (view.sonsColl.length > 0) { //Children has expanded
          context._collapseAllNodes(view);
        }
      }
    });

    if (context.model.node.get("feuille") === false && !STR.isBlank(context.model.node.niveau)) {
      context._collapse();
    }
  },

  /**
   * Expand all nodes
   */
  _expandAllNodes: function(a_context) {
    var context = a_context;

    if (STR.isBlank(context)) {
      context = this;
    }
    if (context.model.node.get("feuille") === false && context.sonsColl.length === 0) { //Node have childrens and don't have expanded
      context._expand(null, function() {
        if (context.sonsColl.length !== 0) { //Have childs!
          context._expandAllNodes(context);
        }
      });
    }
    _.each(context.sonsColl, function(view) { //Model.node.childrens
      var feuille = view.model.node.get("feuille");

      if (feuille === false) { //Hab hildrens
        view._expand(null, function() {
          view._expandAllNodes(view);
        })
      }
    });
  },

  //EVO 186: Multiselection mode, add/remove checked collections <-------

  /**
   * Expand the Node, fetching the children collection of items.
   */
  _expand: function(target, callback, shouldReload) {
    var self = this;
    var fictitiousRoot = false;

    if (!STR.isBlank(this.model.firstLevelNode) && this.model.firstLevelNode === true && this.model.firstLevelModels) {
      fictitiousRoot = true;
    }
    this.$el.removeClass("cw-treenode-expandable").addClass("cw-treenode-collapsable");
    if (fictitiousRoot) { //For fictitiousroot that is not going to be shown, don't show icon
      this.$el.removeClass("cw-treenode-collapsable");
    }
    if (shouldReload === true) {
      if (fictitiousRoot === true) { //When many roots are going to be used
        var l_func_sub1 = function() {
          self._sortSubTree();
          self._showHideIcon();
          self._showArrows();
          self.model.node.childrens = self.model.coll;
          if (callback) {
            callback(self.model.coll, self.sonsColl);
          }
        };

        this.model.coll.reset();
        this.clearSonsArray();
        _.each(this.model.firstLevelModels, function(firstLevelModel) {
          self.model.coll.add(firstLevelModel);
        });
        if (STR.isBlank(this.model.coll.vue) || this.model.coll.vue === 3) {
          this.fillSubTree(null, l_func_sub1);
        } else {
          this.fillSubTreeRest(null, l_func_sub1);
        }
      } else { //When there is only one root
        this.model.coll.reset();
        this.clearSonsArray();
        if (this.model.node.get("typelt") === "A" && !STR.isBlank(this.model.node.get("hierid"))) {
          this.model.coll.hierid = this.model.node.get("hierid");
        }
        this.model.coll.fetch({
          success: function(fresh) {
            if (self.model && self.model.root) {
              self.model.root.trigger("updatedTreeNodeCollection", fresh, self.model.node);
            }
            var l_func_sub2 = function() {
              self._sortSubTree();
              self._showArrows();
              self._showHideIcon();
              self.model.node.childrens = self.model.coll;
              if (callback) {
                callback(self.model.coll, self.sonsColl);
              }
            };
            if (STR.isBlank(self.model.coll.vue) || self.model.coll.vue === 3) {
              self.fillSubTree(null, l_func_sub2);
            } else {
              self.fillSubTreeRest(null, l_func_sub2);
            }
          }
        });
      }
    } else {
      if (STR.isBlank(this.model.get("hasChild"))) {
        if (fictitiousRoot === true) { //When many roots are going to be used
          var l_func_sub3 = function() {
            self._sortSubTree();
            self._showArrows();
            self._showHideIcon();
            if (!STR.isBlank(self.model) && !STR.isBlank(self.model.node)) {
              self.model.node.childrens = self.model.coll;
              if (callback) {
                callback(self.model.coll, self.sonsColl);
              }
            }
          };

          this.model.coll.reset();
          this.clearSonsArray();
          _.each(this.model.firstLevelModels, function(firstLevelModel) {
            self.model.coll.add(firstLevelModel);
          });
          if (STR.isBlank(self.model.coll.vue) || self.model.coll.vue === 3) {
            self.fillSubTree(null, l_func_sub3);
          } else {
            self.fillSubTreeRest(null, l_func_sub3);
          }
        } else { //When there is only one root
          if (this.model.node.get("typelt") === "A" && !STR.isBlank(this.model.node.get("hierid"))) {
            this.model.coll.hierid = this.model.node.get("hierid");
          }
          this.model.coll.reset();
          this.clearSonsArray();
          this.model.coll.fetch({
            success: function(fresh) {
              if (self.model && self.model.root) {
                self.model.root.trigger("updatedTreeNodeCollection", fresh, self.model.node);
              }
              var l_func_sub4 = function() {
                self._sortSubTree();
                self._showArrows();
                self._showHideIcon();
                self.model.node.childrens = fresh;
                if (callback) {
                  callback(fresh, self.sonsColl);
                }
              };

              if (STR.isBlank(self.model) || STR.isBlank(self.model.coll.vue) || self.model.coll.vue === 3) {
                self.fillSubTree(null, l_func_sub4);
              } else {
                self.fillSubTreeRest(null, l_func_sub4);
              }
            }
          });
        }

      } else {
        var sublevel = this.model.get("level") + 1;
        this.$el.find("div.cw-level-" + sublevel).removeClass("cw-treenode-closed");
        this.model.node.childrens = self.model.coll;
        if (callback) {
          callback(self.model.coll, self.sonsColl);
        }
      }
    }
    this.model.set("expanded", true);
    if (!fictitiousRoot) {
      this._showHideIcon();
    }
  },

  /**
   * Collapse the Node.
   */
  _collapse: function() {
    //		$(target).removeClass("ui-icon-circle-minus").addClass("ui-icon-circle-plus");
    this.$el.removeClass("cw-treenode-collapsable").addClass("cw-treenode-expandable");

    var sublevel = this.model.get("level") + 1;
    this.$el.find("div.cw-level-" + sublevel).addClass("cw-treenode-closed");

    this.model.set("expanded", false);
  },

  /**
   * Update the data recursive
   */
  _updateTreeNodeRecursive: function(callback) {
    this._updateTreeNode(callback);
  },

  /**
   * Update the data of current node and his childs.
   */
  _updateTreeNode: function(callback, a_expand) {
    var self = this;
    var expand = a_expand;
    var fictitiousRoot = false;

    if (!_.isBoolean(expand)) {
      expand = true;
    }

    if (!STR.isBlank(this.model.firstLevelNode) && this.model.firstLevelNode === true && this.model.firstLevelModels) {
      fictitiousRoot = true;
    }

    if (fictitiousRoot === true) {
      this.model.root.rootModel.firstLevelColl.fetch({
        success: function(freshColl) {
          self.model.coll.reset();
          _.each(freshColl.models, function(firstLevelModel) {
            if (STR.isBlank(firstLevelModel.get("code"))) { //Hors regroupement
              firstLevelModel.set("code", " ");
            }
            self.model.coll.add(firstLevelModel);
          });
          self.updateSubTree();
          self._sortSubTree();
          self._showArrows();
          if (self.model.get("hasChild") > 0 && expand) {
            self._expand(null);
          }
          if (callback) {
            callback();
          }
        }
      });
    } else {
      this.model.coll.fetch({
        success: function() {
          self.updateSubTree();
          self._sortSubTree();
          self._showArrows();
          if (self.model.get("hasChild") > 0 && expand) {
            self._expand(null);
          }
          if (callback) {
            callback();
          }
        }
      });
    }
  },

  /**
   * Update the data of the parent Node and his childs.
   */
  _updateParentTreeNode: function() {
    if (this.parent) {
      var self = this.parent;
      //this.parent.model.root.buildUrlColl.call(this, this.parent.model.get("level"), this.parent.model.node, this.parent.model.root.coll);
      this.parent.model.coll.fetch({
        success: function() {
          self.updateSubTree();
          self._sortSubTree();
          self._showArrows();
          //If parent is blank, it is because it is the root of the tree so we don't have to
          //expand its parent
          if (!STR.isBlank(self.parent) && self.parent.model.get("hasChild") > 0) {
            self.parent._expand(null);
          }
        }
      });
    }
  },

  /* Listen the Click on Refresh Button.
   * Refresh the data of the clicked node and the subtrees.
   * @instance
   * @private
   * @listens TreeNodeView#"click .cw-treenode-wrap"
   * @param {external:JQuery.Event} event - click event.
   * @returns {Boolean}
   */
  _refreshClickedSubtree: function(event) {
    if (!STR.isBlank(event)) //It is the first time that we refresh the parent to update the current clicked node
    { //Refreshing parent
      var target = event.currentTarget.className.split(" ")[0];
      var self = this;
      var callback = function() {
        if (target === "cw-treenode-refresh") {
          self._refreshTreeNode();
        } else if (target === "cw-treenode-prev") {
          self._prevPagination();
        } else if (target === "cw-treenode-next") {
          self._nextPagination();
        }
        _.each(self.sonsColl, function(node) {
          node._refreshClickedSubtree();
        }, self);
      };
      this._refreshClickedNode(this.model.node.id, callback);
      //this._updateParentTreeNode();
    } else { //Refreshing current clicked node or its sons sons
      this._refreshTreeNode();
      _.each(this.sonsColl, function(node) {
        node._refreshClickedSubtree();
      }, this);

    }

    return false;
  },

  /* Listen Refresh trigger in node.
   * Refresh the data of the selected node and the subtrees.
   */
  _refreshSubtreeByTrigger: function() {
    var self = this;
    var callback = function() {
      self._refreshTreeNode();
      _.each(self.sonsColl, function(node) {
        node._refreshClickedSubtree();
      }, self);
    };
    this._refreshClickedNode(this.model.node.id, callback);
  },

  /**
   * Refresh this Node and its sub tree.
   */
  _refreshTreeNode: function() {
    var self = this;
    if (STR.isBlank(this.model.node.get("feuille")) || (!STR.isBlank(this.model.node.get("feuille")) && this.model.node.get("feuille") !== true)) {
      this.model.coll.fetch({
        success: function() {
          self.refreshSubTree();
          self._sortSubTree();
          self._showArrows();
        }
      });
    }

  },

  _prevPagination: function() {
    var self = this;
    if (STR.isBlank(this.model.node.get("feuille")) || (!STR.isBlank(this.model.node.get("feuille")) && this.model.node.get("feuille") !== true)) {
      if (this.model.coll.paginated) {
        if (this.model.coll.pagination.startIndex >= (this.model.coll.pagination.size + 1)) {
          this.model.coll.pagination.startIndex -= this.model.coll.pagination.size;
        } else {
          this.model.coll.pagination.startIndex = 0;
        }
        this.model.coll.fetch({
          success: function() {
            self.refreshSubTree();
            self._sortSubTree();
            self._showArrows();
          }
        });
      }
    }
  },

  _nextPagination: function() {
    var self = this;
    if (STR.isBlank(this.model.node.get("feuille")) || (!STR.isBlank(this.model.node.get("feuille")) && this.model.node.get("feuille") !== true)) {
      if (this.model.coll.paginated) {
        if ((this.model.coll.totalRecords - this.model.coll.pagination.startIndex) >= this.model.coll.pagination.size) {
          this.model.coll.pagination.startIndex += this.model.coll.pagination.size;
          this.model.coll.fetch({
            success: function() {
              self.refreshSubTree();
              self._sortSubTree();
              self._showArrows();
            }
          });
        }
      }
    }
  },

  /**
   * Refresh data for the Clicked Node.
   */
  _refreshClickedNode: function(idClickedNode, callback) {
    var fictitiousRoot = false;
    var self = this.parent;

    if (!STR.isBlank(this.parent.model.firstLevelNode) && this.parent.model.firstLevelNode === true &&
      this.parent.model.firstLevelModels) {
      fictitiousRoot = true;
    }
    //We fetch parent in order to get updated info about clicked node only if it is not a fictitous node
    //Only if parent is not a fictitious root
    if (this.parent && STR.isBlank(this.parent.model.firstLevelNode) && STR.isBlank(this.parent.model.firstLevelModels)) {
      //this.parent.model.root.buildUrlColl.call(this, this.parent.model.get("level"), this.parent.model.node, this.parent.model.root.coll);
      this.parent.model.coll.fetch({
        success: function() {
          _.each(self.model.coll.models, function(item) {
            var found = false;
            var existingNodeIndex = null;
            if (!STR.isBlank(self.sonsColl)) {
              for (var idx = 0; idx < self.sonsColl.length; idx++) {
                var son = self.sonsColl[idx];
                if (item.id === idClickedNode && item.id === son.model.node.id) {
                  found = true;
                  existingNodeIndex = idx;
                  break;
                }
              }
            }
            if (found === true) {
              // update existing tree node
              var existingNode = self.sonsColl[existingNodeIndex];
              if (existingNode) {
                existingNode.model.node = item;
                //Added to maintain parents after refreshing subtree
                existingNode.model.node.parent = self.model.node;
                existingNode.model.node.set("parents", _.clone(self.model.node.get("parents")));
                if (STR.isBlank(existingNode.model.node.get("parents"))) {
                  existingNode.model.node.set("parents", []);
                }
                existingNode.model.node.get("parents").push(self.model.node.attributes);
                //existingNode.render();
                existingNode._showHideIcon();
                //Update label of current Node

                var label = existingNode.model.root.renderer.call(existingNode, existingNode.model.node);
                existingNode.model.set("label", label);
                //Paint Label
                $(existingNode.$el.find(".cw-treenode-label:first")).html(label);

                //show tooltips
                if (existingNode.tooltipRenderer) {
                  existingNode.tooltipRenderer(existingNode.model.node, existingNode);
                }
                //show tooltips
                if (existingNode.overLabelTooltipRenderer) {
                  existingNode.overLabelTooltipRenderer(existingNode.model.node, existingNode);
                }
              }
            }
          }, this);
          if (callback) {
            callback();
          }
        }
      });
    } else if (this.parent && this.parent._refreshClickedNodeSons && !fictitiousRoot) { //We have clicked in a first level that has a fictitious root
      //Refresh data for firstLevelColl
      this.model.root.rootModel.firstLevelColl.fetch({
        success: function(freshColl) {
          self.model.coll.reset();
          _.each(freshColl.models, function(firstLevelModel) {
            if (STR.isBlank(firstLevelModel.get("code"))) { //Hors regroupement
              firstLevelModel.set("code", " ");
            }
            self.model.coll.add(firstLevelModel);
          });
          self._refreshClickedNodeSons(idClickedNode, callback);
        }
      });

    } else { //We have clicked on a root and it is not fictitious
      if (callback) {
        callback();
      }
    }
  },

  /**
   * Refresh data of parent's sons of node that we want to be refreshed
   */
  _refreshClickedNodeSons: function(idClickedNode, callback) {
    var self = this;
    _.each(self.model.coll.models, function(item) {
      var found = false;
      var existingNodeIndex = null;
      for (var idx = 0; idx < self.sonsColl.length; idx++) {
        var son = self.sonsColl[idx];
        if (item.id === idClickedNode && item.id === son.model.node.id) {
          found = true;
          existingNodeIndex = idx;
          break;
        }
      }
      if (found === true) {
        // update existing tree node
        var existingNode = self.sonsColl[existingNodeIndex];
        if (existingNode) {
          existingNode.model.node = item;
          //Added to maintain parents after refreshing subtree
          existingNode.model.node.parent = self.model.node;
          existingNode.model.node.set("parents", _.clone(self.model.node.get("parents")));
          if (STR.isBlank(existingNode.model.node.get("parents"))) {
            existingNode.model.node.set("parents", []);
          }
          existingNode.model.node.get("parents").push(self.model.node.attributes);
          //existingNode.render();
          existingNode._showHideIcon();
          //Update label of current Node

          var label = existingNode.model.root.renderer.call(existingNode, existingNode.model.node);
          existingNode.model.set("label", label);
          //Paint Label
          $(existingNode.$el.find(".cw-treenode-label:first")).text(label);

          //show tooltips
          if (existingNode.tooltipRenderer) {
            existingNode.tooltipRenderer(existingNode.model.node, existingNode);
          }
          //show tooltips
          if (existingNode.overLabelTooltipRenderer) {
            existingNode.overLabelTooltipRenderer(existingNode.model.node, existingNode);
          }
        }
      }
    }, this);
    if (callback) {
      callback();
    }
  },
  /**
   * Create the subtree for the current node(mode recursive).
   */

  fillSubTree: function(collection, callback) {
    var self = this;
    var l_lon = 0;
    var lRest = 0;

    if (!STR.isBlank(this.model)) {
      l_lon = (!STR.isBlank(collection)) ? collection.length : this.model.coll.models.length;
      this.model.set("hasChild", l_lon);
    }
    var l_last = (l_lon === 0) ? 0 : (l_lon - 1); //derniers process
    var l_quantProcess = 10;
    var l_part = 0;
    var l_list = [];
    var l_estat = [];

    if (l_lon > l_quantProcess) {
      l_part = l_last / l_quantProcess;
      lRest = l_lon % l_quantProcess;
    } else {
      l_quantProcess = l_lon;
      l_part = 1;
    }
    //Math.trunc doesn't work on IE11 and lower
    l_part = parseInt(l_part, 10);
    for (var j = 0; j < l_quantProcess; j++) {
      l_list[j] = l_part * (j + 1);
      l_estat[j] = false;
    }
    if (lRest > 0 && l_part > 0) {
      var incr = parseInt(lRest / l_part) + ((lRest % l_part > 0) ? 1 : 0);

      for (let j = l_quantProcess; j < l_quantProcess + incr; j++) {
        if ((j + 1) === l_quantProcess + incr) {
          l_list[j] = l_part * j + lRest;
        } else {
          l_list[j] = l_part * (j + 1);
        }
        l_estat[j] = false;
      }
      l_quantProcess += incr;
    }
    for (let i = 0; i < l_quantProcess; i++) {
      this._runList(collection, l_estat, (i === 0 ? 0 : l_list[i - 1]), l_list[i], false, i, function(a_ind) {
        l_estat[a_ind] = true;
        self._runList(collection, l_estat, l_list[l_quantProcess - 1], l_lon, true, a_ind, callback);
      });
    }
  },

  _runList: function(collection, a_estat, a_ind, a_lon, a_last, a_posEstat, callback) {
    if (_.isBoolean(a_last) && a_last) {
      if (a_estat) {
        var lb_fin = true;
        var l_lon2 = a_estat.length;
        for (let i = 0; i < l_lon2; i++) {
          lb_fin = lb_fin && a_estat[i];
        }
        if (lb_fin) {
          if (a_ind < a_lon) {
            this._fillPartial(collection, a_estat, a_ind, a_lon, a_last, a_posEstat, callback);
          } else {
            if (callback) {
              callback();
            }
          }
        }
        //rien dans un autre cas
      }
    } else {
      if (a_ind < a_lon) {
        this._fillPartial(collection, a_estat, a_ind, a_lon, a_last, a_posEstat, callback);
      } else {
        if (callback) {
          callback(a_posEstat);
        }
      }
    }
  },

  _fillPartial: function(collection, a_estat, a_pos, a_lon, a_last, a_posEstat, callback) {
    var self = this;
    var item = null;
    var l_ind = a_pos + 1;
    var selectionableNode = true;

    if (STR.isBlank(collection) && a_pos >= 0 && a_pos < a_lon && !STR.isBlank(this.model) && !STR.isBlank(this.model.coll) && this.model.coll.length > 0 && a_pos < this.model.coll.length) {
      item = this.model.coll.at(a_pos);
      item.parent = this.model.node;
      item.set("parents", _.clone(this.model.node.get("parents")));
      if (STR.isBlank(item.get("parents"))) {
        item.set("parents", []);
      }
      item.get("parents").push(this.model.node.attributes);
      if (this.model.selectableNode) {
        selectionableNode = this.model.selectableNode.call(item, item, item.parent);
      }
      var treeNode = new CWTreeNodeView({
        root: this.model.root,
        parent: this,
        node: item,
        level: this.model.get("level") + 1,
        showSelected: this.showSelected,
        selectableNode: selectionableNode,
        multiselect: this.multiselect,
        isCheckedCallback: this.isCheckedCallback,
        hideCheck: this.hideCheck,
        readOnly: this.readOnly,
        hieractivitidad: this.hieractivitidad
      });
      treeNode.setSortFunction(this.sortTree);
      this.sonsColl.push(treeNode);
      treeNode.render(function() {
        if (!STR.isBlank(self.$el)) {
          self.$el.append(treeNode.el);
        }
        self._runList(collection, a_estat, l_ind, a_lon, a_last, a_posEstat, callback);
      });
    } else if (!STR.isBlank(collection) && a_pos >= 0 && a_pos < a_lon && collection.models && collection.models.length > 0) {
      var node = null;

      for (let i = a_pos; i < a_lon && i < collection.length; i++) {
        item = collection.at(i);
        item.set("parents", _.clone(this.model.node.get("parents")));
        if (STR.isBlank(item.get("parents"))) {
          item.set("parents", []);
        }
        item.get("parents").push(this.model.node.attributes);
        item.parent = this.model.node;
        if (this.model.selectableNode) {
          selectionableNode = this.model.selectableNode.call(item, item.parent);
        }
        node = new CWTreeNodeView({
          root: this.model.root,
          parent: this,
          node: item,
          level: this.model.get("level") + 1,
          showSelected: this.showSelected,
          selectableNode: selectionableNode,
          multiselect: this.multiselect,
          isCheckedCallback: this.isCheckedCallback,
          hideCheck: this.hideCheck,
          readOnly: this.readOnly,
          hieractivitidad: this.hieractivitidad
        });
        node.setSortFunction(this.sortTree);
        this.sonsColl.push(node);
        node.render();
        node.fillSubTree(item.get("enfants"), callback);
        this.model.set("expanded", true);
        this.$el.removeClass("cw-treenode-expandable").addClass("cw-treenode-collapsable");
        this.$el.append(node.el);
      }
    }
  },

  /**
   * Create the subtree for the current node(mode itérative).
   */

  fillSubTreeRest: function(collection, callback) {
    var _this = this;

    this.model.set("hasChild", this.model.coll.models.length);
    if (STR.isBlank(collection)) {
      if (this.model && this.model.coll && this.model.coll.models && this.model.coll.models.length > 0) {
        _.each(this.model.coll.models, function(item) {
          item.parent = _this.model.node;
          item.set("parents", _.clone(_this.model.node.get("parents")));
          if (STR.isBlank(item.get("parents"))) {
            item.set("parents", []);
          }
          item.get("parents").push(_this.model.node.attributes);

          var selectionableNode = true;
          if (_this.model.selectableNode) {
            selectionableNode = _this.model.selectableNode.call(item, item, item.parent);
          }
          var treeNode = new CWTreeNodeView({
            root: _this.model.root,
            node: item,
            level: _this.model.get("level") + 1,
            showSelected: _this.showSelected,
            selectableNode: selectionableNode,
            multiselect: _this.multiselect,
            parent: _this.parent,
            isCheckedCallback: _this.isCheckedCallback,
            hideCheck: _this.hideCheck,
            readOnly: _this.readOnly,
            hieractivitidad: _this.hieractivitidad
          });
          treeNode.setSortFunction(_this.sortTree);
          _this.sonsColl.push(treeNode);
          _this.$el.append(treeNode.render().el);
        });
      }
    } else {
      if (collection && collection.models && collection.models.length > 0) {
        _.each(collection.models, function(item) {
          item.set("parents", _.clone(_this.model.node.get("parents")));
          if (STR.isBlank(item.get("parents"))) {
            item.set("parents", []);
          }
          item.get("parents").push(_this.model.node.attributes);
          item.parent = _this.model.node;

          var selectionableNode = true;
          if (_this.model.selectableNode) {
            selectionableNode = _this.model.selectableNode.call(item, item.parent);
          }
          var node = new CWTreeNodeView({
            root: _this.model.root,
            parent: _this,
            node: item,
            level: _this.model.get("level") + 1,
            showSelected: _this.showSelected,
            selectableNode: selectionableNode,
            multiselect: _this.multiselect,
            isCheckedCallback: _this.isCheckedCallback,
            hideCheck: _this.hideCheck,
            readOnly: _this.readOnly,
            hieractivitidad: _this.hieractivitidad
          });
          node.setSortFunction(_this.sortTree);
          _this.sonsColl.push(node);
          node.render();
          node.fillSubTreeRest(item.get("enfants"), callback);
          _this.model.set("expanded", true);
          _this.$el.removeClass("cw-treenode-expandable").addClass("cw-treenode-collapsable");
          _this.$el.append(node.el);
        });
      }
    }
    if (callback) {
      callback();
    }
  },

  /**
   * Updates the subtree information.
   */
  updateSubTree: function() {
    var _this = this;

    this.model.set("hasChild", this.model.coll.models.length);
    _.each(this.model.coll.models, function(item) {
      var found = false;
      var existingNodeIndex = null;

      for (var idx = 0; idx < _this.sonsColl.length; idx++) {
        var son = _this.sonsColl[idx];
        if (item.id === son.model.node.id) {
          found = true;
          existingNodeIndex = idx;
          break;
        }
      }

      if (found === true) {
        // update existing tree node
        var existingNode = _this.sonsColl[existingNodeIndex];
        if (existingNode) {
          existingNode.model.node = item;
          _this._addListenersToNode(existingNode);
          //Added to maintain parents after updating subtree
          existingNode.model.node.parent = _this.model.node;
          existingNode.model.node.set("parents", _.clone(_this.model.node.get("parents")));
          if (STR.isBlank(existingNode.model.node.get("parents"))) {
            existingNode.model.node.set("parents", []);
          }
          existingNode.model.node.get("parents").push(_this.model.node.attributes);

          //existingNode.render();
          existingNode._showHideIcon();

          //show tooltips
          if (existingNode.tooltipRenderer) {
            existingNode.tooltipRenderer(existingNode.model.node, existingNode);
          }
          //show tooltips
          if (existingNode.overLabelTooltipRenderer) {
            existingNode.overLabelTooltipRenderer(existingNode.model.node, existingNode);
          }
        }
      } else {
        // create new node to add it to the tree
        item.parent = _this.model.node;
        item.set("parents", _.clone(_this.model.node.get("parents")));
        if (STR.isBlank(item.get("parents"))) {
          item.set("parents", []);
        }
        item.get("parents").push(_this.model.node.attributes);
        var selectionableNode = true;
        if (_this.model.selectableNode) {
          selectionableNode = _this.model.selectableNode.call(item, item, item.parent);
        }
        var treeNode = new CWTreeNodeView({
          root: _this.model.root,
          parent: _this,
          node: item,
          level: _this.model.get("level") + 1,
          showSelected: _this.showSelected,
          selectableNode: selectionableNode,
          multiselect: _this.multiselect,
          hideCheck: _this.hideCheck,
          readOnly: _this.readOnly,
          hieractivitidad: _this.hieractivitidad
        });
        treeNode.setSortFunction(_this.sortTree);
        _this.sonsColl.push(treeNode);
        _this.$el.append(treeNode.render().el);
      }
    });

    // remove nodes
    _.each(_.compact(this.sonsColl), function(son, index) {
      var found = false;
      for (var idx = 0; idx < _this.model.coll.models.length; idx++) {
        var item = _this.model.coll.models[idx];
        // when the model values are empty we delete the unused row.
        if (!STR.isBlank(son.model) && item.id === son.model.node.id) {
          found = true;
          break;
        }
      }
      if (found === false) {
        var deleteNode = _this.sonsColl[index];
        if (deleteNode) {
          deleteNode.remove();
          _this.sonsColl.splice(index, 1);
        }
      }
    });

    //Show or not the expand icon
    var feuille = (this.sonsColl.length === 0);
    if (feuille === true && this.model.get("level") !== 0) {
      this.$el.find(".cw-treenode-icon").first().hide();
      this.$el.find(".cw-treenode-leaf-icon").first().show();
    } else {
      this.$el.find(".cw-treenode-icon").first().show();
      this.$el.find(".cw-treenode-leaf-icon").first().hide();
    }
    this.repaintLabel(this.model.root.renderer);
  },

  /**
   * Refresh the subtree Information.
   */
  refreshSubTree: function() {
    var _this = this;

    if (!STR.isBlank(this.model)) { //When the node has been deleted and no longer exists, we don't do anything
      //If node has not been deleted we make the treatment
      _.each(this.model.coll.models, function(item) {
        var found = false;
        var existingNodeIndex = null;
        for (var idx = 0; idx < _this.sonsColl.length; idx++) {
          var son = _this.sonsColl[idx];
          if (item.id === son.model.node.id) {
            found = true;
            existingNodeIndex = idx;
            break;
          }
        }

        if (found === true) {
          // update existing tree node
          var existingNode = _this.sonsColl[existingNodeIndex];
          if (existingNode) {
            existingNode.model.node = item;
            //Added to maintain parents after refreshing subtree
            existingNode.model.node.parent = _this.model.node;
            existingNode.model.node.set("parents", _.clone(_this.model.node.get("parents")));
            if (STR.isBlank(existingNode.model.node.get("parents"))) {
              existingNode.model.node.set("parents", []);
            }
            existingNode.model.node.get("parents").push(_this.model.node.attributes);

            //existingNode.render();
            existingNode._showHideIcon();

            //show tooltips
            if (existingNode.tooltipRenderer) {
              existingNode.tooltipRenderer(existingNode.model.node, existingNode);
            }
            //show tooltips
            if (existingNode.overLabelTooltipRenderer) {
              existingNode.overLabelTooltipRenderer(existingNode.model.node, existingNode);
            }
          }
        } else if (!STR.isBlank(_this.model.get("expanded")) && _this.model.get("expanded") === true) {
          // create new node to add it to the tree
          item.parent = _this.model.node;
          item.set("parents", _.clone(_this.model.node.get("parents")));
          if (!item.get("parents")) {
            item.set("parents", []);
          }
          item.get("parents").push(_this.model.node.attributes);

          var selectionableNode = true;
          if (_this.model.selectableNode) {
            selectionableNode = _this.model.selectableNode.call(item, item, item.parent);
          }
          var treeNode = new CWTreeNodeView({
            root: _this.model.root,
            parent: _this,
            node: item,
            level: _this.model.get("level") + 1,
            showSelected: _this.showSelected,
            selectableNode: selectionableNode,
            multiselect: _this.multiselect,
            hideCheck: _this.hideCheck,
            readOnly: _this.readOnly,
            hieractivitidad: _this.hieractivitidad
          });
          treeNode.setSortFunction(_this.sortTree);
          _this.sonsColl.push(treeNode);
          _this.$el.append(treeNode.render().el);
        }
      }, this);

      // remove nodes
      if (!STR.isBlank(this.model.get("expanded")) && this.model.get("expanded") === true) {
        for (var index = this.sonsColl.length - 1; index >= 0; index--) {
          var found = false;
          var son = this.sonsColl[index];
          for (var idx = 0; idx < this.model.coll.models.length; idx++) {
            if (!STR.isBlank(son)) {
              var item = this.model.coll.models[idx];
              // when the model values are empty we delete the unused row.
              if (!STR.isBlank(son.model) && item.id === son.model.node.id) {
                found = true;
                break;
              }
              if (item.id === son.model.node.id) {
                found = true;
                break;
              }
            }
          }
          if (found === false) {
            var deleteNode = this.sonsColl[index];
            if (deleteNode) {
              deleteNode.remove();
              this.sonsColl.splice(index, 1);
            }
          }
        }
      }

      //Show or not the expand icon
      var feuille = (this.model.coll.models.length === 0);
      if (feuille === true) {
        this.$el.find(".cw-treenode-icon").first().hide();
        this.$el.find(".cw-treenode-leaf-icon").first().show();
      } else {
        this.$el.find(".cw-treenode-icon").first().show();
        this.$el.find(".cw-treenode-leaf-icon").first().hide();
      }
      this.repaintLabel(this.model.root.renderer);
    }

  },

  /**
   * Setter for the sortTree function
   */
  setSortFunction: function(func) {
    this.sortTree = func;
  },

  /**
   * Sort the SubTree with the sorting function "sortTree"
   */
  _sortSubTree: function() {
    var self = this;

    // sort subtree
    this.sonsColl.sort(this.sortTree);

    // repaint subtree
    _.each(this.sonsColl, function(item) {
      self.$el.append(item.$el.detach());
    }, this);
  },

  _selectNode: function() {
    this.$el.find(".cw-treenode-label:first").trigger("click");
    var container = $("#" + this.cid).parents(".activite-overflow");
    if (container.length > 0) {
      container.scrollTop(0);
      var diff = $("#" + this.cid).offset().top - container.offset().top;
      //-8px for separate the element to the top margin.
      container.scrollTop(diff - 8);
    }
  },

  /**
   * Marks element as selected without changing data model about selection element (because it is already selected in mode simple input
   * and we are only remarking the selection class)
   */
  _markCLassSelectedNode: function() {
    if (!this.$el.find(".cw-treenode-label").first().hasClass("cw-node-not-selectionnable")) { //When this node can be selected
      if (this.showSelected) {
        this.$el.find("span.cw-treenode-label").first().addClass("ui-state-active");
        this.$el.find("span.cw-treenode-label").first().addClass("cw-treeNode-lastNodeSelected");
      }
    }
    var container = $("#" + this.cid).parents(".activite-overflow");
    if (container.length > 0) {
      container.scrollTop(0);
      var diff = $("#" + this.cid).offset().top - container.offset().top;
      //-8px for separate the element to the top margin.
      container.scrollTop(diff - 8);
    }
  },

  _addListenersToNode: function(existingNode) {
    if (!STR.isBlank(existingNode)) {
      var node = existingNode.model.node;
      node.off("expandNode");
      node.off("selectNode");
      node.off("markCLassSelectedNode");
      node.off("refresh");
      existingNode.listenTo(node, "expandNode", this._expand);
      existingNode.listenTo(node, "selectNode", this._selectNode);
      existingNode.listenTo(node, "markCLassSelectedNode", this._markCLassSelectedNode);
      existingNode.listenTo(node, "refresh", this._refreshSubtreeByTrigger);
    } else {
      this.model.node.off("expandNode");
      this.model.node.off("selectNode");
      this.model.node.off("markCLassSelectedNode");
      this.model.node.off("refresh");
      this.listenTo(this.model.node, "expandNode", this._expand);
      this.listenTo(this.model.node, "selectNode", this._selectNode);
      this.listenTo(this.model.node, "markCLassSelectedNode", this._markCLassSelectedNode);
      this.listenTo(this.model.node, "refresh", this._refreshSubtreeByTrigger);
    }
  }
});
