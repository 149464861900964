import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWActiviteModel } from 'common/evenements/gerer/gererrecapitulatifs/models/cwActivite.model';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseGridColums } from 'src/core/grids/basegrid/cwBaseGrid.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWBlockView } from 'common/recaphisto/views/cwBlock.view';
import { CWDataGridCellView } from 'src/core/grids/data_grid/cwDataGridCell.view';
import { CWDataGridModel } from 'src/core/grids/data_grid/cwDataGrid.model';
import { CWDataGridView } from 'src/core/grids/data_grid/cwDataGrid.view';
import { CWDialogPopupType, CWDialogPopupView } from 'core/components/dialog/popup/cwDialogPopup.view';
import { CWDIVERS } from 'utils/cwDivers';
import { CWEtiquette, validEtiquetteTypes } from 'src/core/components/etiquette/cwEtiquette.view';
import { CWFORMS } from 'utils/cwForms';
import { CWGererPiecesJointesView } from 'common/evenements/gerer/gererpiecesjointes/views/cwGererPiecesJointes.view';
import { CWGererRecapGeneralView } from 'common/evenements/gerer/gererrecapitulatifs/views/cwGererRecapGeneral.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHeaderPopupView } from './cwHeaderPopup.view';
import { CWListerecapGeneralContextInterface } from './cwListerecapGeneral.view';
import { CWListerecapWorkflow } from '../models/cwListerecap.workflow';
import { CWListerHistoriqueModel } from '../models/cwListerHistorique.model';
import { CWLOG } from 'utils/cwLog';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWPanneauDeroulant } from 'src/core/components/dialog/panneau_deroulant/cwPanneauDeroulant.view';
import { CWProgressBarModel } from 'common/progressbar/models/cwProgressBar.model';
import { CWProgressBarView } from 'src/uc/common/progressbar/views/cwProgressBar.view';
import { CWRecapColl } from '../models/cwRecap.collection';
import { CWRecapModel } from '../models/cwRecap.model';
import { CWResumePopUpView } from './cwResumePopUp.view';
import { CWRowPopupView } from './cwRowPopup.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { NAV } from 'src/utils/nav.js';
import { objs } from 'src/objectsRepository';
import { UTILS } from 'utils/utils.js';

type RowValidationActions = "A" | "R" | "";

export class CWListerRecapFormView extends CWBaseFormView {
  public workflow: CWListerecapWorkflow;
  public table: CWDataGridView<CWRecapModel, CWRecapColl<CWRecapModel>>;
  public model: CWRecapModel;
  protected context: { [key: string]: any };
  protected selectInSorting: boolean;
  protected hideLinkGererRecap: boolean;
  protected historiqueModel: CWListerHistoriqueModel;
  protected wkfEvenements: string[];
  protected copyModelsState: any[];
  protected isSortedAsc: boolean;
  protected headerDialog: CWPanneauDeroulant;
  protected rowDialog: CWPanneauDeroulant;
  protected dialogProgressBar: CWPanneauDeroulant;
  protected recapUpdated: boolean;
  protected popupGereRecapDialog: any;
  protected managingVueChange: boolean;
  protected recap: any;
  protected parent: any;
  protected pjPopupView: any;
  protected resumeDialog: any;
  private progressBarOpened: boolean;
  private $appendTo: JQuery; //pour les messages


  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "click .c-grind__headerScroll__header .btn-validations": "_manageHeaderButtons",
      "click .c-grind__table .btn-validations": "_manageLineButtons",
      "click button": "_clickListener",
      "click .linkCom": "_openRowPopup",
      "click a.recap-link": "_detailRecapitulatif",
      "click .piece-jointe": "_managePieceJointe",
      "click .suivicollab-link": "_manageSuiviLink",
      "click .btn-commentaire-bulle": "_commentairePopUp",
      "click .btn-commentaire-bulle-header": "_openHeaderPopup",
      'click .phx-td1': "_manageSuiviLink",
      'click .phx-td2': "_detailRecapitulatif",
      'click .phx-td3': "_detailRecapitulatif"
    }, options.events);
    options.className = options.context.ctxEcran === "mactivites" ? "tableRecapPopup" : "tableRecap";
    super(options);
    this.workflow = options.workflow;
    this.$appendTo = (this.workflow && this.workflow.context && !CWSTR.isBlank(this.workflow.context.ctxEcran)) ? $("#" + this.workflow.context.ctxEcran) : null;
    this.context = options.context;
    this.selectInSorting = true;
    this.selectInSorting = options.selectInSorting;
    this.hideLinkGererRecap = options.hideLinkGererRecap;
    this.model = new CWRecapModel({
      // This values are set in the workflow.
      progressBarModel: new CWProgressBarModel(),
      globalComment: "",
      isForced: false
    });
    this.model.setHabContext(this.workflow.getHabContext());
    this.historiqueModel = new CWListerHistoriqueModel();
    this.historiqueModel.setHabContext(this.workflow.getHabContext());
    this.wkfEvenements = null;
    this.table = this._initTable();
    //events
    this.model.on("cleanGridErrors", this._cleanErrorsTable, this);
    this.model.get("progressBarModel").on("change:value", this._changeProgressBar, this);
    this.table.model.coll.on("revert:tableNotProfils", (): void => { this._revertTable(); }, this);
    this.table.model.coll.on("revert:table", this._revertTable, this);
    this.model.on("resetSortableColumns", this._resetSortableColumns, this);
    this.table.model.on("rows:checked", this.tableRowChecked, this);
    this.table.model.on("showGrid", this._showGrid, this);
    this.table.model.on("hideGrid", this._hideGrid, this);
    this.table.model.on("finish:proccess", this._validationFinnished, this);
    this.workflow.on('recapCreated', this._openDetailPopup, this);
    this.copyModelsState = [];
    // To manage the btnBar, we initialize the counter to have the number of recap to treat.
    this.table.model.coll.nbrecEtatValid = 0;
    this.table.model.coll.nbrecEtatA = 0;
    this.table.model.coll.nbrecEtatR = 0;
    this.isSortedAsc = true;
    this.progressBarOpened = false;
  }

  resetAfterContextChange(): void {
    // To manage the btnBar, we initialize the counter to have the number of recap to treat.
    // this.resetCompteurs();

    $(this.el).find(".btn-validations.A").removeClass("selectionne").addClass("nonSelectionne");
    $(this.el).find(".btn-validations.R").removeClass("selectionne").addClass("nonSelectionne");
    $(this.el).find(".btn-commentaire-bulle-header").removeClass("selectionne");
    $(this.el).find(".btn-commentaire-bulle").removeClass("selectionne");

    CWSTR.setElValue(this.model, "globalComment", "");
    this.table.model.trigger('collReset');
  }



  private resetCompteurs(): void {
    this.table.model.coll.nbrecEtatValid = 0;
    this.table.model.coll.nbrecEtatA = 0;
    this.table.model.coll.nbrecEtatR = 0;
    this.copyModelsState = this._copyModels(this.table.model.coll);
    this.table.model.multiselectColl.reset(null);
    this._manageBtnBarActivation();
  }

  _revertTable(): void {
    const tableColl = this.table.model.coll as CWRecapColl;

    CWSTR.setElValue(this.model, "globalComment", undefined);
    $(this.el).find(".btn-validations.A").removeClass("selectionne").addClass("nonSelectionne");
    $(this.el).find(".btn-validations.R").removeClass("selectionne").addClass("nonSelectionne");
    $(this.el).find(".btn-commentaire-bulle-header").removeClass("selectionne");
    $(this.el).find(".btn-commentaire-bulle").removeClass("selectionne");

    this._manageRows(); //select - deselect row

    this.table.model.coll.nbrecEtatValid = 0;
    this.table.model.coll.nbrecEtatA = 0;
    this.table.model.coll.nbrecEtatR = 0;
    this.copyModelsState = this._copyModels(this.table.model.coll);

    tableColl.trigger("display:alert", "hide");

    this._cleanErrorsTable();
    this.workflow.btnBarModel.set("mode", "R");
  }


  _openHeaderPopup(event: JQueryEventObject): void {
    const comment = this.model.get("globalComment");
    const isforced = this.model.get("isForced");
    let action = "";
    const commentrequired = this.checkRefuseEvenement();

    if (event.currentTarget.className.split(" ")[0] === "cw-icon") {
      action = event.currentTarget.className.split(" ")[2];
    } else {
      action = event.currentTarget.className.split(" ")[4];
    }
    if (this.rowDialog !== undefined) {
      this.rowDialog._closePanneau();
    }
    if (this.headerDialog === undefined) {
      this.headerDialog = new CWPanneauDeroulant({
        view: CWHeaderPopupView,
        viewData: {
          appendTo: this.el,
          commentaire: comment,
          isForced: isforced,
          action: action,
          commentRequired: commentrequired,
        },
        notIconClose: false,
        closeOnClickOutside: true
      });
    } else {
      this.headerDialog.viewData = {
        appendTo: this.el,
        commentaire: comment,
        isForced: isforced,
        action: action,
        commentRequired: commentrequired
      };
    }

    // set buttons
    const btn = [{
      text: i18n.t('common:listerecap.confirmer'),
      btnClass: 'btn btn-primary btn-withIcon bt-col-blue',
      icon: UTILS.getSVGIcon("valider", "c-panneauMenu__tickIcon", 16, null),
      click: (): void => {
        this.headerDialog.getInternalViewObject().trigger("btnResult", "btnValider");
      }
    }, {
      text: i18n.t('common:listerecap.annuler'),
      btnClass: 'btn btn-secondary btn-withIcon',
      icon: UTILS.getSVGIcon("croix", "", 16, null),
      click: (): void => {
        this.headerDialog.getInternalViewObject().trigger("btnResult", "btnRevert");
      }
    }];
    this.headerDialog.setButtons(btn);
    this.headerDialog.setHeight('auto');
    this.headerDialog.setWidth(500);
    this.headerDialog.model.set("globalComment", comment);


    const dialogModel = this.headerDialog.model;
    dialogModel.trigger("dlg:open", (): void => {
      const isForced = dialogModel.get("isForced");

      if (dialogModel.get("isCommentChanged") === true || isForced === true) {
        this.removeGlobalComment(dialogModel.get("globalComment"), action, isForced);
      }

      if (CWSTR.isBlank(dialogModel.get("globalComment"))) {
        if (action === "R") {
          this.$el.find(".btn-validations." + action).removeClass("selectionne").addClass("nonSelectionne");
          this._manageAllRows(null, false);
          this._manageRows();
          this.resetCompteurs();
          this._revertTable();
        }
      }
    });
    this.headerDialog._setTitle(i18n.t('common:listerecap.commentaireGlobalPopup'));
    event.stopPropagation();
  }

  _openRowPopup(event: JQueryEventObject): void {
    const id = event.currentTarget.className.split(" ")[4];
    const model = !CWSTR.isBlank(id) ? this._getRecapActiviteModel(id) : null;
    const action = event.currentTarget.className.split(" ")[3];
    const comment = !CWSTR.isBlank(model.get("commentaire")) ? model.get("commentaire") : "";
    const refEvenement = model.get("evenement");

    if (this.headerDialog !== undefined) {
      this.headerDialog._closePanneau();
    }

    if (this.rowDialog === undefined) {
      this.rowDialog = new CWPanneauDeroulant({
        fixedButtons: true,
        maxHeight: 360,
        view: CWRowPopupView,
        viewData: {
          appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
          commentaire: comment,
          action: model.recEtatValid,
          refEvenement: refEvenement,
          workflow: this.workflow
        },
        notIconClose: false,
        closeOnClickOutside: true
      });
    } else {
      this.rowDialog.viewData = {
        appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
        commentaire: comment,
        action: model.recEtatValid,
        refEvenement: refEvenement,
        workflow: this.workflow
      };
    }

    this.rowDialog.setPosition({
      my: "right top",
      at: "right bottom",
      of: $(event.currentTarget)
    });

    // set buttons
    const btn = [{
      text: i18n.t('common:listerecap.confirmer'),
      btnClass: 'btn btn-primary btn-withIcon bt-col-blue',
      icon: UTILS.getSVGIcon("valider", "c-panneauMenu__tickIcon", 16, null),
      click: (): void => {
        this.rowDialog.getInternalViewObject().trigger("btnResult", "btnValider");
      }
    }, {
      text: i18n.t('common:listerecap.annuler'),
      btnClass: 'btn btn-secondary btn-withIcon',
      icon: UTILS.getSVGIcon("croix", "", 16, null),
      click: (): void => {
        this.rowDialog.getInternalViewObject().trigger("btnResult", "btnRevert");
      }
    }];
    this.rowDialog.setButtons(btn);
    this.rowDialog.setHeight(360);
    this.rowDialog.setWidth("auto");

    this.rowDialog.model.set("commentaire", model.get("commentaire"));

    //const dialogModel = this.rowDialog.model;
    this.rowDialog.model.set("commentaire", model.get("commentaire"));
    this.rowDialog.model.trigger("dlg:open", (): void => {
      if (this.rowDialog.model.get("isCommentChanged") === true) {
        const commentaire = this.rowDialog.model.get("commentaire");
        model.set("commentaire", commentaire, { silent: true });
        if (CWSTR.isBlank(commentaire)) {
          this.$el.find(".btn-commentaire-bulle." + id).removeClass("selectionne");
          this.table.model.multiselectColl.remove(model);
          this.refreshElements(model);
        } else {
          this.$el.find(".btn-commentaire-bulle." + id).addClass("selectionne");
        }
      } else {
        const commentaire = this.rowDialog.model.get("commentaire");
        const modelSelected = this.table.model.multiselectColl.get(model) as CWRecapModel;
        if (!CWSTR.isBlank(modelSelected)) {
          if (CWSTR.isBlank(commentaire) && modelSelected.recEtatValid === "R") {
            this.table.model.multiselectColl.remove(model);
            this.refreshElements(model);
            model.recEtatValid = null;
            $(this.el).find("." + action + "." + id).removeClass("selectionne").addClass("nonSelectionne");
          }
        }
      }
      this._manageRows(); //select - deselect row

    });
    const titlePopup = this.setPopupTitle(model, action);
    this.rowDialog._setTitle(titlePopup);
    event.stopPropagation();

  }

  /*
   *  Change the comment in each recap if it is selected and it has the old global comment.
   */
  _manageComments(newCommentaire: string, isForced: boolean): void {
    const ancientCommentaire = this.model.get("globalComment") === undefined ? "" : this.model.get("globalComment");
    const tableColl = this.table.model.coll;
    _.each(tableColl.models, (tableRow: CWActiviteModel) => {
      let id = tableRow.get("code");
      id = id.replace(/\//g, '_');
      id = id.replace(/\+/g, '-');
      if (isForced === true) {
        tableRow.set("commentaire", newCommentaire, { silent: true });
        tableRow.individualComment = false;
        if (CWSTR.isBlank(newCommentaire)) {
          this.$el.find(".btn-commentaire-bulle." + id).removeClass("selectionne");
        } else {
          this.$el.find(".btn-commentaire-bulle." + id).addClass("selectionne");
        }
      } else if (tableRow.get("commentaire") === ancientCommentaire && tableRow.individualComment !== true) {
        tableRow.set("commentaire", newCommentaire, { silent: true });
        if (CWSTR.isBlank(newCommentaire)) {
          this.$el.find(".btn-commentaire-bulle." + id).removeClass("selectionne");
        } else {
          this.$el.find(".btn-commentaire-bulle." + id).addClass("selectionne");
        }
      }
    });
  }

  _manageHeaderButtons(event: JQueryEventObject): void {
    const checked = event.currentTarget.className.includes("nonSelectionne");
    const action: RowValidationActions = event.currentTarget.className.split(" ")[4] as RowValidationActions;

    if (checked === true) {
      this.table.model.coll.nbrecEtatValid = 0;
      this.table.model.coll.nbrecEtatA = 0;
      this.table.model.coll.nbrecEtatR = 0;
    }

    this._manageAllRows(action, checked);
    this._manageBtnBarActivation();
    this._manageFiltreEnabled();
    this._displayHeaderLeftText();
    this._displayHeaderRightText();
    this._checkNbAlert();
    this.copyModelsState = this._copyModels(this.table.model.coll);

    switch (action) {
      case "A":
        if (checked === true) {
          this._openHeaderPopup(event);
          $(this.el).find(".btn-validations.R").addClass("nonSelectionne").removeClass("selectionne");
          $(this.el).find(".btn-validations." + action).removeClass("nonSelectionne").addClass("selectionne");
        } else {
          $(this.el).find(".btn-validations." + action).removeClass("selectionne").addClass("nonSelectionne");
          this.removeGlobalComment("", action, false);
        }
        break;
      case "R":
        if (checked === true) {
          this._openHeaderPopup(event);
          $(this.el).find(".btn-validations.A").addClass("nonSelectionne").removeClass("selectionne");
          $(this.el).find(".btn-validations." + action).removeClass("nonSelectionne").addClass("selectionne");
        } else {
          $(this.el).find(".btn-validations." + action).removeClass("selectionne").addClass("nonSelectionne");
          this.removeGlobalComment("", action, false);
        }
        break;
      default:
        break;
    }
    this._manageRows();
    this.table.model.coll.trigger("updateHeader:coll");
  }

  _manageLineButtons(event: JQueryEventObject | any): void {
    const checked = event.currentTarget.className.includes("nonSelectionne");
    const action = event.currentTarget.className.split(" ")[3];
    const id = event.currentTarget.className.split(" ")[4];
    // let model = this.table.model.coll.get(id) as CWRecapModel;
    const model = this._getRecapActiviteModel(id)
    let variateSelection = true;

    this._manageRow(model, action, checked);
    this._manageBtnBarActivation();
    this._displayHeaderRightText();
    this._checkNbAlert();

    switch (action) {
      case "A":
        if (checked === true) {
          if ($(this.el).find(".R." + id).hasClass("selectionne")) {
            $(this.el).find(".R." + id).addClass("nonSelectionne").removeClass("selectionne");
            variateSelection = false;
          }
          $(this.el).find("." + action + "." + id).removeClass("nonSelectionne").addClass("selectionne");
          model.recEtatValid = "A";
        } else {
          $(this.el).find("." + action + "." + id).removeClass("selectionne").addClass("nonSelectionne");
          $(this.el).find("." + action + "." + id).blur();
          model.recEtatValid = "";
        }
        break;
      case "R":
        if (checked === true) {
          if ($(this.el).find(".A." + id).hasClass("selectionne")) {
            $(this.el).find(".A." + id).addClass("nonSelectionne").removeClass("selectionne");
            variateSelection = false;
          }
          $(this.el).find("." + action + "." + id).removeClass("nonSelectionne").addClass("selectionne");
          this._openRowPopup(event);
          model.recEtatValid = "R";
        } else {
          $(this.el).find("." + action + "." + id).removeClass("selectionne").addClass("nonSelectionne");
          $(this.el).find("." + action + "." + id).blur();
          model.recEtatValid = "";
          model.set("commentaire", "");
          $(this.el).find(".btn-commentaire-bulle." + id).removeClass("selectionne");
        }
        break;
    }
    if (variateSelection) {
      if ($(event.currentTarget).parent().find(".selectionne").length > 0 && CWSTR.isBlank(this.table.model.multiselectColl.get(id))) {
        this.table.model.multiselectColl.add(model);
      }
      else {
        this.table.model.multiselectColl.remove(model);
      }

      this._manageRows(); //select - deselect row
      this.table.model.coll.trigger("updateHeader:coll");
    }
  }

  _resetSortableColumns(): void {
    let sortableColumns: string[];

    if (this.context.ctxGestionCollective === true && this.context.ctxValidation === true) {
      sortableColumns = ["collaborateur", "recapitulatif", "periode"];
    } else {
      sortableColumns = ["recapitulatif", "periode"];
    }
    this.table.setSortableColumns(sortableColumns);
  }

  //select - deselect row
  _manageRows(): void {
    _.each(this.table.rows, function (row) {
      row._manageLineSelected();
    });
  }
  _checkNbAlert(): void {
    let nbAlert = this.workflow.pDiversValRAalertModel.get("valeur");
    const nbChecked = this.table.model.coll.nbrecEtatValid;

    if (CWSTR.isBlank(nbAlert)) { nbAlert = 5; } // Default Value

    if (nbChecked >= nbAlert) {
      this.table.model.coll.trigger("display:alert", "show");
    } else {
      this.table.model.coll.trigger("display:alert", "hide");
    }
  }

  _manageAllRows(action: RowValidationActions, checked: boolean): void { // action A, R, ""
    const tableColl = this.table.model.coll;


    _.each(tableColl.models, (tableRow: CWRecapModel): void => {
      tableRow.recEtatValid = "";
    });

    _.each(tableColl.models, (tableRow: CWRecapModel): void => {
      this._manageRow(tableRow, action, checked);
    });
    this.copyModelsState = this._copyModels(this.table.model.coll);
  }

  _manageRow(model: CWRecapModel, action: RowValidationActions, checked: boolean): void { // action A, R, ""
    const tableColl = this.table.model.coll;

    if (this.workflow._modelCanBeManaged(model) === true) {
      //If this model can't be managed don't update its recEtatValid value
      if (checked === true) {
        model.recEtatValid = action === "A" ? "A" : "R";
        if (action === "A") {
          tableColl.nbrecEtatA++;
        } else {
          tableColl.nbrecEtatR++;
        }
        model.nbrecEtatValid = action === "A" ? tableColl.nbrecEtatA : tableColl.nbrecEtatR;
        tableColl.nbrecEtatValid++;
      } else {
        if (action === "A") {
          tableColl.nbrecEtatA--;
        } else {
          tableColl.nbrecEtatR--;
        }
        model.nbrecEtatValid = action === "A" ? tableColl.nbrecEtatA : tableColl.nbrecEtatR;
        model.recEtatValid = "";
        tableColl.nbrecEtatValid--;
      }
    }
  }

  _manageBtnBarActivation(): void {
    const tableColl = this.table.model.coll;

    if (tableColl.nbrecEtatValid === 0) {
      this._cleanErrorsTable();
      //this.workflow.btnBarModel.set("mode", "R");
      this._resetSortableColumns();
    } else {
      _.each(this.table.currentVue._columns, (column: any) => {
        column.sortable = false;
      });
    }
    this.workflow.btnBarModel.set("mode", "E");
  }

  _manageFiltreEnabled(): void {
    const tableColl = this.table.model.coll;

    if (tableColl.nbrecEtatValid === 0) {
      this.workflow.enableFiltering();
    } else {
      this.workflow.disableFiltering();
    }
  }

  _displayHeaderLeftText(): void {
    const context = this.workflow.context;
    if (context && context.ctxGestionCollective === true && context.ctxValidation === true) {
      this.table.model.coll.trigger("display:leftText", "show");
    } else {
      this.table.model.coll.trigger("display:leftText", "hide");
    }
  }

  _displayHeaderRightText(): void {
    const tableColl = this.table.model.coll;

    if (tableColl.nbrecEtatValid === 0) {
      this.table.model.coll.trigger("display:rightText", "hide");
    } else {
      this.table.model.coll.trigger("display:rightText", "show");
    }
  }

  _checkAllRowsActived(action: RowValidationActions): boolean {
    const arrayCheck = $(this.el).find(".checkBoxLine." + action);

    for (let i = 0; i < arrayCheck.length; i++) {
      if ((arrayCheck[i] as any).checked === false) {
        return false;
      }
    }
    return true;
  }

  render(): this {
    $(this.el).html(this.table.el);
    this.table.render();
    if (this.workflow.gridCanBeDisplayed()) {
      this._showGrid();
    } else {
      this._hideGrid();
    }
    this.resetAfterContextChange();
    $(this.el).find(".header.A").attr("title", i18n.t('dabsences.tout_accepter'));
    $(this.el).find(".header.R").attr("title", i18n.t('dabsences.tout_refuser'));

    return this;
  }

  _clickListener(event: JQueryEventObject | any): void {
    const btnClicked = event.currentTarget.value;

    this.model.get("progressBarModel").trigger("btn:click", btnClicked);
  }

  _changeProgressBar(model: any, treated: number): void {
    const maxValue = this.model.get("progressBarModel").get("max");

    if (this.dialogProgressBar === undefined) {
      this.dialogProgressBar = new CWPanneauDeroulant({
        view: CWProgressBarView,
        viewData: {
          appendTo: this.el,
          workflow: this.workflow,
        },
        notIconClose: true,
        notTitle: true,
        closeOnClickOutside: false
      });
    }

    this.dialogProgressBar.setHeight("auto");
    this.dialogProgressBar.setWidth(515);
    if (this.progressBarOpened === false) {
      this.progressBarOpened = true;
      this.dialogProgressBar.model.trigger("dlg:open");
    }
    if (!CWSTR.isBlank(this.dialogProgressBar.getInternalViewObject())) {
      this.dialogProgressBar.getInternalViewObject().trigger('changeProgressBar', treated, maxValue);
    }
  }

  _validationFinnished(): void {

    const tableColl = this.table.model.coll as CWRecapColl;
    tableColl.nbrecEtatValid = 0;
    tableColl.nbrecEtatA = 0;
    tableColl.nbrecEtatR = 0;
    //deselect the header buttons and icons
    //IF SOMEONE knows a better place for this, change it please!!!
    this.table.$el.find(".validation span.btn-validations.header").removeClass("selectionne");
    this.table.$el.find(".validation span.btn-validations.header").addClass("nonSelectionne");
    this.table.$el.find(".btn-commentaire-bulle-header").removeClass("selectionne");
    //launch the popup with the resume
    if (this.workflow && this.workflow.module === "valevt") {
      this.workflow.trigger("updateButtonTextRecap");
    }
    this.table.model.coll.fetch({
      success: (fresh: CWRecapColl) => {
        fresh.trigger("reset");

        this.table.model.coll.trigger("manage:header", "show");
        this.table.model.coll.trigger("updateHeader:coll");
        if (this.workflow && this.workflow.module !== "valevt") {
          this.workflow.trigger("updateButtonTextRecap");
        }
        this.dialogProgressBar._closePanneau(null, null);
        this.progressBarOpened = false;
        this._initPopupResumeView(this.model.nObjErrors);
        this.table.clearSelectedRows();
        this.model.set("globalComment", "");
      },
      silent: true
    });
  }

  _showGridValidationErrors(model: any, errors: { [key: string]: any }): void {

    if (errors && _.has(errors, "errorValidation") && _.has(errors, "errors")) {
      const fieldErrors = errors.errors;
      const comboName = "comboProfil_" + model.get("code");
      const combo = $(this.el).find("." + comboName);
      const tr = combo.closest('tr');
      CWFORMS.showErrors(tr, fieldErrors);
    }
  }

  /**
   * Open detail du recapitulatif
   */
  _detailRecapitulatif(event: JQueryEventObject): void {
    //open gerer recapitulatif d'activite when component is done
    if ($(event.currentTarget).hasClass("recap-link") || !CWSTR.isBlank((event as any).isTrigger)) {
      const currentTarget = event.currentTarget;
      const modelID = $(currentTarget).attr("data-id") || $(currentTarget).find(".recap-link").attr("data-id");
      const recapModel = this.table.model.coll.get(modelID);
      this._openDetailPopup(recapModel);
    }
  }

  private _openDetailPopup(recapModel: CWRecapModel): void {
    if (!CWSTR.isBlank(recapModel)) {
      const recapContext = {} as CWListerecapGeneralContextInterface;
      recapContext.ctxUtilisateur = this.workflow.context.ctxUtilisateur;
      recapContext.ctxHabilitation = {
        HabilitationAcces: {},
        HabilitationGestion: {},
        HabilitationValidation: {},
      };
      //Collaborateur
      if (recapContext.ctxUtilisateur === "Collaborateur") {
        recapContext.ctxHabilitation.HabilitationAcces = {
          absences: "COL_ABS.V",
          recapitulatif: "COL_RECAP.V",
          prevues: "COL_ACT_PREV.V",
          realisees: "COL_ACT_REAL.V",
        };
        recapContext.ctxHabilitation.HabilitationGestion = {
          prevues: "COL_ACT_PREV.G",
          realisees: "COL_ACT_REAL.G",
          absences: "COL_ABS.G",
          recapitulatif: "COL_RECAP_CTRL",
        };
      }
      else {
        recapContext.ctxHabilitation.HabilitationAcces = {
          absences: "RES_ABS.V",
          recapitulatif: "RES_RECAP.V",
          prevues: "RES_ACT_PREV.V",
          realisees: "RES_ACT_REAL.V"
        };
        recapContext.ctxHabilitation.HabilitationGestion = {
          prevues: "RES_ACT_PREV.G",
          realisees: "RES_ACT_REAL.G",
          absences: "RES_ABS.G",
          recapitulatif: "RES_RECAP.G",
        };
      }
      // Collaborateur and Responsable
      if (recapContext.ctxUtilisateur === "Responsable") {
        recapContext.ctxHabilitation.HabilitationValidation = { recapitulatif: "RES_VAL_RECAP.G" };
      }
      else {
        recapContext.ctxHabilitation.HabilitationValidation = "";
      }
      recapContext.ctxEcran = this.workflow.context.ctxEcran;
      recapContext.ctxCollab = {};
      recapContext.ctxCollab.matricule = recapModel.get("collaborateur").matric;
      recapContext.ctxModeRepresentation = "pop-up";
      recapContext.ctxTypeRecap = recapModel.get("recapitulatif");
      recapContext.ctxPeriodeRecap = {
        datedeb: recapModel.get("datedeb"),
        datefin: recapModel.get("datefin"),
      };
      recapContext.ctxFixeRecap = true;
      recapContext.ctxPieceJointe = (CWDIVERS.get("pJointe") === "1");
      this._initPopupGererRecap(recapContext);
      this.recapUpdated = false;
      this.popupGereRecapDialog.open((): void => {
        if (this.recapUpdated) {
          this.workflow.setUp();
          this.workflow.trigger("listeRecapUpdated");
        }
      });
      this.listenTo(this.popupGereRecapDialog.internalView.workflow, "recapUpdated", (): void => {
        this.recapUpdated = true;
      });
    }
  }

  _initPopupGererRecap(context: CWListerecapGeneralContextInterface): CWDialogPopupView {
    let lWidth = 1350, lheight: string | number = 750;

    this.popupGereRecapDialog = null;
    if (screen.width < 1600) {
      lWidth = this.$appendTo.width() - 104;//80;
    }
    if (screen.height < 1024) {
      lheight = this.$appendTo.height() - 50;
    }
    this.popupGereRecapDialog = new CWDialogPopupView({
      view: CWGererRecapGeneralView,
      maxHeight: lheight,
      viewData: {
        context: context,
        appendTo: this.$appendTo
      }
    });
    this.popupGereRecapDialog.setHeight("auto");
    this.popupGereRecapDialog.setWidth(lWidth);
    this.popupGereRecapDialog.model.on("dlg:close", (events: JQueryEventObject): void => {
      const $element = $(events.currentTarget);

      if ($element.dialog("instance")) {
        $element.dialog("destroy");
        $element.remove();
      }
    });
    this.listenToOnce(objs.appRt.workflow, "hidderEnd:" + this.workflow.module, (): void => {
      const heightPrev = this.popupGereRecapDialog.$el.find(".gerer_recap_activite").height();
      let lheight: string | number = 750;
      let heightView: number = null;

      if (this.popupGereRecapDialog?.internalView?.workflow?.modeRepresentationModel && this.popupGereRecapDialog.internalView.workflow.modeRepresentationModel.get("representation") !== "MONO") {
        const $posSplit = this.popupGereRecapDialog.internalView.$el.find(".l-splitB");

        //Dans le mode "MIXTE", on doit cacher le splitter qui ne sera pas utilisé mais il fait augmenter la zone dans la popup(ascenseur horizontal inutile)
        if ($posSplit && $posSplit.length > 0 && !$posSplit.hasClass("d-none")) {
          $posSplit.addClass("d-none");
        }
      }
      if (screen.height < 1024) {
        lheight = this.$appendTo.height() - 50;
      }
      heightView = lheight - 132;
      if (heightPrev >= heightView) {
        this.popupGereRecapDialog.$el.find(".gerer_recap_activite").css({ "height": heightView + "px" });
      }
      this.popupGereRecapDialog.dialog.dialog("option", "position", { my: "center", at: "center", of: this.$appendTo });
    });
    return this.popupGereRecapDialog;
  }

  private tableHeaderCustomClick(event: JQueryEventObject, table: CWDataGridView): void {
    let state = false;

    if ($(event.currentTarget).hasClass("btn-validations")) {
      state = $(event.currentTarget).hasClass("nonSelectionne");
      if (state) {
        table.selectAllRows();
      } else {
        table.clearSelectedRows();
      }
    }
    table.model.trigger("tristate:checked", state);
  }

  _initTable(): CWDataGridView<CWRecapModel, CWRecapColl<CWRecapModel>> {
    ///////////////////////////////////////
    const tableModel = new CWDataGridModel<CWRecapColl, CWRecapModel>({ coll: new CWRecapColl() });
    let tblColumnsDetaille: Array<CWBaseGridColums> = [];
    let appendClassToRow: string = "";
    let sortableColumns: string[] = [];

    this.listenTo(tableModel.coll, "sync", (): void => {
      tableModel.coll.forEach((model: CWRecapModel): void => {
        model.off("invalid");
        model.on("invalid", this._showGridValidationErrors, this);
        if (this.managingVueChange === true) {
          this.managingVueChange = false;
        }
      });
    });
    if (this.context.ctxGestionCollective === true && this.context.ctxValidation === true) {
      tblColumnsDetaille = [
        { title: i18n.t('common:listerecap.grid_collaborateur'), property: "collaborateur", width: 3 },
        { title: i18n.t('common:listerecap.grid_recapitulatif'), property: "recapitulatif", width: 3 },
        { title: i18n.t('common:listerecap.grid_periode'), property: "periode", width: 3 },
        { title: i18n.t('common:listerecap.grid_nbevenement'), property: "nbevenement", width: 1 },
        {
          title: "<div class='validation offset-4 col-8'><div class='validationsContainer'>" +
            UTILS.getSVGIcon('valider', 'btn btn-round btn-validations header A nonSelectionne', null, null, true) +
            UTILS.getSVGIcon('croix', 'btn btn-round btn-validations header R nonSelectionne', null, null, true) + "</div><div class='comment cw-comment-icon'>" +
            UTILS.getSVGIcon('bulle', 'cw-icon btn-commentaire-bulle-header C', 20, null, true) + "</div></div>", property: "icons", width: 2
        }
      ];
      appendClassToRow = "hasValidationButtons";
      sortableColumns = ["recapitulatif", "collaborateur", "periode"];
    } else {
      tblColumnsDetaille = [
        { title: i18n.t('common:listerecap.grid_recapitulatif'), property: "recapitulatif", width: 3 },
        { title: i18n.t('common:listerecap.grid_periode'), property: "periode", width: 5 },
        { title: i18n.t('common:listerecap.grid_nbevenement'), property: "nbevenement", width: 1 },
        { title: i18n.t('common:listeractivites.statut'), property: "statut", width: 3 }
      ];
      appendClassToRow = "hasValidationButtons notGestColl";
      // Configure sorting
      sortableColumns = ["recapitulatif", "periode"];
    }

    const vues: { [key: string]: any } = {};
    vues[i18n.t('common:listerecap.vue_detaille')] = { columns: tblColumnsDetaille };
    let itemPerPage: number;
    if (this.context.ctxGestionCollective) {
      itemPerPage = parseInt(GLOBAL_DATA.paramDivers.get("PgNbValRA").get("valeur"), 10);
    } else {
      itemPerPage = parseInt(GLOBAL_DATA.paramDivers.get("PgNbLstRA").get("valeur"), 10);
    }
    const emptyMessage = this.context.ctxValidation ? i18n.t('messages:GT_2034') : i18n.t('messages:GT_2033');
    const table = new CWDataGridView<CWRecapModel, CWRecapColl<CWRecapModel>>({
      id: "listerecap_table",
      columns: tblColumnsDetaille,
      model: tableModel,
      showFilteredRowsInTitle: true,
      gridHeightType: "auto",
      emptyMessage: emptyMessage,
      selectInSorting: this.selectInSorting,
      itemsPerPage: itemPerPage,
      title: " ",
      disableAlternateRowBackground: true,
      habilitations: "RES_VAL_RECAP.G",
      paginatorPosition: "butom",
      multiselection: true,
      hideMultiSelectionCheckBox: true,
      appendClassToRow: appendClassToRow,
      customHeaderClick: this.tableHeaderCustomClick,
      sortingUseAllColumn: true,
      defaultSortingOrder: ['datedeb', 'nom', 'prenom'],
      resizable: false,
      isNotSelectableRow: true
    });

    table.setColumnsAlignment({
      "icons": "right"
    });
    table._manageSortingOld = table._manageSorting;
    table._manageSorting = (columnCode: string): void => {
      const oldCollection = this.table.model.coll.clone();
      if (columnCode === "role") {
        this.table.model.coll.comparator = (model1: any, model2: any): number => {
          if (this.isSortedAsc === false) {
            const auxModel = model1;
            model1 = model2;
            model2 = auxModel;
          }
          const libelle1 = model1.selectedProfil ? model1.selectedProfil.libelle : i18n.t('common:listerecap.renseigner');
          const libelle2 = model2.selectedProfil ? model2.selectedProfil.libelle : i18n.t('common:listerecap.renseigner');

          if (libelle1 < libelle2) {
            return -1; // model1 before model2
          } else if (libelle1 === libelle2) {
            return 0; // model1 equal model2
          } else {
            return 1; // model2 before model1
          }
        };
        this.isSortedAsc = !this.isSortedAsc;
        this.table.model.coll.sort();
        this.table.model.coll.trigger("reset");
        this._revertTable();
      } else if (columnCode !== "a" && columnCode !== "r") {
        table._manageSortingOld(columnCode, (result: any) => {
          if (this.table.model.coll.nbrecEtatValid === 0) {
            this._revertTable();
            this._setOldProfils(result, oldCollection);
            this.table.model.coll.trigger("reset");
            this.table.$el.find(".validation span.btn-validations").removeClass("selectionne");
            this.table.$el.find(".validation span.btn-validations").addClass("nonSelectionne");
            this.table.$el.find(".validation span.btn-validations.header").removeClass("selectionne");
            this.table.$el.find(".validation span.btn-validations.header").addClass("nonSelectionne");
          }

        }, true, null);
      }
    };

    table.cellRenderers["collaborateur"] = (model: { [key: string]: any }): JQuery | string => {
      const paramIdCollab = this.workflow.pDiversIdCollabModel.get("valeur");
      const matricule = paramIdCollab === "matric_aux" ? model.get("collaborateur").matric_aux : model.get("collaborateur").matric;
      const ligne1 = model.get("collaborateur").nom.toUpperCase() + " " + model.get("collaborateur").prenom + " (" + matricule + ")";
      const error = this._findError(model.get("code"));
      const classLabel = this.model.objErrors.length > 0 && !CWSTR.isBlank(error) ? "erreur-validation important" : "ui-phx-ihm-texte-donnees-important";
      if (CWHABILITATION.canView("RES_GCOLLAB.G") && CWHABILITATION.canView("RES_FICHE.V")) {
        const url = !CWSTR.isBlank(model.get('datedeb')) ? "suivicollab/" + model.get("collaborateur").matric + "/" + model.get('datedeb') : "suivicollab/" + model.get("matricule");
        return "<a class='" + classLabel + " suivicollab-link " + url + "'>" + ligne1 + "</a>";
      } else {
        return "<span>" + ligne1 + "</span>";
      }
    };


    table.cellRenderers["recapitulatif"] = (model: { [key: string]: any }, column: string, cell: CWDataGridCellView): JQuery | string => {
      let ligneError = "";
      let classLink = "";
      if (this.context.ctxEcran === "suivicollab") {
        if (model.get("statut").code === "H") {
          cell.$el.addClass("noEtiquette");
        } else {
          cell.$el.addClass("hasEtiquette");
        }
      }

      if (this.hideLinkGererRecap !== true) {
        classLink = "recap-link";
      }
      const ligne1 = "<a data-id='" + model.id + "' class='" + classLink + "'>" + model.get("libelle") + "</a>";
      const result = $('<span>').html(ligne1);

      //errors
      ligneError = "";
      if (this.model.objErrors.length > 0) {
        const error = this._findError(model.get("code"));
        if (!CWSTR.isBlank(error)) {
          ligneError = this._buildMessageErrorRec(error);
        }
      }
      if (!CWSTR.isBlank(ligneError)) {
        // recapitulatif = recapitulatif + " <br>" + ligneError;
        result.append(ligneError);
      }

      return result;
    };

    table.cellRenderers["periode"] = (model: { [key: string]: any }, column: string, cell: CWDataGridCellView): JQuery | string => {
      let ligneError = "";
      let classLink = "";
      if (this.context.ctxEcran === "suivicollab") {
        if (model.get("statut").code === "H") {
          cell.$el.addClass("noEtiquette");
        } else {
          cell.$el.addClass("hasEtiquette");
        }
      }

      if (this.hideLinkGererRecap !== true) {
        classLink = "recap-link";
      }
      const periode = i18n.t('common:listerecap.du') + " " + CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE_A")) + " " + i18n.t('common:listerecap.au') + " " + CWTYPE.DATE.format(model.get("datefin"), CWTYPE._getFormatByCode("DATE_A")) + " ";
      const ligne1 = "<a data-id='" + model.id + "' class='" + classLink + "'>" + periode + "</a>";
      const result = $('<span>').html(ligne1);
      if (this.model.objErrors.length > 0) {
        const error = this._findError(model.get("code"));
        if (!CWSTR.isBlank(error)) {
          ligneError = this._buildMessageErrorPer(error);
        }
      }
      if (model.get("etat") === "X") {
        result.append($("<span class='cw-texteDemandeSuppression inline-info'>" + i18n.t('common:listerecap.demande_sup') + "</span>"));
      }

      if (model.get("piecesjointes")) {
        const indicateurpj = model.get("indicateurpj");
        if (indicateurpj) {
          const icon = $("<span>");
          icon.addClass("piece-jointe phx-grid-not-selectable-cell inline-info");
          icon.append(UTILS.getSVGIcon('trombone', 'button cw-icon-rot'));
          icon.attr({ "title": i18n.t('common:listerecap.grid_pj_title'), "data-code": model.get("code") });
          icon.append("<div class='pj-menu-container-" + model.get("evenement") + "'></div");
          result.append(String(icon[0].outerHTML));
        }
      }

      if (!CWSTR.isBlank(ligneError)) {
        // periode = periode + " <br>" + ligneError;
        const iconHelp = $("<div class='inline-info'>");
        iconHelp.attr("title", ligneError);
        iconHelp.append(UTILS.getSVGIcon('aide_bulle', 'cw-icon'));
        result.append(iconHelp);
      }
      return result;

    };

    table.cellRenderers["nbevenement"] = (model: { [key: string]: any }, column: string, cell: CWDataGridCellView): JQuery | string => {
      if (this.context.ctxEcran === "suivicollab") {
        if (model.get("statut").code === "H") {
          cell.$el.addClass("noEtiquette");
        } else {
          cell.$el.addClass("hasEtiquette");
        }
      }

      const span = $("<span>");
      span.attr({ "title": i18n.t('common:listerecap.grid_nbevenement_title') });
      span.text(model.get("nbevenement"));

      return span;
    };

    table.cellRenderers["statut"] = (model: CWBaseModel): JQuery | string => {
      let type: validEtiquetteTypes;
      let text: string = model.get("statut").libelle;
      let subText: string = "";
      switch (model.get("statut").code) {
        case "D":
        case "I": {
          type = "demande";
          break;
        } case "E": {
          type = "custom";
          break;
        } case "A": {
          type = "accepte";
          break;
        } case "H": {
          type = null;
          break;
        }
        case "F":
        case "M":
        case "G":
        case "C": {
          type = "revision";
          break;
        }
        case "Y":
        case "Z": {
          type = "refuse";
          break;
        }
        default: {
          break;
        }
      }

      if (model.get('statut').libelle.indexOf('(') !== -1) {
        const split = model.get('statut').libelle.split('(');
        text = split[0];
        subText = split[1].split(')')[0];
      }

      const divStatut = $("<div class='d-flex flex-row justify-content-center'></div>");
      if (!CWSTR.isBlank(type)) {
        const etiquetteStatut = new CWEtiquette({
          text: {
            enabled: text
          },
          subText: {
            enabled: subText
          },
          state: "actif",
          type: type,
          id: model.get("code") + "_etiquette"
        });
        divStatut.append(etiquetteStatut.render().el);
      }

      return divStatut;

    };

    table.cellRenderers["icons"] = (model: CWRecapModel): JQuery => {

      const iconValidation = $("<div class='validation offset-4 col-8'>");
      const iconComment = $("<div class='comment cw-comment-icon'>");
      const divButtons = $("<div class='validationsContainer'>");
      const divComment = $("<div class='commentContainer'>");
      const selectAccepter = model.recEtatValid === "A" ? ' selectionne' : ' nonSelectionne';
      const selectRefuser = model.recEtatValid === "R" ? ' selectionne' : ' nonSelectionne';
      let code = model.get("code").includes("/") === true ? model.get("code").replace(/\//g, '_') : model.get("code");
      code = model.get("code").includes("+") === true ? model.get("code").replace(/\+/g, '-') : code;

      //In the other cases we use the code, but in activite case we should use the evenementCode to respect the other screens
      //for now at least
      /*let evenement = model.get("evenement").includes("/") === true ? model.get("evenement").replace(/\//g, '_') : model.get("evenement");
      evenement = model.get("evenement").includes("+") === true ? model.get("evenement").replace(/\+/g, '-') : evenement;*/

      divButtons.append(UTILS.getSVGIcon('valider', 'btn btn-round btn-validations A ' + code + selectAccepter, null, null, true));
      //Add the particular case of the asterisk
      if (model.get("statut").code === "I") {
        divButtons.append($("<span class='btn-validations-asterisk'>*</span>"));
      }
      divButtons.append(UTILS.getSVGIcon('croix', 'btn btn-round btn-validations R ' + code + selectRefuser, null, null, true));
      divComment.append(UTILS.getSVGIcon('bulle', 'cw-icon btn-commentaire-bulle C ' + code /*+ selectRefuser*/, 20, null, true));
      divButtons.find(".A").attr("title", i18n.t('common:listerecap.accepter'));
      divButtons.find(".R").attr("title", i18n.t('common:listerecap.refuser'));
      divComment.find(".C").attr("title", i18n.t('common:listerecap.comment_title'));

      const div = $("<div>");
      div.append(iconValidation.append(divButtons).append(iconComment.append(divComment)));
      return div;
    };

    table.setSortableColumns(sortableColumns);
    table.setSortableCustomNames({
      "collaborateur": ["nom", "prenom"],
      "profils": "profil",
      "periode": ["datedeb"],
      "recapitulatif": "libelle",
    });
    return table;

  }

  selectMessageEtat(etat: string, code: string): string {
    switch (etat) {
      case "E":
        if (code === "REL_S") {
          return i18n.t('messages:GL_1066');
        } else {
          return i18n.t('messages:GL_1058');
        }
      case "D":
        if (code === "REL_V") {
          return i18n.t('messages:GL_1065');
        } else {
          return i18n.t('messages:GL_1059');
        }
      case "H":
        return i18n.t('messages:GL_1060');
      case "C":
        return i18n.t('messages:GL_1014');
      case "A":
        return i18n.t('messages:GL_1020');
      case "I":
        if (code === "REL_V") {
          return i18n.t('messages:GL_1065');
        } else {
          return i18n.t('messages:GL_1018');
        }
      case "Z":
        if (code === "REL_S") {
          return i18n.t('messages:GL_1066');
        } else {
          return i18n.t('messages:GL_1061');
        }
      case "Y":
        return i18n.t('messages:GL_1062');
      case "M":
        if (code === "DEM_MOD") {
          return i18n.t('messages:GL_1063');
        } else {
          return i18n.t('messages:GL_1014');
        }
      case "G":
      case "F":
        return i18n.t('messages:GL_1064');
      default:
        return "";
    }

  }
  newRecap(buttonId: string): void {
    if (buttonId === "newRecap") {
      const context = {} as CWListerecapGeneralContextInterface;

      context.ctxUtilisateur = "Responsable";
      context.ctxHabilitation = {
        HabilitationAcces: "RES_RECAP.V",
        HabilitationGestion: "RES_RECAP.G",
        HabilitationValidation: "RES_VAL_RECAP.G"
      }
      context.ctxEcran = "suivicollab";
      context.ctxCollab = {
        matricule: this.workflow.context.ctxCollab.matricule
      };
      context.ctxModeRepresentation = "Par date";
      context.ctxPieceJointe = (CWDIVERS.get("pJointe") === "1");
      if (this.popupGereRecapDialog === undefined) {
        this.popupGereRecapDialog = new CWDialogPopupView({
          view: CWGererRecapGeneralView,
          viewData: {
            context: context,
            appendTo: this.$appendTo
          }
        });
      } else {
        this.popupGereRecapDialog.viewData = { context: context };
      }
      this.popupGereRecapDialog.setHeight(800);
      this.popupGereRecapDialog.setWidth(1350);
      this.recapUpdated = false;
      this.popupGereRecapDialog.open((): void => {
        if (this.recapUpdated) {
          const recapactivContext = this._buildContext();
          this.recap.setContext(recapactivContext);
          this.parent.workflow._updateDmaj();
        }
      });
      this.listenTo(this.popupGereRecapDialog.internalView.workflow, "recapUpdated", this._setRecapUpdated);
    }
  }

  _setRecapUpdated(): void {
    this.recapUpdated = true;
  }

  _buildContext(): CWListerecapGeneralContextInterface {
    const recapactivContext = {} as CWListerecapGeneralContextInterface;

    recapactivContext.ctxEcran = "suivicollab";
    recapactivContext.ctxUtilisateur = "Responsable";
    recapactivContext.ctxHabilitation = {};
    recapactivContext.ctxHabilitation.HabilitationAcces = "RES_RECAP.V";
    recapactivContext.ctxHabilitation.HabilitationValidation = "RES_VAL_RECAP.G";
    recapactivContext.ctxCollab = {};
    recapactivContext.ctxCollab.matricule = this.parent.workflow.formModel.get("matricule");
    recapactivContext.ctxCollab.matriculeAux = this.parent.workflow.formModel.get("matricaux");
    recapactivContext.ctxCollab.nom = this.parent.workflow.formModel.get("nom");
    recapactivContext.ctxCollab.prenom = this.parent.workflow.formModel.get("prenom");
    recapactivContext.ctxGestionCollective = false;
    recapactivContext.ctxValidation = true;
    recapactivContext.ctxValideur = false;
    recapactivContext.ctxFiltreApplique = ["E", "F", "M", "Y", "C", "G", "Z", "A", "D", "H", "I"];
    recapactivContext.ctxPeriode = {
      datedeb: "",
      datefin: ""
    };
    recapactivContext.ctxTriDate = "Descendant";
    return recapactivContext;
  }

  /**
   * Returns the recap activite historique detail of this evenement.
   */
  _getHistoObj(even: JQueryEventObject): { [key: string]: any } | null {
    let obj = null;
    _.each(this.historiqueModel.attributes, (attr: { [key: string]: any }): any => {
      if (attr !== null && even === attr.evenement) {
        obj = attr;
      }
    });
    return obj;
  }

  _manageWKFHisto(model: { [key: string]: any }): string {
    //use new composant Historique recapitulatif du workflow
    const typeLabel = "ACTRECAP";
    const contextComponentWkf = {
      ctxEcran: this.workflow.context.ctxEcran,
      ctxHabilitation: this.workflow.context.ctxHabilitation.HabilitationAcces,
      ctxTypeEvenement: typeLabel,
      ctxRefEvenement: model.get("evenement"),
      ctxDemId: null as string,
      ctxModeRestitution: "Infobulle",
      ctxActivModeSynth: true,
      ctxModeDefaut: "",
      ctxModeRech: "false"
    };

    const composantWorkflow = new CWBlockView({ context: contextComponentWkf });
    $(this.el).find(".listerecap-wkfhisto").html(composantWorkflow.render().el);
    composantWorkflow.setContext(contextComponentWkf);
    return composantWorkflow.$el.html();
  }

  /**
   *  Manages the change of the vue for  table, in order to get historiquedata
   */
  _vueChanged(): void {

    this._historique((): void => {
      this.table.model.coll.trigger("reset");
      this.table.model.coll.trigger("updateHeader:coll");
      this.managingVueChange = true;
    });
  }

  _copyModels(coll: CWPaginatedCollection): Array<CWBaseModel> {
    const modelArray: Array<CWBaseModel> = [];
    _.each(coll.models, (model): void => {
      modelArray.push(model);
    });
    return modelArray;
  }

  _updateCheckAndCommentsModels(): void {

    _.each(this.table.model.coll.models, (model: CWRecapModel): void => {
      _.each(this.copyModelsState, (copyModel): void => {
        if (copyModel.get("id") === model.get("id")) {
          model.recEtatValid = copyModel.recEtatValid;
          model.nbrecEtatValid = copyModel.nbrecEtatValid;
          model.set("commentaire", copyModel.get("commentaire"), { silent: true });
        }
      });
    });
    this.managingVueChange = false;
  }

  _historique(callback: () => void): void {
    const params: Array<string> = [];
    _.each(this.table.model.coll.models, (item: CWBaseModel): void => {
      params.push(item.get("evenement"));
    }, this);

    this.wkfEvenements = params;

    this.historiqueModel = new CWListerHistoriqueModel();
    this.historiqueModel.setHabContext(this.workflow.getHabContext());
    this.historiqueModel.set("evenements", this.wkfEvenements);
    //		this.historiqueModel.applyFilter({evenements : this.wkfEvenements});
    this.historiqueModel.save(null, {
      success: (): void => {
        if (callback) {
          callback();
        }
      },
      error: (): void => {
        if (callback) {
          callback();
        }
      }
    });
  }

  /**
   * Gets the table title depending on ctxValideur
   */
  _getTitle(): string {
    if (this.context && this.context.ctxValideur === true) {
      return i18n.t('common:listerecap.grid_title_a_valider');
    } else {
      return i18n.t('common:listerecap.grid_title');
    }
  }

  /**
   * Manage pieces jointes
   */
  _managePieceJointe(event: JQueryEventObject): boolean {
    const maxPieces = !CWSTR.isBlank(this.workflow.maxPieces) ? this.workflow.maxPieces.get("maxi") : 0;
    const modelCode = $(event.currentTarget).attr("data-code");
    const model = _.find(this.table.model.coll.models, (mod: CWRecapModel): boolean => {
      return mod.get("code") === modelCode;
    });
    if (model.get("piecesjointes").length === 1) {
      const code = model.get("piecesjointes")[0].code;
      window.open(Configuration.restRoot + "/rest/fichier/download?id=" + encodeURIComponent(code) + "&x_token_key=" + encodeURIComponent(Configuration.x_token_key), "_blank");
    } else if (model.get("piecesjointes").length > 1) {

      if (this.pjPopupView) {
        this.pjPopupView.internalView.setPJ(model.get("piecesjointes"), "");
      } else {

        const context: { [key: string]: any } = {};
        context.ctxRefEvenement = model.get("evenement");
        context.ctxHabilitation = {};

        if (_.isObject(context.ctxHabilitation.HabilitationAcces)) {
          context.ctxHabilitation.HabilitationAcces = this.context.ctxHabilitation.HabilitationAcces.absences;
        } else {
          context.ctxHabilitation.HabilitationAcces = this.context.ctxHabilitation.HabilitationAcces;
        }

        if (_.isObject(context.ctxHabilitation.HabilitationGestion)) {
          context.ctxHabilitation.HabilitationGestion = this.context.ctxHabilitation.HabilitationValidation.absences;
        } else {
          context.ctxHabilitation.HabilitationGestion = this.context.ctxHabilitation.HabilitationValidation;
        }

        context.ctxEcran = this.context.ctxEcran;
        context.ctxGestionCollab = {};
        context.ctxGestionCollab.nom = model.get("collaborateur").nom;
        context.ctxGestionCollab.prenom = model.get("collaborateur").prenom;
        context.ctxGestionCollab.matricule = model.get("collaborateur").matric;
        context.ctxGestionCollab.matricAux = model.get("collaborateur").matricAux;

        context.ctxActionsPossibles = [];
        context.ctxModeInitialisation = "Consultation";

        this.pjPopupView = this._initPJPopupView(context, model.get("piecesjointes"), "", maxPieces);
        this.pjPopupView.open((): void => {
          this.pjPopupView = null;
        });
      }
    }
    return false;
  }

  private _getRecapActiviteModel(id: string): CWRecapModel {
    id = id.replace(/_/g, '/');
    id = id.replace(/-/g, '+');
    const model = this.table.model.coll.get(id) as CWRecapModel;
    id = id.replace(/\//g, '_');
    id = id.replace(/\+/g, '-');
    return model;
  }

  _formatedParametres(model: CWRecapModel): { datedeb: JQuery; datefin: JQuery; competence: JQuery } {
    const objResponse = {} as { datedeb: JQuery; datefin: JQuery; competence: JQuery };

    objResponse.datedeb = this._addClassToText(CWTYPE.DATE.format(model.get("datedeb"), CWTYPE._getFormatByCode("DATE_A")), "phx-bold-label-style");
    objResponse.datefin = this._addClassToText(CWTYPE.DATE.format(model.get("datefin"), CWTYPE._getFormatByCode("DATE_A")), "phx-bold-label-style");
    const competence = model.get("libelle") + " (" + model.get("code") + ")";
    objResponse.competence = this._addClassToText(competence, "phx-bold-label-style");

    return objResponse;
  }

  _addClassToText(text: string, objClass: string): JQuery {
    return $("<span>").text(text).addClass(objClass);
  }

  /**
     * When we click the comment (bulle) icon at each ROW
     */
  _commentairePopUp(event: JQueryEventObject): void {
    const id = event.currentTarget.className.split(" ")[3];
    let model = this.table.model.coll.get(id) as CWRecapModel;
    const action = event.currentTarget.className.split(" ")[2];

    // needed for correction when they send
    // wrong character "/" in code. (WS)

    if (CWSTR.isBlank(model)) {
      //customer 168040 '/' character can be in all places of the string
      model = this._getRecapActiviteModel(id);
    }

    const comment = !CWSTR.isBlank(model.get("commentaire")) ? model.get("commentaire") : "";
    const refEvenement = model.get("evenement");
    const recapEtatValid = model.recEtatValid;

    if (this.headerDialog !== undefined) {
      this.headerDialog._closePanneau();
    }
    if (this.rowDialog === undefined) {
      this.rowDialog = new CWPanneauDeroulant({
        fixedButtons: true,
        maxHeight: 360,
        view: CWRowPopupView,
        viewData: {
          appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
          commentaire: comment,
          refEvenement: refEvenement,
          recapEtat: recapEtatValid,
          action: action,
          workflow: this.workflow
        },
        notIconClose: false,
        closeOnClickOutside: true
      });
    } else {
      this.rowDialog.viewData = {
        appendTo: this.el, //"autre posibilité -> #phx-zone-coll",
        commentaire: comment,
        refEvenement: refEvenement,
        workflow: this.workflow,
        recapEtat: recapEtatValid,
        action: action,
      };
    }

    this.rowDialog.setPosition({
      my: "right top",
      at: "right bottom",
      of: $(event.currentTarget)
    });

    // set buttons
    const btn = [{
      text: i18n.t('common:listerecap.confirmer'),
      btnClass: 'btn btn-primary btn-withIcon bt-col-blue',
      icon: UTILS.getSVGIcon("valider", "c-panneauMenu__tickIcon", 16, null),
      click: (): void => {
        this.rowDialog.getInternalViewObject().trigger("btnResult", "btnValider");
      }
    }, {
      text: i18n.t('common:listerecap.annuler'),
      btnClass: 'btn btn-secondary btn-withIcon',
      icon: UTILS.getSVGIcon("croix", "", 16, null),
      click: (): void => {
        this.rowDialog.getInternalViewObject().trigger("btnResult", "btnRevert");
      }
    }];

    this.rowDialog.setButtons(btn);

    this.rowDialog.setHeight(360);
    this.rowDialog.setWidth("auto");

    const titlePopup = this.setPopupTitle(model, action);
    this.rowDialog._setTitle(titlePopup)

    this.rowDialog.model.trigger("dlg:open", (): void => {
      if (this.rowDialog.model.get("isCommentChanged") === true) {
        const commentaire = this.rowDialog.model.get("commentaire");
        model.set("commentaire", commentaire, { silent: true });
        if (CWSTR.isBlank(commentaire)) {
          this.$el.find(".btn-commentaire-bulle." + id).removeClass("selectionne");
          model.individualComment = false;
        } else {
          this.$el.find(".btn-commentaire-bulle." + id).addClass("selectionne");
          model.individualComment = true;
        }
      }
    });

    event.stopPropagation();
  }

  /**
    * JMBS: Function to decide the title of the panneu de roulant of commentaires
    * @param model the model of the evenement (change the model of the TAB (absence, regul...))
    * @param action the action in which depends the title of the popup
    */
  private setPopupTitle(model: CWRecapModel, action: string): string {
    let titlePopup = "";
    if (model.get("etat") === "X") { //Demande de supression
      if (action === "A") {
        titlePopup = i18n.t('valevt.accepter_supress_title_popup').toUpperCase();
      }
      else if (action === "R") {
        titlePopup = i18n.t('valevt.refuser_supress_title_popup').toUpperCase();
      } else {
        if (model.recEtatValid === "A") {
          titlePopup = i18n.t('valevt.accepter_supress_title_popup').toUpperCase();
        } else if (model.recEtatValid === "R") {
          titlePopup = i18n.t('valevt.refuser_supress_title_popup').toUpperCase();
        } else {
          titlePopup = i18n.t('valevt.comentaire_title_popup').toUpperCase();
        }
      }
    }
    else { //Demande normal
      if (action === "A") {
        titlePopup = i18n.t('valevt.accepter_title_popup').toUpperCase();
      }
      else if (action === "R") {
        titlePopup = i18n.t('valevt.refuser_title_popup').toUpperCase();

      } else {//comment icon
        if (model.recEtatValid === "A") {
          titlePopup = i18n.t('valevt.accepter_title_popup').toUpperCase();
        } else if (model.recEtatValid === "R") {
          titlePopup = i18n.t('valevt.refuser_title_popup').toUpperCase();
        } else {
          titlePopup = i18n.t('valevt.comentaire_title_popup').toUpperCase();
        }
      }
    }
    return titlePopup;
  }

  _initPJPopupView(context: { [key: string]: any }, list: CWPaginatedCollection, description: string, maxpieces: number): CWDialogPopupView {
    const dialog = new CWDialogPopupView({
      view: CWGererPiecesJointesView,
      viewData: {
        context: context,
        coll: list,
        description: description,
        modal: true,
        maxPieces: maxpieces,
        appendTo: this.$appendTo
      }
    });
    dialog.setHeight("auto");
    dialog.setWidth(600);
    return dialog;
  }

  /**
    * When suivi link is pressed navigate to suivi collaborateurs
    */
  _manageSuiviLink(event: JQueryEventObject): void {
    const target = event.target;

    if ($(event.currentTarget).hasClass("suivicollab-link")) {
      const ecran = target.className.split(" ")[2];
      NAV.navigate("resp", ecran, "uc", true, true);
    } else {
      if (!CWSTR.isBlank((event as any).isTrigger)) {
        const ecran = $(event.currentTarget).find(".suivicollab-link")[0].className.split(" ")[2];
        NAV.navigate("resp", ecran, "uc", true, true);
      }
    }


  }

  _findError(objCode: string): { [key: string]: any } | null {
    let res: { [key: string]: any } = null;
    _.each(this.model.objErrors, (obj: { [key: string]: any }): void => {
      if (obj.code === objCode) {
        res = obj;
      }
    });

    return res;
  }

  _cleanErrorsTable(): void {
    this.table.$el.find(".griderror").remove();
    this.table.$el.find(".ui-state-error").removeClass("ui-state-error");
    this.workflow._checkProfils();
  }

  _buildMessageErrorRec(objError: { [key: string]: any }): string {
    const styleError = "ui-phx-msg-erreur-validation";
    let htmlError = "";
    if (objError.type === "A") {
      htmlError += i18n.t('messages:GT_1370') + ":";
    } else if (objError.type === "R") {
      htmlError += i18n.t('messages:GT_1371') + ":";
    }

    htmlError = this._textToStyle(htmlError, styleError);

    return htmlError;
  }

  _buildMessageErrorPer(objError: { [key: string]: any }): string {
    const styleError = "ui-phx-msg-erreur-validation";
    let htmlError = "";
    if (CWSTR.isBlank(objError.message)) {
      htmlError += i18n.t('messages:GT_1224');
    } else {
      htmlError += objError.message;
    }
    htmlError = this._textToStyle(htmlError, styleError);
    return htmlError;
  }

  _textToStyle(txt: string, style: string): string {
    return "<span class='" + style + "'>" + txt + "</span>";
  }

  _initPopupResumeView(data: { [key: string]: any }): void {
    if (this.resumeDialog === undefined) {
      this.resumeDialog = new CWDialogPopupView({
        popupType: CWDialogPopupType.INFORMATION,
        view: CWResumePopUpView,
        viewData: {
          data: data,
          appendTo: this.$appendTo || this.el
        }
      });
    } else {
      this.resumeDialog.viewData = { data: data, appendTo: this.el, };
    }

    // set buttons
    const btn = [{
      text: i18n.t('common:close'),
      btnClass: 'btn btn-primary btn-withIcon',
      icon: UTILS.getSVGIcon("croix", "", 16, null),
      click: (): void => {
        $(this.resumeDialog.dialog).dialog("close");
      }
    }];

    this.resumeDialog.setHeight("auto");
    this.resumeDialog.setWidth(620);
    this.resumeDialog.setButtons(btn);

    this.resumeDialog.model.trigger("dlg:open", (): void => {
      // Close Callback
      CWLOG.debug("callback from dialog view");
    });
    this.resumeDialog._setTitle(i18n.t('dregularisations.resumePopUp_title'));
    /*
        if (this.resumeDialog === undefined) {
          this.resumeDialog = new CWDialogPopupView({
            popupType: CWDialogPopupType.INFORMATION,
            view: CWResumePopUpView,
            viewData: {
              data: data,
              appendTo: this.$appendTo
            }
          });
        } else {
          this.resumeDialog.viewData = { data: data };
        }
        this.resumeDialog.setHeight(150);
        this.resumeDialog.setWidth(620);

        this.resumeDialog.model.trigger("dlg:open", (): void => {
          // Close Callback
          LOG.debug("callback from dialog view");
        });
        this.resumeDialog.model.trigger("dlg:open", (): void => {
          // Close Callback
          LOG.debug("callback from dialog view");
        });
        this.resumeDialog._setTitle(i18n.t('common:listerecap.resumePopUp_title'));*/
  }

  private tableRowChecked(numRows: number): void {
    this.table.model.trigger("paginator", (numRows > 0 ? "hide" : "show"));
    this.workflow.trigger("tableRowChecked", numRows);
    this.workflow.filterModel.trigger(numRows > 0 ? "disableFilter" : "enableFilter");
  }

  private _showGrid(): void {
    $(this.el).show();
  }

  private _hideGrid(): void {
    $(this.el).hide();
  }

  _setOldProfils(coll: any, oldColl: any): void {
    _.each(coll.models, (model: CWRecapModel) => {
      if (model.get("profils").length > 1 && !CWSTR.isBlank(oldColl)) {
        const oldModel = oldColl.get(model.id);
        if (!CWSTR.isBlank(oldModel) && !CWSTR.isBlank(oldModel.selectedProfil)) {
          model.selectedProfil = oldModel.selectedProfil;
        }
      } else {
        model.selectedProfil = model.get("profils")[0];
      }
    });
  }

  /**
   * Here we check if we have any refuse action
   */
  private checkRefuseEvenement(): boolean {

    let checkComment = false;
    this.table.model.coll.each((itemRecap: CWRecapModel): boolean => {
      if (itemRecap.recEtatValid === "R") {
        checkComment = true;
        return true;
      }
      return false;
    });
    return checkComment;
  }

  private refreshElements(model: CWRecapModel): void {
    //Set number of elements after remove model
    let nbrecEtatA = this.table.model.coll.nbrecEtatA;
    let nbrecEtatR = this.table.model.coll.nbrecEtatR;
    if (model.recEtatValid === "A") {
      nbrecEtatA--;
      this.table.model.coll.nbrecEtatA = nbrecEtatA;
    } else {
      nbrecEtatR--;
      this.table.model.coll.nbrecEtatR = nbrecEtatR;
    }
    this.table.model.coll.nbrecEtatValid--;
  }

  private removeGlobalComment(newCommentaire: string, action: string, isForced: boolean): void {

    this._manageComments(newCommentaire, isForced);
    this.model.set("globalComment", newCommentaire);
    this.model.set("isForced", isForced);

    if (CWSTR.isBlank(newCommentaire) || newCommentaire === null) {
      this.$el.find(".btn-commentaire-bulle-header").removeClass("selectionne");
    } else {
      this.$el.find(".btn-commentaire-bulle-header").addClass("selectionne");
    }
  }
}
