import _ from 'underscore';
import TPLListerecapFilter from '../cwFilter.tpl.html';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'src/core/components/combo/cwCombos.collection';
import { CWListerecapGeneralContextInterface } from './cwListerecapGeneral.view';
import { CWListerecapWorkflow } from '../models/cwListerecap.workflow';
import { CWListerecpFilterModel } from '../models/cwListerecapFilter.model';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'src/utils';


export class CWListerecapFilterContentView extends CWBaseFormView<CWListerecpFilterModel> {

  context: CWListerecapGeneralContextInterface;
  workflow: CWListerecapWorkflow;
  comboRecapLibelle: CWComboBoxView2;
  model: CWListerecpFilterModel;

  private iconLoupe: string;
  comboSearchProfils: CWComboBoxView2;
  private motifComboPlaceholder: {
    code: string;
    libelle: any;
  };

  /**
   * Constructor
   * FilterView for this usecase
   */
  constructor(options?: { [key: string]: any }) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_changeModel",
      'click .loupe': "_search",
      'change .profil': '_changeProfil',
    }, CWTYPE.LONG.events(), options.events);

    super(options);
    this.template = TPLListerecapFilter;
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }
    if (options && options.context) {
      this.context = options.context;
    }
    this.model = new CWListerecpFilterModel();
    this._initCombo();

    this.iconLoupe = UTILS.getSVGIcon('loupe');
  }

  _getModel(): CWListerecpFilterModel {
    return this.model;
  }

  _initCombo(): void {
    const ctxCombo = this.workflow.getHabContext().copy();

    this.comboSearchProfils = new CWComboBoxView2({
      ws: new CWCombosColl({
        name: "profilwf",
        comboId: "utilisateur"
      }),
      habContext: ctxCombo,
      name: "profil",
      required: true,
      placeholder: i18n.t('common:placeholder_combo.combo_roles')
    });
    this.workflow.roleCollection = this.comboSearchProfils.coll;

    this.comboSearchProfils.fetchCombo((): void => {
      this.workflow.checkShowProfils(this.comboSearchProfils.coll);
      this.workflow.trigger("comboProfilsFetched");
      if (!this.workflow.showProfils) {
        this.$el.find('.roleSelectField').hide();
      } else {
        this.workflow.isSelectedProfils = false;
      }
    });

    this.motifComboPlaceholder = {
      code: "aucun",
      libelle: i18n.t('common:placeholder_combo.combo_recap')
    }
    this.comboRecapLibelle = new CWComboBoxView2({
      // ws: new RecapLibelleDataModel({ id: "recapitulatif" }),
      ws: new CWCombosColl({
        comboId: "recapitulatif"
      }),
      name: "recapitulatif",
      habContext: ctxCombo,
      optionsRender: (value: { [key: string]: any }): string => {
        if (value.code === this.motifComboPlaceholder.code || CWSTR.isBlank(value.code)) {
          return this.motifComboPlaceholder.libelle;
        }
        return value.libelle + " (" + value.code + ")";
      }
    });
  }

  _changeModel(event: JQueryEventObject, data: string | any, silentValue: boolean): void {
    const className = event.currentTarget.className.split(" ")[0];
    let selectedMotif;

    if (className === "recapitulatif") {
      selectedMotif = this.comboRecapLibelle.getItemId();
      if (CWSTR.isBlank(selectedMotif)) {
        this.comboRecapLibelle.setItem(this.motifComboPlaceholder);
      }
    }
    this._change(event, data, silentValue, true);
    for (const i in this.model.attributes) {
      if (CWSTR.isBlank(this.model.get(i))) {
        this.model.unset(i, { silent: true });
      }
    }
  }

  render(): this {
    const json = {
      "i18n": i18n,
      'icon': this.iconLoupe
    };
    this.$el.append(this.template(json));
    if (this.context && this.context.ctxGestionCollective === false) {
      this.$el.find(".collabData").addClass('d-none');
    } else {
      this.$el.find(".collabData").removeClass('d-none');
    }
    if (this.context.ctxGestionCollective) {
      $(this.el).find(".roleSelectField").html(this.comboSearchProfils.render().el);
      $(this.el).find(".roleSelectField").removeClass('d-none');
    } else {
      $(this.el).find(".cmbLibelle").html(this.comboRecapLibelle.render().el);
      $(this.el).find(".cmbLibelle").removeClass('d-none');
      this.comboRecapLibelle.setItem(this.motifComboPlaceholder);
    }

    this.checkShowProfil();
    return this;
  }

  private _search(): void {
    this.workflow.filterModel.trigger('btn:click', 'search');
  }

  private _changeProfil(): void {
    this.workflow.isSelectedProfils = true;
    this.workflow.selectedProfil = this.model.get('profil');
  }

  restaureDefaultValues(): void {
    this.model.restaureDefaultValues();
    this._mapModelToForm($(this.el), this._getModel());
    this.comboRecapLibelle.setItem(this.motifComboPlaceholder);
  }

  checkShowProfil(): void {
    if (this.context && this.context.ctxEcran !== "mactivites" && this.context.ctxEcran !== "suivicollab" && (this.workflow.showProfils || !this.context.ctxGestionCollective)) {
      $(this.el).find(".roleSelectField").removeClass('d-none');
    } else {
      $(this.el).find(".roleSelectField").addClass('d-none');
    }
  }
}
