import _ from 'underscore';
import { CWAccepterRecap } from './cwAccepterRecap';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWCombosColl } from 'src/core/components/combo/cwCombos.collection';
import { CWCreerRecapView } from 'common/evenements/gerer/gererrecapitulatifs/views/cwCreerRecap.view';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWListerecapGeneralContextInterface } from '../views/cwListerecapGeneral.view';
import { CWMSGS } from 'utils/cwMsgs';
import { CWPaginatedCollection } from 'src/core/models/cwPaginated.collection';
import { CWPiecesAutorisees } from 'common/evenements/gerer/gererpiecesjointes/models/cwPiecesAutorisees';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { CWRecapModel } from './cwRecap.model';
import { CWRefuserRecap } from './cwRefuserRecap';
import { CWSTR } from 'utils/cwStr';
import { CWVoletView } from 'src/core/components/dialog/volet/cwVolet.view';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { LOG } from 'utils/log.js';
import { objs } from 'src/objectsRepository';
import { ResultControlesView } from 'common/resultcontroles/views/resultControles.view.js';
import { UTILS } from 'utils/utils.js';


/**
 * Model Representing the UC work flow
 */
export class CWListerecapWorkflow extends CWReadOnlyModel {
  public combosModel: any;
  public filterModel: any;
  public roleCollection: CWCombosColl;
  public formModel: any;
  public tableModel: any;
  public btnBarModel: any;
  public headerModel: any;
  public progressBarModel: any;
  public rolesModel: any;

  public maxPieces: CWPiecesAutorisees;
  public objErrors: any[];
  public nObjErrors: { [key: string]: any };
  public firstLoad: boolean;
  public roleFirst: boolean;
  public context: CWListerecapGeneralContextInterface;

  public COLLABORATEUR: string;
  public RESPONSABLE: string;
  public ASCENSDANT: string;
  public DESCENDANT: string;

  public paramDivPieceJointe: any;
  public pDiversValRAalertModel: any;
  public pDiversIdCollabModel: any;
  public action: string;
  public pendingPopulationRefresh: boolean;
  public showProfils: boolean;
  public isSelectedProfils: boolean;
  public selectedProfil: { [key: string]: any };
  volet: CWVoletView<CWCreerRecapView>;
  public module: string;
  public $appendTo: JQuery; //pour les messages
  private loadedInitial: boolean;

  /**
   * Add this to have Outline
   *
   */

  constructor(attributes?: { [key: string]: any }, options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(attributes)) {
      options = attributes;
    }
    options = options || {};
    super(attributes, options);
    this.module = options.usecase;
    if (options && options.context) {
      this.context = options.context;
      this.module = this.context.ctxEcran;
      this.usecase = this.module;
    }
    this.$appendTo = (!CWSTR.isBlank(this.module)) ? $("#" + this.module) : null;
    // CONSTANT
    this.combosModel = null;
    this.filterModel = null;
    this.roleCollection = null;
    this.tableModel = null;
    this.btnBarModel = null;
    this.headerModel = null;
    this.progressBarModel = null;
    this.rolesModel = null;
    this.maxPieces = null;
    //array and object errors
    this.objErrors = []; //code: ,type: Aceppte/Refuser , message:""
    this.nObjErrors = {};
    this.nObjErrors.nbDemandes = 0;
    this.nObjErrors.nbDemandesAbandonee = 0;
    this.nObjErrors.nbErrors = 0;
    this.set("ready", false); // true, when the setUp is finished.
    // One setUp per life cycle of the module
    this.firstLoad = (_.isBoolean(options.firstLoad) ? options.firstLoad : true);//((this.usecase === "suivicollab") ? false : true);
    this.loadedInitial = false;
    this.roleFirst = true;
    this.COLLABORATEUR = "Collaborateur";
    this.RESPONSABLE = "Responsable";
    this.ASCENSDANT = "Ascendant";
    this.DESCENDANT = "Descendant";
    this.showProfils = true;
    this.isSelectedProfils = true;
    if (options && options.context) {
      let habContextWorkflow: { [key: string]: any } = null;

      if (options.context.ctxGestionCollective && options.context.ctxValidation) {
        habContextWorkflow = {
          onglet: options.context.ctxEcran,
          foncCour: options.context.ctxHabilitation.HabilitationValidation,
          natGest: ""
        }
      } else {
        habContextWorkflow = {
          onglet: options.context.ctxEcran,
          foncCour: options.context.ctxHabilitation.HabilitationAcces,
          natGest: ""
        }
      }
      this.setHabContext(new CWHabilitationContext(habContextWorkflow));
    } else {
      this.setHabContext(new CWHabilitationContext());
    }
    this.paramDivPieceJointe = GLOBAL_DATA.paramDivers.get("pJointe");
    this.pDiversValRAalertModel = GLOBAL_DATA.paramDivers.get("ValRAalert");
    // Retrieve parametre divers for th collab info (idCollab)
    this.pDiversIdCollabModel = GLOBAL_DATA.paramDivers.get("idCollab");
    this._registerSyncEvents();
  }
  /**
   * Sets the context for selecteurActivite complete model
   */
  setContext(context: CWListerecapGeneralContextInterface, callback: () => void): void {
    this.context = context;
    this.setUp(callback);
  }

  setUp(callback?: () => void): void {
    const hc = this.getHabContext().copy();
    const callbackFetchTable = (): void => {
      const hc2 = this.getHabContext().copy();
      let hab: { [key: string]: any } = { "acces": "", "validation": this.context.ctxHabilitation.HabilitationValidation };

      if (this.context.ctxUtilisateur === this.RESPONSABLE && this.context.ctxValideur && this.context.ctxValidation) {
        hab = { "acces": "", "validation": this.context.ctxHabilitation.HabilitationValidation };
      } else if (this.context.ctxUtilisateur === this.RESPONSABLE && !this.context.ctxValideur && this.context.ctxValidation) {
        hab = { "acces": this.context.ctxHabilitation.HabilitationAcces, "validation": "" };
      } else if (this.context.ctxUtilisateur === this.COLLABORATEUR) {
        hab = { "acces": this.context.ctxHabilitation.HabilitationAcces, "validation": "" };
      }
      if (!_.isEmpty(hab)) {
        hc2.update({ foncCour: hab, natGest: "" });
      } else {
        hc2.update({ natGest: "" });
      }
      this.tableModel.coll.setHabContext(hc2);
      this.tableModel.coll.usePopulation = (objs.appRt.workflow.get("zone") === "resp");
      this.tableModel.coll.setParametres(this.context, this.pDiversIdCollabModel, this.maxPieces, this.paramDivPieceJointe);
      if (this.isSelectedProfils) {
        this.tableModel.coll.params.profil = this.selectedProfil.code;
      }
      if (this.gridCanBeDisplayed()) {
        this.tableModel.coll.fetch({
          success: (fresh: CWPaginatedCollection): void => {
            if (!CWSTR.isBlank(this.tableModel.coll.params) && !CWSTR.isBlank(this.tableModel.coll.params.profil)) {
              this._setNewProfil(fresh, this.tableModel.coll.params.profil);
            }
            this.tableModel.coll.trigger("revert:table", false);
            this.tableModel.coll.trigger("reset");
            // this.firstLoad = false;
            if (callback) {
              callback();
            }
            this.tableModel.trigger('showGrid');
            this.loadedInitial = true;
          }
        });
      } else {
        //this.firstLoad = false;
        if (callback) {
          callback();
        }
        this.loadedInitial = true;
      }
    };

    // Declare events consumers
    this.filterModel.off("search");
    this.filterModel.off("clear");
    this.btnBarModel.off("btn:click");
    this.progressBarModel.off("btn:click");
    this.showProfils = true;
    this.filterModel.on("search", this._filterTable, this);
    this.filterModel.on("clear", this._clearFilter, this);
    this.btnBarModel.on("btn:click", this._buttonAction, this);
    this.progressBarModel.on("btn:click", this._progressBarBtnAction, this);
    this._clearArrays();
    this.updateHabContext({ onglet: this.context.ctxEcran, foncCour: this.context.ctxHabilitation.HabilitationAcces, natGest: "" });
    if (this.roleFirst) {
      this.roleFirst = false;
    }

    hc.update({ foncCour: this.context.ctxHabilitation.HabilitationValidation, natGest: "M" });
    if (this.firstLoad === true) {
      this.firstLoad = false;
      this.isSelectedProfils = false;
      //Max pieces jointes
      this.maxPieces = new CWPiecesAutorisees({ id: "PJEVTRECAP" });
      if (this.getHabContext() !== undefined) {
        this.maxPieces.setHabContext(this.getHabContext());
      }
      if (this.context.ctxEcran === "valevt") {
        this.maxPieces.setHabContext(hc);
      }
      this.maxPieces.fetch({
        success: (): void => {
          if (callbackFetchTable) {
            callbackFetchTable();
          }
        }
      });
    }
    else if (this.loadedInitial) {
      if (callbackFetchTable) {
        callbackFetchTable();
      }
    }
    this.set("ready", true);
  }

  public checkShowProfils(comboCollection: CWCombosColl): void {
    if (comboCollection && comboCollection.length === 1) {
      this.selectedProfil = comboCollection.models[0];
      this.isSelectedProfils = true;
      this.showProfils = false;
    } else {
      this.showProfils = true;
    }
  }

  _filterTable(filter: { [key: string]: any }): void {
    LOG.debug("Filter table " + filter);

    if (this.gridCanBeDisplayed()) {
      if (CWSTR.isBlank(filter.profil) && !CWSTR.isBlank(this.tableModel.coll.params.profil)) {
        const profil = { profil: this.tableModel.coll.params.profil };
        filter = _.extend(filter, profil);
      }
      if (filter.recapitulatif && filter.recapitulatif === "aucun") {
        delete filter.recapitulatif;
      }
      this.tableModel.coll.applyFilter(filter);
      this._clearArrays();
      this.refreshList();
    }
  }

  private refreshList(): void {
    this.tableModel.coll.fetch({
      success: (fresh: CWPaginatedCollection): void => {
        if (!CWSTR.isBlank(this.tableModel.coll.params.profil)) {
          this._setNewProfil(fresh, this.tableModel.coll.params.profil);
        }
        this.tableModel.coll.trigger("revert:table");
        this.tableModel.coll.trigger("updateHeader:coll");
        this.tableModel.coll.trigger("reset");
        //Don't select autommatically a row, because gerer recapitulatifs listens to row selection
        //So we only want to listen to the selection of a row by a user.
        this.tableModel.set("value", null);
        this.tableModel.trigger('collReset');
      },
      silent: true
    });
    this.tableModel.trigger('showGrid');

  }

  _modelCanBeManaged(model: CWBaseModel): boolean {
    let modelCanBeManaged = false;

    const etat = model.get("etat");
    //add UtilValideur check when this field is defined
    if (this.context.ctxUtilisateur === this.RESPONSABLE && (etat === "D" || etat === "Z" || etat === "C" ||
      etat === "G") && model.get("profils").length > 0) { //Only this etat values can be validated sfd v1.2 section 2.4 and if responsable had profils to validate
      modelCanBeManaged = true;
    }
    return modelCanBeManaged;
  }

  _setNewProfil(coll: CWPaginatedCollection, profil: string): void {
    _.each(coll.models, (model: { [key: string]: any }): void => {
      if (model.get("profils").length > 1) {
        model.selectedProfil = {
          code: profil,
          libelle: ""
        };
      } else {
        model.selectedProfil = model.get("profils")[0];
      }
    });
  }

  _clearFilter(): void {
    const profil: string = this.tableModel.coll.params.profil;
    this.isSelectedProfils = false;
    const filter: { [key: string]: any } = {};
    if (!CWSTR.isBlank(profil)) {
      filter.profil = profil;
      if (!this.showProfils) {
        this.isSelectedProfils = true;
        this.selectedProfil = { "code": profil };
      }
    }
    this.tableModel.coll.applyFilter(filter);
    if (!this.gridCanBeDisplayed()) {
      this.tableModel.trigger('hideGrid');
    }
  }

  _buttonAction(buttonId: string): void {
    this._buttonActionWithCallback(buttonId);
  }

  _buttonActionWithCallback(buttonId: string, callback?: () => void): void {
    LOG.debug("Button clicked in listerecap : " + buttonId);
    let recaps = null;
    let areConditionsReady: boolean = true;
    let areProfilsReady: boolean = false;

    switch (buttonId) {
      case "save":
        this.formModel.trigger("cleanGridErrors");

        recaps = this.tableModel.coll;
        areConditionsReady = true;

        this._clearArrays();

        _.each(recaps.models, (recap: { [key: string]: any }): void => {
          if (recap.recEtatValid === "R" && CWSTR.isBlank(recap.get("commentaire"))) {
            areConditionsReady = false;
          }
        });

        areProfilsReady = this._checkProfils();

        if (areProfilsReady) {
          if (!areConditionsReady) {
            CWMSGS.showError(i18n.t('messages:GT_1365'), null, this.$appendTo);
          } else {
            this.btnBarModel.set("mode", "R", { silent: true });
            this.btnBarModel.trigger("hide:save");
            this.btnBarModel.trigger("hide:revert");
            (UTILS.usecase_loading_exception as any)[this.context.ctxEcran] = true;
            this._launchProcess((): void => {
              (UTILS.usecase_loading_exception as any)[this.context.ctxEcran] = false;
              this.btnBarModel.set("mode", "R", { silent: true });
              this.btnBarModel.trigger("hide:save");
              this.btnBarModel.trigger("hide:revert");
              this.tableModel.trigger("finish:proccess");
              this.trigger("listeRecapUpdated");
              if (typeof callback === "function") {
                callback.call(this);
              }
            });
          }
        } else {
          this.tableModel.coll.trigger("reset");
        }
        break;

      case "revert":
        _.each(this.tableModel.coll.models, (rowModel: CWRecapModel) => {
          rowModel.set("commentaire", "");
          rowModel.recEtatValid = null;
        });
        this.tableModel.coll.trigger("revert:table");
        this.tableModel.multiselectColl.reset(null);
        this.btnBarModel.set("mode", "R");
        this.formModel.trigger("resetSortableColumns");

        if (typeof callback === "function") {
          callback.call(this);
        }
        break;

      case "new":
        this.showVolet();
        break;
      default:
        break;
    }
  }

  _checkProfils(): boolean {
    let areProfilsReady = true;

    _.each(this.tableModel.coll.models, (recap: CWRecapModel): void => {
      if ((recap.recEtatValid === "R" || recap.recEtatValid === "A") && (!recap.isValid())) {
        areProfilsReady = false;
      } else {
        recap.profilErrors = null;
      }
    });
    return areProfilsReady;
  }

  _formEdited(): void {
    this.btnBarModel.set("mode", "E");
  }

  _launchProcess(callback: () => void): void {
    const recaps = this.tableModel.coll;
    const progressBarModel = this.formModel.get("progressBarModel");
    progressBarModel.set("max", recaps.nbrecEtatValid);
    progressBarModel.set("treatedSuccess", 0);
    progressBarModel.set("value", 0);
    // Reset index saved for pause/play and booleans
    CWSTR.setElValue(this.formModel.get("progressBarModel"), "stop", false);

    // items will be treated sequentially
    this._processItem(progressBarModel, 0, 0, callback);
  }

  _processItem(progressBarModel: CWBaseModel, index: number, nbItemExecuted: number, callback: () => void): void {

    // If stop process button is clicked we show a message, else we continue the process
    if (CWSTR.getElValue(this.formModel.get("progressBarModel"), "stop") === true) {

      CWMSGS.showConfirm({
        text: i18n.t('messages:GT_1110'),
        buttons: { yes: true, no: true },
        callback: (result: string) => {
          if (result === "Y") { //Stop the process
            this.formModel.nObjErrors.nbDemandesAbandonee = this.formModel.get("progressBarModel").get("max") - this.formModel.get("progressBarModel").get("treatedSuccess");
            this._isValidationComplet(callback, true);
          } else {//Process continues
            CWSTR.setElValue(this.formModel.get("progressBarModel"), "stop", false);
            this._executerItem(progressBarModel, index, nbItemExecuted, callback);
          }
        },
        appendTo: this.$appendTo
      });
    } else {
      this._executerItem(progressBarModel, index, nbItemExecuted, callback);
    }
  }

  /**
   * Execute the action in a collaborator and continues the recursion if there are more to treat.
   */
  _executerItem(progressBarModel: CWBaseModel, index: number, nbItemExecuted: number, callback: () => void): void {

    const recapColl = this.tableModel.coll;
    const recap = recapColl.models[index];
    const action = recap.recEtatValid;

    // Treat a collaborator if it has been selected in the collection, else we continue the process
    if (!CWSTR.isBlank(action)) {
      const itemVal = this._buildModelToVal(recap, action);
      const hc = this.formModel.getHabContext().copy();
      hc.update({ foncCour: this.context.ctxHabilitation.HabilitationValidation, natGest: "A" });
      itemVal.setHabContext(hc);


      const successCallback = (): void => {
        this.formModel.nObjErrors.nbDemandes++;
        recapColl.nbrecEtatValid--;
        const treatedSuccess = progressBarModel.get("treatedSuccess");
        progressBarModel.set("treatedSuccess", treatedSuccess + 1);
        progressBarModel.set("value", ++nbItemExecuted);
        index++;
        if (this._isValidationComplet(callback, false) === false) {
          this._processItem(progressBarModel, index, nbItemExecuted, callback);
        }
      };
      const originalOptions = {
        success: (): void => {
          successCallback();
        },
        error: (model: CWBaseModel, xhf: { [key: string]: any }): void => {
          const recapColl = this.tableModel.coll;
          const recap = recapColl.models[index];
          const action = recap.recEtatValid;
          if (xhf.status === 410) {
            const context: { [key: string]: any } = {};
            context.ctxUtilisateur = this.context.ctxUtilisateur;
            context.ctxUtilisation = "Traitement";
            context.ctxModePopup = "Modale";
            context.ctxGestionCollab = this.context.ctxCollab;
            context.ctxPeriodeControles = {};
            context.ctxPeriodeControles.datedeb = recap.get("datedeb");
            context.ctxPeriodeControles.datefin = recap.get("datefin");
            context.ctxEcran = this.context.ctxEcran;
            if (action === "A") {
              context.ctxTitrePopup = i18n.t('common:listerecap.messageAccepterTitle');
            } else {
              context.ctxTitrePopup = i18n.t('common:listerecap.messageRefuserTitle');
            }
            context.ctxIdentifiantRecap = recap.get("code");
            const controlePopUp = new ResultControlesView({
              context: context,
              habilitations: hc,
              messagesModel: new CWBaseModel(xhf.responseJSON),
              originalModel: itemVal,
              actionModel: "save"
            });
            controlePopUp.open({
              success: (): void => {
                successCallback();
              },
              error: (): void => {
                if (this.action === "fermer") {
                  const error: { [key: string]: any } = {};
                  error.code = model.get("code");
                  error.message = "Error";
                  error.type = action;

                  this.formModel.nObjErrors.nbErrors++;
                  this.formModel.objErrors.push(error);
                } else {
                  this.formModel.nObjErrors.nbDemandesAbandonee++;
                }
                index++;
                this.formModel.nObjErrors.nbDemandes++;
                recapColl.nbrecEtatValid--;
                const treatedSuccess = progressBarModel.get("treatedSuccess");
                progressBarModel.set("treatedSuccess", treatedSuccess + 1);
                progressBarModel.set("value", ++nbItemExecuted);

                if (this._isValidationComplet(callback, false) === false) {
                  this._processItem(progressBarModel, index, nbItemExecuted, callback);
                }
              }
            });
          } else {
            index++;
            this.formModel.nObjErrors.nbDemandes++;
            recapColl.nbrecEtatValid--;
            progressBarModel.set("value", ++nbItemExecuted);

            if (this._isValidationComplet(callback, false) === false) {
              this._processItem(progressBarModel, index, nbItemExecuted, callback);
            }
          }
        }
      };

      itemVal.save(null, originalOptions); //end save

    } else {
      index++;
      if (this._isValidationComplet(callback, false) === false) {
        this._processItem(progressBarModel, index, nbItemExecuted, callback);
      }
    }
  }

  _manageError(model: CWBaseModel, options: { [key: string]: any }, xhr: { [key: string]: any }, recapitulatif: CWBaseModel, action: string, callback: () => void): void {
    let customMsg: { [key: string]: any } = {};
    const status = xhr.status;

    try {
      if (!CWSTR.isBlank(xhr.oldResponseText)) {
        customMsg = JSON.parse(xhr.oldResponseText);
      } else {
        customMsg = JSON.parse(xhr.responseText);
      }
    } catch (error) {
      // ignore
    }

    if (status === 406 || status === 500 || xhr.oldStatus === 406 || xhr.oldStatus === 500) {

      if (!CWSTR.isBlank(customMsg.titre) && !CWSTR.isBlank(customMsg.message)) {
        const error: { [key: string]: any } = {};
        switch (customMsg.titre) {
          case "i18n_alert":
          case "i18n_error":
          case "i18n_information":
            error.code = recapitulatif.get("code");
            error.message = customMsg.message;
            error.type = action;

            this.formModel.nObjErrors.nbErrors++;
            this.formModel.objErrors.push(error);

            xhr.omit = true;

            if (callback) {
              callback();
            }
            break;
          case "i18n_confirmation":
          case "i18n_question":
            xhr.omit = true;
            // notification message returned by server that needs processing...
            CWMSGS.showNotification(customMsg, (result: string): void => {
              switch (result) {
                case "ABORT":
                  this.formModel.nObjErrors.nbDemandesAbandonee++;

                  if (callback) {
                    callback();
                  }
                  break;
                default:
                  options.url = Configuration.restRoot + "/rest/" + result;
                  model.save(null, options);
              }
            }, null, this.$appendTo);

            break;
          default:
            break;
        }
      }
    }
  }

  _clearArrays(): void {
    this.formModel.objErrors = []; //code: ,type: Aceppte/Refuser , message:""
    this.formModel.nObjErrors = {};
    this.formModel.nObjErrors.nbDemandes = 0;
    this.formModel.nObjErrors.nbDemandesAbandonee = 0;
    this.formModel.nObjErrors.nbErrors = 0;
  }

  /**
   * Build the correct model to execute it depends on the action selected.
   */
  _buildModelToVal(recap: CWRecapModel, action: string): CWAccepterRecap | CWRefuserRecap {
    const buildId = recap.get("code");

    const model = action === "A" ? new CWAccepterRecap() : new CWRefuserRecap();

    model.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationValidation
    }));

    model.id = buildId;
    model.set("commentaire", recap.get("commentaire"));
    model.set("droit", recap.get("droit"));
    const profils = recap.get("profils");
    const selectedProfil = recap.selectedProfil;
    let profil: { [key: string]: any };
    if (!CWSTR.isUndefined(selectedProfil)) {
      profil = _.find(profils, (p: { [key: string]: any }): boolean => {
        return p.code === selectedProfil.code;
      });
    } else {
      profil = this.selectedProfil;
    }

    model.set("profil", profil);

    return model;
  }

  /**
   * Build the correct model to execute it depends on the action selected.
   */
  _isValidationComplet(callback: () => void, forceComplete: boolean): boolean {
    let isValidationFinish = false;
    // If the number of collaborators validated is the total we show the report table.
    if (this.tableModel.coll.nbrecEtatValid === 0 || forceComplete === true) {
      this.tableModel.coll.trigger("display:rightText", "hide");
      if (callback) {
        callback();
      }
      isValidationFinish = true;
    }
    return isValidationFinish;
  }

  /**
   * Function prepared to manage also pause and play action.
   */
  _progressBarBtnAction(action: string): void {
    LOG.debug("Action Selected : " + action);

    if (action === "interrompre") {
      CWSTR.setElValue(this.formModel.get("progressBarModel"), "stop", true);
    }
  }

  // register listener to syncEvents type
  _registerSyncEvents(): void {
    this.pendingPopulationRefresh = false;
    this.stopListening(objs.appRt.workflow, "changed:usecase");
    this.listenTo(objs.appRt.workflow, "changed:usecase", this._managePendingSyncEvents);
    this.stopListening(objs.appRt.workflow.syncModel, "change:population");
    this.listenTo(objs.appRt.workflow.syncModel, "change:population", this._manageChangePopulationEvent);
  }

  // execute pending syncEvents
  _managePendingSyncEvents(): void {
    if (this.pendingPopulationRefresh === true) {
      this._manageChangePopulationEvent();
    }
  }

  // syncEvent : populations
  _manageChangePopulationEvent(): void {
    if (objs.appRt.workflow.get("zone") === "resp" && objs.appRt.workflow.get("usecase") === this.context.ctxEcran && objs.appRt.workflow.get("usecase") !== "valevt") {
      this._changePopulation();
    } else {
      this.pendingPopulationRefresh = true;
    }
  }

  _changePopulation(): void {


    this.pendingPopulationRefresh = false;
    if (this.btnBarModel.get("mode") === "E") {
      this.pendingPopulationRefresh = true;
      CWMSGS.showInfo(i18n.t('messages:GL_1034'), this.$appendTo);
    } else {
      if (this.tableModel) {
        this.tableModel.coll.resetPagination();
        this.tableModel.coll.fetch({
          success: (): void => {
            this.tableModel.coll.trigger("updateHeader:coll");
            //Don't select autommatically a row, because gerer recapitulatifs listens to row selection
            //So we only want to listen to the selection of a row by a user.
            this.tableModel.set("value", null);

          }
        });
      }
    }
  }

  /**event: JQueryEventObject */
  public showVolet(): void {
    if (this.volet) {
      this.volet.open();
      this.trigger('contextchanged');
      this.manageVoletTitle();

      $(this.volet.el).parent().css('width', 'auto');
    }
  }

  /**
   *  Manage title of the volet
   * */
  public manageVoletTitle(): void {
    const title = $('<div>').html('<div class="gererrecap-titlePopup"><span class="typeRecap">' + i18n.t('common:gererrecapitulatifs.titleNouveau').toUpperCase() + '</span> <span class="c-titleHistoWkf"></span>');
    title.find(".typeRecap").append($('<span>').addClass('gererrecap-titlePopup').addClass('cw-texteAllege cw-texteAllege--selectionne').html(String(" - ").concat(i18n.t('common:gererrecapitulatifs.creation_en_cours'))));
    this.volet.isTitleHTML = true;
    this.volet._setTitle(title.html());
  }

  public gridCanBeDisplayed(): boolean {
    return (!this.showProfils || (this.showProfils && this.isSelectedProfils) || !this.context.ctxGestionCollective);
  }

  enableFiltering(): void {
    this.filterModel.trigger('enableFilter');
  }
  disableFiltering(): void {
    this.filterModel.trigger('disableFilter');
  }

  destroy(): void {
    if (this.filterModel) {
      this.filterModel.off();
      this.filterModel = null;
    }
    if (this.filterModel) {
      this.filterModel.off();
      this.filterModel = null;
    }
    if (this.btnBarModel) {
      this.btnBarModel.off();
      this.btnBarModel = null;
    }
    if (this.progressBarModel) {
      this.progressBarModel.off();
      this.progressBarModel = null;
    }
    this.stopListening(objs.appRt.workflow);
    this.stopListening(objs.appRt.workflow.syncModel);
  }
}
