import { CompletDomModel } from './completDom.model.js';
import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { STR } from 'utils/str.js';

export var CompletDomColl = PaginatedCollection.extend({

  model: CompletDomModel,

  /**
   * Constructor
   * Domaine collection at Selecteur Activite Mode Complet
   */
  initialize: function(params) {
    PaginatedCollection.prototype.initialize.call(this);
    this.params = "";
    this.search = "";
    this.setCollParameters(params);
    this.paginated = false;
  },

  /**
   * Sets the parameters for this collection
   */
  setCollParameters: function(parametres) {
    if (parametres) {
      if (!STR.isBlank(parametres.contenu)) {
        this.contenu = parametres.contenu;
      }
      if (!STR.isBlank(parametres.typo)) {
        this.typo = parametres.typo;
      }
      if (!STR.isBlank(parametres.typevt)) {
        this.typevt = parametres.typevt;
      }
      if (!STR.isBlank(parametres.ctxutil)) {
        this.ctxutil = parametres.ctxutil;
      }
      if (!STR.isBlank(parametres.datedeb)) {
        this.datedeb = parametres.datedeb;
      }
      if (!STR.isBlank(parametres.datefin)) {
        this.datefin = parametres.datefin;
      }
      if (!STR.isBlank(parametres.dom)) {
        this.dom = parametres.dom;
      }
      if (!STR.isBlank(parametres.pertot)) {
        this.pertot = parametres.pertot;
      }
      if (!STR.isBlank(parametres.perdeb)) {
        this.perdeb = parametres.perdeb;
      }
      if (!STR.isBlank(parametres.ctxdeplacement)) {
        this.ctxdeplacement = parametres.ctxdeplacement;
      }
      if (!STR.isBlank(parametres.idplanact)) {
        this.idplanact = parametres.idplanact;
      }
      if (!STR.isBlank(parametres.ctxgrille)) {
        this.ctxgrille = parametres.ctxgrille;
      }
      if (!STR.isBlank(parametres.ctxpalette)) {
        this.ctxpalette = parametres.ctxpalette;
      }
      if (parametres.medical === true) {
        this.medical = true;
      }
      this.typegest = parametres.ctxTypeGest;
      this.horstypegest = _.isBoolean(parametres.ctxHorsTypeGest) ? parametres.ctxHorsTypeGest : false;
    }
  },

  /**
   * Build params URL for server call
   */
  _buildURL: function() {
    var existParam = false;

    this.params = "";
    if (!STR.isBlank(this.contenu)) {
      existParam = true;
      this.params += "contenu=" + encodeURIComponent(this.contenu);
    }
    if (!STR.isBlank(this.ctxpalette)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "ctxpalette=" + encodeURIComponent(this.ctxpalette);
    }
    if (!STR.isBlank(this.typevt)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "typevt=" + encodeURIComponent(this.typevt);
    }
    if (!STR.isBlank(this.typo)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "typo=" + encodeURIComponent(this.typo);
    }
    if (!STR.isBlank(this.ctxgrille)) {
      if (existParam) {
        this.params += "&";
      }
      this.params += "ctxgrille=" + encodeURIComponent(this.ctxgrille);
    }
    if (!STR.isBlank(this.ctxutil)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "ctxutil=" + encodeURIComponent(this.ctxutil);
    }
    if (!STR.isBlank(this.datedeb)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "rechdeb=" + encodeURIComponent(this.datedeb);
    }
    if (!STR.isBlank(this.datefin)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "rechfin=" + encodeURIComponent(this.datefin);
    }
    if (!STR.isBlank(this.dom)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "dom=" + encodeURIComponent(this.dom);
    }
    if (!STR.isBlank(this.pertot)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "pertot=" + encodeURIComponent(this.pertot);
    }
    if (!STR.isBlank(this.perdeb)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "perdeb=" + encodeURIComponent(this.perdeb);
    }
    if (!STR.isBlank(this.idplanact)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "idplanact=" + encodeURIComponent(this.idplanact);
    }
    if (!STR.isBlank(this.ctxdeplacement)) {
      if (existParam) {
        this.params += "&";
      }
      this.params += "ctxdeplacement=true";
    }
    if (this.medical === true) {
      if (existParam) {
        this.params += "&";
      }
      this.params += "medical=true";
    }
    if (this.contenu === "R" && !_.isNull(this.typegest) && !STR.isBlank(this.typegest)) { //il faut ne pas utiliser CWSTR.isBlank parce qu'on doit envoyer le cas que la valeur soit "".
      //les valeurs possibles sont : "" et la valeur de ctxTypeGest
      if (existParam) {
        this.params += "&";
      }
      this.params += "typegest=" + encodeURIComponent(this.typegest);
      //uniquement s'il y a contexte de typegest, on enverra l'autre contexte ctxHorsTypeGest ->horstypegest
      this.params += "&horstypegest=" + this.horstypegest;
    }
    if (existParam) {
      this.params = "?" + this.params;
    }
  },

  /**
   * Builds URL adding mode params and search
   */
  url: function() {
    this._buildURL();
    return Configuration.restRoot + "/rest/composant/selecteur/activite/complet/domaines" + this.params;
  }
});
