import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLCommonTableCellMultiSelectAction from '../basegrid/cwTableCellMultiselectAction.tpl.html';
import { CWDataGridRowView } from '../data_grid/cwDataGridRow.view';
import { CWEditableGridView } from '../editablegrid/cwEditableGrid.view';
import { CWSTR } from 'utils/cwStr';

/**
 * View underlying a row form the Tree type DataGrid
 */
export class CWTreeTypeGridRowView extends CWDataGridRowView {

  visible: boolean;

  constructor(options: Backbone.ViewOptions | any) {
    options.events = _.extend({
      "click .treetbl_expbtn": "_clickTreeTableBtn",
      "click": "_rowListener",
      "dblclick": "_rowListener",
      "keydown": "keyEvent"
    });
    options.tagName = "tr";
    options.className = "c-grind__table__row";
    options.edited = false;
    options.dragging = false;
    super(options);
    CWDataGridRowView.prototype.initialize.call(this, options);
    (this.model as any).dragging = false;
    this.visible = true;
    if (options && _.isBoolean(options.visible)) {
      this.visible = options.visible;
    }
  }
  /**
   * Method which expands or not the row of the table grid when we click the related button.
   */
  keyEvent(event: JQueryEventObject): boolean {
    let ret = true;

    if (event.keyCode === 13 || event.keyCode === 32) { // enter or space
      if ($(event.currentTarget).children().find("span.treetbl_expbtn:visible").length > 0) {
        this.model.trigger("treetbl_exp", this.model);
      } else {
        this.model.trigger("treetbl_exp_select_row", this.model);
      }
      event.preventDefault();
      ret = false;
    }
    return ret;
  }

  /**
   * Method which expands or not the row of the table grid when we click the related button.
   */
  _clickTreeTableBtn(event: any): void {
    this.model.trigger("treetbl_exp", this.model);
    event.stopPropagation();
  }

  /**
   * Overrides basegrid rowListener to prevent click launched when dragg finnished
   * Method which manages the row click or double click in order to select it or edit it.
   */
  _rowListener(event: any): void {
    if ((this.model as any).dragging === false && $(event.target).hasClass("preventRowListener") === false) {
      CWDataGridRowView.prototype._rowListener.call(this, event);
    }
  }
  /**
   * Paints the row view of the tree grid
   */
  render(): CWTreeTypeGridRowView {
    const rowThis = $(this.el);

    this._addTreeHtmlData();
    const isSelected = rowThis.hasClass("phx-selected");
    if (this.dataGrid.selectable) {
      rowThis.css("cursor", "pointer");
    }
    if (!this.visible) {
      rowThis.css("display", "none");
    }
    rowThis.empty();

    if (this.edited === true &&
      (this.dataGrid instanceof CWEditableGridView)) {
      this._renderForm();
    } else {
      _.each(this.dataGrid.currentVue.columns, (column, index): void => {
        let cell = null;

        switch (column.property) {
          case "phx-multiselect":
            if (this.dataGrid.currentVue._columns["phx-multiselect"] && this.dataGrid.currentVue._columns["phx-multiselect"].visible) {
              cell = this._addActionCell(column, index, TPLCommonTableCellMultiSelectAction);
            }
            break;
          // case "phx-action":
          //   if (this.dataGrid.currentVue._columns["phx-action"] && this.dataGrid.currentVue._columns["phx-action"].visible) {
          //     cell = this._addActionCell(column, index, TPLCommonTableCellEditAction);
          //   }
          //   break;
          case "phx-last":
            break;
          default:
            cell = this._addCell(column, index);
        }

        if (cell !== null) {
          if ((column as any).treecol === true) {
            let marginLeft = (this.model as any).level * 10;
            let span = null;
            if ((this.model as any).isExpanded === true && !CWSTR.isBlank((this.model as any).hasChild)) {
              span = $("<div style='position:relative;display:inline-block;width:16px;'><span class='treetbl_expbtn ui-corner-all " + (this.dataGrid as any).expCollIcons.collapsible + "' branch='" + (this.model as any).branch +
                "' level='" + (this.model as any).level + "' item='" + (this.model as any).item + "' treetype_path='" + (this.model as any).path +
                "' style='position:absolute;top:-13px;'></span></div>");
            } else {
              span = $("<div style='position:relative;display:inline-block;width:16px;'><span class='treetbl_expbtn ui-corner-all  " + (this.dataGrid as any).expCollIcons.expandable + "' branch='" + (this.model as any).branch +
                "' level='" + (this.model as any).level + "' item='" + (this.model as any).item + "' treetype_path='" + (this.model as any).path +
                "' style='position:absolute;top:-13px;'></span></div>");
            }
            cell.$el.prepend(span);
            //cell.$el.css("height", "17px");
            if ((this.model as any).collapsible === true && ((this.model as any).hasChild === true || CWSTR.isBlank((this.model as any).hasChild))) {
              span.show();
            } else {
              span.hide();
              marginLeft += 16;
            }
            cell.$el.css("padding-left", marginLeft + "px");
          }
          rowThis.append(cell.el);
          this.dataGrid.currentVue._columns[column.property].cells.push(cell);
          if (!this.dataGrid.currentVue._columns[column.property].visible) {
            cell.$el.hide();
          }
          if ((column as any).draggable === true && CWSTR.isBlank((this.dataGrid as any).draggableClass) === false) {
            cell.$el.find("." + (this.dataGrid as any).draggableClass).attr("modelId", this.model.id);
            cell.$el.find("." + (this.dataGrid as any).draggableClass).draggable({
              revert: true,
              delay: 150,
              //make a copy of the content and adds to a floating on the cursor
              helper: "clone",
              start: (event): void => {
                this._calculateDrops(event);
              },
              stop: (event): void => {
                this._resetDrops(event);
              }
            });
            this.$el.find("." + (this.dataGrid as any).draggableClass).droppable({
              //Don't propagate the drop event
              greedy: true,
              //Set the drag interact on cursor
              tolerance: "pointer",
              //Class when hover accepted element
              hoverClass: "ui-drop-hover",
              //Function when drop an acceptable element
              drop: (event, ui): void => {
                const targetId = $(event.target).attr("modelId");
                const origId = ui.draggable.attr("modelId");
                const targetModel = this.dataGrid.model.coll.get(targetId);
                const origModel = this.dataGrid.model.coll.get(origId);

                this.dataGrid.model.trigger("dropped:element", origModel, targetModel);
              }
            });
          }
        }
      }, this);

      if (this.dataGrid.currentVue._columns["phx-multiselect"]) {
        this._showMultiSelection();
      }

      if (isSelected) {
        this._selectRow();
      }
    }
    return this;
  }
  /**
   * add hierarchie info to the html tr that represents a row
   */
  _addTreeHtmlData(): void {
    $(this.el).attr("data-level", (this.model as any).level);
    $(this.el).attr("data-branch", (this.model as any).branch);
    $(this.el).attr("data-path", (this.model as any).path);
    $(this.el).attr("data-id", this.model.get("id"));

  }
  /**
   * Function called when start the drag.
   */
  _calculateDrops(event: any): void {
    const modelId = $(event.target).attr("modelId");
    const model = this.dataGrid.model.coll.get(modelId);
    const path = String((model as any).path);

    (model as any).dragging = true;
    //Disable own
    $(event.target).droppable("disable");

    //Disable the childs
    if ((model as any).hasChild === true && (model as any).isExpanded === true) {
      //disable droppable from childrens
      const rowsInBranch = this.dataGrid.$el.find("tr[data-branch='" + (model as any).branch + "']");
      _.each(rowsInBranch, (row): void => {
        const rowmodelId = $(row).find("." + (this.dataGrid as any).draggableClass).attr("modelId");
        const rowModel = this.dataGrid.model.coll.get(rowmodelId);
        const elementPathString = String((rowModel as any).path);
        if ((rowModel as any).level >= (model as any).level && elementPathString !== path && elementPathString.indexOf(path) === 0) {
          //If the element is a son of the dragging element, disable drop
          $(row).find("." + (this.dataGrid as any).draggableClass).droppable("disable");
        }
      });
    }

  }

  /**
   * Function called when end the drag.
   */
  _resetDrops(event: any): void {
    const modelId = $(event.target).attr("modelId");
    const model = this.dataGrid.model.coll.get(modelId);

    (model as any).dragging = false;
    this.dataGrid.$el.find("." + (this.dataGrid as any).draggableClass + ".ui-droppable-disabled").droppable("enable");
  }

}