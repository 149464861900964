import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLAppHeader from '../header.tpl.html';
import { CWMenuBarView } from './cwMenuBar.view';
import { CWSTR } from 'src/utils/cwStr';
import { CWUserMenuView } from './cwUserMenu.view';
import { CWWorkFlowModel } from '../models/cwWorkFlow.model';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'utils/utils.js';

/**
 * View of the header of the home
 */
export class CWHeaderView extends Backbone.View<CWWorkFlowModel> {

  userMenuView: CWUserMenuView;


  constructor(params: Backbone.ViewOptions<Backbone.Model> | any) {
    params.events = _.extend({
      "click .phx-acount-span": (): void => {
        //logout
        this.userMenuView.clickMenu();
      },
      "mouseover .phx-acount-span": "_checkInfobulle",
      "click #cw-logout .phx-menus-transversal-logout": "_clickLogout",
      "keydown #cw-logout .phx-menus-transversal-logout": "_keydownLogout"
    }, params.events);
    super(params);
    this.template = TPLAppHeader;
    this.model.on("change:usecaseTitle", this._usecaseChangeTitle, this);
  }

  _usecaseChangeTitle(title: string): void {
    this.$el.find('.cw-header-usecase-title').html(title);
  }

  render(): CWHeaderView {
    const json = { i18n: i18n, auth: this.model.authModel.toJSON(), STR: CWSTR, UTILS: UTILS };
    let menuBarView: CWMenuBarView = null;

    this.$el.html(this.template(json));
    menuBarView = new CWMenuBarView({
      el: $("#phx-menu-bar", this.el),
      model: this.model
    });
    menuBarView.render();
    this.userMenuView = new CWUserMenuView({
      el: $("#phx-user-menu", this.el),
      model: this.model
    });
    this.userMenuView.render();
    return this;
  }

  _clickLogout(): void {
    this.model.trigger("user:prelogout");
  }

  _keydownLogout(e: { [key: string]: any }): void {
    if (e.keyCode === 13 || e.keyCode === 32) { //enter, spacebar
      this.model.trigger("user:prelogout");
    }
  }

  _checkInfobulle(): void {
    const $lpos = this.$el.find(".phx-acount-span");

    if ($lpos && $lpos.length > 0 && this.model?.authModel && !CWSTR.isBlank(this.model.authModel.get("name"))) {
      if ($lpos[0].clientWidth < $lpos[0].scrollWidth) {
        if (CWSTR.isBlank($lpos.attr("title"))) {
          $lpos.attr("title", this.model.authModel.get("name"));
        }
      } else {
        if (!CWSTR.isBlank($lpos.attr("title"))) {
          $lpos.attr("title", "");
        }
      }
    } else if ($lpos && $lpos.length > 0 && !CWSTR.isBlank($lpos.attr("title"))) {
      $lpos.attr("title", "");
    }
  }
}
