import { i18n } from 'src/i18n.js';
import { STR } from './str.js';
import { TYPE } from 'tda/tda.js';
import { UTILS } from './utils.js';

/**
 * Utilities to check SIMULATION
 */
export var SIMULATION = {

  simulationStates: {
    1: "Collaborateur HORS simulation",
    2: "Période HORS simulation",
    3: "Période simulée passée",
    4: "Période simulée",
    5: "Période simulée en partie passée",
    6: "Période simulée partiellement",
    7: "Période simulée partiellement et passée",
    8: "Période simulée partiellement et en partie passée"
  },

  getJourEstSimuleLabel: function(code, donneeSimulee) {
    var label = "";

    switch (code) {
      case 1:
        label = i18n.t('messages:PLH_151');
        break;
      case 2:
        label = i18n.t('messages:PLH_150');
        break;
      case 3:
        label = i18n.t('messages:PLH_149');
        break;
      case 4:
        if (donneeSimulee === true) {
          label = i18n.t('messages:PLH_154');
        } else {
          label = i18n.t('messages:PLH_155');
        }

        break;
    }

    return label;
  },

  /**
   * Check if a cell can be edited
   */
  canEditCell: function(pk, a_module) {
    var simulationState = SIMULATION.getCellState(pk, a_module);

    return simulationState === "E";
  },

  /**
   * Check if a cell can be viewed
   */
  canViewCell: function(pk, a_module) {
    var simulationState = SIMULATION.getCellState(pk, a_module);

    return simulationState === "V";
  },

  /**
   * Check if a cell is in the simulation
   */
  isSimulatedCell: function(pk, a_module) {
    var simulationState = SIMULATION.getCellState(pk, a_module);

    return !STR.isBlank(simulationState) && simulationState !== "N";
  },

  /**
   * Returns cell
   */
  getSimulationCell: function(pk, a_module) {
    return $('[data-selectpk="' + pk + '"]', $("#" + a_module));
  },

  /**
   * Returns cell simulation state
   */
  getCellState: function(pk, a_module) {
    var selectedCell = SIMULATION.getSimulationCell(pk, a_module);

    return $(selectedCell).data("simulation");
  },

  /**
   * Returns periode simulation state
   */
  periodeState: function(a_collab, a_datedeb, a_datefin, a_module) {
    var lb_rtn = false;
    var l_datedeb = parseInt(a_datedeb, 10);
    var l_datefin = parseInt(a_datefin, 10);

    if (!STR.isBlank(a_collab) && _.isNumber(l_datedeb) && _.isNumber(l_datefin) && l_datedeb <= l_datefin) {
      var lb_edit = true;
      var l_day_per = "";
      var i = l_datedeb;
      while (i <= l_datefin && lb_edit) {
        l_day_per = a_collab + "," + i;
        lb_edit = SIMULATION.canEditCell(l_day_per, a_module);
        i++;
      }
      lb_rtn = lb_edit;
    }
    return lb_rtn;
  },

  formatSimulationData: function(data, extended, useIcon, type) {
    var output = $("<div class=\"simulation-data-format\">");

    if (extended === true && useIcon === false) {
      output.append($("<div>" + i18n.t('plans.planresp.title_simulation') + "<span class=\"format-separator\">  -  </span></div>"));
    } else if (useIcon === true) {
      output.append($("<div><span class=\"dialog-enregistrer-content-icon ui-phx-simulation-icon\" /><span class=\"format-separator\">  -  </span></div>"));
    }

    output.append($("<div><span class=\"phx-bold-style\">" + TYPE.DATE.format(data.get("datedeb")) + "</span> <span>" + i18n.t('plans.planresp.simulation_saveDialog_periode_au') + "</span> <span class=\"phx-bold-style\">" + TYPE.DATE.format(data.get("datefin")) + "</span></div>"));

    if (data.get("population")) {
      output.append($("<div><span class=\"phx-bold-style\"><span class=\"format-separator\">  -  </span>" + (data.get("population").libelle ? data.get("population").libelle : i18n.t('populationsmenu.tous')) + "</span></div>"));
    }

    if (!STR.isBlank(data.get("commentaire"))) {
      output.append($("<div class=\"ui-phx-ihm-texte-donnees-attenue\"><span class=\"format-separator\">  -  </span>" + data.get("commentaire") + "</div>"));
    }

    var hour_substr_len = data.get("heurecrea").toString().length >= 6 ? 4 : 3;
    var d = TYPE.DATE.strToDate(data.get("datecrea"));
    var strDate = d.getDate() + " " + i18n.t('common:monthNamesShortMin.' + d.getMonth()) + " " + d.getFullYear();

    output.append($("<div><span class=\"format-separator\">  -  </span><span>" + data.get("auteur").nomutil + "</span><span class=\"format-separator\">  -  </span><span class=\"ui-phx-ihm-texte-donnees-alternatif\">" + (useIcon === true ? "" : "<span class=\"dialog-enregistrer-content-icon ui-phx-simulation-icon\" />") + " " + i18n.t('plans.planresp.simulation_saveDialog_le') + " " + strDate + " " + i18n.t('plans.planresp.simulation_saveDialog_a') + " " + TYPE.HOUR_MINUTE_NIGHTLY.format(data.get("heurecrea").toString().substring(0, hour_substr_len)) + "</span></div>"));
    if (extended === true) {
      output.append($("<div><span class=\"format-separator-vertical\">  /  </span>" + (type === "F" ? i18n.t('plans.planresp.dialog_openplanning_clreference') : i18n.t('plans.planresp.sim_en_cours')) + "</div>"));
    }
    return output;
  },

  /**
   *
   */
  getSimulatedCellsByCollab: function(matric, a_module) {
    var selectedCells = $('[data-pk^="' + matric + ',"][data-simulation!="N"][data-simulation]', $("#" + a_module));

    return selectedCells;
  },

  /**
   * Returns days where the collab is in simulation
   */
  getSimulatedDaysByCollab: function(matric, a_module) {
    var selectedCells = SIMULATION.getSimulatedCellsByCollab(matric, a_module);
    var selectedDays = _.map(selectedCells, function(item) {
      var pk = $(item).data("pk");

      if (!STR.isBlank(pk) && pk.indexOf(",") > -1) {
        pk = pk.split(",");
        pk = pk[1];
      }
      return pk;
    });
    return selectedDays;
  },

  /**
   * Returns simulation state
   */
  getSimulationState: function(valeur) {
    var simulationState = {};

    if (valeur) {
      simulationState.valeur = valeur;
      simulationState.libelle = SIMULATION.simulationStates[valeur];
    }
    return simulationState;
  },

  /**
   * Returns event simulation state
   */
  getEvtSimulationState: function(pk, evtDatedeb, evtDatefin, a_module) {
    var collabData = pk.split(",");
    var collabSimDays = SIMULATION.getSimulatedDaysByCollab(collabData[0], a_module);
    var simu = collabSimDays.length > 0;
    var valeur = "";
    var simulationState = {};
    var collabInTout = true;
    var collabInAuMoinsUne = false;
    var eventDatedeb = TYPE.DATE.strToDate(evtDatedeb, TYPE.DATE.DEFAULT_MASK_BBDD);
    var eventDatefin = TYPE.DATE.strToDate(evtDatefin, TYPE.DATE.DEFAULT_MASK_BBDD);
    var dateDuJour = TYPE.DATE.strToDate(TYPE.DATE.getCurrentDate());
    var eventDatedebAux = new Date(eventDatedeb);

    while (eventDatedebAux <= eventDatefin) {
      var datedeb = TYPE.DATE.dateToStr(eventDatedebAux, TYPE.DATE.DEFAULT_MASK_BBDD);

      if (collabSimDays.indexOf(datedeb) > -1) {
        collabInAuMoinsUne = true;
      } else {
        collabInTout = false;
      }
      eventDatedebAux = UTILS.addDays(eventDatedebAux, 1);
    }
    if (!simu) {
      valeur = 1;
    } else if (!collabInAuMoinsUne && !collabInTout) {
      valeur = 2;
    } else if (collabInTout) {
      if (eventDatefin < dateDuJour) {
        valeur = 3;
      } else if (eventDatedeb >= dateDuJour) {
        valeur = 4;
      } else if (eventDatefin >= dateDuJour && eventDatedeb < dateDuJour) {
        valeur = 5;
      }
    } else if (collabInAuMoinsUne) {
      if (eventDatedeb >= dateDuJour) {
        valeur = 6;
      } else if (eventDatefin < dateDuJour) {
        valeur = 7;
      } else if (eventDatefin >= dateDuJour && eventDatedeb < dateDuJour) {
        valeur = 8;
      }
    }

    if (valeur) {
      simulationState.valeur = valeur;
      simulationState.libelle = SIMULATION.simulationStates[valeur];
    }

    return simulationState;
  },

  areAllCollaboratorsSelectNotSimu: function(a_collection, a_selection) {
    var lb_rtn = false;
    if (a_collection && a_collection.length > 0 && a_selection && a_selection.length > 0) {
      var lb_notSimu = true

      for (var k = 0; k < a_selection.length && lb_notSimu; k++) {
        if (!STR.isBlank(a_selection[k])) {
          var l_collab = a_selection[k].split(",")[0];
          if (a_collection.get(l_collab) && a_collection.get(l_collab).get("dts") &&
            a_collection.get(l_collab).get("dts").simu === true) {
            lb_notSimu = false;
          } else {
            lb_notSimu = true;
          }
        } else {
          lb_notSimu = true
        }
      }
      lb_rtn = lb_notSimu
    }
    return lb_rtn;
  }

};
