import { LOG } from './log.js';
import { objs } from 'src/objectsRepository';
import { STR } from './str.js';
import { TYPE } from 'tda/tda.js';
import _ from 'underscore';

/**
 * Generic Utilities
 */
export var UTILS = {

  global_loading_counter: 0,
  usecase_loading_counter: [],
  usecase_loading_exception: [],
  _isDownloadingFile: false,
  scrollWidth: null, //Var to save the scrollbar width,to avoit paint several times
  backboneProperties: ["_events", "_listeners", "_listeningTo", "_byId", "$el", "el", "i18n", "i18nCom"],

  _logear: function() {
    if (objs && objs.appRt && objs.appRt.workflow && objs.appRt.workflow.authModel) {
      objs.appRt.workflow.authModel.clear();
    }
    if (window.location.pathname.indexOf("login.html") === -1) {
      window.location.href = "./login/login.html";
    }
  },

  _isLoged: function() {
    if (!objs || !objs.appRt || !objs.appRt.workflow || !objs.appRt.workflow.authModel ||
      STR.isBlank(objs.appRt.workflow.authModel.get("user"))) {
      return false;
    }
    return true;
  },

  /**
   * Check if the application should be showed in a frame or not
   */
  isFramed: function() {
    var location = window.location.pathname;
    var result = false;

    if (location.indexOf("frame.html") > -1) {
      result = true;
    }
    return result;
  },

  /**
   * Returns the language stored as a cookie
   *
   */
  getLanguage: function() {
    var lRtn = Configuration.userlocale;

    if (objs && objs.appRt && objs.appRt.workflow && objs.appRt.workflow.configuration && objs.appRt.workflow.configuration.get("langue")) {
      lRtn = objs.appRt.workflow.configuration.get("langue").toLowerCase();
    }
    return lRtn;
  },

  /**
   * Returns a value from a cookie
   *
   */
  getCookieValue: function(key) {
    var currentcookie = document.cookie;
    var firstidx, lastidx;

    if (currentcookie.length > 0) {
      firstidx = currentcookie.indexOf(key + "=");
      if (firstidx !== -1) {
        firstidx = firstidx + key.length + 1;
        lastidx = currentcookie.indexOf(";", firstidx);
        if (lastidx === -1) {
          lastidx = currentcookie.length;
        }
        return unescape(currentcookie.substring(firstidx, lastidx));
      }
    }
    return "";
  },

  /**
   * Test sequence for authorized values, format: 1,2,5:8,12,... or *
   *
   */
  checkListOfValues: function(valueMin, valueMax, text) {
    var reponse = true;
    var existMin = !STR.isBlank(valueMin);
    var existMax = !STR.isBlank(valueMax);
    /*
     * Regular expression for sequences separated with comma with ranges
     * (x:y). Tokens could be natural numbers, set of letters or set of
     * numbers and letters Skeleton of RegExp: /^A(:A)?(,A(:A)?)*$/ A is
     * compose of A = B|C, where B is natural numbers (([+-]?\d)+) and C is
     * set of letters or set of numbers and letters ([\dA-Za-z]+)
     */
    var RegExPattern = /^(([+-]?\d)+|[\dA-Za-z]+)(:(([+-]?\d)+|[\dA-Za-z]+))?(,(([+-]?\d)+|[\dA-Za-z]+)(:(([+-]?\d)+|[\dA-Za-z]+))?)*$/;
    // Regular expressions for ranges in tokens
    var RegExPatternRange = /^(([+-]?\d)+|[\dA-Za-z]+):(([+-]?\d)+|[\dA-Za-z]+)$/;

    if (RegExPattern.test(text)) {
      var tokens = text.split(","); // Obtain each token from the text

      for (var i = 0; i < tokens.length; i++) {
        // Test if the token is a range (x:y)
        if (RegExPatternRange.test(tokens[i])) {
          var tokensRange = tokens[i].split(":"); // Obtain each endpoint from the range

          // Convert string to Int to compare
          if (!isNaN(tokensRange[0]) && !isNaN(tokensRange[1])) {
            tokensRange[0] = parseInt(tokensRange[0], 10);
            tokensRange[1] = parseInt(tokensRange[1], 10);
          }
          // Test if the range is ordered and if the endpoints are
          // between min & max
          if (tokensRange[0] > tokensRange[1] || existMin && tokensRange[0] < valueMin || existMax &&
            tokensRange[1] > valueMax) {
            reponse = false;
          }
          // Simple token (not a range)
          if (isNaN(tokensRange[0]) || isNaN(tokensRange[1])) {
            reponse = false;
          }
        } else {
          // Test if the token is between min & max
          if ((existMin && tokens[i] < valueMin) || (existMax && tokens[i] > valueMax)) {
            reponse = false;
          }
          if (isNaN(tokens[i])) {
            reponse = false;
          }
        }
      }
      // text could be '*' also, that means everyday, every month, ...
    } else if (text === "*") {
      reponse = true;
    } else {
      reponse = false;
    }
    return reponse;
  },

  /**
   * Add a number of days to the current date
   */
  addDays: function(date, numDays) {
    date.setDate(date.getDate() + numDays);
    return date;
  },

  /**
   * Add a number of days to the current date in BBDD format
   */
  addDaysString: function(date, numDays) {
    var dateAux = TYPE.DATE.strToDate(date);
    var newDate = null;

    UTILS.addDays(dateAux, numDays);
    newDate = TYPE.DATE.dateToStr(dateAux, TYPE.DATE.DEFAULT_MASK_BBDD);
    return newDate;
  },

  disableKeys: function(event) {
    event.preventDefault();
    return false;
  },

  /**
   * Puts the wait icon in usecase
   */
  showUsecaseHidder: function(usecase) {
    var targetUc = "";
    var newId = "";
    var l_exist = false;
    var $usecase = null;
    var $usecaseId = null;
    var isPortlet = false;
    var isDownloadingFile = false;
    var dialogInDialog = false;

    try {
      newId = Backbone.history.getFragment().split("/")[3];
      targetUc = newId.split("_")[0];
    } catch (e) {
      // Do nothing
    }
    if (usecase === targetUc && usecase !== newId && newId !== "agenda_R") {
      usecase = newId;
    }
    try {
      $usecase = $("#" + usecase);
    } catch (e) {
      usecase = this.escapeJQueryString(usecase);
      $usecase = $("#" + usecase);
    }
    $usecase = $("#" + usecase);
    $usecaseId = $("#" + usecase); //pour ne pas faire autres recherches sur le DOM

    if ($usecase.length === 0) {
      if (!_.isString(usecase) || (_.isString(usecase) && usecase.length === 0)) {
        LOG.error("Error usecase is not defined");
      } else if ($("div.ui-dialog-content." + usecase + ":visible").length !== 0) {
        // corrects hidder dialog in dialog
        $usecase = $("div.ui-dialog-content." + usecase + ":visible").last();
        dialogInDialog = true;
      } else {
        $usecase = $(".phx-Dialog div." + usecase);
      }
    }

    isPortlet = $usecaseId.hasClass("phx-portlet");
    if ($usecase.length === 0) {
      if (!_.isString(usecase) || (_.isString(usecase) && usecase.length === 0)) {
        LOG.error("Error usecase is not defined");
      } else {
        $usecase = $("div." + usecase);
      }
    }
    if ($usecase.length !== 0) {
      var showHidder = null;

      isDownloadingFile = $usecase.hasClass("pieceJointeComponentContainer");
      UTILS.usecase_loading_counter[usecase] = UTILS.usecase_loading_counter[usecase] || 0;
      showHidder = (UTILS.usecase_loading_exception[usecase] !== false);
      if (UTILS.usecase_loading_counter[usecase] < 0) {
        UTILS.usecase_loading_counter[usecase] = 0;
      }
      l_exist = ($usecase.find(".phx-uc-hidder-left.phx-icon-hidder") && $usecase.find(".phx-uc-hidder-left.phx-icon-hidder").length > 0) ? true : false;
      if (!STR.isBlank(objs) && showHidder && (UTILS.usecase_loading_counter[usecase] === 0 || !l_exist)) {
        var hidder = $("<div class='phx-uc-hidder-left phx-icon-hidder'>");

        if (isPortlet) {
          hidder.addClass("cw-hidder_portlet");
        }
        if ($usecase && $usecase.offset()) {
          var topOffset = $usecase.offset().top;
          var height = $usecase.height() !== 0 ? $usecase.height() + "px" : "100%";
          var parentsDialog = $usecase.parents(".phx-Dialog");
          var isElementUsecase = $usecaseId.hasClass("cw-elementUsecase");

          $usecase.find("table").keydown(this.disableKeys);
          if (objs.appRt && objs.appRt.workflow && !STR.isBlank(objs.appRt.workflow.get("zone"))) {
            topOffset = $usecase.offset().top - $("#phx-zone-" + objs.appRt.workflow.get("zone")).offset().top;
            if (topOffset < 0) {
              topOffset = 0;
            }
            hidder.css({
              //display: "none",
              height: height,
              top: topOffset + "px"
            });
          }
          //On dialogs dont calculate the offset block all the dialog. Idem on briques.
          if (isPortlet || (parentsDialog && parentsDialog.length > 0)) {
            if (isPortlet && $usecase.attr("aria-expanded") === "true") {
              height = "100%";
            }
            topOffset = 0;
            hidder.css({
              //display: "none",
              height: height,
              top: topOffset + "px"
            });
          }
          if (isElementUsecase) {
            height = "100%";
            if ($usecaseId.height() < 150) { //à utiliser uniquement si la hauteur de la zone est inférieure à 150 et si la zone ne pourra pas s'agrandir
              hidder.addClass("cw-elementHidderReduit");
            }
            topOffset = 0;
            hidder.css({
              //display: "none",
              height: height,
              top: topOffset + "px"
            });
          }
          if (isDownloadingFile && $usecase.length > 0) {
            topOffset = $usecase.position().top;
            hidder.css({ top: topOffset + "px" });
          }
          if ((parentsDialog && parentsDialog.length > 0) && isDownloadingFile !== true) {
            var heightDialog = parentsDialog.outerHeight() !== 0 ? parentsDialog.outerHeight() + "px" : "100%";

            //il faut vérifier les types avant de comparer
            if (heightDialog.indexOf("px") >= 0 && typeof height === "string" && height.indexOf("px") >= 0) {
              //"500"ou "500px" n'est plus grand que "7" et "7px" --> "5" est inferieur à "7" ->tout est texte
              if (parseInt(heightDialog.split("px")[0], 10) > parseInt(height.split("px"), 10)) {
                height = heightDialog;
              }
            } else if (heightDialog.indexOf("%") >= 0 && typeof height === "string" && height.indexOf("%") >= 0) {
              //"500"ou "500px" n'est plus grand que "7" et "7px" --> "5" est inferieur à "7" ->tout est texte
              if (parseFloat(heightDialog.split("%")[0]) > parseFloat(height.split("%"))) {
                height = heightDialog;
              }
            } //dans autre cas, "height" sera utilisé
            hidder.css({
              //display: "none",
              height: height,
              top: topOffset + "px",
              "margin-left": -24
            });
          }
          if (dialogInDialog) {
            hidder.css({
              top: "0px",
              left: "0px",
              height: "100%"
            });
          }
          $usecase.append(hidder);
          //cette option d'exécuter le "timeout"  avec display "none" et après "block", c'est pour le donner de temps au événement "click" si avant
          //il y a un événement "blur" avec fetch's, on mettrait cette "hidder" et on ne cliquerait jamais sur le bouton la première fois (Il serait comme faire deux click sur
          // le bouton-> avec cette modification est corrigé et il ne faut qu'un click)
          // setTimeout(function() {
          //   hidder.css({ display: "block" });
          // }, 280);
        }
      }
      if (!STR.isBlank(objs) && showHidder && $usecase && $usecase.offset()) {
        UTILS.usecase_loading_counter[usecase]++;
      }
      LOG.debug("--> showUsecaseHidder (" + usecase + ") : " + UTILS.usecase_loading_counter[usecase]);
    }
  },

  /**
   * Transform text to a valid string JQuery
   */
  escapeJQueryString: function(expression) {
    var l_expression = expression;

    if (!STR.isBlank(l_expression)) {
      l_expression = l_expression.replace(/ /g, '_');
      l_expression = l_expression.replace(/[@]/g, '-1-');
      l_expression = l_expression.replace(/["]/g, '-2-');
      l_expression = l_expression.replace(/[#]/g, '-3-');
      l_expression = l_expression.replace(/[$]/g, '-4-');
      l_expression = l_expression.replace(/[%]/g, '-5-');
      l_expression = l_expression.replace(/[&]/g, '-6-');
      l_expression = l_expression.replace(/[']/g, '-7-');
      l_expression = l_expression.replace(/[(]/g, '-8-');
      l_expression = l_expression.replace(/[)]/g, '-9-');
      l_expression = l_expression.replace(/[*]/g, '-10-');
      l_expression = l_expression.replace(/[+]/g, '-11-');
      l_expression = l_expression.replace(/[,]/g, '-12-');
      l_expression = l_expression.replace(/[.]/g, '-13-');
      l_expression = l_expression.replace(/[\/]/g, '-14-'); //eslint-disable-line
      l_expression = l_expression.replace(/[:]/g, '-15-');
      l_expression = l_expression.replace(/[;]/g, '-16-');
      l_expression = l_expression.replace(/[<]/g, '-17-');
      l_expression = l_expression.replace(/[=]/g, '-18-');
      l_expression = l_expression.replace(/[>]/g, '-19-');
      l_expression = l_expression.replace(/[?]/g, '-20-');
      l_expression = l_expression.replace(/[!]/g, '-21-');
      l_expression = l_expression.replace(/[\[]/g, '-22-'); //eslint-disable-line
      l_expression = l_expression.replace(/[\\]/g, '-23-');
      l_expression = l_expression.replace(/[\]]/g, '-24-');
      l_expression = l_expression.replace(/[\^]/g, '-25-');
      l_expression = l_expression.replace(/[`]/g, '-26-');
      l_expression = l_expression.replace(/[{]/g, '-27-');
      l_expression = l_expression.replace(/[|]/g, '-28-');
      l_expression = l_expression.replace(/[}]/g, '-29-');
      l_expression = l_expression.replace(/[~]/g, '-30-');
      l_expression = l_expression.replace(/[á]/g, '-31-');
      l_expression = l_expression.replace(/[é]/g, '-32-');
      l_expression = l_expression.replace(/[í]/g, '-33-');
      l_expression = l_expression.replace(/[ó]/g, '-34-');
      l_expression = l_expression.replace(/[ú]/g, '-35-');
      l_expression = l_expression.replace(/[ñ]/g, '-36-');
      l_expression = l_expression.replace(/[à]/g, '-37-');
      l_expression = l_expression.replace(/[è]/g, '-38-');
      l_expression = l_expression.replace(/[ì]/g, '-39-');
      l_expression = l_expression.replace(/[ò]/g, '-40-');
      l_expression = l_expression.replace(/[ù]/g, '-41-');
      l_expression = l_expression.replace(/[â]/g, '-42-');
      l_expression = l_expression.replace(/[ê]/g, '-43-');
      l_expression = l_expression.replace(/[î]/g, '-44-');
      l_expression = l_expression.replace(/[ô]/g, '-45-');
      l_expression = l_expression.replace(/[û]/g, '-46-');
      l_expression = l_expression.replace(/[ä]/g, '-47-');
      l_expression = l_expression.replace(/[ë]/g, '-48-');
      l_expression = l_expression.replace(/[ï]/g, '-49-');
      l_expression = l_expression.replace(/[ö]/g, '-50-');
      l_expression = l_expression.replace(/[ü]/g, '-51-');
    } else {
      LOG.warn("Parameter of escapeJQueryString was null");
    }

    return l_expression;
  },

  /**
   * Escape special chars in selector to form a valid JQuery selector.
   * <p>Escape chars:<p>
   */
  addScapeSlashesForJQuerySelector: function(selector) {
    return selector.replace(/( |@|"|#|\$|%|&|'|\(|\)|\*|\+|,|\.|\/|:|;|<|=|>|\?|!|\[|\\|\]|\^|`|{|\||}|~)/g, "\\$1");
  },

  /**
   * Remove the wait icon in usecase
   */
  hideUsecaseHidder: function(usecase) {
    var targetUc = "";
    var newId = "";
    var $usecaseId = null;
    var $usecaseClass = null

    try {
      newId = Backbone.history.getFragment().split("/")[3];
      targetUc = newId.split("_")[0];
    } catch (e) {
      // Do nothing
    }
    if (usecase === targetUc && usecase !== newId && newId !== "agenda_R") {
      LOG.debug("hideusecase --change usecase--- " + usecase + "-->" + newId);
      usecase = newId;
    }
    if (!STR.isBlank(UTILS.usecase_loading_counter[usecase])) {
      UTILS.usecase_loading_counter[usecase]--;
      LOG.debug("--> hideUsecaseHidder (" + usecase + ") : " + UTILS.usecase_loading_counter[usecase]);
      if (UTILS.usecase_loading_counter[usecase] <= 0) {
        var $lPosId = null;

        $usecaseId = $("#" + usecase);
        $usecaseClass = $("." + usecase);
        $usecaseId.find("table").unbind("keydown", this.disableKeys);
        $usecaseClass.find("table").unbind("keydown", this.disableKeys);
        $lPosId = $usecaseId.find(".phx-uc-hidder-left");
        if ($lPosId && $lPosId.length > 1 && usecase.indexOf("ElementHidder") < 0) {
          //pour ne pas enlever le hidder de élément si l'il y a
          for (var i = 0; i < $lPosId.length; i++) {
            var lIdUse = $($lPosId[i]).parent().attr("id");

            if (lIdUse === usecase && lIdUse.indexOf("ElementHidder") < 0) {
              $($lPosId[i]).remove();
            }
          }
        } else {
          $lPosId.remove();
        }
        $usecaseClass.find(".phx-uc-hidder-left").remove();
        UTILS.usecase_loading_counter[usecase] = 0;
        LOG.debug("--> assign to 0: hideUsecaseHidder (" + usecase + ") : " + UTILS.usecase_loading_counter[usecase]);
        if (objs && objs.appRt && objs.appRt.workflow) {
          objs.appRt.workflow.trigger("hidderEnd:" + usecase);
        }
      }
    }
  },

  /**
   * Discount token number called webservice
   */
  removeHidder: function() {
    UTILS.global_loading_counter--;
    LOG.debug("--> removeHidder : " + UTILS.global_loading_counter);
    if (UTILS.global_loading_counter <= 0) {
      $(document.body).find(".phx-uc-hidder-left").remove();
      UTILS.global_loading_counter = 0;
    }
  },

  /**
   * Set token number called webservice to 0
   */
  resetHidder: function() {
    UTILS.global_loading_counter = 0;
    UTILS.usecase_loading_counter = [];
    LOG.debug("--> resetHidder : " + UTILS.global_loading_counter);
    $(document.body).find(".phx-uc-hidder-left").remove();

    //CUSTOMER 170849
    if (objs && objs.appRt && objs.appRt.workflow && !STR.isBlank(objs.appRt.workflow.get("usecase"))) {
      $("#" + objs.appRt.workflow.get("usecase")).find("table").unbind("keydown", this.disableKeys);
    }
  },

  /**
   * Clone the one array in other array
   */
  cloneArray: function(obj) {
    var out = [];
    var i = null;

    if (Object.prototype.toString.call(obj) === '[object Array]') {
      var len = obj.length;

      for (i = 0; i < len; i++) {
        out[i] = UTILS.cloneArray(obj[i]);
      }
      return out;
    }
    if (typeof obj === 'object') {
      for (i in obj) {
        if (i) {
          out[i] = UTILS.cloneArray(obj[i]);
        }
      }
      return out;
    }
    return obj;
  },
  /**
   * Return the number of weeks of the month for the year and month passed
   */
  getNumWeeksForMonth: function(month, year) {
    var firstOfMonth = new Date(year, month - 1, 1);
    var days = UTILS.daysInMonth(month - 1, year);
    var weeks = 0;

    if (firstOfMonth.getDay() !== 1) {
      weeks++;
    }
    for (var i = 1; i <= days; i++) {
      var loopDate = new Date(year, month - 1, i);
      var newWeek = loopDate.getDay();

      if (newWeek === 1) {
        weeks++;
      }
    }
    return weeks;
  },
  /**
   * Return the number of week in a Date passed
   */
  getWeekNumOfDate: function(d) {
    var year = d.getFullYear();
    var month = d.getMonth();
    var firstOfMonth = new Date(year, month, 1);
    var week = 0;

    if (firstOfMonth.getDay() !== 1) {
      week++;
    }
    for (var i = 1; i <= d.getDate(); i++) {
      var loopDate = new Date(year, month, i);
      var newWeek = loopDate.getDay();

      if (newWeek === 1) {
        week++;
      }
    }
    return week;
  },
  /**
   * Return the numbers of days of the month for the year and month passed
   */
  daysInMonth: function(month, year) {
    return new Date(year, month + 1, 0).getDate();
  },

  /**
   * Delete the object in memory with the close a usecase
   */
  backboneObjectRecollector: function(obj) {
    if (obj && !STR.isBlank(obj.module)) {
      $(document).off("click." + obj.module); //on supprime le "event" "click" sur le document si l'il existe en utilisant un "namespace"
      $(document).off("mousedown." + obj.module); //on supprime le "event" "mousedown" sur le document si l'il existe
      $(document).off("mouseup." + obj.module); //on supprime le "event" "mousedown" sur le document si l'il existe
      $(document).off("mousemove." + obj.module); //on supprime le "event" "mousemove" sur le document si l'il existe
      $(document).off("wheel." + obj.module); //on supprime le "event" "wheel" sur le document si l'il existe
      $(document).off("mousewheel." + obj.module); //on supprime le "event" "mousewheel" sur le document si l'il existe
    }
    this._recursiveRecollector(obj);
  },

  /**
   * Delete the object in memory with the close a usecase (In recursive)
   */
  _recursiveRecollector: function(obj) {
    var self = this;

    _.each(obj, function(value, key, parent) {
      if (self._isRecollectableValue(value, key)) {
        if (self._isBackboneObject(parent[key])) {
          parent[key] = undefined;
        }
        self._resetBackboneObject(value);
      }
    });
  },

  _isBackboneObject: function(value) {
    var isBackb = false;

    if (value instanceof Backbone.Model) {
      isBackb = true;
    } else if (value instanceof Backbone.Collection) {
      isBackb = true;
    } else if (value instanceof Backbone.View) {
      isBackb = true;
    }
    return isBackb;
  },

  /**
   * Decides if an object should be recollected
   */
  _isRecollectableValue: function(value, key) {
    var recollectable = false;

    if ((_.isObject(value) || _.isArray(value)) && !_.isEmpty(value) && value.recollected !== true) {
      if (!_.contains(this.backboneProperties, key)) {
        recollectable = true;
      }
    }
    return recollectable;
  },

  /**
   * Reset a objects of BackBone (Backbone.Model, Backbone.Collection, Backbone.View, Array...)
   */
  _resetBackboneObject: function(value) {
    if (value && value.recollected !== true) {
      if (value instanceof Backbone.Model) {
        // added to don't treat the same obj again
        value.recollected = true;
        value.off();
        value.stopListening();
        if (value.clear) {
          value.clear({ silent: true });
        }
        this._recursiveRecollector(value);
      } else if (value instanceof Backbone.Collection) {
        // added to don't treat the same obj again
        value.recollected = true;
        value.off();
        value.stopListening();
        this._recursiveRecollector(value);
      } else if (value instanceof Backbone.View) {
        // added to don't treat the same obj again
        value.recollected = true;
        $(document).off("click." + value.cid); //on supprime le "event" "click" sur le document si l'il existe en utilisant un "namespace"
        $(document).off("mousedown." + value.cid); //on supprime le "event" "mousedown" sur le document si l'il existe
        $(document).off("mouseup." + value.cid); //on supprime le "event" "mousedown" sur le document si l'il existe
        $(document).off("mousemove." + value.cid); //on supprime le "event" "mousemove" sur le document si l'il existe
        $(document).off("wheel." + value.cid); //on supprime le "event" "wheel" sur le document si l'il existe
        $(document).off("mousewheel." + value.cid); //on supprime le "event" "mousewheel" sur le document si l'il existe
        //---------------
        $(document).off("click." + value.id); //on supprime le "event" "click" sur le document si l'il existe en utilisant un "namespace"
        $(document).off("mousedown." + value.id); //on supprime le "event" "mousedown" sur le document si l'il existe
        $(document).off("mouseup." + value.id); //on supprime le "event" "mousedown" sur le document si l'il existe
        $(document).off("mousemove." + value.id); //on supprime le "event" "mousemove" sur le document si l'il existe
        $(document).off("wheel." + value.id); //on supprime le "event" "wheel" sur le document si l'il existe
        $(document).off("mousewheel." + value.id); //on supprime le "event" "mousewheel" sur le document si l'il existe
        value.remove();
        this._recursiveRecollector(value);
      } else if (_.isArray(value)) {
        for (var i in value) {
          if (i) {
            var obj = value[i];

            value[i] = null;
            this._resetBackboneObject(obj);
          }
        }
      } else if (_.isObject(value)) {
        this._recursiveRecollector(value);
      }
    }
  },

  /**
   *
   */
  stringToBoolean: function(original) {
    var stringValue = String(original).toLowerCase();

    if (stringValue === "true") {
      return true;
    } else if (stringValue === "false") {
      return false;
    } else {
      return NaN;
    }
  },

  /**
   * Clones a object
   */
  cloneObj: function(obj) {
    var jsonObj = JSON.stringify(obj);
    var newObj = JSON.parse(jsonObj);

    return newObj;
  },

  /**
   * getTextWidth (pour basegrid)
   */
  getTextWidth: function(width) {
    if (width && !_.isNumber(width) && width.indexOf("ch") > 0 && UTILS.isIE()) {
      var number = width.replace("ch", "");

      number *= 1.21;
      return number + "ch";
    }
    return width;
  },

  /**
   * isIE
   */
  isIE: function() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ') > 0;
    var ie11 = ua.indexOf('Trident/') > 0;
    var ie12 = ua.indexOf('Edge/') > 0;

    return msie || ie11 || ie12;
  },

  isFirefox: function() {
    var ua = window.navigator.userAgent;

    return ua.indexOf('Firefox/') > 0
  },

  isChrome: function() {
    return (window.navigator.userAgent.toLowerCase().indexOf('chrome') >= 0 && window.navigator.vendor.toLowerCase().indexOf("google") >= 0 && window.navigator.userAgent.toLowerCase().indexOf('edg') < 0);
  },

  isInteger: function(value) {
    return typeof value === "number" && isFinite(value) && Math.floor(value) === value;
  },

  /**
   * Performs a binary search on the host array. This method can either be
   * injected into Array.prototype or called with a specified scope like this:
   * binaryIndexOf.call(someArray, searchElement);
   *
   */
  binaryIndexOf: function(someArray, searchElement, f_comparator) {
    if (_.isArray(someArray) && !STR.isBlank(searchElement)) {
      var minIndex = 0;
      var maxIndex = someArray.length - 1;
      var currentIndex = null;
      var currentElement = null;
      var previousElement = null;
      var l_rtn = null;
      var l_func_aux = function(currentElement, searchElement) {
        if (currentElement < searchElement) {
          return -1;
        } else if (currentElement > searchElement) {
          return 1
        } else {
          return 0;
        }
      };

      while (minIndex <= maxIndex) {
        currentIndex = (minIndex + maxIndex) / 2 | 0;
        currentElement = someArray[currentIndex];
        if (currentIndex > 0) {
          previousElement = someArray[currentIndex - 1];
        } else {
          previousElement = null;
        }
        l_rtn = null;
        if (f_comparator) {
          l_rtn = f_comparator(currentElement, searchElement, previousElement);
        } else {
          l_rtn = l_func_aux(currentElement, searchElement, previousElement)
        }
        if (l_rtn === -1) {
          minIndex = currentIndex + 1;
        } else if (l_rtn === 1) {
          maxIndex = currentIndex - 1;
        } else {
          return currentIndex;
        }
      }
    }
    return -1;
  },

  /**
   * getWidthTextHTML (pour tout élément HTML)
   */
  getWidthTextHTML: function(a_element) {
    var lRtn = 0;
    var lItem = a_element;

    if (!STR.isBlank(a_element)) {
      var mirror = $("body .cloned_elements .autresElements_clone_elements");
      var padding = 0;

      if ((typeof a_element) === "string") {
        lItem = $("<div>").html(a_element);
      }
      padding = parseInt(lItem.css("padding-left").replace("px", "")) + parseInt(lItem.css("padding-right").replace("px", ""));
      mirror.append(lItem);
      lRtn = mirror.width();
      if (!_.isNaN(padding) && _.isNumber(padding)) {
        lRtn += padding;
      }
      mirror.empty(); //clean once it has been used to calculate width
    }
    return lRtn;
  },

  /**
   * Returns the HTML need to render a SVG icon
   * taken from the generated sprite from the exisitng icons
   * at src/img/icons folder
   *
   * @method
   * @name getSVGIcon
   * @param {string} aNomIcon name of the icon to render (required)
   * @param {string} aAddClass additional class to add to the returned HTML (optional)
   * @param {number} aSize size in px for the icon (optional)
   */
  getSVGIcon: function(aNomIcon, aAddClass = null, aSize = null, aInfobulle = null, aFocusable = false, aSansPreserveAspect = false, aContentDiv = false, aClassFourSVG = null) {
    var lRtn = null;

    if (!STR.isBlank(aNomIcon)) {
      var lUrl = "assets/images/icons.svg";
      var lAddClass = !STR.isBlank(aAddClass) ? aAddClass : "";
      var lSize = aSize;
      var lTitle = "";
      var lFocusable = "";
      var lAriaLabel = "";

      if (!STR.isBlank(lAddClass) && typeof aAddClass === "object") {
        lAddClass = aAddClass.toString().replace(",", " ");
      }
      if (!STR.isBlank(lSize)) {
        lSize = lSize.toString().replace("px", "");
        lAddClass += " cw-icon-" + lSize;
      }
      if (!STR.isBlank(aInfobulle)) {
        lTitle = "title='" + STR.convertToAscii(aInfobulle) + "'"; //Changé le guillemet double par le simple pour les texte qui ont déjà quelqu'un de type simple(ex. title='"teste d'exemple"').
        lAriaLabel = "aria-label='" + STR.convertToAscii(aInfobulle) + "'";
      }
      if (window.location.pathname.indexOf("/login.html") > -1) {
        lUrl = "../" + lUrl;
      }
      if (aFocusable === true) {
        lFocusable = "tabindex='0'";
        lAddClass += " cw-triggerClick";
      }
      if (aSansPreserveAspect === true) {
        //sans la classe "cw-icon et avec la proprieté "preserveAspectRatio='none'""
        if (aContentDiv === true) {
          lRtn = "<div class='" + lAddClass + " ctime-sprite-icon' " + lTitle + " " + lFocusable + ">" +
            "<svg role='img' " + lAriaLabel + (!STR.isBlank(aClassFourSVG) ? " class='" + aClassFourSVG + "'" : "") + " preserveAspectRatio='none'><use xlink:href='" + lUrl + "#sprite-" + aNomIcon + "'></use></svg>" +
            "</div>";
        } else {
          lRtn = "<span class='" + lAddClass + " ctime-sprite-icon' " + lTitle + " " + lFocusable + ">" +
            "<svg role='img' " + lAriaLabel + (!STR.isBlank(aClassFourSVG) ? " class='" + aClassFourSVG + "'" : "") + " preserveAspectRatio='none'><use xlink:href='" + lUrl + "#sprite-" + aNomIcon + "'></use></svg>" +
            "</span>";
        }
      } else {
        if (aContentDiv === true) {
          lRtn = "<div class='" + lAddClass + " ctime-sprite-icon cw-icon' " + lTitle + " " + lFocusable + ">" +
            "<svg role='img' " + lAriaLabel + (!STR.isBlank(aClassFourSVG) ? " class='" + aClassFourSVG + "'" : "") + " ><use xlink:href='" + lUrl + "#sprite-" + aNomIcon + "'></use></svg>" +
            "</div>";
        } else {
          lRtn = "<span class='" + lAddClass + " ctime-sprite-icon cw-icon' " + lTitle + " " + lFocusable + ">" +
            "<svg role='img' " + lAriaLabel + (!STR.isBlank(aClassFourSVG) ? " class='" + aClassFourSVG + "'" : "") + " ><use xlink:href='" + lUrl + "#sprite-" + aNomIcon + "'></use></svg>" +
            "</span>";
        }
      }
    }
    return lRtn;
  },

  firstLetterToUpperCase: function(text) {
    var lRtn = text;

    if (!STR.isBlank(text)) {
      if (text.length === 1) {
        lRtn = lRtn.toUpperCase();
      } else {
        lRtn = lRtn.charAt(0).toUpperCase() + lRtn.slice(1);
      }
    }
    return lRtn;
  },

  /**
   * Calculate the width of scroll bar in one element of if don't provide
   * element arg calculate an a global width
   *
   * @param {JQuery} element
   */
  getScrollbarWidth: function(element) {
    var inner = null;

    if (element) {
      var widthOfScroll = null;

      inner = $('<div>');
      //Create child inside to calculate
      element.append(inner);
      widthOfScroll = element[0] ? (element[0].offsetWidth - inner[0].offsetWidth) : null;
      inner.remove();
      //Remove temporaly child
      return widthOfScroll;
    } else {
      if (STR.isBlank(UTILS.scrollWidth)) {
        var outer = document.createElement('div');

        // Creating invisible container
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll'; // forcing scrollbar to appear
        outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
        document.body.appendChild(outer);
        // Creating inner element and placing it in the container
        inner = document.createElement('div');
        outer.appendChild(inner);
        // Calculating difference between container's full width and the child width
        UTILS.scrollWidth = (outer.offsetWidth - inner.offsetWidth);
        // Removing temporary elements from the DOM
        outer.parentNode.removeChild(outer);
      }
      return UTILS.scrollWidth;
    }
  },

  // Check if the element was visible with the scrolls
  isElementVisible: function(element, fullyDisplayed) {
    if (element) {
      var pageTop = $(window).scrollTop();
      var pageBottom = pageTop + $(window).height();
      var elementTop = $(element).offset().top;
      var elementBottom = elementTop + $(element).height();

      if (fullyDisplayed === true) {
        return ((pageTop < elementTop) && (pageBottom > elementBottom));
      } else {
        return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
      }
    } else {
      LOG.warn("I can't check if the element is visible if you have not provided me an element!");
    }
    return false;
  },

  makeIdRandom: function(len) {
    var result = "";
    var arr = new Uint8Array((len || 40) / 2);

    (window.crypto || window.msCrypto).getRandomValues(arr) //window.msCrypto--> pour IE11
    result = Array.from(arr, function(dec) {
      return dec.toString(16).padStart(2, "0");
    }).join('');
    return result;
  },

  isDownloadingFile: function() {
    return UTILS._isDownloadingFile;
  },

  /**
   * Download file in async mode, when download propt to the user for save it.
   *
   * @param {string} urlToFile the path to the file
   * @param {string} filename the name of the file (if is null try to get the filename from server)
   * @param {string} classForHidder optional class for set a hidder over div to prevent multiple clicks
   */
  downloadFileWithAjaxAsync: function(urlToFile, filename, classForHidder) {
    try {
      if (!STR.isBlank(classForHidder)) {
        UTILS.showUsecaseHidder(classForHidder);
      }
      UTILS._isDownloadingFile = true;
      // Ok lets to download the file, because we expose the token on the url i make this methos
      // to download the file with Ajax.
      //Call to the HTMl 5 function "fetch(url)"
      fetch(urlToFile).then(resp => { //Tell when get response call to the blob function object
        if (!filename && resp.headers.get("content-disposition")) { //If don't receive filename try to get form headers
          filename = resp.headers.get("content-disposition").substr(resp.headers.get("content-disposition").indexOf('filename="') + String('filename="').length).slice(0, -1);
        }
        resp.blob().then(blob => { //We get the blob object on memory and now work with it
          var url = window.URL.createObjectURL(blob); //First assign an a url
          var a = document.createElement('a'); //Now create an a link object

          a.style.display = 'none';
          a.href = url;
          // the filename you want
          a.download = filename;
          document.body.appendChild(a); //Append to the body
          a.click(); //And do a click
          window.URL.revokeObjectURL(url); //Remove the URL
          if (!STR.isBlank(classForHidder)) {
            UTILS.hideUsecaseHidder(classForHidder);
          }
          UTILS._isDownloadingFile = false;
        })
      }).catch((excep) => {
        // Something was wrong... do it in the old mode...
        if (!STR.isBlank(classForHidder)) {
          UTILS.hideUsecaseHidder(classForHidder);
        }
        UTILS._isDownloadingFile = false;
        LOG.error("I can't download the file, may be the file is TOO big? I'm try use an insecure method..\nError: " + excep);
        window.open(urlToFile, "_blank");
      });
    } catch (e) {
      if (!STR.isBlank(classForHidder)) {
        UTILS.hideUsecaseHidder(classForHidder);
      }
      UTILS._isDownloadingFile = false;
      LOG.error("I can't download the file, you don't have an update browser? I'm try use an insecure method.");
      window.open(urlToFile, "_blank");
    }
  },

  _getCSSFromClass: function(nameClass, propCss) {
    var styleRtn = "";
    var posClonedElements = $("body .cloned_elements .autresElements_clone_elements");

    if (!STR.isBlank(nameClass) && !STR.isBlank(propCss)) {
      posClonedElements.addClass(nameClass);
      styleRtn = posClonedElements.css(propCss);
      posClonedElements.removeClass(nameClass);
    }
    return styleRtn;
  },

  resizeUsecaseHidder: function(usecase) {
    var targetUc = "";
    var newId = "";
    var l_exist = false;
    var $usecase = null;
    var $usecaseId = null;

    try {
      newId = Backbone.history.getFragment().split("/")[3];
      targetUc = newId.split("_")[0];
    } catch (e) {
      // Do nothing
    }
    if (usecase === targetUc && usecase !== newId && newId !== "agenda_R") {
      usecase = newId;
    }
    try {
      $usecase = $("#" + usecase);
    } catch (e) {
      usecase = this.escapeJQueryString(usecase);
      $usecase = $("#" + usecase);
    }
    $usecase = $("#" + usecase);
    $usecaseId = $("#" + usecase); //pour ne pas faire autres recherches sur le DOM
    if ($usecase.length === 0) {
      if (!_.isString(usecase) || (_.isString(usecase) && usecase.length === 0)) {
        LOG.error("Error usecase is not defined");
      } else {
        $usecase = $(".phx-Dialog div." + usecase);
      }
    }
    if ($usecase.length === 0) {
      if (!_.isString(usecase) || (_.isString(usecase) && usecase.length === 0)) {
        LOG.error("Error usecase is not defined");
      } else {
        $usecase = $("div." + usecase);
      }
    }
    if ($usecase.length !== 0) {
      var showHidder = null;
      var $lPosHidder = $usecase.find(".phx-uc-hidder-left.phx-icon-hidder");

      UTILS.usecase_loading_counter[usecase] = UTILS.usecase_loading_counter[usecase] || 0;
      showHidder = (UTILS.usecase_loading_exception[usecase] !== false);
      if (UTILS.usecase_loading_counter[usecase] < 0) {
        UTILS.usecase_loading_counter[usecase] = 0;
      }
      l_exist = ($lPosHidder && $lPosHidder.length > 0) ? true : false;
      if (!STR.isBlank(objs) && showHidder && UTILS.usecase_loading_counter[usecase] > 0 && l_exist) {
        var hidder = $lPosHidder;

        if ($usecase && $usecase.offset()) {
          var isPortlet = $usecaseId.hasClass("phx-portlet");
          var topOffset = $usecase.offset().top;
          var height = $usecase.height() !== 0 ? $usecase.height() + "px" : "100%";
          var parentsDialog = $usecase.parents(".phx-Dialog");
          var isDownloadingFile = $usecase.hasClass("pieceJointeComponentContainer");

          $usecase.find("table").keydown(this.disableKeys);
          if (objs.appRt && objs.appRt.workflow && !STR.isBlank(objs.appRt.workflow.get("zone"))) {
            topOffset = $usecase.offset().top - $("#phx-zone-" + objs.appRt.workflow.get("zone")).offset().top;
            if (topOffset < 0) {
              topOffset = 0;
            }
            for (let i = 0; i < hidder.length; i++) {
              if (!$(hidder[i]).hasClass("cw-elementHidderReduit")) {
                $(hidder[i]).css({
                  //display: "none",
                  height: height,
                  top: topOffset + "px"
                });
              }
            }
          }
          //On dialogs dont calculate the offset block all the dialog. Idem on briques.
          if (isPortlet || (parentsDialog && parentsDialog.length > 0)) {
            if ((isPortlet && $usecase.attr("aria-expanded") === "true")) {
              height = "100%";
            }
            topOffset = 0;
            hidder.css({
              //display: "none",
              height: height,
              top: topOffset + "px"
            });
          }
          if ((parentsDialog && parentsDialog.length > 0) && isDownloadingFile !== true) {
            var heightDialog = parentsDialog.outerHeight() !== 0 ? parentsDialog.outerHeight() + "px" : "100%";

            //il faut vérifier les types avant de comparer
            if (heightDialog.indexOf("px") >= 0 && typeof height === "string" && height.indexOf("px") >= 0) {
              //"500"ou "500px" n'est plus grand que "7" et "7px" --> "5" est inferieur à "7" ->tout est texte
              if (parseInt(heightDialog.split("px")[0], 10) > parseInt(height.split("px"), 10)) {
                height = heightDialog;
              }
            } else if (heightDialog.indexOf("%") >= 0 && typeof height === "string" && height.indexOf("%") >= 0) {
              //"500"ou "500px" n'est plus grand que "7" et "7px" --> "5" est inferieur à "7" ->tout est texte
              if (parseFloat(heightDialog.split("%")[0]) > parseFloat(height.split("%"))) {
                height = heightDialog;
              }
            } //dans autre cas, "height" sera utilisé
            hidder.css({
              //display: "none",
              height: height,
              top: topOffset + "px",
              "margin-left": -24
            });
          }
        }
      }
    }
  }
};
