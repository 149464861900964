import { PaginatedCollection } from 'core/models/paginated.collection.js';
import { SelecteurActiviteModeSimpleModel } from './selecteurActiviteModeSimple.model.js';
import { STR } from 'utils/str.js';
import { SYNC } from 'utils/sync.js';
import { TYPE } from 'tda/tda.js';

export var SelecteurActiviteModeSimpleColl = PaginatedCollection.extend({

  model: SelecteurActiviteModeSimpleModel,

  /**
   * Constructor
   * Selecteur Activite Mode Simple Collection
   */
  initialize: function( /*params*/ ) {
    PaginatedCollection.prototype.initialize.call(this);
    this.paginated = false;
    this.params = "";
    this.search = "";
  },

  /**
   * Sets the period for this collection
   */
  setCollParameters: function(parametres) {
    var today = TYPE.DATE.parse(TYPE.DATE.dateToStr(SYNC.getServerDate())).val;

    if (parametres) {
      if (!STR.isBlank(parametres.contenu)) {
        this.contenu = parametres.contenu;
      }
      if (!STR.isBlank(parametres.act_unique)) {
        this.act_unique = parametres.act_unique;
      }
      if (!STR.isBlank(parametres.search)) {
        this.search = parametres.search;
      }
      if (!STR.isBlank(parametres.typevt)) {
        this.typevt = parametres.typevt;
      }
      if (!STR.isBlank(parametres.typo)) {
        this.typo = parametres.typo;
      }
      if (!STR.isBlank(parametres.ctxutil)) {
        this.ctxutil = parametres.ctxutil;
      }
      if (STR.isBlank(parametres.datedeb) && STR.isBlank(parametres.datefin)) {
        //If no date is informed search for today
        this.datedeb = today;
        this.datefin = today;
      } else {
        if (!STR.isBlank(parametres.datedeb) && !STR.isBlank(parametres.datefin)) {
          this.datedeb = parametres.datedeb;
          this.datefin = parametres.datefin;
        } else if (!STR.isBlank(parametres.datedeb) && STR.isBlank(parametres.datefin)) {
          this.datedeb = parametres.datedeb;
          this.datefin = TYPE.DATE.INFINITY;
        } else if (STR.isBlank(parametres.datedeb) && !STR.isBlank(parametres.datefin)) {
          this.datedeb = TYPE.DATE.INITIAL;
          this.datefin = parametres.datefin;
        }
      }
      if (!STR.isBlank(parametres.dom)) {
        this.dom = parametres.dom;
      }
      if (!STR.isBlank(parametres.matric)) {
        this.matric = parametres.matric;
      }
      if (!STR.isBlank(parametres.pertot)) {
        this.pertot = parametres.pertot;
      }
      if (!STR.isBlank(parametres.perdeb)) {
        this.perdeb = parametres.perdeb;
      }
      if (!STR.isBlank(parametres.fltstruct)) {
        this.fltstruct = parametres.fltstruct;
      }
      if (!STR.isBlank(parametres.heritage)) {
        this.heritage = parametres.heritage;
      }
      if (!STR.isBlank(parametres.emploidutemps)) {
        this.emploidutemps = parametres.emploidutemps;
      }
      if (!STR.isBlank(parametres.flt_hiertypeniv)) {
        this.flt_hiertypeniv = parametres.flt_hiertypeniv;
      }

      if (!STR.isBlank(parametres.flt_ratthorsregr)) {
        this.flt_ratthorsregr = parametres.flt_ratthorsregr;
      }

      if (!STR.isBlank(parametres.ctxdeplacement)) {
        this.ctxdeplacement = parametres.ctxdeplacement;
      }

      if (!STR.isBlank(parametres.ctxunique)) {
        this.ctxunique = parametres.ctxunique;
      }
      if (!STR.isBlank(parametres.medical)) {
        this.medical = parametres.medical;
      }
      if (!STR.isBlank(parametres.ctxgrille)) {
        this.ctxgrille = parametres.ctxgrille;
      }
      this.typegest = parametres.ctxTypeGest;
      this.horstypegest = _.isBoolean(parametres.ctxHorsTypeGest) ? parametres.ctxHorsTypeGest : false;
      //pour le mode Explotation et grille non médicals
      this.horsStructExplotation = parametres.horsStructExplotation;
      this.seulementPourFiltre = parametres.seulementPourFiltre; //utiliser uniquement pour rechercher des activités avec grille ou pas, en mode explotation
    }
  },

  /**
   * Build params URL for server call
   */
  _buildURL: function() {
    var existParam = false;
    this.params = "";

    if (!STR.isBlank(this.contenu)) {
      existParam = true;
      this.params += "contenu=" + encodeURIComponent(this.contenu);
    }
    if (!STR.isBlank(this.act_unique)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "act_unique=" + encodeURIComponent(this.act_unique);
    }
    if (!STR.isBlank(this.search)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "search=" + encodeURIComponent(this.search);
    }
    if (!STR.isBlank(this.typevt)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "typevt=" + encodeURIComponent(this.typevt);
    }
    if (!STR.isBlank(this.typo)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "typo=" + encodeURIComponent(this.typo);
    }
    if (!STR.isBlank(this.ctxutil)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "ctxutil=" + encodeURIComponent(this.ctxutil);
    }
    if (!STR.isBlank(this.datedeb)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "datedeb=" + this.datedeb;
    }
    if (!STR.isBlank(this.datefin)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "datefin=" + this.datefin;
    }
    if (!STR.isBlank(this.dom)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "dom=" + encodeURIComponent(this.dom);
    }
    if (!STR.isBlank(this.matric)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "matric=" + encodeURIComponent(this.matric);
    }
    if (!STR.isBlank(this.pertot)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "pertot=" + encodeURIComponent(this.pertot);
    }
    if (!STR.isBlank(this.perdeb)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "perdeb=" + encodeURIComponent(this.perdeb);
    }
    if (!STR.isBlank(this.fltstruct)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "fltstruct=" + encodeURIComponent(this.fltstruct);
    }
    if (!STR.isBlank(this.heritage)) {
      if (existParam) {
        this.params += "&";
      }
      existParam = true;
      this.params += "heritage=" + encodeURIComponent(this.heritage);
    }
    if (!STR.isBlank(this.emploidutemps)) {
      if (existParam) {
        this.params += "&";
      }
      this.params += "emploidutemps=" + encodeURIComponent(this.emploidutemps);
    }
    if (!STR.isBlank(this.flt_hiertypeniv)) {
      if (existParam) {
        this.params += "&";
      }
      this.params += "flt_hiertypeniv=" + encodeURIComponent(this.flt_hiertypeniv);
    }
    if (!STR.isBlank(this.flt_ratthorsregr)) {
      if (existParam) {
        this.params += "&";
      }
      this.params += "flt_ratthorsregr=" + this.flt_ratthorsregr;
    }

    if (!STR.isBlank(this.ctxdeplacement)) {
      if (existParam) {
        this.params += "&";
      }
      this.params += "ctxdeplacement=true";
    }

    if (!STR.isBlank(this.ctxunique) && this.ctxunique === true) {
      if (existParam) {
        this.params += "&";
      }
      this.params += "ctxunique=true";
    }
    if (!STR.isBlank(this.medical) && this.medical === true) {
      if (existParam) {
        this.params += "&";
      }
      this.params += "medical=true";
    }
    if (this.contenu === "R" && !_.isNull(this.typegest) && !STR.isUndefined(this.typegest)) { //il faut ne pas utiliser CWSTR.isBlank parce qu'on doit envoyer le cas que la valeur soit "".
      if (existParam) {
        this.params += "&";
      }
      //les valeurs possibles sont : "" et la valeur de ctxTypeGest
      this.params += "typegest=" + encodeURIComponent(this.typegest);
      //uniquement s'il y a contexte de typegest, on enverra l'autre contexte ctxHorsTypeGest ->horstypegest
      this.params += "&horstypegest=" + this.horstypegest;
    }
    if (!STR.isBlank(this.ctxgrille)) {
      if (existParam) {
        this.params += "&";
      }
      this.params += "ctxgrille=" + encodeURIComponent(this.ctxgrille);
    }
    if (this.horsStructExplotation === true) { //pour le mode Explotation et grille non médicals
      if (existParam) {
        this.params += "&";
      }
      this.params += "horsStruct=true";
    }
    if (this.seulementPourFiltre === true) { //pour rechercher des activités avec grille ou pas, dans le mode Explotation
      if (existParam) {
        this.params += "&";
      }
      this.params += "sansgrillebesoin=false";
    }
    if (existParam) {
      this.params = "?" + this.params;
    }
  },

  /**
   * Builds URL adding mode params and search
   */
  url: function() {
    this._buildURL();
    var urlFin = Configuration.restRoot + "/rest/composant/selecteur/activite/simple" + this.params;
    return urlFin;
  }
});
