module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--  css in staticUCs.css  -->\n<div class="listerprets-dialogPopupHeader form-group">\n  <fieldset class="listerprets-dialogPopupHeader cw-fieldset">\n      <div class="input-group">\n        <div class="phx-listerprets-data">\n            <label for="commentaire" class="cw-label-entrezcomment text-left" title="'+
((__t=(i18n.t("common:listerprets.commentaires")))==null?'':__t)+
'">'+
((__t=(UTILS.getSVGIcon('bulle','',16)))==null?'':__t)+
''+
((__t=(i18n.t("common:listerprets.commentaires")))==null?'':__t)+
'</label>\n            <textarea class="commentaire form-control" rows="6" cols="40" maxlength="60" style="resize:none"></textarea>\n            <div class="cw-texteSuperAllege">'+
((__t=(i18n.t("common:listerprets.comment_popup2")))==null?'':__t)+
'</div>\n              <span class="checkPrets c-form__groupCheckbox c-form__groupCheckbox--vertical">\n                <div class="c-form__labelCheckbox">\n                  <input type="checkbox" name="demande" class="isForced demande c-form__checkbox" value="checkForcer" title="'+
((__t=(i18n.t('common:listerprets.forcer')))==null?'':__t)+
'" />\n                  <span for="isForced" class="c-form__label">'+
((__t=(i18n.t("common:listerprets.forcer")))==null?'':__t)+
'</span>\n                </div>\n              </span>\n        </div>\n      </div>\n  </fieldset>\n</div>\n\n';
}
return __p;
};
