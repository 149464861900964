module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="gererregularisation-content gererregularisations-detail c-gererRegularisationContent">\n    <div class="form-group phx-formErrors ui-state-error"></div>\n    <div class="gererregularisation-form-inputs">\n        <span class="c-gestionCollab cw-titreLight"></span>\n        <div class="c-gererRegularisationContent__form">\n\n            <div class="motifRegul form-group">\n                <label for="motif.code" class="c-labelComposed cw-libelleEnrichi">\n                    '+
((__t=( UTILS.getSVGIcon("etiquette", "", 16) ))==null?'':__t)+
'\n                    <span class="c-textLabel cw-required">'+
((__t=(i18n.t("common:gererregularisations.motif")))==null?'':__t)+
'</span>\n                    <span class="gererregularisations-aide">'+
((__t=( UTILS.getSVGIcon("aide_bulle", "", 16) ))==null?'':__t)+
'</span>\n                </label>\n                <span class="cmbMotif" title="'+
((__t=(i18n.t("common:gererregularisations.motif_title")))==null?'':__t)+
'"></span>\n            </div>\n\n            <div class="gererregularisations-dates form-group">\n                <div class="form-row paddingRight">\n                    <div class="col m-0">\n                        <label class="gererregularisations-dates__label cw-required cw-libelleEnrichi">\n                            '+
((__t=( UTILS.getSVGIcon("periode", "", 16) ))==null?'':__t)+
'\n                            <span class="gererregularisations-dates__Textlabel">'+
((__t=(i18n.t('common:gererregularisations.periode')))==null?'':__t)+
'</span>\n                        </label>\n                        <div class="row no-gutter marginRight">\n                            <div class="form-group col paddingRight">\n                                <label for="datedeb" class="sr-only">'+
((__t=(i18n.t("common:gererregularisations.datedebut")))==null?'':__t)+
'</label>\n                                <div class="c-date-selector input-group">\n                                    <input type="text" class="datedeb typeDate periodStart form-control required" periodId="1" />\n                                </div>\n                            </div>\n                            <div class="form-group col datefinal paddingRight">\n                                <label for="datefin" class="sr-only">'+
((__t=(i18n.t("common:gererregularisations.datefin")))==null?'':__t)+
'</label>\n                                <div class="c-date-selector input-group">\n                                    <input type="text" class="datefin typeDate periodEnd form-control required" periodId="1" />\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class="value form-group">\n                <label for="valeur" class="cw-libelleEnrichi cw-required">\n                    '+
((__t=( UTILS.getSVGIcon("valeur", "", 16) ))==null?'':__t)+
'\n                    '+
((__t=(i18n.t("common:gererregularisations.valeur")))==null?'':__t)+
'\n                </label>\n                <input type="text" class="valeur form-control typeCustom" />\n            </div>\n\n            <div class="originActif form-group">\n                <label for="origine.code" class="cw-libelleEnrichi">\n                    '+
((__t=( UTILS.getSVGIcon("personnage", "", 16) ))==null?'':__t)+
'\n                    '+
((__t=(i18n.t("common:gererregularisations.origine")))==null?'':__t)+
'\n                </label>\n                <span class="comboOrigine"></span>\n            </div>\n\n\n            <span class="gererregularisations-infocomp"></span>\n            <span class="gererregularisations-wkfhisto"></span>\n\n\n            <div class="spinfocomp form-group">\n                <label for="infocomp" class="cw-libelleEnrichi">\n                    '+
((__t=( UTILS.getSVGIcon("rond_i", "", 16) ))==null?'':__t)+
'\n                    '+
((__t=(i18n.t("common:gererregularisations.commentregul")))==null?'':__t)+
'\n                </label>\n                <textarea type="text" class="infocomp form-control" style="white-space: pre-wrap;" maxlength="60" cols="30"></textarea>\n            </div>\n\n            <div class="blockCommentaire form-group">\n                <label for="commentaire" class="cw-libelleEnrichi">\n                    '+
((__t=( UTILS.getSVGIcon("bulle", "", 16) ))==null?'':__t)+
'\n                    '+
((__t=(i18n.t("common:gererregularisations.comment")))==null?'':__t)+
'\n                </label>\n                <textarea class="commentaire form-control" style="white-space: pre-wrap;" maxlength="60" cols="30" rows="4"></textarea>\n            </div>\n        </div>\n    </div>\n    <div class="c-gererRegularisationContent__btnBar">\n        <ul class="validerListMenu menuContent"></ul>\n    </div>\n</div>\n\n<div class="unselectedElementText">\n    <label>'+
((__t=(i18n.t("common:gererregularisations.unselectedElementText")))==null?'':__t)+
'</label>\n    <ul>\n        <li>\n            <div class="textAccent1">\n                '+
((__t=(i18n.t("common:gererregularisations.ajouter")))==null?'':__t)+
'\n            </div>\n            <span>'+
((__t=(i18n.t("common:gererregularisations.text_ajouter")))==null?'':__t)+
'</span>\n        </li>\n        <li class="consulterText">\n            <div class="textAccent1">\n                '+
((__t=(i18n.t("common:gererregularisations.consulter")))==null?'':__t)+
'\n            </div>\n            <span>'+
((__t=(i18n.t("common:gererregularisations.text_consulter")))==null?'':__t)+
'</span>\n        </li>\n    </ul>\n</div>';
}
return __p;
};
