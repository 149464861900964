import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWFORMS } from 'utils/cwForms';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWLOG } from 'utils/cwLog';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';


export class CWBaseFormView<TModel extends CWBaseModel = CWBaseModel> extends Backbone.View<TModel> {

  habContext: CWHabilitationContext;
  typeFormatByClass: { [key: string]: any }; // Array<any> | Object;
  typeMaskByClass: { [key: string]: any }; // Array<any> | Object;
  isEditedRowView: boolean;
  pkSelectors: Array<any>;

  constructor(viewOptions?: Backbone.ViewOptions<TModel> | any) {
    super(viewOptions);
    this.habContext = new CWHabilitationContext();
    this.typeFormatByClass = {};
    this.typeMaskByClass = {};
    this.isEditedRowView = false;
    this.pkSelectors = [];
    if (CWSTR.isBlank(this.model)) {
      this.model = new CWBaseModel() as TModel;
    }
  }
  public _formEdited(): any {
    return null;
  }

  public _getModel(): TModel {
    try {
      return this.model.get("value");
    } catch (error) {
      return null;
    }
  }

  public _cleanValidationErrors(): void {
    CWFORMS.cleanErrors($(this.el));
  }

  public setHabContext(habContext: CWHabilitationContext): void {
    this.habContext = habContext;
  }

  public updateHabContext(attributes: CWHabilitationContext | { [key: string]: any }): void {
    if (this.habContext) {
      this.habContext.update(attributes);
    }
  }

  public getHabContext(): CWHabilitationContext {
    return this.habContext;
  }

  _change(event: JQueryInputEventObject | JQueryEventObject | any, data?: object, aSilentValue?: boolean, ifValidate?: boolean): void {
    let silentValue = aSilentValue;
    const model = this._getModel();
    const target = event.target;
    const className = target.className.split(" ")[0];
    const typeSelector = CWTYPE.supportedClass;
    let viewRef: JQuery | any = null;

    //Check APP TYPEs
    if (!_.isBoolean(silentValue)) {
      silentValue = true;
    }
    if ($(target).is(typeSelector)) {
      const typeClassesArray = typeSelector.replace(/[.,]/g, "").split(" ");
      let inputMask = "";
      const parse = new Array<any>();
      const typeClass = _.find(typeClassesArray, (type: string) => {
        if ($(target).hasClass(type)) {
          return true;
        }
        return false;
      });

      if (!CWSTR.isBlank(this.typeFormatByClass) && !CWSTR.isBlank(this.typeFormatByClass[className])) { //TypeCustom
        inputMask = CWTYPE._getMaskByFormat(this.typeFormatByClass[className]);
        parse[className] = CWTYPE._getTypeByTypeClass(typeClass).parse(target.value, inputMask);
      } else { //Other types
        parse[className] = CWTYPE._getTypeByTypeClass(typeClass).parse(target.value);
      }
      CWSTR.setElValue(model, className, parse[className]["val"], { silent: silentValue });
      if (CWSTR.isBlank(ifValidate) || ifValidate === true) {
        // if (!CWSTR.isBlank(ifValidate)) {
        // We validate the errors if it's a typeClass
        this._controlError(true, model, className, target, parse);
        if (this.isEditedRowView) { //In case we are inside an editedRowView, we don't want to show errors in the whole fieldset
          //we only want to modify the errors for this row (to avoid wrong errors for inputs with the same class name)
          if (!_.isEmpty(model.validationError.errors)) {
            CWFORMS.showErrors($(this.el), model.validationError.errors);
          } else {
            CWFORMS.cleanErrors($(this.el));
          }
        } else { //a form that is NOT an editedRowView
          if (!_.isEmpty(model.validationError.errors)) {
            CWFORMS.showErrors($(this.el), model.validationError.errors);
          } else {
            CWFORMS.cleanErrors($(target).parents("fieldset"));
          }
        }
      }
    } else {
      switch (target.type) {
        case "checkbox":
          CWSTR.setElValue(model, className, target.checked, {
            silent: silentValue
          });
          break;
        default:
          // Gets the reference of the view if it exists.
          viewRef = $(target).prop("viewRef");
          if ($(target).hasClass("phx-autocomplete-input")) {
            CWSTR.setElValue(model, className, $(target).prop("data-code"), {
              silent: silentValue
            });
          } else if (viewRef && viewRef.isComboBoxView2) {
            if (viewRef.multiselection === false) {
              CWSTR.setElValue(model, className, viewRef.getItemId(), {
                silent: silentValue
              });
            } else {
              CWSTR.setElValue(model, className, viewRef.getItemId(), {
                silent: silentValue
              });
            }

          } else if (!this._elementIsReferentiel(target) && !this._elementIsChemin(target) && !this._elementIsListBuilder(target) &&
            !this._elementIsSelecteurActivite(target) && !this._elementIsHorRef(target)) {
            CWSTR.setElValue(model, className, target.value, {
              silent: silentValue
            });
          } else if (this._elementIsHorRef(target) && target.value === "") {
            // element is Horaire Reference and delete value displayed
            CWSTR.setElValue(model, className, target.value, {
              silent: silentValue
            });
          }
      }
      if (!CWSTR.isBlank(ifValidate) || ifValidate === undefined) {
        // We validate the errors if isn't a typeClass
        this._controlError(false, model, className, target, null);
      }

    }
    this._notifyEdition(event);
  }

  private _elementIsReferentiel(element: HTMLElement): boolean {
    return $(element).hasClass("phx-referentiel-input");
  }
  private _elementIsChemin(element: HTMLElement): boolean {
    return $(element).hasClass("phx-chemin-input");
  }
  private _elementIsListBuilder(element: HTMLElement): boolean {
    return $(element).hasClass("phx-list-builder-select");
  }
  private _elementIsSelecteurActivite(element: HTMLElement): boolean {
    return $(element).hasClass("phx-selecteur-activite-input") || $(element).hasClass("cw-selecteur-activite-input");
  }
  private _elementIsHorRef(element: HTMLElement): boolean {
    return $(element).hasClass("txtHoraire");
  }

  /** We will utilize the _controlError function to determine Validation errors.

  In var "tempError" we'll save the errors that we generate (new errors) and we're going to compare that with old mistakes
  for we can add or delete it to our array of errors and can show it correctly.

  - pointToSBracket (Chronotime function). If this function receive a variable, it always will return this one..
    but if this function receives  a compound variable (ejº libelle.code), the function will return that there is after point
    (Ejº code).
  - In this function (_controlError) the variable "isTyped" is to determine if is a typeClass (true) or not (false).
  */
  public _controlError(isTyped: boolean, model: TModel, className: string, target: JQueryEventObject, parse: Array<any>): void {
    let tempError: any;
    let arrayErrors = [className];
    let infoCompName = null;

    if (model.validate) {
      tempError = model.validate(model.attributes, { individual: true, editedAttr: className });
    }
    if (!model.validationError || _.isEmpty(model.validationError.errors)) {
      model.validationError = { errors: {}, errorValidation: {} };
    }
    if (model.validationError && model.groupedErrors && model.groupedErrors[className]) {
      const grouped = model.groupedErrors;

      arrayErrors = grouped[className];
    }
    infoCompName = model.infoCompAttributeName;
    for (let i = 0; i < arrayErrors.length; i++) {
      const lClassName = arrayErrors[i];
      const simpleClassName = arrayErrors[i].split(".")[0];

      if (tempError && !CWSTR.isBlank(CWFORMS.pointToSBracket(tempError.errors, lClassName))) {
        CWFORMS.assignValue(model.validationError.errors, lClassName, CWFORMS.pointToSBracket(tempError.errors, lClassName));
      } else if (!CWSTR.isBlank(CWFORMS.pointToSBracket(model.validationError.errors, lClassName))) {
        CWFORMS.removeValue(model.validationError.errors, lClassName);
      } else if (!CWSTR.isBlank(CWFORMS.pointToSBracket(model.validationError.errors, simpleClassName)) && (CWSTR.isBlank(infoCompName) || infoCompName !== simpleClassName)) {
        CWFORMS.removeValue(model.validationError.errors, simpleClassName);
      }
      if (parse && parse[arrayErrors[i] as any] && parse[arrayErrors[i] as any]["errors"] && CWSTR.isBlank(CWFORMS.pointToSBracket(model.validationError.errors, arrayErrors[i]))) {
        CWFORMS.assignValue(model.validationError.errors, arrayErrors[i], parse[arrayErrors[i] as any]["errors"]);
      }
    }
    if (this.isEditedRowView) { //In case we are inside an editedRowView, we don't want to show errors in the whole fieldset
      //we only want to modify the errors for this row (to avoid wrong errors for inputs with the same class name)
      if (!_.isEmpty(model.validationError.errors)) {
        CWFORMS.showErrors($(this.el), model.validationError.errors);
      } else {
        CWFORMS.cleanErrors($(this.el));
      }
    } else { //a form that is NOT an editedRowView
      if (!_.isEmpty(model.validationError.errors)) {
        if (isTyped === true) {
          CWFORMS.showErrors($(this.el), model.validationError.errors);
        } else {
          CWFORMS.showErrors($(target).parents("fieldset"), model.validationError.errors);
        }
      } else {
        CWFORMS.cleanErrors($(target).parents("fieldset"));
      }
    }
  }

  public _notifyEdition(event: JQueryEventObject): void {
    let target: any = null;
    let className: string = null;

    if (event.type === "keyup") {
      const key = event.which || event.keyCode;

      // if the key is not printable it's ommited.
      if ((key >= 9 && key <= 45 && key !== 32) || (key >= 91 && key <= 93) || (key >= 112 && key <= 185)) {
        return;
      }
    }
    if (event.target.tagName === "BUTTON") {
      return;
    }
    target = event.target;
    className = target.className.split(" ")[0];
    // trigger event to notify form has been edited
    this.model.trigger("form:edited", className + " > " + target.value);
  }

  public _manageMode(): void {
    const newMode = this.model.get("mode");

    if (!_.contains(["E", "C"], newMode)) {
      throw new Error("Mode not supported in Form : " + newMode);
    }
    this._enablePk(newMode === "C");
  }

  public _enablePk(enabled: boolean): void {
    let size = 0;

    if ((CWSTR.isBlank(this.pkSelectors) || this.pkSelectors.length <= 0) && Configuration.development === true) {
      throw new Error("The form view have to be pkSelector setted correctly");
    }

    size = this.pkSelectors.length;
    for (let i = 0; i < size; i++) {
      const fieldset = this._getFieldset();
      const fieldName = this.pkSelectors[i].replace(".", "\\.");
      const input = fieldset.find(":input." + fieldName);
      const label = fieldset.find("label[for=" + fieldName + "],label[for^=" + fieldName + "_uid_]");
      const inputsNumber = input.length;

      for (let j = 0; j < inputsNumber; j++) {
        CWFORMS.setFieldReadonly(input.eq(j), !enabled, false);
      }
      if (enabled) {
        label.addClass('cw-required');
      } else {
        label.removeClass('cw-required');
      }
    }
  }

  public _getFieldset(): JQuery {
    return $(this.el).find("fieldset");
  }

  public _mapModelToForm($fieldset: JQuery, model: CWBaseModel, renderers?: { [key: string]: any }, avoidLinkingLabels?: boolean, callback?: (arg?: string) => any): void {
    const typeRenderers: { [key: string]: any } = {};
    const typeClasses = CWTYPE.supportedClass;
    let i = 0;
    let isReadonly = false;
    const lType = this.$el.find(typeClasses);
    const typeClass: { [key: string]: any } = {};
    const formatMask: { [key: string]: any } = {};
    const lFindInput = $fieldset.find(":input");

    for (i = 0; i < lType.length; i++) {
      const it = lType[i];
      const attrName = it.className.split(" ")[0];
      const typeClassesArray = typeClasses.replace(/[\.,]/g, "").split(" "); // eslint-disable-line

      typeClass[attrName] = _.find(typeClassesArray, function (type) { // eslint-disable-line
        return $(it).hasClass(type);
      }, this);

      if (!CWSTR.isBlank(this.typeFormatByClass) && !CWSTR.isBlank(this.typeFormatByClass[attrName])) { //TypeCustom
        formatMask[attrName] = this.typeFormatByClass[attrName];
        typeRenderers[attrName] = (typeClass: any, v: any, formatMask: any): string => {
          return CWTYPE._getTypeByTypeClass(typeClass).format(v, formatMask);
        };
      } else { //Other types
        if (this.typeMaskByClass && this.typeMaskByClass[attrName]) {
          formatMask[attrName] = CWTYPE._getFormatByCode(this.typeMaskByClass[attrName]);
          typeRenderers[attrName] = (typeClass: any, v: any, formatMask: any): string => {
            return CWTYPE._getTypeByTypeClass(typeClass).format(v, formatMask);
          };
        } else {
          typeRenderers[attrName] = (typeClass: any, v: any): string => {
            return CWTYPE._getTypeByTypeClass(typeClass).format(v);
          };
        }
      }
    }
    for (i = 0; i < lFindInput.length; i++) {
      const element: any = lFindInput[i];
      const domEl = $(element);

      if (!CWSTR.isBlank(domEl.attr("class"))) {
        const name = domEl.attr("class").split(" ")[0];
        let value = CWSTR.getElValue(model, name);
        const lNameScaped = name.replace(/\./g, "\\.");//Si le "name" a de valeur "." dans son texte, on ajoute le caractère "\\" pour le "escaper"
        const label = $fieldset.find("label[for=" + lNameScaped + "],label[for^=" + lNameScaped + "_uid_]");
        let viewRef = null;

        // Apply original renderer
        if (!CWSTR.isBlank(renderers) && !_.isNull(renderers) && renderers[name]) {
          value = renderers[name](value, model);
        }
        // Apply type renderer
        if (!CWSTR.isBlank(typeRenderers) && !_.isNull(typeRenderers) && typeRenderers[name]) {
          let formatMask: { [key: string]: any } = {};

          if (!CWSTR.isBlank(this.typeFormatByClass) && !CWSTR.isBlank(this.typeFormatByClass[name])) {
            formatMask = this.typeFormatByClass[name];
          } else if (this.typeMaskByClass && this.typeMaskByClass[name]) {
            formatMask[name] = CWTYPE._getFormatByCode(this.typeMaskByClass[name]);
          }
          if (domEl.hasClass("infinityDate")) {
            value = CWTYPE.DATE.manageInfinity(value, formatMask[name]);
          } else {
            if (!CWSTR.isBlank(formatMask[name])) {
              value = typeRenderers[name](typeClass[name], value, formatMask[name], model);
            } else {
              value = typeRenderers[name](typeClass[name], value, formatMask);
            }
          }
        }
        // Get the viewRef of an input
        viewRef = domEl.prop("viewRef");
        if (element.type === "select-one" || element.type === "textarea") {
          //Select case
          domEl.val(value);
          $fieldset.find("span." + name.replace(/\./g, "\\.")).remove();
          isReadonly = domEl.attr("readonly") === "readonly";
          if (isReadonly) {
            // replace readonly field by span field and hide the readonly field
            if (element.type === "select-one") {
              CWFORMS.setSelectFieldReadonly($fieldset, name, true);
            }
            if (element.type === "textarea") {
              //CWFORMS.setTextareaFieldReadonly(fieldset, name, true);
            }
            if (!CWSTR.isBlank(label) && label.length > 0 && isReadonly === true && label.hasClass("cw-required")) {
              label.removeClass("cw-required");
            }
          }
        } else if (viewRef && viewRef.isComboBoxView2) {
          if (viewRef.multiselection === false) {
            let tempValue = value;

            if (!_.isObject(value)) {
              const nameFragments = name.split(".");
              let simpleClassName = null;
              let obj = null;

              tempValue = { code: value };
              nameFragments.pop(); // I remove the last part of the name
              simpleClassName = nameFragments.join(".");
              obj = CWSTR.getElValue(model, simpleClassName);
              if (obj) {
                tempValue = _.extend(tempValue, obj);
              }
            }
            viewRef.setItem(tempValue, (): void => {
              if (typeof callback === "function") {
                callback(name);
              }
            });
          } else {
            viewRef.setItems(value);
          }
        } else if (viewRef && viewRef.isRadioBoxView) {
          if (domEl.val() === value) {
            viewRef.manageAdditionalViewState(value);
          }
          domEl.val([value]);
        } else if (viewRef && viewRef.isSelecteurActivitesView) {
          //For selecteur d'activites do nothing, it should be managed by usecase
        } else {
          //other fields case
          switch (domEl.attr("type")) {
            case "text":
              domEl.val(value);
              isReadonly = domEl.attr("readonly") === "readonly";
              CWFORMS.setInputFieldReadonly($fieldset, name, isReadonly);
              if (!CWSTR.isBlank(label) && label.length > 0 && isReadonly === true && label.hasClass("cw-required")) {
                //label.removeClass("cw-required");
                label.addClass("cw-labelIsReadonly");
              } else if (label.hasClass("cw-labelIsReadonly")) {
                label.removeClass("cw-labelIsReadonly");
              }
              break;
            case "checkbox":
              domEl.val([value]);
              domEl.prop("checked", value);
              break;
            case "radio":
              domEl.val([value]);
              break;
            case "number":
              domEl.val([value]);
              break;
            default:
            //Nothing
          }
        }
      }
    }
    //Accessibility
    if (!avoidLinkingLabels) {
      if (this instanceof CWBaseFormView) {
        CWFORMS.linkLabelsToInputs($fieldset);
      }
    }
    this.disableClickOnReadonlyCheckbox($fieldset);
    this._panelStateRender($fieldset);
  }

  disableClickOnReadonlyCheckbox($fieldset: JQuery): void {
    $("[type=checkbox]", $fieldset).off(".readonlyCheck");
    $("[type=checkbox]", $fieldset).on("click.readonlyCheck", function (e) {
      if ($(e.target).is("[readonly]")) {
        e.preventDefault();
      }
      e.stopPropagation();
    });
  }

  _showValidationErrors(model: CWBaseModel, errors: { [key: string]: any }, focusField?: string | object): void {
    CWLOG.debug("ERROR saving..." + JSON.stringify(errors));
    if (errors && _.has(errors, "errorValidation")) {
      if (_.has(errors, "errors")) {
        const fieldErrors = errors.errors;

        CWFORMS.showErrors($(this.el), fieldErrors);
        if (focusField && focusField instanceof String) {
          this.$el.find(":input.ui-state-error." + focusField).eq(0).focus();
        } else {
          this.$el.find(":input.ui-state-error").eq(0).focus();
        }
      }
    }
  }

  public _manageFormVisibility(): void {
    const model = this._getModel();

    if (CWSTR.isBlank(model)) {
      this.$el.hide();
    } else {
      this.$el.show();
    }
  }

  public _panelStateIsReadonly(...args: any): boolean { //eslint-disable-line
    const model = this._getModel();
    let isReadOnly = false;

    if (model && model.getHabContext && model.getHabContext()) {
      const canUpdate = CWHABILITATION.canUpdate(model.getHabContext().get("foncCour"));

      isReadOnly = !canUpdate && !model.isNew();
    }
    return isReadOnly;
  }

  _panelStateRender($fieldset: JQuery): void {
    const isReadonly = this._panelStateIsReadonly();

    if (this.$el) {
      CWFORMS.setFormReadonly(this.$el, isReadonly, true);
    } else {
      CWFORMS.setFormReadonly($fieldset, isReadonly, true);
    }
    // add special fields manually (tables, combos, ...)
    this._panelStateRenderCustomFields(isReadonly);
  }

  _panelStateRenderCustomFields(isReadOnly: boolean): void { //eslint-disable-line
    // override with custom readonly sentences.
  }

  /**
   * For Filter : Clean the filter and reload the default values. In particulary for the combo
   */
  restaureDefaultValues(): void {
    // Do custom action here
  }
}
