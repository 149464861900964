import _ from 'underscore';
import TPLListerecapHeaderPopup from '../cwHeaderPopup.tpl.html';
import { CWAccepterRecap } from '../models/cwAccepterRecap';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWRefuserRecap } from '../models/cwRefuserRecap';
import { CWSTR } from 'utils/cwStr';
import { i18n } from 'src/i18n.js';
import { UTILS } from 'src/utils';


export class CWHeaderPopupView extends CWBaseFormView {
  action: any;
  commentaire: any;
  isForced: any;
  parent: any;
  private commentRequired: boolean


  constructor(options?: { [key: string]: any }) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_change",
      "keyup :input:not([readonly])": "_enableButtons",
      "click :input.isForced": "_change",
      "click button": "_clickListener"
    }, options.events);
    super(options);
    this.template = TPLListerecapHeaderPopup;

    this.model = new CWBaseModel({
      value: new CWBaseModel({
        "commentaire": "",
        "isForced": false
      })
    });

    this.commentRequired = CWSTR.isBlank(options.commentRequired) ? false : options.commentRequired;

    if (options.action) {
      this.action = options.action;
      if (this.action === "A") {
        this.model.set("value", new CWAccepterRecap());
      } else if (this.action === "R") {
        this.model.set("value", new CWRefuserRecap());
      } else if (this.action === "C") {
        if (this.commentRequired) {
          this.model.set("value", new CWRefuserRecap());
        } else {
          this.model.set("value", new CWAccepterRecap());
        }
      }
    } else {
      this.model.set("value", new CWAccepterRecap());
    }


    this.model.get("value").on("change", this._mapToForm, this);

    this.commentaire = CWSTR.isBlank(options.commentaire) ? "" : options.commentaire;
    this.isForced = CWSTR.isBlank(options.isForced) ? "" : options.isForced;

    if (options.parent) {
      this.parent = options.parent;
      this.parent.model.set("isCommentChanged", false);
    }

    this.listenTo(this, "btnResult", this._clickListener);
  }

  render(): this {
    const json = { "i18n": i18n, "UTILS": UTILS };
    this.$el.append(this.template(json));

    //Initialize the comment textarea with the previous comment and the forced checkbox.
    this.model.get("value").isForced = this.isForced;
    CWSTR.setElValue(this.model.get("value"), "commentaire", this.commentaire);

    if (this.action === "R" || this.commentRequired) {
      $(this.el)
        .find("fieldset.listerecap-dialogPopupHeader label[for=commentaire], fieldset.listerecap-dialogPopupHeader label[for^=commentaire_uid_]")
        .addClass("cw-required");
    }

    if (this.parent) {
      $(this.parent.el).css("height", "auto");
    }

    return this;
  }

  _change(event: JQueryEventObject | any): void {
    const element = event.currentTarget;

    //MapCheckBoxToModel
    if ($(element).hasClass("isForced")) {
      this.model.get("value").isForced = element.checked;
    } else {
      CWBaseFormView.prototype._change.call(this, event);
    }

    // Clear current validation erros
    this._cleanValidationErrors();
  }

  _enableButtons(): void {
    $(this.el).find("fieldset.listerecap-dialogPopupHeader button").button({ disabled: false });
  }

  _mapToForm(): void {
    const model = this.model.get("value");
    const fieldset = this.$el.find("fieldset");

    this._mapModelToForm(fieldset, model, null);

    $(this.el).find("fieldset.listerecap-dialogPopupHeader input.isForced").prop("checked", model.isForced);
  }

  _clickListener(btnClicked: string): void {
    const model = this.model.get("value");
    const commentaire = CWSTR.getElValue(model, "commentaire");
    const isForced = model.isForced;

    switch (btnClicked) {
      case "btnValider":
        // Error management
        model.off("invalid");
        model.on("invalid", this._showValidationErrors, this);

        if (model.isValid()) {
          this.parent.model.set("globalComment", commentaire);
          this.parent.model.set("isForced", isForced);
          if (commentaire !== this.commentaire) {//if the comment change...
            this.parent.model.set("isCommentChanged", true);
          }
          this.parent.close();
        }
        break;
      case "btnRevert":
        model.isForced = this.isForced;
        //CWSTR.setElValue(model, "commentaire", this.commentaire);
        this.parent.close();
        break;
      default:
        break;
    }
  }
}
