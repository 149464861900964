import { STR } from 'utils/str.js';
import TPL_common_selecteur_referentiel_item from './selecteur_referentiel_item.tpl.html';

export var SelecteurReferentielResultItemView = Backbone.View.extend({

  /**
   * Name of the container class of the view
   */
  className: "phx-referentiel-item ui-corner-all",

  /**
   * Name of the html tag which involves the view
   */
  tagName: "span",

  /**
   * Event launched when the remove icon is clicked
   *
   */

  events: {
    "click .phx-referentiel-item-icon": "_removeItem"
  },

  /**
   * Constructor
   * View underlying a Selecteur de referentiel selected item
   */
  initialize: function(params) {
    /**
     * HTML template of the view
     */
    this.template = TPL_common_selecteur_referentiel_item;
    if (params && !STR.isBlank(params.itemTitle)) {
      this.itemTitle = params.itemTitle;
    }
    this.readonly = false;
    if (params && params.readonly === true) {
      this.readonly = true;
    }
  },

  /**
   * Paints the view of selected item
   */
  render: function() {
    var json = { readonly: this.readonly, model: this.model.attributes };

    $(this.el).html(this.template(json));

    if (STR.isBlank(this.itemTitle) === false) {
      var title = this.itemTitle(this.model);
      $(this.el).find(".phx-referentiel-item-text").attr("title", title);
    }
    return this;
  },

  /**
   * Removes the current element
   */
  _removeItem: function() {
    if (this.readonly === false) {
      this.model.trigger("click:item", this.model);
    }
  }

});
