import _ from 'underscore';
import { CWFilterBaseModel } from 'core/models/cwFilterBase.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';

export class CWMoreFilterModel extends CWFilterBaseModel {

  protected workflow: { [key: string]: any };

  defaults(): { [key: string]: any } {
    return {
      "motif": {
        "code": null,
        "libelle": ""
      },
      "datedeb": "",
      "datefin": ""
    }
  }

  constructor(options?: { [key: string]: any }, workflow?: { [key: string]: any }) {
    options = options || {};
    super(options)
    this.workflow = workflow;
    this._storeDefaultValues();
    this.groupedErrors = {
      datedeb: ["datedeb", "datefin"],
      datefin: ["datedeb", "datefin"]
    };
  }

  validate(attrs: { [key: string]: any }, options?: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};
    //Period validation
    const datedeb: { [key: string]: any } = {
      "name": "datedeb",
      "value": attrs.datedeb,
      "label": undefined
    };
    const datefin: { [key: string]: any } = {
      "name": "datefin",
      "value": attrs.datefin,
      "label": undefined,
      "isInfinity": false
    };
    const errorMsg = CWTYPE.DATE.validatePeriod(datedeb, datefin, options);

    if (!CWSTR.isBlank(errorMsg)) {
      if (!CWSTR.isUndefined(errorMsg.datedeb)) {
        errors.datedeb = errorMsg.datedeb;
      }
      if (!CWSTR.isUndefined(errorMsg.datefin)) {
        errors.datefin = errorMsg.datefin;
      }
    }
    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };

      return errorMap;
    }
    return null;
  }

  protected _initDefaultValues(): void {
    this.set('motif', this.workflow.motifComboPlaceholder);
    if (this.workflow && this.workflow.context && this.workflow.context.ctxPeriode && !CWSTR.isNull(this.workflow.context.ctxPeriode)) {
      this.set('datedeb', this.workflow.contex.ctxPeriode.datedeb);
      this.set('datefin', this.workflow.contex.ctxPeriode.datefin);
    } else {
      const paramStartDate = Number(GLOBAL_DATA.paramDivers.get("R.NbJVisuP").get("valeur"));
      const paramEndDate = Number(GLOBAL_DATA.paramDivers.get("R.NbJVisuF").get("valeur"));
      const startDate = new Date();
      const endDate = new Date();

      startDate.setDate(startDate.getDate() - this.checkStartDate(paramStartDate));
      this.set('datedeb', CWTYPE.DATE.dateToStr(startDate, "YYYYMMDD"));
      if (paramEndDate > 0) {
        endDate.setDate(endDate.getDate() + this.checkEndDate(paramEndDate));
        this.set('datefin', CWTYPE.DATE.dateToStr(endDate, "YYYYMMDD"));
      } else {
        this.set('datefin', '');
      }
    }
  }

  checkStartDate(nbj: number): number {
    const intEffmax = Number(GLOBAL_DATA.paramDivers.get("int_effmax").get("valeur"));

    return Math.max(intEffmax, ((nbj > 0) ? nbj : 180));
  }

  checkEndDate(nbj: number): number {
    const futMax = Number(GLOBAL_DATA.paramDivers.get("futmax").get("valeur"));

    if (futMax > 0 && nbj > futMax) {
      return futMax;
    }
    return nbj;
  }
}
