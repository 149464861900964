import * as Backbone from 'Backbone';
import _ from 'underscore';
import CWComboBoxView2TPL from './cwComboBoxView2.tpl.html';
import { CWAutocompleteColl } from './cwAutocomplete.collection';
import { CWBaseModel } from 'src/core/models/cwBase.model';
import { CWComboBox2ResultItemView } from './cwComboBox2ResultItem.view';
import { CWCombosColl } from './cwCombos.collection';
import { CWFORMS } from 'utils/cwForms';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWLOG } from 'utils/cwLog';
import { CWPaginatedCollection } from '../../models/cwPaginated.collection';
import { CWSTR } from 'utils/cwStr';
import { objs } from 'src/objectsRepository';
import { UTILS } from 'utils/utils.js';


export interface CWComboBoxView2Options<Tmodel extends Backbone.Model = Backbone.Model> extends Backbone.ViewOptions<Tmodel> {
  ws?: CWCombosColl;
  enum?: Array<{ [key: string]: any }>;
  name: string;
  required?: boolean;
  autreText?: string;
  disableEmptyItem?: boolean;
  autocomplete?: boolean;
  optionsRender?: (item: { [key: string]: any }) => string | JQuery;
  inputRender?: (item: { [key: string]: any }) => string;
  width?: string | number;
  height?: string | number;
  useExternalErrorContainer?: boolean;
  setOutErrorContainer?: boolean;
  externalCache?: { [key: string]: any };
  syncExternalCache?: boolean;
  habContext?: CWHabilitationContext;
  infobulle?: boolean;
  /**
  * Indicator of the multiselection functionality
  */
  multiselection?: boolean;
  /**
     * Callback function to be executed when a new item is add to the list
     */
  addItemCallback?: (...args: any[]) => string;
  /**
   * Callback function to be executed when a new item is removed from the list
   */
  removeItemCallback?: (...args: any[]) => string;
  preprocessBeforeSetItem?: (arg: { [key: string]: any }) => { [key: string]: any };
  _isValidComboId?: () => boolean;
  keepOldId?: string;
  /**
   * **Deprecated, unnused options.
   */
  selectedOption?: string | number;
  placeholder?: string | { [key: string]: any };
  title?: string;
  idAttribute?: string;
}

export class CWComboBoxView2 extends Backbone.View<Backbone.Model> {

  /** Mark to use like instanceof */
  public isComboBoxView2: boolean;
  /**
   * Indicator of the multiselection functionality
   */
  private multiselection: boolean;
  /**
   * Indicator of the readonly mode
   */
  public readonly: boolean;
  public coll: CWPaginatedCollection | any;
  public enumColl: Array<{ [key: string]: any }>;
  // class name
  public name: string;
  public required: boolean;
  public infobulle: boolean;
  private autreText: any;
  private disableEmptyItem: boolean;
  private autocompleteMode: boolean;
  private refreshCombo: boolean;
  _oldButtonSearch: (event?: JQueryEventObject) => boolean;
  public width: number | string;
  public height: number | string;
  public useExternalErrorContainer: boolean;
  public setOutErrorContainer: boolean;
  public syncExternalCache: boolean;
  public cache: { [key: string]: any };
  term: string;
  // used to iterate thru the list of result starting with the same letter
  private delay: number;
  selectLetter: { [key: string]: any };
  searchLetters: string;
  private addItemCallback: (...args: Array<any>) => string;
  /**
   * Callback function to be executed when a new item is removed from the list
   */
  private removeItemCallback: (...args: Array<any>) => string;
  // response list filled with user selection
  /**
   * Current selection in the list
   */
  public selection: CWAutocompleteColl;
  private preprocessBeforeSetItem: (params: any) => { [key: string]: any };
  public keepOldId: any;
  public input: JQuery;
  public appended: boolean;
  public searching: boolean;
  public menu: any;
  public setItemPendingAction: boolean;
  public setItemPendingValue: { [key: string]: any };
  public shouldCloseMenu: boolean;
  public target: JQuery;
  public currentCode: string;
  public isHoverSelect: boolean;
  public placeholder: string;
  private _omitViews: (...args: Array<any>) => any;
  private _resetRendered: (...args: Array<any>) => any;


  public optionsRender(item: { [key: string]: any }): string | JQuery {
    return ((item) ? item.libelle : "");
  }

  public inputRender(item: { [key: string]: any }): string {
    const itemText = this.optionsRender(item);

    if (CWSTR.isBlank(itemText) || typeof itemText === "string") {
      return itemText as string;
    }
    return itemText.text();
  }

  /**
   * Constructor
   * ComboBox2 components to lazy load the information
   */
  constructor(params?: CWComboBoxView2Options) {
    params.tagName = params.tagName || "div";
    params.className = params.className || "c-cwComboBoxView2 phx-list-builder";
    params.events = _.extend({
      "click .c-cwComboBoxView2__icon:not(.c-cwComboBoxView2__icon--disabled)": "_buttonSearch",
      "click .c-cwComboBoxView2__input:not([readonly])": "_inputSearch",
      "autocompleteselect": "_storeValue",
      "autocompletefocus": "_focus",
      "keydown :not([readonly]):not([disabled])": "_keyDownEvent"
    }, params.events);
    super(params);
    //Default values from class
    this.template = CWComboBoxView2TPL;
    this.isComboBoxView2 = true;
    this.multiselection = false;
    this.readonly = false;
    this.enumColl = null;
    this.name = "";
    this.required = false;
    this.infobulle = false;
    this.autreText = null;
    this.disableEmptyItem = false;
    this.autocompleteMode = false;
    this.useExternalErrorContainer = false;
    this.setOutErrorContainer = false;
    this.syncExternalCache = false;
    this.term = "";
    this.delay = 500;
    this.selectLetter = [];
    this.searchLetters = "";
    this.keepOldId = null;
    this.appended = false;
    this.searching = false;
    this.setItemPendingAction = false;
    this.shouldCloseMenu = false;
    this.isHoverSelect = false;
    this.refreshCombo = true;
    // set the collection used to retrieve the data
    this.coll = params.ws;
    this.enumColl = params.enum;
    // placeholder value
    this.placeholder = params.placeholder as string;
    // class name
    this.name = params.name;
    // if true removes the blank option
    if (_.isBoolean(params.required)) {
      this.required = params.required;
    }
    if (_.isBoolean(params.infobulle)) {
      this.infobulle = params.infobulle;
    }
    //autreText for the required option
    this.autreText = params.autreText;
    //cas spécial: disableEmptyItem
    this.disableEmptyItem = false;
    if (_.isBoolean(params.disableEmptyItem) && CWSTR.isBlank(this.autreText)) {
      //Seuelement lorsque il n'avait pas de valeur dans "autreText", on appliquera la valeur de disableEmptyItem
      this.disableEmptyItem = params.disableEmptyItem;
    }
    //if autocomplete mode is allowed, component uses search term to filter results in WS
    if (_.isBoolean(params.autocomplete)) {
      this.autocompleteMode = params.autocomplete;
    }
    if (this.coll && this.autocompleteMode === true && !this.coll.applyFilter) {
      CWLOG.debug("You must define a collection to allow autocomplete");
    }
    if (params.optionsRender) {
      this.optionsRender = params.optionsRender;
    }
    //Input renderer: optional parameter to determine how to show the option selected in the input
    if (params.inputRender) {
      this.inputRender = params.inputRender;
    }
    this.width = params.width;
    this.height = params.height;
    // externalErrorContainer : if an external error container is defined for this component we
    // do not add out internal error container
    if (_.isBoolean(params.useExternalErrorContainer)) {
      this.useExternalErrorContainer = params.useExternalErrorContainer;
    } else if (params.useExternalErrorContainer) {
      throw new Error("Parameter 'useExternalErrorContainer' should be a boolean ");
    }
    if (_.isBoolean(params.setOutErrorContainer)) {
      this.setOutErrorContainer = params.setOutErrorContainer;
    }
    // used to share the combos results between differents combos (example in Motif absence --> droits panel)
    if (params.externalCache) {
      this.syncExternalCache = true;
      this.cache = params.externalCache;
    } else {
      this.syncExternalCache = false;
      this.cache = {};
    }
    if (_.isBoolean(params.syncExternalCache)) {
      this.syncExternalCache = params.syncExternalCache;
    }
    // if autocompleteMode == true, the external cache is not allowed
    if (this.autocompleteMode === true) {
      this.syncExternalCache = false;
      this.cache = {};
    }
    this.cache["pendingInUse"] = this.cache["pendingInUse"] || [];
    this.term = "";
    // used to iterate thru the list of result starting with the same letter
    this.delay = 500;
    this.selectLetter = [];
    this.searchLetters = "";
    if (params && params.habContext) {
      this.habilitationContext(params.habContext);
    }
    if (params && params.multiselection) {
      this.multiselection = params.multiselection;
    }
    if (this.multiselection === true) {
      // called with the selected items
      /**
       * Callback function to be executed when a new item is added to the list
       */
      this.addItemCallback = params.addItemCallback;
      /**
       * Callback function to be executed when a new item is removed from the list
       */
      this.removeItemCallback = params.removeItemCallback;
      // response list filled with user selection
      /**
       * Current selection in the list
       */
      this.selection = new CWAutocompleteColl();
      this.selection.on("click:item", this._removeItem, this);
      this.selection.on("reset", this._resetItems, this);
    }
    if (params && params.preprocessBeforeSetItem) {
      this.preprocessBeforeSetItem = params.preprocessBeforeSetItem;
    }
    if (params._isValidComboId) {
      this._isValidComboId = params._isValidComboId;
    }
    this.keepOldId = null;
    if (params && params.keepOldId) {
      this.keepOldId = params.keepOldId;
    }
    this.refreshCombo = true;
  }

  render(): CWComboBoxView2 {
    let dropDownButton: JQuery = null;
    let div: JQuery = null;
    let classNames: string = null;

    this.$el.empty();
    this.$el.html(this.template());
    div = this.$el.find(".c-cwComboBoxView2__content");
    this.input = this.$el.find(".c-cwComboBoxView2__input");
    this.input.addClass("form-control");
    //set placeHolder value
    if (!CWSTR.isBlank(this.placeholder)) {
      this.input.attr("placeholder", this.placeholder);
    }
    // Prepend combobox name class
    classNames = this.input.attr("class");
    this.input.attr("class", this.name + " " + classNames);
    this.input.prop({ "viewRef": this });
    if (!CWSTR.isBlank(this.keepOldId)) {
      this.input.attr("id", this.keepOldId);
    }
    if (this.autocompleteMode !== true) {
      this.input.css("cursor", "default");
    }
    this.appended = false;
    $(this.input).autocomplete({
      minLength: 0,
      appendTo: null,
      position: {
        my: "left top",
        at: "left bottom",
        collision: 'flipfit',
        of: this.input
      },
      open: () => {
        const menu = $(this.input).data("ui-autocomplete").menu;
        let code = $(this.input).prop("data-code");

        if (!menu.activeMenu.hasClass("c-cwComboBoxView2__list")) {
          menu.activeMenu.addClass("c-cwComboBoxView2__list");
        }
        if (!menu.activeMenu.hasClass("c-panneauMenu")) {
          menu.activeMenu.addClass("c-panneauMenu");
        }
        if (!menu.activeMenu.hasClass("c-panneauMenu--noIcon")) {
          menu.activeMenu.addClass("c-panneauMenu--noIcon");
        }
        if (this.multiselection === true && !CWSTR.isBlank(code)) {
          code = undefined;
        }
        if (!CWSTR.isBlank(code) && !this.searching) {
          let codeSel = code.toString().replace(/ /g, "\\ "); //To let selecting codes that contain spaces
          let element: JQuery = null;

          codeSel = codeSel.replace("*", "\\*");
          element = $(menu.element).find("[data-value=" + UTILS.escapeJQueryString(codeSel) + "]");
          if (element && element.length > 0) {
            menu.focus(null, element);
          }
        }
        return false;
      },
      close: (): boolean => {
        try {
          $(document).off("wheel." + this.cid);
          $(document).off("mousedown." + this.cid);
        } catch (error) {
          CWLOG.error("Cannot remove event handler: " + this.cid);
        }
        this.searching = false;
        $(this.input).data('ui-autocomplete').term = null;
        if (this.cache["current"] && this.autocompleteMode === true) {
          this._setItem(this.cache["current"].attrs);
        }
        if (this.multiselection === true) {
          this._setInputValue("");
        }
        return false;
      },
      focus: (event: JQueryEventObject, ui: any) => {
        if (ui && ui.item) {
          this._renderColorComboChange(ui.item.attrs);
        }
        return false;
      },
      create: (): void => {
        const menu = $(this.input).data("ui-autocomplete").menu;

        menu.activeMenu.addClass("c-cwComboBoxView2__list c-panneauMenu c-panneauMenu--noIcon");
        $(menu.activeMenu).css("position", "fixed");
        $(this.input).data('ui-autocomplete')._renderItem = (ul: any, item: any): JQuery => {
          return this._renderItem(this.input, ul, item);
        };
        $(this.input).data('ui-autocomplete')._resizeMenu = (): any => {
          const lis = menu.element.find("li");
          const padding = (!_.isEmpty(lis) ? parseInt($(lis[0]).css("padding-left").replace("px", "")) + parseInt($(lis[0]).css("padding-right").replace("px", "")) : 0);
          const size = lis.length;
          let minWidth = 20;
          const mirror = $("body .cloned_elements .cw-combobox2_clone_elements"); //Generate a mirror to measure each element's width
          let tmpWidth: JQuery = null;
          let tmpWidthContainer: number = null;
          let tabContanerHeight = 0;
          const limitContainer = $("#phx-container");

          if (size > 0 && mirror.length > 0) {
            let lenTexteMax = lis.eq(0).text().length;
            let currentWidth = 0;
            let listElement = [];

            for (let i = 1; i < size; i++) {
              const lenTextAux = lis.eq(i).text().length;

              if (lenTextAux > lenTexteMax) {
                lenTexteMax = lenTextAux;
                listElement = [];
                listElement.push(i);
              } else if (lenTextAux === lenTexteMax) {
                listElement.push(i);
              }
            }
            for (let j = 0; j < listElement.length; j++) {
              mirror.append(lis.eq(listElement[j]).text().toUpperCase()); //pour avoir la largeur plus grande (majuscule)
              if (j < (listElement.length - 1)) {
                mirror.append($("</br>"));
              }
            }
            $.each(['fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'letterSpacing', 'textTransform', 'wordSpacing', 'textIndent'], (aPosText: any, val: any) => {
              mirror[0].style[val] = lis.eq(aPosText).css(val);
            });
            currentWidth = mirror.width();
            if (size === 1 && currentWidth < lis.eq(0).getHiddenDimensions().width) {//cas spécial pour une seule option
              currentWidth = lis.eq(0).getHiddenDimensions().width;
            }
            currentWidth += padding;
            if (currentWidth > minWidth) {
              minWidth = currentWidth;
            }
            mirror.empty(); //clean once it has been used to calculate width of each line
            mirror.attr("style", "left:0; white-space:pre;"); //retablir les styles                        
          }
          if (size > 20) {
            minWidth += 14; // adjusted to show the scroll
          }
          // adjust the size of the elements, to the size of the combo.
          tmpWidth = $("<div>").width(this.width); //pour faire les conversions de "auto", "100%", "300px" et "1em" à les mêmes unités que minWidth
          /*customer 205285*/
          tmpWidthContainer = this.$el.find(".c-cwComboBoxView2__content").width();
          if (tmpWidth && minWidth < (tmpWidth.width() - 3)) {
            minWidth = tmpWidth.width() - 3;
          } else if (minWidth < tmpWidthContainer) {
            minWidth = tmpWidthContainer - 3;
          }
          menu.element.innerWidth(minWidth);
          if (this.appended === false) {
            //AppendTo option in order to let jquery recalculate z-index for each element
            $(this.input).autocomplete("option", "appendTo", null);
            this.appended = true;
          }
          $(".ui-tabs-nav").each((index, tab): any => {
            if (tabContanerHeight < $(tab)[0].offsetHeight) {
              tabContanerHeight = $(tab)[0].offsetHeight;
            }
          });
          //Get the limit container
          if (this.input && $(this.input) && limitContainer && $(this.input).offset()) {
            const scrollTop = $(window).scrollTop(),
              scrollBot = scrollTop + $(window).height(),
              elTop = limitContainer.offset().top,
              elBottom = elTop + limitContainer.outerHeight(),
              visibleTop = elTop < scrollTop ? scrollTop : elTop,
              visibleBottom = elBottom > scrollBot ? scrollBot : elBottom;
            let distanceDromInputToTopContainer: number = null;
            let distanceFromInputToButtom: number = null;
            let heightOfSelectDespl: number = null;
            let heightOfTheInput: number = null;

            menu.element[0].style.maxHeight = ""; //set default heiht
            distanceDromInputToTopContainer = $(this.input).offset().top - (limitContainer.offset().top + tabContanerHeight);
            distanceFromInputToButtom = (visibleBottom - visibleTop - tabContanerHeight) - distanceDromInputToTopContainer;
            heightOfSelectDespl = menu.element[0].offsetHeight;
            heightOfTheInput = this.input[0].offsetHeight;
            distanceFromInputToButtom -= heightOfTheInput;
            if (distanceFromInputToButtom < heightOfSelectDespl) {
              if (distanceFromInputToButtom > distanceDromInputToTopContainer) {
                let maxHeight = distanceFromInputToButtom - 15;

                if (maxHeight < 20) {
                  maxHeight = 20;
                }
                menu.element[0].style.maxHeight = maxHeight + "px";
              } else {
                menu.element[0].style.maxHeight = (distanceDromInputToTopContainer - 15) + "px";
              }
            }
          }
        };
        this.refreshCombo = false;
      }
    });
    dropDownButton = this.$el.find(".c-cwComboBoxView2__icon");
    dropDownButton.append(UTILS.getSVGIcon('fleche_bas', '', 16, undefined));
    if (this.autreText) {
      this._fetchCombo();
      // autocomplete input
      $(this.input).autocomplete("option", "source", (request: any, response: any) => {
        this.trigger("comboEdited");
        this._fetchCombo(response);
      }

      );
    } else {
      // autocomplete input
      $(this.input).autocomplete("option", "source",
        (request: any, response: any) => {
          this.term = "";
          if (this.autocompleteMode === true) {
            this.term = request.term;
          }
          //removed code(see previous rev if any problems)
          //when the term in is cache still apply the filter instead of showing the menu
          //the menu items(last menu items loaded) might not be correct for filtered term
          if (this.term in this.cache) {
            this._updatePendingInUse(this.cache[this.term]);
            response(this._applyFilter(this.cache[this.term]));
            this._repositionMenu();
          } else {
            if (this.coll && this.autocompleteMode === true && this.coll.applyFilter) {
              _.extend(this.coll.params, { "search": this.term });
            }
            this.trigger("comboEdited");
            this._fetchCombo(response);
          }
        }
      );
    }
    div.width(this.width);
    this._setHeight();
    this.$el.attr("cid", this.cid);
    $(this.input).attr("cid", this.cid);
    dropDownButton.attr("cid", this.cid);
    if (this.multiselection === true) {
      const resultList = $("<div>");

      //make the result list container
      resultList.addClass("phx-list-builder-selection");
      $(this.el).append(resultList);
    }
    if (this.useExternalErrorContainer === false && this.setOutErrorContainer === false) {
      const error = $("<span class='" + this.name + "-error-container'>");

      $(this.el).append(error);
    }
    this.delegateEvents();
    this._registerResizeEvent();
    return this;
  }

  /**
   * Retrieve information from the WS for the combo
   *
   * It caches the response returned from the WS for the term used (if autocompleteMode == true)
   */
  protected _fetchCombo(response?: (...args: Array<any>) => void): void {
    if (this.coll && (!this.cache[this.term] || this.cache[this.term].length === 0) && this._isValidComboId()) {
      if (!CWSTR.isBlank(this.attributes) && !CWSTR.isBlank(this.attributes.souhait) && this.attributes.souhait === true) {
        _.extend(this.coll.params, { "souhait": true });
      }
      this.coll.fetch({
        success: (resp: Backbone.Collection<any> | CWBaseModel) => {
          this.processFetchResponse(resp, response);
        },
        error: (): any => {
          if (response) {
            response([]);
          }
        }
      });
    } else if (this.enumColl && (!this.cache[this.term] || this.cache[this.term].length === 0)) {
      this.cache[this.term] = [];
      if (this.autreText && this.required) {
        this.cache[this.term].push(this._otherText());
      }
      if (this.required !== true && _.keys(this.enumColl).length > 0 && this.disableEmptyItem !== true) {
        this.cache[this.term].push(this._emptyItem());
      } //AD
      _.each(this.enumColl, (item: { [key: string]: any }) => {
        if (!CWSTR.isBlank(item.code)) {
          this.cache[this.term].push(this._formatItem(item));
        } else {
          this.cache[this.term].push(this._emptyItem());
        }
      });
      this._updatePendingInUse(this.cache[this.term]);
      if (_.keys(this.enumColl).length > 0 && this.setItemPendingAction === true) {
        this._setItem(this.setItemPendingValue);
      }
      if (response) {
        response(this._applyFilter(this.cache[this.term]));
      }
    } else {
      if (response) {
        response(this._applyFilter(this.cache[this.term]));
      }
    }
  }

  private processFetchResponse(resp: Backbone.Collection | { attributes: { [key: string]: any } }, response?: (...args: Array<any>) => void): void {
    if (resp instanceof Backbone.Collection) {
      this.cache[this.term] = this._formatResponse(resp.models);
    } else {
      this.cache[this.term] = this._formatResponse(resp.attributes);
    }
    // clear dropdown content if fetch return empty list of elements
    if (this.cache[this.term].length === 0) {
      if ($(this.input).hasClass('ui-autocomplete-input')) {
        $(this.input).autocomplete("widget").empty();
      }
    }
    if (this.autreText && this.required) {
      this.cache[this.term].unshift(this._otherText());
    }
    this._updatePendingInUse(this.cache[this.term]);
    if (this.setItemPendingAction === true && !CWSTR.isBlank(resp) && (resp instanceof Backbone.Collection && !CWSTR.isBlank(resp.get(this.setItemPendingValue.code)))) {
      this._setItem(this.setItemPendingValue);
    }
    if (response) {
      response(this._applyFilter(this.cache[this.term]));
    }
  }

  _isValidComboId(): any {
    return true;
  }

  /**
   * Capture key events avoid input modification when autocompleteMode == false
   *
   */
  private _keyDownEvent(e: JQueryKeyEventObject): boolean {
    if (this.shouldCloseMenu === true) {
      if ($(this.input).autocomplete("widget").is(":visible")) {
        $(this.input).autocomplete("close");
      }
      this.shouldCloseMenu = false;
    } else {
      const passedBy = [9, 13, 27, 91, 92];
      let key: number = null;

      this._registerOneMousedonwEvent();
      this._registerOneWheelEvent();
      if (this.autocompleteMode === true) {
        this.searching = true;
        return true;
      }
      key = e.which || e.keyCode;
      // If ctrl + c or ctrl+v do nothing.
      if (e.ctrlKey && (key === 99 || key === 118)) {
        return true;
      }
      // function keys
      if (key >= 112 && key <= 123) {
        return true;
      }
      if (_.indexOf(passedBy, key) !== -1) {
        return true;
      }
      this.searchLetters += String.fromCharCode(key);
      this._delay((): void => {
        if (this.searchLetters !== "") {
          this._selectItemStartingWithLetter();
        }
      }, this.delay);
    }
    e.preventDefault();
    return false;
  }

  /**
   * Delay the call to the handler function
   *
   */
  private _delay(handler: () => void | string, delay: number): any {
    const instance = this; // eslint-disable-line @typescript-eslint/no-this-alias
    const handlerProxy = function (): any {
      return (typeof handler === "string" ? instance[handler] : handler).apply(instance, arguments);// eslint-disable-line
    };

    return setTimeout(handlerProxy, delay || 0);
  }

  /**
   * Selects the entries starting with the same letter as the letter received from the keyevent
   * If entries > 0 then it iterates thru them
   *
   */
  private _selectItemStartingWithLetter(): any {
    const baseLetter = this.searchLetters.toUpperCase();

    this.searchLetters = "";
    //customer 163495 error when type at input when none comboId is setted by this ws collection.
    if (!this._isValidComboId()) {
      return;
    }
    this._fetchCombo((list: Array<{ [key: string]: any }>): void => {
      let responseIndex = -1;
      let lastIndex = -1;
      let index = 0;
      let substringLength = 0;

      if (CWSTR.isBlank(this.selectLetter[baseLetter])) {
        this.selectLetter[baseLetter] = 0;
      }
      substringLength = baseLetter.length;
      for (let i = list.length - 1; i >= 0; i--) {
        const item = list[i];

        if (item && !CWSTR.isBlank(item.label)) {
          let firstLetter: string = null;

          if (typeof item.label === "string") {
            firstLetter = item.label.substr(0, substringLength).toUpperCase();
          } else {
            firstLetter = item.label.text().substr(0, substringLength).toUpperCase();
          }
          if (baseLetter === firstLetter) {
            if (i > this.selectLetter[baseLetter]) {
              responseIndex = i;
            }
            lastIndex = i;
          }
        }
      }
      index = (responseIndex >= 0) ? responseIndex : lastIndex;
      if (index >= 0) {
        this.selectLetter[baseLetter] = index;
        this._setItem(list[index].attrs);
        $(this.input).trigger("change", list[index].id);
        $(this.input).data("ui-autocomplete")._trigger("open");
      }
    });
  }

  /**
   * This function is called when we click on the dropdown button
   *
   * It triggers the search action on the autocomplete plugin to open the dropdown menu
   *
   */
  public _buttonSearch(event?: any): boolean { //eslint-disable-line
    if (this.$el.find(".c-cwComboBoxView2__input").is(":not([disabled])")) {
      if (this.shouldCloseMenu === true) {
        if ($(this.input).autocomplete("widget").is(":visible")) {
          $(this.input).autocomplete("close");
        }
        this.shouldCloseMenu = false;
      } else {
        $(this.input).autocomplete("search", "");
        $(this.input).focus();
        this._registerOneMousedonwEvent();
        this._registerOneWheelEvent();
      }
    }
    return false;
  }

  private _isListVisible(): boolean {
    if ($(this.input).hasClass("ui-autocomplete-input")) {
      return $(this.input).autocomplete("widget").is(":visible");
    }
    return false;
  }

  private _repositionMenu(): void {
    const menu = $(this.input).data("ui-autocomplete").menu;
    const position = $(this.input).autocomplete("option", "position");

    menu.element.position(position);
  }

  /**
   * Reposition combo list when window is resized
   */
  private _registerResizeEvent(): any {
    this.listenTo(objs.appRt.workflow, "resize", () => {
      if (this._isListVisible()) {
        this._repositionMenu();
      }
    });
  }

  /**
   * Close autocomplete dropdown when scrolling outside.
   */
  private _registerOneWheelEvent(): any {
    $(document).off("wheel." + this.cid);
    $(document).one("wheel." + this.cid, (event: JQuery.TriggeredEvent): void => {
      this.shouldCloseMenu = false;
      // wheel on the list
      try {
        if ($(event.target).attr("id") === $(this.input).autocomplete("widget").attr("id") || $(this.input).autocomplete("widget").find(event.target).length > 0) {
          this._registerOneWheelEvent();
        } else if ($(this.input).autocomplete("widget").find(this.target).length === 0) {
          if ($(this.input).autocomplete("widget").is(":visible")) {
            $(this.input).autocomplete("close");
            if (this.cache["current"] && this.cache["current"].attrs && this.multiselection === false) {
              this._renderColorComboChange(this.cache["current"].attrs);
            } else if (CWSTR.isBlank(this.currentCode)) {
              this._setInputValue("");
            }
          }
          this.shouldCloseMenu = false;
        }
      } catch (e) {
        CWLOG.error("Autocomplete__registerOneWheelEvent: " + e);
      }
    });
  }

  private _registerOneMousedonwEvent(): any {
    $(document).off("mousedown." + this.cid);
    $(document).one("mousedown." + this.cid, (event: JQuery.TriggeredEvent): void => {
      this.shouldCloseMenu = false;
      // clicked on the scroll of the list
      try {
        if ($(event.target).attr("id") === $(this.input).autocomplete("widget").attr("id")) {
          this._registerOneMousedonwEvent();
          return;
        } else if ($(this.input).autocomplete("widget").find(event.target).length === 0) {
          if ($(this.input).autocomplete("widget").is(":visible")) {
            $(this.input).autocomplete("close");
            if (this.cache["current"] && this.cache["current"].attrs && this.multiselection === false) {
              this._renderColorComboChange(this.cache["current"].attrs);
            } else if (CWSTR.isBlank(this.currentCode)) {
              if (this.autreText && this.required) {
                this._setInputValue(this.autreText);
              } else {
                this._setInputValue("");
              }
            }
          }
          this.shouldCloseMenu = false;
        }
      } catch (e) {
        CWLOG.error("Autocomplete__registerOneMousedonwEvent: " + e);
      }
      if (this.$el.find(event.target).length > 0 && $(event.target).hasClass("c-cwComboBoxView2__icon")) {
        if ($(this.input).autocomplete("widget").is(":visible")) {
          this.shouldCloseMenu = true;
        }
      }
    });
    $(document).off("tabsactivate." + this.cid);
    $(document).one("tabsactivate." + this.cid, (): any => {
      this.shouldCloseMenu = false;
      if (!CWSTR.isBlank($(this.input).data('ui-autocomplete'))) {
        $(this.input).autocomplete("close");
      }
    });
  }

  /**
   * This function is called when we click on the input field and the autocompleteMode == false
   *
   * It triggers the search action on the autocomplete plugin to open the dropdown menu
   *
   */
  private _inputSearch(): any {
    if (this.autocompleteMode !== true) {
      if ($(this.input).autocomplete("widget").is(":visible")) {
        $(this.input).autocomplete("close");
      } else {
        this._buttonSearch();
      }
    } else {
      $(this.input).autocomplete("close");
    }
  }

  private _focus(event: JQueryKeyEventObject): void {
    // solve problem when mouse is over the menu but the user uses the keyboard to validate the entry
    if (typeof event.keyCode === 'undefined' || String(event.keyCode) === "0" || String(event.keyCode) === "38" || String(event.keyCode) === "40") {
      this.isHoverSelect = true;
    } else {
      this.isHoverSelect = false;
    }
  }

  /**
   * This function is called when the autocomplete plugin trigger the event "autocompleteselect"
   *
   * It occurs when an item from the dropdown menu is selected and trigger a "change" event to the application
   *
   */
  private _storeValue(event: JQueryEventObject | any, ui: any): boolean {
    let trulyClick = false;

    if (event.originalEvent.originalEvent.type === "click") {
      trulyClick = true;
    }
    if (trulyClick === false && (_.isBoolean(this.isHoverSelect) && !this.isHoverSelect && typeof event.keyCode !== 'undefined' && event.keycode !== 0)) {
      //just tabbed or hovered and hit enter
      event.preventDefault();
    } else {
      const code = ui.item.id;

      event.stopPropagation();
      // *important: trigger the change event to the parent view with the code
      // of the selected item
      this._setItem({ code: code });
      $(this.input).prop("data-code", code);
      $(this.input).trigger("change", code);
      if (this.multiselection === true) {
        this._selectItem(event);
      }
      return (String(this.currentCode) === String(code));
    }
    return false;
  }

  /**
   * Formats the data returned by the model or collection
   * In the case of a model, it must have at least the following attributs 'code', 'libelle'
   * In the case of a collection, you can override the function 'optionsRender' to adapt it
   * to the needed attributs
   *
   */
  private _formatResponse(response: any): { [key: string]: any } {
    const data = [];
    let counter = 0;
    let length = response.length;

    if (response && _.isObject(response)) {
      const array = $.map(response, (value) => {
        return [value];
      });

      length = array.length;
    }
    if (this.required !== true && length > 0) {
      data[counter] = this._emptyItem();
      counter++;
    }
    _.each(response, (resp): void => {
      const item = this._formatItem(resp);

      if (item) {
        data[counter] = item;
        counter++;
      }
    }, this);

    return data;
  }

  private _emptyItem(): { [key: string]: any } {
    const data: { [key: string]: any } = {};

    data.inUse = false;
    data.label = "";
    data.attrs = { code: null, libelle: "" };
    data.id = null;
    return data;
  }
  private _otherText(): { [key: string]: any } {
    const data: { [key: string]: any } = {};

    data.inUse = false;
    if (this.autreText && this.required) {
      data.id = null;
      data.attrs = { code: null, libelle: $("<div>").text(this.autreText).text() };
      data.label = this.autreText;
    }
    return data;
  }

  private _formatItem(item: Backbone.Model | any): { [key: string]: any } {
    let data: { [key: string]: any } = null;

    if (item instanceof Backbone.Model && !CWSTR.isBlank(item.get("id"))) {
      data = {};
      data.inUse = false;
      data.label = this.inputRender(item.attributes);
      data.attrs = item.attributes;
      data.id = item.get("id");
    } else if (!CWSTR.isBlank(item.code)) {
      data = {};
      data.inUse = false;
      data.label = this.inputRender(item);
      data.attrs = item;
      data.id = item.code;
    }
    return data;
  }

  /**
   * Search and return an item by its id from the current cache
   *
   */
  public _getItemById(id: string): { [key: string]: any } {
    let result = null;

    if (!CWSTR.isBlank(id)) {
      const list = this.cache[this.term];

      if (list) {
        result = _.find(list, (obj: { [key: string]: any }) => { return String(obj.id) === String(id); });
      }
    }
    return result;
  }

  /**
   * Indicates if the current id is being used by this combo or another combo that shares the same cache
   *
   */
  private _inUseState(id: string, inUse: boolean): void {
    const item = this._getItemById(id);

    if (item) {
      item.inUse = (inUse && this.syncExternalCache);
    } else {
      this.cache["pendingInUse"].push({ id: id, inUse: inUse && this.syncExternalCache });
    }
  }

  /**
   * 'pendingInUse' is a temporary cache that is used while no call to the underlying WS has been made
   * We use this cache to store the state of the item.
   * When a call to the WS is made this cache is cleaned and synchronized with the current cache
   *
   */
  private _updatePendingInUse(data: any): void {
    _.each(this.cache["pendingInUse"], (obj: { [key: string]: any }) => {
      const item = _.find(data, (o: { [key: string]: any }) => {
        //return (!CWSTR.isBlank(o.id) && !CWSTR.isBlank(obj.id) && String(o.id) === String(obj.id));
        return (String(o.id) === String(obj.id));
      });

      if (item) {
        item.inUse = obj.inUse;
      }
    }, this);
    this.cache["pendingInUse"].length = 0;
  }

  /**
   * Retrieve the list of items currently used by the combo or the combos that shares the same cache
   */
  inUseItems(): any {
    return _.union(_.where(this.cache[this.term], { inUse: true }), _.where(this.cache["pendingInUse"], { inUse: true }));
  }

  setItem(item: { [key: string]: any }, callback?: (...args: Array<any>) => void | any): void {
    this.clean();
    if (this.preprocessBeforeSetItem) {
      item = this.preprocessBeforeSetItem(item);
    }
    this._setItem(item, callback);
    if (this.multiselection === true) {
      this.selection.add([item], { parse: true } as any);
      // paint items
      this._paintItems();
    }
  }

  setItems(items: { [key: string]: any }): void {
    if (!CWSTR.isBlank(items) && this.multiselection === true) {
      this.clean();
      for (let i = 0; i < items.length; i++) {
        this._setItem(items[i]);
        this.selection.add([items[i]], { parse: true } as any);
      }
      // paint items
      this._paintItems();
    }
  }

  /**
   * Mainly used in the function mapModelToForm, it initializes the combo with the models value
   */
  private _setItem(item: { [key: string]: any }, callback?: (...args: Array<any>) => void | any): void {
    let code = null;

    this.setItemPendingAction = false;
    if (!CWSTR.isBlank(item) && !CWSTR.isBlank(item.code)) {
      code = item.code;
    } else if (!CWSTR.isBlank(item) && !CWSTR.isBlank(item.attrs)) {
      code = item.id;
    }

    if (item && !CWSTR.isBlank(code)) {
      const val = this._getItemById(code);

      this._inUseState(this.currentCode, false);
      if (val) {
        $(this.input).prop("data-code", val.id);
        this.cache["current"] = val;
        this._renderColorComboChange(val.attrs);
        this.refreshCombo = true;
        if (callback) {
          callback();
        }
      } else {
        if (CWSTR.isBlank(item.libelle)) {
          this.setItemPendingAction = true;
          this.setItemPendingValue = item;
          this.fetchCombo(callback);
        } else {
          this.cache["current"] = this._formatItem(item);
          this._renderColorComboChange(item);
          if (callback) {
            callback();
          }
        }
        $(this.input).prop("data-code", item.code);
      }
      this.currentCode = code;
      this._inUseState(this.currentCode, true);
    } else {
      $(this.input).prop("data-code", "");
      if (this.autreText) {
        this._setInputValue(this.autreText);
      } else {
        this._setInputValue("");
      }
      this._removeColorClasses();
      this._inUseState(this.currentCode, false);
      this.currentCode = null;
      this.cache["current"] = {};
      if (callback) {
        callback();
      }
    }
  }

  public setInputValue(value: string): void {
    this._setInputValue(value);
  }

  private _setInputValue(value: string): void {
    let fields: JQuery;

    if (this.name) {
      fields = $("." + this.name.replace(/\./g, "\\."), this.el);
    }
    _.each(fields, (field) => {
      if ($(field).is("span")) {
        $(field).html(value);
      } else {
        $(field).val(value);
      }
    }, this);
  }

  /**
   * Called the first time to initialize the context of the combo
   */
  habilitationContext(context: CWHabilitationContext): void {
    if (this.coll) {
      if (CWSTR.isBlank(this.coll.habContext) || !_.isEqual(this.coll.habContext.toJSON(), context.toJSON())) {
        this.clearCache();
      }
      this.stopListening();
      this.coll.setHabContext(context);
      this.listenTo(context, "change", this.clearCache);
    }
  }

  /**
   * This function is used to filter the response received from the WS
   * It can be overriden thru the function 'setFilter'
   *
   */
  private _filter(response: { [key: string]: any }): { [key: string]: any } {
    return response;
  }

  /**
   * Apply the filter to the response
   *
   */
  private _applyFilter(response: { [key: string]: any }): { [key: string]: any } {
    let filtered = response;

    if (this._filter) {
      filtered = this._filter(response);
    }
    if (this.required && CWSTR.isBlank(this.autreText)) {
      // we remove the empty row
      return _.filter(filtered, (item) => {
        if (CWSTR.isBlank(item)) {
          return false;
        } else {
          return !CWSTR.isBlank(item.id);
        }
      });
    }
    return filtered;
  }

  /**
   * Pass a callback to the new filter function to be used. This will replace the default '_filter' behavior.
   */
  setFilter(filterCallback: (...args: Array<any>) => void | any): void {
    const existFiltrePrec = (typeof this._filter === "function");

    this._filter = filterCallback;
    //If we do the call, we need to re-apply the filter to see the corrects results
    if ((this.coll && this.coll.attributes && !_.isEmpty(this.coll.attributes[0])) || (existFiltrePrec && this.enum && this.enum.length > 0)) {
      this.refreshCombo = true;
    }
  }

  /**
   * Public function to call the underlying WS of the combo
   * A callback function can be provided that will be called once the data is available
   */
  fetchCombo(doneCallback: (...args: Array<any>) => void | any): void {
    this._fetchCombo(doneCallback);
  }

  /**
   * Set the state of the combo, taking in account the Habilitations
   */
  enable(enabled: boolean, argContext?: any): void {
    let context = argContext;

    if (!context) {
      context = this.$el;
    }
    CWFORMS.setFieldReadonly(context.find(".c-cwComboBoxView2__input"), !enabled);
  }

  /**
   * Enables/Disables the Combo
   */
  private _enable(enabled: boolean): void {
    $(".c-cwComboBoxView2__input", this.$el).attr("readonly", enabled ? null : "true");
    $(".c-cwComboBoxView2__content", this.$el).attr("readonly", enabled ? null : "true");
    if (enabled) {
      $(".c-cwComboBoxView2__icon", this.$el).removeClass("c-cwComboBoxView2__icon--disabled");
    } else {
      $(".c-cwComboBoxView2__icon", this.$el).addClass("c-cwComboBoxView2__icon--disabled");
    }
    if (enabled) {
      $(".c-cwComboBoxView2__input", this.$el).css("width", "");
    }
    if (!enabled === true) {
      if ($(".c-cwComboBoxView2__input", this.$el).hasClass("form-control")) {
        $(".c-cwComboBoxView2__input", this.$el).parent(".input-group").addClass("readonly-within");
      }
    } else {
      if ($(".c-cwComboBoxView2__input", this.$el).hasClass("form-control")) {
        $(".c-cwComboBoxView2__input", this.$el).parent(".input-group").removeClass("readonly-within");
      }
    }
  }

  /**
   * Retrieve the current value of the combo
   */
  getItem(): any {
    let item = this._getItemById(this.currentCode);

    if (!item) {
      item = _.find(this.cache["pendingInUse"], (o): boolean => {
        return String(o.id) === String(this.currentCode);
      });
    }
    return item;
  }

  getItemId(): any {
    const item = this.getItem();
    let returnedValue = null;

    // user invented values are not valid
    if (!CWSTR.isBlank(item)) {
      returnedValue = item.id;
    } else {
      returnedValue = this.currentCode;
    }
    return returnedValue;
  }

  setCache(term: string, list: any): void {
    this.term = term;
    this.cache[this.term] = this._formatResponse(list);
  }

  getCache(): any {
    return this.cache[this.term];
  }

  clearCache(): any {
    for (const i in this.cache) {
      if (Object.prototype.hasOwnProperty.call(this.cache, i)) {
        this.cache[i].length = 0;
        delete this.cache[i];
      }
    }
    this.cache["pendingInUse"] = this.cache["pendingInUse"] || [];
  }

  clearColl(): any {
    if (this.coll instanceof CWPaginatedCollection) {
      if (this.coll._events) {
        this.coll.trigger("reset");
      } else {
        //Pour rédemarrer la valeur de this.coll.pagination.size-> "resetPagination"
        this.coll.resetPagination();
        this.coll.reset();
      }
    } else {
      // This removes the id too
      this.coll.clear();
    }
  }

  /**
   * Method to do when the combo value is changed. Add the style color and background to the
   * selected value
   */
  private _renderColorComboChange(item: { [key: string]: any }): void {
    let bakRGB = "";
    let textRGB = "";
    let className = "";

    if (item && item.coularp) {
      if (!CWSTR.isBlank(item)) {
        bakRGB = this._getColorRGB(item.coularp);
        textRGB = this._getColorRGB(item.coulavp);

        //USE CLASSNAME OR COULARP AND COULAVP, NOT BOTH
        //If component applies a class to its options to define background and text color instead of coularp and coulavp
        if (!CWSTR.isBlank(item.className)) {
          className = item.className;
        }
      }
      $(this.el).removeClass("ui-phx-anomalie-bloquante ui-phx-anomalie-persistante ui-phx-anomalie-non-bloquante ui-phx-anomalie-ignoree");
      if (!CWSTR.isBlank(className)) {
        $(this.el).addClass(className);
      } else {
        $(this.input).css("color", textRGB);
        $(this.input).css("background-color", bakRGB);
      }
    }
    this._removeColorClasses();
    if (item && (item.affichage || (item.style && item.style.affichage))) {
      const affichage = (item.style && item.style.affichage) ? item.style.affichage : item.affichage;

      //add new color class
      $(this.input).addClass(affichage);
      $(this.input).siblings('.input-group-append,.input-group-prepend').find('.input-group-text').addClass(affichage);
    }
    this._setInputValue(this.inputRender(item));
  }

  /**
   * Removes the color classes in the combo
   */
  private _removeColorClasses(): any {
    //Delete all the color classes
    if ($(this.input) && $(this.input).length > 0) {
      this._removeColorClassesFromItem($(this.input)[0]);
      this._removeColorClassesFromItem($(this.input).siblings('.input-group-append').find('.input-group-text')[0]);
      this._removeColorClassesFromItem($(this.input).siblings('.input-group-prepend').find('.input-group-text')[0]);
    }
  }

  private _removeColorClassesFromItem(element: HTMLElement): any {
    if (element) {
      const classes = element.className.split(" ").filter((c: string) => {
        return (c.lastIndexOf("ui-phx-color", 0) !== 0 && c.lastIndexOf("ui-phx-letiation", 0) !== 0);
      });

      element.className = $.trim(classes.join(" "));
    }
  }

  /**
   * Gets the color in css style
   */
  private _getColorRGB(color: { [key: string]: any }): string {
    let rtn = "rgb(255,255,255)";

    if (color) {
      rtn = "rgb(" + color.coder + "," + color.codeg + "," + color.codeb + ")";
    }
    return rtn;
  }

  private _renderItem(component: any, ul: JQuery, item: { [key: string]: any }): JQuery {
    let colBackground = undefined;
    let colText = undefined;
    let text = this.optionsRender(item.attrs);
    let emptyItem = false;
    let a = null;
    let infoBulle = null;

    if (text === "") {
      text = "&nbsp;Empty";
      emptyItem = true;
    }
    if (CWSTR.isBlank(text) || typeof text === "string") {
      a = $("<a>").text(text as string);
      //Si on a un infobulle sur les elements de la liste
      if (this.infobulle && !CWSTR.isBlank(item.attrs.desc)) {
        a.attr("title", item.attrs.desc);
      }
    } else {
      infoBulle = $(text).attr("title");
      $(text).attr("title", null); //on le supprime et il sera ajouté à "<li>"
      a = $("<a>").append(text); //Pour peindre "html" dans les items du menu(infobulle, etc.)
    }
    if (emptyItem === true) { //text-indent in order to avoid the text (Empty) to be shown as an option
      a.css("text-indent", "-9999px");
    }
    //If the component has background color, apply it to the option.
    if (!CWSTR.isBlank(item.attrs.coularp)) {
      colBackground = item.attrs.coularp;
      a.css("background-color", "rgb(" + colBackground.coder + "," + colBackground.codeg + "," + colBackground.codeb + ")");
    }
    //If the component has text color, apply it to the option.
    if (!CWSTR.isBlank(item.attrs.coulavp)) {
      colText = item.attrs.coulavp;
      a.css("color", "rgb(" + colText.coder + "," + colText.codeg + "," + colText.codeb + ")");
    }
    if (!CWSTR.isBlank(item.attrs.affichage) || (!CWSTR.isBlank(item.attrs.style) && !CWSTR.isBlank(item.attrs.style.affichage))) {
      const affichage = (item.attrs.style && item.attrs.style.affichage) ? item.attrs.style.affichage : item.attrs.affichage;
      a.addClass(affichage);
    }
    //		$(ul).zIndex(this.$el.zIndex() + 1);
    if (String(item.id) === String(this.currentCode) || item.inUse === false) {
      const li = $("<li>").attr("data-value", item.id);

      if (!CWSTR.isEmpty(infoBulle)) {
        li.attr("title", infoBulle);
      }
      return li.append(a).appendTo(ul);
    } else {
      return $("");
    }
  }

  showErrors(): any {
    const span = this.$el.find(".c-cwComboBoxView2__content");

    if (!span.hasClass("ui-state-error")) {
      span.addClass("ui-state-error");
    }
  }

  cleanErrors(): any {
    const span = this.$el.find(".c-cwComboBoxView2__content");

    if (span.hasClass("ui-state-error")) {
      span.removeClass("ui-state-error");
    }
  }

  /**
   * Paints the selected items of the view
   */
  private _paintItems(): any {
    this.$el.find(".phx-list-builder-selection").empty();
    _.each(this.selection.models, (value: { [key: string]: any }): void => {
      const label = this.optionsRender(value.attributes.attrs);
      let itemView: CWComboBox2ResultItemView = null;

      if (CWSTR.isBlank(label) || typeof label === "string") {
        const labelText = label as string;

        itemView = new CWComboBox2ResultItemView({ "label": labelText, "model": value });
      } else {
        const labelJQuery = label as JQuery;

        itemView = new CWComboBox2ResultItemView({ "label": labelJQuery.text(), "model": value });
      }
      this.$el.find(".phx-list-builder-selection").append(itemView.render().el);
    });
    if (this.selection.models.length === 0) {
      this.$el.find(".phx-list-builder-selection").css("display", "inherit");
    } else {
      this.$el.find(".phx-list-builder-selection").css("display", "");
    }
    this.$el.find(".phx-list-builder-selection").position({
      my: "left top",
      at: "left bottom",
      of: this.$el.find(".phx-list-builder-wrap")
    });
  }

  /**
   * Sets the selected values of the list
   */
  setValues(coll: CWPaginatedCollection): void {
    this.selection.reset(null, { silent: true });
    if (coll) {
      this.selection.add(coll.toJSON(), { parse: true } as any);
    }
    // paint selected values
    this._paintItems();
    this.model.on("change:omitedViews", this._omitViews, this);
    this.model.on("resetRendered", this._resetRendered, this);
  }

  /**
   * Gets the selected elements
   */
  getValues(): any {
    return this.selection;
  }

  /**
   * Selects an item in the view
   */
  private _selectItem(event: JQueryEventObject): boolean {
    const id = this.currentCode;

    if (!CWSTR.isBlank(id)) {
      const attrs = this._getItemById(id);

      this._addItem(event, attrs);
    }
    return false;
  }

  /**
   * Adds an item to the selection
   */
  private _addItem(event: JQueryEventObject, attrs: { [key: string]: any }): boolean {
    // clear input field and set focus to it.
    $(this.el).find(".phx-list-builder-select").val("");
    $(this.el).find(".phx-list-builder-select").focus();
    // add item to selection list
    if (CWSTR.isBlank(this.selection.get(attrs.id))) {
      this.selection.add([attrs.attrs], { parse: true } as any);
      if (this.addItemCallback) {
        this.addItemCallback(attrs.attrs, event);
      }
      // paint items
      this._paintItems();
      // Notify edition
    }
    // retur false to prevent bubbling of event
    return false;
  }

  /**
   * Resets the painted items
   */
  private _resetItems(): any {
    // paint items
    this._paintItems();
    // Notify edition
    $(this.el).find(".phx-list-builder-select").trigger("change");
  }

  /**
   * Deletes an existing element from the component selection list
   */
  private _removeItem(model: CWBaseModel, event: JQueryEventObject): void {
    // remove item from collection
    this.selection.remove(model);
    if (this.removeItemCallback) {
      this.removeItemCallback(model.get("attrs"), event);
    }
    $(this.el).find(".phx-list-builder-select").trigger("focus");
    // repaint
    this._paintItems();
  }

  /**
   * Cleans the selection list and the component's value
   */
  clean(): any {
    this.$el.find(".phx-list-builder-select").val("");
    if (this.selection) {
      this.selection.reset();
    }
  }
  /**
   * Set the height of the combo list in percentage
   */
  private _setHeight(): any {
    if (this.height) {
      $(this.input).autocomplete("widget").css("max-height", this.height + "%");
    }
  }

  get enum(): Array<{ [key: string]: any }> {
    return this.enumColl;
  }

  set enum(value: Array<{ [key: string]: any }>) {
    this.enumColl = value;
  }

  public formatItem(item: Backbone.Model | any): { [key: string]: any } {
    return this._formatItem(item);
  }

  public getValeurMultiselection(): boolean {
    return this.multiselection;
  }

  public getValueEnum(position?: number): any {
    let lRtn = null;

    if (!CWSTR.isBlank(this.enumColl)) {
      const pos = (!CWSTR.isBlank(position)) ? position : 0;

      lRtn = this.enumColl[pos];
    }
    return lRtn;
  }

  public getValueEnumByCode(code?: string): { [key: string]: any } {
    if (!CWSTR.isBlank(this.enumColl)) {
      for (let i = 0, j = this.enumColl.length; i < j; i++) {
        if (this.enumColl[i].code === code) {
          return this.enumColl[i];
        }
      }
    }
    return {};
  }

  public setValeurMultiselection(etat: boolean): void {
    if (_.isBoolean(etat)) {
      this.multiselection = etat;
    }
  }
}
