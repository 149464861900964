import _ from 'underscore';
import TPLListerecapFilterMore from '../cwFilterMore.tpl.html';
import { CWBaseFormView } from 'src/core/views/cwForm.view';
import { CWComboBoxView2 } from 'src/core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'src/core/components/combo/cwCombos.collection';
import { CWFORMS } from 'utils/cwForms';
import { CWListerecapGeneralContextInterface } from './cwListerecapGeneral.view';
import { CWListerecapWorkflow } from '../models/cwListerecap.workflow';
import { CWListerecpFilterMoreModel } from '../models/cwListerecapFilterMore.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n.js';


export class CWListerecapFilterMoreContentView extends CWBaseFormView<CWListerecpFilterMoreModel> {

  context: CWListerecapGeneralContextInterface;
  workflow: CWListerecapWorkflow;
  comboRecapLibelle: CWComboBoxView2;
  comboEtat: CWComboBoxView2;
  model: CWListerecpFilterMoreModel;
  private motifComboPlaceholder: {
    code: string;
    libelle: any;
  };

  /**
   * Event launched when any editable field of the static form view changes its value
   *
   */
  /**
   * Constructor
   * View for the content of the filter more criterias part
   */
  constructor(options?: { [key: string]: any }) {
    options = options || {};
    options.events = _.extend({
      "change :input:not([readonly])": "_changeModel"
    }, CWTYPE.LONG.events(),
      CWTYPE.DATE.events(),
      CWTYPE.CUSTOM.events(),
      options.events);

    super(options);
    this.template = TPLListerecapFilterMore;
    if (options && options.workflow) {
      this.workflow = options.workflow;
    }
    if (options && options.context) {
      this.context = options.context;
    }
    this._initCombo();
    this.model = new CWListerecpFilterMoreModel();
    this.typeMaskByClass = {};
    this.typeMaskByClass["filtre_datedeb"] = "DATE_L";
    this.typeMaskByClass["filtre_datefin"] = "DATE_L";
  }


  /**
   * Gets the model
   **/
  _getModel(): CWListerecpFilterMoreModel {
    return this.model;
  }

  _changeModel(event: JQueryEventObject, data: string | any, silentValue: boolean): void {
    const className = event.currentTarget.className.split(" ")[0];
    let selectedMotif;

    if (className === "recapitulatif") {
      selectedMotif = this.comboRecapLibelle.getItemId();
      if (CWSTR.isBlank(selectedMotif)) {
        this.comboRecapLibelle.setItem(this.motifComboPlaceholder);
      }
    }
    this._change(event, data, silentValue, true);
    for (const i in this.model.attributes) {
      if (CWSTR.isBlank(this.model.get(i))) {
        this.model.unset(i, { silent: true });
      }
    }
  }


  _initCombo(): void {
    const ctxCombo = this.workflow.getHabContext().copy();
    this.motifComboPlaceholder = {
      code: "aucun",
      libelle: i18n.t('common:placeholder_combo.combo_recap')
    }
    this.comboRecapLibelle = new CWComboBoxView2({
      // ws: new RecapLibelleDataModel({ id: "recapitulatif" }),
      ws: new CWCombosColl({
        comboId: "recapitulatif"
      }),
      name: "recapitulatif",
      habContext: ctxCombo,
      optionsRender: (value: { [key: string]: any }): string => {
        if (value.code === this.motifComboPlaceholder.code || CWSTR.isBlank(value.code)) {
          return this.motifComboPlaceholder.libelle;
        }
        return value.libelle + " (" + value.code + ")";
      }
    });
  }

  /**
   * Renders this view
   */
  render(): this {
    const json = { "i18n": i18n };
    this.$el.html(this.template(json));

    CWFORMS.setDatepicker(this, ".filtre_datedeb");
    CWFORMS.setDatepicker(this, ".filtre_datefin");

    if (this.context.ctxGestionCollective) {
      this.$el.find(".cmbLibelle").html(this.comboRecapLibelle.render().el);
    } else {
      $(this.el).find(".cmbLibelle").addClass('d-none');
    }

    this.comboRecapLibelle.setItem(this.motifComboPlaceholder);
    return this;
  }

  restaureDefaultValues(): void {
    this.model.restaureDefaultValues();
    this._mapModelToForm($(this.el), this._getModel());
    this.comboRecapLibelle.setItem(this.motifComboPlaceholder);
  }
}
