import * as Backbone from 'Backbone';
import _ from 'underscore';
import bdemBriqueMesDemandesQteTPL from '../briqueMesDemandes.tpl.html';
import { CWDIVERS } from 'src/utils/cwDivers';
import { CWEvenementModelColl } from 'src/uc/common/evenements/lister/listerevenements/models/cwListerEvtGen.collection';
import { CWGererAbsenceSouhaitView } from 'common/evenements/gerer/gererabsencesouhait/views/cwGererAbsenceSouhait.view';
import { CWGererActiviteClassiqueView } from 'common/evenements/gerer/gereractivites/views/cwGererActiviteClassique.view';
import { CWGererActiviteDeplacementView } from 'common/evenements/gerer/gereractivites/views/cwGererActiviteDeplacement.view';
import { CWGererEvtGenView } from 'src/uc/common/evenements/gerer/gererevtgen/views/cwGererEvtGen.view';
import { CWGererOneBadgeageView } from 'common/evenements/gerer/gereronebadgeage/views/cwGererOneBadgeage.view';
import { CWGererRecapGeneralView } from 'common/evenements/gerer/gererrecapitulatifs/views/cwGererRecapGeneral.view';
import { CWGererRegularisationView } from 'common/evenements/gerer/gererregularisations/views/cwGererRegularisation.view';
import { CWGroupeGridModel } from 'core/grids/groupegrid/cwGroupeGrid.model';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWLICENCE } from 'utils/cwLicence';
import { CWMesDemandeQte } from '../models/cwMesDemandesQte.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { CWTypoDomCol } from 'common/typodom/models/cwTypoDom.collection';
import { CWVoletView } from 'core/components/dialog/volet/cwVolet.view';
import { CWWkfEvtColl } from '../models/cwWkfEvt.collection';
import { forkJoin } from 'rxjs';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
import { SYNC } from 'utils/sync.js';
import { UTILS } from 'utils/utils.js';


export class CWMesDemandesView extends Backbone.View<CWMesDemandeQte> {

  usePopulation: boolean;
  parent: any;
  habContext: CWHabilitationContext;
  usecase: string;
  UTILISATEUR_TYPE: string;
  volet: CWVoletView;
  wkfEvtColl: CWWkfEvtColl;
  evenementsModel: CWGroupeGridModel;
  collTypo: CWTypoDomCol;
  collTypoDeplac: CWTypoDomCol;
  bWkfBdg: boolean;
  activedMenu: boolean;
  isReadyCollTypoDeplac: boolean;
  isReadyRefresh: boolean;
  bwkfReg: boolean;


  constructor(options?: Backbone.ViewOptions<CWMesDemandeQte> | any) {
    options = options || {};
    options.events = _.extend({
      "click .link_volet": "_ouvrirVolet",
      "click .cw-pave-menu": "_toggleMenu",
      "click .customButton": "_customClickListener"
    }, options.events);
    super(options);
    const lZone = objs.appRt.workflow.get("zone");
    const lHabActivites: any = {};
    const params: any = {};
    const statutFilter = "A,D,H,I,R,T";
    const isGar = CWLICENCE.hasModule("GAR");
    const isGtm = CWLICENCE.hasModule("GTM");

    this.usecase = "bdem";
    this.activedMenu = false;
    //Default values from class
    //this._maxLines = 2;
    this.usePopulation = false;
    this.habContext = new CWHabilitationContext({
      onglet: this.usecase,
      foncCour: "N", // eslint-disable-line
      natGest: ""  // eslint-disable-line
    });
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.template = bdemBriqueMesDemandesQteTPL;
    this.parent = null;
    if (options && options.parent) {
      this.parent = options.parent;
    }
    this.model = new CWMesDemandeQte();
    this.model.usePopulation = this.usePopulation;
    this.model.setHabContext(this.habContext);
    switch (lZone) {
      case "coll":
        this.UTILISATEUR_TYPE = "Collaborateur";
        break;
      case "resp":
        this.UTILISATEUR_TYPE = "Responsable";
        break;
      default:
        this.UTILISATEUR_TYPE = "";
    }
    this.volet = this._creationVolet();
    this.bWkfBdg = false;
    this.bwkfReg = false;
    this.listenTo(this.volet.model, "volet:close", (options?: { [key: string]: any }) => {
      if (this.parent && this.parent.parent && this.parent.parent.model && options && options.refresh === true) {
        const briquesToRefresh = [(this as any).idEmid];

        this.parent.parent.model.trigger("refresh:Brique_" + this.parent.parent.model.get("zone"), briquesToRefresh, options);
      }
    });
    this.wkfEvtColl = new CWWkfEvtColl();
    this.wkfEvtColl.setHabContext(new CWHabilitationContext({ onglet: this.usecase, foncCour: "N" }));
    // AUTRES EVENEMENTS
    this.evenementsModel = new CWGroupeGridModel({ coll: new CWEvenementModelColl() });
    this.evenementsModel.coll.paginated = false;
    this.evenementsModel.coll.setOmittedParams({ matricule: "", statuts: "" });
    this.evenementsModel.coll.setHabContext(new CWHabilitationContext({ onglet: this.usecase, foncCour: "COL_EVTGEN.G" }));
    this.evenementsModel.coll.applyFilter(this._contextToFilter());
    params.statut = statutFilter;
    if (objs.appRt.workflow.get("zone") === "coll") {
      params.matricule = objs.appRt.workflow.authModel.get("matricule");
    }
    this.evenementsModel.coll.applyFilter(params);
    // ACTIVITES
    if (CWHABILITATION.canView("COL_ACT_PREV.G")) {
      lHabActivites.actprev = "COL_ACT_PREV.G";
    }
    if (CWHABILITATION.canView("COL_ACT_REAL.G")) {
      lHabActivites.actreal = "COL_ACT_REAL.G";
    }
    if (!_.isEmpty(lHabActivites)) {
      this.collTypo = new CWTypoDomCol({
        habContext: new CWHabilitationContext({
          onglet: this.usecase,
          foncCour: lHabActivites,
          natGest: ""
        }),
        initFetch: true,
        deplacements: (isGar && isGtm) ? false : null,
      });
    } else {
      this.collTypo = null;
    }
    // DEPLACEMENTS
    if (!_.isEmpty(lHabActivites) && isGar && isGtm) {
      this.collTypoDeplac = new CWTypoDomCol({
        habContext: new CWHabilitationContext({
          onglet: this.usecase,
          foncCour: lHabActivites,
          natGest: "",
        }),
        initFetch: true,
        deplacements: true,
      });
      this.listenTo(this.collTypoDeplac, 'fetch:success', this._listenerFetchCollTypoDeplac);
    } else {
      this.collTypoDeplac = null;
      this.isReadyCollTypoDeplac = true;
    }
  }

  _listenerFetchCollTypoDeplac(): void {
    this.isReadyCollTypoDeplac = true;
    this._renderGrid();
  }

  render(): CWMesDemandesView {
    this.$el.html(this.template);
    return this;
  }

  _renderGrid(): void {
    if (this.isReadyCollTypoDeplac && this.isReadyRefresh) {
      const $lInitGrid = this._initGrid();
      let menuContents: any = null;
      let lPosPaveContent: any = null;
      const minHeightPave = 144; //max. 4 lignes

      this.$el.find(".table_BriqueMesDemandes").empty();
      this.$el.find(".table_BriqueMesDemandes").append($lInitGrid).each(() => {
        menuContents = this.$el.find(".table_BriqueMesDemandes .cw-pMenu__content");
        lPosPaveContent = this.$el.find(".table_BriqueMesDemandes .cw-pave-contenu");
        if (lPosPaveContent.length > 0) {
          let hauteurPave = lPosPaveContent[0].clientHeight;

          if (_.isNaN(hauteurPave) || CWSTR.isBlank(hauteurPave)) {
            hauteurPave = minHeightPave;
          }
          if (hauteurPave < minHeightPave || hauteurPave > minHeightPave) {
            hauteurPave = minHeightPave;
          }
          if (!CWSTR.isBlank(menuContents)) {
            if (menuContents.length > 0) {
              menuContents.each((i: number, menuContent: any) => {
                // Assigner la hauteur maximum            
                $(menuContent).css('max-height', hauteurPave);
                $(menuContent).css('overflow', 'auto');
                // gestion position
                $(menuContent).position({
                  of: $(menuContent).parent().find('.cw-pave__creation'),
                  my: 'right top',
                  at: 'left+17 bottom-16'
                });
              });
            }
          }
        }
      });
    }
  }

  checkHabilitation(): boolean {
    return CWHABILITATION.canView("COL_ABS.V") || CWHABILITATION.canView("COL_REG.V") || CWHABILITATION.canView("COL_BADG.V") || CWHABILITATION.canView("COL_ACT_PREV.V") ||
      CWHABILITATION.canView("COL_ACT_REAL.V") || CWHABILITATION.canView("COL_RECAP.V") || CWHABILITATION.canView("COL_EVTGEN.V") || CWHABILITATION.canView("COL_ACT_REAL.V");
  }

  refreshBrique(options?: { [key: string]: any }): CWMesDemandesView {
    this.isReadyRefresh = false;
    const calls = [];
    const lParams: any = {};
    let LEvtGen = false;

    if (options && !CWSTR.isBlank(options.typeEvenement)) {
      let lObj = {};

      switch (options.typeEvenement) {
        case "Absence":
        case "Souhait":
          lObj = { "ABSENCE": "COL_ABS.V" };
          break;
        case "Regularisation":
          lObj = { "REGULARISATION": "COL_REG.V" };
          break;
        case "Badgeage":
          lObj = { "BADGEAGE": "COL_BADG.V" };
          break;
        case "ActivitePrevue":
          lObj = { "ACTPREV": "COL_ACT_PREV.V" };
          break;
        case "ActiviteRealise":
          lObj = { "ACTREAL": "COL_ACT_REAL.V" };
          break;
        case "Recapitulatif":
          lObj = { "ACTRECAP": "COL_RECAP.V" };
          break;
        case "AutreEvenement":
          lObj = { "EVTGEN": "COL_EVTGEN.V" };
          break;
        default:
        //Nothing
      }
      if (!CWSTR.isBlank(lObj)) {
        lParams.data = JSON.stringify(lObj);
      }
    }
    calls.push(this.wkfEvtColl.fetch());
    calls.push(this.model.fetch(lParams));
    if (CWHABILITATION.canView("COL_EVTGEN.V")) {
      this.evenementsModel.coll.updateHabContext({ foncCour: "COL_EVTGEN.V" });
      calls.push(this.evenementsModel.coll.fetch({}));
      LEvtGen = true;
    }
    forkJoin(calls).subscribe(
      (): void => {//succes
        if (LEvtGen) {
          (this.evenementsModel.coll as any).typo.sort((a: { [key: string]: any }, b: { [key: string]: any }) => {
            const textA = a.libelle.toUpperCase();
            const textB = b.libelle.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
        }
        this.render();
        this.isReadyRefresh = true;
        this._renderGrid();
      },
      (): void => { //error
        //nothing
      }
    );
    this.delegateEvents();
    return this;
  }

  _initGrid(): JQuery {
    let lbWkfAbs = false;
    let lbWkfSou = false;
    let lbWkfEvtGen = false;
    let lbWkfActPre = false;
    let lbWkfActRea = false;
    let lbWkfRecap = false;
    const paves = [];
    let totalPaves = 0; // Montant total des pavÃ©s qui ne sont pas vides.
    const $html = $("<div>");
    let rowNumber = 1;
    let paintRow = true;
    let lbShowAbsSou = false;
    let liNumAbsSou = 0;
    let lbShowAct = false;
    let liNumAct = 0;
    let LCreerMenu = false;
    let $ldivRow = null;
    const isGar = CWLICENCE.hasModule("GAR");
    const isGtm = CWLICENCE.hasModule("GTM");

    // Ordre d'affichage
    if (this.wkfEvtColl) {
      if (this.wkfEvtColl.get("ABSENCE") && this.wkfEvtColl.get("ABSENCE").get("active")) {
        lbWkfAbs = this.wkfEvtColl.get("ABSENCE").get("active");
      }
      if (this.wkfEvtColl.get("SOUHAITABS") && this.wkfEvtColl.get("SOUHAITABS").get("active")) {
        lbWkfSou = this.wkfEvtColl.get("SOUHAITABS").get("active");
      }
      if (this.wkfEvtColl.get("BADGEAGE") && this.wkfEvtColl.get("BADGEAGE").get("active")) {
        this.bWkfBdg = this.wkfEvtColl.get("BADGEAGE").get("active");
      }
      if (this.wkfEvtColl.get("REGULARISATION") && this.wkfEvtColl.get("REGULARISATION").get("active")) {
        this.bwkfReg = this.wkfEvtColl.get("REGULARISATION").get("active");
      }
      if (this.wkfEvtColl.get("ACTPREV") && this.wkfEvtColl.get("ACTPREV").get("active")) {
        lbWkfActPre = this.wkfEvtColl.get("ACTPREV").get("active");
      }
      if (this.wkfEvtColl.get("ACTREAL") && this.wkfEvtColl.get("ACTREAL").get("active")) {
        lbWkfActRea = this.wkfEvtColl.get("ACTREAL").get("active");
      }
      if (this.wkfEvtColl.get("EVTGEN") && this.wkfEvtColl.get("EVTGEN").get("active")) {
        lbWkfEvtGen = this.wkfEvtColl.get("EVTGEN").get("active");
      }
      if (this.wkfEvtColl.get("ACTRECAP") && this.wkfEvtColl.get("ACTRECAP").get("active")) {
        lbWkfRecap = this.wkfEvtColl.get("ACTRECAP").get("active");
      }
    }
    if (CWHABILITATION.canView("COL_ABS.V")) {
      if ((this.model && this.model.get("absences") > 0) || lbWkfAbs) {
        lbShowAbsSou = true;
        liNumAbsSou = this.model.get("absences") || 0;
      }
      if ((this.model && this.model.get("souhaits") > 0) || lbWkfSou) {
        lbShowAbsSou = true;
        liNumAbsSou += (this.model.get("souhaits") || 0);
      }
    }
    if (lbShowAbsSou) {
      LCreerMenu = (CWHABILITATION.canView("COL_ABS.G") && CWDIVERS.get("SouhAbs") === "1");
      paves.push({
        type: "absences",
        title: (liNumAbsSou > 1) ? i18n.t('common:bdem.absences') : i18n.t('common:bdem.absence'),
        textLink: i18n.t('common:bdem.absences'),
        size: liNumAbsSou,
        href: "#z/coll/uc/mabsences/bq",
        habilitationGestion: CWHABILITATION.canCreate("COL_ABS.G") ? "COL_ABS.G" : "",
        menu: (LCreerMenu) ? this._createMenu("absences") : null
      });
    }
    if (CWHABILITATION.canView("COL_BADG.V") && ((this.model && this.model.get("badgeages") > 0) || this.bWkfBdg)) {
      paves.push({
        type: "badgeages",
        title: (this.model.get("badgeages") > 1) ? i18n.t('common:bdem.badgeages') : i18n.t('common:bdem.badgeage'),
        textLink: i18n.t('common:bdem.badgeages'),
        size: this.model.get("badgeages") || 0,
        href: null,
        habilitationGestion: CWHABILITATION.canCreate("COL_BADG.G") ? "COL_BADG.G" : "",
      });
    }
    if (CWHABILITATION.canView("COL_REG.V") && ((this.model && this.model.get("regularisations") > 0) || this.bwkfReg)) {
      paves.push({
        type: "regularisations",
        title: (this.model.get("regularisations") > 1) ? i18n.t('common:bdem.regularisations') : i18n.t('common:bdem.regularisation'),
        textLink: i18n.t('common:bdem.regularisations'),
        size: this.model.get("regularisations") || 0,
        href: "#z/coll/uc/mregul/bq",
        habilitationGestion: CWHABILITATION.canCreate("COL_REG.G") ? "COL_REG.G" : ""
      });
    }
    if (CWHABILITATION.canView("COL_EVTGEN.V") && ((this.model && this.model.get("autresEvenements") > 0) || lbWkfEvtGen)) {
      LCreerMenu = (CWHABILITATION.canView("COL_EVTGEN.G") && (this.evenementsModel.coll as any).typo.length > 1);
      paves.push({
        type: "autresEvenements",
        title: (this.model.get("autresEvenements") > 1) ? i18n.t('common:bdem.autresEvenements') : i18n.t('common:bdem.autreEvenement'),
        textLink: i18n.t('common:bdem.autresEvenements'),
        size: this.model.get("autresEvenements") || 0,
        href: "#z/coll/uc/mevenements",
        habilitationGestion: (CWHABILITATION.canCreate("COL_EVTGEN.G") && (this.evenementsModel.coll as any).typo.length > 0) ? "COL_EVTGEN.G" : "",
        menu: (LCreerMenu) ? this._createMenu("autresEvenements") : null,
        add: ((this.evenementsModel.coll as any).typo.length > 0)
      });
    }
    if (CWHABILITATION.canView("COL_ACT_PREV.V") && ((this.model && this.model.get("activitesPrevues") > 0) || lbWkfActPre)) {
      lbShowAct = true;
      liNumAct = this.model.get("activitesPrevues") || 0;
    }
    if (CWHABILITATION.canView("COL_ACT_REAL.V") && ((this.model && this.model.get("activitesRealisees") > 0) || lbWkfActRea)) {
      lbShowAct = true;
      liNumAct += (this.model.get("activitesRealisees") || 0);
    }
    if (lbShowAct) {
      const lHabActivites: any = {};

      LCreerMenu = ((CWHABILITATION.canView("COL_ACT_PREV.G") || CWHABILITATION.canView("COL_ACT_REAL.G")) && !CWSTR.isBlank(this.collTypo) && this.collTypo.listVal.length > 1);
      if (CWHABILITATION.canView("COL_ACT_PREV.G") && CWHABILITATION.canCreate("COL_ACT_PREV.G")) {
        lHabActivites.actprev = "COL_ACT_PREV.G";
      }
      if (CWHABILITATION.canView("COL_ACT_REAL.G") && CWHABILITATION.canCreate("COL_ACT_REAL.G")) {
        lHabActivites.actreal = "COL_ACT_REAL.G";
      }
      paves.push({
        type: "activites",
        title: (liNumAct > 1) ? i18n.t('common:bdem.activites') : i18n.t('common:bdem.activite'),
        textLink: i18n.t('common:bdem.activites'),
        size: liNumAct,
        href: null,
        habilitationGestion: (!_.isEmpty(lHabActivites)) ? lHabActivites : "",
        menu: (LCreerMenu) ? this._createMenu("activites") : null,
        add: (this.collTypo && this.collTypo.listVal) ? (this.collTypo.listVal.length > 0) : null
      });
    }
    if (CWHABILITATION.canView("COL_RECAP.V") && ((this.model && this.model.get("recapitulatifs") > 0) || lbWkfRecap)) {
      paves.push({
        type: "recapitulatifs",
        title: (this.model.get("recapitulatifs") > 1) ? i18n.t('common:bdem.recapitulatifs') : i18n.t('common:bdem.recapitulatif'),
        textLink: i18n.t('common:bdem.recapitulatifs'),
        size: this.model.get("recapitulatifs") || 0,
        href: "#z/coll/uc/mactivites",
        habilitationGestion: "", //de moment en blanc. Après: CWHABILITATION.canView("COL_RECAP.G") ? "COL_RECAP.G" : "",
        menu: null
      });
    }
    if (CWHABILITATION.canView("COL_ACT_REAL.V") && isGtm && isGar) {
      if (this.collTypoDeplac && this.collTypoDeplac.listVal && this.collTypoDeplac.listVal.length > 0) {
        LCreerMenu = (CWHABILITATION.canView("COL_ACT_REAL.G") && this.collTypoDeplac.listVal.length > 1);
        paves.push({
          type: "deplacements",
          title: (this.model.get("deplacements") > 1) ? i18n.t('common:bdem.deplacements') : i18n.t('common:bdem.deplacement'),
          textLink: i18n.t('common:bdem.deplacements'),
          size: this.model.get("deplacements") || 0,
          href: "#z/coll/uc/mdepl/s/demande",
          habilitationGestion: CWHABILITATION.canCreate("COL_ACT_REAL.G") ? "COL_ACT_REAL.G" : "",
          menu: (LCreerMenu) ? this._createMenu("deplacements") : null,
          add: (this.collTypoDeplac && this.collTypoDeplac.listVal) ? (this.collTypoDeplac.listVal.length > 0) : null
        });
      }
    }
    if (paves.length > 0) {
      for (let i = 0; i < paves.length; i++) {
        let $lDivPav = null;

        if (totalPaves % 2 === 0 && !paintRow && totalPaves > 0) {
          paintRow = true;
          $html.append($ldivRow);
        }
        if (totalPaves % 2 === 0 && paintRow) {
          $ldivRow = $("<div class='row row-" + rowNumber++ + "'>");
          paintRow = false;
        }
        $lDivPav = $("<div class='col-6 cw_pave-" + (i + 1) + "' style='display: table;'>");
        $lDivPav.append(this._createPave(paves[i]));
        $ldivRow.append($lDivPav);
        totalPaves++;
        if (i === paves.length - 1 && !paintRow) {
          $html.append($ldivRow);
        }
      }
      return $html;
    } else {
      const $lDivAffiche = $("<div class='py-2'>").html(i18n.t("common:bdem.noDemandesAffiche"));

      return $lDivAffiche;
    }
  }

  _createPave(pave: any): JQuery {
    const aideIcon = UTILS.getSVGIcon("aide", "", 24);
    let $plus: any = null;
    let $paveCreation: any = null;
    let $linkVolet: any = null;
    let $paveMenu: any = null;
    const $pave = $("<div>").addClass("cw-pave mb-2 text-center");
    const $paveIcon = $("<div>").addClass('cw-pave__icon col-12').addClass("cw-pave__icon__" + pave.type);
    const $paveContenu = $("<div>").addClass('cw-pave-contenu');
    const $paveSize = $("<div>").addClass('cw-texteTresImportant cw-pave-contenu__white cw-pave-valeur');
    const $paveTitle = $("<div>").addClass('cw-texteImportant cw-pave-contenu__white cw-pave-title title');
    const $paveLienDiv = $("<div>").addClass('cw-texteImportant cw-pave-contenu__white cw-pave-link');
    const $paveLien = $("<a>").addClass('cw-pave__lien');

    $pave.addClass("cw-pave__" + pave.type);
    $paveIcon.append(aideIcon);
    $pave.append($paveIcon);
    // Cercle ajouter
    if (!CWSTR.isBlank(pave.habilitationGestion)) {
      if (!CWSTR.isBlank(pave.menu)) {
        $paveMenu = $("<div>").addClass(pave.type + "-menu cw-pave-menu");
        $paveCreation = $("<span>").addClass('cw-pave__creation');
        $plus = $("<span>").addClass("cw-plus__" + pave.type);
        $paveMenu.attr("data-type", pave.type);
        $paveMenu.append(pave.menu);
        $plus.append("+");
        $paveCreation.append($plus);
        $paveMenu.append($paveCreation);
        $pave.append($paveMenu);
      } else if (CWSTR.isBlank(pave.add) || pave.add === true) {
        $linkVolet = $("<div>").addClass('link_volet');
        $paveCreation = $("<span>").addClass('cw-pave__creation');
        $plus = $("<span>").addClass("cw-plus__" + pave.type);
        $linkVolet.attr("data-type", pave.type);
        $plus.append("+");
        $pave.append($linkVolet);
        $linkVolet.append($paveCreation);
        $paveCreation.append($plus);
      }
    }
    $paveSize.append(pave.size);
    $paveTitle.append(pave.title);
    if (CWSTR.isBlank(pave.href)) {
      $paveLienDiv.css('visibility', 'hidden');
    }
    $paveLien.attr("href", pave.href);
    $paveLien.append((i18n.t('common:bdem.voirMes') + " " + pave.textLink).toUpperCase());
    $paveLienDiv.append($paveLien);
    $paveContenu.append($paveSize);
    $paveContenu.append($paveTitle);
    $paveContenu.append($paveLienDiv);
    $pave.append($paveContenu);
    return $pave;
  }

  _creationVolet(): CWVoletView {
    const volet = new CWVoletView();

    volet.setHeight("auto");
    volet.setWidth("auto");
    volet.dialogClass += " cw-pave__volet";
    return volet;
  }

  _ouvrirVolet(event: JQueryEventObject): void {
    if (!CWSTR.isBlank(event) && !CWSTR.isBlank(event.currentTarget)) {
      let lbOpen = true;
      let codeTypo: any = null;
      let typeEvtTypo: any = null;
      let hasDeplacement: any;

      switch ($(event.currentTarget).data("type")) {
        case "absences":
          this.volet.view = CWGererAbsenceSouhaitView;
          this.volet.viewData = _.extend({}, this.volet.viewData, { "context": this._buildContextAbsenceSouhait() });
          break;
        case "badgeages":
          this.volet.view = CWGererOneBadgeageView;
          this.volet.viewData = _.extend({}, this.volet.viewData, { "context": this._buildContextBadge() });
          break;
        case "regularisations":
          this.volet.view = CWGererRegularisationView;
          this.volet.viewData = _.extend({}, this.volet.viewData, { "context": this._buildContextRegul(), "wkfActiv": this.bwkfReg });
          break;
        case "autresEvenements":
          this.volet.view = CWGererEvtGenView;
          this.volet.viewData = _.extend({}, this.volet.viewData, { "context": this._buildContextAutresEvenements((this.evenementsModel.coll as any).typo[0]) });
          break;
        case "activites":
          codeTypo = this.collTypo.listVal[0].attributes.code;
          typeEvtTypo = this.collTypo.listVal[0].attributes.typeevenement;
          hasDeplacement = this.collTypo.listVal[0].attributes.hasDeplacement;
          this.volet.view = CWGererActiviteClassiqueView;
          this.volet.viewData = _.extend({}, this.volet.viewData, { "context": this._buildContextActivites(codeTypo, typeEvtTypo, hasDeplacement) });
          break;
        case "recapitulatifs":
          this.volet.view = CWGererRecapGeneralView;
          break;
        case "deplacements":
          codeTypo = this.collTypoDeplac.listVal[0].attributes.code;
          typeEvtTypo = this.collTypoDeplac.listVal[0].attributes.typeevenement;
          hasDeplacement = this.collTypoDeplac.listVal[0].attributes.hasDeplacement;
          this.volet.view = CWGererActiviteDeplacementView;
          this.volet.viewData = _.extend({}, this.volet.viewData, { "context": this._buildContextDepl(codeTypo, typeEvtTypo, hasDeplacement) });
          break;
        default:
          lbOpen = false;
      }
      if (lbOpen) {
        this.volet.open();
      }
    }
  }

  _buildContextDepl(typoDomData: string, typeEvtTypo: string, hasDeplacement: boolean): { [key: string]: any } {
    const ctxDepl: { [key: string]: any } = {};
    const localDate = SYNC.getServerDate();
    const startDay = CWTYPE.DATE.dateToStr(localDate, "YYYYMMDD");

    typoDomData = typoDomData || null;
    typeEvtTypo = typeEvtTypo || null;
    ctxDepl.ctxUtilisateur = "Collaborateur";
    ctxDepl.ctxUtilisation = "SAIS_ACT_STD";
    ctxDepl.ctxHabilitation = {
      HabilitationAcces: "COL_ACT_REAL.V",
      HabilitationGestion: "COL_ACT_REAL.G",
      HabilitationValidation: ""
    };
    ctxDepl.ctxTypologieActivite = typoDomData;
    ctxDepl.ctxEcran = "home" + objs.appRt.workflow.get("zone");
    ctxDepl.ctxDeplacement = true;
    ctxDepl.ctxValeursCreation = {
      ActDateDebut: startDay,
      ActDateFin: startDay
    };
    ctxDepl.ctxTypeEvtGere = "R";
    ctxDepl.ctxDateConsultee = "";
    ctxDepl.ctxActionsPossibles = ["Creer"];
    ctxDepl.ctxModeInitialisation = "Ajout";
    ctxDepl.ctxModeRepresentation = 'pop-up';
    ctxDepl.ctxIdentifiantEvt = "";
    ctxDepl.ctxValeursEvenement = "";
    ctxDepl.ctxValeursCréation = "";
    ctxDepl.ctxValeursEvenement = {};
    ctxDepl.ctxHistoriqueWKF = true;
    ctxDepl.ctxGestionCollective = false;
    ctxDepl.ctxPieceJointe = true;
    ctxDepl.ctxDomainesActivités = "";
    ctxDepl.ctxSaisieMasse = false;
    ctxDepl.ctxactiviteBesoin = false;
    ctxDepl.ctxCouverturePartielle = false;
    ctxDepl.ctxDeplacement = hasDeplacement;
    ctxDepl.ctxactiviteBesoin = false;
    ctxDepl.ctxCouverturePartielle = false;
    ctxDepl.ctxGestionCollab = {
      matricule: objs.appRt.workflow.authModel.get("matricule")
    }
    return ctxDepl;
  }


  _buildContextAbsenceSouhait(type?: string): { [key: string]: any } {
    let contextGerer: { [key: string]: any } = {};

    contextGerer = {
      ctxUtilisateur: this.UTILISATEUR_TYPE,
      ctxTypeEvenement: (!CWSTR.isBlank(type)) ? type : "Absence",
      ctxHabilitation: {
        HabilitationAcces: "COL_ABS.V",
        HabilitationGestion: "COL_ABS.G",
        HabilitationValidation: ""
      },
      ctxEcran: "home" + objs.appRt.workflow.get("zone"),
      ctxModeRepresentation: "pop-up",
      ctxGestionCollab: {
        matricule: objs.appRt.workflow.authModel.get("matricule")
      },
      ctxGestionCollective: false,
      ctxModeInitialisation: "Ajout"
    };
    contextGerer.ctxHistoriqueWKF = false;
    contextGerer.ctxActionsPossibles = [];//["Creer"];
    contextGerer.ctxIdentifiantEvt = null;
    contextGerer.ctxValeursEvenement = {
      AbsCodeStatutWKF: null,
      AbsCodeMotif: null,
      AbsDateDebut: null,
      AbsUniteDebut: null,
      AbsDateFin: null,
      AbsUniteFin: null,
      AbsHeureDuree: null,
      AbsHeureDebut: null,
      AbsHeureFin: null,
      AbsCommentaire: null
    };
    contextGerer.ctxValeursCreation = {
      AbsCodeMotif: "",
      AbsDateDebut: "",
      AbsUniteDebut: "",
      AbsDateFin: "",
      AbsUniteFin: "",
      AbsHeureDuree: "",
      AbsHeureDebut: "",
      AbsHeureFin: ""
    };
    contextGerer.ctxPieceJointe = (CWDIVERS.get("pJointe") === "1");
    contextGerer.ctxVolet = true;
    return contextGerer;
  }

  habilitationActivites(): string {
    let lsRtn = "";

    if (CWHABILITATION.canView("COL_ACT_PREV.G")) {
      lsRtn = "COL_ACT_PREV.G";
    }
    if (CWHABILITATION.canView("COL_ACT_REAL.G")) {
      lsRtn += (CWSTR.isBlank(lsRtn)) ? "COL_ACT_REAL.G" : ",COL_ACT_REAL.G";
    }
    return lsRtn;
  }

  _createMenu(typeEvenement: string): JQuery {
    const menu = $("<span class='cw-pMenu__content phx-buttonBar-menu-content ui-menu c-panneauMenu'></span>");
    let menuOptions: any = [];

    if (typeEvenement) {
      switch (typeEvenement) {
        case "absences":
          menuOptions = [
            { name: "Absence", label: "<span class='opt-absence'>" + i18n.t('common:bdem.absence') + "</span>" },
            { name: "Souhait", label: "<span class='opt-souhait'>" + i18n.t('common:bdem.souhait') + "</span>" }
          ];
          break;
        case "autresEvenements":
          for (let i = 0; i < (this.evenementsModel.coll as any).typo.length; i++) {
            menuOptions.push({ name: "autresEvenements", label: "<span class='opt-" + (this.evenementsModel.coll as any).typo[i].code + "'>" + (this.evenementsModel.coll as any).typo[i].libelle + "</span>" });
          }
          break;
        case "activites":
          if (!CWSTR.isBlank(this.collTypo)) {
            this.collTypo.sortByLibelle();
            for (let i = 0; i < this.collTypo.listVal.length; i++) {
              menuOptions.push({
                name: "activites", label: "<span class='opt-" + i + "'>" + this.collTypo.listVal[i].attributes.libelle + "</span>"
              });
            }
          }
          break;
        case "deplacements":
          if (!CWSTR.isBlank(this.collTypoDeplac)) {
            this.collTypoDeplac.sortByLibelle();
            for (let i = 0; i < this.collTypoDeplac.listVal.length; i++) {
              menuOptions.push({
                name: "deplacements", label: "<span class='opt-" + i + "'>" + this.collTypoDeplac.listVal[i].attributes.libelle + "</span>"
              });
            }
          }
        //Not Default
      }
    }
    if (menuOptions.length > 0) {
      const $options = $("<ul>");

      menu.addClass(typeEvenement + "-menu-content");
      menu.addClass("phx-buttonBar-menu-content");
      menu.addClass("ui-menu c-panneauMenu");
      for (let i = 0; i < menuOptions.length; i++) {
        $options.append("<li class='" + menuOptions[i].name + " button customButton phx-hover cw-pave__option' value='" + menuOptions[i].name + "' style='white-space:nowrap;'>" + menuOptions[i].label + "</li>");
      }
      menu.append($options);
    }
    return menu;
  }

  _contextToFilter(): any {
    const params: any = {};

    params.matricule = objs.appRt.workflow.authModel.get("matricule");
    params.statut = null;
    return params;
  }

  _toggleMenu(event: JQueryEventObject): void {
    const menuId = (!CWSTR.isBlank(event.currentTarget)) ? $(event.currentTarget).data("type") : null;

    if (!CWSTR.isBlank(menuId)) {
      if ($("." + menuId + "-menu-content", this.el).is(":visible")) {
        $("." + menuId + "-menu-content", this.el).css("display", "none");
      } else {
        //If you click out of the menu, close the menu.
        const mouseDown = (event: JQueryEventObject | any): void => {
          const menuContent = $(this.el).find(".cw-pMenu__content");
          const element = menuContent.find(event.target);

          if (element.length === 0 && (event.target.classList[event.target.classList.length - 1] !== `${menuId}-menu-content`)) {
            $("." + menuId + "-menu-content", this.el).css("display", "none");
          }
          $(document).one("mousedown", mouseDown);
        };
        $(document).one("mousedown", mouseDown);
        $("." + menuId + "-menu-content", this.el).css("display", "inline");
        $("." + menuId + "-menu-content", this.el).position({
          of: $("." + menuId + "-menu-content", this.el).parent().find('.cw-pave__creation'),
          my: 'left top',
          at: 'left+17 bottom-16'
        });
        if (!this.activedMenu) {
          this.activedMenu = true;
        } else {
          this.activedMenu = false;
          $(document).off("wheel." + this.cid);
        }
        $(document).on("wheel." + this.cid, (event: JQueryEventObject): void => {
          const menuContent = $(this.el).find(".cw-pMenu__content");
          const element = menuContent.find(event.target);

          if (element.length === 0) {
            $("." + menuId + "-menu-content", this.el).css("display", "none");
            $(document).off("wheel." + this.cid);
          }
        });
      }
    }
  }

  _customClickListener(event: JQueryEventObject): void {
    if ($(event.currentTarget) && !CWSTR.isBlank($(event.currentTarget).attr("value"))) {
      const optClicked = $(event.currentTarget).attr("value");
      let lbOpen = true;
      let codeAutreEvenement = "";
      let evenementModel: any = null;
      let numLigneTypo = "";
      let codeTypo: any = null;
      let typeEvtTypo: any = null;
      let deplNumLigneTypo = "";
      let deplCodeTypo: any = null;
      let deplTypeEvtTypo: any = null;
      let hasDeplacement = false;
      let hasDeplacementD = false;

      switch (optClicked) {
        case "Absence":
        case "Souhait":
          this.volet.view = CWGererAbsenceSouhaitView;
          this.volet.viewData = _.extend({}, this.volet.viewData, { "context": this._buildContextAbsenceSouhait(optClicked) });
          break;
        case "autresEvenements":
          codeAutreEvenement = ($(event.currentTarget)[0].children[0].attributes as any).class.value.substring(4);
          //evenementModel = (this.evenementsModel.coll as any).typo.find(x => x.code === codeAutreEvenement);
          evenementModel = _.find((this.evenementsModel.coll as any).typo, (item: any): boolean => {
            return item.code === codeAutreEvenement;
          });
          this.volet.view = CWGererEvtGenView;
          this.volet.viewData = _.extend({}, this.volet.viewData, { "context": this._buildContextAutresEvenements(evenementModel) });
          break;
        case "activites":
          numLigneTypo = ($(event.currentTarget)[0].children[0].attributes as any).class.value.substring(4);
          codeTypo = this.collTypo.listVal[numLigneTypo].attributes.code;
          typeEvtTypo = this.collTypo.listVal[numLigneTypo].attributes.typeevenement;
          hasDeplacement = this.collTypo.listVal[numLigneTypo].attributes.hasDeplacement;
          this.volet.view = CWGererActiviteClassiqueView;
          this.volet.viewData = _.extend({}, this.volet.viewData, { "context": this._buildContextActivites(codeTypo, typeEvtTypo, hasDeplacement) });
          break;
        case "deplacements":
          deplNumLigneTypo = ($(event.currentTarget)[0].children[0].attributes as any).class.value.substring(4);
          deplCodeTypo = this.collTypoDeplac.listVal[deplNumLigneTypo].attributes.code;
          deplTypeEvtTypo = this.collTypoDeplac.listVal[deplNumLigneTypo].attributes.typeevenement;
          hasDeplacementD = this.collTypoDeplac.listVal[deplNumLigneTypo].attributes.hasDeplacement;
          this.volet.view = CWGererActiviteDeplacementView;
          this.volet.viewData = _.extend({}, this.volet.viewData, { "context": this._buildContextDepl(deplCodeTypo, deplTypeEvtTypo, hasDeplacementD) });
          break;
        case "recapitulatifs":
          this.volet.view = CWGererRecapGeneralView;
          break;
        default:
          lbOpen = false;
      }
      if (lbOpen) {
        this.volet.open();
      }
    }
  }

  _buildContextRegul(): { [key: string]: any } {
    const ctxRegul: { [key: string]: any } = {};

    ctxRegul.ctxUtilisateur = this.UTILISATEUR_TYPE;
    ctxRegul.ctxTypeEvenement = "Regularisation";
    ctxRegul.ctxHabilitation = {};
    ctxRegul.ctxHabilitation.HabilitationAcces = "";
    ctxRegul.ctxHabilitation.HabilitationGestion = "COL_REG.G";
    ctxRegul.ctxHabilitation.HabilitationValidation = "";
    ctxRegul.ctxActionsPossibles = ['Creer'];//["Creer", "Supprimer", "Modifier"];
    ctxRegul.ctxEcran = "home" + objs.appRt.workflow.get("zone");
    ctxRegul.ctxModeRepresentation = "pop-up";
    ctxRegul.ctxGestionCollab = {};
    ctxRegul.ctxGestionCollab.matricule = objs.appRt.workflow.authModel.get("matricule");
    ctxRegul.ctxGestionCollab.matric_aux = ""; // eslint-disable-line
    ctxRegul.ctxGestionCollab.nom = "";
    ctxRegul.ctxGestionCollab.prenom = "";
    ctxRegul.ctxModeInitialisation = "Ajout";
    ctxRegul.ctxGestionCollective = false;
    ctxRegul.ctxIdentifiantEvt = null;
    ctxRegul.ctxValeursEvenement = {};
    ctxRegul.ctxValeursEvenement.RegCodeStatutWKF = null;
    ctxRegul.ctxValeursEvenement.RegCodeMotif = null;
    ctxRegul.ctxValeursEvenement.RegDateDebut = null;
    ctxRegul.ctxValeursEvenement.RegDateFin = null;
    ctxRegul.ctxValeursEvenement.RegValeur = null;
    ctxRegul.ctxValeursEvenement.RegInfoCompl = null;
    ctxRegul.ctxValeursEvenement.RegCommentaire = null;
    ctxRegul.ctxValeursCreation = {};
    ctxRegul.ctxValeursCreation.RegCodeMotif = null;
    ctxRegul.ctxValeursCreation.RegDateDebut = "";
    ctxRegul.ctxValeursCreation.RegDateFin = "";
    ctxRegul.ctxValeursCreation.RegValeur = null;
    ctxRegul.ctxValeursCreation.RegCommentaire = null;
    ctxRegul.ctxHistoriqueWKF = false;
    ctxRegul.ctxVolet = true;
    return ctxRegul;
  }

  _buildContextBadge(): { [key: string]: any } {
    const ctxBadge: { [key: string]: any } = {};
    const localDate = SYNC.getServerDate();
    const startDay = CWTYPE.DATE.dateToStr(localDate, "YYYYMMDD");
    const startTime = CWTYPE.HOUR_MINUTE.parse(CWTYPE.DATE.dateToStr(localDate, "HH:mm", "", ":")).val;

    ctxBadge.ctxUtilisateur = this.UTILISATEUR_TYPE;
    ctxBadge.ctxTypeEvenement = "Badgeage";
    ctxBadge.ctxHabilitation = {};
    ctxBadge.ctxHabilitation.HabilitationAcces = "COL_BADG.V";
    ctxBadge.ctxHabilitation.HabilitationGestion = "COL_BADG.G";
    ctxBadge.ctxHabilitation.HabilitationValidation = "";
    ctxBadge.ctxActionsPossibles = [];
    ctxBadge.ctxEcran = "home" + objs.appRt.workflow.get("zone");
    ctxBadge.ctxModeRepresentation = "pop-up";
    ctxBadge.ctxGestionPeriode = null;
    ctxBadge.ctxGestionCollab = {};
    ctxBadge.ctxGestionCollab.matricule = objs.appRt.workflow.authModel.get("matricule");
    ctxBadge.ctxGestionCollab.matric_aux = "";  // eslint-disable-line
    ctxBadge.ctxGestionCollab.nom = "";
    ctxBadge.ctxGestionCollab.prenom = "";
    ctxBadge.ctxModeInitialisation = "Ajout";
    ctxBadge.ctxGestionCollective = false;
    ctxBadge.ctxGestionDate = startDay;
    ctxBadge.ctxIdentifiantEvt = null;
    ctxBadge.ctxValeursEvenement = {};
    ctxBadge.ctxValeursEvenement.BadgeageCodeStatutWKF = null;
    ctxBadge.ctxValeursEvenement.BadgeageDate = null;
    ctxBadge.ctxValeursEvenement.BadgeageHeure = null;
    ctxBadge.ctxValeursEvenement.BadgeageSens = null;
    ctxBadge.ctxValeursEvenement.BadgeageAbsence = null;
    ctxBadge.ctxValeursEvenement.BadgeageFonction = null;
    ctxBadge.ctxValeursEvenement.BadgeageCommentaire = null;
    ctxBadge.ctxValeursCreation = {};
    ctxBadge.ctxValeursCreation.BadgeageDate = startDay;
    ctxBadge.ctxValeursCreation.BadgeageHeure = startTime;
    ctxBadge.ctxValeursCreation.BadgeageSens = null;
    ctxBadge.ctxValeursCreation.BadgeageAbsence = null;
    ctxBadge.ctxValeursCreation.BadgeageFonction = null;
    ctxBadge.ctxValeursCreation.BadgeageCommentaire = null;
    ctxBadge.ctxHistoriqueWKF = false;
    ctxBadge.ctxModeSuppression = "Button";
    ctxBadge.ctxVolet = true;
    return ctxBadge;
  }

  _buildContextAutresEvenements(evtGenTypoData?: { [key: string]: any }): { [key: string]: any } {
    evtGenTypoData = evtGenTypoData || { "libelle": null, "saidefo": null, "sairepet": null };
    const ctxAutreEvenement: { [key: string]: any } = {};
    const localDate = SYNC.getServerDate();
    const startDay = CWTYPE.DATE.dateToStr(localDate, "YYYYMMDD");

    ctxAutreEvenement.ctxUtilisateur = this.UTILISATEUR_TYPE;
    ctxAutreEvenement.ctxVueJourneeDate = startDay;
    ctxAutreEvenement.ctxTypologieEvenement = evtGenTypoData;
    if (ctxAutreEvenement.ctxUtilisateur === "Responsable") {
      ctxAutreEvenement.ctxHabilitation = {
        HabilitationAcces: "RES_EVTGEN.V",
        HabilitationGestion: "RES_EVTGEN.G",
        HabilitationValidation: "RES_VAL_EGEN.G",

      };
    } else {
      ctxAutreEvenement.ctxHabilitation = {
        HabilitationAcces: "COL_EVTGEN.V",
        HabilitationGestion: "COL_EVTGEN.G",
        HabilitationValidation: ""
      };
    }
    ctxAutreEvenement.ctxEvtGenIntitule = evtGenTypoData.libelle;
    ctxAutreEvenement.ctxEcran = "home" + objs.appRt.workflow.get("zone");
    ctxAutreEvenement.ctxModeRepresentation = "pop-up";
    ctxAutreEvenement.ctxGestionCollab = {};
    ctxAutreEvenement.ctxGestionCollab.matricule = objs.appRt.workflow.authModel.get("matricule");
    ctxAutreEvenement.ctxGestionCollab.matric_aux = ""; // eslint-disable-line
    ctxAutreEvenement.ctxGestionCollab.nom = "";
    ctxAutreEvenement.ctxGestionCollab.prenom = "";
    //Customer 196582
    ctxAutreEvenement.ctxEvtGenModeSaiDef = evtGenTypoData.saidefo;
    ctxAutreEvenement.ctxEvtGenSaiRepJ = evtGenTypoData.sairepet;

    ctxAutreEvenement.ctxDateConsultee = "";
    ctxAutreEvenement.ctxActionsPossibles = [];
    ctxAutreEvenement.ctxModeInitialisation = "Ajout";
    ctxAutreEvenement.ctxIdentifiantEvt = null;
    ctxAutreEvenement.ctxValeursEvenement = {};
    ctxAutreEvenement.ctxValeursCreation = {
      EvtGenDatedebut: localDate,
      EvtGenDatefin: localDate,
      //Customer 196582
      EvtGenCode: evtGenTypoData.code,
      EvtGenModeSaiDef: evtGenTypoData.saidefo
    };
    ctxAutreEvenement.ctxHistoriqueWKF = true;
    ctxAutreEvenement.ctxGestionCollective = false;

    ctxAutreEvenement.ctxPieceJointe = (CWDIVERS.get("pJointe") === "1");
    return ctxAutreEvenement;
  }

  _buildContextActivites(typoDomData: string, typeEvtTypo: string, hasDeplacement: boolean): { [key: string]: any } {
    const ctxActivite: { [key: string]: any } = {};
    const localDate = SYNC.getServerDate();
    const startDay = CWTYPE.DATE.dateToStr(localDate, "YYYYMMDD");

    typoDomData = typoDomData || null;
    typeEvtTypo = typeEvtTypo || null;
    ctxActivite.ctxUtilisateur = this.UTILISATEUR_TYPE;
    ctxActivite.ctxUtilisation = "SAIS_ACT_STD";
    ctxActivite.ctxEcran = "home" + objs.appRt.workflow.get("zone");
    ctxActivite.ctxModeRepresentation = "pop-up";
    ctxActivite.ctxHabilitation = {
      HabilitationAcces: {},
      HabilitationGestion: {},
      HabilitationValidation: "",
      HabilitationGestionRecap: ""
    };
    if (CWHABILITATION.canView("COL_ACT_PREV.V")) {
      ctxActivite.ctxHabilitation.HabilitationAcces["P"] = "COL_ACT_PREV.V";
    }
    if (CWHABILITATION.canView("COL_ACT_REAL.V")) {
      ctxActivite.ctxHabilitation.HabilitationAcces["R"] = "COL_ACT_REAL.V";
    }
    if (CWHABILITATION.canCreate("COL_ACT_PREV.G")) {
      ctxActivite.ctxHabilitation.HabilitationGestion["P"] = "COL_ACT_PREV.G";
    }
    if (CWHABILITATION.canCreate("COL_ACT_REAL.G")) {
      ctxActivite.ctxHabilitation.HabilitationGestion["R"] = "COL_ACT_REAL.G";
    }
    ctxActivite.ctxTypologieActivite = typoDomData;
    ctxActivite.ctxTypeEvtGere = typeEvtTypo;
    ctxActivite.ctxDomainesActivites = "";
    ctxActivite.ctxGestionCollab = {};
    ctxActivite.ctxGestionCollab.matricule = objs.appRt.workflow.authModel.get("matricule");
    ctxActivite.ctxGestionCollab.matric_aux = ""; // eslint-disable-line
    ctxActivite.ctxGestionCollab.nom = "";
    ctxActivite.ctxGestionCollab.prenom = "";
    ctxActivite.ctxGestionData = startDay;
    ctxActivite.ctxDateConsultee = "";
    ctxActivite.ctxActionsPossibles = [];
    ctxActivite.ctxModeInitialisation = "Ajout";
    ctxActivite.ctxIdentifiantEvt = null;
    ctxActivite.ctxValeursEvenement = {};
    ctxActivite.ctxValeursCreation = {
      ActDateDebut: startDay,
      ActDateFin: startDay
    };
    ctxActivite.ctxPlanActivites = "";
    ctxActivite.ctxHistoriqueWKF = true;
    ctxActivite.ctxGestionCollective = false;
    ctxActivite.ctxPieceJointe = (CWDIVERS.get("pJointe") === "1");
    ctxActivite.ctxSaisieMasse = false;
    ctxActivite.ctxDeplacement = hasDeplacement;
    ctxActivite.ctxactiviteBesoin = false;
    ctxActivite.ctxPourPlanningMedical = false;
    return ctxActivite;
  }
}
