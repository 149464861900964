import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLAppMenu from '../menu.tpl.html';
import TPLAppMenubar from '../menubar.tpl.html';
import TPLAppMenuItemNiv1 from '../ctimeMenuItemNiv1Haut.tpl.html';
import TPLItemAgendaCrit from '../item_agenda_crit.tpl.html';
import { CWDialogPopupView } from 'src/core/components/dialog/popup/cwDialogPopup.view';
import { CWGeneralView } from 'common/lanceetat/views/cwGeneral.view';
import { CWGeneralView as SuvietatGeneralView } from 'common/suvietat/views/cwGeneral.view';
import { CWGeneralView as CalendaccGeneralView } from 'common/calendacc/views/cwGeneral.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWMSGS } from 'utils/cwMsgs';
import { CWPreferenceGeneralExiste } from 'common/briques/persobriques/models/cwPreferenceGeneralExiste.model';
import { CWPreferenceUtilisateurExiste } from 'common/briques/persobriques/models/cwPreferenceUtilisateurExiste.model';
import { CWSTR } from 'utils/cwStr';
import { CWWorkFlowModel } from '../models/cwWorkFlow.model';
import { CWZoneMenuView } from './cwZoneMenu.view';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { objs } from 'src/objectsRepository';
import { UTILS } from 'utils/utils.js';

/**
 * Main MenuBar View
 *
 *  contains all the different menus
 *
 */
export class CWMenuBarView<TModel extends CWWorkFlowModel = CWWorkFlowModel> extends Backbone.View<TModel>{

  zJSON: any;
  template: any;
  templateMenu: any;
  templateItemNiv1: any;
  templateItemAgendaCrit: any;
  zViews: any;
  components: any;
  calendaccDialog: any;
  transversalViewDialog: any;
  autoSaveMenu: boolean;

  constructor(params: Backbone.ViewOptions<Backbone.Model> | any) {
    params.events = _.extend({
      "click .phx-menus-transversal-print-menu a": "_clickOption",
      "click .phx-savecriteria-menu li": "_clickPersonnaliserMenu",
      "click #phx-menus-transversal-right .phx-menus-transversal-print": "_clickMenu",
      "click #phx-menus-transversal-right .phx-menus-transversal-refresh": "_clickRefresh",
      "click #phx-menus-transversal-right .phx-menus-transversal-personnaliser": "_clickPersonnaliser",
      "click #phx-menus-transversal-right .phx-menus-transversal-calend": "_clickCalend",
      "click #phx-menus-transversal-right .phx-menus-transversal-help": "_clickHelp",
      "mouseleave .phx-savecriteria-menu": "_hideSavecriteriaMenu",
      "click li.itemMenuPrint": (event: JQuery.TriggeredEvent): void => {
        if ($(event.target).parents("div.phx-menus-transversal-print-menu").length !== 0) {
          $(event.target).find("a").trigger("click");
        }
      }
    }, params.events);
    super(params);
    this.template = TPLAppMenubar;
    this.templateMenu = TPLAppMenu;
    this.templateItemNiv1 = TPLAppMenuItemNiv1;
    this.templateItemAgendaCrit = TPLItemAgendaCrit;
    this.listenTo(GLOBAL_DATA.rights, "sync", this._reloadIcons);
    this.zViews = {};
    this.zJSON = params.zJSON;
    this.model.on("change:zone", this._zonesSwapping, this);
    this.components = null;
  }

  _zonesSwapping(): void {
    const oldZone = this.model.previous("zone");
    const newZone = this.model.get("zone");

    if (oldZone !== "" && !CWSTR.isBlank(oldZone)) {
      this.zViews[oldZone].$el.hide();
      this.zViews[oldZone].$el.attr("aria-hidden", "true");
    }
    if (newZone !== "" && !CWSTR.isBlank(newZone)) {
      this.zViews[newZone].$el.show();
      this.zViews[newZone].$el.attr("aria-hidden", "false");
    }
    // resize header
    (Backbone as any).trigger("resize:header");
  }

  _renderMenuCalend(): void {
    const calendarButton = this.$el.find("#phx-menus-transversal-right .phx-menus-transversal-calend");
    const uc = _.find(this.model.configuration.get("confignav").get("transverses"), { id: "calendacc" });

    if (uc) {
      const divMenu = $("<div>").addClass("phx-menus-transversal-calend-menu ui-menu ui-corner-all");

      divMenu.css({ "position": "fixed", "z-index": "200", "padding": "5px", "right": "10px" });
      this.$el.find("#phx-menus-transversal-right").append(divMenu);
      $(divMenu).hide();
    } else {
      calendarButton.hide();
    }
  }

  _renderMenuPrint(): void {
    const ucOpen = objs.appRt.workflow.get("usecase");
    const zone = !CWSTR.isBlank(ucOpen) ? objs.appRt.workflow._searchZoneFromUc(ucOpen) : !CWSTR.isBlank(objs.appRt.workflow.get("zone")) ? objs.appRt.workflow.get("zone") : "coll";
    const printButton = this.$el.find("#phx-menus-transversal-right .phx-menus-transversal-print");
    let uc: { [key: string]: any } = {};

    uc = _.find(this.model.configuration.get("confignav").get("transverses"), { id: "etats" });
    if (uc) {
      const divMenu = $("<div>").addClass("phx-menus-transversal-print-menu ui-menu ui-corner-all c-panneauMenu");

      divMenu.css({ "position": "absolute", "z-index": "200", "padding": "5px", "margin-left": "-25px" });
      printButton.append(divMenu);

      _.each(uc.ecrans, (ecran: { [key: string]: any }) => {
        let itemNiv1 = null;

        ecran.href = "#z/" + zone + "/uc/" + ecran.id;
        ecran.target = "";
        itemNiv1 = this.templateItemNiv1(ecran);
        $(divMenu).append(itemNiv1);
        $(divMenu).find("li.ui-menu-item").last().keydown((e: JQueryEventObject): void => {
          this._transversalPrintKeyboardNav(e);
        }, null);
      }, this);
      $(divMenu).find("li").addClass("itemMenuPrint");
      $(divMenu).hide();
    } else {
      printButton.hide();
    }
  }

  _renderMenuAgendaCrit(): void {
    const personnaliserButton = this.$el.find("#phx-menus-transversal-right .phx-menus-transversal-personnaliser");
    const divMenu = $("<div>").addClass("phx-savecriteria-menu c-panneauMenu ui-menu");
    const json = { i18n: i18n };

    divMenu.css({ "position": "fixed", "z-index": "200", "padding": "5px" });
    personnaliserButton.append(divMenu);
    $(divMenu).append(this.templateItemAgendaCrit(json));
    $(divMenu).hide();
  }

  _hideSavecriteriaMenu(): void {
    $(".phx-savecriteria-menu").hide();
  }

  _reloadIconPrint(): void {
    const printButton = this.$el.find("#phx-menus-transversal-right .phx-menus-transversal-print");

    if (printButton) {
      if (CWHABILITATION.canView("ETATS.V,ETATSCOL.V")) {
        printButton.removeClass("ui-state-disabled");
      } else {
        printButton.addClass("ui-state-disabled");
      }
    }
  }

  _reloadIcons(): void {
    this.model.configuration.get("confignav").get("transverses").forEach((trans: { [key: string]: any }) => {
      if (GLOBAL_DATA.rights.menuVisibility.get(trans.emid)) {
        this.$el.find("." + trans.emid).removeClass("ui-state-disabled");
      } else {
        this.$el.find("." + trans.emid).addClass("ui-state-disabled");
      }
    }, this);
    this._renderMenuHelp();
    this._reloadIconPrint();
  }

  _renderMenuHelp(): void {
    const helputton = this.$el.find("#phx-menus-transversal-right .phx-menus-transversal-help");
    const lUcNav: { [key: string]: any } = _.find(this.model.configuration.get("confignav").get("transverses"), { id: "aide" });

    if (!CWSTR.isBlank(lUcNav)) {
      const uc = lUcNav.emid;

      if (uc) {
        helputton.addClass(uc.emid);
        this.$el.find(".phx-menus-transversal-help").show();
        if (CWHABILITATION.canView("AIDE.V")) {
          helputton.show();
        } else {
          helputton.hide();
        }
      } else {
        helputton.hide();
      }
    }
  }

  _clickMenu(event: any): boolean { // menu lancement des états
    const uc = objs.appRt.workflow.get("usecase");
    const hidderOn = this._checkHidder(uc);
    const menu = this.$el.find(".phx-menus-transversal-print-menu");

    if (hidderOn || $(event.currentTarget).hasClass("ui-state-disabled")) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    if ($(menu).is(":visible")) {
      $(menu).hide();
    } else {
      if (!CWSTR.isBlank(event.originalEvent)) {
        menu.children().removeClass("hovered");
      }
      $(menu).show();
      //cacher les autres menus
      this._hideMenuUtilisateur();
      //If you click out of the menu, close the menu.
      $(document).one("mousedown", (event: any) => {
        const element = $(this.el).find(event.target);

        if (element.length === 0) {
          $(menu).hide();
        }
      });
    }
    return true;
  }

  _clickRefresh(): void {
    const buttonsClass: any = { no: 'btn-secondary' }
    const usecase = this.model.get("usecase") || null;
    const hidderOn = this._checkHidder(usecase) || this._checkAffMultiplePlanning(usecase);//CheckAffMultiplePlanning seulement pour l'action de rafraîchir

    this._hideAllMenus();
    if (!hidderOn) {
      CWMSGS.showConfirmAdapter(i18n.t('messages:GT_1301'), (result: string) => {
        if (result === "Y") {

          if (CWSTR.isBlank(usecase)) {
            this.model.trigger("reload:brique");
          } else {
            this.model.trigger("predestroy:usecase", usecase, true);
          }
        }
      }, true, null, true, null, buttonsClass);
    }
  }

  _checkHidder(usecase: string): boolean {
    const hidderCounter = UTILS.usecase_loading_counter[usecase as any];
    let rtn = false;

    if (hidderCounter && hidderCounter > 0) {
      rtn = true;
    }
    return rtn;
  }

  _clickPersonnaliser(event: JQueryEventObject): void {
    let planRt: any = null;
    let options: any = {};
    let habContext: any;
    const uc = objs.appRt.workflow.get("usecase");
    const hidderOn = this._checkHidder(uc);

    this._hideAllMenus();
    /** stops action while screen is loading */
    if (!hidderOn && event && !_.isString(event.target.className)) {
      const zone = !CWSTR.isBlank(uc) ? objs.appRt.workflow._searchZoneFromUc(uc) : objs.appRt.workflow.get("zone");
      const lZoneNav: { [key: string]: any } = _.find(this.model.configuration.get("confignav").get("zones"), { id: zone });

      if (!CWSTR.isBlank(lZoneNav)) {
        const zoneid = lZoneNav.emid;

        //on enleve le tooltip de personnaliser pour afficher correctement les menus
        _.each($("#" + $(document).find("[aria-describedby]").attr("aria-describedby")), (element: HTMLElement): void => {
          const lPos = $(element);

          if (lPos && lPos.length > 0) {
            if (lPos.attr("role") === "tooltip") {
              lPos.remove();
            }
          }
        });
        if (!CWSTR.isBlank(uc)) {
          this.autoSaveMenu = false;
          switch (uc) {
            case "agenda":
            case "agenda_R": {
              const agendaRt = objs.agendaRt ? objs.agendaRt : null;
              const agendaRRt = objs.agenda_RRt ? objs.agenda_RRt : null;
              const agendaTmp = (uc === 'agenda') ? agendaRt : agendaRRt;

              if (agendaTmp && agendaTmp.workflow && agendaTmp.workflow.preferences) {
                if (agendaTmp.workflow.preferences.get("persicriu")) {
                  this._showMenu(event);
                } else {
                  this.model.trigger("click:personnaliser" + zone, zoneid, uc, 'save');
                }
              }
              break;
            }
            case "planresp":
            case "planact":
            case "planmed":
            case "tabservind":
            case "tabservcoll":
            case "tabgardes":
            case "plequipe": {
              let canModify = "N";

              if (uc === "planmed") {
                planRt = objs.planmedRt ? objs.planmedRt : null;
                options = {
                  habilitationV: "RES_PLMED",
                  habilitationG: "RES_PLMED",
                  canViewTreatment: true,
                  usecase: "planmed"
                };
                habContext = new CWHabilitationContext({
                  onglet: "planmed",
                  foncCour: "RES_PLMED",
                  natGest: ""
                });
              } else if (_.isEqual(uc, "tabservind")) {
                planRt = objs.tabservindRt ? objs.tabservindRt : null;
                options = {
                  canViewTreatment: true,
                  usecase: "tabservind"
                };
                habContext = new CWHabilitationContext({
                  onglet: "tabservind",
                  foncCour: "N",
                  natGest: ""
                });
              } else if (_.isEqual(uc, "tabservcoll")) {
                planRt = objs.tabservcollRt ? objs.tabservcollRt : null;
                options = {
                  canViewTreatment: true,
                  usecase: "tabservcoll"
                };
                habContext = new CWHabilitationContext({
                  onglet: "tabservcoll",
                  foncCour: "N",
                  natGest: ""
                });
              } else if (_.isEqual(uc, "tabgardes")) {
                planRt = objs.tabgardesRt ? objs.tabgardesRt : null;
                options = {
                  canViewTreatment: true,
                  usecase: "tabgardes"
                };
                habContext = new CWHabilitationContext({
                  onglet: "tabgardes",
                  foncCour: "N",
                  natGest: ""
                });
              } else if (_.isEqual(uc, "plequipe")) {
                planRt = objs.plequipeRt ? objs.plequipeRt : null;
                options = {
                  canViewTreatment: true,
                  usecase: "plequipe"
                };
                habContext = new CWHabilitationContext({
                  onglet: "plequipe",
                  foncCour: "N",
                  natGest: ""
                });
              } else {
                planRt = objs.planrespRt ? objs.planrespRt : null;
                options = {
                  habilitationV: "RES_GCOLLAB.G",
                  habilitationG: "RES_GCOLLAB.G",
                  canViewTreatment: true,
                  usecase: "planresp"
                };
                habContext = new CWHabilitationContext({
                  onglet: "planresp",
                  foncCour: "RES_GCOLLAB.G",
                  natGest: ""
                });
              }
              if (planRt && planRt.workflow) {
                if (CWHABILITATION.canView("PER_PERSOGEN.M")) {
                  canModify = "O"
                }
                if (planRt.workflow.prefGeneralExiste && planRt.workflow.prefGeneralExiste.notFecth !== true) {
                  planRt.workflow.prefGeneralExiste = new CWPreferenceGeneralExiste(options);
                  planRt.workflow.prefGeneralExiste.setHabContext(habContext);
                }
                if (planRt.workflow.prefUtilisateurExiste && planRt.workflow.prefUtilisateurExiste.notFecth !== true) {
                  planRt.workflow.prefUtilisateurExiste = new CWPreferenceUtilisateurExiste(options);
                  planRt.workflow.prefUtilisateurExiste.setHabContext(habContext);
                  planRt.workflow.prefUtilisateurExiste.fetch({
                    success: (fresh: any) => {
                      planRt.workflow.prefUtilisateurExiste.notFecth = true;
                      planRt.workflow.persUtil = fresh.get("preference");
                      if (planRt.workflow.persUtil === 0 || planRt.workflow.persUtil === true) {
                        if (planRt.workflow.prefGeneralExiste.notFecth !== true) {
                          planRt.workflow.prefGeneralExiste.fetch({
                            success: () => {
                              planRt.workflow.prefGeneralExiste.notFecth = true;
                              this._checkOptions(event, canModify, planRt);
                            }
                          });
                        } else {
                          this._checkOptions(event, canModify, planRt);
                        }
                      } else {
                        this._checkOptions(event, canModify, planRt);
                      }
                    }
                  });
                } else {
                  if (planRt.workflow.persUtil === 0 || planRt.workflow.persUtil === true) {
                    if (planRt.workflow.prefGeneralExiste.notFecth !== true) {
                      planRt.workflow.prefGeneralExiste.fetch({
                        success: () => {
                          planRt.workflow.prefGeneralExiste.notFecth = true;
                          this._checkOptions(event, canModify, planRt);
                        }
                      });
                    } else {
                      this._checkOptions(event, canModify, planRt);
                    }
                  } else {
                    this._checkOptions(event, canModify, planRt);
                  }
                }
              }
              break;
            }
            //Not default
          }
        } else {
          this.model.trigger("click:personnaliser" + zone, zoneid, uc);
        }
      }
    }
  }

  _checkOptions(event: JQueryEventObject, canModify: string, planRt: any): void {
    const uc = objs.appRt.workflow.get("usecase");
    const zone = !CWSTR.isBlank(uc) ? objs.appRt.workflow._searchZoneFromUc(uc) : objs.appRt.workflow.get("zone");
    const lZoneNav: { [key: string]: any } = _.find(this.model.configuration.get("confignav").get("zones"), { id: zone });

    if (!CWSTR.isBlank(lZoneNav)) {
      const zoneid = lZoneNav.emid;

      if (canModify !== "O") {
        if (planRt.workflow.persUtil === false) {
          this.model.trigger("click:personnaliser" + zone, zoneid, uc, 'autosave');
        } else {
          this.autoSaveMenu = true;
          this._showMenu(event);
        }
      } else {
        if (CWSTR.isBlank(planRt.workflow.persUtil) || planRt.workflow.persUtil === false) {
          this.model.trigger("click:personnaliser" + zone, zoneid, uc, 'save');
        } else {
          this._showMenu(event);
        }
      }
    }
  }

  _clickPersonnaliserMenu(event: JQueryEventObject): void {
    const uc = objs.appRt.workflow.get("usecase");
    const zone = !CWSTR.isBlank(uc) ? objs.appRt.workflow._searchZoneFromUc(uc) : objs.appRt.workflow.get("zone");
    const menu = this.$el.find(".phx-savecriteria-menu");
    const name = (event.target && _.isString(event.target.className)) ? event.target.className.split(" ")[0] : null;
    const lZoneNav: { [key: string]: any } = _.find(this.model.configuration.get("confignav").get("zones"), { id: zone });

    if (!CWSTR.isBlank(lZoneNav) && !CWSTR.isBlank(name)) {
      const zoneid = lZoneNav.emid;

      if (this.autoSaveMenu && name === 'percrit_save') {
        if ($(menu).is(":visible")) {
          $(menu).hide();
        } else {
          $(menu).show();
        }
        this.model.trigger("click:personnaliser" + zone, zoneid, uc, 'autosave');
      } else {
        this.model.trigger("click:personnaliser" + zone, zoneid, uc, (name === 'percrit_save') ? 'save' : 'delete');
      }
    }
  }

  _showMenu(event: any): boolean { // menu lancement des états
    const menu = this.$el.find(".phx-savecriteria-menu");

    if ($(event.currentTarget).hasClass("ui-state-disabled")) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    if ($(menu).is(":visible")) {
      $(menu).hide();
    } else {
      $(menu).show();
      //cacher les autres menus
      this._hideMenuPrint();
      //If you click out of the menu, close the menu.
      $(document).one("mousedown", (event: any) => {
        const element = $(this.el).find(event.target);

        if (element.length === 0) {
          $(menu).hide();
        }
      });
    }
    return true;
  }

  _clickCalend(event: any): boolean {
    const uc = objs.appRt.workflow.get("usecase");
    const hidderOn = this._checkHidder(uc);

    if (hidderOn || $(event.target).hasClass("ui-state-disabled")) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    // load dependencies to component
    if (CWSTR.isBlank(this.calendaccDialog)) {
      this.calendaccDialog = new CWDialogPopupView({
        dialogClass: "c-cwDialogPopup cw-style cwHeader__transversal calendacc-general",
        view: CalendaccGeneralView,
        resizable: true
      });
    }
    this._hideAllMenus();
    this.calendaccDialog.open();
    return true;
  }

  _clickHelp(event: any): boolean {
    let lang = UTILS.getLanguage();
    const uc = objs.appRt.workflow.get("usecase");
    const zone = !CWSTR.isBlank(uc) ? objs.appRt.workflow._searchZoneFromUc(uc) : objs.appRt.workflow.get("zone");
    let ucPath = !CWSTR.isBlank(uc) ? zone + "_" + uc : zone;
    const navBar = $((!CWSTR.isBlank(uc) ? "#" + uc + " .phx-navigation-bar" : ".phx-navigation-bar")).find(".menuText").first().text().split(/[->]+/);
    let globalPath = null;
    let tab = null;

    if ($(event.target).hasClass("ui-state-disabled")) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    for (let i = 0; i < navBar.length; i++) {
      navBar[i] = navBar[i].trim();
    }
    if (!CWSTR.isBlank(navBar[1]) && Configuration.userlocale === "fr") {
      // add subsections for screen Suivicollab ( we get value from pos in navBar )
      ucPath = this._checkNavBarOption(navBar, ucPath, uc);
    }

    // Until they are implemented, idioms different to english and french will be french
    if (lang !== "fr" && lang !== "en" && lang !== "es") {
      lang = "fr";
    }
    globalPath = !CWSTR.isBlank(ucPath) ? "help/aide.html?lang=" + lang + "#" + ucPath : "help/aide.html?lang=" + lang;
    tab = window.open(
      globalPath,
      "Aide", 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600'
    );
    if (tab) {
      tab.focus();
    }
    return true;
  }

  _checkNavBarOption(navBar: any, aUcPath: string, uc: string): string {
    let lUcPath = aUcPath;

    if (uc === "fichecollab") {
      lUcPath = this._helpFicheCollab(navBar.pop(), aUcPath);
    }
    if (uc === "suivicollab") {
      lUcPath = this._helpSuiviCollab(navBar.pop());
    }
    return lUcPath;
  }

  _helpSuiviCollab(aUcPath: string): string {
    let lText = aUcPath;

    if (i18n.t('suivicollab.menuSituation') === aUcPath ||
      i18n.t('suivicollab.menuEtatCivil') === aUcPath ||
      i18n.t('suivicollab.menuContact') === aUcPath ||
      i18n.t('suivicollab.menuHoraireRef') === aUcPath ||
      i18n.t('suivicollab.menuContrat') === aUcPath ||
      i18n.t('suivicollab.menuChangements') === aUcPath ||
      i18n.t('suivicollab.menuCaracteristiques') === aUcPath ||
      i18n.t('suivicollab.menuPeriodePresence') === aUcPath ||
      i18n.t('suivicollab.menuRattachements') === aUcPath ||
      i18n.t('suivicollab.menuBadgeage') === aUcPath ||
      i18n.t('suivicollab.menuActivites') === aUcPath ||
      i18n.t('suivicollab.menuClassification') === aUcPath ||
      i18n.t('suivicollab.menuInfComp') === aUcPath) {
      lText = "_" + aUcPath.replace(/\s/g, "_") + "_1";
    } else if (i18n.t('suivicollab.menuProfilsMetier') === aUcPath ||
      i18n.t('suivicollab.menuCompetences') === aUcPath ||
      i18n.t('suivicollab.menuInaptitudes') === aUcPath ||
      i18n.t('suivicollab.menuRecapitulatifs') === aUcPath ||
      i18n.t('suivicollab.menuVueJournee') === aUcPath ||
      i18n.t('suivicollab.menuResindiv') === aUcPath ||
      i18n.t('suivicollab.menuSoldes') === aUcPath ||
      i18n.t('suivicollab.menuDroits') === aUcPath ||
      i18n.t('suivicollab.menuPretsDisponibilites') === aUcPath ||
      i18n.t('suivicollab.menuEmpTemps') === aUcPath) {
      lText = "_" + aUcPath.replace(/\s/g, "_");
    } else if (i18n.t('suivicollab.menuRecapActivite') === aUcPath ||
      i18n.t('suivicollab.menuRegularisations') === aUcPath) {
      lText = "_" + aUcPath.replace(/\s/g, "_") + "_2";
    } else if (i18n.t('suivicollab.menuAnomalies') === aUcPath) {
      lText = "_" + aUcPath.replace(/\s/g, "_") + "_3";
    } else if (i18n.t('suivicollab.menuAbsences') === aUcPath) {
      //"menuAbsences": "Absences" sans Souhait
      lText = i18n.t('suivicollab.menuAbsences') + "_4";
    } else if (i18n.t('suivicollab.menuCircuitsValidation') === aUcPath) {
      lText = "_Toc386117931";
    } else if (i18n.t('suivicollab.menuStructures') === aUcPath ||
      i18n.t('suivicollab.menuOrgAdmin') === aUcPath ||
      i18n.t('suivicollab.menuSiteGeograph') === aUcPath ||
      i18n.t('suivicollab.menuZoneScolaire') === aUcPath) {
      lText = "_Rattachements_1";
    } else if (i18n.t('suivicollab.menuDelegations') === aUcPath) {
      lText = "aide_179"; //meilleur que utiliser "_Delegations"
    } else if (i18n.t('suivicollab.menuAbsencesSohuaits') === aUcPath) {
      lText = aUcPath.replace(/\s/g, "_") + "_1";
    } else {
      lText = "_Fiche_collaborateur_2";
    }
    return lText;
  }

  _helpFicheCollab(aUcPath: string, aPathDefault: string): string {
    let lText = aUcPath;

    if (i18n.t('fichecollab.menuSituation') === aUcPath ||
      i18n.t('fichecollab.menuEtatCivil') === aUcPath ||
      i18n.t('fichecollab.menuContact') === aUcPath ||
      i18n.t('fichecollab.menuHoraireRef') === aUcPath ||
      i18n.t('fichecollab.menuChangements') === aUcPath ||
      i18n.t('fichecollab.menuCaracteristiques') === aUcPath ||
      i18n.t('fichecollab.menuPeriodePresence') === aUcPath ||
      i18n.t('fichecollab.menuBadgeage') === aUcPath ||
      i18n.t('fichecollab.menuClassification') === aUcPath ||
      i18n.t('fichecollab.menuEmpTemps') === aUcPath ||
      i18n.t('fichecollab.menuInfComp') === aUcPath) {
      lText = "_" + aUcPath.replace(/\s/g, "_");
    } else if (i18n.t('fichecollab.menuProfilsMetier') === aUcPath ||
      i18n.t('fichecollab.menuCompetences') === aUcPath ||
      i18n.t('fichecollab.menuInaptitudes') === aUcPath) {
      lText = "_" + aUcPath.replace(/\s/g, "_") + "_1";
    } else if (i18n.t('fichecollab.menuRecapitulatifs') === aUcPath) {
      lText = "_" + aUcPath.split(" ")[0] + "_1";
    } else {
      lText = aPathDefault;
    }
    return lText;
  }

  _clickOption(event: any): boolean {
    const menu = this.$el.find(".phx-menus-transversal-print-menu");
    const name = event.target.className.split(" ")[0];
    let view = null;
    let uc = objs.appRt.workflow.get("usecase");
    const zone = !CWSTR.isBlank(uc) ? objs.appRt.workflow._searchZoneFromUc(uc) : objs.appRt.workflow.get("zone");
    const screenSize = {
      width: $(window).width(),
      height: $(window).height()
    };

    if ($(event.target).hasClass("ui-state-disabled")) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    //if "uc" est vide alors c'est un cas transversal(on ne doit pas faire cette verification dans "_searchZoneFromUc")
    if (CWSTR.isBlank(uc) && !CWSTR.isBlank(name)) {
      uc = name;
    }
    switch (name) {
      case "lanceetat":
        view = CWGeneralView;
        break;
      case "suvietat":
        view = SuvietatGeneralView;
        break;
      default:
      //Nothing
    }
    this.transversalViewDialog = new CWDialogPopupView({
      dialogClass: "c-cwDialogPopup cw-style cwHeader__transversal",
      view: view,
      viewData: {
        loc: {
          zone: zone,
          uc: uc
        }
      }
    });
    if (screenSize.height < 800) {
      this.transversalViewDialog.setHeight(screenSize.height - 20);
    } else {
      this.transversalViewDialog.setHeight(800);
    }
    if (screenSize.width < 1400) {
      this.transversalViewDialog.setWidth(screenSize.width - 40);
    } else {
      this.transversalViewDialog.setWidth(1400);
    }
    this.transversalViewDialog.open();
    $(menu).hide();
    this.transversalViewDialog.model.on("dlg:close", (events: JQuery.TriggeredEvent): void => {
      const $element = $(events.currentTarget);

      if ($element.dialog("instance")) {
        $element.dialog("destroy");
        $element.remove();
      }
    });
    return false;
  }

  render(): CWMenuBarView<TModel> {
    const json = { UTILS: UTILS, i18n: i18n };

    this.$el.html(this.template(json));
    //Let navigation by keyboard (enter key acts as click pressed).
    _.each(this.$el.find("#phx-menus-transversal-right span"), (spanItem: any) => {
      $(spanItem).keydown((e: any) => {
        this._transversalRightKeyboardNav(e);
      });
    });
    // create zone menus
    _.each(this.model.configuration.get("confignav").get("zones"), (zone: any) => {
      const zView = new CWZoneMenuView({
        id: "phx-menu-zone-" + zone.id,
        model: this.model,
        zJSON: zone
      });
      const zoneSelectorMenu = this.$el.parent().parent().find(".phx-zone-selector .phx-zones-menu #phx-btn-zone-" + zone.id);

      this.zViews[zone.id] = zView;
      zoneSelectorMenu.after(zView.render().el);
      this.$el.parent().parent().find(".phx-zone-selector .phx-zones-menu #phx-menu-zone-" + zone.id).css("position", "absolute").css("top", "1.55em").css("margin-left", "-0.1em");
      // By default it's hidden
      zView.$el.hide();
    }, this);
    this._renderMenuPrint();
    this._renderMenuAgendaCrit();
    this._renderMenuCalend();
    this._renderMenuHelp();
    return this;
  }

  _transversalRightKeyboardNav(e: JQueryEventObject): void {
    if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 40) { //enter, spacebar,  down arrow
      const menu = this.$el.find(".phx-menus-transversal-print-menu");
      const targetClass = e.target.className.split(" ")[0];
      const parentMenu = $(this.el).find("#phx-menus-transversal-right");

      //Open-Close menu
      if (!$(menu).is(":visible") || ($(menu).is(":visible") && e.keyCode !== 40)) {
        if (CWSTR.isBlank($(e.target).data("ecran"))) {
          $(e.target).click();
        }
      }
      //Select first element if it exist
      if (parentMenu.find("div." + targetClass + "-menu").length > 0) {
        parentMenu.find("div." + targetClass + "-menu").find("li").removeClass("hovered");
        if (!CWSTR.isBlank(e)) {
          parentMenu.find("div." + targetClass + "-menu").find("li").first().addClass("hovered").children().focus();
        } else {
          parentMenu.find("div." + targetClass + "-menu").find("li").first().children().focus();
        }
      }
      e.preventDefault();
    }
  }

  _transversalPrintKeyboardNav(e: JQueryEventObject): void {
    const $currentLi = $(e.target).parent().parent();

    if (e.keyCode === 38) { // up
      if ($(e.target).data("ecran") === "suvietat") {
        $currentLi.children().removeClass("hovered");
        $currentLi.children().first().addClass("hovered").children().focus();
      }
    } else if (e.keyCode === 40) { // down
      if ($(e.target).data("ecran") === "lanceetat") {
        $currentLi.children().removeClass("hovered");
        $currentLi.children().last().addClass("hovered").children().focus();
      }
    } else if (e.keyCode === 27) { // escape
      const printMenu = $(this.el).find(".phx-menus-transversal-print");

      // Close menu
      printMenu.click();
      printMenu.focus();
    } else if (e.keyCode === 9) { //tab
      //Close menu before tab
      this._clickMenu(e);
      this._showMenu(e);
    } else if (e.keyCode === 13 || e.keyCode === 32) { //enter, spacebar
      //Open-Close menu
      $(e.target).find("a").first()[0].click();
      e.preventDefault();
    }
  }

  _checkAffMultiplePlanning(usecase: string): boolean {
    let rtn = false;

    //seulement pour l'action de rafraîchir
    //cas spécial pour les planning des collaborateurs et médical avec la popup d'affectation multiple
    if (usecase === "planresp" || usecase === "planmed") {
      this.model.trigger(usecase + "_checkAffectMultiple", (etat: boolean): void => {
        rtn = etat ?? false;
      });
    }
    return rtn;
  }

  _hideMenuUtilisateur(): void { // menu lancement des états
    const menu = this.$el.find(".phx-savecriteria-menu");

    if ($(menu).is(":visible")) {
      $(menu).hide();
    }
  }

  _hideMenuPrint(): void { // menu lancement des états
    const menu = this.$el.find(".phx-menus-transversal-print-menu");

    if ($(menu).is(":visible")) {
      $(menu).hide();
    }
  }

  _hideAllMenus(): void {
    //cacher les menus
    this._hideMenuPrint();
    this._hideMenuUtilisateur();
  }
}
