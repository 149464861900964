import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLLanceetatDetail from '../cwDetail.tpl.html';
import { CWBaseFormView as CommonFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWComboBoxView2 } from 'core/components/combo/cwComboBoxView2';
import { CWCombosColl } from 'core/components/combo/cwCombos.collection';
import { CWCriteresColl } from '../models/cwCriteres.collection';
import { CWCriteresModel } from '../models/cwCriteres.model';
import { CWCriteresReinitModel } from '../models/cwCriteresReinit.model';
import { CWCritidComboColl } from '../models/cwCritidCombo.collection';
import { CWDynamicComboModel } from '../models/cwDynamicCombo.model';
import { CWDynamicComboModelColl } from '../models/cwDynamicComboModel.collection';
import { CWFORMS } from 'src/utils/cwForms';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWHEADERS } from 'src/utils/cwHeaders';
import { CWLanceetatWorkflow } from '../models/cwLanceetat.workflow';
import { CWLancerEtatModel } from '../models/cwLancerEtat.model';
import { CWPopulationComboModel } from '../models/cwPopulationCombo.model';
import { CWRadioBoxView } from 'src/core/views/cwRadioBox.view';
import { CWSelecteurCheminView } from 'core/components/selecteur_chemin/cwSelecteurChemin.view';
import { CWSelecteurRefDialogView } from './cwSelecteurRefDialog.view';
import { CWSelecteurReferentielView } from 'core/components/selecteur_referentiel/cwSelecteurReferentiel.view.js';
import { CWSTR } from 'src/utils/cwStr';
import { CWTYPE } from 'src/tda/cwTda';
import { i18n } from 'src/i18n.js';
import { ListBoxView } from 'src/core/components/list_box/view/listBox.view';
import { objs } from 'src/objectsRepository';

export class CWFormView extends CommonFormView {

  coll: CWCriteresColl<CWCriteresModel>;
  groupe: any;
  isValidCollection: boolean;
  workflow: CWLanceetatWorkflow;
  subgroupe: any[];
  numSubGroupe: any[];
  combos: any;
  referentielArray: any[];
  isCascade: boolean;
  comboPopulation: any;
  popId: string;
  popType: string;
  datedeb: any;
  datefin: any;
  host: any;
  idComponent: any;

  constructor(options: Backbone.ViewOptions<Backbone.Model> | any) {
    options = options || {};
    options.events = _.extend({
      "keyup .phx-populations-detail :input:not([readonly])": "_notifyEdition",
      "change .lst_pers": "_populationDispatcher",
      "change .phx-lanceetat-detail :input:not([readonly])": "_changeView"
    }, CWTYPE.SHORT.events(), CWTYPE.LONG.events(), CWTYPE.DATE.events(), CWTYPE.HOUR_MINUTE.events(), options.events);
    super(options);
    this.template = TPLLanceetatDetail;
    if (options && options.workflow) {
      this.workflow = options.workflow;
      this.workflow.formView = this;
    }
    this.coll = new CWCriteresColl();

    const criteresHab = new CWHabilitationContext({
      onglet: this.workflow.module,
      foncCour: "RES_RECAP.E",
      natGest: ""
    });

    this.coll.setHabContext(criteresHab);
    this.groupe = 1;
    this.isValidCollection = false;

    this.model = new CWBaseModel({
      value: new CWBaseModel()
    });
    this.listenTo(this.coll, "sync", this.mapToForm);
    this.listenTo(this.coll, "validateColl", this._isValidCollection);
    this.combos = {};
    this.referentielArray = [];
  }

  mapToForm(model: { [key: string]: any }): void {
    this._cleanValidationErrors();
    $(this.el).find(".phx-lanceetat-detail-content").empty();
    this.groupe = []; //	on initialise pour groupes
    this.subgroupe = []; //	on initialise pour subgroupes
    this.numSubGroupe = []; //un compteur de subgroupes
    this._checkButtons();
    this.workflow.btnBarModel.trigger("enable:newhj");
    this._prepareGroupes(model); // révise les groupes de les sous-groupes
    if (!CWSTR.isBlank(model)) {
      this.referentielArray = [];
      this._manageGroupes(model);
      this._chargeFieldData(model);
    }
  }

  _checkButtons(): void {
    if (this.workflow && this.workflow.btnBarModel && this.workflow.tableModel && this.workflow.tableModel.get("value")) {
      const lbPrint = this.workflow.tableModel.get("value").get("imprimer");
      const lbExport = this.workflow.tableModel.get("value").get("exporter");
      let lsAction = "";

      if (lbPrint === true) {
        lsAction = "enable:print";
      } else {
        lsAction = "disable:print";
      }
      this.workflow.btnBarModel.trigger(lsAction);
      if (lbExport === true) {
        lsAction = "enable:export";
      } else {
        lsAction = "disable:export";
      }
      this.workflow.btnBarModel.trigger(lsAction);
    }
  }

  _numberSubgroup(aGroupe: any): any {
    let lRtn = -1;
    if (!CWSTR.isBlank(aGroupe) && this.subgroupe[aGroupe] && this.numSubGroupe.length > 0) {
      lRtn = this.numSubGroupe[aGroupe]; //_.values(this.subgroupe[a_groupe]).length;
    }
    return lRtn;
  }

  _orderCodeSubgroup(aGroupe: any, aCode: any): any {
    let lRtn = -1;

    if (!CWSTR.isBlank(aGroupe) && this.subgroupe[aGroupe] && this.subgroupe[aGroupe][aCode]) {
      lRtn = this.subgroupe[aGroupe][aCode];
    }
    return lRtn;
  }

  _ExistInSousCriteres(aCode: any, aModel: { [key: string]: any }): boolean {
    let lbRtn = false;
    const element = aModel.get("souscriteres");

    if (element.length > 0) {
      for (let i = 0; i < element.length; i++) {

        if (element.at(i).get("alignement") === aCode) {
          lbRtn = true;
          break;
        } else {
          if (element.at(i).get("souscriteres").length > 0) {
            lbRtn = this._ExistInSousCriteres(aCode, element.at(i));
          }
        }
      }
    }
    return lbRtn;
  }

  _existAlignementForGroup(aCode: any, aModels: any): boolean {
    let lbRtn = false;

    if (aCode && aModels && aModels.length > 0) {
      for (let i = 0; i < aModels.length; i++) {
        if (aModels.at(i).get("alignement") === aCode) {
          lbRtn = true;
          break;
        } else {
          lbRtn = this._ExistInSousCriteres(aCode, aModels.at(i));
          if (lbRtn) {
            break;
          }
        }
      }
    }
    return lbRtn;
  }

  _searchGroup(aCode: any): any {
    let lRtn = -1;

    if (aCode && this.coll && this.coll.models.length > 0) {
      if (_.isEmpty(this.coll.get(aCode))) {
        for (let i = 0; i < this.coll.models.length; i++) {
          if (this._ExistInSousCriteres(aCode, this.coll.models[i])) {
            lRtn = this.coll.models[i].get("groupe");
            break;
          }
        }
      } else {
        lRtn = this.coll.get(aCode).get("groupe");
      }
    }
    return lRtn;
  }

  _manageGroupes(aModel: { [key: string]: any }): void {
    const value = aModel.get("value") !== undefined ? aModel.get("value") : aModel;

    if (value) {
      const $div = $(this.el).find(".phx-lanceetat-detail-content");
      let lbFirstAlig = false;

      _.each(value.models, (model: { [key: string]: any }): void => {
        let lAux = [];
        if (model) {
          const lGroupe = model.get("groupe");

          if (this.groupe.indexOf(lGroupe) === (-1)) {
            this.groupe.push(lGroupe);
          }
          if (this.subgroupe[lGroupe]) {
            if ((this._existAlignementForGroup(model.get("code"), value)) || lbFirstAlig) {
              lbFirstAlig = false;
              lAux = this.subgroupe[lGroupe];
              this.numSubGroupe[lGroupe]++;
              lAux[model.get("code")] = this.numSubGroupe[lGroupe]; //nouveau groupe
              this.subgroupe[lGroupe] = lAux;
            } else {
              lAux = this.subgroupe[lGroupe];
              lAux[model.get("code")] = this.numSubGroupe[lGroupe]; //même groupe
              this.subgroupe[lGroupe] = lAux;
            }
          } else {
            lAux[model.get("code")] = 1;
            lbFirstAlig = false;
            if (this._existAlignementForGroup(model.get("code"), value)) {
              lbFirstAlig = true;
            }
            this.subgroupe[lGroupe] = lAux;
            this.numSubGroupe[lGroupe] = 1;
          }
        }
      }, this);
      //on crée la structure
      for (let i = 0; i < this.groupe.length; i++) {
        const lDiv1 = $('<div>').addClass("phx-lanceetat-detail-content-groupe" + this.groupe[i]).css({ "display": "table", "width": "100%" });
        const lLen = this._numberSubgroup(this.groupe[i]);
        let lTmp = null;

        if (lLen > 1) {
          for (let k = 1; k <= lLen; k++) {
            lTmp = $('<div>').addClass("phx-lanceetat-detail-content-groupe" + this.groupe[i] + "-subgroupe" + k + "-row").attr('style', "width:100%; display:table-row; min-width:796px;");
            lTmp.append($('<div>').addClass("phx-lanceetat-detail-content-groupe" + this.groupe[i] + "-subgroupe" + k + "-column1").attr('style', "width:48%; display:table-cell; vertical-align: middle; min-width:398px;"));
            lTmp.append($('<div>').addClass("phx-lanceetat-detail-content-groupe" + this.groupe[i] + "-subgroupe" + k + "-column2").attr('style', "width:48%; display:table-cell; vertical-align: middle; min-width:398px; padding-left:10px"));
            lDiv1.append(lTmp);
          }
        } else {
          lTmp = $('<div>').addClass("phx-lanceetat-detail-content-groupe" + this.groupe[i] + "-row").attr('style', "width:100%; display:table-row; min-width:720px;");
          lTmp.append($('<div>').addClass("phx-lanceetat-detail-content-groupe" + this.groupe[i] + "-column1").attr('style', "width:48%; display:table-cell; vertical-align: middle; min-width:398px;"));
          lTmp.append($('<div>').addClass("phx-lanceetat-detail-content-groupe" + this.groupe[i] + "-column2").attr('style', "width:48%; display:table-cell; vertical-align: middle; min-width:398px; padding-left:10px"));
          lDiv1.append(lTmp);
        }
        if (i < (this.groupe.length - 1)) {
          lDiv1.css({ "border-bottom": "1px solid #e5e7f1" });
          lDiv1.css({ "margin-bottom": "10px" });
          $div.append(lDiv1);
        } else {
          $div.append(lDiv1);
        }
      }
    }
  }

  _chargeFieldData(model: { [key: string]: any }): CWFormView {
    const value = model.get("value") !== undefined ? model.get("value") : model;

    if (value) {
      const listCascade = [];
      for (let i = 0; i < value.length; i++) {
        const element = value.at(i);
        //render simple field
        this._manageFields(element);
        if (element.get("souscriteres").length > 0) {
          this._manageSouscriteres(element);
        }
        if (element.get('cascade') && element.get('index') === 0) {
          listCascade.push(element);
        }
      }
      for (let indexCascade = 0; indexCascade < listCascade.length; indexCascade++) {
        const elementCascade = listCascade[indexCascade];
        const _emptyField = (CWSTR.isNull(value) || value === "") ? true : false;
        this._manageCascade(elementCascade, _emptyField);
      }
    }
    return this;
  }

  _manageFields(model: CWCriteresModel, index?: number): boolean {
    const libelle = model.get("libelle");
    const obligatoire = model.get("obligatoire");
    const parent = model.get("parent");
    const tda = model.get("tda");
    const code = model.get("code");
    const type = model.get("type");
    let groupe = model.get("groupe");
    const defaut = model.get("defaut");
    const lColonne = model.get("colonne");
    const lAlignement = model.get("alignement");
    let container: JQuery = null;
    //form model
    const formValue = this.model.get("value");
    let valeurParentSelected = "";
    let populationComboModel = null;
    let lsFind = "";
    //let lWidth: any = null;

    //Avoid population if no habilitation
    if (code === "lst_pers" && !CWHABILITATION.canView("RES_POPCOLLAB.V")) {
      return true;
    }
    if (this.combos[parent]) {
      valeurParentSelected = !CWSTR.isBlank(parent) && this.combos[parent].getItem() ? this.combos[parent].getItemId() : "";
    } else {
      valeurParentSelected = !CWSTR.isBlank(parent) ? $(this.el).find("." + parent).val() as string : "";
    }

    const name = "edition/definition/" + this.workflow.tableModel.get("value").get("code");
    let comboId = "critere/" + code;

    if (model.get('cascade')) {
      this.isCascade = true;
      comboId += "?grpName=" + model.get('grpName') + "&valeurs=";
      const urlValeur = model.get('urlvaleur');
      if (!CWSTR.isUndefined(urlValeur)) {
        for (let indexValue = 0; indexValue < urlValeur.length; indexValue++) {
          comboId += urlValeur[indexValue];
          if (indexValue !== urlValeur.length - 1) {
            comboId += ',,'
          }
        }
      }
    }

    if (code === "lst_pers") {
      populationComboModel = CWPopulationComboModel;
    }

    let valeurColl = new CWCombosColl({
      name: name,
      comboId: comboId,
      model: populationComboModel
    });

    valeurColl.setHabContext(this.workflow.getHabContext().copy());

    if (!CWSTR.isBlank(valeurParentSelected)) {
      const obj = {
        valeur: valeurParentSelected
      };
      valeurColl.params = obj;
    }
    formValue.set(code, defaut);

    //chercher container s'il y a "alignement" (il dépend du groupe avec valeur de alignement)
    if (!CWSTR.isBlank(lAlignement) && lAlignement !== parent) {
      //je cherche le groupe auquel il appartient
      groupe = this._searchGroup(lAlignement);

      if (this._numberSubgroup(groupe) <= 1) {
        lsFind = ".phx-lanceetat-detail-content-groupe" + groupe;
      } else {
        const lSub = this._orderCodeSubgroup(groupe, lAlignement);
        lsFind = ".phx-lanceetat-detail-content-groupe" + groupe + "-subgroupe" + lSub;
      }
    } else {
      if (this._numberSubgroup(groupe) <= 1) {
        lsFind = ".phx-lanceetat-detail-content-groupe" + groupe;
      } else {
        let lSub = this._orderCodeSubgroup(groupe, code);
        if (lSub < 1) {
          //parent
          lSub = this._orderCodeSubgroup(groupe, parent);
        }
        lsFind = ".phx-lanceetat-detail-content-groupe" + groupe + "-subgroupe" + lSub;
      }
    }
    if (!CWSTR.isBlank(lAlignement) && lAlignement === parent && lColonne === 1) {
      lsFind += "-column2";
    } else {
      lsFind += "-column" + lColonne;
    }
    container = $(this.el).find(lsFind);
    container.show();

    let field = null;

    //span container
    const div = $(this.el).find(".div_" + code).length > 0 ? $(this.el).find(".div_" + code) : $('<div>').addClass("div_" + code);
    div.empty();

    //label
    let label = $('<label>');
    label.attr("for", code);
    label.text(libelle);
    if (obligatoire === true && type !== "libelle" && type !== "checkbox" && type !== "radio" && type !== "info" && type !== "titre") {
      label.addClass("cw-required");
    }

    //span for errors
    const errorSpan = $('<span>');
    errorSpan.addClass(code + "-error-container");

    switch (type) {
      case "radio": {
        //div container

        //paint div and label
        container.append(div);
        label.addClass("phx-lanceetat-labelRadio");
        label.css("padding-bottom", "10px");
        $(this.el).find(".div_" + code).before(label);
        $(this.el).find(".div_" + code).append(errorSpan);

        const options: any = [];
        let valDefaut = "";
        //load radio
        _.each(model.get("radio"), (aux: any): void => {
          if (aux && !_.isEmpty(aux)) {

            const option: { [key: string]: any } = {};
            option.code = aux.code;
            option.libelle = aux.libelle;
            options.push(option);

            if (String(aux.code) === String(defaut)) { // ne pas mettre ===
              valDefaut = aux.code;
            }
          }
        });

        const radioParam = new CWRadioBoxView({
          options: options,
          name: code,
          required: true,
          selectedOption: valDefaut,
        }).render().el;
        this.$el.find(".div_" + code).append(radioParam);
        this._resizeFields(code);
        model.set("value", defaut);

        break;
      }
      case "checkbox": {
        const parentClass = this._buildParentClass(parent);

        const divDiv = $('<div class="form-group cw-customControlGroup" style="border: none; padding: 0; margin-bottom: 0;">');
        const divField = $('<div class="custom-control custom-checkbox">');

        //input
        field = $('<input>');
        field.attr({ "type": "checkbox", "parent": parentClass });
        field.attr("id", code);
        field.addClass(code);
        field.addClass("custom-control-input");

        //paint fields
        container.append(div);
        label.addClass("phx-lanceetat-labelCheck");
        label.addClass("custom-control-label");

        divField.append(field);
        divField.append(label);
        divDiv.append(divField);
        $(this.el).find(".div_" + code).append(divDiv);
        $(this.el).find(".div_" + code).append(errorSpan);
        //$(this.el).find(".div_" + code).append("<br>");

        //default
        if (defaut === "checked") { //oui
          $(this.el).find("." + code).attr('checked', "true");
          model.set("value", true);
        } else {
          model.set("value", false);
        }
        model.set('dysplayText', model.get('code'));
        this._resizeFields(code);

        break;
      }
      case "date": {
        const parentClass = this._buildParentClass(parent);

        //input
        field = $('<input>');
        field.attr("parent", parentClass);
        field.addClass(code);

        //div-field
        const divField = $('<div class="c-date-selector input-group">');

        //paint fields
        container.append(div);
        label.addClass("phx-lanceetat-labelDate");
        $(this.el).find(".div_" + code).append(label);
        field.addClass("phx-lanceetat-fieldDate");
        field.addClass("form-control");
        field.addClass("typeDate");
        divField.append(field);
        $(this.el).find(".div_" + code).append(divField);
        $(this.el).find(".div_" + code).append(errorSpan);
        $(this.el).find(".div_" + code).addClass("form-group")
        $(this.el).find(".div_" + code).append("<br>");

        //set datepicker
        CWFORMS.setDatepicker(this, "." + code);
        $(this.el).find("." + code).addClass(CWTYPE._getTypeClassByCode(tda));

        //default
        const date = CWTYPE.DATE.format(defaut, "DD/MM/YYYY");
        $(this.el).find("." + code).val(date);
        model.set("value", defaut);
        this._resizeFields(code);

        break;
      }
      case "select": {
        //add class parent
        const parentClass = this._buildParentClass(parent);
        let defalutValue = null;
        let displayText = null;

        if (model.get('multivaleur')) {
          let nbline = 3;
          let search = true;
          if (model.get('valeur').length <= 4) {
            nbline = model.get('valeur').length;
            search = false;
          }

          const callBackFunction = (): void => {
            const listBox = new ListBoxView({
              id: 'lsb-' + code,
              multiValue: true,
              search: search,
              enum: model.get('valeur'),
              size: nbline,
              name: code,
              default: defaut
            });

            this.listenTo(listBox, "selectionChanged", (newValues: (string | number)[]) => {
              if (newValues.length === 0) { //Only managment this case because the case on selected one is managed by changeView
                const localModel = this._getModelByCritere(code, null);
                localModel.set('value', []);
                localModel.set('displayText', []);
                if (localModel.get('cascade')) {
                  this._manageCascade(localModel);
                } else {
                  this._manageSouscriteres(localModel);
                }
                //this._change(null, localModel, false);
              }
            });

            // div for listBox
            field = $('<div>');
            field.addClass('lsb_' + code);
            field.append(listBox.render().el);

            //paint fields
            container.append(div);
            label.addClass("phx-lanceetat-labelSelectMulti");
            $(this.el).find(".div_" + code).append(label);
            $(this.el).find(".div_" + code).append(field);
            $(this.el).find(".div_" + code).append(errorSpan);

            listBox.addTitleLabel();

            defalutValue = model.get('valeur').filter((el: { [key: string]: any }): void => {
              return defaut.filter((def: any): boolean => {
                return def === el.code
              })
            });
            displayText = defalutValue.map((el: { [key: string]: any }): string => {
              return el.libelle;
            })
            model.set('displayText', displayText);

            model.set("value", defaut);

            this._resizeFields(code);
          }
          if (index && index > 0) {
            valeurColl.updateHabContext({
              foncCour: this.workflow.tableModel.get("value").get("fonction").code
            });
            valeurColl.fetch({
              success: (newValues: CWCombosColl) => {
                //Transform to enum
                const enumer: Array<{ [key: string]: any }> = [];
                let index = 0;
                _.each(newValues.models, (model) => {
                  enumer[index] = model.attributes;
                  index++;
                })
                model.set("valeur", enumer);
                callBackFunction();
              }
            });
          }
          else {
            callBackFunction();
          }


        } else {
          // This would be the value of  the "selchoix param
          const typeSel = model.get("selchoix");
          let combo = null;
          let codeSetItem = defaut;
          let values = null;
          let codeGlobal = "";
          let typeGlobal = "";
          let valuePopulation = "";

          if (typeSel !== "SELREF") {
            //create combo

            if (!CWSTR.isBlank(objs.populationMenu)) {
              codeGlobal = objs.populationMenu.model.get("ident");
              typeGlobal = objs.populationMenu.model.get("type");
              valuePopulation = codeGlobal + "," + typeGlobal;
            }

            if (!CWSTR.isNull(model.get('valeur'))) {
              values = model.get('valeur');
              combo = this._createCombo(code, obligatoire, values, 0, this.workflow.getHabContext().clone());

              defalutValue = model.get('valeur').filter((el: { [key: string]: any }): boolean => {
                return el.code === defaut
              });
              if (defalutValue.length > 0) {
                model.set('displayText', defalutValue[0].libelle);
              }
              model.set("value", defaut);
            } else if (code === "critid" && this.workflow.openedFromPlanning) {
              valeurColl = new CWCritidComboColl([], {
                name: name,
                comboId: comboId,
              });
              valeurColl.setHabContext(this.workflow.getHabContext().copy());

              combo = this._createCombo(code, obligatoire, valeurColl, 0);
              model.set("value", defaut);
            } else if (code === "lst_pers") {
              combo = this._createCombo(code, true, valeurColl, 0);
              if (!CWSTR.isBlank(codeGlobal)) {
                codeSetItem = valuePopulation;
                model.set("value", valuePopulation);
              } else {
                model.set("value", defaut);
              }
              this.comboPopulation = combo;
              if (!CWSTR.isBlank(codeGlobal)) {
                const valuePopulationArray = valuePopulation.split(",");
                this.popId = !CWSTR.isBlank(valuePopulationArray[0]) ? valuePopulationArray[0] : null;
                this.popType = !CWSTR.isBlank(valuePopulationArray[1]) ? valuePopulationArray[1] : null;
              }
              //}
            } else {
              combo = this._createCombo(code, obligatoire, valeurColl, 0);
              model.set("value", defaut);
            }

            valeurColl.updateHabContext({
              foncCour: this.workflow.tableModel.get("value").get("fonction").code
            });

            // span for combo
            field = $('<span>');
            field.addClass('cmb_' + code);
            field.append(combo.render().el);

            if (code === "lst_pers") {
              // initialize with the population transverse, if not found set 0.
              combo.setItem({ code: codeSetItem }, (response: { [key: string]: any }): void => {
                if (CWSTR.isBlank(_.find(response, { id: model.get("value") }))) {
                  model.set("value", "0");
                }
              });
            } else {
              if (!CWSTR.isBlank(codeSetItem) || !obligatoire) { //seul quand codeSetitem is null et obligatorie est true (combobox avec blanc), on ne peut pas faire un setitem
                combo.setItem({ code: codeSetItem });
              }
            }

            container.append(div);
            label.addClass("phx-lanceetat-labelSelect");
            $(this.el).find(".div_" + code).append(label);
            $(this.el).find(".div_" + code).append(field);
            //No errorSpan comme les autres cas
            $(this.el).find(".div_" + code).append("<br>");
            $(this.el).find("." + code).attr("parent", parentClass);

            this._resizeFields(code);
          } else {
            let urlService = model.get("selservice");
            const modifyHeader = this.workflow.openedFromPlanning ? true : false;

            if (model.get('cascade')) {
              const urlValeur = model.get('urlvaleur');

              urlService += "?grpName=" + model.get('grpName') + "&valeurs=";
              if (!CWSTR.isUndefined(urlValeur)) {
                for (let indexValue = 0; indexValue < urlValeur.length; indexValue++) {
                  urlService += urlValeur[indexValue];
                  if (indexValue !== urlValeur.length - 1) {
                    urlService += ',,'
                  }
                }
              }
            }
            if (!CWSTR.isBlank(objs.populationMenu)) {
              codeGlobal = objs.populationMenu.model.get("ident");
              typeGlobal = objs.populationMenu.model.get("type");
              valuePopulation = codeGlobal + "," + typeGlobal;
            }

            if (!CWSTR.isBlank(this.model.get("value").get("date"))) {
              this.datedeb = this.model.get("value").get("date");
              this.datefin = this.model.get("value").get("date");
            } else if (!CWSTR.isBlank(this.model.get("value").get("date_a")) || !CWSTR.isBlank(this.model.get("value").get("date_de"))) {
              this.datedeb = this.model.get("value").get("date_de");
              this.datefin = this.model.get("value").get("date_a");
            }

            const comboModelCollOptions: any = {
              url: urlService,
              modifyHeader: modifyHeader,
              defaut: defaut,
              refForm: this
            }

            if (code === "nom_du" || code === "matr_du" || code === "nom_au" || code === "matr_au") {
              if (this.popId && this.popId !== '0') {
                _.extend(comboModelCollOptions, { headers: CWHEADERS.populationContext(this.popId, this.popType) });
                comboModelCollOptions.filtre = true;
              }
            }

            const valeurColl = new CWDynamicComboModelColl(comboModelCollOptions);

            valeurColl.datedeb = this.datedeb;
            valeurColl.datefin = this.datefin;
            valeurColl.setHabContext(this.workflow.getHabContext().copy());

            // let first = null;
            // if (code === "nom_du" || code === "matr_du") {
            //   first = true;
            // } else if (code === "nom_au" || code === "matr_au") {
            //   first = false;
            // }

            valeurColl.updateHabContext({
              foncCour: this.workflow.tableModel.get("value").get("fonction").code
            });

            combo = this._createReferentiel(code, obligatoire, valeurColl, 0, model);

            this.referentielArray.push(combo);

            if (!CWSTR.isBlank(defaut)) {
              let options;

              if (this.popId && this.popId !== '0') {
                options = {
                  model: { filtre: true }
                };
                options = _.extend(options, { headers: CWHEADERS.populationContext(this.popId, this.popType) });
              }
              this._initReferentielWithCode(defaut, urlService, combo, model, options);
            }

            // span for combo
            field = $('<span>');
            field.addClass('cmb_' + code);
            //field.css("display", "inline-block");
            field.append(combo.render().el);

            container.append(div);
            label.addClass("phx-lanceetat-labelSelect");
            $(this.el).find(".div_" + code).append(label);
            $(this.el).find(".div_" + code).append(field);
            //No errorSpan comme les autres cas
            $(this.el).find(".div_" + code).append("<br>");
            $(this.el).find("." + code).attr("parent", parentClass);

            this._resizeFields(code);
          }
        }
        break;
      }
      case "text": {
        const parentClass = this._buildParentClass(parent);

        const divField = $('<div class="input-group">')
        //input
        field = $('<input>');
        field.attr({ "type": "text", "value": "", "size": "15", "parent": parentClass });
        field.addClass(code).addClass(CWTYPE._getTypeClassByCode(tda));
        field.addClass("form-control");

        //paint fields
        container.append(div);
        label.addClass("phx-lanceetat-labelText");
        divField.append(field);

        $(this.el).find(".div_" + code).append(label);
        $(this.el).find(".div_" + code).append(divField);
        $(this.el).find(".div_" + code).append(errorSpan);
        $(this.el).find(".div_" + code).append("<br>");

        //default
        const mask = CWTYPE._getMaskByCode(tda);
        const valAux = CWTYPE.MASK.applyFormatMask(defaut, mask);

        $(this.el).find("." + code).val(valAux);
        model.set("value", defaut);
        this._resizeFields(code);

        break;
      }
      case "textarea": {
        const parentClass = this._buildParentClass(parent);

        //lWidth = (container.width() * 0.43); //43% du container
        //lWidth = (lWidth > 2) ? lWidth - 2 : 0;
        //input
        field = $('<textarea>');
        field.attr({ "parent": parentClass, "rows": 4, "cols": 50 });
        field.addClass(code);
        //field.width(lWidth);
        //paint fields
        container.append(div);
        label.addClass("phx-lanceetat-labelTextArea");
        $(this.el).find(".div_" + code).append(label);
        $(this.el).find(".div_" + code).append(field);
        $(this.el).find(".div_" + code).append(errorSpan);
        $(this.el).find(".div_" + code).append("<br>");

        //default
        $(this.el).find("." + code).val(defaut);
        model.set("value", defaut);
        this._resizeFields(code);

        break;
      }
      case "libelle": {
        //const parentClass = this._buildParentClass(parent);

        //paint fields
        container.append(div);
        label.addClass("phx-lanceetat-labelLibelle");
        $(this.el).find(".div_" + code).append(label);

        //default
        model.set("value", defaut);
        if (CWSTR.isBlank(defaut)) {
          label.hide();
        } else {
          label.show();
          this._resizeFields(code);
        }

        break;
      }
      case "organisation": {//chemin
        const parentClass = this._buildParentClass(parent);
        //lWidth = (container.width() * 0.43); //43% du container

        //input
        field = $('<span>');
        field.addClass("chemin_" + code);
        //field.css("display", "inline-block");

        //paint fields
        if (container.find(".div_" + code).length === 0) {
          container.append(div);
        }

        if (CWSTR.isBlank(this.$el.find("." + parent).val())) {
          $(this.el).find("." + code).hide();
        }

        label.addClass("phx-lanceetat-labelOrganisation");
        $(this.el).find(".div_" + code).append(label);
        $(this.el).find(".div_" + code).append(field);
        $(this.el).find(".div_" + code).append(errorSpan);
        $(this.el).find(".div_" + code).append("<br>");

        field.empty();
        model.set("value", null);
        let id = "";
        if (this.combos[parent].getItem()) {
          id = this.combos[parent].getItemId();
        }
        if (!CWSTR.isBlank(id)) {
          const lhabContext = new CWHabilitationContext({
            onglet: this.workflow.getHabContext().get("onglet"),
            foncCour: this.workflow.tableModel.get("value").get("fonction").code,
            natGest: this.workflow.getHabContext().get("natGest")
          });
          const chemin = new CWSelecteurCheminView({
            name: code,
            listRenderStyle: 1, //format
            //fieldWidth: lWidth,
            height: 550,
            width: 850,
            structid: id, //code
            addItemCallback: (data: { [key: string]: any }): void => {
              this._responseCallbackChemin(data, code);
            },
            intermediaire: true,
            nonrattachable: true,
            partial: false,
            modeComplet: true,
            multiselection: false,
            habContext: lhabContext,
            context: { ctxEcran: this.workflow.module },
            appendTo: null
          });
          field.append(chemin.render().el);
          $(this.el).find("." + code).attr("parent", parentClass);
          $(this.el).find("." + code).show();
        }
        this._resizeFields(code);

        break;
      }
      case "titre": {
        //const parentClass = this._buildParentClass(parent);

        //paint fields
        container.append(div);
        label.addClass("phx-lanceetat-typeTitre");
        label.addClass("ui-phx-ihm-texte-application-important");
        label.css("font-weight", "300 !important");
        label.css("text-transform", "uppercase");
        $(this.el).find(".div_" + code).append(label);
        $(this.el).find(".div_" + code).append("<br>");

        //default
        model.set("value", defaut);
        this._resizeFields(code);

        break;
      }
      case "info": {
        //const parentClass = this._buildParentClass(parent);
        const parInfo = $("<p>");

        //lWidth = (container.width() * 0.49); //49% du container
        //paint fields
        container.append(div);
        label = null;
        //parInfo.width(lWidth);
        parInfo.text(libelle);
        parInfo.attr("data-info", code);
        parInfo.addClass("phx-lanceetat-typeInfo cw-texteNormal");
        $(this.el).find(".div_" + code).append(parInfo);

        //default
        model.set("value", defaut);
        if (CWSTR.isBlank(defaut)) {
          parInfo.hide();
        } else {
          parInfo.show();
        }

        break;
      }
      case "increment": {
        const parentClas = this._buildParentClass(parent);

        //input
        field = $('<input>');
        field.attr({ "type": "number", "parent": parentClas, "min": "0", "maxlength": "2" }); //de 0 à 99
        field.addClass(code);

        //paint fields
        container.append(div);
        label.addClass("phx-lanceetat-labelIncrement");
        $(this.el).find(".div_" + code).append(label);
        field.addClass("phx-lanceetat-fieldIncrement");
        $(this.el).find(".div_" + code).append(field);
        $(this.el).find(".div_" + code).append(errorSpan);
        $(this.el).find(".div_" + code).append("<br>");

        //default
        if (!CWSTR.isBlank(defaut)) {
          $(this.el).find("." + code).attr('value', defaut);
          model.set("value", defaut);
        } else {
          model.set("value", "0");
        }
        this._resizeFields(code);
        break;
      }
      default:

    }
    model.set("visible", true);
    return true;
  }

  _resizeFields(code: string): void {
    const labelHeight = $(this.el).find(".div_" + code + " label").height();
    const divHeight = $(this.el).find(".div_" + code).height();

    if (labelHeight > divHeight) {
      $(this.el).find(".div_" + code).css("height", $(this.el).find(".div_" + code + " label").height());
    }
  }

  _buildParentClass(parent: any): string {
    let parentClass = "";

    if (!CWSTR.isBlank(parent) && this.$el.find("." + parent).length > 0) {
      if (this.$el.find("." + parent).attr("parent")) {
        parentClass += this.$el.find("." + parent).attr("parent");
      }
      if (!CWSTR.isBlank(parentClass)) {
        parentClass += " ";
      }
    }
    parentClass += parent;
    return parentClass;
  }

  //callback chemin
  _responseCallbackChemin(data: { [key: string]: any }, code: string): void {
    const target = $(this.el).find("." + code);
    const parent = target.attr("parent");
    const model = this._getModelByCritere(code, parent);
    //Obtain chemin
    let parents = "";

    if (!CWSTR.isBlank(data.parents)) {
      _.each(data.parents, (parent: { [key: string]: any }): void => {
        parents += "/" + parent.code;
      });
      parents += "/" + data.code + "/";
    } else {
      if (!CWSTR.isBlank(data.code)) {
        parents = "/" + data.code + "/";
      } else {
        parents = "";
      }
    }
    model.set("value", parents);
  }

  _createCombo(code: string, obligatoire: boolean, ws: { [key: string]: any }, aWidth: number, hc?: { [key: string]: any }): any {
    let combo = null;

    if (CWSTR.isUndefined(hc)) {
      hc = ws.getHabContext();
    }

    let options = {
      id: code + "_comboId",
      name: code,
      //width: aWidth,
      required: obligatoire,
      habContext: hc as CWHabilitationContext
    }
    if (ws instanceof Array) {
      options = $.extend({}, options, { enum: ws });
    } else {
      options = $.extend({}, options, { ws: ws });
    }
    combo = new CWComboBoxView2(options);

    this.combos[code] = combo;
    return combo;
  }

  _createReferentiel(code: string, obligatoire: boolean, ws: { [key: string]: any }, aWidth: number, model: { [key: string]: any }): { [key: string]: any } {
    const searchParams = {};

    const referentiel = new CWSelecteurReferentielView({
      coll: ws,
      name: code,
      view: CWSelecteurRefDialogView,
      title: model.get("libelle"),
      height: 650,
      width: 850,
      required: false,
      //fieldWidth: aWidth,
      readonly: false,
      addItemCallback: (data: { [key: string]: any }): void => {
        this._responseCallback(data, model);
      },
      displayTitle: false,
      displayAllColumns: false,
      displayColumns: ["libelle"],
      searchParams: searchParams,
      appendTo: (this.workflow ? this.workflow.$appendTo : null)
    });

    return referentiel;
  }

  _responseCallback(data: { [key: string]: any }, model: { [key: string]: any }): void {
    model.set("value", data.code);

    if (this.host && this.host._responseCallback) {
      this.host._responseCallback(data, this.idComponent);
    }
  }

  _initReferentielWithCode(code: string, url: string, combo: any, model: { [key: string]: any }, options: { [key: string]: any }): void {
    const headerPopulation = (options && options.headers) ? options.headers : null;
    let optionsFetch = {};
    let optionsModel = {
      url: url,
      defaut: code,
      nbrang: 2
    };
    const originalCollection = new Backbone.Collection();
    let originalModel = null;

    optionsModel = (!_.isEmpty(options) && !_.isEmpty(options.model)) ? _.extend({}, optionsModel, options.model) : optionsModel;
    originalModel = new CWDynamicComboModel(optionsModel);
    originalModel.setHabContext(this.workflow.getHabContext().copy());
    originalModel.code = code;
    optionsFetch = {
      success: (fresh: any): void => {
        if (_.size(fresh.attributes) >= 1) {
          originalCollection.push(fresh.get("list")[0]);
          model.set("value", code);
        }
        combo.setValues(originalCollection);
      }
    };
    optionsFetch = (headerPopulation) ? _.extend(optionsFetch, { headers: headerPopulation }) : optionsFetch;
    originalModel.fetch(optionsFetch);
  }

  _populationDispatcher(event: JQueryEventObject): void {
    this.workflow.once("end:ManageSouscriteurs", this._updateSouscriteurs, this);
    this._updateCombosCollection(event);
  }

  _getModelByCritere(id: any, parent: any): any {
    let collection = this.coll;

    if (!CWSTR.isBlank(parent)) {
      const parentArray = parent.split(" ");

      for (let i = 0; i < parentArray.length; i++) {
        collection = collection.get(parentArray[i]).get("souscriteres");
      }
    }
    return collection.get(id);
  }

  _updateSouscriteurs(): void {
    const model = this._getModelByCritere("sel_crit", "");
    if (model) {
      this._manageSouscriteres(model);
    }
  }

  _updateCombosCollection(event: JQueryEventObject, data?: { [key: string]: any }): void {

    if (this.workflow && this.workflow.formView && this.workflow.formView.comboPopulation && this.workflow.formView.comboPopulation.currentCode) {
      data = this.workflow.formView.comboPopulation.currentCode.split(',');
    } else {
      data = (event.currentTarget as any).value.split(",");
    }
    this.popId = !CWSTR.isBlank(data[0]) ? data[0] : null;
    this.popType = !CWSTR.isBlank(data[1]) ? data[1] : null;
  }

  validate(attrs: { [key: string]: any }, options: { [key: string]: any }): { [key: string]: any } {
    const errors: { [key: string]: any } = {};

    //Period validation
    const datedeb: any = {
      "name": "date_de",
      "value": attrs.date_de,
      "label": undefined
    };
    const datefin: any = {
      "name": "date_a",
      "value": attrs.date_a,
      "label": undefined,
      "isInfinity": false
    };
    const errorMsg = CWTYPE.DATE.validatePeriod(datedeb, datefin, options);
    if (!CWSTR.isBlank(errorMsg)) {
      errors["date_de"] = errorMsg.date_de;
      errors["date_a"] = errorMsg.date_a;
    }

    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      return errorMap;
    } else {
      return null;
    }
  }

  _changeView(event: JQueryEventObject): void {
    let targetClass = event.currentTarget.className.split(" ")[0];
    if ((targetClass === "select-all" || targetClass === "cwListBoxSelect") && event.currentTarget.className.split(" ")[1]) {
      targetClass = event.currentTarget.className.split(" ")[1];
    }
    const target = $(this.el).find("." + targetClass);
    const parent = target.attr("parent");
    const model = this._getModelByCritere(targetClass, parent);
    if (!CWSTR.isUndefined(model)) {
      const type = model.get("type");
      let lNewVal = null;
      let lNewDisplayText = null;
      let i = 0;
      let typeSel = "";
      const fournisseur = this.workflow.headerModel.get('value').get('fournisseur');
      if (fournisseur > 3 && fournisseur !== 4) {
        switch (type) {
          case "radio":
            lNewVal = this.$el.find('.' + targetClass + ':checked').val();
            lNewDisplayText = this.$el.find('.' + targetClass + ':checked').next('.phx-lanceetat-textRadio').text();
            model.set("value", lNewVal);
            model.set('displayText', lNewDisplayText);
            break;
          case "select":
            typeSel = model.get("selchoix");
            if (model.get("multivaleur")) {
              const listValues = this.$el.find('.' + targetClass + ' option:selected:visible');
              lNewVal = [];
              lNewDisplayText = [];
              if (listValues.length > 0) {
                for (let index = 0; index < listValues.length; index++) {
                  const el = listValues[index];
                  const elementClass = el.className.split(" ")[0];
                  if (elementClass !== "select-all") {
                    lNewVal.push(($(el).val()));
                    lNewDisplayText.push($(el).text());
                  }
                }
              }
            } else if (typeSel !== "SELREF") {
              lNewVal = (event.target as any)["data-code"];
              lNewDisplayText = (event.target as any).value;
            }
            if (typeSel !== "SELREF") {
              model.set('value', lNewVal);
              model.set('displayText', lNewDisplayText);
            }
            break;
          case "checkbox":
            lNewVal = this.$el.find('.' + targetClass + ' option:selected');
            model.set("value", lNewVal); //returns true or false
            break;
          default:
          //none
          //We don't update model for organisation type, because it is already updated when additemcallback is executed.
        }
      } else {
        switch (type) {
          case "radio":
            lNewVal = this.$el.find('.' + targetClass + ':checked').val();
            model.set("value", lNewVal);
            break;
          case "select":
            typeSel = model.get("selchoix");
            if (typeSel !== "SELREF") {
              lNewVal = (event.target as any)["data-code"];
              model.set("value", lNewVal);
            }
            break;
          case "checkbox":
            lNewVal = this.$el.find('.' + targetClass).is(':checked');
            model.set("value", lNewVal); //returns true or false
            break;
          default:
          //none
          //We don't update model for organisation type, because it is already updated when additemcallback is executed.
        }
      }
      switch (type) {
        case "date": {
          lNewVal = this.$el.find("." + targetClass).val() as string;
          model.set("value", CWTYPE.DATE.parse(lNewVal).val);
          if (targetClass === "date") {
            for (i = 0; i < this.referentielArray.length; i++) {
              this.referentielArray[i].coll.datedeb = CWTYPE.DATE.parse(lNewVal).val;
              this.referentielArray[i].coll.datefin = CWTYPE.DATE.parse(lNewVal).val;
            }
          } else if (targetClass === "date_de") {
            for (i = 0; i < this.referentielArray.length; i++) {
              this.referentielArray[i].coll.datedeb = CWTYPE.DATE.parse(lNewVal).val;
            }
          } else if (targetClass === "date_a") {
            for (i = 0; i < this.referentielArray.length; i++) {
              this.referentielArray[i].coll.datefin = CWTYPE.DATE.parse(lNewVal).val;
            }
          }
          break;
        }
        case "text": {
          lNewVal = this.$el.find("." + targetClass).val();
          if (!CWSTR.isBlank(model.get("tda")) && CWTYPE._getTypeByCode(model.get("tda"))) {
            lNewVal = CWTYPE._getTypeByCode(model.get("tda")).parse(lNewVal).val;
          }
          model.set("value", lNewVal);
          break;
        }
        case "textarea": {
          lNewVal = this.$el.find("." + targetClass).val();
          model.set("value", lNewVal);
          break;
        }
        case "heure": { //needs parse
          lNewVal = this.$el.find("." + targetClass).val();
          if (!CWSTR.isBlank(model.get("tda"))) {
            lNewVal = CWTYPE._getTypeByCode(model.get("tda")).parse(lNewVal).val;
          }
          model.set("value", lNewVal);
          break;
        }
        case "increment": {
          lNewVal = this.$el.find("." + targetClass).val();
          model.set("value", lNewVal);
          break;
        }
        default:
        //none
        //We don't update model for organisation type, because it is already updated when additemcallback is executed.
      }

      //hide when the selected element has criteres and has no parent
      this._checkHideSouscriteres(model, parent);

      const lFuncCallback = (): void => {
        if (model.get('cascade')) {
          this._manageCascade(model);
        } else {
          this._manageSouscriteres(model);
        }
        this._change(event, model, false);
      };

      //S'il y a "dependance", on doit appeler à WS Reinit
      if (model.get("dependance") === true) {
        /*Pour les critères de type « checkbox », la valeur sera :
          -	« »  (vide) : case non cochée
          -	« checked » : case cochée
          Pour les autres types, affecter la valeur.
        */
        if (type === "checkbox") {
          if (lNewVal === true) {
            lNewVal = "checked";
          } else {
            lNewVal = "";
          }
        }
        this.reinitModel(event, lNewVal, lFuncCallback);
      } else {
        lFuncCallback();
      }
    }
  }

  _manageSouscriteres(model: { [key: string]: any }): void {
    const element = model.get("souscriteres");
    if (element.length > 0) {
      for (let i = 0; i < element.length; i++) {
        element.at(i).set("visible", false);
        if (element.at(i).get("parent") === model.get("code") && (element.at(i).get("valeurparent") === model.get("value")) ||
          (element.at(i).get("parent") === model.get("code") && (CWSTR.isBlank(element.at(i).get("valeurparent")) ||
            ((element.at(i).get("valeurparent") === "x" || element.at(i).get("valeurparent") === "checked") && model.get("value") === true))) ||
          (element.at(i).get("parent") === model.get("code") && model.get("type") === "radio" && element.at(i).get("valeurparent") === "checked")) {
          this._manageFields(element.at(i));
          if (element.at(i).get("souscriteres").length > 0) {
            this._manageSouscriteres(element.at(i));
          }
        }
      }
    }
    this.workflow.trigger("end:ManageSouscriteurs");
  }

  _manageCascade(model: { [key: string]: any }, emptyField?: boolean): void {
    const value = model.get('value');
    emptyField = !CWSTR.isUndefined(emptyField) ? emptyField : (CWSTR.isNull(value) || value === "") ? true : false;
    const modelIndex = parseInt(model.get('index'));
    const listeModels = this.coll.where({
      grpName: model.get('grpName'),
      cascade: true,
    });
    if (!CWSTR.isUndefined(listeModels)) {
      const valeurs = [];
      for (let index = 0; index < listeModels.length; index++) {
        if (listeModels[index].get('index') > modelIndex) {
          listeModels[index].set('urlvaleur', valeurs)

          // Afficher/masquer les champs
          if (listeModels[index].get('index') !== modelIndex + 1 || (emptyField && listeModels[index].get('index') !== modelIndex)) {
            $('.div_' + listeModels[index].get('id')).hide();
          } else {
            this._manageFields(listeModels[index], index);
            $('.div_' + listeModels[index].get('id')).show();
          }
        } else {
          valeurs[listeModels[index].get('index')] = listeModels[index].get('value');
        }
      }
    }
  }

  render(): CWFormView {
    const json = { 'i18n': i18n };
    this.$el.html(this.template(json));
    return this;
  }

  _isValidCollection(callback: (arg: any) => void): void {
    const collection = this.coll;
    const errors = {};
    let errorMap;
    const errorRange: any = [];

    this._cleanValidationErrors();
    this.isValidCollection = true;
    this._validateCollection(collection, errors, errorMap, errorRange);
    if (this.isValidCollection === true) {
      const lancerEtatModel = new CWLancerEtatModel();

      this._buildURL(collection, lancerEtatModel);
      if (callback) {
        callback(lancerEtatModel);
      }
    }
  }

  _validateCollection(collection: { [key: string]: any }, errors: { [key: string]: any }, errorMap: { [key: string]: any }, errorRange: any): void {
    /*Redmine 008-Codage et TU #12369
      [ANO] Affichage du message fourni dans une popup avec en titre « Action impossible » et autres actions.
    L'IHM ne doit faire aucun contrôle, c'est le métier qui les fait. Il faut donc supprimer les contrôles sur :
      >   - les dates constituant une période
      >   - les bornes des matricules quand on choisit une population "par matricule"
      >   - les bornes des noms quand on choisit une population "par nom"
    */

    for (let i = 0; i < collection.length; i++) {
      const element = collection.at(i);

      //visible and valid model
      if (element.get("visible") === true) {
        if (element.get("obligatoire") === true && CWSTR.isBlank(element.get("value"))) {
          errors[element.get("code")] = {};
          errors[element.get("code")] = i18n.t('common:required', { "0": element.get("libelle") });
        } else if (element.get("code").indexOf("_") >= 0) {
          const lCad = element.get("code").split("_");
          if (lCad.length === 2) {
            const lVal: any = { value: null, libelle: "" };

            lVal.value = element.get("value");
            lVal.libelle = !CWSTR.isBlank(element.get("libelle")) ? element.get("libelle") : "";
            if (_.keys(errorRange).length === 0 || _.keys(errorRange[lCad[0]]).length === 0) {
              const lRange = [];

              lRange[lCad[1]] = lVal;
              errorRange[lCad[0]] = lRange;
            } else {
              errorRange[lCad[0]][lCad[1]] = lVal;
            }
          }
        }
        if (!CWSTR.isBlank(element.get("souscriteres")) && element.get("souscriteres").length > 0) {
          this._validateCollection(element.get("souscriteres"), errors, errorMap, errorRange);
        }
      }
    }
    if (_.keys(errorRange).length > 0) {
      _.each(_.keys(errorRange), (item: any): void => {
        const lVal = _.keys(errorRange[item]);
        if (lVal.length === 2) {
          const lErr1 = errors[item + "_" + lVal[0]];
          const lErr2 = errors[item + "_" + lVal[1]];

          //il n'existe pas avant comme une erreur
          if (CWSTR.isBlank(lErr1) && CWSTR.isBlank(lErr2)) {
            const lCont1 = errorRange[item][lVal[0]];
            const lCont2 = errorRange[item][lVal[1]];

            //Dans ce point, ce n'est pas necessaire faire la verification de s'il a de valeur et le type (numéro o texte) non plus:
            //On doit permettre par exemple "2">"a"-> false->ok, "2"<"a"-> true->ok, "2"<"3"->true->ok , "a">"A"->true ->ok, "a"<"A"->true ->ok
            if (parseInt(lCont1.value, 10) > parseInt(lCont2.value, 10)) {
              errors[item + "_" + lVal[1]] = i18n.t('lanceetat.valeurSupEq', { "0": lCont1.libelle });
            }
          }
        }
      }, this);

    }
    if (!_.isEmpty(errors)) {
      const errorMap = {
        errorValidation: CWSTR.formatMessage(errors),
        errors: errors
      };
      const lModel: CWBaseModel = null; //il n'est pas nécessaire pour cettes erreurs

      this.isValidCollection = false;
      this._showValidationErrors(lModel, errorMap);
    }
  }

  _buildURL(collection: any, model: { [key: string]: any }): void {
    for (let i = 0; i < collection.length; i++) {
      const element = collection.at(i);
      if (element.get("visible") === true) {
        const code = element.get("code");
        let value = element.get("value");
        if (!CWSTR.isUndefined(element.get('displayText')) && !CWSTR.isBlank(element.get('displayText'))) {
          const displayText = element.get('displayText');
          value = {
            value: value,
            displayText: displayText
          }
        }
        model.set(code, value);
      }

      if (element.get("souscriteres").length > 0) {
        this._buildURL(element.get("souscriteres"), model);
      }
    }
  }

  reinitModel(aEvent: any, aNewval: any, aCallback: (arg?: any) => void, openedFromPlanning?: any): void {
    if ((aEvent || openedFromPlanning) && !CWSTR.isBlank(aNewval) && this.workflow && this.workflow.tableModel &&
      this.workflow.tableModel.get("value") && this.workflow.tableModel.get("value").id) {
      const lId = this.workflow.tableModel.get("value").get("code");
      let targetClass;
      if (openedFromPlanning) {
        targetClass = "critid";
      } else {
        targetClass = aEvent.currentTarget.className.split(" ")[0];
      }
      const target = $(this.el).find("." + targetClass);
      const parent = target.attr("parent");
      const model = this._getModelByCritere(targetClass, parent);
      const lReinit = new CWCriteresReinitModel();
      const lHab = new CWHabilitationContext({
        onglet: this.workflow.module,
        foncCour: "RES_RECAP.E",
        natGest: ""
      });

      lReinit.setHabContext(lHab);
      lReinit.newValeur = aNewval;
      lReinit.id = lId;
      lReinit.code = model.get("code");
      lReinit.fetch({
        success: (fresh: any): void => {
          if (!_.isEmpty(fresh)) {
            //je parcours tous les attributs
            const lTokens = _.keys(fresh.attributes);
            const lLen = lTokens.length;

            for (let i = 0; i < lLen; i++) {
              this._changeValeurType(aEvent, lTokens[i], fresh.get(lTokens[i]));
              const lfTarget = $(this.el).find("." + lTokens[i]);
              if (lfTarget.length > 0) {
                const lfParent = lfTarget.attr("parent");
                const lfModel = this._getModelByCritere(lTokens[i], lfParent);
                if (lfModel) {
                  this._manageSouscriteres(lfModel);
                }
              }
            }
          }
          if (aCallback) {
            aCallback();
          }
        },
        error: (): void => {
          if (aCallback) {
            aCallback();
          }
        }
      });
    } else {
      if (aCallback) {
        aCallback();
      }
    }
  }

  _changeValeurType(aEvent: JQueryEventObject, aTargetClass: any, aNewVal: any): void {
    const target = $(this.el).find("." + aTargetClass);
    const parent = target.attr("parent");
    const model = this._getModelByCritere(aTargetClass, parent);
    if (model) {
      const type = model.get("type");
      let lVal = null;
      let lLabel = null;

      switch (type) {
        case "radio": {
          lVal = this.$el.find('.' + aTargetClass + ':checked').val();
          if (lVal !== aNewVal) {
            this.$el.find('.' + aTargetClass + "[value='" + aNewVal + "']").prop("checked", true)
            model.set("value", aNewVal);
          }
          this._resizeFields(aTargetClass);
          break;
        }
        case "select": {
          lVal = (aEvent.target as any)["data-code"];
          if (lVal !== aNewVal) {
            (aEvent.target as any)["data-code"] = aNewVal;
            model.set("value", aNewVal);
          }
          this._resizeFields(aTargetClass);
          break;
        }
        case "checkbox": {
          lVal = this.$el.find('.' + aTargetClass).is(':checked');
          if (lVal !== aNewVal) {
            if (aNewVal === true || aNewVal === "checked" || aNewVal === "x") {
              this.$el.find('.' + aTargetClass).prop("checked", true);
              model.set("value", true);
            } else {
              this.$el.find('.' + aTargetClass).prop("checked", false);
              model.set("value", false);
            }
          }
          this._resizeFields(aTargetClass);
          break;
        }
        case "date": {
          lVal = this.$el.find("." + aTargetClass).val();
          if (lVal !== aNewVal) {
            this.$el.find("." + aTargetClass).val(aNewVal)
            model.set("value", CWTYPE.DATE.parse(aNewVal).val);
          }
          this._resizeFields(aTargetClass);
          break;
        }
        case "text": {
          lVal = this.$el.find("." + aTargetClass).val();
          if (lVal !== aNewVal) {
            this.$el.find("." + aTargetClass).val(aNewVal);
            if (!CWSTR.isBlank(model.get("tda"))) {
              model.set("value", CWTYPE._getTypeByCode(model.get("tda")).parse(aNewVal).val);
            } else {
              model.set("value", aNewVal);
            }
          }
          this._resizeFields(aTargetClass);
          break;
        }
        case "textarea": {
          lVal = this.$el.find("." + aTargetClass).val();
          if (lVal !== aNewVal) {
            this.$el.find("." + aTargetClass).val(aNewVal);
            model.set("value", aNewVal);
          }
          this._resizeFields(aTargetClass);
          break;
        }
        case "increment": {
          lVal = this.$el.find("." + aTargetClass).val();
          if (lVal !== aNewVal) {
            this.$el.find("." + aTargetClass).val(aNewVal);
            model.set("value", aNewVal);
          }
          this._resizeFields(aTargetClass);
          break;
        }
        case "libelle": {
          lLabel = $(this.el).find(".div_" + aTargetClass + " label[for='" + aTargetClass + "']");
          lVal = lLabel.html()
          if (lVal !== aNewVal) {
            lLabel.html(aNewVal);
            model.set("value", aNewVal);
          }
          if (CWSTR.isBlank(aNewVal)) {
            lLabel.hide();
          } else {
            lLabel.show();
            this._resizeFields(aTargetClass);
          }
          break;
        }
        case "titre": {
          lLabel = $(this.el).find(".div_" + aTargetClass + " label[for='" + aTargetClass + "']");
          lVal = lLabel.html()
          if (lVal !== aNewVal) {
            lLabel.html(aNewVal);
            model.set("value", aNewVal);
          }
          this._resizeFields(aTargetClass);
          break;
        }
        case "info": {
          const parInfo = $(this.el).find(".div_" + aTargetClass + " p[data-info='" + aTargetClass + "']");
          lVal = parInfo.html()
          if (lVal !== aNewVal) {
            parInfo.html(aNewVal);
            model.set("value", aNewVal);
          }
          if (CWSTR.isBlank(aNewVal)) {
            parInfo.hide();
          } else {
            parInfo.show();
          }
          break;
        }
        //We don't update model for organisation type, because it is already updated when additemcallback is executed.
        default:
        //none
      }
      //hide when the selected element has criteres and has no parent
      this._checkHideSouscriteres(model, parent);
    }
  }

  _prepareSubGroupes(model: { [key: string]: any }): void {
    const element = model.get("souscriteres");

    if (element.length > 0) {
      for (let i = 0; i < element.length; i++) {
        if (model.get("groupe") !== element.at(i).get("groupe")) {
          element.at(i).set("groupe", model.get("groupe"));
        }
        if (element.at(i).get("souscriteres").length > 0) {
          this._prepareSubGroupes(element.at(i));
        }
      }
    }
  }

  _prepareGroupes(aModel: { [key: string]: any }): void {
    const value = (aModel && !CWSTR.isBlank(aModel.get("value"))) ? aModel.get("value") : null;

    if (value) {
      _.each(value.models, (model: { [key: string]: any }): void => {
        if (model) {
          this._prepareSubGroupes(model);
        }
      }, this);
    }
  }

  _checkHideSouscriteres(model: { [key: string]: any }, parent: string): void {
    //hide when the selected element has criteres and has no parent
    if (model && CWSTR.isBlank(parent) && model.get("souscriteres") && model.get("souscriteres").length > 0) {
      let lsFind = "";
      let lDiv = null;

      if (this._numberSubgroup(model.get("groupe")) <= 1) {
        lsFind = ".phx-lanceetat-detail-content-groupe" + model.get("groupe") + "-column2"; //+l_colonne;
      } else {
        let lSub = this._orderCodeSubgroup(model.get("groupe"), model.get("code"));

        if (lSub < 1) {
          //parent
          lSub = this._orderCodeSubgroup(model.get("groupe"), model.get("parent"));
        }
        lsFind = ".phx-lanceetat-detail-content-groupe" + model.get("groupe") + "-subgroupe" + lSub + "-column2"; //+l_colonne;
      }
      lDiv = $(this.el).find(lsFind);
      if (lDiv.length > 0) {

        lDiv.empty();

        //  quelquefois le titre 'Voir les totalisations individuelles :' ne s'effacait pas
        if ($(this.el).find(".div_voirtot2").is(':empty') === false) {
          lDiv.parent().parent().find(".div_voirtot2").empty();
        }
      }
    }
  }

  empty(isEmpty: boolean): void {
    if (isEmpty) {
      this.$el.find(".unselectedElementText").show();
      this.$el.find(".phx-lanceetat-detail-content").hide();
      this.workflow.btnBarModel.trigger("disable:export");
      this.workflow.btnBarModel.trigger("disable:print");
    } else {
      this.$el.find(".unselectedElementText").hide();
      this.$el.find(".phx-lanceetat-detail-content").show();
      this._checkButtons();
    }
  }
}
