import _ from 'underscore';
import { CWBaseGridCollection } from 'src/core/grids/basegrid/cwBaseGrid.collection';
import { CWSoldesAbsencesModel } from './cwSoldesAbsences.model';



export class CWSoldesAbsencesColl extends CWBaseGridCollection<CWSoldesAbsencesModel> {

  id: string;
  matricule: string | number;
  motif: CWSoldesAbsencesModel;
  /**
   * Constructor - Collection for Composant 'Soldes d’absences en D/P/S'
   */
  constructor(models?: CWSoldesAbsencesModel[] | { [key: string]: any }[], options?: { [key: string]: any }) {
    if (!options && !_.isEmpty(models)) {
      options = models;
    }
    options = options || {};
    options.model = options.model || CWSoldesAbsencesModel;
    super(models, options);
    this.id = options.id;
    this.url = (): string => {
      return Configuration.restRoot + "/rest/soldeabs/" + encodeURIComponent(this.id);
    }
  }
}