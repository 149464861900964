import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLBsitregBriqueSituationsARegulariser from '../briqueSituationsARegulariser.tpl.html';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWHabilitationContext } from 'core/models/cwHabilitationContext';
import { CWSituationsARegulariserModel } from '../models/cwSituationsARegulariser.model';
import { CWTYPE } from 'tda/cwTda';
import { i18n } from 'src/i18n.js';
import { NAV } from 'src/utils/nav.js';
import { STYLE } from 'src/utils/style.js';
import { UTILS } from 'utils/utils.js';

export class CWSituationsARegulariserView extends Backbone.View<Backbone.Model> {

  usePopulation: boolean;
  template: (params: any) => string;
  parent: any;
  anomaliesALeverColl: CWSituationsARegulariserModel;
  nbAnoB: number; // Nombre d’anomalies bloquante
  plusAncienneAnoBMatric: number; // Matricule du collaborateur ayant la plus ancienne anomalie bloquante
  plusAncienneAnoBDate: string; // Date de la plus ancienne anomalie bloquante
  nbAnoPNV: number; // Nombre d’anomalies persistantes non visées
  plusAncienneAnoPNVMatric: number; // Entier long	Matricule du collaborateur ayant la plus ancienne anomalie persistante non visée
  plusAncienneAnoPNVDate: string; // Date de la plus ancienne anomalie persistante non visée
  private flagRepaintBrique: boolean; //pour répeindre ou pas, sans faire une action de "fetch" (une pétiton de Ws)

  constructor(options?: Backbone.ViewOptions<Backbone.Model> | any) {
    options.events = _.extend({
      "click .cw-bsitregPers_link": "_linkToValidation",
      "click .cw-bsitregBloq_link": "_linkToValidation",
      "click .bsitreg_link": "_linkToValidation"
    });
    super(options);
    this.flagRepaintBrique = false;
    this.usePopulation = false;
    if (options && options.usePopulation) {
      this.usePopulation = options.usePopulation;
    }
    this.template = TPLBsitregBriqueSituationsARegulariser;
    this.parent = null;
    this.anomaliesALeverColl = new CWSituationsARegulariserModel();
    this.anomaliesALeverColl.usePopulation = this.usePopulation;
    this.anomaliesALeverColl.setHabContext(new CWHabilitationContext({
      onglet: "bsitreg",
      foncCour: "RES_LEVANO.V"
    }));

    if (options && options.parent) {
      this.parent = options.parent;
    }
    //this.listenTo(this,"repaintBriqueNotFetch", this._updateNbAnoSize);
  }

  render(): CWSituationsARegulariserView {
    const json = { 'i18n': i18n };
    this.$el.html(this.template(json));
    this.$el.find(".table_BriqueSituationsARegulariser").html(i18n.t('common:bsitreg.noSituationsARegulariserAffiche'));
    return this;
  }

  checkHabilation(): boolean {
    return CWHABILITATION.canView("RES_LEVANO.V");
  }

  refreshBrique(): CWSituationsARegulariserView {

    if (CWHABILITATION.canView("RES_LEVANO.V")) {
      this.nbAnoB = 0;
      this.nbAnoPNV = 0;
      this.plusAncienneAnoBDate = "";
      this.plusAncienneAnoPNVDate = "";
      this.plusAncienneAnoBMatric = 0;
      this.plusAncienneAnoPNVMatric = 0;
      this.anomaliesALeverColl.fetch({
        success: (fresh: any) => {
          if (fresh.get("nbanob") > 0 || fresh.get("nbanopnv") > 0) {
            this.nbAnoB = fresh.get("nbanob");
            this.nbAnoPNV = fresh.get("nbanopnv");
            this.plusAncienneAnoBDate = fresh.get("datemin");
            this.$el.find(".table_BriqueSituationsARegulariser").html(this._initGrid());
            this.$el.find(".table_BriqueSituationsARegulariser").removeClass('cw-aucunSit');
          } else {
            this.$el.find(".table_BriqueSituationsARegulariser").html(i18n.t('common:bsitreg.noSituationsARegulariserAffiche'));
            this.$el.find(".table_BriqueSituationsARegulariser").addClass('cw-aucunSit');

          }
          this._updateNbAnoSize();
        }
      });

    }
    this.delegateEvents();
    return this;
  }

  _initGrid(): any {
    const $anomaliesALever = $("<div>");
    //const anomalies = this.anomaliesALeverColl;
    const $grid = $("<div class='cw-gridSituations'>");
    // for (let i = 0; i < anomalies.length; i++) {
    //   switch (anomalies[i].get("typeano")) {
    //     case "B":
    //       if (anomalies[i].get("dateano") < this.plusAncienneAnoBDate) {
    //         this.plusAncienneAnoBDate = anomalies[i].get("dateano");
    //         this.plusAncienneAnoBMatric = anomalies[i].get("matricule");
    //       }
    //       this.nbAnoB++;
    //       break;
    //     case "P":
    //       if (anomalies[i].get("dateano") < this.plusAncienneAnoPNVDate) {
    //         this.plusAncienneAnoPNVDate = anomalies[i].get("dateano");
    //         this.plusAncienneAnoPNVMatric = anomalies[i].get("matricule");
    //       }
    //       this.nbAnoPNV++;
    //       break;
    //     default:
    //       break;
    //   }
    // }
    const tblColumns = [
      { property: "typeAnomalie" },
      { property: "quantite" },
      { property: "libelle" }
    ];
    const typeAnomalies = [
      { typeAnomalie: "B", quantite: this.nbAnoB, class: "cw-bsitregBloq_link", dataUrl: "anomalie/B" },
      { typeAnomalie: "P", quantite: this.nbAnoPNV, class: "cw-bsitregPers_link", dataUrl: "anomalie/P" }
    ];

    const format: { [key: string]: any } = {};
    format["typeAnomalie"] = (model: any): any => {
      const $typeAnomalie = $("<span class='cw-sitIcon'>")
      const avertissementIcon = UTILS.getSVGIcon("avertissement", "", 24);
      let className = "";
      if (model.typeAnomalie === "B" || model.typeAnomalie === "P") {
        className = STYLE.getClassColorFromAnomalie(model.typeAnomalie);
        $typeAnomalie.addClass(className);
        $typeAnomalie.append(avertissementIcon);
      }
      return $typeAnomalie;
    };

    format["quantite"] = (model: any): any => {
      return $("<span class='cw-sitQuantite cw-texteImportant'>").append(model.quantite);
    };

    format["libelle"] = (model: any): any => {
      return $("<span class='cw-sitLib cw-texteAllege'>").append(this._getTitleFromAnomalie(model.typeAnomalie));
    };

    for (let i = 0; i < typeAnomalies.length; i++) {
      if (typeAnomalies[i].quantite > 0) {
        const $row = $("<div class='row no-gutters'>");
        $row.addClass(typeAnomalies[i].class);
        $row.attr('data-url', typeAnomalies[i].dataUrl);
        for (let j = 0; j < tblColumns.length; j++) {
          $row.append(format[tblColumns[j].property](typeAnomalies[i]));
        }
        $grid.append($row);
      }
    }

    // Creation du button
    const avertissementIcon = UTILS.getSVGIcon("avertissement", "", 24);
    const $buttonRegulariser = $("<div class='btn btn-secondary bsitreg_link cw-btn__sitReg'>");
    const $texteButton = $("<span>").addClass('btnResult mr-1');
    $texteButton.append(i18n.t('common:bsitreg.situationsARegulariser_button'));
    $texteButton.append(CWTYPE.DATE.format(this.plusAncienneAnoBDate, CWTYPE._getFormatByCode("DATE_A")));
    $buttonRegulariser.attr('data-url', "anomalie/B,P");

    const $iconButton = $("<span>").addClass('ml-1');
    $iconButton.append(avertissementIcon);
    $buttonRegulariser.append($texteButton);
    $buttonRegulariser.append($iconButton);

    $anomaliesALever.append($grid);
    $anomaliesALever.append($buttonRegulariser);

    return $anomaliesALever;
  }

  /**
   * Gets type of anomalie depending on Type of anomalie
   */
  _getTitleFromAnomalie(anocode: string): string {
    let title = "";
    switch (anocode) {
      case "B":
        title = i18n.t('common:bsitreg.bloqueante');
        break;
      case "P":
        title = i18n.t('common:bsitreg.persistante');
        break;
      default:
    }
    return title;
  }

  /**
   * Opens vue journee panel at anomalie screen
   */
  _linkToValidation(event: any): void {
    const target = event.currentTarget;
    const ecran = $(target).attr("data-url");

    NAV.navigate("resp", ecran, "uc", true, true);
  }

  /** Récupère la largeur max du span du nombre d'anomalie
   * pour l'affecter à tous
   * Utilisable uniquement si le rendu est terminé
   */
  _updateNbAnoSize(): void {

    let widthQuant = 0;
    let widthQuantTmp = 0;

    const listsitQuant = this.$el.find(".cw-sitQuantite");

    if (!$(".cw-sitLib").attr("style")) { // uniquement si la marge n'a jamais été modifiée.

      for (let i = 0; i < listsitQuant.length; i++) {
        $(listsitQuant[i]).css("width", "");//1er. lieu, remove la propriété par s'il déjà a de valeur.
        widthQuantTmp = $(listsitQuant[i]).width();

        if (widthQuant < widthQuantTmp) {
          widthQuant = widthQuantTmp;
        }
      }
      $(".cw-sitQuantite").width(widthQuant.toString() + "px");
    }
    if (widthQuant === 0) {
      this.flagRepaintBrique = true;
    } else {
      this.flagRepaintBrique = false;
    }
  }

  repaintBriqueNotFetch(): void {
    if (this.flagRepaintBrique) {
      this._updateNbAnoSize();
    }
  }
}
