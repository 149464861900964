import * as Backbone from 'Backbone';
import _ from 'underscore';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWFORMS } from 'utils/cwForms';
import { CWSTR } from 'utils/cwStr';
import { CWTree2NodeView } from './cwTree2Node.view';
import { CWTreeModel } from './cwTree.model';
import { TREE } from 'utils/tree.js';

interface CWTreeViewOption extends Backbone.ViewOptions {
  checkedColl?: any;
  readOnly?: boolean;
  isCheckedCallback?: () => boolean;
  hieractivitidad?: any;
  multiselect?: boolean;
  hideCheck?: boolean;
  showSelected?: any;
  coll?: any;
  name?: any;
  draggable?: any;
  rootModel?: any;
  manyRoots?: any;
  firstLevelModels?: any;
  buildUrlColl?: any;
  dragAndDropCallback?: () => void;
  checkClass?: (model: CWBaseModel) => string;
  renderer?: (item: CWBaseModel) => string;
  selectableNode?: (model: CWBaseModel) => boolean;
  tooltipRenderer?: (model: CWBaseModel, view: { [key: string]: any }) => void;
  overLabelTooltipRenderer?: (model: { [key: string]: any }, view: { [key: string]: any }, callback2: (context: any) => void) => void;
  checkPeriode?: (model: CWBaseModel, view: { [key: string]: any }) => void;
  expandedNodes?: boolean;
  context?: { [key: string]: any };
  view?: number;
}

export class CWTree2View extends Backbone.View {
  sortTree: any;
  multiselect: boolean;
  readOnly: boolean;
  isCheckedCallback: any;
  hieractivitidad: any;
  hideCheck: boolean;
  root: CWTree2NodeView;
  strNiveau: number;
  model: CWTreeModel;
  opennode: any;
  context: { [key: string]: any };
  view: number;
  expandedNodes: boolean;
  filter: boolean;
  structSimpleFetchNodeOrFirst: boolean;
  hierarchieFetchNodeOrFirst: boolean;

  /**
   * View of the Tree Component
   */
  constructor(params?: CWTreeViewOption) {
    params = params || {} as CWTreeViewOption;
    super(params);
    /**
     * Model of the Tree
     */
    this.model = new CWTreeModel(params);
    /**
     * Sort Function of the tree
     */
    this.sortTree = undefined;
    //EVO 186: Multiselection mode, to show the checkboxes
    this.multiselect = false;
    if (!CWSTR.isBlank(params.multiselect)) {
      this.multiselect = params.multiselect;
    }
    if (this.multiselect) {
      if (params.checkedColl) {
        if (params.checkedColl instanceof Backbone.Collection) {
          this.model.checkedColl = params.checkedColl;
        } else {
          throw Error("checkedColl parm must be a Backbone.Collection instance");
        }
      } else {
        this.model.checkedColl = new Backbone.Collection();
      }
    }
    this.readOnly = false;
    if (params && params.readOnly) {
      this.readOnly = params.readOnly;
    }
    if (params && params.isCheckedCallback) {
      this.isCheckedCallback = params.isCheckedCallback;
    }
    if (params && params.hieractivitidad) {
      this.hieractivitidad = params.hieractivitidad;
    }
    if (params && params.view) {
      this.view = params.view;
    }
    if (params && params.expandedNodes) {
      this.expandedNodes = params.expandedNodes;
    }
    this.hideCheck = false;
    if (!CWSTR.isBlank(params.hideCheck)) {
      this.hideCheck = params.hideCheck
    }
    /**
     * Root Node
     */
    this.root = undefined;
    this.strNiveau = 0;
    this.context = (params && !_.isEmpty(params.context)) ? params.context : null;
  }

  expandAllNodes(calback?: () => void): void {
    this.root._expandAllNodes(undefined, calback);
  }

  collapseAllNodes(): void {
    this.root._collapseAllNodes();
  }

  setReadOnly(readOnly: boolean): void {
    this.readOnly = readOnly;
    CWFORMS.setFormReadonly(this.$el, readOnly, true);
  }

  /**
   * Clear all selected rows.
   */
  clearCheckedRows(): void {
    this.model.checkedColl.reset(null);
    this.model.coll.forEach((model: { [key: string]: any }) => {
      model.trigger("row:unchecked");
    });
  }

  /**
   * Gets all rows marked for selection if in multiselection, otherwise return the active row.
   */
  getCheckedRows(): { [key: string]: any } {
    if (this.multiselect === true) {
      return this.model.checkedColl;
    } else {
      return this.getCurrentRow();
    }
  }

  /**
   * Gets all rows marked for selection if in multiselection, otherwise return the active row.
   */
  setCheckedRows(selectedModelsColls: { [key: string]: any }): void {
    this.model.checkedColl = selectedModelsColls;
  }

  /**
   * Get active row (row selected but not marked for selection.
   */
  getCurrentRow(): { [key: string]: any } {
    return this.model.get("value");
  }

  /**
   * Set the collection for the tree data
   */
  setColl(coll: { [key: string]: any }): void {
    this.model.coll = coll;
  }

  /**
   * Set the sortTree function for tree sorting
   */
  setSortFunction(func: (a: { [key: string]: any }, b: { [key: string]: any }) => number): void {
    this.sortTree = func;
  }

  /**
   * Set the renderer function to render the tree nodes
   */
  setRenderer(renderer?: (item: CWBaseModel) => string): void {
    if (!CWSTR.isBlank(renderer)) {
      this.model.renderer = renderer;
    }
  }

  /**
   * Render the View
   */
  render(): this {
    const showSelected = this.model.get("showSelected") || false;

    this.$el.empty();
    if (!CWSTR.isBlank(this.model.manyRoots) && this.model.manyRoots === true) { //There are many roots for this Node
      this.root = new CWTree2NodeView({
        root: this.model,
        level: 0,
        showSelected: showSelected,
        firstLevelNode: true,
        multiselect: this.multiselect,
        parent: this,
        isCheckedCallback: this.isCheckedCallback,
        hideCheck: this.hideCheck,
        readOnly: this.readOnly,
        hieractivitidad: this.hieractivitidad,
        context: this.context,
        expandedNodes: this.expandedNodes,
        view: this.view
      });
      this.root.setSortFunction(this.sortTree);
      this.$el.html(this.root.render().el);
      $(this.root.el).find(".cw-treenode-icon").hide(); //Hide fictitious root
      $(this.root.el).find(".cw-treenode-leaf-icon").hide();
      $(this.root.el).find(".cw-treenode-wrap").hide();
    } else { //There is only one root for the node
      this.root = new CWTree2NodeView({
        root: this.model,
        level: 0,
        showSelected: showSelected,
        multiselect: this.multiselect,
        parent: this,
        hideCheck: this.hideCheck,
        readOnly: this.readOnly,
        context: this.context,
        expandedNodes: this.expandedNodes
      });
      this.root.setSortFunction(this.sortTree);
      this.$el.html(this.root.render().el);
    }
    return this;
  }

  /**
   * Remove the View
   */
  remove(): Backbone.View {
    let res = null;

    this.root.remove();
    res = super.remove(); // Remove view from DOM
    delete this.$el; // Delete the jQuery wrapped object letiable
    delete this.el; // Delete the letiable reference to this node
    return res;
  }

  /**
   * Select the first node available. It opens all structures to reach to it.
   * callback - Claabck function to be executed to know if an element has been selected or not
   */
  _selectFirstNode(callback?: (found: boolean) => void): void {
    if (CWSTR.isBlank(this.opennode) && !CWSTR.isBlank(this.root.opennode)) {
      this.opennode = this.root.opennode;
    }
    this._selectFirstNodeRecurs(this.root.sonsColl, callback);
  }

  /**
   * If the node is a structure, then open it and start again with its childrens.
   */
  _selectFirstNodeRecurs(nodeViews: { [key: string]: any }, callback?: (expand?: boolean) => void): void {
    let expanded = false;
    let cont = 0;

    _.each(nodeViews, (view: { [key: string]: any }) => {
      const model = view.model.node;

      if (!CWSTR.isBlank(view.model.coll.filter) && Object.keys(view.model.coll.filter).length === 0) {
        this.opennode = false;
      }
      if (this.opennode === true) {
        expanded = false;
      }
      if (this.filter === true && cont === 0) {
        view.filter = true;
      }
      if (model.get("typelt") === "A" && expanded === false && this.opennode === false) {
        model.trigger("selectNode");
        expanded = true;
      } else if ((model.get("typelt") === "S" || model.get("code") === " " || model.get("typelt") === "F" || this.opennode === true) && expanded === false) {
        expanded = true;
        model.trigger("expandNode", null, (coll: { [key: string]: any }, sonsColl: { [key: string]: any }) => {
          if ((model.get("typelt") === "S")) {
            this.strNiveau += 1;
          }
          // we deploy nodes until level of filter
          _.each(sonsColl, (son: { [key: string]: any }) => {
            _.each(son.model.coll.filter, (view: { [key: string]: any }, index: string) => {
              if (son.model.node.collection.niveau - this.strNiveau === parseInt(index) && (!CWSTR.isBlank(view.code) || !CWSTR.isBlank(view.libelle))) {
                this.opennode = false;
              }
            });
          });
          // filter selection
          this._selectFirstNodeRecurs(sonsColl);
        });
      }
      cont++;
    });
    if (expanded === false && nodeViews.length > 0 && !CWSTR.isBlank(nodeViews.model)) {
      nodeViews.model.node.trigger("selectNode");
      //collection.at(0).trigger("selectNode");
      if (callback) {
        callback(true);
      }
    } else {
      if (callback) {
        callback(expanded); //Informs if element has been selected or not.
      }
    }
  }

  findElement(parentNode: { [key: string]: any }, elementCode: string, datedeb: string, recursive: boolean, callback?: (childNode: { [key: string]: any }, treatedArray?: any[]) => void, treatedArray?: Array<any>): void {
    treatedArray = !CWSTR.isBlank(treatedArray) ? treatedArray : [];
    _.each(parentNode.sonsColl, (childNode: { [key: string]: any }) => {
      treatedArray.push(childNode);
      // recursive navigation thru all the nodes
      if (childNode.model.get("expanded") && recursive) {
        this.findElement(childNode, elementCode, datedeb, recursive, callback, treatedArray);
      }
      // if node == elementCode then we call the callback function for further processing
      if (childNode.model.node.get("code") === elementCode) {
        if (datedeb) {
          if (childNode.model.node.get("datedeb") === datedeb) {
            callback(childNode, treatedArray);
          }
        } else {
          callback(childNode, treatedArray);
        }
      }

    }, this);
  }

  /**
   * The method seach for an element and returns if this element is found or not.
   */
  findElementWithResponse(parentNode: { [key: string]: any }, elementCode: string, datedeb: string, recursive: boolean, callback: (found: boolean, foundNode: { [key: string]: any }) => void): void {
    let found = false;
    let foundNode = undefined;
    let seachingInsideChildren = false;
    let size = 0;

    if (parentNode.model.node.get("code") === elementCode) {
      if (datedeb) {
        if (parentNode.model.node.get("datedeb") === datedeb) {
          found = true;
          foundNode = parentNode;
        }
      } else {
        found = true;
        foundNode = parentNode;
      }
    }
    size = parentNode.sonsColl.length;
    for (let i = 0; i < size && found === false; i++) {
      const childNode = parentNode.sonsColl[i];

      // recursive navigation thru all the nodes
      if (childNode.model.get("expanded") && recursive) {
        seachingInsideChildren = true;
        this.findElementWithResponse(childNode, elementCode, datedeb, recursive, callback);
      }
      // if node == elementCode then we call the callback function for further processing
      if (childNode.model.node.get("code") === elementCode) {
        if (datedeb) {
          if (childNode.model.node.get("datedeb") === datedeb) {
            found = true;
            foundNode = childNode;
          }
        } else {
          found = true;
          foundNode = childNode;
        }
      }
    }
    if (seachingInsideChildren === false) {
      callback(found, foundNode);
    }
  }

  expandRoot(callback?: () => void): JQueryXHR {
    return this.root._expand(null, callback);
  }

  /**
   * Construct the complete path and trigger the function to open the path and find the correct activity.
   * rattachement dates of the element to be selected are filterede by periodes d'utilization to obtain valid periods.
   * don't click on it, because it is being selected from first open of the tree, so element is already the value selected in selecteuractiviteinput
   */
  expandPath(pathModel: CWBaseModel, objectNodeToOpen: { [key: string]: any }, addHors: boolean, shouldReload: boolean, perutil: boolean, arrayPerUtil: Array<any>, callbackFound: (found: boolean) => void, onlyMarkSelected?: boolean): void {
    const hierarchy: any[] = !CWSTR.isBlank(CWSTR.getElValue(pathModel, "hierar")) ? CWSTR.getElValue(pathModel, "hierar") : [];
    let structure: any[] = !CWSTR.isBlank(CWSTR.getElValue(pathModel, "structa")) ? CWSTR.getElValue(pathModel, "structa") : [];
    let elemDateDeb = null;
    let elemDateFin = null;
    let perUtilInfo = {};
    let arrayCompletePath: any[] = null;

    if (perutil === true) {
      //If perutil is=true, dates are limited by utilization dates. So hierid doesn'tpoint to a unique element.
      //And we have to filter path by dates of the element  to be selected.
      elemDateDeb = !CWSTR.isBlank(CWSTR.getElValue(pathModel, "datedeb")) ? CWSTR.getElValue(pathModel, "datedeb") : null;
      elemDateFin = !CWSTR.isBlank(CWSTR.getElValue(pathModel, "datefin")) ? CWSTR.getElValue(pathModel, "datefin") : null;
      perUtilInfo = { elemDateDeb: elemDateDeb, elemDateFin: elemDateFin, arrayPerUtil: arrayPerUtil, index: 0 };
    }
    if (CWSTR.isBlank(structure) && addHors === true) {
      const horsObject = { "code": " ", "libelle": "", "niveau": null as string, "codef": null as string, nodeType: "S" };

      //if do not have structure then it is "hors structure".
      structure = [];
      structure.push(horsObject);
    }
    arrayCompletePath = _.union(structure, hierarchy);
    // ATTENTION!! Il faut pas utiliser le this.root.model.coll cars il ne represente pas le treeNode
    // Il faut utiliser l'objet this.root.sonsColl qui represente vraiment les fils du node (contient les évènements nécessaires)
    if (arrayCompletePath.length > 0) {
      if (perutil === true) { //Find element in tree for different periodes d'utilisation
        this.recursExpandPerUtil(arrayCompletePath, 0, this.root.sonsColl, objectNodeToOpen, shouldReload, perUtilInfo, callbackFound, onlyMarkSelected);
      } else { //Find element in tree (without perutil)
        this.recursExpand(arrayCompletePath, 0, this.root.sonsColl, objectNodeToOpen, shouldReload, null, callbackFound, onlyMarkSelected);
      }

    } else {
      const nodeToSelect = this._findElementPath(this.root.sonsColl, objectNodeToOpen);

      if (!CWSTR.isBlank(nodeToSelect)) {
        if (CWSTR.isBlank(onlyMarkSelected) || onlyMarkSelected !== true) {
          nodeToSelect.trigger("selectNode");
        } else { //Only paint style of selection but don't select element because it is already selected
          nodeToSelect.trigger("markCLassSelectedNode");
        }
        if (callbackFound) {
          callbackFound(true);
        }
      } else if (callbackFound) {
        callbackFound(false);
      }
    }
  }

  /**
   * Call recursexpan once per each period d'utilization until a chemin is found to the element we want to select.
   */
  recursExpandPerUtil(arrayCompletePath: { [key: string]: any }[], index: number, coll: { [key: string]: any }, elementToSelect: { [key: string]: any }, shouldReload: boolean, perUtilInfo: { [key: string]: any }, callbackFound: (found: boolean) => void, onlyMarkSelected: boolean): void {
    const callbackRecurs = (found: boolean): void => {
      if (found !== true) {
        perUtilInfo.index++;
        if (perUtilInfo.index <= (perUtilInfo.arrayPerUtil.length - 1)) {
          this.recursExpand(arrayCompletePath, 0, coll, elementToSelect, shouldReload, perUtilInfo, callbackRecurs, onlyMarkSelected);
        } else {
          if (callbackFound) {
            callbackFound(false); //Element not found
          }
        }

      } else {
        if (callbackFound) {
          callbackFound(true); //Element found
        }
      }
    };

    this.recursExpand(arrayCompletePath, 0, coll, elementToSelect, shouldReload, perUtilInfo, callbackRecurs, onlyMarkSelected);
  }
  /**
   * Find and open all elements of the path until the last one is reached.
   */
  recursExpand(arrayCompletePath: { [key: string]: any }[], index: number, coll: { [key: string]: any }, elementToSelect: { [key: string]: any }, shouldReload: boolean, perUtilInfo: { [key: string]: any }, callback: (found: boolean) => void, onlyMarkSelected: boolean): void {
    const objectPathToFind = arrayCompletePath[index];    //search the element of path and if it's found, select it.
    const findPathModel = this._findElementPath(coll, objectPathToFind, perUtilInfo);

    if (!CWSTR.isBlank(findPathModel)) {
      findPathModel.trigger("expandNode", null, (coll: { [key: string]: any }) => {
        if (index + 1 < arrayCompletePath.length) {
          this.recursExpand(arrayCompletePath, index + 1, coll, elementToSelect, shouldReload, perUtilInfo, callback, onlyMarkSelected);
        } else {
          const nodeToSelect = this._findElementPath(coll, elementToSelect);

          if (!CWSTR.isBlank(nodeToSelect)) {
            if (CWSTR.isBlank(onlyMarkSelected) || onlyMarkSelected !== true) {
              nodeToSelect.trigger("selectNode");
            } else { //Only paint style of selection but don't select element because it is already selected
              nodeToSelect.trigger("markCLassSelectedNode");
            }
            if (callback) {
              callback(true);
            }
          } else {
            if (callback) {
              callback(false);
            }
          }
        }
      }, shouldReload);
    } else {
      if (callback) {
        callback(false);
      }
    }
  }

  /**
   * Find the element into the collection
   */
  _findElementPath(collection: { [key: string]: any }, elementToSelect: { [key: string]: any }, perUtilInfo?: { [key: string]: any }): { [key: string]: any } {
    let findObject = undefined;
    const type = elementToSelect.nodeType;
    const element = !(collection instanceof Backbone.Collection) ? collection : collection.models;

    _.each(element, (item: { [key: string]: any }) => {
      const model = CWSTR.isBlank(item.model) ? item : item.model.node;
      const id = type === "H" ? model.get("hierid") : model.get("code");
      let idToFind = null;

      if (type === "H") {
        if (elementToSelect instanceof Backbone.Model) {
          idToFind = elementToSelect.get("hierid");
        } else {
          idToFind = elementToSelect.hierid;
        }
      } else {
        if (elementToSelect instanceof Backbone.Model) {
          idToFind = elementToSelect.get("code");
        } else {
          idToFind = elementToSelect.code;
        }
      }
      if (id === idToFind) {
        if (CWSTR.isBlank(perUtilInfo) || CWSTR.isBlank(perUtilInfo.elemDateDeb)) {
          if (!CWSTR.isBlank(elementToSelect) && !CWSTR.isBlank(elementToSelect.periodeToSelect)) {
            const periodeModelColl = { datedeb: CWSTR.getElValue(model, "datedeb"), datefin: CWSTR.getElValue(model, "datefin") };

            if (TREE.periodeContainesPart(periodeModelColl, elementToSelect.periodeToSelect)) {
              findObject = model;
            }
          } else {
            findObject = model;
          }
        } else { //dates filtered by periode d'utilization
          const periodUtil = perUtilInfo.arrayPerUtil[perUtilInfo.index];
          const intersectionRattAndUtil = TREE.getPeriodeIntersection({ datedeb: periodUtil.datedeb, datefin: periodUtil.datefin }, { datedeb: perUtilInfo.elemDateDeb, datefin: perUtilInfo.elemDateFin });

          //there could be many elements in the tree with the same hierid, so we have to filter by date.
          //The period of the parent must contain totally or partially the period of the element to select.
          if (!CWSTR.isBlank(intersectionRattAndUtil) && TREE.periodeContainesPart({ datedeb: model.get("datedeb"), datefin: model.get("datefin") }, intersectionRattAndUtil)) {
            findObject = model;
          }
        }
      }
    });
    return findObject;
  }

  _sortCollection(coll: { [key: string]: any }): void {
    coll.comparator = (model: { [key: string]: any }): boolean => {
      //return (!_.isEmpty(model.node)?model.get("libelle")+model.node.get("datedeb"):model.get("libelle"));
      return model.get("libelle");
    };
    coll.sort();
  }
}
