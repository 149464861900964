module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!-- <div id="motpassediv">\n  <br/>\n  <span class="ctxGestionCollab"></span>\n  <span id="messages" class="messages"></span>\n  <br/>\n  <br/>\n  <br/>\n  <span id="motpassetitle" class="phx-details-header-text">'+
((__t=(i18n.t('login:pwdtitle2')))==null?'':__t)+
'</span>\n  <br/>\n  <span id="messageInfor" class=""></span>\n  <br/>\n  <br/>\n  <br/>\n  <fieldset id="motpasseform" name="motpasseform" action="#">\n    <span class="old_password">\n				<label for="password" title="">'+
((__t=(i18n.t('login:pwdmotpassact')))==null?'':__t)+
'</label>\n				<input id="password" class="password" tabindex="20" type="password" size="35" maxlength="32" />\n				<br/>\n				<br/>\n			</span>\n    <br/>\n    <br/>\n    <label for="pwd_new">'+
((__t=(i18n.t('login:pwdmotpassnou')))==null?'':__t)+
'</label>\n    <input id="pwd_new" class="pwd_new" tabindex="20" type="password" size="35" maxlength="32" />\n    <br/>\n    <br/>\n    <label for="pwd_confirm">'+
((__t=(i18n.t('login:pwdmotpassconf')))==null?'':__t)+
'</label>\n    <input id="pwd_confirm" class="pwd_confirm" tabindex="20" type="password" size="35" maxlength="32" />\n    <br/>\n    <br/>\n    <br/>\n    <br/>\n  </fieldset>\n  <table id="logmotpassebuttons" class="ui-dialog-buttonpane">\n    <tr>\n      <td align="left">\n        <button type="button" class="motpassecontinue" type="button">'+
((__t=(i18n.t('login:pwdenregistrer')))==null?'':__t)+
'</button>\n      </td>\n      <td align="right">\n        <button type="button" class="motpassecancel" type="button">'+
((__t=(i18n.t('login:pwdannuler')))==null?'':__t)+
'</button>\n      </td>\n    </tr>\n  </table>\n</div> -->\n<fieldset class="cw-fieldset">\n<div class="form-row messages cw-texteNormal">\n  <span id="messages" class="messages cw-texteNormal"></span>\n  <span id="messageInfor" class=""></span>\n</div>\n\n<div class="form-row">\n  <div class="old_password form-group">\n    <label for="password" class="cw-required">'+
((__t=(i18n.t('login:pwdmotpassact')))==null?'':__t)+
'</label>\n    <input id="password" class="password form-control" tabindex="20" type="password" size="35" maxlength="32" />\n  </div>\n</div>\n<div class="form-row">\n  <div class="form-group new">\n    <label for="pwd_new"  class="cw-required">'+
((__t=(i18n.t('login:pwdmotpassnou')))==null?'':__t)+
'</label>\n    <input id="pwd_new" class="pwd_new form-control" tabindex="21" type="password" size="35" maxlength="32" />\n  </div>\n</div>\n<div class="form-row">\n<div class="form-group">\n  <label for="pwd_confirm"  class="cw-required">'+
((__t=(i18n.t('login:pwdmotpassconf')))==null?'':__t)+
'</label>\n  <input id="pwd_confirm" class="pwd_confirm form-control" tabindex="22" type="password" size="35" maxlength="32" />\n</div>\n</div>\n<div class="form-row">\n  <button class="motpassecontinue save btn btn-withIcon btn-primary">'+
((__t=(i18n.t('login:pwdenregistrer')))==null?'':__t)+
''+
((__t=(UTILS.getSVGIcon('valider')))==null?'':__t)+
'</button>\n  <button class="motpassecancel revert btn btn-withIcon btn-secondary">'+
((__t=(i18n.t('login:pwdannuler')))==null?'':__t)+
''+
((__t=(UTILS.getSVGIcon('croix')))==null?'':__t)+
'</button>\n</div>\n</fieldset>\n<!-- <fieldset id="motpassediv" class="cw-fieldset">\n  <span class="ctxGestionCollab"></span>\n  <span id="messages" class="messages"></span>\n\n  <span id="motpassetitle" class="cw-titre">'+
((__t=(i18n.t('login:pwdtitle2')))==null?'':__t)+
'</span>\n  <span id="messageInfor" class=""></span>\n\n  <fieldset id="motpasseform" name="motpasseform" action="#">\n\n    <div class="old_password form-group">\n        <label for="password">'+
((__t=(i18n.t('login:pwdmotpassact')))==null?'':__t)+
'</label>\n        <input id="password" class="password form-control" tabindex="20" type="password" size="35" maxlength="32" />\n    </div>\n    <div class="form-group">\n        <label for="pwd_new">'+
((__t=(i18n.t('login:pwdmotpassnou')))==null?'':__t)+
'</label>\n        <input id="pwd_new" class="pwd_new form-control" tabindex="20" type="password" size="35" maxlength="32" />\n    </div>\n    <div class="form-group">\n        <label for="pwd_confirm">'+
((__t=(i18n.t('login:pwdmotpassconf')))==null?'':__t)+
'</label>\n        <input id="pwd_confirm" class="pwd_confirm form-control" tabindex="20" type="password" size="35" maxlength="32" />\n    </div>\n  </fieldset>\n  <table id="logmotpassebuttons">\n    <tr>\n      <td align="left" style="padding: .5em">\n        <button class="motpassecontinue save btn btn-withIcon btn-primary">'+
((__t=(i18n.t('login:pwdenregistrer')))==null?'':__t)+
''+
((__t=(UTILS.getSVGIcon('valider')))==null?'':__t)+
'</button>\n      </td>\n      <td align="right" style="padding: .5em">\n        <button class="motpassecancel revert btn btn-withIcon btn-secondary">'+
((__t=(i18n.t('login:pwdannuler')))==null?'':__t)+
''+
((__t=(UTILS.getSVGIcon('croix')))==null?'':__t)+
'</button>\n      </td>\n    </tr>\n  </table>\n</fieldset> -->\n';
}
return __p;
};
