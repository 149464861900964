import * as Backbone from 'Backbone';
import _ from 'underscore';
import TPLvuemensuelDetail from '../detail.tpl.html';
import TPLvuemensuelHorairePlageTooltip from '../horairePlageTooltip.tpl.html';
import { CWBaseFormView as CWCommonFormView } from 'core/views/cwForm.view';
import { CWBaseModel } from 'core/models/cwBase.model';
import { CWCriteresCalModel } from '../models/cwCriteresCal.model';
import { CWDialogPopupView } from 'src/core/components/dialog/popup/cwDialogPopup.view';
import { CWDialogView } from 'core/components/dialog/cwDialog.view';
import { CWGererBadgeagesView } from 'common/evenements/gerer/gererbadgeages/views/cwGererBadgeages.view';
import { CWGererOneBadgeageView } from 'common/evenements/gerer/gereronebadgeage/views/cwGererOneBadgeage.view';
import { CWHABILITATION } from 'utils/cwHabilitation';
import { CWRENDER } from 'src/utils';
import { CWScheduleModel } from 'core/controls/schedule/cwSchedule.model';
import { CWScheduleView } from 'core/controls/schedule/cwSchedule.view';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { SYNC } from 'utils/sync.js';
import { UTILS } from 'utils/utils.js';

export class CWFormView extends CWCommonFormView {

  public selectionModel: Backbone.Model;
  public parent: any;
  public template: any;
  public workflow: any;
  public calendar: any;
  public selectedDate: string;
  public currentDay: number;
  public currentMonth: number;
  public currentYear: number;
  public typeGerer: string;
  public datesSelectable: boolean;
  public isMultipleSelection: boolean;
  public oldDatefinClicked: string;
  public context: { [key: string]: any };
  public dblclick: number;
  public selectPresence: Backbone.Model;
  public agendaZone: number;

  constructor(options?: { [key: string]: any }) {
    options.events = _.extend({
      "dblclick .js-event-pret": "_manageClickPret",
      "dblclick .js-event-absence": "_manageClickAbsence",
      "dblclick .js-event-activite": "_manageClickActivite",
      "dblclick .js-horaire": "_manageClickHoraire",
      "dblclick .presence-info": "_initPopupView",
      "dblclick .vuemensuel-nweek": "_manageClickWeek",
      "click .js-event-selectionable": "_manageEventSelection",
      //"click .vuemensuel-cell-header": "_goToWeeklyView",
      "click .scroll-down": "_manageScrollDown",
      "click .scroll-up": "_manageScrollUp",
      "click .display-totalisations-groups": "_toggleTotalisationsMenu",
      "click .phx-totalisations-groups .c-panneauMenu__item ": "_selectMenuElement",
      "mousedown .vuemensuel-day": "_mousedownHandler",
      "mousemove .vuemensuel-day": "_mousemoveHandler",
      "mouseup .vuemensuel-day": "_mouseupHandler"
    });
    super(options);
    this.model = new CWBaseModel({
      value: new CWCriteresCalModel()
    });

    this.template = TPLvuemensuelDetail;

    this.selectionModel = new Backbone.Model({
      dateStart: "",
      dateEnd: "",
      isRange: false
    });

    this.selectPresence = new Backbone.Model({
      selPresence: [],
      hasPresence: false
    });

    this.workflow = options.workflow;
    this.calendar = null;

    this.listenTo(this.workflow, "drawCalendar", this._drawCalendar);
    this.listenTo(this.workflow, "clearSelectedChangeTab", this._unselectAllDates);
    this.listenTo(this.workflow, "initTabPresence", this._markCellInit);
    this.listenTo(this.workflow, "clearPresenceAfterAction", this._unselectAllDatesAfterAction);

    if (CWSTR.isBlank(this.workflow.get("selectedDate"))) {
      this.selectedDate = "19700101";
      this.currentDay = 1;
      this.currentMonth = 0;
      this.currentYear = 1970;
    } else {
      this.selectedDate = this.workflow.get("selectedDate");
      const date = CWTYPE.DATE.strToDate(this.selectedDate);

      this.currentDay = date.getDate();
      this.currentMonth = date.getMonth();
      this.currentYear = date.getFullYear();
    }
  }

  _cellClickListener(event: any): void {
    const type = "creerAb";
    const hourAux = SYNC.getServerDate().getHours();
    const minutesAux = SYNC.getServerDate().getMinutes();
    let strMinutesAux = "";
    if (minutesAux < 10) {
      strMinutesAux = String("0" + minutesAux.toString());
    } else {
      strMinutesAux = String(minutesAux.toString());
    }

    let currentHour = "";
    if (hourAux < 10) {
      currentHour = String("0" + hourAux.toString()) + strMinutesAux;
    } else {
      currentHour = String(hourAux.toString()) + strMinutesAux;
    }
    const startHour = currentHour;
    const endHour = currentHour;

    let startDate = this.$el.find(".vuemensuel-day[data-week=" + $(event.target).text() + "]").first().data('date').toString();
    startDate = CWTYPE.DATE.strToDate(startDate);

    let endDate = this.$el.find(".vuemensuel-day[data-week=" + $(event.target).text() + "]").last().data('date').toString();
    endDate = CWTYPE.DATE.strToDate(endDate);

    startDate = CWTYPE.DATE.dateToStr(startDate, CWTYPE.DATE.DEFAULT_MASK_BBDD);
    endDate = CWTYPE.DATE.dateToStr(endDate, CWTYPE.DATE.DEFAULT_MASK_BBDD);

    this.workflow.trigger("popup:open", type, this.workflow.get("collabId"), null, startDate, endDate, startHour, endHour);
  }

  _manageScrollDown(event: any): void {
    const target = $(event.currentTarget);
    const cell = target.parent();
    const body = cell.find(".vuemensuel-day-body");

    const scrollHeight = body[0].scrollHeight;
    const actualScroll = body.scrollTop();
    const bodyHeight = body.height();
    const lineHeight = body.children(":first-child").height();

    if ((actualScroll + bodyHeight) <= scrollHeight) {
      if ((lineHeight + (actualScroll + bodyHeight)) <= scrollHeight) {
        body.scrollTop(actualScroll + lineHeight);
      } else {
        body.scrollTop(scrollHeight);
      }

      if ((body.scrollTop() + bodyHeight) >= scrollHeight) {
        target.hide();
      }

      cell.find(".scroll-up").show();
    }
  }

  _manageScrollUp(event: any): void {
    const target = $(event.currentTarget);
    const cell = target.parent();
    const body = cell.find(".vuemensuel-day-body");
    const actualScroll = body.scrollTop();
    const lineHeight = body.children(":first-child").height();

    if (lineHeight < actualScroll) {
      body.scrollTop(actualScroll - lineHeight);
    } else {
      body.scrollTop(0);
    }
    if (body.scrollTop() <= 0) {
      target.hide();
    }
    cell.find(".scroll-down").show();
  }

  _initPopupView(): void {
    const contextAjouter = this._buildContextGerer("initialiser");
    let dialog: CWDialogPopupView | CWDialogView = null;

    if (this.typeGerer === "badgeages") {
      dialog = new CWDialogPopupView({
        view: CWGererBadgeagesView,
        viewData: {
          context: contextAjouter
        }
      });
      dialog.setHeight("auto");
      dialog.setWidth(1200);
      dialog.model.trigger("dlg:open");
    } else if (this.typeGerer === "onebadgeage") {
      dialog = new CWDialogView({
        view: CWGererOneBadgeageView,
        viewData: {
          context: contextAjouter
        }
      });
      dialog.setHeight("auto");
      dialog.setWidth(600);
      dialog.model.trigger("dlg:open");
      dialog.internalView.model.on("ready", (): void => {
        dialog.dialog.dialog("option", "position", { my: "center", at: "center", of: window });
      });
    }
  }

  _buildContextGerer(type: string): { [key: string]: any } {
    const contextGerer: { [key: string]: any } = {};
    let habBadgeG = "RES_PT.G";
    let habBadgeA = "RES_PT.V";
    const habBadgeV = "RES_VAL_BDG.G";

    if (this.workflow.get("utilisateur") === this.workflow.COLLABORATEUR_TYPE) {
      habBadgeA = "COL_BADG.V";
      habBadgeG = "COL_BADG.G";
    }
    if (type === "initialiser") {
      contextGerer.ctxUtilisateur = this.context.ctxUtilisateur;
      contextGerer.ctxHabilitation = { HabilitationAcces: habBadgeA, HabilitationGestion: habBadgeG, HabilitationValidation: habBadgeV };
      contextGerer.ctxEcran = !CWSTR.isBlank(this.context.ctxEcran) ? this.context.ctxEcran : ((this.workflow.get("utilisateur") === this.workflow.COLLABORATEUR_TYPE) ? "agenda" : "agenda_R");
      contextGerer.ctxGestionPerioaff = null;
      contextGerer.ctxGestionPeriode = "";
      contextGerer.ctxGestionCollective = false;
      contextGerer.ctxGestionCollab = {
        "matric_aux": this.workflow.mainModel.models[0].get("aux"),
        matricule: this.workflow.mainModel.models[0].get("mat"),
        nom: this.workflow.mainModel.models[0].get("nom"),
        prenom: this.workflow.mainModel.models[0].get("pre")
      };
      contextGerer.ctxActionsPossibles = [];
      contextGerer.ctxActionsPossibles = ["Creer", "Modifier", "Supprimer", "Valider"];
      contextGerer.ctxModeSuppression = "Button"; // SensX,Button
      contextGerer.ctxModeInitialisation = "Consultation";
      contextGerer.ctxIdentifiantBadgeage = "";
      contextGerer.ctxFiltreApplique = ["A", "D", "I", "T", "H", "R"];
      contextGerer.ctxGestionDate = this.workflow.get("selectedDate");
    }
    contextGerer.ctxModeRepresentation = "pop-up";
    return contextGerer;
  }

  // Events handlers
  _mousedownHandler(event: any): void {
    event.preventDefault();
    const isCtrlPressed = event.ctrlKey;
    // If the element clicked is not a selectable element.
    if ($(event.target).parents(".vuemensuel-day").length > 0) {
      // Delete old selection
      this.datesSelectable = true;
      this.isMultipleSelection = false;
      if (!isCtrlPressed && this.workflow.activeTab === 1 || this.workflow.activeTab === 0) {
        this._unselectAllDates();
      }
      this._dayClickListener(event);
    }
  }

  _mousemoveHandler(event: any): void {
    event.preventDefault();
    const isShiftPressed = event.shiftKey;

    if (!CWSTR.isBlank(this.selectionModel.get("dateStart")) && this.datesSelectable === true) {
      this.isMultipleSelection = true;

      if (this.workflow.activeTab === 0) {
        this._unselectAllDates();
      }
      this.oldDatefinClicked = this.selectionModel.get("dateEnd");
      this.selectionModel.set("dateEnd", $(event.currentTarget).attr("data-date"));

      if (isShiftPressed) {
        this._treatSelectedDates();
      }
    }
  }

  _mouseupHandler(event: any): void {
    this.datesSelectable = false;
    const isShiftPressed = event.shiftKey;
    const isCtrlPressed = event.ctrlKey;

    if (this.workflow.activeTab !== 1) {
      this.workflow.trigger("end:selectDay");
    }
    if (isShiftPressed === false && isCtrlPressed === false && this.isMultipleSelection === false) {
      this._goToDay(event);
    } else {
      if (isShiftPressed) {
        const selectionStartDate = CWTYPE.DATE.strToDate(this.selectionModel.get("dateStart"), CWTYPE.DATE.DEFAULT_MASK_BBDD);
        const selectionEndDate = CWTYPE.DATE.strToDate(this.selectionModel.get("dateEnd"), CWTYPE.DATE.DEFAULT_MASK_BBDD);

        if (selectionEndDate < selectionStartDate) {
          this.selectionModel.set({
            "dateStart": this.selectionModel.get("dateEnd"),
            "dateEnd": this.selectionModel.get("dateStart")
          });
        }

        this._goToStartDay(this.selectionModel.get("dateStart"));
        this._treatSelectedDates();
      }
    }
    this.isMultipleSelection = false;
  }

  _manageClickMensualSelection(event: any): void {
    const eventSelectionParent = $(event.target).closest(".vuemensuel-day.js-heure-selectionnee");
    const eventSelection = eventSelectionParent.find(".agenda_selected_item");

    if (eventSelection.length > 0) {
      this.workflow.selection.select(eventSelection.get(0).getAttribute('evtid'), eventSelection.get(0).dataset.typeevent, eventSelectionParent.get(0).dataset.date, eventSelectionParent.get(0).dataset.date);
      this.workflow.once("end:selectDay", this.workflow._emptySelection, this.workflow);
    }
  }

  _unselectAllDates(): any {
    if (this.workflow.get("viewType") === this.workflow.MONTHLY_VIEW) {
      let element = null;

      this.selectPresence.set('selPresence', []);
      this.selectPresence.set('hasPresence', false);
      element = this.$el.find("div.ui-phx-date-heure-selectionnee-border-imp");
      element.removeClass("ui-phx-date-heure-selectionnee-border-imp");
      element = this.$el.find("div.ui-phx-date-heure-actuelle-border");
      element.removeClass("ui-phx-date-heure-actuelle-border");

      if (this.workflow.activeTab === 0) {
        const today = CWTYPE.DATE.getCurrentDate();

        this.$el.find(".vuemensuel-day[data-date='" + today + "']").addClass("ui-phx-date-heure-actuelle-border");
      }
      this.selectionModel.set("dateEnd", "");
    }
  }

  _unselectAllDatesAfterAction(): any {
    let element = null;

    this.selectPresence.set('selPresence', []);
    this.selectPresence.set('hasPresence', false);
    if (this.workflow.activeTab === 1) {
      element = this.$el.find("div.ui-phx-date-heure-actuelle-border");
      element.removeClass("ui-phx-date-heure-actuelle-border");
    }
  }

  _dayClickListener(event: any): void {
    const isShiftPressed = event.shiftKey;
    const isCtrlPressed = event.ctrlKey;
    const dateSelected = $(event.currentTarget).attr("data-date");

    if (isShiftPressed === true && !CWSTR.isBlank(this.selectionModel.get("dateStart"))) {
      this.selectionModel.set("dateEnd", dateSelected);

      //Put range of dates in correct order
      const selectionStartDate = CWTYPE.DATE.strToDate(this.selectionModel.get("dateStart"), CWTYPE.DATE.DEFAULT_MASK_BBDD);
      const selectionEndDate = CWTYPE.DATE.strToDate(this.selectionModel.get("dateEnd"), CWTYPE.DATE.DEFAULT_MASK_BBDD);

      if (selectionEndDate < selectionStartDate) {
        this.selectionModel.set({
          "dateStart": this.selectionModel.get("dateEnd"),
          "dateEnd": this.selectionModel.get("dateStart")
        });
      }
      this._treatSelectedDates(isShiftPressed);
      if (this.workflow.activeTab === 1) {
        if (this.selectPresence.get('selPresence').length > 1) {
          this.selectPresence.set('selPresence', []);
          this.selectPresence.get('selPresence').push(dateSelected);
          this._checkDatePresence(dateSelected);
        }
        this._addSelectedDatesPresence(this.selectionModel.get("dateStart"), this.selectionModel.get("dateEnd"));
      }
    } else if (isCtrlPressed === true && !CWSTR.isBlank(this.selectionModel.get("dateStart")) && this.workflow.activeTab === 1) {
      if (CWSTR.isBlank(this.selectPresence.get('selPresence'))) {
        this.selectPresence.set('selPresence', []);
      }
      const idx = _.indexOf(this.selectPresence.get('selPresence'), dateSelected);
      if (idx > -1) {
        this._unmarkCell(".vuemensuel-day[data-date='" + dateSelected + "']", dateSelected);
      } else {
        this.selectPresence.get('selPresence').push(dateSelected);
        this._checkDatePresence(dateSelected);

        this._markCell(".vuemensuel-day[data-date='" + dateSelected + "']");
      }
    } else {
      if (!CWSTR.isBlank(this.selectionModel.get("dateStart"))) {
        if (this.workflow.activeTab === 1) {
          this._unselectAllDates();
        } else {
          this._unmarkCell(".vuemensuel-day[data-date='" + this.selectionModel.get("dateStart") + "']", this.selectionModel.get("dateStart"));
        }
      }

      if (this.selectionModel.get("dateStart") !== dateSelected || CWSTR.isBlank(this.selectionModel.get("dateStart"))) {
        this.selectionModel.set("dateStart", dateSelected);
        this._markCell(".vuemensuel-day[data-date='" + this.selectionModel.get("dateStart") + "']");
        if (this.workflow.activeTab === 1) {
          this.selectPresence.set('selPresence', []);
          this.selectPresence.get('selPresence').push(this.selectionModel.get("dateStart"));
          this._checkDatePresence(this.selectionModel.get("dateStart"));
        }
      } else {
        if (!CWSTR.isBlank(this.selectionModel.get("dateStart")) && this.selectionModel.get("dateStart") === dateSelected && this.workflow.activeTab === 1) {
          this.selectPresence.set('selPresence', []);
          this.selectPresence.get('selPresence').push(this.selectionModel.get("dateStart"));
          this._checkDatePresence(this.selectionModel.get("dateStart"));

          this._markCell(".vuemensuel-day[data-date='" + this.selectionModel.get("dateStart") + "']");
        } else {
          this.selectionModel.set("dateStart", null);
        }
      }
    }

    if (!CWSTR.isBlank(this.selectionModel.get("dateStart")) && this.workflow.activeTab !== 1) {
      this.datesSelectable = false;
      if (CWSTR.isBlank(this.selectionModel.get("dateEnd"))) {
        this.workflow.selection.select(null, null, this.selectionModel.get("dateStart"), this.selectionModel.get("dateStart"), null, null); // Adjusted with +1 for the borders
      } else {
        this.workflow.selection.select(null, null, this.selectionModel.get("dateStart"), this.selectionModel.get("dateEnd"), null, null); // Adjusted with +1 for the borders
      }
    }
  }

  _markCellInit(): void {
    if (this.workflow.get("viewType") === this.workflow.MONTHLY_VIEW) {
      const element = this.$el.find(".vuemensuel-day[data-date='" + this.workflow.get("selectedDate") + "']");
      if (this.workflow.activeTab === 1) {
        element.removeClass("ui-phx-date-heure-actuelle-border");
      } else {
        element.addClass("ui-phx-date-heure-selectionnee-border-imp");
      }
    }
  }

  _markCell(target: string, isShiftPressed: boolean = false): void {
    const element = this.$el.find(target);
    const today = CWTYPE.DATE.getCurrentDate();
    if (this.workflow.activeTab === 1) {
      if (element.data('date').toString() === today || isShiftPressed) {
        element.addClass("ui-phx-date-heure-actuelle-border")
      } else {
        element.addClass("ui-phx-date-heure-actuelle-border");
      }
    } else {
      element.addClass("ui-phx-date-heure-selectionnee-border-imp");
    }
  }

  _unmarkCell(target: string, dateSelected?: string): void {
    const element = this.$el.find(target);
    if (this.workflow.activeTab === 1) {
      const idx = _.indexOf(this.selectPresence.get('selPresence'), dateSelected);
      if (idx > -1) {
        const aArr = _.without(this.selectPresence.get('selPresence'), dateSelected);
        this.selectPresence.set('selPresence', aArr);
        this.selectPresence.set('hasPresence', false);
        if (aArr.length > 0) {
          for (const i in aArr) {
            if (i) {
              this._checkDatePresence(aArr[i]);
            }
          }
        }
      }
      element.removeClass("ui-phx-date-heure-actuelle-border");
    } else {
      element.removeClass("ui-phx-date-heure-selectionnee-border-imp");
    }
  }

  _treatSelectedDates(isShiftPressed: boolean = false): void {
    const datedeb = this.selectionModel.get("dateEnd");
    const datefin = this.selectionModel.get("dateStart");

    this._markSelectedDates(datedeb, datefin, isShiftPressed);
  }

  _markSelectedDates(datedeb: any, datefin: any, isShiftPressed: boolean = false): void {
    if (!CWSTR.isBlank(datedeb) && !CWSTR.isBlank(datefin)) {
      const size = datefin - datedeb;
      let date = "";

      if (size > 0) {
        for (let i = 0; i <= size; i++) {
          date = String(parseInt(datedeb, 10) + i);
          this._markCell(".vuemensuel-day[data-date='" + date + "']", isShiftPressed);
        }
      } else {
        for (let i = size; i <= 0; i++) {
          date = String(parseInt(datedeb, 10) + i);
          this._markCell(".vuemensuel-day[data-date='" + date + "']", isShiftPressed);
        }
      }
    }
  }

  _addSelectedDatesPresence(datedeb: any, datefin: any): void {
    if (!CWSTR.isBlank(datedeb) && !CWSTR.isBlank(datefin)) {
      const size = datefin - datedeb;
      let date = "";
      if (CWSTR.isBlank(this.selectPresence.get('selPresence'))) {
        this.selectPresence.set('selPresence', []);
      }
      if (size > 0) {
        for (let i = 0; i <= size; i++) {
          date = String(parseInt(datedeb, 10) + i);
          const idx = _.indexOf(this.selectPresence.get('selPresence'), date);
          if (idx < 0) {
            this.selectPresence.get('selPresence').push(date);
            this._checkDatePresence(date);
          }
        }
      } else {
        for (let i = size; i <= 0; i++) {
          date = String(parseInt(datedeb, 10) + i);
          const idx = _.indexOf(this.selectPresence.get('selPresence'), date);
          if (idx < 0) {
            this.selectPresence.get('selPresence').push(date);
            this._checkDatePresence(date);
          }
        }
      }
    }
  }

  _checkDatePresence(date: string): void {
    const ele = this.workflow.mainModel.models[0].get("cel").get(date);

    if (!CWSTR.isBlank(ele) && !this.selectPresence.get('hasPresence')) {
      if (ele.get("presence")) {
        this.selectPresence.set('hasPresence', true);
      }
    }
  }

  _goToDay(event: any): void {
    const target = $(event.currentTarget);

    if (target.attr("data-type") !== "out" && !target.hasClass("js-heure-selectionnee")) {
      const dateSelected = CWTYPE.DATE.strToDate(this.context.ctxDateEnEvidence);
      const year = dateSelected.getFullYear().toString();
      const month = (dateSelected.getMonth() + 1) < 10 ? "0" + (dateSelected.getMonth() + 1).toString() : (dateSelected.getMonth() + 1).toString();
      let day = target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text();
      let date = null;

      if (!_.isNaN(parseInt(target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text(), 10))) {
        day = parseInt(target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text(), 10) < 10 ? "0" + target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text() : target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text();
      }
      this.context.ctxDateEnEvidence = year + month + day;
      date = CWTYPE.DATE.strToDate(this.context.ctxDateEnEvidence);
      this.currentDay = date.getDate();
      this.currentMonth = date.getMonth();
      this.currentYear = date.getFullYear();
      this.workflow.set("selectedDate", this.context.ctxDateEnEvidence, { silent: this.workflow.activeTab === 1 });
      this.selectedDate = this.context.ctxDateEnEvidence;
      date = CWTYPE.DATE.strToDate(this.context.ctxDateEnEvidence);
      this.currentDay = date.getDate();
      this.currentMonth = date.getMonth();
      this.currentYear = date.getFullYear();
    }
  }

  _goToStartDay(date: string): void {
    if (!CWSTR.isBlank(date)) {
      const target = $(".vuemensuel-day[data-date=" + date + "]");

      if (target.attr("data-type") !== "out" && !target.hasClass("js-heure-selectionnee")) {
        const dateSelected = CWTYPE.DATE.strToDate(this.context.ctxDateEnEvidence);
        const year = dateSelected.getFullYear().toString();
        const month = (dateSelected.getMonth() + 1) < 10 ? "0" + (dateSelected.getMonth() + 1).toString() : (dateSelected.getMonth() + 1).toString();
        let day = target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text();
        let date = null;

        if (!_.isNaN(parseInt(target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text(), 10))) {
          day = parseInt(target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text(), 10) < 10 ? "0" + target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text() : target.children(".vuemensuel-day-header").children(".vuemensuel-day-header-number").text();
        }
        this.context.ctxDateEnEvidence = year + month + day;
        date = CWTYPE.DATE.strToDate(this.context.ctxDateEnEvidence);
        this.currentDay = date.getDate();
        this.currentMonth = date.getMonth();
        this.currentYear = date.getFullYear();
        this.workflow.set("selectedDate", this.context.ctxDateEnEvidence, { silent: this.workflow.activeTab === 1 });
        this.selectedDate = this.context.ctxDateEnEvidence;
        date = CWTYPE.DATE.strToDate(this.context.ctxDateEnEvidence);
        this.currentDay = date.getDate();
        this.currentMonth = date.getMonth();
        this.currentYear = date.getFullYear();
      }
    }
  }

  _goToWeeklyView(): void {
    this.workflow.set("viewType", this.workflow.WEEKLY_VIEW);
  }

  _manageEventSelection(event: any): void {
    const target = $(event.currentTarget);
    //event.stopPropagation();
    const button = $(event.target).parents("#agenda").find(".agenda_acmsupbtn");
    if (!target.hasClass("agenda_selected_item")) {
      this.$el.find(".agenda_selected_item").removeClass("agenda_selected_item");
      target.addClass("agenda_selected_item");
      if (this.workflow.activeTab === 1) {
        target.parents(".vuemensuel-day").addClass("ui-phx-date-heure-actuelle-border");
      } else {
        target.parents(".vuemensuel-day").addClass("ui-phx-date-heure-selectionnee-border-imp");
      }
      this._manageClickMensualSelection(event);
    } else {
      target.removeClass("agenda_selected_item");
      this.workflow.selection.clear();
      if (this.workflow.activeTab === 1) {
        target.parents(".vuemensuel-day").addClass("ui-phx-date-heure-actuelle-border");
      } else {
        target.parents(".vuemensuel-day").addClass("ui-phx-date-heure-selectionnee-border-imp");
      }
    }

    if (target.hasClass("js-event-activite")) {
      const date = target.parents(".vuemensuel-day").attr("data-date");
      const user = this.workflow.mainModel.collabId;
      const pk = user + "," + date;
      const msgGrilleHoraire = 'title="<span class=\'grillehoraire-tooltip\'>' + i18n.t('messages:GME_52') + '</span>"';
      if (!this.workflow.evtModel.getEvent("ACTIVITES", pk).get("evt").get(target.attr("evtid")).get("dts").grh) {
        button.removeClass("ui-state-disabled").prop("disabled", false);
        $(event.target).parents("#agenda").find(".tooltipSpan").remove();
      } else {
        button.addClass("ui-state-disabled").prop("disabled", true);
        //We add a span for show tooltip in a disabled element
        const span = $("<span class='tooltipSpan' style='position: absolute;top: 0;left: 0;right: 0;bottom: 0;'" + msgGrilleHoraire + "></span>");
        $(event.target).parents("#agenda").find(".buttonDiv").append(span);
      }
    } else {
      button.removeClass("ui-state-disabled").prop("disabled", false);
      $(event.target).parents("#agenda").find(".tooltipSpan").remove();
    }
  }

  _manageClickHoraire(event: any): void {
    const target = $(event.currentTarget);

    if (this.workflow.get("utilisateur") === this.workflow.RESPONSABLE_TYPE) {
      if (event.type === "dblclick") {
        this.dblclick = 2;

        const date = target.parents(".vuemensuel-day").attr("data-date");
        const user = this.workflow.mainModel.collabId;
        const pk = user + "," + date;

        if (this.workflow.evtModel.hasEvent("HORAIRES", pk)) {
          const evt = this.workflow.evtModel.getEvent("HORAIRES", pk).get("evt").models[0].get("val");

          if (!CWSTR.isBlank(evt)) {
            this.workflow.trigger("popup:open", "dropHor", this.workflow.get("collabId"), evt, date, date, null, null);
          }
        }

        event.stopPropagation();
      }
    }
  }

  _manageClickAbsence(event: any): void {
    const target = $(event.currentTarget);

    if (event.type === "dblclick") {
      target.addClass("agenda_selected_item");

      this.dblclick = 2;

      const date = target.parents(".vuemensuel-day").attr("data-date");
      const user = this.workflow.mainModel.collabId;
      const pk = user + "," + date;

      if (this.workflow.evtModel.hasEvent("ABSENCES", pk)) {
        const evt = this.workflow.evtModel.getEvent("ABSENCES", pk).get("evt").get(target.attr("evtid"));

        if (!CWSTR.isBlank(evt)) {
          let ev = "consulterAb";
          if (evt.get("dts").sou === true) {
            ev = "consulterSo";
          }
          this.workflow.trigger("popup:open", ev, this.workflow.get("collabId"), evt);
        }
      }

      event.stopPropagation();
    }
  }

  _manageClickActivite(event: any): void {
    const target = $(event.currentTarget);

    if (event.type === "dblclick") {
      target.addClass("agenda_selected_item");

      this.dblclick = 2;

      const date = target.parents(".vuemensuel-day").attr("data-date");
      const user = this.workflow.mainModel.collabId;
      const pk = user + "," + date;

      if (this.workflow.evtModel.hasEvent("ACTIVITES", pk)) {
        const evt = this.workflow.evtModel.getEvent("ACTIVITES", pk).get("evt").get(target.attr("evtid"));

        if (!CWSTR.isBlank(evt)) {
          let ev = "consulterAct";
          if (evt.get("dts").sou === true) {
            ev = "consulterSo";
          }
          this.workflow.trigger("popup:open", ev, this.workflow.get("collabId"), evt);
        }
      }

      event.stopPropagation();
    }
  }

  _manageClickPret(event: any): void {
    const target = $(event.currentTarget);

    if (event.type === "dblclick") {
      target.addClass("agenda_selected_item");

      this.dblclick = 2;

      const date = target.parents(".vuemensuel-day").attr("data-date");
      const user = this.workflow.mainModel.collabId;
      const pk = user + "," + date;

      if (this.workflow.evtModel.hasEvent("PRETS", pk)) {
        const evt = this.workflow.evtModel.getEvent("PRETS", pk).get("evt").get(target.attr("evtid"));

        if (!CWSTR.isBlank(evt)) {
          let ev = "consulterPret";
          if (evt.get("dts").sou === true) {
            ev = "consulterSo";
          }

          const date = target.parents(".vuemensuel-day").attr("data-date");
          this.workflow.trigger("popup:open", ev, this.workflow.get("collabId"), evt.get("dts"), date, date, evt.get("val"), evt.get("val"));
        }
      } else if (this.workflow.evtModel.hasEvent("AFFECTATIONS", pk)) {
        const evt = this.workflow.evtModel.getEvent("AFFECTATIONS", pk).get("evt").get(target.attr("evtid"));

        if (!CWSTR.isBlank(evt)) {
          let ev = "consulterPret";
          if (evt.get("dts").sou === true) {
            ev = "consulterSo";
          }

          const date = target.parents(".vuemensuel-day").attr("data-date");
          this.workflow.trigger("popup:open", ev, this.workflow.get("collabId"), evt.get("dts"), date, date, evt.get("val"), evt.get("val"));
        }
      }

      event.stopPropagation();
    }
  }

  _toggleTotalisationsMenu(): void {
    const menuContent = $(".phx-totalisations-groups ul", this.el);

    if (menuContent.is(":visible")) {
      menuContent.hide();
    } else {
      menuContent.show();

      const closeMenu = (event: any): void => {
        const element = $(this.el).find(event.target);
        if (element.length === 0) {
          menuContent.hide();
        } else {
          $(document).one("mousedown", closeMenu);
        }
      };

      $(document).one("mousedown", closeMenu);
    }
  }

  _selectMenuElement(event: any): void {
    const li = $(event.currentTarget).closest("li");
    const list = li.parent();

    const opt = li.children("a").attr("menu-opt");
    this.workflow.get("totals").set("val", parseInt(opt.replace("total-groups-", "")));

    let valoHab = "COL_SITUVAL.V";
    if (this.workflow.get("utilisateur") === this.workflow.RESPONSABLE_TYPE) {
      valoHab = "RES_RESCPT.V";
    }

    if (CWHABILITATION.canView(valoHab) === true) {
      list.find(".phx-icon-planresp-valider").hide();
      li.find(".phx-icon-planresp-valider").show();

      $(".phx-agenda-view:visible .phx-agenda-viewmenu-totals .phx-icon-planresp-valider").hide();
      $(".phx-agenda-view:visible .phx-agenda-viewmenu-totals a[menu-opt=total-" + parseInt(opt.replace("total-groups-", "")) + "]").siblings(".phx-icon-planresp-valider").show();

      this.workflow.trigger("rerender:totalisations", this.workflow.get("viewType"));
    }
  }

  _drawTotalisations(): void {
    if (this.workflow.get("panelsState").get("bottom") === "S") {
      this.workflow.get("panelsState").set("bottom", "H");
    }

    if ((this.workflow.get("synt").get("val") === false) || CWSTR.isBlank(this.workflow.get("synt").get("val"))) {
      const totalisations = this.$el.find(".vuemensuel-totalisations");

      $(".vuemensuel-days:visible").css("width", "95%");
      $(".vuemensuel-totalisations:visible").css("width", "0px");
      $(".phx-totalisations-groups:visible").css("display", "none");

      totalisations.empty();
    } else {
      const divisionCells = this.$el.find(".vuemensuel-calendrier:visible").hasClass("c5") ? 6 : 8
      const cellWidth = (this.agendaZone - this.$el.find(".vuemensuel-weeks").width() - 35) / divisionCells;
      const totalisations = this.$el.find(".vuemensuel-totalisations");

      totalisations.css("display", "inline-block");
      totalisations.empty();
      totalisations.css("width", cellWidth);

      totalisations.append(this._renderTotalisationsGroups());
      const totalisationsContent = $("<div class=\"totalisations-content\">");
      totalisationsContent.append(this._renderTotalisationsHeader());
      totalisationsContent.append(this._renderTotalisationsBody());
      totalisations.append(totalisationsContent);

      if (totalisations.length > 0) {
        this.$el.find(".vuemensuel-days").css("width", "calc(96% - " + cellWidth + "px)");
        this.$el.find(".vuemensuel-weeks").css("width", "4%");
      }
    }
  }

  _renderTotalisationsGroups(): JQuery {
    const output = $("<div class=\"phx-totalisations-groups ui-menu-icons\">");
    const current = this.workflow.get("totals").get("val");
    const list = $("<ul class=\"c-panneauMenu\" tabindex=\"0\" role=\"menu\">");

    if (_.size(this.workflow.totalsMensuelGroups.attributes) > 0) {
      $.each(this.workflow.totalsMensuelGroups.attributes, function (ix: string, group: any) {
        const liElement = $("<li class=\"c-panneauMenu__item \"  tabindex=\"-1\"  role=\"menuitem\">");
        const spanElement = $(UTILS.getSVGIcon("valider", "", 16));
        spanElement.attr("menu-group", "total-groups");
        if (parseInt(ix) !== current) {
          spanElement.attr("style", "display:none");
        }
        const aElement = $("<a>").attr("menu-opt", "total-groups-" + ix).text(group.libelle);
        aElement.prepend(spanElement);
        liElement.append(aElement);
        list.append(liElement);
      });

      const arrow = $("<button type=\"button\" class=\"display-totalisations-groups ui-button ui-corner-all ui-button-icon-only\" role=\"button\">" + UTILS.getSVGIcon('cercle_plein_trois_points', '', 16));

      output.append(arrow);
      output.append(list);
    }

    return output;
  }

  _renderTotalisationsHeader(): JQuery {
    const output = $("<div class=\"vuemensuel-totalisations-header vuemensuel-header\">");
    const titles = this.workflow.totalsMensuelModel.get("lib");

    if (!CWSTR.isBlank(titles)) {
      _.each(titles, function (title: any) {
        const cell = $("<div class=\"vuemensuel-totalisation-cell-header\">");
        cell.text((title.nom).slice(0, 4));
        cell.attr("data-idx", title.idx);

        let tooltip = "";
        tooltip = title.lib + " (" + title.nom + ")";
        cell.attr("title", tooltip);

        output.append(cell);
      });
    }

    return output;
  }

  _renderTotalisationsBody(): any {
    const drawData: { [key: string]: any } = {};

    drawData.cMonth = this.currentMonth;
    drawData.cYear = this.currentYear;

    const nbRows = UTILS.getNumWeeksForMonth(drawData.cMonth + 1, drawData.cYear);

    const formats: any = [];
    _.each(this.workflow.totalsMensuelModel.get("lib"), (column: any): void => {
      formats[column.idx] = column.fmt;
    });

    const output = $("<div class=\"vuemensuel-totalisations-body\">");

    for (let i = 0; i < nbRows; ++i) {
      const weekDay = new Date(drawData.cYear, drawData.cMonth, (i * 7 + 1));
      const weekNumber = $.datepicker.iso8601Week(weekDay);

      const weekRow = $("<div class=\"vuemensuel-total-row vuemensuel-row\">");

      const totals = this.workflow.totalsMensuelModel.get("totalisations");
      let data: any[] = [];

      if (!CWSTR.isBlank(totals)) {
        _.each(totals, (total: any): void => {
          if (String(total.semaine) === String(weekNumber)) {
            data = total.data;
          }
        });
      }

      _.each(data, (item: any): void => {
        const nbWeek = $("<div class=\"vuemensuel-totalisation-value\">");
        const nbWeekWrapper = $("<div>");

        if (!CWSTR.isBlank(item.val)) {
          const format = formats[item.idx];

          nbWeekWrapper.text(CWTYPE.HOUR_MINUTE.format(item.val, format));

          if (item.val < 0) {
            nbWeekWrapper.addClass("ui-phx-valorisation-negative");
          }
        } else {
          nbWeekWrapper.text("NC");
          nbWeekWrapper.attr("title", i18n.t('agenda.valoNonCalc'));
          nbWeekWrapper.addClass("ui-phx-resultat-invalide");
        }

        nbWeek.attr("data-idx", item.idx);

        nbWeek.append(nbWeekWrapper);
        weekRow.append(nbWeek);
      });

      output.append(weekRow);
    }

    return output;
  }

  setContext(options: any): void {
    const scrollPosition = this._getScrollPosition(this.context, options.context);
    this.context = options.context;
    this.selectedDate = this.context.ctxDateEnEvidence;

    const date = CWTYPE.DATE.strToDate(this.context.ctxDateEnEvidence);

    this.currentDay = date.getDate();
    this.currentMonth = date.getMonth();
    this.currentYear = date.getFullYear();

    this.render(() => { this._setScrollPosition(scrollPosition) });
  }

  _getScrollPosition(prevContext: any, context: any): number {
    let position: number = 0;

    // It is in the same view type (mensuealle)
    if (prevContext && prevContext.ctxRestitution === "mensuelle" && context.ctxRestitution === "mensuelle") {
      // previus and new date are in the same month
      if (prevContext.ctxDateEnEvidence.substring(0, 6) === context.ctxDateEnEvidence.substring(0, 6)) {
        // keep scroll position
        position = this.$el.find(".vuemensuel-calendrier").scrollTop();
      }
    }

    return position;
  }

  _setScrollPosition(position: number): void {
    if (position !== 0) {
      this.$el.find(".vuemensuel-calendrier").scrollTop(position);
    }
  }

  _setDroppable(selection: any): void {
    selection.droppable({
      scope: "agenda-items",
      addClasses: false,
      activeClass: "",
      tolerance: "pointer",
      drop: (event: any, ui: any) => {
        const div = $(event.target);
        const code = ui.helper[0].attributes.code.value;
        let libelle = null;
        if (ui.helper[0].attributes.libelle) {
          libelle = ui.helper[0].attributes.libelle.value;
        }

        let buttonCode;
        let type = "";
        if (ui.draggable.attr("class").split(" ")[0].indexOf("abs") === 0) {
          type = "dropAbs";
        } else if (ui.draggable.attr("class").split(" ")[0].indexOf("reg") === 0) {
          type = "dropReg";
        } else if (ui.draggable.attr("class").split(" ")[0].indexOf("hor") === 0) {
          type = "dropHor";
        } else if (ui.draggable.attr("class").split(" ")[0].indexOf("act_") === 0) {
          const typo = ui.draggable.attr("class").split(" ")[0].split("act_")[1];
          type = "dropAct_" + typo;
          buttonCode = ui.draggable.data("data");
        }

        if (!CWSTR.isBlank(this.workflow.selectionMonthModel.get("dateStart")) && !CWSTR.isBlank(this.workflow.selectionMonthModel.get("dateEnd"))) {
          const startDay = this.workflow.selectionMonthModel.get("dateStart");
          const endDay = this.workflow.selectionMonthModel.get("dateEnd");

          this.workflow.trigger("popup:open", type, this.workflow.get("collabId"), code, startDay, endDay, undefined, undefined, libelle, buttonCode);
        } else {
          const startDay = div.attr("data-date");
          const endDay = div.attr("data-date");

          this.workflow.trigger("popup:open", type, this.workflow.get("collabId"), code, startDay, endDay, undefined, undefined, libelle, buttonCode);
        }
      }
    });
  }

  render(callback?: () => void): CWFormView {
    const json = { 'i18n': i18n };

    this.$el.html(this.template(json));
    if (CWSTR.isBlank(this.agendaZone)) {
      this.agendaZone = $(".agenda_zone_center:visible").width();
    }
    this._drawWeeks();
    this.$el.find(".phx-agenda-monthly-dynstyles").html(CWRENDER.refreshDynamicCSS(this.workflow.dtsModel.GROUPS_ABSENCE, "abs", ".agenda_zone_center_C_monthly"));
    this._drawCalendar();
    this._drawTotalisations();
    this._setDroppable(this.$el.find(".vuemensuel-day"));

    if (callback) {
      callback();
    }
    return this;
  }

  _drawWeeks(): void {
    const drawData: any = {};
    drawData.cMonth = this.currentMonth;
    drawData.cYear = this.currentYear;
    drawData.nbRows = UTILS.getNumWeeksForMonth(drawData.cMonth + 1, drawData.cYear);
    drawData.today = new Date();

    const todayWeek = $.datepicker.iso8601Week(drawData.today);

    let selectedWeek;
    if (!CWSTR.isBlank(this.selectedDate)) {
      selectedWeek = $.datepicker.iso8601Week(CWTYPE.DATE.strToDate(this.selectedDate));
    }

    const content = this.$el.find(".vuemensuel-weeks");
    content.append($("<div class=\"vuemensuel-header\">"));

    for (let i = 0; i < drawData.nbRows; ++i) {
      const weekDay = new Date(drawData.cYear, drawData.cMonth, (i * 7 + 1));
      const weekNumber = $.datepicker.iso8601Week(weekDay);
      const weekRow = $("<div class=\"vuemensuel-row\">");
      const nbWeek = $("<div class=\"vuemensuel-nweek\">");
      const nbWeekWrapper = $("<div>");

      nbWeekWrapper.text(weekNumber);
      if (String(weekNumber) === String(todayWeek)) {
        nbWeek.addClass("ui-phx-date-heure-actuelle");
      }
      if (!CWSTR.isBlank(selectedWeek) && String(weekNumber) === String(selectedWeek)) {
        nbWeek.addClass("ui-phx-date-heure-selectionnee");
      }
      nbWeek.append(nbWeekWrapper);
      weekRow.append(nbWeek);
      content.append(weekRow);
    }
  }

  _drawCalendar(): any {
    if (this.workflow.get("viewType") === "M") {
      const drawData: { [key: string]: any } = {};
      const calendar = this.$el.find(".vuemensuel-days");
      let mFirstDay = null;

      drawData.cMonth = this.currentMonth;
      drawData.cYear = this.currentYear;
      // min number of rows 5
      drawData.nbRows = (UTILS.getNumWeeksForMonth(drawData.cMonth + 1, drawData.cYear) > 5) ? UTILS.getNumWeeksForMonth(drawData.cMonth + 1, drawData.cYear) : 5;
      drawData.nbDays = UTILS.daysInMonth(drawData.cMonth, drawData.cYear);
      drawData.prevMonthDays = UTILS.daysInMonth(drawData.cMonth - 1, drawData.cYear);
      drawData.today = new Date();
      mFirstDay = new Date(drawData.cYear, drawData.cMonth, "01" as any);
      drawData.initDay = mFirstDay.getDay();
      calendar.empty();
      calendar.append(this._drawHeader(drawData));
      calendar.append(this._drawDays(drawData));
      this.workflow.presenceAfterEvent = false;
      this.$el.find(".vuemensuel-calendrier").removeClass("r6").removeClass("r5");
      this.$el.find(".vuemensuel-calendrier").addClass("r" + drawData.nbRows);
      if (!CWSTR.isBlank(this.workflow.get("options").get("affSemTrevail")) && this.workflow.get("options").get("affSemTrevail")) {
        this.$el.find(".vuemensuel-calendrier").addClass("c5");
      } else {
        this.$el.find(".vuemensuel-calendrier").addClass("c7");
      }
      if (this.workflow.activeTab === 1) {
        this.$el.find(".ui-phx-date-heure-actuelle-border").removeClass('ui-phx-date-heure-actuelle-border');
      }
      this.calendar = calendar;
    }
  }

  _drawHeader(drawData: any): JQuery {
    const selectedDay = CWTYPE.DATE.strToDate(this.selectedDate).getDay();
    const output = $("<div class=\"vuemensuel-days-header vuemensuel-header\">");
    const cell = $("<div class=\"vuemensuel-cell-header\">");

    for (let i = 1; i < 7; ++i) {
      const cell = $("<div class=\"vuemensuel-cell-header\">");

      cell.text(i18n.t('common:dayNames', { returnObjects: true })[i]);
      if (drawData.today.getMonth() === drawData.cMonth && drawData.today.getFullYear() === drawData.cYear && String(drawData.today.getDay()) === String(i)) {
        cell.addClass("ui-phx-date-heure-actuelle");
      }
      if (selectedDay === i) {
        cell.addClass("ui-phx-date-heure-selectionnee");
      }
      output.append(cell);
    }
    cell.text(i18n.t('common:dayNames', { returnObjects: true })[0]);
    if (drawData.today.getDay() === 0) {
      cell.addClass("ui-phx-date-heure-actuelle");
    }
    if (selectedDay === 0) {
      cell.addClass("ui-phx-date-heure-selectionnee");
    }
    output.append(cell);
    return output;
  }

  _drawDays(drawData: any): JQuery {
    const day = new Date(drawData.cYear, drawData.cMonth, 0);
    const selectedDay = CWTYPE.DATE.strToDate(this.selectedDate);
    let displayHorTop = true;
    const output = $("<div class=\"vuemensuel-days-body\">");

    if (GLOBAL_DATA.paramDivers.get("AgdMHorEvt") && parseInt(GLOBAL_DATA.paramDivers.get("AgdMHorEvt").get("valeur"), 10) === 2) {
      displayHorTop = false;
    }

    if ((drawData.initDay) !== "1") {
      day.setDate(day.getDate() - (drawData.initDay === 0 ? 7 : drawData.initDay) + 1);
    }

    for (let i = 0; i < drawData.nbRows; ++i) {
      const wrow = $("<div class=\"vuemensuel-row\">");

      for (let j = 0; j < 7; ++j) {
        day.setDate(day.getDate() + 1);
        const dateformat = String(day.getFullYear()) + ((day.getMonth() + 1) < 10 ? "0" + (day.getMonth() + 1) : (day.getMonth() + 1)) + (day.getDate() < 10 ? "0" + day.getDate() : day.getDate());
        const ele = this.workflow.mainModel.models[0].get("cel").get(dateformat);
        let hcon = false;
        if (ele) {
          hcon = ele.get("hcon");
        }

        const cell = $("<div class=\"vuemensuel-day vuemensuel-cell\" data-type=\"\" data-date=\"\">");
        const cellHeader = this._drawCellHeader($("<div class=\"vuemensuel-day-header\">"), day.getDate(), dateformat, (String(day.getMonth()) === String(drawData.cMonth)), displayHorTop);

        if (hcon) {
          cell.addClass("ui-phx-hors-contrat");
          cell.append(cellHeader);
        } else {

          const cellVueMensuelBody = $("<div class=\"vuemensuel-day-body\">");
          const cellBody = this._drawCellBody(cellVueMensuelBody, dateformat, displayHorTop);
          const cellFooter = this._drawCellFooter($("<div class=\"vuemensuel-day-footer\">"), dateformat, cellBody);

          if (day.getFullYear() === drawData.today.getFullYear() &&
            day.getMonth() === drawData.today.getMonth() &&
            day.getDate() === drawData.today.getDate()) {
            let idx = -1;
            if (!CWSTR.isBlank(this.selectPresence.get('selPresence')) && this.workflow.activeTab === 1) {
              const dPresence = CWTYPE.DATE.dateToStr(drawData.today).split('/')[2] + CWTYPE.DATE.dateToStr(drawData.today).split('/')[1] + CWTYPE.DATE.dateToStr(drawData.today).split('/')[0];
              idx = _.indexOf(this.selectPresence.get('selPresence'), dPresence);
            }
            if (this.workflow.activeTab === 1 && idx > -1 && !this.workflow.presenceAfterEvent) {
              cell.addClass("ui-phx-date-heure-actuelle-border");
            } else {
              cell.addClass("ui-phx-date-heure-actuelle-border");
            }
          }

          if (!CWSTR.isBlank(selectedDay) && i === UTILS.getWeekNumOfDate(selectedDay) - 1 && j === (selectedDay.getDay() - 1)) {
            if (this.workflow.activeTab !== 1 && !CWSTR.isBlank(this.workflow.activeTab)) {
              cell.addClass("ui-phx-date-heure-selectionnee-border-imp");
            }
            cell.addClass("js-heure-selectionnee");
          }
          if (!CWSTR.isBlank(selectedDay) && i === UTILS.getWeekNumOfDate(selectedDay) - 1 && selectedDay.getDay() === 0 && j === 6) {
            if (this.workflow.activeTab !== 1 && !CWSTR.isBlank(this.workflow.activeTab)) {
              cell.addClass("ui-phx-date-heure-selectionnee-border-imp");
            }
            cell.addClass("js-heure-selectionnee");
          }

          if (String(day.getMonth()) !== String(drawData.cMonth)) {
            cell.attr("data-type", "out");
          } else {
            if (this.isJourExceptionnel(dateformat)) { //declaré dans cwMontlyCalendar.view
              cell.attr("data-type", "exceptionnel");
              cellHeader.addClass("ui-phx-jours-exceptionnel");
            } else if (this.isJourFerie(dateformat)) {//declaré dans cwMontlyCalendar.view
              cell.attr("data-type", "ferie");
              cellHeader.addClass("ui-phx-jours-ferie");
            } else if (this.isJourHorRepos(dateformat)) {//declaré dans cwMontlyCalendar.view
              if (!ele.get("presence")) {
                cell.attr("data-type", "repos");
                cell.addClass("ui-phx-horaire-repos");
              }
            } else {
              cell.attr("data-type", "normal");
            }
          }
          cell.attr("data-date", dateformat);
          cell.attr("data-week", $.datepicker.iso8601Week(day));

          cell.append(cellHeader).append(cellBody).append(cellFooter);
        }
        wrow.append(cell);
      }
      output.append(wrow);
    }

    return output;
  }

  _drawCellHeader(wrapper: JQuery, nbDay: number, format: string, inMonth: boolean, displayHorTop: boolean): JQuery {
    const headerLeft = $("<span class=\"vuemensuel-day-header-number\">");
    const headerRight = $("<span class=\"vuemensuel-day-header-info\">");
    let displayColor = true;
    const pk = this.workflow.get("collabId") + "," + format;
    let habRegAst = "RES_REG.V";//Restitution Régularisation d'astreintes
    const cell = this.workflow.mainModel.models[0].get("cel").get(format);
    const ele = this.workflow.mainModel.models[0].get("cel").get(format);
    let showZones = 0;

    if (this.workflow.get("utilisateur") === this.workflow.COLLABORATEUR_TYPE) {
      habRegAst = "COL_REG.V";
    }
    if (this.workflow.evtModel.hasEvent("ASTREINTES", pk) && CWHABILITATION.canView(habRegAst) && this.workflow.getLayersModel().get("ast") === true) {
      wrapper.css("border-bottom", "4px solid black");
    }
    if (this.context && this.context.ctxEcran !== "agenda") {
      if (this.context && parseInt(this.context.ctxHorAffNa) === 0) {
        displayColor = false;
      }
    } else {
      if (GLOBAL_DATA.paramDivers.get("AgdMHorCnh") && parseInt(GLOBAL_DATA.paramDivers.get("AgdMHorCnh").get("valeur"), 10) === 0) {
        displayColor = false;
      }
    }
    if (inMonth === true) {
      if (displayHorTop) {
        if (!_.isEmpty(cell) && (_.isEmpty(cell.get("forfaitj")) || String(cell.get("forfaitj").code) === "0" || CWSTR.isBlank(cell.get("forfaitj").code))) {
          let tooltip: string = null;

          headerRight.text(this.headerRender(format)); //declaré dans cwMontlyCalendar.view
          //tooltip
          tooltip = this._buildHoraireTooltip(format);
          headerRight.attr("title", tooltip);
        } else {
          if (this.isJourHorRepos(format) || this.isHoraireExceptionnel(format)) {
            headerRight.text(this.headerRender(format)); //declaré dans cwMontlyCalendar.view
          }
        }
        if (displayColor && !ele.get("presence")) {
          if (!this.isJourExceptionnel(format) && !this.isJourFerie(format)) {
            headerLeft.addClass(this.headerColor(format)); //declaré dans cwMontlyCalendar.view
          }
          headerRight.addClass(this.headerColor(format));
        }
        headerRight.addClass("js-horaire");
      }
    }
    headerLeft.text(nbDay);
    this._headerLeftTooltip(headerLeft, format);
    wrapper.append(headerLeft).append(headerRight);
    if (GLOBAL_DATA.paramDivers.get("GTvissco")) {
      showZones = parseInt(GLOBAL_DATA.paramDivers.get("GTvissco").get("valeur"), 10);
    }
    if (showZones === 0 || showZones === 1) {
      const day = this.workflow.mainModel.models[0].get("cel").get(format);

      if (!CWSTR.isBlank(day)) {
        const zone = day.get("zsc");

        if (!CWSTR.isBlank(zone)) {
          const lisereZone = $("<span class=\"lisere lisere-zone\">");

          lisereZone.css("background-color", "rgb(" + zone.coul.coder + ", " + zone.coul.codeg + ", " + zone.coul.codeb + ")");
          lisereZone.attr("title", zone.lib);
          wrapper.append(lisereZone);
        }
      }
    }
    if (!CWSTR.isBlank(this.workflow.marqueursModel)) {
      const marqueurDiv = $("<div class='marqueurDiv'>");
      const marq: any = _.find(this.workflow.marqueursModel.get("marqueurs"), { dated: format });
      let typeMarq: any = "";

      if (!CWSTR.isBlank(marq)) {
        typeMarq = _.findWhere(this.workflow.marqueursModel.get("typesmarqueur"), { code: marq.marqueur });
        if (!CWSTR.isBlank(typeMarq)) {
          marqueurDiv.addClass(typeMarq.style.affichage);
          marqueurDiv.attr('title', this.workflow.buildMarqTooltip(typeMarq));
          wrapper.append(marqueurDiv);
        }
      }
    }
    return wrapper;
  }

  _headerLeftTooltip(headerLeft: any, date: string): void {
    const table = this.$el.find("table.phx-agenda-center-header-dais");// Days
    const tr = $("<tr>");
    const dateTemp = new Date(CWTYPE.DATE.strToDate(date).getTime());
    const td = $("<td>");// First row with day names
    const actualStrDate = CWTYPE.DATE.dateToStr(dateTemp, CWTYPE.DATE.DEFAULT_MASK_BBDD);
    let jex = false;
    let jfe = false;
    const element = this.workflow.mainModel.models[0].get("cel").get(actualStrDate);
    let styleClass = "";
    let headerTitle: string = null;
    let dc: string = null;
    let dep: string = null;
    let div: string = null;
    let hab = "COL_AFFPRINC.V";

    table.empty();
    // Set day property
    td.prop("day", actualStrDate);
    //Apply style colors to the headers
    if (element && String(element.get("jex")) === "true") {
      styleClass = "ui-phx-jours-exceptionnel";
      jex = true;
    } else if (element && String(element.get("jfe")) === "true") {
      styleClass = "ui-phx-jours-ferie";
      jfe = true;
    } else if (dateTemp.getDay() === 0 || dateTemp.getDay() === 6) {
      const pk = this.workflow.get("collabId") + "," + actualStrDate;
      let hasRepos = false;

      if (this.workflow.evtModel.hasEvent("HORAIRES", pk)) {
        const evts = this.workflow.evtModel.getEvent("HORAIRES", pk).get("evt");

        if (evts) {
          for (let i = 0; i < evts.length; i++) {
            const cod = evts.at(i).get("cod");
            const dts = this.workflow.dtsModel.HORAIRES.get(cod);

            if (!CWSTR.isBlank(dts) && String(dts.get("re")) === "true") {
              hasRepos = true;
              break;
            }
          }
        }
      }
      if (hasRepos === false) {
        styleClass = "ui-phx-entete-jour-week-end";
      }
    }
    headerTitle = "<b>" + CWTYPE.DATE.format(date, CWTYPE._getFormatByCode("DATE_L")) + i18n.t('agenda.headerTitleSem', { "0": $.datepicker.iso8601Week(CWTYPE.DATE.strToDate(date)) }) + "</b>";
    if (jex === true) {
      headerTitle += "<br/>" + i18n.t('agenda.headerTitleJex');
    } else if (jfe === true) {
      headerTitle += "<br/>" + i18n.t('agenda.headerTitleJfl');
    }
    dc = this.workflow.mainModel.models[0].get("dc");
    if (!CWSTR.isBlank(dc)) {
      if (actualStrDate <= dc) {
        const titlePc = "<label>" + i18n.t('agenda.headerTitlePcPerio') + " </label>" + CWTYPE.DATE.format(dc);

        headerTitle += "<br/>" + titlePc;
      } else {
        const titlePnc = "<label>" + i18n.t('agenda.headerTitlePncPerio') + " </label>" + CWTYPE.DATE.format(dc);

        headerTitle += "<br/>" + titlePnc;
      }
    }
    dep = this.workflow.mainModel.models[0].get("dep");
    if (!CWSTR.isBlank(dep)) {
      if (actualStrDate <= dep) {
        const titlePe = "<label>" + i18n.t('agenda.headerTitlePePerio') + " </label>" + CWTYPE.DATE.format(dep);

        headerTitle += "<br/>" + titlePe;
      } else {
        const titlePne = "<label>" + i18n.t('agenda.headerTitlePnePerio') + " </label>" + CWTYPE.DATE.format(dep);

        headerTitle += "<br/>" + titlePne;
      }
    }
    // ::::::: add Structure P to HeaderTooltip ::::::::::::::
    if (this.workflow.get("utilisateur") === this.workflow.RESPONSABLE_TYPE) {
      hab = "RES_AFFPRINC.V";
    }
    if (CWHABILITATION.canView(hab) === true) {
      headerTitle = headerTitle += "<br/>";
      headerTitle = headerTitle + this._buildStructTooltip(actualStrDate);
    }
    div = "<div title='" + headerTitle + "' style='position:relative;text-align:center'";
    if (this._isDateSelected(dateTemp) === true) {
      div += " class='ui-phx-date-heure-selectionnee " + styleClass + "'";
    } else if (this._isDateToday(dateTemp) === true && this._isDateSelected(dateTemp) === false && ((element && String(element.get("jfe")) === "false" && String(element.get("jex")) === "false") || !element)) {
      div += " class='ui-phx-date-heure-actuelle " + styleClass + "'";
    } else if (!CWSTR.isBlank(styleClass)) {
      div += " class='" + styleClass + "'";
    }
    div += "><label style='left:5px;position:absolute;'>" + dateTemp.getDate() + "</label><label style='margin-left:10px'>" + i18n.t('common:dayNames', { returnObjects: true })[dateTemp.getDay()] + "</label></div>";
    td.html(div);
    tr.append(td);
    headerLeft.attr("title", headerTitle);
  }

  _isDateToday(date: Date): boolean {
    let result = false;
    if (date && CWTYPE.DATE.dateToStr(date) === CWTYPE.DATE.dateToStr(SYNC.getServerDate())) {
      result = true;
    }
    return result;
  }

  _isDateSelected(date: Date): boolean {
    let result = false;
    const selectedDate = CWTYPE.DATE.strToDate(this.workflow.get("selectedDate"));
    if (date && CWTYPE.DATE.dateToStr(date) === CWTYPE.DATE.dateToStr(selectedDate)) {
      result = true;
    }
    return result;
  }

  _buildStructTooltip(date: string): string {
    let tooltip = "";
    const affDate = date;
    const affCod = this._getCodePrinc(affDate);

    if (CWSTR.isBlank(affCod)) {
      return tooltip;
    }

    const dtsModel = this.workflow.dtsModel.AFFECTATIONS.get(affCod);

    if (!CWSTR.isBlank(dtsModel)) {
      let txtTitle = "";

      txtTitle = "<span>" + i18n.t('agenda.tt_aff_princ') + "</span><br/>";
      tooltip += txtTitle;
      tooltip += this._getTooltipText(dtsModel);
    }

    return tooltip;
  }

  _getCodePrinc(date: string): string {
    let prevCodePrinc = null;

    const pk = this.workflow.get("collabId") + "," + date;
    if (this.workflow.evtModel.hasEvent(this.workflow.AFFECTATIONS_TYPE, pk)) {
      const evts = this.workflow.evtModel.getEvent(this.workflow.AFFECTATIONS_TYPE, pk).get("evt");
      if (evts) {
        for (let i = 0; i < evts.length; i++) {
          const evt = evts.at(i);
          prevCodePrinc = evt.get("dts").ppl;
          break;
        }
      }
    }
    return prevCodePrinc;
  }

  _getTooltipText(dtsModel: any): string {
    let txt = "";

    if (!CWSTR.isBlank(dtsModel) && !CWSTR.isBlank(dtsModel.get("dts"))) {
      const levels = dtsModel.get("dts");
      txt = "<table style=\"width:500px\">";
      let marginLeft = 0;
      const tabAux = "<span class=\"ui-icon\" style=\"display:inline-block; background:none; padding:0; margin-left:10px\"></span>";
      if (levels[0].niv > 0) {
        // paint chemin when node is not a root node
        for (let j = 1; j < levels.length; j++) {
          // const tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";
          const tab = "<span style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\">" + UTILS.getSVGIcon('fleche_droite_arrondie', '', 12) + "</span>";
          if (j === 1) {
            txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + levels[j].lib + "(" + levels[j].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + i18n.t('common:chemin.structure') + "</td></tr>";
          } else {
            const niveau = levels[j].lbnv;
            txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + tab + levels[j].lib + "(" + levels[j].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + niveau + "</td></tr>";
            marginLeft += 10;
          }
        }
        // const tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";
        const tab = "<span style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\">" + UTILS.getSVGIcon('fleche_droite_arrondie', '', 12) + "</span>";
        const niveau = levels[0].lbnv;
        txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + tab + levels[0].lib + "(" + levels[0].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + niveau + "</td></tr>";
        txt += "</table>";
      } else {
        // paint chemin when node is a root node
        const tabAux = "<span class=\"ui-icon\" style=\"display:inline-block; background:none; padding:0; margin-left:10px\"></span>";
        txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + levels[0].lib + "(" + levels[0].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + i18n.t('common:chemin.structure') + "</td></tr>";
      }
    }
    return txt;
  }

  /**
   * Creating a structure that has divs by events in every cell
   */
  _drawCellBody(container: JQuery, dateformat: string, displayHorTop: boolean): JQuery {
    const arrayEvents = this._eventsInDay(dateformat, displayHorTop);
    const ele = this.workflow.mainModel.models[0].get("cel").get(dateformat);
    let typeBlock = "";
    const cellVueMensuelContainer = $("<div class='vuemensuel-day-body-container'>");

    if (arrayEvents.length > 0) {
      const hasHoraire = _.find(arrayEvents, e => { return e.type === 'HORAIRES'; });

      for (let i = 0; i < arrayEvents.length; i++) {
        const events = this._renderCellBody(arrayEvents[i], dateformat);

        if (!CWSTR.isBlank(events) && events.length > 0) {
          _.each(events, (event: any) => {
            cellVueMensuelContainer.append(event);
          });
        }
      }

      if (!CWSTR.isBlank(ele)) {
        if (ele.get("presence") && !CWSTR.isBlank(ele.get("forfaitj")) && ele.get("forfaitj").code !== "0") {
          cellVueMensuelContainer.css({ "width": "91%", "margin": "auto", "top": "1px", "left": "0", "right": "0", "bottom": "0" });
          cellVueMensuelContainer.find("div").css("border-radius", "5px");
          if (!CWSTR.isBlank(hasHoraire)) {
            const dts = this.workflow.dtsModel.HORAIRES.get(hasHoraire.events[0].get("evt").models[0].get("val"));
            const nature = this.workflow.mainModel.dtsModel.NATHOR.get(dts.get("nat"));
            if (dts.get("hm") && !dts.get("hs")) {
              typeBlock = "MAT";             // horaire matin
            }
            if (!dts.get("hm") && dts.get("hs")) {
              typeBlock = "SOIR";            // horaire soir
            }
            //const typeBlock = dts.get("nat");
            if (!CWSTR.isBlank(nature)) {
              container.append(this._paintForfaij($("<div class='forfaitj-cell'>"), nature.get("aff"), typeBlock));
            }
          } else {
            container.append(this._paintForfaij($("<div class='forfaitj-cell'>"), "ui-phx-color-blanc_1", typeBlock));
          }
        }
      }
    } else {
      if (!CWSTR.isBlank(ele)) {
        if (ele.get("presence") && !CWSTR.isBlank(ele.get("forfaitj")) && ele.get("forfaitj").code !== "0") {
          container.append(this._paintForfaij($("<div class='forfaitj-cell'>"), "ui-phx-color-blanc_1", typeBlock));
        }
      }
    }
    return container.prepend(cellVueMensuelContainer);
  }

  /**
   * We declare an array that will have:
   * type - is the name witch we like to call the divs (class) that will be created.
   * events - is the array with all the events from every kind of evenements
   * number - is to know the number of event (of every kind of evenements) that we will create
   */
  _eventsInDay(dateformat: string, displayHorTop: boolean): any[] {
    const pk = this.workflow.get("collabId") + "," + dateformat;
    let events: any = [];
    let typeEvent;
    const eventsTypArray = [];
    const ele = this.workflow.mainModel.models[0].get("cel");
    let eleDate = null;
    if (!CWSTR.isBlank(ele.get(dateformat))) {
      eleDate = this.workflow.mainModel.models[0].get("cel").get(dateformat);
    }

    //Horaires
    if (!displayHorTop || (!CWSTR.isBlank(eleDate) && !CWSTR.isBlank(eleDate.get("forfaitj")) && eleDate.get("forfaitj").code !== "0")) {
      if (this.workflow.evtModel.hasEvent("HORAIRES", pk)) {
        events = [];
        typeEvent = this.workflow.evtModel.get("HORAIRES");

        for (let i = 0; i < typeEvent.length; i++) {
          if (dateformat === typeEvent.at(i).get("dat")) {
            const tpe = typeEvent.at(i);
            events.push(tpe);
          }
        }

        if (events.length > 0) {
          eventsTypArray.push({
            "type": "HORAIRES",
            "events": events,
            "numberEvents": events.length
          });
        }
      }
    }

    //Presences
    if (this.workflow.evtModel.hasEvent("PRESENCES", pk)) {
      events = [];
      typeEvent = this.workflow.evtModel.get("PRESENCES");

      for (let i = 0; i < typeEvent.length; i++) {
        if (dateformat === typeEvent.at(i).get("dat")) {
          events.push(typeEvent.at(i));
        }
      }

      if (events.length > 0) {
        this.typeGerer = "badgeages";
        eventsTypArray.push({
          "type": "PRESENCES",
          "events": events,
          "numberEvents": events.length
        });
      } else {
        this.typeGerer = "onebadgeage";
      }
    }

    //Affectations secondaires
    if (this.workflow.evtModel.hasEvent("AFFECTATIONS", pk)) {
      events = [];
      typeEvent = this.workflow.evtModel.get("AFFECTATIONS");

      for (let i = 0; i < typeEvent.length; i++) {
        if (dateformat === typeEvent.at(i).get("dat")) {
          const affec = typeEvent.at(i);

          if (affec.get("evt").models[0].get("dts").sec.length > 0) {
            // eslint-disable-next-line no-loop-func
            _.each(affec.get("evt").models[0].get("dts").sec, (affsec: any) => {
              events.push(affsec);
            });
          }
        }
      }

      if (events.length > 0) {
        eventsTypArray.push({
          "type": "AFFECTATIONS",
          "events": events,
          "numberEvents": events.length
        });
      }
    }

    //Prets
    if (this.workflow.evtModel.hasEvent("PRETS", pk)) {
      events = [];
      typeEvent = this.workflow.evtModel.get("PRETS");

      for (let i = 0; i < typeEvent.length; i++) {
        if (dateformat === typeEvent.at(i).get("dat")) {
          events.push(typeEvent.at(i));
        }
      }

      if (events.length > 0) {
        eventsTypArray.push({
          "type": "PRETS",
          "events": events,
          "numberEvents": events.length
        });
      }
    }

    //Absences
    if (this.workflow.evtModel.hasEvent("ABSENCES", pk)) {
      events = [];
      typeEvent = this.workflow.evtModel.get("ABSENCES");

      for (let i = 0; i < typeEvent.length; i++) {
        if (dateformat === typeEvent.at(i).get("dat")) {
          events.push(typeEvent.at(i));
        }
      }

      if (events.length > 0) {
        eventsTypArray.push({
          "type": "ABSENCES",
          "events": events,
          "numberEvents": events.length
        });
      }
    }

    //Activites
    if (this.workflow.evtModel.hasEvent("ACTIVITES", pk)) {
      events = [];
      typeEvent = this.workflow.evtModel.get("ACTIVITES");

      for (let i = 0; i < typeEvent.length; i++) {
        if (dateformat === typeEvent.at(i).get("dat")) {
          events.push(typeEvent.at(i));
        }
      }

      if (events.length > 0) {
        eventsTypArray.push({
          "type": "ACTIVITES",
          "events": events,
          "numberEvents": events.length
        });
      }
    }

    return eventsTypArray;
  }

  _renderCellBody(eventContainer: any, dateformat: any): Array<any> {
    const events: any = [];
    const typeEvent = eventContainer.type;

    const date = dateformat;
    const plusOpt = this.workflow.get("plusopt");

    switch (typeEvent) {
      case "HORAIRES":
        if (plusOpt.get("plusHorJ") === true) {
          _.each(eventContainer.events[0].get("evt").models, (event: JQueryEventObject) => {
            events.push(this._renderHoraire($("<div data-typeEvent='HORAIRES' >"), event, date));
          });
        }
        break;
      case "PRESENCES":
        if (plusOpt.get("plusPreMis") === true) {
          events.push(this._renderPresences($("<div data-typeEvent='PRESENCES'>"), eventContainer.events[0].get("evt").models, date));
        }
        break;
      case "PRETS":
        if (plusOpt.get("plusAff") === true) {
          _.each(eventContainer.events[0].get("evt").models, (event: any) => {
            events.push(this._renderPrets($("<div data-typeEvent='PRETS'>"), event, date));
          });
        }
        break;
      case "AFFECTATIONS":
        if (plusOpt.get("plusAff") === true) {
          _.each(eventContainer.events, (event: any) => {
            events.push(this._renderAffectations($("<div data-typeEvent='AFFECTATIONS'>"), event));
          });
        }
        break;
      case "ABSENCES":
        _.each(eventContainer.events[0].get("evt").models, (event: any) => {
          if (event.get("dts").sou === true && plusOpt.get("plusSou") === true) {
            events.push(this._renderAbsences($("<div data-typeEvent='ABSENCES'>"), event, date));
          } else if (event.get("dts").sou === false && plusOpt.get("plusAbs") === true) {
            events.push(this._renderAbsences($("<div data-typeEvent='ABSENCES'>"), event, date));
          }
        });
        break;
      case "ACTIVITES":
        _.each(eventContainer.events[0].get("evt").models, (model: any) => {
          if (plusOpt.get("plus" + model.get("dts").tpo) === true && this._filterActivitesByPreferences(date, model)) {
            events.push(this._renderActivites($("<div data-typeEvent='ACTIVITES'>"), model, date));
          }
        });
        break;
      default:
        break;
    }

    return events;
  }

  _filterActivitesByPreferences(dateformat: any, event: any): boolean {
    let preference;
    let filtered;
    const today = CWTYPE.DATE.getCurrentDate();
    const typeActivite = event.get('dts').typ;

    if (typeActivite === "P") {
      preference = this.workflow.preferences.getValue("AG_OPT_AFF_ACT", null, "varint1");
      switch (preference) {
        case 0: // Aujourd'hui
          filtered = CWTYPE.DATE.getDiffInDays(today, dateformat) >= 0 ? true : false;
          break;
        case 1: {// Demain 
          const tomorrowDate = CWTYPE.DATE.strToDate(today);
          tomorrowDate.setDate(tomorrowDate.getDate() + 1);
          //const tomorrowFormat = "" + tomorrowDate.getFullYear() + ((tomorrowDate.getMonth() + 1) < 10 ? "0" + (tomorrowDate.getMonth() + 1) : (tomorrowDate.getMonth() + 1)) + (tomorrowDate.getDate() < 10 ? "0" + tomorrowDate.getDate() : tomorrowDate.getDate());
          const tomorrowFormat = CWTYPE.DATE.dateToStr(tomorrowDate, CWTYPE.DATE.DEFAULT_MASK_BBDD);
          filtered = CWTYPE.DATE.getDiffInDays(tomorrowFormat, dateformat) >= 0 ? true : false;
        }
          break;
        default:
          filtered = true;
      }
    } else {
      preference = this.workflow.preferences.getValue("AG_OPT_AFF_ACT", null, "varint2");
      switch (preference) {
        case 0: {// Hier
          const yesterdayDate = CWTYPE.DATE.strToDate(today);
          yesterdayDate.setDate(yesterdayDate.getDate() - 1);
          //const yesterdayFormat = "" + yesterdayDate.getFullYear() + ((yesterdayDate.getMonth() + 1) < 10 ? "0" + (yesterdayDate.getMonth() + 1) : (yesterdayDate.getMonth() + 1)) + (yesterdayDate.getDate() < 10 ? "0" + yesterdayDate.getDate() : yesterdayDate.getDate());
          const yesterdayFormat = CWTYPE.DATE.dateToStr(yesterdayDate, CWTYPE.DATE.DEFAULT_MASK_BBDD);
          filtered = CWTYPE.DATE.getDiffInDays(yesterdayFormat, dateformat) <= 0 ? true : false;
        }
          break;
        case 1: // Aujourd'hui
          filtered = CWTYPE.DATE.getDiffInDays(today, dateformat) <= 0 ? true : false;
          break;
        default:
          filtered = true;
      }
    }

    return filtered;
  }

  _paintForfaij(wrapper: any, color: any, typeBlock: any): any {
    wrapper.addClass(color);
    wrapper.css("height", "calc(100% - 4px)");
    if (typeBlock === "MAT") {
      wrapper.addClass("cell-matin");
    } else if (typeBlock === "SOIR") {
      wrapper.addClass("cell-apresmidi");
    } else {
      wrapper.addClass("cell-jour");
    }
    return wrapper;
  }

  _renderHoraire(wrapper: any, event: any, dateformat: any): void {
    const dts = this.workflow.dtsModel.HORAIRES.get(event.get("val"));
    const ele = this.workflow.mainModel.models[0].get("cel").get(dateformat);
    let horEvt = null;
    let typeForfait = "";

    if (GLOBAL_DATA.paramDivers.get("AgdMHorEvt")) {
      horEvt = parseInt(GLOBAL_DATA.paramDivers.get("AgdMHorEvt").get("valeur"), 10)
    }

    if (!CWSTR.isBlank(ele.get("forfaitj"))) {
      typeForfait = ele.get("forfaitj").code;
    }

    if (!ele.get("presence") || this.isHoraireExceptionnel(dateformat)) {
      if ((!CWSTR.isBlank(ele.get("forfaitj")) && typeForfait === "0") || (dts.get("nat") === "REP" && horEvt !== 1) || (this.isHoraireExceptionnel(dateformat) && horEvt === 2 && !CWSTR.isBlank(ele.get("forfaitj")) && typeForfait !== "0")) {
        if (!CWSTR.isBlank(dts)) {
          //Text
          let typeHor = 1;

          if (GLOBAL_DATA.paramDivers.get("AgdMHorInf")) {
            typeHor = parseInt(GLOBAL_DATA.paramDivers.get("AgdMHorInf").get("valeur"), 10);
          }

          const horaireTdu = $("<span class=\"horaire-tdu\">");
          let horaireLibelle: any = null;

          if (dts.get("re") === true) {
            horaireLibelle = $("<span class=\"horaire-libelle-only\">");
            horaireLibelle.text(dts.get("ab8"));
          } else {
            let suffix = "";

            if (this.isHoraireExceptionnel(dateformat)) { //declaré dans cwMonthlyCalendar.view
              suffix = "*";
            }

            if (typeHor === 1) {
              horaireLibelle = $("<span class=\"horaire-libelle-ext\">");
              horaireLibelle.text(suffix + dts.get("ab4") + " ");
              horaireTdu.text(CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.get("ho")) + "..." + CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.get("hf")));
            } else if (typeHor === 2) {
              horaireLibelle = $("<span class=\"horaire-libelle\">");
              horaireLibelle.text(suffix + dts.get("lib") + " ");
              horaireTdu.text(CWTYPE.HOUR_MINUTE.format(dts.get("tdu"), CWTYPE._getFormatByCode("DUREEHM")));
            } else if (typeHor === 3) {
              horaireLibelle = $("<span class=\"horaire-libelle\">");
              horaireLibelle.text(suffix + dts.get("ab8") + " ");
              horaireTdu.text(CWTYPE.HOUR_MINUTE.format(dts.get("tdu"), CWTYPE._getFormatByCode("DUREEHM")));
            }
          }

          wrapper.append(horaireLibelle).append(horaireTdu);

          //Color
          let displayColor = true;

          if (this.context && this.context.ctxEcran !== "agenda") {
            if (this.context && parseInt(this.context.ctxHorAffNa) === 0) {
              displayColor = false;
            }
          } else {
            if (GLOBAL_DATA.paramDivers.get("AgdMHorCnh") && parseInt(GLOBAL_DATA.paramDivers.get("AgdMHorCnh").get("valeur"), 10) === 0) {
              displayColor = false;
            }
          }

          if (!CWSTR.isBlank(ele.get("forfaitj")) && !CWSTR.isBlank(ele.get("forfaitj").code) && ele.get("forfaitj").code !== "0") {
            displayColor = false;
          }

          if (displayColor && parseInt(GLOBAL_DATA.paramDivers.get("AgdMHorCnh").get("valeur"), 10) === 1) {
            const nature = this.workflow.mainModel.dtsModel.NATHOR.get(dts.get("nat"));

            if (!CWSTR.isBlank(nature)) {
              wrapper.addClass(nature.get("aff"));
            }
          }

          if (displayColor && parseInt(GLOBAL_DATA.paramDivers.get("AgdMHorCnh").get("valeur"), 10) === 2) {
            const matin = dts.get("hm");
            const soir = dts.get("hs");
            const nuit = dts.get("hn");
            const repos = dts.get("re");

            // EVO 545 presence jour added
            const jour = dts.get("hj");
            const parametreDiversIndJourneeValeur = GLOBAL_DATA.paramDivers.get("indJournee").get("valeur");

            if (!CWSTR.isBlank(matin) && !CWSTR.isBlank(soir) && !CWSTR.isBlank(nuit) && !CWSTR.isBlank(repos) && !CWSTR.isBlank(jour)) {
              if (repos) {
                wrapper.removeAttr('class');
                wrapper.addClass("ui-phx-horaire-repos");
              } else if (nuit && matin && soir) {
                wrapper.removeAttr('class');
                wrapper.addClass("ui-phx-horaire-jour-nuit");
              } else if (nuit && matin) {
                wrapper.removeAttr('class');
                wrapper.addClass("ui-phx-horaire-matin-nuit");
              } else if (nuit && soir) {
                wrapper.removeAttr('class');
                wrapper.addClass("ui-phx-horaire-soir-nuit");
              } else if (nuit && !jour) {
                wrapper.removeAttr('class');
                wrapper.addClass("ui-phx-horaire-nuit");
              } else if (matin && soir) {
                wrapper.removeAttr('class');
                wrapper.addClass("ui-phx-horaire-jour");
              } else if (matin && !soir) {
                wrapper.removeAttr('class');
                wrapper.addClass("ui-phx-horaire-matin");
              } else if (!matin && soir) {
                wrapper.removeAttr('class');
                wrapper.addClass("ui-phx-horaire-soir");
              } else if (String(parametreDiversIndJourneeValeur) === "1" && jour && !nuit) {
                wrapper.removeAttr('class');
                wrapper.addClass("ui-phx-horaire-jour1");
              } else if (String(parametreDiversIndJourneeValeur) === "1" && jour && nuit) {
                wrapper.removeAttr('class');
                wrapper.addClass("ui-phx-horaire-jour1-nuit");
              } else {
                wrapper.removeAttr('class');
                wrapper.addClass("ui-phx-presence-dans-lequipe");
              }
            }
          }
        }
        if (!CWSTR.isBlank(ele.get("forfaitj")) && typeForfait === "0") {
          //Tooltip
          const tooltip = this._buildHoraireTooltip(dateformat);
          wrapper.attr("title", tooltip);
        }

        wrapper.addClass("ui-phx-horaire");
        wrapper.addClass("js-horaire");
      }
    }

    return wrapper;
  }

  _renderPresences(wrapper: any, events: any, dateformat: any): any {
    let hStart = "";
    let hEnd = "";
    let startCode = "";
    let endCode = "";
    let validate = false;
    let tooltip = "";
    const formattedDate = CWTYPE.DATE.format(dateformat, CWTYPE._getFormatByCode("DATE_A"));
    let union = "";
    const presencePrefix = $("<span class=\"presence-prefix\">");
    const presenceInfo = $("<span class=\"presence-info\">");

    if (_.isEmpty(events[0].get("dts"))) {
      validate = true;
    }
    _.each(events, (event: any) => {
      let endtext = "";

      if (validate) {
        if (CWSTR.isBlank(hStart)) {
          hStart = CWTYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val"));
        }
        hEnd = CWTYPE.HOUR_MINUTE_NIGHTLY.format(event.get("valf"));
      } else {
        if (CWSTR.isBlank(hStart) && event.get("cod").toLowerCase() === "e") {
          hStart = CWTYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val"));
          startCode = "(" + event.get("cod").toLowerCase() + ")";
        } else if (CWSTR.isBlank(hStart) && event.get("cod").toLowerCase() === "r") {
          hStart = CWTYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val"));
          startCode = "(" + event.get("cod").toLowerCase() + ")";
        }
        if (event.get("cod").toLowerCase() === "s") {
          hEnd = CWTYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val"));
          endCode = "(" + event.get("cod").toLowerCase() + ")";
        } else if (event.get("cod").toLowerCase() === "d") {
          hEnd = CWTYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val"));
          endCode = "(" + event.get("cod").toLowerCase() + ")";
        }
      }
      if (!CWSTR.isBlank(event.get("cod")) && this.workflow?.dtsModel?.BADGEAGES && this.workflow.dtsModel.BADGEAGES.length > 0 && !_.isEmpty(this.workflow.dtsModel.BADGEAGES.get(event.get("cod").toUpperCase()))) {
        endtext = this.workflow.dtsModel.BADGEAGES.get(event.get("cod").toUpperCase()).get("lib");
      }
      tooltip += "<span><b>" + formattedDate + "</b></span><label> " + i18n.t('agenda.presencesInfoA') + " </label><span><b> " + CWTYPE.HOUR_MINUTE_NIGHTLY.format(event.get("val")) + "</b></span><span> " + endtext + "</span><br/>";

      // When "codf" and "valf" have a value, - add new lines in the tooltip  (customer 181758)
      if (event.get("valf") && event.get("codf")) {
        let endtextF = "";

        if (!CWSTR.isBlank(event.get("codf")) && this.workflow?.dtsModel?.BADGEAGES && this.workflow.dtsModel.BADGEAGES.length > 0 && !_.isEmpty(this.workflow.dtsModel.BADGEAGES.get(event.get("codf")))) {
          endtextF = this.workflow.dtsModel.BADGEAGES.get(event.get("codf")).get("lib");
        }
        tooltip += "<span><b>" + formattedDate + "</b></span><label> " + i18n.t('agenda.presencesInfoA') + " </label><span><b> " + CWTYPE.HOUR_MINUTE_NIGHTLY.format(event.get("valf")) + "</b></span><span> " + endtextF + "</span><br/>";
      }
    });
    if (!CWSTR.isBlank(hStart) && !CWSTR.isBlank(hEnd)) {
      union = "-";
      if (validate && events.length > 1) {
        union = "...";
      } else if (!validate && events.length > 2) {
        union = "...";
      }
    }
    if (validate) {
      presencePrefix.text(i18n.t('agenda.presenceValidate'));
    }
    presenceInfo.text(hStart + startCode + union + hEnd + endCode);
    wrapper.append(presencePrefix).append(presenceInfo);
    //Tooltip
    wrapper.attr("title", tooltip);
    // Add js events
    return wrapper;
  }

  _renderAbsences(wrapper: any, event: any, dateformat: any): any {
    const dts = event.get("dts");
    const wfdts = this.workflow.dtsModel.ABSENCES.get(event.get("cod"));
    const group = this.workflow.dtsModel.GROUPS_ABSENCE.get(wfdts.get("grp"));
    const cel = this.workflow.mainModel?.models[0]?.get("cel")?.get(dateformat);
    const confidentielle = cel ? (cel.get("affd") === "N") : false;

    if ((this.workflow.get("options").get("evt_val") === false && dts.sta !== "A" && dts.sta !== "H") || dts.sta === "R") {
      return this;
    }

    const params = _.clone(dts.par);
    params["@absDateDebut"] = "<b>" + CWTYPE.DATE.format(params["@absDateDebut"], CWTYPE._getFormatByCode("DATE_A")) + "</b>";
    params["@absDateFin"] = "<b>" + CWTYPE.DATE.format(params["@absDateFin"], CWTYPE._getFormatByCode("DATE_A")) + "</b>";
    params["@absHeureDuree"] = CWTYPE.HOUR_MINUTE.format(params["@absHeureDuree"], CWTYPE._getFormatByCode("DUREEHM"));

    //check hours
    params["@absHeureDebut"] = CWTYPE.HOUR_MINUTE_NIGHTLY.format(params["@absHeureDebut"]);
    params["@absHeureFin"] = CWTYPE.HOUR_MINUTE_NIGHTLY.format(params["@absHeureFin"]);

    if (confidentielle) {
      params["@absLibelleMotif"] = "";
    }

    wrapper.attr("evtid", event.get("uid"));

    //time
    const abscensetime = $("<span class=\"body-cell-time\">");
    let time = "";
    if ((dts.par["@absUniteDebut"] !== "H" || dts.par["@absUniteFin"] !== "H") && (dts.par["@absDateDebut"] || dts.par["@absDateFin"])) {
      if (dts.par["@absDateDebut"] === dateformat) {
        if (!CWSTR.isBlank(dts.par["@absUniteDebut"])) {
          time = "(" + dts.par["@absUniteDebutAbrege"] + ")";
        } else if (!CWSTR.isBlank(dts.par["@absHeureDebut"])) {
          time = CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.par["@absHeureDebut"]) + "...";
        }
      } else if (dts.par["@absDateFin"] === dateformat) {
        if (!CWSTR.isBlank(dts.par["@absUniteFin"])) {
          time = "(" + dts.par["@absUniteFinAbrege"] + ")";
        } else if (!CWSTR.isBlank(dts.par["@absHeureFin"])) {
          time = "..." + CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.par["@absHeureFin"]);
        }
      } else {
        if (dts.per === true) {
          time = "(" + dts.par["@absUniteDateAbrege"] + ")";
        } else {
          if (!CWSTR.isBlank(dts.par["@absUniteDebut"]) && !CWSTR.isBlank(dts.par["@absUniteFin"]) && (dts.par["@absUniteDebut"] === dts.par["@absUniteFin"])) {
            time = "(" + dts.par["@absUniteDebutAbrege"] + ")";
          } else {
            if (this.workflow.dtsModel.UNITES_SAISIE_ABS.models[4]) {
              time = "(" + this.workflow.dtsModel.UNITES_SAISIE_ABS.models[4].get("abrege") + ")";
            } else {
              time = "(" + dts.par["@absUniteDebutAbrege"] + ")";
            }
          }
        }
      }
    } else if (dts.par["@absHeureDebut"]) {
      time = CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.par["@absHeureDebut"]) + "...";
    } else if (dts.par["@absHeureFin"]) {
      time = "..." + CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.par["@absHeureFin"]);
    } else if (dts.par["@absHeureDuree"]) {
      time = CWTYPE.HOUR_MINUTE.format(dts.par["@absHeureDuree"], CWTYPE._getFormatByCode("DUREEHM"));
    }

    abscensetime.text(time);
    wrapper.append(abscensetime);

    //body
    const abscenseText = $("<span class=\"body-cell-text\">");
    const abscenseInd = $("<span class=\"body-cell-indicateur\">");
    let abscenceIndContent = "";

    if (this.workflow.get("indicateurs")) {
      if (dts.sou === false) {
        abscenceIndContent += i18n.t('agenda.absenceab');
      } else {
        abscenceIndContent += i18n.t('agenda.absenceso');
      }
    }

    if (dts.sta !== "A" && dts.sta !== "H") {
      abscenceIndContent += "?";
    }

    if (!CWSTR.isBlank(abscenceIndContent)) {
      abscenseInd.text(abscenceIndContent);
      wrapper.append(abscenseInd);
    }

    let displayMotif = true;

    if (this.context && !CWSTR.isBlank(this.context.ctxLibelleAbs)) {
      if (parseInt(this.context.ctxLibelleAbs, 10) === 1) {
        displayMotif = false;
      }
    } else if (GLOBAL_DATA.paramDivers.get("PLAbsLibGr") && parseInt(GLOBAL_DATA.paramDivers.get("PLAbsLibGr").get("valeur"), 10) === 1) {
      displayMotif = false;
    }

    let libelle = "**";

    if (confidentielle) {
      libelle = "";
    } else {
      if (displayMotif === true) {
        if (!CWSTR.isBlank(params["@absLibelleMotif"])) {
          libelle = params["@absLibelleMotif"];
        }
      } else if (!CWSTR.isBlank(group)) {
        libelle = group.get("lib");
      }
    }

    abscenseText.text(libelle);
    wrapper.append(abscenseText);

    //Color
    if (confidentielle) {
      wrapper.addClass("ui-phx-absence-dans-lequipe");
    } else {
      if (displayMotif === true) {
        wrapper.css({ "background-color": wfdts.get("group").get("bgcolor"), "color": wfdts.get("group").get("color") });
      } else {
        wrapper.css({ "background-color": group.get("bgcolor"), "color": group.get("color") });
      }
    }

    //Periode
    if (String(dts.per) === "true") {
      const lClassSvg = "cw-ui-icon-options phx-dynamicStyle-cell-abs_" + UTILS.escapeJQueryString(wfdts.get("group").get("cod"));
      if (dts.par["@absDateDebut"] !== dateformat) {
        wrapper.append($("<span class='agenda_absicon_n icon-periode-start'>"))
        wrapper.find(".agenda_absicon_n").html(UTILS.getSVGIcon('marqueur_periode_m', "cw-icon-agenda-periode-s", 16, null, false, false, false, lClassSvg));
        wrapper.css({ "padding-left": "20px" });
      }

      if (dts.par["@absDateFin"] !== dateformat) {
        wrapper.append($("<span class='agenda_absicon_s icon-periode-end'>"))
        wrapper.find(".agenda_absicon_s").html(UTILS.getSVGIcon('marqueur_periode_m', "cw-icon-agenda-periode-n", 16, null, false, false, false, lClassSvg));
        wrapper.css({ "padding-right": "20px" });
      }
    }

    //Tooltip
    let tooltip = "";
    const line1 = this._buildAbsenceMessage(dts.lib, params);
    const line3 = !CWSTR.isBlank(dts.com) ? dts.com : "";
    let line4 = "";

    if (!CWSTR.isBlank(dts.acp) && !CWSTR.isBlank(dts.acn) && !CWSTR.isBlank(dts.sai) && !CWSTR.isBlank(dts.acd)) {
      const nom = dts.acp.substring(0, 1) + "." + dts.acn;
      const sai = CWTYPE.DATE.format(dts.sai, CWTYPE._getFormatByCode("DATE_A"));
      const acd = CWTYPE.DATE.format(dts.acd, CWTYPE._getFormatByCode("DATE_A"));

      line4 = "<label for ='agenda_saidate'>" + i18n.t('agenda.absencetooltipSaisi') + " </label><span class='agenda_saidate'><b>" + sai + "</b><label for='agenda_acp'>" +
        i18n.t('agenda.absencetooltipDerniere') + " </label><span class='agenda_acp'><b>" + nom + "</b></span><label for='agenda_acd'> " + i18n.t('agenda.absencetooltipLe') +
        " </label><span class='agenda_acd'><b>" + acd + "</b></span>";

    }

    let h1 = "<label for ='agenda_abstable'><b>" + i18n.t('agenda.absencetooltipAbs') + "</b></label>";
    if (dts.sou === true) {
      h1 = "<label for ='agenda_abstable'><b>" + i18n.t('agenda.absencetooltipSou') + "</b></label>";
    }

    let h2 = "";
    if ((this.workflow.wkfActiv.souhait && (event.get("dts").sou === true)) || (this.workflow.wkfActiv.absence && (event.get("dts").sou === false))) {
      switch (dts.eta) {
        case "D":
        case "T":
          h2 += "<label class='ui-phx-statut-demande'><b>";
          break;
        case "I":
          h2 += "<label class='ui-phx-statut-en-cours'><b>";
          break;
        case "A":
          h2 += "<label class='ui-phx-statut-accepte'><b>";
          break;
        case "R":
          h2 += "<label class='ui-phx-statut-refuse'><b>";
          break;
        case "H":
          h2 += "<label class='ui-phx-statut-hors-validation'><b>";
          break;
        case "X":
          h2 += "<label class='ui-phx-demande-suppression'><b>";
          break;
        default:
          break;
      }

      let staLib = "";
      staLib = dts.sta.libelle;
      const workflowStatus = this.workflow.dtsModel.WORKFLOWS.get(dts.sta);

      if (dts.eta !== "X") {
        staLib = dts.sta.libelle;
      } else {
        staLib = i18n.t('agenda.demande_de_suppression');
      }
      if (CWSTR.isBlank(staLib) && workflowStatus) {
        staLib = workflowStatus.get("lib");
      }
      h2 += staLib + "</b></label>";

    }
    if (dts.eta === "X") {
      tooltip = "<table style='width: 100%'><tr><td align='left' width='35%'><label><b>" + h1 + "</b></label></td><td align='left'>" + h2 + "</td></tr></table>";
    } else {
      tooltip = "<table style='width: 100%'><tr><td align='left' width='45%'><label><b>" + h1 + "</b></label></td><td align='left'>" + h2 + "</td></tr></table>";
    }

    if (!CWSTR.isBlank(line1) && line1.indexOf("null") === -1) {
      tooltip += "<br/>" + line1;
    }
    if (!confidentielle) {
      if (!CWSTR.isBlank(line3)) {
        tooltip += "<br/>" + line3;
      }
      if (!CWSTR.isBlank(line4)) {
        tooltip += "<br/>" + line4;
      }
    }

    wrapper.attr("title", tooltip);
    wrapper.css({ "margin-top": "3px" });

    // Add js events
    wrapper.addClass("js-event-selectionable");
    if (!confidentielle) {
      wrapper.addClass("js-event-absence");
    }
    return wrapper;
  }

  _renderPrets(wrapper: any, event: any, dateformat: any): any {
    const dts = event.get("dts");
    const status = dts.sta || null;
    const unit = this.getUnit(this.workflow.dtsModel.UNITES_SAISIE.models, dts.ude, dts.ufi);

    if ((this.workflow.get("options").get("evt_val") === false && status !== "A" && status !== "H") || status === "R") {
      return this;
    }

    wrapper.attr("evtid", event.get("uid"));

    //time
    const pretTime = $("<span class=\"body-cell-time\">");
    let time = "";
    if ((dts.mod === "UNITE" || dts.mod === "PERIOC") && (dts.deb || dts.fin)) {
      if (dts.deb === dateformat) {
        if (!CWSTR.isBlank(unit.abrDe)) {
          time = "(" + unit.abrDe + ")";
        } else if (!CWSTR.isBlank(dts.hed)) {
          time = CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.hed) + "...";
        }
      } else if (dts.fin === dateformat) {
        if (!CWSTR.isBlank(unit.abrFi)) {
          time = "(" + unit.abrFi + ")";
        } else if (!CWSTR.isBlank(dts.hef)) {
          time = "..." + CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.hef);
        }
      } else if (unit.abrDe && unit.abrFi && unit.abrDe === unit.abrFi) {
        time = "(" + unit.abrDe + ") ";
      } else {
        time = "(" + this.workflow.dtsModel.UNITES_SAISIE.models[4].get("abrege") + ")";
      }
    } else if (dts.hed) {
      time = CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.hed) + "...";
    } else if (dts.hef) {
      time = "..." + CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.hef);
    } else if (dts.het) {
      time = CWTYPE.HOUR_MINUTE.format(dts.het, CWTYPE._getFormatByCode("DUREEHM"));
    }

    pretTime.text(time);
    wrapper.append(pretTime);

    //body
    const pretText = $("<span class=\"body-cell-text\">");
    const pretInd = $("<span class=\"body-cell-indicateur\">");
    let pretIndContent = "";

    if (this.workflow.get("indicateurs")) {
      pretIndContent += i18n.t('agenda.affpret');
    }

    if (status !== "A" && status !== "H") {
      pretIndContent += "?";
    }

    if (!CWSTR.isBlank(pretIndContent)) {
      pretInd.text(pretIndContent);
      wrapper.append(pretInd);
    }

    const libelle = dts.libstruct + " (" + dts.codstruct + ")";

    pretText.text(libelle);
    wrapper.append(pretText);

    wrapper.addClass("ui-phx-pret");

    //Periode
    if (dts.deb !== dateformat) {
      wrapper.append($("<span class='icon-periode-start phx-icon-agenda-periode-w phx-icon'>"))
    }

    if (dts.fin !== dateformat) {
      wrapper.append($("<span class='icon-periode-end phx-icon-agenda-periode-e phx-icon'>"))
    }

    // Tooltip
    const dtsModel = this.workflow.dtsModel.AFFECTATIONS.get(dts.cod);
    let tooltip = "";

    if (dtsModel) {
      let txtTitle = "";
      let txtDates = "";
      let txtHours = "";
      let txtMotif = "";

      if (String(dts.pret) === "true") {
        txtTitle = "<span>" + i18n.t('agenda.tt_pret') + "</span><br/>";
      } else {
        txtTitle = "<span>" + i18n.t('agenda.tt_emprunt') + "</span><br/>";
      }

      tooltip += txtTitle;

      let dates = "";
      if (dts.deb > dtsModel.get("deb") && dts.fin >= dtsModel.get("fin")) {
        dates = i18n.t('common:chemin.sitpartdu', { "0": CWTYPE.DATE.format(dts.deb) });
      } else if (dts.deb <= dtsModel.get("deb") && dts.fin < dtsModel.get("fin")) {
        dates = i18n.t('common:chemin.sitjusq', { "0": CWTYPE.DATE.format(dts.fin) });
      } else if (dts.deb > dtsModel.get("deb") && dts.fin < dtsModel.get("fin") && dts.deb !== dts.fin) {
        dates = i18n.t('common:chemin.sitduau', { "0": CWTYPE.DATE.format(dts.deb), "1": CWTYPE.DATE.format(dts.fin) });
      } else if (dts.deb > dtsModel.get("deb") && dts.fin < dtsModel.get("fin") && dts.deb === dts.fin) {
        dates = i18n.t('common:chemin.sitdatedu', { "0": CWTYPE.DATE.format(dts.fin) });
      }

      if (dates) {
        txtDates = "<span>" + dates + "</span><br/>";
      }
      tooltip += txtDates;

      if (!CWSTR.isBlank(dts.hed) && !CWSTR.isBlank(dts.hef) && !CWSTR.isBlank(dts.het)) {
        txtHours = "<span>" + i18n.t('agenda.tt_aff_dea', { "0": CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.hed), "1": CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.hef), "2": CWTYPE.DURATION.HOUR_MINUTE.format(dts.het).replace(/\ /g, "") }) + "</span><br/>"; //eslint-disable-line
      } else {
        let auxTxt = "";
        if (dts.ude === this.workflow.dtsModel.UNITES_SAISIE.models[4].get("code")) {
          auxTxt = unit.libDe;
        } else {
          auxTxt = i18n.t('agenda.tt_aff_duau', { "0": unit.libDe, "1": unit.libFi });
        }
        txtHours = "<span>" + auxTxt + "</span><br/>";
      }
      tooltip += txtHours;

      if (dts.pret === true) {
        tooltip += this._getPretTooltipText(dtsModel);
      }

      const pretDts = this.workflow.dtsModel.PRETS.get(dts.mot);
      if (!CWSTR.isBlank(pretDts)) {
        txtMotif = "<span>" + pretDts.get("lib") + " (" + pretDts.get("cod") + ")</span><br/>";
      }
      tooltip += txtMotif;
    }

    wrapper.attr("title", tooltip);

    // Add js events
    wrapper.addClass("js-event-selectionable");
    wrapper.addClass("js-event-pret");

    return wrapper;
  }

  _renderAffectations(wrapper: any, event: any): any {
    const dts = this.workflow.dtsModel.AFFECTATIONS.get(event.cod);
    const affTime = $("<span class=\"body-cell-time\">");
    const unit = this.getUnit(this.workflow.dtsModel.UNITES_SAISIE.models, event.uni, dts.ufi);

    const time = "(" + unit.abrDe + ")";

    affTime.text(time);
    wrapper.append(affTime);

    const affText = $("<span class=\"body-cell-text\">");

    if (this.workflow.get("indicateurs")) {
      const affInd = $("<span class=\"body-cell-indicateur\">");
      affInd.text(i18n.t('agenda.affseco'));
      wrapper.append(affInd);
    }

    const libelle = dts.get("dts")[0].lib + " (" + dts.get("dts")[0].cod + ")";

    affText.text(libelle);
    wrapper.append(affText);

    wrapper.addClass("ui-phx-affsec");

    //tooltip
    let tooltip = "";

    if (dts) {
      let txtTitle = "";

      txtTitle = "<span>" + i18n.t('agenda.tt_aff_sec') + "</span><br/>";
      tooltip += txtTitle;

      tooltip += this._getPretTooltipText(dts);
    }

    wrapper.attr("title", tooltip);

    // Add js events
    wrapper.addClass("js-event-selectionable");
    wrapper.addClass("js-event-affectation");

    return wrapper;
  }

  getUnit(models: any, ude: any, ufi: any): any {
    const unit = { "abrDe": "", "libDe": "", "abrFi": "", "libFi": "" };

    _.each(models, (value: any) => {
      if (!CWSTR.isBlank(ude) && value.get("code") === ude) {
        unit.abrDe = value.get("abrege");
        unit.libDe = value.get("libelle");
      }
      if (!CWSTR.isBlank(ufi) && value.get("code") === ufi) {
        unit.abrFi = value.get("abrege");
        unit.libFi = value.get("libelle");
      }
    });
    return unit;
  }

  _renderActivites(wrapper: any, event: any, dateformat: any): any {
    const dts = event.get("dts");
    const dtsModel = this.workflow.dtsModel.ACTIVITES.get(event.get("cod"));
    const fam = this.workflow.dtsModel.FAM_ACTIVITE.get(dts.fam);
    const status = dts.sta ? dts.sta.code : null;
    const unit = this.getUnit(this.workflow.dtsModel.UNITES_SAISIE.models, dts.ude, dts.ufi);

    if ((this.workflow.get("options").get("evt_val") === false && status !== "A" && status !== "H") || status === "R") {
      return this;
    }

    wrapper.attr("evtid", event.get("uid"));

    //time
    const actTime = $("<span class=\"body-cell-time\">");
    let time = "";
    if ((dts.mod === "UNITE" || dts.mod === "PERIOC") && (dts.deb || dts.fin)) {
      if (dts.deb === dateformat) {
        if (!CWSTR.isBlank(unit.abrDe)) {
          time = "(" + unit.abrDe + ") ";
        } else if (!CWSTR.isBlank(dts.hed)) {
          time = CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.hed) + "...";
        }
      } else if (dts.fin === dateformat) {
        if (!CWSTR.isBlank(unit.abrFi)) {
          time = "(" + unit.abrFi + ") ";
        } else if (!CWSTR.isBlank(dts.hef)) {
          time = "..." + CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.hef);
        }
      } else if (unit.abrDe && unit.abrFi && unit.abrDe === unit.abrFi) {
        time = "(" + unit.abrDe + ") ";
      } else {
        time = "(" + this.workflow.dtsModel.UNITES_SAISIE.models[4].get("abrege") + ")";
      }
    } else if (dts.hed) {
      time = CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.hed) + "...";
    } else if (dts.hef) {
      time = "..." + CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.hef);
    } else if (dts.het) {
      time = CWTYPE.HOUR_MINUTE.format(dts.het, CWTYPE._getFormatByCode("DUREEHM"));
    }

    actTime.text(time);
    wrapper.append(actTime);

    //body
    const actText = $("<span class=\"body-cell-text\">");
    const actInd = $("<span class=\"body-cell-indicateur\">");
    let actIndContent = "";

    if (this.workflow.get("indicateurs")) {
      if (dts.typ === "P") {
        actIndContent += i18n.t('agenda.activiteprevue');
      } else if (dts.typ === "R") {
        actIndContent += i18n.t('agenda.activiterealisee');
      }
    }

    if (status !== "A" && status !== "H") {
      actIndContent += "?";
    }

    if (!CWSTR.isBlank(actIndContent)) {
      actInd.text(actIndContent);
      wrapper.append(actInd);
    }

    const libelle = dtsModel.get("lib") + " (" + dtsModel.get("cod") + ")";

    actText.text(libelle);
    wrapper.append(actText);

    //Color
    if (CWSTR.isBlank(dts.fam) || dts.fam === "EMPTY") {
      if (dts && dts.typ === "P") {
        wrapper.addClass("ui-phx-activite-prevue");
      } else if (dts && dts.typ === "R") {
        wrapper.addClass("ui-phx-activite-realisee");
      }
    } else {
      wrapper.css({ "background-color": fam.get("bgcolor"), "color": fam.get("color") });
      //wrapper.addClass("phx-dynamicStyle-cell-act_" + UTILS.escapeJQueryString(dts.fam));
    }

    //Periode
    if (dts.deb !== dateformat) {
      wrapper.append($("<span class='icon-periode-start phx-icon-agenda-periode-w phx-icon'>"))
    }

    if (dts.fin !== dateformat) {
      wrapper.append($("<span class='icon-periode-end phx-icon-agenda-periode-e phx-icon'>"))
    }

    //Tooltip
    let tooltip = "";
    const line1 = this._buildActiviteMessage(dts, libelle, unit);
    const line2 = dts.com;

    const typAux = GLOBAL_DATA.typologies.get(dts.tpo).get("evenement");
    const type = typAux.substr(0, 1).toUpperCase() + typAux.substr(1);
    const h1 = "<label for ='agenda_acttable'><b>" + type + "</b></label>";

    let h2 = "";
    switch (dts.sta.code) {
      case "D":
      case "T":
        h2 += "<label class='ui-phx-statut-demande'><b>";
        break;
      case "I":
        h2 += "<label class='ui-phx-statut-en-cours'><b>";
        break;
      case "A":
        h2 += "<label class='ui-phx-statut-accepte'><b>";
        break;
      case "R":
        h2 += "<label class='ui-phx-statut-refuse'><b>";
        break;
      case "H":
        h2 += "<label class='ui-phx-statut-hors-validation'><b>";
        break;
      default:
        h2 += "<label><b>";
        break;
    }

    let line4 = "";
    if (!CWSTR.isBlank(dts.acp) && !CWSTR.isBlank(dts.acn) &&
      !CWSTR.isBlank(dts.sai) && !CWSTR.isBlank(dts.acd)) {
      const nom = dts.acp.substring(0, 1) + "." + dts.acn;
      const sai = CWTYPE.DATE.format(dts.sai, CWTYPE._getFormatByCode("DATE_A"));
      const acd = CWTYPE.DATE.format(dts.acd, CWTYPE._getFormatByCode("DATE_A"));
      line4 = "<label for ='agenda_saidate'>" + i18n.t('agenda.absencetooltipSaisi') + " </label><span class='agenda_saidate'><b>" + sai +
        "</b></span><label for='agenda_acp'>" + i18n.t('agenda.absencetooltipDerniere') + " </label><span class='agenda_acp'><b>" + nom +
        "</b></span><label for='agenda_acd'> " + i18n.t('agenda.absencetooltipLe') + " </label><span class='agenda_acd'><b>" + acd + "</b></span>";
    }

    let staLib = "";
    if (!CWSTR.isBlank(dts.sta.libelle)) {
      staLib = dts.sta.libelle;
    }
    h2 += staLib + "</b></label>";

    tooltip = "<table style='width: 100%'><tr><td align='left' width='" + (dts.sta.code === "H" ? "70%" : "45%") + "'><label><b>" + h1 + "</b></label></td><td align='left'>" + h2 + "</td></tr></table>";

    if (!CWSTR.isBlank(line1) && line1.indexOf("null") === -1) {
      tooltip += "<br/>" + line1;
    }

    if (!CWSTR.isBlank(line4)) {
      tooltip += "<br/>" + line4;
    }

    if (!CWSTR.isBlank(line2) && (dts.sta.code === "D" || dts.sta.code === "A" || dts.sta.code === "H")) {
      tooltip += "<br/>" + line2;
    }

    wrapper.attr("title", tooltip);

    // Add js events
    wrapper.addClass("js-event-selectionable");
    wrapper.addClass("js-event-activite");

    return wrapper;
  }

  _drawCellFooter(wrapper: JQuery, dateformat: string, cellBody: any): JQuery {
    let optList = $("<ul>");
    const ele = this.workflow.mainModel.models[0].get("cel").get(dateformat);

    optList.append($("<li><span title = '" + i18n.t('agenda.disponibiliteind') + "' class=\"hideDispo\">" + i18n.t('agenda.disponibiliteDi') + "</span></li>"));
    optList.append($("<li><span title = '" + i18n.t('agenda.regularisationsrgTooltip') + "'class=\"hideRegul\">" + i18n.t('agenda.regularisationsrg') + "</span></li>"));
    optList.append($("<li><span title = '" + i18n.t('agenda.anomaliesanTooltip') + "'class=\"hideAnom\">" + i18n.t('agenda.anomaliesan') + "</span></li>"));
    optList.append($("<li><span title = '" + i18n.t('agenda.messagesmsTooltip') + "'class=\"hideMessage\">" + i18n.t('agenda.messagesms') + "</span></li>"));
    optList = this._renderIndicateurs(optList, dateformat, cellBody);
    wrapper.append(optList);

    if (!CWSTR.isBlank(ele)) {
      if (!CWSTR.isBlank(optList) && !optList.hasClass("noFooter")) {
        if (ele.get("presence") && !CWSTR.isBlank(ele.get("forfaitj")) && ele.get("forfaitj").code !== "0") {
          if (!CWSTR.isBlank(cellBody.find(".forfaitj-cell")[0])) {
            const cellMatin = _.filter(cellBody.find(".forfaitj-cell")[0].classList.value.split(" "), (e: any) => { return e.includes("cell-matin") });
            const cellApresmidi = _.filter(cellBody.find(".forfaitj-cell")[0].classList.value.split(" "), (e: any) => { return e.includes("cell-apresmidi") });
            cellBody.find(".forfaitj-cell").css("height", "calc(100%");
            cellBody.find(".forfaitj-cell").css("border-radius", "10px 10px 0 0");

            if (cellMatin.length > 0) {
              wrapper.addClass("vuemensuel-day-footer-avec-forfaitj-demi");
            } else if (cellApresmidi.length > 0) {
              wrapper.addClass("vuemensuel-day-footer-avec-forfaitj-demiApresmidi");
            } else {
              wrapper.addClass("vuemensuel-day-footer-avec-forfaitj");
            }

            const natColor = _.filter(cellBody.find(".forfaitj-cell")[0].classList.value.split(" "), (e: any) => { return e.includes("ui-phx-color") });
            if (natColor.length > 0) {
              wrapper.addClass(natColor[0]);
            }
          }
        }
      }
    }

    return wrapper;
  }

  _renderIndicateurs(optList: JQuery, dateformat: string, cellBody: any): any {
    let noEvents = true;
    const pk = this.workflow.get("collabId") + "," + dateformat;

    //Disponibilites
    let habDisp = "RES_DISP.V";
    if (this.workflow.get("utilisateur") === this.workflow.COLLABORATEUR_TYPE) {
      habDisp = "COL_DISP.V";
    }

    if (this.workflow.evtModel.hasEvent("DISPONIBILITES", pk) && CWHABILITATION.canView(habDisp)) {
      optList.find("span").removeClass("hideDispo");
      noEvents = false;
    }

    //Regularisations
    let habReg = "RES_REG.V";
    if (this.workflow.get("utilisateur") === this.workflow.COLLABORATEUR_TYPE) {
      habReg = "COL_REG.V";
    }

    if (this.workflow.evtModel.hasEvent("REGULARISATIONS", pk) && CWHABILITATION.canView(habReg)) {
      optList.find("span").removeClass("hideRegul");
      noEvents = false;
    }

    //Anomalies
    let habAno = "RES_LEVANO.V";
    if (this.workflow.get("utilisateur") === this.workflow.COLLABORATEUR_TYPE) {
      habAno = "COL_ANO.V";
    }

    if (this.workflow.evtModel.hasEvent("ANOMALIES", pk) && CWHABILITATION.canView(habAno)) {
      optList.find("span").removeClass("hideAnom");
      noEvents = false;
    }

    //Messages
    let habMess = "RES_MESS.V";
    if (this.workflow.get("utilisateur") === this.workflow.COLLABORATEUR_TYPE) {
      habMess = "COL_MESS.V";
    }

    if (this.workflow.evtModel.hasEvent("MESSAGES", pk) && CWHABILITATION.canView(habMess)) {
      optList.find("span").removeClass("hideMessage");
      noEvents = false;
    }

    if (noEvents === true) {
      cellBody.addClass("noFooter");
      return "";
    } else {
      return optList;
    }
  }

  _buildAbsenceMessage(message: string, parametres: Array<string>): string {
    let result = null;

    if (!CWSTR.isBlank(message)) {
      result = message;
      if (!CWSTR.isBlank(parametres)) {
        const array: any = [];

        for (const i in parametres) {
          if (i) {
            array.push(i);
          }
        }
        array.sort(
          (a: any, b: any) => {
            return b.length - a.length;
          }
        );
        for (let j = 0; j < array.length; j++) {
          result = result.replace(array[j], "</label><span class='agenda_absl1'>" + parametres[array[j]] + "</span><label for='agenda_absl1'>");
        }
      }
    }
    return "<label for='agenda_absl1'>" + result + "</label>";
  }

  _buildHoraireTooltip(date: string): string {
    const user = this.workflow.mainModel.collabId;
    const evt = this.workflow.mainModel.evtModel.get("HORAIRES").get(user + "," + date);
    let dts = null;
    let result = null;

    if (!CWSTR.isBlank(evt)) {
      let code = evt.get("evt").models[0].get("cod");
      const lVal = evt.get("evt").models[0].get("val");

      if (_.isNumber(lVal) && String(lVal) !== String(code)) {
        code = lVal;
      }
      dts = this.workflow.mainModel.dtsModel.HORAIRES.get(code);
    }
    if (!CWSTR.isBlank(dts)) {
      const json = { 'i18n': i18n };

      const tooltipPlages = new CWScheduleModel({ showPlageBlank: false });
      tooltipPlages.set({
        tolouv: dts.get("too"),
        tolclo: dts.get("toc"),
        houv: dts.get("ho"),
        hclo: dts.get("hf"),
        dpf1: dts.get("f1d"),
        fpf1: dts.get("f1f"),
        dpf2: dts.get("f2d"),
        fpf2: dts.get("f2f"),
        pause: dts.get("pau")
      });

      if (tooltipPlages) {
        const tooltipTemplate = TPLvuemensuelHorairePlageTooltip;
        const plageView = new CWScheduleView(tooltipPlages);

        $(tooltipTemplate(json)).find(".agenda_plageGraph").html(plageView.render().el);

        // EVO 545 prints presence in the infobulle
        const repos = dts.get("re");
        const nuit = dts.get("hn");
        const matin = dts.get("hm");
        const soir = dts.get("hs");
        const jour = dts.get("hj");
        const parametreDiversIndJourneeValeur = GLOBAL_DATA.paramDivers.get("indJournee").get("valeur");

        let presence = "";

        if (repos) {
          presence = i18n.t('common:vuemensuel.hor_repos');
        } else if (nuit) {
          presence = i18n.t('common:vuemensuel.hor_nuit');
        } else if (String(parametreDiversIndJourneeValeur) === "0" && matin && soir) {
          presence = i18n.t('common:vuemensuel.hor_journee');
        } else if (String(parametreDiversIndJourneeValeur) === "1" && matin && soir) {
          presence = i18n.t('common:vuemensuel.hor_journee_M_A');
        } else if (String(parametreDiversIndJourneeValeur) === "1" && jour) {
          presence = i18n.t('common:vuemensuel.hor_journee');
        } else if (matin && !soir) {
          presence = i18n.t('common:vuemensuel.hor_matin');
        } else if (!matin && soir) {
          presence = i18n.t('common:vuemensuel.hor_apresMidi');
        }

        result = $("<div/>").html(tooltipTemplate(json));
        result.find(".agenda_plageGraph").html(plageView.render().el);
        result.find(".agenda_horaire").text(dts.get("lib") + " (" + dts.get("cod") + ")");
        result.find(".agenda_presence").text(presence);
        result.find(".agenda_temps").text(CWTYPE.HOUR_MINUTE.format(dts.get("tdu"), CWTYPE._getFormatByCode("DUREEHM")));
        result = result.html();
      }
    }

    return result;
  }

  _getPretTooltipText(dtsModel: any): string {
    let txt = "";

    if (!CWSTR.isBlank(dtsModel) && !CWSTR.isBlank(dtsModel.get("dts"))) {
      const levels = dtsModel.get("dts");
      txt = "<table style=\"width:500px\">";
      let marginLeft = 0;
      const tabAux = "<span class=\"ui-icon\" style=\"display:inline-block; background:none; padding:0; margin-left:10px\"></span>";
      if (levels[0].niv > 0) {
        // paint chemin when node is not a root node
        for (let j = 1; j < levels.length; j++) {
          // const tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";
          const tab = "<span style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\">" + UTILS.getSVGIcon('fleche_droite_arrondie', '', 12) + "</span>";
          if (j === 1) {
            txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + levels[j].lib + "(" + levels[j].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + i18n.t('common:chemin.structure') + "</td></tr>";
          } else {
            const niveau = levels[j].lbnv;
            txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + tab + levels[j].lib + "(" + levels[j].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + niveau + "</td></tr>";
            marginLeft += 10;
          }
        }
        // const tab = "<span class=\"ui-icon ui-icon-arrowreturn-1-e\" style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\"></span>";
        const tab = "<span style=\"display:inline-block; padding:0; margin-left:" + marginLeft + "px\">" + UTILS.getSVGIcon('fleche_droite_arrondie', '', 12) + "</span>";
        const niveau = levels[0].lbnv;
        txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + tab + levels[0].lib + "(" + levels[0].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + niveau + "</td></tr>";
        txt += "</table>";
      } else {
        // paint chemin when node is a root node
        const tabAux = "<span class=\"ui-icon\" style=\"display:inline-block; background:none; padding:0; margin-left:10px\"></span>";
        txt += "<tr><td style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:300px\">" + levels[0].lib + "(" + levels[0].cod + ")</td><td class=\"phx-chemin-tooltip-text\" style=\"text-overflow:ellipsis; overflow:hidden; white-space:nowrap;max-width:200px\">" + tabAux + i18n.t('common:chemin.structure') + "</td></tr>";
      }
    }
    return txt;
  }

  _buildActiviteMessage(dts: { [key: string]: any }, motif: string, unit: { [key: string]: any }): string {
    let result = "";
    const dateDeb = "</label><span class='agenda_actl1'><b>" + CWTYPE.DATE.format(dts.deb, CWTYPE._getFormatByCode("DATE_A")) + "</b></span><label for='agenda_actl1'>";//Check dates
    const dateFin = "</label><span class='agenda_actl1'><b>" + CWTYPE.DATE.format(dts.fin, CWTYPE._getFormatByCode("DATE_A")) + "</b></span><label for='agenda_actl1'>";
    let hDeb = "";//check hours
    let hFin = "";

    if (!CWSTR.isBlank(dts.hed) && !CWSTR.isBlank(dts.hef) && dts.hed !== dts.hef) {
      hDeb = "</label><span class='agenda_actl1'>" + CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.hed) + "</span><label for='agenda_actl1'>";
      hFin = "</label><span class='agenda_actl1'>" + CWTYPE.HOUR_MINUTE_NIGHTLY.format(dts.hef) + "</span><label for='agenda_actl1'>";
    }
    if (dts.deb === dts.fin) {
      if (!CWSTR.isBlank(dts.hed) && !CWSTR.isBlank(dts.hef) && dts.hed !== dts.hef && dts.mod === "PLH") {
        result = i18n.t('agenda.act_tt_leh', { "0": dateDeb, "1": hDeb, "2": hFin, "3": motif, interpolation: { escapeValue: false } });
      } else {
        result = i18n.t('agenda.act_tt_le', { "0": dateDeb, "1": unit.libDe, "2": motif, interpolation: { escapeValue: false } });
      }
    } else {
      if (!CWSTR.isBlank(dts.hed) && !CWSTR.isBlank(dts.hef) && dts.hed !== dts.hef && (dts.mod === "PLH" || (dts.mod === "PERIOC" && !dts.ude))) {
        result = i18n.t('agenda.act_tt_duh', { "0": dateDeb, "1": dateFin, "2": hDeb, "3": hFin, "4": motif, interpolation: { escapeValue: false } });
      } else {
        result = i18n.t('agenda.act_tt_du', { "0": dateDeb, "1": unit.libDe, "2": dateFin, "3": unit.libFi, "4": motif, interpolation: { escapeValue: false } });
      }
    }
    return "<label for='agenda_actl1'>" + result + "</label>";
  }

  _optimizeCellBody(calendar: JQuery): void {
    const lineHeight = 14;

    $.each(calendar.find(".vuemensuel-cell:not([data-type=out])"), (id: any, cell: any) => {
      const body = $(cell).find(".vuemensuel-day-body");
      const events = body.children();
      const aEvents: any = [];
      let nTotalLines = 0;
      const hbody = body.outerHeight();
      const hdisp = hbody - (events.length * 4); // 6 == sum padding bot and top of ecah line;

      if (hbody > 0) {
        const nLines = Math.floor(hdisp / lineHeight);

        $.each(events, (i: any, event: any) => {
          const eventLines = $(event).height() / lineHeight;

          aEvents.push({
            "lines": eventLines,
          });
          nTotalLines += eventLines;
        });
        if (nTotalLines > nLines) {
          const nLinesMin = nLines - events.length;

          //Min lines needed for display events
          if (nLinesMin < 0) {
            const scrollContainerUp = $("<div class=\"scroll-up\">");
            const scrollContainerDown = $("<div class=\"scroll-down\">");

            // can't expand all lines;
            $.each(events, (k: any, event: any) => {
              $(event).addClass("noexpand");
            });
            scrollContainerUp.append($(UTILS.getSVGIcon("fleche_pleine_haut", "cw-fleche-pleine-haut-icon", 12, null)));
            scrollContainerDown.append($(UTILS.getSVGIcon("fleche_pleine_bas", "cw-fleche-pleine-bas-icon", 12, null)));
            $(cell).append(scrollContainerUp.hide());
            $(cell).append(scrollContainerDown);
          } else {
            const ospace = this._getOptimSpace(aEvents, nLinesMin);

            $.each(events, (k: any, event: any) => {
              if (String(ospace[k].expand) === "false") {
                $(event).addClass("noexpand");
              }
            });
          }
        }
      }
    });
  }

  _getOptimSpace(aEvents: any, nLinesMin: number): any[] {
    let totals = 0;
    const optim: any = [];
    const shortedEvents: any = [];
    let k = -1;

    $.each(aEvents, (id: string | number, event: any) => {
      optim.push({ "expand": false });
      shortedEvents.push({ "lines": event.lines - 1, "id": id });
    });
    for (let i = 0; i < shortedEvents.length - 1; ++i) {
      let min = i;
      let aux = null;

      for (let j = i + 1; j < shortedEvents.length; ++j) {
        if (shortedEvents[min].lines <= shortedEvents[j].lines) {
          min = j;
        }
      }
      aux = shortedEvents[min];
      shortedEvents[min] = shortedEvents[i];
      shortedEvents[i] = aux;
    }
    k = shortedEvents.length - 1;
    while (totals < nLinesMin) {
      const evento = shortedEvents[k];

      if ((totals + evento.lines) <= nLinesMin) {
        optim[evento.id].expand = true;
        totals += evento.lines;
      } else {
        totals = nLinesMin;
      }
      --k;
    }
    return optim;
  }

  headerRender(content?: string): string {
    //override 
    return content;
  }

  headerColor(content?: string): string {
    //override 
    return content;
  }

  isJourExceptionnel(content?: string): boolean {
    //override 
    return !CWSTR.isBlank(content);
  }

  isHoraireExceptionnel(content?: string): boolean {
    //override 
    return !CWSTR.isBlank(content);
  }

  isJourFerie(content?: string): boolean {
    //override 
    return !CWSTR.isBlank(content);
  }

  isJourHorRepos(content?: string): boolean {
    //override 
    return !CWSTR.isBlank(content);
  }
}