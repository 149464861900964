import _ from 'underscore';
import TPLListerregularisationsListerRegularisations from '../cwListerRegularisations.tpl.html';
import { CWBaseFormView } from 'core/views/cwForm.view';
import { CWBaseGridColums } from 'src/core/grids/basegrid/cwBaseGrid.view';
import { CWCustomBarListeRegulView } from './cwCustomBarListeRegul.view';
import { CWEtiquette, validEtiquetteTypes } from 'src/core/components/etiquette/cwEtiquette.view';
import { CWFilterContentListeRegulView } from './cwFilterContentListeRegul.view';
import { CWFilterMoreContentListeRegulView } from './cwFilterMoreContentListeRegul.view';
import { CWFilterView } from 'core/components/filter/cwFilter.view';
import { CWGererRegularisationsWorkflow } from 'common/evenements/gerer/gererregularisations/models/cwGererregularisations.workflow';
import { CWHABILITATION } from 'src/utils/cwHabilitation';
import { CWHabilitationContext } from 'src/core/models/cwHabilitationContext';
import { CWListerRegularisationColl } from '../models/cwListerRegularisation.collection';
import { CWListerRegularisationModel } from '../models/cwListerRegularisation.model';
import { CWListerRegulHistoriqueColl } from '../models/cwListerRegulHistorique.collection';
import { CWMenuGridModel } from 'src/core/grids/menugrid/cwMenuGrid.model';
import { CWMenuGridView } from 'core/grids/menugrid/cwMenuGrid.view';
import { CWMotifRegulInitModel } from 'common/evenements/gerer/gererregularisations/models/cwMotifRegulInit.model';
import { CWMregulWorkflow } from 'src/uc/mon_compte/gerer/mregul/models/cwMregul.workflow';
import { CWReadOnlyModel } from 'src/core/models/cwReadOnly.model';
import { CWSTR } from 'utils/cwStr';
import { CWTYPE } from 'tda/cwTda';
import { GLOBAL_DATA } from 'src/globalData';
import { i18n } from 'src/i18n.js';
import { Model, ViewOptions } from 'Backbone';
import { WKF } from 'utils/wkf.js';


export interface CWListerRegulViewOptions extends ViewOptions<CWReadOnlyModel> {
  context: { [key: string]: any };
  workflow?: CWGererRegularisationsWorkflow | CWMregulWorkflow;
  parent?: any;
  isForResponsable?: boolean;
}

/**
 * Lister Regularisations componentes
 *
 * Events:
 *	rowChange : triggered when a row is selected
 *	updateList: trigger this event when an update is needed
 */
export class CWListerRegularisationView extends CWBaseFormView<CWReadOnlyModel> {

  context: { [key: string]: any };
  tableMode: string;
  table: CWMenuGridView<CWListerRegularisationModel, CWListerRegularisationColl>;
  filter: CWFilterView;
  customBar: CWCustomBarListeRegulView;
  historiqueColl: CWListerRegulHistoriqueColl;
  wkfEvenements: string;
  workflow: CWGererRegularisationsWorkflow | CWMregulWorkflow;
  parent: any;
  initWorkflow: CWMotifRegulInitModel;
  isForResponsable?: boolean;

  constructor(options?: CWListerRegulViewOptions) {
    options = options || { context: null };
    options.className = options.className || "suivi-list-regularisation";
    options.events = _.extend({
      "click .phx-combobox-button": "_buttonSearch",
      "click .phx-combobox-input:not([readonly])": "_inputSearch",
      "keydown :not([readonly]):not([disabled])": "_keyDownEvent"
    }, options.events);
    super(options);
    this.context = options.context;
    if (options.workflow) {
      this.workflow = options.workflow;
    } else {
      this.workflow = new CWGererRegularisationsWorkflow({}, { "context": this.context });
    }
    if (options.parent) {
      this.parent = options.parent;
    }
    this.isForResponsable = options.isForResponsable;
    // component template
    this.template = TPLListerregularisationsListerRegularisations;
    this.model = new CWReadOnlyModel();
    if (CWSTR.isBlank(this.context)) {
      throw new Error("A context must be specified for this component.");
    }
    this.tableMode = this.context.ctxModeRepresentation;
    this.setHabContext(new CWHabilitationContext({
      onglet: this.context.ctxEcran,
      foncCour: this.context.ctxHabilitation.HabilitationAcces
    }));
    this.initWorkflow = new CWMotifRegulInitModel();
    this.initWorkflow.setHabContext(new CWHabilitationContext({ onglet: this.context.ctxEcran, foncCour: this.context.ctxHabilitation.HabilitationAcces }));
    if (CWHABILITATION.canView(this.context.ctxHabilitation.HabilitationAcces)) {
      this.filter = this._initFilter();
      this.filter.model.on("search", this._filterTable, this);
    }
    this.table = this._initTable();
    this.table.model.coll.setHabContext(this.getHabContext());
    this.table.model.on("change:value", this._tableValueChange, this);
    this.listenTo(this.table.model.coll, "collectionParams:cleaned", (notIsButton?: boolean, origine?: string) => {
      this.filter.model.trigger("clean:filter", notIsButton, origine);
      this.workflow.trigger("enabledEtiquette");
    });
    this.listenTo(this.filter.model, "btn:click", (idbutton: string) => {
      if (idbutton === "clear") {
        this.workflow.trigger("enabledEtiquette");
      }
    });
    this.customBar = new CWCustomBarListeRegulView({ tableMode: this.tableMode });
    this.customBar.model.on("change:tableMode", this._manageTableMode, this);
    this.historiqueColl = new CWListerRegulHistoriqueColl();
    this.historiqueColl.setHabContext(this.getHabContext());
    this.listenTo(this.workflow, "selectedOptionfilter", this.searchFilter);
    this.listenTo(this.table.model.coll, 'reset', this.resteTitle);
    this.wkfEvenements = null;
  }

  _changeCollab(newCollab: { [key: string]: any }, callback?: () => any): void {
    this.context.ctxCollab = newCollab.matricule;
    this.table.model.coll.params.matricule = newCollab.matricule;
    this.wkfEvenements = null;
    this.refreshList(null, null, callback);
    this.customBar.swapLink("tableSimple");
  }

  getGrid(): CWMenuGridView<CWListerRegularisationModel, CWListerRegularisationColl> {
    return this.table;
  }

  _manageTableMode(): void {
    this.tableMode = this.customBar.model.get("tableMode");
    if (this.tableMode !== "Simple") {
      this._historique(() => {
        this.table.model.coll.trigger("reset");
      });
    } else {
      this.table.model.coll.trigger("reset");
    }
  }

  render(): CWListerRegularisationView {
    const json = { "i18n": i18n };
    const callback = (): void => {
      this.filter.render();
      this._filterTable(this.filter.getDefaultValues(), true);
      this.$el.find(".filtre_RegulCollab").html(this.filter.el);
    };

    this.$el.html(this.template(json));
    $(".table_RegulCollab", this.el).html(this.table.el);
    this.table.render();
    if (this.context.ctxEcran === "suivicollab") {
      this.$el.parents().find(".l-grind-principalContainer.flex-grow-1").addClass("hideScroll");
    } else {
      this.$el.find(".cw-fieldset__situationPanel__title").remove();
    }
    this.resteTitle();
    if (this.workflow.wkfRecupere === true) {
      callback();
    } else {
      this._fetchWorkflowOpt(callback);
    }
    return this;
  }

  getfilter(): CWFilterView {
    return this.filter;
  }

  getTable(): CWMenuGridView<CWListerRegularisationModel, CWListerRegularisationColl> {
    return this.table;
  }

  getCustomBar(): CWCustomBarListeRegulView {
    return this.customBar;
  }

  _initFilter(): CWFilterView {
    const lStatusEtiq = (this.context && this.context.statusEtiquette) ? this.context.statusEtiquette : {};
    const part1View = new CWFilterContentListeRegulView({ mainView: this, workflow: this.workflow, "statusEtiquette": lStatusEtiq });
    const part2View = new CWFilterMoreContentListeRegulView({ mainView: this, workflow: this.workflow });
    const filter = new CWFilterView({ id: "regulcollab_filter", viewPart1: part1View, viewPart2: part2View, buttonOnMoreFilterView: true });

    return filter;
  }

  _fetchWorkflowOpt(callback?: () => void): void {
    this.initWorkflow.fetch({
      success: (fresh: CWMotifRegulInitModel) => {
        // manages the options of the workflow.
        this.workflow.wkfRecupere = true; //flag pour savoir que le fetch déjà est fait ou pas (ne pas le faire deux fois)
        this.workflow.wkfActiv = fresh.get("wkf_activ");
        if (callback) {
          callback();
        }
      }
    });
  }

  /**
   * Filters the grid by some fields.
   */
  _filterTable(filter: { [key: string]: any }, isFirstTime?: boolean, origine?: string): void {
    const tableColl = this.table.model.coll;
    const params: { [key: string]: any } = {};

    params.matricule = this.context.ctxCollab;
    if (filter.statuts) {
      params.statuts = filter.statuts;
    } else {
      if (!CWSTR.isBlank(this.context.statutInitial)) {
        params.statuts = this.context.statutInitial;
        this.context.statutInitial = null;
        (this.filter.viewPart1 as CWFilterContentListeRegulView)._updateModel();
      } else {
        params.statuts = this.context.ctxStatut;
      }
    }
    if (filter.datedeb) {
      params.datedeb = filter.datedeb;
    }
    if (filter.datefin) {
      params.datefin = filter.datefin;
    }
    if (filter.motif) {
      switch (typeof filter.motif) {
        case 'string':
          if (!CWSTR.isBlank(filter.motif) && filter.motif !== "aucun") {
            params.motif = filter.motif;
          }
          break;
        case 'number':
          if (!CWSTR.isBlank(filter.motif)) {
            params.motif = filter.motif;
          }
          break;
        case 'object':
          if (filter.motif.code !== "aucun") {
            params.motif = filter.motif.code;
          }
          break;
      }
    }
    tableColl.applyFilter(params);
    if (isFirstTime === true) {
      this.refreshList(null);
    } else if (origine !== "findAndGo") {
      this.refreshList(null, "filter");
    }
  }

  _contextToFilter(): { [key: string]: any } {
    const params: { [key: string]: any } = {};

    params.matricule = this.context.ctxCollab;
    params.statuts = this.context.ctxStatut;
    if (this.context.ctxPeriode) {
      params.datedeb = this.context.ctxPeriode;
    }
    return params;
  }

  _fillList(selectedRowId?: number): void {
    // fetch data form table
    this.table.model.coll.fetch({
      success: () => {
        this.$el.find(".table_RegulCollab").html(this.table.render().el);
        if (this.table.model.coll.length > 0) {
          let row = null;

          if (selectedRowId === undefined) {
            row = this.table.model.coll.at(0);
          } else {
            row = this.table.model.coll.get(selectedRowId);
          }
          this.table.model.trigger("row:click", row);
        } else {
          this.model.trigger("emptyList");
        }
      }
    });
  }

  // trigger a rowChange event when a new row is selected
  _tableValueChange(tableModel: CWMenuGridModel<CWListerRegularisationColl>, changed?: { [key: string]: any }, options?: { [key: string]: any }): void {
    if (tableModel.coll.length > 0) {
      this.model.trigger("rowChange", tableModel.get("value"), options);
    } else {
      this.model.trigger("emptyList");
    }
    this.filter.model.trigger('enableFilter');
  }

  _initTable(): CWMenuGridView<CWListerRegularisationModel, CWListerRegularisationColl> {
    const tableModel = new CWMenuGridModel<CWListerRegularisationColl>({ coll: new CWListerRegularisationColl(null, { isForResponsable: this.isForResponsable }) });
    const tblColumns: Array<CWBaseGridColums> = [
      { title: "", property: "actionregul", width: 7 },
      { title: "", property: "colonage", width: 5 }
    ];
    const itemPerPage = parseInt(GLOBAL_DATA.paramDivers.get("PgNbLstReg").get("valeur"), 10);
    const table = new CWMenuGridView<CWListerRegularisationModel, CWListerRegularisationColl>({
      id: "listerregularisations_table",
      columns: tblColumns,
      model: tableModel,
      itemsPerPage: itemPerPage,
      title: " ",
      emptyMessage: i18n.t('messages:GT_2030'),
      paginatorPosition: (this.context.ctxEcran === "suivicollab") ? "butom" : "top",
      showFilteredRowsInTitle: true,
      gridHeightType: "auto",
      disableAlternateRowBackground: true,
      resizable: false,
      minVisibleRows: 4,
      maxVisibleRows: 4,
      disableColumnTitles: true
    });

    table.cellRenderers["actionregul"] = (model: CWListerRegularisationModel): JQuery => {
      const formattedParameters = this._formatParameters(model, "cw-texteAllege");
      const txt = CWSTR.buildMessageParametres("@regLibelleMotif", formattedParameters);
      const ligne1 = $("<span>");
      const ligne2 = !CWSTR.isBlank(model.get("libelle")) ? CWSTR.buildMessageParametres(model.get("libelle").split(",")[0], formattedParameters) : "";

      ligne1.addClass("phx-label-style");
      ligne1.html(txt);
      ligne1.append($("<br>"));
      ligne1.append(ligne2);
      return ligne1;
    };
    table.cellRenderers["colonage"] = (model: CWListerRegularisationModel): JQuery => {
      const valeur = $("<div class='valeur cw-texteAllege col-4'></div>");
      const statut = $("<div class='statut col-6'>");
      const iconComment = $("<div class='comment cw-comment-icon col-2'>");
      let type: validEtiquetteTypes;
      let subText: string = "";
      const divStatut = $("<div class='d-flex flex-row justify-content-center'></div>");
      const div = $("<div class='d-flex flex-row align-items-center'></div>");

      switch (model.get("statut").code) {
        case "D":
        case "T":
        case "I": {
          type = "demande";
          break;
        } case "A": {
          type = "accepte";
          break;
        } case "H": {
          type = null;
          break;
        }
        case "R": {
          type = "refuse";
          break;
        }
        default:
        //Nothing
      }
      subText = model.get("etat") === "X" ? i18n.t('common:listerabsencesouhait.suppression') : "";
      if (!CWSTR.isBlank(type)) {
        const etiquetteStatut = new CWEtiquette({
          text: {
            enabled: model.get("statut").libelle
          },
          subText: {
            enabled: subText
          },
          state: "actif",
          type: type,
          id: model.get("code") + "_etiquette"
        });

        divStatut.append(etiquetteStatut.render().el);

      } else {
        valeur.addClass("noEtiquette");
      }
      div.append(valeur.append(CWTYPE.LONG.format(model.get("valeur"), model.get("format"))));
      div.append(statut.append(divStatut));
      div.append(iconComment);
      return div;
    };
    table.setSortableColumns();
    table.setColumnsAlignment({ "valeur": "left" });
    return table;
  }

  _formatParameters(model: CWListerRegularisationModel, styleDate: string,): Array<string> {
    const params = _.clone(model.get("parametres"));
    let value = "";

    // @1 regDateDebut
    value = model.get("parametres")["@regDateDebut"];
    if (!CWSTR.isBlank(value)) {
      value = CWTYPE.DATE.format(value, CWTYPE._getFormatByCode("DATE_A"));
      value = this._textToStyle(value, styleDate);
      params["@regDateDebut"] = value;
    }
    // @2 regDateFin
    value = model.get("parametres")["@regDateFin"];
    if (!CWSTR.isBlank(value)) {
      value = CWTYPE.DATE.format(value, CWTYPE._getFormatByCode("DATE_A"));
      value = this._textToStyle(value, styleDate);
      params["@regDateFin"] = value;
    }
    // @3 regMotif
    value = model.get("parametres")["@regLibelleMotif"];
    if (!CWSTR.isBlank(value)) {
      value = this._textToStyle(value, "ui-phx-ihm-texte-donnees-important");
      params["@regLibelleMotif"] = value;
    }
    return params;
  }

  _textToStyle(txt: string, style: string, linkStyle?: boolean): string {
    let newTxt = "";

    if (linkStyle === true) {
      newTxt = "<span class='" + style + "'>" + txt + "</span>";
    } else {
      newTxt = "<span class='" + style + "'>" + txt + "</span>";
    }
    return newTxt;
  }

  _historique(callback?: () => void): void {
    if (this.wkfEvenements === null) {
      const params: Array<string> = [];

      _.each(this.table.model.coll.models, function (item) {
        if (item.get("historique") === true) {
          params.push(item.get("evenement"));
        }
      }, this);
      this.wkfEvenements = params.join(",");
      if (!CWSTR.isBlank(this.wkfEvenements)) {
        this.historiqueColl.applyFilter({ evenements: this.wkfEvenements });
        this.historiqueColl.fetch({
          success: function () {
            if (callback) {
              callback();
            }
          }
        });
      } else {
        if (callback) {
          callback();
        }
      }
    } else {
      if (callback) {
        callback();
      }
    }
  }

  _getWorkflowStyle(statut: string): string {
    switch (statut) {
      case "A":
        return "ui-phx-statut-accepte";
      case "D":
      case "T":
        return "ui-phx-statut-demande";
      case "I":
        return "ui-phx-statut-en-cours";
      case "R":
        return "ui-phx-statut-refuse";
      case "H":
        return "ui-phx-statut-hors-validation";
    }
    return "";
  }

  _toogleNameNumberJours(hebdo: string): void {
    if (hebdo === "semaine") {
      this.table.model.lock("jour");
      this.table.model.unlock("nomJour");
    } else {
      this.table.model.unlock("jour");
      this.table.model.lock("nomJour");
    }
  }

  _buildHistoDemande(objModel: CWListerRegularisationModel): string {
    let index = 0;
    const wkfModels = [];
    let message = "";

    for (let i = 0; i < this.historiqueColl.length; i++) {
      const model = this.historiqueColl.at(i);

      //if iddemande = 0 use the evenement
      if (objModel.get("iddemande") === 0) {
        if (model.get("evenement") === objModel.get("evenement")) {
          wkfModels[index] = model;
          if (index === 0) {
            index++;
          }
        }
      } else {
        if (model.get("demande") === objModel.get("iddemande")) {
          wkfModels[index] = model;
          if (index === 0) {
            index++;
          }
        }
      }
    }
    if (wkfModels[0]) {
      message = this._buildHistoLine(wkfModels[0]);
      if (wkfModels[1] && wkfModels[0].get("acte") !== wkfModels[1].get("acte")) {
        message += i18n.t('messages:GL_1035');
        message += this._buildHistoLine(wkfModels[1]);
      }
    }
    return message;
  }

  _buildHistoLine(model: Model): string {
    return WKF.buildHistoLine(model);
  }

  _bold(normalText: string): string {
    const boldText = "<b>" + normalText + "</b>";

    return boldText;
  }

  refreshList(selectedRowId: string, gererAction?: string, callback?: () => any): void {
    let position = -1;

    // calculate position (used when a row is removed)
    if (!CWSTR.isBlank(selectedRowId) && gererAction === "delete") {
      const target2 = this.table.model.coll.get(selectedRowId);

      position = _.indexOf(this.table.model.coll.models, target2);
      if (position > 0) {
        position--;
      }
    }
    if (CWSTR.isBlank(selectedRowId) && this.table.model.coll.pagination !== null) {
      this.table.model.coll.pagination.startIndex = 0;
    }
    // fetch data form table
    this.table.model.coll.fetch({
      success: () => {
        if (this.table.model.coll.length === 0) {
          this.model.trigger("isEmpty");
        }
        if (this.table.model.coll.length > 0 && this.context.ctxEcran !== "suivicollab") {
          let row: CWListerRegularisationModel;

          if (CWSTR.isBlank(selectedRowId) && this.table.model.coll.length > 0) {
            row = this.table.model.coll.at(0) as CWListerRegularisationModel;
          } else {
            row = this.table.model.coll.get(selectedRowId) as CWListerRegularisationModel;
            if (CWSTR.isBlank(row) && gererAction === "delete") {
              position = Math.min(position, this.table.model.coll.length);
              position = position === -1 ? 0 : position;
              row = this.table.model.coll.at(position) as CWListerRegularisationModel;
            }
          }
          this._manageTableMode();
          if (CWSTR.isBlank(row)) {
            row = new CWListerRegularisationModel({ id: selectedRowId });
          }
          if (!CWSTR.isBlank(gererAction)) {
            this.table.model.resetScroll();
            this.table.model.selectRow(row);
          }
        } else {
          if (this.table.model.coll.length === 0 && gererAction && gererAction === "create" && this.context && this.context.ctxEcran !== "suivicollab") {
            const row = new CWListerRegularisationModel({ id: selectedRowId });

            if (!CWSTR.isBlank(gererAction)) {
              this.table.model.resetScroll();
              this.table.model.selectRow(row);
            }
          } else {
            this.model.trigger("isEmpty");
          }
        }
        if (typeof callback === "function") {
          callback();
        }
      },
      error: (): void => {
        if (typeof callback === "function") {
          callback();
        }
      }
    });
  }

  unselectAllRows(): void {
    this.table.model.coll.trigger("row:click", this.table.model.get("value"));
    this.table.model.set("value", null);
  }

  private searchFilter(): void {
    this.filter.model.set('isEditing', true);
    if (this.filter.model.get("moreCriterias") !== -1) {
      this.filter.model.trigger('btn:click', "search");
    }
  }

  private resteTitle(): void {
    if (this.context && this.context.ctxEcran === "suivicollab") {
      const title = '<span>' + i18n.t('suivicollab.menuRegularisations') + '</span><span class="cw-texteSuperAllege"> (' + this.table.model.coll.totalRecords + ') </span>';

      this.$el.find(".cw-fieldset__situationPanel__title .cw-titre").html(title);
    }
  }

  selectRegularisation(selectEvenement?: string, gererAction?: string): void {
    if (this.table.model.coll.length > 0 && !CWSTR.isBlank(gererAction)) {//sans 'gererAction', on ne fera rien
      let row: CWListerRegularisationModel;

      if (CWSTR.isBlank(selectEvenement)) {
        row = this.table.model.coll.at(0) as CWListerRegularisationModel;
      } else {
        row = _.find(this.table.model.coll.models, (itemAbs: CWListerRegularisationModel): boolean => {
          return itemAbs.get("evenement") === selectEvenement;
        });
        if (CWSTR.isBlank(row)) {
          row = this.table.model.coll.at(0) as CWListerRegularisationModel;
        }
      }
      this.table.model.selectRow(row);
    }
  }
}
