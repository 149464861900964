module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="cw-app-header d-flex flex-fill align-items-center justify-content-between">\n    <div class="cw-header-usecase-title d-flex col-4"></div>\n    <div class="cw-header-transversale d-flex align-items-center col-8">\n        <div class="phx-populations-menu-zone-container d-flex mr-5">\n            <div class="phx-populations-menu-zone"></div>\n        </div>\n        <div class="phx-right-menu d-flex align-items-center">\n            <div id="phx-menu-bar"></div>\n            <div id="phx-account" class="d-flex align-items-center">\n                <span class="phx-acount-span cw-texte-reduit">\n                    <span class="phx-account-username">';
 if(!STR.isBlank(auth.name)) {
                      print(auth.name) } else { print(auth.login) } 
__p+=' </span>\n                </span>\n                <div id="phx-user-menu"></div>\n            </div>\n            <div id="cw-logout">\n                <span tabindex="0" data-cy="wp-logout" class="phx-menus-transversal-logout" title="'+
((__t=(i18n.t('screens:app.logout')))==null?'':__t)+
'">\n                    '+
((__t=(UTILS.getSVGIcon('deconnexion','',20,i18n.t('screens:app.logout'))))==null?'':__t)+
'\n                </span>\n            </div>\n        </div>\n    </div>\n</div>\n';
}
return __p;
};
